import type { FC } from 'react';

import TableActionButton from '@zen/Components/TableActionButton';
import type { IconName } from '@zen/Styleguide';

interface Props {
  isDefaultChargeVisible: boolean | undefined;
  onDelete: () => void;
  onUpdate: () => void;
}

interface ActionsConfig {
  action: () => void;
  icon: IconName;
}

const ActionHandleContainer: FC<Props> = (props) => {
  const { isDefaultChargeVisible, onDelete, onUpdate } = props;
  const canDelete: boolean = isDefaultChargeVisible === undefined;
  const getIcon = (): IconName => {
    if (canDelete) {
      return 'zicon-trash';
    }

    return isDefaultChargeVisible ? 'zicon-hidden' : 'zicon-visible';
  };

  const config: ActionsConfig = {
    action: canDelete ? onDelete : onUpdate,
    icon: getIcon()
  };

  return <TableActionButton icon={config.icon} onClick={config.action} />;
};

export default ActionHandleContainer;
