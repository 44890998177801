export const COLUMN: string = 'column';

export interface DragAndDropItem {
  id: string;
}

export interface DropData {
  isOver: boolean;
}

export interface DragData {
  isDragging: boolean;
}
