import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ZencargoNetworkQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type ZencargoNetworkQueryResult = { __typename?: 'Query' } & {
  zencargoNetwork?: GraphQLTypes.Maybe<{ __typename?: 'Network' } & Pick<GraphQLTypes.Network, 'id'>>;
};

export const ZencargoNetworkQueryDocument = /* #__PURE__ */ gql`
  query zencargoNetworkQuery {
    zencargoNetwork {
      id
    }
  }
`;

/**
 * __useZencargoNetworkQuery__
 *
 * To run a query within a React component, call `useZencargoNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useZencargoNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZencargoNetworkQuery({
 *   variables: {
 *   },
 * });
 */
export function useZencargoNetworkQuery(
  baseOptions?: Apollo.QueryHookOptions<ZencargoNetworkQueryResult, ZencargoNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ZencargoNetworkQueryResult, ZencargoNetworkQueryVariables>(ZencargoNetworkQueryDocument, options);
}
export function useZencargoNetworkQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ZencargoNetworkQueryResult, ZencargoNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ZencargoNetworkQueryResult, ZencargoNetworkQueryVariables>(ZencargoNetworkQueryDocument, options);
}
export type ZencargoNetworkQueryHookResult = ReturnType<typeof useZencargoNetworkQuery>;
export type ZencargoNetworkQueryLazyQueryHookResult = ReturnType<typeof useZencargoNetworkQueryLazyQuery>;
