import { ChargeBasisEnum, ChargeTypeEnum } from '../types';

export const chargeTypeLabelMapping: Record<ChargeTypeEnum, string> = {
  [ChargeTypeEnum.DESTINATION]: 'Destination',
  [ChargeTypeEnum.FREIGHT]: 'Freight',
  [ChargeTypeEnum.ORIGIN]: 'Origin'
};

export const chargeBasisLabelMapping: Record<ChargeBasisEnum, string> = {
  [ChargeBasisEnum.BILL]: 'Bill',
  [ChargeBasisEnum.BOOKING]: 'Booking',
  [ChargeBasisEnum.CHARGEABLE_WEIGHT]: 'Chargeable weight',
  [ChargeBasisEnum.CONTAINER]: 'Container',
  [ChargeBasisEnum.DAY]: 'Day',
  [ChargeBasisEnum.HOUR]: 'Hour',
  [ChargeBasisEnum.HS_CODE]: 'HS code',
  [ChargeBasisEnum.LABEL]: 'Label',
  [ChargeBasisEnum.OTHER]: 'Other',
  [ChargeBasisEnum.PALLET]: 'Pallet',
  [ChargeBasisEnum.TEU]: 'TEU',
  [ChargeBasisEnum.WEIGHT_MEASURE_AIR]: 'Weight measure air',
  [ChargeBasisEnum.WEIGHT_MEASURE_OCEAN]: 'Weight measure ocean'
};
