import { WebhookHeader, WebhookSubscriptionTopicEnum } from '../types';

export const topicOptions = [
  {
    value: WebhookSubscriptionTopicEnum.BOOKING_CREATED,
    label: 'Booking created'
  },
  {
    value: WebhookSubscriptionTopicEnum.CARGO_CONTAINER_CONFIGURATION_CHANGED,
    label: 'Cargo container configuration changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.CARGO_CONTAINER_NUMBER_CHANGED,
    label: 'Cargo container number changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.LOT_BOOKED,
    label: 'Lot booked'
  },
  {
    value: WebhookSubscriptionTopicEnum.LOT_QUANTITY_CHANGED,
    label: 'Lot quantity changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.PURCHASE_ORDER_CARGO_READY_DATES_CHANGED,
    label: 'Purchase order cargo ready dates changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.PURCHASE_ORDER_CLOSED,
    label: 'Purchase order closed'
  },
  {
    value: WebhookSubscriptionTopicEnum.PACKING_LIST_LINES_CHANGED,
    label: 'Packing list lines changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.SHIPMENT_DELIVERY_ESTIMATE_CHANGED,
    label: 'Shipment delivery estimate changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.SHIPMENT_SAILING_SCHEDULE_CHANGED,
    label: 'Shipment sailing schedule changed'
  },
  {
    value: WebhookSubscriptionTopicEnum.SHIPMENT_STAGE_CHANGED,
    label: 'Shipment stage changed'
  }
];

export const emptyHeader: WebhookHeader = {
  name: '',
  value: ''
};
