import type { FC } from 'react';

import { FormLabel } from '@zen/Components';
import CargoModeSelect from '@zen/Components/CargoModeSelect';
import ModeOfTransportSelect from '@zen/Components/ModeOfTransportSelect';
import NetworkContactSelect from '@zen/Components/NetworkContactSelect';
import { Container, DatePickerInput, DateRange } from '@zen/DesignSystem';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import type { RateCardInputUpdatePayload, RateCardReducerState } from '@zen/RateCards/reducer';
import { getToday } from '@zen/utils/date';
import { add, compareDate } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  disabledDateRanges?: DateRange[];
  onChange: (payload: RateCardInputUpdatePayload) => void;
  values: RateCardReducerState;
}

const RateCardOverview: FC<Props> = ({ disabledDateRanges, onChange, values }) => {
  const { cargoType, endDate, issuedBy, modeOfTransport, startDate } = values;
  const { data } = useZencargoNetwork();
  const zencargoNetworkId = data?.zencargoNetwork?.id;
  const initialStartDate: string = getToday();
  const initialEndDate: string = add(getToday(), { days: 13 });

  const handleStartDateChange = (date: Nullable<string>): void => {
    onChange({ startDate: date || initialStartDate });

    if (date && compareDate(date).isAfter(endDate)) {
      handleEndDateChange(add(date, { days: 1 }));
    }
  };

  const handleEndDateChange = (date: Nullable<string>): void => {
    onChange({ endDate: date || initialEndDate });
  };

  const handleCoordinatorChange = (id: Nullable<string>): void => {
    onChange({ issuedBy: id || '' });
  };

  return (
    <Container title="Overview">
      <div className="grid grid-cols-5 gap-x-4">
        <div>
          <FormLabel label="Mode of transport" />
          <ModeOfTransportSelect isDisabled={true} label="Mode of transport" name="modeOfTransport" value={modeOfTransport} />
        </div>
        <CargoModeSelect isDisabled={true} label="Cargo mode" name="cargoType" value={cargoType} />
        <div>
          <FormLabel label="Start date" />
          <DatePickerInput
            disabledDates={{ dateRanges: disabledDateRanges }}
            name="startDate"
            onDayChange={handleStartDateChange}
            selectedDate={startDate}
          />
        </div>
        <div>
          <FormLabel label="End date" />
          <DatePickerInput
            disabledDates={{
              dateRanges: disabledDateRanges,
              minDate: startDate
            }}
            name="endDate"
            onDayChange={handleEndDateChange}
            selectedDate={endDate}
          />
        </div>
        <NetworkContactSelect
          accountId={zencargoNetworkId}
          isMulti={false}
          label="Issued by"
          name="issuedBy"
          onChange={handleCoordinatorChange}
          value={issuedBy || null}
        />
      </div>
    </Container>
  );
};

export default RateCardOverview;
