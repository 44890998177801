import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingRequestsRevertRejectionAsManufacturerMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.BookingRequestsRevertRejectionAsManufacturerInput;
}>;

export type BookingRequestsRevertRejectionAsManufacturerMutationResult = { __typename?: 'Mutation' } & {
  bookingRequestsRevertRejectionAsManufacturer?: GraphQLTypes.Maybe<
    { __typename?: 'BookingRequestsRevertRejectionAsManufacturerPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const BookingRequestsRevertRejectionAsManufacturerDocument = /* #__PURE__ */ gql`
  mutation bookingRequestsRevertRejectionAsManufacturer($input: BookingRequestsRevertRejectionAsManufacturerInput!) {
    bookingRequestsRevertRejectionAsManufacturer(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useBookingRequestsRevertRejectionAsManufacturerMutation__
 *
 * To run a mutation, you first call `useBookingRequestsRevertRejectionAsManufacturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestsRevertRejectionAsManufacturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [bookingRequestsRevertRejectionAsManufacturerMutation, { data, loading, error }] = useBookingRequestsRevertRejectionAsManufacturerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBookingRequestsRevertRejectionAsManufacturerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BookingRequestsRevertRejectionAsManufacturerMutationResult,
    BookingRequestsRevertRejectionAsManufacturerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    BookingRequestsRevertRejectionAsManufacturerMutationResult,
    BookingRequestsRevertRejectionAsManufacturerMutationVariables
  >(BookingRequestsRevertRejectionAsManufacturerDocument, options);
}
export type BookingRequestsRevertRejectionAsManufacturerMutationHookResult = ReturnType<
  typeof useBookingRequestsRevertRejectionAsManufacturerMutation
>;
