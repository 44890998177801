import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IssuesGetLegacyBookingIssuesQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  bookingManagerIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.BookingIssueSortInput>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type IssuesGetLegacyBookingIssuesQueryResult = { __typename?: 'Query' } & {
  legacyBookingIssues?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyBookingIssueConnection' } & Pick<GraphQLTypes.LegacyBookingIssueConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'LegacyBookingIssue' } & Pick<
                GraphQLTypes.LegacyBookingIssue,
                'zencargoReference' | 'clientReference' | 'issueId' | 'category' | 'severity' | 'title'
              > & {
                  occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
                  openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
                  startedAt?: GraphQLTypes.Maybe<
                    { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const IssuesGetLegacyBookingIssuesDocument = /* #__PURE__ */ gql`
  query issuesGetLegacyBookingIssues(
    $accountIds: [String!]
    $bookingManagerIds: [String!]
    $after: String
    $before: String
    $order: BookingIssueSortInput
    $first: Int
    $last: Int
  ) {
    legacyBookingIssues(
      accountIds: $accountIds
      bookingManagerIds: $bookingManagerIds
      after: $after
      before: $before
      order: $order
      first: $first
      last: $last
    ) {
      nodes {
        zencargoReference
        clientReference
        issueId
        category
        severity
        title
        occurredOn {
          date
        }
        openedAt {
          date
          dateTime
        }
        startedAt {
          date
          dateTime
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useIssuesGetLegacyBookingIssuesQuery__
 *
 * To run a query within a React component, call `useIssuesGetLegacyBookingIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssuesGetLegacyBookingIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssuesGetLegacyBookingIssuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      bookingManagerIds: // value for 'bookingManagerIds'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useIssuesGetLegacyBookingIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<IssuesGetLegacyBookingIssuesQueryResult, IssuesGetLegacyBookingIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IssuesGetLegacyBookingIssuesQueryResult, IssuesGetLegacyBookingIssuesQueryVariables>(
    IssuesGetLegacyBookingIssuesDocument,
    options
  );
}
export function useIssuesGetLegacyBookingIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IssuesGetLegacyBookingIssuesQueryResult, IssuesGetLegacyBookingIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IssuesGetLegacyBookingIssuesQueryResult, IssuesGetLegacyBookingIssuesQueryVariables>(
    IssuesGetLegacyBookingIssuesDocument,
    options
  );
}
export type IssuesGetLegacyBookingIssuesQueryHookResult = ReturnType<typeof useIssuesGetLegacyBookingIssuesQuery>;
export type IssuesGetLegacyBookingIssuesLazyQueryHookResult = ReturnType<typeof useIssuesGetLegacyBookingIssuesLazyQuery>;
