import type { FC } from 'react';

import NoResults from '@zen/Components/NoResults';

import { ReactComponent as Image } from './log.svg';

const NoMilestoneSelected: FC = () => {
  return (
    <NoResults
      headline="No milestone selected"
      image={<Image />}
      tagline="Please use filters to narrow down the log of change for specific milestone."
    />
  );
};

export default NoMilestoneSelected;
