import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import { useUsedAssignablesLazyQuery } from '@zen/Networks/graphql';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks/types';

import type { AssignableType } from './types';

type SearchableNetworkOptionsArguments = {
  accountIds?: string[];
  assignmentTypes: AssignmentTypeValue[];
  domainNames: AssignmentTargetTypeEnum[];
  selectedValues?: string[];
};

export const useSearchableNetworkOptions = (args: SearchableNetworkOptionsArguments) => {
  const { accountIds, assignmentTypes, domainNames, selectedValues } = args;
  const [fetchAssignables, { data, loading, error }] = useUsedAssignablesLazyQuery();

  useEffect(() => {
    if (selectedValues) {
      fetchAssignables({
        variables: {
          networkIds: accountIds,
          assignmentNames: assignmentTypes,
          targetTypes: domainNames,
          assignableIds: selectedValues
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchAssignables({
        variables: {
          networkIds: accountIds,
          assignmentNames: assignmentTypes,
          targetTypes: domainNames,
          textContains: query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createAssignableOption = (assignable: AssignableType): Option<string> => ({
    label: assignable.label?.long || '',
    value: assignable.id || ''
  });

  const options: Option<string>[] = data?.networksUsedAssignables?.map(createAssignableOption) || [];

  return { options, loading, error: !!error, onSearch: debouncedHandleInputChange };
};
