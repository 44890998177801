import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Pill } from '@zen/DesignSystem';
import type { IconType } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  alignmentVariant?: 'right' | 'left';
  dryStandard?: Optional<boolean>;
  flatRack?: Optional<boolean>;
  garmentsOnHangers?: Optional<boolean>;
  hazardous?: Optional<boolean>;
  healthCertificate?: Optional<boolean>;
  openTop?: Optional<boolean>;
  overweight?: Optional<boolean>;
  refrigerated?: Optional<boolean>;
  stackable?: Optional<boolean>;
  tailLift?: Optional<boolean>;
}

const CargoOptions: FC<Props> = (props) => {
  const {
    alignmentVariant = 'left',
    dryStandard,
    flatRack,
    garmentsOnHangers,
    hazardous,
    healthCertificate,
    openTop,
    overweight,
    refrigerated,
    stackable,
    tailLift
  } = props;
  const classNames: string = cx(
    {
      'justify-start': alignmentVariant === 'left',
      'justify-end': alignmentVariant === 'right'
    },
    'flex space-x-2 flex-wrap'
  );

  const hasOptions: boolean = [
    dryStandard,
    flatRack,
    garmentsOnHangers,
    hazardous,
    healthCertificate,
    openTop,
    overweight,
    refrigerated,
    stackable,
    tailLift
  ].some(Boolean);

  const renderPill = (icon: IconType, label: string): ReactNode => {
    return (
      <Pill className="mb-1" iconLeft={icon} type="default">
        {label}
      </Pill>
    );
  };

  if (!hasOptions) {
    return null;
  }

  return (
    <div className={classNames} data-testid="cargo-options">
      {dryStandard && renderPill('zicon-shipment', 'Dry standard')}
      {flatRack && renderPill('zicon-shipment', 'Flat rack')}
      {garmentsOnHangers && renderPill('zicon-garments', 'Garments on hangers')}
      {hazardous && renderPill('zicon-hazardous', 'Hazardous')}
      {healthCertificate && renderPill('zicon-orders', 'Health certificate')}
      {tailLift && renderPill('zicon-tail-lift', 'Tail lift')}
      {openTop && renderPill('zicon-shipment', 'Open top')}
      {overweight && renderPill('zicon-overweight', 'Overweight')}
      {refrigerated && renderPill('zicon-refrigerated', 'Refrigerated')}
      {stackable && renderPill('zicon-stackable', 'Stackable')}
    </div>
  );
};

export type { Props as CargoOptionsType };

export default CargoOptions;
