import type { FC } from 'react';

import MultiStopSelect from '@zen/Components/MultiStopSelect';
import type { MultiStopEnum } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateMultiStopMutation } from './graphql';

interface Props {
  multiStop: Optional<MultiStopEnum>;
  zencargoReference: string;
}

const MultiStopInlineField: FC<Props> = ({ multiStop, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [updateMultiStop, { loading }] = useUpdateMultiStopMutation();

  const handleUpdateMultiStop = async (value: Nullable<MultiStopEnum>): Promise<void> => {
    if (value) {
      await performMutation({
        mutationFn: () =>
          updateMultiStop({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary'],
            variables: {
              input: {
                zencargoReference,
                bookingInput: {
                  multiStop: value
                }
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          addSuccess('Multi-stop updated.');
        }
      });
    }
  };

  return (
    <div className="-ml-3">
      <MultiStopSelect
        isDisabled={loading}
        isLoading={loading}
        name="multiStop"
        onChange={handleUpdateMultiStop}
        renderMenuInPortal={true}
        value={multiStop}
        variant="inline"
      />
    </div>
  );
};

export default MultiStopInlineField;
