import type { IconName } from '@zen/Styleguide';

import type { MarkerIcon } from './types';

export const iconMapping: Record<MarkerIcon, IconName> = {
  pin: 'zicon-pin',
  truck: 'zicon-road',
  warehouse: 'zicon-warehouse',
  sea: 'zicon-ship',
  air: 'zicon-air',
  office: 'zicon-office'
};
