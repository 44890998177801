const COST_TRACKING_RELATIVE_PATH = 'cost-tracking';

export const COST_TRACKING_PREFIX = `/dashboard/${COST_TRACKING_RELATIVE_PATH}`;

interface CostTrackingRoutes {
  index: {
    getUrl: (zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
}

const costTrackingRoutes: CostTrackingRoutes = {
  index: {
    path: `${COST_TRACKING_PREFIX}/:zencargoReference`,
    relativePath: `${COST_TRACKING_RELATIVE_PATH}/:zencargoReference`,
    getUrl: (zencargoReference: string) => `${COST_TRACKING_PREFIX}/${zencargoReference}`
  }
};

export default costTrackingRoutes;
