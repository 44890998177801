import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCargoIdsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetCargoIdsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & {
            coreCargos?: GraphQLTypes.Maybe<Array<{ __typename?: 'CoreCargo' } & Pick<GraphQLTypes.CoreCargo, 'id'>>>;
          }
        >
      >
    >;
  };
};

export const GetCargoIdsDocument = /* #__PURE__ */ gql`
  query getCargoIds($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        coreCargos {
          id
        }
      }
    }
  }
`;

/**
 * __useGetCargoIdsQuery__
 *
 * To run a query within a React component, call `useGetCargoIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCargoIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCargoIdsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetCargoIdsQuery(baseOptions: Apollo.QueryHookOptions<GetCargoIdsQueryResult, GetCargoIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCargoIdsQueryResult, GetCargoIdsQueryVariables>(GetCargoIdsDocument, options);
}
export function useGetCargoIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCargoIdsQueryResult, GetCargoIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCargoIdsQueryResult, GetCargoIdsQueryVariables>(GetCargoIdsDocument, options);
}
export type GetCargoIdsQueryHookResult = ReturnType<typeof useGetCargoIdsQuery>;
export type GetCargoIdsLazyQueryHookResult = ReturnType<typeof useGetCargoIdsLazyQuery>;
