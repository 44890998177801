import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderCountQueryVariables = GraphQLTypes.Exact<{
  customerId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OrderCountQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & Pick<GraphQLTypes.PurchaseOrderConnection, 'totalCount'>;
};

export const OrderCountQueryDocument = /* #__PURE__ */ gql`
  query orderCountQuery($customerId: String) {
    purchaseOrders(customerUuid: $customerId) {
      totalCount
    }
  }
`;

/**
 * __useOrderCountQuery__
 *
 * To run a query within a React component, call `useOrderCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderCountQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useOrderCountQuery(baseOptions?: Apollo.QueryHookOptions<OrderCountQueryResult, OrderCountQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderCountQueryResult, OrderCountQueryVariables>(OrderCountQueryDocument, options);
}
export function useOrderCountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderCountQueryResult, OrderCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderCountQueryResult, OrderCountQueryVariables>(OrderCountQueryDocument, options);
}
export type OrderCountQueryHookResult = ReturnType<typeof useOrderCountQuery>;
export type OrderCountQueryLazyQueryHookResult = ReturnType<typeof useOrderCountQueryLazyQuery>;
