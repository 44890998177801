import type { FC, ReactNode } from 'react';

import { Chip } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  disabled?: boolean;
  label: string;
  onClick: () => void;
  onDelete: () => void;
  value: Nullable<ReactNode>;
}

const FilterChip: FC<Props> = ({ label, value, disabled, onDelete, onClick }) => {
  const displayLabel: ReactNode = value ? (
    <span className="flex space-x-0.5">
      <span>{label}:</span>
      <span className="max-w-40">{value}</span>
    </span>
  ) : (
    label
  );

  return (
    <Chip isDisabled={disabled} isSelected={true} label={displayLabel} onDelete={onDelete} onSelect={onClick} size="default" />
  );
};

export default FilterChip;
