import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetContactNamesQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type GetContactNamesQueryResult = { __typename?: 'Query' } & {
  contacts?: GraphQLTypes.Maybe<
    { __typename?: 'NetworkContactConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'name'>>>
      >;
    }
  >;
};

export const GetContactNamesDocument = /* #__PURE__ */ gql`
  query getContactNames($paginated: Boolean) {
    contacts(paginated: $paginated) {
      nodes {
        name
      }
    }
  }
`;

/**
 * __useGetContactNamesQuery__
 *
 * To run a query within a React component, call `useGetContactNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactNamesQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *   },
 * });
 */
export function useGetContactNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetContactNamesQueryResult, GetContactNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetContactNamesQueryResult, GetContactNamesQueryVariables>(GetContactNamesDocument, options);
}
export function useGetContactNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactNamesQueryResult, GetContactNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetContactNamesQueryResult, GetContactNamesQueryVariables>(GetContactNamesDocument, options);
}
export type GetContactNamesQueryHookResult = ReturnType<typeof useGetContactNamesQuery>;
export type GetContactNamesLazyQueryHookResult = ReturnType<typeof useGetContactNamesLazyQuery>;
