import { FC, useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import type { AccountConnectionTypeEnum } from '../../types';
import DeleteConnectionModal from '../DeleteConnectionModal';

interface Props {
  connectionType: AccountConnectionTypeEnum;
  sourceAccountId: string;
  targetAccountId: string;
  targetAccountName: string;
}

const DeleteConnectionButton: FC<Props> = (props) => {
  const { connectionType, sourceAccountId, targetAccountId, targetAccountName } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);

  return (
    <>
      <DeleteConnectionModal
        connectionType={connectionType}
        isOpen={isModalOpen}
        onClose={toggleModal}
        sourceAccountId={sourceAccountId}
        targetAccountId={targetAccountId}
        targetAccountName={targetAccountName}
      />
      <IconButton icon="zicon-trash" onClick={toggleModal} size="medium" title="Delete" variant="ghost" />
    </>
  );
};

export default DeleteConnectionButton;
export type { Props as DeleteConnectionButtonProps };
