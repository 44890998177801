import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAllBookingDocumentTypesQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type GetAllBookingDocumentTypesQueryResult = { __typename?: 'Query' } & {
  bookingDocumentTypes: { __typename?: 'BookingDocumentTypeConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'BookingDocumentType' } & Pick<
            GraphQLTypes.BookingDocumentType,
            'id' | 'description' | 'name' | 'title'
          > & {
              permissions: { __typename?: 'Permissions' } & Pick<
                GraphQLTypes.Permissions,
                'agentForwarder' | 'customerUser' | 'manufacturer'
              >;
            }
        >
      >
    >;
  };
};

export const GetAllBookingDocumentTypesDocument = /* #__PURE__ */ gql`
  query getAllBookingDocumentTypes($paginated: Boolean) {
    bookingDocumentTypes(paginated: $paginated) {
      nodes {
        id
        description
        name
        title
        permissions {
          agentForwarder
          customerUser
          manufacturer
        }
      }
    }
  }
`;

/**
 * __useGetAllBookingDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetAllBookingDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBookingDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBookingDocumentTypesQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *   },
 * });
 */
export function useGetAllBookingDocumentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllBookingDocumentTypesQueryResult, GetAllBookingDocumentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAllBookingDocumentTypesQueryResult, GetAllBookingDocumentTypesQueryVariables>(
    GetAllBookingDocumentTypesDocument,
    options
  );
}
export function useGetAllBookingDocumentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllBookingDocumentTypesQueryResult, GetAllBookingDocumentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAllBookingDocumentTypesQueryResult, GetAllBookingDocumentTypesQueryVariables>(
    GetAllBookingDocumentTypesDocument,
    options
  );
}
export type GetAllBookingDocumentTypesQueryHookResult = ReturnType<typeof useGetAllBookingDocumentTypesQuery>;
export type GetAllBookingDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetAllBookingDocumentTypesLazyQuery>;
