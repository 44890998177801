const ORDERS_RELATIVE_INDEX_PATH = 'orders';

export const ORDERS_ROUTE_PREFIX = `/dashboard/${ORDERS_RELATIVE_INDEX_PATH}`;

// This matcher will make sure the param is in a certain format,
// if not router won't take this url as a match.
export const uuidMatcher = '(\\w+-\\w+-\\w+-\\w+-\\w+)';

interface OrderRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  lotAssignmentsToBooking: {
    getUrl: (zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  orderActivity: {
    getRelativeUrl: () => string;
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  orderDetails: {
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  orderDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  orderEdit: {
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  orderIssues: {
    getRelativeUrl: () => string;
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  orderItems: {
    getRelativeUrl: () => string;
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  orderList: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  orderNew: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  orderOtherDetails: {
    getRelativeUrl: () => string;
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
}

const orderRoutes: OrderRoutes = {
  index: {
    path: `${ORDERS_ROUTE_PREFIX}/*`,
    getUrl: () => ORDERS_ROUTE_PREFIX,
    relativePath: `${ORDERS_RELATIVE_INDEX_PATH}/*`
  },
  orderNew: {
    path: `${ORDERS_ROUTE_PREFIX}/new`,
    getUrl: () => `${ORDERS_ROUTE_PREFIX}/new`,
    relativePath: 'new'
  },
  orderActivity: {
    path: `${ORDERS_ROUTE_PREFIX}/:id${uuidMatcher}/activity`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}/activity`,
    relativePath: 'activity',
    getRelativeUrl: () => 'activity'
  },
  orderOtherDetails: {
    path: `${ORDERS_ROUTE_PREFIX}/:id${uuidMatcher}/details`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}/details`,
    relativePath: 'details',
    getRelativeUrl: () => 'details'
  },
  orderEdit: {
    path: `${ORDERS_ROUTE_PREFIX}/:id${uuidMatcher}/edit`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}/edit`,
    relativePath: ':id/edit'
  },
  orderItems: {
    path: `${ORDERS_ROUTE_PREFIX}/:id${uuidMatcher}/items`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}/items`,
    relativePath: 'items',
    getRelativeUrl: () => 'items'
  },
  orderIssues: {
    path: `${ORDERS_ROUTE_PREFIX}/:id${uuidMatcher}/items`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}/issues`,
    relativePath: 'issues',
    getRelativeUrl: () => 'issues'
  },
  orderDetails: {
    path: `${ORDERS_ROUTE_PREFIX}/:id`,
    getUrl: (orderId: string) => `${ORDERS_ROUTE_PREFIX}/${orderId}`,
    relativePath: ':id/*'
  },
  orderList: {
    path: `${ORDERS_ROUTE_PREFIX}/*`,
    getUrl: () => ORDERS_ROUTE_PREFIX,
    relativePath: '*'
  },
  orderDetailsPage: {
    path: `${ORDERS_ROUTE_PREFIX}/details/:id/:sectionName?`,
    relativePath: `${ORDERS_RELATIVE_INDEX_PATH}/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${ORDERS_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  lotAssignmentsToBooking: {
    path: `${ORDERS_ROUTE_PREFIX}/assignments/:zencargoReference`,
    getUrl: (zencargoReference: string) => `${ORDERS_ROUTE_PREFIX}/assignments/${zencargoReference}`,
    relativePath: 'assignments/:zencargoReference'
  }
};

export default orderRoutes;
