const OPS_QUOTES_RELATIVE_INDEX_PATH = 'ops-quotes';

export const OPS_QUOTES_ROUTE_PREFIX = `/dashboard/${OPS_QUOTES_RELATIVE_INDEX_PATH}`;

interface OpsQuoteRoutes {
  quoteIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  quoteIndexOrderDetails: {
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  quotesDetailsPage: {
    getUrl: (zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
}

const opsQuoteRoutes: OpsQuoteRoutes = {
  quoteIndex: {
    path: `${OPS_QUOTES_ROUTE_PREFIX}/*`,
    getUrl: () => OPS_QUOTES_ROUTE_PREFIX,
    relativePath: `${OPS_QUOTES_RELATIVE_INDEX_PATH}/*`
  },
  quoteIndexOrderDetails: {
    getUrl: (orderId: string) => `${OPS_QUOTES_ROUTE_PREFIX}/${orderId}`,
    path: `${OPS_QUOTES_ROUTE_PREFIX}/:id/*`,
    relativePath: ':id/*'
  },
  quotesDetailsPage: {
    path: `${OPS_QUOTES_ROUTE_PREFIX}/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${OPS_QUOTES_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    },
    relativePath: 'details/:id/:sectionName?'
  }
};

export default opsQuoteRoutes;
