import type { FC, ReactNode } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import { BookingStageEnum, isRoadBooking, VoyageMilestoneNameEnum } from '@zen/Booking';
import BookingStages from '@zen/Booking/BookingDetails/BookingOverview/components/BookingStages';
import SpecialInstructions from '@zen/Components/SpecialInstructions';
import { Banner, Icon, TextLink } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';
import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import { RoadTrackingAction, RoadTrackingCategory } from '../../../../types';
import type { BookingSummaryData } from '../../types';
import BookingActions from '../BookingActions';
import BookingRoute from '../BookingRoute';
import CargoJourneysSummary from '../CargoJourneysSummary';
import CompleteBookingButton from '../CompleteBookingButton';
import { getAddressLabel, getBookingStatus, getBookingStatusLabel } from './helpers';

interface StageProps {
  iconClassName: string;
  iconName: IconName;
  label?: string;
  showDeliveryEstimate?: boolean;
  value: string;
}

interface Props {
  booking: BookingSummaryData;
  onBookingCompleted: () => void;
}

const RouteSummary: FC<Props> = ({ booking, onBookingCompleted }) => {
  const role = useRole();
  const { trackEvent } = useTracking();
  const {
    archivedAt,
    bookingStages,
    cargoJourneysSummary,
    coreCargos,
    customsOnly,
    modeOfTransport,
    roadTracking,
    specialInstructions,
    voyageMilestone,
    zencargoReference
  } = booking;

  const voyageMilestoneName: Optional<VoyageMilestoneNameEnum> = voyageMilestone?.name;
  const isArchived: boolean = !!archivedAt?.date;
  const isInProgress: boolean = !!voyageMilestoneName && voyageMilestoneName !== VoyageMilestoneNameEnum.GATE_OUT_EMPTY;
  const isDelivered: boolean = voyageMilestoneName === VoyageMilestoneNameEnum.DELIVERED;
  const isAdmin: boolean = role === Role.ADMIN;
  const shouldRenderCargoJourneysSummary: boolean = !!cargoJourneysSummary && !isArchived;
  const cargoIds: string[] = coreCargos?.map(({ id }) => id) || [];
  const isBookingReceived = !!bookingStages?.find(({ name }) => name === BookingStageEnum.BOOKING_RECEIVED)?.current;
  const isCompleteBookingButtonVisible: boolean = customsOnly && !isDelivered && cargoIds.length > 0 && isAdmin;
  const trackingMapUrl: Optional<string> = roadTracking?.trackingInput?.trackingSubscription?.mapUrl;

  const handleTrackingLinkClick = (): void => {
    trackEvent({
      category: RoadTrackingCategory,
      action: RoadTrackingAction.TRACKING_LINK_CLICK,
      label: zencargoReference
    });
  };

  const renderStatus = ({ iconName, iconClassName, label, value }: StageProps): ReactNode => {
    return (
      <div className="mt-4 ml-48">
        {label && <div className="pl-12 text-xs font-normal leading-tight text-grey-base">{label}</div>}
        <div className="flex items-center">
          <div className={`flex justify-center ${JOURNEY_TIMELINE_WIDTH}`}>
            <Icon className={`text-xl ${iconClassName}`} icon={iconName} />
          </div>
          <div className="text-sm font-bold text-grey-dark">{value}</div>
        </div>
      </div>
    );
  };

  const renderBookingStatus = (): ReactNode => {
    if (!archivedAt && !isDelivered) return null;

    if (archivedAt) {
      return renderStatus({
        iconName: 'zicon-cross-oval',
        iconClassName: 'text-red-dark',
        value: 'Archived',
        showDeliveryEstimate: false
      });
    }

    return renderStatus({ iconName: 'zicon-tickoval', iconClassName: 'text-azure-base', value: 'Delivered' });
  };

  const bookingStatusLabel: string = getBookingStatusLabel(isRoadBooking(modeOfTransport), voyageMilestoneName);

  return (
    <div className="relative flex flex-col flex-1 w-full p-6">
      {customsOnly && <Banner className="p-4 mb-6 rounded" message="This is customs only shipment." />}
      <div className="relative">
        {bookingStages && <BookingStages isBookingInProgress={isInProgress} stages={bookingStages} />}
        {isAdmin && (
          <div className="absolute top-0 right-0">
            <BookingActions zencargoReference={zencargoReference} />
          </div>
        )}
      </div>
      {!shouldRenderCargoJourneysSummary && (
        <BookingRoute
          bookingStatus={getBookingStatus(isArchived, isDelivered, isInProgress)}
          bookingStatusLabel={bookingStatusLabel}
          destination={getAddressLabel(booking, 'delivery')}
          origin={getAddressLabel(booking, 'collection')}
        />
      )}
      {shouldRenderCargoJourneysSummary && (
        <CargoJourneysSummary bookingModeOfTransport={modeOfTransport} zencargoReference={zencargoReference} />
      )}
      {!shouldRenderCargoJourneysSummary && renderBookingStatus()}
      {isCompleteBookingButtonVisible && (
        <CompleteBookingButton
          cargoIds={cargoIds}
          className="mt-6 ml-64"
          isBookingReceived={isBookingReceived}
          onSuccess={onBookingCompleted}
          zencargoReference={zencargoReference}
        />
      )}
      {trackingMapUrl && (
        <div className="my-4 ml-64">
          <TextLink isExternal={true} linkTo={trackingMapUrl} onClick={handleTrackingLinkClick}>
            See GPS tracking
          </TextLink>
        </div>
      )}
      {specialInstructions?.message && <SpecialInstructions text={specialInstructions.message} />}
    </div>
  );
};

export default RouteSummary;
