import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components/Form';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { DeepNullable } from '@zen/utils/typescript';

import FormAddressFields from '../FormAddressFields';
import { validationSchema } from './locationForm.validation';
import type { ILocationFormFields } from './types';

interface Props {
  initialValues: DeepNullable<ILocationFormFields>;
  onCancel: () => void;
  onSubmit: (values: ILocationFormFields) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const LocationForm: FC<Props> = (props) => {
  const { initialValues, onSubmit, onSuccess, onCancel } = props;

  const newLocationText =
    'Please provide a name for this new location to \
    differentiate if from the other locations at this organisation.';

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => (
    <FormButtons isSubmitting={isSubmitting}>
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      className="mt-4"
      formButtons={renderFormButtons}
      formName="LocationForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {() => (
        <>
          <FormInput label="Location name" labelDescription={newLocationText} name="name" />

          <FormAddressFields showLatLngFields={true} />
        </>
      )}
    </Form>
  );
};

export default LocationForm;
