import type { Optional } from '@zen/utils/typescript';

import { browserLanguage } from '../languageSettings';

// countryCode should be an ISO3166 two-letter country code
// https://www.iso.org/iso-3166-country-codes.html
export const codeToCountryName = (countryCode: Optional<string>): string => {
  /*
    We decided to hardcode as English until we have a proper localisation strategy.
    We can use window.navigator.language once we decide to localise
  */
  const usersLanguage = browserLanguage();
  const displayNamesInLanguage = new Intl.DisplayNames([usersLanguage], { type: 'region', locale: usersLanguage });
  const defaultValue: string = '-';

  if (!countryCode) return defaultValue;

  try {
    return displayNamesInLanguage.of(countryCode) || defaultValue;
  } catch (_rangeError) {
    return defaultValue;
  }
};
