import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateJourneyAirlineMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateJourneyAirlineInput;
}>;

export type UpdateJourneyAirlineMutationResult = { __typename?: 'Mutation' } & {
  updateJourneyAirline: { __typename?: 'UpdateJourneyAirlinePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateJourneyAirlineDocument = /* #__PURE__ */ gql`
  mutation updateJourneyAirline($input: UpdateJourneyAirlineInput!) {
    updateJourneyAirline(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateJourneyAirlineMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyAirlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyAirlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyAirlineMutation, { data, loading, error }] = useUpdateJourneyAirlineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJourneyAirlineMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyAirlineMutationResult, UpdateJourneyAirlineMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateJourneyAirlineMutationResult, UpdateJourneyAirlineMutationVariables>(
    UpdateJourneyAirlineDocument,
    options
  );
}
export type UpdateJourneyAirlineMutationHookResult = ReturnType<typeof useUpdateJourneyAirlineMutation>;
