import type { FC } from 'react';

import { shipmentTypeLabelMapping, shipmentTypeOptions } from '@zen/Booking/helpers';
import LabelledValue from '@zen/Components/LabelledValue';
import { Select } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { Shipment } from '../../../types';
import { useUpdateShipmentTypeMutation } from './graphql';

interface Props {
  canUpdateShipmentType: boolean;
  onSuccess?: () => void;
  shipmentType: Optional<Shipment>;
  zencargoReference: string;
}

const ShipmentTypeInlineField: FC<Props> = ({ canUpdateShipmentType, onSuccess, shipmentType, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [updateShipmentType, { loading }] = useUpdateShipmentTypeMutation();

  const label: string = 'Shipment type';

  const handleUpdateShipmentType = async (shipmentTypeValue: Nullable<Shipment>): Promise<void> => {
    if (shipmentTypeValue) {
      await performMutation({
        mutationFn: () =>
          updateShipmentType({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary'],
            variables: {
              input: {
                zencargoReference,
                shipmentType: shipmentTypeValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          onSuccess?.();
          addSuccess('Shipment type updated.');
        }
      });
    }
  };

  if (!canUpdateShipmentType) {
    return (
      <LabelledValue label={label} variant="default">
        {shipmentType ? shipmentTypeLabelMapping[shipmentType] : '-'}
      </LabelledValue>
    );
  }

  return (
    <div className="-ml-3">
      <Select
        isDisabled={loading}
        isLoading={loading}
        label={label}
        name="shipmentType"
        onChange={handleUpdateShipmentType}
        options={shipmentTypeOptions}
        renderMenuInPortal={true}
        value={shipmentType}
        variant="inline"
      />
    </div>
  );
};

export default ShipmentTypeInlineField;
