import * as Yup from 'yup';

export const contractIdTypeValidationMessages: Record<string, string> = {
  contractId: 'Contract ID is required',
  simplifiedContractId: 'Simplified contract ID is required'
};

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  contractId: Yup.string().required(contractIdTypeValidationMessages.contractId),
  simplifiedContractId: Yup.string().required(contractIdTypeValidationMessages.simplifiedContractId)
});
