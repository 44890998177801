import { useFeatureIsOn } from '@growthbook/growthbook-react';
import { get } from 'lodash';
import type { FC, ReactNode } from 'react';

import AccountContactsSelect from '@zen/Accounts/components/forms/AccountContactsSelect';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import Copyable from '@zen/Components/Copyable';
import TerminalSelect, { Terminal } from '@zen/Components/TerminalSelect';
import type { ModeOfTransport, UpdateStopLocationInput } from '@zen/graphql/types.generated';
import { isCollectionStop, isDeliveryStop } from '@zen/Journey/helpers';
import type { NetworksOrgLoc } from '@zen/Networks';
import { AssignmentTargetTypeEnum, AssignmentTypeValue } from '@zen/Networks';
import NetworksContactInput from '@zen/Networks/NetworksContactPicker/NetworksContactInput';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';
import { defaultErrorMessage } from '@zen/utils/validation';

import type { JourneyStopActionEnum } from '../../../types';
import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import { journeyRefetchQueryList } from '../helpers';
import { useUpdateStopLocationMutation } from './graphql';

interface Props {
  locationId: string;
  locationName: string;
  modeOfTransport: Optional<ModeOfTransport>;
  stopAction: JourneyStopActionEnum;
  stopId: string;
}

const StopLocationInlineField: FC<Props> = ({ locationId, locationName, modeOfTransport, stopAction, stopId }) => {
  const { cargoId, zencargoReference } = useJourneyContext();
  const { accountId } = useCargoOverviewContext();
  const { addError, addSuccess } = useNotification();
  const [updateStopLocation, { loading }] = useUpdateStopLocationMutation();
  const isWarehouseLocation: boolean = isCollectionStop(stopAction) || isDeliveryStop(stopAction);
  const isCreateAccountFeatureEnabled: boolean = useFeatureIsOn('create-account');
  const placeholder: string = 'Select location';

  const handleWarehouseChange = (value: Nullable<NetworksOrgLoc>): void => {
    if (!value?.id || value?.id === locationId) return;

    updateLocation(value.id);
  };

  const handleTerminalChange = (terminal: Nullable<Terminal>): void => {
    if (!terminal?.unlocode || terminal?.unlocode === locationId) return;

    updateLocation(terminal.unlocode);
  };

  const updateLocation = async (locationValue: string): Promise<void> => {
    const input: UpdateStopLocationInput = {
      journeyStopId: stopId,
      locationValue,
      zencargoReference
    };

    await performMutation({
      mutationFn: () =>
        updateStopLocation({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: { input }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', defaultErrorMessage));
      },
      onSuccess: () => {
        addSuccess('Location has been updated.');
      }
    });
  };

  const renderWarehouseSelect = (): ReactNode => {
    const assignmentType: AssignmentTypeValue = isCollectionStop(stopAction)
      ? AssignmentTypeValue.COLLECTION_LOCATION
      : AssignmentTypeValue.DELIVERY_LOCATION;
    const value: Optional<NetworksOrgLoc> = locationId ? { id: locationId, label: { long: locationName } } : null;

    if (isCreateAccountFeatureEnabled) {
      return (
        <AccountContactsSelect
          accountUuid={accountId}
          assignmentType={assignmentType}
          isClearable={false}
          isDisabled={loading}
          isLoading={loading}
          name="location"
          onChange={handleWarehouseChange}
          placeholder={placeholder}
          value={value}
          variant="inline"
        />
      );
    }

    return (
      <NetworksContactInput
        accountUuid={accountId}
        assignmentType={assignmentType}
        cargoId={cargoId}
        domainName={AssignmentTargetTypeEnum.BOOKING_CARGO}
        isClearable={false}
        isDisabled={loading}
        isLoading={loading}
        name="location"
        onChange={handleWarehouseChange}
        placeholder={placeholder}
        value={value}
        variant="inline"
      />
    );
  };

  const renderTerminalSelect = (): ReactNode => {
    const value: Nullable<Terminal> = locationId ? { unlocode: locationId, label: locationName } : null;

    return (
      <div data-testid="terminal-select">
        <TerminalSelect
          isDisabled={loading}
          isLoading={loading}
          name="location"
          onChange={handleTerminalChange}
          placeholder={placeholder}
          value={value}
          variant="inline"
        />
      </div>
    );
  };

  return (
    <div className="flex flex-1 w-full -mt-3 space-x-4 shrink-0 group">
      <div className="flex-1 min-w-0 -mx-3">{isWarehouseLocation ? renderWarehouseSelect() : renderTerminalSelect()}</div>
      {locationName && <Copyable textToCopy={locationName || ''} />}
    </div>
  );
};

export default StopLocationInlineField;
