import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ForwardersQueryVariables = GraphQLTypes.Exact<{
  networkId: GraphQLTypes.Scalars['String'];
  searchContains: GraphQLTypes.LocationSearchInput;
}>;

export type ForwardersQueryResult = { __typename?: 'Query' } & {
  forwarders?: GraphQLTypes.Maybe<
    { __typename?: 'LocationAssignableConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            | { __typename?: 'Account' }
            | { __typename?: 'AccountLocation' }
            | { __typename?: 'NetworksLegacyOrgLoc' }
            | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                  organisation?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id' | 'name'>
                  >;
                })
            | { __typename?: 'NetworksTerminal' }
          >
        >
      >;
    }
  >;
};

export const ForwardersQueryDocument = /* #__PURE__ */ gql`
  query forwardersQuery($networkId: String!, $searchContains: LocationSearchInput!) {
    forwarders(networkId: $networkId, searchContains: $searchContains) {
      nodes {
        ... on NetworksOrgLoc {
          id
          label {
            long
            short
          }
          organisation {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useForwardersQuery__
 *
 * To run a query within a React component, call `useForwardersQuery` and pass it any options that fit your needs.
 * When your component renders, `useForwardersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForwardersQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *      searchContains: // value for 'searchContains'
 *   },
 * });
 */
export function useForwardersQuery(baseOptions: Apollo.QueryHookOptions<ForwardersQueryResult, ForwardersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ForwardersQueryResult, ForwardersQueryVariables>(ForwardersQueryDocument, options);
}
export function useForwardersQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ForwardersQueryResult, ForwardersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ForwardersQueryResult, ForwardersQueryVariables>(ForwardersQueryDocument, options);
}
export type ForwardersQueryHookResult = ReturnType<typeof useForwardersQuery>;
export type ForwardersQueryLazyQueryHookResult = ReturnType<typeof useForwardersQueryLazyQuery>;
