import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OceanCarriersQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OceanCarriersQueryResult = { __typename?: 'Query' } & {
  oceanCarriers?: GraphQLTypes.Maybe<
    { __typename?: 'OceanCarrierConnection' } & Pick<GraphQLTypes.OceanCarrierConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'OceanCarrier' } & Pick<
              GraphQLTypes.OceanCarrier,
              'carrierScac' | 'id' | 'isTrackable' | 'oceanCarrierName' | 'synonyms'
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const OceanCarriersDocument = /* #__PURE__ */ gql`
  query oceanCarriers($after: String, $before: String, $first: Int, $last: Int, $query: String) {
    oceanCarriers(after: $after, before: $before, first: $first, last: $last, query: $query) {
      nodes {
        carrierScac
        id
        isTrackable
        oceanCarrierName
        synonyms
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOceanCarriersQuery__
 *
 * To run a query within a React component, call `useOceanCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOceanCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanCarriersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useOceanCarriersQuery(
  baseOptions?: Apollo.QueryHookOptions<OceanCarriersQueryResult, OceanCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OceanCarriersQueryResult, OceanCarriersQueryVariables>(OceanCarriersDocument, options);
}
export function useOceanCarriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OceanCarriersQueryResult, OceanCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OceanCarriersQueryResult, OceanCarriersQueryVariables>(OceanCarriersDocument, options);
}
export type OceanCarriersQueryHookResult = ReturnType<typeof useOceanCarriersQuery>;
export type OceanCarriersLazyQueryHookResult = ReturnType<typeof useOceanCarriersLazyQuery>;
