import DocumentActionsContainer from '@zen/Components/Documents/DocumentActionsContainer';
import type { TabViewConfig } from '@zen/Components/TabViewController/types';
import { getActiveIssues } from '@zen/Issues/issueHelpers';
import QuoteOptionsContainer from '@zen/Quotes/QuoteOptionsContainer';
import { RateCardSection } from '@zen/RateCards';
import TradeParties from '@zen/TradeParties';
import type { Optional } from '@zen/utils/typescript';

import { BookingDetailsPageSection, BookingStageEnum } from '../types';
import BookingSummaryContainer from './BookingSummaryContainer';
import AccrualsLink from './components/AccrualsLink/AccrualsLink';
import DocumentsContainer from './components/DocumentsContainer';
import IssuesContainer from './components/IssuesContainer';
import OrderList from './components/OrderList';
import type { BookingIssue, BookingOverviewData } from './types';

interface Params {
  booking: BookingOverviewData;
  canAccessAccruals: boolean;
  canAccessDocumentCreation: boolean;
  canAccessRateCards: boolean;
  canManageAssignedLots: boolean;
  canViewForwarder: boolean;
  canViewIssues: boolean;
  issues: Optional<BookingIssue[]>;
  sectionName?: BookingDetailsPageSection;
  showNewQuoteButton: boolean;
}

export const getTabsConfig = (params: Params): TabViewConfig[] => {
  const {
    booking,
    canAccessAccruals,
    canAccessDocumentCreation,
    canManageAssignedLots,
    canViewForwarder,
    canViewIssues,
    sectionName,
    showNewQuoteButton,
    canAccessRateCards,
    issues
  } = params;
  const { accountUuid, bookingStage, quoteRequired, zencargoReference } = booking;

  const shouldRenderQuotesTab: boolean = !!quoteRequired && bookingStage?.name !== BookingStageEnum.BOOKING_REQUESTED;
  const activeIssuesCount: number = getActiveIssues(issues || []).length;
  const shouldRenderRateCardTab: boolean = canAccessRateCards && !quoteRequired;

  const quotesTab: TabViewConfig = {
    label: 'Quotes',
    component: (
      <div className="p-6">
        <QuoteOptionsContainer
          accountUuid={accountUuid}
          showAddNewQuote={showNewQuoteButton}
          zencargoReference={zencargoReference}
        />
      </div>
    )
  };

  const rateCardTab: TabViewConfig = {
    label: 'Rate card',
    component: (
      <div className="p-6">
        <RateCardSection editable={false} zencargoReference={zencargoReference} />
      </div>
    )
  };

  const issuesTab: TabViewConfig = {
    label: 'Issues',
    isDefault: sectionName === BookingDetailsPageSection.ISSUES,
    component: (
      <div className="p-6">
        <IssuesContainer isAddButtonVisible={true} zencargoReference={zencargoReference} />
      </div>
    ),
    totalCount: activeIssuesCount
  };

  const accrualsTab: TabViewConfig = {
    label: <AccrualsLink accountId={accountUuid} zencargoReference={zencargoReference} />,
    component: <span />
  };

  return [
    {
      label: 'Booking overview',
      component: <BookingSummaryContainer canViewForwarder={canViewForwarder} zencargoReference={zencargoReference} />
    },
    {
      label: 'Orders',
      isDefault: sectionName === BookingDetailsPageSection.ORDERS,
      component: (
        <div className="p-6">
          <OrderList
            canManageAssignedLots={canManageAssignedLots}
            isUpdateLotsButtonVisible={true}
            zencargoReference={zencargoReference}
          />
        </div>
      )
    },
    {
      label: 'Documents',
      isDefault: sectionName === BookingDetailsPageSection.DOCUMENTS,
      component: (
        <div className="p-6">
          <div className="flex justify-end mb-4">
            <DocumentActionsContainer
              accountId={accountUuid}
              componentType="button"
              showDocumentCreation={canAccessDocumentCreation}
              zencargoReference={zencargoReference}
            />
          </div>
          <DocumentsContainer zencargoReference={zencargoReference} />
        </div>
      )
    },
    {
      label: 'Parties',
      isDefault: sectionName === BookingDetailsPageSection.TRADE_PARTIES,
      component: (
        <div className="p-6">
          <TradeParties accountUuid={accountUuid} canViewForwarder={false} zencargoReference={zencargoReference} />
        </div>
      )
    },
    ...(shouldRenderQuotesTab ? [quotesTab] : []),
    ...(shouldRenderRateCardTab ? [rateCardTab] : []),
    ...(canViewIssues ? [issuesTab] : []),
    ...(canAccessAccruals ? [accrualsTab] : [])
  ];
};
