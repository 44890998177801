import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';
import { orderRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  zencargoReference: string;
}

const OrderListButton: FC<Props> = ({ zencargoReference }) => {
  const { navigate } = useNavigationHistory();

  return (
    <Button
      iconLeft="zicon-edit"
      onClick={() => navigate(orderRoutes.lotAssignmentsToBooking.getUrl(zencargoReference))}
      variant="secondary"
    >
      Update lots
    </Button>
  );
};

export default OrderListButton;
