import { capitalize, isEqual } from 'lodash';
import { FC, ReactNode, useState } from 'react';

import UpdateBusinessHoursModal from '@zen/Accounts/AccountDetails/AccountLocation/UpdateBusinessHoursModal';
import { getDefaultWorkDays } from '@zen/Accounts/AccountForms/helpers';
import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Banner } from '@zen/DesignSystem';

import type { FormattedHours } from '../types';
import type { UpdateBusinessHoursModalProps } from '../UpdateBusinessHoursModal/UpdateBusinessHoursModal';

interface Props {
  businessHours: FormattedHours;
  canEdit: boolean;
  locationId: string;
}

const BusinessHoursDetails: FC<Props> = ({ businessHours, canEdit, locationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  const renderBusinessHours = (): ReactNode => {
    return Object.entries(businessHours)
      .filter(([key]) => key !== '__typename')
      .map(([day, times]) => {
        return (
          <tr key={day} className="flex">
            <td className="flex-1 py-1">{capitalize(day)}</td>
            <td className="flex-1 py-1 text-right">{times ? `${times.startTime} - ${times.endTime}` : 'Closed'}</td>
          </tr>
        );
      });
  };

  const hasStandardOpeningHours = (): boolean => {
    return isEqual(businessHours, getDefaultWorkDays());
  };

  const inititialValues: UpdateBusinessHoursModalProps['initialValues'] = {
    businessHours,
    businessHoursOption: hasStandardOpeningHours() ? 'standard' : 'custom',
    locationId
  };

  const modal: ReactNode = (
    <UpdateBusinessHoursModal initialValues={inititialValues} isOpen={isModalOpen} onClose={toggleModal} />
  );

  return (
    <>
      {modal}
      <EditableContentContainer heading="Business hours" onEdit={canEdit ? toggleModal : undefined}>
        <div className="col-span-8 space-y-4">
          <table className="grid grid-cols-4 col-span-4">
            <tbody className="col-span-2">{renderBusinessHours()}</tbody>
          </table>
          {!canEdit && (
            <Banner message="This location's business hours cannot be edited due to its association with various companies. If you need assistance, please contact your coordinator." />
          )}
        </div>
      </EditableContentContainer>
    </>
  );
};

export default BusinessHoursDetails;
export type { Props as BusinessHoursDetailsProps };
