import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { BookingManager } from './types';

export const createCoordinatorOption = (coordinator: Optional<BookingManager>): Optional<Option<string>> => {
  if (!coordinator) {
    return undefined;
  }

  return {
    value: coordinator?.id || '',
    label: `${coordinator?.firstName} ${coordinator?.lastName}`
  };
};

export const getCoordinatorsOptions = (bookingCoordinators: BookingManager[]): Option<string>[] => {
  return bookingCoordinators
    .map(
      (coordinator: BookingManager): Option<string> => ({
        value: coordinator.id,
        label: `${coordinator.firstName} ${coordinator.lastName}`
      })
    )
    .sort((a, b) => a.label.localeCompare(b.label));
};
