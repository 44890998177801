import { createContext } from 'react';

import type { VisitedAccounts } from './types';

const initialContext: VisitedAccounts = {
  visitedAccounts: [],
  updateVisitedAccounts: () => {},
  lastVisitedAccounts: [],
  mostVisitedAccounts: []
};

const VisitedAccountsContext = createContext<VisitedAccounts>(initialContext);

export default VisitedAccountsContext;
