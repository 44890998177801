import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { dashboardRoutes } from '@zen/Routes';

import { SIDEBAR_WIDTH_CLASS, TOP_NAVIGATION_HEADER_ZINDEX } from '../constants';
import { ReactComponent as Logo } from './logo.svg';

interface Props {
  isLogoLinked?: boolean;
}

const Header: FC<Props> = ({ children, isLogoLinked = true }) => {
  const className: string = cx(
    'sticky top-0 h-16 shrink-0 bg-white border-b border-solid border-grey-lighter flex items-center',
    TOP_NAVIGATION_HEADER_ZINDEX
  );
  const logo: ReactNode = <Logo className="w-32" data-testid="logo" />;

  const renderLogo = (): ReactNode => {
    if (isLogoLinked) {
      return <Link to={dashboardRoutes.dashboard.getUrl()}>{logo}</Link>;
    }

    return logo;
  };

  return (
    <div className={className} data-testid="header">
      <div className={`${SIDEBAR_WIDTH_CLASS} pl-6`}>{renderLogo()}</div>
      {children}
    </div>
  );
};

export default Header;
