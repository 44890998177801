import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';

import { incotermOptions } from '@zen/Booking/helpers';
import { Button, InlineDialog, MultiSelect, Select, SelectProps, TextLink } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { IncotermsValue } from './types';

interface Props<T extends boolean> extends Omit<SelectProps<IncotermsValue>, 'options' | 'isClearable' | 'onChange' | 'value'> {
  isMulti?: T;
  onChange: T extends true ? (values: IncotermsValue[]) => void : (value: Nullable<IncotermsValue>) => void;
  showIncotermsExplanation?: boolean;
  value: T extends true ? IncotermsValue[] : Optional<IncotermsValue>;
  variant?: 'default' | 'inline';
}

const IncotermSelect = <T extends boolean>(props: Props<T>) => {
  const {
    className,
    label,
    name = 'incoterm',
    showIncotermsExplanation = false,
    isMulti = false,
    variant = 'default',
    ...rest
  } = props;
  const containerClasses = cx({ 'flex items-end space-x-4 flex-1': showIncotermsExplanation });
  const incotermLabel: ReactNode = variant === 'inline' ? label : '';

  const renderIncotermSelect = (): ReactElement => {
    const commonProps: Pick<SelectProps<IncotermsValue>, 'name' | 'options'> = {
      name,
      options: incotermOptions
    };

    if (isMulti) {
      return (
        <div className={className} data-testid="multi-select-incoterm">
          <MultiSelect renderMenuInPortal={true} {...(rest as Props<true>)} {...commonProps} />
        </div>
      );
    }

    return (
      <div className={className} data-testid="select-incoterm">
        <Select renderMenuInPortal={true} {...(rest as Props<false>)} {...commonProps} label={incotermLabel} variant={variant} />
      </div>
    );
  };

  const moreInformationLink: string = 'https://assets.zencargo.com/assets/incoterms-explained.pdf';

  const dialogBody: ReactNode = (
    <div>
      <p className="mb-4">
        This is the international commercial term that has been agreed between seller and buyer for the transaction
      </p>
      <TextLink isExternal={true} linkTo={moreInformationLink}>
        More information
      </TextLink>
    </div>
  );

  const incotermsExplanation: ReactNode = (
    <InlineDialog body={dialogBody} title="Incoterms explained">
      <Button iconLeft="zicon-information" variant="ghost">
        Incoterms explained
      </Button>
    </InlineDialog>
  );

  return (
    <div className={containerClasses}>
      {renderIncotermSelect()}
      {showIncotermsExplanation && incotermsExplanation}
    </div>
  );
};

export type { Props as IncotermSelectProps };

export default IncotermSelect;
