import type { IconName } from '@zen/Styleguide';

export type {
  GlobalSearchMetadataBookingType,
  GlobalSearchMetadataPackingListType,
  Search as GlobalSearchResult
} from '@zen/graphql/types.generated';
export { GlobalSearchTypeEnum } from '@zen/graphql/types.generated';

export interface GlobalSearchAutosuggestItem {
  accountName?: string;
  entityLabel: string;
  icon: IconName;
  name: string;
  url: string;
}

export const GlobalSearchTrackingCategory = 'Search';

export enum GlobalSearchTrackingAction {
  GLOBAL_SEARCH_INPUT_CHANGED = 'GlobalSearchInputChanged',
  GLOBAL_SEARCH_RESULT_SELECTED = 'GlobalSearch'
}
