import { getLegacyCargoType } from '../../../cargoDictionaryMapping.helper';
import { getDimensions, getWeight } from '../../../helpers';
import { CargoFullContainerVariant, ContainerTypeEnum, type CoreCargo } from '../../../types';
import { getLooseCargoDimensions, initialDimensions, initialWeightValues, mapLooseCargoType } from '../../LooseCargo/helpers';
import type { FullContainerFormInitialValues, FullContainerFormValues } from '../FullContainerForm/types';
import { prepareFullContainerFormValues } from '../helpers';

export const prepareFullContainerFormInitialValues = (cargo: CoreCargo): FullContainerFormInitialValues => {
  const {
    cargoType,
    containerNumber,
    containerSealNumber,
    garmentsOnHangers,
    grossWeight,
    hazardous,
    dimensions,
    looseCargo,
    overweight,
    quantity,
    volume
  } = cargo;
  const containerType = getLegacyCargoType(cargoType);
  const cargoAndPalletType = mapLooseCargoType(looseCargo?.cargoType);

  return {
    loose: {
      ...cargoAndPalletType,
      volume: volume?.value || null,
      grossWeight: getWeight(grossWeight) || initialWeightValues,
      dimensions: getLooseCargoDimensions(dimensions, cargoAndPalletType.value) || initialDimensions,
      quantity: looseCargo?.quantity || 1
    },
    containerNumber,
    quantity: quantity || 1,
    type: containerType as ContainerTypeEnum,
    containerSealNumber,
    containerVariant: getCargoVariant(cargo),
    hazardous: !!hazardous,
    overweight: !!overweight,
    garmentsOnHangers: !!garmentsOnHangers
  };
};

const getCargoVariant = (coreCargo: CoreCargo): CargoFullContainerVariant => {
  const { dryStandard, flatRack, openTop, refrigerated } = coreCargo;

  const cargoVariantMap: Record<CargoFullContainerVariant, boolean> = {
    [CargoFullContainerVariant.DRY]: !!dryStandard,
    [CargoFullContainerVariant.FLATRACK]: !!flatRack,
    [CargoFullContainerVariant.OPEN_TOP]: !!openTop,
    [CargoFullContainerVariant.REFRIGERATED]: !!refrigerated
  };
  const cargoVariant = Object.keys(cargoVariantMap).find((variant) => cargoVariantMap[variant as CargoFullContainerVariant]);

  return cargoVariant as CargoFullContainerVariant;
};

export const prepareEditFullContainerFormValues = (values: FullContainerFormValues) => {
  const fullContainerValues = prepareFullContainerFormValues(values);

  return {
    ...fullContainerValues,
    actualCbm: values.loose?.volume,
    palletType: values.loose?.value || null,
    looseCargoType: values.loose?.type,
    quantity: values.loose?.quantity,
    dimensions: getDimensions(values.loose?.dimensions) || null,
    grossWeight: getWeight(values.loose?.grossWeight)
  };
};
