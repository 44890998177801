import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import type { LocationDescriptor } from '@zen/types';
import { extractToAndState } from '@zen/utils/routing';

import Icon from '../Icon';

interface Props {
  className?: string;
  isExternal?: boolean;
  isStandalone?: boolean;
  linkTo?: LocationDescriptor;
  onClick?: (event: SyntheticEvent) => void;
}

const TextLink: FC<Props> = ({ children, isExternal = false, linkTo, onClick, isStandalone = true, className }) => {
  const textLinkWrapperClassName: string = `text-sm group/text-wrap text-navy-base cursor-pointer hover:text-azure-base ${className}`;

  const textLinkClassName: string = cx('border-transparent border-solid border-b', {
    'group-hover/text-wrap:border-azure-base': isStandalone,
    'border-navy-base group-hover/text-wrap:border-transparent': !isStandalone
  });

  const TextLinkContent = (
    <span className={textLinkClassName} data-testid="text-link-content">
      {children}
    </span>
  );

  if (isExternal) {
    return (
      <a
        className={textLinkWrapperClassName}
        data-component="text-link"
        data-testid="text-link"
        href={linkTo as string}
        onClick={onClick}
        target="_blank"
      >
        {TextLinkContent}
        <Icon className="ml-1 text-sm" icon="zicon-diagonal-arrow" />
      </a>
    );
  }

  const handleClick = (event: SyntheticEvent): void => {
    onClick?.(event);
  };

  if (linkTo) {
    return (
      <Link
        className={textLinkWrapperClassName}
        data-component="text-link"
        data-testid="text-link"
        onClick={handleClick}
        {...extractToAndState(linkTo)}
      >
        {TextLinkContent}
      </Link>
    );
  }

  return (
    <span className={textLinkWrapperClassName} data-component="text-link" data-testid="text-link" onClick={handleClick}>
      {TextLinkContent}
    </span>
  );
};

export type { Props as TextLinkProps };

export default TextLink;
