import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargeTypesListQueryVariables = GraphQLTypes.Exact<{
  applicability?: GraphQLTypes.InputMaybe<GraphQLTypes.Applicability>;
  chargeGroupIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
}>;

export type GetChargeTypesListQueryResult = { __typename?: 'Query' } & {
  getAllCharges?: GraphQLTypes.Maybe<
    Array<
      { __typename?: 'ChargesPayload' } & Pick<GraphQLTypes.ChargesPayload, 'applicability' | 'basisGroup' | 'id' | 'name'> & {
          group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'>;
        }
    >
  >;
};

export const GetChargeTypesListDocument = /* #__PURE__ */ gql`
  query getChargeTypesList($applicability: Applicability, $chargeGroupIds: [String]) {
    getAllCharges(applicability: $applicability, chargeGroupIds: $chargeGroupIds) {
      applicability
      basisGroup
      group {
        id
        name
      }
      id
      name
    }
  }
`;

/**
 * __useGetChargeTypesListQuery__
 *
 * To run a query within a React component, call `useGetChargeTypesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeTypesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeTypesListQuery({
 *   variables: {
 *      applicability: // value for 'applicability'
 *      chargeGroupIds: // value for 'chargeGroupIds'
 *   },
 * });
 */
export function useGetChargeTypesListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargeTypesListQueryResult, GetChargeTypesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargeTypesListQueryResult, GetChargeTypesListQueryVariables>(GetChargeTypesListDocument, options);
}
export function useGetChargeTypesListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargeTypesListQueryResult, GetChargeTypesListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargeTypesListQueryResult, GetChargeTypesListQueryVariables>(
    GetChargeTypesListDocument,
    options
  );
}
export type GetChargeTypesListQueryHookResult = ReturnType<typeof useGetChargeTypesListQuery>;
export type GetChargeTypesListLazyQueryHookResult = ReturnType<typeof useGetChargeTypesListLazyQuery>;
