import type { Optional, Undefinable } from '@zen/utils/typescript';

import {
  CargoItemTypeEnum,
  CargoType,
  cargoValues,
  ContainerTypeEnum,
  CoreCargoType,
  LooseCargoTypeEnum,
  PalletTypeEnum,
  VehicleTypeEnum
} from './types';

export const legacyNewTypesMapping: Record<CargoType, CoreCargoType> = {
  [ContainerTypeEnum.X20DV]: cargoValues.containerX20Dv,
  [ContainerTypeEnum.X20HC]: cargoValues.containerX20Hc,
  [ContainerTypeEnum.X40DV]: cargoValues.containerX40Dv,
  [ContainerTypeEnum.X40HC]: cargoValues.containerX40Hc,
  [ContainerTypeEnum.X45HC]: cargoValues.containerX45Hc,
  [VehicleTypeEnum.STRAIGHT_TRUCK]: cargoValues.straightTruck,
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: cargoValues.trailer136MBox,
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: cargoValues.trailer136MCurtainSided,
  [VehicleTypeEnum.TRAILER_13_6_M_HIAB]: cargoValues.trailer136MHiab,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: cargoValues.trailer136MMegatrailer,
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED]: cargoValues.trailer136MMegatrailerCurtainSided,
  [VehicleTypeEnum.TRAILER_13_6_M_REFRIGERATED]: cargoValues.trailer136MRefrigerated,
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: cargoValues.trailer136MSlidingRoof,
  [VehicleTypeEnum.TRAILER_18_T_BOX]: cargoValues.trailer18TBox,
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: cargoValues.trailer18TCurtainSided,
  [VehicleTypeEnum.TRAILER_18_T_HIAB]: cargoValues.trailer18THiab,
  [VehicleTypeEnum.TRAILER_18_T_REFRIGERATED]: cargoValues.trailer18TRefrigerated,
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: cargoValues.trailer18TSlidingRoof,
  [VehicleTypeEnum.TRAILER_3_5_T_BOX]: cargoValues['35TBox'],
  [VehicleTypeEnum.TRAILER_3_5_T_CURTAIN_SIDED]: cargoValues['35TCurtainSided'],
  [VehicleTypeEnum.TRAILER_53_FT_BOXED]: cargoValues['53FtTrailer'],
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: cargoValues.trailer75TBox,
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: cargoValues.trailer75TCurtainSided,
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: cargoValues.trailer75TSlidingRoof,
  [VehicleTypeEnum.VAN_BOX]: cargoValues.trailerVanBox,
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: cargoValues.trailerVanCurtainSided,
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: cargoValues.trailerVanSlidingRoof,
  [PalletTypeEnum.EURO]: cargoValues.loosePalletEuro,
  [PalletTypeEnum.OTHER]: cargoValues.loosePalletOther,
  [PalletTypeEnum.UK]: cargoValues.loosePalletUk,
  [PalletTypeEnum.US]: cargoValues.loosePalletUs,
  [LooseCargoTypeEnum.ASSORTED_CARGO]: cargoValues.looseAssortedCargo,
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: cargoValues.looseBoxesOrCrates,
  [LooseCargoTypeEnum.PALLETS]: cargoValues.loosePalletLegacy
};

const containerTypes: CoreCargoType[] = [
  cargoValues.containerX20Dv,
  cargoValues.containerX20Hc,
  cargoValues.containerX40Dv,
  cargoValues.containerX40Hc,
  cargoValues.containerX45Hc
];
const looseTypes: CoreCargoType[] = [
  cargoValues.looseAssortedCargo,
  cargoValues.looseBoxesOrCrates,
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletLegacy,
  cargoValues.loosePalletOther,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs
];

export const templatedPalletTypes: CoreCargoType[] = [
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs
];

export const palletTypes: CoreCargoType[] = [
  cargoValues.loosePalletEuro,
  cargoValues.loosePalletLegacy,
  cargoValues.loosePalletOther,
  cargoValues.loosePalletUk,
  cargoValues.loosePalletUs
];

const vehicleTypes: CoreCargoType[] = [
  cargoValues.straightTruck,
  cargoValues.trailer136MBox,
  cargoValues.trailer136MCurtainSided,
  cargoValues.trailer136MHiab,
  cargoValues.trailer136MMegatrailer,
  cargoValues.trailer136MMegatrailerCurtainSided,
  cargoValues.trailer136MSlidingRoof,
  cargoValues.trailer136MRefrigerated,
  cargoValues.trailer18TBox,
  cargoValues.trailer18TCurtainSided,
  cargoValues.trailer18THiab,
  cargoValues.trailer18TSlidingRoof,
  cargoValues.trailer18TRefrigerated,
  cargoValues['35TBox'],
  cargoValues['35TCurtainSided'],
  cargoValues['53FtTrailer'],
  cargoValues.trailer75TBox,
  cargoValues.trailer75TCurtainSided,
  cargoValues.trailer75TSlidingRoof,
  cargoValues.trailerVanBox,
  cargoValues.trailerVanCurtainSided,
  cargoValues.trailerVanSlidingRoof
];

type LegacyCargoMappingItem = Record<CargoItemTypeEnum, CoreCargoType[]>;

export const legacyCargoItemTypeMapping: LegacyCargoMappingItem = {
  [CargoItemTypeEnum.CONTAINER]: containerTypes,
  [CargoItemTypeEnum.LOOSE_CARGO]: looseTypes,
  [CargoItemTypeEnum.VEHICLE]: vehicleTypes
};

export const getLegacyCargoItemType = (cargoType: Optional<CoreCargoType>): Optional<CargoItemTypeEnum> => {
  if (!cargoType) {
    return null;
  }

  return Object.keys(legacyCargoItemTypeMapping).find((key) =>
    legacyCargoItemTypeMapping[key as keyof typeof CargoItemTypeEnum].includes(cargoType)
  ) as CargoItemTypeEnum;
};

export const getLegacyCargoType = (cargoType: Optional<CoreCargoType>): Undefinable<CargoType> => {
  return Object.keys(legacyNewTypesMapping).find(
    (keyItem) => legacyNewTypesMapping[keyItem as keyof typeof legacyNewTypesMapping] === cargoType
  ) as CargoType;
};
