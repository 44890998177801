import type { FC } from 'react';

import { ActivityFeedTargetTypeEnum } from '@zen/ActivityFeed/types';

import { ReactComponent as Bubbles } from './bubbles.svg';

interface Props {
  targetType: ActivityFeedTargetTypeEnum;
}

const EmptyState: FC<Props> = ({ targetType }) => {
  const label: string = targetType === ActivityFeedTargetTypeEnum.BOOKING ? 'booking' : 'order';

  return (
    <div className="flex items-center justify-between py-6" data-testid="wrapper-with-icon">
      <div className="w-2/3 py-6 pl-6 ml-5 leading-normal shrink-0">
        We automatically create an activity feed for every {label} you create so you can keep a log of{' '}
        <b>all the decisions you make</b>, <b>actions that are taken </b>
        and <b>milestones that are hit</b> in one continuous thread.
      </div>
      <Bubbles className="w-full h-auto max-w-full max-h-52" />
    </div>
  );
};

export default EmptyState;
