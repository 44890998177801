import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  color?: string;
  iconName?: IconName;
}

const ActivityFeedItemIcon: FC<Props> = ({ color = 'text-green-dark', iconName = 'zicon-tickoval' }) => {
  return (
    <div className="p-0.5 bg-white">
      <Icon className={`text-xl ${color}`} icon={iconName} />
    </div>
  );
};

export default ActivityFeedItemIcon;
