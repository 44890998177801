import cx from 'classnames';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';

interface Props {
  isCompleted: boolean;
}

const LocationIcon: FC<Props> = ({ isCompleted }) => {
  const iconClassName: string = cx(
    {
      'text-azure-base': isCompleted,
      'text-grey-light': !isCompleted
    },
    'bg-white mt-4 py-0.5 z-20 flex justify-center shrink-0 text-xs',
    JOURNEY_TIMELINE_WIDTH
  );

  return (
    <Icon className={iconClassName} data-routedatapoint={true} data-routedatapointcompleted={isCompleted} icon="zicon-pin" />
  );
};

export default LocationIcon;
