import type { FC } from 'react';

import Input, { InputProps } from '../Input';

type Props = Omit<InputProps, 'type'>;

const TextInput: FC<Props> = (props) => {
  return <Input data-component="text-input" type="text" {...props} />;
};

export type { Props as TextInputProps };

export default TextInput;
