import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderIssuesQueryVariables = GraphQLTypes.Exact<{
  orderId: GraphQLTypes.Scalars['String'];
}>;

export type OrderIssuesQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderReferenceNumber'> & {
              canModifyIssueVisibility: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canOpenIssue: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              issues?: GraphQLTypes.Maybe<
                Array<
                  { __typename: 'PurchaseOrderIssue' } & Pick<
                    GraphQLTypes.PurchaseOrderIssue,
                    | 'associatedId'
                    | 'associatedType'
                    | 'category'
                    | 'id'
                    | 'problemDescription'
                    | 'severity'
                    | 'solutionDescription'
                    | 'state'
                    | 'title'
                  > & {
                      canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canModifyVisibility: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      canResolve: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canStart: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdateCategory: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                      canUpdateProblemDescription: { __typename?: 'AuthorizationResult' } & Pick<
                        GraphQLTypes.AuthorizationResult,
                        'value'
                      >;
                      occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
                      openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
                      openedBy: { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>;
                      resolvedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                      >;
                      resolvedBy?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                      >;
                      startedAt?: GraphQLTypes.Maybe<
                        { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                      >;
                      startedBy?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
                      >;
                      visibility: { __typename?: 'IssueVisibility' } & Pick<
                        GraphQLTypes.IssueVisibility,
                        'agentForwarder' | 'customerUser' | 'manufacturer'
                      >;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const OrderIssuesDocument = /* #__PURE__ */ gql`
  query orderIssues($orderId: String!) {
    purchaseOrders(id: $orderId) {
      nodes {
        canModifyIssueVisibility {
          value
        }
        canOpenIssue {
          value
        }
        id
        issues {
          __typename
          associatedId
          associatedType
          category
          canArchive {
            value
          }
          canModifyVisibility {
            value
          }
          canResolve {
            value
          }
          canStart {
            value
          }
          canUpdate {
            value
          }
          canUpdateCategory {
            value
          }
          canUpdateProblemDescription {
            value
          }
          id
          occurredOn {
            date
          }
          openedAt {
            date
            dateTime
          }
          openedBy {
            firstName
            lastName
          }
          problemDescription
          resolvedAt {
            date
            dateTime
          }
          resolvedBy {
            firstName
            lastName
          }
          severity
          solutionDescription
          startedAt {
            date
            dateTime
          }
          startedBy {
            firstName
            lastName
          }
          state
          title
          visibility {
            agentForwarder
            customerUser
            manufacturer
          }
        }
        orderReferenceNumber
      }
    }
  }
`;

/**
 * __useOrderIssuesQuery__
 *
 * To run a query within a React component, call `useOrderIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderIssuesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderIssuesQuery(baseOptions: Apollo.QueryHookOptions<OrderIssuesQueryResult, OrderIssuesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderIssuesQueryResult, OrderIssuesQueryVariables>(OrderIssuesDocument, options);
}
export function useOrderIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderIssuesQueryResult, OrderIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderIssuesQueryResult, OrderIssuesQueryVariables>(OrderIssuesDocument, options);
}
export type OrderIssuesQueryHookResult = ReturnType<typeof useOrderIssuesQuery>;
export type OrderIssuesLazyQueryHookResult = ReturnType<typeof useOrderIssuesLazyQuery>;
