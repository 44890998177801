import { type FC, ReactElement, useMemo } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Table } from '@zen/DesignSystem';
import { ConnectionSourceTargetEnum } from '@zen/graphql/types.generated';
import { usePagination } from '@zen/utils/hooks/pagination';

import type { GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables } from '../graphql';
import { useGetAccountConnectionsQuery } from '../graphql';
import type { AccountConnectionsSummary } from '../types';
import getColumnsConfiguration from './accountConnectionsTableConfiguration';
import AddConnectionButton from './AddConnectionButton';

interface Props {
  accountId: string;
}

const AccountConnections: FC<Props> = ({ accountId }) => {
  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountConnectionsQueryResult,
    GetAccountConnectionsQueryVariables,
    AccountConnectionsSummary
  >(
    useGetAccountConnectionsQuery,
    'accountConnections',
    { accountId, accountConnectionsFiltersInput: { connectionSourceTarget: ConnectionSourceTargetEnum.TARGET } },
    20,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const columns = useMemo(() => {
    return getColumnsConfiguration(accountId);
  }, [accountId]);

  const tableId: string = 'accountConnections';

  const getLocationsTable = (locations: AccountConnectionsSummary[]): ReactElement => (
    <Table<AccountConnectionsSummary>
      actions={<AddConnectionButton accountId={accountId} />}
      columns={columns}
      data={locations}
      emptyText="No connections"
      paginationInfo={paginationInfo}
      tableId={tableId}
      title="Connections"
      totalCountConfig={{
        totalCount,
        entityName: 'connection'
      }}
    />
  );

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonTableLoading columns={columns} rowCount={20} tableId={tableId} />}
      noResults={getLocationsTable([])}
    >
      {getLocationsTable}
    </QueryHandler>
  );
};

export default AccountConnections;
