import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetContactQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type GetContactQueryResult = { __typename?: 'Query' } & {
  contact: { __typename?: 'NetworkContact' } & Pick<
    GraphQLTypes.NetworkContact,
    | 'bankAccountDetails'
    | 'bipCode'
    | 'customer'
    | 'carrier'
    | 'eoriNumber'
    | 'haulier'
    | 'id'
    | 'name'
    | 'scacCode'
    | 'status'
    | 'supplier'
    | 'taxNumber'
    | 'warehouse'
  > & {
      contactPeople?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'NetworkContactPerson' } & Pick<
            GraphQLTypes.NetworkContactPerson,
            'id' | 'email' | 'firstName' | 'lastName' | 'skypeUserName' | 'phoneNumber' | 'mainContact'
          >
        >
      >;
    };
};

export const GetContactDocument = /* #__PURE__ */ gql`
  query getContact($id: String!) {
    contact(id: $id) {
      bankAccountDetails
      bipCode
      customer
      carrier
      contactPeople {
        id
        email
        firstName
        lastName
        skypeUserName
        phoneNumber
        mainContact
      }
      eoriNumber
      haulier
      id
      name
      scacCode
      status
      supplier
      taxNumber
      warehouse
    }
  }
`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQueryResult, GetContactQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetContactQueryResult, GetContactQueryVariables>(GetContactDocument, options);
}
export function useGetContactLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactQueryResult, GetContactQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetContactQueryResult, GetContactQueryVariables>(GetContactDocument, options);
}
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
