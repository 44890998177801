import cx from 'classnames';
import type { FC, ReactNode } from 'react';

interface Props {
  fullRow?: boolean;
  name: string;
  value: ReactNode;
}

const AccountDetailsItem: FC<Props> = ({ name, value, fullRow = false }) => {
  const detailClassName: string = cx({
    'col-span-4': fullRow,
    'col-span-2': !fullRow
  });

  return (
    <div key={name} className={detailClassName}>
      <div className="text-xs text-grey-base">{name}</div>
      <div>{value || '-'}</div>
    </div>
  );
};

export default AccountDetailsItem;
