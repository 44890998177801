import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { calculateTransitTime } from './helpers';

interface Props {
  timeInMinutes: number;
}

const TransitTime: FC<Props> = ({ timeInMinutes }) => {
  const transitTimeInDays: number = calculateTransitTime(timeInMinutes);

  const renderTransitTime = (): ReactNode => {
    if (transitTimeInDays === 0) {
      return 'under one day';
    }

    return (
      <>
        {transitTimeInDays} {pluralize('day', Math.abs(transitTimeInDays))}
      </>
    );
  };

  return (
    <div className="text-grey-dark text-xs mt-0.5" data-testid="transit-time">
      {renderTransitTime()}
    </div>
  );
};

export default TransitTime;
