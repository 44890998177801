import { ReactNode, useState } from 'react';

import { Popover } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { ComponentType, FilterItemType } from '../../../types';
import FilterComponent from '../../FilterComponent';
import type { FilterComponentProps } from '../../types';
import ListItem from '../OptionList/ListItem/ListItem';
import type { ListItemType } from '../OptionList/ListItem/types';
import OptionList from '../OptionList/OptionList';
import type { OptionListSection } from '../OptionList/types';
import { prepareGroupFilterOptionList } from './helpers';
import { useFilterGroupValue } from './hooks';

type FilterGroupProps<T> = {
  configurations: Array<FilterItemType<T>>;
  formatValue: (value: T) => string;
};

type Props<T> = {
  onChange: (value: Nullable<T>) => void;
  value: Nullable<T>;
} & FilterComponentProps &
  FilterGroupProps<T>;

const FilterGroup = <T,>(props: Props<T>) => {
  const { active, deselectFilter, onChange, onDelete, configurations, placement, value, children, formatValue, disabled } = props;
  const validationKeys: (keyof T)[] = configurations.map((configuration: FilterItemType<T>) => configuration.key);
  const { state, setState, isStateValid } = useFilterGroupValue<T>({
    onChange,
    validationKeys,
    value
  });
  const [activeItemKey, setActiveItemKey] = useState<string>();

  const renderListItem = (item: ListItemType & ComponentType<T>) => {
    const handleChange = (values: unknown): void => {
      setState({ ...state, [item.key]: values });
    };

    const handleDelete = (): void => {
      setState({ ...state, [item.key]: null });
    };

    const handleDeselect = (): void => {
      setActiveItemKey(undefined);
    };

    return (
      <FilterComponent
        active={activeItemKey === item.key}
        componentType={item.componentType}
        deselectFilter={handleDeselect}
        onChange={handleChange}
        onDelete={handleDelete}
        placement="right"
        props={item.props as ComponentType<unknown>}
        value={state?.[item.key] || null}
      >
        {(formattedValue: Nullable<ReactNode>) => {
          const label: ReactNode = formattedValue || 'Select';

          return <ListItem item={{ ...item, label }} selection="menu" />;
        }}
      </FilterComponent>
    );
  };

  const popover = (): ReactNode => {
    const optionList: OptionListSection<ComponentType<T>>[] = prepareGroupFilterOptionList(configurations, setActiveItemKey);

    const handleClear = (): void => {
      onChange(null);
    };

    return (
      <div data-testid="filter-group-popover">
        <OptionList list={optionList} onClear={handleClear} renderItem={renderListItem} />
      </div>
    );
  };

  const buildLabel = (): ReactNode => {
    if (isStateValid) {
      return formatValue(state as T);
    }

    return null;
  };

  const handleVisibilityChange = (visible: boolean): void => {
    if (!visible && !isStateValid) {
      onDelete();
    }

    if (!visible) {
      deselectFilter();
    }
  };

  return (
    <Popover
      closeOnOutsideClick={!activeItemKey}
      disabled={disabled}
      isVisible={active}
      onVisibilityChange={handleVisibilityChange}
      placement={placement}
      popover={popover}
      popoverClassNames="border border-solid border-grey-lighter"
      triggerClassName="w-full"
    >
      {children(buildLabel())}
    </Popover>
  );
};

export default FilterGroup;
export type { FilterGroupProps };
