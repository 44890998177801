import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConsolidationsQueryVariables = GraphQLTypes.Exact<{
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ConsolidationsQueryResult = { __typename?: 'Query' } & {
  consolidations?: GraphQLTypes.Maybe<
    { __typename?: 'ConsolidatedShipmentConnection' } & Pick<GraphQLTypes.ConsolidatedShipmentConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ConsolidatedShipment' } & Pick<
                GraphQLTypes.ConsolidatedShipment,
                | 'actualDepartureDate'
                | 'estimatedArrivalDate'
                | 'actualArrivalDate'
                | 'cutOffDate'
                | 'modeOfTransport'
                | 'mblDocumentReference'
                | 'id'
                | 'reference'
                | 'vehiclePlateNumber'
              > & {
                  canCopy: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                  canEdit: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                  canManage: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                  canViewDetails: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                  networksDestination?: GraphQLTypes.Maybe<
                    | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                  >;
                  networksOrigin?: GraphQLTypes.Maybe<
                    | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const ConsolidationsQueryDocument = /* #__PURE__ */ gql`
  query consolidationsQuery(
    $order: SortInput
    $after: String
    $before: String
    $customerUuid: String
    $first: Int
    $textContains: String
  ) {
    consolidations(
      order: $order
      after: $after
      before: $before
      customerUuid: $customerUuid
      first: $first
      textContains: $textContains
    ) {
      nodes {
        actualDepartureDate
        estimatedArrivalDate
        actualArrivalDate
        canCopy {
          value
        }
        canEdit {
          value
        }
        canManage {
          value
        }
        canViewDetails {
          value
        }
        cutOffDate
        modeOfTransport
        mblDocumentReference
        networksDestination {
          id
          label {
            long
            short
          }
        }
        networksOrigin {
          id
          label {
            long
            short
          }
        }
        id
        reference
        vehiclePlateNumber
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useConsolidationsQuery__
 *
 * To run a query within a React component, call `useConsolidationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidationsQuery({
 *   variables: {
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      customerUuid: // value for 'customerUuid'
 *      first: // value for 'first'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useConsolidationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ConsolidationsQueryResult, ConsolidationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ConsolidationsQueryResult, ConsolidationsQueryVariables>(ConsolidationsQueryDocument, options);
}
export function useConsolidationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsolidationsQueryResult, ConsolidationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ConsolidationsQueryResult, ConsolidationsQueryVariables>(ConsolidationsQueryDocument, options);
}
export type ConsolidationsQueryHookResult = ReturnType<typeof useConsolidationsQuery>;
export type ConsolidationsQueryLazyQueryHookResult = ReturnType<typeof useConsolidationsQueryLazyQuery>;
