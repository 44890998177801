const API_CONSOLE_RELATIVE_INDEX_PATH = 'api-console';

export const API_CONSOLE_ROUTE_PREFIX = `/dashboard/${API_CONSOLE_RELATIVE_INDEX_PATH}`;

interface ApiConsoleRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const apiConsoleRoutes: ApiConsoleRoutes = {
  index: {
    path: `${API_CONSOLE_ROUTE_PREFIX}/*`,
    getUrl: (): string => API_CONSOLE_ROUTE_PREFIX,
    relativePath: `${API_CONSOLE_RELATIVE_INDEX_PATH}/*`
  }
};

export default apiConsoleRoutes;
