import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderListSavedFiltersCountQueryVariables = GraphQLTypes.Exact<{
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  cargoReadyDateBetweenPast?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  cargoReadyDateBetween2Weeks?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  cargoReadyDateUpdatedInLast3Days?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  status?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrderStatusEnum>;
}>;

export type OrderListSavedFiltersCountQueryResult = { __typename?: 'Query' } & {
  unbookedPastCRD: { __typename?: 'PurchaseOrderConnection' } & Pick<GraphQLTypes.PurchaseOrderConnection, 'totalCount'>;
  unbookedCRDIn2Weeks: { __typename?: 'PurchaseOrderConnection' } & Pick<GraphQLTypes.PurchaseOrderConnection, 'totalCount'>;
  cargoReadyDateUpdatedInLast3Days: { __typename?: 'PurchaseOrderConnection' } & Pick<
    GraphQLTypes.PurchaseOrderConnection,
    'totalCount'
  >;
};

export const OrderListSavedFiltersCountDocument = /* #__PURE__ */ gql`
  query orderListSavedFiltersCount(
    $customerUuid: String
    $cargoReadyDateBetweenPast: DateRangeInput
    $cargoReadyDateBetween2Weeks: DateRangeInput
    $cargoReadyDateUpdatedInLast3Days: DateRangeInput
    $status: PurchaseOrderStatusEnum
  ) {
    unbookedPastCRD: purchaseOrders(
      customerUuid: $customerUuid
      bookingState: [NOT_BOOKED, PARTIALLY_BOOKED]
      cargoReadyDateBetween: $cargoReadyDateBetweenPast
      status: $status
    ) {
      totalCount
    }
    unbookedCRDIn2Weeks: purchaseOrders(
      customerUuid: $customerUuid
      bookingState: [NOT_BOOKED, PARTIALLY_BOOKED]
      cargoReadyDateBetween: $cargoReadyDateBetween2Weeks
      status: $status
    ) {
      totalCount
    }
    cargoReadyDateUpdatedInLast3Days: purchaseOrders(
      customerUuid: $customerUuid
      bookingState: [NOT_BOOKED, PARTIALLY_BOOKED]
      lastUpdatedBetween: $cargoReadyDateUpdatedInLast3Days
      status: $status
    ) {
      totalCount
    }
  }
`;

/**
 * __useOrderListSavedFiltersCountQuery__
 *
 * To run a query within a React component, call `useOrderListSavedFiltersCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListSavedFiltersCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListSavedFiltersCountQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *      cargoReadyDateBetweenPast: // value for 'cargoReadyDateBetweenPast'
 *      cargoReadyDateBetween2Weeks: // value for 'cargoReadyDateBetween2Weeks'
 *      cargoReadyDateUpdatedInLast3Days: // value for 'cargoReadyDateUpdatedInLast3Days'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrderListSavedFiltersCountQuery(
  baseOptions?: Apollo.QueryHookOptions<OrderListSavedFiltersCountQueryResult, OrderListSavedFiltersCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderListSavedFiltersCountQueryResult, OrderListSavedFiltersCountQueryVariables>(
    OrderListSavedFiltersCountDocument,
    options
  );
}
export function useOrderListSavedFiltersCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderListSavedFiltersCountQueryResult, OrderListSavedFiltersCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderListSavedFiltersCountQueryResult, OrderListSavedFiltersCountQueryVariables>(
    OrderListSavedFiltersCountDocument,
    options
  );
}
export type OrderListSavedFiltersCountQueryHookResult = ReturnType<typeof useOrderListSavedFiltersCountQuery>;
export type OrderListSavedFiltersCountLazyQueryHookResult = ReturnType<typeof useOrderListSavedFiltersCountLazyQuery>;
