import type {
  Account as AccountType,
  AccountLocation as AccountLocationType,
  AgentOrganisation,
  AssignmentTargetTypeEnum,
  Country,
  ForwarderOrganisation,
  Geolocation,
  LegacyAddress,
  LocationTypeValue,
  NetworksAssignableInterface,
  NetworksContactInLocation,
  NetworksLabelType,
  NetworksLegacyAddress,
  NetworksLegacyOrgLoc,
  NetworksLegacyOrgLocContact,
  NetworksLegacyOrgLocOrganisation,
  NetworksOrgLoc,
  NetworksOrgLocContact,
  NetworksOrgLocLocation,
  NetworksOrgLocOrganisation,
  NetworksTerminal,
  SavedOrganisation,
  TimeRange
} from '@zen/graphql/types.generated';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { IAddressForm } from './Form/FormAddressFields/types';

export type {
  Address,
  AuthorizationResult,
  Country,
  CreateOrganisationInput,
  CreateOrganisationLocationInput,
  Geolocation,
  Invitation,
  LegacyAddress,
  MyOrganisation,
  Network,
  NetworksAssignableInterface,
  NetworksContact,
  NetworksContactInLocation,
  NetworksLabelType,
  NetworksLegacyOrgLoc,
  NetworksLocation,
  NetworksLocationInContact,
  NetworksOrganisationInterface,
  NetworksOrgLoc,
  NetworksTerminal,
  Terminal,
  TimeRange,
  UpdateLocationContactInput,
  UpdateOrganisationLocationInput,
  Warehouse
} from '@zen/graphql/types.generated';
export {
  AssignmentTargetTypeEnum,
  AssignmentTypeValue,
  CountryCode,
  InvitationStatus,
  LocationTypeValue,
  OrganisationTypeValue,
  Role,
  TerminalTypeValue
} from '@zen/graphql/types.generated';

export interface BookingOrgLocTerminal {
  label: NetworksLabelType;
  typename: string;
}

export type LimitedContact = Pick<NetworksContactInLocation, 'id' | 'phoneNumber' | 'firstName' | 'lastName' | 'email'>;

export interface NetworksAssignable {
  id: string;
  label: {
    long: string;
    short: string;
  };
  unlocode?: string;
}

export interface ContactFormValues {
  autoSubscribeToOrganisationTargets: boolean;
  email: string;
  firstName: string;
  id?: string;
  lastName: string;
  phoneNumber: string;
}

export interface BusinessHours {
  friday: TimeRange;
  monday: TimeRange;
  saturday: TimeRange;
  sunday: TimeRange;
  thursday: TimeRange;
  tuesday: TimeRange;
  wednesday: TimeRange;
}

export interface ContactLocationAssignment {
  locationId: string;
  mainContact: boolean;
}

export interface ContactFormFields extends ContactFormValues {
  contactLocationAssignments: ContactLocationAssignment[];
  locationAssignmentsToRemove?: string[];
}

export interface INetworksOrgLoc {
  contact: {
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    phoneNumber: string;
  };
  id: string;
  label?: NetworksLabelType;
  location: {
    address: LegacyAddress;
    geolocation: Geolocation;
    id: string;
    locationType?: LocationTypeValue;
    name: string;
  };
  organisation: {
    id: string;
    name: string;
  };
  typename: string;
}

export interface INetworksLegacyOrgLoc {
  contact: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  };
  id: string;
  label?: NetworksLabelType;
  location: {
    address: LegacyAddress;
  };
  organisation: {
    name: string;
  };
  typename: string;
}

export type IOrgLoc = INetworksOrgLoc | INetworksLegacyOrgLoc;

export type OrgLoc = NetworksOrgLoc | NetworksLegacyOrgLoc;

export type LocationData = IAddressForm & { name?: string };

export interface NetworksOrgLocOption extends NetworksOrgLoc {
  geolocation?: Optional<Geolocation>;
}
export interface INetworksLegacyOrgLocOption extends INetworksLegacyOrgLoc {
  name: string;
}

export type IOrgLocOption = NetworksOrgLocOption | INetworksLegacyOrgLocOption;

export interface INetworksAssignInput {
  assignableId?: string;
  assignmentName: string;
  targetId: string;
  targetType: AssignmentTargetTypeEnum;
}

export enum OrgLocType {
  LEGACY = 'NetworksLegacyOrgLoc',
  NETWORKS = 'NetworksOrgLoc'
}
export interface INotifiedPartiesInput {
  assignableId?: string;
  notifiedPartyId: string;
  zencargoReference: string;
}

export interface INetworksNotifiedParty {
  assignable: INetworksOrgLoc;
  notifiedPartyId: string;
}

export interface FailureReasons {
  details: string;
  fullMessages: string[];
}

export type Organisation = SavedOrganisation | AgentOrganisation | ForwarderOrganisation;

type OrganisationLocationNetworksOrgLoc = { __typename?: 'NetworksOrgLoc' } & Pick<NetworksOrgLoc, 'id'> & {
    contact?: Nullable<Pick<NetworksOrgLocContact, 'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'>>;
    label?: Nullable<Pick<NetworksLabelType, 'short' | 'long'>>;
    location?: Nullable<
      Pick<NetworksOrgLocLocation, 'id' | 'name'> & {
        address?: Nullable<
          Pick<LegacyAddress, 'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'> & {
            country?: Nullable<Pick<Country, 'name' | 'code'>>;
          }
        >;
        geolocation?: Nullable<Pick<Geolocation, 'latitude' | 'longitude'>>;
      }
    >;
    organisation?: Nullable<Pick<NetworksOrgLocOrganisation, 'id' | 'name'>>;
  };

type OrganisationLocationNetworksLegacyOrgLoc = { __typename?: 'NetworksLegacyOrgLoc' } & Pick<NetworksLegacyOrgLoc, 'id'> & {
    contact?: Nullable<Pick<NetworksLegacyOrgLocContact, 'firstName' | 'lastName' | 'email' | 'phoneNumber'>>;
    label?: Nullable<Pick<NetworksLabelType, 'short' | 'long'>>;
    location?: Nullable<{
      address?: Nullable<
        Pick<NetworksLegacyAddress, 'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'> & {
          country?: Nullable<{ __typename?: 'Country' } & Pick<Country, 'name' | 'code'>>;
        }
      >;
    }>;
    organisation?: Nullable<Pick<NetworksLegacyOrgLocOrganisation, 'name'>>;
  };

type OrganisationLocationNetworksTerminal = { __typename?: 'NetworksTerminal' } & Pick<NetworksTerminal, 'id'> & {
    label?: Nullable<Pick<NetworksLabelType, 'short' | 'long'>>;
  };

type AccountLocation = { __typename?: 'AccountLocation' } & Pick<AccountLocationType, 'id'> & {
    label?: Nullable<Pick<NetworksLabelType, 'short' | 'long'>>;
  };

type Account = { __typename?: 'Account' } & Pick<AccountType, 'id'> & {
    label?: Nullable<Pick<NetworksLabelType, 'short' | 'long'>>;
  };

export type OrganisationLocation = Nullable<
  | OrganisationLocationNetworksOrgLoc
  | OrganisationLocationNetworksLegacyOrgLoc
  | OrganisationLocationNetworksTerminal
  | AccountLocation
  | Account
>;

export interface NetworksOrgLocWithName extends NetworksAssignableInterface {
  name?: Nullable<string>;
}

export interface NetworksLocationData {
  businessHours?: BusinessHours;
  geolocation?: Optional<Geolocation>;
  id: Optional<string>;
  label: Optional<NetworksLabelType>;
  organisation?: Optional<{ id: string }>;
  timeZone?: Optional<string>;
}
