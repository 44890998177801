import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShipmentFiltersQueryVariables = GraphQLTypes.Exact<{
  accountUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetShipmentFiltersQueryResult = { __typename?: 'Query' } & {
  filters: { __typename?: 'FiltersFiltersType' } & {
    shipments?: GraphQLTypes.Maybe<
      { __typename?: 'ShipmentsFilter' } & Pick<GraphQLTypes.ShipmentsFilter, 'issueTitles' | 'vesselNames'> & {
          canViewCustomerFilter: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
          customers: Array<{ __typename?: 'FiltersCustomerType' } & Pick<GraphQLTypes.FiltersCustomerType, 'name' | 'uuid'>>;
          destinationCountries: Array<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>>;
          forwarders: Array<
            | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
            | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
            | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
            | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                  organisation?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id'>
                  >;
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
            | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
          >;
          originCountries: Array<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>>;
        }
    >;
  };
};

export const GetShipmentFiltersDocument = /* #__PURE__ */ gql`
  query getShipmentFilters($accountUuid: String) {
    filters(accountUuid: $accountUuid) {
      shipments {
        canViewCustomerFilter {
          value
        }
        customers {
          name
          uuid
        }
        destinationCountries {
          name
          code
        }
        forwarders {
          id
          label {
            long
            short
          }
          ... on NetworksOrgLoc {
            organisation {
              id
            }
          }
        }
        issueTitles
        originCountries {
          name
          code
        }
        vesselNames
      }
    }
  }
`;

/**
 * __useGetShipmentFiltersQuery__
 *
 * To run a query within a React component, call `useGetShipmentFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentFiltersQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useGetShipmentFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetShipmentFiltersQueryResult, GetShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShipmentFiltersQueryResult, GetShipmentFiltersQueryVariables>(GetShipmentFiltersDocument, options);
}
export function useGetShipmentFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentFiltersQueryResult, GetShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShipmentFiltersQueryResult, GetShipmentFiltersQueryVariables>(
    GetShipmentFiltersDocument,
    options
  );
}
export type GetShipmentFiltersQueryHookResult = ReturnType<typeof useGetShipmentFiltersQuery>;
export type GetShipmentFiltersLazyQueryHookResult = ReturnType<typeof useGetShipmentFiltersLazyQuery>;
