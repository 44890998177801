import { cargoModeLabelMapping } from '@zen/Cargo';
import type { RateCard } from '@zen/RateCards/LegacyRateCards/types';
import { formatIssuedBy, formatModeOfTransport } from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';
import { cargoModeLabel } from '@zen/types';
import { compareDate, formatDate } from '@zen/utils/dateTime';

import type { OverviewSection } from './types';

export const getOverviewSections = (rateCard: RateCard): OverviewSection[] => {
  const { startDate, endDate, modeOfTransport, cargoType, issuedAt, issuedBy, updatedAt } = rateCard;
  const shouldDisplayUpdatedAtDate: boolean = !!updatedAt && !compareDate(issuedAt).isEqual(updatedAt);

  return [
    {
      title: 'Start date',
      detail: formatDate(startDate)
    },
    {
      title: 'End date',
      detail: formatDate(endDate)
    },
    {
      title: 'Mode of transport',
      detail: formatModeOfTransport(modeOfTransport)
    },
    {
      title: cargoModeLabel,
      detail: cargoModeLabelMapping[cargoType]
    },
    {
      title: 'Issued by',
      detail: formatIssuedBy(issuedBy)
    },
    {
      title: 'Issued date',
      detail: formatDate(issuedAt)
    },
    ...(shouldDisplayUpdatedAtDate && updatedAt
      ? [
          {
            title: 'Last updated',
            detail: formatDate(updatedAt)
          }
        ]
      : [])
  ];
};
