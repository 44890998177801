import type { Undefinable } from '@zen/utils/typescript';

export const prepareInputDigitsPattern = (maxDecimalDigits: Undefinable<number>, maxWholeDigits: Undefinable<number>): RegExp => {
  const decimalDigitsPattern: string = `[,.][0-9]${maxDecimalDigits ? `{0,${maxDecimalDigits}}` : '*'}`;
  const wholeDigitsPattern: string = `[0-9]${maxWholeDigits ? `{0,${maxWholeDigits}}` : '*'}`;

  return new RegExp(`^(${wholeDigitsPattern})(${decimalDigitsPattern})?$`);
};

export const getInputSymbols = (maxDecimalDigits: Undefinable<number>, min: Undefinable<number | string>) => {
  const decimalSymbol: string = maxDecimalDigits === 0 ? '.' : '';
  const negativeNumberSymbol: string = typeof min === 'number' && min >= 0 ? '-' : '';

  return ['+', decimalSymbol, negativeNumberSymbol];
};

export const getPlaceholder = (maxDecimalDigits: Undefinable<number>, placeholder: Undefinable<string>) => {
  const defaultPlaceholder: string = maxDecimalDigits === 0 ? '0' : '0.00';

  return placeholder || defaultPlaceholder;
};
