import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConsolidatedShipmentReferenceQueryVariables = GraphQLTypes.Exact<{
  consolidationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ConsolidatedShipmentReferenceQueryResult = { __typename?: 'Query' } & {
  consolidations?: GraphQLTypes.Maybe<
    { __typename?: 'ConsolidatedShipmentConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ConsolidatedShipment' } & Pick<GraphQLTypes.ConsolidatedShipment, 'reference' | 'id'>
          >
        >
      >;
    }
  >;
};

export const ConsolidatedShipmentReferenceDocument = /* #__PURE__ */ gql`
  query consolidatedShipmentReference($consolidationId: String) {
    consolidations(consolidatedShipmentId: $consolidationId) {
      nodes {
        reference
        id
      }
    }
  }
`;

/**
 * __useConsolidatedShipmentReferenceQuery__
 *
 * To run a query within a React component, call `useConsolidatedShipmentReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsolidatedShipmentReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsolidatedShipmentReferenceQuery({
 *   variables: {
 *      consolidationId: // value for 'consolidationId'
 *   },
 * });
 */
export function useConsolidatedShipmentReferenceQuery(
  baseOptions?: Apollo.QueryHookOptions<ConsolidatedShipmentReferenceQueryResult, ConsolidatedShipmentReferenceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ConsolidatedShipmentReferenceQueryResult, ConsolidatedShipmentReferenceQueryVariables>(
    ConsolidatedShipmentReferenceDocument,
    options
  );
}
export function useConsolidatedShipmentReferenceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConsolidatedShipmentReferenceQueryResult, ConsolidatedShipmentReferenceQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ConsolidatedShipmentReferenceQueryResult, ConsolidatedShipmentReferenceQueryVariables>(
    ConsolidatedShipmentReferenceDocument,
    options
  );
}
export type ConsolidatedShipmentReferenceQueryHookResult = ReturnType<typeof useConsolidatedShipmentReferenceQuery>;
export type ConsolidatedShipmentReferenceLazyQueryHookResult = ReturnType<typeof useConsolidatedShipmentReferenceLazyQuery>;
