/* eslint no-console: "off"  */
import { isString } from 'lodash';

type LogLevel = 'input' | 'prompt' | 'data' | 'warn' | 'debug' | 'error';

const colors: Record<LogLevel, string> = {
  input: 'black',
  prompt: 'blue',
  data: 'grey',
  warn: 'orange',
  debug: 'grey',
  error: 'red'
};

class Logger {
  enabled = false;

  prefix;

  constructor(enabled = false) {
    this.enabled = enabled;
    this.prefix = '[zencargo]';
  }

  getStyles(method: LogLevel, bold: boolean = false) {
    const styles = [`color: ${colors[method]}`, bold && 'font-weight: bold'];

    return styles.join(';');
  }

  write(method: LogLevel, message: string) {
    if (this.enabled) {
      switch (method) {
        case 'debug':
          console.debug(this.prefix, message);
          break;

        default:
          if (isString(message)) {
            console.log(`%c ${this.prefix}: ${message}`, this.getStyles(method));
          } else {
            console.log(`%c ${this.prefix} ↓`, this.getStyles(method));
            console.debug(message);
          }
          break;
      }
    }
  }

  log = (message: string) => this.write('input', message);

  info = (message: string) => this.write('prompt', message);

  warn = (message: string) => this.write('warn', message);

  error = (message: string) => this.write('error', message);

  debug = (message: string) => this.write('debug', message);

  enable() {
    this.enabled = true;
  }

  disable() {
    this.enabled = false;
  }
}

const logger = new Logger(process.env.NODE_ENV === 'development');

export default logger;
