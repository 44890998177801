import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';
import { useAcceptQuoteOptionMutation, useRejectQuoteOptionMutation } from '@zen/Quotes/graphql';
import type { QuoteOption } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  handleAction: (quoteOption: QuoteOption) => void;
  quoteOptionId: string;
}

const ActiveQuoteOptionButtons: FC<Props> = (props) => {
  const { quoteOptionId, handleAction } = props;
  const { addError } = useNotification();
  const onError = (): void => {
    addError();
  };

  const [acceptQuote] = useAcceptQuoteOptionMutation();
  const [rejectQuoteOption] = useRejectQuoteOptionMutation({ onError });

  const handleReject = async (): Promise<void> => {
    const { data } = await rejectQuoteOption({
      variables: {
        input: { quoteOptionId }
      }
    });

    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    handleAction(data.bookingRejectQuoteOption.quoteOption);
  };

  const handleAccept = async (): Promise<void> => {
    const { data } = await acceptQuote({
      variables: {
        input: { quoteOptionId }
      }
    });

    // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
    handleAction(data.bookingAcceptQuoteOption.quoteOption);
  };

  return (
    <>
      <Button className="mr-4" onClick={handleAccept}>
        Accept
      </Button>
      <Button className="mr-4" onClick={handleReject} variant="secondary">
        Reject
      </Button>
    </>
  );
};

export default ActiveQuoteOptionButtons;
