import type { ModeOfTransport } from '@zen/Booking';
import { isRoadBooking } from '@zen/Booking';
import type { BusinessHours, NetworksLabelType } from '@zen/Networks';
import { prepareBusinessHoursInitialValues } from '@zen/Networks/networksHelpers';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { getStopTimeZone, isCarriageTransshipmentStop, isCollectionStop, isDeliveryStop } from '../../helpers';
import {
  JourneyLeg,
  JourneyLegTypeEnum,
  JourneyShippingMilestone,
  JourneyShippingMilestoneCategoryEnum,
  JourneyStop,
  JourneyStopLocationTypeEnum,
  JourneyStopReference,
  JourneyStopWithTransitInfo,
  ShippingMilestoneDateTypeEnum,
  TransitInfo
} from '../../types';

export const filterCompletedMilestones = (
  stops: JourneyStopWithTransitInfo[],
  isLastCompletedMilestone: (id: string) => boolean
): JourneyStopWithTransitInfo[] => {
  const stopsWithMilestones = ({ shippingMilestones }: JourneyStopWithTransitInfo): boolean => shippingMilestones.length > 0;

  return stops
    .map((stop) => {
      return {
        ...stop,
        shippingMilestones: stop.shippingMilestones.filter(({ completed, id }) => {
          return isLastCompletedMilestone(id) || !completed;
        })
      };
    })
    .filter(stopsWithMilestones);
};

export const getLocationName = ({ locationType, terminal, warehouse }: JourneyStop): Nullable<NetworksLabelType> => {
  const isWarehouse: boolean = locationType === JourneyStopLocationTypeEnum.WAREHOUSE;

  if (!terminal && !warehouse) {
    return null;
  }

  return {
    short: isWarehouse ? warehouse?.label?.short : terminal?.label,
    long: isWarehouse ? warehouse?.label?.long : terminal?.label
  };
};

export const getCollectionStopTimeZone = (stops: JourneyStopWithTransitInfo[]): Optional<string> => {
  const collectionStop: Optional<JourneyStopWithTransitInfo> = stops?.find(({ stopAction }) => isCollectionStop(stopAction));

  return collectionStop ? getStopTimeZone(collectionStop) : null;
};

export const getCollectionLocationBusinessHours = (stops: JourneyStopWithTransitInfo[]): BusinessHours => {
  const collectionStop: Optional<JourneyStopWithTransitInfo> = stops?.find(({ stopAction }) => isCollectionStop(stopAction));

  return prepareBusinessHoursInitialValues(collectionStop?.warehouse?.businessHours);
};

export const isTransshipmentJourney = (legs: JourneyLeg[]): boolean => {
  return legs.some(({ from, to }) => isCarriageTransshipmentStop(from.stopAction) || isCarriageTransshipmentStop(to.stopAction));
};

export const isInTransit = ({ from, to }: JourneyLeg): boolean => {
  return from.shippingMilestones[from.shippingMilestones.length - 1]?.completed && !to.shippingMilestones[0]?.completed;
};

export const isTransitCompleted = ({ to }: JourneyLeg): boolean => {
  return to.shippingMilestones[0]?.completed;
};

export const prepareStops = (legs: JourneyLeg[]): JourneyStop[] => {
  return legs.reduce((stops: JourneyStopWithTransitInfo[], currentLeg: JourneyLeg, currentIndex: number, array: JourneyLeg[]) => {
    const { from, to } = currentLeg;

    const isFirstLeg: boolean = currentIndex === 0;
    const nextLeg: Optional<JourneyLeg> = array[currentIndex + 1];

    const toStop: JourneyStopWithTransitInfo = nextLeg
      ? {
          ...to,
          shippingMilestones: [...to.shippingMilestones, ...nextLeg.from.shippingMilestones],
          outgoingTransitInfo: prepareTransitInfo(nextLeg)
        }
      : to;

    if (isFirstLeg) {
      return [{ ...from, outgoingTransitInfo: prepareTransitInfo({ ...currentLeg, to: toStop }) }, toStop];
    }

    return [...stops, toStop];
  }, []);
};

export const prepareStopReferences = (
  stops: JourneyStop[],
  legType: JourneyLegTypeEnum,
  modeOfTransport: Optional<ModeOfTransport>
): JourneyStopReference[] => {
  const getReferenceInfo = ({ canViewReference, canUpdateReference, id, reference, stopAction }: JourneyStop) => ({
    canViewReference,
    canUpdateReference,
    id,
    reference,
    stopAction
  });

  const collectionStops: JourneyStopReference[] = stops
    .filter(({ stopAction }: JourneyStop) => isCollectionStop(stopAction))
    .map(getReferenceInfo);

  const deliveryStops: JourneyStopReference[] = stops
    .filter(({ stopAction }: JourneyStop) => isDeliveryStop(stopAction))
    .map(getReferenceInfo);

  if (isRoadBooking(modeOfTransport)) {
    return [...collectionStops, ...deliveryStops];
  }

  if (legType === JourneyLegTypeEnum.CARRIAGE) return [];

  return legType === JourneyLegTypeEnum.PRE_CARRIAGE ? [...collectionStops] : [...deliveryStops];
};

export const prepareTransitInfo = (leg: JourneyLeg): TransitInfo => {
  const { modeOfTransport, transitTimeInMinutes, type, vehicle } = leg;

  return {
    isCompleted: isTransitCompleted(leg),
    isInTransit: isInTransit(leg),
    legType: type,
    modeOfTransport,
    transitTimeInMinutes,
    vehicleId: vehicle.id,
    vehicleProperties: vehicle.properties || []
  };
};

export const getCollectionMilestoneDateType = (
  milestones: JourneyShippingMilestone[],
  isFirstRoadLeg: boolean
): Optional<ShippingMilestoneDateTypeEnum> => {
  const collectionMilestoneCategory: JourneyShippingMilestoneCategoryEnum = isFirstRoadLeg
    ? JourneyShippingMilestoneCategoryEnum.ARRIVED_AT_STOP
    : JourneyShippingMilestoneCategoryEnum.DEPARTED_FROM_STOP;

  return milestones.find(({ category }) => category === collectionMilestoneCategory)?.dateType;
};
