import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import type { LocationDescriptorObject } from '@zen/types';

import NavigationHistoryContext from './NavigationHistoryContext';

const useNavigationHistory = () => {
  const { pathname } = useLocation();

  const { navigateBack: goBack, navigate } = useContext(NavigationHistoryContext);

  const navigateBack = (
    defaultUrl: string,
    modifyTargetLocation?: (location: LocationDescriptorObject) => LocationDescriptorObject
  ) => {
    goBack(pathname, defaultUrl, modifyTargetLocation);
  };

  return { navigate, navigateBack };
};

export default useNavigationHistory;
