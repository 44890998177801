import moment from 'moment';
import type { FC } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

const DATE_FORMAT = 'h:mma';

interface Props {
  className?: string;
  date: string;
  resolvedAt?: Optional<string>;
}

const Timestamp: FC<Props> = ({ className, date, resolvedAt = undefined }) => {
  const time: string = moment(date).format(DATE_FORMAT);
  const dateTimeLabel: string = resolvedAt ? formatDate(resolvedAt) : time;
  const tooltipContent: string = resolvedAt ? `Resolved at ${dateTimeLabel}` : dateTimeLabel;

  return (
    <div className={className} data-testid="timestamp">
      <Tooltip placement="top" tooltipContent={tooltipContent}>
        <Icon className="text-base text-grey-light hover:text-grey-dark" icon="zicon-time" />
      </Tooltip>
    </div>
  );
};

export default Timestamp;
