import { AnySchema, number, object } from 'yup';

import type { Nullable, Optional } from '@zen/utils/typescript';

import { isValidHeightForRoadShipment, maxCargoHeightForRoadShipment } from '../helpers';
import type { CargoDimensionsUnitEnum, ModeOfTransport } from '../types';

export const positiveWholeNumberValidator = (): AnySchema => {
  return number().nullable().positive('Must be greater than 0.').integer('Must be whole number.');
};

export const quantityValidator = (isRequired: boolean = true, maxQuantity?: number | undefined): AnySchema => {
  let validator = number().nullable().positive('Must be greater than 0.').integer('Must be whole number.');

  if (maxQuantity) {
    validator = validator.max(maxQuantity, `Quantity can not be greater than ${maxQuantity}.`);
  }

  if (isRequired) {
    validator = validator.required('Please add quantity.');
  }

  return validator;
};

export const positiveNumberValidator = (): AnySchema => {
  return number().nullable().positive('Must be greater than 0.');
};

export const dimensionsValidator = (
  modeOfTransport: Nullable<ModeOfTransport>,
  isDimensionsAndWeightMandatory: boolean
): AnySchema => {
  return object().shape({
    ...(isDimensionsAndWeightMandatory
      ? {
          length: positiveNumberValidator().required('Please enter length.'),
          width: positiveNumberValidator().required('Please enter width.')
        }
      : {
          length: positiveNumberValidator(),
          width: positiveNumberValidator()
        }),
    height: positiveNumberValidator()
      .test('is-greater-than', function test(value: Optional<number>) {
        const metric = this.parent.metric as CargoDimensionsUnitEnum;
        const isValid: boolean = isValidHeightForRoadShipment(value, metric, modeOfTransport);
        const message: string = `Height can not be greater than ${
          maxCargoHeightForRoadShipment[metric]
        } ${metric.toLocaleLowerCase()}.`;

        return isValid || this.createError({ message });
      })
      .test('height validator', function test(value: Optional<number>) {
        if (!value && isDimensionsAndWeightMandatory) {
          return this.createError({ message: 'Please enter height.' });
        }

        return true;
      })
  });
};
