import type { FC } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { Optional } from '@zen/utils/typescript';

import LocationIcon from '../LocationIcon';

interface Props {
  isCompleted: boolean;
  label: Optional<string>;
  locationAddress: Optional<string>;
}

const CollectionOrDeliveryStop: FC<Props> = ({ isCompleted, label, locationAddress }) => {
  return (
    <div className="relative flex items-start pr-2 mt-3 bg-white">
      <div className="w-48 pt-6" />
      <LocationIcon isCompleted={isCompleted} />
      <div className="flex-1 w-full">
        <div className="text-xs leading-tight text-grey-base">{label}</div>
        <div className="text-sm text-grey-dark">
          <ValueWithDefault value={locationAddress} />
        </div>
      </div>
    </div>
  );
};

export default CollectionOrDeliveryStop;
