import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RejectQuoteOptionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RejectQuoteOptionInput;
}>;

export type RejectQuoteOptionMutationResult = { __typename?: 'Mutation' } & {
  bookingRejectQuoteOption?: GraphQLTypes.Maybe<
    { __typename?: 'RejectQuoteOptionPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<
        { __typename?: 'QuoteOption' } & Pick<
          GraphQLTypes.QuoteOption,
          'id' | 'zencargoReference' | 'quoteReference' | 'accrualId' | 'description' | 'state'
        > & {
            total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
            expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
          }
      >;
    }
  >;
};

export const RejectQuoteOptionDocument = /* #__PURE__ */ gql`
  mutation rejectQuoteOption($input: RejectQuoteOptionInput!) {
    bookingRejectQuoteOption(input: $input) {
      quoteOption {
        id
        zencargoReference
        quoteReference
        accrualId
        description
        state
        total {
          value
          currency
        }
        expiryDate {
          date
        }
      }
    }
  }
`;

/**
 * __useRejectQuoteOptionMutation__
 *
 * To run a mutation, you first call `useRejectQuoteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectQuoteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectQuoteOptionMutation, { data, loading, error }] = useRejectQuoteOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectQuoteOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectQuoteOptionMutationResult, RejectQuoteOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RejectQuoteOptionMutationResult, RejectQuoteOptionMutationVariables>(
    RejectQuoteOptionDocument,
    options
  );
}
export type RejectQuoteOptionMutationHookResult = ReturnType<typeof useRejectQuoteOptionMutation>;
