import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping, ContainerTypeEnum } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import { CargoFullContainerVariant } from '../../types';

const fclConfiguration: Record<CargoFullContainerVariant, ContainerTypeEnum[]> = {
  [CargoFullContainerVariant.DRY]: [
    ContainerTypeEnum.X20DV,
    ContainerTypeEnum.X40DV,
    ContainerTypeEnum.X40HC,
    ContainerTypeEnum.X45HC
  ],
  [CargoFullContainerVariant.REFRIGERATED]: [
    ContainerTypeEnum.X20DV,
    ContainerTypeEnum.X40DV,
    ContainerTypeEnum.X40HC,
    ContainerTypeEnum.X45HC
  ],
  [CargoFullContainerVariant.OPEN_TOP]: [ContainerTypeEnum.X20DV, ContainerTypeEnum.X40DV, ContainerTypeEnum.X45HC],
  [CargoFullContainerVariant.FLATRACK]: [ContainerTypeEnum.X20DV, ContainerTypeEnum.X40DV, ContainerTypeEnum.X40HC]
};

export const getContainerTypeOptions = (containerVariant: Optional<CargoFullContainerVariant>): Option<ContainerTypeEnum>[] => {
  if (!containerVariant) {
    return [];
  }

  return fclConfiguration[containerVariant].map((item: ContainerTypeEnum) => ({
    label: cargoTypeLabelMapping[item],
    value: item
  }));
};
