import type { FC } from 'react';

interface Props {
  labels: string[];
}

const FilterMultiSelectLabel: FC<Props> = ({ labels }) => {
  const [first, ...remaining] = labels;

  return (
    <span className="flex items-center space-x-1" data-testid="filter-multi-select-label">
      <span className="overflow-hidden truncate">{first}</span>
      {!!remaining.length && (
        <div className="h-5 w-6 text-center text-grey-dark rounded bg-grey-dark/[.08]">+{remaining.length}</div>
      )}
    </span>
  );
};

export default FilterMultiSelectLabel;
