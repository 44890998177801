import type { FC } from 'react';

import BookingButtonContainer from '@zen/Components/BookingButton/BookingButtonContainer';
import GlobalSearch from '@zen/Components/GlobalSearch';
import Notifications from '@zen/Notifications';

import GuidesLink from '../components/GuidesLink';
import UserMenu from '../components/UserMenu';
import Header from '../Header';

const TopNavigation: FC = () => {
  return (
    <Header>
      <div className="flex-1 max-w-xl mr-8 -ml-px">
        <GlobalSearch />
      </div>
      <div className="flex items-center h-full ml-auto space-x-2">
        <BookingButtonContainer />
        <GuidesLink />
        <Notifications />
        <UserMenu />
      </div>
    </Header>
  );
};

export default TopNavigation;
