import type { FC, ReactNode } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { Banner } from '@zen/DesignSystem';

import { useOceanTrackingStatusQuery } from './graphql';
import { oceanTrackingIssuesLabelMapping } from './helpers';
import type { BookingData, OceanTrackingIssue } from './types';

interface Props {
  zencargoReference: string;
}

const OceanTrackingStatus: FC<Props> = ({ zencargoReference }) => {
  const { data, loading, error } = useOceanTrackingStatusQuery({ variables: { zencargoReference } });

  const renderIssue = ({ type, message, isActionable }: OceanTrackingIssue): ReactNode => {
    const bannerType: 'warning' | 'default' = isActionable ? 'warning' : 'default';
    const bannerMessage: string = message || `This booking is not tracked because ${oceanTrackingIssuesLabelMapping[type]}.`;

    return <Banner key={type} message={bannerMessage} type={bannerType} />;
  };

  return (
    <QueryHandler data={data?.bookings?.nodes?.[0]} error={!!error} isLoading={loading} loadingComponent={null}>
      {(booking: BookingData) => {
        const canViewTrackingDetails: boolean = !!booking.oceanTracking?.canViewTrackingDetails;
        const issues: OceanTrackingIssue[] = booking.oceanTracking?.issues || [];
        const hasIssues: boolean = issues.length > 0;
        const shouldRenderOceanTrackingIssue: boolean = canViewTrackingDetails && hasIssues;

        return shouldRenderOceanTrackingIssue ? (
          <div className="flex flex-col mb-6 space-y-2">{issues.map(renderIssue)}</div>
        ) : null;
      }}
    </QueryHandler>
  );
};

export default OceanTrackingStatus;
