import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersDeleteOrderMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteOrderInput;
}>;

export type PurchaseOrdersDeleteOrderMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersDeleteOrder?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteOrderPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const PurchaseOrdersDeleteOrderDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersDeleteOrder($input: DeleteOrderInput!) {
    purchaseOrdersDeleteOrder(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __usePurchaseOrdersDeleteOrderMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersDeleteOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersDeleteOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersDeleteOrderMutation, { data, loading, error }] = usePurchaseOrdersDeleteOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersDeleteOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersDeleteOrderMutationResult, PurchaseOrdersDeleteOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersDeleteOrderMutationResult, PurchaseOrdersDeleteOrderMutationVariables>(
    PurchaseOrdersDeleteOrderDocument,
    options
  );
}
export type PurchaseOrdersDeleteOrderMutationHookResult = ReturnType<typeof usePurchaseOrdersDeleteOrderMutation>;
