import type { FC, ReactNode } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { Banner } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { useRoadTrackingStatusQuery } from './graphql';
import { missingDataPointLabelMapping, trackingStatusLabelMapping } from './helpers';
import {
  type BookingData,
  type MissingRoadTrackingInputData,
  MultiStopEnum,
  RoadTrackingStatusReason,
  TruckSwapEnum
} from './types';

interface Props {
  zencargoReference: string;
}

const RoadTrackingStatus: FC<Props> = ({ zencargoReference }) => {
  const { data, loading, error } = useRoadTrackingStatusQuery({ variables: { zencargoReference } });

  const renderNotTrackableInfo = ({ multiStop }: BookingData): ReactNode => {
    const bookingType: string = multiStop === MultiStopEnum.MORE_THAN_TWO_STOPS ? 'multi-stop' : 'truck swap';
    const message: string = `This booking is not trackable because it is a ${bookingType} booking.`;

    return <Banner message={message} />;
  };

  const renderMissingDataPoints = (missingDataPoints: MissingRoadTrackingInputData[]): ReactNode => {
    const dataPoints: string[] = missingDataPoints.map((point) => missingDataPointLabelMapping[point]);
    const lastDataPoint = dataPoints.pop();
    const dataPointsFormatted = dataPoints.length > 0 ? `${dataPoints.join(', ')} and ${lastDataPoint}` : lastDataPoint;

    return <Banner message={`This booking is not tracked because it is missing ${dataPointsFormatted}.`} type="warning" />;
  };

  const renderStatus = (booking: BookingData): ReactNode => {
    const { multiStop, roadTracking, truckSwap } = booking;
    const missingDataPoints: MissingRoadTrackingInputData[] = roadTracking?.trackingInput?.missingData || [];
    const trackingStatus: Optional<RoadTrackingStatusReason> = roadTracking?.trackingInput?.trackingSubscription?.statusReason;
    const trackingStatusFormatted: Optional<string> = trackingStatus ? trackingStatusLabelMapping[trackingStatus] : null;

    if (multiStop === MultiStopEnum.MORE_THAN_TWO_STOPS || truckSwap === TruckSwapEnum.TRUCK_SWAP) {
      return renderNotTrackableInfo(booking);
    }

    if (missingDataPoints.length > 0) {
      return renderMissingDataPoints(missingDataPoints);
    }

    if (trackingStatusFormatted) {
      const message: string = `This booking is not tracked because ${trackingStatusFormatted}`;

      return <Banner message={message} />;
    }

    return null;
  };

  return (
    <QueryHandler data={data?.bookings?.nodes?.[0]} error={!!error} isLoading={loading} loadingComponent={null}>
      {(booking: BookingData) => {
        return booking.customsOnly ? null : <div className="mb-6">{renderStatus(booking)}</div>;
      }}
    </QueryHandler>
  );
};

export default RoadTrackingStatus;
