import type { FC } from 'react';

import { useRejectQuoteOptionReasonMutation } from '@zen/Quotes/graphql';
import type { QuoteOption, QuoteOptionRejectionReason } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';

import RejectReason from './RejectReason';

interface Props {
  onReject: (quoteOption: QuoteOption) => void;
  quoteOption: QuoteOption;
}

const RejectReasonContainer: FC<Props> = (props) => {
  const { addError } = useNotification();
  const { quoteOption, onReject } = props;
  const [rejectQuoteOptionReason] = useRejectQuoteOptionReasonMutation({
    onError: () => {
      addError();
    }
  });

  const handleReject = async (rejectionReason: QuoteOptionRejectionReason, text?: string) => {
    const variables = {
      input: {
        quoteOptionId: quoteOption.id,
        rejectionReason,
        ...(text && { reasonDescription: text })
      }
    };

    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ input: { reasonDescription?: string | unde... Remove this comment to see the full error message
    const { data, errors } = await rejectQuoteOptionReason({ variables });

    if (!errors) {
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      onReject(data.bookingProvideQuoteOptionRejectionReason.quoteOption);
    }
  };

  return <RejectReason handleReject={handleReject} quoteOption={quoteOption} />;
};

export default RejectReasonContainer;
