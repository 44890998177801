import type { FC } from 'react';

import NetworkContactItem from '@zen/Components/NetworkContactItem';
import { useNetworksAssignMutation, useNetworksRemoveAssignmentMutation } from '@zen/Networks/graphql';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, OrganisationLocation } from '@zen/Networks/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { BookingTradeParties } from '../types';

interface InputDataItem {
  assignmentType: AssignmentTypeValue;
  key: string;
  name: string;
  value: Optional<OrganisationLocation>;
}

interface Props {
  accountUuid?: string;
  canManageTradeParties: boolean;
  data: BookingTradeParties;
  zencargoReference: string;
}

const ConsignorConsigneeFields: FC<Props> = (props) => {
  const { accountUuid, canManageTradeParties, data, zencargoReference } = props;
  const { consignor, consignee } = data;

  const [assignAction] = useNetworksAssignMutation({ refetchQueries: ['getBookingTradeParties'], awaitRefetchQueries: true });
  const [removeAssignment] = useNetworksRemoveAssignmentMutation({
    refetchQueries: ['getBookingTradeParties'],
    awaitRefetchQueries: true
  });

  const inputs: InputDataItem[] = [
    {
      key: 'consignor',
      name: 'consignor',
      assignmentType: AssignmentTypeValue.CONSIGNOR,
      value: consignor
    },
    {
      key: 'consignee',
      name: 'consignee',
      assignmentType: AssignmentTypeValue.CONSIGNEE,
      value: consignee
    }
  ];

  const { addSuccess, addError } = useNotification();

  const handleInputChange = async (input: InputDataItem, value: Nullable<OrganisationLocation>): Promise<void> => {
    const { name } = input;

    const confirmationText: string = value ? 'saved' : 'removed';

    const variables = {
      assignmentName: name,
      targetId: zencargoReference,
      targetType: AssignmentTargetTypeEnum.BOOKING
    };

    const onSuccess = () => addSuccess(`${removeSpecialCharactersAndUpperFirst(name)} has been ${confirmationText}.`);

    if (!value) {
      performMutation({
        mutationFn: () => removeAssignment({ variables: { input: variables } }),
        onSuccess,
        onError: () => addError()
      });
    } else {
      const assignableId: string = value?.id ? value.id : '';

      performMutation({
        mutationFn: () => assignAction({ variables: { input: { ...variables, assignableId } } }),
        onError: () => addError(),
        onSuccess
      });
    }
  };

  return (
    <>
      {inputs.map((input: InputDataItem) => (
        <NetworkContactItem
          key={input.key}
          accountUuid={accountUuid}
          assignmentType={input.assignmentType}
          isEditable={canManageTradeParties}
          name={input.name}
          onChange={(value: Nullable<OrganisationLocation>) => handleInputChange(input, value)}
          value={input.value}
        />
      ))}
    </>
  );
};

export default ConsignorConsigneeFields;
