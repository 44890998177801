import { createContext } from 'react';

import type { CargoModeEnum, ModeOfTransport } from '@zen/Booking';
import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';
import type { Optional } from '@zen/utils/typescript';

import type {
  CarriageLeg,
  JourneyMilestoneWithMetadata,
  JourneyShippingMilestone,
  JourneyStop,
  MilestoneDateType
} from '../../types';
import type { MilestoneDisabledDateProps } from './types';

export interface JourneyContext {
  cargoCount: number;
  cargoId: string;
  cargoMilestones: Record<string, JourneyMilestoneWithMetadata[]>;
  cargoMode: Optional<CargoModeEnum>;
  cargoReadyDate: Optional<string>;
  carriageLeg: Optional<CarriageLeg>;
  completedMilestoneCount: number;
  getMilestoneDisabledDates: (milestoneId: string, dateType: MilestoneDateType) => MilestoneDisabledDateProps;
  isCompletedMilestonesSwitcherVisible: boolean;
  isFirstMilestone: (id: string) => boolean;
  isJourneyCompleted: boolean;
  isLastCompletedMilestone: (id: string) => boolean;
  isLastMilestone: (id: string) => boolean;
  milestones: JourneyMilestoneWithMetadata[];
  modeOfTransport: Optional<ModeOfTransport>;
  onCompletedMilestonesVisibilityChange: (visible: boolean) => void;
  onMilestoneDateChange: (milestoneId: string, values: DateWithTimeFields) => void;
  postLegsShippingMilestones: JourneyShippingMilestone[];
  preLegsShippingMilestones: JourneyShippingMilestone[];
  showCompletedMilestones: boolean;
  stops: JourneyStop[];
  zencargoReference: string;
}

const initialContext = {
  cargoCount: 0,
  cargoId: '',
  cargoMilestones: {},
  cargoMode: null,
  cargoReadyDate: null,
  carriageLeg: null,
  completedMilestoneCount: 0,
  getMilestoneDisabledDates: (milestoneId: string, dateType: string) => ({}),
  isCompletedMilestonesSwitcherVisible: false,
  isJourneyCompleted: false,
  isLastCompletedMilestone: (id: string) => false,
  isFirstMilestone: (id: string) => false,
  isLastMilestone: (id: string) => false,
  milestones: [],
  modeOfTransport: null,
  onCompletedMilestonesVisibilityChange: (visible: boolean) => false,
  onMilestoneDateChange: (milestoneId: string, values: DateWithTimeFields) => {},
  postLegsShippingMilestones: [],
  preLegsShippingMilestones: [],
  showCompletedMilestones: false,
  stops: [],
  zencargoReference: ''
};

const JourneyDetailsContext = createContext<JourneyContext>(initialContext);

const useJourneyContext = (): JourneyContext => {
  return useContextOrThrowError<JourneyContext>(
    JourneyDetailsContext,
    'useJourneyContext must be used within JourneyDetailsContext'
  );
};

export { JourneyDetailsContext, useJourneyContext };
