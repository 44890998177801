import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props {
  apiKeyId: string;
  closeModal: () => void;
  isModalOpen: boolean;
  onConfirm: () => Promise<IOkOrErrorResult>;
}

const DeleteKeyModal: FC<Props> = (props) => {
  const { isModalOpen = false, closeModal, apiKeyId, onConfirm } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ apiKeyId: string }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Delete API key">
      <Button data-testid="cancel-button" onClick={() => closeModal()} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const renderFormContent = (): ReactNode => {
    return (
      <div className="mb-4 text-sm leading-normal text-grey-base">
        Access to the SCIM user-provisioning API will be lost for those using this key.
      </div>
    );
  };

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} title="Delete this key?">
      <Form formButtons={renderFormButtons} initialValues={{ apiKeyId }} onSubmit={onConfirm} onSuccess={closeModal}>
        {renderFormContent}
      </Form>
    </Modal>
  );
};

export default DeleteKeyModal;
export type { Props as DeleteKeyModalProps };
