import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import OceanCarrierSelect from '../OceanCarrierSelect';
import { useOceanCarrierByScacQuery, useUpdateCarrierMutation } from './graphql';
import type { OceanCarrier } from './types';

interface Props {
  canUpdateCarrier: boolean;
  carrier: string;
}

const OceanCarrierInlineField: FC<Props> = ({ canUpdateCarrier, carrier }) => {
  const { addSuccess, addError } = useNotification();

  const { cargoIds, zencargoReference } = useCargoOverviewContext();

  const { data } = useOceanCarrierByScacQuery({ variables: { scac: carrier }, fetchPolicy: 'no-cache', skip: !carrier });
  const [updateCarrier, { loading }] = useUpdateCarrierMutation();

  const oceanCarrier: Undefinable<OceanCarrier> = data?.oceanCarrierByScac || undefined;
  const label: string = 'Carrier (SCAC)';

  const handleChange = async (value: Nullable<string>): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updateCarrier({
          variables: {
            input: {
              cargoIds,
              scac: value,
              zencargoReference
            }
          },
          refetchQueries: ['cargoOverview']
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateCarrier) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={oceanCarrier?.oceanCarrierName} />
      </LabelledValue>
    );
  }

  return (
    <OceanCarrierSelect
      isLoading={loading}
      label={label}
      name="carrier"
      onChange={handleChange}
      value={carrier}
      variant="inline"
    />
  );
};

export default OceanCarrierInlineField;
