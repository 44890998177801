import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountLocationsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  accountLocationsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.AccountLocationsFiltersInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetAccountLocationsQueryResult = { __typename?: 'Query' } & {
  accountLocations?: GraphQLTypes.Maybe<
    { __typename?: 'AccountLocationConnection' } & Pick<GraphQLTypes.AccountLocationConnection, 'totalCount'> & {
        nodes: Array<
          GraphQLTypes.Maybe<
            { __typename?: 'AccountLocation' } & Pick<
              GraphQLTypes.AccountLocation,
              'addressLabel' | 'companyName' | 'name' | 'id' | 'isRegisteredLegalAddress'
            > & {
                members: Array<{ __typename?: 'AccountMember' } & Pick<GraphQLTypes.AccountMember, 'fullName' | 'id'>>;
                permissions?: GraphQLTypes.Maybe<
                  { __typename?: 'LocationPermissions' } & {
                    canArchive?: GraphQLTypes.Maybe<
                      { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                    >;
                    canEdit?: GraphQLTypes.Maybe<
                      { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                    >;
                  }
                >;
              }
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetAccountLocationsDocument = /* #__PURE__ */ gql`
  query getAccountLocations(
    $accountId: String!
    $accountLocationsFiltersInput: AccountLocationsFiltersInput
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
  ) {
    accountLocations(
      accountId: $accountId
      accountLocationsFiltersInput: $accountLocationsFiltersInput
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
    ) {
      nodes {
        addressLabel
        companyName
        name
        id
        isRegisteredLegalAddress
        members {
          fullName
          id
        }
        permissions {
          canArchive {
            value
          }
          canEdit {
            value
          }
        }
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetAccountLocationsQuery__
 *
 * To run a query within a React component, call `useGetAccountLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountLocationsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountLocationsFiltersInput: // value for 'accountLocationsFiltersInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetAccountLocationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountLocationsQueryResult, GetAccountLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountLocationsQueryResult, GetAccountLocationsQueryVariables>(GetAccountLocationsDocument, options);
}
export function useGetAccountLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountLocationsQueryResult, GetAccountLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountLocationsQueryResult, GetAccountLocationsQueryVariables>(
    GetAccountLocationsDocument,
    options
  );
}
export type GetAccountLocationsQueryHookResult = ReturnType<typeof useGetAccountLocationsQuery>;
export type GetAccountLocationsLazyQueryHookResult = ReturnType<typeof useGetAccountLocationsLazyQuery>;
