import { Schema, string } from 'yup';

const entityNameRegex: RegExp = /^[A-Za-z0-9 '-.]{1,255}$/;

export const entityNameValidator = (entityName: string): Schema => {
  return string()
    .nullable()
    .matches(
      entityNameRegex,
      `${entityName} can only contain alphanumeric characters, spaces, apostrophe, dots or - and must be at least 1 character in length.`
    )
    .required(`Please enter a ${entityName.toLowerCase()}.`);
};
