import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateJourneyCourierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateJourneyCourierInput;
}>;

export type UpdateJourneyCourierMutationResult = { __typename?: 'Mutation' } & {
  updateJourneyCourier: { __typename?: 'UpdateJourneyCourierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateJourneyCourierDocument = /* #__PURE__ */ gql`
  mutation updateJourneyCourier($input: UpdateJourneyCourierInput!) {
    updateJourneyCourier(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateJourneyCourierMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyCourierMutation, { data, loading, error }] = useUpdateJourneyCourierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJourneyCourierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyCourierMutationResult, UpdateJourneyCourierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateJourneyCourierMutationResult, UpdateJourneyCourierMutationVariables>(
    UpdateJourneyCourierDocument,
    options
  );
}
export type UpdateJourneyCourierMutationHookResult = ReturnType<typeof useUpdateJourneyCourierMutation>;
