import type { FC } from 'react';

import LastUpdatedHeadline from '@zen/Components/LastUpdated';
import { Tooltip } from '@zen/DesignSystem';
import type { OrderRichDate, RichDate } from '@zen/Orders/types';

import HistoryItem from './HistoryItem';

const renderTooltipContent = (history: NonNullable<NonNullable<OrderRichDate>['history']>) => {
  const referenceDate = history?.shift()?.date;

  return (
    <div className="w-80 max-h-80 text-grey-dark overflow-y-auto">
      {history.reverse().map((item, i) => (
        <HistoryItem key={i} history={item as RichDate} referenceDate={referenceDate} />
      ))}
    </div>
  );
};

interface Props {
  cargoReadyDate: OrderRichDate;
}

const CargoReadyDateHistory: FC<Props> = ({ cargoReadyDate }) => {
  if (!cargoReadyDate) {
    return null;
  }

  const { date, history, lastUpdated, reasonForChange } = cargoReadyDate;
  const hasHistory = history?.length ? history.length > 0 : false;

  const fullHistory = [...(history || []), { date, history, reasonForChange, lastUpdated }];

  return (
    <div>
      {lastUpdated && <LastUpdatedHeadline lastUpdate={lastUpdated} />}
      {hasHistory && (
        <Tooltip placement="bottom-end" tooltipContent={renderTooltipContent(fullHistory)} trigger="click">
          <div className="inline-block text-xs text-azure-base font-bold cursor-pointer underline">View history of updates</div>
        </Tooltip>
      )}
    </div>
  );
};

export default CargoReadyDateHistory;
