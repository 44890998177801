import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { RadioInput } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { RadioGroupOption } from './types';

interface Props {
  className?: string;
  disabled?: boolean;
  name: string;
  onChange: (value: string) => void;
  options: RadioGroupOption[];
  radioAlignment?: 'row' | 'column';
  radioType?: 'default' | 'tile';
  value: Optional<string>;
}

const RadioGroup: FC<Props> = (props) => {
  const { className, options, radioAlignment, radioType = 'default', value, ...rest } = props;

  const isColumnAlignment: boolean = radioAlignment === 'column';
  const isTileRadioType: boolean = radioType === 'tile';

  const classNames: string = cx(
    {
      'flex-col': isColumnAlignment,
      'space-y-1.5': isColumnAlignment && !isTileRadioType,
      'space-x-2': !isColumnAlignment && !isTileRadioType,
      'divide-x': !isColumnAlignment && isTileRadioType,
      'divide-y': isColumnAlignment && isTileRadioType,
      'border border-solid border-grey-lighter rounded divide-grey-lighter divide-solid': isTileRadioType
    },
    'flex',
    className
  );

  return (
    <div className={classNames} data-component="radio-group" data-testid="radio-group">
      {options.map(
        (option: RadioGroupOption, index: number): ReactNode => (
          <RadioInput
            {...rest}
            key={index}
            checked={option.value === value}
            description={option?.description}
            type={radioType}
            value={option.value}
          >
            {option.label}
          </RadioInput>
        )
      )}
    </div>
  );
};

export default RadioGroup;
