import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ToggleJourneyCollectionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ToggleJourneyCollectionInput;
}>;

export type ToggleJourneyCollectionMutationResult = { __typename?: 'Mutation' } & {
  toggleJourneyCollection: { __typename?: 'ToggleJourneyCollectionPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const ToggleJourneyCollectionDocument = /* #__PURE__ */ gql`
  mutation toggleJourneyCollection($input: ToggleJourneyCollectionInput!) {
    toggleJourneyCollection(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useToggleJourneyCollectionMutation__
 *
 * To run a mutation, you first call `useToggleJourneyCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleJourneyCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleJourneyCollectionMutation, { data, loading, error }] = useToggleJourneyCollectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleJourneyCollectionMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleJourneyCollectionMutationResult, ToggleJourneyCollectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ToggleJourneyCollectionMutationResult, ToggleJourneyCollectionMutationVariables>(
    ToggleJourneyCollectionDocument,
    options
  );
}
export type ToggleJourneyCollectionMutationHookResult = ReturnType<typeof useToggleJourneyCollectionMutation>;
