import * as Yup from 'yup';

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  documents: Yup.array().of(
    Yup.object().shape({
      description: Yup.string().required('Document name is required.'),
      documentType: Yup.string().nullable().required('Document type is required.')
    })
  )
});
