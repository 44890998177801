import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useRole from '@zen/Auth/hooks/useRole';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { Role } from '@zen/Networks';
import { SortingOrder } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';
import useAccount from '@zen/utils/hooks/useAccount';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import { prepareFilterVariables } from '../components/Filters/helpers';
import { OrderListQueryResult, OrderListQueryVariables, useOrderListQuery } from '../graphql';
import type { OrderFilters, OrderListViewItem } from '../types';

interface Params {
  availableForBooking?: string;
  availableForCargo?: string;
  filterByUserPreferences?: boolean;
  onlyOwnAccount?: boolean;
  withLotCountsForCargo?: boolean;
}

const useOrdersListQuery = (
  params: Params = {}
): PaginatedQueryResult<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem> => {
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const role = useRole();
  const {
    availableForBooking,
    availableForCargo,
    filterByUserPreferences,
    onlyOwnAccount = false,
    withLotCountsForCargo = false
  } = params;
  const { accountUuid: accountId } = useAccount();
  const { search } = useLocation();
  const { appliedFilters = {} } = useAppliedFilters<OrderFilters>();
  const { textContains }: QueryParams = parseQueryParams(search);

  useEffect(() => {
    results.refetch();
  }, [filterByUserPreferences]); // eslint-disable-line react-hooks/exhaustive-deps

  const filter =
    createAccountFlagIsOn && role !== Role.ADMIN && !onlyOwnAccount ? { accountIds: [] } : { customerUuid: accountId };

  const results = usePagination<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem>(
    useOrderListQuery,
    'purchaseOrders',
    {
      ...prepareFilterVariables(appliedFilters),
      ...(accountId && { ...filter }),
      availableForBooking,
      availableForCargo,
      textContains: textContains ? textContains.toString() : '',
      order: {
        field: 'createdAt',
        direction: SortingOrder.DESC
      },
      withLotCountsForCargo
    },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  return results;
};

export default useOrdersListQuery;
