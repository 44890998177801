import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

type CollapsibleElementConfiguration = [boolean, (isExpanded: boolean) => void];

export const useCollapsibleElement = (
  elementKeyName: string,
  isExpandedByDefault: boolean = false,
  localStorageKeyName: string
): CollapsibleElementConfiguration => {
  const [isExpanded, saveIsExpanded] = useLocalStorage<Record<string, boolean>>(localStorageKeyName, {});
  const isElementExpanded: boolean =
    isExpanded && elementKeyName in isExpanded ? isExpanded[elementKeyName] : isExpandedByDefault;

  const setIsExpanded = useCallback(
    (expanded: boolean) => {
      const storedValue: Record<string, any> = JSON.parse(localStorage.getItem(localStorageKeyName) || '');

      saveIsExpanded({
        ...storedValue,
        [elementKeyName]: expanded
      });
    },
    [elementKeyName, saveIsExpanded, localStorageKeyName]
  );

  return [isElementExpanded, setIsExpanded];
};
