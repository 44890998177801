import type { FC, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import { Icon } from '@zen/DesignSystem';
import { accrualRoutes } from '@zen/Routes';

interface Props {
  accountId: string;
  zencargoReference: string;
}

const AccrualsLink: FC<Props> = ({ accountId, zencargoReference }) => {
  const handleClick = (event: SyntheticEvent): void => event.stopPropagation();

  return (
    <Link
      className="flex items-baseline pb-2 -mb-2 space-x-2"
      onClick={handleClick}
      target="_blank"
      to={accrualRoutes.accrualIndex.getUrl(accountId, zencargoReference)}
    >
      <span>V&As</span> <Icon className="text-sm text-navy-base" icon="zicon-diagonal-arrow" />
    </Link>
  );
};

export default AccrualsLink;
