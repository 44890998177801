import type { FC } from 'react';

import { Form } from '@zen/Components/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props {
  handleSubmit: (values: { reason: string }) => void;
  reasonDescription: string;
}

const OtherRejectReasonForm: FC<Props> = (props) => {
  const { reasonDescription } = props;

  const onSubmit = async (values: any): Promise<IOkOrErrorResult> => {
    return Promise.resolve({ ok: { data: values }, error: null });
  };

  return (
    <div className="py-2">
      <Form
        formButtons={reasonDescription ? () => null : undefined}
        initialValues={{ reason: reasonDescription || '' }}
        onSubmit={onSubmit}
        onSuccess={props.handleSubmit}
      >
        {() => <FormTextarea disabled={!!reasonDescription} label="Can you please explain your reason..." name="reason" />}
      </Form>
    </div>
  );
};

export default OtherRejectReasonForm;
