import { pick } from 'lodash';
import type { FC, ReactNode } from 'react';

import { useActivityFeedItemPermissions } from '@zen/ActivityFeed/ActivityFeedItem/context/ActivityFeedItemPermissionsContext';
import { usePermittedParties } from '@zen/ActivityFeed/context/PermittedPartiesContext';
import { Icon, Tooltip } from '@zen/DesignSystem';

import type { Permissions } from '../../types';
import PermittedParties from '../PermissionParties';

interface Props {
  withTooltip?: boolean;
}

const ActivityFeedVisibleParties: FC<Props> = ({ withTooltip = false }) => {
  const permissions = useActivityFeedItemPermissions();
  const permittedParties = usePermittedParties();

  const keysToPick = Object.keys(permittedParties).filter((key: string) => permissions[key as keyof Permissions]);

  // zencargo is always allowed to see message
  keysToPick.push('forwarder');

  const permittedPartiesToViewMessage = pick(permittedParties, keysToPick);
  const isPrivate = keysToPick.length === 1;
  const privateMessage: string = 'Only visible to the Zencargo team';

  if (withTooltip) {
    const tooltipContent: ReactNode = isPrivate ? (
      <div data-testid="tooltip-private-message">{privateMessage}</div>
    ) : (
      <PermittedParties parties={permittedPartiesToViewMessage} />
    );

    return (
      <div className="text-grey-base">
        <Tooltip placement="bottom" tooltipContent={tooltipContent}>
          <Icon className="text-base text-grey-light hover:text-grey-dark" icon="zicon-visible" />
        </Tooltip>
      </div>
    );
  }

  if (isPrivate) {
    return <div className="px-6 py-4">{privateMessage}</div>;
  }

  return <PermittedParties parties={permittedPartiesToViewMessage} />;
};

export default ActivityFeedVisibleParties;
