import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCurrentBookingCoordinatorsQueryVariables = GraphQLTypes.Exact<{
  zencargoReferences?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type GetCurrentBookingCoordinatorsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              operationsManager?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksOrgLocContact' } & Pick<
                  GraphQLTypes.NetworksOrgLocContact,
                  'id' | 'firstName' | 'lastName'
                >
              >;
              commercialManager?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksOrgLocContact' } & Pick<
                  GraphQLTypes.NetworksOrgLocContact,
                  'id' | 'firstName' | 'lastName'
                >
              >;
            }
        >
      >
    >;
  };
};

export const GetCurrentBookingCoordinatorsDocument = /* #__PURE__ */ gql`
  query getCurrentBookingCoordinators($zencargoReferences: [String!]) {
    bookings(zencargoReferences: $zencargoReferences) {
      nodes {
        zencargoReference
        operationsManager {
          id
          firstName
          lastName
        }
        commercialManager {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useGetCurrentBookingCoordinatorsQuery__
 *
 * To run a query within a React component, call `useGetCurrentBookingCoordinatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentBookingCoordinatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentBookingCoordinatorsQuery({
 *   variables: {
 *      zencargoReferences: // value for 'zencargoReferences'
 *   },
 * });
 */
export function useGetCurrentBookingCoordinatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentBookingCoordinatorsQueryResult, GetCurrentBookingCoordinatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCurrentBookingCoordinatorsQueryResult, GetCurrentBookingCoordinatorsQueryVariables>(
    GetCurrentBookingCoordinatorsDocument,
    options
  );
}
export function useGetCurrentBookingCoordinatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentBookingCoordinatorsQueryResult, GetCurrentBookingCoordinatorsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCurrentBookingCoordinatorsQueryResult, GetCurrentBookingCoordinatorsQueryVariables>(
    GetCurrentBookingCoordinatorsDocument,
    options
  );
}
export type GetCurrentBookingCoordinatorsQueryHookResult = ReturnType<typeof useGetCurrentBookingCoordinatorsQuery>;
export type GetCurrentBookingCoordinatorsLazyQueryHookResult = ReturnType<typeof useGetCurrentBookingCoordinatorsLazyQuery>;
