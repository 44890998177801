import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteWebhookMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteWebhookInput;
}>;

export type DeleteWebhookMutationResult = { __typename?: 'Mutation' } & {
  webhookSubscriptionsDeleteWebhook?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteWebhookPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const DeleteWebhookDocument = /* #__PURE__ */ gql`
  mutation deleteWebhook($input: DeleteWebhookInput!) {
    webhookSubscriptionsDeleteWebhook(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useDeleteWebhookMutation__
 *
 * To run a mutation, you first call `useDeleteWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebhookMutation, { data, loading, error }] = useDeleteWebhookMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteWebhookMutationResult, DeleteWebhookMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteWebhookMutationResult, DeleteWebhookMutationVariables>(DeleteWebhookDocument, options);
}
export type DeleteWebhookMutationHookResult = ReturnType<typeof useDeleteWebhookMutation>;
