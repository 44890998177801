import cx from 'classnames';
import type { FC } from 'react';

import TransportModeIcon from '@zen/Components/TransportModeIcon';
import type { JourneyModeOfTransportEnum, JourneyStopReference, JourneyVehicleProperty } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import PathIndicator from '../PathIndicator';
import StopReferences from '../StopReferences';
import TransitTime from '../TransitTime';
import VehicleProperties from '../VehicleProperties';

interface Props {
  className?: string;
  isCompleted: boolean;
  isInTransit: boolean;
  isVisible: boolean;
  modeOfTransport: JourneyModeOfTransportEnum;
  stopReferences: JourneyStopReference[];
  transitTimeInMinutes: Optional<number>;
  vehicleId: string;
  vehicleProperties: JourneyVehicleProperty[];
}

const InTransitMilestone: FC<Props> = (props) => {
  const {
    className,
    isCompleted,
    isInTransit,
    isVisible,
    modeOfTransport,
    stopReferences,
    transitTimeInMinutes,
    vehicleId,
    vehicleProperties
  } = props;

  const classNames: string = cx(
    { 'border-grey-lighter': isVisible, 'border-white': !isVisible },
    'flex items-start transition-all duration-200',
    className
  );

  return (
    <div className={classNames} data-testid="in-transit-milestone">
      <div className="flex justify-end w-48 shrink-0">
        {transitTimeInMinutes && <TransitTime timeInMinutes={transitTimeInMinutes} />}
      </div>
      <PathIndicator isCompleted={isCompleted} isCurrent={isInTransit} pathIndicatorVisible={isVisible} />
      <div className="w-full min-w-0">
        <div className="flex items-center flex-1 text-base">
          {modeOfTransport && <TransportModeIcon className="mr-2 text-2xl" mode={modeOfTransport} />}
          <span className="font-bold">Transit</span>
        </div>
        <div className="[&>*]:border-t [&>*]:border-solid [&>*]:border-grey-lighter [&>*]:mt-2 [&>*:first-child]:border-none [&>*:first-child]:mt-0">
          <StopReferences stopReferences={stopReferences} />
          <VehicleProperties properties={vehicleProperties} vehicleId={vehicleId} />
        </div>
      </div>
    </div>
  );
};

export default InTransitMilestone;
