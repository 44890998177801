export const downloadFile = (name: string, blob: Blob): void => {
  const a = document.createElement('a');

  a.href = URL.createObjectURL(blob);

  a.download = name;
  document.body.append(a);
  a.click();
  document.body.removeChild(a);
};
