import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateStopLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateStopLocationInput;
}>;

export type UpdateStopLocationMutationResult = { __typename?: 'Mutation' } & {
  updateStopLocation: { __typename?: 'UpdateStopLocationPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateStopLocationDocument = /* #__PURE__ */ gql`
  mutation updateStopLocation($input: UpdateStopLocationInput!) {
    updateStopLocation(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateStopLocationMutation__
 *
 * To run a mutation, you first call `useUpdateStopLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStopLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStopLocationMutation, { data, loading, error }] = useUpdateStopLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStopLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateStopLocationMutationResult, UpdateStopLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateStopLocationMutationResult, UpdateStopLocationMutationVariables>(
    UpdateStopLocationDocument,
    options
  );
}
export type UpdateStopLocationMutationHookResult = ReturnType<typeof useUpdateStopLocationMutation>;
