import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersUpdateLotEstimatesMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateLotEstimatesInput;
}>;

export type PurchaseOrdersUpdateLotEstimatesMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersUpdateLotEstimates?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateLotEstimatesPayload' } & {
      purchaseOrder?: GraphQLTypes.Maybe<
        { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'> & {
            cargoReadyDateEstimate?: GraphQLTypes.Maybe<
              { __typename?: 'PurchaseOrderEstimatedDateType' } & Pick<
                GraphQLTypes.PurchaseOrderEstimatedDateType,
                'date' | 'delayInDays' | 'lastUpdatedAt'
              >
            >;
            deliveryEstimate?: GraphQLTypes.Maybe<
              { __typename?: 'PurchaseOrderEstimatedDateType' } & Pick<
                GraphQLTypes.PurchaseOrderEstimatedDateType,
                'date' | 'delayInDays' | 'lastUpdatedAt'
              >
            >;
          }
      >;
      lots?: GraphQLTypes.Maybe<
        Array<
          { __typename?: 'Lot' } & Pick<
            GraphQLTypes.Lot,
            'atRisk' | 'cbm' | 'estimatedDeliveryDate' | 'fulfilled' | 'id' | 'quantityFulfilled'
          > & {
              booking?: GraphQLTypes.Maybe<
                { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
                    stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
                  }
              >;
              cargoReadyDate?: GraphQLTypes.Maybe<
                { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                    history?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                            lastUpdated?: GraphQLTypes.Maybe<
                              { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                  updatedBy?: GraphQLTypes.Maybe<
                                    { __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username' | 'zencargoEmployee'>
                                  >;
                                }
                            >;
                            reasonForChange?: GraphQLTypes.Maybe<
                              { __typename?: 'ReasonForChange' } & Pick<GraphQLTypes.ReasonForChange, 'category' | 'description'>
                            >;
                          }
                      >
                    >;
                    lastUpdated?: GraphQLTypes.Maybe<
                      { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                          updatedBy?: GraphQLTypes.Maybe<
                            { __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username' | 'zencargoEmployee'>
                          >;
                        }
                    >;
                    reasonForChange?: GraphQLTypes.Maybe<
                      { __typename?: 'ReasonForChange' } & Pick<GraphQLTypes.ReasonForChange, 'category' | 'description'>
                    >;
                  }
              >;
              currentStage?: GraphQLTypes.Maybe<{ __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'name'>>;
              purchaseOrder?: GraphQLTypes.Maybe<{ __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>>;
              stages?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'ProgressStatus' } & Pick<
                    GraphQLTypes.ProgressStatus,
                    'currentStage' | 'label' | 'name' | 'statusType'
                  > & {
                      date?: GraphQLTypes.Maybe<
                        { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'> & {
                            history?: GraphQLTypes.Maybe<
                              Array<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'>>
                            >;
                          }
                      >;
                    }
                >
              >;
            }
        >
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const PurchaseOrdersUpdateLotEstimatesDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersUpdateLotEstimates($input: UpdateLotEstimatesInput!) {
    purchaseOrdersUpdateLotEstimates(input: $input) {
      purchaseOrder {
        id
        cargoReadyDateEstimate {
          date
          delayInDays
          lastUpdatedAt
        }
        deliveryEstimate {
          date
          delayInDays
          lastUpdatedAt
        }
      }
      lots {
        atRisk
        booking {
          stage {
            value
          }
          zencargoReference
        }
        cargoReadyDate {
          date
          history {
            date
            lastUpdated {
              updatedAt
              updatedBy {
                username
                zencargoEmployee
              }
            }
            reasonForChange {
              category
              description
            }
          }
          lastUpdated {
            updatedAt
            updatedBy {
              username
              zencargoEmployee
            }
          }
          reasonForChange {
            category
            description
          }
        }
        cbm
        currentStage {
          name
        }
        estimatedDeliveryDate
        fulfilled
        id
        purchaseOrder {
          id
        }
        quantityFulfilled
        stages {
          currentStage
          date {
            confidenceLevel
            date
            history {
              confidenceLevel
              date
            }
          }
          label
          name
          statusType
        }
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __usePurchaseOrdersUpdateLotEstimatesMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersUpdateLotEstimatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersUpdateLotEstimatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersUpdateLotEstimatesMutation, { data, loading, error }] = usePurchaseOrdersUpdateLotEstimatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersUpdateLotEstimatesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseOrdersUpdateLotEstimatesMutationResult,
    PurchaseOrdersUpdateLotEstimatesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersUpdateLotEstimatesMutationResult, PurchaseOrdersUpdateLotEstimatesMutationVariables>(
    PurchaseOrdersUpdateLotEstimatesDocument,
    options
  );
}
export type PurchaseOrdersUpdateLotEstimatesMutationHookResult = ReturnType<typeof usePurchaseOrdersUpdateLotEstimatesMutation>;
