import { keyBy, mapValues } from 'lodash';

import type { TabConfig } from '@zen/DesignSystem';
import type { Undefinable } from '@zen/utils/typescript';

import type { EnhancedTabViewConfig, TabViewConfig } from './types';

export const enhanceTabViewConfig = (tabViewConfig: TabViewConfig[], tabsIdentifier: string): EnhancedTabViewConfig[] =>
  tabViewConfig.map((tab: TabViewConfig, index: number) => ({
    ...tab,
    value: `${tabsIdentifier}-${index}`
  }));

export const prepareComponentMapping = (tabViewConfig: EnhancedTabViewConfig[]) => {
  const tabConfigByLabel = keyBy(tabViewConfig, (config: EnhancedTabViewConfig) => config.value);

  return mapValues(tabConfigByLabel, (value: EnhancedTabViewConfig) => value.component);
};

export const prepareTabsConfig = (tabViewConfig: EnhancedTabViewConfig[], activeTab?: string): TabConfig[] => {
  return tabViewConfig.map(({ label, totalCount, value }: EnhancedTabViewConfig): TabConfig => {
    return {
      isActive: value === activeTab,
      value,
      totalCount,
      label
    };
  });
};

export const getDefaultTab = (tabViewConfig: EnhancedTabViewConfig[]): string => {
  const defaultTab: Undefinable<string> = tabViewConfig.find((tabView: EnhancedTabViewConfig) => tabView.isDefault)?.value;
  const firstTab: string = tabViewConfig[0]?.value || '';

  return defaultTab || firstTab;
};
