import type { IconButtonSize } from './types';

interface IconButtonConfig {
  buttonClassnames: string;
  iconClassnames: string;
}

const config: Record<IconButtonSize, IconButtonConfig> = {
  small: {
    buttonClassnames: 'w-5 h-5 rounded-sm',
    iconClassnames: 'text-xs'
  },
  medium: {
    buttonClassnames: 'w-8 h-8 rounded',
    iconClassnames: 'text-base'
  },
  large: {
    buttonClassnames: 'w-10 h-10 rounded',
    iconClassnames: 'text-base'
  }
};

export const getStyles = (size: IconButtonSize): IconButtonConfig => {
  return config[size];
};
