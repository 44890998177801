import { array, number, object, ObjectSchema, string } from 'yup';

export const validationSchema: ObjectSchema<{}> = object().shape({
  orderReferenceNumber: string().nullable().required('Order number is required.'),
  orderedLineItems: array().of(
    object().shape({
      cbm: number().nullable().positive('Must be greater than 0.'),
      product: object().nullable().required('Product name/SKU is required.'),
      quantityOrdered: number()
        .integer('Qty must be whole number.')
        .typeError('Qty is required.')
        .positive('Qty is required.')
        .required('Qty is required.')
    })
  )
});
