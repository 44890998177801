import type { FC } from 'react';

import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import { Icon } from '@zen/DesignSystem';
import type { QuoteOption } from '@zen/Quotes';

import type { Activity } from '../../../types';

interface Props {
  activityMetadata: Activity;
  quoteOption: QuoteOption;
}

const QuoteOptionAutomaticallyRejected: FC<Props> = ({ quoteOption: { quoteReference }, activityMetadata }) => {
  return (
    <ActivityFeedItemWrapper>
      <ActivityFeedItemDetails
        createdAt={activityMetadata.createdAt}
        icon={<Icon className="text-2xl bg-white text-red-dark" icon="zicon-cross-oval" />}
        subtitle={
          <span className="text-grey-base">
            Quote option <b className="font-bold text-grey-dark"> {quoteReference} </b>
            has now been rejected because a new one has been accepted.
          </span>
        }
        title="Automatically rejected quote option"
      />
    </ActivityFeedItemWrapper>
  );
};

export default QuoteOptionAutomaticallyRejected;
