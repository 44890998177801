import type { FC } from 'react';

import { Button, Icon } from '@zen/DesignSystem';

const NoDateForm: FC = () => {
  return (
    <div>
      <div className="flex items-baseline p-6 space-x-2 text-sm">
        <Icon icon="zicon-information" />
        <span>Calculated dates are based on previous milestones and presented to the customer.</span>
      </div>
      <div className="flex justify-end px-6 py-4 border-t border-solid border-grey-lighter">
        <Button disabled={true}>Change to no date</Button>
      </div>
    </div>
  );
};

export default NoDateForm;
