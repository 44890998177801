import type { FC, ReactNode } from 'react';
import { useMeasure } from 'react-use';

import CollapsibleSection from '@zen/Components/CollapsibleSection';

import LabelledValue from '../LabelledValue';

interface Props {
  className?: string;
  isCollapsible?: boolean;
  text: string;
}

const SpecialInstructions: FC<Props> = ({ className, isCollapsible = true, text }) => {
  const [wrapperRef, { height }] = useMeasure<HTMLDivElement>();

  const specialInstructions: ReactNode = (
    <div ref={wrapperRef} className="pt-4 mt-auto whitespace-pre-wrap">
      <LabelledValue label="Special instructions">
        <div className="mt-2 text-sm">{text}</div>
      </LabelledValue>
    </div>
  );

  const renderSpecialInstructions = (): ReactNode => {
    const twoLineHeight: number = 100;

    if (height > twoLineHeight && isCollapsible) {
      return (
        <CollapsibleSection
          collapseLabel="Hide full special instruction"
          expandLabel="Show full special instruction"
          minDisplayedHeight={twoLineHeight}
        >
          {specialInstructions}
        </CollapsibleSection>
      );
    }

    return specialInstructions;
  };

  return <div className={className}>{renderSpecialInstructions()}</div>;
};

export default SpecialInstructions;
