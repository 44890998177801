import { CostTrackingCostItemStatus, CostTrackingRevenueItemStatus } from '../../types';
import type { ChargeItemStatus } from '../types';

export const chargeItemStatusLabelMapping: Record<ChargeItemStatus, string> = {
  [CostTrackingCostItemStatus.APPROVED]: 'Approved',
  [CostTrackingCostItemStatus.CREDITED]: 'Credited',
  [CostTrackingCostItemStatus.DRAFT]: 'Draft',
  [CostTrackingCostItemStatus.MATCHED]: 'Matched',
  [CostTrackingRevenueItemStatus.INVOICED]: 'Invoiced'
};
