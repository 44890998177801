import type { FC } from 'react';

import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import type { Activity } from '@zen/ActivityFeed/types';

import ActivityFeedItemDetails from '../../components/ActivityFeedItemDetails';
import ActivityFeedItemWrapper from '../../components/ActivityFeedItemWrapper';

interface Props {
  activityMetaData: Activity;
}

const RejectedBookingResubmitForApproval: FC<Props> = ({ activityMetaData }) => {
  const { createdAt, itemId, legacyUser } = activityMetaData;

  return (
    <ActivityFeedItemWrapper>
      <div className="flex items-center justify-between">
        <ActivityFeedItemDetails
          createdAt={createdAt}
          icon={<ActivityFeedItemIcon color="text-navy-base" />}
          title="Booking re-submitted for approval"
          user={legacyUser}
        />
        <p className="font-bold">{itemId}</p>
      </div>
    </ActivityFeedItemWrapper>
  );
};

export type { Props as RejectedBookingResubmitForApprovalProps };
export default RejectedBookingResubmitForApproval;
