import type { FocusEvent } from 'react';

import type { FormInstance } from '@zen/Components';
import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';
import { MultiText, MultiTextProps } from '@zen/DesignSystem';

type Props = Omit<MultiTextProps, 'onChange' | 'value'> & FormFieldProps & { name: string };

const FormMultiText = (props: Props) => {
  const { className, onBlur, ...rest } = props;

  return (
    <FormField {...props} hasError={true}>
      {(field: { error: boolean; name: string; value: string[] }, form: FormInstance<unknown>) => {
        const handleChange = (value: string[]): void => {
          form.setFieldTouched(field.name);
          form.setFieldValue(field.name, value);
        };

        // we need to do this ourselves because of: https://github.com/JedWatson/react-select/issues/4416#issuecomment-770976373
        const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
          form.setFieldTouched(field.name);

          if (onBlur) {
            onBlur(event);
          }
        };

        return (
          <MultiText
            {...rest}
            {...field}
            hasError={field.error}
            onBlur={handleBlur}
            onChange={handleChange}
            value={field.value}
          />
        );
      }}
    </FormField>
  );
};

export default FormMultiText;
