import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateShipmentActionTypeMutation } from '../graphql';
import ShipmentActionTypeForm from '../ShipmentActionTypeForm';
import type { ShipmentActionTypeFormValues } from '../ShipmentActionTypeForm/types';
import type { ShipmentActionType } from '../ShipmentActionTypesList/types';

interface Props {
  item: ShipmentActionType;
  onClose: () => void;
}

const EditShipmentActionType: FC<Props> = ({ item, onClose }) => {
  const [updateShipmentActionType] = useUpdateShipmentActionTypeMutation();

  const { addError, addSuccess } = useNotification();

  const handleSuccess = (): void => {
    addSuccess('Shipment action type updated.');
    onClose();
  };

  const handleUpdateShipmentActionType = (values: ShipmentActionTypeFormValues): Promise<IOkOrErrorResult> => {
    const { id } = item;

    return performFormMutation({
      mutationFn: () =>
        updateShipmentActionType({
          variables: {
            input: { id, ...values }
          }
        }),
      onError: () => addError()
    });
  };

  return (
    <ShipmentActionTypeForm
      buttonLabel="Save"
      initialValues={item}
      onCancel={onClose}
      onSubmit={handleUpdateShipmentActionType}
      onSuccess={handleSuccess}
    />
  );
};

export default EditShipmentActionType;
