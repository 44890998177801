import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingBaseDataQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type BookingBaseDataQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'accountUuid' | 'modeOfTransport' | 'zencargoReference'> & {
              costTracking?: GraphQLTypes.Maybe<
                { __typename?: 'CostTracking' } & Pick<GraphQLTypes.CostTracking, 'costTrackingId'>
              >;
            }
        >
      >
    >;
  };
};

export const BookingBaseDataDocument = /* #__PURE__ */ gql`
  query bookingBaseData($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        costTracking {
          costTrackingId
        }
        modeOfTransport
        zencargoReference
      }
    }
  }
`;

/**
 * __useBookingBaseDataQuery__
 *
 * To run a query within a React component, call `useBookingBaseDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingBaseDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingBaseDataQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useBookingBaseDataQuery(
  baseOptions: Apollo.QueryHookOptions<BookingBaseDataQueryResult, BookingBaseDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingBaseDataQueryResult, BookingBaseDataQueryVariables>(BookingBaseDataDocument, options);
}
export function useBookingBaseDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingBaseDataQueryResult, BookingBaseDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingBaseDataQueryResult, BookingBaseDataQueryVariables>(BookingBaseDataDocument, options);
}
export type BookingBaseDataQueryHookResult = ReturnType<typeof useBookingBaseDataQuery>;
export type BookingBaseDataLazyQueryHookResult = ReturnType<typeof useBookingBaseDataLazyQuery>;
