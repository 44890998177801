import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useActivateQuoteOptionMutation, useUpdateQuoteOptionDescriptionMutation } from '../graphql';
import ActiveQuoteOptionButtons from '../LegacyQuote/LegacyQuoteOption/ActiveQuoteOptionButtons';
import RejectReasonContainer from '../LegacyQuote/LegacyQuoteOption/RejectReason/RejectReasonContainer';
import { QuoteOption, QuoteOptionState } from '../types';
import ActivateQuoteForm from './ActivateQuoteForm';
import type { ActivateQuoteFormSubmitData } from './ActivateQuoteForm/ActivateQuoteForm';
import ArchiveQuoteOptionButton from './ArchiveQuoteOptionButton';
import DescriptionForm from './DescriptionForm';
import type { DescriptionFormData } from './DescriptionForm/DescriptionForm';
import QuoteOptionItem from './QuoteOptionItem';

interface Props {
  accountId: string;
  handleAction: (quoteOption: QuoteOption) => void;
  isAccepted?: boolean;
  isSelected: boolean;
  onSuccess: () => void;
  quoteOption: QuoteOption;
  setSelectedQuoteOption: (id: string) => void;
}

const QuoteOptionComponent: FC<Props> = (props) => {
  const { accountId, quoteOption, onSuccess, handleAction, isAccepted } = props;
  const { zencargoReference } = quoteOption;
  const { addError } = useNotification();

  const [submitActivateQuoteOption] = useActivateQuoteOptionMutation();
  const [updateQuoteDescription] = useUpdateQuoteOptionDescriptionMutation();

  const handleDescriptionSubmit = async (data: DescriptionFormData): Promise<IOkOrErrorResult> => {
    const input = {
      quoteOptionId: data.id,
      description: data.description
    };

    return performFormMutation({
      mutationFn: () => updateQuoteDescription({ variables: { input } }),
      onError: (): void => {
        addError('Unable to update description.');
      }
    });
  };

  const onSubmitActivate = async (values: ActivateQuoteFormSubmitData): Promise<IOkOrErrorResult> => {
    const input = {
      quoteOptionId: values.id,
      expiryDate: values.expiryDate.date
    };

    return performFormMutation({
      mutationFn: () => submitActivateQuoteOption({ variables: { input } }),
      onError: (): void => {
        addError('Unable to activate quote option.');
      }
    });
  };

  const onQuoteOptionArchived = (state: QuoteOptionState): void => {
    const updatedQuoteOption: QuoteOption = {
      ...quoteOption,
      state
    };

    handleAction(updatedQuoteOption);
  };

  const isReadOnly = quoteOption.state !== QuoteOptionState.DRAFT;
  const isActive = quoteOption.state === QuoteOptionState.ACTIVE;
  const isRejected = quoteOption.state === QuoteOptionState.REJECTED;
  const isArchived = quoteOption.state === QuoteOptionState.ARCHIVED;
  const quoteOptionId = quoteOption.id || '';
  const { description, expiryDate } = quoteOption;
  const isSelected = props.isSelected || quoteOption.state === QuoteOptionState.ACCEPTED;

  return (
    <div key={quoteOptionId} onClick={() => props.setSelectedQuoteOption(quoteOptionId)}>
      <QuoteOptionItem {...{ accountId, isSelected, quoteOption }}>
        <div className="flex py-4">
          <div className="pr-4">
            <ActivateQuoteForm
              initialValues={{ expiryDate, id: quoteOptionId, zencargoReference }}
              isReadOnly={isReadOnly}
              onSubmit={onSubmitActivate}
              onSuccess={onSuccess}
            />
          </div>
          <div className="flex mt-6">
            {isActive && <ActiveQuoteOptionButtons handleAction={handleAction} quoteOptionId={quoteOptionId} />}
            {!isArchived && <ArchiveQuoteOptionButton handleSuccess={onQuoteOptionArchived} quoteOptionId={quoteOptionId} />}
          </div>
        </div>
        <DescriptionForm
          initialValues={{ description: description || '', id: quoteOptionId, zencargoReference }}
          isReadOnly={isReadOnly}
          onSubmit={handleDescriptionSubmit}
          onSuccess={onSuccess}
        />
        {isRejected && !isAccepted && (
          <div className="mt-4">
            <RejectReasonContainer onReject={handleAction} quoteOption={quoteOption} />
          </div>
        )}
      </QuoteOptionItem>
    </div>
  );
};

export default QuoteOptionComponent;
