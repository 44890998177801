import { FC, useState } from 'react';

import OriginAndDestination from '@zen/Booking/OriginAndDestination';
import { ContextMenu, MenuItemType, Modal } from '@zen/DesignSystem';

interface Props {
  zencargoReference: string;
}

const BookingActions: FC<Props> = ({ zencargoReference }) => {
  const [showOriginDestinationModal, setShowOriginDestinationModal] = useState<boolean>(false);

  const contextMenuItems: MenuItemType[] = [
    {
      label: 'Edit origin and destination',
      onClick: () => setShowOriginDestinationModal(true),
      icon: 'zicon-network'
    }
  ];

  return (
    <>
      <ContextMenu items={contextMenuItems} />
      <Modal
        isOpen={showOriginDestinationModal}
        modalOverflowY="visible"
        onClose={() => setShowOriginDestinationModal(false)}
        title="Edit origin and destination"
      >
        <OriginAndDestination onCancel={() => setShowOriginDestinationModal(false)} zencargoReference={zencargoReference} />
      </Modal>
    </>
  );
};

export default BookingActions;
