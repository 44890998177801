import { FC, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useRole from '@zen/Auth/hooks/useRole';
import { Button, Dialog, Popover } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { Role } from '@zen/Networks';
import { newBookingRoutes } from '@zen/Routes';
import type { LocationDescriptor } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import OptionList from '../Filters/FilterComponents/components/OptionList';
import type { OptionListSection } from '../Filters/FilterComponents/components/OptionList/types';
import ChevronIcon from '../Icons/ChevronIcon';
import { getOrdersUrl } from './bookingButtonHelper';

interface Props {
  canRequestBooking?: boolean;
}

const BookingButton: FC<Props> = ({ canRequestBooking = false }) => {
  const location = useLocation();
  const role = useRole();
  const { referencePrefix } = useAccount();
  const { navigate } = useNavigationHistory();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);

  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');

  const toggleModal = (): void => setIsModalVisible(!isModalVisible);

  const handleClick = (url: LocationDescriptor): void => {
    if (!referencePrefix && createAccountFlagIsOn && role !== Role.AGENT_FORWARDER) {
      return toggleModal();
    }

    navigate(url);
  };

  const handleConfirm = (): void => {
    window.open('mailto:sales@zencargo.com');
    toggleModal();
  };

  const newBookingUrl: string = newBookingRoutes.newBookingIndex.getUrl();

  const options: Array<OptionListSection> = canRequestBooking
    ? [
        {
          title: "I'm the customer",
          items: [
            { label: 'Place a booking', onClick: () => handleClick(`${newBookingUrl}?onlyOwnAccount=true`) },
            { label: 'Book a purchase order', onClick: () => handleClick(getOrdersUrl(location, true)) }
          ]
        },
        {
          title: "I'm the supplier",
          items: [{ label: 'Fulfil a purchase order', onClick: () => navigate(getOrdersUrl(location)) }]
        }
      ]
    : [
        {
          items: [
            {
              label: 'Book a PO',
              onClick: () => navigate(getOrdersUrl(location))
            },
            {
              label: 'Book without PO',
              onClick: () => navigate(newBookingUrl)
            }
          ]
        }
      ];

  return (
    <>
      <Popover
        onVisibilityChange={() => setIsPopoverVisible(!isPopoverVisible)}
        popover={<OptionList list={options} />}
        popoverClassNames="border border-solid border-grey-lighter"
        renderInPortal={true}
      >
        <Button
          className="focus:outline-none"
          data-testid="booking-button"
          iconRight={<ChevronIcon className="text-lg" expanded={isPopoverVisible} size="small" />}
          variant="secondary"
        >
          New booking
        </Button>
      </Popover>
      <Dialog
        cancelLabel="Not now"
        confirmLabel="Contact Sales"
        header="Contact Sales to enable self-shipping"
        isOpen={isModalVisible}
        message="To initiate shipping your own orders, please contact our sales team. We require additional information to ensure smooth processing and support for your shipping needs."
        onClose={toggleModal}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default BookingButton;
