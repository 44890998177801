import { toNumber } from 'lodash';

import type { Nullable, Optional } from '@zen/utils/typescript';

import type { TableCellInteractionProps, TableStylesAction, UpdateTableRowStylesProps } from './types';

const updateTableRowsStyles = (props: UpdateTableRowStylesProps) => {
  const { action, className, firstRowIndex, lastRowIndex, tableId } = props;
  const table: Nullable<HTMLTableRowElement> = document.querySelector(`div.${tableId}`);

  if (!table) {
    return;
  }

  const rows: NodeListOf<HTMLTableRowElement> = table.querySelectorAll('tr.zen-table-row');

  for (let index = firstRowIndex; index <= lastRowIndex; index++) {
    if (rows[index]) {
      rows[index]?.classList[action](className);
    }
  }
};

export const handleTableCellInteraction = (props: TableCellInteractionProps): void => {
  const { element, interactionType, rowIndex, tableId } = props;
  const rowSpanAttributeValue: Optional<string> = element?.getAttribute('rowspan');

  if (!rowSpanAttributeValue) {
    return;
  }

  const firstRowIndex: number = rowIndex + 1;
  const lastRowIndex: number = rowIndex + toNumber(rowSpanAttributeValue) - 1;
  const action: TableStylesAction = interactionType === 'hover' ? 'add' : 'remove';

  updateTableRowsStyles({ action, className: 'rowspan-group', firstRowIndex, lastRowIndex, tableId });
};
