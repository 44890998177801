import { get } from 'lodash';
import type { FC, ReactNode } from 'react';

import type { FormInstance } from '@zen/Components/Form';
import { Checkbox } from '@zen/DesignSystem';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends FormFieldProps {
  disabled?: boolean;
  hideCheckboxLabel?: boolean;
  onChange?: (name: string, value: boolean) => void;
}

const FormCheckbox: FC<Props> = (props) => {
  const { label, onChange, hideCheckboxLabel = false } = props;

  return (
    <FormField {...props} hideLabel={true} noMargin={true}>
      {(field: { name: string }, form: FormInstance<unknown>) => {
        const { name } = field;
        const checked = get(form.values, name);
        const formattedLabel: ReactNode = hideCheckboxLabel ? undefined : label || removeSpecialCharactersAndUpperFirst(name);

        return (
          <div className="leading-none">
            <Checkbox
              {...field}
              checked={checked}
              label={formattedLabel}
              name={name}
              onChange={() => {
                const value: boolean = !checked;

                form.setFieldValue(name, value);

                if (onChange) {
                  onChange(name, value);
                }
              }}
            />
          </div>
        );
      }}
    </FormField>
  );
};

export default FormCheckbox;
