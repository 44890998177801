import type { Document, DocumentQueryResult } from '@zen/Components/Documents/types';
import type { Undefinable } from '@zen/utils/typescript';

import type { GetBookingDocumentsQueryResult } from './graphql';

export const getFormattedDocuments = (documentsData: Undefinable<GetBookingDocumentsQueryResult>): Undefinable<Document[]> => {
  if (documentsData?.bookings?.nodes?.[0]?.bookingDocuments) {
    const documents = documentsData.bookings.nodes[0].bookingDocuments as DocumentQueryResult[];

    return documents.map((document) => ({
      ...document,
      createdAt: document.createdAt.dateTime,
      uuid: document.id
    }));
  }
};
