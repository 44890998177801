import cx from 'classnames';
import { isNil } from 'lodash';
import type { FC, TextareaHTMLAttributes } from 'react';

import HelperText from '@zen/Components/HelperText';

import { useInputStyles } from '../hooks/useInputStyles';

interface Props
  extends Pick<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    'autoFocus' | 'disabled' | 'name' | 'onBlur' | 'onChange' | 'onFocus' | 'placeholder' | 'readOnly' | 'rows'
  > {
  className?: string;
  error?: boolean;
  helperText?: string;
  value: string | number;
}

const TextArea: FC<Props> = (props) => {
  const {
    value = '',
    className,
    helperText,
    disabled,
    error,
    placeholder,
    name,
    onBlur,
    onChange,
    onFocus,
    rows,
    ...rest
  } = props;

  const inputValue: string | number = isNil(value) ? '' : value;
  const inputClassNames: string = useInputStyles({
    disabled,
    error
  });
  const classNames: string = cx(inputClassNames, 'p-3 w-full h-full', className);

  return (
    <>
      <div className="relative flex items-center">
        <textarea
          {...rest}
          className={classNames}
          data-component="textarea"
          disabled={disabled}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          rows={rows}
          value={inputValue}
        />
      </div>
      <HelperText helperText={helperText} />
    </>
  );
};

export type { Props as TextAreaProps };

export default TextArea;
