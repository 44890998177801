import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceUpdateChargeTypeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateChargeTypeMutationInput;
}>;

export type FinanceUpdateChargeTypeMutationResult = { __typename?: 'Mutation' } & {
  financeUpdateChargeType?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateChargeTypeMutationPayload' } & {
      chargeType?: GraphQLTypes.Maybe<
        { __typename?: 'ChargeType' } & Pick<
          GraphQLTypes.ChargeType,
          'id' | 'name' | 'chargeBasis' | 'modeOfTransport' | 'deletedAt'
        >
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const FinanceUpdateChargeTypeDocument = /* #__PURE__ */ gql`
  mutation financeUpdateChargeType($input: UpdateChargeTypeMutationInput!) {
    financeUpdateChargeType(input: $input) {
      chargeType {
        id
        name
        chargeBasis
        modeOfTransport
        deletedAt
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useFinanceUpdateChargeTypeMutation__
 *
 * To run a mutation, you first call `useFinanceUpdateChargeTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinanceUpdateChargeTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financeUpdateChargeTypeMutation, { data, loading, error }] = useFinanceUpdateChargeTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinanceUpdateChargeTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<FinanceUpdateChargeTypeMutationResult, FinanceUpdateChargeTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinanceUpdateChargeTypeMutationResult, FinanceUpdateChargeTypeMutationVariables>(
    FinanceUpdateChargeTypeDocument,
    options
  );
}
export type FinanceUpdateChargeTypeMutationHookResult = ReturnType<typeof useFinanceUpdateChargeTypeMutation>;
