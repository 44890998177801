import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';

import type { ShipmentActionType } from './types';

export const getShipmentActionTypesColumnsConfiguration = (
  onEdit: (shipmentActionType: ShipmentActionType) => void
): TableColumn<ShipmentActionType>[] => {
  return [
    {
      key: 'name',
      title: 'Name'
    },
    {
      key: 'title',
      title: 'Title'
    },
    {
      key: 'description',
      title: 'Description'
    },
    {
      key: 'edit',
      title: 'Action',
      fixed: 'right',
      render: (_, shipmentActionType: ShipmentActionType): ReactNode => (
        <TextLink onClick={() => onEdit(shipmentActionType)}>Edit</TextLink>
      ),
      sortable: false,
      width: '100px'
    }
  ];
};
