import type { FC } from 'react';

interface Props {
  helperText?: string;
}

const HelperText: FC<Props> = ({ helperText }) => {
  if (helperText) {
    return (
      <p className="text-grey-base leading-tight mt-2 text-sm" data-testid="helper-text">
        {helperText}
      </p>
    );
  }

  return null;
};

export type { Props as HelperTextProps };
export default HelperText;
