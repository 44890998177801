import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ArchiveAccountLocationMutationVariables = GraphQLTypes.Exact<{
  locationId: GraphQLTypes.Scalars['String'];
}>;

export type ArchiveAccountLocationMutationResult = { __typename?: 'Mutation' } & {
  archiveAccountLocation?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveAccountLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path'>>>;
    }
  >;
};

export const ArchiveAccountLocationDocument = /* #__PURE__ */ gql`
  mutation archiveAccountLocation($locationId: String!) {
    archiveAccountLocation(locationId: $locationId) {
      errors {
        path
      }
    }
  }
`;

/**
 * __useArchiveAccountLocationMutation__
 *
 * To run a mutation, you first call `useArchiveAccountLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAccountLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAccountLocationMutation, { data, loading, error }] = useArchiveAccountLocationMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useArchiveAccountLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAccountLocationMutationResult, ArchiveAccountLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ArchiveAccountLocationMutationResult, ArchiveAccountLocationMutationVariables>(
    ArchiveAccountLocationDocument,
    options
  );
}
export type ArchiveAccountLocationMutationHookResult = ReturnType<typeof useArchiveAccountLocationMutation>;
