import cx from 'classnames';
import type { ReactElement, ReactNode } from 'react';

import { createTrackingLabelAttribute } from '@zen/utils/tracking';

import InformationIcon from '../InformationIcon';

export type LabelPlacement = 'left' | 'top';

interface Props {
  additionalInformation?: ReactNode;
  children?: ReactNode;
  className?: string;
  content: ReactNode;
  hasError?: boolean;
  htmlFor?: string;
  isOptional?: boolean;
  isRequired?: boolean;
  labelPlacement?: LabelPlacement;
  name?: string;
  variant?: 'default' | 'inline';
}

const Label = ({
  additionalInformation,
  className,
  children,
  content,
  hasError = false,
  isOptional = false,
  isRequired = false,
  labelPlacement = 'top',
  name = '',
  variant = 'default'
}: Props) => {
  const classNames = cx(
    {
      'text-red-base': hasError,
      'text-grey-dark': variant === 'default' && !hasError,
      'text-grey-base': variant === 'inline' && !hasError,
      'text-sm': variant === 'default',
      'text-xs': variant === 'inline',
      'mb-0.5': labelPlacement === 'top',
      'ml-3': labelPlacement === 'top' && variant === 'inline',
      'w-36': labelPlacement === 'left'
    },
    'flex items-center leading-normal space-x-1 whitespace-nowrap shrink-0',
    className
  );

  const wrapperClassNames: string = cx(
    {
      'flex-col': labelPlacement === 'top',
      'items-center': labelPlacement === 'left'
    },
    'relative flex group'
  );
  const metadataClassNames: string = cx({ 'text-grey-base': !hasError, 'text-red-base': hasError }, 'text-xs self-end');
  const iconClassName: string = cx({ 'text-base': variant === 'default', 'text-sm': variant === 'inline' }, 'flex');
  const labelElement: ReactElement = (
    <div className={classNames}>
      <label htmlFor={name} {...createTrackingLabelAttribute()}>
        {content}
      </label>
      {additionalInformation && <InformationIcon className={iconClassName} content={additionalInformation} />}
      {isRequired && <span className={metadataClassNames}>Required</span>}
      {isOptional && <span className={metadataClassNames}>Optional</span>}
    </div>
  );

  if (!children) {
    return labelElement;
  }

  return (
    <div className={wrapperClassNames}>
      {labelElement}
      {children}
    </div>
  );
};

export type { Props as LabelProps };

export default Label;
