import cx from 'classnames';
import type { ReactNode } from 'react';

import { Checkbox, Icon } from '@zen/DesignSystem';

import type { ListItemType } from './types';

interface Props {
  item: ListItemType;
  selection?: 'default' | 'checkbox' | 'menu';
}

const ListItem = ({ item, selection = 'default' }: Props) => {
  const { isSelected, label, renderLabel, icon, onClick, disabled } = item;

  const renderRightIcon = (): ReactNode => {
    if (selection === 'menu') {
      return <Icon icon="zicon-chevron-right" />;
    }

    if (isSelected && selection === 'default') {
      return <Icon className="text-azure-base" icon="zicon-tick" />;
    }
  };

  const handleClick = () => {
    if (disabled) return;

    onClick();
  };

  const classNames: string = cx(
    {
      'cursor-not-allowed opacity-50': disabled,
      'active:bg-azure-light hover:bg-azure-lightest cursor-pointer': !disabled
    },
    'py-2 px-4 w-full font-normal text-grey-dark'
  );

  return (
    <div className={classNames} data-testid="list-item" onClick={handleClick}>
      <div className="flex items-center justify-between space-x-4">
        <div className="flex items-center min-w-0 space-x-2 leading-6">
          {selection === 'checkbox' && <Checkbox checked={!!isSelected} />}
          {icon && <Icon icon={icon} />}
          <div className="text-sm truncate whitespace-nowrap">{renderLabel ? renderLabel() : label}</div>
        </div>
        {renderRightIcon()}
      </div>
    </div>
  );
};

export default ListItem;
