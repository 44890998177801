import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AddJourneyRequestedScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AddJourneyRequestedScheduleInput;
}>;

export type AddJourneyRequestedScheduleMutationResult = { __typename?: 'Mutation' } & {
  addJourneyRequestedSchedule: { __typename?: 'AddJourneyRequestedSchedulePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const AddJourneyRequestedScheduleDocument = /* #__PURE__ */ gql`
  mutation addJourneyRequestedSchedule($input: AddJourneyRequestedScheduleInput!) {
    addJourneyRequestedSchedule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useAddJourneyRequestedScheduleMutation__
 *
 * To run a mutation, you first call `useAddJourneyRequestedScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddJourneyRequestedScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addJourneyRequestedScheduleMutation, { data, loading, error }] = useAddJourneyRequestedScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddJourneyRequestedScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddJourneyRequestedScheduleMutationResult,
    AddJourneyRequestedScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AddJourneyRequestedScheduleMutationResult, AddJourneyRequestedScheduleMutationVariables>(
    AddJourneyRequestedScheduleDocument,
    options
  );
}
export type AddJourneyRequestedScheduleMutationHookResult = ReturnType<typeof useAddJourneyRequestedScheduleMutation>;
