import { createContext, FC, ReactNode } from 'react';
import { useLocalStorage } from 'react-use';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

export interface Subscription {
  setShowSubscribed: (showSubscribed: boolean) => void;
  showSubscribed: boolean;
}

interface Props {
  children: ReactNode;
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
const SubscriptionContext = createContext<Subscription>(undefined);

export const SubscriptionContextProvider: FC<Props> = ({ children }) => {
  const [showSubscribed, setShowSubscribed] = useLocalStorage('showSubscribedShipments', false);

  return (
    <SubscriptionContext.Provider value={{ showSubscribed: !!showSubscribed, setShowSubscribed }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = (): Subscription => {
  const subscription: Subscription = useContextOrThrowError<Subscription>(
    SubscriptionContext,
    'useSubscriptionContext must be used inside SubscriptionContextProvider'
  );

  return subscription;
};

export default SubscriptionContext;
