import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

import { ACCESS_TOKEN_REFRESH_INTERVAL } from '@zen/utils/constants';

import authStore from '../authStore';

const useApiAuthoriser = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [hasApiAccessToken, setHasApiAccessToken] = useState(false);

  useEffect(() => {
    const getAccessToken = async () => {
      if (!isAuthenticated) return;
      const { setAccessToken } = authStore();
      const token = await getAccessTokenSilently();

      setHasApiAccessToken(!!token);

      setAccessToken(token);
    };

    const timer = setInterval(() => {
      getAccessToken();
    }, ACCESS_TOKEN_REFRESH_INTERVAL);

    getAccessToken();

    return () => clearInterval(timer);
  }, [setHasApiAccessToken, getAccessTokenSilently, isAuthenticated]);

  return { hasApiAccessToken };
};

export default useApiAuthoriser;
