import cx from 'classnames';
import type { FC } from 'react';

export type BarType = 'info' | 'warning' | 'problem';

const typeClassNames: Record<BarType, string> = {
  info: 'bg-azure-base',
  warning: 'bg-orange-base',
  problem: 'bg-red-base'
};

export interface BarChartConfig {
  percentage: number;
  type: BarType;
}

interface Props {
  className?: string;
  configs: BarChartConfig[];
}
const BarChart: FC<Props> = (props) => {
  const { className = '', configs } = props;

  const renderBar = ({ percentage, type }: BarChartConfig, i: number) => {
    const barClassNames = cx(
      'h-full overflow-hidden transition-all duration-200 first:rounded-l-full last:rounded-r-full',
      typeClassNames[type]
    );

    return <div key={i} className={barClassNames} style={{ width: `${percentage}%` }} />;
  };

  return (
    <div className={`flex ${className}`} data-component="percentage-bar" data-testid="percentage-bar">
      {configs.map(renderBar)}
    </div>
  );
};

export type { Props as BarChartProps };

export default BarChart;
