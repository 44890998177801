import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Nullable } from '@zen/utils/typescript';

import CostTrackingLocationSelect, { CostTrackingLocation } from '../CostTrackingLocationSelect';
import { CostTrackingLocationType } from './types';

interface Props extends FormFieldProps {
  locationType?: CostTrackingLocationType;
  renderMenuInPortal?: boolean;
}

const FormCostTrackingLocationSelect: FC<Props> = (props) => {
  const { className, label, locationType = CostTrackingLocationType.TERMINAL, renderMenuInPortal = true, ...rest } = props;

  return (
    <FormField renderMenuInPortal={renderMenuInPortal} {...props}>
      {(
        field: { error: boolean; label: string; name: string; value: Nullable<CostTrackingLocation> },
        form: FormInstance<unknown>
      ) => {
        const { label: fieldLabel, ...fieldRest } = field;

        return (
          <CostTrackingLocationSelect
            {...rest}
            {...fieldRest}
            hasError={field.error}
            locationType={locationType}
            onChange={(value: Nullable<CostTrackingLocation>) => {
              form.setFieldValue(field.name, value);
            }}
          />
        );
      }}
    </FormField>
  );
};

export type { Props as FormCostTrackingLocationSelectProps };

export default FormCostTrackingLocationSelect;
