import { isEmpty, isEqual } from 'lodash';
import { useEffect } from 'react';
import { useSessionStorage } from 'react-use';

import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

const usePersistedFilters = <T extends {}>(storageKey: string, initialValues: Partial<T> = {}) => {
  const [savedFilters, setSavedFilters] = useSessionStorage(storageKey, {});
  const initialUrlFilters = isEmpty(savedFilters) ? initialValues : savedFilters;
  const {
    search,
    setSearch,
    filters: urlFilters,
    setFilters: setUrlFilters,
    pushWithFilters: pushWithUrlFilters
  } = useUrlFilters<T>(initialUrlFilters);

  useEffect(() => {
    if (!isEqual(savedFilters, urlFilters)) {
      setSavedFilters(urlFilters);
    }
  }, [urlFilters, savedFilters, setSavedFilters]);

  const setFilters = (filters: Partial<T>): void => {
    setUrlFilters(filters);
    setSavedFilters(filters);
  };

  const pushWithFilters = (path: string, filters: Partial<T>): void => {
    pushWithUrlFilters(path, filters, false);
    setSavedFilters(filters);
  };

  return { filters: urlFilters, setFilters, pushWithFilters, search, setSearch };
};

export default usePersistedFilters;
