import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCostTrackingLocationsQueryVariables = GraphQLTypes.Exact<{
  costTrackingLocationType?: GraphQLTypes.InputMaybe<GraphQLTypes.CostTrackingLocationType>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetCostTrackingLocationsQueryResult = { __typename?: 'Query' } & {
  getCostTrackingLocations?: GraphQLTypes.Maybe<
    { __typename?: 'CostTrackingLocationConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
            | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                  label?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                  >;
                })
          >
        >
      >;
    }
  >;
};

export const GetCostTrackingLocationsDocument = /* #__PURE__ */ gql`
  query getCostTrackingLocations($costTrackingLocationType: CostTrackingLocationType, $textContains: String) {
    getCostTrackingLocations(costTrackingLocationType: $costTrackingLocationType, textContains: $textContains) {
      nodes {
        id
        label {
          long
          short
        }
      }
    }
  }
`;

/**
 * __useGetCostTrackingLocationsQuery__
 *
 * To run a query within a React component, call `useGetCostTrackingLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostTrackingLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostTrackingLocationsQuery({
 *   variables: {
 *      costTrackingLocationType: // value for 'costTrackingLocationType'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useGetCostTrackingLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCostTrackingLocationsQueryResult, GetCostTrackingLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCostTrackingLocationsQueryResult, GetCostTrackingLocationsQueryVariables>(
    GetCostTrackingLocationsDocument,
    options
  );
}
export function useGetCostTrackingLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCostTrackingLocationsQueryResult, GetCostTrackingLocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCostTrackingLocationsQueryResult, GetCostTrackingLocationsQueryVariables>(
    GetCostTrackingLocationsDocument,
    options
  );
}
export type GetCostTrackingLocationsQueryHookResult = ReturnType<typeof useGetCostTrackingLocationsQuery>;
export type GetCostTrackingLocationsLazyQueryHookResult = ReturnType<typeof useGetCostTrackingLocationsLazyQuery>;
