import copy from 'copy-to-clipboard';
import type { FC, ReactNode } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';

import { Dialog, IconButton, TextInput } from '../../DesignSystem';

interface Props {
  isOpened: boolean;
  onClose: () => void;
  secret: string;
}

const SecretsModal: FC<Props> = ({ isOpened, onClose, secret }) => {
  const { addSuccess } = useNotification();

  const handleCopy = (): void => {
    copy(secret);
    addSuccess('Copied to the clipboard.');
  };

  const body: ReactNode = (
    <div>
      This is the only instance we will provide the token. Copy it now and securely store it. Once secure, close this dialogue.
      <div className="flex mt-4 space-x-4">
        <div className="flex-1">
          <TextInput className="font-mono" data-testid="secret-input" disabled={true} value={secret} />
        </div>
        <IconButton icon="zicon-copy" onClick={handleCopy} popoverPlacement="top" title="Copy token" variant="secondary" />
      </div>
    </div>
  );

  return (
    <Dialog
      confirmLabel="The token is securely stored"
      hasCancelButton={false}
      header="Here is your new secret token"
      isOpen={isOpened}
      message={body}
      onClose={onClose}
      onConfirm={onClose}
    />
  );
};

export default SecretsModal;
