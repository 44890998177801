import type { ReactChild } from 'react';
import { createContext, useState } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';
import type { Undefinable } from '@zen/utils/typescript';

import { ViewMode } from './types';

export interface ViewModeConfiguration {
  isTableView: boolean;
  setViewMode: (mode: ViewMode) => void;
  viewMode: ViewMode;
}

const ViewModeContext = createContext<Undefinable<ViewModeConfiguration>>(undefined);

export const ViewModeProvider = ({
  initialViewMode = ViewMode.TABLE,
  children
}: {
  children: ReactChild;
  initialViewMode?: ViewMode;
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>(initialViewMode);
  const isTableView = viewMode === ViewMode.TABLE;

  return (
    <ViewModeContext.Provider
      value={{
        viewMode,
        setViewMode,
        isTableView
      }}
    >
      {children}
    </ViewModeContext.Provider>
  );
};

export default ViewModeContext;

export const useViewMode = (): ViewModeConfiguration => {
  return useContextOrThrowError<ViewModeConfiguration>(ViewModeContext, 'useViewMode must be used inside ViewModeProvider');
};
