import { type FC, useState } from 'react';

import { Button, Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { journeyRefetchQueryList } from '../forms/helpers';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import { useCancelJourneyRoadLegMutation } from './graphql';

interface Props {
  className?: string;
}

const CancelRoadLegButton: FC<Props> = ({ className }) => {
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const { addError, addSuccess } = useNotification();
  const [cancelRoadLeg] = useCancelJourneyRoadLegMutation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { cargoId, carriageLeg, zencargoReference } = useJourneyContext();

  const handleConfirm = async (): Promise<void> => {
    setIsLoading(true);

    await performMutation({
      mutationFn: () =>
        cancelRoadLeg({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              cargoId,
              journeyLegId: carriageLeg?.id || '',
              vehicleId: carriageLeg?.vehicleId || '',
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Booking has been cancelled.');
      }
    });

    setIsLoading(false);
    setIsConfirmationDialogVisible(false);
  };

  return (
    <>
      <Button
        className={className}
        iconLeft="zicon-close"
        onClick={() => setIsConfirmationDialogVisible(true)}
        size="compact"
        variant="secondary"
      >
        Cancel booking
      </Button>
      <Dialog
        cancelLabel="Back"
        confirmLabel="Cancel booking"
        header="Cancel booking"
        isLoading={isLoading}
        isOpen={isConfirmationDialogVisible}
        message="This will remove the booked status and dates from all legs managed by the same carrier. All vehicle details will also be deleted."
        onClose={() => setIsConfirmationDialogVisible(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default CancelRoadLegButton;
