import { FC, ReactNode, useEffect, useState } from 'react';

import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import CargoReadyDateForm from '../components/CargoReadyDateForm';
import type { RichDateInput } from '../components/CargoReadyDateForm/types';
import { usePurchaseOrdersUpdateLotEstimatesMutation } from '../graphql';
import type { CargoReadyDateMutationResponse } from './types';

type RichDateInputFormValues = { [P in keyof RichDateInput]: Optional<RichDateInput[P]> };

interface Props {
  className?: string;
  initialValues: Optional<RichDateInputFormValues>;
  isVisible: boolean;
  lotIds: string[];
  onClose: () => void;
  onSuccess?: (values?: CargoReadyDateMutationResponse) => void;
  orderDate: Optional<string>;
  orderReferenceNumber?: Optional<string>;
}

const CargoReadyDateSlideout: FC<Props> = (props) => {
  const { className, initialValues, isVisible, lotIds, onClose, onSuccess, orderDate, orderReferenceNumber } = props;
  const { addSuccess, addError } = useNotification();
  const [show, setShow] = useState<boolean>(false);

  const title: ReactNode = <>Update cargo ready date {orderReferenceNumber && ` for ${orderReferenceNumber}`}</>;

  useEffect(() => {
    setShow(isVisible);
  }, [isVisible]);

  const [updateCRD] = usePurchaseOrdersUpdateLotEstimatesMutation();

  const handleSubmit = (cargoReadyDate: RichDateInput): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () =>
        updateCRD({
          variables: {
            input: {
              lotIds,
              cargoReadyDate
            }
          }
        }),
      onError: () => addError()
    });

  const handleSuccess = (values: CargoReadyDateMutationResponse) => {
    addSuccess('Cargo ready date has been updated.');
    onClose();
    if (onSuccess) {
      onSuccess(values);
    }
  };

  return (
    <Slideout className={className} onOutsideClick={onClose} overlay={true} show={show}>
      <SlideoutHeader onClose={onClose} title={title} />
      <div
        className="relative flex flex-col h-[calc(100vh-8rem)] px-6 pt-6 pb-20 overflow-y-scroll w-120"
        data-testid="cargo-ready-slideout"
      >
        <CargoReadyDateForm
          cargoReadyDate={initialValues?.date || ''}
          onSubmit={handleSubmit}
          onSuccess={handleSuccess}
          orderDate={orderDate}
        />
      </div>
    </Slideout>
  );
};

export default CargoReadyDateSlideout;
