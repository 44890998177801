import * as Yup from 'yup';

import { LocationLink } from '@zen/Accounts/AccountDetails/components/CompanyNameFields/helpers';
import { entityNameValidator } from '@zen/Accounts/validations';

const emailMessage: string = 'Please enter a valid email address.';

const accountDetailsValidation = Yup.object({
  tradingName: entityNameValidator('Trading name'),
  referencePrefix: Yup.string()
    .nullable()
    .matches(
      /^Z([a-zA-Z0-9]){2,14}$/,
      "Reference prefix must start with 'Z', can only contain alphanumeric characters and must be between 3 and 15 characters in length."
    )
    .required('Please enter a Zen reference.')
}).required();

export const contactValidation = Yup.object({
  firstName: Yup.string().nullable().required('Please enter a first name.'),
  lastName: Yup.string().nullable().required('Please enter a last name.'),
  email: Yup.string().nullable().email(emailMessage).required(emailMessage),
  phoneNumber: Yup.string().nullable()
});

export const addressValidation = Yup.object({
  country: Yup.string().nullable().required('Please select a country.'),
  countyOrState: Yup.string().nullable().optional(),
  city: Yup.string().nullable().required('Please enter a city.'),
  line1: Yup.string().nullable().required('Please enter a street.'),
  postalCodeOrZip: Yup.string().nullable().required('Please enter a post code.')
});

const legalInformationValidation = Yup.object({
  registeredName: entityNameValidator('Registered name'),
  companyRegistrationNumber: Yup.string().nullable().optional(),
  vatIdentificationNumber: Yup.string().nullable().optional(),
  eoriNumber: Yup.string().nullable().optional()
});

export const shippingLocationValidation = Yup.object({
  companyName: Yup.string()
    .nullable()
    .when('linkType', {
      is: LocationLink.OTHER_COMPANY,
      then: () => entityNameValidator('Company name')
    }),
  name: entityNameValidator('Location name'),
  linkType: Yup.string().nullable(),
  locationType: Yup.string().nullable(),
  usageContext: Yup.string().nullable().required('Please select location usage.'),
  businessHoursOption: Yup.string().nullable(),
  geolocation: Yup.object({
    isPinPlacementConfirmed: Yup.bool().oneOf([true], 'Please confirm that the map pin is in the correct location.')
  })
});

const createShippingLocationValidation = Yup.object({
  shippingLocationAddress: addressValidation
}).concat(shippingLocationValidation);

const updateShippingLocationValidation = Yup.object({
  shippingLocationAddress: addressValidation,
  geolocation: Yup.object({
    isPinPlacementConfirmed: Yup.bool().oneOf([true], 'Please confirm that the map pin is in the correct location.')
  })
});

const updateLocationAddressValidation = Yup.object({
  geolocation: Yup.object({
    isPinPlacementConfirmed: Yup.bool().oneOf([true], 'Please confirm that the map pin is in the correct location.')
  }),
  shippingLocationAddress: addressValidation
});

const fullAccountValidationSchema = {
  isBillingAddressSameAsRegisteredAddress: Yup.boolean(),
  accountDetails: accountDetailsValidation,
  legalInformation: legalInformationValidation,
  shippingLocation: shippingLocationValidation,
  shippingLocationAddress: addressValidation
};

const updateAccountLegalDetailsValidation = legalInformationValidation;

const updateBillingDetailsValidation = Yup.object({
  billingAddress: addressValidation
}).concat(contactValidation);

const updateAccountContactDetailsValidation = contactValidation;

const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape(fullAccountValidationSchema);

export {
  accountDetailsValidation,
  createShippingLocationValidation,
  fullAccountValidationSchema,
  updateAccountContactDetailsValidation,
  updateAccountLegalDetailsValidation,
  updateBillingDetailsValidation,
  updateLocationAddressValidation,
  updateShippingLocationValidation,
  validationSchema
};
