import cx from 'classnames';
import { FC, KeyboardEvent, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import type { ContentEditableEvent } from 'react-contenteditable';
import ContentEditable from 'react-contenteditable';
import sanitizeHtml from 'sanitize-html';

import Copyable from '../Copyable';
import type { LabelPlacement } from '../Label';
import Label from '../Label';

interface Props {
  additionalElement?: ReactNode;
  className?: string;
  copyable?: boolean;
  disabled?: boolean;
  label?: string;
  labelPlacement?: LabelPlacement;
  name: string;
  onUpdate: (value: string) => void;
  placeholder?: string;
  size?: 'default' | 'compact';
  value?: string;
}

const InlineEditableField: FC<Props> = (props) => {
  const {
    additionalElement,
    className,
    copyable = true,
    disabled = false,
    label = '',
    labelPlacement = 'top',
    name,
    onUpdate,
    placeholder = 'Enter data',
    size = 'default',
    value: submittedValue = ''
  } = props;
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const inlineEditableField = useRef<HTMLElement>(null);
  const fieldValue = useRef<string>(submittedValue);

  useEffect(() => {
    setValue(submittedValue || '');
  }, [submittedValue]);

  const setValue = (inputValue: string): void => {
    fieldValue.current = sanitizeHtml(inputValue, {
      allowedTags: []
    });
  };

  const handleChange = (event: ContentEditableEvent): void => {
    setValue(event.target.value);
  };

  const handleFocusChange = (): void => {
    setIsEditMode(true);
  };

  const handleBlur = (): void => {
    if (inlineEditableField.current) {
      inlineEditableField.current.scrollLeft = 0;
    }

    handleUpdateValue();
    setIsEditMode(false);
  };

  const handleUpdateValue = (): void => {
    if (fieldValue.current !== submittedValue) {
      onUpdate(fieldValue.current.trim());
    }
  };

  const onKeyPress = (event: KeyboardEvent<HTMLElement>): void => {
    const isEnterKey: boolean = event.key === 'Enter';
    const isTextModificationKey: boolean = event.key === 'b' || event.key === 'i' || event.key === 'u';
    const isControlOrMetaKey: boolean = event.ctrlKey || event.metaKey;

    if ((isTextModificationKey && isControlOrMetaKey) || isEnterKey) {
      event.preventDefault();
    }

    if (isEnterKey) {
      inlineEditableField?.current?.blur();
    }
  };

  const showCopyIcon: boolean = copyable && !!fieldValue.current;

  const inputClassNames: string = cx(
    {
      'truncate hover:bg-grey-lighter border-transparent transition-all duration-300': !isEditMode && !disabled,
      'overflow-y-auto scrollbar-hidden whitespace-nowrap focus:outline-none focus:border-azure-base caret-azure-base !font-normal !not-italic !no-underline':
        isEditMode,
      'truncate disabled text-grey-dark': disabled,
      'mr-2': showCopyIcon,
      'h-10 py-2': size === 'default',
      'h-8 py-1': size === 'compact'
    },
    className,
    'border border-solid border-transparent rounded px-3 min-w-[4rem] text-grey-dark max-w-max text-sm leading-normal'
  );

  const wrapperClassNames: string = cx(
    {
      group: !label
    },
    'flex items-center min-w-0'
  );

  const content: ReactElement = (
    <div className={wrapperClassNames}>
      <div className="truncate">
        <ContentEditable
          aria-disabled={disabled}
          className={inputClassNames}
          data-name={name}
          data-testid="inline-editable-field"
          disabled={disabled}
          html={fieldValue.current}
          innerRef={inlineEditableField}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocusChange}
          onKeyDown={onKeyPress}
          placeholder={placeholder}
        />
      </div>
      {showCopyIcon && <Copyable text={null} textToCopy={fieldValue.current} />}
      {additionalElement && (
        <div className="transition-all duration-300 opacity-0 group-hover:opacity-100">{additionalElement}</div>
      )}
    </div>
  );

  if (!label) {
    return content;
  }

  return (
    <Label content={label} labelPlacement={labelPlacement} name={name || 'field'} variant="inline">
      {content}
    </Label>
  );
};

export type { Props as InlineEditableFieldProps };

export default InlineEditableField;
