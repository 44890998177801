import { get } from 'lodash';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import FormTermsAndConditionsCheckbox from '@zen/Accounts/components/forms/FormTermsAndConditionsCheckbox';
import { Form } from '@zen/Components';
import { Headline } from '@zen/DesignSystem';
import { dashboardRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { activateAccountValidation } from './ActivatateAccount.validation';
import { useActivatePendingAccountMutation } from './graphql';

interface Props {
  accountId: string;
}

const ActivatateAccount: FC<Props> = ({ accountId }) => {
  const { addError, addSuccess } = useNotification();
  const [activateAccount] = useActivatePendingAccountMutation();

  const navigate = useNavigate();

  const handleSubmit = (): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        activateAccount({
          refetchQueries: ['getCurrentUser'],
          variables: {
            accountId
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was an issue with activating the account.'));
      },
      onSuccess: () => {
        navigate(dashboardRoutes.dashboard.getUrl());
        addSuccess('Your account is activated now.');
      }
    });
  };

  return (
    <div className="pt-4" data-testid="account-activation">
      <Headline className="mb-2" level={2}>
        Your account is looking ship shape!
      </Headline>
      <p className="mb-4">
        You're all set to start shipping. Don't worry, you can change your details - except for the Zencargo reference - at any
        time.
      </p>
      <Form
        buttonText="Start using Zencargo"
        initialValues={{ terms: false }}
        onSubmit={handleSubmit}
        validationSchema={activateAccountValidation}
      >
        {() => <FormTermsAndConditionsCheckbox name="terms" />}
      </Form>
    </div>
  );
};

export default ActivatateAccount;
