import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateRateCardMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.LegacyCreateRateCardInput;
}>;

export type CreateRateCardMutationResult = { __typename?: 'Mutation' } & {
  legacyCreateRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyCreateRateCardPayload' } & Pick<GraphQLTypes.LegacyCreateRateCardPayload, 'rateCardId'> & {
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
      }
  >;
};

export const CreateRateCardDocument = /* #__PURE__ */ gql`
  mutation createRateCard($input: LegacyCreateRateCardInput!) {
    legacyCreateRateCard(input: $input) {
      errors {
        path
        message
      }
      rateCardId
    }
  }
`;

/**
 * __useCreateRateCardMutation__
 *
 * To run a mutation, you first call `useCreateRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRateCardMutation, { data, loading, error }] = useCreateRateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRateCardMutationResult, CreateRateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateRateCardMutationResult, CreateRateCardMutationVariables>(CreateRateCardDocument, options);
}
export type CreateRateCardMutationHookResult = ReturnType<typeof useCreateRateCardMutation>;
