const UTILISATION_RELATIVE_INDEX_PATH = 'utilisation';

export const UTILISATION_PREFIX = `/dashboard/${UTILISATION_RELATIVE_INDEX_PATH}`;

interface UtilisationRoutes {
  details: {
    getUrl: (supplierId: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const utilisationRoutes: UtilisationRoutes = {
  details: {
    getUrl: (supplierId: string) => `${UTILISATION_PREFIX}/${supplierId}`,
    path: `${UTILISATION_PREFIX}/:supplierId`,
    relativePath: ':supplierId'
  },
  index: {
    getUrl: () => UTILISATION_PREFIX,
    path: `${UTILISATION_PREFIX}/*`,
    relativePath: `${UTILISATION_RELATIVE_INDEX_PATH}/*`
  }
};

export default utilisationRoutes;
