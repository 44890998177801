import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { dashboardRoutes } from '@zen/Routes';
import { downloadS3File } from '@zen/utils';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useQueryParams } from '@zen/utils/QueryParams';

import { ReactComponent as Logo } from './logo.svg';
import PostDownloadContent from './PostDownloadContent';
import PreDownloadContent from './PreDownloadContent';

const DownloadPage: FC = () => {
  const { queryParams: assetUrl } = useQueryParams<string>('asset-url');
  const [hasStartedDownload, setHasStartedDownload] = useState(false);
  const navigate = useNavigate();
  const { addError } = useNotification();

  const handleDownload = async (): Promise<void> => {
    setHasStartedDownload(true);
    await downloadS3File(assetUrl).catch(() => addError('There was a problem downloading the file.'));
  };

  const handleLaunchZen = (): void => {
    navigate(dashboardRoutes.dashboard.getUrl());
  };

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center p-20">
      <div className="flex flex-col items-center max-w-xs text-center">
        {hasStartedDownload ? (
          <PostDownloadContent onAction={handleLaunchZen} />
        ) : (
          <PreDownloadContent onAction={handleDownload} />
        )}
        <Logo className="mt-32" />
      </div>
    </div>
  );
};

export default DownloadPage;
