import type { Nullable } from '@zen/utils/typescript';

import { preparePortChargeInitialValues } from '../helpers';
import type {
  ContainerChargePayload,
  FreightChargePayload,
  HaulageChargePayload,
  RateCardReducerState
} from '../RateCardForm/reducer/types';
import type { ContainerCharge, FreightCharge, RateCard } from '../types';

export const getInitialValues = (rateCard?: Nullable<RateCard>): RateCardReducerState | undefined => {
  if (!rateCard) {
    return;
  }

  const { modeOfTransport, cargoType, note, originHaulageCharges, destinationHaulageCharges, originCharges, destinationCharges } =
    rateCard;
  const freightCharges: FreightChargePayload[] = prepareFreightCharges(rateCard.freightCharges);

  return {
    startDate: '',
    endDate: '',
    modeOfTransport,
    freightCharges,
    cargoType,
    note,
    originCharges: preparePortChargeInitialValues(originCharges),
    destinationCharges: preparePortChargeInitialValues(destinationCharges),
    name: '',
    issuedBy: '',
    originHaulageCharges: (originHaulageCharges || []) as HaulageChargePayload[],
    destinationHaulageCharges: (destinationHaulageCharges || []) as HaulageChargePayload[]
  };
};

const prepareFreightCharges = (freightCharges: FreightCharge[]): FreightChargePayload[] => {
  return freightCharges.map((freightCharge: FreightCharge) => ({
    ...freightCharge,
    containerCharges: prepareContainerCharges(freightCharge.containerCharges)
  }));
};

const prepareContainerCharges = (containerCharges: ContainerCharge[]): ContainerChargePayload[] => {
  return containerCharges.map((containerCharge: ContainerCharge) => ({
    ...containerCharge,
    chargeValue: null
  }));
};
