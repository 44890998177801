import type { SyntheticEvent } from 'react';

import { createTrackingLabel } from './label';

interface TrackingEvent {
  action: string;
  category: string;
  label: string;
  properties: {
    componentLabel: string;
  };
}

const isSyntheticEvent = (event: SyntheticEvent | Event): event is SyntheticEvent => {
  return (event as SyntheticEvent).nativeEvent != null;
};

export const createTrackingEvent = (category: string, action: string, event: Event | SyntheticEvent): TrackingEvent => {
  const trackedEvent: Event = isSyntheticEvent(event) ? event.nativeEvent : event;

  return {
    category,
    action,
    label: createTrackingLabel(trackedEvent),
    properties: {
      componentLabel: createTrackingLabel(trackedEvent, true)
    }
  };
};
