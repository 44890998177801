import cx from 'classnames';
import { FC, SyntheticEvent, useState } from 'react';
import Dropzone from 'react-dropzone';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import { ReactComponent as Upload } from './upload.svg';

interface Props {
  className?: string;
  onDrop: (files: File[]) => void;
}

export const displayName = 'Design System/DropZone';

const DropZone: FC<Props> = ({ onDrop, className: dropZoneClassName }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const classNames: string = cx(
    'flex items-center justify-center',
    'rounded border-solid',
    'px-6',
    'hover:border-azure-light hover:shadow-fixed-top',
    'cursor-pointer transition-all duration-200 ease-linear',
    {
      'border-2 border-azure-light': isActive,
      'border border-grey-lighter': !isActive
    },
    dropZoneClassName
  );

  const handleDrop = (acceptedFiles: File[]): void => {
    setIsActive(false);

    onDrop(acceptedFiles);
  };

  const trackDropzoneEvent = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, event.type, event);

    trackEvent(trackingEvent);
  };

  return (
    <Dropzone
      onDragEnter={() => {
        setIsActive(true);
      }}
      onDragLeave={() => {
        setIsActive(false);
      }}
      onDrop={handleDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps({
            className: classNames,
            'data-component': 'dropzone',
            'data-testid': 'dropzone',
            onClick: trackDropzoneEvent,
            onDrop: trackDropzoneEvent,
            ...createTrackingParentAttribute('dropzone')
          })}
        >
          <input {...getInputProps()} />
          <div className="text-center md:flex md:items-center">
            <Upload className="inline w-16 my-5 md:mr-6" />
            <div {...createTrackingLabelAttribute('dropzone')}>
              Drag and drop your files here to upload or <span className="font-bold underline text-navy-base">browse</span>
            </div>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export type { Props as DropZoneProps };

export default DropZone;
