import type { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const Body: FC<Props> = ({ className, children }) => {
  const classNames = `text-sm leading-5 ${className}`;

  return (
    <p className={classNames} data-component="body">
      {children}
    </p>
  );
};

export type { Props as BodyProps };

export default Body;
