import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OriginPortFiltersQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableFiltersInput>;
}>;

export type OriginPortFiltersQueryResult = { __typename?: 'Query' } & {
  shipmentsTablePortOfLoadingFilterValues: { __typename?: 'ShipmentsTableFilterValueConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'ShipmentsTableFilterValue' } & Pick<GraphQLTypes.ShipmentsTableFilterValue, 'key' | 'value'>
        >
      >
    >;
  };
};

export const OriginPortFiltersDocument = /* #__PURE__ */ gql`
  query originPortFilters($accountIds: [String!]!, $searchTerm: String, $first: Int, $filterBy: ShipmentsTableFiltersInput) {
    shipmentsTablePortOfLoadingFilterValues(
      accountIds: $accountIds
      searchTerm: $searchTerm
      first: $first
      filterBy: $filterBy
    ) {
      nodes {
        key
        value
      }
    }
  }
`;

/**
 * __useOriginPortFiltersQuery__
 *
 * To run a query within a React component, call `useOriginPortFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOriginPortFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOriginPortFiltersQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      searchTerm: // value for 'searchTerm'
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useOriginPortFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<OriginPortFiltersQueryResult, OriginPortFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OriginPortFiltersQueryResult, OriginPortFiltersQueryVariables>(OriginPortFiltersDocument, options);
}
export function useOriginPortFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OriginPortFiltersQueryResult, OriginPortFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OriginPortFiltersQueryResult, OriginPortFiltersQueryVariables>(OriginPortFiltersDocument, options);
}
export type OriginPortFiltersQueryHookResult = ReturnType<typeof useOriginPortFiltersQuery>;
export type OriginPortFiltersLazyQueryHookResult = ReturnType<typeof useOriginPortFiltersLazyQuery>;
