import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import useTracking from '@zen/utils/hooks/useTracking';

import { TableConfigurationTrackingAction, TableConfigurationTrackingCategory } from '../types';

interface ColumnConfiguration {
  hiddenColumns: string[];
  setHiddenColumns: (hiddenColumns: string[]) => void;
}

export const useColumnConfiguration = (tableId: string, initialHiddenColumns: string[] = []): ColumnConfiguration => {
  const [columnConfig, saveColumnConfig] = useLocalStorage<Record<string, string[]>>('table-hidden-columns', {});
  const { trackEvent } = useTracking();

  const setHiddenColumns = useCallback(
    (hiddenColumns: string[]) => {
      trackEvent({
        category: TableConfigurationTrackingCategory,
        action: TableConfigurationTrackingAction.CUSTOMISE,
        label: tableId,
        properties: {
          tableId,
          hiddenColumns
        }
      });

      saveColumnConfig({
        ...columnConfig,
        [tableId]: hiddenColumns
      });
    },
    [columnConfig, saveColumnConfig, tableId, trackEvent]
  );

  const hiddenColumns: string[] = columnConfig?.[tableId] || initialHiddenColumns;

  return {
    hiddenColumns,
    setHiddenColumns
  };
};
