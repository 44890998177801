import type { DateRange } from '@zen/DesignSystem';
import { formatDate, formatDateRange } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

export const buildDateRangeLabel = ({ startDate, endDate }: Partial<DateRange>): Nullable<string> => {
  if (!startDate || !endDate) {
    return null;
  }

  if (startDate && startDate === endDate) {
    return formatDate(startDate);
  }

  return startDate ? formatDateRange(startDate, endDate) : '';
};
