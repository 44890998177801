import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  leftOffset: number;
  visible: boolean;
}

const TableColumnActionIndicator: FC<Props> = ({ leftOffset, visible }) => {
  const classNames = cx('absolute top-0 bottom-0 z-10 bg-azure-light', {
    hidden: !visible
  });

  return <div className={classNames} style={{ left: leftOffset, width: '1px' }} />;
};

export default TableColumnActionIndicator;
