import { FC, ReactNode, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import OrderDetailsContainer from '@zen/Orders/OrderDetailsContainer';
import { bookingRequestRoutes } from '@zen/Routes';
import { SortingOrder, SortInput } from '@zen/types';

import BookingRequestLoadingSkeleton from '../BookingRequestLoading';
import BookingRequestsTable from '../BookingRequestsTable';
import { prepareBookingRequestsList } from '../helper';
import useBookingRequests from '../hooks/useBookingRequests';
import type { BookingRequest, Shipment } from '../types';

const initialOrder: SortInput = { field: 'createdAt', direction: SortingOrder.DESC };

const ReadyForApproval: FC = () => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const { nodes, paginationInfo, loading, error, totalCount } = useBookingRequests({ onlyReadyForApproval: true }, order);

  const noResults: ReactNode = <NoResults headline="There aren't any requests ready for approval to review at the moment" />;

  return (
    <>
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<BookingRequestLoadingSkeleton order={order} />}
        noResults={noResults}
      >
        {(readyForApprovalRequests: Shipment[]) => {
          const data: BookingRequest[] = prepareBookingRequestsList(readyForApprovalRequests);

          return (
            <BookingRequestsTable
              bookingRequests={data}
              onOrderChange={setOrder}
              order={order}
              paginationInfo={paginationInfo}
              totalCount={totalCount}
            />
          );
        }}
      </QueryHandler>
      <Routes>
        <Route
          element={<OrderDetailsContainer originPathname={bookingRequestRoutes.readyForApproval.getUrl()} />}
          path={bookingRequestRoutes.readyForApprovalOrderDetails.relativePath}
        />
      </Routes>
    </>
  );
};

export default ReadyForApproval;
