import type { FC } from 'react';

import { IconButton } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  icon: IconName;
  onClick: () => void;
}

const TableActionButton: FC<Props> = ({ icon, onClick }) => {
  return <IconButton icon={icon} onClick={onClick} size="medium" variant="ghost" />;
};

export default TableActionButton;
