import cx from 'classnames';
import { Form as FormikForm, Formik, FormikHelpers, FormikProps, FormikTouched } from 'formik';
import pluralize from 'pluralize';
import { FC, ReactNode, useState } from 'react';
import type * as Yup from 'yup';

import { Banner, ButtonVariant } from '@zen/DesignSystem';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable } from '@zen/utils/typescript';
import { defaultErrorMessage } from '@zen/utils/validation';

import FormButtons from './FormButtons';
import NestedForm from './NestedForm';
import ScrollToFirstError from './ScrollToFirstError';
import { Error, FormActions, FormInstance, FormTrackingAction, FormTrackingCategory, ServerError } from './types';
import withTimingTracker from './withTimingTracker';

type InitialValues = any;

interface Props {
  buttonClassName?: string;
  buttonText?: string;
  buttonVariant?: ButtonVariant;
  children: (formProps: FormInstance<InitialValues>) => ReactNode;
  className?: string;
  enableReinitialize?: boolean;
  errorClassName?: string;
  formButtons?: (formProps: FormInstance<InitialValues>) => ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  formName?: string;
  initialTouched?: FormikTouched<any>;
  initialValues: InitialValues;
  isNested?: boolean;
  onError?: (data: any) => void;
  onSubmit: (values: InitialValues) => Promise<IOkOrErrorResult>;
  onSuccess?: (data: any, values: InitialValues, actions: FormActions<InitialValues>) => void;
  scrollToError?: boolean;
  scrollToErrorOffset?: number;
  submitOnEnter?: boolean;
  validateOnMount?: boolean;
  validationSchema?: Yup.ObjectSchema<{}>;
}

const Form: FC<Props> = (props) => {
  const {
    buttonClassName,
    buttonText,
    buttonVariant,
    children,
    className,
    enableReinitialize,
    errorClassName,
    formButtons,
    formName,
    initialValues,
    initialTouched,
    isNested,
    onError,
    onSubmit,
    onSuccess,
    scrollToError = true,
    scrollToErrorOffset,
    submitOnEnter = true,
    validationSchema,
    validateOnMount = false
  } = props;
  const { trackEvent } = useTracking();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(null);

  const validateResponse = (response: any, { setFieldError, resetForm }: FormikHelpers<InitialValues>, values: InitialValues) => {
    if (response) {
      const key = Object.keys(response)[0];
      const data = response[key];

      if (data && data.errors && data.errors.length > 0) {
        data.errors.forEach((err: Error) => {
          setFieldError(err?.path || '', err.message);
        });

        handleErrors(data);
      } else if (onSuccess) {
        onSuccess(data, values, { resetForm });
      }
    }
  };

  const handleErrors = (data: any) => {
    const baseErrors: Error[] = data.errors.filter((error: Error) => error.path === 'base');
    const prepareBaseErrorMessage = (): string =>
      data.errors.map((error: Error) => error.message || defaultErrorMessage).join('\n');

    const text: string = baseErrors.length > 0 ? prepareBaseErrorMessage() : 'Please fix fields below';

    setErrorMessage(text);

    if (onError) {
      onError(data);
    }
  };

  const handleServerError = (error: string | ServerError): void => {
    const text = typeof error === 'string' ? error : 'Error submitting form';

    window.scrollTo({ top: 0, behavior: 'smooth' });

    trackEvent({
      category: FormTrackingCategory,
      action: FormTrackingAction.SERVER_ERROR,
      label: text,
      properties: {
        errorMessage: text,
        formName: formName || 'unknown'
      }
    });

    setErrorMessage(text);
  };

  const renderButtons = (formBag: FormInstance<InitialValues>) => {
    if (formButtons) {
      return formButtons(formBag);
    }

    return (
      <FormButtons className={buttonClassName} isSubmitting={formBag.isSubmitting} text={buttonText} variant={buttonVariant} />
    );
  };

  const renderFormikForm = (formBag: FormInstance<InitialValues>): ReactNode => (
    <FormikForm className={className} data-testid="form">
      {children(formBag)}
      {renderButtons(formBag)}
    </FormikForm>
  );

  const renderNestedForm = (formBag: FormikProps<InitialValues>): ReactNode => (
    <NestedForm className={className} data-testid="form" onSubmit={formBag.handleSubmit} submitOnEnter={submitOnEnter}>
      {children(formBag as FormInstance<InitialValues>)}
      {renderButtons(formBag as FormInstance<InitialValues>)}
    </NestedForm>
  );

  const handleSubmit = async (values: InitialValues, actions: FormikHelpers<InitialValues>) => {
    setErrorMessage(null);

    actions.setSubmitting(true);

    const result = await onSubmit(values);

    actions.setSubmitting(false);

    if (result.error) {
      handleServerError(result.error);
    } else {
      validateResponse(result.ok, actions, values);
    }
  };

  return (
    <Formik
      enableReinitialize={enableReinitialize || false}
      initialTouched={initialTouched}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnMount={validateOnMount}
      validationSchema={validationSchema}
    >
      {(formBag: FormikProps<InitialValues>) => {
        const { submitCount, errors } = formBag;
        const errorsLength: number = Object.keys(errors).length;
        const errorClassNames: string = cx('p-4 rounded mb-4', errorClassName);
        const isValid: boolean = errorsLength === 0;
        let message: string = '';

        if (submitCount > 0 && (!isValid || errorMessage)) {
          message = errorMessage || `Please fix ${pluralize('field', errorsLength)} below`;
        }

        return (
          <>
            {message && <Banner className={errorClassNames} data-testid="form-error-message" message={message} type="error" />}
            {isNested ? renderNestedForm(formBag) : renderFormikForm(formBag)}
            {scrollToError && <ScrollToFirstError additionalYOffset={scrollToErrorOffset} />}
          </>
        );
      }}
    </Formik>
  );
};

export type { Props as FormProps };

export default withTimingTracker(Form);
