import { get } from 'lodash';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useAddressOrientedZoom } from '@zen/Accounts/hooks/useAddressOrientedZoom';
import { FormHiddenInput, useForm } from '@zen/Components';
import Map from '@zen/Components/Map';
import type { GeoCoordinates } from '@zen/Components/Map/types';
import { Banner } from '@zen/DesignSystem';
import type { AddressInput } from '@zen/graphql/types.generated';
import { useGetAddressCoordinates } from '@zen/utils/hooks/useGetAddressCoordinates';

import GoogleMapStyleIconButton from './GoogleMapStyleIconButton';
import { isOutsideDistanceThreshold } from './helpers';

type FormProps<T extends string> = {
  [K in T]: {
    isPinPlacementConfirmed: boolean;
    latitude: number;
    longitude: number;
  };
};

interface Props<T = 'geolocation'> {
  address: AddressInput;
  initialCoordinates?: GeoCoordinates;
  name: T;
}

const FormAddressGeolocationInput = <T extends string>({ name, address, initialCoordinates }: Props<T>) => {
  const { values, setFieldValue } = useForm<FormProps<T>>();
  const { isPinPlacementConfirmed } = get(values, name) || {};
  const [showDistanceAlert, setShowDistanceAlert] = useState(false);
  const { coordinates: addressCoordinates } = useGetAddressCoordinates(address);
  const [shouldUseAddressDerivedCoordinates, setShouldUseAddressDerivedCoordinates] = useState(!initialCoordinates);
  const zoomValue: number = useAddressOrientedZoom(address);

  const confirmPinPlacement = (isConfirmed: boolean): void => setFieldValue(`${name}.isPinPlacementConfirmed`, isConfirmed);
  const setCoordinates = (lat: number, lng: number): void => {
    setFieldValue(`${name}.longitude`, lng);
    setFieldValue(`${name}.latitude`, lat);
  };

  useEffect(() => {
    confirmPinPlacement(!!initialCoordinates);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useUpdateEffect(() => {
    setShouldUseAddressDerivedCoordinates(true);
    confirmPinPlacement(false);
  }, [address]);

  const handleGeocoordinateChange = (lat: number, lng: number): void => {
    setCoordinates(lat, lng);

    if (addressCoordinates) {
      const shouldShowDistanceWarning: boolean = isOutsideDistanceThreshold({ lat, lng }, addressCoordinates);

      setShowDistanceAlert(shouldShowDistanceWarning);
    }
  };

  const markers = useMemo(() => {
    if (shouldUseAddressDerivedCoordinates && !!addressCoordinates) {
      return [addressCoordinates];
    }

    if (initialCoordinates) {
      return [initialCoordinates];
    }

    return [];
  }, [addressCoordinates, shouldUseAddressDerivedCoordinates]); // eslint-disable-line react-hooks/exhaustive-deps

  const customMapButton: ReactNode = isPinPlacementConfirmed ? (
    <GoogleMapStyleIconButton aria-label="Edit pin placement" icon="zicon-edit" onClick={() => confirmPinPlacement(false)} />
  ) : (
    <GoogleMapStyleIconButton aria-label="Confirm pin placement" icon="zicon-tick" onClick={() => confirmPinPlacement(true)} />
  );

  const bannerMessage: ReactNode = showDistanceAlert ? (
    <Banner message="That appears to be quite far from the address given. Please confirm the pin location." type="warning" />
  ) : (
    <Banner message="Please ensure and confirm that the pin corresponds with the provided address." />
  );

  const mapWrapperClassName: string =
    'relative w-full grid-cols-4 col-span-4 aspect-[2/1] bg-azure-light border border-solid border-grey-lighter rounded overflow-hidden';

  return (
    <>
      {!isPinPlacementConfirmed && <div className="mb-4">{bannerMessage}</div>}
      <div className={mapWrapperClassName}>
        <Map
          defaultZoom={zoomValue}
          isInteractive={!isPinPlacementConfirmed}
          markers={markers}
          maxZoomValue={20}
          onMapChange={handleGeocoordinateChange}
          resizable={false}
          singleMarkerMode={true}
        />
        <div className="absolute top-0 right-0 m-[10px] pointer-events-none">{customMapButton}</div>
      </div>
      <FormHiddenInput name={`${name}.latitude`} />
      <FormHiddenInput name={`${name}.longitude`} />
      <FormHiddenInput className="col-span-8" name={`${name}.isPinPlacementConfirmed`} />
    </>
  );
};

export default FormAddressGeolocationInput;
export type { Props as FormAddressGeolocationInputProps };
