import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

const GlobalPermissionsContext = createContext<unknown>(undefined);

const useGlobalPermissionsContext = () => {
  const globalPermissions = useContextOrThrowError(
    GlobalPermissionsContext,
    'useGlobalPermissionsContext must be used inside GlobalPermissionsContextProvider'
  );

  return globalPermissions;
};

const GlobalPermissionsContextProvider = GlobalPermissionsContext.Provider;

export { GlobalPermissionsContextProvider, useGlobalPermissionsContext };
