import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import PageTitle from '@zen/Components/PageTitle';
import type { ConsolidatedShipment as ConsolidatedShipmentType } from '@zen/graphql/types.generated';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { TrackingDetails } from '../../types';
import BookingTrackingIndicator from '../BookingTrackingIndicator';
import ClientReferenceContainer from '../ClientReferenceContainer';
import ConsolidatedShipment from '../ConsolidatedShipment';
import ModeOfTransportIcon from '../ModeOfTransportIcon';

interface Props {
  clientReference?: Nullable<string>;
  consolidatedShipment?: Optional<Pick<ConsolidatedShipmentType, 'id' | 'reference'>>;
  modeOfTransport?: Nullable<ModeOfTransport>;
  sticky?: boolean;
  trackingDetails?: Optional<TrackingDetails>;
  zencargoReference: string;
}

const BookingHeader: FC<Props> = (props) => {
  const { clientReference, consolidatedShipment, modeOfTransport, sticky, trackingDetails, zencargoReference } = props;

  const renderTitle = (): ReactNode => {
    return (
      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-2">
          <span>{zencargoReference}</span>
          {trackingDetails && <BookingTrackingIndicator status={trackingDetails.status} />}
        </div>
        <ConsolidatedShipment id={consolidatedShipment?.id} reference={consolidatedShipment?.reference} />
      </div>
    );
  };

  if (sticky) {
    return (
      <div className="flex items-center flex-1 min-w-0 space-x-4" data-testid="sticky-header">
        {modeOfTransport && <ModeOfTransportIcon className="shrink-0" modeOfTransport={modeOfTransport} size="small" />}
        <div className="text-lg font-bold whitespace-nowrap">{renderTitle()}</div>
        <div className="truncate text-grey-base">{clientReference}</div>
      </div>
    );
  }

  return (
    <div className="flex items-start space-x-4">
      {modeOfTransport && <ModeOfTransportIcon className="py-2 pr-2" modeOfTransport={modeOfTransport} size="small" />}
      <PageTitle
        className="flex-1 min-w-0"
        tagline={
          <ClientReferenceContainer
            className="-ml-3.5 -mt-1.5"
            clientReference={clientReference}
            zencargoReference={zencargoReference}
          />
        }
        title={renderTitle()}
      />
    </div>
  );
};

export default BookingHeader;
