import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductCategoriesQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
}>;

export type ProductCategoriesQueryResult = { __typename?: 'Query' } & {
  productCategories: Array<{ __typename?: 'ProductCategory' } & Pick<GraphQLTypes.ProductCategory, 'id' | 'name'>>;
};

export const ProductCategoriesDocument = /* #__PURE__ */ gql`
  query productCategories($accountUuid: String!) {
    productCategories(customerId: $accountUuid) {
      id
      name
    }
  }
`;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *   },
 * });
 */
export function useProductCategoriesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
}
export function useProductCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductCategoriesQueryResult, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
}
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
