import { upperFirst } from 'lodash';
import type { ReactNode } from 'react';

import CargoValue from '@zen/Cargo/components/CargoValue';
import CargoSummary from '@zen/Components/CargoSummary';
import DateWithTimeZone from '@zen/Components/DateWithTimeZone';
import OrdersReferencesList from '@zen/Components/OrdersReferencesList';
import ShipmentReferences from '@zen/Components/ShipmentReferences';
import TransportModeIcon from '@zen/Components/TransportModeIcon';
import { TableColumn, TableLink, Tooltip } from '@zen/DesignSystem';
import { bookingRequestRoutes } from '@zen/Routes';
import { formatDate } from '@zen/utils/dateTime';

import type { BookingRequest, BookingRequestField } from '../types';
import ActionHandleContainer from './ActionHandleContainer';

const geOrderDetailsUrl = ({ rejected, forwarderApproved }: BookingRequestField, id: string): string => {
  const { rejectedOrderDetails, manufacturerRequestOrderDetails, readyForApprovalOrderDetails } = bookingRequestRoutes;

  if (rejected) {
    return rejectedOrderDetails.getUrl(id, 'items');
  }

  if (!forwarderApproved) {
    return manufacturerRequestOrderDetails.getUrl(id, 'items');
  }

  return readyForApprovalOrderDetails.getUrl(id, 'items');
};

export const getColumns = (
  getShipmentDetailsPageLink: (zencargoReference: string, sectionName?: string) => string
): TableColumn<BookingRequest>[] => {
  const transportModeColumn: TableColumn<BookingRequest> = {
    key: 'modeOfTransport',
    title: 'Mode',
    render: (_, { modeOfTransport }: BookingRequest): ReactNode => {
      if (!modeOfTransport) {
        return null;
      }

      return (
        <div className="text-center">
          <TransportModeIcon className="text-xl text-azure-base" mode={modeOfTransport} />
        </div>
      );
    },
    fixed: 'left'
  };

  const referencesColumn: TableColumn<BookingRequest> = {
    key: 'references',
    sortKey: 'zencargoReference',
    title: 'References',
    render: (_, shipment: BookingRequest): ReactNode => {
      const { clientReference, zencargoReference } = shipment;

      return zencargoReference && <ShipmentReferences clientReference={clientReference} zencargoReference={zencargoReference} />;
    },
    fixed: 'left'
  };

  const customerColumn: TableColumn<BookingRequest> = {
    key: 'customerAndRef',
    title: 'Customer',
    sortable: false,
    render: (_, booking: BookingRequest): ReactNode => {
      const customerName: string = booking.customer?.name || '';
      const { zencargoReference } = booking;

      return <TableLink linkTo={getShipmentDetailsPageLink(zencargoReference)}>{customerName}</TableLink>;
    }
  };

  const originColumn: TableColumn<BookingRequest> = {
    key: 'origin',
    title: 'Origin',
    sortable: false
  };

  const destinationColumn: TableColumn<BookingRequest> = {
    key: 'destination',
    title: 'Destination',
    sortable: false
  };

  const poReferencesColumn: TableColumn<BookingRequest> = {
    key: 'purchaseOrders',
    title: 'PO references',
    sortable: false,
    render: (_, { purchaseOrderReferences, zencargoReference, bookingRequest }: BookingRequest): ReactNode => (
      <OrdersReferencesList
        detailsUrl={(id: string) => geOrderDetailsUrl(bookingRequest, id)}
        orderReferencesList={purchaseOrderReferences}
        zencargoReference={zencargoReference}
      />
    )
  };

  const cargoColumn: TableColumn<BookingRequest> = {
    key: 'cargo',
    title: 'Cargo details',
    sortable: false,
    render: (_, { calculatedInfo }: BookingRequest): ReactNode => <CargoSummary summary={calculatedInfo?.cargoSummary} />
  };

  const cargoReadyDateColumn: TableColumn<BookingRequest> = {
    key: 'cargoReadyDate',
    sortable: false,
    title: 'Cargo ready date',
    render: (_, { cargoReadyDate }: BookingRequest): ReactNode => {
      return cargoReadyDate && <a className="cursor-pointer text-navy-base">{formatDate(cargoReadyDate)}</a>;
    }
  };

  const cargoValueColumn: TableColumn<BookingRequest> = {
    key: 'cargoValue',
    dataKey: 'cargo',
    title: 'Cargo value',
    sortKey: 'totalValueOfGoods',
    render: (_, { calculatedInfo }: BookingRequest): ReactNode => (
      <CargoValue
        highestRiskLevel={calculatedInfo?.cargoSummary?.highestRiskLevel}
        totalValueOfGoods={calculatedInfo?.cargoSummary?.totalValueOfGoods}
      />
    )
  };

  const latestDeliveryEstimateColumn: TableColumn<BookingRequest> = {
    key: 'latestDeliveryEstimate',
    sortable: false,
    title: 'Delivery estimate',
    render: (_, { estimatedDelivery }: BookingRequest): ReactNode => {
      return <DateWithTimeZone dateWithTimeZone={estimatedDelivery} />;
    }
  };

  const rejectedReasonColumn: TableColumn<BookingRequest> = {
    key: 'rejectedReason',
    title: 'Reason for rejection',
    sortable: false,
    render: (_, { bookingRequest }: BookingRequest): ReactNode => {
      const { rejectedReason, rejectedAdditionalInfo } = bookingRequest;

      const tooltipContent: ReactNode = (
        <div>
          <div className="font-bold"> Additional information</div>
          <div className="grey-base">{rejectedAdditionalInfo}</div>
        </div>
      );

      const label: string = rejectedReason ? upperFirst(rejectedReason) : '';

      if (!rejectedAdditionalInfo) {
        return label;
      }

      return (
        <Tooltip tooltipContent={tooltipContent}>
          <div className="underline text-navy-base">{label}</div>
        </Tooltip>
      );
    },
    fixed: 'right'
  };

  const estimatedDepartureColumn: TableColumn<BookingRequest> = {
    key: 'estimatedDeparture',
    title: 'ETD',
    className: 'whitespace-nowrap',
    render: (_, { estimatedDeparture }: BookingRequest): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedDeparture} />
  };

  const estimatedArrivalColumn: TableColumn<BookingRequest> = {
    key: 'estimatedArrival',
    title: 'ETA',
    className: 'whitespace-nowrap',
    render: (_, { estimatedArrival }: BookingRequest): ReactNode => <DateWithTimeZone dateWithTimeZone={estimatedArrival} />
  };

  const actionsColumn: TableColumn<BookingRequest> = {
    key: 'actions',
    title: 'Actions',
    render: (_, { bookingRequest, zencargoReference }: BookingRequest): ReactNode => {
      return <ActionHandleContainer bookingRequest={bookingRequest} zencargoReference={zencargoReference} />;
    },
    sortable: false,
    fixed: 'right'
  };

  return [
    transportModeColumn,
    referencesColumn,
    customerColumn,
    cargoValueColumn,
    originColumn,
    destinationColumn,
    poReferencesColumn,
    cargoColumn,
    cargoReadyDateColumn,
    estimatedDepartureColumn,
    estimatedArrivalColumn,
    latestDeliveryEstimateColumn,
    rejectedReasonColumn,
    actionsColumn
  ];
};
