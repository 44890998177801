import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  cargoType?: GraphQLTypes.InputMaybe<GraphQLTypes.CargoModeEnum>;
  customerDivisionId: GraphQLTypes.Scalars['String'];
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  modeOfTransport?: GraphQLTypes.InputMaybe<GraphQLTypes.ModeOfTransport>;
  validOn?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Date']>;
}>;

export type RateCardsQueryResult = { __typename?: 'Query' } & {
  legacyGetRateCards?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyRateCardConnection' } & Pick<GraphQLTypes.LegacyRateCardConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'LegacyRateCardSummary' } & Pick<
                GraphQLTypes.LegacyRateCardSummary,
                'cargoType' | 'endDate' | 'modeOfTransport' | 'name' | 'rateCardId' | 'startDate'
              >
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const RateCardsDocument = /* #__PURE__ */ gql`
  query rateCards(
    $after: String
    $before: String
    $cargoType: CargoModeEnum
    $customerDivisionId: String!
    $first: Int
    $last: Int
    $modeOfTransport: ModeOfTransport
    $validOn: Date
  ) {
    legacyGetRateCards(
      after: $after
      before: $before
      cargoType: $cargoType
      customerDivisionId: $customerDivisionId
      first: $first
      last: $last
      modeOfTransport: $modeOfTransport
      validOn: $validOn
    ) {
      nodes {
        cargoType
        endDate
        modeOfTransport
        name
        rateCardId
        startDate
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useRateCardsQuery__
 *
 * To run a query within a React component, call `useRateCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      cargoType: // value for 'cargoType'
 *      customerDivisionId: // value for 'customerDivisionId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      validOn: // value for 'validOn'
 *   },
 * });
 */
export function useRateCardsQuery(baseOptions: Apollo.QueryHookOptions<RateCardsQueryResult, RateCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardsQueryResult, RateCardsQueryVariables>(RateCardsDocument, options);
}
export function useRateCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateCardsQueryResult, RateCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardsQueryResult, RateCardsQueryVariables>(RateCardsDocument, options);
}
export type RateCardsQueryHookResult = ReturnType<typeof useRateCardsQuery>;
export type RateCardsLazyQueryHookResult = ReturnType<typeof useRateCardsLazyQuery>;
