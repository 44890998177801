import type { FC } from 'react';

import { getStageLabel } from './helpers';
import type { ModeOfTransport, StageNameEnum, StageNameType } from './types';

interface Props {
  name: StageNameEnum;
  transportMode?: ModeOfTransport;
  type?: StageNameType;
}

const StageName: FC<Props> = ({ name, transportMode, type }) => {
  const stageLabel: string = getStageLabel(name, transportMode, type);

  return <span>{stageLabel}</span>;
};

export default StageName;
