import { object, string } from 'yup';

import type { ModeOfTransport } from '@zen/Booking';
import { isRoadBooking } from '@zen/Booking';

const validationSchema = object().shape({
  accountId: string().required('Please select account.'),
  modeOfTransport: string().nullable().required('Please select mode of transport.'),
  collectionWarehouse: object()
    .nullable()
    .when('modeOfTransport', {
      is: (val: ModeOfTransport) => isRoadBooking(val),
      then: (schema) => schema.required('Please select collection warehouse.')
    }),
  deliveryWarehouse: object()
    .nullable()
    .when('modeOfTransport', {
      is: (val: ModeOfTransport) => isRoadBooking(val),
      then: (schema) => schema.required('Please select delivery warehouse.')
    }),
  portOfLoad: object()
    .nullable()
    .when('modeOfTransport', {
      is: (val: ModeOfTransport) => !isRoadBooking(val),
      then: (schema) => schema.required('Please select port of loading.')
    }),
  portOfDestination: object()
    .nullable()
    .when('modeOfTransport', {
      is: (val: ModeOfTransport) => !isRoadBooking(val),
      then: (schema) => schema.required('Please select port of destination.')
    }),
  originAgent: object().nullable().required('Please select origin agent.')
});

export default validationSchema;
