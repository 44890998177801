import type { CargoType } from '@zen/Cargo';
import { CargoItemTypeEnum, LooseCargoTypeEnum, ModeOfTransport } from '@zen/graphql/types.generated';
import { ContainerTypeEnum, PalletTypeEnum, VehicleTypeEnum } from '@zen/types';

export const getContainerLabel = (containerType: ContainerTypeEnum): string => containerTypeLabelMapping[containerType].long;

type ContainerTypeToLabel = Record<ContainerTypeEnum, { long: string; short: string }>;

export const containerTypeLabelMapping: ContainerTypeToLabel = {
  [ContainerTypeEnum.X20DV]: {
    short: "20' ST",
    long: "20' standard"
  },
  [ContainerTypeEnum.X20HC]: {
    short: "20' HC",
    long: "20' high cube"
  },
  [ContainerTypeEnum.X40DV]: {
    short: "40' ST",
    long: "40' standard"
  },
  [ContainerTypeEnum.X40HC]: {
    short: "40' HC",
    long: "40' high cube"
  },
  [ContainerTypeEnum.X45HC]: {
    short: "45' HC",
    long: "45' high cube"
  }
};

export const cargoModeLabel: string = 'Cargo mode';

export const cargoTypeLabelMapping: Record<CargoType | CargoItemTypeEnum, string> = {
  [CargoItemTypeEnum.CONTAINER]: 'Container',
  [CargoItemTypeEnum.LOOSE_CARGO]: 'Loose cargo',
  [CargoItemTypeEnum.VEHICLE]: 'Vehicle',
  [ContainerTypeEnum.X20DV]: getContainerLabel(ContainerTypeEnum.X20DV),
  [ContainerTypeEnum.X20HC]: getContainerLabel(ContainerTypeEnum.X20HC),
  [ContainerTypeEnum.X40DV]: getContainerLabel(ContainerTypeEnum.X40DV),
  [ContainerTypeEnum.X40HC]: getContainerLabel(ContainerTypeEnum.X40HC),
  [ContainerTypeEnum.X45HC]: getContainerLabel(ContainerTypeEnum.X45HC),
  [LooseCargoTypeEnum.ASSORTED_CARGO]: 'Assorted cargo',
  [LooseCargoTypeEnum.BOXES_OR_CRATES]: 'Box or crate',
  [LooseCargoTypeEnum.PALLETS]: 'Pallet',
  [PalletTypeEnum.EURO]: 'Euro pallet',
  [PalletTypeEnum.OTHER]: 'Other pallet',
  [PalletTypeEnum.UK]: 'UK standard pallet',
  [PalletTypeEnum.US]: 'US standard pallet',
  [VehicleTypeEnum.STRAIGHT_TRUCK]: 'Straight truck',
  [VehicleTypeEnum.TRAILER_13_6_M_BOX]: '13.6m box',
  [VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED]: '13.6m curtain sided',
  [VehicleTypeEnum.TRAILER_13_6_M_HIAB]: '13.6m hiab',
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER]: 'Mega boxed trailer',
  [VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED]: 'Mega curtain sided',
  [VehicleTypeEnum.TRAILER_13_6_M_REFRIGERATED]: '13.6m refrigerated',
  [VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF]: '13.6m sliding roof',
  [VehicleTypeEnum.TRAILER_18_T_BOX]: '18t box',
  [VehicleTypeEnum.TRAILER_18_T_HIAB]: '18t hiab',
  [VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED]: '18t curtain sided',
  [VehicleTypeEnum.TRAILER_18_T_REFRIGERATED]: '18t refrigerated',
  [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF]: '18t sliding roof',
  [VehicleTypeEnum.TRAILER_3_5_T_BOX]: '3.5t box',
  [VehicleTypeEnum.TRAILER_3_5_T_CURTAIN_SIDED]: '3.5t curtain sided',
  [VehicleTypeEnum.TRAILER_53_FT_BOXED]: '53ft boxed trailer',
  [VehicleTypeEnum.TRAILER_7_5_T_BOX]: '7.5t box',
  [VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED]: '7.5t curtain sided',
  [VehicleTypeEnum.TRAILER_7_5_T_SLIDING_ROOF]: '7.5t sliding roof',
  [VehicleTypeEnum.VAN_BOX]: 'Van box',
  [VehicleTypeEnum.VAN_CURTAIN_SIDED]: 'Van curtain sided',
  [VehicleTypeEnum.VAN_SLIDING_ROOF]: 'Van sliding roof'
};

export const bookingModeOfTransportLabelMapping: Record<ModeOfTransport, string> = {
  [ModeOfTransport.OCEAN]: 'Ocean',
  [ModeOfTransport.AIR]: 'Air',
  [ModeOfTransport.RAIL]: 'Rail',
  [ModeOfTransport.TRUCK]: 'Road'
};
