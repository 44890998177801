import { Applicability, ChargeBasisGroup, ChargeItemType } from '../types';
import {
  type ChargeCostItem,
  ChargeGroup,
  type ChargeItem,
  type ChargeListItem,
  type ChargeRevenueItem,
  type CostTrackingCostItem,
  type CostTrackingRevenueItem,
  CostTrackingRevenueItemStatus
} from './types';

export const applicabilityLabelMapping: Record<Applicability, string> = {
  [Applicability.BOOKING]: 'Booking',
  [Applicability.DESTINATION]: 'Destination',
  [Applicability.FREIGHT]: 'Freight',
  [Applicability.ORIGIN]: 'Origin'
};

export const chargeItemTypeLabelMapping: Record<ChargeItemType, string> = {
  [ChargeItemType.COST]: 'Costs',
  [ChargeItemType.REVENUE]: 'Revenue'
};

export const chargeBasisLabelMapping: Record<ChargeBasisGroup, string> = {
  [ChargeBasisGroup.BILL]: 'Bill',
  [ChargeBasisGroup.BOOKING]: 'Booking',
  [ChargeBasisGroup.CARGO]: 'Cargo'
};

const chargeGroupAndApplicabilityIdentifiers: string[] = [
  `${Applicability.ORIGIN} ${ChargeGroup.HAULAGE}`,
  `${Applicability.ORIGIN} ${ChargeGroup.WAREHOUSING}`,
  `${Applicability.ORIGIN} ${ChargeGroup.PORT_CHARGES}`,
  `${Applicability.ORIGIN} ${ChargeGroup.CUSTOMS_CLEARANCE}`,
  `${Applicability.FREIGHT} ${ChargeGroup.FREIGHT}`,
  `${Applicability.DESTINATION} ${ChargeGroup.CUSTOMS_CLEARANCE}`,
  `${Applicability.DESTINATION} ${ChargeGroup.PORT_CHARGES}`,
  `${Applicability.DESTINATION} ${ChargeGroup.WAREHOUSING}`,
  `${Applicability.DESTINATION} ${ChargeGroup.HAULAGE}`,
  `${Applicability.BOOKING} ${ChargeGroup.BOOKING_MANAGEMENT}`,
  `${Applicability.BOOKING} ${ChargeGroup.ADDITIONAL_CHARGES}`
];

const chargeTypeComparator = (item: ChargeListItem, secondItem: ChargeListItem) => {
  const nameA = `${item.chargeType.name} ${item.chargeTypeGroup}`;
  const nameB = `${secondItem.chargeType.name} ${secondItem.chargeTypeGroup}`;

  if (nameA < nameB) {
    return -1;
  }

  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

const chargeGroupAndApplicabilityComparator = (item: ChargeListItem, secondItem: ChargeListItem) => {
  const first: string = `${item.applicability} ${item.chargeType.group.name}`;
  const second: string = `${secondItem.applicability} ${secondItem.chargeType.group.name}`;

  return chargeGroupAndApplicabilityIdentifiers.indexOf(first) - chargeGroupAndApplicabilityIdentifiers.indexOf(second);
};

const enhanceCostOrRevenueItemWithChargeItem = (
  costRevenueItem: CostTrackingCostItem | CostTrackingRevenueItem,
  item: ChargeItem,
  type: ChargeItemType
): ChargeCostItem | ChargeRevenueItem => ({
  ...costRevenueItem,
  chargeTypeGroup: type,
  chargeId: item.id,
  applicability: item.applicability,
  chargeType: item.chargeType
});

const extractChargeCostAndRevenueItems = (chargeItem: ChargeItem): ChargeListItem[] => {
  const chargeListItems: ChargeListItem[] = [];

  chargeItem.costItems?.forEach((costItem: CostTrackingCostItem) => {
    const modifiedItem = enhanceCostOrRevenueItemWithChargeItem(costItem, chargeItem, ChargeItemType.COST) as ChargeCostItem;

    chargeListItems.push(modifiedItem);
  });

  chargeItem.revenueItems?.forEach((revenueItem: CostTrackingRevenueItem) => {
    const modifiedItem = enhanceCostOrRevenueItemWithChargeItem(
      revenueItem,
      chargeItem,
      ChargeItemType.REVENUE
    ) as ChargeRevenueItem;

    chargeListItems.push(modifiedItem);
  });

  return chargeListItems;
};

const getChargesListItems = (charges: ChargeItem[]): ChargeListItem[] => {
  return charges.map(extractChargeCostAndRevenueItems).flat();
};

export const prepareChargesList = (charges: ChargeItem[]): ChargeListItem[] => {
  return getChargesListItems(charges).sort(chargeTypeComparator).sort(chargeGroupAndApplicabilityComparator);
};

export const getChargesTableId = (): string => 'charges';

export const isChargeItemEditable = (chargeItem: ChargeListItem) => {
  return chargeItem.status === CostTrackingRevenueItemStatus.DRAFT;
};
