import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CountriesQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type CountriesQueryResult = { __typename?: 'Query' } & {
  countries?: GraphQLTypes.Maybe<Array<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>>>;
};

export const CountriesDocument = /* #__PURE__ */ gql`
  query countries {
    countries {
      code
      name
    }
  }
`;

/**
 * __useCountriesQuery__
 *
 * To run a query within a React component, call `useCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQueryResult, CountriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CountriesQueryResult, CountriesQueryVariables>(CountriesDocument, options);
}
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQueryResult, CountriesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CountriesQueryResult, CountriesQueryVariables>(CountriesDocument, options);
}
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
