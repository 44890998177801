import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useCreateVehicleCargoItemMutation } from '../../../graphql';
import {
  CargoItemTypeEnum,
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  type CreateVehicleCargoItemInput
} from '../../../types';
import FullTruckForm from '../FullTruckForm/FullTruckForm';
import type { FullTruckFormValues } from '../FullTruckForm/types';
import { prepareFullTruckValues } from '../helpers';

interface Props {
  onCancel: () => void;
  onSuccess: (cargoId: string) => void;
  zencargoReference: string;
}

const NewFullTruck: FC<Props> = ({ zencargoReference, onCancel, onSuccess }) => {
  const { trackEvent } = useTracking();
  const [newVehicle] = useCreateVehicleCargoItemMutation();
  const { addError, addSuccess } = useNotification();

  const handleSubmit = async (values: FullTruckFormValues): Promise<IOkOrErrorResult> => {
    const input: CreateVehicleCargoItemInput = {
      zencargoReference,
      ...prepareFullTruckValues(values)
    };

    return performMutation({
      mutationFn: () =>
        newVehicle({
          variables: {
            input
          },
          refetchQueries: ['cargoSummary', 'cargoOverview'],
          awaitRefetchQueries: true
        }),
      onError: () => addError()
    });
  };

  const handleSuccess = (cargoId: string): void => {
    addSuccess('New vehicle has been created.');
    onSuccess(cargoId);

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.VEHICLE
    });
  };

  return <FullTruckForm onCancel={onCancel} onSubmit={handleSubmit} onSuccess={handleSuccess} />;
};

export default NewFullTruck;
