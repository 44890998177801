import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import FiltersComponent from '@zen/Components/Filters';
import type { FilterItemType } from '@zen/Components/Filters/types';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, useUsedAssignables } from '@zen/Networks';
import useOrderFilters from '@zen/Orders/hooks/useOrderFilters';
import type { OrderFilters } from '@zen/Orders/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';

import OrdersSearch from '../OrdersSearch';
import { buildFilters } from './helpers';

interface Props {
  showSearch: boolean;
}

const Filters: FC<Props> = ({ showSearch }) => {
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const domainNames: AssignmentTargetTypeEnum[] = [AssignmentTargetTypeEnum.PURCHASE_ORDER];
  const assignmentTypes: AssignmentTypeValue[] = [AssignmentTypeValue.MANUFACTURER];

  const { data: manufacturers } = useUsedAssignables(domainNames, assignmentTypes);
  const { data: filterOptions } = useOrderFilters();

  const shouldRenderCustomerFilter: boolean = filterOptions ? checkPermission(filterOptions, 'canViewCustomerFilter') : false;

  const filtersOptions: FilterItemType<OrderFilters>[] = buildFilters(
    {
      accounts: filterOptions?.accounts || [],
      customers: filterOptions?.customers || [],
      issueTitles: filterOptions?.issueTitles || [],
      manufacturers: manufacturers || [],
      polTerminals: filterOptions?.portsOfLoad || [],
      podTerminals: filterOptions?.portsOfDestination || []
    },
    shouldRenderCustomerFilter
  );

  return (
    <div className="flex space-x-2" data-testid="order-filters">
      {showSearch && <OrdersSearch />}
      <FiltersComponent items={filtersOptions} onChange={setAppliedFilters} value={appliedFilters} />
    </div>
  );
};

export default Filters;
