export const PARENT_ATTRIBUTE = 'data-tracking-parent';

const isTrackingParent = (target: EventTarget): target is HTMLElement => {
  if (target instanceof Document || target instanceof Window) {
    return false;
  }

  const element = target as Element;

  return element.hasAttribute != null && element.hasAttribute(PARENT_ATTRIBUTE);
};

export const getTrackingParents = (nativeEvent: Event): Element[] => {
  return nativeEvent.composedPath().filter(isTrackingParent);
};

export const getTrackingParentAttributeValue = (parentEl: Element): string => {
  return parentEl.getAttribute(PARENT_ATTRIBUTE) || '';
};

export const createTrackingParentAttribute = (
  parentName: string
): {
  [key: string]: string;
} => {
  return {
    [`${PARENT_ATTRIBUTE}`]: parentName
  };
};
