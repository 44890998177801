import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { Counter, Tooltip } from '@zen/DesignSystem';
import type { PurchaseOrderLotStageCountType } from '@zen/Orders';
import { lotStageLabelMapping } from '@zen/Orders/utils';

interface Props {
  lotsByStage: PurchaseOrderLotStageCountType[];
  lotsTotalCount: number;
}

const OrderStageCell: FC<Props> = ({ lotsByStage, lotsTotalCount }) => {
  const visibleLotsCount: number = 2;
  const lotStageCount: number = lotsByStage.length;

  const renderLotRow = ({ stage, count }: PurchaseOrderLotStageCountType): ReactNode => {
    return (
      <>
        {stage && <span className="font-bold">{lotStageLabelMapping[stage]}</span>}
        <span className="text-sm text-grey-base">
          &nbsp;{count}/{lotsTotalCount} {pluralize('lot', lotsTotalCount)}
        </span>
      </>
    );
  };

  const renderCounterTooltip = (): ReactNode => {
    const tooltipLotsCount: number = lotStageCount - visibleLotsCount;
    const tooltipContent: ReactNode = (
      <div>
        {lotsByStage.slice(visibleLotsCount).map((lotStage: PurchaseOrderLotStageCountType, index: number): ReactNode => {
          return (
            <div key={index} className="leading-normal">
              {renderLotRow(lotStage)}
            </div>
          );
        })}
      </div>
    );

    return (
      <Tooltip placement="top-end" tooltipContent={tooltipContent}>
        <div className="inline ml-4">
          <Counter prefix="+" showCounterLimit={false} value={tooltipLotsCount} />
        </div>
      </Tooltip>
    );
  };

  return (
    <div className="relative">
      {lotsByStage.slice(0, visibleLotsCount).map((lotStage: PurchaseOrderLotStageCountType, index: number): ReactNode => {
        const showCounter: boolean = lotStageCount > visibleLotsCount && index === 1;

        return (
          <div key={index} className="leading-normal">
            {renderLotRow(lotStage)}
            {showCounter && renderCounterTooltip()}
          </div>
        );
      })}
    </div>
  );
};

export default OrderStageCell;
