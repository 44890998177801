import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking/types';
import LabelledValue from '@zen/Components/LabelledValue';
import ModeOfTransportSelect from '@zen/Components/ModeOfTransportSelect';
import { bookingModeOfTransportLabelMapping } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateModeOfTransportMutation } from './graphql';

interface Props {
  canUpdateModeOfTransport: boolean;
  hasCargoItems: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  onSuccess?: () => void;
  zencargoReference: string;
}

const refetchQueries = ['getCargoDetailsQuery', 'cargoItems', 'orderDetailsQuery'];

const ModeOfTransportInlineField: FC<Props> = (props) => {
  const { hasCargoItems, modeOfTransport, zencargoReference, onSuccess, canUpdateModeOfTransport } = props;
  const { addSuccess, addError } = useNotification();
  const [updateModeOfTransport, { loading }] = useUpdateModeOfTransportMutation();

  const isDisabled: boolean = !canUpdateModeOfTransport || hasCargoItems || loading;
  const showTooltip: boolean = canUpdateModeOfTransport && hasCargoItems;

  const label: string = 'Mode of transport';
  const labelTooltip: ReactNode = showTooltip ? 'Please delete all cargo items to change the mode of transport' : null;

  const handleUpdateModeOfTransport = async (modeOfTransportValue: Nullable<ModeOfTransport>): Promise<void> => {
    if (modeOfTransportValue) {
      await performMutation({
        mutationFn: () =>
          updateModeOfTransport({
            awaitRefetchQueries: true,
            variables: {
              input: {
                zencargoReference,
                modeOfTransport: modeOfTransportValue
              }
            },
            refetchQueries
          }),
        onError: () => addError(),
        onSuccess: () => {
          onSuccess?.();
          addSuccess('Mode of transport updated.');
        }
      });
    }
  };

  if (!canUpdateModeOfTransport) {
    return (
      <LabelledValue label={label} variant="default">
        {modeOfTransport ? bookingModeOfTransportLabelMapping[modeOfTransport] : '-'}
      </LabelledValue>
    );
  }

  return (
    <div className="-ml-3">
      <ModeOfTransportSelect
        isDisabled={isDisabled}
        isLoading={loading}
        label={label}
        labelTooltip={labelTooltip}
        onChange={handleUpdateModeOfTransport}
        renderMenuInPortal={true}
        value={modeOfTransport}
        variant="inline"
      />
    </div>
  );
};

export default ModeOfTransportInlineField;
