import * as Yup from 'yup';

import { addressValidation } from '../networks.validation';
import type { OrgLocFormType } from './types';

const organisationNameSchema = (formType: OrgLocFormType) => {
  return Yup.object().shape({
    ...(formType === 'organisation' && { name: Yup.string().required('Organisation name is required').nullable() }),
    ...(formType === 'location' && { organisation: Yup.object().required('Organisation is required').nullable() })
  });
};

const orgLocBaseValidation = Yup.object().shape({
  ...addressValidation
});

export const createValidationSchema = (formType: OrgLocFormType): Yup.ObjectSchema<{}> => {
  return orgLocBaseValidation.concat(organisationNameSchema(formType));
};
