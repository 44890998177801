import copy from 'copy-to-clipboard';
import type { FC } from 'react';

import { Banner, Icon, IconButton, Input } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  password: string;
  recipientEmail: string;
}

const PasswordCollectionPanel: FC<Props> = ({ password, recipientEmail }) => {
  const { addSuccess } = useNotification();

  const handleCopyPassword = (): void => {
    copy(password);
    addSuccess('Copied to clipboard.');
  };

  return (
    <>
      <div className="flex items-center">
        <Icon className="mr-4 text-xl text-green-base" icon="zicon-tickoval" />
        <div>
          The email containing the encrypted file with the API key has been successfully sent to{' '}
          <span className="font-bold">{recipientEmail}</span>
        </div>
      </div>
      <div className="flex items-end gap-2 py-4">
        <div className="flex-1 ">
          <label className="block mb-1">Password</label>
          <Input aria-label="PDF password" disabled={true} value={password} />
        </div>
        <IconButton icon="zicon-copy" onClick={handleCopyPassword} title="Copy" variant="ghost" />
      </div>
      <Banner
        className="p-4 my-4"
        message="This password is used to unlock the encrypted file. Kindly share it exclusively via SMS or another non-email channel."
      />
    </>
  );
};

export default PasswordCollectionPanel;
export type { Props as PasswordCollectionProps };
