import { flatten } from 'lodash';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import { Button } from '@zen/DesignSystem';
import useTracking from '@zen/utils/hooks/useTracking';

import FilterChips from './FilterChips/FilterChips';
import FilterOptions from './FilterOptions';
import { getInitialFilters, hasSections } from './helpers';
import { type FilterItemType, FILTERS_CATEGORY, FiltersAction, FilterSection } from './types';

interface Props<FiltersType extends {}> {
  items: Array<FilterItemType<FiltersType>> | Array<FilterSection<FilterItemType<FiltersType>>>;
  onChange: (value: FiltersType) => void;
  value: FiltersType;
}

const Filters = <T extends object>(props: Props<T>) => {
  const { items, value, onChange } = props;
  const [selectedFilters, setSelectedFilters] = useState<FilterItemType<T>[]>([]);
  const { trackEvent } = useTracking();
  const [activeItemKey, setActiveItemKey] = useState<keyof T>();
  const selectedFilterKeys: (keyof T)[] = selectedFilters.map((filter: FilterItemType<T>) => filter.key);
  const shouldDisplayClearFiltersButton: boolean =
    selectedFilters.filter((selectedFilter) => !selectedFilter.disabled).length > 1;

  const filterItems: Array<FilterItemType<T>> = hasSections(items) ? flatten(items.map((item) => item.items)) : items;

  useDeepCompareEffect(() => {
    setSelectedFilters(getInitialFilters(value, filterItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useDeepCompareEffect(() => {
    setSelectedFilters((previousFilters: FilterItemType<T>[]) => {
      return previousFilters.map((previousFilter: FilterItemType<T>) => {
        const selectedItem = filterItems.find((item: FilterItemType<T>) => item.key === previousFilter.key);

        return selectedItem || previousFilter;
      });
    });
  }, [items]);

  const handleFiltersChange = (filterValues: T) => {
    trackEvent({
      category: FILTERS_CATEGORY,
      action: FiltersAction.FILTERS_CHANGED,
      label: 'Filters changed',
      properties: {
        ...filterValues
      }
    });

    onChange(filterValues);
  };

  const handleClear = (): void => {
    trackEvent({
      category: FILTERS_CATEGORY,
      action: FiltersAction.FILTERS_CLEARED,
      label: 'Clear filters'
    });

    handleFiltersChange({} as T);
    setSelectedFilters([]);
  };

  const handleDeleteFilter = (key: keyof T): void => {
    trackEvent({
      category: FILTERS_CATEGORY,
      action: FiltersAction.FILTER_REMOVED,
      label: key as string
    });

    handleFiltersChange({ ...value, [key]: null });
    setSelectedFilters((previousFilters: FilterItemType<T>[]) =>
      previousFilters.filter((previousFilter: FilterItemType<T>) => previousFilter.key !== key)
    );
  };

  const handleAddNewFilter = (item: FilterItemType<T>): void => {
    setSelectedFilters([...new Set([item, ...selectedFilters])]);
  };

  const handleChange = (key: keyof T, filterValue: unknown): void => {
    trackEvent({
      category: FILTERS_CATEGORY,
      action: FiltersAction.FILTER_APPLIED,
      label: key as string,
      properties: {
        value: filterValue
      }
    });
    handleFiltersChange({ ...value, [key]: filterValue });
  };

  const deselectFilter = (): void => {
    setActiveItemKey(undefined);
  };

  return (
    <div className="flex items-center space-x-2 overflow-hidden">
      <FilterOptions
        addNewFilter={handleAddNewFilter}
        items={items}
        openFilter={setActiveItemKey}
        selectedFilterKeys={selectedFilterKeys}
      />
      <div className="flex space-x-2 overflow-x-scroll scrollbar-hidden">
        <FilterChips
          activeItemKey={activeItemKey as string}
          deselectFilter={deselectFilter}
          filters={selectedFilters}
          onChange={handleChange}
          onClick={setActiveItemKey}
          onDelete={handleDeleteFilter}
          value={value}
        />
        {shouldDisplayClearFiltersButton && (
          <Button onClick={handleClear} size="compact" variant="ghost">
            Clear filters
          </Button>
        )}
      </div>
    </div>
  );
};

export default Filters;
