import type { FC } from 'react';

import { Button, Counter } from '@zen/DesignSystem';

interface Props {
  className?: string;
  filterCount: number;
  label?: string;
  onClick: () => void;
}

const FiltersButton: FC<Props> = ({ filterCount, label = 'Filters', onClick, className }) => {
  return (
    <Button className={className} data-testid="filters-button" iconLeft="zicon-filter" onClick={onClick} variant="ghost">
      {label}
      {filterCount > 0 && <Counter className="ml-2" size="small" type="selected" value={filterCount} />}
    </Button>
  );
};

export type { Props as FiltersButtonProps };

export default FiltersButton;
