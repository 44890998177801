import { CargoModeEnum, ModeOfTransport } from '@zen/types';

import type { RateCardSummary } from '../types';

export const createListRateCard = (override: Partial<RateCardSummary> = {}): RateCardSummary => ({
  cargoType: CargoModeEnum.FCL,
  endDate: '2023-01-15',
  modeOfTransport: ModeOfTransport.OCEAN,
  name: 'EdnaModeInc.Feb23.OF2',
  rateCardId: '1',
  startDate: '2022-12-12',
  ...override
});
