import { chargeBasisLabelMapping } from '@zen/Accruals/ChargeTypes/ChargeTypeList/chargeTypeList.helpers';
import { incotermsLabelMapping } from '@zen/Booking/helpers';
import { getChargeTypeAttributes } from '@zen/Components';
import type { ChargeType } from '@zen/Components/Form/FormChargeTypeSelect';
import type { Terminal } from '@zen/Components/TerminalSelect';
import type { NetworksOrgLocOption } from '@zen/Networks';
import {
  bookingModeOfTransportLabelMapping,
  ContainerTypeEnum,
  containerTypeLabelMapping,
  Currency,
  IncotermsValue
} from '@zen/types';
import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import type { RateCard } from '../types';

const formatChargeCurrency = (value: number, currency: Currency): string => {
  return `${formatNumber(value, 2, 2)} ${currency}`;
};

export const formatChargeBasis = (chargeType: Optional<ChargeType>): string =>
  chargeType?.chargeBasis ? chargeBasisLabelMapping[chargeType.chargeBasis] : '-';

export const formatLocation = (location: NetworksOrgLocOption) => location.label?.long || '-';

export const formatPort = (port: Terminal): string => port.label || '-';

export const formatChargeType = (chargeType: ChargeType): string => chargeType.name;

export const formatUnit = (chargeType: Optional<ChargeType>): string => {
  if (!chargeType) {
    return '-';
  }

  return getChargeTypeAttributes(chargeType) || '-';
};

export const formatIncoterms = (incoterms: IncotermsValue[]): string => {
  const incotermLabels: string[] = incoterms.map((incoterm: IncotermsValue) => incotermsLabelMapping[incoterm]);

  return incotermLabels.join(', ');
};

export const formatIssuedBy = (issuedBy: RateCard['issuedBy']): string =>
  issuedBy ? `${issuedBy?.firstName} ${issuedBy?.lastName}` : '-';

export const formatModeOfTransport = (modeOfTransport: RateCard['modeOfTransport']): string =>
  bookingModeOfTransportLabelMapping[modeOfTransport];

export const formatCost = (chargeValue: Optional<number>, currency: Optional<Currency>): string => {
  if (!chargeValue || !currency) {
    return '-';
  }

  return formatChargeCurrency(chargeValue, currency);
};

export const formatContainerType = (unitType: ContainerTypeEnum): string => containerTypeLabelMapping[unitType].short;

export const formatCharacteristics = (chargeType: ChargeType): string => {
  const refeer: string | null = chargeType.reefer ? 'Refrigerated' : null;
  const hazardous: string | null = chargeType.hazardous ? 'Hazardous' : null;
  const overweight: string | null = chargeType.overweight ? 'Overweight' : null;

  return [refeer, hazardous, overweight].filter(Boolean).join(', ');
};
