import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import CargoOptionsSelect from '../CargoOptionsSelect';
import type { CargoOptionEnum } from '../types';

const FormCargoOptionsSelect: FC<FormFieldProps> = (props) => {
  return (
    <FormField {...props}>
      {(
        field: { error: boolean; name: string; options: Option<CargoOptionEnum>[]; value: CargoOptionEnum[] },
        form: FormInstance<unknown>
      ) => (
        <CargoOptionsSelect
          {...field}
          hasError={field.error}
          onChange={(value: Nullable<string[]>) => {
            form.setFieldValue(field.name, value || null);
          }}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormCargoOptionsSelect;
