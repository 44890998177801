import { useParams } from 'react-router-dom';

import { type RateCardDetailsQueryHookResult, useRateCardDetailsQuery } from '../graphql';
import type { RateCardDetailsQueryHookVariables } from './types';

const useRateCardDetails = (variables: RateCardDetailsQueryHookVariables = {}): RateCardDetailsQueryHookResult => {
  const { rateCardId } = useParams<{ rateCardId?: string }>();

  return useRateCardDetailsQuery({
    variables: { id: rateCardId || '', ...variables },
    skip: !rateCardId,
    fetchPolicy: 'no-cache'
  });
};

export default useRateCardDetails;
