const OPS_DASHBOARD_RELATIVE_INDEX_PATH = 'ops-dashboard';

export const OPS_DASHBOARD_ROUTE_PREFIX = `/dashboard/${OPS_DASHBOARD_RELATIVE_INDEX_PATH}`;

interface OpsDashboardRoutes {
  dashboardIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const opsDashboardRoutes: OpsDashboardRoutes = {
  dashboardIndex: {
    path: `${OPS_DASHBOARD_ROUTE_PREFIX}/*`,
    relativePath: `${OPS_DASHBOARD_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => OPS_DASHBOARD_ROUTE_PREFIX
  }
};

export default opsDashboardRoutes;
