import { omit } from 'lodash';

import type { IOrganisationFormFields } from '../Form/OrganisationForm/types';
import { prepareLocationInput } from '../networksHelpers';
import type { CreateOrganisationInput, LocationData } from '../types';

export const prepareRequestData = (values: IOrganisationFormFields, accountUuid: string): CreateOrganisationInput => {
  const { name, type } = values;

  const locationData: LocationData = omit(values, 'name');

  return {
    networkId: accountUuid,
    name,
    type,
    location: prepareLocationInput(locationData)
  };
};
