import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ModeOfTransport } from '@zen/Booking';
import { CargoModeEnum } from '@zen/Cargo';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { rateCardRoutes } from '@zen/Routes';
import { FiltersContextProvider } from '@zen/utils/Filtering/contexts/FiltersContext';

import { getRateCardsComponentsConfig, type RateCardsConfig } from './helpers';
import type { RateCardsFilters } from './LegacyRateCards/types';

export const initialFilters: RateCardsFilters = {
  modeOfTransport: ModeOfTransport.OCEAN,
  cargoType: CargoModeEnum.FCL,
  date: undefined
};

const RateCardsContainer: FC = () => {
  const rateCardsFlagIsOn = useFeatureIsOn('rate-cards');
  const { create, details, edit, index }: RateCardsConfig = getRateCardsComponentsConfig(rateCardsFlagIsOn);

  return (
    <FiltersContextProvider filterName="rateCardsFilters" initialFilters={initialFilters}>
      <Routes>
        <Route element={details} path={rateCardRoutes.details.relativePath} />
        <Route element={edit} path={rateCardRoutes.edit.relativePath} />
        <Route element={create} path={rateCardRoutes.new.relativePath} />
        <Route element={index} index={true} />
      </Routes>
    </FiltersContextProvider>
  );
};

export default RateCardsContainer;
