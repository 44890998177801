/* eslint-disable react/jsx-no-useless-fragment */
import { isEmpty, isObject } from 'lodash';
import type { ReactElement, ReactNode } from 'react';

import { Loading } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import NoResults from '../NoResults';

interface Props<T> {
  children: (result: T) => ReactElement | null;
  data: Optional<T>;
  error: boolean;
  errorComponent?: ReactNode;
  isLoading: boolean;
  loadingComponent?: ReactNode;
  noResults?: ReactNode;
}

const QueryHandler = <T extends {}>(props: Props<T>) => {
  const {
    children,
    error,
    data,
    isLoading,
    errorComponent = <NoResults headline="Something went wrong" tagline="Please try again later." />,
    noResults = <NoResults headline="No results found" tagline="Please change your search query and try again." />,
    loadingComponent = <Loading />
  } = props;

  const hasData: boolean = Array.isArray(data) || isObject(data) ? !isEmpty(data) : !!data;

  if (isLoading) {
    return <>{loadingComponent}</>;
  }

  if (error) {
    return <>{errorComponent}</>;
  }

  if (!hasData) {
    return <>{noResults}</>;
  }

  return children(data!);
};

export default QueryHandler;
