import { type FC, ReactNode, useState } from 'react';

import EventLog, { EventLogSkeleton } from '@zen/Components/EventLog';
import QueryHandler from '@zen/Components/QueryHandler';
import { Group, Icon, Pagination, Select, SelectOption } from '@zen/DesignSystem';
import type { JourneyShippingMilestoneNameEnum } from '@zen/graphql/types.generated';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { isSharedMilestone } from '../JourneyDetails/helpers';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import NoMilestoneHistory from '../NoMilestoneHistory';
import NoMilestoneSelected from '../NoMilestoneSelected';
import { prepareEvents } from './helpers';
import useMilestoneDateHistory from './hooks/useMilestoneDateHistory';
import type { JourneyMilestoneDateLog } from './types';

interface Props {
  defaultSelectedMilestone?: Optional<string>;
  milestoneOptions: SelectOption<string>[];
  onMilestoneSelect: (milestoneId: Nullable<string>) => void;
}

const MilestoneDatesHistory: FC<Props> = ({ defaultSelectedMilestone = null, milestoneOptions, onMilestoneSelect }) => {
  const [selectedMilestone, setSelectedMilestone] = useState<Nullable<string>>(defaultSelectedMilestone);

  const { loading, error, nodes, paginationInfo } = useMilestoneDateHistory(selectedMilestone);
  const { milestones } = useJourneyContext();

  const selectedMilestoneName: Optional<JourneyShippingMilestoneNameEnum> = selectedMilestone
    ? milestones.find((milestone) => milestone.id === selectedMilestone)?.name
    : null;

  const isSelectedMilestoneShared: boolean = selectedMilestoneName ? isSharedMilestone(selectedMilestoneName) : false;

  const formatGroupLabel = ({ label }: Group<String>): ReactNode => {
    return (
      <div className="flex items-center min-w-0 space-x-2">
        <Icon className="text-base" icon="zicon-pin" />
        <div className="truncate">{label}</div>
      </div>
    );
  };

  const handleMilestoneSelect = (milestoneId: Nullable<string>): void => {
    setSelectedMilestone(milestoneId);
    onMilestoneSelect(milestoneId);
  };

  return (
    <div data-testid="milestone-date-history">
      <div className="px-6 py-3 border-b border-solid border-grey-lighter">
        <Select
          className="w-1/2"
          formatGroupLabel={formatGroupLabel}
          isClearable={true}
          isSearchable={false}
          name="milestones"
          onChange={handleMilestoneSelect}
          options={milestoneOptions}
          value={selectedMilestone}
        />
      </div>
      {!selectedMilestone && <NoMilestoneSelected />}
      {selectedMilestone && (
        <QueryHandler
          data={nodes}
          error={!!error}
          isLoading={loading}
          loadingComponent={<EventLogSkeleton />}
          noResults={<NoMilestoneHistory />}
        >
          {(logs: JourneyMilestoneDateLog[]) => (
            <div className="p-6">
              <div className="flex-1 h-[calc(100vh-17.5rem)] overflow-y-auto mb-4">
                <EventLog events={prepareEvents(logs, isSelectedMilestoneShared)} />
              </div>
              <Pagination pageInfo={paginationInfo} />
            </div>
          )}
        </QueryHandler>
      )}
    </div>
  );
};

export default MilestoneDatesHistory;
