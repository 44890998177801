import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShipmentActionTypesQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetShipmentActionTypesQueryResult = { __typename?: 'Query' } & {
  shipmentActionTypes: { __typename?: 'ShipmentActionTypeConnection' } & Pick<
    GraphQLTypes.ShipmentActionTypeConnection,
    'totalCount'
  > & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ShipmentActionType' } & Pick<GraphQLTypes.ShipmentActionType, 'id' | 'description' | 'name' | 'title'>
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetShipmentActionTypesDocument = /* #__PURE__ */ gql`
  query getShipmentActionTypes(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchQuery: String
    $order: SortInput
  ) {
    shipmentActionTypes(after: $after, before: $before, first: $first, last: $last, textContains: $searchQuery, order: $order) {
      nodes {
        id
        description
        name
        title
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetShipmentActionTypesQuery__
 *
 * To run a query within a React component, call `useGetShipmentActionTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentActionTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentActionTypesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetShipmentActionTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetShipmentActionTypesQueryResult, GetShipmentActionTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShipmentActionTypesQueryResult, GetShipmentActionTypesQueryVariables>(
    GetShipmentActionTypesDocument,
    options
  );
}
export function useGetShipmentActionTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentActionTypesQueryResult, GetShipmentActionTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShipmentActionTypesQueryResult, GetShipmentActionTypesQueryVariables>(
    GetShipmentActionTypesDocument,
    options
  );
}
export type GetShipmentActionTypesQueryHookResult = ReturnType<typeof useGetShipmentActionTypesQuery>;
export type GetShipmentActionTypesLazyQueryHookResult = ReturnType<typeof useGetShipmentActionTypesLazyQuery>;
