import { useContext, useMemo } from 'react';

import TrackingContext from '@zen/Components/TrackingProvider/TrackingContext';

const useTracking = () => {
  const { trackEvent, identify } = useContext(TrackingContext);

  return useMemo(() => ({ trackEvent, identify }), [trackEvent, identify]);
};

export default useTracking;
