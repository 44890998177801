import { AccountType, type CurrentUserResponse, type UserProfile } from './types';

export const getAccountInformation = ({ account, legacyAccount }: CurrentUserResponse) => {
  if (account) {
    const { parentAccountId, tradingName, type } = account;
    const isDivision: boolean = type === AccountType.DIVISION;

    return {
      division: isDivision && parentAccountId ? { name: tradingName, parentAccountId } : null,
      isDivision,
      isParent: type === AccountType.PARENT,
      registeredCompanyName: tradingName
    };
  }

  return {
    division: legacyAccount?.division,
    isDivision: legacyAccount?.isDivision || false,
    isParent: legacyAccount?.isParent || false,
    registeredCompanyName: legacyAccount?.name || ''
  };
};

export const getUserInformation = ({ legacyAccount, currentUserDetails, currentUser: legacyUser }: CurrentUserResponse) => {
  if (currentUserDetails) {
    const { email, firstName, id, isProfileCompleted, lastName, phoneNumber, account } = currentUserDetails;

    return {
      firstName: firstName || '',
      lastName: lastName || '',
      email: email || '',
      phoneNumber: phoneNumber || '',
      uuid: id || '',
      role: null,
      isSingleSignOn: account.ssoDetails.enabled || false,
      isProfileCompleted
    };
  }

  return {
    firstName: legacyUser?.profile?.firstName || '',
    lastName: legacyUser?.profile?.lastName || '',
    email: legacyUser?.profile?.email || '',
    phoneNumber: legacyUser?.profile?.phoneNumber || '',
    uuid: legacyUser?.uuid || '',
    role: null,
    isSingleSignOn: legacyAccount?.ssoDetails?.enabled || false,
    isProfileCompleted: !!legacyUser?.profile?.firstName && !!legacyUser?.profile?.lastName
  };
};

export const prepareUserProfile = (values: CurrentUserResponse): UserProfile => {
  return {
    ...values.authorization,
    ...getUserInformation(values),
    ...getAccountInformation(values)
  };
};

export const saveUserData = ({ division, isAdmin, isParent, registeredCompanyName, uuid }: UserProfile) => {
  window.localStorage.setItem('accountName', registeredCompanyName);
  window.localStorage.setItem('isAdmin', `${isAdmin}`);
  window.localStorage.setItem('isDivision', `${!!division}`);
  window.localStorage.setItem('isParent', `${!!isParent}`);
  window.localStorage.setItem('userId', uuid);
};
