import { FC, ReactElement, ReactNode, useRef } from 'react';
import { useDayRender } from 'react-day-picker';

import Tooltip from '../../../Tooltip';
import { useDatePickerContext } from '../../DatePicker.context';
import { convertDateObjectIntoDateString, getTooltipContent } from '../../helpers';

interface Props {
  date: Date;
  displayMonth: Date;
}

const Day: FC<Props> = ({ date, displayMonth }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dayRender = useDayRender(date, displayMonth, buttonRef);
  const { tooltip } = useDatePickerContext();

  const tooltipContent: ReactNode | undefined = tooltip ? getTooltipContent(date, tooltip) : undefined;

  const renderDay = (): ReactElement => {
    return (
      <button
        ref={buttonRef}
        data-testid={`day-${convertDateObjectIntoDateString(date)}`}
        name="day"
        type="button"
        {...dayRender.buttonProps}
      />
    );
  };

  const renderTooltip = (): ReactElement => {
    return (
      <Tooltip offset={[0, 20]} tooltipContent={tooltipContent}>
        {renderDay()}
      </Tooltip>
    );
  };

  if (dayRender.isHidden) {
    return null;
  }

  return tooltipContent ? renderTooltip() : renderDay();
};

export default Day;
