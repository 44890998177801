import type { IOrgLocFormFields } from '@zen/Networks/Form/OrgLocForm/OrgLocForm';
import { prepareLocationInput } from '@zen/Networks/networksHelpers';
import type { CreateOrganisationLocationInput } from '@zen/Networks/types';

import type { IOrganisationLocationValues } from './NewLocation';

export const prepareLocationRequestData = (
  values: IOrganisationLocationValues | IOrgLocFormFields,
  accountUuid: string,
  organisationId: string
): CreateOrganisationLocationInput => {
  return {
    networkId: accountUuid,
    organisationId,
    location: prepareLocationInput(values)
  };
};
