import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateOceanChargeCurrencyMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateDefaultChargeItemCurrencyInput;
}>;

export type UpdateOceanChargeCurrencyMutationResult = { __typename?: 'Mutation' } & {
  updateDefaultChargeItemCurrency?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateDefaultChargeItemPayload' } & {
      errors?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>
      >;
    }
  >;
};

export const UpdateOceanChargeCurrencyDocument = /* #__PURE__ */ gql`
  mutation updateOceanChargeCurrency($input: UpdateDefaultChargeItemCurrencyInput!) {
    updateDefaultChargeItemCurrency(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateOceanChargeCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateOceanChargeCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOceanChargeCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOceanChargeCurrencyMutation, { data, loading, error }] = useUpdateOceanChargeCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOceanChargeCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOceanChargeCurrencyMutationResult, UpdateOceanChargeCurrencyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateOceanChargeCurrencyMutationResult, UpdateOceanChargeCurrencyMutationVariables>(
    UpdateOceanChargeCurrencyDocument,
    options
  );
}
export type UpdateOceanChargeCurrencyMutationHookResult = ReturnType<typeof useUpdateOceanChargeCurrencyMutation>;
