import { isEmpty, isEqual, pickBy } from 'lodash';
import pluralize from 'pluralize';
import type { ReactNode } from 'react';
import * as Yup from 'yup';

import { incotermsLabelMapping } from '@zen/Booking/helpers';
import { CoreCargoType, getCoreCargoType, getCoreCargoTypeLabel } from '@zen/Cargo';
import type { ReferenceListItem } from '@zen/Components/ReferencesList';
import type { DateRange, ExpandableData } from '@zen/DesignSystem';
import { IncotermsValue, SortingOrder, type SortInput } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';
import type { ParsedUrlType } from '@zen/utils/QueryParams';
import type { Modify, Optional } from '@zen/utils/typescript';

import { getDateRangeOptions } from './Filters/helpers';
import type { ShipmentsTableFilterValue } from './Filters/hooks/types';
import {
  LiveViewsSortOrder,
  ModifiedDateRanges,
  RelativeDateRange,
  ShipmentsTableCargoGroupsFiltersInput,
  ShipmentsTableCargoGroupsGroupByColumn,
  ShipmentsTableCargoGroupsSortByColumn,
  ShipmentsTableCargoGroupsSortByInput,
  ShipmentsTableCargoItem,
  ShipmentsTableFilters,
  ShipmentsTableFiltersValues,
  ShipmentsTableMilestone
} from './types';

export const SHIPMENTS_TABLE_EMPTY_VALUE = '-';

export const shipmentTableMilestoneConfig: Record<ShipmentsTableMilestone, string> = {
  [ShipmentsTableMilestone.ARRIVED_AT_PORT_OF_DESTINATION]: 'Arrived at port of destination',
  [ShipmentsTableMilestone.BOOKING_CONFIRMED]: 'Booking confirmed',
  [ShipmentsTableMilestone.BOOKING_RECEIVED]: 'Booking received',
  [ShipmentsTableMilestone.BOOKING_REQUESTED]: 'Booking requested',
  [ShipmentsTableMilestone.CARGO_ARRIVED_AT_PORT_OF_LOADING]: 'Cargo arrived at port of loading',
  [ShipmentsTableMilestone.CARGO_LOADED]: 'Cargo loaded',
  [ShipmentsTableMilestone.DELIVERED]: 'Delivered',
  [ShipmentsTableMilestone.EN_ROUTE_TO_FINAL_DESTINATION]: 'En route to final destination',
  [ShipmentsTableMilestone.EN_ROUTE_TO_PORT_OF_LOADING]: 'En route to port of loading',
  [ShipmentsTableMilestone.IN_TRANSIT]: 'In transit'
};

export const initialShipmentsTableFilters = {
  currentMilestone: Object.keys(shipmentTableMilestoneConfig).filter(
    (key: string) => key !== ShipmentsTableMilestone.DELIVERED
  ) as ShipmentsTableMilestone[]
};

export const renderShipmentsTableMilestoneName = (milestone: Optional<ShipmentsTableMilestone>) => {
  return milestone ? shipmentTableMilestoneConfig[milestone] : SHIPMENTS_TABLE_EMPTY_VALUE;
};

export const getOrderReferenceList = (
  references: { id: string; referenceNumber: string }[],
  getUrl: (id: string, tab?: string) => string
): ReferenceListItem[] => {
  return references.map(({ referenceNumber, id }) => ({
    referenceLabel: referenceNumber,
    url: getUrl(id, 'items')
  }));
};

export const renderAggregatedCargoLabel = <T extends { cargoType: string; quantity: number }>(nestedRowItems: T[]) => {
  const hasSameCargoType = hasSameValues(nestedRowItems.map((item) => item.cargoType));

  if (!hasSameCargoType) {
    return 'Multiple';
  }

  const quantity = nestedRowItems.reduce((acc, item) => acc + item.quantity, 0);

  return renderShipmentsTableCargoLabel(nestedRowItems[0].cargoType, quantity);
};

export const renderShipmentsTableCargoLabel = (cargoType: string, quantity: number) => {
  const coreCargoType = getCoreCargoType(cargoType) as CoreCargoType;

  if (!coreCargoType) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  const cargoLabel: string = pluralize(getCoreCargoTypeLabel(coreCargoType), quantity);
  const prefix: string = quantity > 0 ? `${quantity} x ` : '';

  return `${prefix}${cargoLabel}`;
};

export const renderAggregatedValues = <T>(items: T[], render: (value: T) => ReactNode): ReactNode => {
  const values: T[] = items.filter(Boolean);

  if (isEmpty(values)) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  const sameValues: boolean = hasSameValues(values);

  return sameValues ? render(values[0]) : 'Multiple';
};

export const hasSameValues = <T>(items: T[]): boolean => {
  return items.every((value) => isEqual(value, items[0]));
};

export const prepareShipmentsTableData = <T extends { cargos: K[] }, K extends {}>(data: T[]): ExpandableData<K>[] => {
  return (data || []).map((item) => ({
    ...item?.cargos?.[0],
    nestedRowItems: item.cargos.length > 1 ? item.cargos : undefined
  }));
};

export const getSortBy = (sortInput: SortInput | undefined): ShipmentsTableCargoGroupsSortByInput | undefined => {
  if (!sortInput) return;

  const { field, direction } = sortInput;

  const columnConfig: { [key: string]: ShipmentsTableCargoGroupsSortByColumn } = {
    arrivalDate: ShipmentsTableCargoGroupsSortByColumn.ARRIVAL_DATE,
    cargoReadyDate: ShipmentsTableCargoGroupsSortByColumn.CARGO_READY_DATE,
    collectionDate: ShipmentsTableCargoGroupsSortByColumn.COLLECTION_DATE,
    currentMilestone: ShipmentsTableCargoGroupsSortByColumn.CURRENT_MILESTONE,
    deliveryDate: ShipmentsTableCargoGroupsSortByColumn.DELIVERY_DATE,
    departureDate: ShipmentsTableCargoGroupsSortByColumn.DEPARTURE_DATE,
    modeOfTransport: ShipmentsTableCargoGroupsSortByColumn.MODE_OF_TRANSPORT,
    zencargoReference: ShipmentsTableCargoGroupsSortByColumn.ZENCARGO_REFERENCE,
    consignee: ShipmentsTableCargoGroupsSortByColumn.CONSIGNEE,
    consignor: ShipmentsTableCargoGroupsSortByColumn.CONSIGNOR,
    deliveryStatus: ShipmentsTableCargoGroupsSortByColumn.DELIVERY_OFFSET,
    arrivalStatus: ShipmentsTableCargoGroupsSortByColumn.ARRIVAL_OFFSET,
    departureStatus: ShipmentsTableCargoGroupsSortByColumn.DEPARTURE_OFFSET
  };
  const orderConfig = {
    [SortingOrder.ASC]: LiveViewsSortOrder.ASC,
    [SortingOrder.DESC]: LiveViewsSortOrder.DESC
  };

  const column = columnConfig[field];

  if (!column) return;

  return {
    column,
    order: orderConfig[direction]
  };
};

const isDateRange = (value: DateRange | RelativeDateRange | undefined): value is DateRange => {
  return typeof value === 'object' && Object.hasOwn(value, 'startDate') && Object.hasOwn(value, 'endDate');
};

const prepareDateRange = (dateRange: DateRange | RelativeDateRange | undefined) => {
  if (!dateRange) return;

  if (isDateRange(dateRange)) {
    return {
      from: dateRange.startDate,
      to: dateRange.endDate
    };
  }

  const relativeDateRange = getDateRangeOptions().find((option) => option.value.value === dateRange)?.value;

  if (!relativeDateRange) return;

  return { from: relativeDateRange.startDate, to: relativeDateRange.endDate };
};

const prepareShipmentsFilterValues = (values: ShipmentsTableFilterValue[] | undefined) => {
  if (!values) return;

  return values.map((value) => value.key);
};

export const prepareShipmentsTableFilters = (
  filters: ShipmentsTableFilters
): ShipmentsTableCargoGroupsFiltersInput | undefined => {
  if (isEmpty(filters)) return;
  const result = {
    arrivalDate: prepareDateRange(filters.arrivalDate),
    cargoReadyDate: prepareDateRange(filters.cargoReadyDate),
    collectionDate: prepareDateRange(filters.collectionDate),
    deliveryDate: prepareDateRange(filters.deliveryDate),
    departureDate: prepareDateRange(filters.departureDate),
    portOfDestination: prepareShipmentsFilterValues(filters.portOfDestination),
    portOfLoading: prepareShipmentsFilterValues(filters.portOfLoading),
    carrierScac: filters.carrierScac,
    collectionLocation: filters.collectionLocation,
    currentMilestone: filters.currentMilestone,
    customsOnly: filters.customsOnly,
    deliveryLocation: filters.deliveryLocation,
    incoterms: filters.incoterms,
    modeOfTransport: filters.modeOfTransport,
    productCategory: prepareShipmentsFilterValues(filters.productCategory),
    product: prepareShipmentsFilterValues(filters.product),
    quotesRequestStatus: filters.quotesRequestStatus
  };

  return pickBy(result, (value) => !isEmpty(value));
};

export const renderLocation = (location: Optional<ShipmentsTableCargoItem['collectionLocation']>) => {
  if (!location?.networksOrgLoc?.label?.long) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return location.networksOrgLoc.label.long;
};

export const renderPort = (location: Optional<{ id: string; name?: string | null }>) => {
  if (!location?.name) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return location.name;
};

export const renderDate = (cargoReadyDate: Optional<string>) => {
  if (!cargoReadyDate) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return formatDate(cargoReadyDate);
};

export const renderIncoterms = (incoterms: Optional<IncotermsValue>) => {
  if (!incoterms) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return incotermsLabelMapping[incoterms];
};

export const preparePurchaseOrderReferences = <
  T extends {
    bookingPurchaseOrders?: Optional<ShipmentsTableCargoItem['bookingPurchaseOrders']>;
    cargoPurchaseOrders?: Optional<ShipmentsTableCargoItem['cargoPurchaseOrders']>;
  }
>(
  item: ExpandableData<T>,
  groupBy: ShipmentsTableCargoGroupsGroupByColumn
) => {
  const isAggregatedRow = item.nestedRowItems && item.nestedRowItems.length > 1;
  const isGroupedByZenCargoReference = groupBy === ShipmentsTableCargoGroupsGroupByColumn.ZENCARGO_REFERENCE;

  if (isAggregatedRow) {
    const nestedCargoPurchaseOrders = item.nestedRowItems?.map((cargo) => cargo.cargoPurchaseOrders);

    return isGroupedByZenCargoReference ? [item.bookingPurchaseOrders] : nestedCargoPurchaseOrders;
  }

  return [item.cargoPurchaseOrders];
};

export const newShipmentsHiddenColumns: string[] = ['consignee', 'consignor'];

export const initialShipmentsTableView = {
  filters: initialShipmentsTableFilters,
  groupBy: ShipmentsTableCargoGroupsGroupByColumn.ZENCARGO_REFERENCE,
  hiddenColumns: newShipmentsHiddenColumns,
  searchTerm: undefined,
  sortBy: undefined
};

type ShimentsTableFiltersSchema = Pick<
  ParsedUrlType<ShipmentsTableFilters>,
  'arrivalOffset' | 'departureOffset' | 'deliveryOffset'
>;

type ParsedShipmentsTableFilterValues = Modify<
  ShimentsTableFiltersSchema,
  {
    arrivalOffset?: ShipmentsTableFilters['arrivalOffset'];
    deliveryOffset?: ShipmentsTableFilters['deliveryOffset'];
    departureOffset?: ShipmentsTableFilters['deliveryOffset'];
  }
>;

type ParsedShipmentsTableFilters<T> = Omit<T, 'arrivalOffset' | 'departureOffset' | 'deliveryOffset'> &
  ParsedShipmentsTableFilterValues;

export const parseShipmentsTableFilters = <T extends ShimentsTableFiltersSchema>(filters: T): ParsedShipmentsTableFilters<T> => {
  const shipmentFiltersSchema = Yup.object<ShimentsTableFiltersSchema>({
    arrivalOffset: Yup.array().of(Yup.object({ from: Yup.number(), to: Yup.number() })),
    deliveryOffset: Yup.array().of(Yup.object({ from: Yup.number(), to: Yup.number() })),
    departureOffset: Yup.array().of(Yup.object({ from: Yup.number(), to: Yup.number() }))
  });

  return shipmentFiltersSchema.cast(filters) as ParsedShipmentsTableFilters<T>;
};

export const prepareShipmentsTableFiltersWithDates = <
  T extends Pick<
    ShipmentsTableFiltersValues,
    'arrivalDate' | 'collectionDate' | 'cargoReadyDate' | 'deliveryDate' | 'departureDate'
  >
>(
  values: T
) => {
  return {
    ...values,
    arrivalDate: values.arrivalDate?.value || values.arrivalDate,
    collectionDate: values.collectionDate?.value || values.collectionDate,
    cargoReadyDate: values.cargoReadyDate?.value || values.cargoReadyDate,
    deliveryDate: values.deliveryDate?.value || values.deliveryDate,
    departureDate: values.departureDate?.value || values.departureDate
  };
};

const prepareDateRangeValue = (
  value: DateRange<RelativeDateRange> | RelativeDateRange | undefined
): DateRange<RelativeDateRange> | undefined => {
  if (!value) return;
  if (typeof value === 'object') {
    return value;
  }

  return getDateRangeOptions().find((option) => option.value.value === value)?.value;
};

export const getShipmentsTableFiltersValues = <
  T extends Pick<ShipmentsTableFilters, 'arrivalDate' | 'collectionDate' | 'cargoReadyDate' | 'deliveryDate' | 'departureDate'>
>(
  filters: T
): ModifiedDateRanges<T> => {
  return {
    ...filters,
    arrivalDate: prepareDateRangeValue(filters.arrivalDate),
    collectionDate: prepareDateRangeValue(filters.collectionDate),
    cargoReadyDate: prepareDateRangeValue(filters.cargoReadyDate),
    deliveryDate: prepareDateRangeValue(filters.deliveryDate),
    departureDate: prepareDateRangeValue(filters.departureDate)
  };
};
