import type { FC, ReactElement } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import RouteTabs, { RouteTabConfig } from '@zen/Components/RouteTabs';
import RouteTabSwitcher from '@zen/Components/RouteTabSwitcher';
import { Role } from '@zen/Networks';
import { accountsRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';

import { useGetAccountDetailsQuery } from '../AccountDetails/graphql';
import type { Account } from '../AccountDetails/types';
import { getTabsConfig } from './helpers';

const AccountSettings: FC = () => {
  const { accountUuid: accountId } = useAccount();
  const { check } = useGlobalPermissions();
  const role = useRole();
  const canViewAuthentication: boolean = check('accounts.canViewAuthentication');

  const defaultBackUrl = role === Role.ADMIN ? accountsRoutes.accountsIndex.getUrl() : undefined;

  const { data, error, loading } = useGetAccountDetailsQuery({
    variables: { accountId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only'
  });

  return (
    <QueryHandler data={data?.account} error={!!error} isLoading={loading}>
      {(accountDetails: Account): ReactElement => {
        const { referencePrefix, tradingName } = accountDetails;

        const tabsConfig: RouteTabConfig[] = getTabsConfig(accountDetails, { canViewAuthentication });

        return (
          <Page
            defaultBackUrl={defaultBackUrl}
            tabsComponent={<RouteTabs tabsConfig={tabsConfig} />}
            tagline={referencePrefix}
            title={tradingName}
          >
            <RouteTabSwitcher tabsConfig={tabsConfig} />
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default AccountSettings;
