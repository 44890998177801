import { isEmpty, isEqual } from 'lodash';
import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import useTracking from '@zen/utils/hooks/useTracking';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { ShipmentFilters } from '../types';
import QuickFilters from './components/QuickFilters';
import QuickFiltersActions from './components/QuickFiltersActions';
import QuickFiltersAddRemove from './components/QuickFiltersAddRemove';
import { QUICK_FILTERS_CATEGORY, QuickFiltersAction } from './types';

const ShipmentsQuickFilters: FC = () => {
  const { filters, setFilters } = useUrlFilters<ShipmentFilters>();
  const { savedFilters } = useSavedFilters();
  const { check } = useGlobalPermissions();
  const { trackEvent } = useTracking();
  const canSetPreferences = check('settings.canSetPreferences');
  const areAppliedFiltersNonEmpty = !isEqual(filters, {});
  const shouldRenderFiltersActions: boolean = areAppliedFiltersNonEmpty && canSetPreferences;
  const shouldRenderSavedFilters: boolean = !isEmpty(savedFilters) && canSetPreferences;

  const handleClear = (): void => {
    trackEvent({
      category: QUICK_FILTERS_CATEGORY,
      action: QuickFiltersAction.CLEAR_ALL_FILTERS,
      label: 'Clear all'
    });

    setFilters({});
  };

  return (
    <>
      {shouldRenderFiltersActions && (
        <div className="flex mt-6 mb-3">
          <QuickFiltersAddRemove filters={filters} />
          <QuickFiltersActions label="Clear current filters" onClick={handleClear} />
        </div>
      )}
      {shouldRenderSavedFilters && (
        <div className="flex space-x-4 items-center">
          <span>Saved filters:</span>
          <QuickFilters filters={filters} setFilters={setFilters} />
        </div>
      )}
    </>
  );
};

export default ShipmentsQuickFilters;
