import type { Option } from '@zen/DesignSystem';
import type { Optional, Undefinable } from '@zen/utils/typescript';

import type { ChargeType } from './types';

export const prepareOptions = (chargeTypes: ChargeType[]): Option<ChargeType>[] => {
  return chargeTypes.map((item: ChargeType) => ({
    value: item,
    label: item.name
  }));
};

export const formatChargeTypeSubtitle = (chargeType: ChargeType): Optional<string> => {
  const attributes: string = getChargeTypeAttributes(chargeType);
  const chargeBasis: Optional<string> = chargeType?.chargeBasis && ` per ${chargeType?.chargeBasis?.toLowerCase()}`;
  const label: Optional<string> = attributes.length > 0 ? `${chargeBasis} - ${attributes}` : chargeBasis;

  return label;
};

export const getChargeTypeAttributes = (chargeType: ChargeType): string => {
  const result: string[] = [];

  Object.keys(formatAttributes).forEach((attributeKey: string): void => {
    const key = attributeKey as keyof typeof formatAttributes;

    if (chargeType[key]) {
      result.push(formatAttributes[key]);
    }
  });

  return result.join(', ');
};

export const getChargeType = (chargeTypes: ChargeType[], id: string): Undefinable<ChargeType> => {
  return chargeTypes.find((type: ChargeType) => type.id === id);
};

export const formatAttributes = {
  twentyFtDv: "20'DV",
  fortyFtDv: "40'DV",
  twentyFtHc: "20'HC",
  fortyFtHc: "40'HC",
  fortyFiveFtHc: "45'HC",
  reefer: 'Reefer',
  hazardous: 'Hazardous',
  overweight: 'Overweight'
};
