import { type FC, useState } from 'react';

import { BookingStageEnum } from '@zen/Booking';
import { Button } from '@zen/DesignSystem';
import { getToday } from '@zen/utils/date';
import { useMutationIteration } from '@zen/utils/hooks/useMutationIteration';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useCompleteDeliveryMutation, useUpdateBookingStageMutation } from './graphql';

interface Props {
  cargoIds: string[];
  className?: string;
  isBookingReceived: boolean;
  onSuccess: () => void;
  zencargoReference: string;
}

const CompleteBookingButton: FC<Props> = ({ cargoIds, className, isBookingReceived, onSuccess, zencargoReference }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { addSuccess, addError } = useNotification();
  const [completeDelivery] = useCompleteDeliveryMutation();
  const [updateBookingStage] = useUpdateBookingStageMutation();

  const handleError = (): void => {
    setIsLoading(false);
    addError('Something went wrong.');
  };

  const handleSuccess = (): void => {
    setIsLoading(false);
    addSuccess('Booking is marked as delivered.');
    onSuccess();
  };

  const { handleMutation } = useMutationIteration(cargoIds, handleSuccess, handleError);

  const completeCargoDeliveries = (): Promise<IOkOrErrorResult> => {
    const mutate = (id: string) => {
      return completeDelivery({
        variables: {
          input: {
            id,
            deliveredOn: getToday(),
            zencargoReference
          }
        }
      });
    };

    return handleMutation(mutate);
  };

  const handleClick = async () => {
    setIsLoading(true);

    if (isBookingReceived) {
      await performMutation({
        mutationFn: () =>
          updateBookingStage({
            variables: {
              input: {
                occurredAt: getToday(),
                stage: BookingStageEnum.BOOKING_CONFIRMED,
                zencargoReference
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          completeCargoDeliveries();
        }
      });
    } else {
      completeCargoDeliveries();
    }
  };

  return (
    <Button
      className={className}
      iconLeft="zicon-tick"
      isLoading={isLoading}
      onClick={handleClick}
      size="compact"
      variant="secondary"
    >
      Mark as delivered
    </Button>
  );
};

export default CompleteBookingButton;
