import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import type { Optional } from '@zen/utils/typescript';

import BookingRequestApproveButton from '../BookingRequestApproveButton';
import BookingRequestRejectButton from '../BookingRequestRejectButton';
import type { BookingRequestField } from '../types';

interface Props {
  bookingRequest: Optional<BookingRequestField>;
  zencargoReference: string;
}

const BookingRequestButtons: FC<Props> = ({ bookingRequest, zencargoReference }) => {
  if (!bookingRequest || bookingRequest?.rejected) {
    return null;
  }

  const { forwarderApproved } = bookingRequest;
  const permission: keyof BookingRequestField = !forwarderApproved ? 'canUpdateForwarderApproved' : 'canUpdateCustomerApproved';
  const showApproveButton: boolean = checkPermission<BookingRequestField>(bookingRequest, permission);
  const showRejectButton: boolean = checkPermission<BookingRequestField>(bookingRequest, 'canReject');

  return (
    <div className="flex space-x-2">
      {showRejectButton && <BookingRequestRejectButton zencargoReference={zencargoReference} />}
      {showApproveButton && (
        <BookingRequestApproveButton forwarderApproved={!!forwarderApproved} zencargoReference={zencargoReference} />
      )}
    </div>
  );
};

export default BookingRequestButtons;
