import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OceanCarrierByScacQueryVariables = GraphQLTypes.Exact<{
  scac: GraphQLTypes.Scalars['String'];
}>;

export type OceanCarrierByScacQueryResult = { __typename?: 'Query' } & {
  oceanCarrierByScac?: GraphQLTypes.Maybe<
    { __typename?: 'OceanCarrier' } & Pick<
      GraphQLTypes.OceanCarrier,
      'carrierScac' | 'id' | 'isTrackable' | 'oceanCarrierName' | 'synonyms'
    >
  >;
};

export const OceanCarrierByScacDocument = /* #__PURE__ */ gql`
  query oceanCarrierByScac($scac: String!) {
    oceanCarrierByScac(scac: $scac) {
      carrierScac
      id
      isTrackable
      oceanCarrierName
      synonyms
    }
  }
`;

/**
 * __useOceanCarrierByScacQuery__
 *
 * To run a query within a React component, call `useOceanCarrierByScacQuery` and pass it any options that fit your needs.
 * When your component renders, `useOceanCarrierByScacQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanCarrierByScacQuery({
 *   variables: {
 *      scac: // value for 'scac'
 *   },
 * });
 */
export function useOceanCarrierByScacQuery(
  baseOptions: Apollo.QueryHookOptions<OceanCarrierByScacQueryResult, OceanCarrierByScacQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OceanCarrierByScacQueryResult, OceanCarrierByScacQueryVariables>(OceanCarrierByScacDocument, options);
}
export function useOceanCarrierByScacLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OceanCarrierByScacQueryResult, OceanCarrierByScacQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OceanCarrierByScacQueryResult, OceanCarrierByScacQueryVariables>(
    OceanCarrierByScacDocument,
    options
  );
}
export type OceanCarrierByScacQueryHookResult = ReturnType<typeof useOceanCarrierByScacQuery>;
export type OceanCarrierByScacLazyQueryHookResult = ReturnType<typeof useOceanCarrierByScacLazyQuery>;
