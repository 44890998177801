import type { FC } from 'react';

import { DatePicker, Icon } from '@zen/DesignSystem';
import { journeyRefetchQueryList } from '@zen/Journey/components/forms/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyShippingCargoReadyDateMilestoneDatesValidation, ModeOfTransport } from '../../types';
import { useUpdateJourneysCargoReadyDateMutation } from './graphql';
import { getCargoReadyForCollectionDisabledDates } from './helpers';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  date?: Optional<string>;
  datesValidation: Optional<JourneyShippingCargoReadyDateMilestoneDatesValidation>;
  onChange: () => void;
  zencargoReference: string;
}

const CargoReadyDatePicker: FC<Props> = ({ bookingModeOfTransport, date, datesValidation, onChange, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [updateCargoReadyDates] = useUpdateJourneysCargoReadyDateMutation();

  const handleChange = (dateString: string) => {
    onChange();

    if (!dateString) return;

    return performMutation({
      mutationFn: () =>
        updateCargoReadyDates({
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              date: dateString,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError();
      },
      onSuccess: () => {
        addSuccess('Cargo ready date updated.');
      }
    });
  };

  const { disabledDates, tooltip } = getCargoReadyForCollectionDisabledDates(bookingModeOfTransport, datesValidation);

  return (
    <div>
      <DatePicker
        className="!border-none"
        disabled={disabledDates}
        mode="single"
        onChange={handleChange}
        selected={date || ''}
        tooltip={tooltip}
      />
      <div className="flex gap-3 p-4 text-sm text-grey-base max-w-[19rem] bg-white border-t border-solid border-grey-lighter text-left">
        <Icon className="mt-1 text-base" icon="zicon-information" />
        Cargo ready dates and delay reason changes will be reflected across all cargo in this shipment
      </div>
    </div>
  );
};

export default CargoReadyDatePicker;
