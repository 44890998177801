import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateJourneyScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateJourneyScheduleInput;
}>;

export type UpdateJourneyScheduleMutationResult = { __typename?: 'Mutation' } & {
  updateJourneySchedule?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateJourneySchedulePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateJourneyScheduleDocument = /* #__PURE__ */ gql`
  mutation updateJourneySchedule($input: UpdateJourneyScheduleInput!) {
    updateJourneySchedule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateJourneyScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyScheduleMutation, { data, loading, error }] = useUpdateJourneyScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJourneyScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyScheduleMutationResult, UpdateJourneyScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateJourneyScheduleMutationResult, UpdateJourneyScheduleMutationVariables>(
    UpdateJourneyScheduleDocument,
    options
  );
}
export type UpdateJourneyScheduleMutationHookResult = ReturnType<typeof useUpdateJourneyScheduleMutation>;
