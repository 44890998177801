const SETTINGS_RELATIVE_INDEX_PATH = 'settings';

export const SETTINGS_ROUTE_PREFIX = `/dashboard/${SETTINGS_RELATIVE_INDEX_PATH}`;

interface SettingsRoutes {
  accountSetup: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  billingDetails: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  integrations: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  notifications: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  preferences: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  profileSettings: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const settingsRoutes: SettingsRoutes = {
  accountSetup: {
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/account-setup`,
    path: `${SETTINGS_ROUTE_PREFIX}/account-setup`,
    relativePath: 'account-setup'
  },
  billingDetails: {
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/billing-details`,
    path: `${SETTINGS_ROUTE_PREFIX}/billing-details`,
    relativePath: 'billing-details'
  },
  index: {
    getUrl: () => SETTINGS_ROUTE_PREFIX,
    path: `${SETTINGS_ROUTE_PREFIX}/*`,
    relativePath: `${SETTINGS_RELATIVE_INDEX_PATH}/*`
  },
  integrations: {
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/integrations`,
    path: `${SETTINGS_ROUTE_PREFIX}/integrations`,
    relativePath: 'integrations'
  },
  notifications: {
    path: `${SETTINGS_ROUTE_PREFIX}/notifications`,
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/notifications`,
    relativePath: 'notifications'
  },
  preferences: {
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/preferences`,
    path: `${SETTINGS_ROUTE_PREFIX}/preferences`,
    relativePath: 'preferences'
  },
  profileSettings: {
    getUrl: () => `${SETTINGS_ROUTE_PREFIX}/profile-setting`,
    path: `${SETTINGS_ROUTE_PREFIX}/profile-setting`,
    relativePath: 'profile-setting'
  }
};

export default settingsRoutes;
