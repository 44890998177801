import type { ModeOfTransport } from '@zen/Booking';
import DisabledDateTooltip from '@zen/Journey/components/DisabledDateTooltip';
import { getMilestoneLabel } from '@zen/Journey/components/MilestoneName/helpers';
import { getDateType } from '@zen/Journey/helpers';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyShippingCargoReadyDateMilestoneDatesValidation } from '../../types';

export const getCargoReadyForCollectionDisabledDates = (
  bookingModeOfTransport: Optional<ModeOfTransport>,
  datesValidation: Optional<JourneyShippingCargoReadyDateMilestoneDatesValidation>
) => {
  return {
    disabledDates: {
      maxDate: datesValidation?.maximumDate
    },
    tooltip: [
      {
        matcher: {
          maxDate: datesValidation?.maximumDate
        },
        message: datesValidation && (
          <DisabledDateTooltip
            dateConstraint="max"
            dateType={getDateType({
              ...datesValidation.nextMilestone,
              stopAction: datesValidation.nextMilestoneStopAction
            })}
            milestoneName={getMilestoneLabel({
              completed: datesValidation?.nextMilestone.completed,
              modeOfTransport: bookingModeOfTransport,
              name: datesValidation.nextMilestone.name,
              stopAction: datesValidation.nextMilestoneStopAction
            })}
          />
        )
      }
    ]
  };
};
