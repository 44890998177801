import type { TimeRange } from '@zen/utils/dateTime/types';

export enum TimeOption {
  NO_TIME = 'NO_TIME',
  POINT_IN_TIME = 'POINT_IN_TIME',
  TIME_RANGE = 'TIME_RANGE'
}

export interface DateWithTimeFields {
  date: string;
  endTime: string;
  startTime: string;
  time: string;
  timeOption?: TimeOption;
}

export type BusinessHoursType = {
  friday: TimeRange;
  monday: TimeRange;
  saturday: TimeRange;
  sunday: TimeRange;
  thursday: TimeRange;
  tuesday: TimeRange;
  wednesday: TimeRange;
};
