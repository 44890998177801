import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { AddressInput } from '@zen/graphql/types.generated';

import type { Nullable } from '../typescript';
import { joinNotNull } from '../utils';

type Coordinates = {
  lat: number;
  lng: number;
};

type AddressRestriction = {
  country?: string;
};

type GeocodeAddressInput = { address: string; componentRestrictions: AddressRestriction };

const useGetAddressCoordinates = (address: AddressInput): { coordinates: Nullable<Coordinates> } => {
  const [geocoder, setGeocoder] = useState<Nullable<google.maps.Geocoder>>(null);

  const [coordinates, setCoordinates] = useState<Nullable<Coordinates>>(null);

  useEffect(() => {
    if (geocoder) return;

    setGeocoder(new window.google.maps.Geocoder());
  }, [geocoder]);

  const preparedAddressDetails = useMemo((): GeocodeAddressInput => {
    const { line1, line2, city, postalCodeOrZip = '', country = '', countyOrState } = address;
    const componentRestrictions: AddressRestriction = {};

    if (country) {
      // Adding a component restriction constrains the map search to a specific region
      componentRestrictions.country = country;
    }

    const minAddress: string = joinNotNull([line1, line2, city, countyOrState, postalCodeOrZip]);

    return { componentRestrictions, address: minAddress };
  }, [address]);

  const generateCoordinates = useCallback(
    (updatedAddress: GeocodeAddressInput): void => {
      geocoder
        ?.geocode(updatedAddress)
        .then(({ results }) => {
          const { location } = results[0].geometry;

          setCoordinates({ lng: location.lng(), lat: location.lat() });
        })
        .catch((err) => {
          setCoordinates(null);
        });
    },
    [geocoder]
  );

  const DEBOUNCE_DELAY_IN_MS: number = 700;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCoordinates = useCallback(debounce(generateCoordinates, DEBOUNCE_DELAY_IN_MS), [generateCoordinates]);

  useEffect(() => {
    const hasAddressInput: boolean = !!preparedAddressDetails.address || !!preparedAddressDetails.componentRestrictions.country;

    if (hasAddressInput) {
      getCoordinates(preparedAddressDetails);
    }
  }, [preparedAddressDetails, geocoder, getCoordinates]);

  return { coordinates };
};

export { useGetAddressCoordinates };
