import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateMilestoneRequestedDateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateMilestoneRequestedDateInput;
}>;

export type UpdateMilestoneRequestedDateMutationResult = { __typename?: 'Mutation' } & {
  updateMilestoneRequestedDate: { __typename?: 'UpdateMilestoneRequestedDatePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateMilestoneRequestedDateDocument = /* #__PURE__ */ gql`
  mutation updateMilestoneRequestedDate($input: UpdateMilestoneRequestedDateInput!) {
    updateMilestoneRequestedDate(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateMilestoneRequestedDateMutation__
 *
 * To run a mutation, you first call `useUpdateMilestoneRequestedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestoneRequestedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestoneRequestedDateMutation, { data, loading, error }] = useUpdateMilestoneRequestedDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMilestoneRequestedDateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMilestoneRequestedDateMutationResult,
    UpdateMilestoneRequestedDateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateMilestoneRequestedDateMutationResult, UpdateMilestoneRequestedDateMutationVariables>(
    UpdateMilestoneRequestedDateDocument,
    options
  );
}
export type UpdateMilestoneRequestedDateMutationHookResult = ReturnType<typeof useUpdateMilestoneRequestedDateMutation>;
