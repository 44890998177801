import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductsCreateProductMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateProductInput;
}>;

export type ProductsCreateProductMutationResult = { __typename?: 'Mutation' } & {
  productsCreateProduct?: GraphQLTypes.Maybe<
    { __typename?: 'CreateProductPayload' } & {
      product?: GraphQLTypes.Maybe<
        { __typename?: 'Product' } & Pick<
          GraphQLTypes.Product,
          'id' | 'name' | 'skuCode' | 'hsCode' | 'htsCode' | 'unitsPerMasterCarton'
        > & {
            packageInfo?: GraphQLTypes.Maybe<
              { __typename?: 'PackageInfo' } & Pick<GraphQLTypes.PackageInfo, 'type'> & {
                  depth?: GraphQLTypes.Maybe<
                    { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                  >;
                  width?: GraphQLTypes.Maybe<
                    { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                  >;
                  length?: GraphQLTypes.Maybe<
                    { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                  >;
                }
            >;
            properties: Array<{ __typename?: 'ProductProperty' } & Pick<GraphQLTypes.ProductProperty, 'name' | 'value'>>;
            costPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
            sellPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
            weight?: GraphQLTypes.Maybe<
              { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
            >;
          }
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const ProductsCreateProductDocument = /* #__PURE__ */ gql`
  mutation productsCreateProduct($input: CreateProductInput!) {
    productsCreateProduct(input: $input) {
      product {
        id
        name
        skuCode
        hsCode
        htsCode
        packageInfo {
          depth {
            unit
            value
          }
          width {
            unit
            value
          }
          length {
            unit
            value
          }
          type
        }
        properties {
          name
          value
        }
        costPrice {
          currency
          value
        }
        sellPrice {
          currency
          value
        }
        weight {
          unit
          value
        }
        unitsPerMasterCarton
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useProductsCreateProductMutation__
 *
 * To run a mutation, you first call `useProductsCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsCreateProductMutation, { data, loading, error }] = useProductsCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<ProductsCreateProductMutationResult, ProductsCreateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ProductsCreateProductMutationResult, ProductsCreateProductMutationVariables>(
    ProductsCreateProductDocument,
    options
  );
}
export type ProductsCreateProductMutationHookResult = ReturnType<typeof useProductsCreateProductMutation>;
