const timeRegexp = /^\d{0,2}?:?\d{0,2}$/;

const isValidHour = (hours: number): boolean => Number.isInteger(hours) && hours >= 0 && hours < 24;
const isValidMinutes = (minutes: number): boolean =>
  (Number.isInteger(minutes) && minutes >= 0 && minutes < 60) || Number.isNaN(minutes);

export const isValidInput = (value: string): boolean => {
  const [hoursString, minutesString] = value.split(':');

  const hours: number = Number(hoursString);
  const minutes: number = Number(minutesString);

  if (!timeRegexp.test(value) || value.length > 5 || value === ':' || !isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  return true;
};
