import * as Yup from 'yup';

export const validationMessages: Record<string, string> = {
  name: 'Name is required',
  title: 'Title is required',
  description: 'Description is required'
};

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  name: Yup.string().required(validationMessages.name),
  title: Yup.string().required(validationMessages.title),
  description: Yup.string().required(validationMessages.description)
});
