import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateBookingDocumentMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateInput;
}>;

export type UpdateBookingDocumentMutationResult = { __typename?: 'Mutation' } & {
  bookingDocumentUpdate?: GraphQLTypes.Maybe<
    { __typename?: 'UpdatePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateBookingDocumentDocument = /* #__PURE__ */ gql`
  mutation updateBookingDocument($input: UpdateInput!) {
    bookingDocumentUpdate(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateBookingDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateBookingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingDocumentMutation, { data, loading, error }] = useUpdateBookingDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBookingDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBookingDocumentMutationResult, UpdateBookingDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateBookingDocumentMutationResult, UpdateBookingDocumentMutationVariables>(
    UpdateBookingDocumentDocument,
    options
  );
}
export type UpdateBookingDocumentMutationHookResult = ReturnType<typeof useUpdateBookingDocumentMutation>;
