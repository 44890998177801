import cx from 'classnames';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  currentIndicator?: 'route' | 'tick';
  isCompleted: boolean;
  isCurrent: boolean;
  pathIndicatorVisible?: boolean;
}

const PathIndicator: FC<Props> = (props) => {
  const { className = '', currentIndicator = 'route', isCompleted, isCurrent, pathIndicatorVisible = true } = props;
  const icon: IconName = currentIndicator === 'route' ? 'zicon-progress-down-oval' : 'zicon-tickoval';

  const classNames: string = cx(
    {
      'mt-1.5': !isCurrent
    },
    'flex justify-center bg-white relative z-10 py-0.5 shrink-0',
    JOURNEY_TIMELINE_WIDTH,
    className
  );

  const pathClassNames: string = cx(
    {
      'border-azure-base bg-azure-base': isCompleted,
      'border-grey-light bg-white': !isCompleted
    },
    'rounded border border-solid w-[5px] h-[5px] relative z-10'
  );

  return (
    <div
      className={classNames}
      data-routedatapoint={isCurrent ? true : pathIndicatorVisible}
      data-routedatapointcompleted={isCurrent ? true : isCompleted}
    >
      {isCurrent && <Icon className="text-xl text-azure-base" icon={icon} />}
      {!isCurrent && <div className={pathClassNames} data-testid="path-indicator" />}
    </div>
  );
};

export default PathIndicator;
