import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConsolidationUpdateConsolidatedShipmentMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateConsolidatedShipmentInput;
}>;

export type ConsolidationUpdateConsolidatedShipmentMutationResult = { __typename?: 'Mutation' } & {
  consolidationUpdateConsolidatedShipment?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateConsolidatedShipmentPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
      consolidatedShipment?: GraphQLTypes.Maybe<
        { __typename?: 'ConsolidatedShipment' } & Pick<
          GraphQLTypes.ConsolidatedShipment,
          | 'actualArrivalDate'
          | 'actualDepartureDate'
          | 'cbm'
          | 'cutOffDate'
          | 'estimatedArrivalDate'
          | 'id'
          | 'looseCargoType'
          | 'quantity'
          | 'reference'
          | 'transitDocumentReference'
          | 'vehiclePlateNumber'
        > & {
            grossWeight?: GraphQLTypes.Maybe<{ __typename?: 'Weight' } & Pick<GraphQLTypes.Weight, 'metric' | 'value'>>;
            legs: Array<
              { __typename?: 'ConsolidationLegType' } & Pick<
                GraphQLTypes.ConsolidationLegType,
                'actualArrivalDate' | 'actualDepartureDate' | 'estimatedArrivalDate' | 'estimatedDepartureDate' | 'id'
              > & {
                  networksDestination:
                    | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        });
                  networksOrigin:
                    | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        })
                    | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                          >;
                        });
                }
            >;
            networksDestination?: GraphQLTypes.Maybe<
              | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
            >;
            networksOrigin?: GraphQLTypes.Maybe<
              | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
            >;
            originAgent?: GraphQLTypes.Maybe<
              | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
              | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  })
            >;
          }
      >;
    }
  >;
};

export const ConsolidationUpdateConsolidatedShipmentDocument = /* #__PURE__ */ gql`
  mutation consolidationUpdateConsolidatedShipment($input: UpdateConsolidatedShipmentInput!) {
    consolidationUpdateConsolidatedShipment(input: $input) {
      errors {
        path
        message
      }
      consolidatedShipment {
        actualArrivalDate
        actualDepartureDate
        cbm
        cutOffDate
        estimatedArrivalDate
        grossWeight {
          metric
          value
        }
        id
        legs {
          actualArrivalDate
          actualDepartureDate
          estimatedArrivalDate
          estimatedDepartureDate
          id
          networksDestination {
            id
            label {
              long
            }
          }
          networksOrigin {
            id
            label {
              long
            }
          }
        }
        looseCargoType
        networksDestination {
          id
          label {
            long
          }
        }
        networksOrigin {
          id
          label {
            long
          }
        }
        originAgent {
          id
          label {
            long
          }
        }
        quantity
        reference
        transitDocumentReference
        vehiclePlateNumber
      }
    }
  }
`;

/**
 * __useConsolidationUpdateConsolidatedShipmentMutation__
 *
 * To run a mutation, you first call `useConsolidationUpdateConsolidatedShipmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConsolidationUpdateConsolidatedShipmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [consolidationUpdateConsolidatedShipmentMutation, { data, loading, error }] = useConsolidationUpdateConsolidatedShipmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConsolidationUpdateConsolidatedShipmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConsolidationUpdateConsolidatedShipmentMutationResult,
    ConsolidationUpdateConsolidatedShipmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    ConsolidationUpdateConsolidatedShipmentMutationResult,
    ConsolidationUpdateConsolidatedShipmentMutationVariables
  >(ConsolidationUpdateConsolidatedShipmentDocument, options);
}
export type ConsolidationUpdateConsolidatedShipmentMutationHookResult = ReturnType<
  typeof useConsolidationUpdateConsolidatedShipmentMutation
>;
