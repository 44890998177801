import { array, boolean, object, ObjectSchema, string } from 'yup';

export const validationSchema: ObjectSchema<{}> = object().shape({
  name: string().required('Account name is required'),
  status: string().required('Status is required'),
  carrier: boolean(),
  scacCode: string().when('carrier', { is: true, then: (schema) => schema.required('Carrier (SCAC) is required') }),
  bipCode: string().when('carrier', { is: true, then: (schema) => schema.required('BIP code is required') }),
  contactPeople: array().of(
    object().shape({
      firstName: string().required('First name is required'),
      lastName: string().required('Last name is required'),
      email: string().email().required('Email is required'),
      phoneNumber: string().required('Phone number is required')
    })
  )
});
