import type { FC } from 'react';

import type { JourneyStopReference } from '@zen/Journey/types';

import StopReferenceInlineField from '../forms/StopReferenceInlineField';

interface Props {
  stopReferences: JourneyStopReference[];
}

const StopReferences: FC<Props> = ({ stopReferences }) => {
  const filteredReferences: JourneyStopReference[] = stopReferences.filter(
    ({ canViewReference }: JourneyStopReference) => canViewReference
  );

  if (filteredReferences.length === 0) {
    return null;
  }

  const renderReference = ({ canUpdateReference, id, reference, stopAction }: JourneyStopReference) => {
    return (
      <StopReferenceInlineField
        key={id}
        canUpdateReference={!!canUpdateReference}
        reference={reference || ''}
        stopAction={stopAction}
        stopId={id}
      />
    );
  };

  return <div className="flex flex-col pt-2 space-y-2 text-xs text-grey-base">{filteredReferences.map(renderReference)}</div>;
};

export default StopReferences;
