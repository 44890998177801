import type { FC, ReactNode } from 'react';

import Popover from '../Popover';

interface Props {
  body: ReactNode;
  children: ReactNode;
  className?: string;
  footer?: ReactNode;
  offset?: [number, number];
  title?: string;
}

const InlineDialog: FC<Props> = ({ body, children, className, footer, offset = [0, 10], title }) => {
  const renderTooltipContent = (): ReactNode => {
    if (!title) {
      return body;
    }

    return (
      <div className="max-w-sm p-6 text-sm leading-5 text-grey-dark">
        <h1 className="mb-2 text-lg font-bold">{title}</h1>
        <div>{body}</div>
        {footer && <div className="mt-4">{footer}</div>}
      </div>
    );
  };

  return (
    <Popover
      offset={offset}
      placement="bottom-start"
      popover={renderTooltipContent()}
      showArrow={false}
      trigger="click"
      triggerClassName={className}
    >
      {children}
    </Popover>
  );
};

export type { Props as InlineDialogProps };
export default InlineDialog;
