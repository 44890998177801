import { isEqual } from 'lodash';

import type { OrderFilters } from '@zen/Orders';
import { PurchaseOrderBookingStateEnum, PurchaseOrderStatusEnum } from '@zen/Orders/types';
import { getDateRange, getYesterday } from '@zen/utils/dateTime';
import { ALL_DAYS_IN_THE_PAST } from '@zen/utils/Filtering';
import type { Optional } from '@zen/utils/typescript';

import type { OrderListSavedFiltersCountQueryResult, SavedFilterConfigItem } from './types';

const BOOKING_STATE_DEFAULT: PurchaseOrderBookingStateEnum[] = [
  PurchaseOrderBookingStateEnum.NOT_BOOKED,
  PurchaseOrderBookingStateEnum.PARTIALLY_BOOKED
];

export const compareFilters = (item: SavedFilterConfigItem, appliedFilters: OrderFilters): boolean => {
  // needs sorting as we don't know in which order bookingState array elements are sorted as they are selected by user
  const sortedAppliedFilters: OrderFilters = {
    ...appliedFilters,
    ...(appliedFilters?.bookingState ? { bookingState: appliedFilters?.bookingState.sort() } : {})
  };
  const sortedItemFilters: OrderFilters = {
    ...item.filters,
    bookingState: item?.filters?.bookingState?.sort()
  };

  return isEqual(sortedItemFilters, sortedAppliedFilters);
};

export const getSavedFiltersConfig = (data: Optional<OrderListSavedFiltersCountQueryResult>): SavedFilterConfigItem[] => {
  const baseFilters: OrderFilters = {
    bookingState: BOOKING_STATE_DEFAULT,
    status: PurchaseOrderStatusEnum.OPEN
  };

  return [
    {
      title: 'Unbooked POs',
      subtitle: 'CRD in the past',
      count: data?.unbookedPastCRD?.totalCount || 0,
      filters: {
        cargoReadyDateBetween: getDateRange(ALL_DAYS_IN_THE_PAST, getYesterday()),
        ...baseFilters
      },
      type: 'problem'
    },
    {
      title: 'Unbooked POs',
      subtitle: 'CRD in the next 2 weeks',
      count: data?.unbookedCRDIn2Weeks?.totalCount || 0,
      filters: {
        cargoReadyDateBetween: getDateRange(14),
        ...baseFilters
      },
      type: 'warning'
    },
    {
      title: 'CRD updated',
      subtitle: 'in the last 3 days',
      count: data?.cargoReadyDateUpdatedInLast3Days?.totalCount || 0,
      filters: {
        lastUpdatedBetween: getDateRange(-2, getYesterday()),
        ...baseFilters
      },
      type: 'success'
    }
  ];
};
