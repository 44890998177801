import { type ReactNode, useState } from 'react';
import useDeepCompareEffect from 'react-use/lib/useDeepCompareEffect';

import type { FilterOptionsType } from '@zen/Components/Filters/types';
import { getAllOptions, type Option, Popover } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { FilterComponentProps } from '../../types';
import { buildOptionListSections } from '../helpers';
import OptionList from '../OptionList';

type FilterSelectProps<T> = {
  clearable?: boolean;
  loading?: boolean;
  onSearch?: (query: string) => void;
  options: FilterOptionsType<T>;
  searchPlaceholder?: string;
  searchable?: boolean;
};

type Props<T> = {
  onChange: (value: Nullable<T>) => void;
  value: Nullable<T>;
} & FilterSelectProps<T> &
  FilterComponentProps;

const FilterSelect = <T,>({
  onChange,
  options,
  value,
  active = false,
  searchable = true,
  clearable = true,
  loading,
  disabled,
  onSearch,
  onDelete,
  searchPlaceholder,
  placement,
  deselectFilter,
  children
}: Props<T>) => {
  const [selectedOption, setSelectedOption] = useState<Option<T>>();
  const allOptions = getAllOptions(options);

  useDeepCompareEffect(() => {
    const initialOption: Option<T> | undefined = allOptions.find((option) => option.value === value);

    if (initialOption) {
      setSelectedOption(initialOption);
    }
  }, [options, value]);

  const popover = (): ReactNode => {
    const onClick = (option: Option<T>): void => {
      onChange(option.value);
      setSelectedOption(option);
      deselectFilter();
    };

    const isSelected = (option: Option<T>): boolean => selectedOption?.value === option.value;

    const list = buildOptionListSections(options, { isSelected, onClick, isPrioritised: isSelected });

    const handleClear = (): void => {
      onChange(null);
      setSelectedOption(undefined);
    };

    return (
      <div data-testid="filter-select-popover">
        <OptionList
          list={list}
          loading={loading}
          onClear={clearable ? handleClear : undefined}
          onSearch={onSearch}
          searchable={searchable}
          searchPlaceholder={searchPlaceholder}
        />
      </div>
    );
  };

  const handleVisibilityChange = (visible: boolean): void => {
    if (!visible && !value) {
      onDelete();
    }

    if (!visible) {
      deselectFilter();
    }
  };

  return (
    <Popover
      disabled={disabled}
      isVisible={active}
      onVisibilityChange={handleVisibilityChange}
      placement={placement}
      popover={popover()}
      popoverClassNames="border border-solid border-grey-lighter"
      renderInPortal={true}
    >
      {children(selectedOption?.label || null)}
    </Popover>
  );
};

export default FilterSelect;
export type { FilterSelectProps };
