import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { useLocalStorage } from 'react-use';

import type { IconName } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';

type BannerMessageType = 'announcement' | 'default' | 'error' | 'warning';

interface Props {
  bannerkey?: string;
  className?: string;
  icon?: IconName;
  isClosable?: boolean;
  message: ReactNode;
  onClose?: () => void;
  type?: BannerMessageType;
}

export const displayName = 'Design System/Banner';

const Banner: FC<Props> = ({ bannerkey = '', className, icon, isClosable = false, message, type = 'default', ...rest }) => {
  const [isBannerDisplayed, saveIsBannerDisplayed] = useLocalStorage(`banner-${bannerkey}`, true);
  const { trackEvent } = useTracking();

  const typeClassNameMapping: Record<BannerMessageType, string> = {
    announcement: 'bg-azure-lighter',
    default: 'bg-grey-lighter text-grey-dark',
    error: 'bg-red-dark text-white',
    warning: 'bg-orange-light text-grey-dark'
  };

  const defaultIcon: Record<BannerMessageType, IconName> = {
    announcement: 'zicon-announcement',
    default: 'zicon-information',
    error: 'zicon-warning-triangle',
    warning: 'zicon-information'
  };

  const wrapperClassName: string = cx(
    'flex justify-between  items-center',
    {
      'p-4 rounded': !className
    },
    typeClassNameMapping[type],
    className
  );

  if (isClosable && !isBannerDisplayed) {
    return null;
  }

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'close', event);

    trackEvent(trackingEvent);

    saveIsBannerDisplayed(!isBannerDisplayed);
  };

  return (
    <div className={wrapperClassName} data-testid="banner-component" {...createTrackingParentAttribute('banner')} {...rest}>
      <div className="flex items-center flex-1" {...createTrackingLabelAttribute(type)}>
        <Icon className="mr-3 text-base" icon={icon || defaultIcon[type]} />
        {message}
      </div>
      {isClosable && <Icon className="cursor-pointer text-grey-dark" icon="zicon-close" onClick={handleClick} />}
    </div>
  );
};

export type { Props as BannerProps };

export default Banner;
