import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateMilestonePlannedDateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateMilestonePlannedDateInput;
}>;

export type UpdateMilestonePlannedDateMutationResult = { __typename?: 'Mutation' } & {
  updateMilestonePlannedDate: { __typename?: 'UpdateMilestonePlannedDatePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateMilestonePlannedDateDocument = /* #__PURE__ */ gql`
  mutation updateMilestonePlannedDate($input: UpdateMilestonePlannedDateInput!) {
    updateMilestonePlannedDate(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateMilestonePlannedDateMutation__
 *
 * To run a mutation, you first call `useUpdateMilestonePlannedDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestonePlannedDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestonePlannedDateMutation, { data, loading, error }] = useUpdateMilestonePlannedDateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMilestonePlannedDateMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMilestonePlannedDateMutationResult, UpdateMilestonePlannedDateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateMilestonePlannedDateMutationResult, UpdateMilestonePlannedDateMutationVariables>(
    UpdateMilestonePlannedDateDocument,
    options
  );
}
export type UpdateMilestonePlannedDateMutationHookResult = ReturnType<typeof useUpdateMilestonePlannedDateMutation>;
