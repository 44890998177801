import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCargoOptionsQueryVariables = GraphQLTypes.Exact<{
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  cargoType: GraphQLTypes.CargoModeEnum;
}>;

export type GetCargoOptionsQueryResult = { __typename?: 'Query' } & {
  getCargoOptions?: GraphQLTypes.Maybe<
    Array<GraphQLTypes.Maybe<{ __typename?: 'CargoOptions' } & Pick<GraphQLTypes.CargoOptions, 'cargoType' | 'name'>>>
  >;
};

export const GetCargoOptionsDocument = /* #__PURE__ */ gql`
  query getCargoOptions($modeOfTransport: ModeOfTransport!, $cargoType: CargoModeEnum!) {
    getCargoOptions(modeOfTransport: $modeOfTransport, cargoType: $cargoType) {
      cargoType
      name
    }
  }
`;

/**
 * __useGetCargoOptionsQuery__
 *
 * To run a query within a React component, call `useGetCargoOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCargoOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCargoOptionsQuery({
 *   variables: {
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *   },
 * });
 */
export function useGetCargoOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCargoOptionsQueryResult, GetCargoOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCargoOptionsQueryResult, GetCargoOptionsQueryVariables>(GetCargoOptionsDocument, options);
}
export function useGetCargoOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCargoOptionsQueryResult, GetCargoOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCargoOptionsQueryResult, GetCargoOptionsQueryVariables>(GetCargoOptionsDocument, options);
}
export type GetCargoOptionsQueryHookResult = ReturnType<typeof useGetCargoOptionsQuery>;
export type GetCargoOptionsLazyQueryHookResult = ReturnType<typeof useGetCargoOptionsLazyQuery>;
