import { findIndex, orderBy, take } from 'lodash';
import type { ReactChild } from 'react';
import { useLocalStorage } from 'react-use';

import type { VisitedAccount } from './types';
import VisitedAccountsContext from './VisitedAccountsContext';

interface Props {
  children: ReactChild;
  currentUserId: string;
}

const VISITED_ACCOUNTS_KEY = 'visitedAccounts';

const VisitedAccountsProvider = ({ currentUserId, children }: Props) => {
  const [visitedAccountsRecord, setVisitedAccountsRecord] = useLocalStorage<Record<string, VisitedAccount[]>>(currentUserId, {
    [VISITED_ACCOUNTS_KEY]: []
  });

  const visitedAccounts = visitedAccountsRecord?.[VISITED_ACCOUNTS_KEY] || [];

  const updateVisitedAccounts = (accountId: string) => {
    const savedAccountIndex = findIndex(visitedAccounts, { id: accountId });
    let visitedAccount: VisitedAccount;

    if (savedAccountIndex > -1) {
      visitedAccount = visitedAccounts[savedAccountIndex];
      ++visitedAccount.count;
      visitedAccount.lastVisitedTime = Date.now();
    } else {
      visitedAccount = { id: accountId, count: 1, lastVisitedTime: Date.now() };
    }

    setVisitedAccountsRecord({ [VISITED_ACCOUNTS_KEY]: [...visitedAccounts, visitedAccount] });
  };

  const lastVisitedAccounts = take(orderBy(visitedAccounts, 'lastVisitedTime', 'desc'), 3);

  const mostVisitedAccounts = take(orderBy(visitedAccounts, 'count', 'desc'), 3);

  const context = {
    visitedAccounts,
    updateVisitedAccounts,
    lastVisitedAccounts,
    mostVisitedAccounts
  };

  return <VisitedAccountsContext.Provider value={context}>{children}</VisitedAccountsContext.Provider>;
};

export default VisitedAccountsProvider;
