import type { SortInput } from '@zen/types';
import usePersistedFilters from '@zen/utils/Filtering/hooks/usePersistedFilters';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';

import type {
  GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
  GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables
} from '../../graphql';
import { useGetPurchaseOrdersOriginAgentAssignmentRulesQuery } from '../../graphql';
import type { AgentAssignmentRulesFiltersType, OriginAgentAssignmentRule } from '../../types';

const useAgentAssignmentRules = (order: SortInput) => {
  const { filters } = usePersistedFilters<AgentAssignmentRulesFiltersType>('agentAssignmentRulesFilters');
  const { accountId, origin, destination, transportModes } = filters;

  return useUrlPagination<
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables,
    OriginAgentAssignmentRule
  >(
    useGetPurchaseOrdersOriginAgentAssignmentRulesQuery,
    'purchaseOrdersOriginAgentAssignmentRules',
    {
      order,
      ...(accountId && { accountIds: [accountId] }),
      ...(origin && { origins: [origin?.id as string] }),
      ...(destination && { destinations: [destination?.id as string] }),
      transportModes
    },
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    },
    20
  );
};

export default useAgentAssignmentRules;
