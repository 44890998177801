import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { BookingStageEnum, IncotermsValue, ModeOfTransport, ShipmentType, TradeRole } from './types';

export const isAirBooking = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.AIR;

export const isOceanBooking = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.OCEAN;

export const isRailBooking = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.RAIL;

export const isRoadBooking = (modeOfTransport: Optional<ModeOfTransport>): boolean => modeOfTransport === ModeOfTransport.TRUCK;

export const isBookingConfirmed = (bookingStage: Optional<BookingStageEnum>): boolean => {
  return bookingStage === BookingStageEnum.BOOKING_CONFIRMED;
};

export const incotermsLabelMapping: Record<IncotermsValue, string> = {
  [IncotermsValue.CFR]: 'CFR',
  [IncotermsValue.CIF]: 'CIF',
  [IncotermsValue.CIP]: 'CIP',
  [IncotermsValue.CPT]: 'CPT',
  [IncotermsValue.DAP]: 'DAP',
  [IncotermsValue.DAT]: 'DAT',
  [IncotermsValue.DDP]: 'DDP',
  [IncotermsValue.EXWORKS]: 'EXW',
  [IncotermsValue.FAS]: 'FAS',
  [IncotermsValue.FCA]: 'FCA',
  [IncotermsValue.FOB]: 'FOB'
};

export const incotermOptions: Option<IncotermsValue>[] = [
  { label: incotermsLabelMapping[IncotermsValue.CFR], value: IncotermsValue.CFR },
  { label: incotermsLabelMapping[IncotermsValue.CIF], value: IncotermsValue.CIF },
  { label: incotermsLabelMapping[IncotermsValue.CIP], value: IncotermsValue.CIP },
  { label: incotermsLabelMapping[IncotermsValue.CPT], value: IncotermsValue.CPT },
  { label: incotermsLabelMapping[IncotermsValue.DAP], value: IncotermsValue.DAP },
  { label: incotermsLabelMapping[IncotermsValue.DAT], value: IncotermsValue.DAT },
  { label: incotermsLabelMapping[IncotermsValue.DDP], value: IncotermsValue.DDP },
  { label: incotermsLabelMapping[IncotermsValue.EXWORKS], value: IncotermsValue.EXWORKS },
  { label: incotermsLabelMapping[IncotermsValue.FAS], value: IncotermsValue.FAS },
  { label: incotermsLabelMapping[IncotermsValue.FCA], value: IncotermsValue.FCA },
  { label: incotermsLabelMapping[IncotermsValue.FOB], value: IncotermsValue.FOB }
];

export const shipmentTypeLabelMapping: Record<ShipmentType, string> = {
  [ShipmentType.CROSS_TRADE]: 'Cross trade',
  [ShipmentType.DOMESTIC]: 'Domestic',
  [ShipmentType.EXPORT]: 'Export',
  [ShipmentType.IMPORT]: 'Import'
};

export const shipmentTypeOptions: Option<ShipmentType>[] = [
  { label: shipmentTypeLabelMapping[ShipmentType.EXPORT], value: ShipmentType.EXPORT },
  { label: shipmentTypeLabelMapping[ShipmentType.IMPORT], value: ShipmentType.IMPORT },
  { label: shipmentTypeLabelMapping[ShipmentType.DOMESTIC], value: ShipmentType.DOMESTIC },
  { label: shipmentTypeLabelMapping[ShipmentType.CROSS_TRADE], value: ShipmentType.CROSS_TRADE }
];

export const tradeRoleLabelMapping: Record<TradeRole, string> = {
  [TradeRole.BUYER]: 'Buyer',
  [TradeRole.SELLER]: 'Seller',
  [TradeRole.TRANSFER]: 'Transfer'
};

export const tradeRoleOptions: Option<TradeRole>[] = [
  { label: tradeRoleLabelMapping[TradeRole.BUYER], value: TradeRole.BUYER },
  { label: tradeRoleLabelMapping[TradeRole.SELLER], value: TradeRole.SELLER },
  { label: tradeRoleLabelMapping[TradeRole.TRANSFER], value: TradeRole.TRANSFER }
];
