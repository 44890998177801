import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useConsumeLocationState<T>(callback: (state: T) => void): void;
export function useConsumeLocationState<T>(callback: (state: T[keyof T]) => void, stateProp: keyof T): void;
export function useConsumeLocationState<T>(callback: (state: T | T[keyof T]) => void, stateProp?: keyof T) {
  const navigate = useNavigate();
  const { search, pathname, state } = useLocation();

  useEffect(() => {
    const data = stateProp ? state && state[stateProp] : state;

    if (data) {
      if (stateProp) {
        delete state[stateProp];
      }
      const newState = state;

      navigate({ pathname, search }, { state: newState, replace: true });
      callback(data);
    }
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps
}
