import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Option, Select } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { useSearchableOptions } from './useSearchableOptions';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  onChange?: (value: Nullable<string>) => void;
  showSearchIcon?: boolean;
  value?: Nullable<string>;
  variant?: 'inline' | 'default';
}

const OceanCarrierSelect: FC<Props> = (props) => {
  const { getCarrier, options, loading, onSearch } = useSearchableOptions({ selectedValue: props.value });

  const getOptionLabel = (carrier: Option<string>, isSelected: boolean, context: 'menu' | 'value'): ReactNode => {
    const { carrierScac, oceanCarrierName, synonyms } = getCarrier(carrier.value) || {};
    const scacCodes: string = [carrierScac].concat(synonyms || []).join(', ') || '';
    const classNames: string = cx({ 'text-white': isSelected, 'text-grey-base': !isSelected }, 'text-xs truncate');

    if (context === 'value') {
      return oceanCarrierName;
    }

    return (
      <div>
        <div className="truncate">{oceanCarrierName}</div>
        <div className={classNames}>{scacCodes}</div>
      </div>
    );
  };

  return (
    <Select
      {...props}
      formatOptionLabel={getOptionLabel}
      hasError={props.error}
      isLoading={loading}
      labelPlacement="left"
      onInputChange={onSearch}
      options={options}
      placeholder="Enter carrier"
      showSearchIcon={props.showSearchIcon}
      wrapperClassName="flex-1"
    />
  );
};

export default OceanCarrierSelect;
