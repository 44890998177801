import { IconButton } from '@zen/DesignSystem';

interface CloseIconProps {
  className?: string;
  onClick: () => void;
}

const CloseIcon = (props: CloseIconProps) => {
  return <IconButton delayShow={1500} icon="zicon-close" size="medium" variant="ghost" {...props} title="Close" />;
};

export default CloseIcon;
