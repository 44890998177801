import { omit } from 'lodash';

import { getDefaultLatLng } from '@zen/Accounts/AccountForms/helpers';

import type { ShippingAddressFormValues } from '../types';
import type { UpdateAccountLocationAddressInput } from './types';

const prepareValues = (formValues: ShippingAddressFormValues): UpdateAccountLocationAddressInput => {
  const { shippingLocationAddress, geolocation, locationId } = omit(formValues, 'isPinPlacementConfirmed');

  return {
    address: shippingLocationAddress,
    geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
    locationId
  };
};

export { prepareValues };
