import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetWebhooksQueryVariables = GraphQLTypes.Exact<{
  customerAccountId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  id?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetWebhooksQueryResult = { __typename?: 'Query' } & {
  webhookSubscriptionsWebhooks: Array<
    { __typename?: 'WebhookSubscriptionsWebhook' } & Pick<
      GraphQLTypes.WebhookSubscriptionsWebhook,
      'id' | 'callbackUrl' | 'contactEmail' | 'name' | 'topics'
    > & { isDisabled: GraphQLTypes.WebhookSubscriptionsWebhook['disabled'] } & {
        headers?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'WebhookHeader' } & Pick<GraphQLTypes.WebhookHeader, 'name' | 'value'>>
        >;
      }
  >;
};

export const GetWebhooksDocument = /* #__PURE__ */ gql`
  query getWebhooks($customerAccountId: String, $id: String) {
    webhookSubscriptionsWebhooks(customerAccountId: $customerAccountId, id: $id) {
      id
      callbackUrl
      contactEmail
      name
      topics
      isDisabled: disabled
      headers {
        name
        value
      }
    }
  }
`;

/**
 * __useGetWebhooksQuery__
 *
 * To run a query within a React component, call `useGetWebhooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebhooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebhooksQuery({
 *   variables: {
 *      customerAccountId: // value for 'customerAccountId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWebhooksQuery(baseOptions?: Apollo.QueryHookOptions<GetWebhooksQueryResult, GetWebhooksQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetWebhooksQueryResult, GetWebhooksQueryVariables>(GetWebhooksDocument, options);
}
export function useGetWebhooksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWebhooksQueryResult, GetWebhooksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetWebhooksQueryResult, GetWebhooksQueryVariables>(GetWebhooksDocument, options);
}
export type GetWebhooksQueryHookResult = ReturnType<typeof useGetWebhooksQuery>;
export type GetWebhooksLazyQueryHookResult = ReturnType<typeof useGetWebhooksLazyQuery>;
