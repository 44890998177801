import type { PillType } from './types';

export const defaultPillClasses: Record<PillType, string> = {
  custom: '', // Allows custom colours to be added without conflicting tw classes
  default: 'bg-azure-lighter text-grey-dark',
  pending: 'bg-grey-lighter text-grey-dark',
  warning: 'bg-orange-light text-grey-dark',
  problem: 'bg-red-light text-grey-dark',
  success: 'bg-green-light text-grey-dark'
};

export const activePillClasses: Record<PillType, string> = {
  custom: '', // Allows custom colours to be added without conflicting tw classes
  default: 'hover:border-azure-base active:border-azure-base active:bg-azure-light',
  pending: 'hover:border-grey-base active:border-grey-base active:bg-grey-light',
  warning: 'hover:border-orange-dark active:border-orange-dark active:bg-orange-base',
  problem: 'hover:border-red-dark active:border-red-dark active:bg-red-base',
  success: 'hover:border-green-dark active:border-green-dark active:bg-green-base'
};
