import cx from 'classnames';
import type { FC } from 'react';

import { NumberInput } from '@zen/DesignSystem';
import type { Currency, Money } from '@zen/types';
import type { Modify, Nullable } from '@zen/utils/typescript';

import CurrencySelect from '../CurrencySelect';

export type MoneyType = Modify<Money, { value: Nullable<number> }>;

interface Props {
  autoFocus?: boolean;
  availableCurrencies?: Currency[];
  className?: string;
  error?: boolean;
  isDisabled?: boolean;
  name: string;
  onBlur?: () => void;
  onChange: (value: MoneyType) => void;
  value: MoneyType;
}

const CurrencyInput: FC<Props> = (props) => {
  const { autoFocus, availableCurrencies, className, isDisabled, onBlur, onChange, name, value, error } = props;
  const currencyValue: Currency = value?.currency;
  const inputValue: Nullable<number> = value?.value;
  const wrapperClassNames: string = cx('flex items-end space-x-1', className);

  const classNames: string = cx('min-w-24 flex-1', className);

  const handleChangeValue = (newValue: Nullable<number>): void => {
    onChange({ value: newValue, currency: currencyValue });
  };

  const handleChangeCurrency = (currency: Nullable<Currency>): void => {
    if (currency) {
      onChange({ value: inputValue, currency });
    }
  };

  return (
    <div className={wrapperClassNames}>
      <div className={classNames}>
        <NumberInput
          autoFocus={autoFocus}
          className={classNames}
          data-testid={name}
          disabled={isDisabled}
          error={error}
          name={name}
          onBlur={onBlur}
          onChange={handleChangeValue}
          placeholder="0.00"
          step="any"
          tabIndex={0}
          value={inputValue}
        />
      </div>
      <CurrencySelect
        availableCurrencies={availableCurrencies}
        hasError={error}
        isDisabled={isDisabled}
        isSearchable={false}
        name="currencyInput"
        onChange={handleChangeCurrency}
        renderMenuInPortal={true}
        value={currencyValue}
      />
    </div>
  );
};

export type { Props as CurrencyInputProps };

export default CurrencyInput;
