import { FC, ReactNode, useState } from 'react';

import { Button, ButtonVariant } from '@zen/DesignSystem';
import { QuoteOption, QuoteOptionRejectionReason } from '@zen/Quotes/types';

import { isReasonProvided } from '../../quotes.helpers';
import QuoteOptionPanel from '../QuoteOptionPanel';
import OtherRejectReasonForm from './OtherRejectReasonForm';

interface Props {
  handleReject: (reason: QuoteOptionRejectionReason, text?: string) => void;
  quoteOption: Pick<QuoteOption, 'rejectionReason' | 'reasonDescription'>;
}

const RejectReasonChoice: FC<Props> = (props) => {
  const { quoteOption } = props;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Maybe<QuoteOptionRejectionReason... Remove this comment to see the full error message
  const [selectedReason, setSelectedReason] = useState<QuoteOptionRejectionReason>(quoteOption.rejectionReason);
  const reasonProvided: boolean = isReasonProvided(quoteOption);

  const renderOption = (value: QuoteOptionRejectionReason, label: string): ReactNode => {
    const isSelected: boolean = selectedReason === value;
    const buttonVariant: ButtonVariant = isSelected ? 'primary' : 'secondary';

    const handleSelect = (): void => {
      setSelectedReason(value);

      if (value !== QuoteOptionRejectionReason.OTHER) {
        props.handleReject(value);
      }
    };

    return (
      <Button className="mr-4" disabled={reasonProvided} onClick={handleSelect} variant={buttonVariant}>
        {label}
      </Button>
    );
  };

  const showTextArea: boolean = selectedReason === QuoteOptionRejectionReason.OTHER;

  const handleSubmit = (values: { reasonDescription: string }): void => {
    props.handleReject(QuoteOptionRejectionReason.OTHER, values.reasonDescription);
  };

  return (
    <>
      <QuoteOptionPanel className="pl-12 mb-2">
        <div className="font-bold text-sm mb-4">Can I ask for the reason you rejected this quote option?</div>
        <div className="flex justify-between">
          {renderOption(QuoteOptionRejectionReason.PRICE, 'Price')}
          {renderOption(QuoteOptionRejectionReason.SCHEDULE, 'Schedule')}
          {renderOption(QuoteOptionRejectionReason.RESPONSE_TIME, 'Response time')}
          {renderOption(QuoteOptionRejectionReason.OTHER, 'Other')}
        </div>
      </QuoteOptionPanel>

      {showTextArea && (
        <QuoteOptionPanel className="pl-12">
          <OtherRejectReasonForm
            initialValues={{
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
              reasonDescription: quoteOption.reasonDescription
            }}
            onSubmit={handleSubmit}
          />
        </QuoteOptionPanel>
      )}
    </>
  );
};

export default RejectReasonChoice;
