import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { TaskPriority } from '@zen/Tasks/types';
import { compareDate, formatDate, getToday } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  className?: string;
  completedDate: Optional<string>;
  dueDate: Optional<string>;
  priority: TaskPriority;
}

const TaskDateDisplay: FC<Props> = ({ dueDate = '', completedDate = '', priority, className }) => {
  const isDueDateOverdue: boolean = compareDate(getToday()).isAfter(dueDate || '');
  const isCompleted: boolean = !!completedDate;

  const dateClassName: string = cx('w-20 border-solid border-r flex flex-col justify-center items-center h-full', className, {
    'border-red-light': priority === TaskPriority.high,
    'text-red-dark': isDueDateOverdue,
    'border-red-base': priority === TaskPriority.critical,
    'border-orange-light': priority === TaskPriority.medium,
    'border-navy-light': priority === TaskPriority.low,
    'border-azure-lighter': priority === TaskPriority.minor
  });

  const renderIcon = (): ReactNode => {
    const calendarIcon: IconName = isDueDateOverdue ? 'zicon-calendar-alert' : 'zicon-calendar';
    const displayedIcon: IconName = isCompleted ? 'zicon-tickoval' : calendarIcon;
    const iconClassName: string = cx({ 'text-green-base': isCompleted });

    return <Icon className={iconClassName} icon={displayedIcon} />;
  };

  const renderDateText = (): ReactNode => {
    const dateTextClassName: string = cx('mt-2', { 'text-grey-dark': isCompleted });
    const dateToDisplay: Optional<string> = isCompleted ? completedDate : dueDate;
    const tooltipContent: string = isCompleted ? 'Completion date' : 'Due date';

    return (
      <div className={dateTextClassName}>
        <Tooltip delayShow={1000} placement="bottom" tooltipContent={tooltipContent}>
          <time>{formatDate(dateToDisplay || '', 'MMM_D')}</time>
        </Tooltip>
      </div>
    );
  };

  return (
    <div className={dateClassName} data-testid="due-date">
      {renderIcon()}
      {renderDateText()}
    </div>
  );
};

export default TaskDateDisplay;

export type { Props as TaskDateDisplayProps };
