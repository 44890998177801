import cx from 'classnames';
import { FC, ReactNode, useState } from 'react';

import CollapsibleElement from '@zen/Components/CollapsibleElement';
import StageName from '@zen/Components/StageName';
import JourneyContentSwitcher from '@zen/Journey/components/JourneyContentSwitcher';
import PathIndicator from '@zen/Journey/components/PathIndicator';
import VerticalPathProvider from '@zen/Journey/components/VerticalPathProvider';
import { formatDate } from '@zen/utils/dateTime';

import type { BookingStage } from '../../../types';

interface Props {
  isBookingInProgress: boolean;
  stages: BookingStage[];
}

const BookingStages: FC<Props> = ({ isBookingInProgress, stages }) => {
  const [showBookingStates, setShowBookingStages] = useState<boolean>(!isBookingInProgress);

  const renderStage = ({ current, name, started, startedAt }: BookingStage): ReactNode => {
    const isCurrent: boolean = !!current && !isBookingInProgress;
    const className: string = cx('text-grey-dark', { 'font-bold text-base': isCurrent, 'text-sm': !isCurrent });
    const pathIndicatorClassNames: string = cx({ '-mt-px': isCurrent }, 'self-start');

    return (
      <div key={name} className="flex items-baseline pb-3.5 pr-8" data-testid="booking-stage">
        <div className="w-48 text-right text-xs mt-0.5">{startedAt && formatDate(startedAt.date)}</div>
        <PathIndicator
          className={pathIndicatorClassNames}
          currentIndicator="tick"
          isCompleted={!!started}
          isCurrent={isCurrent}
        />
        <div key={name} className={className}>
          <StageName name={name} />
        </div>
      </div>
    );
  };

  return (
    <>
      <JourneyContentSwitcher
        className="pb-2 ml-48"
        isVisible={showBookingStates}
        label={`${showBookingStates ? 'Hide' : 'Show'} booking stages`}
        onChange={() => setShowBookingStages(!showBookingStates)}
      />
      <CollapsibleElement isOpened={showBookingStates}>
        <VerticalPathProvider dependencies={[stages]}>{stages.map(renderStage)}</VerticalPathProvider>
      </CollapsibleElement>
    </>
  );
};

export default BookingStages;
