import type { FC, ReactNode } from 'react';
import { Navigate, Route, Routes as RouterRoutes, useLocation } from 'react-router-dom';

import AccountActivationPage from '@zen/Accounts/AccountActivationPage';
import RouteAuthenticator from '@zen/Auth/RouteAuthenticator';
import OutdatedBrowser from '@zen/Components/OutdatedBrowser';
import Rollbar from '@zen/Components/Rollbar';
import DownloadPage from '@zen/DownloadPage';
import { authRoutes, dashboardRoutes, developerRoutes, downloadRoutes } from '@zen/Routes';
import { Env } from '@zen/types';
import loadable from '@zen/utils/component/loadable';

import DashboardContainer from '../DashboardContainer';
import { useCatchAuthError } from './hooks/useCatchAuthError';
import RouteError from './RouteError';

interface Props {
  env: Env;
}

const AccountSwitcher = loadable(() => import('@zen/Components/AccountSwitcher'));
const PageNotFound = loadable(() => import('@zen/Components/PageNotFound'));
const PrivateGraphiQL = loadable(() => import('@zen/PrivateGraphiql/PrivateGraphiql'));

export const AppRoutes: FC<Props> = ({ env }) => {
  const { isError, errorMessage } = useCatchAuthError();

  // we need to keep useLocation here even if it's not used because otherwise,
  // user is not Navigateed to the dashboard page after successful login
  useLocation();

  const routes: ReactNode = (
    <RouterRoutes>
      <Route element={<RouteAuthenticator element={<DashboardContainer />} />} path={dashboardRoutes.dashboard.path} />
      <Route element={<RouteAuthenticator element={<AccountSwitcher />} />} path={authRoutes.accountSwitcher.path} />
      <Route element={<RouteAuthenticator element={<AccountActivationPage />} />} path={authRoutes.accountActivation.path} />
      <Route element={<RouteAuthenticator element={<DownloadPage />} />} path={downloadRoutes.index.path} />
      {env === Env.DEV && <Route element={<PrivateGraphiQL />} path={developerRoutes.graphiql.getUrl()} />}
      <Route element={<Navigate to={dashboardRoutes.dashboard.getUrl()} />} path="/" />
      <Route element={<Navigate to={dashboardRoutes.dashboard.getUrl()} />} path={authRoutes.login.getUrl()} />
      <Route element={<PageNotFound />} path="*" />
    </RouterRoutes>
  );

  if (isError) {
    return <RouteError message={errorMessage} />;
  }

  return (
    <div className="antialiased">
      <Rollbar env={env} />
      <OutdatedBrowser />
      {routes}
    </div>
  );
};

export default AppRoutes;
