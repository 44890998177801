import type { FC, ReactNode } from 'react';

import { AsyncSelect } from '@zen/DesignSystem';
import { apolloClient as apollo } from '@zen/graphql/GraphQLProvider';
import { ProductListDocument } from '@zen/Products/graphql';
import type { Product } from '@zen/Products/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import { highlightQuery } from '../utils';

interface Props {
  error?: boolean;
  name?: string;
  onChange?: (value: Nullable<Product>) => void;
  value?: Product;
}

const ProductSelect: FC<Props> = (props) => {
  const { accountUuid } = useAccount();

  const getLabel = ({ name, skuCode }: Product, inputValue: string = ''): ReactNode => {
    const label: string = `${name} (${skuCode})`;

    return highlightQuery(label, inputValue);
  };

  const handleInput = async (query: string = ''): Promise<Product[]> => {
    const QUERY = {
      query: ProductListDocument,
      variables: {
        accountUuid,
        query,
        archived: false,
        page: 1
      }
    };

    const response = await apollo.query(QUERY);

    return response.data.products.nodes;
  };

  return (
    <AsyncSelect<Product>
      {...props}
      cacheOptions={false}
      formatOptionLabel={getLabel}
      hasError={props.error}
      isClearable={true}
      loadOptions={handleInput}
      placeholder="Search for a SKU or product name"
    />
  );
};

export default ProductSelect;
