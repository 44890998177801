import moment, { Moment } from 'moment';
import pluralize from 'pluralize';

import type { DateRangeInput } from '@zen/types';

import { FORMAT_DATE_TRANSFERABLE } from './formatting';

export const getToday = () => moment().format(FORMAT_DATE_TRANSFERABLE);

export const getTomorrow = () => moment().add(1, 'd').format(FORMAT_DATE_TRANSFERABLE);

export const getTimeAgo = (date: string): string => {
  return moment(date).fromNow();
};

// Deprecated: use functions exposed in `src/utils/dateTime/index.ts` instead!
export const DEPRECATED_toDateString = (date: Date | number): string => {
  return moment(date).format(FORMAT_DATE_TRANSFERABLE);
};

export const getDateRangeInput = (daysDifference: number, startFrom?: Moment): DateRangeInput => {
  startFrom = startFrom || moment();

  let startDate: string = startFrom.format(FORMAT_DATE_TRANSFERABLE);
  let endDate: string = startFrom.add(daysDifference, 'days').format(FORMAT_DATE_TRANSFERABLE);

  if (daysDifference < 0) {
    // flip dates
    [startDate, endDate] = [endDate, startDate];
  }

  return {
    startDate,
    endDate
  };
};

export const getDateDifference = (delayInDays: number): string => {
  const count: number = Math.abs(delayInDays);
  const sign: string = delayInDays > 0 ? '+' : '-';

  if (!delayInDays) return '';

  return `${sign}${count} ${pluralize('day', count)}`;
};
