import { toast } from 'react-toastify';

import { Flash, FlashType } from '@zen/Components/FlashMessage';

import ToastMessage from './ToastMessage';

export { FlashType };

export const addFlashMessage =
  ({ type, text, options = {} }: Flash) =>
  () => {
    if (type === FlashType.INFO) {
      toast.success(<ToastMessage message={text} />, options);
    } else {
      toast.error(<ToastMessage message={text} />, options);
    }
  };
