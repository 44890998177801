import { isEmpty } from 'lodash';
import pluralize from 'pluralize';

import type { ShipmentsTableGroupsInfo } from '@zen/graphql/types.generated';

import { ShipmentsTableCargoGroupsGroupByColumn } from '../../types';

const groupMapping = {
  [ShipmentsTableCargoGroupsGroupByColumn.ZENCARGO_REFERENCE]: 'shipment',
  [ShipmentsTableCargoGroupsGroupByColumn.CARGO_ID]: 'cargo item',
  [ShipmentsTableCargoGroupsGroupByColumn.VEHICLE_PLATE]: 'vehicle',
  [ShipmentsTableCargoGroupsGroupByColumn.MASTER_AIRWAY_BILL]: 'master airway bill',
  [ShipmentsTableCargoGroupsGroupByColumn.CONTAINER_NUMBER]: 'container',
  [ShipmentsTableCargoGroupsGroupByColumn.CONSOLIDATION_REFERENCE]: 'consolidated shipment'
};

const createLabel = (totalCount: number, groupByColumn: ShipmentsTableCargoGroupsGroupByColumn) => {
  switch (groupByColumn) {
    case ShipmentsTableCargoGroupsGroupByColumn.ZENCARGO_REFERENCE:
    case ShipmentsTableCargoGroupsGroupByColumn.CARGO_ID:
    case ShipmentsTableCargoGroupsGroupByColumn.VEHICLE_PLATE:
    case ShipmentsTableCargoGroupsGroupByColumn.MASTER_AIRWAY_BILL:
    case ShipmentsTableCargoGroupsGroupByColumn.CONTAINER_NUMBER:
    case ShipmentsTableCargoGroupsGroupByColumn.CONSOLIDATION_REFERENCE:
      return `${totalCount} ${pluralize(groupMapping[groupByColumn], totalCount)}`;
    case ShipmentsTableCargoGroupsGroupByColumn.MASTER_BILL_OF_LADING:
      return `${totalCount} ${pluralize('master bill', totalCount)} of lading`;
  }
};

export const buildTotalCountLabel = (groupsInfo: ShipmentsTableGroupsInfo, groupBy: ShipmentsTableCargoGroupsGroupByColumn) => {
  const { numberOfGroups } = groupsInfo;
  const ungroupedTotal = groupsInfo.numberOfUngroupedCargos;
  const result: string[] = [];

  if (numberOfGroups) {
    result.push(createLabel(numberOfGroups, groupBy));
  }

  if (ungroupedTotal) {
    result.push(createLabel(ungroupedTotal, ShipmentsTableCargoGroupsGroupByColumn.CARGO_ID));
  }

  if (isEmpty(result)) {
    result.push(createLabel(0, groupBy));
  }

  return `${result.join(', ')} found`;
};
