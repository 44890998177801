import type { FC } from 'react';

import { DatePicker, Icon } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import { journeyRefetchQueryList } from '../helpers';
import { useUpdateJourneysCargoReadyDateMutation } from './graphql';
import { getDisabledDates } from './helpers';

interface Props {
  date?: Optional<string>;
  onChange: () => void;
}

const CargoReadyDatePicker: FC<Props> = ({ date, onChange }) => {
  const { addError, addSuccess } = useNotification();
  const [updateCargoReadyDates] = useUpdateJourneysCargoReadyDateMutation();
  const { cargoMilestones, modeOfTransport, zencargoReference } = useJourneyContext();

  const { disabledDates, tooltip } = getDisabledDates(cargoMilestones, modeOfTransport);

  const handleChange = (dateString: string) => {
    onChange();

    if (!dateString) return;

    return performMutation({
      mutationFn: () =>
        updateCargoReadyDates({
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              date: dateString,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError();
      },
      onSuccess: () => {
        addSuccess('Cargo ready date updated.');
      }
    });
  };

  return (
    <div>
      <DatePicker
        className="!border-none"
        disabled={disabledDates}
        mode="single"
        onChange={handleChange}
        selected={date || ''}
        tooltip={tooltip}
      />
      <div className="flex gap-3 p-4 text-sm text-grey-base max-w-[19rem] bg-white border-t border-solid border-grey-lighter text-left">
        <Icon className="mt-1 text-base" icon="zicon-information" />
        Cargo ready dates and delay reason changes will be reflected across all cargo in this shipment
      </div>
    </div>
  );
};

export default CargoReadyDatePicker;
