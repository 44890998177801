const QUOTES_RELATIVE_INDEX_PATH = 'quotes';

export const QUOTES_ROUTE_PREFIX = `/dashboard/${QUOTES_RELATIVE_INDEX_PATH}`;

interface QuotesRoutes {
  quoteDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  quoteIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  quoteIndexOrderDetails: {
    getUrl: (orderId: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
}

const quotesRoutes: QuotesRoutes = {
  quoteIndex: {
    path: `${QUOTES_ROUTE_PREFIX}/*`,
    getUrl: () => QUOTES_ROUTE_PREFIX,
    relativePath: `${QUOTES_RELATIVE_INDEX_PATH}/*`
  },
  quoteDetailsPage: {
    path: `${QUOTES_ROUTE_PREFIX}/details/:id/*`,
    relativePath: `${QUOTES_RELATIVE_INDEX_PATH}/details/:id/*`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${QUOTES_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  quoteIndexOrderDetails: {
    getUrl: (orderId: string, tabName?: string) => {
      const url: string = `${QUOTES_ROUTE_PREFIX}/${orderId}`;

      return tabName ? `${url}/${tabName}` : url;
    },
    path: `${QUOTES_ROUTE_PREFIX}/:id`,
    relativePath: ':id/*'
  }
};

export default quotesRoutes;
