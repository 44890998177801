import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { CounterType } from './types';

interface Props {
  className?: string;
  counterClassNames?: string;
  prefix?: string;
  showCounterLimit?: boolean;
  size?: 'small' | 'normal';
  type?: CounterType;
  value: number | string;
}

const getCounterValueWithLimit = (counterNumber: number): string | number => {
  return counterNumber < 100 ? counterNumber : '99+';
};

const Counter: FC<Props> = (props) => {
  const { className, counterClassNames, prefix = '', showCounterLimit = true, type = 'default', value, size = 'normal' } = props;

  const defaultClasses: Record<CounterType, string> = {
    default: 'bg-azure-lightest text-grey-dark font-bold',
    selected: 'bg-azure-base text-white',
    deselected: 'bg-grey-lighter text-grey-dark',
    active: 'bg-azure-base text-white font-bold',
    warning: 'bg-orange-base text-white font-bold',
    problem: 'bg-red-base text-white font-bold',
    success: 'bg-green-base text-white font-bold'
  };

  const wrapperClassNames = cx('inline-block', className);

  const classNames = cx(
    {
      'text-sm': size === 'normal',
      'text-xs': size === 'small'
    },
    defaultClasses[type],
    'flex items-center justify-center min-w-5 h-5 px-1 rounded-full',
    counterClassNames
  );

  const counterNumber: number = Number(value);

  const counterValue: ReactNode = showCounterLimit ? getCounterValueWithLimit(counterNumber) : value;

  if (showCounterLimit && counterNumber <= 0) {
    return null;
  }

  return (
    <span className={wrapperClassNames}>
      <span className={classNames} data-component="counter" data-testid={`counter-${type}`}>
        {prefix && prefix}
        {counterValue}
      </span>
    </span>
  );
};

export type { Props as CounterProps };
export default Counter;
