import type { FC, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import type { LocationPermissions } from '@zen/Accounts/types';
import { checkPermission } from '@zen/Auth/authHelper';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import type { Nullable } from '@zen/utils/typescript';

import { useGetAccountLocationQuery } from '../graphql';
import type { AccountLocationDetails } from '../types';
import AddressDetails from './AddressDetails';
import BusinessHoursDetails from './BusinessHoursDetails';
import { formatBusinessHours } from './helpers';
import LocationDetails from './LocationDetails';
import LocationName from './LocationName';

type RouteParams = {
  locationId: string;
};

interface Props {
  accountId?: Nullable<string>;
  accountName?: string;
  backUrl: string;
}

const AccountLocation: FC<Props> = ({ accountId, accountName, backUrl }) => {
  const { locationId } = useParams<RouteParams>() as RouteParams;

  const {
    data: locationData,
    loading,
    error
  } = useGetAccountLocationQuery({
    variables: { accountId, locationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only'
  });

  return (
    <QueryHandler data={locationData?.accountLocation} error={!!error} isLoading={loading}>
      {(location: AccountLocationDetails) => {
        const { addressLabel, companyName, name, address, businessHours, geolocation, permissions, usageContext } = location;

        const canEdit: boolean = checkPermission<LocationPermissions>(permissions, 'canEdit');
        const canEditAddress: boolean = checkPermission<LocationPermissions>(permissions, 'canEditAddress');
        const canEditBusinessHours: boolean = checkPermission<LocationPermissions>(permissions, 'canEditBusinessHours');

        const renderLocationDetails = (): ReactNode => {
          if (!accountId) {
            return <LocationName canEdit={canEdit} locationId={locationId} name={name} />;
          }

          return (
            <LocationDetails
              accountId={accountId}
              accountName={accountName || ''}
              canEdit={canEdit}
              companyName={companyName}
              locationId={locationId}
              name={name}
              usage={usageContext}
            />
          );
        };

        return (
          <Page defaultBackUrl={backUrl} title={name || addressLabel} width="wide">
            <div className="grid grid-cols-8 gap-8 xl:gap-6 xl:grid-cols-12">
              {renderLocationDetails()}
              <AddressDetails address={address} canEdit={canEditAddress} geolocation={geolocation} locationId={locationId} />
              <BusinessHoursDetails
                businessHours={formatBusinessHours(businessHours)}
                canEdit={canEditBusinessHours}
                locationId={locationId}
              />
            </div>
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default AccountLocation;
