import { type FC, useRef } from 'react';

import { Form, FormButtons, FormInput } from '@zen/Components';
import { Banner } from '@zen/DesignSystem';
import { generateRandomString } from '@zen/utils';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useCreateScimApiKeyMutation } from '../../graphql';
import type { PasswordCollectionProps } from '../PasswordCollectionPanel';
import { validationSchema } from './generateKeyForm.validation';

interface Props {
  accountId: string;
  onKeyCreated: (passwordCollectionDetails: PasswordCollectionProps) => void;
}

interface EmailField {
  recipientEmail: string;
}

const GenerateKeyForm: FC<Props> = ({ onKeyCreated, accountId }) => {
  const [createApiKey, { loading }] = useCreateScimApiKeyMutation({ refetchQueries: ['getAccountDetails'] });
  const { addError } = useNotification();
  const documentPassword = useRef<string>('');
  const initialValues: EmailField = {
    recipientEmail: ''
  };

  const handleSendEmail = async (values: EmailField): Promise<IOkOrErrorResult> => {
    const { recipientEmail } = values;

    documentPassword.current = generateRandomString();

    return performMutation({
      mutationFn: () =>
        createApiKey({
          variables: { input: { accountId, recipientEmail, documentPassword: documentPassword.current } }
        }),
      onError: () => addError('There was a problem creating the key.')
    });
  };

  const handleSuccess = (_: IOkOrErrorResult, values: EmailField): void => {
    const passwordDetails: PasswordCollectionProps = {
      password: documentPassword.current,
      recipientEmail: values.recipientEmail
    };

    onKeyCreated(passwordDetails);
  };

  return (
    <Form
      buttonText="Enable SSO"
      formButtons={() => null}
      initialValues={initialValues}
      onSubmit={handleSendEmail}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {() => {
        return (
          <>
            <div className="flex gap-2">
              <div className="flex-1">
                <FormInput name="recipientEmail" />
              </div>
              <FormButtons isSubmitting={loading} text="Send email" />
            </div>
            <div className="mb-4">
              <Banner message="We will send an email containing an encrypted file with the API key. To ensure the secure sharing of this token, please communicate the associated password via text message exclusively." />
            </div>
          </>
        );
      }}
    </Form>
  );
};

export default GenerateKeyForm;
