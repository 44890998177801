import { createListRateCard } from '@zen/RateCards/factories/listRateCard';
import type { GraphqlMockedResponse } from '@zen/utils/jest/TestGraphqlProvider';

import type { RateCardSummary } from '../../types';
import { GetRateCardsDocument } from '..';

const rateCards: RateCardSummary[] = [
  createListRateCard(),
  createListRateCard({ rateCardId: '2' }),
  createListRateCard({ rateCardId: '3' })
];

export const mockRateCardsQuery = (): GraphqlMockedResponse => ({
  request: {
    query: GetRateCardsDocument,
    variables: {
      customerId: '12345678',
      first: 20
    }
  },
  result: {
    data: {
      getRateCards: {
        nodes: rateCards,
        totalCount: rateCards.length,
        pageInfo: {
          __typename: 'PageInfo',
          hasNextPage: false,
          hasPreviousPage: false,
          startCursor: 'MQ',
          endCursor: 'NA'
        }
      }
    }
  }
});
