import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderListQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  availableForBooking?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  availableForCargo?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  bookingState?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.PurchaseOrderBookingStateEnum> | GraphQLTypes.PurchaseOrderBookingStateEnum
  >;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  manufacturerIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.FilterOptionsEnum>;
  cargoReadyDateBetween?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  lastUpdatedBetween?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  portOfLoadUnlocode?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  incoterms?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  issues?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrdersIssuesFilterInput>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  portOfDestinationUnlocode?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  status?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrderStatusEnum>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  withLotCountsForCargo?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type OrderListQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & GraphQLTypes.MakeOptional<
            Pick<
              GraphQLTypes.PurchaseOrder,
              | 'id'
              | 'fulfilledCbm'
              | 'lotsBookedCount'
              | 'lotsTotalCount'
              | 'lotsAvailableForCargoCount'
              | 'lotsBookedForCargoCount'
              | 'orderReferenceNumber'
            >,
            'lotsAvailableForCargoCount' | 'lotsBookedForCargoCount'
          > & {
              account?: GraphQLTypes.Maybe<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
              bookings?: GraphQLTypes.Maybe<Array<{ __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'>>>;
              canClose: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canRemove: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewCustomer: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              customer?: GraphQLTypes.Maybe<{ __typename?: 'Customer' } & Pick<GraphQLTypes.Customer, 'name' | 'uuid'>>;
              manufacturer?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                      >;
                    })
              >;
            }
        >
      >
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      GraphQLTypes.PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
  };
};

export const OrderListQueryDocument = /* #__PURE__ */ gql`
  query orderListQuery(
    $accountIds: [String!]
    $after: String
    $availableForBooking: String
    $availableForCargo: String
    $before: String
    $bookingState: [PurchaseOrderBookingStateEnum!]
    $customerUuid: String
    $manufacturerIds: [String]
    $filterBy: FilterOptionsEnum
    $cargoReadyDateBetween: DateRangeInput
    $lastUpdatedBetween: DateRangeInput
    $portOfLoadUnlocode: String
    $incoterms: String
    $issues: PurchaseOrdersIssuesFilterInput
    $order: SortInput
    $portOfDestinationUnlocode: String
    $status: PurchaseOrderStatusEnum
    $textContains: String
    $withLotCountsForCargo: Boolean = false
  ) {
    purchaseOrders(
      accountIds: $accountIds
      after: $after
      availableForBooking: $availableForBooking
      availableForCargo: $availableForCargo
      before: $before
      bookingState: $bookingState
      cargoReadyDateBetween: $cargoReadyDateBetween
      lastUpdatedBetween: $lastUpdatedBetween
      customerUuid: $customerUuid
      filterBy: $filterBy
      incoterms: $incoterms
      issues: $issues
      manufacturerIds: $manufacturerIds
      order: $order
      portOfLoadUnlocode: $portOfLoadUnlocode
      portOfDestinationUnlocode: $portOfDestinationUnlocode
      status: $status
      textContains: $textContains
    ) {
      nodes {
        account {
          id
          tradingName
        }
        bookings {
          zencargoReference
        }
        canClose {
          value
        }
        canRemove {
          value
        }
        canUpdate {
          value
        }
        canViewCustomer {
          value
        }
        customer {
          name
          uuid
        }
        id
        fulfilledCbm
        lotsBookedCount
        lotsTotalCount
        lotsAvailableForCargoCount(cargoId: $availableForCargo) @include(if: $withLotCountsForCargo)
        lotsBookedForCargoCount(cargoId: $availableForCargo) @include(if: $withLotCountsForCargo)
        manufacturer {
          id
          label {
            short
          }
        }
        orderReferenceNumber
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOrderListQuery__
 *
 * To run a query within a React component, call `useOrderListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderListQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      after: // value for 'after'
 *      availableForBooking: // value for 'availableForBooking'
 *      availableForCargo: // value for 'availableForCargo'
 *      before: // value for 'before'
 *      bookingState: // value for 'bookingState'
 *      customerUuid: // value for 'customerUuid'
 *      manufacturerIds: // value for 'manufacturerIds'
 *      filterBy: // value for 'filterBy'
 *      cargoReadyDateBetween: // value for 'cargoReadyDateBetween'
 *      lastUpdatedBetween: // value for 'lastUpdatedBetween'
 *      portOfLoadUnlocode: // value for 'portOfLoadUnlocode'
 *      incoterms: // value for 'incoterms'
 *      issues: // value for 'issues'
 *      order: // value for 'order'
 *      portOfDestinationUnlocode: // value for 'portOfDestinationUnlocode'
 *      status: // value for 'status'
 *      textContains: // value for 'textContains'
 *      withLotCountsForCargo: // value for 'withLotCountsForCargo'
 *   },
 * });
 */
export function useOrderListQuery(baseOptions?: Apollo.QueryHookOptions<OrderListQueryResult, OrderListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderListQueryResult, OrderListQueryVariables>(OrderListQueryDocument, options);
}
export function useOrderListQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderListQueryResult, OrderListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderListQueryResult, OrderListQueryVariables>(OrderListQueryDocument, options);
}
export type OrderListQueryHookResult = ReturnType<typeof useOrderListQuery>;
export type OrderListQueryLazyQueryHookResult = ReturnType<typeof useOrderListQueryLazyQuery>;
