import type { FC } from 'react';

import type { RejectQuoteOptionReasonMutationVariables } from '@zen/Quotes/graphql';
import { useRejectQuoteOptionReasonMutation } from '@zen/Quotes/graphql';
import type { QuoteOption, QuoteOptionRejectionReason } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';

import RejectReasonChoice from '../components/RejectReasonChoice';

interface Props {
  onReject?: () => void;
  quoteOption: Pick<QuoteOption, 'id' | 'rejectionReason' | 'reasonDescription'>;
}

const RejectReasonContainer: FC<Props> = (props) => {
  const { quoteOption, onReject } = props;

  const { addSuccess, addError } = useNotification();
  const [rejectQuoteOptionReason] = useRejectQuoteOptionReasonMutation();

  const handleReject = async (rejectionReason: QuoteOptionRejectionReason, text?: string): Promise<void> => {
    try {
      const variables: RejectQuoteOptionReasonMutationVariables = {
        input: {
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<string> | undefined' is not assignable... Remove this comment to see the full error message
          quoteOptionId: quoteOption.id,
          rejectionReason,
          ...(text && { reasonDescription: text })
        }
      };

      const { errors } = await rejectQuoteOptionReason({ variables });

      if (errors) {
        throw new Error(errors.toString());
      }

      addSuccess('Thank you for your feedback.');
      if (onReject) {
        onReject();
      }
    } catch (error) {
      addError();
    }
  };

  return <RejectReasonChoice handleReject={handleReject} quoteOption={quoteOption} />;
};

export default RejectReasonContainer;
