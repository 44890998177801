import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderDetailsLineItemsQueryVariables = GraphQLTypes.Exact<{
  purchaseOrderId: GraphQLTypes.Scalars['String'];
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type OrderDetailsLineItemsQueryResult = { __typename?: 'Query' } & {
  purchaseOrderOrderedLineItems: { __typename?: 'OrderedLineItemConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'OrderedLineItem' } & Pick<GraphQLTypes.OrderedLineItem, 'cbm' | 'id' | 'quantityOrdered'> & {
              canUpdateDeliveryDate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              initialCargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
              lots: Array<
                { __typename?: 'Lot' } & Pick<
                  GraphQLTypes.Lot,
                  'atRisk' | 'cbm' | 'estimatedDeliveryDate' | 'fulfilled' | 'id' | 'quantityFulfilled'
                > & {
                    booking?: GraphQLTypes.Maybe<
                      { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
                          stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
                        }
                    >;
                    cargoReadyDate?: GraphQLTypes.Maybe<
                      { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                          history?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                                  lastUpdated?: GraphQLTypes.Maybe<
                                    { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                        updatedBy?: GraphQLTypes.Maybe<
                                          { __typename?: 'UpdatedBy' } & Pick<
                                            GraphQLTypes.UpdatedBy,
                                            'username' | 'zencargoEmployee'
                                          >
                                        >;
                                      }
                                  >;
                                  reasonForChange?: GraphQLTypes.Maybe<
                                    { __typename?: 'ReasonForChange' } & Pick<
                                      GraphQLTypes.ReasonForChange,
                                      'category' | 'description'
                                    >
                                  >;
                                }
                            >
                          >;
                          lastUpdated?: GraphQLTypes.Maybe<
                            { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                updatedBy?: GraphQLTypes.Maybe<
                                  { __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username' | 'zencargoEmployee'>
                                >;
                              }
                          >;
                          reasonForChange?: GraphQLTypes.Maybe<
                            { __typename?: 'ReasonForChange' } & Pick<GraphQLTypes.ReasonForChange, 'category' | 'description'>
                          >;
                        }
                    >;
                    currentStage?: GraphQLTypes.Maybe<
                      { __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'name'>
                    >;
                    purchaseOrder?: GraphQLTypes.Maybe<{ __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>>;
                    stages?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'ProgressStatus' } & Pick<
                          GraphQLTypes.ProgressStatus,
                          'currentStage' | 'label' | 'name' | 'statusType'
                        > & {
                            date?: GraphQLTypes.Maybe<
                              { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'> & {
                                  history?: GraphQLTypes.Maybe<
                                    Array<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'>>
                                  >;
                                }
                            >;
                          }
                      >
                    >;
                  }
              >;
              product?: GraphQLTypes.Maybe<
                { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode'> & {
                    costPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
                  }
              >;
              requiredDeliveryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
              totalCost?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
            }
        >
      >
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      GraphQLTypes.PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
  };
};

export const OrderDetailsLineItemsDocument = /* #__PURE__ */ gql`
  query orderDetailsLineItems($purchaseOrderId: String!, $after: String, $before: String, $first: Int, $last: Int) {
    purchaseOrderOrderedLineItems(purchaseOrderId: $purchaseOrderId, after: $after, before: $before, first: $first, last: $last) {
      nodes {
        canUpdateDeliveryDate {
          value
        }
        cbm
        id
        initialCargoReadyDate {
          date
        }
        lots {
          atRisk
          booking {
            stage {
              value
            }
            zencargoReference
          }
          cargoReadyDate {
            date
            history {
              date
              lastUpdated {
                updatedAt
                updatedBy {
                  username
                  zencargoEmployee
                }
              }
              reasonForChange {
                category
                description
              }
            }
            lastUpdated {
              updatedAt
              updatedBy {
                username
                zencargoEmployee
              }
            }
            reasonForChange {
              category
              description
            }
          }
          cbm
          currentStage {
            name
          }
          estimatedDeliveryDate
          fulfilled
          id
          purchaseOrder {
            id
          }
          quantityFulfilled
          stages {
            currentStage
            date {
              confidenceLevel
              date
              history {
                confidenceLevel
                date
              }
            }
            label
            name
            statusType
          }
        }
        product {
          id
          costPrice {
            value
            currency
          }
          name
          skuCode
        }
        quantityOrdered
        requiredDeliveryDate {
          date
        }
        totalCost {
          value
          currency
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOrderDetailsLineItemsQuery__
 *
 * To run a query within a React component, call `useOrderDetailsLineItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderDetailsLineItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderDetailsLineItemsQuery({
 *   variables: {
 *      purchaseOrderId: // value for 'purchaseOrderId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useOrderDetailsLineItemsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables>(
    OrderDetailsLineItemsDocument,
    options
  );
}
export function useOrderDetailsLineItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderDetailsLineItemsQueryResult, OrderDetailsLineItemsQueryVariables>(
    OrderDetailsLineItemsDocument,
    options
  );
}
export type OrderDetailsLineItemsQueryHookResult = ReturnType<typeof useOrderDetailsLineItemsQuery>;
export type OrderDetailsLineItemsLazyQueryHookResult = ReturnType<typeof useOrderDetailsLineItemsLazyQuery>;
