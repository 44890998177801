import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useCreateShipmentActionTypeMutation } from '../graphql';
import ShipmentActionTypeForm from '../ShipmentActionTypeForm';
import type { ShipmentActionTypeFormValues } from '../ShipmentActionTypeForm/types';

interface Props {
  onClose: () => void;
}

const NewShipmentActionType: FC<Props> = ({ onClose }) => {
  const [createShipmentActionType] = useCreateShipmentActionTypeMutation();

  const { addError, addSuccess } = useNotification();

  const handleSuccess = (): void => {
    addSuccess('New shipment action type created.');
    onClose();
  };

  const handleCreateShipmentActionType = (values: ShipmentActionTypeFormValues): Promise<IOkOrErrorResult> =>
    performFormMutation({
      mutationFn: () =>
        createShipmentActionType({
          variables: {
            input: values
          },
          refetchQueries: ['getShipmentActionTypes']
        }),
      onError: () => addError()
    });

  return (
    <ShipmentActionTypeForm
      buttonLabel="Create"
      onCancel={onClose}
      onSubmit={handleCreateShipmentActionType}
      onSuccess={handleSuccess}
    />
  );
};

export default NewShipmentActionType;
