import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountConnectionsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  accountConnectionsFiltersInput: GraphQLTypes.AccountConnectionsFiltersInput;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetAccountConnectionsQueryResult = { __typename?: 'Query' } & {
  accountConnections?: GraphQLTypes.Maybe<
    { __typename?: 'AccountConnectionTypeConnection' } & Pick<GraphQLTypes.AccountConnectionTypeConnection, 'totalCount'> & {
        nodes: Array<
          GraphQLTypes.Maybe<
            { __typename?: 'AccountConnectionType' } & Pick<GraphQLTypes.AccountConnectionType, 'connectionType'> & {
                account: { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'status' | 'tradingName'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  };
              }
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetAccountConnectionsDocument = /* #__PURE__ */ gql`
  query getAccountConnections(
    $accountId: String!
    $accountConnectionsFiltersInput: AccountConnectionsFiltersInput!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    accountConnections(
      accountId: $accountId
      accountConnectionsFiltersInput: $accountConnectionsFiltersInput
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        account {
          id
          status
          tradingName
          label {
            long
          }
        }
        connectionType
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetAccountConnectionsQuery__
 *
 * To run a query within a React component, call `useGetAccountConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountConnectionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountConnectionsFiltersInput: // value for 'accountConnectionsFiltersInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAccountConnectionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables>(
    GetAccountConnectionsDocument,
    options
  );
}
export function useGetAccountConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountConnectionsQueryResult, GetAccountConnectionsQueryVariables>(
    GetAccountConnectionsDocument,
    options
  );
}
export type GetAccountConnectionsQueryHookResult = ReturnType<typeof useGetAccountConnectionsQuery>;
export type GetAccountConnectionsLazyQueryHookResult = ReturnType<typeof useGetAccountConnectionsLazyQuery>;
