import { ContactStatus, NetworkContact } from '../../types';
import { GetContactDocument } from '..';

export const mockGetContactQuery = (contactId: string) => ({
  request: {
    query: GetContactDocument,
    variables: {
      id: contactId
    }
  },
  result: {
    data: {
      contact: {
        ...contactData,
        __typename: 'NetworkContact'
      }
    }
  }
});

export const contactData: NetworkContact = {
  id: 'test-contact-id-1',
  name: 'Happy Hippos',
  bankAccountDetails: 'account123',
  bipCode: '',
  carrier: false,
  contactPeople: [
    {
      id: 'test-contact-person-1',
      email: 'johnnybravo@email.com',
      firstName: 'Johnny',
      lastName: 'Bravo',
      skypeUserName: 'johny_rocks',
      phoneNumber: '123456',
      mainContact: true
    }
  ],
  customer: true,
  eoriNumber: '',
  scacCode: '',
  status: ContactStatus.ACTIVE,
  supplier: false,
  taxNumber: '123',
  warehouse: false,
  haulier: false
};

export const newContactData: NetworkContact = {
  ...contactData,
  id: '',
  contactPeople: []
};
