import type { FC } from 'react';

import { Option, Select, SelectProps } from '@zen/DesignSystem';
import { Currency } from '@zen/types';

interface Props extends Omit<SelectProps<Currency>, 'options'> {
  availableCurrencies?: Currency[];
}

const CurrencySelect: FC<Props> = ({ availableCurrencies, ...rest }) => {
  const currencies: Currency[] = availableCurrencies || (Object.keys(Currency) as Currency[]);
  const currencyOptions: Option<Currency>[] = currencies.map((currency) => ({ value: currency, label: currency }));

  return <Select {...rest} options={currencyOptions} />;
};

export default CurrencySelect;
