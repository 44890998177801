import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderActivityFeedItemsQueryVariables = GraphQLTypes.Exact<{
  textMessagesIdsByTarget: Array<GraphQLTypes.TextMessagesInput> | GraphQLTypes.TextMessagesInput;
  orderId: GraphQLTypes.Scalars['String'];
}>;

export type OrderActivityFeedItemsQueryResult = { __typename?: 'Query' } & {
  activityFeed: { __typename?: 'ActivityFeed' } & {
    textMessages?: GraphQLTypes.Maybe<
      Array<
        { __typename?: 'TextMessage' } & Pick<GraphQLTypes.TextMessage, 'id' | 'content' | 'archivedAt'> & {
            canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
          }
      >
    >;
  };
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'> & {
              lotsRequiredDeliveryDate?: GraphQLTypes.Maybe<
                { __typename?: 'PurchaseOrderLotsDateType' } & Pick<
                  GraphQLTypes.PurchaseOrderLotsDateType,
                  'date' | 'sameForAllLots'
                >
              >;
              lotsInitialCargoReadyDate?: GraphQLTypes.Maybe<
                { __typename?: 'PurchaseOrderLotsDateType' } & Pick<
                  GraphQLTypes.PurchaseOrderLotsDateType,
                  'date' | 'sameForAllLots'
                >
              >;
            }
        >
      >
    >;
  };
};

export const OrderActivityFeedItemsDocument = /* #__PURE__ */ gql`
  query orderActivityFeedItems($textMessagesIdsByTarget: [TextMessagesInput!]!, $orderId: String!) {
    activityFeed {
      textMessages(idsByTarget: $textMessagesIdsByTarget) {
        id
        content
        archivedAt
        canArchive {
          value
        }
      }
    }
    purchaseOrders(id: $orderId) {
      nodes {
        id
        lotsRequiredDeliveryDate {
          date
          sameForAllLots
        }
        lotsInitialCargoReadyDate {
          date
          sameForAllLots
        }
      }
    }
  }
`;

/**
 * __useOrderActivityFeedItemsQuery__
 *
 * To run a query within a React component, call `useOrderActivityFeedItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderActivityFeedItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderActivityFeedItemsQuery({
 *   variables: {
 *      textMessagesIdsByTarget: // value for 'textMessagesIdsByTarget'
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useOrderActivityFeedItemsQuery(
  baseOptions: Apollo.QueryHookOptions<OrderActivityFeedItemsQueryResult, OrderActivityFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderActivityFeedItemsQueryResult, OrderActivityFeedItemsQueryVariables>(
    OrderActivityFeedItemsDocument,
    options
  );
}
export function useOrderActivityFeedItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderActivityFeedItemsQueryResult, OrderActivityFeedItemsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderActivityFeedItemsQueryResult, OrderActivityFeedItemsQueryVariables>(
    OrderActivityFeedItemsDocument,
    options
  );
}
export type OrderActivityFeedItemsQueryHookResult = ReturnType<typeof useOrderActivityFeedItemsQuery>;
export type OrderActivityFeedItemsLazyQueryHookResult = ReturnType<typeof useOrderActivityFeedItemsLazyQuery>;
