import type { FC, ReactNode } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Role, roleLabels } from '@zen/Auth/role';
import FormCheckbox from '@zen/Components/Form/FormCheckbox';
import FormLabel from '@zen/Components/Form/FormLabel';
import { getVisibleRoles, toPermissionKey } from '@zen/Components/RolePermissions/helpers';
import { Checkbox } from '@zen/DesignSystem';

interface RolePermissionToggleItem {
  label: string;
  permissionKey?: string;
  static?: boolean;
}

interface Props {
  isRequired?: boolean;
  label: ReactNode;
  name: string;
}

const prepareToggleItems = (roles: Role[]): RolePermissionToggleItem[] => {
  return roles.map((role: Role) => {
    if (role === Role.ADMIN) {
      // we do not allow editing admin, we just display it as a static disabled control
      return {
        label: roleLabels[role],
        static: true
      };
    }

    return {
      label: roleLabels[role],
      permissionKey: toPermissionKey(role)
    };
  });
};

const FormRolePermissionToggles: FC<Props> = ({ name, label, isRequired }) => {
  const currentRole: Role = useRole();
  const roles: Role[] = getVisibleRoles(currentRole);
  const items = prepareToggleItems(roles);
  const itemClassNames = 'mr-8 inline-block';

  const renderFormToggle = (item: RolePermissionToggleItem, index: number): ReactNode => {
    const fieldName = `${name}.${item.permissionKey}`;

    return <FormCheckbox key={index} className={itemClassNames} label={item.label} name={fieldName} />;
  };

  const renderStaticToggle = (item: RolePermissionToggleItem, index: number): ReactNode => (
    <div key={index} className={itemClassNames}>
      <Checkbox checked={true} disabled={true} label={item.label} />
    </div>
  );

  const renderToggleItem = (item: RolePermissionToggleItem, index: number): ReactNode => {
    const renderFn = item.static ? renderStaticToggle : renderFormToggle;

    return renderFn(item, index);
  };

  return (
    <div className="mb-8" data-testid="role-permission-toggles">
      <FormLabel className="mb-6" isRequired={isRequired} label={label} />
      <div className="flex">{items.map(renderToggleItem)}</div>
    </div>
  );
};

export default FormRolePermissionToggles;
