import type { Option } from '@zen/DesignSystem';

import { MultiStopEnum } from './types';

export const multiStopLabelMapping: Record<MultiStopEnum, string> = {
  [MultiStopEnum.MORE_THAN_TWO_STOPS]: 'Multi-stop',
  [MultiStopEnum.TWO_STOPS]: 'Single stop',
  [MultiStopEnum.UNKNOWN]: 'Skip for now'
};

export const multiStopOptions: Option<MultiStopEnum>[] = [
  { value: MultiStopEnum.MORE_THAN_TWO_STOPS, label: multiStopLabelMapping[MultiStopEnum.MORE_THAN_TWO_STOPS] },
  { value: MultiStopEnum.TWO_STOPS, label: multiStopLabelMapping[MultiStopEnum.TWO_STOPS] },
  { value: MultiStopEnum.UNKNOWN, label: multiStopLabelMapping[MultiStopEnum.UNKNOWN] }
];
