import type { ApolloQueryResult, DocumentNode } from '@apollo/client';

import type { KeyValuePair, TaskDetailsFiltersInput } from '@zen/graphql/types.generated';
import type { DeepNonNullable, Optional, Undefinable } from '@zen/utils/typescript';

import type {
  GetActiveTaskListQueryResult,
  GetCompletedTaskListQueryResult,
  GetTaskByIdQueryResult,
  GetTaskListCountQueryResult
} from './graphql';
import type { TaskUrgency } from './TaskLists/types';

export type { KeyValuePair, KeyValuePairInput, WorkflowTaskType } from '@zen/graphql/types.generated';

export type TaskListCountData = DeepNonNullable<GetTaskListCountQueryResult, 'taskDetails'>['taskDetails'];

export type ActiveTaskItem = DeepNonNullable<GetActiveTaskListQueryResult, 'nodes'>['taskDetails']['tasks']['nodes'];
export type ActiveTaskTableItem = ActiveTaskItem & { id: string };
export type CompletedTaskItem = DeepNonNullable<GetCompletedTaskListQueryResult, 'nodes'>['taskDetails']['tasks']['nodes'];
export type TaskListItem = ActiveTaskItem | CompletedTaskItem;

export type TaskItem = DeepNonNullable<GetTaskByIdQueryResult, 'taskById'>['taskById'];

export type TaskListQueryResult = Undefinable<GetCompletedTaskListQueryResult | GetActiveTaskListQueryResult>;

export type WorkflowTaskVariablesArray = KeyValuePair[];

export type { BookingManager } from '@zen/graphql/types.generated';
export type { NetworksOrgLocOption } from '@zen/Networks';

export type RefreshActiveTaskList = Promise<ApolloQueryResult<GetActiveTaskListQueryResult>>;

export type TaskCompleteArgs = {
  taskId: string;
  taskName: string;
  zencargoReference: string;
};

export enum TaskPriority {
  'minor' = 1,
  'low' = 2,
  'medium' = 3,
  'high' = 4,
  'critical' = 5
}

export const TaskListTrackingCategory = 'TaskList';

export enum TaskListTrackingAction {
  TASK_DELETED = 'TaskDeleted',
  TASK_MARKED_AS_COMPLETED = 'TaskMarkedAsCompleted'
}

export type RefetchQuery = { query: DocumentNode };

type TrackingProperties = {
  taskDescription: string;
  taskUrgency?: TaskUrgency;
  zencargoReference: Optional<string>;
};

export type TaskListAction = {
  isDisabled: boolean;
  onActionEnd: () => void;
  onActionStart: () => void;
  onActionSuccess?: () => void;
  taskId: string;
  trackingProps: TrackingProperties;
};

export type TaskStatus = 'completed' | 'active';
export type MiniTaskListFilter = TaskDetailsFiltersInput & { activeStatus?: TaskStatus };

export type FixedFilterOptions = 'ALL' | 'UNASSIGNED';
export type TaskActionPermissions = {
  canCompleteTask?: boolean;
  canDeleteTask?: boolean;
};
