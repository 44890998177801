import type { FC } from 'react';
import { useState } from 'react';

import FiltersButton from '@zen/Components/FiltersButton';
import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { getNetworksFieldValueWithName } from '@zen/Networks';
import { countFilters } from '@zen/utils/Filtering/helpers';
import usePersistedFilters from '@zen/utils/Filtering/hooks/usePersistedFilters';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { AgentAssignmentRulesFiltersType } from '../../types';
import { emptyFilters, removeEmptyFilters } from './agentAssignmentRulesFilters.helper';
import AgentAssignmentRulesFiltersForm from './AgentAssignmentRulesFiltersForm';

const AgentAssignmentRulesFilters: FC = () => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const { filters, setFilters } = usePersistedFilters<AgentAssignmentRulesFiltersType>('agentAssignmentRulesFilters');

  const filterValues = {
    ...filters,
    origin: getNetworksFieldValueWithName(filters.origin),
    destination: getNetworksFieldValueWithName(filters.destination)
  };

  const filtersCount: number = countFilters<AgentAssignmentRulesFiltersType>(filterValues);

  const openFilters = (): void => setShowFilters(true);
  const closeFilters = (): void => setShowFilters(false);

  const handleFiltersSubmit = (values: AgentAssignmentRulesFiltersType): Promise<IOkOrErrorResult> => {
    setFilters(removeEmptyFilters(values));

    return Promise.resolve({ ok: { values }, error: null });
  };
  const handleFiltersSuccess = (): void => closeFilters();

  const clearFilters = (): void => {
    closeFilters();
    setFilters({});
  };

  const initialValues: AgentAssignmentRulesFiltersType = {
    ...emptyFilters,
    ...filterValues
  };

  return (
    <div data-testid="agent-assignment-rules-filters">
      <div className="flex justify-end">
        <FiltersButton filterCount={filtersCount} onClick={openFilters} />
      </div>
      <Slideout onOutsideClick={closeFilters} overlay={true} show={showFilters}>
        <SlideoutHeader onClose={closeFilters} title="Filter my agent assignment rules" />
        <div className="h-full p-6 overflow-y-auto w-160">
          <AgentAssignmentRulesFiltersForm
            initialValues={initialValues}
            onClearFilters={clearFilters}
            onSubmit={handleFiltersSubmit}
            onSuccess={handleFiltersSuccess}
          />
        </div>
      </Slideout>
    </div>
  );
};

export default AgentAssignmentRulesFilters;
