import { type ChargeItemInitialValues, ChargeItemType, Currency } from '@zen/CostTracking/types';
import type { Nullable } from '@zen/utils/typescript';

import { CostTrackingParty } from '../CostTrackingParties';

export interface ChargeItemConfigItem {
  paidFrom: CostTrackingParty;
  paidTo: CostTrackingParty;
  valuesKey: 'costItems' | 'revenueItems';
}

export const chargeItemTypeConfig: Record<ChargeItemType, ChargeItemConfigItem> = {
  [ChargeItemType.COST]: {
    paidFrom: CostTrackingParty.FORWARDER,
    paidTo: CostTrackingParty.SUPPLIER,
    valuesKey: 'costItems'
  },
  [ChargeItemType.REVENUE]: {
    paidFrom: CostTrackingParty.CUSTOMER,
    paidTo: CostTrackingParty.FORWARDER,
    valuesKey: 'revenueItems'
  }
};

export const getEmptyChargeItem = (chargeTypeName: Nullable<string>): ChargeItemInitialValues => ({
  name: chargeTypeName,
  paidFrom: null,
  paidTo: null,
  quantity: null,
  total: null,
  unitPrice: {
    currency: Currency.GBP,
    value: 1
  }
});

export const getTotalAmount = (priceValue: Nullable<number>, quantity: Nullable<number>): number =>
  priceValue && quantity ? priceValue * quantity : 0;
