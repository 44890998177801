import { type FC, type ReactNode, useState } from 'react';

import { Button, Modal } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import GenerateKeyForm from './GenerateKeyForm';
import PasswordCollectionPanel, { PasswordCollectionProps } from './PasswordCollectionPanel';

interface GenerateKeyProps {
  accountId: string;
  isOpen: boolean;
  onClose: () => void;
}

const GenerateKeyModal: FC<GenerateKeyProps> = ({ isOpen, onClose, accountId }) => {
  const [passwordCollectionInfo, setPasswordCollectionInfo] = useState<Nullable<PasswordCollectionProps>>(null);

  const handlePasswordDetails = (passwordCollectionDetails: PasswordCollectionProps): void => {
    setPasswordCollectionInfo(passwordCollectionDetails);
  };

  const handleClose = (): void => {
    setPasswordCollectionInfo(null);
    onClose();
  };

  const closeModalButton: ReactNode = (
    <Button className="ml-auto" disabled={!passwordCollectionInfo} onClick={handleClose} variant="primary">
      I have stored the password
    </Button>
  );

  const renderModalContent = (): ReactNode => {
    if (passwordCollectionInfo) {
      return <PasswordCollectionPanel {...passwordCollectionInfo} />;
    }

    return <GenerateKeyForm accountId={accountId} onKeyCreated={handlePasswordDetails} />;
  };

  return (
    <Modal footer={closeModalButton} isOpen={isOpen} modalOverflowY="visible" onClose={handleClose} title="Share API key">
      {renderModalContent()}
    </Modal>
  );
};

export default GenerateKeyModal;
