import type { FC } from 'react';

import BookingCoordinatorSelect from '@zen/Components/BookingCoordinatorSelect';
import { ManagerTeam, TaskDetailsFiltersInput } from '@zen/graphql/types.generated';
import TaskStatusSelect from '@zen/Tasks/components/TaskStatusSelect';
import { fixedTaskFilterOptions } from '@zen/Tasks/helpers';
import type { TaskStatus } from '@zen/Tasks/types';
import type { Nullable } from '@zen/utils/typescript';

export type MiniTaskListFilter = TaskDetailsFiltersInput & { activeStatus?: TaskStatus };
interface Props {
  currentFilters: MiniTaskListFilter;
  onFilterChange: (input: MiniTaskListFilter) => void;
}

const MiniTaskListFilters: FC<Props> = ({ onFilterChange, currentFilters }) => {
  const [currentCoordinator] = currentFilters?.assignee || [];
  const taskStatus: TaskStatus = currentFilters?.activeStatus || 'completed';
  const handleAssigneeUpdate = (assignee: string): void => {
    onFilterChange({ assignee: [assignee] });
  };

  return (
    <div className="flex gap-4">
      <div className="flex-1">
        <TaskStatusSelect
          currentStatus={taskStatus}
          onTaskStatusChange={(taskActiveStatus: Nullable<TaskStatus>) =>
            onFilterChange({ activeStatus: taskActiveStatus || 'completed' })
          }
        />
      </div>
      <div className="flex-1">
        <BookingCoordinatorSelect
          additionalOptions={fixedTaskFilterOptions}
          currentCoordinator={currentCoordinator}
          label="Assignee"
          name="assignee"
          onCoordinatorUpdate={handleAssigneeUpdate}
          type={ManagerTeam.OPERATIONS}
        />
      </div>
    </div>
  );
};

export default MiniTaskListFilters;
