import { Table, TableColumn, useTableConfiguration } from '@zen/DesignSystem';
import type { PageInfo, SortInput } from '@zen/types';

import Filters from '../Filters';
import ShipmentsDataHeader from '../ShipmentsDataHeader';
import type { FlatShipment, ShipmentsListCapabilities } from '../types';

interface TableViewProps {
  capabilities: ShipmentsListCapabilities;
  columns: TableColumn<FlatShipment>[];
  error?: boolean;
  loading?: boolean;
  onOrderChange: (order: SortInput) => void;
  order: SortInput;
  paginationInfo: PageInfo;
  shipments: FlatShipment[];
  totalCount: number;
}

const TableView = ({
  capabilities,
  columns,
  loading,
  onOrderChange,
  order,
  paginationInfo,
  error,
  shipments,
  totalCount
}: TableViewProps) => {
  const { hiddenColumns, tableId } = useTableConfiguration();

  return (
    <Table<FlatShipment>
      actions={<ShipmentsDataHeader capabilities={capabilities} />}
      additionalActions={<Filters />}
      columns={columns}
      data={shipments}
      error={error}
      hiddenColumns={hiddenColumns}
      loading={loading}
      onOrderChange={onOrderChange}
      order={order}
      paginationInfo={paginationInfo}
      tableId={tableId}
      totalCountConfig={{
        totalCount,
        entityName: 'shipment'
      }}
    />
  );
};

export default TableView;
