import cx from 'classnames';
import { FieldArray } from 'formik';
import type { FC, ReactNode } from 'react';

import type { FormFieldArrayHelpers } from './types';

export interface Props {
  children: (arrayHelpers: FormFieldArrayHelpers) => ReactNode;
  name: string;
  noMargin?: boolean;
}

const FormFieldArray: FC<Props> = ({ children, name, noMargin = false }) => {
  const className: string = cx({ 'mb-4': !noMargin });

  return (
    <FieldArray name={name}>
      {(arrayHelpers: FormFieldArrayHelpers) => <div className={className}>{children(arrayHelpers)}</div>}
    </FieldArray>
  );
};

export default FormFieldArray;
