import type { FC } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

interface Props {
  count?: number;
}

const RepeatedSkeletons: FC<Props> = ({ count }) => (
  <div data-testid="skeleton-location">
    {[...Array(count)].map((_, i) => (
      <div key={i} className="skeleton-element">
        <div className="flex items-center">
          <SkeletonLoading className="my-2" height={16} width="w-20" />
          <SkeletonLoading className="my-2" height={24} width="w-20" />
          <SkeletonLoading className="my-2" height={16} width="w-4" />
          <SkeletonLoading className="my-2" height={24} width="w-20" />
        </div>
      </div>
    ))}
  </div>
);

const SkeletonNewLocationLoading: FC<Props> = ({ count = 5 }) => {
  return (
    <div className="flex flex-col">
      <div className="px-10 py-10 bg-grey-lightest border-b border-solid border-grey-lighter">
        <SkeletonLoading className="my-2" height={24} lighter={true} width="w-12" />
        <SkeletonLoading className="my-2" height={30} lighter={true} width="w-60" />
      </div>
      <div className="px-10 py-10">
        <SkeletonLoading className="my-2" height={20} width="w-24" />
        <SkeletonLoading className="my-2" height={20} width="w-2/5" />
        <SkeletonLoading className="my-2" height={32} width="w-3/5" />
        <SkeletonLoading className="mt-10" height={20} width="w-24" />
        <div className="flex">
          <SkeletonLoading circle={true} className="my-2" height={16} width="w-4" />
          <SkeletonLoading className="my-2" height={20} width="w-24" />
        </div>
        <div className="flex">
          <SkeletonLoading circle={true} className="my-2" height={16} width="w-4" />
          <SkeletonLoading className="my-2" height={20} width="w-16" />
        </div>
        <SkeletonLoading className="mt-10 mb-2" height={20} width="w-20" />
        <RepeatedSkeletons count={count} />
        <SkeletonLoading className="mt-10 mb-2" height={20} width="w-24" />
        <SkeletonLoading className="my-2" height={32} width="w-3/5" />
        <div className="flex mt-10">
          <SkeletonLoading className="m-2" height={32} lighter={true} width="w-20" />
          <SkeletonLoading className="m-2" height={32} width="w-20" />
        </div>
      </div>
    </div>
  );
};

export type { Props as SkeletonSkeletonNewLocationLoadingProps };

export default SkeletonNewLocationLoading;
