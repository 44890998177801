import cx from 'classnames';
import type { CSSProperties, FC } from 'react';

import type { Optional } from '@zen/utils/typescript';

import type { PopoverPlacement } from '../types';

interface Props {
  placement: Optional<PopoverPlacement>;
  style: CSSProperties;
  variant?: 'white' | 'dark';
}

const PopoverArrow: FC<Props> = ({ placement, style, variant }) => {
  const topPlacement: boolean = placement === 'top' || placement === 'top-start' || placement === 'top-end';
  const bottomPlacement: boolean = placement === 'bottom' || placement === 'bottom-start' || placement === 'bottom-end';
  const leftPlacement: boolean = placement === 'left';
  const rightPlacement: boolean = placement === 'right';
  const topPlacementBorder: string = variant === 'white' ? 'border-t-white' : 'border-t-navy-light';
  const bottomPlacementBorder: string = variant === 'white' ? 'border-b-white' : 'border-b-navy-light';
  const rightPlacementBorder: string = variant === 'white' ? 'border-r-white' : 'border-r-navy-light';
  const leftPlacementBorder: string = variant === 'white' ? 'border-l-white' : 'border-l-navy-light';

  const className: string = cx(
    {
      [`${topPlacementBorder} border-t-8 border-x-transparent border-x-8 border-b-0 -bottom-1.5`]: topPlacement,
      [`${bottomPlacementBorder} border-b-8 border-x-transparent border-x-8 border-t-0 -top-1.5`]: bottomPlacement,
      [`${rightPlacementBorder} border-r-8 border-y-transparent border-y-8 border-l-0 -left-1.5`]: rightPlacement,
      [`${leftPlacementBorder} border-l-8 border-y-transparent border-y-8 border-r-0 -right-1.5`]: leftPlacement
    },
    'border-solid pointer-events-none'
  );

  return <div className={className} data-popper-arrow={true} data-testid="popover-arrow" style={style} />;
};

export default PopoverArrow;
