const PACKING_LIST_RELATIVE_INDEX_PATH = 'packing-lists';

export const PACKING_LISTS_ROUTE_PREFIX = `/dashboard/${PACKING_LIST_RELATIVE_INDEX_PATH}`;

interface PackingListsRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  packingListDetails: {
    getUrl: (zencargoReference: string, cargoId: string) => string;
    path: string;
    relativePath: string;
  };
  packingListDetailsOrderDetails: {
    getUrl: (zencargoReference: string, cargoId: string, orderId: string) => string;
    path: string;
    relativePath: string;
  };

  packingListIndex: {
    getUrl: (zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  packingListIndexOrderDetails: {
    getUrl: (zencargoReference: string, orderId: string) => string;
    path: string;
    relativePath: string;
  };
}

const packingListsRoutes: PackingListsRoutes = {
  index: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/*`,
    relativePath: `${PACKING_LIST_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => PACKING_LISTS_ROUTE_PREFIX
  },
  packingListDetails: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/cargo/:cargoId/*`,
    relativePath: '/:zencargoReference/cargo/:cargoId/*',
    getUrl: (zencargoReference: string, cargoId: string) => `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}/cargo/${cargoId}`
  },
  packingListDetailsOrderDetails: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/cargo/:cargoId/:id/*`,
    relativePath: ':id/*',
    getUrl: (zencargoReference: string, cargoId: string, orderId: string) =>
      `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}/cargo/${cargoId}/${orderId}`
  },
  packingListIndex: {
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/*`,
    relativePath: ':zencargoReference/*',
    getUrl: (zencargoReference: string) => `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}`
  },
  packingListIndexOrderDetails: {
    getUrl: (zencargoReference: string, orderId: string) => `${PACKING_LISTS_ROUTE_PREFIX}/${zencargoReference}/${orderId}`,
    path: `${PACKING_LISTS_ROUTE_PREFIX}/:zencargoReference/:id/*`,
    relativePath: ':id/*'
  }
};

export default packingListsRoutes;
