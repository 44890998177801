import type { FC } from 'react';

import { incotermsLabelMapping } from '@zen/Booking/helpers';
import IncotermSelect from '@zen/Components/IncotermSelect';
import LabelledValue from '@zen/Components/LabelledValue';
import type { IncotermsValue } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateIncotermsMutation } from './graphql';

interface Props {
  canUpdateIncoterms: boolean;
  incoterms: Optional<IncotermsValue>;
  onSuccess?: () => void;
  zencargoReference: string;
}

const IncotermsInlineField: FC<Props> = ({ incoterms, zencargoReference, onSuccess, canUpdateIncoterms }) => {
  const { addSuccess, addError } = useNotification();
  const [updateIncoterms, { loading }] = useUpdateIncotermsMutation();

  const label: string = 'Incoterm';

  const handleUpdateIncoterms = async (incotermsValue: Nullable<IncotermsValue>): Promise<void> => {
    if (incotermsValue) {
      await performMutation({
        mutationFn: () =>
          updateIncoterms({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary'],
            variables: {
              input: {
                zencargoReference,
                incoterms: incotermsValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          onSuccess?.();
          addSuccess('Incoterms updated.');
        }
      });
    }
  };

  if (!canUpdateIncoterms) {
    return (
      <LabelledValue label={label} variant="default">
        {incoterms ? incotermsLabelMapping[incoterms] : '-'}
      </LabelledValue>
    );
  }

  return (
    <div className="-ml-3">
      <IncotermSelect
        isDisabled={loading}
        isLoading={loading}
        label="Incoterm"
        onChange={handleUpdateIncoterms}
        value={incoterms}
        variant="inline"
      />
    </div>
  );
};

export default IncotermsInlineField;
