import type { IconName } from '@zen/Styleguide';

import type { EventLogActionType } from '../types';

export const eventActionMapping: Record<EventLogActionType, { icon: IconName; label: string }> = {
  added: {
    icon: 'zicon-add',
    label: 'added'
  },
  removed: {
    icon: 'zicon-trash',
    label: 'removed'
  },
  updated: {
    icon: 'zicon-edit',
    label: 'changed'
  }
};
