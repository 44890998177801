const TASK_LIST_RELATIVE_INDEX_PATH = 'task-list';

export const TASK_LIST_ROUTE_PREFIX = `/dashboard/${TASK_LIST_RELATIVE_INDEX_PATH}`;

interface TaskListRoutes {
  taskListAllOpenTasks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  taskListComplete: {
    getUrl: (taskId: string) => string;
    path: string;
    relativePath: string;
  };
  taskListIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  taskListUnassignedTasks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  taskListYourActiveTasks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  taskListYourCompletedTasks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  taskListYourTasks: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const taskListRoutes: TaskListRoutes = {
  taskListAllOpenTasks: {
    getUrl: () => `${TASK_LIST_ROUTE_PREFIX}/all-open-tasks`,
    path: `${TASK_LIST_ROUTE_PREFIX}/all-open-tasks`,
    relativePath: 'all-open-tasks'
  },
  taskListComplete: {
    getUrl: (taskId: string) => `${TASK_LIST_ROUTE_PREFIX}/${taskId}/complete`,
    path: `${TASK_LIST_ROUTE_PREFIX}/:taskId/complete`,
    relativePath: ':taskId/complete'
  },
  taskListIndex: {
    path: `${TASK_LIST_ROUTE_PREFIX}/*`,
    getUrl: (): string => TASK_LIST_ROUTE_PREFIX,
    relativePath: `${TASK_LIST_RELATIVE_INDEX_PATH}/*`
  },
  taskListUnassignedTasks: {
    getUrl: () => `${TASK_LIST_ROUTE_PREFIX}/unassigned-tasks`,
    path: `${TASK_LIST_ROUTE_PREFIX}/unassigned-tasks`,
    relativePath: 'unassigned-tasks'
  },
  taskListYourTasks: {
    path: `${TASK_LIST_ROUTE_PREFIX}/your-tasks`,
    getUrl: (): string => `${TASK_LIST_ROUTE_PREFIX}/your-tasks`,
    relativePath: 'your-tasks/*'
  },
  taskListYourActiveTasks: {
    path: `${TASK_LIST_ROUTE_PREFIX}/your-tasks/active`,
    getUrl: (): string => `${TASK_LIST_ROUTE_PREFIX}/your-tasks/active`,
    relativePath: 'active'
  },
  taskListYourCompletedTasks: {
    path: `${TASK_LIST_ROUTE_PREFIX}/your-tasks`,
    getUrl: (): string => `${TASK_LIST_ROUTE_PREFIX}/your-tasks/completed`,
    relativePath: 'completed'
  }
};

export default taskListRoutes;
