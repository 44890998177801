const SHIPMENT_RELATIVE_INDEX_PATH = 'shipments';

export const SHIPMENT_ROUTE_PREFIX = `/dashboard/${SHIPMENT_RELATIVE_INDEX_PATH}`;

interface ShipmentRoutes {
  shipmentDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  shipmentDetailsPageOrders: {
    getUrl: (zencargoReference: string, orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  shipmentIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  shipmentIndexOrderDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  trackedShipmentIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const shipmentRoutes: ShipmentRoutes = {
  shipmentDetailsPage: {
    path: `${SHIPMENT_ROUTE_PREFIX}/details/:id/:sectionName?/*`,
    relativePath: `${SHIPMENT_RELATIVE_INDEX_PATH}/details/:id/:sectionName?/*`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${SHIPMENT_ROUTE_PREFIX}/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  trackedShipmentIndex: {
    path: `${SHIPMENT_ROUTE_PREFIX}/tracked-shipments`,
    relativePath: `${SHIPMENT_RELATIVE_INDEX_PATH}/tracked-shipments/*`,
    getUrl: () => `${SHIPMENT_ROUTE_PREFIX}/tracked-shipments`
  },
  shipmentDetailsPageOrders: {
    path: `${SHIPMENT_ROUTE_PREFIX}/details/:zencargoReference/orders/:id?`,
    getUrl: (zencargoReference: string, orderId: string, tab?: string) => {
      const url = `${SHIPMENT_ROUTE_PREFIX}/details/${zencargoReference}/orders/${orderId}`;

      return `${url}/${tab || 'activity'}`;
    },
    relativePath: ':id/*'
  },
  shipmentIndex: {
    getUrl: () => SHIPMENT_ROUTE_PREFIX,
    path: `${SHIPMENT_ROUTE_PREFIX}/*`,
    relativePath: `${SHIPMENT_RELATIVE_INDEX_PATH}/*`
  },
  shipmentIndexOrderDetails: {
    path: `${SHIPMENT_ROUTE_PREFIX}/all-shipments/:id?`,
    relativePath: ':id/*',
    getUrl: (orderId: string, tab?: string): string => {
      const url = `${SHIPMENT_ROUTE_PREFIX}/all-shipments/${orderId}`;

      return `${url}/${tab || 'activity'}`;
    }
  }
};

export default shipmentRoutes;
