import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type EditOrganisationQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  id: GraphQLTypes.Scalars['String'];
}>;

export type EditOrganisationQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & Pick<GraphQLTypes.AgentOrganisation, 'id' | 'name'>)
                | ({ __typename?: 'ForwarderOrganisation' } & Pick<GraphQLTypes.ForwarderOrganisation, 'id' | 'name'>)
                | ({ __typename?: 'MyOrganisation' } & Pick<GraphQLTypes.MyOrganisation, 'id' | 'name'>)
                | ({ __typename?: 'SavedOrganisation' } & Pick<GraphQLTypes.SavedOrganisation, 'id' | 'name'>)
              >
            >
          >;
        }
      >;
    }
  >;
};

export const EditOrganisationDocument = /* #__PURE__ */ gql`
  query editOrganisation($accountUuid: String!, $id: String!) {
    network(id: $accountUuid) {
      organisations(ids: [$id]) {
        nodes {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useEditOrganisationQuery__
 *
 * To run a query within a React component, call `useEditOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrganisationQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<EditOrganisationQueryResult, EditOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<EditOrganisationQueryResult, EditOrganisationQueryVariables>(EditOrganisationDocument, options);
}
export function useEditOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<EditOrganisationQueryResult, EditOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<EditOrganisationQueryResult, EditOrganisationQueryVariables>(EditOrganisationDocument, options);
}
export type EditOrganisationQueryHookResult = ReturnType<typeof useEditOrganisationQuery>;
export type EditOrganisationLazyQueryHookResult = ReturnType<typeof useEditOrganisationLazyQuery>;
