import { createContext } from 'react';

import type { LocationDescriptor, LocationDescriptorObject } from '@zen/types';

interface NavigationHistoryContextType {
  navigate: (to: LocationDescriptor) => void;
  navigateBack: (
    key: string,
    defaultUrl: string,
    modifyTargetLocation?: (location: LocationDescriptorObject) => LocationDescriptorObject
  ) => void;
}

const initialContext = {
  navigate: (to: LocationDescriptor) => {},
  navigateBack: (
    key: string,
    defaultUrl: string,
    modifyTargetLocation?: (location: LocationDescriptorObject) => LocationDescriptorObject
  ) => {}
};

const NavigationHistoryContext = createContext<NavigationHistoryContextType>(initialContext);

export default NavigationHistoryContext;
