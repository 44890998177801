import type { FC, ReactNode } from 'react';

import { TimeOption } from '@zen/Components/DateWithTimeForm';
import DateWithTimeForm from '@zen/Components/DateWithTimeForm/DateWithTimeForm';
import { Button } from '@zen/DesignSystem';
import { prepareDateAndTimePayload } from '@zen/Journey/helpers';
import { JourneyShippingMilestoneNameEnum, MilestoneDateType } from '@zen/Journey/types';
import type { BusinessHours } from '@zen/Networks';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { isSharedMilestone } from '../../JourneyDetails/helpers';
import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import { getRequestedDateTypeLabel } from '../../MilestoneDatesDialog/helpers';
import FormRequestedBySelect from '../FormRequestedBySelect';
import { journeyRefetchQueryList } from '../helpers';
import { useRemoveMilestoneRequestedDateMutation, useUpdateMilestoneRequestedDateMutation } from './graphql';
import { createValidationSchema } from './requestedDateForm.validation';
import type { RequestedDateFormFields } from './types';

interface Props {
  businessHours: BusinessHours;
  canRemoveRequestedDate: boolean;
  canUpdateRequestedBy: boolean;
  initialValues: RequestedDateFormFields;
  milestoneId: string;
  milestoneName: JourneyShippingMilestoneNameEnum;
  onSuccess?: () => void;
  timeZone?: Nullable<string>;
  title: string;
}

const RequestedDateForm: FC<Props> = (props) => {
  const {
    businessHours,
    canRemoveRequestedDate,
    canUpdateRequestedBy,
    initialValues,
    milestoneId,
    milestoneName,
    onSuccess,
    timeZone,
    title
  } = props;
  const [removeRequestedDate, { loading }] = useRemoveMilestoneRequestedDateMutation();
  const [updateRequestedDate] = useUpdateMilestoneRequestedDateMutation();
  const { addError, addSuccess } = useNotification();
  const { getMilestoneDisabledDates, zencargoReference } = useJourneyContext();
  const { disabledDates, tooltip } = getMilestoneDisabledDates(milestoneId, MilestoneDateType.REQUESTED);

  const dateTypeLabel: string = getRequestedDateTypeLabel(milestoneName);
  const dateTypeLabelLowercased: string = dateTypeLabel.toLowerCase();
  const timeConfig: TimeOption[] = isSharedMilestone(milestoneName)
    ? [TimeOption.NO_TIME, TimeOption.POINT_IN_TIME]
    : [TimeOption.POINT_IN_TIME, TimeOption.TIME_RANGE];

  const handleClear = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removeRequestedDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError();
      },
      onSuccess: () => {
        addSuccess(`${dateTypeLabel} date has been removed.`);
        onSuccess?.();
      }
    });
  };

  const handleSubmit = (values: RequestedDateFormFields) => {
    return performMutation({
      mutationFn: () =>
        updateRequestedDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              date: prepareDateAndTimePayload(values),
              requestedBy: canUpdateRequestedBy ? values.requestedBy : undefined,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${dateTypeLabel} date has been ${initialValues.date ? 'updated' : 'added'}.`);
      }
    });
  };

  const buttonText: string = initialValues.date
    ? `Update ${dateTypeLabelLowercased} date`
    : `Add ${dateTypeLabelLowercased} date`;
  const additionalFields: ReactNode = canUpdateRequestedBy ? <FormRequestedBySelect className="mt-4" name="requestedBy" /> : null;
  const additionalActionButton: ReactNode =
    canRemoveRequestedDate && initialValues.date ? (
      <Button disabled={loading} isLoading={loading} onClick={handleClear} variant="secondary">
        Clear {dateTypeLabelLowercased} date
      </Button>
    ) : null;

  return (
    <DateWithTimeForm<RequestedDateFormFields>
      additionalActionButton={additionalActionButton}
      additionalFields={additionalFields}
      businessHours={businessHours}
      buttonText={buttonText}
      dateTooltip={tooltip}
      disabledDates={disabledDates}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      timeConfig={timeConfig}
      timeZone={timeZone}
      title={title}
      validationSchema={createValidationSchema(canUpdateRequestedBy)}
      variant="compact"
    />
  );
};

export default RequestedDateForm;
