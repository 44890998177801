import cx from 'classnames';
import { FC, useState } from 'react';

import { Button, Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props {
  className?: string;
  confirmLabel?: string;
  errorMessage?: string;
  onClickPromise: () => Promise<IOkOrErrorResult>;
  successHeader?: string;
  successMessage?: string;
}

export const DataExportButton: FC<Props> = (props) => {
  const {
    successMessage = 'When it is completed a link will be sent to your email',
    successHeader = 'Please wait, your file is being generated...',
    errorMessage = 'An error occurred during data file generation',
    confirmLabel = 'Return to page',
    className,
    onClickPromise
  } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const { addError } = useNotification();

  const closeModal = () => setModalOpen(false);

  const onClick = async () => {
    try {
      const { ok, error } = await onClickPromise();

      if (ok && !error) {
        setModalOpen(true);
      } else {
        addError(errorMessage);
      }
    } catch (e) {
      addError(errorMessage);
    }
  };

  const renderButton = () => (
    <Button iconLeft="zicon-download" onClick={onClick} size="compact" variant="ghost">
      Export data
    </Button>
  );

  return (
    <div className={cx('inline-block', className)}>
      {renderButton()}
      <Dialog
        confirmLabel={confirmLabel}
        hasCancelButton={false}
        header={successHeader}
        isOpen={isModalOpen}
        message={successMessage}
        onClose={closeModal}
        onConfirm={closeModal}
      />
    </div>
  );
};

export type { Props as DataExportButtonProps };
export default DataExportButton;
