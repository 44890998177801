const WORKFLOWS_RELATIVE_INDEX_PATH = 'workflows';

export const WORKFLOWS_PREFIX = `/dashboard/${WORKFLOWS_RELATIVE_INDEX_PATH}`;

interface WorkflowsRoutes {
  workflowRuleCreate: {
    getUrl: (ruleDefinitionId: string) => string;
    path: string;
    relativePath: string;
  };
  workflowRuleDefinitionCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowRuleEdit: {
    getUrl: (ruleId: string) => string;
    path: string;
    relativePath: string;
  };
  workflowRulesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsFlows: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsFlowsActive: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsFlowsDraft: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsTriggers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  workflowsTriggersCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const workflowsRoutes: WorkflowsRoutes = {
  workflowsIndex: {
    getUrl: () => WORKFLOWS_PREFIX,
    path: `${WORKFLOWS_PREFIX}/*`,
    relativePath: `${WORKFLOWS_RELATIVE_INDEX_PATH}/*`
  },
  workflowsFlows: {
    getUrl: () => `${WORKFLOWS_PREFIX}/flows`,
    path: `${WORKFLOWS_PREFIX}/flows/*`,
    relativePath: 'flows/*'
  },
  workflowsFlowsActive: {
    getUrl: () => `${WORKFLOWS_PREFIX}/flows/active`,
    path: `${WORKFLOWS_PREFIX}/flows/active`,
    relativePath: 'active'
  },
  workflowsFlowsDraft: {
    getUrl: () => `${WORKFLOWS_PREFIX}/flows/draft`,
    path: `${WORKFLOWS_PREFIX}/flows/draft`,
    relativePath: 'draft'
  },
  workflowsTriggers: {
    getUrl: () => `${WORKFLOWS_PREFIX}/triggers`,
    path: `${WORKFLOWS_PREFIX}/triggers`,
    relativePath: 'triggers'
  },
  workflowsTriggersCreate: {
    getUrl: () => `${WORKFLOWS_PREFIX}/triggers/create`,
    path: `${WORKFLOWS_PREFIX}/triggers/create`,
    relativePath: 'triggers/create'
  },
  workflowRulesIndex: {
    getUrl: () => `${WORKFLOWS_PREFIX}/workflow-rules`,
    path: `${WORKFLOWS_PREFIX}/workflow-rules/*`,
    relativePath: 'workflow-rules/*'
  },
  workflowRuleCreate: {
    path: `${WORKFLOWS_PREFIX}/workflow-rules/create/:ruleDefinitionId`,
    getUrl: (ruleDefinitionId: string) => `${WORKFLOWS_PREFIX}/workflow-rules/create/${ruleDefinitionId}`,
    relativePath: 'create/:ruleDefinitionId'
  },
  workflowRuleEdit: {
    getUrl: (ruleId: string) => `${WORKFLOWS_PREFIX}/workflow-rules/edit/${ruleId}`,
    path: `${WORKFLOWS_PREFIX}/workflow-rules/edit/:ruleId`,
    relativePath: 'edit/:ruleId'
  },
  workflowRuleDefinitionCreate: {
    getUrl: () => `${WORKFLOWS_PREFIX}/workflow-rules/workflow-rule-definition/create`,
    path: `${WORKFLOWS_PREFIX}/workflow-rules/workflow-rule-definition/create`,
    relativePath: 'workflow-rule-definition/create'
  }
};

export default workflowsRoutes;
