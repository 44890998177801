import cx from 'classnames';
import { CSSProperties, FC, ReactNode, RefObject, SyntheticEvent, useMemo, useState } from 'react';
import { Resizable } from 'react-resizable';

import { TABLE_HEADER_ZINDEX } from '@zen/Layout';
import type { SortingOrder } from '@zen/types';
import { useHover } from '@zen/utils/hooks/useHover';
import { createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Loading from '../../../Loading';
import DragHandle from '../../DragHandle';
import ResizeHandle from '../../ResizeHandle';
import SortIndicator from '../../SortIndicator';
import { getMaxResizeWidth } from '../helpers';

interface Props {
  children?: ReactNode;
  className?: string;
  draggable?: boolean;
  fixed?: boolean;
  isDragging: boolean;
  isOver: boolean;
  isSorted?: boolean;
  loading?: boolean;
  offsetElementRef: RefObject<HTMLDivElement>;
  onClick: (event: SyntheticEvent) => void;
  onResizeEnd?: (width: number) => void;
  resizable?: boolean;
  resizableWidth?: number;
  rightAligned: boolean;
  setCellRef: (element: HTMLTableHeaderCellElement) => void;
  setDragRef: (element: HTMLDivElement) => void;
  setResizableWidth: (width: number) => void;
  sortDirection?: SortingOrder;
  sortable?: boolean;
  style: CSSProperties;
  title: ReactNode;
}

const StyledTableHeaderCell: FC<Props> = (props) => {
  const [ref, isHovered] = useHover();
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const {
    children,
    className,
    draggable,
    fixed,
    isDragging,
    isOver,
    isSorted,
    loading = false,
    offsetElementRef,
    onClick,
    onResizeEnd,
    resizable,
    resizableWidth,
    rightAligned = false,
    setCellRef,
    setDragRef,
    setResizableWidth,
    sortable,
    sortDirection,
    style,
    title,
    ...rest
  } = props;

  const cellClassNames: string = cx(
    'zen-table-resize-wrap',
    'group',
    'h-10',
    'align-middle',
    'text-grey-dark text-xs font-bold',
    {
      'hover:text-azure-base': sortable,
      'opacity-50': isDragging,
      'bg-grey-lightest': !isOver,
      'bg-azure-lighter': isOver,
      'cursor-pointer': sortable,
      relative: resizable && !fixed
    },
    className
  );

  const renderSort = useMemo(() => {
    if (!sortable) {
      return null;
    }

    const sortIndicatorClassName: string = cx('z-20', {
      'text-azure-base': isSorted,
      'ml-auto': rightAligned
    });

    return loading ? (
      <Loading color="grey" size="smallest" />
    ) : (
      <SortIndicator className={sortIndicatorClassName} direction={sortDirection} isHovered={isHovered} isResizing={isResizing} />
    );
  }, [sortable, loading, isHovered, isSorted, sortDirection, rightAligned, isResizing]);

  const handleResize = (_: SyntheticEvent, data: { size: { width: number } }): void => {
    if (!resizable) {
      return;
    }

    setResizableWidth(data?.size?.width);
  };

  const handleResizeStart = (): void => {
    setIsResizing(true);
  };

  const handleResizeStop = (e: SyntheticEvent, data: { size: { width: number } }): void => {
    if (!resizable) {
      return;
    }

    setIsResizing(false);

    onResizeEnd?.(data?.size?.width);
  };

  const renderStyledHeader = (): JSX.Element => {
    const titleClassName: string = cx({
      'text-right': rightAligned
    });

    const contentClassNames: string = cx('flex items-center h-10 px-4 space-x-2', { 'justify-end': rightAligned });
    const contentDraggableClassNames: string = cx(
      'ml-auto transition-opacity opacity-0 group-hover:opacity-100',
      TABLE_HEADER_ZINDEX
    );

    return (
      <>
        <div ref={ref} className={contentClassNames} data-testid="column-title">
          {rightAligned && renderSort}
          <div className={titleClassName} {...createTrackingLabelAttribute()}>
            {title}
          </div>
          {!rightAligned && renderSort}

          {draggable && (
            <DragHandle ref={setDragRef} className={contentDraggableClassNames} isDragging={isDragging} isResizing={isResizing} />
          )}
        </div>
        {children}
      </>
    );
  };

  const resizableClassnames: string = cx('absolute inset-0');

  return (
    <th
      ref={setCellRef}
      onClick={onClick}
      {...rest}
      {...createTrackingParentAttribute('table-header')}
      className={cellClassNames}
      style={style}
    >
      {!!(resizable && typeof resizableWidth === 'number') && (
        <div className={resizableClassnames} style={{ width: resizableWidth }}>
          <Resizable
            handle={<ResizeHandle />}
            height={0}
            maxConstraints={[getMaxResizeWidth(offsetElementRef.current), 1]}
            onResize={handleResize}
            onResizeStart={handleResizeStart}
            onResizeStop={handleResizeStop}
            width={resizableWidth}
          >
            <div />
          </Resizable>
        </div>
      )}
      {renderStyledHeader()}
    </th>
  );
};

export default StyledTableHeaderCell;
