import { useAuth0 } from '@auth0/auth0-react';
import { logout as thoughtspotLogout } from '@thoughtspot/visual-embed-sdk';

import { useAccountId } from '@zen/App/AccountIdContext';
import authStore from '@zen/Auth/authStore';

const useAuth = () => {
  const { isAuthenticated, logout: auth0Logout } = useAuth0();
  const { setAccountId, removeAccountId } = useAccountId();
  const { clearSensitiveData, removeAccountInfo, unsetAccessToken } = authStore();

  const logout = async (): Promise<void> => {
    thoughtspotLogout();
    removeAccountId();
    removeAccountInfo();
    unsetAccessToken();
    auth0Logout({ logoutParams: { returnTo: `${window.location.origin}/dashboard` } });
  };

  return {
    isAuthenticated,
    logout,
    clearSensitiveData,
    loginWithAccountId: (id: string) => setAccountId(id)
  };
};

export default useAuth;
