import type { FC, ReactNode } from 'react';

import { BookingDetailsPageSection } from '@zen/Booking/BookingDetails/types';
import TableLink from '@zen/Components/TableLink';
import { Counter, Tooltip } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';

export interface ReferenceListItem {
  referenceLabel: string;
  url: string;
}

interface Props {
  hasLimitation?: boolean;
  onClick?: (id: string) => void;
  referencesList: ReferenceListItem[];
  totalCount?: number;
  zencargoReference?: string;
}

const ReferencesList: FC<Props> = ({
  hasLimitation = true,
  onClick = () => {},
  referencesList,
  totalCount = 0,
  zencargoReference = ''
}) => {
  const renderReferencesList = (list: ReferenceListItem[]): ReactNode => (
    <div className="flex flex-col text-sm leading-normal text-navy-base">
      {list.map((reference: ReferenceListItem, index: number): ReactNode => {
        const { referenceLabel, url } = reference;

        return (
          <div key={index} data-testid="reference-item" onClick={() => onClick(referenceLabel)}>
            <TableLink linkTo={url} preserveCurrentQueryString={true}>
              {referenceLabel}
            </TableLink>
          </div>
        );
      })}
    </div>
  );

  const visibleItems: number = 2;
  const referencesLimitation: number = 10;

  if (referencesList.length <= visibleItems) {
    return <>{renderReferencesList(referencesList)}</>;
  }

  const visibleElements: ReferenceListItem[] = referencesList.slice(0, visibleItems);
  const endIndex: number = hasLimitation ? referencesLimitation : referencesList.length;
  const showDetailsLink: boolean = hasLimitation && !!totalCount && totalCount > referencesLimitation;
  const tooltipList: ReferenceListItem[] = referencesList.slice(visibleItems, endIndex);
  const tooltipCounter: number = hasLimitation ? totalCount - visibleItems : tooltipList.length;

  const detailsLink: string = shipmentRoutes.shipmentDetailsPage.getUrl(zencargoReference, BookingDetailsPageSection.ORDERS);

  const tooltipContent: ReactNode = (
    <>
      {renderReferencesList(tooltipList)}
      {showDetailsLink && (
        <div className="pt-1 font-bold">
          <TableLink linkTo={detailsLink}>View whole list</TableLink>
        </div>
      )}
    </>
  );

  return (
    <div className="flex items-end" data-testid="references-list">
      {renderReferencesList(visibleElements)}
      <Tooltip tooltipContent={tooltipContent}>
        <div className="pl-4">
          <Counter prefix="+" showCounterLimit={false} value={tooltipCounter} />
        </div>
      </Tooltip>
    </div>
  );
};

export default ReferencesList;
