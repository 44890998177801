import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';

interface Props {
  onClear: () => void;
}

const FilterFooter: FC<Props> = ({ onClear }) => {
  return (
    <div className="flex justify-end leading-normal p-4">
      <Button onClick={onClear} size="compact" variant="secondary">
        Clear
      </Button>
    </div>
  );
};

export default FilterFooter;
