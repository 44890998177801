import type { ReactNode } from 'react';

import type { IconName } from '@zen/Styleguide';
import type { ArrayElement } from '@zen/utils/typescript';

import type { FilterDateRangePickerProps } from './FilterComponents/components/FilterDateRangePicker';
import type { FilterGroupProps } from './FilterComponents/components/FilterGroup';
import type { FilterMultiSelectProps } from './FilterComponents/components/FilterMultiSelect';
import type { FilterSelectProps } from './FilterComponents/components/FilterSelect';

export type FilterComponentType = 'select' | 'multi-select' | 'date-range-picker' | 'date-picker' | 'group';

export type FilterPropType<ComponentType extends FilterComponentType, ComponentPropType> = {
  componentType: ComponentType;
  props: ComponentPropType;
};

export type FilterItemPropType<T> =
  | FilterPropType<'select', FilterSelectProps<T>>
  | FilterPropType<'multi-select', FilterMultiSelectProps<T>>
  | FilterPropType<'date-range-picker', FilterDateRangePickerProps>
  | FilterPropType<'group', FilterGroupProps<T>>
  | FilterPropType<'date-picker', {}>;

export type FilterItemType<FiltersType> = {
  disabled?: boolean;
  icon?: IconName;
  onClick?: () => void;
  title: string;
} & ComponentType<FiltersType>;

export type FilterItems<FiltersType> = Array<FilterItemType<FiltersType>>;

export type ComponentType<FiltersType> = {
  [K in keyof FiltersType]: { key: K } & FilterItemPropType<ArrayElement<NonNullable<FiltersType[K]>>>;
}[keyof FiltersType];

export const FILTERS_CATEGORY = 'Filters';

export enum FiltersAction {
  FILTERS_CHANGED = 'FiltersChanged',
  FILTERS_CLEARED = 'FiltersCleared',
  FILTER_APPLIED = 'FilterApplied',
  FILTER_REMOVED = 'FilterRemoved'
}

export type FilterSection<T> = {
  items: Array<T>;
  title?: ReactNode;
};

export type FilterOptionsType<T> = Array<FilterOption<T>> | Array<FilterGroup<T>>;

export interface FilterOption<Value> {
  label: string;
  renderLabel?: () => ReactNode;
  value: Value;
}

export interface FilterGroup<Value> {
  label?: string;
  options: readonly FilterOption<Value>[];
}
