import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { TooltipConfig } from './types';

export interface DatePickerContextType {
  tooltip: TooltipConfig | TooltipConfig[] | undefined;
}

const initialContext = {
  tooltip: undefined
};

const DatePickerContext = createContext<DatePickerContextType>(initialContext);

const useDatePickerContext = (): DatePickerContextType => {
  return useContextOrThrowError<DatePickerContextType>(
    DatePickerContext,
    'useDatePickerContext must be used within DatePickerContext'
  );
};

export { DatePickerContext, useDatePickerContext };
