import type { FC } from 'react';

import { Switch } from '@zen/DesignSystem';

interface Props {
  isChecked: boolean;
  isForwarderApproval: boolean;
  onSwitcherChange: (value: boolean) => void;
  zencargoReference: string;
}

const ApprovalMessage: FC<Props> = ({ isChecked, isForwarderApproval, onSwitcherChange, zencargoReference }) => {
  const showSwitcher: boolean = isForwarderApproval;
  const forwarderApprovalText: string = `Are you sure you want to approve booking request ${zencargoReference}?
    Doing so will generate a shipment or you can opt to send this request to the customer for approval.`;
  const customerApprovalText: string = `Are you sure you want to approve booking request ${zencargoReference}?
    Sending approval will generate a shipment.`;

  const text: string = isForwarderApproval ? forwarderApprovalText : customerApprovalText;

  return (
    <>
      {text}
      {showSwitcher && (
        <div>
          <Switch
            className="flex justify-center mt-4 font-bold text-grey-dark"
            isChecked={isChecked}
            label="Send to customer for approval"
            onChange={onSwitcherChange}
          />
        </div>
      )}
    </>
  );
};

export default ApprovalMessage;
