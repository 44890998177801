import { type ChargeItemFormInitialValues, Currency } from '../../types';

export const getEmptyChargeItem = (chargeName: string = ''): ChargeItemFormInitialValues => {
  return {
    chargeBasis: [],
    cargoOptions: [],
    chargeType: null,
    chargeName,
    unitPrice: {
      currency: Currency.USD,
      value: null
    }
  };
};
