import qs from 'qs';

import { orderRoutes } from '@zen/Routes';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

const isOrderPage = ({ pathname }: { pathname: string }): boolean => pathname === orderRoutes.orderList.getUrl();

const getOrdersUrl = (
  location: { pathname: string; search: string },
  onlyOwnAccount: boolean = false
): { pathname: string; search: string } => {
  if (isOrderPage(location)) {
    const queryParams = parseQueryParams(location.search);
    const params = qs.stringify({ ...queryParams, booking: true, onlyOwnAccount }, { addQueryPrefix: true, encode: false });

    return { pathname: location.pathname, search: params };
  }

  return {
    pathname: orderRoutes.orderList.getUrl(),
    search: qs.stringify(
      { booking: true, ...(onlyOwnAccount ? { onlyOwnAccount: true } : {}) },
      { addQueryPrefix: true, encode: false }
    )
  };
};

export { getOrdersUrl };
