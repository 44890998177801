import { DirectUpload } from '@rails/activestorage';
import { v4 } from 'uuid';

import authStore from '@zen/Auth/authStore';
import csrfTokenManager from '@zen/utils/csrfToken';
import staticConfig from '@zen/utils/staticConfig';

import type { Document, QueuedDocument } from './types';

class DirectUploader {
  private file: File;

  private onStart: (document: QueuedDocument) => void;

  private onProgress: (document: QueuedDocument) => void;

  private onFinish: (document: Document) => void;

  private onError: (message: string) => void;

  constructor(
    file: File,
    onStart: (document: QueuedDocument) => void,
    onFinish: (document: Document) => void,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(document: ... Remove this comment to see the full error message
    onProgress: (document: QueuedDocument) => void = null,
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '(message: s... Remove this comment to see the full error message
    onError: (message: string) => void = null
  ) {
    this.file = file;
    this.onStart = onStart;
    this.onProgress = onProgress;
    this.onFinish = onFinish;
    this.onError = onError;
  }

  upload = (): void => {
    const uploader = new DirectUpload(this.file, staticConfig.activeStorageDirectUploadsPath, this);

    this.onStart({ description: this.file.name, progress: 0 });

    uploader.create((error, blob) => {
      if (error && this.onError) {
        this.onError('Unable to upload the file. Please refresh the page and try again.');
      } else {
        this.onFinish({
          attachmentId: blob.signed_id,
          uuid: v4(),
          assetUrl: '',
          description: this.file.name,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
          documentType: null,
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'DocumentPer... Remove this comment to see the full error message
          permissions: null
        });
      }
    });
  };

  directUploadWillCreateBlobWithXHR = (request: XMLHttpRequest) => {
    const { getAccessToken } = authStore();

    request.setRequestHeader('Authorization', `Bearer ${getAccessToken()}`);
    request.setRequestHeader('X-CSRF-Token', csrfTokenManager.get());
  };

  directUploadWillStoreFileWithXHR = (request: XMLHttpRequest) => {
    if (this.onProgress) {
      request.upload.addEventListener('progress', (event) => {
        const { loaded, total } = event;
        const progress = Math.round((100 * loaded) / total);

        this.onProgress({ description: this.file.name, progress });
      });
    }
  };
}

export default DirectUploader;
