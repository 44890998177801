import { get } from 'lodash';
import type { FC, ReactNode } from 'react';

import { useUpdateAccountMainDetailsMutation } from '@zen/Accounts/AccountForms/graphql';
import type { UpdateAccountMainDetailsInput } from '@zen/Accounts/AccountForms/types';
import { Form, FormButtons, FormInput, FormInstance } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import businessUnitValidation from './updateBusinessUnitModal.validation';

interface Props {
  accountId: string;
  isOpen: boolean;
  name: string;
  onClose: () => void;
  referencePrefix: string;
}

const UpdateBusinessUnitModal: FC<Props> = ({ isOpen, onClose, accountId, name, referencePrefix }) => {
  const initialValues: UpdateAccountMainDetailsInput = {
    referencePrefix,
    tradingName: name,
    accountId
  };

  const [updateMainDetails] = useUpdateAccountMainDetailsMutation({ refetchQueries: ['getAccountDetails', 'getAccounts'] });
  const { addError, addSuccess } = useNotification();

  const handleUpdateAccount = async (values: UpdateAccountMainDetailsInput): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateMainDetails({
          variables: {
            input: values
          }
        }),
      onError: (errors) => addError(get(errors, '[0].message', 'There was a problem updating the business unit.')),
      onSuccess: () => addSuccess('Business unit updated.')
    });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ members: string[] }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save changes">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Edit ${name}`}>
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleUpdateAccount}
        onSuccess={onClose}
        validationSchema={businessUnitValidation}
      >
        {() => {
          return <FormInput helperText='For example: "EMEA".' label="Division name" name="tradingName" />;
        }}
      </Form>
    </Modal>
  );
};

export default UpdateBusinessUnitModal;
