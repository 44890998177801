import type { FC } from 'react';

import { Select } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useSearchableOptions } from './useSearchableOptions';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  onChange?: (value: Nullable<string>) => void;
  showSearchIcon?: boolean;
  value?: Optional<string>;
  variant?: 'inline' | 'default';
}

const CourierSelect: FC<Props> = (props) => {
  const { options, loading, onSearch } = useSearchableOptions({ selectedValue: props.value });

  return (
    <Select
      {...props}
      hasError={props.error}
      isLoading={loading}
      labelPlacement="left"
      onInputChange={onSearch}
      options={options}
      placeholder="Enter courier"
      showSearchIcon={props.showSearchIcon}
      wrapperClassName="flex-1"
    />
  );
};

export default CourierSelect;
