import type { FC, ReactElement } from 'react';

import { useGetLegacyAccountDetailsQuery } from '@zen/Accounts/LegacyAccountDetails/graphql';
import QueryHandler from '@zen/Components/QueryHandler';
import { Table } from '@zen/DesignSystem';

import type { ScimApiKey } from '../graphql';
import { getColumns } from './tableConfiguration';

interface Props {
  accountId: string;
}

const ApiKeyTable: FC<Props> = ({ accountId }) => {
  const { data, error, loading } = useGetLegacyAccountDetailsQuery({
    variables: { accountId }
  });

  const apiKeys: ScimApiKey[] = data?.legacyAccount?.ssoDetails?.apiKeys || [];

  return (
    <QueryHandler data={apiKeys} error={!!error} isLoading={loading} noResults={null}>
      {(keys: ScimApiKey[]): ReactElement => <Table<ScimApiKey> columns={getColumns()} data={keys} rowKey="id" tableId="keys" />}
    </QueryHandler>
  );
};

export default ApiKeyTable;
