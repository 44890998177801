import type { FC } from 'react';

import { Button, Icon } from '@zen/DesignSystem';

interface Props {
  onApprove: () => void;
  onReject: () => void;
}

const QuoteOptionButtons: FC<Props> = ({ onApprove, onReject }) => {
  const iconLeft = <Icon className="text-xl" icon="zicon-tick" />;

  return (
    <div className="flex justify-end mt-6" data-testid="action-buttons">
      <Button className="fs-af-reject-option" iconLeft="zicon-close" onClick={onReject} variant="secondary">
        Reject
      </Button>
      <Button className="ml-4 fs-af-accept-quote-option" iconLeft={iconLeft} onClick={onApprove}>
        Approve
      </Button>
    </div>
  );
};

export default QuoteOptionButtons;
