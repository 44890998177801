import type { FC } from 'react';

import FilterComponent from '@zen/Components/Filters';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { useGetLocationCompaniesQuery } from './graphql';
import { buildFilters } from './helpers';
import type { AccountLocationsFilterItem, AccountLocationsFilters } from './types';

interface Props {
  accountId: string;
}

const Filters: FC<Props> = (props: Props) => {
  const { accountId } = props;
  const { filters, setFilters } = useUrlFilters<AccountLocationsFilters>();
  const { data } = useGetLocationCompaniesQuery({ variables: { accountId } });

  const filterItems: AccountLocationsFilterItem[] = buildFilters(data?.accountCompanies || []);

  return <FilterComponent items={filterItems} onChange={setFilters} value={filters} />;
};

export default Filters;
