import type { ShipmentColumnEnum } from '../types';

const columnMap = {
  references: ['zencargoReference', 'clientReference', 'purchaseOrders'],
  stages: ['ordered', 'ready', 'inTransit', 'arriving', 'status', 'estimatedDeliveryDate'],
  cargo: ['cargoDescription'],
  orderedLineItem: ['requiredDeliveryDate'],
  booking: ['bookingStatus'],
  dateRange: ['startDate', 'endDate'],
  product: ['productName', 'productSkuCode']
};

const excludedColumnKeys = ['customerAndRef', 'issues', 'actionItems', 'cargoValue'];

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'columns' implicitly has an 'any' type.
const extractColumnKeys = (columns): ShipmentColumnEnum[] => {
  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'key' implicitly has an 'any' type... Remove this comment to see the full error message
  return columns.map(({ key }) => key);
};

const mapColumnKeys = (keys: Set<ShipmentColumnEnum>): ShipmentColumnEnum[] => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return [...keys].reduce((acc: ShipmentColumnEnum[], key: ShipmentColumnEnum) => acc.concat(columnMap[key] || [key]), []);
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'columns' implicitly has an 'any' type.
export const getColumnKeys = (columns, hiddenColumnKeys): ShipmentColumnEnum[] => {
  const columnKeys = extractColumnKeys(columns);
  const keys = new Set(columnKeys.filter((key) => ![...hiddenColumnKeys, ...excludedColumnKeys].includes(key)));

  return mapColumnKeys(keys);
};
