import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateNewMessageMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateTextMessageInput;
}>;

export type CreateNewMessageMutationResult = { __typename?: 'Mutation' } & {
  activityFeedCreateTextMessage?: GraphQLTypes.Maybe<
    { __typename?: 'CreateTextMessagePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const CreateNewMessageMutationDocument = /* #__PURE__ */ gql`
  mutation createNewMessageMutation($input: CreateTextMessageInput!) {
    activityFeedCreateTextMessage(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useCreateNewMessageMutation__
 *
 * To run a mutation, you first call `useCreateNewMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewMessageMutation, { data, loading, error }] = useCreateNewMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNewMessageMutationResult, CreateNewMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateNewMessageMutationResult, CreateNewMessageMutationVariables>(
    CreateNewMessageMutationDocument,
    options
  );
}
export type CreateNewMessageMutationHookResult = ReturnType<typeof useCreateNewMessageMutation>;
