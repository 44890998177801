import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UnreadNotificationsCounterUpdatedSubscriptionVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type UnreadNotificationsCounterUpdatedSubscriptionResult = { __typename?: 'Subscription' } & Pick<
  GraphQLTypes.Subscription,
  'unreadNotificationsCounterUpdated'
>;

export const UnreadNotificationsCounterUpdatedDocument = /* #__PURE__ */ gql`
  subscription unreadNotificationsCounterUpdated {
    unreadNotificationsCounterUpdated
  }
`;

/**
 * __useUnreadNotificationsCounterUpdatedSubscription__
 *
 * To run a query within a React component, call `useUnreadNotificationsCounterUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUnreadNotificationsCounterUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadNotificationsCounterUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUnreadNotificationsCounterUpdatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    UnreadNotificationsCounterUpdatedSubscriptionResult,
    UnreadNotificationsCounterUpdatedSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSubscription<
    UnreadNotificationsCounterUpdatedSubscriptionResult,
    UnreadNotificationsCounterUpdatedSubscriptionVariables
  >(UnreadNotificationsCounterUpdatedDocument, options);
}
export type UnreadNotificationsCounterUpdatedSubscriptionHookResult = ReturnType<
  typeof useUnreadNotificationsCounterUpdatedSubscription
>;
