import type { Account as AccountType } from '@zen/Components/AccountSwitcherForm';
import { useAllLegacyAccountsQuery } from '@zen/Components/AccountSwitcherForm';
import type { Division } from '@zen/graphql/types.generated';

import type { ServerError } from '../../types';
import type { Undefinable } from '../typescript';

export interface Account {
  division?: Division;
  registeredCompanyName: string;
  uuid: string;
}

interface QueryResult {
  data: Undefinable<Account[]>;
  error: Undefinable<ServerError>;
  isLoading: boolean;
}

export const getAccountLabel = (account: AccountType): string => {
  const { division, name } = account;

  return division ? division.name : name;
};

const useAccounts = (): QueryResult => {
  const { data, loading, error } = useAllLegacyAccountsQuery({
    fetchPolicy: 'cache-and-network'
  });

  const accounts: Undefinable<Account[]> = data?.legacyAccounts?.nodes?.map((accountItem) => ({
    uuid: accountItem?.uuid || '',
    registeredCompanyName: accountItem ? getAccountLabel(accountItem) : ''
  }));

  return {
    data: accounts,
    error,
    isLoading: loading
  };
};

export default useAccounts;
