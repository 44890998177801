import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import { Select } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useGetChargeGroupsQuery, useGetChargeTypesListQuery } from './graphql';
import { getChargeGroupIds, getChargeType, prepareOptions } from './helpers';
import type { Applicability, ChargeGroup, ChargeGroupItemType, ChargeType } from './types';

interface Props extends FormFieldProps {
  applicability?: Applicability;
  onChange?: (value: Nullable<ChargeType>) => void;
  searchableChargeTypes?: ChargeGroupItemType[];
}

const FormChargeTypeSelect: FC<Props> = (props) => {
  const { applicability, name, onChange, searchableChargeTypes = [] } = props;
  const { data: chargeGroupsData } = useGetChargeGroupsQuery();
  const chargeGroupsList: ChargeGroup[] = chargeGroupsData?.getChargeGroups || [];

  const { data: chargeTypesData, loading } = useGetChargeTypesListQuery({
    variables: {
      applicability,
      chargeGroupIds: getChargeGroupIds(searchableChargeTypes, chargeGroupsList)
    },
    skip: !chargeGroupsData
  });

  const chargeTypesList = (chargeTypesData?.getAllCharges || []) as ChargeType[];

  return (
    <FormField {...props}>
      {(field: { error: boolean; value: Nullable<ChargeType> }, form: FormInstance<unknown>) => {
        const handleBlur = (): void => {
          form.setFieldTouched(name, true);
        };

        const handleChange = (chargeType: Nullable<ChargeType>): void => form.setFieldValue(name, chargeType);

        const selectedChargeTypeId: Optional<string> = typeof field?.value === 'string' ? field.value : field?.value?.id;
        const selectedChargeType: Optional<ChargeType> = selectedChargeTypeId
          ? getChargeType(chargeTypesList, selectedChargeTypeId)
          : null;

        return (
          <Select
            hasError={field.error}
            isLoading={loading}
            name={name}
            onBlur={handleBlur}
            onChange={onChange || handleChange}
            options={prepareOptions(chargeTypesList)}
            renderMenuInPortal={true}
            value={selectedChargeType}
          />
        );
      }}
    </FormField>
  );
};

export default FormChargeTypeSelect;
