import { groupBy } from 'lodash';

import type { CargoLot, OrderLot } from './types';

export const prepareOrders = (lots: OrderLot[]): [string, CargoLot[]][] => {
  if (!lots) {
    return [];
  }

  const data = lots.map(
    ({ id, cargoReadyDate, orderedLineItem, purchaseOrder, quantityFulfilled, stages, estimatedDeliveryDate }) => {
      return {
        id,
        orderId: purchaseOrder?.id,
        orderNumber: purchaseOrder?.orderReferenceNumber,
        orderDate: purchaseOrder?.orderDate,
        quantityFulfilled,
        quantityOrdered: orderedLineItem?.quantityOrdered,
        cargoReadyDate,
        product: orderedLineItem ? orderedLineItem.product : null,
        progressStatus: stages ? stages[stages.length - 1] : null,
        estimatedDeliveryDate
      };
    }
  );

  return Object.entries(groupBy(data, 'orderNumber'));
};
