import type { FC, ReactNode } from 'react';

import { Headline } from '../../Typography';

interface Props {
  children?: ReactNode;
  className?: string;
  title?: string;
}

const InlineEmptyState: FC<Props> = ({ children, className = '', title }) => {
  return (
    <div className={`text-grey-base text-sm text-left leading-relaxed ${className}`} data-testid="inline-empty-state">
      {title && <Headline level={4}>{title}</Headline>}
      {children}
    </div>
  );
};

export default InlineEmptyState;
export type { Props as InlineEmptyStateProps };
