import type { FC, ReactNode } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button } from '@zen/DesignSystem';
import RateCardNotes from '@zen/RateCards/RateCardNotes';
import TermsAndConditionsLink from '@zen/RateCards/TermsAndConditionsLink';
import { rateCardRoutes } from '@zen/Routes';

import FreightChargeTable from '../FreightCharges/FreightChargeTable';
import HaulageChargeTable from '../HaulageCharges/HaulageChargeTable';
import OtherChargeTable from '../OtherCharges/OtherChargeTable';
import PortChargeTable from '../PortCharges/PortChargeTable';
import RateCardActions from '../RateCardActions';
import { RateCardProvider } from '../RateCardContext';
import type { RateCardCharge, RateCardReducerState } from '../reducer';
import { getChargesClassification } from './helpers';
import { useRateCardDetails } from './hooks';
import RateCardOverviewDetails from './RateCardOverviewDetails';
import type { RateCard } from './types';

const RateCardDetails: FC = () => {
  const { data, loading, error } = useRateCardDetails();

  return (
    <QueryHandler data={data?.getRateCard?.rateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        const rateCardDetails: RateCardReducerState = getChargesClassification(rateCard);

        const {
          cargoType,
          destinationCharges,
          destinationHaulageCharges,
          freightCharges,
          modeOfTransport,
          name,
          note,
          originCharges,
          originHaulageCharges,
          otherCharges,
          rateCardId
        } = rateCardDetails;

        const preparePortCharges = (portCharges: RateCardCharge[]): RateCardCharge[] =>
          portCharges?.filter((portCharge) => !portCharge.defaultChargeHidden) || [];

        const originPortCharges: RateCardCharge[] = preparePortCharges(originCharges);
        const destinationPortCharges: RateCardCharge[] = preparePortCharges(destinationCharges);

        return (
          <RateCardProvider cargoType={cargoType} isEditable={false} modeOfTransport={modeOfTransport}>
            <RateCardActions>
              {({ handleEdit }) => {
                const renderActionsButtons = (): ReactNode => {
                  return (
                    <Button iconLeft="zicon-edit" onClick={() => handleEdit(rateCardId || '')}>
                      Edit
                    </Button>
                  );
                };

                return (
                  <Page
                    actionButtons={renderActionsButtons()}
                    defaultBackUrl={rateCardRoutes.index.getUrl()}
                    title={name || 'Rate card details'}
                  >
                    <div className="flex flex-col space-y-6">
                      <RateCardOverviewDetails rateCard={rateCardDetails} />
                      <FreightChargeTable charges={freightCharges} />
                      <HaulageChargeTable charges={originHaulageCharges} type="origin" />
                      <PortChargeTable charges={originPortCharges} type="origin" />
                      <PortChargeTable charges={destinationPortCharges} type="destination" />
                      <HaulageChargeTable charges={destinationHaulageCharges} type="destination" />
                      <OtherChargeTable charges={otherCharges} />
                      <RateCardNotes editable={false} value={note || ''} />
                      <TermsAndConditionsLink />
                    </div>
                  </Page>
                );
              }}
            </RateCardActions>
          </RateCardProvider>
        );
      }}
    </QueryHandler>
  );
};

export default RateCardDetails;
