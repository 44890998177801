import type { Env } from '@zen/types';

interface StaticConfig {
  activeStorageDirectUploadsPath: string;
  applicationVersion: string;
  auth0Audience: string;
  auth0ClientId: string;
  auth0Domain: string;
  environment: Env;
  environmentName: string;
  googleMapsKey: string;
  graphqlUrl: string;
  growthBookApiHost: string;
  growthBookClientKey: string;
  openTelemetryTracesUrl: string;
  subscriptionsUrl: string;
  unproxiedGraphqlUrl: string;
  unproxiedPublicGraphqlUrl: string;
}
declare global {
  interface Window {
    zencargoStaticConfig?: StaticConfig;
  }
}

const staticConfig = (): Readonly<StaticConfig> => {
  if (!window.zencargoStaticConfig) {
    throw new Error('Missing "zencargoStaticConfig" on window');
  }

  return window.zencargoStaticConfig;
};

export default staticConfig();
