export const getDialogConfig = (isProfileCompleted: boolean, memberName: string) => {
  if (isProfileCompleted) {
    return {
      header: `Delete ${memberName}?`,
      message: `Access to the platform for this member will be revoked permanently. Any existing references between bookings and ${memberName} will be retained. This action cannot be undone.`,
      confirmLabel: 'Delete',
      successMessage: 'Member was successfully deleted.',
      errorMessage: 'Member could not be deleted.'
    };
  }

  return {
    header: 'Revoke invitation?',
    message: 'Are you sure you want to revoke the invitation? This action will cancel their invite to join the platform.',
    confirmLabel: 'Revoke invite',
    successMessage: 'Invitation was successfully revoked.',
    errorMessage: 'Invitation could not be revoked.'
  };
};
