import type { TableColumn } from '@zen/DesignSystem';
import type { FlatShipment } from '@zen/Shipments';
import { getColumns as getShipmentsColumns } from '@zen/Shipments/TableView/tableConfiguration';

export const getColumns = (pathname: string): TableColumn<FlatShipment>[] => [
  ...getShipmentsColumns({
    capabilities: { canViewDivision: true },
    pathname
  })
];
