import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';

interface Props {
  onApprove?: () => void;
  onReject?: () => void;
}

const QuoteOptionButtons: FC<Props> = ({ onApprove, onReject }) => {
  return (
    <div className="flex space-x-2" data-testid="action-buttons">
      <Button className="fs-af-reject-option" onClick={onReject} variant="secondary">
        Reject
      </Button>
      <Button className="fs-af-accept-quote-option" onClick={onApprove}>
        Approve
      </Button>
    </div>
  );
};

export default QuoteOptionButtons;
