import { UsageContext } from '../../AccountLocation/types';
import type { AccountLocationsFilterItem } from './types';

const buildFilters = (companies: string[]): AccountLocationsFilterItem[] => {
  return [
    {
      title: 'Company',
      key: 'companies',
      props: {
        options: companies.map((company) => ({
          value: company,
          label: company
        })),
        selectAllOption: true
      },
      componentType: 'multi-select'
    },
    {
      title: 'HQ addresses only',
      key: 'hqOnly',
      props: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]
      },
      componentType: 'select'
    },
    {
      title: 'Include archived',
      key: 'includeArchived',
      props: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]
      },
      componentType: 'select'
    },
    {
      title: 'Usage',
      key: 'usageContexts',
      props: {
        options: [
          { value: UsageContext.BOTH, label: 'Both' },
          { value: UsageContext.SHIP_FROM, label: 'Send shipments' },
          { value: UsageContext.SHIP_TO, label: 'Receive shipments' }
        ],
        selectAllOption: true
      },
      componentType: 'multi-select'
    }
  ];
};

export { buildFilters };
