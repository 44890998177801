import { Currency, IncotermsValue } from '@zen/types';

import { OriginDestinationChargeTypeEnum } from '../../ChargesCSVUpload/types';
import type { CreateCentralPortChargeInput } from '../../UploadCharges/types';
import { UploadChargesDocument } from '..';

const defaultInput: CreateCentralPortChargeInput = {
  chargeTypeId: '2f825d22-b053-4d74-a11a-c137bb95f280',
  incoterms: [IncotermsValue.FOB, IncotermsValue.EXWORKS],
  portChargeType: OriginDestinationChargeTypeEnum.DESTINATION,
  port: 'GBFXT',
  chargeValue: 185,
  currency: Currency.GBP
};

export const mockUploadCharges = (input: CreateCentralPortChargeInput = defaultInput) => ({
  request: {
    query: UploadChargesDocument,
    variables: {
      input: {
        portCharges: [input]
      }
    }
  },
  result: jest.fn(() => ({
    data: {
      uploadPortCharges: {
        errors: []
      }
    }
  }))
});
