import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RejectQuoteOptionReasonMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ProvideQuoteOptionRejectionReasonInput;
}>;

export type RejectQuoteOptionReasonMutationResult = { __typename?: 'Mutation' } & {
  bookingProvideQuoteOptionRejectionReason?: GraphQLTypes.Maybe<
    { __typename?: 'ProvideQuoteOptionRejectionReasonPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<
        { __typename?: 'QuoteOption' } & Pick<
          GraphQLTypes.QuoteOption,
          | 'id'
          | 'zencargoReference'
          | 'quoteReference'
          | 'accrualId'
          | 'description'
          | 'state'
          | 'reasonDescription'
          | 'rejectionReason'
        > & {
            total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
            expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
          }
      >;
    }
  >;
};

export const RejectQuoteOptionReasonDocument = /* #__PURE__ */ gql`
  mutation rejectQuoteOptionReason($input: ProvideQuoteOptionRejectionReasonInput!) {
    bookingProvideQuoteOptionRejectionReason(input: $input) {
      quoteOption {
        id
        zencargoReference
        quoteReference
        accrualId
        description
        state
        total {
          value
          currency
        }
        expiryDate {
          date
        }
        reasonDescription
        rejectionReason
      }
    }
  }
`;

/**
 * __useRejectQuoteOptionReasonMutation__
 *
 * To run a mutation, you first call `useRejectQuoteOptionReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectQuoteOptionReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectQuoteOptionReasonMutation, { data, loading, error }] = useRejectQuoteOptionReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRejectQuoteOptionReasonMutation(
  baseOptions?: Apollo.MutationHookOptions<RejectQuoteOptionReasonMutationResult, RejectQuoteOptionReasonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RejectQuoteOptionReasonMutationResult, RejectQuoteOptionReasonMutationVariables>(
    RejectQuoteOptionReasonDocument,
    options
  );
}
export type RejectQuoteOptionReasonMutationHookResult = ReturnType<typeof useRejectQuoteOptionReasonMutation>;
