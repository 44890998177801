import { FC, useState } from 'react';

import EditableContentContainer from '@zen/Components/EditableContentContainer';
import { Modal } from '@zen/DesignSystem';

import AccountDetailsItem from '../../components/AccountDetailsItem';
import UpdateLocationName from '../UpdateLocationName';

interface Props {
  canEdit: boolean;
  locationId: string;
  name: string;
}

const LocationName: FC<Props> = ({ canEdit, locationId, name }) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update details">
        <UpdateLocationName initialValues={{ name }} locationId={locationId} onCancel={toggleModal} onSuccess={toggleModal} />
      </Modal>
      <EditableContentContainer heading="Details" onEdit={canEdit ? toggleModal : undefined}>
        <AccountDetailsItem fullRow={true} name="Name" value={name} />
      </EditableContentContainer>
    </>
  );
};

export default LocationName;
