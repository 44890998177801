import { useEffect } from 'react';

import { PageInfo, PageSize } from '@zen/types';
import { useQueryParams } from '@zen/utils/QueryParams';

import { useComponentDidMount } from '../useComponentDidMount';
import { getNextPageVariables, getPreviousPageVariables } from './helpers';
import type { PaginatedQueryResult, PaginatedVariables, PaginationQueryHookVariables, UseQueryHookType } from './types';
import usePaginatedQuery from './usePaginatedQuery';

const useUrlPagination = <Result, Variables extends PaginatedVariables, NodeType>(
  useQueryHook: UseQueryHookType<Result, Variables>,
  responsePath: string,
  variables: PaginationQueryHookVariables<Variables>,
  options: object = {},
  limit: number = PageSize.TWENTY,
  paginationKey: string = 'page'
): PaginatedQueryResult<Result, Variables, NodeType> => {
  const { queryParams, setQueryParams } = useQueryParams<PaginatedVariables>(paginationKey, { first: limit });
  const paginationParams = {
    after: queryParams.after,
    before: queryParams.before,
    ...(queryParams.first ? { first: +queryParams.first } : {}),
    ...(queryParams.last ? { last: +queryParams.last } : {})
  };

  const isComponentMounted: boolean = useComponentDidMount();
  const { fetchMore, pageInfo, refetch, ...queryResults } = usePaginatedQuery<Result, Variables, NodeType>(
    useQueryHook,
    responsePath,
    { ...variables, ...paginationParams },
    options
  );

  useEffect(() => {
    if (isComponentMounted) {
      setQueryParams({ first: limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit]);

  const { startCursor, endCursor, hasNextPage, hasPreviousPage } = pageInfo;

  const handleNext = (): void => {
    setQueryParams(getNextPageVariables(limit, endCursor));
  };

  // Stay on the same paginated page, but refresh results on that page
  const handlePageRefetch = () => {
    const pageHasOnlyOneItem: boolean = startCursor === endCursor;

    // If the query params don't change the page won't refresh. We need to force the refresh by unsetting and resetting them.
    setQueryParams({});
    setQueryParams(pageHasOnlyOneItem && !hasPreviousPage ? {} : getPreviousPageVariables(limit, endCursor));
  };

  const handlePrevious = (): void => {
    setQueryParams(getPreviousPageVariables(limit, startCursor));
  };

  const paginationInfo: PageInfo = { hasNextPage, hasPreviousPage, onNext: handleNext, onPrevious: handlePrevious };

  return { ...queryResults, paginationInfo, pageRefresh: handlePageRefetch, refetch };
};

export default useUrlPagination;
