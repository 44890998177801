import type { ExecutionResult } from 'graphql';
import { isEmpty } from 'lodash';
import { useState } from 'react';

import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { Undefinable } from '../typescript';

interface FinalisedErrors {
  errorIndexes: number[];
  errors: unknown[];
}

const finaliseErrors = (results: IOkOrErrorResult[]): FinalisedErrors => {
  const errors: unknown[] = [];
  const itemIndexes: number[] = [];

  results.forEach(({ ok, error }, index) => {
    if (!isEmpty(error)) {
      errors.push(error);
      itemIndexes.push(index);
    } else {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      const mutationKey = Object.keys(ok)[0];
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const dataErrors = ok[mutationKey]?.errors;

      if (dataErrors && dataErrors.length !== 0) {
        itemIndexes.push(index);
        errors.push(dataErrors);
      }
    }
  });

  return { errors, errorIndexes: itemIndexes };
};

export const useMutationIteration = <T>(
  items: T[],
  onSuccess?: (successResult: string) => void,
  onError?: (errorItems: T[]) => void
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const getMutationResults = (mutate: (item: T) => Promise<ExecutionResult<unknown>>): Promise<IOkOrErrorResult[]> => {
    const results = items.map(async (item) => {
      try {
        const result = await mutate(item);

        return { ok: result.data, error: result.errors };
      } catch (err) {
        return { ok: null, error: err };
      }
    });

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<({ ok: unknown; error: readonly Grap... Remove this comment to see the full error message
    return Promise.all(results);
  };

  const handleSuccess = (successResult: string) => {
    if (onSuccess) {
      onSuccess(successResult);
    }
  };

  const handleError = (errorItems: T[]) => {
    if (onError) {
      onError(errorItems);
    }
  };

  const handleMutation = async (mutate: (item: T) => Promise<ExecutionResult<unknown>>) => {
    setLoading(true);
    const results = await getMutationResults(mutate);

    const { errors, errorIndexes } = finaliseErrors(results);

    const errorItems: T[] = items.filter((_, i) => errorIndexes.includes(i));

    if (errorItems.length > 0) {
      handleError(errorItems);
    }

    if (errorItems.length !== items.length) {
      const numberOfItems = items.length - errorItems.length;
      const successResult = numberOfItems === items.length ? 'All' : `${numberOfItems}`;

      handleSuccess(successResult);
    }

    setLoading(false);

    const error: Undefinable<unknown[]> = isEmpty(errors) ? undefined : errors;

    return Promise.resolve({ ok: results[0].ok, error });
  };

  return { handleMutation, loading };
};
