import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargeBasisQueryVariables = GraphQLTypes.Exact<{
  modeOfTransport?: GraphQLTypes.InputMaybe<GraphQLTypes.ModeOfTransport>;
  cargoType?: GraphQLTypes.InputMaybe<GraphQLTypes.CargoModeEnum>;
  chargeBasisGroup: GraphQLTypes.ChargeBasisGroup;
}>;

export type GetChargeBasisQueryResult = { __typename?: 'Query' } & {
  getChargeBasis: Array<GraphQLTypes.Maybe<{ __typename?: 'ChargeBasis' } & Pick<GraphQLTypes.ChargeBasis, 'id' | 'name'>>>;
};

export const GetChargeBasisDocument = /* #__PURE__ */ gql`
  query getChargeBasis($modeOfTransport: ModeOfTransport, $cargoType: CargoModeEnum, $chargeBasisGroup: ChargeBasisGroup!) {
    getChargeBasis(modeOfTransport: $modeOfTransport, cargoType: $cargoType, chargeBasisGroup: $chargeBasisGroup) {
      id
      name
    }
  }
`;

/**
 * __useGetChargeBasisQuery__
 *
 * To run a query within a React component, call `useGetChargeBasisQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeBasisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeBasisQuery({
 *   variables: {
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *      chargeBasisGroup: // value for 'chargeBasisGroup'
 *   },
 * });
 */
export function useGetChargeBasisQuery(
  baseOptions: Apollo.QueryHookOptions<GetChargeBasisQueryResult, GetChargeBasisQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargeBasisQueryResult, GetChargeBasisQueryVariables>(GetChargeBasisDocument, options);
}
export function useGetChargeBasisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargeBasisQueryResult, GetChargeBasisQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargeBasisQueryResult, GetChargeBasisQueryVariables>(GetChargeBasisDocument, options);
}
export type GetChargeBasisQueryHookResult = ReturnType<typeof useGetChargeBasisQuery>;
export type GetChargeBasisLazyQueryHookResult = ReturnType<typeof useGetChargeBasisLazyQuery>;
