import cx from 'classnames';
import type { FC } from 'react';

import type { Optional } from '@zen/utils/typescript';

export interface Props {
  className?: string;
  firstName?: string;
  imageUrl?: string;
  size?: 'small' | 'normal';
}

const getFirstChar = (str: Optional<string>) => str && str[0].toUpperCase();

const Avatar: FC<Props> = ({ className, imageUrl, firstName, size = 'normal' }) => {
  const imagePresent: boolean = !!imageUrl;

  const classNames: string = cx(
    'relative flex justify-center items-center rounded-full text-white border',
    {
      'w-8 h-8 text-sm': size === 'normal',
      'w-6 h-6 text-xs': size === 'small',
      'border-dashed': !imagePresent && !firstName,
      'bg-grey-dark border-solid border-grey-dark': !!firstName && !imagePresent,
      'bg-white border-dashed border-grey-base': !firstName && !imagePresent,
      'border-transparent': imagePresent
    },
    className
  );

  return (
    <div className={classNames} data-testid="avatar">
      {imagePresent && <img alt={firstName} className="w-auto h-full rounded-full" src={imageUrl} />}
      {!imagePresent && getFirstChar(firstName)}
    </div>
  );
};

export type { Props as AvatarProps };

export default Avatar;
