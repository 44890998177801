import type { ReactNode } from 'react';
import InfiniteScrollComponent from 'react-infinite-scroller';

import { Loading } from '@zen/DesignSystem';

interface InfiniteScrollProps {
  children: ReactNode;
  hasMoreResults: boolean;
  isReverse?: boolean;
  loader?: ReactNode;
  onLoad: () => void;
  useWindow?: boolean;
}

const InfiniteScroll = ({
  children,
  hasMoreResults,
  onLoad,
  useWindow = true,
  loader,
  isReverse = false
}: InfiniteScrollProps) => {
  const onLoadHandler = hasMoreResults ? onLoad : () => {};

  return (
    <InfiniteScrollComponent
      hasMore={hasMoreResults}
      initialLoad={false}
      isReverse={isReverse}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      loader={loader || <Loading key="spinner" />}
      loadMore={() => onLoadHandler()}
      useWindow={useWindow}
    >
      {children}
    </InfiniteScrollComponent>
  );
};

export default InfiniteScroll;
