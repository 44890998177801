import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RailwayCompanyQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type RailwayCompanyQueryResult = { __typename?: 'Query' } & {
  railwayCompany?: GraphQLTypes.Maybe<
    { __typename?: 'RailwayCompany' } & Pick<GraphQLTypes.RailwayCompany, 'id' | 'railwayCompany'>
  >;
};

export const RailwayCompanyDocument = /* #__PURE__ */ gql`
  query railwayCompany($id: String!) {
    railwayCompany(id: $id) {
      id
      railwayCompany
    }
  }
`;

/**
 * __useRailwayCompanyQuery__
 *
 * To run a query within a React component, call `useRailwayCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRailwayCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRailwayCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRailwayCompanyQuery(
  baseOptions: Apollo.QueryHookOptions<RailwayCompanyQueryResult, RailwayCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RailwayCompanyQueryResult, RailwayCompanyQueryVariables>(RailwayCompanyDocument, options);
}
export function useRailwayCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RailwayCompanyQueryResult, RailwayCompanyQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RailwayCompanyQueryResult, RailwayCompanyQueryVariables>(RailwayCompanyDocument, options);
}
export type RailwayCompanyQueryHookResult = ReturnType<typeof useRailwayCompanyQuery>;
export type RailwayCompanyLazyQueryHookResult = ReturnType<typeof useRailwayCompanyLazyQuery>;
