import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingParentAttribute } from '@zen/utils/tracking';

interface Props {
  className?: string;
  isDisabled?: boolean;
  onClick?: () => void;
}

export const displayName = 'Design System/Gateway';

const Gateway: FC<Props> = ({ isDisabled = false, onClick, children, className }) => {
  const { trackEvent } = useTracking();

  const hoverClasses: string = 'cursor-pointer transition-all duration-200 group hover:border-azure-base active:bg-azure-lighter';

  const classNames = cx(
    {
      [hoverClasses]: onClick,
      'bg-white': !isDisabled,
      'bg-grey-lightest': isDisabled
    },
    'rounded border border-solid border-grey-light',
    className
  );

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.();
  };

  return (
    <div className={classNames} data-testid="gateway" onClick={handleClick} {...createTrackingParentAttribute('gateway')}>
      {children}
    </div>
  );
};

export type { Props as GatewayProps };

export default Gateway;
