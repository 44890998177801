import { createContext } from 'react';

import type { OrderBasketLot, OrderListViewItem, OrderListViewLot } from '@zen/Orders/types';
import type { Customer } from '@zen/utils/hooks/useCustomers';
import type { Optional } from '@zen/utils/typescript';

interface InitialContext {
  addItems: (items: OrderListViewLot[], addToInitialState?: boolean) => void;
  customer?: Optional<Customer>;
  hasItems?: boolean;
  initialItemIds?: string[];
  initializeOrderLots: (orderLots: OrderListViewLot[]) => void;
  initializedOrderIds?: string[];
  isBasketMode: boolean;
  isOrderPartiallySelected: (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>) => boolean;
  isOrderSelected: (order: OrderListViewItem, orderLots: Optional<OrderListViewLot[]>) => boolean;
  isSelected: (id: string) => boolean;
  items: OrderBasketLot[];
  removeAllItems?: () => void;
  removeItems: (ids: string[]) => void;
  resetBasket?: () => void;
  setCustomer?: (customer: Optional<Customer>) => void;
  updateItem?: (id: string, values: Partial<OrderBasketLot>) => void;
}

const initialContext = {
  addItems: () => {},
  customer: null,
  hasItems: false,
  initialItemIds: [],
  initializedOrderIds: [],
  initializeOrderLots: () => {},
  isBasketMode: false,
  items: [],
  isSelected: () => false,
  isOrderPartiallySelected: () => false,
  isOrderSelected: () => false,
  resetBasket: () => {},
  removeItems: () => {},
  removeAllItems: () => {},
  setCustomer: () => {},
  updateItem: () => {}
};

const BasketContext = createContext<InitialContext>(initialContext);

export default BasketContext;
