import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetRateCardsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  cargoType?: GraphQLTypes.InputMaybe<GraphQLTypes.CargoModeEnum>;
  customerId: GraphQLTypes.Scalars['String'];
  destinationLocationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  modeOfTransport?: GraphQLTypes.InputMaybe<GraphQLTypes.ModeOfTransport>;
  originLocationId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  validOn?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Date']>;
}>;

export type GetRateCardsQueryResult = { __typename?: 'Query' } & {
  getRateCards?: GraphQLTypes.Maybe<
    { __typename?: 'RateCardSummaryConnection' } & Pick<GraphQLTypes.RateCardSummaryConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'RateCardSummary' } & Pick<
                GraphQLTypes.RateCardSummary,
                'cargoType' | 'endDate' | 'modeOfTransport' | 'name' | 'rateCardId' | 'startDate'
              >
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetRateCardsDocument = /* #__PURE__ */ gql`
  query getRateCards(
    $after: String
    $before: String
    $cargoType: CargoModeEnum
    $customerId: String!
    $destinationLocationId: String
    $first: Int
    $last: Int
    $modeOfTransport: ModeOfTransport
    $originLocationId: String
    $validOn: Date
  ) {
    getRateCards(
      after: $after
      before: $before
      cargoType: $cargoType
      customerId: $customerId
      destinationLocationId: $destinationLocationId
      first: $first
      last: $last
      modeOfTransport: $modeOfTransport
      originLocationId: $originLocationId
      validOn: $validOn
    ) {
      nodes {
        cargoType
        endDate
        modeOfTransport
        name
        rateCardId
        startDate
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetRateCardsQuery__
 *
 * To run a query within a React component, call `useGetRateCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRateCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRateCardsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      cargoType: // value for 'cargoType'
 *      customerId: // value for 'customerId'
 *      destinationLocationId: // value for 'destinationLocationId'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      originLocationId: // value for 'originLocationId'
 *      validOn: // value for 'validOn'
 *   },
 * });
 */
export function useGetRateCardsQuery(baseOptions: Apollo.QueryHookOptions<GetRateCardsQueryResult, GetRateCardsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetRateCardsQueryResult, GetRateCardsQueryVariables>(GetRateCardsDocument, options);
}
export function useGetRateCardsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRateCardsQueryResult, GetRateCardsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetRateCardsQueryResult, GetRateCardsQueryVariables>(GetRateCardsDocument, options);
}
export type GetRateCardsQueryHookResult = ReturnType<typeof useGetRateCardsQuery>;
export type GetRateCardsLazyQueryHookResult = ReturnType<typeof useGetRateCardsLazyQuery>;
