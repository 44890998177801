import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ControlTowerEnabledQueryVariables = GraphQLTypes.Exact<{
  uuid: GraphQLTypes.Scalars['String'];
}>;

export type ControlTowerEnabledQueryResult = { __typename?: 'Query' } & {
  legacyAccount?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyAccount' } & {
      controlTower: { __typename?: 'ControlTower' } & Pick<GraphQLTypes.ControlTower, 'enabled'>;
    }
  >;
};

export const ControlTowerEnabledDocument = /* #__PURE__ */ gql`
  query controlTowerEnabled($uuid: String!) {
    legacyAccount(uuid: $uuid) {
      controlTower {
        enabled
      }
    }
  }
`;

/**
 * __useControlTowerEnabledQuery__
 *
 * To run a query within a React component, call `useControlTowerEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useControlTowerEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useControlTowerEnabledQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useControlTowerEnabledQuery(
  baseOptions: Apollo.QueryHookOptions<ControlTowerEnabledQueryResult, ControlTowerEnabledQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ControlTowerEnabledQueryResult, ControlTowerEnabledQueryVariables>(ControlTowerEnabledDocument, options);
}
export function useControlTowerEnabledLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ControlTowerEnabledQueryResult, ControlTowerEnabledQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ControlTowerEnabledQueryResult, ControlTowerEnabledQueryVariables>(
    ControlTowerEnabledDocument,
    options
  );
}
export type ControlTowerEnabledQueryHookResult = ReturnType<typeof useControlTowerEnabledQuery>;
export type ControlTowerEnabledLazyQueryHookResult = ReturnType<typeof useControlTowerEnabledLazyQuery>;
