import { useGetAccountUserGroupsQuery } from '../AccountDetails/graphql';
import type { GetAccountUserGroupsQueryResult } from '../AccountDetails/graphql/getAccountUserGroups.generated';
import type { AccountUserGroup } from '../AccountDetails/types';

type DefaultUserGroupResult = Omit<GetAccountUserGroupsQueryResult, 'accountUserGroups'> & { userGroup: string | null };

const useDefaultUserGroup = (accountId: string): DefaultUserGroupResult => {
  const { data, ...rest } = useGetAccountUserGroupsQuery({ variables: { accountId } });

  const defaultUserGroup = data?.accountUserGroups?.filter((userGroup: AccountUserGroup) => {
    return userGroup.isDefaultRole;
  });

  return {
    ...rest,
    userGroup: defaultUserGroup?.[0]?.userGroupId || null
  };
};

export { useDefaultUserGroup };
