import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentsQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  groupBy: GraphQLTypes.ShipmentsTableCargoGroupsGroupByColumn;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  sortBy?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.ShipmentsTableCargoGroupsSortByInput> | GraphQLTypes.ShipmentsTableCargoGroupsSortByInput
  >;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableCargoGroupsFiltersInput>;
}>;

export type ShipmentsQueryResult = { __typename?: 'Query' } & {
  shipmentsTableCargoGroups?: GraphQLTypes.Maybe<
    { __typename?: 'ShipmentsTableCargoGroupsConnection' } & Pick<
      GraphQLTypes.ShipmentsTableCargoGroupsConnection,
      'totalCount'
    > & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ShipmentsTableCargoGroup' } & Pick<
                GraphQLTypes.ShipmentsTableCargoGroup,
                'groupId' | 'totalHits' | 'totalInGroup'
              > & {
                  cargos: Array<
                    { __typename?: 'ShipmentsTableCargo' } & Pick<
                      GraphQLTypes.ShipmentsTableCargo,
                      | 'cargoId'
                      | 'cargoType'
                      | 'carrierScac'
                      | 'cargoReadyDate'
                      | 'clientReference'
                      | 'consolidationReference'
                      | 'containerNumber'
                      | 'currentMilestone'
                      | 'customsOnly'
                      | 'incoterms'
                      | 'modeOfTransport'
                      | 'quantity'
                      | 'zencargoReference'
                      | 'masterBillOfLading'
                      | 'vehiclePlate'
                      | 'masterAirwayBill'
                    > & {
                        arrivalDate?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDateWithCertainty' } & Pick<
                            GraphQLTypes.ShipmentsTableDateWithCertainty,
                            'date' | 'dateCertainty'
                          >
                        >;
                        collectionDate?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDateWithCertainty' } & Pick<
                            GraphQLTypes.ShipmentsTableDateWithCertainty,
                            'date' | 'dateCertainty'
                          >
                        >;
                        collectionLocation?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'> & {
                              networksOrgLoc?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                    label?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                    >;
                                  }
                              >;
                            }
                        >;
                        deliveryDate?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDateWithCertainty' } & Pick<
                            GraphQLTypes.ShipmentsTableDateWithCertainty,
                            'date' | 'dateCertainty'
                          >
                        >;
                        deliveryLocation?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'> & {
                              networksOrgLoc?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                    label?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                    >;
                                  }
                              >;
                            }
                        >;
                        deliveryStatus?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDelayWithStatus' } & Pick<
                            GraphQLTypes.ShipmentsTableDelayWithStatus,
                            'delayInDays' | 'status'
                          >
                        >;
                        departureStatus?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDelayWithStatus' } & Pick<
                            GraphQLTypes.ShipmentsTableDelayWithStatus,
                            'delayInDays' | 'status'
                          >
                        >;
                        arrivalStatus?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDelayWithStatus' } & Pick<
                            GraphQLTypes.ShipmentsTableDelayWithStatus,
                            'delayInDays' | 'status'
                          >
                        >;
                        departureDate?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableDateWithCertainty' } & Pick<
                            GraphQLTypes.ShipmentsTableDateWithCertainty,
                            'date' | 'dateCertainty'
                          >
                        >;
                        consignee?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'> & {
                              networksOrgLoc?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                    label?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                    >;
                                  }
                              >;
                            }
                        >;
                        consignor?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'> & {
                              networksOrgLoc?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                                    label?: GraphQLTypes.Maybe<
                                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                                    >;
                                  }
                              >;
                            }
                        >;
                        portOfDestination?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'>
                        >;
                        portOfLoading?: GraphQLTypes.Maybe<
                          { __typename?: 'ShipmentsTableLocation' } & Pick<GraphQLTypes.ShipmentsTableLocation, 'id' | 'name'>
                        >;
                        cargoPurchaseOrders: Array<
                          { __typename?: 'ShipmentsTablePurchaseOrder' } & Pick<
                            GraphQLTypes.ShipmentsTablePurchaseOrder,
                            'id' | 'referenceNumber'
                          >
                        >;
                        bookingPurchaseOrders: Array<
                          { __typename?: 'ShipmentsTablePurchaseOrder' } & Pick<
                            GraphQLTypes.ShipmentsTablePurchaseOrder,
                            'id' | 'referenceNumber'
                          >
                        >;
                      }
                  >;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const ShipmentsDocument = /* #__PURE__ */ gql`
  query shipments(
    $accountIds: [String!]!
    $groupBy: ShipmentsTableCargoGroupsGroupByColumn!
    $first: Int
    $last: Int
    $after: String
    $before: String
    $searchTerm: String
    $sortBy: [ShipmentsTableCargoGroupsSortByInput!]
    $filterBy: ShipmentsTableCargoGroupsFiltersInput
  ) {
    shipmentsTableCargoGroups(
      accountIds: $accountIds
      groupBy: $groupBy
      first: $first
      last: $last
      after: $after
      before: $before
      searchTerm: $searchTerm
      sortBy: $sortBy
      filterBy: $filterBy
    ) {
      nodes {
        cargos {
          arrivalDate {
            date
            dateCertainty
          }
          cargoId
          cargoType
          carrierScac
          cargoReadyDate
          clientReference
          collectionDate {
            date
            dateCertainty
          }
          collectionLocation {
            id
            name
            networksOrgLoc {
              id
              label {
                long
              }
            }
          }
          consolidationReference
          containerNumber
          currentMilestone
          customsOnly
          deliveryDate {
            date
            dateCertainty
          }
          deliveryLocation {
            id
            name
            networksOrgLoc {
              id
              label {
                long
              }
            }
          }
          deliveryStatus {
            delayInDays
            status
          }
          departureStatus {
            delayInDays
            status
          }
          arrivalStatus {
            delayInDays
            status
          }
          departureDate {
            date
            dateCertainty
          }
          consignee {
            id
            name
            networksOrgLoc {
              id
              label {
                long
              }
            }
          }
          consignor {
            id
            name
            networksOrgLoc {
              id
              label {
                long
              }
            }
          }
          incoterms
          modeOfTransport
          portOfDestination {
            id
            name
          }
          portOfLoading {
            id
            name
          }
          quantity
          zencargoReference
          masterBillOfLading
          vehiclePlate
          masterAirwayBill
          cargoPurchaseOrders {
            id
            referenceNumber
          }
          bookingPurchaseOrders {
            id
            referenceNumber
          }
        }
        groupId
        totalHits
        totalInGroup
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useShipmentsQuery__
 *
 * To run a query within a React component, call `useShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      groupBy: // value for 'groupBy'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      searchTerm: // value for 'searchTerm'
 *      sortBy: // value for 'sortBy'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useShipmentsQuery(baseOptions: Apollo.QueryHookOptions<ShipmentsQueryResult, ShipmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentsQueryResult, ShipmentsQueryVariables>(ShipmentsDocument, options);
}
export function useShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsQueryResult, ShipmentsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentsQueryResult, ShipmentsQueryVariables>(ShipmentsDocument, options);
}
export type ShipmentsQueryHookResult = ReturnType<typeof useShipmentsQuery>;
export type ShipmentsLazyQueryHookResult = ReturnType<typeof useShipmentsLazyQuery>;
