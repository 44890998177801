import type { FC } from 'react';

import type { CargoModeEnum } from '@zen/Booking';
import QueryHandler from '@zen/Components/QueryHandler';
import { TextLink } from '@zen/DesignSystem';
import { EmptyState, RateCardError, RateCardLoader, usePriceCalculation } from '@zen/RateCards';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Optional } from '@zen/utils/typescript';

import { useSummaryRateCardQuery } from './graphql';
import type { ModeOfTransport, SummaryRateCardType } from './types';

interface Props {
  cargoType: CargoModeEnum;
  className?: string;
  destinationPort: Optional<string>;
  isBookingConfirmed: boolean;
  modeOfTransport: ModeOfTransport;
  originPort: Optional<string>;
  zencargoReference: string;
}

const SummaryRateCard: FC<Props> = (props) => {
  const { cargoType, className, destinationPort, isBookingConfirmed, modeOfTransport, originPort, zencargoReference } = props;
  const { accountUuid: accountId } = useAccount();
  const { date } = usePriceCalculation(zencargoReference);

  const hasSailingSchedule: boolean = !!originPort && !!destinationPort;
  const shouldSkipRateCardQuery: boolean = !hasSailingSchedule && !date;
  const { data, loading, error } = useSummaryRateCardQuery({
    variables: { cargoType, modeOfTransport, accountId, originPort, destinationPort, validOn: date },
    skip: shouldSkipRateCardQuery
  });

  const rateCardData: Optional<SummaryRateCardType> = date ? data?.legacyGetRateCards?.nodes?.[0] : null;

  return (
    <div className={className}>
      <QueryHandler
        data={rateCardData}
        error={!!error}
        errorComponent={<RateCardError />}
        isLoading={loading}
        loadingComponent={<RateCardLoader />}
        noResults={
          <EmptyState
            editable={false}
            hasPriceCalculationPoint={!!date}
            hasSailingSchedule={hasSailingSchedule}
            isBookingConfirmed={isBookingConfirmed}
          />
        }
      >
        {(rateCard: SummaryRateCardType) => (
          <TextLink isExternal={true} linkTo={rateCardRoutes.details.getUrl(rateCard.rateCardId)}>
            {rateCard.name}
          </TextLink>
        )}
      </QueryHandler>
    </div>
  );
};

export type { Props as SummaryRateCardProps };

export default SummaryRateCard;
