import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import FormField, { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import ChargeBasisSelect from '../ChargeBasisSelect';
import type { ChargeBasis, ChargeBasisGroup } from '../types';

interface Props extends FormFieldProps {
  chargeBasisGroup: Optional<ChargeBasisGroup>;
  isDisabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
}

const FormChargeBasisSelect: FC<Props> = (props) => {
  const { chargeBasisGroup, isDisabled, ...rest } = props;

  return (
    <FormField {...rest}>
      {(
        field: { error: boolean; name: string; options: Option<string>[]; value: ChargeBasis[] },
        form: FormInstance<unknown>
      ) => {
        const handleBlur = (): void => {
          form.setFieldTouched(field.name, true);
        };

        const handleChange = (value: Nullable<ChargeBasis[]>): void => {
          form.setFieldValue(field.name, value || null);
        };

        const fieldValue: string[] = field.value?.map((value: ChargeBasis) => value.id) || [];

        return (
          <ChargeBasisSelect
            {...field}
            chargeBasisGroup={chargeBasisGroup}
            hasError={field.error}
            isDisabled={isDisabled}
            onBlur={handleBlur}
            onChange={handleChange}
            value={fieldValue}
          />
        );
      }}
    </FormField>
  );
};

export default FormChargeBasisSelect;
