import type { HaulageChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import {
  formatChargeBasis,
  formatChargeType,
  formatCost,
  formatIncoterms,
  formatLocation,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';

import { mapDataToRow } from '../utils';

export const prepareHaulageChargeData = (haulageCharges: HaulageChargePayload[], type: 'origin' | 'destination'): string[][] => {
  return haulageCharges.map((haulageCharge: HaulageChargePayload) => {
    const formattedHaulageCharge: Record<string, string> = getFormattedHaulageCharge(haulageCharge, type);

    return mapDataToRow(formattedHaulageCharge);
  });
};

const getFormattedHaulageCharge = (
  haulageCharge: HaulageChargePayload,
  type: 'origin' | 'destination'
): Record<string, string> => {
  const portField: string = type === 'origin' ? 'originPort' : 'destinationPort';
  const locationField: string = type === 'origin' ? 'originLocation' : 'destinationLocation';
  const { port, chargeType, incoterms, chargeValue, currency, location } = haulageCharge;

  return {
    chargeType: formatChargeType(chargeType),
    [portField]: formatPort(port),
    chargeBasis: formatChargeBasis(chargeType),
    unit: formatUnit(chargeType),
    incoterms: formatIncoterms(incoterms),
    cost: formatCost(chargeValue, currency),
    [locationField]: formatLocation(location)
  };
};
