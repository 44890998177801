import type { FC } from 'react';

import { DatePicker, Popover } from '@zen/DesignSystem';
import { formatDate } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

import type { FilterComponentProps } from '../../types';
import FilterFooter from '../FilterFooter';

type Props = {
  onChange: (value: Nullable<string>) => void;
  value: Nullable<string>;
} & FilterComponentProps;

const FilterDatePicker: FC<Props> = (props) => {
  const { value, onChange, deselectFilter, active, onDelete, children, placement, disabled } = props;

  const handleClear = (): void => {
    onChange(null);
  };

  const handleChange = (date: Nullable<string>) => {
    onChange(date);
    deselectFilter();
  };

  const popover = () => {
    return (
      <div className="divide-y divide-solid divide-grey-lighter" data-testid="filter-date-picker-popover">
        <DatePicker className="border-0" isInline={true} mode="single" onChange={handleChange} selected={value || undefined} />
        <FilterFooter onClear={handleClear} />
      </div>
    );
  };

  const handleVisibilityChange = (visible: boolean): void => {
    if (!visible && !value) {
      onDelete();
    }

    if (!visible) {
      deselectFilter();
    }
  };

  const label: string = value ? formatDate(value) : '';

  return (
    <Popover
      disabled={disabled}
      isVisible={active}
      onVisibilityChange={handleVisibilityChange}
      placement={placement}
      popover={popover}
      popoverClassNames="border border-solid border-grey-lighter overflow-hidden"
      renderInPortal={true}
    >
      {children(label)}
    </Popover>
  );
};

export default FilterDatePicker;
