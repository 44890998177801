import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ContractIdsQueryVariables = GraphQLTypes.Exact<{
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ContractIdsQueryResult = { __typename?: 'Query' } & {
  getContracts?: GraphQLTypes.Maybe<
    { __typename?: 'ContractConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ContractPayload' } & Pick<GraphQLTypes.ContractPayload, 'simplifiedContractId' | 'id'>
          >
        >
      >;
    }
  >;
};

export const ContractIdsDocument = /* #__PURE__ */ gql`
  query contractIds($textContains: String) {
    getContracts(first: 20, textContains: $textContains) {
      nodes {
        simplifiedContractId
        id
      }
    }
  }
`;

/**
 * __useContractIdsQuery__
 *
 * To run a query within a React component, call `useContractIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractIdsQuery({
 *   variables: {
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useContractIdsQuery(baseOptions?: Apollo.QueryHookOptions<ContractIdsQueryResult, ContractIdsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ContractIdsQueryResult, ContractIdsQueryVariables>(ContractIdsDocument, options);
}
export function useContractIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractIdsQueryResult, ContractIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ContractIdsQueryResult, ContractIdsQueryVariables>(ContractIdsDocument, options);
}
export type ContractIdsQueryHookResult = ReturnType<typeof useContractIdsQuery>;
export type ContractIdsLazyQueryHookResult = ReturnType<typeof useContractIdsLazyQuery>;
