import type { Nullable } from '@zen/utils/typescript';

export const mergeObjectTypes = (existing: Nullable<object>, incoming: Nullable<object>) => {
  if (incoming === null) {
    return null;
  }

  if (!existing) {
    return incoming;
  }

  return {
    ...existing,
    ...incoming
  };
};

export const mergeArrayType = <T>(existing: Array<T> = [], incoming: Array<T>) => {
  return incoming;
};
