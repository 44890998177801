import type { FC, ReactNode } from 'react';

import type { CargoItemDetailsType } from '@zen/Cargo';

import { getCargoIconComponent, getCargoLabel } from './cargoTypeImageLabel.helper';

interface Props {
  cargoType: CargoItemDetailsType;
  className?: string;
  isCoreCargo?: boolean;
}

const CargoTypeImage: FC<Props> = ({ className = '', cargoType, isCoreCargo }) => {
  const CargoIconComponent: ReactNode = getCargoIconComponent(cargoType, isCoreCargo);
  const cargoLabel: string = getCargoLabel(cargoType, isCoreCargo);

  return (
    <div aria-label={cargoLabel} className={className} data-testid="cargo-type-image" role="img">
      {CargoIconComponent}
    </div>
  );
};

export default CargoTypeImage;
