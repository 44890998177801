import type { Option } from '@zen/DesignSystem';
import { getFilterOptionArray, getShipmentStatus } from '@zen/Shipments/Filters/helpers';
import type { Account } from '@zen/utils/hooks/useAccounts';

import type { CustomerAdminShipmentFilters, CustomerAdminShipmentFilterVariables } from './types';

export const getDivisionOptions = (accounts: Account[]): Option<string>[] =>
  accounts?.map((account: Account) => ({
    value: account.uuid,
    label: account.registeredCompanyName
  }));

export const prepareFilterVariables = (filters: CustomerAdminShipmentFilters): CustomerAdminShipmentFilterVariables => {
  return {
    accountIds: getFilterOptionArray(filters.accountIds),
    transportModes: getFilterOptionArray(filters.transportModes),
    ...getShipmentStatus(filters.status),
    origins: filters.origins,
    destinations: filters.destinations,
    withinTimeRange: filters.withinTimeRange
  };
};
