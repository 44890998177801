const REQUEST_BOOKING_RELATIVE_INDEX_PATH = 'request-booking';

export const REQUEST_BOOKING_ROUTE_PREFIX = `/dashboard/${REQUEST_BOOKING_RELATIVE_INDEX_PATH}`;

interface RequestBookingRoutes {
  requestBooking: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const requestBookingRoutes: RequestBookingRoutes = {
  requestBooking: {
    path: `${REQUEST_BOOKING_ROUTE_PREFIX}/*`,
    getUrl: () => REQUEST_BOOKING_ROUTE_PREFIX,
    relativePath: `${REQUEST_BOOKING_RELATIVE_INDEX_PATH}/*`
  }
};

export default requestBookingRoutes;
