import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountLegalDetailsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountLegalDetailsInput;
}>;

export type UpdateAccountLegalDetailsMutationResult = { __typename?: 'Mutation' } & {
  updateAccountLegalDetails?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountLegalDetailsPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAccountLegalDetailsDocument = /* #__PURE__ */ gql`
  mutation UpdateAccountLegalDetails($input: UpdateAccountLegalDetailsInput!) {
    updateAccountLegalDetails(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAccountLegalDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountLegalDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountLegalDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountLegalDetailsMutation, { data, loading, error }] = useUpdateAccountLegalDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountLegalDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountLegalDetailsMutationResult, UpdateAccountLegalDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountLegalDetailsMutationResult, UpdateAccountLegalDetailsMutationVariables>(
    UpdateAccountLegalDetailsDocument,
    options
  );
}
export type UpdateAccountLegalDetailsMutationHookResult = ReturnType<typeof useUpdateAccountLegalDetailsMutation>;
