import { DatePicker, Matcher, TooltipConfig } from '@zen/DesignSystem';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';

interface FormFieldType {
  error: string;
  name: string;
  value: string;
}

interface Props extends FormFieldProps {
  disabled?: Matcher;
  isInline?: boolean;
  numberOfMonths?: number;
  onChange?: (value: string) => void;
  tooltip?: TooltipConfig | TooltipConfig[];
}

const FormDatePicker = ({ className, onChange, ...rest }: Props) => {
  return (
    <FormField {...rest}>
      {(field: FormFieldType, form: FormInstance<unknown>) => (
        <DatePicker
          className={className}
          disabled={rest.disabled}
          hasError={!!field.error}
          isInline={rest.isInline}
          mode="single"
          numberOfMonths={rest.numberOfMonths}
          onChange={(value: string) => {
            form.setFieldValue(field.name, value);

            onChange?.(value);
          }}
          selected={field.value}
          tooltip={rest.tooltip}
        />
      )}
    </FormField>
  );
};

export default FormDatePicker;
