import cx from 'classnames';
import type { FC } from 'react';

import BarChart from '../BarChart';

export type ProgressType = 'info';
interface Props {
  className?: string;
  label?: string;
  percentage: number;
  showLabel?: boolean;
}
const ProgressBar: FC<Props> = (props) => {
  const { className, percentage = 0, label = '', showLabel = true } = props;
  const progressBarlabel = label || `${percentage}% completed`;

  const progressClassNames = cx('h-4 leading-10 rounded-full bg-grey-lighter', className);

  return (
    <div data-component="progress-bar" data-testid="progress-bar">
      {showLabel && (
        <div className="text-xs text-grey-dark font-bold leading-normal mb-1" data-testid="progress-bar-label">
          {progressBarlabel}
        </div>
      )}
      <BarChart className={progressClassNames} configs={[{ percentage, type: 'info' }]} />
    </div>
  );
};

export type { Props as ProgressBarProps };

export default ProgressBar;
