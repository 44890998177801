import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IssueTemplatesGetIssueTemplatesQueryVariables = GraphQLTypes.Exact<{
  ruleIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type IssueTemplatesGetIssueTemplatesQueryResult = { __typename?: 'Query' } & {
  issueTemplates?: GraphQLTypes.Maybe<
    { __typename?: 'IssueTemplateConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'IssueTemplate' } & Pick<
              GraphQLTypes.IssueTemplate,
              'id' | 'active' | 'category' | 'problemDescription' | 'ruleId' | 'severity' | 'title'
            > & {
                visibility: { __typename?: 'IssueVisibility' } & Pick<
                  GraphQLTypes.IssueVisibility,
                  'agentForwarder' | 'customerUser' | 'manufacturer'
                >;
                canDelete: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              }
          >
        >
      >;
    }
  >;
};

export const IssueTemplatesGetIssueTemplatesDocument = /* #__PURE__ */ gql`
  query issueTemplatesGetIssueTemplates($ruleIds: [String!]) {
    issueTemplates(ruleIds: $ruleIds) {
      nodes {
        id
        active
        category
        problemDescription
        ruleId
        severity
        title
        visibility {
          agentForwarder
          customerUser
          manufacturer
        }
        canDelete {
          value
        }
        canUpdate {
          value
        }
      }
    }
  }
`;

/**
 * __useIssueTemplatesGetIssueTemplatesQuery__
 *
 * To run a query within a React component, call `useIssueTemplatesGetIssueTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueTemplatesGetIssueTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueTemplatesGetIssueTemplatesQuery({
 *   variables: {
 *      ruleIds: // value for 'ruleIds'
 *   },
 * });
 */
export function useIssueTemplatesGetIssueTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<IssueTemplatesGetIssueTemplatesQueryResult, IssueTemplatesGetIssueTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<IssueTemplatesGetIssueTemplatesQueryResult, IssueTemplatesGetIssueTemplatesQueryVariables>(
    IssueTemplatesGetIssueTemplatesDocument,
    options
  );
}
export function useIssueTemplatesGetIssueTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IssueTemplatesGetIssueTemplatesQueryResult,
    IssueTemplatesGetIssueTemplatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<IssueTemplatesGetIssueTemplatesQueryResult, IssueTemplatesGetIssueTemplatesQueryVariables>(
    IssueTemplatesGetIssueTemplatesDocument,
    options
  );
}
export type IssueTemplatesGetIssueTemplatesQueryHookResult = ReturnType<typeof useIssueTemplatesGetIssueTemplatesQuery>;
export type IssueTemplatesGetIssueTemplatesLazyQueryHookResult = ReturnType<typeof useIssueTemplatesGetIssueTemplatesLazyQuery>;
