import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceUpdateAccrualMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccrualMutationInput;
}>;

export type FinanceUpdateAccrualMutationResult = { __typename?: 'Mutation' } & {
  financeUpdateAccrual?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccrualMutationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      accrual?: GraphQLTypes.Maybe<
        { __typename?: 'Accrual' } & Pick<GraphQLTypes.Accrual, 'zencargoReference'> & {
            publishedAt?: GraphQLTypes.Maybe<
              { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
            >;
            publishedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
            updatedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
            updatedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
            costItems: Array<
              { __typename?: 'CostItem' } & Pick<GraphQLTypes.CostItem, 'id'> & {
                  cost: { __typename?: 'Cost' } & Pick<GraphQLTypes.Cost, 'id'>;
                  revenue: { __typename?: 'Revenue' } & Pick<GraphQLTypes.Revenue, 'id'>;
                  margin: { __typename?: 'Margin' } & Pick<GraphQLTypes.Margin, 'id'>;
                }
            >;
          }
      >;
    }
  >;
};

export const FinanceUpdateAccrualDocument = /* #__PURE__ */ gql`
  mutation financeUpdateAccrual($input: UpdateAccrualMutationInput!) {
    financeUpdateAccrual(input: $input) {
      errors {
        message
        path
      }
      accrual {
        zencargoReference
        publishedAt {
          date
          dateTime
        }
        publishedBy {
          username
        }
        updatedAt {
          date
          dateTime
        }
        updatedBy {
          username
        }
        costItems {
          id
          cost {
            id
          }
          revenue {
            id
          }
          margin {
            id
          }
        }
      }
    }
  }
`;

/**
 * __useFinanceUpdateAccrualMutation__
 *
 * To run a mutation, you first call `useFinanceUpdateAccrualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinanceUpdateAccrualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financeUpdateAccrualMutation, { data, loading, error }] = useFinanceUpdateAccrualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinanceUpdateAccrualMutation(
  baseOptions?: Apollo.MutationHookOptions<FinanceUpdateAccrualMutationResult, FinanceUpdateAccrualMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinanceUpdateAccrualMutationResult, FinanceUpdateAccrualMutationVariables>(
    FinanceUpdateAccrualDocument,
    options
  );
}
export type FinanceUpdateAccrualMutationHookResult = ReturnType<typeof useFinanceUpdateAccrualMutation>;
