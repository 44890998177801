import { getDimensions, getWeight } from '@zen/Cargo/helpers';
import type { MoneyInput } from '@zen/graphql/types.generated';

import type { FullTruckFormValues } from './FullTruckForm/types';

export const prepareFullTruckValues = (values: FullTruckFormValues) => {
  const { type, loose, hazardous, refrigerated, valueOfGoods, tailLift, riskLevel, healthCertificate } = values;

  return {
    actualCbm: loose?.volume,
    vehicleType: type,
    grossWeight: getWeight(loose?.grossWeight),
    dimensions: getDimensions(loose?.dimensions),
    hazardous: !!hazardous,
    riskLevel,
    tailLift: !!tailLift,
    looseCargoType: loose?.type,
    palletType: loose?.value,
    valueOfGoods: valueOfGoods?.value ? (valueOfGoods as MoneyInput) : null,
    quantity: loose?.quantity,
    reefer: !!refrigerated,
    healthCertificate: !!healthCertificate
  };
};
