import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignUserAsCoordinatorMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.SetUserAsManagerInput;
}>;

export type AssignUserAsCoordinatorMutationResult = { __typename?: 'Mutation' } & {
  operationsSetUserAsManager?: GraphQLTypes.Maybe<
    { __typename?: 'SetUserAsManagerPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const AssignUserAsCoordinatorDocument = /* #__PURE__ */ gql`
  mutation assignUserAsCoordinator($input: SetUserAsManagerInput!) {
    operationsSetUserAsManager(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useAssignUserAsCoordinatorMutation__
 *
 * To run a mutation, you first call `useAssignUserAsCoordinatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignUserAsCoordinatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignUserAsCoordinatorMutation, { data, loading, error }] = useAssignUserAsCoordinatorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignUserAsCoordinatorMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignUserAsCoordinatorMutationResult, AssignUserAsCoordinatorMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AssignUserAsCoordinatorMutationResult, AssignUserAsCoordinatorMutationVariables>(
    AssignUserAsCoordinatorDocument,
    options
  );
}
export type AssignUserAsCoordinatorMutationHookResult = ReturnType<typeof useAssignUserAsCoordinatorMutation>;
