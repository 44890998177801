import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CostTrackingSummaryQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CostTrackingSummaryQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'modeOfTransport' | 'zencargoReference'> & {
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
              calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                cargoSummary?: GraphQLTypes.Maybe<
                  { __typename?: 'CargoSummary' } & Pick<GraphQLTypes.CargoSummary, 'totalActualCbm'> & {
                      totalGrossWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'value' | 'metric'>>
                      >;
                    }
                >;
              };
              costTracking?: GraphQLTypes.Maybe<
                { __typename?: 'CostTracking' } & Pick<GraphQLTypes.CostTracking, 'costTrackingId'> & {
                    totals: { __typename?: 'CostTrackingTotals' } & Pick<
                      GraphQLTypes.CostTrackingTotals,
                      | 'displayCurrency'
                      | 'profit'
                      | 'margin'
                      | 'thresholdDate'
                      | 'totalCostInDisplayCurrency'
                      | 'totalRevenueInDisplayCurrency'
                    >;
                  }
              >;
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    consolidatedCargo?: GraphQLTypes.Maybe<
                      { __typename?: 'ConsolidatedCargo' } & {
                        consolidatedCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'CargoConsolidatedItemType' } & Pick<
                              GraphQLTypes.CargoConsolidatedItemType,
                              'quantity' | 'type' | 'subType'
                            >
                          >
                        >;
                        consolidatedLooseCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'ConsolidatedLooseCargoItem' } & Pick<
                              GraphQLTypes.ConsolidatedLooseCargoItem,
                              'quantity' | 'type'
                            >
                          >
                        >;
                      }
                    >;
                  }
              >;
              cargoJourneys?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneys' } & {
                  journeys: Array<
                    { __typename?: 'CargoJourney' } & {
                      journey?: GraphQLTypes.Maybe<
                        { __typename?: 'Journey' } & {
                          legs?: GraphQLTypes.Maybe<
                            Array<
                              { __typename?: 'JourneyLeg' } & Pick<GraphQLTypes.JourneyLeg, 'type' | 'id'> & {
                                  from: { __typename?: 'JourneyStop' } & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'id' | 'label' | 'unlocode'>
                                    >;
                                  };
                                  vehicle: { __typename?: 'JourneyVehicle' } & Pick<GraphQLTypes.JourneyVehicle, 'id'> & {
                                      properties?: GraphQLTypes.Maybe<
                                        Array<
                                          { __typename?: 'JourneyVehicleProperty' } & Pick<
                                            GraphQLTypes.JourneyVehicleProperty,
                                            'name' | 'value'
                                          >
                                        >
                                      >;
                                    };
                                  to: { __typename?: 'JourneyStop' } & {
                                    terminal?: GraphQLTypes.Maybe<
                                      { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'id' | 'label' | 'unlocode'>
                                    >;
                                  };
                                }
                            >
                          >;
                        }
                      >;
                    }
                  >;
                }
              >;
              haulier?: GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>>;
              incoterms?: GraphQLTypes.Maybe<{ __typename?: 'Incoterms' } & Pick<GraphQLTypes.Incoterms, 'value'>>;
            }
        >
      >
    >;
  };
  getContract?: GraphQLTypes.Maybe<
    { __typename?: 'ContractPayload' } & Pick<GraphQLTypes.ContractPayload, 'simplifiedContractId'>
  >;
};

export const CostTrackingSummaryDocument = /* #__PURE__ */ gql`
  query costTrackingSummary($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        bookingStage {
          name
        }
        calculatedInfo {
          cargoSummary {
            totalGrossWeight {
              value
              metric
            }
            totalActualCbm
          }
        }
        costTracking {
          costTrackingId
          totals {
            displayCurrency
            profit
            margin
            thresholdDate
            totalCostInDisplayCurrency
            totalRevenueInDisplayCurrency
          }
        }
        cargo {
          mode
          consolidatedCargo {
            consolidatedCargoItems {
              quantity
              type
              subType
            }
            consolidatedLooseCargoItems {
              quantity
              type
            }
          }
        }
        cargoJourneys {
          journeys {
            journey {
              legs {
                from {
                  terminal {
                    id
                    label
                    unlocode
                  }
                }
                type
                vehicle {
                  id
                  properties {
                    name
                    value
                  }
                }
                id
                to {
                  terminal {
                    id
                    label
                    unlocode
                  }
                }
              }
            }
          }
        }
        haulier {
          id
          name
        }
        incoterms {
          value
        }
        modeOfTransport
        zencargoReference
      }
    }
    getContract(zencargoReference: $zencargoReference) {
      simplifiedContractId
    }
  }
`;

/**
 * __useCostTrackingSummaryQuery__
 *
 * To run a query within a React component, call `useCostTrackingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostTrackingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostTrackingSummaryQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCostTrackingSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<CostTrackingSummaryQueryResult, CostTrackingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CostTrackingSummaryQueryResult, CostTrackingSummaryQueryVariables>(CostTrackingSummaryDocument, options);
}
export function useCostTrackingSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CostTrackingSummaryQueryResult, CostTrackingSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CostTrackingSummaryQueryResult, CostTrackingSummaryQueryVariables>(
    CostTrackingSummaryDocument,
    options
  );
}
export type CostTrackingSummaryQueryHookResult = ReturnType<typeof useCostTrackingSummaryQuery>;
export type CostTrackingSummaryLazyQueryHookResult = ReturnType<typeof useCostTrackingSummaryLazyQuery>;
