import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CheckForDuplicateAccountsQueryVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CheckForDuplicateAccountsInput;
}>;

export type CheckForDuplicateAccountsQueryResult = { __typename?: 'Query' } & {
  checkForDuplicateAccounts?: GraphQLTypes.Maybe<
    { __typename?: 'CheckForDuplicateAccountsPayload' } & {
      errors: Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>;
      possibleDuplicates: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
    }
  >;
};

export const CheckForDuplicateAccountsDocument = /* #__PURE__ */ gql`
  query checkForDuplicateAccounts($input: CheckForDuplicateAccountsInput!) {
    checkForDuplicateAccounts(input: $input) {
      errors {
        message
        path
      }
      possibleDuplicates {
        id
        tradingName
      }
    }
  }
`;

/**
 * __useCheckForDuplicateAccountsQuery__
 *
 * To run a query within a React component, call `useCheckForDuplicateAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckForDuplicateAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckForDuplicateAccountsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckForDuplicateAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<CheckForDuplicateAccountsQueryResult, CheckForDuplicateAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CheckForDuplicateAccountsQueryResult, CheckForDuplicateAccountsQueryVariables>(
    CheckForDuplicateAccountsDocument,
    options
  );
}
export function useCheckForDuplicateAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckForDuplicateAccountsQueryResult, CheckForDuplicateAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CheckForDuplicateAccountsQueryResult, CheckForDuplicateAccountsQueryVariables>(
    CheckForDuplicateAccountsDocument,
    options
  );
}
export type CheckForDuplicateAccountsQueryHookResult = ReturnType<typeof useCheckForDuplicateAccountsQuery>;
export type CheckForDuplicateAccountsLazyQueryHookResult = ReturnType<typeof useCheckForDuplicateAccountsLazyQuery>;
