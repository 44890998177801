import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateRailwayCompanyMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateRailwayCompanyInput;
}>;

export type CreateRailwayCompanyMutationResult = { __typename?: 'Mutation' } & {
  createRailwayCompany: { __typename?: 'CreateRailwayCompanyPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    railwayCompany?: GraphQLTypes.Maybe<
      { __typename?: 'RailwayCompany' } & Pick<GraphQLTypes.RailwayCompany, 'railwayCompany' | 'id'>
    >;
  };
};

export const CreateRailwayCompanyDocument = /* #__PURE__ */ gql`
  mutation createRailwayCompany($input: CreateRailwayCompanyInput!) {
    createRailwayCompany(input: $input) {
      errors {
        message
        path
      }
      railwayCompany {
        railwayCompany
        id
      }
    }
  }
`;

/**
 * __useCreateRailwayCompanyMutation__
 *
 * To run a mutation, you first call `useCreateRailwayCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRailwayCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRailwayCompanyMutation, { data, loading, error }] = useCreateRailwayCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRailwayCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateRailwayCompanyMutationResult, CreateRailwayCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateRailwayCompanyMutationResult, CreateRailwayCompanyMutationVariables>(
    CreateRailwayCompanyDocument,
    options
  );
}
export type CreateRailwayCompanyMutationHookResult = ReturnType<typeof useCreateRailwayCompanyMutation>;
