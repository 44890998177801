import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking } from '@zen/Booking';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { useUpdateHouseBillOfLadingMutation } from './graphql';

interface Props {
  canUpdateHouseBillOfLading: boolean;
  houseBillOfLading: string;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

const HouseBillOfLadingInlineField: FC<Props> = (props) => {
  const { canUpdateHouseBillOfLading, houseBillOfLading, modeOfTransport, zencargoReference } = props;
  const { addSuccess, addError } = useNotification();
  const [updateHouseBillOfLading] = useUpdateHouseBillOfLadingMutation();

  const label: string = isAirBooking(modeOfTransport) ? 'House airway bill' : 'House bill of lading';

  const handleUpdateHouseBillOfLading = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateHouseBillOfLading({
          variables: {
            input: {
              zencargoReference,
              houseBillOfLading: value
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('House bill of lading updated.');
      }
    });
  };

  if (!canUpdateHouseBillOfLading) {
    return (
      <LabelledValue label={label} variant="default">
        {houseBillOfLading || '-'}
      </LabelledValue>
    );
  }

  return (
    <div className="-ml-3">
      <InlineEditableField
        label={label}
        name="house-bill-of-lading"
        onUpdate={handleUpdateHouseBillOfLading}
        value={houseBillOfLading}
      />
    </div>
  );
};

export default HouseBillOfLadingInlineField;
