import { array, object, string } from 'yup';

const validatePrice = (message: string) =>
  object({ value: string().nullable() }).test('price test', function testPriceValue(price) {
    if (price.value === null) {
      return this.createError({ message });
    }

    return true;
  });

export const getValidationSchema = (type: 'origin' | 'destination') => {
  const portLabel = type === 'origin' ? 'Port of origin' : 'Port of destination';
  const incotermsValidationMessage: string = 'Incoterms is required.';

  return object().shape({
    chargeType: object().nullable().required('Charge type is required.'),
    incoterms: array().min(1, incotermsValidationMessage).nullable().required(incotermsValidationMessage),
    port: object().nullable().required(`${portLabel} is required.`),
    price: validatePrice('Price is required.')
  });
};
