const RATE_CARDS_RELATIVE_INDEX_PATH = 'rate-cards';

export const RATE_CARDS_ROUTE_PREFIX = `/dashboard/${RATE_CARDS_RELATIVE_INDEX_PATH}`;

interface RateCardRoutes {
  details: {
    getUrl: (rateCardId: string) => string;
    path: string;
    relativePath: string;
  };
  edit: {
    getUrl: (rateCardId: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  new: {
    getUrl: (rateCardId?: string) => string;
    path: string;
    relativePath: string;
  };
}

const rateCardRoutes: RateCardRoutes = {
  index: {
    path: `${RATE_CARDS_ROUTE_PREFIX}/*`,
    getUrl: () => RATE_CARDS_ROUTE_PREFIX,
    relativePath: `${RATE_CARDS_RELATIVE_INDEX_PATH}/*`
  },
  new: {
    path: `${RATE_CARDS_ROUTE_PREFIX}/new/:rateCardId?`,
    getUrl: (rateCardId?: string) => {
      const newRateCardUrl = `${RATE_CARDS_ROUTE_PREFIX}/new`;

      if (rateCardId) {
        return `${newRateCardUrl}/${rateCardId}`;
      }

      return newRateCardUrl;
    },
    relativePath: 'new/:rateCardId?'
  },
  details: {
    path: `${RATE_CARDS_ROUTE_PREFIX}/details/:rateCardId`,
    getUrl: (rateCardId: string) => `${RATE_CARDS_ROUTE_PREFIX}/details/${rateCardId}`,
    relativePath: 'details/:rateCardId'
  },
  edit: {
    path: `${RATE_CARDS_ROUTE_PREFIX}/edit/:rateCardId`,
    getUrl: (rateCardId: string) => `${RATE_CARDS_ROUTE_PREFIX}/edit/${rateCardId}`,
    relativePath: 'edit/:rateCardId'
  }
};

export default rateCardRoutes;
