import { createContext } from 'react';

import type { CargoReadyDateValues } from '../components/CargoReadyDateForm';

interface InitialContext {
  setCargoReadyDateValues: (values: CargoReadyDateValues) => void;
}

const initialContext = {
  setCargoReadyDateValues: () => {}
};

const UpdateCRDContext = createContext<InitialContext>(initialContext);

export default UpdateCRDContext;
