import { createContext } from 'react';

import type { NotificationAction } from './types';

export interface INotificationContext {
  addError: (text?: string, action?: NotificationAction) => void;
  addSuccess: (text: string, action?: NotificationAction) => void;
}

const initialContext = {
  addSuccess: () => {},
  addError: () => {}
};

const NotificationContext = createContext<INotificationContext>(initialContext);

export default NotificationContext;
