import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PortChargesQueryVariables = GraphQLTypes.Exact<{
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  portChargeType?: GraphQLTypes.InputMaybe<GraphQLTypes.OriginDestinationChargeTypeEnum>;
  ports?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  chargeTypes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  incoterms?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.IncotermsValue> | GraphQLTypes.IncotermsValue>;
}>;

export type PortChargesQueryResult = { __typename?: 'Query' } & {
  getCurrentPortCharges?: GraphQLTypes.Maybe<
    { __typename?: 'PortChargeConnection' } & Pick<GraphQLTypes.PortChargeConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'CentralPortCharge' } & Pick<
                GraphQLTypes.CentralPortCharge,
                'id' | 'incoterms' | 'currency' | 'chargeValue'
              > & {
                  chargeType: { __typename?: 'ChargeType' } & Pick<
                    GraphQLTypes.ChargeType,
                    | 'id'
                    | 'name'
                    | 'chargeType'
                    | 'modeOfTransport'
                    | 'chargeBasis'
                    | 'vatApplicable'
                    | 'twentyFtDv'
                    | 'twentyFtHc'
                    | 'fortyFtDv'
                    | 'fortyFtHc'
                    | 'fortyFiveFtHc'
                    | 'hazardous'
                    | 'reefer'
                    | 'overweight'
                  >;
                  port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const PortChargesDocument = /* #__PURE__ */ gql`
  query portCharges(
    $first: Int
    $last: Int
    $after: String
    $before: String
    $portChargeType: OriginDestinationChargeTypeEnum
    $ports: [String!]
    $chargeTypes: [String!]
    $incoterms: [IncotermsValue!]
  ) {
    getCurrentPortCharges(
      first: $first
      last: $last
      after: $after
      before: $before
      chargeTypes: $chargeTypes
      incoterms: $incoterms
      ports: $ports
      portChargeType: $portChargeType
    ) {
      nodes {
        id
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        incoterms
        currency
        chargeValue
        port {
          unlocode
          name
          label
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __usePortChargesQuery__
 *
 * To run a query within a React component, call `usePortChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePortChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePortChargesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      portChargeType: // value for 'portChargeType'
 *      ports: // value for 'ports'
 *      chargeTypes: // value for 'chargeTypes'
 *      incoterms: // value for 'incoterms'
 *   },
 * });
 */
export function usePortChargesQuery(baseOptions?: Apollo.QueryHookOptions<PortChargesQueryResult, PortChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PortChargesQueryResult, PortChargesQueryVariables>(PortChargesDocument, options);
}
export function usePortChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<PortChargesQueryResult, PortChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PortChargesQueryResult, PortChargesQueryVariables>(PortChargesDocument, options);
}
export type PortChargesQueryHookResult = ReturnType<typeof usePortChargesQuery>;
export type PortChargesLazyQueryHookResult = ReturnType<typeof usePortChargesLazyQuery>;
