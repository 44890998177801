import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackedShipmentFiltersQueryVariables = GraphQLTypes.Exact<{
  customerId: GraphQLTypes.Scalars['String'];
  event?: GraphQLTypes.InputMaybe<GraphQLTypes.RoadTrackedShipmentEvent>;
  firstStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  lastStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type RoadTrackedShipmentFiltersQueryResult = { __typename?: 'Query' } & {
  roadTrackedShipmentFilters: Array<
    { __typename?: 'RoadTrackedShipmentFilter' } & Pick<GraphQLTypes.RoadTrackedShipmentFilter, 'count' | 'status'>
  >;
};

export const RoadTrackedShipmentFiltersDocument = /* #__PURE__ */ gql`
  query roadTrackedShipmentFilters(
    $customerId: String!
    $event: RoadTrackedShipmentEvent
    $firstStopCountryCodes: [String!]
    $lastStopCountryCodes: [String!]
  ) {
    roadTrackedShipmentFilters(
      active: ACTIVE
      customerId: $customerId
      event: $event
      firstStopCountryCodes: $firstStopCountryCodes
      lastStopCountryCodes: $lastStopCountryCodes
    ) {
      count
      status
    }
  }
`;

/**
 * __useRoadTrackedShipmentFiltersQuery__
 *
 * To run a query within a React component, call `useRoadTrackedShipmentFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackedShipmentFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackedShipmentFiltersQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      event: // value for 'event'
 *      firstStopCountryCodes: // value for 'firstStopCountryCodes'
 *      lastStopCountryCodes: // value for 'lastStopCountryCodes'
 *   },
 * });
 */
export function useRoadTrackedShipmentFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackedShipmentFiltersQueryResult, RoadTrackedShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackedShipmentFiltersQueryResult, RoadTrackedShipmentFiltersQueryVariables>(
    RoadTrackedShipmentFiltersDocument,
    options
  );
}
export function useRoadTrackedShipmentFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackedShipmentFiltersQueryResult, RoadTrackedShipmentFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackedShipmentFiltersQueryResult, RoadTrackedShipmentFiltersQueryVariables>(
    RoadTrackedShipmentFiltersDocument,
    options
  );
}
export type RoadTrackedShipmentFiltersQueryHookResult = ReturnType<typeof useRoadTrackedShipmentFiltersQuery>;
export type RoadTrackedShipmentFiltersLazyQueryHookResult = ReturnType<typeof useRoadTrackedShipmentFiltersLazyQuery>;
