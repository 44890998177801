const EXCHANGE_RATES_RELATIVE_INDEX_PATH = 'manage-exchange-rates';

export const EXCHANGE_RATES_ROUTE_PREFIX = `/dashboard/${EXCHANGE_RATES_RELATIVE_INDEX_PATH}`;

interface ExchangeRatesRoutes {
  manageExchangeRatesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const exchangeRatesRoutes: ExchangeRatesRoutes = {
  manageExchangeRatesIndex: {
    getUrl: () => EXCHANGE_RATES_ROUTE_PREFIX,
    path: `${EXCHANGE_RATES_ROUTE_PREFIX}/*`,
    relativePath: `${EXCHANGE_RATES_RELATIVE_INDEX_PATH}/*`
  }
};

export default exchangeRatesRoutes;
