import type { FC, ReactNode } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import type { RouteTabConfig } from '../RouteTabs';

interface Props {
  tabsConfig: RouteTabConfig[];
}

const RouteTabSwitcher: FC<Props> = ({ tabsConfig }) => {
  const { search } = useLocation();

  const defaultTab: RouteTabConfig = tabsConfig.find((tabConfig: RouteTabConfig) => tabConfig.isDefault) || tabsConfig[0];

  const renderRoutes = () =>
    tabsConfig.map(
      ({ component, path }: RouteTabConfig, i): ReactNode => <Route key={`${path}${i}`} element={component} path={`${path}`} />
    );

  return (
    <Routes>
      {renderRoutes()}
      <Route element={<Navigate replace={true} to={`${defaultTab.url}${search}`} />} index={true} />
    </Routes>
  );
};

export default RouteTabSwitcher;
