import { CargoItemTypeEnum } from '@zen/Cargo';

export const cargoConfiguration = {
  [CargoItemTypeEnum.LOOSE_CARGO]: [
    'cargoType',
    'dimensions',
    'grossWeight',
    'volume',
    'chargeableWeight',
    'containerNumber',
    'hazardous',
    'refrigerated',
    'stackable'
  ],
  [CargoItemTypeEnum.VEHICLE]: [
    'cargoType',
    'looseCargo',
    'dimensions',
    'valueOfCargo',
    'grossWeight',
    'volume',
    'hazardous',
    'healthCertificate',
    'refrigerated',
    'tailLift'
  ]
};
