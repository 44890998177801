import { applicabilityLabelMapping } from '@zen/CostTracking';

import type { OceanChargeInitialValues, OceanChargeItemInitialValues } from '../OceanChargeForm/types';
import { type Applicability, Currency } from './types';

const emptyChargeItem: OceanChargeItemInitialValues = {
  basisGroup: null,
  chargeType: null,
  itemName: '',
  unitPrice: {
    currency: Currency.GBP,
    value: null
  }
};

export const initialValues: OceanChargeInitialValues = {
  location: null,
  chargeItems: [emptyChargeItem]
};

export const getFormattedApplicabilityLabel = (applicability: Applicability): string => {
  return applicabilityLabelMapping[applicability].toLowerCase();
};
