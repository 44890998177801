import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductCategoryFilterValuesQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableFiltersInput>;
}>;

export type ProductCategoryFilterValuesQueryResult = { __typename?: 'Query' } & {
  shipmentsTableProductCategoryFilterValues: { __typename?: 'ShipmentsTableFilterValueConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'ShipmentsTableFilterValue' } & Pick<GraphQLTypes.ShipmentsTableFilterValue, 'key' | 'value'>
        >
      >
    >;
  };
};

export const ProductCategoryFilterValuesDocument = /* #__PURE__ */ gql`
  query productCategoryFilterValues(
    $accountIds: [String!]!
    $searchTerm: String
    $first: Int
    $filterBy: ShipmentsTableFiltersInput
  ) {
    shipmentsTableProductCategoryFilterValues(
      accountIds: $accountIds
      searchTerm: $searchTerm
      first: $first
      filterBy: $filterBy
    ) {
      nodes {
        key
        value
      }
    }
  }
`;

/**
 * __useProductCategoryFilterValuesQuery__
 *
 * To run a query within a React component, call `useProductCategoryFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoryFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoryFilterValuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      searchTerm: // value for 'searchTerm'
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useProductCategoryFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductCategoryFilterValuesQueryResult, ProductCategoryFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductCategoryFilterValuesQueryResult, ProductCategoryFilterValuesQueryVariables>(
    ProductCategoryFilterValuesDocument,
    options
  );
}
export function useProductCategoryFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoryFilterValuesQueryResult, ProductCategoryFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductCategoryFilterValuesQueryResult, ProductCategoryFilterValuesQueryVariables>(
    ProductCategoryFilterValuesDocument,
    options
  );
}
export type ProductCategoryFilterValuesQueryHookResult = ReturnType<typeof useProductCategoryFilterValuesQuery>;
export type ProductCategoryFilterValuesLazyQueryHookResult = ReturnType<typeof useProductCategoryFilterValuesLazyQuery>;
