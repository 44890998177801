import type { Group, Option, SelectOption } from './types';

export const isGroupOption = <Value>(option: Option<Value> | Group<Value>): option is Group<Value> => {
  return !!(option as Group<Value>)?.options;
};

export const getAllOptions = <Value>(options: SelectOption<Value>[]): Option<Value>[] => {
  return options.reduce((prev: Option<Value>[], option) => {
    if (isGroupOption(option)) {
      return prev.concat(option.options);
    }

    return prev.concat(option);
  }, []);
};
