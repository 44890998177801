import type { FC } from 'react';

import { CargoItemTypeEnum } from '@zen/Cargo';
import { Icon } from '@zen/DesignSystem';
import type { CargoSummary as CargoSummaryType } from '@zen/graphql/types.generated';
import type { IconName } from '@zen/Styleguide';
import type { Optional } from '@zen/utils/typescript';

const getIconName = (type: CargoItemTypeEnum): IconName => {
  switch (type) {
    case CargoItemTypeEnum.LOOSE_CARGO:
      return 'zicon-pallet';
    case CargoItemTypeEnum.VEHICLE:
      return 'zicon-road';
    default:
      return 'zicon-shipment';
  }
};

interface Props {
  summary: Optional<Pick<CargoSummaryType, 'label' | 'type'>>;
}

const CargoSummary: FC<Props> = ({ summary }) => {
  if (!summary) {
    return null;
  }

  const { type, label } = summary;

  return (
    <div className="flex items-center">
      {type && (
        <div className="flex justify-center">
          <Icon className="text-azure-base mb-px" icon={getIconName(type)} />
        </div>
      )}
      <span className="ml-2 text-grey-dark">{label}</span>
    </div>
  );
};

export type { Props as CargoSummaryProps };
export default CargoSummary;
