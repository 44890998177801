import { camelCase } from 'lodash';

import { Role } from '@zen/Auth/role';

import type { RolePermissionMap, RolePermissionMapWithAdmin } from './types';

// backend permissions contains key like `agentForwarder` but roles contain  AGENT_FORWARDER
export const toPermissionKey = (role: Role): keyof RolePermissionMap => camelCase(role) as keyof RolePermissionMap;

export const getVisibleRoles = (currentRole: Role, rolePermissions?: RolePermissionMap | RolePermissionMapWithAdmin): Role[] => {
  // skip item for current user role or if permissions do not contain key for a role
  return Object.values(Role).filter((role: Role) => {
    const permissionKey: string = toPermissionKey(role);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const permissionsHaveRole = rolePermissions ? typeof rolePermissions[permissionKey] === 'boolean' : true;
    const isCurrentRule = role === currentRole;

    return permissionsHaveRole && !isCurrentRule;
  });
};
