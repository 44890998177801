import { FC, useReducer } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import type { Option } from '@zen/DesignSystem';

import { deleteDocument, initialState, reducer, updateDocument } from './document.reducer';
import DocumentList from './DocumentList';
import { useGetAllBookingDocumentTypesQuery } from './graphql';
import { getDocumentTypeOptions } from './helpers';
import type { Document, DocumentType } from './types';

interface Props {
  documents?: Document[];
  isGeneratingDocument?: boolean;
  onDelete?: (id: string) => void;
  onEdit?: (document: Document) => void;
  showDocumentDate?: boolean;
}

const Documents: FC<Props> = (props) => {
  const { documents: existingDocuments, showDocumentDate = true, isGeneratingDocument, onEdit, onDelete } = props;
  const [, dispatch] = useReducer(reducer, initialState);
  const {
    data: documentTypesData,
    loading: documentTypesLoading,
    error: documentTypesError
  } = useGetAllBookingDocumentTypesQuery();

  const handleEdit = (document: Document): void => {
    dispatch(updateDocument(document));

    onEdit?.(document);
  };

  const handleDelete = (id: string): void => {
    dispatch(deleteDocument(id));

    onDelete?.(id);
  };

  return (
    <QueryHandler
      data={documentTypesData?.bookingDocumentTypes?.nodes as DocumentType[]}
      error={!!documentTypesError}
      isLoading={documentTypesLoading}
    >
      {(bookingDocumentTypes: DocumentType[]) => {
        const documentTypes: Option<string>[] = getDocumentTypeOptions(bookingDocumentTypes);

        return (
          <DocumentList
            documents={existingDocuments || []}
            documentTypes={documentTypes}
            isGeneratingDocument={isGeneratingDocument}
            onDelete={handleDelete}
            onEdit={handleEdit}
            showDocumentDate={showDocumentDate}
          />
        );
      }}
    </QueryHandler>
  );
};

export default Documents;
