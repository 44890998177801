import { type FC, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import type { Contact } from '@zen/Components/ContactInput';
import ContactInput from '@zen/Components/ContactInput/ContactInput';
import { ContactNameQueryResult, useContactNameQuery } from '@zen/Components/ContactInput/graphql';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateVehicleHaulierIdMutation } from './graphql';

interface Props {
  canUpdateHaulier: boolean;
  haulierId: string;
  vehicleId: string;
}

const HaulierInlineField: FC<Props> = ({ canUpdateHaulier, vehicleId, haulierId }) => {
  const { addSuccess, addError } = useNotification();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [contact, setContact] = useState<Optional<Contact>>();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateHaulier] = useUpdateVehicleHaulierIdMutation();

  const handleComplete = (data: ContactNameQueryResult): void => {
    setContact(data.contact);
  };

  const { data } = useContactNameQuery({ variables: { id: haulierId || '' }, onCompleted: handleComplete, skip: !haulierId });

  const label: string = 'Haulier ID';

  const handleHaulierChange = async (haulier: Nullable<Contact>) => {
    if (haulier?.id === haulierId) return;

    setIsLoading(true);

    await performMutation({
      mutationFn: () =>
        updateHaulier({
          variables: {
            input: {
              vehicleId,
              haulierId: haulier?.id || '',
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Haulier updated.');
        setContact(haulier);
      }
    });

    setIsLoading(false);
  };

  if (!canUpdateHaulier) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={data?.contact.name} />
      </LabelledValue>
    );
  }

  return (
    <ContactInput
      isDisabled={isLoading}
      isLoading={isLoading}
      label={label}
      labelPlacement="left"
      name="haulier-id"
      onChange={handleHaulierChange}
      placeholder="Enter haulier ID"
      value={contact}
      variant="inline"
    />
  );
};

export default HaulierInlineField;
