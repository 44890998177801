/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement, ReactNode } from 'react';

import type { Optional } from '@zen/utils/typescript';

export interface Props<T = string | number> {
  defaultValue?: ReactNode;
  formatValue?: (value: T) => ReactNode;
  value: Optional<T>;
}

const ValueWithDefault = <T extends number | string>({ value, defaultValue = '-', formatValue }: Props<T>): ReactElement => {
  if (value === null || value === undefined || !String(value)) {
    return <>{defaultValue}</>;
  }

  const displayedValue: ReactNode = formatValue && value ? formatValue(value) : value;

  return <>{displayedValue}</>;
};

export default ValueWithDefault;
