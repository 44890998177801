import { FC, ReactText, SyntheticEvent, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import LabelledValue from '@zen/Components/LabelledValue';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { ViewMode } from '@zen/Components/ViewSwitcher';
import type { MenuItemType } from '@zen/DesignSystem';
import { Checkbox } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import type { OrderListViewItem } from '@zen/Orders/types';
import { orderRoutes } from '@zen/Routes';
import { formatNumber } from '@zen/utils/formatting';

import OrdersContextMenu from '../../OrdersContextMenu';
import type { OrderOverviewProps } from '../types';

const isOrderWithCustomerType = (item: OrderListViewItem): boolean => {
  return !!item.customer;
};

interface Props extends OrderOverviewProps {}

const OrderListItemHeadline: FC<Props> = (props) => {
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const { isOrderPartiallySelected, isOrderSelected, onCargoReadyUpdate, onClick, onOrderSelect, order, expanded } = props;
  const navigate = useNavigate();
  const { isBasketMode } = useContext(BasketContext);

  const { id, fulfilledCbm, orderReferenceNumber, manufacturer } = order;
  const canViewOrderCustomer: boolean = checkPermission<OrderListViewItem>(order, 'canViewCustomer');
  const canRemove: boolean = checkPermission<OrderListViewItem>(order, 'canRemove');
  const canUpdate: boolean = checkPermission<OrderListViewItem>(order, 'canUpdate');
  const canClose: boolean = checkPermission<OrderListViewItem>(order, 'canClose');
  const classNames: string = 'flex items-center p-4 font-medium hover:bg-azure-lightest cursor-pointer group';

  let customer = null;

  if (isOrderWithCustomerType(order)) {
    customer = order.customer;
  }

  const getDropdownItems = (orderId: string): MenuItemType[] => [
    {
      label: 'View more details',
      linkTo: orderRoutes.orderItems.getUrl(orderId),
      icon: 'zicon-orders'
    },
    {
      label: 'Update ready date',
      onClick: onCargoReadyUpdate,
      icon: 'zicon-calendar'
    }
  ];

  const openSlideout = (event: SyntheticEvent): void => {
    event.stopPropagation();

    navigate(orderRoutes.orderItems.getUrl(id));
  };

  const handleSelect = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onOrderSelect();
  };

  return (
    <div className={classNames} data-testid="order-list-item-headline" onClick={onClick}>
      {isBasketMode && (
        <div className="w-12">
          <Checkbox checked={!!isOrderSelected} indeterminate={isOrderPartiallySelected} onChange={handleSelect} />
        </div>
      )}

      <LabelledValue className="w-1/5 mr-4" label="PO number">
        <div className="text-navy-base break-all hover:underline inline-block" onClick={openSlideout}>
          {orderReferenceNumber}
        </div>
      </LabelledValue>

      <LabelledValue className="w-1/12 mr-4" label="CBM">
        <ValueWithDefault formatValue={(value: ReactText) => formatNumber(value as number)} value={fulfilledCbm} />
      </LabelledValue>

      <LabelledValue className="w-1/2 mr-4" label="Manufacturer">
        <ValueWithDefault value={manufacturer?.label?.short} />
      </LabelledValue>

      {(canViewOrderCustomer || createAccountFlagIsOn) && (
        <LabelledValue className="w-1/6 mr-4" label="Customer">
          <ValueWithDefault value={order.account?.tradingName || customer?.name} />
        </LabelledValue>
      )}

      <div className="ml-auto flex h-6 items-center">
        {!isBasketMode && (
          <div
            className="pr-2 mr-4 border-r border-solid border-grey-lightest"
            onClick={(event: SyntheticEvent) => event.stopPropagation()}
          >
            <OrdersContextMenu
              additionalItems={getDropdownItems(id)}
              canClose={canClose}
              canRemove={canRemove}
              canUpdate={canUpdate}
              id={id}
              mode={ViewMode.LIST}
              orderReferenceNumber={orderReferenceNumber || ''}
            />
          </div>
        )}
        <ChevronIcon className="text-sm" expanded={expanded} />
      </div>
    </div>
  );
};

export default OrderListItemHeadline;
