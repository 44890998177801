import type { FC } from 'react';
import { useDeepCompareEffect } from 'react-use';

import CaptureProfileUsersName from '@zen/Accounts/CaptureProfileUsersName';
import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import ErrorBoundary from '@zen/Components/ErrorBoundary/ErrorBoundary';
import { growthBookClient, useFeatureIsOn } from '@zen/FeatureFlag';
import { AccountStatus } from '@zen/graphql/types.generated';
import Sidebar from '@zen/Layout/Sidebar';
import TopNavigation from '@zen/Layout/TopNavigation';
import { authRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import useTracking from '@zen/utils/hooks/useTracking';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import DashboardRoutes from '../DashboardRoutes';

const Dashboard: FC = () => {
  const { accountUuid, accountStatus, userProfile, refetchUser } = useAccount();
  const newCreateAccountFlagIsOn = useFeatureIsOn('create-account');

  const { check } = useGlobalPermissions();
  const { identify } = useTracking();
  const role = useRole();
  const { navigate } = useNavigationHistory();

  const canSwitchAccount: boolean = check('accounts.canSwitch');

  useDeepCompareEffect(() => {
    identify(accountUuid, userProfile, role);

    growthBookClient.setAttributes({
      accountId: accountUuid,
      id: userProfile.uuid,
      userRole: role
    });
  }, [identify, accountUuid, userProfile, role]);

  if (canSwitchAccount && !accountUuid) {
    navigate(authRoutes.accountSwitcher.getUrl());

    return null;
  }

  if (newCreateAccountFlagIsOn && !userProfile.isProfileCompleted) {
    return <CaptureProfileUsersName onSuccess={refetchUser} />;
  }

  if (newCreateAccountFlagIsOn && accountStatus === AccountStatus.PENDING) {
    navigate(authRoutes.accountActivation.getUrl());

    return null;
  }

  return (
    <div className="flex flex-col h-full bg-white" data-testid="main-application">
      <TopNavigation />
      <div className="flex flex-row">
        <Sidebar />
        <div className="flex-1 min-w-0">
          <ErrorBoundary>
            <DashboardRoutes />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
