import type { FC, ReactNode } from 'react';

import CloseIcon from '@zen/Components/Icons/CloseIcon';
import { Headline } from '@zen/DesignSystem';

interface Props {
  actionButtons?: ReactNode;
  onClose: () => void;
  title: ReactNode;
}

const SlideoutHeader: FC<Props> = ({ title, onClose, actionButtons }) => {
  const closeButton: ReactNode = <CloseIcon onClick={onClose} />;

  const renderButtons = (): ReactNode => {
    if (actionButtons) {
      return (
        <div className="flex items-center space-x-4">
          {actionButtons}
          {closeButton}
        </div>
      );
    }

    return closeButton;
  };

  return (
    <div className="relative z-10 flex items-center justify-between px-6 py-4 border-b border-solid border-grey-lighter">
      <Headline level={3}>{title}</Headline>
      {renderButtons()}
    </div>
  );
};

export default SlideoutHeader;
