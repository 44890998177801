import { FC, useEffect, useState } from 'react';

import { SearchInput } from '@zen/DesignSystem';

type CommonProps = {
  autoFocus?: boolean;
  className?: string;
  collapsible?: false;
  helperText?: string;
  initialValue?: string;
  onClear: () => void;
  onSubmit: (searchQuery: string) => void;
  placeholder?: string;
  size?: 'default' | 'compact';
};

type CollapsibleProps = {
  collapsible: true;
  widthClassName: string;
} & Omit<CommonProps, 'collapsible'>;

type Props = CommonProps | CollapsibleProps;

const Search: FC<Props> = (props) => {
  const {
    autoFocus,
    className,
    initialValue = '',
    onClear,
    onSubmit,
    helperText,
    placeholder = 'Search...',
    size,
    collapsible
  } = props;
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    setQuery(initialValue);
  }, [initialValue]);

  const handleSearch = (): void => {
    onSubmit(query);
  };

  const additionalProps = {
    widthClassName: (props as CollapsibleProps).widthClassName
  };

  return (
    <SearchInput
      {...additionalProps}
      autoFocus={autoFocus}
      className={className}
      collapsible={collapsible}
      helperText={helperText}
      onChange={setQuery}
      onClear={onClear}
      onSearch={handleSearch}
      placeholder={placeholder}
      size={size}
      value={query}
    />
  );
};

export default Search;
