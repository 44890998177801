import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ActivePortChargesQueryVariables = GraphQLTypes.Exact<{
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  portChargeFilters?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.PortChargeFilter> | GraphQLTypes.PortChargeFilter>;
}>;

export type ActivePortChargesQueryResult = { __typename?: 'Query' } & {
  getActivePortCharges?: GraphQLTypes.Maybe<
    { __typename?: 'PortChargeConnection' } & Pick<GraphQLTypes.PortChargeConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'CentralPortCharge' } & Pick<
                GraphQLTypes.CentralPortCharge,
                'id' | 'incoterms' | 'currency' | 'chargeValue'
              > & {
                  chargeType: { __typename?: 'ChargeType' } & Pick<
                    GraphQLTypes.ChargeType,
                    | 'id'
                    | 'name'
                    | 'chargeType'
                    | 'modeOfTransport'
                    | 'chargeBasis'
                    | 'vatApplicable'
                    | 'twentyFtDv'
                    | 'twentyFtHc'
                    | 'fortyFtDv'
                    | 'fortyFtHc'
                    | 'fortyFiveFtHc'
                    | 'hazardous'
                    | 'reefer'
                    | 'overweight'
                  >;
                  port: { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'name' | 'label'>;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const ActivePortChargesDocument = /* #__PURE__ */ gql`
  query activePortCharges($first: Int, $last: Int, $after: String, $before: String, $portChargeFilters: [PortChargeFilter!]) {
    getActivePortCharges(
      first: $first
      last: $last
      after: $after
      before: $before
      activePortChargeFilters: $portChargeFilters
    ) {
      nodes {
        id
        chargeType {
          id
          name
          chargeType
          modeOfTransport
          chargeBasis
          vatApplicable
          twentyFtDv
          twentyFtHc
          fortyFtDv
          fortyFtHc
          fortyFiveFtHc
          hazardous
          reefer
          overweight
        }
        incoterms
        currency
        chargeValue
        port {
          unlocode
          name
          label
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useActivePortChargesQuery__
 *
 * To run a query within a React component, call `useActivePortChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivePortChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivePortChargesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      portChargeFilters: // value for 'portChargeFilters'
 *   },
 * });
 */
export function useActivePortChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<ActivePortChargesQueryResult, ActivePortChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ActivePortChargesQueryResult, ActivePortChargesQueryVariables>(ActivePortChargesDocument, options);
}
export function useActivePortChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivePortChargesQueryResult, ActivePortChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ActivePortChargesQueryResult, ActivePortChargesQueryVariables>(ActivePortChargesDocument, options);
}
export type ActivePortChargesQueryHookResult = ReturnType<typeof useActivePortChargesQuery>;
export type ActivePortChargesLazyQueryHookResult = ReturnType<typeof useActivePortChargesLazyQuery>;
