import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import { getTerminalOptionLabel } from '@zen/Components/TerminalSelect/helpers';
import type { Option } from '@zen/DesignSystem';
import type { Terminal } from '@zen/Networks';

interface Props extends FormFieldProps {
  disabled?: boolean;
  ports: Terminal[];
}

const FormFreightChargePortSelect: FC<Props> = ({ ports, disabled, ...formFieldProps }) => {
  const options: Option<Terminal>[] = ports.map((port: Terminal) => ({
    value: port,
    label: port.label || ''
  }));

  const formatOptionLabel = (option: Option<Terminal>) => {
    return getTerminalOptionLabel(option.value);
  };

  return <FormSelect formatOptionLabel={formatOptionLabel} isDisabled={disabled} options={options} {...formFieldProps} />;
};

export default FormFreightChargePortSelect;
