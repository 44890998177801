import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteScimApiKeyMutationVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type DeleteScimApiKeyMutationResult = { __typename?: 'Mutation' } & {
  deleteScimApiKey?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteScimApiKeyPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path'>>>;
    }
  >;
};

export const DeleteScimApiKeyDocument = /* #__PURE__ */ gql`
  mutation deleteScimApiKey($id: String!) {
    deleteScimApiKey(id: $id) {
      errors {
        path
      }
    }
  }
`;

/**
 * __useDeleteScimApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteScimApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScimApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScimApiKeyMutation, { data, loading, error }] = useDeleteScimApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScimApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteScimApiKeyMutationResult, DeleteScimApiKeyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteScimApiKeyMutationResult, DeleteScimApiKeyMutationVariables>(DeleteScimApiKeyDocument, options);
}
export type DeleteScimApiKeyMutationHookResult = ReturnType<typeof useDeleteScimApiKeyMutation>;
