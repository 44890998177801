const CUSTOMER_ADMIN_RELATIVE_INDEX_PATH = 'customer-admin';

export const CUSTOMER_ADMIN_ROUTE_PREFIX = `/dashboard/${CUSTOMER_ADMIN_RELATIVE_INDEX_PATH}`;

interface CustomerAdminRoutes {
  allShipments: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  allShipmentsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const customerAdminRoutes: CustomerAdminRoutes = {
  allShipments: {
    path: `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments`,
    relativePath: 'all-shipments',
    getUrl: () => {
      return `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments`;
    }
  },
  allShipmentsDetailsPage: {
    path: `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments/details/:id/:sectionName?`,
    relativePath: 'all-shipments/details/:id/:sectionName?',
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${CUSTOMER_ADMIN_ROUTE_PREFIX}/all-shipments/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  index: {
    path: `${CUSTOMER_ADMIN_ROUTE_PREFIX}/*`,
    getUrl: () => CUSTOMER_ADMIN_ROUTE_PREFIX,
    relativePath: `${CUSTOMER_ADMIN_RELATIVE_INDEX_PATH}/*`
  }
};

export default customerAdminRoutes;
