import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrderTableQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  bookingState?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.PurchaseOrderBookingStateEnum> | GraphQLTypes.PurchaseOrderBookingStateEnum
  >;
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  manufacturerIds?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>> | GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>
  >;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.FilterOptionsEnum>;
  cargoReadyDateBetween?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  lastUpdatedBetween?: GraphQLTypes.InputMaybe<GraphQLTypes.DateRangeInput>;
  portOfLoadUnlocode?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  incoterms?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  issues?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrdersIssuesFilterInput>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  portOfDestinationUnlocode?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  status?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrderStatusEnum>;
}>;

export type OrderTableQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & Pick<GraphQLTypes.PurchaseOrderConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'PurchaseOrder' } & Pick<
              GraphQLTypes.PurchaseOrder,
              | 'bookedCbm'
              | 'modeOfTransport'
              | 'fulfilledCbm'
              | 'id'
              | 'lotsBookedCount'
              | 'lotsTotalCount'
              | 'orderedCbm'
              | 'orderReferenceNumber'
              | 'requiredDeliveryDate'
            > & {
                account?: GraphQLTypes.Maybe<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName'>>;
                bookings?: GraphQLTypes.Maybe<
                  Array<{ __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'>>
                >;
                canClose: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canRemove: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canViewCustomer: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                cargoReadyDateEstimate?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderEstimatedDateType' } & Pick<
                    GraphQLTypes.PurchaseOrderEstimatedDateType,
                    'date' | 'delayInDays' | 'lastUpdatedAt'
                  >
                >;
                customer?: GraphQLTypes.Maybe<{ __typename?: 'Customer' } & Pick<GraphQLTypes.Customer, 'name' | 'uuid'>>;
                deliveryEstimate?: GraphQLTypes.Maybe<
                  { __typename?: 'PurchaseOrderEstimatedDateType' } & Pick<
                    GraphQLTypes.PurchaseOrderEstimatedDateType,
                    'date' | 'delayInDays' | 'lastUpdatedAt'
                  >
                >;
                destination?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                >;
                issues?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'PurchaseOrderIssue' } & Pick<
                      GraphQLTypes.PurchaseOrderIssue,
                      'id' | 'category' | 'severity' | 'state' | 'title'
                    >
                  >
                >;
                lotsByStageCount?: GraphQLTypes.Maybe<
                  Array<
                    { __typename?: 'PurchaseOrderLotStageCountType' } & Pick<
                      GraphQLTypes.PurchaseOrderLotStageCountType,
                      'count' | 'stage'
                    >
                  >
                >;
                manufacturer?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                        >;
                      })
                  | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short'>
                        >;
                      })
                >;
                origin?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                        >;
                      })
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const OrderTableQueryDocument = /* #__PURE__ */ gql`
  query orderTableQuery(
    $accountIds: [String!]
    $after: String
    $before: String
    $bookingState: [PurchaseOrderBookingStateEnum!]
    $customerUuid: String
    $manufacturerIds: [String]
    $filterBy: FilterOptionsEnum
    $cargoReadyDateBetween: DateRangeInput
    $lastUpdatedBetween: DateRangeInput
    $portOfLoadUnlocode: String
    $incoterms: String
    $issues: PurchaseOrdersIssuesFilterInput
    $order: SortInput
    $portOfDestinationUnlocode: String
    $status: PurchaseOrderStatusEnum
  ) {
    purchaseOrders(
      accountIds: $accountIds
      after: $after
      before: $before
      bookingState: $bookingState
      cargoReadyDateBetween: $cargoReadyDateBetween
      lastUpdatedBetween: $lastUpdatedBetween
      customerUuid: $customerUuid
      filterBy: $filterBy
      incoterms: $incoterms
      issues: $issues
      manufacturerIds: $manufacturerIds
      order: $order
      portOfLoadUnlocode: $portOfLoadUnlocode
      portOfDestinationUnlocode: $portOfDestinationUnlocode
      status: $status
    ) {
      nodes {
        account {
          id
          tradingName
        }
        bookedCbm
        bookings {
          zencargoReference
        }
        canClose {
          value
        }
        canRemove {
          value
        }
        canUpdate {
          value
        }
        canViewCustomer {
          value
        }
        cargoReadyDateEstimate {
          date
          delayInDays
          lastUpdatedAt
        }
        customer {
          name
          uuid
        }
        deliveryEstimate {
          date
          delayInDays
          lastUpdatedAt
        }
        destination {
          id
          label {
            long
          }
        }
        modeOfTransport
        fulfilledCbm
        id
        issues {
          id
          category
          severity
          state
          title
        }
        lotsBookedCount
        lotsByStageCount {
          count
          stage
        }
        lotsTotalCount
        manufacturer {
          id
          label {
            short
          }
        }
        orderedCbm
        orderReferenceNumber
        origin {
          id
          label {
            long
          }
        }
        requiredDeliveryDate
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOrderTableQuery__
 *
 * To run a query within a React component, call `useOrderTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderTableQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      bookingState: // value for 'bookingState'
 *      customerUuid: // value for 'customerUuid'
 *      manufacturerIds: // value for 'manufacturerIds'
 *      filterBy: // value for 'filterBy'
 *      cargoReadyDateBetween: // value for 'cargoReadyDateBetween'
 *      lastUpdatedBetween: // value for 'lastUpdatedBetween'
 *      portOfLoadUnlocode: // value for 'portOfLoadUnlocode'
 *      incoterms: // value for 'incoterms'
 *      issues: // value for 'issues'
 *      order: // value for 'order'
 *      portOfDestinationUnlocode: // value for 'portOfDestinationUnlocode'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useOrderTableQuery(baseOptions?: Apollo.QueryHookOptions<OrderTableQueryResult, OrderTableQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrderTableQueryResult, OrderTableQueryVariables>(OrderTableQueryDocument, options);
}
export function useOrderTableQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrderTableQueryResult, OrderTableQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrderTableQueryResult, OrderTableQueryVariables>(OrderTableQueryDocument, options);
}
export type OrderTableQueryHookResult = ReturnType<typeof useOrderTableQuery>;
export type OrderTableQueryLazyQueryHookResult = ReturnType<typeof useOrderTableQueryLazyQuery>;
