import { type FC, type ReactNode, useState } from 'react';

import { ContextMenu, Dialog, type MenuItemType } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useToggleParentAccountAccessMutation } from '../../graphql';
import DeleteMemberModal from '../DeleteMemberModal';
import DeleteMemberModalButton from '../DeleteMemberModalButton';

interface Props {
  accountHasBusinessUnits: boolean;
  accountName: string;
  canAccessParentAccount: boolean;
  isProfileCompleted: boolean;
  memberId: string;
  memberName: string;
}

const AccountMembersActions: FC<Props> = (props: Props) => {
  const { accountName, accountHasBusinessUnits, isProfileCompleted, memberId, memberName, canAccessParentAccount } = props;

  const [isDeleteMemberModalOpen, setIsDeleteMemberModalOpen] = useState<boolean>(false);
  const [isToggleAccessModalOpen, setIsToggleAccessModalOpen] = useState<boolean>(false);
  const [toggleParentAccess, { loading }] = useToggleParentAccountAccessMutation();
  const { addError, addSuccess } = useNotification();

  const toggleAccess = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        toggleParentAccess({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountMembers'],
          variables: {
            input: {
              canAccessParentAccount: !canAccessParentAccount,
              memberId
            }
          }
        }),
      onError: () => {
        closeToggleAccessModal();

        addError(`Something went wrong when trying to ${canAccessParentAccount ? 'revoke' : 'grant'} access.`, {
          label: 'Try again',
          onClick: toggleAccess
        });
      },
      onSuccess: () => {
        closeToggleAccessModal();
        addSuccess(`Access ${canAccessParentAccount ? 'revoked' : 'granted'}.`);
      }
    });
  };

  const closeToggleAccessModal = () => setIsToggleAccessModalOpen(false);

  const handleToggleAccess = (): void => {
    if (canAccessParentAccount) {
      return setIsToggleAccessModalOpen(true);
    }

    toggleAccess();
  };

  const renderActions = (): ReactNode => {
    if (!accountHasBusinessUnits) {
      return <DeleteMemberModalButton isProfileCompleted={isProfileCompleted} memberId={memberId} memberName={memberName} />;
    }

    const items: MenuItemType[] = [
      {
        label: `${canAccessParentAccount ? 'Revoke' : 'Grant'} access to ${accountName}`,
        onClick: handleToggleAccess,
        icon: 'zicon-office'
      },
      {
        label: isProfileCompleted ? 'Delete' : 'Revoke invitation',
        onClick: () => setIsDeleteMemberModalOpen(true),
        icon: 'zicon-trash'
      }
    ];

    return <ContextMenu inline={false} items={items} />;
  };

  return (
    <>
      <DeleteMemberModal
        isOpen={isDeleteMemberModalOpen}
        isProfileCompleted={isProfileCompleted}
        memberId={memberId}
        memberName={memberName}
        onClose={() => setIsDeleteMemberModalOpen(false)}
      />
      <Dialog
        confirmLabel="Revoke access"
        header={`Revoke access to ${accountName}?`}
        isLoading={loading}
        isOpen={isToggleAccessModalOpen}
        message={`Access to the parent account ${accountName} will be revoked without notification. Access to assigned business units will remain unaffected. You can easily grant access again if needed.`}
        onClose={closeToggleAccessModal}
        onConfirm={toggleAccess}
      />
      {renderActions()}
    </>
  );
};

export default AccountMembersActions;
