import type { Option } from '@zen/DesignSystem';
import type { RoadTrackedShipmentFilterOptions } from '@zen/graphql/types.generated';
import { codeToCountryName } from '@zen/utils/countries';
import { getDateRangeInput } from '@zen/utils/date';
import { omitEmptyValues } from '@zen/utils/Filtering/helpers';
import type { Optional } from '@zen/utils/typescript';

import {
  StopType,
  TrackedShipmentFilterOptions,
  TrackedShipmentFilters,
  TrackedShipmentFiltersInput,
  TrackedShipmentTimeframe
} from './types';

const timeframeMapping: Record<TrackedShipmentTimeframe, number> = {
  [TrackedShipmentTimeframe.NEXT_1_DAY]: 1,
  [TrackedShipmentTimeframe.NEXT_3_DAYS]: 3,
  [TrackedShipmentTimeframe.NEXT_7_DAYS]: 7,
  [TrackedShipmentTimeframe.NEXT_14_DAYS]: 14
};

export const buildCountryCodeOptions = (countryCodes: string[]): Option<string>[] => {
  return countryCodes
    .map((code) => ({
      value: code,
      label: codeToCountryName(code)
    }))
    .sort((a, b): number => a.label.localeCompare(b.label));
};

export const prepareFilterOptions = (data: Optional<RoadTrackedShipmentFilterOptions>): TrackedShipmentFilterOptions => {
  if (!data) {
    return {
      firstStopCountryCodes: [],
      lastStopCountryCodes: []
    };
  }

  return {
    lastStopCountryCodes: buildCountryCodeOptions(data.lastStopCountryCodes),
    firstStopCountryCodes: buildCountryCodeOptions(data.firstStopCountryCodes)
  };
};

export const buildEventFilter = (event: StopType, timeframe: TrackedShipmentTimeframe) => {
  const daysDifference: number = timeframeMapping[timeframe];

  return {
    type: event,
    dateRange: getDateRangeInput(daysDifference)
  };
};

export const buildFilters = (filters: TrackedShipmentFilters): TrackedShipmentFiltersInput => {
  const activeFilters: Partial<TrackedShipmentFilters> = omitEmptyValues<TrackedShipmentFilters>(filters);

  const { event, timeframe, originCountryCode, destinationCountryCode } = activeFilters;

  return {
    ...(event && timeframe && { event: buildEventFilter(event, timeframe) }),
    ...(originCountryCode && { firstStopCountryCodes: [originCountryCode] }),
    ...(destinationCountryCode && { lastStopCountryCodes: [destinationCountryCode] })
  };
};
