import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Headline, Icon, Tooltip } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import CollapsibleElement from '../CollapsibleElement';

interface Props {
  disabledTooltip?: string;
  headline: string;
  iconName: IconName;
  isCompleted: boolean;
  isDisabled?: boolean;
  isExpanded: boolean;
  onExpand: () => void;
  tagline: string;
}

const Step: FC<Props> = (props) => {
  const { children, disabledTooltip, headline, iconName, isCompleted, isDisabled, isExpanded, onExpand, tagline } = props;

  const icon: IconName = isCompleted && !isDisabled && !isExpanded ? 'zicon-tick' : iconName;

  const headerClassNames: string = cx(
    {
      'cursor-pointer': !isDisabled && !isExpanded,
      'cursor-not-allowed': isDisabled
    },
    'flex p-4 space-x-4 items-center'
  );
  const classNames: string = cx(
    {
      'hover:border-azure-base': !isDisabled && !isExpanded
    },
    'border border-solid rounded border-grey-lighter transition-all duration-300'
  );

  const iconWrapperClassNames: string = cx(
    {
      'bg-green-base': isCompleted && !isDisabled && !isExpanded,
      'bg-azure-base': isExpanded,
      'bg-grey-base': isDisabled
    },
    'flex items-center justify-center w-8 h-8 rounded-full'
  );

  const handleClick = (): void => {
    if (isDisabled || isExpanded) return;

    onExpand();
  };

  const renderHeader = (): ReactNode => {
    const header: ReactNode = (
      <div className={headerClassNames} data-testid="step-header" onClick={handleClick}>
        <div className={iconWrapperClassNames}>
          <Icon className="text-xs text-white" icon={icon} />
        </div>
        <div className="flex-1">
          <Headline level={4}>{headline}</Headline>
          <div className="mt-1 text-sm text-grey-base">{tagline}</div>
        </div>
      </div>
    );

    if (isDisabled && disabledTooltip) {
      return (
        <Tooltip placement="top-start" tooltipContent={disabledTooltip}>
          {header}
        </Tooltip>
      );
    }

    return header;
  };

  return (
    <div className={classNames}>
      {renderHeader()}
      <CollapsibleElement isOpened={isExpanded}>
        <div className="px-4 pb-4">{children}</div>
      </CollapsibleElement>
    </div>
  );
};

export default Step;
