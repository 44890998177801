import type { RoadLegValues } from './types';

export const prepareInitialValues = (): RoadLegValues => {
  return {
    haulier: null,
    collection: {
      date: '',
      time: '',
      startTime: '',
      endTime: ''
    },
    delivery: {
      date: '',
      time: '',
      startTime: '',
      endTime: ''
    }
  };
};
