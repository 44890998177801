import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductFilterValuesQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableFiltersInput>;
}>;

export type ProductFilterValuesQueryResult = { __typename?: 'Query' } & {
  shipmentsTableProductsFilterValues: { __typename?: 'ShipmentsTableFilterValueConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'ShipmentsTableFilterValue' } & Pick<
            GraphQLTypes.ShipmentsTableFilterValue,
            'key' | 'value' | 'additionalValue'
          >
        >
      >
    >;
  };
};

export const ProductFilterValuesDocument = /* #__PURE__ */ gql`
  query productFilterValues($accountIds: [String!]!, $searchTerm: String, $first: Int, $filterBy: ShipmentsTableFiltersInput) {
    shipmentsTableProductsFilterValues(accountIds: $accountIds, searchTerm: $searchTerm, first: $first, filterBy: $filterBy) {
      nodes {
        key
        value
        additionalValue
      }
    }
  }
`;

/**
 * __useProductFilterValuesQuery__
 *
 * To run a query within a React component, call `useProductFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductFilterValuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      searchTerm: // value for 'searchTerm'
 *      first: // value for 'first'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useProductFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<ProductFilterValuesQueryResult, ProductFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductFilterValuesQueryResult, ProductFilterValuesQueryVariables>(ProductFilterValuesDocument, options);
}
export function useProductFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductFilterValuesQueryResult, ProductFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductFilterValuesQueryResult, ProductFilterValuesQueryVariables>(
    ProductFilterValuesDocument,
    options
  );
}
export type ProductFilterValuesQueryHookResult = ReturnType<typeof useProductFilterValuesQuery>;
export type ProductFilterValuesLazyQueryHookResult = ReturnType<typeof useProductFilterValuesLazyQuery>;
