import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking } from '@zen/Booking';
import type { Optional } from '@zen/utils/typescript';

import {
  isCarriageTransshipmentStop,
  isCollectionAndDeliveryStop,
  isCollectionStop,
  isCustomsStop,
  isVehicleChangeStop
} from '../../helpers';
import { JourneyShippingMilestoneNameEnum, JourneyStopActionEnum } from '../../types';
import type { MilestoneParams } from './types';

const getArrivedLabel = (isMilestoneCompleted: boolean): string => {
  return isMilestoneCompleted ? 'Arrived' : 'Arriving';
};

const getDepartedLabel = (isMilestoneCompleted: boolean): string => {
  return isMilestoneCompleted ? 'Departed' : 'Departing';
};

const getVehicleArrivedAtWarehouseLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getArrivedLabel(isMilestoneCompleted);

  if (isCollectionStop(stopAction)) {
    return `${actionLabel} for collection`;
  }

  if (isCollectionAndDeliveryStop(stopAction)) {
    return `${actionLabel} at stop`;
  }

  return isMilestoneCompleted ? 'Delivered' : 'Delivering';
};

const getVehicleDepartedFromWarehouseLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getDepartedLabel(isMilestoneCompleted);

  if (isCollectionStop(stopAction)) {
    return isMilestoneCompleted ? 'Departed collection point' : 'Departing collection point';
  }

  if (isCollectionAndDeliveryStop(stopAction)) {
    return `${actionLabel} from stop`;
  }

  return isMilestoneCompleted ? 'Vehicle departed' : 'Vehicle departure';
};

const getVehicleArrivedAtStopLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getArrivedLabel(isMilestoneCompleted);

  if (isCustomsStop(stopAction)) {
    return `${actionLabel} at Customs`;
  }

  if (isVehicleChangeStop(stopAction)) {
    return isMilestoneCompleted ? 'Vehicle arrived for truck swap' : 'Vehicle arriving for truck swap';
  }

  return `${actionLabel} at stop`;
};

const getVehicleDepartedFromStopLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getDepartedLabel(isMilestoneCompleted);

  if (isCustomsStop(stopAction)) {
    return `${actionLabel} from Customs`;
  }

  if (isVehicleChangeStop(stopAction)) {
    return isMilestoneCompleted ? 'Vehicle departed' : 'Vehicle departure';
  }

  return `${actionLabel} from stop`;
};

const getAircraftArrivedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getArrivedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} at transshipment airport`;
  }

  return `${actionLabel} at destination airport`;
};

const getAircraftDepartedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getDepartedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} from transshipment airport`;
  }

  return `${actionLabel} from origin airport`;
};

const getTrainArrivedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getArrivedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} at transshipment rail station`;
  }

  return `${actionLabel} at destination rail station`;
};

const getTrainDepartedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getDepartedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} from transshipment rail station`;
  }

  return `${actionLabel} from rail station`;
};

const getVesselArrivedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getArrivedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} at transshipment port`;
  }

  return `${actionLabel} at port of destination`;
};

const getVesselDepartedLabel = (stopAction: JourneyStopActionEnum, isMilestoneCompleted: boolean): string => {
  const actionLabel: string = getDepartedLabel(isMilestoneCompleted);

  if (isCarriageTransshipmentStop(stopAction)) {
    return `${actionLabel} from transshipment port`;
  }

  return `${actionLabel} port of loading`;
};

const getCargoPickedUpLabel = (modeOfTransport: Optional<ModeOfTransport>, isMilestoneCompleted: boolean): string => {
  if (isAirBooking(modeOfTransport)) {
    return isMilestoneCompleted ? 'Cargo picked up' : 'Cargo to be picked up';
  }

  return isMilestoneCompleted ? 'Gated out full' : 'Gating out full';
};

export const getMilestoneLabel = ({ completed, modeOfTransport, name, stopAction }: MilestoneParams): string => {
  const mapping: Record<JourneyShippingMilestoneNameEnum, string> = {
    [JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED]: getAircraftArrivedLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.AIRCRAFT_DEPARTED]: getAircraftDepartedLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.CARGO_AVAILABLE_FOR_PICKUP]: completed
      ? 'Cargo available for pickup'
      : 'Cargo not yet available for pickup',
    [JourneyShippingMilestoneNameEnum.CARGO_LOADED]: completed ? 'Cargo loaded' : 'Cargo to be loaded',
    [JourneyShippingMilestoneNameEnum.CARGO_PICKED_UP]: getCargoPickedUpLabel(modeOfTransport, completed),
    [JourneyShippingMilestoneNameEnum.CARGO_READY_DATE]: 'Cargo ready for collection',
    [JourneyShippingMilestoneNameEnum.CARGO_RECEIVED_AT_AIRLINE_SHED]: completed
      ? 'Arrived at airline shed'
      : 'Arriving at airline shed',
    [JourneyShippingMilestoneNameEnum.CARGO_UNLOADED]: completed ? 'Cargo unloaded' : 'Cargo to be unloaded',
    [JourneyShippingMilestoneNameEnum.GATE_IN]: completed ? 'Gated in full' : 'Gating in full',
    [JourneyShippingMilestoneNameEnum.GATE_IN_EMPTY]: completed ? 'Gated in empty' : 'Gate in empty',
    [JourneyShippingMilestoneNameEnum.GATE_OUT_EMPTY]: completed ? 'Gated out empty' : 'Gate out empty',
    [JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED]: getTrainArrivedLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.TRAIN_DEPARTED]: getTrainDepartedLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_STOP]: getVehicleArrivedAtStopLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE]: getVehicleArrivedAtWarehouseLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_STOP]: getVehicleDepartedFromStopLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE]: getVehicleDepartedFromWarehouseLabel(
      stopAction,
      completed
    ),
    [JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED]: getVesselArrivedLabel(stopAction, completed),
    [JourneyShippingMilestoneNameEnum.VESSEL_DEPARTED]: getVesselDepartedLabel(stopAction, completed)
  };

  return mapping[name];
};
