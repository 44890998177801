import cx from 'classnames';
import type { FC, MouseEvent, MouseEventHandler, ReactNode } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import { IconButton } from '../Button';

interface Props {
  className?: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  label: ReactNode;
  onDelete?: MouseEventHandler<HTMLDivElement> | undefined;
  onSelect?: () => void;
  size?: 'compact' | 'default';
}

export const displayName = 'Design System/Chip';

const Chip: FC<Props> = (props) => {
  const { className, label, onDelete, onSelect, isSelected, isDisabled, size = 'default' } = props;

  const { trackEvent } = useTracking();

  const chipClassNames: string = cx(
    {
      'active:bg-navy-light cursor-pointer': !isDisabled,
      'bg-grey-lightest': !isSelected,
      'hover:bg-grey-lighter': !isSelected && !isDisabled,
      'bg-navy-light': isSelected,
      'opacity-50 cursor-not-allowed': isDisabled,
      'py-[0.375rem] px-2': size === 'default',
      'py-0.5 px-[0.375rem]': size === 'compact'
    },
    'text-sm inline-flex items-center text-navy-base leading-5 rounded space-x-2',
    className
  );

  const handleClick = (event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>): void => {
    if (isDisabled) {
      return;
    }

    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);

    onSelect?.();
  };

  return (
    <div
      className={chipClassNames}
      data-component="chip"
      data-testid={isSelected ? 'selected-chip' : 'chip'}
      onClick={handleClick}
      {...createTrackingParentAttribute('chip')}
    >
      <span className="whitespace-nowrap truncate" {...createTrackingLabelAttribute('chip')}>
        {label}
      </span>
      {!isDisabled && onDelete && (
        <IconButton
          className="text-navy-base"
          icon="zicon-close"
          onClick={(event) => {
            event.stopPropagation();

            (onDelete as () => void)();
          }}
          size="small"
          variant="ghost"
        />
      )}
    </div>
  );
};

export type { Props as ChipProps };

export default Chip;
