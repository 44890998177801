import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingDocumentTypesQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetBookingDocumentTypesQueryResult = { __typename?: 'Query' } & {
  bookingDocumentTypes: { __typename?: 'BookingDocumentTypeConnection' } & Pick<
    GraphQLTypes.BookingDocumentTypeConnection,
    'totalCount'
  > & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'BookingDocumentType' } & Pick<
              GraphQLTypes.BookingDocumentType,
              'id' | 'description' | 'name' | 'title'
            > & {
                permissions: { __typename?: 'Permissions' } & Pick<
                  GraphQLTypes.Permissions,
                  'agentForwarder' | 'customerUser' | 'manufacturer'
                >;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetBookingDocumentTypesDocument = /* #__PURE__ */ gql`
  query getBookingDocumentTypes(
    $paginated: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchQuery: String
    $order: SortInput
  ) {
    bookingDocumentTypes(
      paginated: $paginated
      after: $after
      before: $before
      first: $first
      last: $last
      textContains: $searchQuery
      order: $order
    ) {
      nodes {
        id
        description
        name
        title
        permissions {
          agentForwarder
          customerUser
          manufacturer
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetBookingDocumentTypesQuery__
 *
 * To run a query within a React component, call `useGetBookingDocumentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDocumentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDocumentTypesQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetBookingDocumentTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBookingDocumentTypesQueryResult, GetBookingDocumentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingDocumentTypesQueryResult, GetBookingDocumentTypesQueryVariables>(
    GetBookingDocumentTypesDocument,
    options
  );
}
export function useGetBookingDocumentTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingDocumentTypesQueryResult, GetBookingDocumentTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingDocumentTypesQueryResult, GetBookingDocumentTypesQueryVariables>(
    GetBookingDocumentTypesDocument,
    options
  );
}
export type GetBookingDocumentTypesQueryHookResult = ReturnType<typeof useGetBookingDocumentTypesQuery>;
export type GetBookingDocumentTypesLazyQueryHookResult = ReturnType<typeof useGetBookingDocumentTypesLazyQuery>;
