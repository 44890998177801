import { FetchPolicy, useApolloClient } from '@apollo/client';
import type { DocumentNode } from 'graphql';
import { get, isEqual } from 'lodash';
import { useRef, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import type { Optional } from '@zen/utils/typescript';

import { defaultPageInfo } from './helpers';
import type { BasicQueryResult, PaginatedVariables } from './types';

export interface AllPaginatedResults<NodeType> {
  data: NodeType[];
  error: boolean;
  isLoading: boolean;
  totalCount?: number;
}

interface Props<T> {
  document: DocumentNode;
  fetchPolicy?: FetchPolicy;
  limit?: number;
  responsePath: string;
  skip?: boolean;
  variables?: T;
}

const useAllPaginatedResults = <Result, Variables extends PaginatedVariables, NodeType>(
  props: Props<Variables>
): AllPaginatedResults<NodeType> => {
  const { document, fetchPolicy, limit = 50, responsePath, skip, variables } = props;

  const { query } = useApolloClient();
  const [data, setData] = useState<NodeType[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);

  const variablesRef = useRef<Optional<Variables>>(null);

  const fetchData = async (after: string | undefined = undefined): Promise<void> => {
    try {
      const { data: responseData } = await query<Result, Variables>({
        query: document,
        fetchPolicy,
        variables: { ...variables, first: limit, after } as Variables
      });

      if (!isEqual(variablesRef.current, variables)) return;

      const {
        nodes = [],
        pageInfo,
        totalCount: count
      } = get(responseData, responsePath, {
        pageInfo: defaultPageInfo,
        nodes: [],
        totalCount: 0
      }) as BasicQueryResult<NodeType>;

      setData((prev) => [...prev, ...nodes]);

      if (count && count !== totalCount) {
        setTotalCount(count);
      }

      if (pageInfo.hasNextPage === true) {
        fetchData(pageInfo.endCursor);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      setError(true);
    }
  };

  useDeepCompareEffect(() => {
    if (skip) return;

    variablesRef.current = variables;

    setData(() => []);
    fetchData();
  }, [variables, skip]);

  return { data, isLoading, error, totalCount };
};

export default useAllPaginatedResults;
