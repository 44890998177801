import type { FC, ReactNode } from 'react';

import Address from '@zen/Components/Address';
import { isOrgLocType } from '@zen/Networks/networksHelpers';
import type { NetworksOrgLoc, OrganisationLocation } from '@zen/Networks/types';

interface Props {
  tradeParty: OrganisationLocation;
}

const NetworkContactDetails: FC<Props> = ({ tradeParty }) => {
  const network = tradeParty as NetworksOrgLoc;
  const contact = network?.contact;
  const organisation = network?.organisation;
  const location = network?.location;

  const fullName: string = contact ? `${contact.firstName} ${contact.lastName}` : '-';

  const renderLocationDetails = (): ReactNode => {
    const address = location?.address;

    return (
      <div className="mt-4">
        {isOrgLocType(tradeParty) && tradeParty?.location?.name && <p className="font-bold">{tradeParty.location.name}</p>}
        {address && <Address address={address} />}
      </div>
    );
  };

  return (
    <div className="w-full relative text-grey-dark text-sm" data-testid="network-contact-details">
      <p className="font-bold leading-normal">{fullName}</p>
      <p className="text-grey-base text-xs mb-1">{organisation?.name}</p>
      <div>
        {contact && (
          <>
            <p>{contact.email}</p>
            <p className="mb-4">{contact.phoneNumber}</p>
          </>
        )}
        {renderLocationDetails()}
      </div>
    </div>
  );
};

export default NetworkContactDetails;
