import type { FC } from 'react';

import FormLabel from '@zen/Components/Form/FormLabel';
import type { Option } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';
import { defaultErrorMessage } from '@zen/utils/validation';

import { useGetBookingCoordinatorsByTypeQuery } from './graphql';
import { getCoordinatorsOptions } from './helpers';
import type { BookingManager, ManagerTeam } from './types';

interface Props {
  additionalOptions?: Option<string>[];
  currentCoordinator?: Optional<string>;
  disabled?: boolean;
  label: string;
  name: string;
  onCoordinatorUpdate: (coordinatorId: string) => void;
  type: ManagerTeam;
}

const BookingCoordinatorSelect: FC<Props> = (props) => {
  const { additionalOptions = [], currentCoordinator, disabled, label, name, onCoordinatorUpdate, type } = props;

  const { data, error } = useGetBookingCoordinatorsByTypeQuery({
    variables: {
      managerType: type
    }
  });

  const handleChange = (coordinatorId: Nullable<string>): void => {
    if (coordinatorId) {
      onCoordinatorUpdate(coordinatorId);
    }
  };

  const bookingCoordinators = (data?.bookingManagers || []) as BookingManager[];

  return (
    <div>
      <FormLabel label={label} />
      <Select
        key={label}
        isDisabled={disabled}
        name={name}
        onChange={handleChange}
        options={[...additionalOptions, ...getCoordinatorsOptions(bookingCoordinators)]}
        value={currentCoordinator}
      />
      {error && <div className="text-sm text-red-base">{defaultErrorMessage}</div>}
    </div>
  );
};

export default BookingCoordinatorSelect;
