import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Nullable } from '@zen/utils/typescript';

import type { CargoFullContainerVariant } from '../../types';
import { getContainerTypeOptions } from './helpers';

interface Props extends FormFieldProps {
  containerVariant?: Nullable<CargoFullContainerVariant>;
  isDisabled?: boolean;
}

const FormContainerTypeSelect: FC<Props> = ({ containerVariant, ...props }) => {
  return (
    <FormSelect isClearable={true} options={getContainerTypeOptions(containerVariant)} renderMenuInPortal={true} {...props} />
  );
};

export default FormContainerTypeSelect;
