import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type NotificationsUnsubscribeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UnsubscribeInput;
}>;

export type NotificationsUnsubscribeMutationResult = { __typename?: 'Mutation' } & {
  result?: GraphQLTypes.Maybe<
    { __typename?: 'UnsubscribePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const NotificationsUnsubscribeDocument = /* #__PURE__ */ gql`
  mutation notificationsUnsubscribe($input: UnsubscribeInput!) {
    result: notificationsUnsubscribe(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useNotificationsUnsubscribeMutation__
 *
 * To run a mutation, you first call `useNotificationsUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotificationsUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notificationsUnsubscribeMutation, { data, loading, error }] = useNotificationsUnsubscribeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNotificationsUnsubscribeMutation(
  baseOptions?: Apollo.MutationHookOptions<NotificationsUnsubscribeMutationResult, NotificationsUnsubscribeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NotificationsUnsubscribeMutationResult, NotificationsUnsubscribeMutationVariables>(
    NotificationsUnsubscribeDocument,
    options
  );
}
export type NotificationsUnsubscribeMutationHookResult = ReturnType<typeof useNotificationsUnsubscribeMutation>;
