import type { FC } from 'react';

import type { FormFieldProps } from '@zen/Components/Form/FormField';
import { SortingOrder } from '@zen/types';

import FormSelect from '../FormSelect';
import { usePurchaseOrderDelayOptions } from './hooks';

const FormPurchaseOrderDelayReasonSelect: FC<FormFieldProps> = (props) => {
  const { options, loading, refetch } = usePurchaseOrderDelayOptions();

  const handleInputChange = (query: string = ''): void => {
    refetch({
      searchQuery: query,
      order: { field: 'reason', direction: SortingOrder.ASC }
    });
  };

  return (
    <FormSelect
      isClearable={true}
      isLoading={loading}
      isSearchable={true}
      onInputChange={handleInputChange}
      options={options}
      {...props}
    />
  );
};

export default FormPurchaseOrderDelayReasonSelect;
