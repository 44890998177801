import pluralize from 'pluralize';

import { getRelativeDateDifference } from '../date/comparing';
import { formatDate } from '../date/formatting';
import type { DateFormatOptions, RelativeTimeOptions } from '../types';

export const convertDaysToSeconds = (days: number = 0): number => {
  return days * 24 * 60 * 60;
};

const getDatePlaceholderPopulator = (date: string) => {
  return (dateFormatString: string) => {
    return dateFormatString.replace(/\[(.+?)\]/i, (_, format: keyof DateFormatOptions) => {
      return formatDate(date, format);
    });
  };
};

const stripTimePart = (dateTime: string): string => dateTime.split('T')?.shift() || '';

export const getRelativeTime = (dateTime: string, customRelativeTimeText?: Partial<RelativeTimeOptions>): string => {
  const date: string = stripTimePart(dateTime);
  const populateDatePlaceholders = getDatePlaceholderPopulator(date);

  const { days, months, years } = getRelativeDateDifference(dateTime);

  const defaultRelativeTimeText: RelativeTimeOptions = {
    sameDay: 'Today',
    nextDay: 'Tomorrow',
    nextWeek: '[DDDD]',
    lastDay: 'Yesterday',
    lastFewDays: `${days} days ago`,
    lastFewMonths: `${months} ${pluralize('month', months)} ago`,
    lastFewYears: `${years} ${pluralize('year', years)} ago`,
    afterNextWeek: '[MMM_D_YYYY]'
  };

  const relativeTimeText: RelativeTimeOptions = { ...defaultRelativeTimeText, ...customRelativeTimeText };

  const { sameDay, nextDay, lastDay, lastFewDays, lastFewMonths, afterNextWeek, lastFewYears, nextWeek } = relativeTimeText;

  switch (true) {
    case years >= 1:
      return populateDatePlaceholders(lastFewYears);
    case years <= -1:
      return populateDatePlaceholders(afterNextWeek);
    case months >= 1:
      return populateDatePlaceholders(lastFewMonths);
    case months <= -1:
      return populateDatePlaceholders(afterNextWeek);
    case days > 1:
      return populateDatePlaceholders(lastFewDays);
    case days === 1:
      return populateDatePlaceholders(lastDay);
    case days === 0:
      return populateDatePlaceholders(sameDay);
    case days === -1:
      return populateDatePlaceholders(nextDay);
    case days <= -7:
      return populateDatePlaceholders(afterNextWeek);
    case days <= -2:
      return populateDatePlaceholders(nextWeek);
    default:
      return populateDatePlaceholders(afterNextWeek);
  }
};
