import { JourneyToggleCollectionReasonEnum } from '@zen/Journey/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

export const getTooltipMessage = (reason: Optional<JourneyToggleCollectionReasonEnum>): Nullable<string> => {
  if (!reason) return null;

  const defaultMessage = 'First you need to remove all actual dates.';

  const labelMapping: Record<JourneyToggleCollectionReasonEnum, string> = {
    [JourneyToggleCollectionReasonEnum.BOOKING_DEPARTED_ORIGIN_TERMINAL]: defaultMessage,
    [JourneyToggleCollectionReasonEnum.COLLECTION_COMPLETED_FOR_ONE_OF_CARGOS]: defaultMessage
  };

  return labelMapping[reason];
};
