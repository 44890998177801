import { ShipmentsTableDateCertainty } from '../../types';

export const getDateCertaintyLabel = (value: ShipmentsTableDateCertainty) => {
  const postFixConfig: Record<ShipmentsTableDateCertainty, string> = {
    [ShipmentsTableDateCertainty.ACTUAL]: '',
    [ShipmentsTableDateCertainty.BOOKED]: 'Booked',
    [ShipmentsTableDateCertainty.CALCULATED]: 'Calculated',
    [ShipmentsTableDateCertainty.REQUESTED]: 'Planned'
  };

  return postFixConfig[value];
};
