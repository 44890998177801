import cx from 'classnames';
import type { FC, MouseEvent, ReactNode } from 'react';

import type { LoadingColor } from '../../Loading';
import Loading from '../../Loading';
import type { ButtonType, ButtonVariant } from '../types';

interface Props {
  'aria-label'?: string;
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => unknown;
  type?: ButtonType;
  variant?: ButtonVariant;
}

const StyledButton: FC<Props> = (props) => {
  const { className = '', variant = 'primary', type = 'button', children, disabled = false, onClick, isLoading, ...rest } = props;

  const baseClassNames: string =
    'leading-6 flex relative text-sm \
  items-center justify-center transition duration-200 ease-in-out';

  const variantClasses: Record<ButtonVariant, string> = {
    primary: 'bg-navy-base text-grey-lightest',
    secondary: 'bg-white text-grey-dark border border-grey-lighter',
    destructive: 'text-red-dark border border-red-dark',
    ghost: 'text-grey-dark border border-transparent',
    'ghost-inverse': 'text-white'
  };

  const interactionClasses: Record<ButtonVariant, string> = {
    primary: 'hover:bg-navy-dark hover:border-transparent active:bg-navy-base',
    secondary: 'hover:bg-grey-dark/[.08] hover:border-transparent active:bg-white active:border-grey-lighter',
    destructive:
      'hover:bg-red-dark hover:border-transparent hover:text-white active:bg-white active:border-red-dark active:text-red-dark',
    ghost: 'hover:bg-grey-dark/[.08] hover:border-transparent active:bg-transparent active:border-transparent',
    'ghost-inverse': 'hover:bg-white/[.12] active:bg-transparent'
  };

  const spinnerColorMapping: Record<ButtonVariant, LoadingColor> = {
    primary: 'white',
    secondary: 'grey',
    destructive: 'red',
    ghost: 'grey',
    'ghost-inverse': 'grey'
  };

  const disabledClasses: string = 'cursor-not-allowed opacity-[.48]';

  const classNames: string = cx(
    {
      'cursor-not-allowed': isLoading,
      [disabledClasses]: disabled,
      [interactionClasses[variant]]: !disabled && !isLoading
    },
    variantClasses[variant],
    baseClassNames,
    className
  );

  const renderContent = (): ReactNode => {
    if (isLoading) {
      return (
        // including "children" here, so that the width doesn't change when loading
        <div>
          <div className="invisible">{children}</div>
          <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
            <Loading color={spinnerColorMapping[variant]} size="smallest" />
          </div>
        </div>
      );
    }

    return children;
  };

  return (
    <button className={classNames} disabled={disabled || isLoading} onClick={onClick} type={type} {...rest}>
      {renderContent()}
    </button>
  );
};

export type { Props as StyledButtonProps };

export default StyledButton;
