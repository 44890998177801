import type { FC } from 'react';

import { IconButton } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  isSubscriptionsMenuVisible: boolean;
  onClick: () => void;
}

const SubscriptionsToggler: FC<Props> = ({ isSubscriptionsMenuVisible, onClick }) => {
  const icon: IconName = isSubscriptionsMenuVisible ? 'zicon-close' : 'zicon-user-group';

  return (
    <IconButton
      data-testid="subscriptions-toggler"
      icon={icon}
      onClick={onClick}
      size="medium"
      title="Toggle subscription panel"
      variant="secondary"
    />
  );
};

export default SubscriptionsToggler;
