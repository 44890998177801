import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ToggleParentAccountAccessMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ToggleParentAccountAccessInput;
}>;

export type ToggleParentAccountAccessMutationResult = { __typename?: 'Mutation' } & {
  toggleParentAccountAccess?: GraphQLTypes.Maybe<
    { __typename?: 'ToggleParentAccountAccessPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const ToggleParentAccountAccessDocument = /* #__PURE__ */ gql`
  mutation toggleParentAccountAccess($input: ToggleParentAccountAccessInput!) {
    toggleParentAccountAccess(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useToggleParentAccountAccessMutation__
 *
 * To run a mutation, you first call `useToggleParentAccountAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleParentAccountAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleParentAccountAccessMutation, { data, loading, error }] = useToggleParentAccountAccessMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleParentAccountAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<ToggleParentAccountAccessMutationResult, ToggleParentAccountAccessMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ToggleParentAccountAccessMutationResult, ToggleParentAccountAccessMutationVariables>(
    ToggleParentAccountAccessDocument,
    options
  );
}
export type ToggleParentAccountAccessMutationHookResult = ReturnType<typeof useToggleParentAccountAccessMutation>;
