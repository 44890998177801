import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetContactsQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetContactsQueryResult = { __typename?: 'Query' } & {
  contacts?: GraphQLTypes.Maybe<
    { __typename?: 'NetworkContactConnection' } & Pick<GraphQLTypes.NetworkContactConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'NetworkContact' } & Pick<
                GraphQLTypes.NetworkContact,
                'bipCode' | 'carrier' | 'customer' | 'haulier' | 'id' | 'name' | 'scacCode' | 'supplier' | 'status' | 'warehouse'
              > & {
                  contactPeople?: GraphQLTypes.Maybe<
                    Array<
                      { __typename?: 'NetworkContactPerson' } & Pick<
                        GraphQLTypes.NetworkContactPerson,
                        'id' | 'email' | 'firstName' | 'lastName' | 'skypeUserName' | 'phoneNumber' | 'mainContact'
                      >
                    >
                  >;
                }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetContactsDocument = /* #__PURE__ */ gql`
  query getContacts(
    $paginated: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchQuery: String
    $order: SortInput
  ) {
    contacts(
      paginated: $paginated
      after: $after
      before: $before
      first: $first
      last: $last
      textContains: $searchQuery
      order: $order
    ) {
      nodes {
        bipCode
        carrier
        contactPeople {
          id
          email
          firstName
          lastName
          skypeUserName
          phoneNumber
          mainContact
        }
        customer
        haulier
        id
        name
        scacCode
        supplier
        status
        warehouse
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQueryResult, GetContactsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetContactsQueryResult, GetContactsQueryVariables>(GetContactsDocument, options);
}
export function useGetContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQueryResult, GetContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetContactsQueryResult, GetContactsQueryVariables>(GetContactsDocument, options);
}
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
