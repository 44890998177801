export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CargoCargoItem: ['CargoContainerType', 'CargoLooseCargoType', 'CargoVehicleType'],
    CostTrackingLocation: ['AccountLocation', 'NetworksTerminal'],
    GlobalSearchMetadataType: [
      'GlobalSearchMetadataBookingType',
      'GlobalSearchMetadataEmptyType',
      'GlobalSearchMetadataPackingListType'
    ],
    LastNotificationItem: ['Activity'],
    Location: ['Terminal', 'Warehouse'],
    Metadata: [
      'BookingCollectionMetadataType',
      'BookingDeliveryMetadataType',
      'CargoMetadataContainerType',
      'CargoMetadataLooseCargoType',
      'CargoMetadataVehicleType',
      'IssueActivityMetadataType',
      'PurchaseOrderCargoReadyDatesMetadataType',
      'PurchaseOrderClosedMetadataType',
      'PurchaseOrderDeliveryEstimatesMetadataType',
      'ShipmentEtaMetadataType'
    ],
    NetworksAsMainContact: ['NetworksContactInLocation', 'NetworksLocationInContact'],
    NetworksAssignableInterface: ['Account', 'AccountLocation', 'NetworksLegacyOrgLoc', 'NetworksOrgLoc', 'NetworksTerminal'],
    NetworksContactInterface: ['NetworksContact', 'NetworksContactInLocation'],
    NetworksLocationInterface: ['NetworksLocation', 'NetworksLocationInContact'],
    NetworksOrganisationInterface: ['AgentOrganisation', 'ForwarderOrganisation', 'MyOrganisation', 'SavedOrganisation']
  }
};

export default result;
