import type { FC, ReactNode } from 'react';

import { TextLink } from '@zen/DesignSystem';
import type { LocationDescriptor } from '@zen/types';

interface Props {
  children: ReactNode;
  url?: LocationDescriptor;
}

const ProductName: FC<Props> = ({ url, children }) => {
  const nameComponent = <span className="font-bold">{children}</span>;

  if (url) {
    return <TextLink linkTo={url}>{nameComponent}</TextLink>;
  }

  return nameComponent;
};

export default ProductName;
