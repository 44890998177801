import type { FC } from 'react';

import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { IconButton } from '../../DesignSystem';

export interface Props {
  path: string;
}

const BackLink: FC<Props> = ({ path }) => {
  const { navigateBack } = useNavigationHistory();

  const handleClick = () => navigateBack(path);

  return (
    <a data-testid="back-link">
      <IconButton
        icon="zicon-arrow-left"
        onClick={handleClick}
        popoverPlacement="right"
        size="medium"
        title="Back"
        variant="ghost"
      />
    </a>
  );
};

export default BackLink;
