import { cargoModeLabelMapping } from '@zen/Cargo';
import type { RateCardReducerState } from '@zen/RateCards/reducer';
import { bookingModeOfTransportLabelMapping, cargoModeLabel } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';

import type { RateCard } from '../types';
import type { OverviewSection } from './types';

export const formatModeOfTransport = (modeOfTransport: RateCard['modeOfTransport']): string =>
  bookingModeOfTransportLabelMapping[modeOfTransport];

export const getOverviewSections = (rateCard: RateCardReducerState): OverviewSection[] => {
  const { cargoType, endDate, issuedAt, issuedBy, modeOfTransport, startDate } = rateCard;

  return [
    {
      title: 'Mode of transport',
      detail: formatModeOfTransport(modeOfTransport)
    },
    {
      title: cargoModeLabel,
      detail: cargoModeLabelMapping[cargoType]
    },
    {
      title: 'Start date',
      detail: formatDate(startDate)
    },
    {
      title: 'End date',
      detail: formatDate(endDate)
    },
    {
      title: 'Issued by',
      detail: issuedBy
    },
    {
      title: 'Issued date',
      detail: formatDate(issuedAt)
    }
  ];
};
