import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTotalsBreakdownQueryVariables = GraphQLTypes.Exact<{
  breakdownType: GraphQLTypes.BreakdownType;
  costTrackingId: GraphQLTypes.Scalars['String'];
  displayCurrency: GraphQLTypes.Currency;
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetTotalsBreakdownQueryResult = { __typename?: 'Query' } & {
  getCostTrackingTotalBreakdown?: GraphQLTypes.Maybe<
    { __typename?: 'GetCostTrackingTotalBreakdownPayload' } & Pick<
      GraphQLTypes.GetCostTrackingTotalBreakdownPayload,
      'displayCurrency' | 'fxRateDate' | 'totalInDisplayCurrency'
    > & {
        perCurrencyBreakdown?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'CurrencyBreakdown' } & Pick<
              GraphQLTypes.CurrencyBreakdown,
              'currency' | 'fxRateToDisplayCurrency' | 'subTotal' | 'subTotalInDisplayCurrency'
            >
          >
        >;
      }
  >;
};

export const GetTotalsBreakdownDocument = /* #__PURE__ */ gql`
  query getTotalsBreakdown(
    $breakdownType: BreakdownType!
    $costTrackingId: String!
    $displayCurrency: Currency!
    $zencargoReference: String!
  ) {
    getCostTrackingTotalBreakdown(
      breakdownType: $breakdownType
      costTrackingId: $costTrackingId
      displayCurrency: $displayCurrency
      zencargoReference: $zencargoReference
    ) {
      displayCurrency
      fxRateDate
      perCurrencyBreakdown {
        currency
        fxRateToDisplayCurrency
        subTotal
        subTotalInDisplayCurrency
      }
      totalInDisplayCurrency
    }
  }
`;

/**
 * __useGetTotalsBreakdownQuery__
 *
 * To run a query within a React component, call `useGetTotalsBreakdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalsBreakdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalsBreakdownQuery({
 *   variables: {
 *      breakdownType: // value for 'breakdownType'
 *      costTrackingId: // value for 'costTrackingId'
 *      displayCurrency: // value for 'displayCurrency'
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetTotalsBreakdownQuery(
  baseOptions: Apollo.QueryHookOptions<GetTotalsBreakdownQueryResult, GetTotalsBreakdownQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTotalsBreakdownQueryResult, GetTotalsBreakdownQueryVariables>(GetTotalsBreakdownDocument, options);
}
export function useGetTotalsBreakdownLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTotalsBreakdownQueryResult, GetTotalsBreakdownQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTotalsBreakdownQueryResult, GetTotalsBreakdownQueryVariables>(
    GetTotalsBreakdownDocument,
    options
  );
}
export type GetTotalsBreakdownQueryHookResult = ReturnType<typeof useGetTotalsBreakdownQuery>;
export type GetTotalsBreakdownLazyQueryHookResult = ReturnType<typeof useGetTotalsBreakdownLazyQuery>;
