import type { PortChargeTableColumnTitleConfig } from './types';

export const getPortChargeTableColumnTitleConfig = (
  type: 'origin' | 'destination',
  isEditable: boolean
): PortChargeTableColumnTitleConfig => {
  const port: string = type === 'destination' ? 'Port of destination' : 'Port of origin';
  const cost: string = isEditable ? 'Default price' : 'Price';

  return {
    incoterms: 'Incoterms',
    unit: 'Unit',
    chargeBasis: 'Charge basis',
    chargeType: 'Charge type',
    port,
    cost,
    customPrice: 'Custom price'
  };
};
