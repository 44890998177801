import { isEmpty, isEqual } from 'lodash';
import { useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import type { Nullable } from '@zen/utils/typescript';

type GroupFilterAttributes<T> = {
  onChange: (value: Nullable<T>) => void;
  validationKeys: (keyof T)[];
  value: Nullable<T>;
};

export const useFilterGroupValue = <T>({ onChange, validationKeys, value }: GroupFilterAttributes<T>) => {
  const [state, setState] = useState<Partial<Nullable<T>>>({});

  const isStateValid = validationKeys.every((key: keyof T) => !isEmpty(state?.[key]));

  useDeepCompareEffect(() => {
    if (isStateValid) {
      onChange(state as Nullable<T>);
    }
  }, [state]);

  useDeepCompareEffect(() => {
    if (!isEqual(state, value)) {
      setState(value);
    }
  }, [value]);

  return { state, setState, isStateValid };
};
