import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductsUnarchiveProductMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UnarchiveProductInput;
}>;

export type ProductsUnarchiveProductMutationResult = { __typename?: 'Mutation' } & {
  productsUnarchiveProduct?: GraphQLTypes.Maybe<
    { __typename?: 'UnarchiveProductPayload' } & {
      product?: GraphQLTypes.Maybe<{ __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'archived'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const ProductsUnarchiveProductDocument = /* #__PURE__ */ gql`
  mutation productsUnarchiveProduct($input: UnarchiveProductInput!) {
    productsUnarchiveProduct(input: $input) {
      product {
        id
        archived
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useProductsUnarchiveProductMutation__
 *
 * To run a mutation, you first call `useProductsUnarchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsUnarchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsUnarchiveProductMutation, { data, loading, error }] = useProductsUnarchiveProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsUnarchiveProductMutation(
  baseOptions?: Apollo.MutationHookOptions<ProductsUnarchiveProductMutationResult, ProductsUnarchiveProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ProductsUnarchiveProductMutationResult, ProductsUnarchiveProductMutationVariables>(
    ProductsUnarchiveProductDocument,
    options
  );
}
export type ProductsUnarchiveProductMutationHookResult = ReturnType<typeof useProductsUnarchiveProductMutation>;
