import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { getEmptyState, hasEmptyState, mergeQueryParams, parseQueryParams, stringifyQueryParams } from './queryParams';
import type { ParsedUrlType, QueryParams, QueryParamsType } from './types';

interface UseQueryParamsResult<T> {
  clearAllQueryParams: () => void;
  pushWithQueryParams: (path: string, params: Partial<T>, includeExistingQueryString?: boolean) => void;
  queryParams: ParsedUrlType<T>;
  setQueryParams: (params: Partial<T>, includeExistingQueryString?: boolean) => void;
}

const getUrl = (pathName: string, queryString: string): string => {
  return `${pathName}${queryString}`;
};

const getQueryString = (params: object, existingQueryString: string = '', objectKey?: string): string => {
  if (!objectKey) {
    return stringifyQueryParams(params);
  }

  return mergeQueryParams(existingQueryString, { [objectKey]: params });
};

const useQueryParams = <T extends {}>(objectKey?: string, initialValues: Partial<T> = {}): UseQueryParamsResult<T> => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const parsedQueryParams: QueryParams = parseQueryParams(searchParams.toString(), false);
  const queryStringParams: QueryParams = !objectKey ? parsedQueryParams : parsedQueryParams[objectKey];

  const appendQueryParams = useCallback(
    (params: Partial<T>): void => {
      setSearchParams(
        (currentParams) => {
          return getQueryString(params, String(currentParams), objectKey);
        },
        { replace: true }
      );
    },
    [setSearchParams, objectKey]
  );

  const initialiseQueryParams = useCallback(
    (currentParams: QueryParams, initalParams: Partial<T>) => {
      if (isEmpty(currentParams) && !isEmpty(initalParams)) {
        appendQueryParams(initalParams);
      }
    },
    [appendQueryParams]
  );

  useEffect(() => {
    initialiseQueryParams(queryStringParams, initialValues);
  }, [queryStringParams, initialValues, initialiseQueryParams]);

  const setQueryParams = (params: Partial<T>, includeExistingQueryString: boolean = true): void => {
    const queryParams: QueryParamsType<T> = isEmpty(params) ? getEmptyState() : params;

    pushWithQueryParams(pathname, queryParams, includeExistingQueryString);
  };

  const pushWithQueryParams = (path: string, params: QueryParamsType<T>, includeExistingQueryString: boolean = true): void => {
    const existingQueryString: string = includeExistingQueryString ? String(searchParams) : '';

    const queryString: string = getQueryString(params, existingQueryString, objectKey);

    navigate(getUrl(path, queryString));
  };

  const clearAllQueryParams = (): void => {
    return setQueryParams({}, false);
  };

  const getQueryParams = (): ParsedUrlType<T> => {
    const queryParams = queryStringParams || initialValues;
    const params = !hasEmptyState(queryParams) ? queryParams : {};

    return params as ParsedUrlType<T>;
  };

  return { queryParams: getQueryParams(), setQueryParams, clearAllQueryParams, pushWithQueryParams };
};

export default useQueryParams;
