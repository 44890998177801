import type { FC } from 'react';

import Page from '@zen/Components/Page';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';

import { useRateCardCreateMutation } from '../graphql';
import RateCardForm from '../RateCardForm';
import { getInitialValues, prepareRateCardInput } from '../RateCardForm/helpers';
import type { CreateRateCardInput } from '../RateCardForm/types';
import type { RateCardReducerState } from '../reducer';

interface Props {}

const NewRateCard: FC<Props> = () => {
  const { accountUuid: accountId, userProfile } = useAccount();
  const { registeredCompanyName } = userProfile;
  const { addError, addSuccess } = useNotification();
  const { navigate } = useNavigationHistory();
  const [createRateCard] = useRateCardCreateMutation();
  const { uuid: currentUserId } = userProfile;

  const defaultBackUrl: string = rateCardRoutes.index.getUrl();

  const handleSubmit = async (values: RateCardReducerState): Promise<void> => {
    const rateCardInput: CreateRateCardInput = {
      ...prepareRateCardInput(values, registeredCompanyName),
      createdBy: userProfile.uuid
    };

    await performMutation({
      mutationFn: () => createRateCard({ variables: { input: rateCardInput } }),
      onError: () => addError(),
      onSuccess: () => {
        const successMessage: string = 'Rate card has been successfully created';

        addSuccess(successMessage);
        navigate(rateCardRoutes.index.getUrl());
      }
    });
  };

  return (
    <Page defaultBackUrl={defaultBackUrl} title="New rate card">
      <RateCardForm initialValues={getInitialValues(accountId, currentUserId)} onSubmit={handleSubmit} />
    </Page>
  );
};

export default NewRateCard;
