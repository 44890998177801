import type { FC, ReactNode } from 'react';

import Header from '@zen/Layout/Header';

import PageContent from '../PageContent';
import PageTitle from '../PageTitle';

interface Props {
  tagline: ReactNode;
  title: string;
}

const LandingPage: FC<Props> = ({ children, title, tagline, ...rest }) => (
  <div className="flex flex-col min-h-screen bg-white" {...rest}>
    <Header isLogoLinked={false} />
    <div className="flex justify-center flex-1 w-full pt-16">
      <PageContent width="narrower">
        <PageTitle className="mb-8" tagline={tagline} title={title} />
        {children}
      </PageContent>
    </div>
  </div>
);

export default LandingPage;
