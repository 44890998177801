import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateTrailerIdMutation } from './graphql';

interface Props {
  canUpdateTrailerId: boolean;
  trailerId: string;
  vehicleId: string;
}

const TrailerIdInlineField: FC<Props> = ({ canUpdateTrailerId, trailerId, vehicleId }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateTrailerId] = useUpdateTrailerIdMutation();

  const label: string = 'Trailer ID';

  const handleTrailerIdUpdate = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateTrailerId({
          variables: {
            input: {
              vehicleId,
              trailerId: value,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Trailer ID updated.');
      }
    });
  };

  if (!canUpdateTrailerId) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={trailerId} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="trailer-id"
      onUpdate={handleTrailerIdUpdate}
      placeholder="Enter trailer ID"
      value={trailerId}
    />
  );
};

export default TrailerIdInlineField;
