import { type FC, type ReactNode, useMemo, useState } from 'react';

import { prepareAddressForSubmission } from '@zen/Accounts/AccountForms/helpers';
import type { Address, AddressInput } from '@zen/Accounts/AccountForms/types';
import EditableContentContainer from '@zen/Components/EditableContentContainer';
import Map from '@zen/Components/Map';
import type { GeoCoordinates } from '@zen/Components/Map/types';
import { Banner, Modal } from '@zen/DesignSystem';

import AccountAddress from '../../components/AccountAddress';
import AccountDetailsItem from '../../components/AccountDetailsItem';
import type { LocationGeolocation } from '../../types';
import type { ShippingAddressFormValues } from '../types';
import UpdateAddress from '../UpdateAddress';

interface Props {
  address: Address;
  canEdit: boolean;
  geolocation: LocationGeolocation;
  locationId: string;
}

const AddressDetails: FC<Props> = ({ address, canEdit, locationId, geolocation }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = (): void => setIsModalOpen(!isModalOpen);

  const shippingLocationAddress: AddressInput = useMemo(() => prepareAddressForSubmission(address), [address]);
  const formattedAddress: ReactNode = <AccountAddress {...address} />;

  const initialValues: ShippingAddressFormValues = {
    shippingLocationAddress,
    isPinPlacementConfirmed: true,
    geolocation,
    locationId
  };

  const renderMap = (): ReactNode => {
    if (geolocation) {
      const { latitude: lat, longitude: lng } = geolocation;
      const markers: GeoCoordinates[] = lng && lat ? [{ lat, lng }] : [];

      return <Map defaultZoom={12} isInteractive={false} markers={markers} resizable={false} singleMarkerMode={true} />;
    }
  };

  const mapWrapperClassName: string =
    'relative grid-cols-4 col-span-4 overflow-hidden border border-solid rounded bg-azure-light border-grey-lighter min-h-40';

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={toggleModal} title="Update address">
        <UpdateAddress initialValues={initialValues} onCancel={toggleModal} onSuccess={toggleModal} />
      </Modal>
      <EditableContentContainer heading="Address" onEdit={canEdit ? toggleModal : undefined}>
        <div className="col-span-8 space-y-4">
          <div className="grid grid-cols-8 col-span-8">
            <div className="grid grid-cols-4 col-span-4 gap-3">
              <AccountDetailsItem fullRow={true} name="Address" value={formattedAddress} />
            </div>
            <div className={mapWrapperClassName}>{renderMap()}</div>
          </div>
          {!canEdit && (
            <Banner
              message="This location's address cannot be edited due to its association with various companies. If you need assistance, please
          contact your coordinator."
            />
          )}
        </div>
      </EditableContentContainer>
    </>
  );
};

export default AddressDetails;
export type { Props as AddressDetailsProps };
