import type { FC } from 'react';

import { Card, Icon } from '@zen/DesignSystem';
import IssueStateDescription from '@zen/Issues/components/IssueStateDescription';
import { Issue, IssueSeverity, IssueState } from '@zen/Issues/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

export const severityTextColor: Record<IssueSeverity, string> = {
  [IssueSeverity.HIGH]: 'text-red-base',
  [IssueSeverity.MEDIUM]: 'text-orange-base',
  [IssueSeverity.LOW]: 'text-azure-light'
};

type ParamIssue = Pick<
  Issue,
  'category' | 'title' | 'state' | 'openedAt' | 'openedBy' | 'startedAt' | 'startedBy' | 'resolvedAt' | 'resolvedBy' | 'severity'
>;

interface Props {
  issue: ParamIssue;
  onViewIssueClick: () => void;
}

const IssueListItem: FC<Props> = ({ issue, onViewIssueClick }) => {
  const { category, title, severity, state } = issue;
  const issueLabel: string = title || `${removeSpecialCharactersAndUpperFirst(category)} issue`;
  const iconColor: string = state === IssueState.RESOLVED ? 'text-grey-base' : severityTextColor[severity];

  return (
    <Card
      className="mt-4"
      data-testid="issue-item"
      leftIcon={<Icon className={`${iconColor} text-2xl`} icon="zicon-warning-oval" />}
      onClick={onViewIssueClick}
      title={issueLabel}
    >
      <IssueStateDescription issue={issue} />
    </Card>
  );
};

export default IssueListItem;
