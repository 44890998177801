import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignNotifiedPartyMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AssignNotifiedPartyInput;
}>;

export type AssignNotifiedPartyMutationResult = { __typename?: 'Mutation' } & {
  bookingAssignNotifiedParty?: GraphQLTypes.Maybe<
    { __typename?: 'AssignNotifiedPartyPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const AssignNotifiedPartyDocument = /* #__PURE__ */ gql`
  mutation assignNotifiedParty($input: AssignNotifiedPartyInput!) {
    bookingAssignNotifiedParty(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useAssignNotifiedPartyMutation__
 *
 * To run a mutation, you first call `useAssignNotifiedPartyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignNotifiedPartyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignNotifiedPartyMutation, { data, loading, error }] = useAssignNotifiedPartyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignNotifiedPartyMutation(
  baseOptions?: Apollo.MutationHookOptions<AssignNotifiedPartyMutationResult, AssignNotifiedPartyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AssignNotifiedPartyMutationResult, AssignNotifiedPartyMutationVariables>(
    AssignNotifiedPartyDocument,
    options
  );
}
export type AssignNotifiedPartyMutationHookResult = ReturnType<typeof useAssignNotifiedPartyMutation>;
