import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackedShipmentsDetailsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  customerId: GraphQLTypes.Scalars['String'];
  event?: GraphQLTypes.InputMaybe<GraphQLTypes.RoadTrackedShipmentEvent>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  firstStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  lastStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  status: GraphQLTypes.RoadTrackedShipmentStatus;
}>;

export type RoadTrackedShipmentsDetailsQueryResult = { __typename?: 'Query' } & {
  roadTrackedShipments?: GraphQLTypes.Maybe<
    { __typename?: 'RoadTrackedShipmentConnection' } & Pick<GraphQLTypes.RoadTrackedShipmentConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'RoadTrackedShipment' } & Pick<GraphQLTypes.RoadTrackedShipment, 'zencargoReference' | 'status'> & {
                  booking?: GraphQLTypes.Maybe<
                    { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'clientReference' | 'zencargoReference'> & {
                        cargo?: GraphQLTypes.Maybe<
                          { __typename?: 'Cargo' } & {
                            cargoItems?: GraphQLTypes.Maybe<
                              Array<
                                | ({ __typename?: 'CargoContainerType' } & {
                                    delivery?: GraphQLTypes.Maybe<
                                      { __typename?: 'DeliveryDetails' } & {
                                        confirmedByForwarder?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeRange' } & {
                                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                              GraphQLTypes.LocalDateTimeType,
                                              'date' | 'time' | 'timeZone'
                                            >;
                                            endDateTime?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeType' } & Pick<
                                                GraphQLTypes.LocalDateTimeType,
                                                'date' | 'time' | 'timeZone'
                                              >
                                            >;
                                          }
                                        >;
                                      }
                                    >;
                                  })
                                | ({ __typename?: 'CargoLooseCargoType' } & {
                                    delivery?: GraphQLTypes.Maybe<
                                      { __typename?: 'DeliveryDetails' } & {
                                        confirmedByForwarder?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeRange' } & {
                                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                              GraphQLTypes.LocalDateTimeType,
                                              'date' | 'time' | 'timeZone'
                                            >;
                                            endDateTime?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeType' } & Pick<
                                                GraphQLTypes.LocalDateTimeType,
                                                'date' | 'time' | 'timeZone'
                                              >
                                            >;
                                          }
                                        >;
                                      }
                                    >;
                                  })
                                | ({ __typename?: 'CargoVehicleType' } & {
                                    delivery?: GraphQLTypes.Maybe<
                                      { __typename?: 'DeliveryDetails' } & {
                                        confirmedByForwarder?: GraphQLTypes.Maybe<
                                          { __typename?: 'LocalDateTimeRange' } & {
                                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                              GraphQLTypes.LocalDateTimeType,
                                              'date' | 'time' | 'timeZone'
                                            >;
                                            endDateTime?: GraphQLTypes.Maybe<
                                              { __typename?: 'LocalDateTimeType' } & Pick<
                                                GraphQLTypes.LocalDateTimeType,
                                                'date' | 'time' | 'timeZone'
                                              >
                                            >;
                                          }
                                        >;
                                      }
                                    >;
                                  })
                              >
                            >;
                          }
                        >;
                        roadTracking?: GraphQLTypes.Maybe<
                          { __typename?: 'RoadTrackingDetails' } & {
                            trackingInput?: GraphQLTypes.Maybe<
                              { __typename?: 'RoadTrackingInput' } & {
                                trackingSubscription?: GraphQLTypes.Maybe<
                                  { __typename?: 'RoadTrackingSubscription' } & Pick<
                                    GraphQLTypes.RoadTrackingSubscription,
                                    'mapUrl'
                                  >
                                >;
                              }
                            >;
                          }
                        >;
                      }
                  >;
                  latestStopUpdates?: GraphQLTypes.Maybe<
                    Array<
                      { __typename?: 'RoadTrackingStopUpdate' } & Pick<
                        GraphQLTypes.RoadTrackingStopUpdate,
                        'confidenceLevel' | 'countryCode' | 'stopType'
                      > & {
                          arrivalWindow?: GraphQLTypes.Maybe<
                            { __typename?: 'LocalDateTimeRange' } & {
                              startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                GraphQLTypes.LocalDateTimeType,
                                'date' | 'time' | 'timeZone'
                              >;
                              endDateTime?: GraphQLTypes.Maybe<
                                { __typename?: 'LocalDateTimeType' } & Pick<
                                  GraphQLTypes.LocalDateTimeType,
                                  'date' | 'time' | 'timeZone'
                                >
                              >;
                            }
                          >;
                        }
                    >
                  >;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const RoadTrackedShipmentsDetailsDocument = /* #__PURE__ */ gql`
  query roadTrackedShipmentsDetails(
    $after: String
    $before: String
    $customerId: String!
    $event: RoadTrackedShipmentEvent
    $first: Int
    $firstStopCountryCodes: [String!]
    $last: Int
    $lastStopCountryCodes: [String!]
    $status: RoadTrackedShipmentStatus!
  ) {
    roadTrackedShipments(
      active: ACTIVE
      after: $after
      before: $before
      customerId: $customerId
      event: $event
      first: $first
      firstStopCountryCodes: $firstStopCountryCodes
      last: $last
      lastStopCountryCodes: $lastStopCountryCodes
      status: $status
    ) {
      nodes {
        zencargoReference
        booking {
          cargo {
            cargoItems {
              delivery {
                confirmedByForwarder {
                  startDateTime {
                    date
                    time
                    timeZone
                  }
                  endDateTime {
                    date
                    time
                    timeZone
                  }
                }
              }
            }
          }
          clientReference
          roadTracking {
            trackingInput {
              trackingSubscription {
                mapUrl
              }
            }
          }
          zencargoReference
        }
        latestStopUpdates {
          arrivalWindow {
            startDateTime {
              date
              time
              timeZone
            }
            endDateTime {
              date
              time
              timeZone
            }
          }
          confidenceLevel
          countryCode
          stopType
        }
        status
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useRoadTrackedShipmentsDetailsQuery__
 *
 * To run a query within a React component, call `useRoadTrackedShipmentsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackedShipmentsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackedShipmentsDetailsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      customerId: // value for 'customerId'
 *      event: // value for 'event'
 *      first: // value for 'first'
 *      firstStopCountryCodes: // value for 'firstStopCountryCodes'
 *      last: // value for 'last'
 *      lastStopCountryCodes: // value for 'lastStopCountryCodes'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRoadTrackedShipmentsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackedShipmentsDetailsQueryResult, RoadTrackedShipmentsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackedShipmentsDetailsQueryResult, RoadTrackedShipmentsDetailsQueryVariables>(
    RoadTrackedShipmentsDetailsDocument,
    options
  );
}
export function useRoadTrackedShipmentsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackedShipmentsDetailsQueryResult, RoadTrackedShipmentsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackedShipmentsDetailsQueryResult, RoadTrackedShipmentsDetailsQueryVariables>(
    RoadTrackedShipmentsDetailsDocument,
    options
  );
}
export type RoadTrackedShipmentsDetailsQueryHookResult = ReturnType<typeof useRoadTrackedShipmentsDetailsQuery>;
export type RoadTrackedShipmentsDetailsLazyQueryHookResult = ReturnType<typeof useRoadTrackedShipmentsDetailsLazyQuery>;
