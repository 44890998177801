import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateJourneyStopReferenceMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateJourneyStopReferenceInput;
}>;

export type UpdateJourneyStopReferenceMutationResult = { __typename?: 'Mutation' } & {
  updateJourneyStopReference: { __typename?: 'UpdateJourneyStopReferencePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateJourneyStopReferenceDocument = /* #__PURE__ */ gql`
  mutation updateJourneyStopReference($input: UpdateJourneyStopReferenceInput!) {
    updateJourneyStopReference(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateJourneyStopReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateJourneyStopReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJourneyStopReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJourneyStopReferenceMutation, { data, loading, error }] = useUpdateJourneyStopReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJourneyStopReferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateJourneyStopReferenceMutationResult, UpdateJourneyStopReferenceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateJourneyStopReferenceMutationResult, UpdateJourneyStopReferenceMutationVariables>(
    UpdateJourneyStopReferenceDocument,
    options
  );
}
export type UpdateJourneyStopReferenceMutationHookResult = ReturnType<typeof useUpdateJourneyStopReferenceMutation>;
