import type { FC } from 'react';

import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import type { Activity } from '@zen/ActivityFeed/types';

import ActivityFeedItemDetails from '../../components/ActivityFeedItemDetails';
import ActivityFeedItemWrapper from '../../components/ActivityFeedItemWrapper';
import { ActivityFeedItemTypeEnum } from '../../types';

export interface Props {
  activityMetaData: Activity;
}

export const bookingRequestTitleMapping: Record<string, string> = {
  [ActivityFeedItemTypeEnum.REQUIRED_CUSTOMER_APPROVAL]: 'Booking ready for approval',
  [ActivityFeedItemTypeEnum.MANUFACTURER_REQUESTED_BOOKING]: 'Booking requested',
  [ActivityFeedItemTypeEnum.FORWARDER_REVERTED_REJECTION_AND_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING]: 'Booking approved',
  [ActivityFeedItemTypeEnum.CUSTOMER_APPROVED_BOOKING]: 'Booking approved',
  [ActivityFeedItemTypeEnum.FORWARDER_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING]: 'Booking approved'
};

const BookingItem: FC<Props> = ({ activityMetaData }) => {
  const { createdAt, itemType, itemId, legacyUser } = activityMetaData;
  const shouldShowUserDetails: boolean = itemType !== ActivityFeedItemTypeEnum.REQUIRED_CUSTOMER_APPROVAL;

  return (
    <ActivityFeedItemWrapper>
      <div className="flex items-center justify-between">
        <ActivityFeedItemDetails
          createdAt={createdAt}
          icon={<ActivityFeedItemIcon />}
          title={bookingRequestTitleMapping[itemType]}
          user={shouldShowUserDetails ? legacyUser : null}
        />
        <p className="font-bold">{itemId}</p>
      </div>
    </ActivityFeedItemWrapper>
  );
};

export default BookingItem;
