import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { get } from 'lodash';

import type { ThoughtSpotPermission } from '@zen/AnalyticsDashboards/types';
import authStore from '@zen/Auth/authStore';

import { addFlashMessage, FlashType } from '../Components/NotificationProvider/actions';

interface RollbarType {
  error: (message: string, error: Error) => void;
}

export interface AcceptInvitationInput {
  password: string;
  passwordConfirmation: string;
  token: string;
}
declare global {
  interface Window {
    Rollbar: RollbarType;
  }
}

interface ThoughtSpotLiveboard {
  metadata_id: string;
  metadata_name: string;
}
interface Api extends AxiosInstance {
  getLiveboards: (permissions?: ThoughtSpotPermission[]) => Promise<{ data: ThoughtSpotLiveboard[] }>;
}

const api: Api = Object.assign(
  axios.create({
    timeout: 10000
  }),
  {
    getLiveboards: (permissions?: ThoughtSpotPermission[]): Promise<{ data: ThoughtSpotLiveboard[] }> => {
      return api.post(
        'https://zencargo.thoughtspot.cloud/api/rest/2.0/metadata/search',
        {
          metadata: { type: 'LIVEBOARD' },
          record_size: -1,
          ...(permissions ? { permissions } : {})
        },
        {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        }
      );
    }
  }
);

api.interceptors.request.use(
  (config) => {
    const { getAccessToken } = authStore();

    if (!config.withCredentials) {
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
    }

    return config;
  },
  (error) => {
    window.Rollbar.error('Request Error', error);

    return Promise.reject(error);
  }
);

export const configure = () => {
  // eslint-disable-next-line consistent-return
  api.interceptors.response.use(
    (response) => response,
    (error) => {
      const status = get(error, 'response.status', false);
      const message = get(error, 'response.data.message', false);

      if (status === 401 && message === 'Invalid token') {
        addFlashMessage({
          type: FlashType.ERROR,
          text: 'Your session has expired, please log in again.',
          options: {
            toastId: 'session_expired'
          }
        });
      } else if (status === 403) {
        addFlashMessage({
          type: FlashType.ERROR,
          text: "You're not authorised to access this resource.",
          options: {
            toastId: 'not_authorized'
          }
        });
      } else if (status === 503) {
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export default api;

export type { AxiosResponse as ApiResponse };
