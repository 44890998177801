const PRODUCTS_RELATIVE_INDEX_PATH = 'products';

export const PRODUCTS_ROUTE_PREFIX = `/dashboard/${PRODUCTS_RELATIVE_INDEX_PATH}`;

interface ProductRoutes {
  analyticsDetails: {
    getUrl: (productId: string) => string;
    path: string;
    relativePath: string;
  };
  details: {
    getUrl: (productId: string) => string;
    path: string;
    relativePath: string;
  };
  detailsIndex: {
    getUrl: (productId: string) => string;
    path: string;
    relativePath: string;
  };
  edit: {
    getUrl: (productId: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  new: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  orderDetails: {
    getUrl: (id: string) => string;
    path: string;
    relativePath: string;
  };
  orderDetailsOrderDetails: {
    getUrl: (productId: string, orderId: string) => string;
    path: string;
    relativePath: string;
  };
  weeklyIntakeDetails: {
    getUrl: (id: string) => string;
    path: string;
    relativePath: string;
  };
}

const productRoutes: ProductRoutes = {
  analyticsDetails: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/analytics`,
    getUrl: (productId: string) => {
      return `${PRODUCTS_ROUTE_PREFIX}/${productId}/analytics`;
    },
    relativePath: 'analytics'
  },
  detailsIndex: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId`,
    getUrl: (productId: string) => {
      return `${PRODUCTS_ROUTE_PREFIX}/${productId}`;
    },
    relativePath: ':productId/*'
  },
  details: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/details`,
    getUrl: (productId: string) => {
      return `${PRODUCTS_ROUTE_PREFIX}/${productId}/details`;
    },
    relativePath: 'details'
  },
  edit: {
    getUrl: (productId: string) => `${PRODUCTS_ROUTE_PREFIX}/${productId}/edit`,
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/edit`,
    relativePath: ':productId/edit'
  },
  index: {
    path: `${PRODUCTS_ROUTE_PREFIX}/*`,
    relativePath: `${PRODUCTS_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => PRODUCTS_ROUTE_PREFIX
  },
  new: {
    getUrl: () => `${PRODUCTS_ROUTE_PREFIX}/new`,
    path: `${PRODUCTS_ROUTE_PREFIX}/new`,
    relativePath: 'new'
  },
  orderDetails: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/orders/*`,
    getUrl: (productId: string) => {
      return `${PRODUCTS_ROUTE_PREFIX}/${productId}/orders`;
    },
    relativePath: 'orders/*'
  },
  orderDetailsOrderDetails: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/orders/:id`,
    getUrl: (productId: string, orderId: string) => `${PRODUCTS_ROUTE_PREFIX}/${productId}/orders/${orderId}`,
    relativePath: ':id/*'
  },
  weeklyIntakeDetails: {
    path: `${PRODUCTS_ROUTE_PREFIX}/:productId/weekly/*`,
    getUrl: (productId: string) => {
      return `${PRODUCTS_ROUTE_PREFIX}/${productId}/weekly`;
    },
    relativePath: 'weekly/*'
  }
};

export default productRoutes;
