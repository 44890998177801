import type { FC } from 'react';

import AccountSwitcherForm from '@zen/Components/AccountSwitcherForm';
import { VisitedAccountsProvider } from '@zen/Components/VisitedAccounts';
import useAccount from '@zen/utils/hooks/useAccount';

import VisitedAccountsMenu from '../VisitedAccountsMenu';

interface Props {
  className?: string;
  onAccountChange?: () => void;
}

const AccountsMenu: FC<Props> = ({ className, onAccountChange }) => {
  const {
    userProfile: { uuid: userId }
  } = useAccount();

  return (
    <div className={className}>
      <VisitedAccountsProvider currentUserId={userId}>
        <>
          <AccountSwitcherForm label="Account name" onAccountChange={onAccountChange} submitOnChange={true} />
          <VisitedAccountsMenu onAccountChange={onAccountChange} />
        </>
      </VisitedAccountsProvider>
    </div>
  );
};

export default AccountsMenu;
