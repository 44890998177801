import { size } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import OrderBasket from '@zen/Components/OrderBasket';
import { useViewMode } from '@zen/Components/ViewSwitcher';
import { TableConfigurationProvider } from '@zen/DesignSystem';
import { ORDERS_ROUTE_PREFIX } from '@zen/Routes';
import { SortingOrder, SortInput } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import type { OrderFilters } from '../types';
import ListView from './ListView';
import OrdersToolbar from './OrdersToolbar/OrdersToolbar';
import TableView from './TableView';

const initialSortOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.DESC
};

interface Props {
  filterByUserPreferences: boolean;
}

const OrderList: FC<Props> = ({ filterByUserPreferences }) => {
  const { check } = useGlobalPermissions();
  const { pathname, search } = useLocation();
  const { isTableView } = useViewMode();
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const [sortOrder, setSortOrder] = useState<SortInput>(initialSortOrder);

  const queryParams: QueryParams = parseQueryParams(search);
  const isBasketMode: boolean = !!queryParams?.booking;
  const onlyOwnAccount: boolean = !!queryParams?.onlyOwnAccount;
  const canViewOrdersCustomers: boolean = check('purchaseOrders.canViewCustomers') && !onlyOwnAccount;
  const hasFilters: boolean = size(appliedFilters) > 0 || !!queryParams?.textContains;
  const showTableView: boolean = !isBasketMode && isTableView;
  const className: string | undefined = isBasketMode ? 'mt-32' : undefined;

  useConsumeLocationState<{ filters: Partial<OrderFilters> }>((filters) => {
    if (filters) {
      setAppliedFilters(filters);
    }
  }, 'filters');

  useEffect(() => {
    return () => {
      if (!pathname.includes(ORDERS_ROUTE_PREFIX)) {
        setAppliedFilters({});
      }
    };
  }, [pathname, setAppliedFilters]);

  return (
    <OrderBasket isEnabled={isBasketMode}>
      <TableConfigurationProvider tableId="orders">
        <div className={className}>
          <OrdersToolbar canViewCustomer={canViewOrdersCustomers} showTableView={showTableView} sortOrder={sortOrder} />
          {showTableView && (
            <TableView
              canViewCustomer={canViewOrdersCustomers}
              filterByUserPreferences={filterByUserPreferences}
              hasFilters={hasFilters}
              onSortChange={(sortInput: SortInput) => setSortOrder(sortInput)}
              sortOrder={sortOrder}
            />
          )}
          {!showTableView && (
            <ListView
              canViewOrdersCustomers={canViewOrdersCustomers}
              filterByUserPreferences={filterByUserPreferences}
              hasFilters={hasFilters}
            />
          )}
        </div>
      </TableConfigurationProvider>
    </OrderBasket>
  );
};

export default OrderList;
