const INTAKE_PLANNING_RELATIVE_INDEX_PATH = 'intake-planning';

export const INTAKE_PLANNING_ROUTE_PREFIX = `/dashboard/${INTAKE_PLANNING_RELATIVE_INDEX_PATH}`;

interface IntakePlanningRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  intakeWeekly: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const intakePlanningRoutes: IntakePlanningRoutes = {
  index: {
    path: `${INTAKE_PLANNING_ROUTE_PREFIX}/*`,
    getUrl: () => INTAKE_PLANNING_ROUTE_PREFIX,
    relativePath: `${INTAKE_PLANNING_RELATIVE_INDEX_PATH}/*`
  },
  intakeWeekly: {
    path: `${INTAKE_PLANNING_ROUTE_PREFIX}/weekly`,
    getUrl: () => `${INTAKE_PLANNING_ROUTE_PREFIX}/weekly`,
    relativePath: 'weekly/*'
  }
};

export default intakePlanningRoutes;
