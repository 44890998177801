import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateVehiclePlateNumberMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateVehiclePlateNumberInput;
}>;

export type UpdateVehiclePlateNumberMutationResult = { __typename?: 'Mutation' } & {
  updateVehiclePlateNumber: { __typename?: 'UpdateVehiclePlateNumberPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateVehiclePlateNumberDocument = /* #__PURE__ */ gql`
  mutation updateVehiclePlateNumber($input: UpdateVehiclePlateNumberInput!) {
    updateVehiclePlateNumber(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateVehiclePlateNumberMutation__
 *
 * To run a mutation, you first call `useUpdateVehiclePlateNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehiclePlateNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehiclePlateNumberMutation, { data, loading, error }] = useUpdateVehiclePlateNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVehiclePlateNumberMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVehiclePlateNumberMutationResult, UpdateVehiclePlateNumberMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateVehiclePlateNumberMutationResult, UpdateVehiclePlateNumberMutationVariables>(
    UpdateVehiclePlateNumberDocument,
    options
  );
}
export type UpdateVehiclePlateNumberMutationHookResult = ReturnType<typeof useUpdateVehiclePlateNumberMutation>;
