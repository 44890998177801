import type { FC } from 'react';

import FiltersComponent from '@zen/Components/Filters';
import { modeOfTransportOptions } from '@zen/Components/ModeOfTransportSelect';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, useSearchableNetworkOptions } from '@zen/Networks';
import {
  formatWithinTimeRangeLabel,
  getDaysFilterOptions,
  stageFilterOptions,
  withinDaysFilterOptions
} from '@zen/Shipments/Filters/helpers';
import useAccounts from '@zen/utils/hooks/useAccounts';

import { getDivisionOptions } from './helpers';
import type { CustomerAdminShipmentFilters } from './types';

interface Props {
  filters: CustomerAdminShipmentFilters;
  onChange: (values: CustomerAdminShipmentFilters) => void;
}

const Filters: FC<Props> = ({ filters, onChange }) => {
  const { data = [] } = useAccounts();

  const {
    options: originOptions,
    onSearch: onOriginSearch,
    loading: isOriginOptionsLoading
  } = useSearchableNetworkOptions({
    assignmentTypes: [AssignmentTypeValue.ORIGIN],
    domainNames: [AssignmentTargetTypeEnum.BOOKING],
    selectedValues: filters.origins
  });

  const {
    options: destinationOptions,
    onSearch: onDestinationsSearch,
    loading: isDestinationOptionsLoading
  } = useSearchableNetworkOptions({
    assignmentTypes: [AssignmentTypeValue.DESTINATION],
    domainNames: [AssignmentTargetTypeEnum.BOOKING],
    selectedValues: filters.destinations
  });

  return (
    <FiltersComponent
      items={[
        {
          componentType: 'multi-select',
          key: 'accountIds',
          title: 'Division',
          props: { options: getDivisionOptions(data) }
        },
        {
          componentType: 'select',
          key: 'status',
          title: 'Shipment status',
          props: { options: stageFilterOptions }
        },
        {
          componentType: 'multi-select',
          title: 'Transport mode',
          key: 'transportModes',
          props: { options: modeOfTransportOptions }
        },
        {
          componentType: 'multi-select',
          title: 'Origins',
          key: 'origins',
          props: { loading: isOriginOptionsLoading, options: originOptions, onSearch: onOriginSearch }
        },
        {
          componentType: 'multi-select',
          title: 'Destinations',
          key: 'destinations',
          props: {
            loading: isDestinationOptionsLoading,
            options: destinationOptions,
            onSearch: onDestinationsSearch
          }
        },
        {
          componentType: 'group',
          key: 'withinTimeRange',
          title: 'Event',
          props: {
            configurations: [
              {
                key: 'eventType',
                title: 'Shipment event',
                props: { options: withinDaysFilterOptions },
                componentType: 'select'
              },
              {
                key: 'dateRange',
                title: 'When...',
                componentType: 'date-range-picker',
                props: { options: getDaysFilterOptions() }
              }
            ],
            formatValue: formatWithinTimeRangeLabel
          }
        }
      ]}
      onChange={onChange}
      value={filters}
    />
  );
};

export default Filters;
