import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { journeyRefetchQueryList } from '@zen/Journey/components/forms/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useToggleOceanTrackingMutation } from '../graphql';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  zencargoReference: string;
}

const EnableOceanTrackingModal: FC<Props> = ({ isOpen, onClose, zencargoReference }) => {
  const [toggleOceanTracking, { loading }] = useToggleOceanTrackingMutation();

  const { addError, addSuccess } = useNotification();

  const handleSubmit = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        toggleOceanTracking({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              isEnabled: true,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Ocean tracking has been enabled.');
        onClose();
      }
    });
  };

  return (
    <Dialog
      confirmLabel="Enable tracking"
      header="Enable tracking"
      isLoading={loading}
      isOpen={isOpen}
      message="Are you sure you want to enable the tracking back? This means that all your future milestones will be populated by tracking."
      onClose={onClose}
      onConfirm={handleSubmit}
    />
  );
};

export default EnableOceanTrackingModal;
