import type { Optional } from '@zen/utils/typescript';

import type { BookingSummaryCargoJourneyType, BookingSummaryData, BookingSummaryJourneyLegType } from '../../types';
import { JourneyStop as JourneyStopType, JourneyStopLocationTypeEnum, VoyageMilestoneNameEnum } from '../../types';
import type { BookingStatus } from '../BookingRoute/types';

type JourneyStop = Pick<JourneyStopType, 'locationType' | 'warehouse' | 'terminal'>;

export const getBookingStatus = (isArchived: boolean, isDelivered: boolean, isInProgress: boolean): BookingStatus => {
  if (isArchived) {
    return 'archived';
  }

  if (isDelivered) {
    return 'delivered';
  }

  return isInProgress ? 'inProgress' : 'unknown';
};

const getStopLocationLabel = ({ locationType, warehouse, terminal }: JourneyStop): string => {
  const label: Optional<string> =
    locationType === JourneyStopLocationTypeEnum.WAREHOUSE ? warehouse?.label?.long : terminal?.unlocode;

  return label || '';
};

const getFirstStop = (cargoJourney: BookingSummaryCargoJourneyType): string => {
  const firstStop: Optional<JourneyStop> = cargoJourney?.journey?.legs?.[0].from;

  return firstStop ? getStopLocationLabel(firstStop) : '';
};

const getLastStop = (cargo: BookingSummaryCargoJourneyType): string => {
  const journeyLegs: Optional<BookingSummaryJourneyLegType[]> = cargo?.journey?.legs;
  const lastStop: Optional<JourneyStop> = journeyLegs?.[journeyLegs.length - 1]?.to;

  return lastStop ? getStopLocationLabel(lastStop) : '';
};

export const getAddressLabel = (booking: BookingSummaryData, locationType: 'collection' | 'delivery'): string => {
  const { cargoJourneys, networksDestination, networksOrigin } = booking;

  const isCollection: boolean = locationType === 'collection';
  const getStop = isCollection ? getFirstStop : getLastStop;
  const addresses: string[] = cargoJourneys?.journeys?.map(getStop).filter(Boolean) || [];
  const uniqueAddresses: string[] = [...new Set(addresses)];

  if (uniqueAddresses.length === 0) {
    const label: Optional<string> = isCollection ? networksOrigin?.label?.long : networksDestination?.label?.long;

    return label || '';
  }

  return uniqueAddresses.length > 1 ? `Multiple ${locationType} addresses` : uniqueAddresses[0];
};

export const getBookingStatusLabel = (isRoadBooking: boolean, value?: VoyageMilestoneNameEnum): string => {
  if (isRoadBooking) return 'In transit';

  switch (value) {
    case VoyageMilestoneNameEnum.CARGO_ABOARD:
    case VoyageMilestoneNameEnum.COLLECTED:
    case VoyageMilestoneNameEnum.GATE_IN:
      return 'Origin';
    case VoyageMilestoneNameEnum.ARRIVED_AT_DESTINATION_TERMINAL:
    case VoyageMilestoneNameEnum.CARGO_UNLOADED:
    case VoyageMilestoneNameEnum.GATE_OUT:
      return 'Destination';
    case VoyageMilestoneNameEnum.DEPARTED_ORIGIN_TERMINAL:
      return 'In transit';
    default:
      return '';
  }
};
