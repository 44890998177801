import { type FC, ReactNode, useState } from 'react';

import LandingPage from '@zen/Components/LandingPage';
import QueryHandler from '@zen/Components/QueryHandler';
import Step from '@zen/Components/Step';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import { GetAccountDetailsQueryResult, useGetAccountDetailsQuery } from '../AccountDetails/graphql';
import type { AccountLocationDetails } from '../AccountForms/types';
import UpdateAccountDetails from '../AccountForms/UpdateAccountDetails';
import UpdateLegalInformation from '../AccountForms/UpdateLegalInformation';
import ActivatateAccount from './ActivatateAccount';
import {
  getInitiallyExpandedStep,
  isAccountDetailsStepCompleted,
  isAccountStepDisabled,
  isCompanyDetailsStepCompleted
} from './helpers';
import type { AccountStep, StepConfig } from './types';
import UpdateShippingLocation from './UpdateShippingLocation';
import { prepareShippingLocationInitialValues } from './UpdateShippingLocation/helpers';

const AccountActivationPage: FC = () => {
  const { accountUuid: accountId } = useAccount();
  const [expandedSection, setExpandedSection] = useState<Nullable<AccountStep>>(null);

  const { data, error, loading, previousData } = useGetAccountDetailsQuery({
    variables: { accountId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'network-only',
    onCompleted: (accountDetails: GetAccountDetailsQueryResult) => {
      if (previousData) return;

      setExpandedSection(getInitiallyExpandedStep(accountDetails));
    }
  });

  const renderStep = (step: StepConfig): ReactNode => {
    const { headline, iconName, isCompleted, name, component, tagline } = step;

    return (
      <Step
        key={step.name}
        disabledTooltip="Please first complete the previous step"
        headline={headline}
        iconName={iconName}
        isCompleted={isCompleted}
        isDisabled={isAccountStepDisabled(name, expandedSection)}
        isExpanded={expandedSection === name}
        onExpand={() => setExpandedSection(name)}
        tagline={tagline}
      >
        {component}
      </Step>
    );
  };

  return (
    <LandingPage
      data-testid="account-invitation-page"
      tagline="Before you can start managing and fulfilling purchase orders on our platform, complete your account details. Let's get started!"
      title="Welcome to Zencargo - You're only a few steps away from shipping with Acme Inc."
    >
      <QueryHandler data={data} error={!!error} isLoading={loading}>
        {({ account, accountLocations }) => {
          if (!account) return null;

          const { tradingName, referencePrefix, legalInformation } = account;

          const locationInitialValues = prepareShippingLocationInitialValues(
            accountLocations?.nodes?.[0],
            accountId
          ) as AccountLocationDetails;

          const stepsConfig: StepConfig[] = [
            {
              name: 'AccountDetails',
              headline: 'Configure account details',
              iconName: 'zicon-adjust',
              isCompleted: isAccountDetailsStepCompleted({ referencePrefix, tradingName }),
              tagline: 'Help you and your connections identify your shipments.',
              component: (
                <UpdateAccountDetails
                  initialValues={{
                    accountId,
                    referencePrefix,
                    tradingName
                  }}
                  onSuccess={() => setExpandedSection('CompanyDetails')}
                />
              )
            },
            {
              name: 'CompanyDetails',
              headline: 'Complete company details',
              iconName: 'zicon-office',
              isCompleted: isCompanyDetailsStepCompleted(legalInformation),
              tagline: 'Provide your company’s legal details necessary for shipping.',
              component: (
                <UpdateLegalInformation
                  initialValues={{
                    accountId,
                    companyRegistrationNumber: legalInformation.companyRegistrationNumber,
                    eoriNumber: legalInformation.eoriNumber,
                    registeredName: legalInformation.registeredName,
                    vatIdentificationNumber: legalInformation.vatIdentificationNumber
                  }}
                  onSuccess={() => setExpandedSection('ShippingLocation')}
                />
              )
            },
            {
              name: 'ShippingLocation',
              headline: 'Add company headquarters',
              iconName: 'zicon-hq',
              isCompleted: !!accountLocations?.nodes?.[0],
              tagline: 'Enter your legal address and specify whether you can ship from or ship to that address.',
              component: (
                <UpdateShippingLocation
                  accountName={tradingName}
                  initialValues={locationInitialValues}
                  onSuccess={() => setExpandedSection(null)}
                />
              )
            }
          ];

          const showTermsAndConditions: boolean = !expandedSection && stepsConfig.every((step) => step.isCompleted);

          return (
            <div className="flex flex-col mb-8 space-y-4" data-testid="account-invitation-steps">
              {stepsConfig.map(renderStep)}
              {showTermsAndConditions && <ActivatateAccount accountId={accountId} />}
            </div>
          );
        }}
      </QueryHandler>
    </LandingPage>
  );
};

export default AccountActivationPage;
