export const createBusinessHoursInput = (overwrites = {}) => {
  return {
    monday: {
      startTime: '',
      endTime: ''
    },
    tuesday: {
      startTime: '',
      endTime: ''
    },
    wednesday: {
      startTime: '',
      endTime: ''
    },
    thursday: {
      startTime: '',
      endTime: ''
    },
    friday: {
      startTime: '',
      endTime: ''
    },
    saturday: {
      startTime: '',
      endTime: ''
    },
    sunday: {
      startTime: '',
      endTime: ''
    },
    ...overwrites
  };
};
