import { useState } from 'react';

export const useTimeTracker = () => {
  const [timeStarted, setTimeStarted] = useState<number>(() => Date.now());

  const getTimeElapsed = (): number => {
    const currentTime: number = Date.now();

    return currentTime - timeStarted;
  };

  const resetTime = (): void => setTimeStarted(Date.now());

  return {
    getTimeElapsed,
    resetTime
  };
};
