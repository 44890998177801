import { FC, useState } from 'react';

import { SearchInput } from '@zen/DesignSystem';

import { filterStakeholders } from '../subscriptions.helpers';
import type { Stakeholder, SubscriptionStakeholder } from '../types';
import NonSubscriberListItem from './NonSubscriberListItem';

interface Props {
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholders: Stakeholder[];
}

const NonSubscriberList: FC<Props> = ({ onSubscribe, stakeholders }) => {
  const [query, setQuery] = useState<string>('');
  const filteredList = filterStakeholders(stakeholders, query);

  const renderEmptyMessage = () => {
    const label = stakeholders.length === 0 ? 'No stakeholders that can be subscribed to this booking' : 'No results found';

    return (
      <div className="text-sm text-grey-dark leading-snug p-4 mt-2 mx-6 text-center leading-normal" data-testid="empty-message">
        {label}
      </div>
    );
  };

  return (
    <div data-testid="non-subscriber-list">
      <div className="mt-4 px-6 h-16">
        <SearchInput onChange={setQuery} value={query} />
      </div>
      <div className="h-48 overflow-y-auto">
        {filteredList.length === 0 && renderEmptyMessage()}
        {filteredList.map((stakeholder: Stakeholder) => {
          return (
            <NonSubscriberListItem key={stakeholder.contactDetails.id} onSubscribe={onSubscribe} stakeholder={stakeholder} />
          );
        })}
      </div>
    </div>
  );
};

export default NonSubscriberList;
