import { array, number, object } from 'yup';

import type { PortChargeFormInitialValues } from './types';

const validatePrice = (message: string) =>
  object({ value: number().nullable() }).test('price test', function testPriceValue(price) {
    if (price.value === null) {
      return this.createError({ message });
    }

    return true;
  });

export const getValidationSchema = (labeConfig: Record<keyof PortChargeFormInitialValues, string>) => {
  const incotermsValidationMessage: string = `${labeConfig.incoterms} is required.`;

  return object().shape({
    chargeType: object().nullable().required(`${labeConfig.chargeType} is required.`),
    incoterms: array().min(1, incotermsValidationMessage).nullable().required(incotermsValidationMessage),
    port: object().nullable().required(`${labeConfig.port} is required.`),
    price: validatePrice(`${labeConfig.price} is required.`)
  });
};
