import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceCreateAccrualMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateAccrualMutationInput;
}>;

export type FinanceCreateAccrualMutationResult = { __typename?: 'Mutation' } & {
  financeCreateAccrual?: GraphQLTypes.Maybe<
    { __typename?: 'CreateAccrualMutationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      accrual?: GraphQLTypes.Maybe<
        { __typename?: 'Accrual' } & Pick<GraphQLTypes.Accrual, 'id'> & {
            updatedByUserAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'dateTime'>>;
            updatedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
          }
      >;
    }
  >;
};

export const FinanceCreateAccrualDocument = /* #__PURE__ */ gql`
  mutation financeCreateAccrual($input: CreateAccrualMutationInput!) {
    financeCreateAccrual(input: $input) {
      errors {
        message
        path
      }
      accrual {
        id
        updatedByUserAt {
          dateTime
        }
        updatedBy {
          username
        }
      }
    }
  }
`;

/**
 * __useFinanceCreateAccrualMutation__
 *
 * To run a mutation, you first call `useFinanceCreateAccrualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinanceCreateAccrualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financeCreateAccrualMutation, { data, loading, error }] = useFinanceCreateAccrualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinanceCreateAccrualMutation(
  baseOptions?: Apollo.MutationHookOptions<FinanceCreateAccrualMutationResult, FinanceCreateAccrualMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinanceCreateAccrualMutationResult, FinanceCreateAccrualMutationVariables>(
    FinanceCreateAccrualDocument,
    options
  );
}
export type FinanceCreateAccrualMutationHookResult = ReturnType<typeof useFinanceCreateAccrualMutation>;
