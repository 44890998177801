import { PageSize } from '@zen/types';
import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';

import type { RateCardSummary } from '../../types';
import { type GetRateCardsQueryResult, type GetRateCardsQueryVariables, useGetRateCardsQuery } from '..';

const useRateCards = (
  variables: GetRateCardsQueryVariables,
  pageSize: PageSize = PageSize.TWENTY
): PaginatedQueryResult<GetRateCardsQueryResult, GetRateCardsQueryVariables, RateCardSummary> => {
  return usePagination<GetRateCardsQueryResult, GetRateCardsQueryVariables, RateCardSummary>(
    useGetRateCardsQuery,
    'getRateCards',
    variables,
    pageSize,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );
};

export default useRateCards;
