import cx from 'classnames';
import type { FC } from 'react';

import PathIndicator from '@zen/Journey/components/PathIndicator';
import type { Optional } from '@zen/utils/typescript';

import type {
  CargoJourneysSummaryMilestone,
  CargoJourneysSummaryMilestoneDateDetailsWithCargos,
  ModeOfTransport
} from '../../../types';
import {
  getCargoDateDescriptionLabel,
  getCargoJourneysSummaryMilestoneName,
  getFormattedDateOrPlaceholderLabel,
  shouldShowCargoDateDetails,
  SUMMARY_MILESTONE_DELIVERED_LABEL,
  SUMMARY_MILESTONE_DELIVERING_LABEL,
  SUMMARY_MILESTONE_NOT_BOOKED_YET_PLACEHOLDER,
  transformCargos
} from '../helpers';
import SummaryMilestoneCargos from '../SummaryMilestoneCargos';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  milestone: CargoJourneysSummaryMilestone;
  onClick: (cargoId: string) => void;
  showDate?: boolean;
  showPathIndicator?: boolean;
}

const SummaryMilestone: FC<Props> = (props) => {
  const { bookingModeOfTransport, milestone, onClick, showDate = true, showPathIndicator = true } = props;
  const { milestoneDatesDetailsWithCargos, name, progressStage } = milestone;

  const dateDescriptionLabel: string = getCargoDateDescriptionLabel(name, bookingModeOfTransport, progressStage);

  const isDateDescriptionLabelDelivering: boolean = dateDescriptionLabel === SUMMARY_MILESTONE_DELIVERING_LABEL;
  const isDateDescriptionLabelDelivered: boolean = dateDescriptionLabel === SUMMARY_MILESTONE_DELIVERED_LABEL;
  const shouldHighlightDate: boolean = isDateDescriptionLabelDelivering || isDateDescriptionLabelDelivered;

  const shouldShowCargosDate: boolean = showDate && shouldShowCargoDateDetails(milestone, bookingModeOfTransport, progressStage);

  const milestoneName = getCargoJourneysSummaryMilestoneName(milestone, bookingModeOfTransport);

  return (
    <div>
      <div className="flex items-center mt-5 mb-3.5">
        <div className="w-48 text-xs text-right text-grey-base">{shouldShowCargosDate ? dateDescriptionLabel : ''}</div>

        {showPathIndicator && <PathIndicator currentIndicator="route" isCompleted={true} isCurrent={true} />}
        <div className="flex items-center text-base font-bold">{milestoneName}</div>
      </div>

      <div className="flex flex-col items-baseline mb-3">
        {milestoneDatesDetailsWithCargos.map(
          ({ cargos, dateDetails }: CargoJourneysSummaryMilestoneDateDetailsWithCargos, index: number) => {
            const hasNoDateToShow: boolean = dateDetails?.date == null;
            const hasUnconfirmedDate: boolean = hasNoDateToShow;
            const dateOrPlaceholderLabel: string = getFormattedDateOrPlaceholderLabel(
              dateDetails?.date,
              dateDetails?.dateType,
              hasUnconfirmedDate,
              true
            );

            const milestoneCargosDateClassName: string = cx('text-right min-w-48 mr-16 font-bold self-center', {
              'text-red-dark': dateDetails?.hasDateExceptionBetweenInitialAndFinalDates || hasNoDateToShow,
              'text-grey-light': dateOrPlaceholderLabel === SUMMARY_MILESTONE_NOT_BOOKED_YET_PLACEHOLDER,
              'text-sm': shouldHighlightDate,
              'text-xs': !shouldHighlightDate
            });

            return (
              <div key={index} className="flex mb-3 first:mt-0">
                <div className={milestoneCargosDateClassName}>{shouldShowCargosDate ? dateOrPlaceholderLabel : ''}</div>
                <SummaryMilestoneCargos cargos={transformCargos(cargos) || []} onCargoButtonClick={onClick} />
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default SummaryMilestone;
