import { sortBy, uniqBy } from 'lodash';
import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { useOceanCarrierByScacLazyQuery } from '../OceanCarrierInlineField/graphql';
import type { OceanCarrier } from '../OceanCarrierInlineField/types';
import { useOceanCarriersLazyQuery } from './graphql';

type SearchableOptionsArguments = {
  selectedValue: Optional<string>;
};

export const useSearchableOptions = ({ selectedValue }: SearchableOptionsArguments) => {
  const [fetchCarriers, { data, loading, error }] = useOceanCarriersLazyQuery();
  const [fetchCarrier, { data: carrierData }] = useOceanCarrierByScacLazyQuery();

  useEffect(() => {
    if (selectedValue) {
      fetchCarrier({
        variables: {
          scac: selectedValue
        }
      });
    } else {
      fetchCarriers({
        variables: {
          query: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchCarriers({
        variables: {
          query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createCarrierOption = (carrier: Optional<OceanCarrier>): Option<string> => ({
    label: carrier?.oceanCarrierName || '',
    value: carrier?.carrierScac || ''
  });

  const carriers = [carrierData?.oceanCarrierByScac, ...(data?.oceanCarriers?.nodes || [])].filter(Boolean);
  const uniqueCarriers = sortBy(uniqBy(carriers, 'carrierScac'), 'oceanCarrierName');

  return {
    getCarrier: (scac: string) => uniqueCarriers.find((carrier) => carrier?.carrierScac === scac),
    options: uniqueCarriers.map(createCarrierOption),
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
