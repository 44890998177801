import type { FC } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { performMutation } from '@zen/utils/performMutation';

import { useEditRateCardMutation } from '../graphql';
import { useRateCardDetails } from '../graphql/hooks';
import { preparePortChargeInitialValues } from '../helpers';
import RateCardForm from '../RateCardForm';
import { prepareRateCardInput } from '../RateCardForm/helpers';
import type { HaulageChargePayload, RateCardReducerState } from '../RateCardForm/reducer/types';
import type { RateCard } from '../types';

const EditRateCard: FC = () => {
  const { data, loading, error } = useRateCardDetails();
  const { userProfile, accountUuid } = useAccount();
  const { registeredCompanyName } = userProfile;
  const [editRateCard] = useEditRateCardMutation();
  const { addError, addSuccess } = useNotification();
  const { navigateBack } = useNavigationHistory();
  const defaultBackUrl: string = rateCardRoutes.index.getUrl();

  const handleSuccess = (): void => {
    addSuccess('Rate card has been successfully updated.');
    navigateBack(defaultBackUrl);
  };

  const handleSubmit = async (values: RateCardReducerState): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        editRateCard({
          variables: {
            input: {
              ...prepareRateCardInput(values, registeredCompanyName),
              id: data?.legacyGetRateCard?.rateCardId || '',
              updatedBy: userProfile.uuid,
              customerDivisionId: accountUuid
            }
          }
        }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  const getInitialValues = (rateCard: RateCard): RateCardReducerState => {
    const {
      issuedBy,
      freightCharges,
      startDate,
      endDate,
      modeOfTransport,
      name,
      cargoType,
      note,
      originCharges,
      originHaulageCharges,
      destinationHaulageCharges,
      destinationCharges
    } = rateCard;

    return {
      startDate,
      endDate,
      modeOfTransport,
      freightCharges,
      cargoType,
      note,
      originCharges: preparePortChargeInitialValues(originCharges),
      destinationCharges: preparePortChargeInitialValues(destinationCharges),
      name: name || '',
      issuedBy: issuedBy?.id || '',
      originHaulageCharges: (originHaulageCharges || []) as HaulageChargePayload[],
      destinationHaulageCharges: (destinationHaulageCharges || []) as HaulageChargePayload[]
    };
  };

  return (
    <QueryHandler data={data?.legacyGetRateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        return (
          <Page defaultBackUrl={defaultBackUrl} title="Edit rate card">
            <RateCardForm initialValues={getInitialValues(rateCard)} onSubmit={handleSubmit} />
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default EditRateCard;
