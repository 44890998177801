import useRole from '@zen/Auth/hooks/useRole';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { type PurchaseOrdersFilter, Role } from '@zen/graphql/types.generated';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { Terminal } from '../components/Filters/types';
import { useOrderListFiltersQuery } from '../graphql';

interface OrderFiltersResults {
  data: Optional<
    PurchaseOrdersFilter & {
      portsOfDestination: Nullable<Terminal[]>;
      portsOfLoad: Nullable<Terminal[]>;
    }
  >;
  error: boolean;
  loading: boolean;
}

const useOrderFilters = (): OrderFiltersResults => {
  const { accountUuid: accountId } = useAccount();
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const role = useRole();

  const filter = createAccountFlagIsOn && role !== Role.ADMIN ? {} : { accountUuid: accountId };

  const { data, loading, error } = useOrderListFiltersQuery({
    fetchPolicy: 'no-cache',
    variables: accountId ? filter : undefined
  });

  return {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<{ __typename?: "PurchaseOrdersFilter" ... Remove this comment to see the full error message
    data: data?.filters?.purchaseOrders,
    loading,
    error: !!error
  };
};

export default useOrderFilters;
