import { trimObjectValues } from '@zen/utils/utils';

import { CargoFullContainerVariant } from '../../types';
import type { FullContainerFormValues } from './FullContainerForm/types';

export const prepareFullContainerFormValues = (values: FullContainerFormValues) => {
  const { containerSealNumber, containerNumber, garmentsOnHangers, type, hazardous, overweight, containerVariant } = values;

  return trimObjectValues({
    containerNumber,
    containerSealNumber,
    containerType: type,
    dryStandard: containerVariant === CargoFullContainerVariant.DRY,
    flatRack: containerVariant === CargoFullContainerVariant.FLATRACK,
    garmentsOnHangers,
    hazardous: !!hazardous,
    openTop: containerVariant === CargoFullContainerVariant.OPEN_TOP,
    overweight: !!overweight,
    reefer: containerVariant === CargoFullContainerVariant.REFRIGERATED
  });
};
