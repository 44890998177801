import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ArchiveAccountConnectionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveAccountConnectionInput;
}>;

export type ArchiveAccountConnectionMutationResult = { __typename?: 'Mutation' } & {
  archiveAccountConnection?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveAccountConnectionPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const ArchiveAccountConnectionDocument = /* #__PURE__ */ gql`
  mutation archiveAccountConnection($input: ArchiveAccountConnectionInput!) {
    archiveAccountConnection(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useArchiveAccountConnectionMutation__
 *
 * To run a mutation, you first call `useArchiveAccountConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAccountConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAccountConnectionMutation, { data, loading, error }] = useArchiveAccountConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAccountConnectionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAccountConnectionMutationResult, ArchiveAccountConnectionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ArchiveAccountConnectionMutationResult, ArchiveAccountConnectionMutationVariables>(
    ArchiveAccountConnectionDocument,
    options
  );
}
export type ArchiveAccountConnectionMutationHookResult = ReturnType<typeof useArchiveAccountConnectionMutation>;
