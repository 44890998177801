import { Currency } from '@zen/CostTracking/CostTrackingSummaryContainer/types';

import type { TotalsBreakdownType } from '../types';

export const totalsBreakdownMock: TotalsBreakdownType = {
  displayCurrency: Currency.GBP,
  fxRateDate: '2023-11-15',
  totalInDisplayCurrency: 1824,
  perCurrencyBreakdown: [
    {
      currency: Currency.USD,
      fxRateToDisplayCurrency: 0.7962,
      subTotalInDisplayCurrency: 796.2,
      subTotal: 1000
    },
    {
      currency: Currency.EUR,
      fxRateToDisplayCurrency: 0.8556,
      subTotalInDisplayCurrency: 427.8,
      subTotal: 500
    },
    {
      currency: Currency.GBP,
      fxRateToDisplayCurrency: 1,
      subTotalInDisplayCurrency: 600,
      subTotal: 600
    }
  ]
};
