import type { FC } from 'react';

import InformationIcon from '@zen/Components/InformationIcon';
import LocalTimeZone from '@zen/Components/LocalTimeZone';
import { Card, Icon } from '@zen/DesignSystem';
import { formatDateTime } from '@zen/utils/dateTime';

import type { EventLog } from '../types';
import { eventActionMapping } from './helpers';

interface Props extends EventLog {}

const EventLogItem: FC<Props> = ({ additionalInfo, createdAt, eventAction, eventLabel, username, tooltip }) => {
  const { icon, label } = eventActionMapping[eventAction];

  return (
    <div className="flex space-x-3" data-testid="event-log-item">
      <div className="relative z-10 flex items-center justify-center w-8 h-8 border-4 border-white border-solid rounded-full shrink-0 bg-grey-lightest text-grey-base point">
        <Icon className="text-xs" icon={icon} />
      </div>
      <div className="mt-1">
        <div className="flex mb-1 space-x-1 text-sm leading-5">
          <div>
            <span className="font-bold text-grey-dark">{username} </span>
            <span className="text-grey-base">{label} </span>
            <span className="text-grey-dark">{eventLabel}</span>
          </div>
          {tooltip && <InformationIcon content={tooltip} size="small" />}
        </div>
        <div className="text-xs leading-4 text-grey-light">
          {formatDateTime(createdAt)}
          <LocalTimeZone className="ml-1" />
        </div>
        {additionalInfo && <Card className="mt-4">{additionalInfo}</Card>}
      </div>
    </div>
  );
};

export default EventLogItem;
