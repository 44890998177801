import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargeTypeQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type GetChargeTypeQueryResult = { __typename?: 'Query' } & {
  chargeType?: GraphQLTypes.Maybe<
    { __typename?: 'ChargeType' } & Pick<
      GraphQLTypes.ChargeType,
      | 'id'
      | 'name'
      | 'modeOfTransport'
      | 'chargeType'
      | 'chargeBasis'
      | 'vatApplicable'
      | 'twentyFtDv'
      | 'twentyFtHc'
      | 'fortyFtDv'
      | 'fortyFtHc'
      | 'fortyFiveFtHc'
      | 'hazardous'
      | 'reefer'
      | 'overweight'
    >
  >;
};

export const GetChargeTypeDocument = /* #__PURE__ */ gql`
  query getChargeType($id: String!) {
    chargeType(id: $id) {
      id
      name
      modeOfTransport
      chargeType
      chargeBasis
      vatApplicable
      twentyFtDv
      twentyFtHc
      fortyFtDv
      fortyFtHc
      fortyFiveFtHc
      hazardous
      reefer
      overweight
    }
  }
`;

/**
 * __useGetChargeTypeQuery__
 *
 * To run a query within a React component, call `useGetChargeTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeTypeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChargeTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetChargeTypeQueryResult, GetChargeTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargeTypeQueryResult, GetChargeTypeQueryVariables>(GetChargeTypeDocument, options);
}
export function useGetChargeTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargeTypeQueryResult, GetChargeTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargeTypeQueryResult, GetChargeTypeQueryVariables>(GetChargeTypeDocument, options);
}
export type GetChargeTypeQueryHookResult = ReturnType<typeof useGetChargeTypeQuery>;
export type GetChargeTypeLazyQueryHookResult = ReturnType<typeof useGetChargeTypeLazyQuery>;
