import { uniqBy } from 'lodash';

import type { Terminal } from '@zen/Components/TerminalSelect';
import type { TableColumn } from '@zen/DesignSystem';
import { Currency } from '@zen/types';

import type { FreightChargePayload, PortChargePayload } from './RateCardForm/reducer/types';
import type { RateCard } from './types';

type FreightPorts = {
  destinationPorts: Terminal[];
  originPorts: Terminal[];
};

export const prepareFreightPorts = (freightCharges: FreightChargePayload[]): FreightPorts => {
  const originPorts: Terminal[] = uniqBy(
    freightCharges.map((freightCharge: FreightChargePayload) => freightCharge.originPort),
    'unlocode'
  );
  const destinationPorts: Terminal[] = uniqBy(
    freightCharges.map((freightCharge: FreightChargePayload) => freightCharge.destinationPort),
    'unlocode'
  );

  return { originPorts, destinationPorts };
};

export const preparePortChargeInitialValues = (
  portCharges: RateCard['originCharges' | 'destinationCharges']
): PortChargePayload[] => {
  return (
    portCharges?.map((portCharge) => ({
      ...portCharge,
      customChargeValue: portCharge.chargeValue,
      customCurrency: portCharge.currency,
      chargeValue: 0,
      currency: Currency.GBP,
      disabled: !!portCharge.excluded
    })) || []
  );
};

export const createRateCardTableId = <T>(columns: TableColumn<T>[], name: string, type?: 'origin' | 'destination'): string => {
  const tableKeys: string[] = columns.map((column) => column.key);

  tableKeys.unshift(name);

  if (type) {
    tableKeys.unshift(type);
  }

  return tableKeys.join('-');
};
