import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AllLegacyAccountsQueryVariables = GraphQLTypes.Exact<{
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type AllLegacyAccountsQueryResult = { __typename?: 'Query' } & {
  legacyAccounts: { __typename?: 'LegacyAccountConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'name' | 'uuid'> & {
              division?: GraphQLTypes.Maybe<
                { __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'id' | 'name' | 'accountId'>
              >;
            }
        >
      >
    >;
  };
};

export const AllLegacyAccountsDocument = /* #__PURE__ */ gql`
  query allLegacyAccounts($searchQuery: String) {
    legacyAccounts(textContains: $searchQuery, paginated: false) {
      nodes {
        name
        uuid
        division {
          id
          name
          accountId
        }
      }
    }
  }
`;

/**
 * __useAllLegacyAccountsQuery__
 *
 * To run a query within a React component, call `useAllLegacyAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLegacyAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLegacyAccountsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useAllLegacyAccountsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllLegacyAccountsQueryResult, AllLegacyAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AllLegacyAccountsQueryResult, AllLegacyAccountsQueryVariables>(AllLegacyAccountsDocument, options);
}
export function useAllLegacyAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllLegacyAccountsQueryResult, AllLegacyAccountsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AllLegacyAccountsQueryResult, AllLegacyAccountsQueryVariables>(AllLegacyAccountsDocument, options);
}
export type AllLegacyAccountsQueryHookResult = ReturnType<typeof useAllLegacyAccountsQuery>;
export type AllLegacyAccountsLazyQueryHookResult = ReturnType<typeof useAllLegacyAccountsLazyQuery>;
