import type { FC, ReactNode } from 'react';

interface Props {
  backgroundIcon: ReactNode;
  children: ReactNode;
  className?: string;
  iconClassName?: string;
}

const ActivityFeedItemWithIconWrapper: FC<Props> = ({ backgroundIcon, children, className, iconClassName }) => (
  <div className={`flex justify-between relative py-6 ${className}`} data-testid="wrapper-with-icon">
    {children}
    <div className={`flex items-end absolute bottom-6 right-0 -my-12 ${iconClassName}`}>{backgroundIcon}</div>
  </div>
);

export default ActivityFeedItemWithIconWrapper;
