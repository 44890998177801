import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountMainDetailsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountMainDetailsInput;
}>;

export type UpdateAccountMainDetailsMutationResult = { __typename?: 'Mutation' } & {
  updateAccountMainDetails?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountMainDetailsPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAccountMainDetailsDocument = /* #__PURE__ */ gql`
  mutation UpdateAccountMainDetails($input: UpdateAccountMainDetailsInput!) {
    updateAccountMainDetails(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAccountMainDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMainDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMainDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMainDetailsMutation, { data, loading, error }] = useUpdateAccountMainDetailsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountMainDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAccountMainDetailsMutationResult, UpdateAccountMainDetailsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountMainDetailsMutationResult, UpdateAccountMainDetailsMutationVariables>(
    UpdateAccountMainDetailsDocument,
    options
  );
}
export type UpdateAccountMainDetailsMutationHookResult = ReturnType<typeof useUpdateAccountMainDetailsMutation>;
