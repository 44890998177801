import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargesQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetChargesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              costTracking?: GraphQLTypes.Maybe<
                { __typename?: 'CostTracking' } & Pick<GraphQLTypes.CostTracking, 'costTrackingId'> & {
                    charges?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'CostTrackingChargePayload' } & Pick<
                          GraphQLTypes.CostTrackingChargePayload,
                          'applicability' | 'id'
                        > & {
                            chargeType: { __typename?: 'ChargesPayload' } & Pick<
                              GraphQLTypes.ChargesPayload,
                              'basisGroup' | 'id' | 'name'
                            > & { group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'> };
                            costItems?: GraphQLTypes.Maybe<
                              Array<
                                { __typename?: 'CostTrackingCostItem' } & Pick<
                                  GraphQLTypes.CostTrackingCostItem,
                                  | 'currency'
                                  | 'id'
                                  | 'name'
                                  | 'note'
                                  | 'paidTo'
                                  | 'paidFrom'
                                  | 'quantity'
                                  | 'status'
                                  | 'total'
                                  | 'unitPrice'
                                >
                              >
                            >;
                            revenueItems?: GraphQLTypes.Maybe<
                              Array<
                                { __typename?: 'CostTrackingRevenueItem' } & Pick<
                                  GraphQLTypes.CostTrackingRevenueItem,
                                  | 'currency'
                                  | 'id'
                                  | 'name'
                                  | 'note'
                                  | 'paidTo'
                                  | 'paidFrom'
                                  | 'quantity'
                                  | 'status'
                                  | 'total'
                                  | 'unitPrice'
                                >
                              >
                            >;
                          }
                      >
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const GetChargesDocument = /* #__PURE__ */ gql`
  query getCharges($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        costTracking {
          charges {
            applicability
            chargeType {
              basisGroup
              group {
                id
                name
              }
              id
              name
            }
            id
            costItems {
              currency
              id
              name
              note
              paidTo
              paidFrom
              quantity
              status
              total
              unitPrice
            }
            revenueItems {
              currency
              id
              name
              note
              paidTo
              paidFrom
              quantity
              status
              total
              unitPrice
            }
          }
          costTrackingId
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useGetChargesQuery__
 *
 * To run a query within a React component, call `useGetChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargesQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetChargesQuery(baseOptions: Apollo.QueryHookOptions<GetChargesQueryResult, GetChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargesQueryResult, GetChargesQueryVariables>(GetChargesDocument, options);
}
export function useGetChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargesQueryResult, GetChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargesQueryResult, GetChargesQueryVariables>(GetChargesDocument, options);
}
export type GetChargesQueryHookResult = ReturnType<typeof useGetChargesQuery>;
export type GetChargesLazyQueryHookResult = ReturnType<typeof useGetChargesLazyQuery>;
