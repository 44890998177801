import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTaskListCountersQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  taskDetailsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.TaskDetailsFiltersInput>;
}>;

export type GetTaskListCountersQueryResult = { __typename?: 'Query' } & {
  taskListCount?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskDetailsPayloadType' } & Pick<
      GraphQLTypes.WorkflowTaskDetailsPayloadType,
      'allOpenTaskCount' | 'allUnassignedTaskCount' | 'activeTaskCount' | 'completedTaskCount'
    >
  >;
};

export const GetTaskListCountersDocument = /* #__PURE__ */ gql`
  query getTaskListCounters(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
    $taskDetailsFiltersInput: TaskDetailsFiltersInput
  ) {
    taskListCount: taskDetails(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      taskDetailsFiltersInput: $taskDetailsFiltersInput
    ) {
      allOpenTaskCount
      allUnassignedTaskCount
      activeTaskCount
      completedTaskCount
    }
  }
`;

/**
 * __useGetTaskListCountersQuery__
 *
 * To run a query within a React component, call `useGetTaskListCountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListCountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListCountersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      taskDetailsFiltersInput: // value for 'taskDetailsFiltersInput'
 *   },
 * });
 */
export function useGetTaskListCountersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>(GetTaskListCountersDocument, options);
}
export function useGetTaskListCountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTaskListCountersQueryResult, GetTaskListCountersQueryVariables>(
    GetTaskListCountersDocument,
    options
  );
}
export type GetTaskListCountersQueryHookResult = ReturnType<typeof useGetTaskListCountersQuery>;
export type GetTaskListCountersLazyQueryHookResult = ReturnType<typeof useGetTaskListCountersLazyQuery>;
