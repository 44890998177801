import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ChargeTypeIdsQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type ChargeTypeIdsQueryResult = { __typename?: 'Query' } & {
  chargeTypes: { __typename?: 'ChargeTypeConnection' } & {
    nodes?: GraphQLTypes.Maybe<Array<GraphQLTypes.Maybe<{ __typename?: 'ChargeType' } & Pick<GraphQLTypes.ChargeType, 'id'>>>>;
  };
};

export const ChargeTypeIdsDocument = /* #__PURE__ */ gql`
  query chargeTypeIds {
    chargeTypes(paginated: false) {
      nodes {
        id
      }
    }
  }
`;

/**
 * __useChargeTypeIdsQuery__
 *
 * To run a query within a React component, call `useChargeTypeIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChargeTypeIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChargeTypeIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChargeTypeIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<ChargeTypeIdsQueryResult, ChargeTypeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ChargeTypeIdsQueryResult, ChargeTypeIdsQueryVariables>(ChargeTypeIdsDocument, options);
}
export function useChargeTypeIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChargeTypeIdsQueryResult, ChargeTypeIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ChargeTypeIdsQueryResult, ChargeTypeIdsQueryVariables>(ChargeTypeIdsDocument, options);
}
export type ChargeTypeIdsQueryHookResult = ReturnType<typeof useChargeTypeIdsQuery>;
export type ChargeTypeIdsLazyQueryHookResult = ReturnType<typeof useChargeTypeIdsLazyQuery>;
