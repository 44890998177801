import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Counter } from '@zen/DesignSystem';

interface Props {
  count?: number;
  isActive: boolean;
  label: string;
  openInNewTab?: boolean;
  url: string;
}

const NavChildLink: FC<Props> = ({ count = 0, isActive, label, openInNewTab, url }) => {
  const classNames: string = cx(
    { 'text-azure-base': isActive, 'text-navy-base hover:text-azure-base': !isActive },
    'text-base xl:text-sm leading-normal py-0.5 pl-10 flex items-center transition-all duration-200 ease-linear'
  );

  const activeIndicatorClassNames: string = cx(
    { 'bg-azure-base': isActive, 'bg-transparent': !isActive },
    'w-1 h-6 rounded-l transition-all duration-200 ease-linear'
  );

  const navChildLinkCounter: ReactNode = count ? <Counter className="mr-2" size="small" type="active" value={count} /> : null;

  return (
    <Link className={classNames} data-component="nav-item" target={openInNewTab ? '_blank' : undefined} to={url}>
      <span className="pl-2">{label}</span>
      <span className="flex ml-auto">{navChildLinkCounter}</span>
      <span className={activeIndicatorClassNames} />
    </Link>
  );
};

export default NavChildLink;
