import { type FC, ReactElement, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import QueryHandler from '@zen/Components/QueryHandler';
import Search from '@zen/Components/Search';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Table } from '@zen/DesignSystem';
import { SortingOrder, type SortInput } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import {
  type GetAccountLocationsQueryResult,
  type GetAccountLocationsQueryVariables,
  useGetAccountLocationsQuery
} from '../graphql';
import type { AccountLocationSummary } from '../types';
import Filters from './AccountLocationsFilters';
import getColumnsConfiguration from './accountLocationsTableConfiguration';
import AddLocationButton from './AddLocationButton';

const initialOrder: SortInput = {
  direction: SortingOrder.ASC,
  field: 'name'
};

const initialFilters = {
  hqOnly: false,
  includeArchived: false,
  search: ''
};

interface Props {
  accountId: string;
  accountName: string;
}

const AccountLocations: FC<Props> = ({ accountId, accountName }) => {
  const { filters, search, setSearch } = useUrlFilters(initialFilters);
  const { pathname } = useLocation();
  const [order, setOrder] = useState<SortInput>(initialOrder);

  const { loading, error, nodes, paginationInfo, totalCount } = usePagination<
    GetAccountLocationsQueryResult,
    GetAccountLocationsQueryVariables,
    AccountLocationSummary
  >(
    useGetAccountLocationsQuery,
    'accountLocations',
    { accountId, order, accountLocationsFiltersInput: { ...filters, searchQuery: search } },
    20,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const columns = useMemo(() => {
    return getColumnsConfiguration({ accountId, pathname });
  }, [pathname]);

  const tableId: string = 'accountLocations';

  const getLocationsTable = (locations: AccountLocationSummary[]): ReactElement => (
    <Table<AccountLocationSummary>
      actions={<AddLocationButton accountId={accountId} accountName={accountName} />}
      additionalActions={
        <div className="flex space-x-2">
          <Search
            collapsible={true}
            onClear={() => setSearch('')}
            onSubmit={setSearch}
            placeholder="Search account locations..."
            size="compact"
            widthClassName="w-48"
          />
          <Filters accountId={accountId} />
        </div>
      }
      columns={columns}
      data={locations}
      emptyText="No locations"
      onOrderChange={setOrder}
      order={order}
      paginationInfo={paginationInfo}
      rowKey="id"
      tableId={tableId}
      title="Locations"
      totalCountConfig={{
        totalCount,
        entityName: 'location'
      }}
    />
  );

  return (
    <QueryHandler
      data={nodes}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonTableLoading columns={columns} rowCount={20} tableId={tableId} />}
      noResults={getLocationsTable([])}
    >
      {getLocationsTable}
    </QueryHandler>
  );
};

export default AccountLocations;
