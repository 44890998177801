import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { Currency, Money } from '@zen/types';
import type { DeepNullable, Optional } from '@zen/utils/typescript';

import type {
  PortChargeFormFieldLabelConfig,
  PortChargeFormInitialValues,
  PortChargeFormLabelConfig,
  PortChargeFormValues
} from './types';

export const preparePayload = (values: PortChargeFormValues): PortChargePayload => {
  const { chargeType, price, incoterms, port } = values;

  return {
    chargeType,
    chargeValue: 0,
    currency: Currency.GBP,
    customChargeValue: price.value,
    customCurrency: price.currency,
    port,
    incoterms,
    centralPortChargeId: ''
  };
};

export const prepareInitialValues = (values: PortChargePayload): PortChargeFormInitialValues => {
  const { chargeType, incoterms, port, customChargeValue, customCurrency, currency } = values;

  return {
    chargeType,
    price: {
      value: customChargeValue || 0,
      currency: customCurrency || currency
    },
    port,
    incoterms
  };
};

export const prepareDefaultPrice = (values: Optional<PortChargePayload>): Money => {
  if (!values?.currency || !values?.chargeValue) {
    return {
      value: 0,
      currency: Currency.GBP
    };
  }

  return {
    value: values.chargeValue,
    currency: values.currency
  };
};

export const originPortChargeFormLabels: PortChargeFormFieldLabelConfig = {
  price: 'Price',
  incoterms: 'Incoterms',
  port: 'Port of origin',
  chargeType: 'Charge type'
};

export const destinationPortChargeFormLabels: PortChargeFormFieldLabelConfig = {
  price: 'Price',
  incoterms: 'Incoterms',
  port: 'Port of destination',
  chargeType: 'Charge type'
};

export const getPortChargeFormLabels = (type: 'origin' | 'destination'): PortChargeFormFieldLabelConfig => {
  return type === 'origin' ? originPortChargeFormLabels : destinationPortChargeFormLabels;
};

const formLabelConfiguration = {
  create: {
    new: {
      buttonTitle: 'Add charge',
      title: 'Add custom charge'
    },
    foundExisting: {
      withCustomPrice: {
        buttonTitle: 'Save changes',
        title: 'Edit custom price'
      },
      withoutCustomPrice: {
        buttonTitle: 'Set price',
        title: 'Set custom price'
      }
    }
  },
  edit: {
    custom: {
      buttonTitle: 'Save changes',
      title: 'Edit custom charge'
    },
    central: {
      withCustomPrice: {
        buttonTitle: 'Save changes',
        title: 'Edit custom price'
      },
      withoutCustomPrice: {
        buttonTitle: 'Set price',
        title: 'Set custom price'
      }
    }
  }
};

export const getCreateFormLabelConfig = (matchingPortCharge: PortChargePayload | undefined): PortChargeFormLabelConfig => {
  if (!matchingPortCharge) {
    return formLabelConfiguration.create.new;
  }

  if (matchingPortCharge.customChargeValue && matchingPortCharge.customCurrency) {
    return formLabelConfiguration.create.foundExisting.withCustomPrice;
  }

  return formLabelConfiguration.create.foundExisting.withoutCustomPrice;
};

export const getEditCentralPortChargeFormLabelConfig = (price: Optional<DeepNullable<Money>>): PortChargeFormLabelConfig => {
  if (price?.value && price?.currency) {
    return formLabelConfiguration.edit.central.withCustomPrice;
  }

  return formLabelConfiguration.edit.central.withoutCustomPrice;
};

export const getEditCustomPortChargeFormLabelConfig = (): PortChargeFormLabelConfig => {
  return formLabelConfiguration.edit.custom;
};
