import { IncotermsValue } from '@zen/types';

export const LABEL_FOR_INCOTERMS: Record<IncotermsValue, string> = {
  [IncotermsValue.CFR]: 'CFR',
  [IncotermsValue.CIF]: 'CIF',
  [IncotermsValue.CIP]: 'CIP',
  [IncotermsValue.CPT]: 'CPT',
  [IncotermsValue.DAP]: 'DAP',
  [IncotermsValue.DAT]: 'DAT',
  [IncotermsValue.DDP]: 'DDP',
  [IncotermsValue.EXWORKS]: 'EXW',
  [IncotermsValue.FAS]: 'FAS',
  [IncotermsValue.FCA]: 'FCA',
  [IncotermsValue.FOB]: 'FOB'
};

export const SEARCH_DEBOUNCE_DELAY: number = 300;

export const QUERY_POLLING_INTERVAL = 15000;

export const ACCESS_TOKEN_REFRESH_INTERVAL = 85 * 60 * 1000; // 85 minutes (tokens set to expire after 90 mins in Auth0)
