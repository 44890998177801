import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteCostTrackingChargeItemsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteCostTrackingItemsInput;
}>;

export type DeleteCostTrackingChargeItemsMutationResult = { __typename?: 'Mutation' } & {
  deleteCostTrackingItems?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteCostTrackingItemsPayload' } & Pick<
      GraphQLTypes.DeleteCostTrackingItemsPayload,
      'deletedItemIds' | 'failedItemIds'
    > & {
        costTracking?: GraphQLTypes.Maybe<
          { __typename?: 'CostTracking' } & Pick<GraphQLTypes.CostTracking, 'costTrackingId' | 'zencargoReference'> & {
              charges?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CostTrackingChargePayload' } & Pick<
                    GraphQLTypes.CostTrackingChargePayload,
                    'applicability' | 'id'
                  > & {
                      chargeType: { __typename?: 'ChargesPayload' } & Pick<
                        GraphQLTypes.ChargesPayload,
                        'basisGroup' | 'id' | 'name'
                      > & { group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'> };
                      costItems?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'CostTrackingCostItem' } & Pick<
                            GraphQLTypes.CostTrackingCostItem,
                            | 'currency'
                            | 'id'
                            | 'name'
                            | 'note'
                            | 'paidTo'
                            | 'paidFrom'
                            | 'quantity'
                            | 'status'
                            | 'total'
                            | 'unitPrice'
                          >
                        >
                      >;
                      revenueItems?: GraphQLTypes.Maybe<
                        Array<
                          { __typename?: 'CostTrackingRevenueItem' } & Pick<
                            GraphQLTypes.CostTrackingRevenueItem,
                            | 'currency'
                            | 'id'
                            | 'name'
                            | 'note'
                            | 'paidTo'
                            | 'paidFrom'
                            | 'quantity'
                            | 'status'
                            | 'total'
                            | 'unitPrice'
                          >
                        >
                      >;
                    }
                >
              >;
              totals: { __typename?: 'CostTrackingTotals' } & Pick<
                GraphQLTypes.CostTrackingTotals,
                | 'displayCurrency'
                | 'profit'
                | 'margin'
                | 'thresholdDate'
                | 'totalCostInDisplayCurrency'
                | 'totalRevenueInDisplayCurrency'
              >;
            }
        >;
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
      }
  >;
};

export const DeleteCostTrackingChargeItemsDocument = /* #__PURE__ */ gql`
  mutation deleteCostTrackingChargeItems($input: DeleteCostTrackingItemsInput!) {
    deleteCostTrackingItems(input: $input) {
      costTracking {
        charges {
          applicability
          chargeType {
            basisGroup
            group {
              id
              name
            }
            id
            name
          }
          id
          costItems {
            currency
            id
            name
            note
            paidTo
            paidFrom
            quantity
            status
            total
            unitPrice
          }
          revenueItems {
            currency
            id
            name
            note
            paidTo
            paidFrom
            quantity
            status
            total
            unitPrice
          }
        }
        costTrackingId
        totals {
          displayCurrency
          profit
          margin
          thresholdDate
          totalCostInDisplayCurrency
          totalRevenueInDisplayCurrency
        }
        zencargoReference
      }
      deletedItemIds
      errors {
        path
        message
      }
      failedItemIds
    }
  }
`;

/**
 * __useDeleteCostTrackingChargeItemsMutation__
 *
 * To run a mutation, you first call `useDeleteCostTrackingChargeItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostTrackingChargeItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostTrackingChargeItemsMutation, { data, loading, error }] = useDeleteCostTrackingChargeItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostTrackingChargeItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCostTrackingChargeItemsMutationResult,
    DeleteCostTrackingChargeItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteCostTrackingChargeItemsMutationResult, DeleteCostTrackingChargeItemsMutationVariables>(
    DeleteCostTrackingChargeItemsDocument,
    options
  );
}
export type DeleteCostTrackingChargeItemsMutationHookResult = ReturnType<typeof useDeleteCostTrackingChargeItemsMutation>;
