import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../../graphql/types.generated';
import { JourneyShippingMilestoneFragmentDoc } from '../../../../../CargoOverview/graphql/cargoOverview.generated';

const defaultOptions = {} as const;

export type CargoDetailsFragment = { __typename?: 'CoreCargo' } & Pick<
  GraphQLTypes.CoreCargo,
  'id' | 'cargoType' | 'containerNumber' | 'quantity'
>;

export type CargoJourneysSummaryStopFragment = { __typename?: 'CargoJourneysSummaryStop' } & Pick<
  GraphQLTypes.CargoJourneysSummaryStop,
  'id' | 'locationType' | 'stopAction'
> & {
    terminal?: GraphQLTypes.Maybe<{ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>>;
    warehouse?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLoc' } & {
        label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
      }
    >;
  };

export type CargoJourneysSummaryDateDetailsFragment = { __typename?: 'CargoJourneysSummaryDateDetails' } & Pick<
  GraphQLTypes.CargoJourneysSummaryDateDetails,
  'date' | 'dateType' | 'hasDateExceptionBetweenInitialAndFinalDates'
>;

export type CargoJourneysSummaryScheduledStopFragment = { __typename?: 'CargoJourneysSummaryScheduledStop' } & Pick<
  GraphQLTypes.CargoJourneysSummaryScheduledStop,
  'isCompleted' | 'locationType'
> & {
    dateDetails?: GraphQLTypes.Maybe<
      { __typename?: 'CargoJourneysSummaryDateDetails' } & Pick<
        GraphQLTypes.CargoJourneysSummaryDateDetails,
        'date' | 'dateType' | 'hasDateExceptionBetweenInitialAndFinalDates'
      >
    >;
    terminal?: GraphQLTypes.Maybe<{ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>>;
    warehouse?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLoc' } & {
        label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
      }
    >;
  };

export type CargoJourneysSummaryQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CargoJourneysSummaryQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              cargoJourneys?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneys' } & {
                  cargoReadyDate?: GraphQLTypes.Maybe<
                    { __typename?: 'JourneyShippingCargoReadyDateMilestone' } & Pick<
                      GraphQLTypes.JourneyShippingCargoReadyDateMilestone,
                      | 'canManageDates'
                      | 'canManageDelays'
                      | 'canUpdatePlannedDate'
                      | 'category'
                      | 'completed'
                      | 'delayInDays'
                      | 'id'
                      | 'milestoneType'
                      | 'name'
                    > & {
                        canManageDatesCurrently?: GraphQLTypes.Maybe<
                          { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                            GraphQLTypes.JourneyManageDatesValidationResult,
                            'reason' | 'value'
                          >
                        >;
                        canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                          { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                            GraphQLTypes.JourneyPlannedDateValidationResult,
                            'value' | 'reason'
                          >
                        >;
                        datesValidation?: GraphQLTypes.Maybe<
                          { __typename?: 'JourneyShippingCargoReadyDateMilestoneDatesValidation' } & Pick<
                            GraphQLTypes.JourneyShippingCargoReadyDateMilestoneDatesValidation,
                            'maximumDate' | 'nextMilestoneStopAction'
                          > & {
                              nextMilestone: { __typename?: 'JourneyShippingMilestone' } & Pick<
                                GraphQLTypes.JourneyShippingMilestone,
                                | 'canManageDates'
                                | 'canManageDelays'
                                | 'canUpdateActualDate'
                                | 'canUpdatePlannedDate'
                                | 'canUpdateRequestedBy'
                                | 'canUpdateRequestedDate'
                                | 'category'
                                | 'completed'
                                | 'dateType'
                                | 'delayInDays'
                                | 'id'
                                | 'milestoneType'
                                | 'name'
                                | 'requestedBy'
                              > & {
                                  actual?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                                  >;
                                  canManageDatesCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyManageDatesValidationResult' } & Pick<
                                      GraphQLTypes.JourneyManageDatesValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveActualDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemovePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemovePlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemovePlannedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canRemoveRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRemoveRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRemoveRequestedDateValidationResult,
                                      'reason' | 'value'
                                    >
                                  >;
                                  canUpdateActualDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyActualDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyActualDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdatePlannedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyPlannedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyPlannedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  canUpdateRequestedDateCurrently?: GraphQLTypes.Maybe<
                                    { __typename?: 'JourneyRequestedDateValidationResult' } & Pick<
                                      GraphQLTypes.JourneyRequestedDateValidationResult,
                                      'value' | 'reason'
                                    >
                                  >;
                                  delays: Array<
                                    { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                                      GraphQLTypes.JourneyShippingMilestoneDelay,
                                      'id' | 'days'
                                    > & {
                                        reason?: GraphQLTypes.Maybe<
                                          { __typename?: 'ShippingDelayReason' } & Pick<
                                            GraphQLTypes.ShippingDelayReason,
                                            'id' | 'reason'
                                          >
                                        >;
                                      }
                                  >;
                                  initialEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  latestEstimate?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                    }
                                  >;
                                  planned?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                  requested?: GraphQLTypes.Maybe<
                                    { __typename?: 'LocalDateTimeRange' } & {
                                      endDateTime?: GraphQLTypes.Maybe<
                                        { __typename?: 'LocalDateTimeType' } & Pick<
                                          GraphQLTypes.LocalDateTimeType,
                                          'date' | 'time'
                                        >
                                      >;
                                      startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                                        GraphQLTypes.LocalDateTimeType,
                                        'date' | 'time'
                                      >;
                                    }
                                  >;
                                };
                            }
                        >;
                        delays: Array<
                          { __typename?: 'JourneyShippingMilestoneDelay' } & Pick<
                            GraphQLTypes.JourneyShippingMilestoneDelay,
                            'id' | 'days'
                          > & {
                              reason?: GraphQLTypes.Maybe<
                                { __typename?: 'ShippingDelayReason' } & Pick<GraphQLTypes.ShippingDelayReason, 'id' | 'reason'>
                              >;
                            }
                        >;
                        initialEstimate?: GraphQLTypes.Maybe<
                          { __typename?: 'LocalDateTimeRange' } & {
                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                              GraphQLTypes.LocalDateTimeType,
                              'date' | 'time'
                            >;
                            endDateTime?: GraphQLTypes.Maybe<
                              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                            >;
                          }
                        >;
                        latestEstimate?: GraphQLTypes.Maybe<
                          { __typename?: 'LocalDateTimeRange' } & {
                            startDateTime: { __typename?: 'LocalDateTimeType' } & Pick<
                              GraphQLTypes.LocalDateTimeType,
                              'date' | 'time'
                            >;
                            endDateTime?: GraphQLTypes.Maybe<
                              { __typename?: 'LocalDateTimeType' } & Pick<GraphQLTypes.LocalDateTimeType, 'date' | 'time'>
                            >;
                          }
                        >;
                      }
                  >;
                }
              >;
              cargoJourneysSummary?: GraphQLTypes.Maybe<
                { __typename?: 'CargoJourneysSummary' } & Pick<
                  GraphQLTypes.CargoJourneysSummary,
                  'collectedCargos' | 'deliveredCargos'
                > & {
                    cargosNotCollected: Array<
                      { __typename?: 'CoreCargo' } & Pick<
                        GraphQLTypes.CoreCargo,
                        'id' | 'cargoType' | 'containerNumber' | 'quantity'
                      >
                    >;
                    collectionStops: Array<
                      { __typename?: 'CargoJourneysSummaryStop' } & Pick<
                        GraphQLTypes.CargoJourneysSummaryStop,
                        'id' | 'locationType' | 'stopAction'
                      > & {
                          terminal?: GraphQLTypes.Maybe<
                            { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>
                          >;
                          warehouse?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            }
                          >;
                        }
                    >;
                    deliveryStops: Array<
                      { __typename?: 'CargoJourneysSummaryStop' } & Pick<
                        GraphQLTypes.CargoJourneysSummaryStop,
                        'id' | 'locationType' | 'stopAction'
                      > & {
                          terminal?: GraphQLTypes.Maybe<
                            { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>
                          >;
                          warehouse?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            }
                          >;
                        }
                    >;
                    milestones: Array<
                      { __typename?: 'CargoJourneysSummaryMilestone' } & Pick<
                        GraphQLTypes.CargoJourneysSummaryMilestone,
                        'name' | 'lastCompletedName' | 'progressStage' | 'modeOfTransport' | 'stopAction'
                      > & {
                          milestoneDatesDetailsWithCargos: Array<
                            { __typename?: 'CargoJourneysSummaryMilestoneDateDetailsWithCargos' } & {
                              cargos: Array<
                                { __typename?: 'CoreCargo' } & Pick<
                                  GraphQLTypes.CoreCargo,
                                  'id' | 'cargoType' | 'containerNumber' | 'quantity'
                                >
                              >;
                              dateDetails?: GraphQLTypes.Maybe<
                                { __typename?: 'CargoJourneysSummaryDateDetails' } & Pick<
                                  GraphQLTypes.CargoJourneysSummaryDateDetails,
                                  'date' | 'dateType' | 'hasDateExceptionBetweenInitialAndFinalDates'
                                >
                              >;
                            }
                          >;
                        }
                    >;
                    scheduledOriginStop?: GraphQLTypes.Maybe<
                      { __typename?: 'CargoJourneysSummaryScheduledStop' } & Pick<
                        GraphQLTypes.CargoJourneysSummaryScheduledStop,
                        'isCompleted' | 'locationType'
                      > & {
                          dateDetails?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoJourneysSummaryDateDetails' } & Pick<
                              GraphQLTypes.CargoJourneysSummaryDateDetails,
                              'date' | 'dateType' | 'hasDateExceptionBetweenInitialAndFinalDates'
                            >
                          >;
                          terminal?: GraphQLTypes.Maybe<
                            { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>
                          >;
                          warehouse?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            }
                          >;
                        }
                    >;
                    scheduledDestinationStop?: GraphQLTypes.Maybe<
                      { __typename?: 'CargoJourneysSummaryScheduledStop' } & Pick<
                        GraphQLTypes.CargoJourneysSummaryScheduledStop,
                        'isCompleted' | 'locationType'
                      > & {
                          dateDetails?: GraphQLTypes.Maybe<
                            { __typename?: 'CargoJourneysSummaryDateDetails' } & Pick<
                              GraphQLTypes.CargoJourneysSummaryDateDetails,
                              'date' | 'dateType' | 'hasDateExceptionBetweenInitialAndFinalDates'
                            >
                          >;
                          terminal?: GraphQLTypes.Maybe<
                            { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode'>
                          >;
                          warehouse?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksOrgLoc' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            }
                          >;
                        }
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const CargoDetailsFragmentDoc = /* #__PURE__ */ gql`
  fragment CargoDetails on CoreCargo {
    id
    cargoType
    containerNumber
    quantity
  }
`;
export const CargoJourneysSummaryStopFragmentDoc = /* #__PURE__ */ gql`
  fragment CargoJourneysSummaryStop on CargoJourneysSummaryStop {
    id
    locationType
    stopAction
    terminal {
      label
      unlocode
    }
    warehouse {
      label {
        short
        long
      }
    }
  }
`;
export const CargoJourneysSummaryDateDetailsFragmentDoc = /* #__PURE__ */ gql`
  fragment CargoJourneysSummaryDateDetails on CargoJourneysSummaryDateDetails {
    date
    dateType
    hasDateExceptionBetweenInitialAndFinalDates
  }
`;
export const CargoJourneysSummaryScheduledStopFragmentDoc = /* #__PURE__ */ gql`
  fragment CargoJourneysSummaryScheduledStop on CargoJourneysSummaryScheduledStop {
    dateDetails {
      ...CargoJourneysSummaryDateDetails
    }
    isCompleted
    locationType
    terminal {
      label
      unlocode
    }
    warehouse {
      label {
        short
        long
      }
    }
  }
  ${CargoJourneysSummaryDateDetailsFragmentDoc}
`;
export const CargoJourneysSummaryDocument = /* #__PURE__ */ gql`
  query cargoJourneysSummary($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        cargoJourneys {
          cargoReadyDate {
            canManageDates
            canManageDatesCurrently {
              reason
              value
            }
            canManageDelays
            canUpdatePlannedDate
            canUpdatePlannedDateCurrently {
              value
              reason
            }
            category
            completed
            datesValidation {
              maximumDate
              nextMilestone {
                ...JourneyShippingMilestone
              }
              nextMilestoneStopAction
            }
            delayInDays
            delays {
              id
              days
              reason {
                id
                reason
              }
            }
            id
            initialEstimate {
              startDateTime {
                date
                time
              }
              endDateTime {
                date
                time
              }
            }
            latestEstimate {
              startDateTime {
                date
                time
              }
              endDateTime {
                date
                time
              }
            }
            milestoneType
            name
          }
        }
        cargoJourneysSummary {
          cargosNotCollected {
            ...CargoDetails
          }
          collectedCargos
          deliveredCargos
          collectionStops {
            ...CargoJourneysSummaryStop
          }
          deliveryStops {
            ...CargoJourneysSummaryStop
          }
          milestones {
            name
            lastCompletedName
            progressStage
            milestoneDatesDetailsWithCargos {
              cargos {
                ...CargoDetails
              }
              dateDetails {
                ...CargoJourneysSummaryDateDetails
              }
            }
            modeOfTransport
            stopAction
          }
          scheduledOriginStop {
            ...CargoJourneysSummaryScheduledStop
          }
          scheduledDestinationStop {
            ...CargoJourneysSummaryScheduledStop
          }
        }
        zencargoReference
      }
    }
  }
  ${JourneyShippingMilestoneFragmentDoc}
  ${CargoDetailsFragmentDoc}
  ${CargoJourneysSummaryStopFragmentDoc}
  ${CargoJourneysSummaryDateDetailsFragmentDoc}
  ${CargoJourneysSummaryScheduledStopFragmentDoc}
`;

/**
 * __useCargoJourneysSummaryQuery__
 *
 * To run a query within a React component, call `useCargoJourneysSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargoJourneysSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargoJourneysSummaryQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCargoJourneysSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<CargoJourneysSummaryQueryResult, CargoJourneysSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CargoJourneysSummaryQueryResult, CargoJourneysSummaryQueryVariables>(
    CargoJourneysSummaryDocument,
    options
  );
}
export function useCargoJourneysSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CargoJourneysSummaryQueryResult, CargoJourneysSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CargoJourneysSummaryQueryResult, CargoJourneysSummaryQueryVariables>(
    CargoJourneysSummaryDocument,
    options
  );
}
export type CargoJourneysSummaryQueryHookResult = ReturnType<typeof useCargoJourneysSummaryQuery>;
export type CargoJourneysSummaryLazyQueryHookResult = ReturnType<typeof useCargoJourneysSummaryLazyQuery>;
