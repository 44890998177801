import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetLegacyAccountListQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetLegacyAccountListQueryResult = { __typename?: 'Query' } & {
  legacyAccounts: { __typename?: 'LegacyAccountConnection' } & Pick<GraphQLTypes.LegacyAccountConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid' | 'name' | 'referencePrefix'> & {
                division?: GraphQLTypes.Maybe<{ __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'name'>>;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetLegacyAccountListDocument = /* #__PURE__ */ gql`
  query getLegacyAccountList($after: String, $before: String, $first: Int, $last: Int, $searchQuery: String, $order: SortInput) {
    legacyAccounts(after: $after, before: $before, first: $first, last: $last, textContains: $searchQuery, order: $order) {
      nodes {
        uuid
        name
        division {
          name
        }
        referencePrefix
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetLegacyAccountListQuery__
 *
 * To run a query within a React component, call `useGetLegacyAccountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegacyAccountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegacyAccountListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetLegacyAccountListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLegacyAccountListQueryResult, GetLegacyAccountListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetLegacyAccountListQueryResult, GetLegacyAccountListQueryVariables>(
    GetLegacyAccountListDocument,
    options
  );
}
export function useGetLegacyAccountListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLegacyAccountListQueryResult, GetLegacyAccountListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetLegacyAccountListQueryResult, GetLegacyAccountListQueryVariables>(
    GetLegacyAccountListDocument,
    options
  );
}
export type GetLegacyAccountListQueryHookResult = ReturnType<typeof useGetLegacyAccountListQuery>;
export type GetLegacyAccountListLazyQueryHookResult = ReturnType<typeof useGetLegacyAccountListLazyQuery>;
