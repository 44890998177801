import { components } from 'react-select';

import { IconButton } from '../../../Button';

const DropdownIndicator: typeof components.DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <IconButton
          className="text-xs text-grey-light"
          delayShow={1500}
          icon="zicon-chevron-down"
          size="small"
          title="Dropdown"
          variant="ghost"
        />
      </components.DropdownIndicator>
    )
  );
};

export default DropdownIndicator;
