import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AcceptQuoteOptionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AcceptQuoteOptionInput;
}>;

export type AcceptQuoteOptionMutationResult = { __typename?: 'Mutation' } & {
  bookingAcceptQuoteOption?: GraphQLTypes.Maybe<
    { __typename?: 'AcceptQuoteOptionPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<
        { __typename?: 'QuoteOption' } & Pick<
          GraphQLTypes.QuoteOption,
          'id' | 'zencargoReference' | 'quoteReference' | 'accrualId' | 'description' | 'state'
        > & {
            total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
            expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
          }
      >;
    }
  >;
};

export const AcceptQuoteOptionDocument = /* #__PURE__ */ gql`
  mutation acceptQuoteOption($input: AcceptQuoteOptionInput!) {
    bookingAcceptQuoteOption(input: $input) {
      quoteOption {
        id
        zencargoReference
        quoteReference
        accrualId
        description
        state
        total {
          value
          currency
        }
        expiryDate {
          date
        }
      }
    }
  }
`;

/**
 * __useAcceptQuoteOptionMutation__
 *
 * To run a mutation, you first call `useAcceptQuoteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptQuoteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptQuoteOptionMutation, { data, loading, error }] = useAcceptQuoteOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptQuoteOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<AcceptQuoteOptionMutationResult, AcceptQuoteOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<AcceptQuoteOptionMutationResult, AcceptQuoteOptionMutationVariables>(
    AcceptQuoteOptionDocument,
    options
  );
}
export type AcceptQuoteOptionMutationHookResult = ReturnType<typeof useAcceptQuoteOptionMutation>;
