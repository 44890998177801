import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type EditRateCardMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.LegacyUpdateRateCardInput;
}>;

export type EditRateCardMutationResult = { __typename?: 'Mutation' } & {
  legacyUpdateRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyUpdateRateCardPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const EditRateCardDocument = /* #__PURE__ */ gql`
  mutation editRateCard($input: LegacyUpdateRateCardInput!) {
    legacyUpdateRateCard(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useEditRateCardMutation__
 *
 * To run a mutation, you first call `useEditRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editRateCardMutation, { data, loading, error }] = useEditRateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditRateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<EditRateCardMutationResult, EditRateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<EditRateCardMutationResult, EditRateCardMutationVariables>(EditRateCardDocument, options);
}
export type EditRateCardMutationHookResult = ReturnType<typeof useEditRateCardMutation>;
