import type { FC } from 'react';

import { Pill, PillType } from '@zen/DesignSystem';

import { buildIssueLabel, variantPillMapping } from '../../issueHelpers';
import { IssueState } from '../../types';
import type { IssuePillData } from './types';

interface Props {
  className?: string;
  issue: IssuePillData;
  onClick?: () => void;
}

const IssuePill: FC<Props> = (props) => {
  const { className, issue, onClick } = props;
  const issueLabel: string = buildIssueLabel(issue);
  const issueResolved: boolean = issue.state === IssueState.RESOLVED;
  const pillType: PillType = issueResolved ? 'pending' : variantPillMapping[issue.severity];

  return (
    <span className="inline-block mb-2" data-testid="issue-pill">
      <Pill className={className} onClick={onClick} type={pillType}>
        {issueLabel}
      </Pill>
    </span>
  );
};

export type { Props as IssuePillProps };

export default IssuePill;
