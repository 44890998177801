import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingRequestsCountQueryVariables = GraphQLTypes.Exact<{
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type BookingRequestsCountQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
  bookingRequests: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
  bookingsManufacturerRequested: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
  bookingReadyForApproval: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
  bookingsRejected: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
};

export const BookingRequestsCountQueryDocument = /* #__PURE__ */ gql`
  query bookingRequestsCountQuery($customerUuid: String) {
    bookings(onlyBookingRequests: true, customerUuid: $customerUuid) {
      totalCount
    }
    bookingRequests: bookings(onlyBookingRequests: true, customerUuid: $customerUuid) {
      totalCount
    }
    bookingsManufacturerRequested: bookings(onlyManufacturerRequested: true, customerUuid: $customerUuid) {
      totalCount
    }
    bookingReadyForApproval: bookings(onlyReadyForApproval: true, customerUuid: $customerUuid) {
      totalCount
    }
    bookingsRejected: bookings(onlyRejected: true, customerUuid: $customerUuid) {
      totalCount
    }
  }
`;

/**
 * __useBookingRequestsCountQuery__
 *
 * To run a query within a React component, call `useBookingRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingRequestsCountQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useBookingRequestsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<BookingRequestsCountQueryResult, BookingRequestsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingRequestsCountQueryResult, BookingRequestsCountQueryVariables>(
    BookingRequestsCountQueryDocument,
    options
  );
}
export function useBookingRequestsCountQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingRequestsCountQueryResult, BookingRequestsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingRequestsCountQueryResult, BookingRequestsCountQueryVariables>(
    BookingRequestsCountQueryDocument,
    options
  );
}
export type BookingRequestsCountQueryHookResult = ReturnType<typeof useBookingRequestsCountQuery>;
export type BookingRequestsCountQueryLazyQueryHookResult = ReturnType<typeof useBookingRequestsCountQueryLazyQuery>;
