import type { RateCard } from '@zen/RateCards/LegacyRateCards/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { Optional } from '@zen/utils/typescript';

import { useRateCardLazyQuery } from '../../graphql';
import { exportRateCardData } from '../exportRateCard';

export const useExportRateCard = (): [(id: string, customerId: string) => Promise<void>] => {
  const [fetchRateCard] = useRateCardLazyQuery();
  const { addError, addSuccess } = useNotification();

  const handleSuccess = (): void => addSuccess('Rate card has been exported.');

  const exportRateCard = async (id: string, customerId: string): Promise<void> => {
    const { data } = await fetchRateCard({ variables: { id, customerId } });

    const rateCardDetails: Optional<RateCard> = data?.legacyGetRateCard;

    if (!rateCardDetails) {
      addError('There was an error exporting this rate card. Please try again later.');

      return;
    }

    exportRateCardData(rateCardDetails);
    handleSuccess();
  };

  return [exportRateCard];
};
