import type { ReactNode } from 'react';
import { components } from 'react-select';

import InlineEmptyState from '../../../EmptyState';

const searchNoResultsText: string = 'No results found matching your query.';
const noOptionsAvailable: string = 'No options available.';

const getNoOptionsMessage =
  (suggestion: ReactNode = null, isAsync: boolean = false): typeof components.NoOptionsMessage =>
  (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <InlineEmptyState>
          {isAsync || props.options.length ? searchNoResultsText : noOptionsAvailable}
          {suggestion}
        </InlineEmptyState>
      </components.NoOptionsMessage>
    );
  };

export default getNoOptionsMessage;
