import type { RadioGroupOption } from '@zen/Components/RadioGroup';

export enum LocationLink {
  MY_COMPANY = 'MY_COMPANY',
  OTHER_COMPANY = 'OTHER_COMPANY'
}

export const locationLinkOptions: RadioGroupOption<LocationLink>[] = [
  {
    label: 'My company',
    value: LocationLink.MY_COMPANY
  },
  {
    label: 'A different company',
    value: LocationLink.OTHER_COMPANY
  }
];
