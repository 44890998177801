import { isEmpty, isNil, isNumber, isString, omitBy } from 'lodash';

export const omitEmptyValues = <T extends {}>(filters: T) => {
  return omitBy<T>(filters, isEmptyValue);
};

const isEmptyValue = (value: unknown): boolean => {
  if (Array.isArray(value)) {
    return isEmpty(value);
  }

  if (typeof value === 'object') {
    const clearedValues = value ? omitEmptyValues(value) : {};

    return isEmpty(clearedValues);
  }

  if (isNumber(value)) {
    return isNil(value);
  }

  if (isString(value)) {
    return isEmpty(value);
  }

  return isNil(value);
};

export const countFilters = <T extends {}>(filters: T): number => {
  return Object.values(filters).reduce((previous: number, current) => {
    if (!current || isEmptyValue(current)) {
      return previous;
    }

    return previous + 1;
  }, 0) as number;
};
