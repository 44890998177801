import type { RateCard } from '@zen/RateCards/LegacyRateCards/types';
import { formatIssuedBy, formatModeOfTransport } from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';
import { formatDate } from '@zen/utils/dateTime';

import { rateCardColumnHeaders } from '../utils';

export const enhanceRowsWithOverviewData = (rows: string[][], rateCard: RateCard): string[][] => {
  const overview: Record<string, string> = getFormattedOverviewData(rateCard);

  return rows.map((row: string[]) => {
    return rateCardColumnHeaders.map((header: string, index: number) => overview[header as keyof typeof overview] || row[index]);
  });
};

const getFormattedOverviewData = (rateCard: RateCard): Record<string, string> => {
  const { startDate, endDate, modeOfTransport, cargoType, issuedAt, issuedBy } = rateCard;

  return {
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
    issuedAt: formatDate(issuedAt),
    cargoType,
    issuedBy: formatIssuedBy(issuedBy),
    modeOfTransport: formatModeOfTransport(modeOfTransport)
  };
};
