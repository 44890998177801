import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FC, memo, ReactElement } from 'react';

import { SplashScreen } from '@zen/DesignSystem';

import useAuth from '../hooks/useAuth';
import useApiAuthoriser from './useApiAuthoriser';

interface GuardProps {
  element: ReactElement;
}

// Authentication (with Auth0) routeguard
const RouteAuthenticator: FC<GuardProps> = memo(({ element }) => {
  const { isAuthenticated } = useAuth();
  const { hasApiAccessToken } = useApiAuthoriser();
  const { pathname, search } = window.location;

  // we have the ID token but not the access token
  // purpose of ID vs Access token https://auth0.com/blog/id-token-access-token-what-is-the-difference/
  if (!hasApiAccessToken && isAuthenticated) {
    return <SplashScreen />;
  }

  // we have both tokens
  if (isAuthenticated) {
    return element;
  }

  const Component = withAuthenticationRequired(() => element, {
    onRedirecting: () => <SplashScreen />,
    returnTo: `${pathname}${search}`
  });

  // Not authenticated, redirect to universal login page
  return <Component />;
});

export default RouteAuthenticator;
