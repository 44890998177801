import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RemoveJourneyScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RemoveJourneyScheduleInput;
}>;

export type RemoveJourneyScheduleMutationResult = { __typename?: 'Mutation' } & {
  removeJourneySchedule: { __typename?: 'RemoveJourneySchedulePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const RemoveJourneyScheduleDocument = /* #__PURE__ */ gql`
  mutation removeJourneySchedule($input: RemoveJourneyScheduleInput!) {
    removeJourneySchedule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useRemoveJourneyScheduleMutation__
 *
 * To run a mutation, you first call `useRemoveJourneyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveJourneyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeJourneyScheduleMutation, { data, loading, error }] = useRemoveJourneyScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveJourneyScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveJourneyScheduleMutationResult, RemoveJourneyScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RemoveJourneyScheduleMutationResult, RemoveJourneyScheduleMutationVariables>(
    RemoveJourneyScheduleDocument,
    options
  );
}
export type RemoveJourneyScheduleMutationHookResult = ReturnType<typeof useRemoveJourneyScheduleMutation>;
