import type { FC, ReactNode } from 'react';

import ActivityFeedItemIcon from '@zen/ActivityFeed/components/ActivityFeedItemIcon';
import type { Activity, BookingRequestType } from '@zen/ActivityFeed/types';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';

import ActivityFeedItemDetails from '../../components/ActivityFeedItemDetails';
import ActivityFeedItemWrapper from '../../components/ActivityFeedItemWrapper';

export interface Props {
  activityMetaData: Activity;
  bookingRequest: BookingRequestType;
}

const RejectedBookingRequestItem: FC<Props> = ({ activityMetaData, bookingRequest }) => {
  const { createdAt, itemId, legacyUser } = activityMetaData;

  const subtitle: ReactNode = bookingRequest?.rejectedReason && (
    <p>
      <span className="font-bold">Reason for rejection: </span>
      {removeSpecialCharactersAndUpperFirst(bookingRequest?.rejectedReason)}
    </p>
  );

  return (
    <ActivityFeedItemWrapper>
      <div className="flex items-center justify-between">
        <ActivityFeedItemDetails
          additionalText={bookingRequest?.rejectedAdditionalInfo}
          createdAt={createdAt}
          icon={<ActivityFeedItemIcon color="text-red-dark" iconName="zicon-cross-oval" />}
          subtitle={subtitle}
          title="Booking request rejected"
          user={legacyUser}
        />
        <p className="font-bold">{itemId}</p>
      </div>
    </ActivityFeedItemWrapper>
  );
};

export default RejectedBookingRequestItem;
