import { isNil } from 'lodash';

import type { DeepNonNullable } from '@zen/utils/typescript';

import { isParseableAsTime } from '../jodaConnector';
import type { Time } from '../types';

export const isValidTime = (time: Time | string): boolean => {
  return isParseableAsTime(time);
};

export const hasTime = (time: Time): time is DeepNonNullable<Time, 'minute hour'> => !isNil(time.hour) && !isNil(time.minute);
