import { BrowserJsPlumbInstance, newInstance } from '@jsplumb/browser-ui';
import { FC, ReactNode, Ref, useEffect, useRef } from 'react';
import { useMeasure } from 'react-use';

import { Color } from '@zen/Styleguide';

interface Props {
  children: ReactNode;
  dependencies?: unknown[];
}

const VerticalPathProvider: FC<Props> = ({ children, dependencies = [] }) => {
  const plumbInstance = useRef<BrowserJsPlumbInstance>();
  const ref: Ref<HTMLDivElement> = useRef(null);
  const [wrapperRef, { height }] = useMeasure<HTMLDivElement>();

  const drawLines = (): void => {
    if (ref.current) {
      const dataPointElements = ref.current.querySelectorAll('[data-routedatapoint="true"]');

      dataPointElements.forEach((el, index, arr) => {
        if (index === 0 || !plumbInstance.current) return;

        const dataPointCompleted: boolean = el.getAttribute('data-routedatapointcompleted') === 'true';

        plumbInstance.current.connect({
          source: arr[index - 1],
          target: el,
          paintStyle: dataPointCompleted
            ? { stroke: Color.AZURE_BASE, strokeWidth: 1 }
            : { stroke: Color.GREY_LIGHT, strokeWidth: 1, dashstyle: '3 3' }
        });
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      plumbInstance.current = newInstance({
        anchor: 'Center',
        container: ref.current,
        endpointStyle: {
          stroke: 'none',
          fill: 'none',
          outlineWidth: 0
        },
        reattachConnections: true
      });

      drawLines();
    }
  }, []);

  useEffect(() => {
    if (plumbInstance.current && ref.current) {
      setTimeout(() => {
        plumbInstance?.current?.deleteEveryConnection();
        drawLines();
      }, 50);
    }
  }, dependencies);

  useEffect(() => {
    if (plumbInstance.current && ref.current) {
      plumbInstance.current.revalidate(ref.current);
    }
  }, [height]);

  return (
    <div ref={wrapperRef}>
      <div ref={ref} className="relative z-0">
        {children}
      </div>
    </div>
  );
};

export default VerticalPathProvider;
