import { ContextMenu, TableColumn } from '@zen/DesignSystem';
import {
  formatChargeBasis,
  formatChargeType,
  formatCost,
  formatIncoterms,
  formatLocation,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';

import type { HaulageChargePayload } from '../../RateCardForm/reducer/types';
import type { HaulageTableColumnTitleConfig } from './types';

type OriginChargeTableActions = {
  onDelete?: (index: number) => void;
  onUpdate?: (index: number, payload: HaulageChargePayload) => void;
};

export const getHaulageChargeTableColumns = (
  columnTitleConfig: HaulageTableColumnTitleConfig,
  { onDelete, onUpdate }: OriginChargeTableActions = {},
  isEditable: boolean = false
): TableColumn<HaulageChargePayload>[] => [
  {
    key: 'location',
    sortable: false,
    title: columnTitleConfig.location,
    render: (_, { location }: HaulageChargePayload) => {
      return <span className="pl-4">{formatLocation(location)}</span>;
    }
  },
  {
    key: 'port',
    title: columnTitleConfig.port,
    sortable: false,
    render: (_, { port }: HaulageChargePayload) => {
      return <span>{formatPort(port)}</span>;
    }
  },
  {
    key: 'chargeType',
    title: columnTitleConfig.chargeType,
    sortable: false,
    render: (_, { chargeType }: HaulageChargePayload) => {
      return formatChargeType(chargeType);
    }
  },
  {
    key: 'chargeBasis',
    title: columnTitleConfig.chargeBasis,
    sortable: false,
    render: (_, { chargeType }: HaulageChargePayload) => {
      return formatChargeBasis(chargeType);
    }
  },

  {
    key: 'unit',
    title: columnTitleConfig.unit,
    sortable: false,
    render: (_, { chargeType }: HaulageChargePayload) => {
      return formatUnit(chargeType);
    }
  },
  {
    key: 'incoterms',
    title: columnTitleConfig.incoterms,
    sortable: false,
    render: (_, { incoterms }: HaulageChargePayload) => {
      return formatIncoterms(incoterms);
    }
  },
  {
    key: 'cost',
    alignment: 'right',
    title: columnTitleConfig.cost,
    sortable: false,
    render: (_, { chargeValue, currency }: HaulageChargePayload) => {
      return formatCost(chargeValue, currency);
    }
  },
  ...(isEditable
    ? [
        {
          title: '',
          key: 'actions',
          render: (_: unknown, item: HaulageChargePayload, index: number) => (
            <ContextMenu
              inline={false}
              items={[
                { label: 'Update', icon: 'zicon-edit', onClick: () => onUpdate?.(index, item) },
                { label: 'Delete', icon: 'zicon-trash', onClick: () => onDelete?.(index) }
              ]}
            />
          ),
          sortable: false
        }
      ]
    : [])
];
