import { type FC, useState } from 'react';

import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import type { SelectOption } from '@zen/DesignSystem';
import { JourneyTrackingAction, JourneyTrackingCategory } from '@zen/Journey/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import MilestoneDatesHistory from '../MilestoneDatesHistory';

interface Props {
  defaultSelectedMilestone?: Optional<string>;
  milestoneOptions: SelectOption<string>[];
}

const MilestoneDatesHistoryButton: FC<Props> = ({ defaultSelectedMilestone = null, milestoneOptions }) => {
  const { trackEvent } = useTracking();
  const { milestones, modeOfTransport, zencargoReference } = useJourneyContext();
  const [isSlideoutVisible, setIsSlideoutVisible] = useState<boolean>(false);

  const handleClick = (): void => {
    setIsSlideoutVisible(true);

    trackEvent({
      category: JourneyTrackingCategory,
      action: JourneyTrackingAction.MILESTONE_HISTORY_OPENED,
      label: zencargoReference,
      properties: {
        modeOfTransport,
        zencargoReference
      }
    });
  };

  const handleMilestoneSelect = (milestoneId: Nullable<string>): void => {
    const milestoneName: string = milestones.find((milestone) => milestone.id)?.name || '';

    trackEvent({
      category: JourneyTrackingCategory,
      action: JourneyTrackingAction.MILESTONE_HISTORY_MILESTONE_SELECTED,
      label: zencargoReference,
      properties: {
        milestoneId,
        milestoneName,
        modeOfTransport,
        zencargoReference
      }
    });
  };

  const handleClose = (): void => setIsSlideoutVisible(false);

  return (
    <div data-testid="milestone-dates-history-button">
      <span className="text-sm underline cursor-pointer hover:no-underline text-grey-dark" onClick={handleClick}>
        See milestone log
      </span>
      <Slideout className="w-[40rem]" onOutsideClick={handleClose} overlay={true} show={isSlideoutVisible} unmountOnClose={true}>
        <SlideoutHeader onClose={handleClose} title="Milestone log" />
        <MilestoneDatesHistory
          defaultSelectedMilestone={defaultSelectedMilestone}
          milestoneOptions={milestoneOptions}
          onMilestoneSelect={handleMilestoneSelect}
        />
      </Slideout>
    </div>
  );
};

export default MilestoneDatesHistoryButton;
