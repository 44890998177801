import type { FormNetworkContactPerson } from '../ContactForm/types';
import { ContactStatus, NetworkContact } from '../types';

export const getEmptyNetworkContact = (): NetworkContact => ({
  bankAccountDetails: '',
  bipCode: '',
  carrier: false,
  contactPeople: [],
  customer: false,
  eoriNumber: '',
  haulier: false,
  id: '',
  name: '',
  scacCode: '',
  status: ContactStatus.ACTIVE,
  supplier: false,
  taxNumber: '',
  warehouse: false
});

export const getEmptyNetworkPersonContact = (): FormNetworkContactPerson => ({
  email: '',
  firstName: '',
  lastName: '',
  mainContact: false,
  phoneNumber: '',
  skypeUserName: ''
});
