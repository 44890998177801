import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargeTypesQueryVariables = GraphQLTypes.Exact<{
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetChargeTypesQueryResult = { __typename?: 'Query' } & {
  chargeTypes: { __typename?: 'ChargeTypeConnection' } & Pick<GraphQLTypes.ChargeTypeConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'ChargeType' } & Pick<
              GraphQLTypes.ChargeType,
              | 'id'
              | 'name'
              | 'chargeType'
              | 'modeOfTransport'
              | 'chargeBasis'
              | 'vatApplicable'
              | 'twentyFtDv'
              | 'twentyFtHc'
              | 'fortyFtDv'
              | 'fortyFtHc'
              | 'fortyFiveFtHc'
              | 'hazardous'
              | 'reefer'
              | 'overweight'
            >
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetChargeTypesDocument = /* #__PURE__ */ gql`
  query getChargeTypes(
    $paginated: Boolean
    $after: String
    $before: String
    $first: Int
    $last: Int
    $searchQuery: String
    $order: SortInput
  ) {
    chargeTypes(
      paginated: $paginated
      after: $after
      before: $before
      first: $first
      last: $last
      textContains: $searchQuery
      order: $order
    ) {
      nodes {
        id
        name
        chargeType
        modeOfTransport
        chargeBasis
        vatApplicable
        twentyFtDv
        twentyFtHc
        fortyFtDv
        fortyFtHc
        fortyFiveFtHc
        hazardous
        reefer
        overweight
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetChargeTypesQuery__
 *
 * To run a query within a React component, call `useGetChargeTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeTypesQuery({
 *   variables: {
 *      paginated: // value for 'paginated'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetChargeTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargeTypesQueryResult, GetChargeTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargeTypesQueryResult, GetChargeTypesQueryVariables>(GetChargeTypesDocument, options);
}
export function useGetChargeTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargeTypesQueryResult, GetChargeTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargeTypesQueryResult, GetChargeTypesQueryVariables>(GetChargeTypesDocument, options);
}
export type GetChargeTypesQueryHookResult = ReturnType<typeof useGetChargeTypesQuery>;
export type GetChargeTypesLazyQueryHookResult = ReturnType<typeof useGetChargeTypesLazyQuery>;
