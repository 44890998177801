import type { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const QuoteOptionPanel: FC<Props> = ({ children, className = '' }) => {
  const classNames: string = `p-6 border border-solid rounded border-grey-light ${className}`;

  return <div className={classNames}>{children}</div>;
};

export type { Props as QuoteOptionPanelProps };

export default QuoteOptionPanel;
