import type { FC, ReactElement, ReactNode } from 'react';

import { Card, Tooltip } from '@zen/DesignSystem';
import PriorityPill from '@zen/Tasks/components/PriorityPill';
import type { TaskPriority } from '@zen/Tasks/types';
import type { Optional } from '@zen/utils/typescript';

import TaskDateDisplay from '../TaskDateDisplay';

interface Props {
  actions?: ReactNode;
  assignee: Optional<string>;
  completedDate: Optional<string>;
  description: string;
  dueDate: Optional<string>;
  onClick?: () => void;
  priority: TaskPriority;
}

const MiniTaskListCard: FC<Props> = (props) => {
  const { dueDate, description, assignee, priority, actions, onClick, completedDate } = props;
  const isCompleted: boolean = !!completedDate;

  const dateWithIcon: ReactElement = <TaskDateDisplay completedDate={completedDate} dueDate={dueDate} priority={priority} />;

  const renderPillWithActions = (): ReactNode => {
    if (isCompleted) return null;

    return (
      <div className="flex flex-between justify-between">
        <PriorityPill priority={priority} />
        {actions}
      </div>
    );
  };

  return (
    <Card leftIcon={dateWithIcon} onClick={onClick} variant="compactView">
      <div className="py-4 px-2">
        <div className="text-grey-base text-xs">{assignee}</div>
        <Tooltip delayShow={1000} placement="top" tooltipContent={description}>
          <div className="font-bold truncate mb-2">{description}</div>
        </Tooltip>
        {renderPillWithActions()}
      </div>
    </Card>
  );
};

export default MiniTaskListCard;

export type { Props as MiniTaskListCardProps };
