import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductDetailsQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type ProductDetailsQueryResult = { __typename?: 'Query' } & {
  products: { __typename?: 'ProductConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Product' } & Pick<
            GraphQLTypes.Product,
            | 'id'
            | 'accountUuid'
            | 'name'
            | 'categoryName'
            | 'archived'
            | 'skuCode'
            | 'hsCode'
            | 'htsCode'
            | 'unitsPerMasterCarton'
          > & {
              packageInfo?: GraphQLTypes.Maybe<
                { __typename?: 'PackageInfo' } & Pick<GraphQLTypes.PackageInfo, 'type'> & {
                    depth?: GraphQLTypes.Maybe<
                      { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                    >;
                    width?: GraphQLTypes.Maybe<
                      { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                    >;
                    length?: GraphQLTypes.Maybe<
                      { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
                    >;
                  }
              >;
              costPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
              sellPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
              properties: Array<{ __typename?: 'ProductProperty' } & Pick<GraphQLTypes.ProductProperty, 'name' | 'value'>>;
              weight?: GraphQLTypes.Maybe<
                { __typename?: 'MetricValueWithSI' } & Pick<GraphQLTypes.MetricValueWithSi, 'unit' | 'value'>
              >;
              perUnitCbm?: GraphQLTypes.Maybe<{ __typename?: 'PerUnitCbm' } & Pick<GraphQLTypes.PerUnitCbm, 'source' | 'value'>>;
            }
        >
      >
    >;
  };
};

export const ProductDetailsDocument = /* #__PURE__ */ gql`
  query productDetails($id: String!) {
    products(ids: [$id]) {
      nodes {
        id
        accountUuid
        name
        categoryName
        archived
        skuCode
        hsCode
        htsCode
        packageInfo {
          depth {
            unit
            value
          }
          width {
            unit
            value
          }
          length {
            unit
            value
          }
          type
        }
        costPrice {
          currency
          value
        }
        sellPrice {
          currency
          value
        }
        properties {
          name
          value
        }
        costPrice {
          currency
          value
        }
        weight {
          unit
          value
        }
        unitsPerMasterCarton
        perUnitCbm {
          source
          value
        }
      }
    }
  }
`;

/**
 * __useProductDetailsQuery__
 *
 * To run a query within a React component, call `useProductDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<ProductDetailsQueryResult, ProductDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductDetailsQueryResult, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
}
export function useProductDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductDetailsQueryResult, ProductDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductDetailsQueryResult, ProductDetailsQueryVariables>(ProductDetailsDocument, options);
}
export type ProductDetailsQueryHookResult = ReturnType<typeof useProductDetailsQuery>;
export type ProductDetailsLazyQueryHookResult = ReturnType<typeof useProductDetailsLazyQuery>;
