import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetLegacyAccountDetailsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetLegacyAccountDetailsQueryResult = { __typename?: 'Query' } & {
  legacyAccount?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyAccount' } & Pick<
      GraphQLTypes.LegacyAccount,
      | 'uuid'
      | 'name'
      | 'referencePrefix'
      | 'companyEmailAddress'
      | 'companyPhoneNumber'
      | 'eoriNumber'
      | 'vat'
      | 'isActive'
      | 'isDemo'
      | 'isDivision'
    > & {
        ssoDetails?: GraphQLTypes.Maybe<
          { __typename?: 'SsoDetails' } & Pick<
            GraphQLTypes.SsoDetails,
            'connectionName' | 'domains' | 'enabled' | 'provisionMethod' | 'uuid'
          > & { defaultLocation: GraphQLTypes.SsoDetails['networkLocationId'] } & {
              apiKeys: Array<{ __typename?: 'ScimApiKey' } & Pick<GraphQLTypes.ScimApiKey, 'expiryDate' | 'id' | 'status'>>;
            }
        >;
      }
  >;
};

export const GetLegacyAccountDetailsDocument = /* #__PURE__ */ gql`
  query getLegacyAccountDetails($accountId: String!) {
    legacyAccount(uuid: $accountId) {
      uuid
      name
      referencePrefix
      companyEmailAddress
      companyPhoneNumber
      eoriNumber
      vat
      isActive
      isDemo
      isDivision
      ssoDetails {
        apiKeys {
          expiryDate
          id
          status
        }
        connectionName
        defaultLocation: networkLocationId
        domains
        enabled
        provisionMethod
        uuid
      }
    }
  }
`;

/**
 * __useGetLegacyAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetLegacyAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLegacyAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLegacyAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetLegacyAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetLegacyAccountDetailsQueryResult, GetLegacyAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetLegacyAccountDetailsQueryResult, GetLegacyAccountDetailsQueryVariables>(
    GetLegacyAccountDetailsDocument,
    options
  );
}
export function useGetLegacyAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLegacyAccountDetailsQueryResult, GetLegacyAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetLegacyAccountDetailsQueryResult, GetLegacyAccountDetailsQueryVariables>(
    GetLegacyAccountDetailsDocument,
    options
  );
}
export type GetLegacyAccountDetailsQueryHookResult = ReturnType<typeof useGetLegacyAccountDetailsQuery>;
export type GetLegacyAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetLegacyAccountDetailsLazyQuery>;
