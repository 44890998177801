import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardValidityDatesQueryVariables = GraphQLTypes.Exact<{
  customerId: GraphQLTypes.Scalars['String'];
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  cargoType: GraphQLTypes.CargoModeEnum;
}>;

export type RateCardValidityDatesQueryResult = { __typename?: 'Query' } & {
  getRateCardValidityDates?: GraphQLTypes.Maybe<
    { __typename?: 'RateCardValidityDates' } & {
      validityDates: Array<{ __typename?: 'DateRange' } & Pick<GraphQLTypes.DateRange, 'startDate' | 'endDate'>>;
    }
  >;
};

export const RateCardValidityDatesDocument = /* #__PURE__ */ gql`
  query rateCardValidityDates($customerId: String!, $modeOfTransport: ModeOfTransport!, $cargoType: CargoModeEnum!) {
    getRateCardValidityDates(customerId: $customerId, modeOfTransport: $modeOfTransport, cargoType: $cargoType) {
      validityDates {
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useRateCardValidityDatesQuery__
 *
 * To run a query within a React component, call `useRateCardValidityDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardValidityDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardValidityDatesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *   },
 * });
 */
export function useRateCardValidityDatesQuery(
  baseOptions: Apollo.QueryHookOptions<RateCardValidityDatesQueryResult, RateCardValidityDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardValidityDatesQueryResult, RateCardValidityDatesQueryVariables>(
    RateCardValidityDatesDocument,
    options
  );
}
export function useRateCardValidityDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RateCardValidityDatesQueryResult, RateCardValidityDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardValidityDatesQueryResult, RateCardValidityDatesQueryVariables>(
    RateCardValidityDatesDocument,
    options
  );
}
export type RateCardValidityDatesQueryHookResult = ReturnType<typeof useRateCardValidityDatesQuery>;
export type RateCardValidityDatesLazyQueryHookResult = ReturnType<typeof useRateCardValidityDatesLazyQuery>;
