import cx from 'classnames';
import type { FC, ReactElement } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { Button, Tooltip } from '@zen/DesignSystem';

import { isSubscriberType } from '../subscriptions.helpers';
import type { SubscriptionStakeholder } from '../types';

interface Props {
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  onUnsubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholder: SubscriptionStakeholder;
  subscribed: boolean;
}

const SubscriptionButton: FC<Props> = ({ onSubscribe, onUnsubscribe, stakeholder, subscribed }) => {
  const label = subscribed ? 'Unsubscribe' : 'Subscribe';
  const className = cx({ 'fs-unsubscribe-self': subscribed, 'fs-subscribe-self': !subscribed });
  const variant = subscribed ? 'secondary' : 'primary';
  const isDisabled: boolean = isSubscriberType(stakeholder) ? !checkPermission(stakeholder, 'canUnsubscribe') : false;

  const handleClick = (): void => {
    if (subscribed) {
      onUnsubscribe(stakeholder);

      return;
    }

    onSubscribe(stakeholder);
  };

  const renderButton = (): ReactElement => {
    return (
      <Button className={className} disabled={isDisabled} onClick={handleClick} variant={variant}>
        {label}
      </Button>
    );
  };

  const renderButtonWithTooltip = (): ReactElement => {
    return (
      <Tooltip placement="top-end" tooltipContent="You can not unsubscribe because you are a key contact.">
        {renderButton()}
      </Tooltip>
    );
  };

  return <div data-testid="subscription-button">{isDisabled ? renderButtonWithTooltip() : renderButton()}</div>;
};

export default SubscriptionButton;
