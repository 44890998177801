import type { Option } from '@zen/DesignSystem';

import { Currency } from '../../types';
import type { OceanChargeItemInitialValues } from '../OceanChargeForm/types';
import { ChargeBasisGroup } from './types';

export const chargeBasisGroupMapping: Record<ChargeBasisGroup, string> = {
  [ChargeBasisGroup.BILL]: 'Bill',
  [ChargeBasisGroup.BOOKING]: 'Booking',
  [ChargeBasisGroup.CARGO]: 'Cargo'
};

export const basisGroupOptions: Option<ChargeBasisGroup>[] = [
  {
    label: chargeBasisGroupMapping[ChargeBasisGroup.BILL],
    value: ChargeBasisGroup.BILL
  },
  {
    label: chargeBasisGroupMapping[ChargeBasisGroup.BOOKING],
    value: ChargeBasisGroup.BOOKING
  },
  {
    label: chargeBasisGroupMapping[ChargeBasisGroup.CARGO],
    value: ChargeBasisGroup.CARGO
  }
];

export const emptyOceanChargeItem: OceanChargeItemInitialValues = {
  basisGroup: null,
  chargeType: null,
  itemName: '',
  unitPrice: {
    currency: Currency.GBP,
    value: null
  }
};
