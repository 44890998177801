import type { Option } from '@zen/DesignSystem';

import { StopType, TrackedShipmentFilters, TrackedShipmentTimeframe } from '../types';

export const initialValues: TrackedShipmentFilters = {
  event: null,
  timeframe: null,
  originCountryCode: null,
  destinationCountryCode: null
};

export const timeframeOptions: Option<TrackedShipmentTimeframe>[] = [
  { value: TrackedShipmentTimeframe.NEXT_1_DAY, label: 'Next 1 day' },
  { value: TrackedShipmentTimeframe.NEXT_3_DAYS, label: 'Next 3 days' },
  { value: TrackedShipmentTimeframe.NEXT_7_DAYS, label: 'Next 7 days' },
  { value: TrackedShipmentTimeframe.NEXT_14_DAYS, label: 'Next 15 days' }
];

export const eventOptions: Option<StopType>[] = [
  { value: StopType.COLLECTION, label: 'Collecting' },
  { value: StopType.DELIVERY, label: 'Delivering' }
];
