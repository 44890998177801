import type { Nullable, Optional } from '@zen/utils/typescript';
import { trimObjectValues } from '@zen/utils/utils';

import { getDimensions, getWeight } from '../../helpers';
import {
  CargoDimensionsUnitEnum,
  cargoValues,
  CargoWeightUnitEnum,
  CoreCargo,
  CoreCargoType,
  Dimensions,
  LooseCargoTypeEnum,
  PalletTypeEnum
} from '../../types';
import { getPalletDimensions } from './LooseFormFields/helpers';
import type { LooseFormInitialValues, LooseFormValues } from './LooseFormFields/types';

export const prepareLooseCargoInput = (values: LooseFormValues) => {
  const {
    type,
    volume,
    dimensions,
    stackable,
    refrigerated,
    quantity,
    grossWeight,
    hazardous,
    value,
    containerNumber,
    chargeableWeight
  } = values.loose;

  return trimObjectValues({
    looseCargoType: type,
    actualCbm: volume,
    dimensions: getDimensions(dimensions),
    quantity,
    stackable,
    palletType: value,
    reefer: refrigerated,
    hazardous,
    containerNumber,
    grossWeight: getWeight(grossWeight),
    chargeableWeight: getWeight(chargeableWeight)
  });
};

export const initialWeightValues = {
  metric: CargoWeightUnitEnum.KG,
  value: null
};

export const initialDimensions = {
  height: null,
  width: null,
  length: null,
  metric: CargoDimensionsUnitEnum.CM
};

export const mapLooseCargoType = (
  value: Optional<CoreCargoType>
): { type: Nullable<LooseCargoTypeEnum>; value: Nullable<PalletTypeEnum> } => {
  if (!value) {
    return {
      type: null,
      value: null
    };
  }

  return looseCargoTypeMapping[value as keyof typeof looseCargoTypeMapping];
};

const looseCargoTypeMapping = {
  [cargoValues.loosePalletEuro]: {
    type: LooseCargoTypeEnum.PALLETS,
    value: PalletTypeEnum.EURO
  },
  [cargoValues.loosePalletUk]: {
    type: LooseCargoTypeEnum.PALLETS,
    value: PalletTypeEnum.UK
  },
  [cargoValues.loosePalletUs]: {
    type: LooseCargoTypeEnum.PALLETS,
    value: PalletTypeEnum.US
  },
  [cargoValues.loosePalletOther]: {
    type: LooseCargoTypeEnum.PALLETS,
    value: PalletTypeEnum.OTHER
  },
  [cargoValues.loosePalletLegacy]: {
    type: LooseCargoTypeEnum.PALLETS,
    value: null
  },
  [cargoValues.looseBoxesOrCrates]: {
    type: LooseCargoTypeEnum.BOXES_OR_CRATES,
    value: null
  },
  [cargoValues.looseAssortedCargo]: {
    type: LooseCargoTypeEnum.ASSORTED_CARGO,
    value: null
  }
};

export const prepareLooseCargoInitialValues = (values: CoreCargo): LooseFormInitialValues => {
  const {
    dimensions,
    grossWeight,
    chargeableWeight,
    containerNumber,
    hazardous,
    stackable,
    refrigerated,
    cargoType,
    quantity,
    volume
  } = values;

  const looseCargoType = mapLooseCargoType(cargoType);

  return {
    loose: {
      dimensions: getLooseCargoDimensions(dimensions, looseCargoType.value),
      containerNumber,
      grossWeight: getWeight(grossWeight) || initialWeightValues,
      chargeableWeight: getWeight(chargeableWeight) || initialWeightValues,
      hazardous: !!hazardous,
      quantity: quantity || 1,
      refrigerated: !!refrigerated,
      stackable: !!stackable,
      type: looseCargoType.type,
      value: looseCargoType.value,
      volume: volume?.value || null
    }
  };
};

export const getLooseCargoDimensions = (dimensions: Optional<Dimensions>, type: Nullable<PalletTypeEnum>) => {
  const cargoDimensions = getDimensions(dimensions);

  if (type) {
    return getPalletDimensions(type, cargoDimensions);
  }

  return cargoDimensions;
};

export const getDefaultDimensionsCoreCargo = (cargoType: Optional<CoreCargoType>): Dimensions => {
  const type = mapLooseCargoType(cargoType);

  if (!type?.value) {
    return {
      length: null,
      width: null,
      height: null,
      unit: CargoDimensionsUnitEnum.CM
    };
  }

  const dimensions = getPalletDimensions(type.value);

  return {
    unit: dimensions.metric,
    height: dimensions.height,
    width: dimensions.width,
    length: dimensions.length
  } as Dimensions;
};
