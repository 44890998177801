import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetChargeGroupsQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetChargeGroupsQueryResult = { __typename?: 'Query' } & {
  getChargeGroups?: GraphQLTypes.Maybe<Array<{ __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'>>>;
};

export const GetChargeGroupsDocument = /* #__PURE__ */ gql`
  query getChargeGroups {
    getChargeGroups {
      id
      name
    }
  }
`;

/**
 * __useGetChargeGroupsQuery__
 *
 * To run a query within a React component, call `useGetChargeGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChargeGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChargeGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChargeGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetChargeGroupsQueryResult, GetChargeGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetChargeGroupsQueryResult, GetChargeGroupsQueryVariables>(GetChargeGroupsDocument, options);
}
export function useGetChargeGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChargeGroupsQueryResult, GetChargeGroupsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetChargeGroupsQueryResult, GetChargeGroupsQueryVariables>(GetChargeGroupsDocument, options);
}
export type GetChargeGroupsQueryHookResult = ReturnType<typeof useGetChargeGroupsQuery>;
export type GetChargeGroupsLazyQueryHookResult = ReturnType<typeof useGetChargeGroupsLazyQuery>;
