import type { FC } from 'react';

import type { LegacyAddress as AddressType } from '@zen/graphql/types.generated';
import { joinNotNull } from '@zen/utils/utils';

interface Props {
  address: AddressType;
  addressContainerClassName?: string;
  className?: string;
}

const Address: FC<Props> = ({ address, className, addressContainerClassName }) => {
  const { city, country, postalCodeOrZip, street, countyOrState } = address;
  const streetCity = joinNotNull([street, city], ', ');
  const stateZip = joinNotNull([countyOrState, postalCodeOrZip], ', ');

  return (
    <div className={addressContainerClassName} data-testid="address">
      {streetCity && <p className={className}>{streetCity}</p>}
      {stateZip && <p className={className}>{stateZip}</p>}
      {country?.name && <p className={className}>{country.name}</p>}
    </div>
  );
};

export type { Props as AddressProps };
export default Address;
