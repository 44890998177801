import { components } from 'react-select';

import Icon from '../../../Icon';

const ValueContainer: typeof components.ValueContainer = (props) => {
  return (
    <div className="flex flex-wrap items-center flex-1 overflow-hidden">
      <Icon className="ml-2 text-sm text-grey-base" icon="zicon-search" />
      <components.ValueContainer {...props}>{props.children}</components.ValueContainer>
    </div>
  );
};

export default ValueContainer;
