import type { FC } from 'react';

import { Option, Select } from '@zen/DesignSystem';
import { PageSize } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  onChange: (val: number) => void;
  pageSize: number;
}

export const PageSizeOptions: Record<PageSize, Option<number>> = {
  [PageSize.TEN]: { label: '10 per page', value: 10 },
  [PageSize.TWENTY]: { label: '20 per page', value: 20 },
  [PageSize.FIFTY]: { label: '50 per page', value: 50 }
};

const PageSizeSelector: FC<Props> = ({ onChange, pageSize }) => {
  const options: Option<number>[] = Object.values(PageSizeOptions);
  const { trackEvent } = useTracking();

  const handleChange = (value: Nullable<number>) => {
    if (value) {
      trackEvent({
        category: 'PageSizeSelector',
        action: 'PageSizeChanged',
        label: value.toString()
      });

      onChange(value);
    }
  };

  return (
    <Select
      className="pl-4 w-40"
      data-testId="pageSizeSelector"
      name="pageSize"
      onChange={handleChange}
      options={options}
      placeholder="Page size"
      value={pageSize}
    />
  );
};

export default PageSizeSelector;
