import type { FC } from 'react';

import RoleProvider from '@zen/Auth/RoleProvider';
import UserDataProvider from '@zen/Auth/UserDataProvider';

import Dashboard from './Dashboard';

const DashboardContainer: FC = () => {
  return (
    <UserDataProvider>
      <RoleProvider>
        <Dashboard />
      </RoleProvider>
    </UserDataProvider>
  );
};

export default DashboardContainer;
