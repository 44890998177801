import type { FC } from 'react';

import TruckSwapSelect from '@zen/Components/TruckSwapSelect';
import type { TruckSwapEnum } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateTruckSwapMutation } from './graphql';

interface Props {
  truckSwap: Optional<TruckSwapEnum>;
  zencargoReference: string;
}

const TruckSwapInlineField: FC<Props> = ({ truckSwap, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [updateTruckSwap, { loading }] = useUpdateTruckSwapMutation();

  const handleUpdateTruckSwap = async (value: Nullable<TruckSwapEnum>): Promise<void> => {
    if (value) {
      await performMutation({
        mutationFn: () =>
          updateTruckSwap({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary'],
            variables: {
              input: {
                zencargoReference,
                bookingInput: {
                  truckSwap: value
                }
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          addSuccess('Truck swap updated.');
        }
      });
    }
  };

  return (
    <div className="-ml-3">
      <TruckSwapSelect
        isDisabled={loading}
        isLoading={loading}
        name="truckSwap"
        onChange={handleUpdateTruckSwap}
        renderMenuInPortal={true}
        value={truckSwap}
        variant="inline"
      />
    </div>
  );
};

export default TruckSwapInlineField;
