import { gql } from '@apollo/client';

import type * as GraphQLTypes from '../types.generated';

export type BusinessHoursFragment = { __typename?: 'BusinessHours' } & {
  monday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  tuesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  wednesday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  thursday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  friday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  saturday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
  sunday?: GraphQLTypes.Maybe<{ __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>>;
};

export const BusinessHoursFragmentDoc = /* #__PURE__ */ gql`
  fragment BusinessHours on BusinessHours {
    monday {
      startTime
      endTime
    }
    tuesday {
      startTime
      endTime
    }
    wednesday {
      startTime
      endTime
    }
    thursday {
      startTime
      endTime
    }
    friday {
      startTime
      endTime
    }
    saturday {
      startTime
      endTime
    }
    sunday {
      startTime
      endTime
    }
  }
`;
