import useVisitedAccounts from '@zen/utils/hooks/useVisitedAccounts';

import useAuth from './useAuth';

const useSwitchAccount = () => {
  const { updateVisitedAccounts } = useVisitedAccounts();
  const { loginWithAccountId, clearSensitiveData } = useAuth();

  const switchToAccount = (accountId: string): void => {
    updateVisitedAccounts(accountId);
    loginWithAccountId(accountId);
    clearSensitiveData();
  };

  return { switchToAccount };
};

export default useSwitchAccount;
