import cx from 'classnames';
import { get } from 'lodash';
import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { getDateDifference } from '@zen/utils/date';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import MilestoneDelayReasonSelect from '../forms/MilestoneDelayReasonSelect';
import { useUpdateMilestoneDelayMutation } from './graphql';

interface Props {
  canManageDelays: boolean;
  className?: string;
  delayId: string;
  delayInDays: number;
  delayReasonId: Optional<string>;
  isDelayInDaysVisible?: boolean;
}

const MilestoneDelay: FC<Props> = (props) => {
  const { canManageDelays, className, delayId, delayInDays, delayReasonId, isDelayInDaysVisible = true } = props;
  const [updateDelay, { loading }] = useUpdateMilestoneDelayMutation();
  const { addError, addSuccess } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const classNames = cx('flex items-center p-1', className);
  const placeholder: string = canManageDelays ? 'Add reason...' : 'Exact reason pending';

  const handleChange = async (reasonId: Nullable<string>): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updateDelay({
          awaitRefetchQueries: true,
          refetchQueries: ['cargoOverview'],
          variables: {
            input: {
              delayId,
              delayReasonId: reasonId,
              zencargoReference
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was an error while updating the delay reason.'));
      },
      onSuccess: () => {
        addSuccess(`Delay reason ${reasonId ? 'updated' : 'removed'}.`);
      }
    });
  };

  return (
    <div className={classNames} data-testid="milestone-delay">
      {isDelayInDaysVisible && <div className="w-20 pl-3 text-xs text-red-dark">{getDateDifference(delayInDays)}</div>}
      <div className="flex-1">
        <MilestoneDelayReasonSelect
          isDisabled={!canManageDelays}
          isLoading={loading}
          onChange={handleChange}
          placeholder={placeholder}
          value={delayReasonId}
        />
      </div>
    </div>
  );
};

export default MilestoneDelay;
