import cx from 'classnames';
import moment, { Moment } from 'moment';
import pluralize from 'pluralize';
import type { ReactNode } from 'react';

import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';

const getSignPrefix = (number: number): string => {
  return number > 0 ? '+' : '';
};

export const getDaysDeltaLabel = (daysDelta: number): ReactNode => {
  if (daysDelta === 0) {
    return null;
  }

  const className: string = cx('font-bold', 'leading-normal', {
    'text-red-base': daysDelta && daysDelta > 0
  });
  const sign: string = getSignPrefix(daysDelta);
  const daysLabel: string = pluralize('day', daysDelta);

  return (
    <div className={className}>
      {sign}
      {daysDelta} {daysLabel}
    </div>
  );
};

export const getDaysDeltaForPill = (daysDifference: number): string => {
  if (daysDifference === 0) {
    return 'On time';
  }

  const daysLabel: string = pluralize('day', daysDifference);
  const timeLabel: string = daysDifference > 0 ? 'late' : 'early';

  return `${Math.abs(daysDifference)} ${daysLabel} ${timeLabel}`;
};

export const getDaysDelta = (dateStart: string, dateEnd: string): number => {
  if (!dateStart || !dateEnd) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    return null;
  }

  // dates are expected to be RFC2822 YYYY-MM-DD strings
  const momentStart: Moment = moment(dateStart, FORMAT_DATE_TRANSFERABLE);
  const momentEnd: Moment = moment(dateEnd, FORMAT_DATE_TRANSFERABLE);

  if (!momentStart.isValid() || !momentEnd.isValid()) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'number'.
    return null;
  }

  return momentEnd.diff(momentStart, 'days');
};
