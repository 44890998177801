import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteRailwayCompanyMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteRailwayCompanyInput;
}>;

export type DeleteRailwayCompanyMutationResult = { __typename?: 'Mutation' } & {
  deleteRailwayCompany: { __typename?: 'DeleteRailwayCompanyPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
  };
};

export const DeleteRailwayCompanyDocument = /* #__PURE__ */ gql`
  mutation deleteRailwayCompany($input: DeleteRailwayCompanyInput!) {
    deleteRailwayCompany(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useDeleteRailwayCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteRailwayCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRailwayCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRailwayCompanyMutation, { data, loading, error }] = useDeleteRailwayCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRailwayCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRailwayCompanyMutationResult, DeleteRailwayCompanyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteRailwayCompanyMutationResult, DeleteRailwayCompanyMutationVariables>(
    DeleteRailwayCompanyDocument,
    options
  );
}
export type DeleteRailwayCompanyMutationHookResult = ReturnType<typeof useDeleteRailwayCompanyMutation>;
