import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UserSettingQueryVariables = GraphQLTypes.Exact<{
  name: GraphQLTypes.Scalars['String'];
}>;

export type UserSettingQueryResult = { __typename?: 'Query' } & {
  getUserSettings?: GraphQLTypes.Maybe<{ __typename?: 'UserSettings' } & Pick<GraphQLTypes.UserSettings, 'name' | 'value'>>;
};

export const UserSettingDocument = /* #__PURE__ */ gql`
  query userSetting($name: String!) {
    getUserSettings(name: $name) {
      name
      value
    }
  }
`;

/**
 * __useUserSettingQuery__
 *
 * To run a query within a React component, call `useUserSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUserSettingQuery(baseOptions: Apollo.QueryHookOptions<UserSettingQueryResult, UserSettingQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<UserSettingQueryResult, UserSettingQueryVariables>(UserSettingDocument, options);
}
export function useUserSettingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserSettingQueryResult, UserSettingQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<UserSettingQueryResult, UserSettingQueryVariables>(UserSettingDocument, options);
}
export type UserSettingQueryHookResult = ReturnType<typeof useUserSettingQuery>;
export type UserSettingLazyQueryHookResult = ReturnType<typeof useUserSettingLazyQuery>;
