import { CargoDimensionsUnitEnum, CargoWeightUnitEnum } from '../../../types';
import type { LooseFormInitialValues } from '../LooseFormFields/types';

export const defaultLooseCargoFormInitialValues: LooseFormInitialValues = {
  loose: {
    dimensions: {
      height: null,
      width: null,
      length: null,
      metric: CargoDimensionsUnitEnum.CM
    },
    containerNumber: null,
    grossWeight: {
      metric: CargoWeightUnitEnum.KG,
      value: null
    },
    chargeableWeight: {
      metric: CargoWeightUnitEnum.KG,
      value: null
    },
    hazardous: false,
    quantity: 1,
    refrigerated: false,
    type: null,
    stackable: false,
    value: null,
    volume: null
  }
};
