import type { Optional } from '@zen/utils/typescript';

import { isParseableAsDateTime, isParseableAsTime, parseDateTime, parseTime } from '../jodaConnector';
import type { JodaDateTime, JodaTime } from '../jodaConnector/types';
import type { Time } from '../types';

export const getTimeFromString = (inputString: Optional<string>): Time => {
  if (!inputString || (!isParseableAsTime(inputString) && !isParseableAsDateTime(inputString))) {
    return {
      hour: null,
      minute: null
    };
  }

  const time: JodaTime | JodaDateTime = isParseableAsTime(inputString) ? parseTime(inputString) : parseDateTime(inputString);

  return {
    hour: time.hour(),
    minute: time.minute()
  };
};
