import type { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Counter } from '@zen/DesignSystem';
import { useRouter } from '@zen/utils/hooks/useRouter';

import type { SidebarCounters, SidebarLink } from '../../types';
import NavItem from '../NavItem';

interface Props {
  counters: SidebarCounters;
  item: SidebarLink;
}

const NavLink: FC<Props> = ({ counters, item }) => {
  const { icon, label, url, counter, isExact = false } = item;
  const { isMatchingPathname } = useRouter();

  const renderCounter = (): ReactNode => {
    const totalCount: number = (counter && counters[counter]) || 0;

    if (totalCount === 0) {
      return null;
    }

    return <Counter className="mr-2" size="small" type="active" value={totalCount} />;
  };

  return (
    <Link to={url}>
      <NavItem icon={icon} isActive={isMatchingPathname(url, isExact)} label={label}>
        {renderCounter()}
      </NavItem>
    </Link>
  );
};

export type { Props as NavLinkProps };

export default NavLink;
