import type { Suggestion } from 'use-places-autocomplete';

import type { Undefinable } from '@zen/utils/typescript';

import type { Option } from '../../DesignSystem';
import type { GeoAddressComponent, GeoLatAndLng, GeoSuggestAddress } from './types';

export const prepareAddressData = (addressComponents: GeoAddressComponent[], geoCoordinates: GeoLatAndLng): GeoSuggestAddress => {
  const findAddress = (key: string): string => {
    const addressComponent = addressComponents?.find((component: GeoAddressComponent) => component.types.includes(key));
    const label: Undefinable<string> = key === 'country' ? addressComponent?.short_name : addressComponent?.long_name;

    return label || '';
  };

  return {
    city: findAddress('postal_town') || findAddress('locality') || findAddress('sublocality_level_1'),
    countryCode: findAddress('country'),
    lat: geoCoordinates.lat,
    lng: geoCoordinates.lng,
    postalCode: findAddress('postal_code'),
    state: findAddress('administrative_area_level_1'),
    street: `${findAddress('route')} ${findAddress('street_number')}`
  };
};

export const prepareOptions = (suggestions: Suggestion[]): Option<string>[] => {
  if (!suggestions) {
    return [];
  }

  return suggestions.map(
    (suggestion: google.maps.places.AutocompletePrediction): Option<string> => ({
      value: suggestion.place_id,
      label: suggestion.description
    })
  );
};
