import { omitEmptyValues } from '@zen/utils/Filtering/helpers';

import type { AgentAssignmentRulesFiltersType } from '../../types';

export const emptyFilters: AgentAssignmentRulesFiltersType = {
  accountId: null,
  destination: null,
  transportModes: null,
  origin: null
};

export const removeEmptyFilters = (values: AgentAssignmentRulesFiltersType): Partial<AgentAssignmentRulesFiltersType> => {
  return omitEmptyValues(values);
};
