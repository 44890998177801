import type { DeepNonNullable } from '@zen/utils/typescript';

import type { BookingDetailsQueryResult } from './graphql';

export type { BookingStage } from '@zen/graphql/types.generated';
export {
  BookingStageEnum,
  CanAddCargoReasonEnum,
  ModeOfTransport,
  OceanTrackingStatusEnum,
  RoadTrackingStatus,
  Shipment
} from '@zen/graphql/types.generated';

export type BookingDetailsData = DeepNonNullable<BookingDetailsQueryResult, 'nodes'>['bookings']['nodes'];

export const RoadTrackingCategory = 'RoadTracking';

export enum RoadTrackingAction {
  TRACKING_LINK_CLICK = 'TrackingLinkClick'
}

export enum BookingDetailsPageSection {
  ACTIVITY_FEED = 'activity-feed',
  ACTIVITY_FEED_EXPANDED = 'activity-feed-expanded',
  CARGO = 'cargo',
  DOCUMENTS = 'documents',
  ISSUES = 'issues',
  NOTES = 'notes',
  ORDERS = 'orders',
  QUOTE_OPTIONS = 'quote-options',
  TRADE_PARTIES = 'trade-parties'
}

export enum TrackingStatus {
  NOT_TRACKED = 'NOT_TRACKED',
  TRACKED = 'TRACKED',
  TRACKING_DISABLED = 'TRACKING_DISABLED'
}

export interface TrackingDetails {
  status: TrackingStatus;
}
