import { get, isNil, omitBy } from 'lodash';

import { incotermOptions } from '@zen/Booking/helpers';
import type { FilterItemType } from '@zen/Components/Filters/types';
import type { Option } from '@zen/DesignSystem';
import type { NetworksAssignableInterface } from '@zen/Networks';
import { Customer, OrderFilters, PurchaseOrderBookingStateEnum } from '@zen/Orders';
import type { OrderListQueryVariables } from '@zen/Orders/graphql';
import type { IssuesFilterInput } from '@zen/Shipments/types';
import { getDateRange, getYesterday } from '@zen/utils/dateTime';
import { ALL_DAYS_IN_THE_PAST } from '@zen/utils/Filtering';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { FiltersAccountType, OrderFilterValues, Terminal } from './types';
import { PurchaseOrderStatusEnum } from './types';

export const mapIssues = (title: Nullable<string>): Option<string> => ({
  label: title || 'User created',
  // BE sends null as a value we convert that to empty string here and back to null when submitting
  value: title || ''
});

export const initialFilters: OrderFilters = {
  status: PurchaseOrderStatusEnum.OPEN
};

export const buildFilters = (options: OrderFilterValues, shouldRenderCustomerFilter: boolean): FilterItemType<OrderFilters>[] => {
  const { accounts, customers, issueTitles, manufacturers, polTerminals, podTerminals } = options;

  const accountsFilterOption: FilterItemType<OrderFilters> = {
    key: 'accountIds',
    title: 'Account',
    props: {
      options: accounts.map((account: FiltersAccountType) => ({
        value: account.id || '',
        label: account.tradingName || ''
      }))
    },
    componentType: 'multi-select'
  };

  const customerFilterOption: FilterItemType<OrderFilters> = {
    key: 'customer',
    title: 'Customer',
    props: {
      options: customers.map((customer: Customer) => ({
        value: customer,
        label: get(customer, 'name', '') || ''
      }))
    },
    componentType: 'select'
  };

  const closedPOStatusOptions: Option<PurchaseOrderStatusEnum>[] = [
    { label: 'Open', value: PurchaseOrderStatusEnum.OPEN },
    { label: 'Closed', value: PurchaseOrderStatusEnum.CLOSED }
  ];

  return [
    accountsFilterOption,
    {
      key: 'status',
      title: 'Order status',
      props: { options: closedPOStatusOptions },
      componentType: 'select'
    },
    {
      key: 'bookingState',
      title: 'Booking status',
      componentType: 'multi-select',
      props: {
        options: [
          { label: 'Fully booked', value: PurchaseOrderBookingStateEnum.FULLY_BOOKED },
          { label: 'Partially booked', value: PurchaseOrderBookingStateEnum.PARTIALLY_BOOKED },
          { label: 'Not booked', value: PurchaseOrderBookingStateEnum.NOT_BOOKED }
        ]
      }
    },
    ...(shouldRenderCustomerFilter ? [customerFilterOption] : []),
    {
      key: 'manufacturerIds',
      componentType: 'multi-select',
      title: 'Manufacturer',
      props: {
        options: manufacturers.map((manufacturer: NetworksAssignableInterface) => ({
          value: get(manufacturer, 'id') || '',
          label: get(manufacturer, 'label.short', '')
        }))
      }
    },
    {
      key: 'cargoReadyDateBetween',
      title: 'Cargo ready dates',
      props: {
        options: [
          { label: 'Next 1 day', value: getDateRange(1) },
          { label: 'Next 3 days', value: getDateRange(3) },
          { label: 'Next 7 days', value: getDateRange(7) },
          { label: 'Next 14 days', value: getDateRange(14) },
          { label: 'Next 21 days', value: getDateRange(21) },
          { label: 'Next 28 days', value: getDateRange(28) },
          {
            label: 'In the past',
            value: getDateRange(ALL_DAYS_IN_THE_PAST, getYesterday()) // simulate getting all past shipments, thus setting -10 years
          }
        ]
      },
      componentType: 'date-range-picker'
    },
    {
      key: 'portOfLoadUnlocode',
      title: 'Port of loading',
      props: {
        options: polTerminals.map(({ unlocode, label }: Terminal) => ({
          value: unlocode || '',
          label: label || ''
        }))
      },
      componentType: 'select'
    },
    {
      key: 'portOfDestinationUnlocode',
      title: 'Port of destination',
      props: {
        options: podTerminals.map(({ unlocode, label }: Terminal) => ({
          value: unlocode || '',
          label: label || ''
        }))
      },
      componentType: 'select'
    },
    {
      key: 'incoterms',
      title: 'Incoterms',
      props: { options: incotermOptions },
      componentType: 'select'
    },
    {
      key: 'issueTitle',
      title: 'Issue types',
      props: { options: issueTitles.map(mapIssues) },
      componentType: 'select'
    }
  ];
};

const isEmptyValue = (value: unknown): boolean => {
  return Array.isArray(value) ? value.length === 0 : isNil(value);
};

const getIssuesFilterValue = (issueTitle: Optional<string>): IssuesFilterInput | undefined => {
  if (issueTitle === null || issueTitle === undefined) {
    return undefined;
  }

  return {
    titleEq: issueTitle === '' ? null : issueTitle,
    active: true
  };
};

export const prepareFilterVariables = (appliedFilters: OrderFilters): Partial<OrderListQueryVariables> => {
  const cleanedFilters: Partial<OrderFilters> = omitBy(appliedFilters, isEmptyValue);
  const {
    accountIds,
    bookingState,
    cargoReadyDateBetween,
    customer,
    filterBy,
    incoterms,
    issueTitle,
    manufacturerIds,
    portOfDestinationUnlocode,
    portOfLoadUnlocode,
    lastUpdatedBetween,
    status
  } = cleanedFilters;

  return {
    accountIds,
    bookingState,
    cargoReadyDateBetween,
    customerUuid: customer?.uuid,
    filterBy,
    incoterms,
    issues: getIssuesFilterValue(issueTitle),
    manufacturerIds,
    portOfDestinationUnlocode,
    portOfLoadUnlocode,
    lastUpdatedBetween,
    status
  };
};
