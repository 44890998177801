import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Body } from '../Typography';

interface Props {
  children?: ReactNode;
  className?: string;
  hasError?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick?: () => void;
  subtitle?: string;
  title: string;
}

export const displayName = 'Design System/Tile';

const Tile: FC<Props> = (props) => {
  const { className, children, hasError, isDisabled, isSelected, onClick, subtitle, title } = props;
  const hoverClasses: string =
    'cursor-pointer transition-all duration-200 group hover:bg-white hover:border-azure-base active:bg-azure-lighter';

  const classNames = cx(
    {
      'border-azure-base bg-azure-lightest': isSelected,
      'border-grey-light': (!isSelected && !hasError) || isDisabled,
      'bg-grey-lightest pointer-events-none': isDisabled,
      [hoverClasses]: !isDisabled,
      'bg-white': !isDisabled && !isSelected,
      'border-red-dark': hasError
    },
    'flex flex-col items-center justify-center rounded border border-solid p-4 text-center',
    className
  );

  const childrenClassNames: string = cx(
    {
      grayscale: isDisabled
    },
    'mb-4'
  );

  return (
    <div className={classNames} data-testid="tile" onClick={onClick}>
      <div className="flex flex-col items-center">
        {children && <div className={childrenClassNames}>{children}</div>}
        <div className="text-sm font-bold leading-normal">{title}</div>
        {subtitle && <Body className="mt-1 text-grey-base">{subtitle}</Body>}
      </div>
    </div>
  );
};

export type { Props as TileProps };
export default Tile;
