import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormHeadline, FormInstance, FormTextarea } from '@zen/Components';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './OceanTrackingForm.validation';
import type { OceanTrackingFormValues } from './types';

interface Props {
  onCancel: () => void;
  onSubmit: (values: OceanTrackingFormValues) => Promise<IOkOrErrorResult>;
}

const OceanTrackingForm: FC<Props> = ({ onCancel, onSubmit }) => {
  const renderFormButtons = ({ isSubmitting }: FormInstance<{ reason: string }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Disable tracking">
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Form
      formButtons={renderFormButtons}
      formName="OceanTrackingForm"
      initialValues={{ reason: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {() => (
        <div data-testid="ocean-tracking-form">
          <FormHeadline
            level={3}
            tagline="Let us know so we can help improve the tracking for future shipments."
            text="Why are you disabling tracking?"
          />
          <FormTextarea name="reason" textareaRows={4} />
        </div>
      )}
    </Form>
  );
};

export default OceanTrackingForm;
