import type { FC, ReactElement } from 'react';

import { FormCheckbox, FormLabel, FormRadioGroup } from '@zen/Components';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import { Applicability } from '@zen/CostTracking/types';

interface BaseProps {
  isOptional?: boolean;
  label?: string;
  options: Applicability[];
}

interface SingleSelectProps extends BaseProps {
  isMulti: false;
  name: string;
}

interface MultiSelectProps extends BaseProps {
  isMulti: true;
  name?: string;
}

type Props = SingleSelectProps | MultiSelectProps;

const applicabilityLabelMapping: Record<Applicability, string> = {
  [Applicability.BOOKING]: 'Booking',
  [Applicability.DESTINATION]: 'Destination',
  [Applicability.FREIGHT]: 'Freight',
  [Applicability.ORIGIN]: 'Origin'
};

const ApplicabilityInput: FC<Props> = (props) => {
  const { isMulti, isOptional = false, label = 'Applies at', name, options } = props;
  const applicabilityOptions: RadioGroupOption[] = options.map((option: Applicability) => {
    return {
      label: applicabilityLabelMapping[option],
      value: option
    };
  });

  const renderSingleSelect: ReactElement = (
    <FormRadioGroup
      className="w-max"
      isOptional={isOptional}
      label={label}
      name={name || ''}
      options={applicabilityOptions}
      radioAlignment="column"
    />
  );

  const renderMultiSelect: ReactElement = (
    <div className="mb-6">
      <FormLabel className="mb-2" isOptional={isOptional} label={label} />
      {options.map((option: Applicability) => (
        <FormCheckbox key={option} className="mb-2" name={option} />
      ))}
    </div>
  );

  return isMulti ? renderMultiSelect : renderSingleSelect;
};

export default ApplicabilityInput;
