import type { ReactNode } from 'react';

import SkeletonLoading from '@zen/Components/SkeletonLoading';

import type { RCTableColumn } from '../types';

export const generateLoadingAttributes = <T,>(columns: RCTableColumn<T>[], dataCount: number = 20) => {
  const keys: string[] = columns.map((column) => column.key);

  return {
    loadingColumns: generateLoadingColumns(columns),
    loadingData: generateLoadingData(keys, dataCount)
  };
};

const generateLoadingColumns = <T,>(columns: RCTableColumn<T>[]) => {
  return columns.map((column) => {
    const { render, ...loadingColumn } = column;

    return loadingColumn;
  });
};

const generateLoadingData = (keys: string[], count: number) => {
  const data: { [key: string]: ReactNode } = keys.reduce((prev, key) => {
    return { ...prev, [key]: <SkeletonLoading className="ml-0 mr-0" height={20} width="w-full" /> };
  }, {});

  return Array.from({ length: count }).map(() => data);
};
