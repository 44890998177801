import './quill.snow.css';

import { uniqueId } from 'lodash';
import { FC, useMemo } from 'react';
import ReactQuill from 'react-quill';

import FormField, { FormFieldProps } from '../FormField';
import type { FormInstance } from '../types';
import EditorToolbar from './RichTextEditor/EditorToolbar';
import { formats, getTextEditorToolbarModules } from './RichTextEditor/EditorToolbar/helpers';

interface Field {
  name: string;
  value: string;
}

interface Props extends FormFieldProps {
  className?: string;
  placeholder?: string;
  toolbarClassName?: string;
}

const FormRichText: FC<Props> = (props) => {
  const { toolbarClassName, placeholder, className = 'h-48' } = props;

  const editorId: string = useMemo(() => uniqueId('editor'), []);

  const modules = getTextEditorToolbarModules(editorId);

  return (
    <FormField {...props} noMargin={!!toolbarClassName}>
      {(field: Field, form: FormInstance<unknown>) => {
        const handleChange = (value: string): void => {
          form.setFieldValue(field.name, value);
        };

        return (
          <div className="[&_strong]:font-bold [&_em]:italic [&_a]:underline" data-testid="form-rich-text">
            <ReactQuill
              className={className}
              formats={formats}
              modules={modules}
              onChange={handleChange}
              placeholder={placeholder}
              theme="snow"
              value={field.value || ''}
            />
            <EditorToolbar className={toolbarClassName} toolbarId={editorId} />
          </div>
        );
      }}
    </FormField>
  );
};

export default FormRichText;
