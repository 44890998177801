import type { Option } from '@zen/DesignSystem';
import type { ArrayElement, Nullable } from '@zen/utils/typescript';

export const getSelectedOptions = <T>(
  options: Option<ArrayElement<T>>[],
  values: Nullable<ArrayElement<T>[]>
): Option<ArrayElement<T>>[] => {
  const filteredOptions: Option<ArrayElement<T>>[] = [];

  options.forEach((option: Option<ArrayElement<T>>) => {
    if (values?.includes(option.value)) {
      filteredOptions.push(option);
    }
  });

  return filteredOptions;
};
