import cx from 'classnames';
import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import type { CoreCargo } from '@zen/Cargo';
import MilestoneName from '@zen/Journey/components/MilestoneName';
import type { Optional } from '@zen/utils/typescript';

import SummaryMilestoneCargos from '../SummaryMilestoneCargos';
import { type JourneyShippingCargoReadyDateMilestone, JourneyShippingMilestoneNameEnum, JourneyStopActionEnum } from '../types';
import CargoReadyDateDetails from './CargoReadyDateDetails';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  cargosNotCollected: CoreCargo[];
  milestone: JourneyShippingCargoReadyDateMilestone;
  onCargoButtonClick: (cargoId: string) => void;
  zencargoReference: string;
}
const CargoReadyDateMilestone: FC<Props> = (props) => {
  const { bookingModeOfTransport, cargosNotCollected, milestone, onCargoButtonClick, zencargoReference } = props;
  const hasCargosNotCollected: boolean = cargosNotCollected.length > 0;

  const milestoneNameAndCargosClassName: string = cx(
    {
      'border-dashed border-grey-light': hasCargosNotCollected,
      'border-solid border-azure-base': !hasCargosNotCollected
    },
    'border-l-[1px] pt-2 pl-8'
  );

  return (
    <div className="flex">
      <CargoReadyDateDetails
        bookingModeOfTransport={bookingModeOfTransport}
        milestone={milestone}
        zencargoReference={zencargoReference}
      />

      <div className={milestoneNameAndCargosClassName}>
        <MilestoneName
          isMilestoneCompleted={milestone.completed}
          modeOfTransport={bookingModeOfTransport}
          name={JourneyShippingMilestoneNameEnum.CARGO_READY_DATE}
          stopAction={JourneyStopActionEnum.COLLECTION}
        />
        {hasCargosNotCollected && (
          <div className="mt-3">
            <SummaryMilestoneCargos cargos={cargosNotCollected} onCargoButtonClick={onCargoButtonClick} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CargoReadyDateMilestone;
