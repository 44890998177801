import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';
import type { AllPaginatedResults } from '@zen/utils/hooks/pagination/useAllPaginatedResults';

import type { MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables } from '../graphql';
import { MilestoneDelayReasonsDocument } from '../graphql';
import type { DelayReason } from '../types';

export const useMilestoneDelays = (skip: boolean = false): AllPaginatedResults<DelayReason> => {
  return useAllPaginatedResults<MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables, DelayReason>({
    document: MilestoneDelayReasonsDocument,
    fetchPolicy: 'cache-first',
    responsePath: 'shippingDelayReasons',
    skip,
    variables: {}
  });
};
