import { object, ObjectSchema, string } from 'yup';

export const validationSchema: ObjectSchema<{}> = object().shape({
  category: string().nullable().required('Category is required'),
  severity: string().nullable().required('Severity is required'),
  state: string().nullable().required('Status is required'),
  occurredOn: string().nullable().required('Date issue began is required'),
  problemDescription: string().nullable().required('Problem description is required'),
  solutionDescription: string().nullable().required('Solution description is required')
});
