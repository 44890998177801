import type { Document } from './types';

export const ADD_DOCUMENTS = 'ADD_DOCUMENTS';
export const ADD_DOCUMENT_TO_QUEUE = 'ADD_DOCUMENT_TO_QUEUE';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const REMOVE_DOCUMENTS_FROM_QUEUE = 'REMOVE_DOCUMENTS_FROM_QUEUE';
export const UPDATE_DOCUMENT_IN_QUEUE = 'UPDATE_DOCUMENT_IN_QUEUE';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';

export const addDocuments = (payload: Document[]) => ({
  type: ADD_DOCUMENTS,
  payload
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export const addDocumentToQueue = (payload) => ({
  type: ADD_DOCUMENT_TO_QUEUE,
  payload
});

export const deleteDocument = (uuid: string) => ({
  type: DELETE_DOCUMENT,
  payload: {
    uuid
  }
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
export const updateDocumentInQueue = (payload) => ({
  type: UPDATE_DOCUMENT_IN_QUEUE,
  payload
});

export const updateDocument = (payload: Document) => ({
  type: UPDATE_DOCUMENT,
  payload
});

export const removeDocumentsFromQueue = () => ({
  type: REMOVE_DOCUMENTS_FROM_QUEUE
});

export const initialState = {
  documentsInQueue: [],
  documents: []
};

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const reducer = (state, action) => {
  switch (action.type) {
    case ADD_DOCUMENTS:
      return {
        ...state,
        documentsInQueue: [],
        documents: [...state.documents, ...action.payload]
      };
    case ADD_DOCUMENT_TO_QUEUE:
      return {
        ...state,
        documentsInQueue: [...state.documentsInQueue, action.payload]
      };
    case DELETE_DOCUMENT:
      return {
        ...state,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'document' implicitly has an 'any' type.
        documents: state.documents.filter((document) => document.uuid !== action.payload.uuid)
      };
    case REMOVE_DOCUMENTS_FROM_QUEUE:
      return {
        ...state,
        documentsInQueue: []
      };
    case UPDATE_DOCUMENT_IN_QUEUE:
      return {
        ...state,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'document' implicitly has an 'any' type.
        documentsInQueue: state.documentsInQueue.map((document) => {
          if (document.description === action.payload.description) {
            return {
              ...document,
              ...action.payload
            };
          }

          return document;
        })
      };
    case UPDATE_DOCUMENT:
      return {
        ...state,
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'document' implicitly has an 'any' type.
        documents: state.documents.map((document) => {
          if (document.uuid === action.payload.uuid) {
            return {
              ...document,
              ...action.payload
            };
          }

          return document;
        })
      };
    default:
      return state;
  }
};
