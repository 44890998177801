import type { StringMap } from 'quill';

function undoChange(): void {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  this.quill.history.undo();
}

function redoChange(): void {
  // @ts-expect-error ts-migrate(2683) FIXME: 'this' implicitly has type 'any' because it does n... Remove this comment to see the full error message
  this.quill.history.redo();
}

export const getTextEditorToolbarModules = (id: string): StringMap => {
  return {
    toolbar: {
      container: `#${id}`,
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    }
  };
};

export const formats: string[] = ['bold', 'italic', 'strike', 'list', 'bullet', 'image'];
