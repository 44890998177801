import qs from 'qs';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation, useSearchParam } from 'react-use';

export const useTableScroll = (tableId: string): [number, (tableScroll: number) => void] => {
  const tableScroll = useSearchParam(`table-scroll-${tableId}`);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const setTableScroll = useCallback(
    (newScroll: number) => {
      if (search) {
        const parsed = qs.parse(search.slice(1));

        parsed[`table-scroll-${tableId}`] = String(newScroll);
        navigate({
          pathname,
          search: qs.stringify(parsed)
        });
      }
    },
    [search, tableId, navigate, pathname]
  );
  const scroll = tableScroll || 0;

  return [Number(scroll), setTableScroll];
};
