import type { Option } from '@zen/DesignSystem';

import { ShippingDelayReasonResponsiblePartyEnum } from './types';

export const responsiblePartyLabels: Record<ShippingDelayReasonResponsiblePartyEnum, string> = {
  [ShippingDelayReasonResponsiblePartyEnum.AI_ESTIMATE]: 'AI estimate',
  [ShippingDelayReasonResponsiblePartyEnum.CARRIER]: 'Carrier',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_BUYER]: 'Customer buyer',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_DC]: 'Customer DC',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_SHIPPING]: 'Customer shipping',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMS]: 'Customs',
  [ShippingDelayReasonResponsiblePartyEnum.HAULIER]: 'Haulier',
  [ShippingDelayReasonResponsiblePartyEnum.OTHER]: 'Other',
  [ShippingDelayReasonResponsiblePartyEnum.PORT]: 'Port',
  [ShippingDelayReasonResponsiblePartyEnum.SUPPLIER]: 'Supplier',
  [ShippingDelayReasonResponsiblePartyEnum.ZENCARGO]: 'Zencargo'
};

export const responsiblePartyOptions: Option<string>[] = Object.keys(ShippingDelayReasonResponsiblePartyEnum).map((key) => ({
  value: key,
  label: responsiblePartyLabels[key as keyof typeof ShippingDelayReasonResponsiblePartyEnum]
}));
