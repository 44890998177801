import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormHeadline, type FormInstance } from '@zen/Components';
import { Button } from '@zen/DesignSystem';
import { ModeOfTransport } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CreateVehicleCargoItemMutationResult } from '../../../graphql';
import LooseFormFields from '../../LooseCargo/LooseFormFields';
import FullTruckLoadFormFields from '../FullTruckLoadFormFields';
import { getFullTruckFormValidationSchema } from './fullTruckForm.validation';
import { defaultFullTruckInitialValues } from './helpers';
import type { FullTruckFormInitialValues, FullTruckFormValues } from './types';

interface Props {
  initialValues?: FullTruckFormInitialValues;
  onCancel: () => void;
  onSubmit: (values: FullTruckFormValues) => Promise<IOkOrErrorResult>;
  onSuccess: (cargoId: string) => void;
}

const FullTruckForm: FC<Props> = ({ onCancel, onSubmit, initialValues, onSuccess }) => {
  const handleSuccess = (results: CreateVehicleCargoItemMutationResult['cargoCreateVehicleCargoItem']): void => {
    onSuccess(results?.cargoItem?.id || '');
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FullTruckFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save">
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  const formInitialValues: FullTruckFormInitialValues = initialValues || defaultFullTruckInitialValues;

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={formInitialValues}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
      validationSchema={getFullTruckFormValidationSchema(formInitialValues?.loose, ModeOfTransport.TRUCK)}
    >
      {() => (
        <div className="space-y-6">
          <FullTruckLoadFormFields />
          <FormHeadline tagline="Select the type and size to ship your goods" text="Cargo details" />
          <LooseFormFields showCargoParams={false} />
        </div>
      )}
    </Form>
  );
};

export default FullTruckForm;
