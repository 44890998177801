import { flatten, isEmpty } from 'lodash';

import type { Optional } from '@zen/utils/typescript';

import {
  renderIncoterms,
  renderLocation,
  renderPort,
  renderShipmentsTableCargoLabel,
  renderShipmentsTableMilestoneName,
  SHIPMENTS_TABLE_EMPTY_VALUE
} from '../../helpers';
import {
  ShipmentsTableCargoGroup,
  ShipmentsTableCargoItem,
  ShipmentsTableColumn,
  ShipmentsTableDateWithCertainty,
  ShipmentsTableModeOfTransport
} from '../../types';
import { getDateCertaintyLabel } from '../DateWithCertainty/helpers';

export const SHIPMENT_DATA_EXPORT_MAX_RESULTS = 9000;

export const prepareDataToExport = (results: ShipmentsTableCargoGroup[]) => {
  const cargoItems: ShipmentsTableCargoItem[] = flatten(results.map((result) => result.cargos));
  const headers: string[] = columns.map((column) => column.header);

  const data: string[][] = cargoItems.map((cargoItem) => {
    return columns.map((column) => column.getDataPoint(cargoItem));
  });

  return { headers, data };
};

type ShipmentsTableExportColumn<T> = { getDataPoint: (value: T) => string; header: string };

export const zencargoReferenceColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'zencargoReference'>> = {
  header: ShipmentsTableColumn.ZENCARGO_REFERENCE,
  getDataPoint: (value) => value.zencargoReference
};
export const modeOfTransportColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'modeOfTransport'>> = {
  header: ShipmentsTableColumn.MODE,
  getDataPoint: (value) => formatModeOfTransport(value.modeOfTransport)
};
export const clientReferenceColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'clientReference'>> = {
  header: ShipmentsTableColumn.CLIENT_REFERENCE,
  getDataPoint: (value) => value.clientReference || SHIPMENTS_TABLE_EMPTY_VALUE
};
export const cargoTypeColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'cargoType' | 'quantity'>> = {
  header: ShipmentsTableColumn.CARGO_TYPE,
  getDataPoint: (value) => renderShipmentsTableCargoLabel(value.cargoType, value.quantity)
};
export const milestoneColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'currentMilestone'>> = {
  header: ShipmentsTableColumn.CURRENT_MILESTONE,
  getDataPoint: (value) => renderShipmentsTableMilestoneName(value.currentMilestone)
};
export const cargoReadyDateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'cargoReadyDate'>> = {
  header: ShipmentsTableColumn.CARGO_READY_DATE,
  getDataPoint: (value) => formatCargoReadyDate(value.cargoReadyDate)
};
export const collectionLocationColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'collectionLocation'>> = {
  header: ShipmentsTableColumn.COLLECTION,
  getDataPoint: (value) => renderLocation(value.collectionLocation)
};
export const collectionDateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'collectionDate'>> = {
  header: ShipmentsTableColumn.COLLECTION_DATE,
  getDataPoint: (value) => formatDateWithCertainty(value.collectionDate)
};
export const deliveryLocationColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'deliveryLocation'>> = {
  header: ShipmentsTableColumn.DESTINATION,
  getDataPoint: (value) => renderLocation(value.deliveryLocation)
};
export const deliveryDateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'deliveryDate'>> = {
  header: ShipmentsTableColumn.DELIVERY_DATE,
  getDataPoint: (value) => formatDateWithCertainty(value.deliveryDate)
};
export const portOfLoadingColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'portOfLoading'>> = {
  header: ShipmentsTableColumn.PORT_OF_LOADING,
  getDataPoint: (value) => renderPort(value.portOfLoading)
};
export const departureDateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'departureDate'>> = {
  header: ShipmentsTableColumn.DEPARTURE_DATE,
  getDataPoint: (value) => formatDateWithCertainty(value.departureDate)
};
export const portOfDestinationColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'portOfDestination'>> = {
  header: ShipmentsTableColumn.PORT_OF_DESTINATION,
  getDataPoint: (value) => renderPort(value.portOfDestination)
};
export const arrivalDateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'arrivalDate'>> = {
  header: ShipmentsTableColumn.ARRIVAL_DATE,
  getDataPoint: (value) => formatDateWithCertainty(value.arrivalDate)
};
export const containerNumberColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'containerNumber'>> = {
  header: ShipmentsTableColumn.CONTAINER_NUMBER,
  getDataPoint: (value) => value.containerNumber || SHIPMENTS_TABLE_EMPTY_VALUE
};
export const carrierScacColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'carrierScac'>> = {
  header: ShipmentsTableColumn.CARRIER_SCAC,
  getDataPoint: (value) => value.carrierScac || SHIPMENTS_TABLE_EMPTY_VALUE
};
export const consigneeColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'consignee'>> = {
  header: ShipmentsTableColumn.CONSIGNEE,
  getDataPoint: (value) => renderLocation(value.consignee)
};
export const consignorColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'consignor'>> = {
  header: ShipmentsTableColumn.CONSIGNOR,
  getDataPoint: (value) => renderLocation(value.consignor)
};
export const customsOnlyColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'customsOnly'>> = {
  header: ShipmentsTableColumn.CUSTOMS_ONLY,
  getDataPoint: (value) => formatCustomsOnly(value.customsOnly)
};
export const cargoPurchaseOrdersColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'cargoPurchaseOrders'>> = {
  header: 'Cargo purchase orders',
  getDataPoint: (value) => formatPurchaseOrders(value.cargoPurchaseOrders)
};

export const bookingPurchaseOrdersColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'bookingPurchaseOrders'>> = {
  header: 'Booking purchase orders',
  getDataPoint: (value) => formatPurchaseOrders(value.bookingPurchaseOrders)
};

export const masterBillOfLadingColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'masterBillOfLading'>> = {
  header: ShipmentsTableColumn.MASTER_BILL_OF_LADING,
  getDataPoint: (value) => value.masterBillOfLading || SHIPMENTS_TABLE_EMPTY_VALUE
};

export const masterAirwayBillColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'masterAirwayBill'>> = {
  header: ShipmentsTableColumn.MASTER_AIRWAY_BILL,
  getDataPoint: (value) => value.masterAirwayBill || SHIPMENTS_TABLE_EMPTY_VALUE
};

export const vehiclePlateColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'vehiclePlate'>> = {
  header: ShipmentsTableColumn.VEHICLE_PLATE,
  getDataPoint: (value) => value.vehiclePlate || SHIPMENTS_TABLE_EMPTY_VALUE
};

export const incotermsColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'incoterms'>> = {
  header: ShipmentsTableColumn.INCOTERMS,
  getDataPoint: (value) => renderIncoterms(value.incoterms)
};

export const consolidationReferenceColumn: ShipmentsTableExportColumn<Pick<ShipmentsTableCargoItem, 'consolidationReference'>> = {
  header: ShipmentsTableColumn.CONSOLIDATION_REFERENCE,
  getDataPoint: (value) => value.consolidationReference || SHIPMENTS_TABLE_EMPTY_VALUE
};

const columns: Array<{ getDataPoint: (value: ShipmentsTableCargoItem) => string; header: string }> = [
  zencargoReferenceColumn,
  modeOfTransportColumn,
  clientReferenceColumn,
  cargoTypeColumn,
  milestoneColumn,
  cargoReadyDateColumn,
  collectionLocationColumn,
  collectionDateColumn,
  deliveryLocationColumn,
  deliveryDateColumn,
  portOfLoadingColumn,
  departureDateColumn,
  portOfDestinationColumn,
  arrivalDateColumn,
  incotermsColumn,
  cargoPurchaseOrdersColumn,
  bookingPurchaseOrdersColumn,
  containerNumberColumn,
  carrierScacColumn,
  masterBillOfLadingColumn,
  vehiclePlateColumn,
  masterAirwayBillColumn,
  consolidationReferenceColumn,
  consigneeColumn,
  consignorColumn,
  customsOnlyColumn
];

const formatModeOfTransport = (value: ShipmentsTableModeOfTransport): string => {
  const config = {
    [ShipmentsTableModeOfTransport.AIR]: 'Air',
    [ShipmentsTableModeOfTransport.OCEAN]: 'Ocean',
    [ShipmentsTableModeOfTransport.RAIL]: 'Rail',
    [ShipmentsTableModeOfTransport.ROAD]: 'Road'
  };

  return config[value];
};

const formatDateWithCertainty = (value: Optional<ShipmentsTableDateWithCertainty>) => {
  if (!value) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return `${value.date} ${getDateCertaintyLabel(value.dateCertainty)}`;
};

const formatCustomsOnly = (customsOnly: Optional<boolean>) => {
  if (!customsOnly) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return 'Customs only';
};

export const formatCargoReadyDate = (value: Optional<string>) => {
  return value || SHIPMENTS_TABLE_EMPTY_VALUE;
};

const formatPurchaseOrders = (value: Optional<Array<{ id: string; referenceNumber: string }>>) => {
  if (!value || isEmpty(value)) {
    return SHIPMENTS_TABLE_EMPTY_VALUE;
  }

  return value.map((order) => order.referenceNumber).join(', ');
};
