import type { FC } from 'react';

import { Switch } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdatePricingRequiredMutation } from './graphql';

interface Props {
  canUpdatePricingRequired: boolean;
  isPricingRequired: boolean;
  zencargoReference: string;
}

const PricingRequiredToggle: FC<Props> = ({ canUpdatePricingRequired, isPricingRequired, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();

  const [updatePricingRequired, { loading }] = useUpdatePricingRequiredMutation();

  const handleChange = async (value: boolean): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updatePricingRequired({
          variables: {
            input: {
              zencargoReference,
              pricingRequired: value
            }
          },
          refetchQueries: ['getBookingManagementSettings'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => addSuccess('Pricing settings has been updated successfully.')
    });
  };

  return (
    <Switch
      isChecked={isPricingRequired}
      isDisabled={!canUpdatePricingRequired || loading}
      label="Pricing required"
      onChange={handleChange}
    />
  );
};

export default PricingRequiredToggle;
