import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type MilestoneDelayReasonsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type MilestoneDelayReasonsQueryResult = { __typename?: 'Query' } & {
  shippingDelayReasons?: GraphQLTypes.Maybe<
    { __typename?: 'ShippingDelayReasonConnection' } & Pick<GraphQLTypes.ShippingDelayReasonConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ShippingDelayReason' } & Pick<
                GraphQLTypes.ShippingDelayReason,
                'id' | 'reason' | 'responsibleParty'
              >
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const MilestoneDelayReasonsDocument = /* #__PURE__ */ gql`
  query milestoneDelayReasons($after: String, $before: String, $first: Int, $last: Int, $order: SortInput) {
    shippingDelayReasons(after: $after, before: $before, first: $first, last: $last, order: $order) {
      nodes {
        id
        reason
        responsibleParty
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useMilestoneDelayReasonsQuery__
 *
 * To run a query within a React component, call `useMilestoneDelayReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMilestoneDelayReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMilestoneDelayReasonsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useMilestoneDelayReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables>(
    MilestoneDelayReasonsDocument,
    options
  );
}
export function useMilestoneDelayReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MilestoneDelayReasonsQueryResult, MilestoneDelayReasonsQueryVariables>(
    MilestoneDelayReasonsDocument,
    options
  );
}
export type MilestoneDelayReasonsQueryHookResult = ReturnType<typeof useMilestoneDelayReasonsQuery>;
export type MilestoneDelayReasonsLazyQueryHookResult = ReturnType<typeof useMilestoneDelayReasonsLazyQuery>;
