import type { FC, ReactNode } from 'react';

import { Option, Select } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { FormFieldProps } from '../FormField';
import FormField from '../FormField';
import type { FormInstance } from '../types';
import { useChargeTypesQuery } from './graphql';
import { formatChargeTypeSubtitle, getChargeType, prepareOptions } from './helpers';
import type { ChargeBasisEnum, ChargeType, ChargeTypeEnum } from './types';

interface Props extends FormFieldProps {
  basis?: ChargeBasisEnum;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
  isClearable?: boolean;
  isRequired?: boolean;
  name: string;
  onChange?: (chargeType: Nullable<ChargeType>) => void;
  placeholder?: string;
  renderMenuInPortal?: boolean;
  type?: ChargeTypeEnum;
}

const FormChargeTypeSelect: FC<Props> = (props) => {
  const { basis, type, onChange, name, className, disabled, hasError, placeholder, isClearable, renderMenuInPortal } = props;
  const { data } = useChargeTypesQuery({
    variables: {
      basis,
      type
    }
  });

  const chargeTypes = (data?.chargeTypes?.nodes || []) as ChargeType[];
  const chargeTypeOptions: Option<ChargeType>[] = prepareOptions(chargeTypes);

  const getChargeTypeLabel = (chargeType: ChargeType): ReactNode => {
    const subtitle = formatChargeTypeSubtitle(chargeType);

    return (
      <span>
        <span className="font-bold">{chargeType.name}</span>
        {subtitle ? ` - ${subtitle}` : null}
      </span>
    );
  };

  const formatOptionLabel = (option: Option<ChargeType>): ReactNode => {
    return option.value ? getChargeTypeLabel(option.value) : '';
  };

  return (
    <FormField {...props}>
      {(field: { value: Nullable<ChargeType> }, form: FormInstance<unknown>) => {
        const handleChange = (chargeType: Nullable<ChargeType>): void => form.setFieldValue(name, chargeType);

        const selectedChargeTypeId: string | undefined = typeof field?.value === 'string' ? field.value : field?.value?.id;
        const selectedChargeType = selectedChargeTypeId ? getChargeType(chargeTypes, selectedChargeTypeId) : null;

        return (
          <Select
            className={className}
            formatOptionLabel={formatOptionLabel}
            hasError={hasError}
            isClearable={isClearable}
            isDisabled={disabled}
            name={name}
            onChange={onChange || handleChange}
            options={chargeTypeOptions}
            placeholder={placeholder}
            renderMenuInPortal={renderMenuInPortal}
            value={selectedChargeType}
          />
        );
      }}
    </FormField>
  );
};

export default FormChargeTypeSelect;
