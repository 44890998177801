import type { Maybe } from 'graphql/jsutils/Maybe';

import type { Option } from '@zen/DesignSystem';
import { getRelativeTime } from '@zen/utils/dateTime/conversion/conversion';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type {
  ActiveTaskItem,
  CompletedTaskItem,
  FixedFilterOptions,
  KeyValuePair,
  MiniTaskListFilter,
  TaskActionPermissions,
  TaskListItem,
  WorkflowTaskVariablesArray
} from './types';

export const getWorkflowTaskVariableValue = (
  workflowTaskVariables: Maybe<WorkflowTaskVariablesArray> = [],
  key: string
): string => {
  return workflowTaskVariables?.find((taskVariableItem: KeyValuePair) => taskVariableItem.key === key)?.value || '-';
};

export const dateAsSentence = (date: Maybe<string>): Nullable<string> => {
  if (!date) return null;

  return getRelativeTime(date, { nextWeek: '[MMM_D_YYYY]', nextDay: '[MMM_D_YYYY]' });
};

export const getStartOfDayISOString = (date: string): string => {
  const startOfDay = new Date(date).setUTCHours(0, 0, 0, 0);

  return new Date(startOfDay).toISOString();
};

export const getEndOfDayISOString = (date: string): string => {
  const endOfDay = new Date(date).setUTCHours(23, 59, 59, 999);

  return new Date(endOfDay).toISOString();
};

export function isCompletedTask(task: CompletedTaskItem | ActiveTaskItem): task is CompletedTaskItem {
  return 'completedDate' in task;
}

export const fixedTaskFilterOptions: Option<FixedFilterOptions>[] = [
  { label: 'All', value: 'ALL' },
  { label: 'Unassigned', value: 'UNASSIGNED' }
];

export const modifyQueryForFixedFilterOptions = (queryVariables: MiniTaskListFilter): MiniTaskListFilter => {
  const assignee: Optional<string> = queryVariables?.assignee?.[0];

  if (assignee === 'ALL') {
    return { ...queryVariables, assignee: [] };
  }
  if (assignee === 'UNASSIGNED') {
    return { onlyUnassignedTasks: true };
  }

  return queryVariables;
};

export const getTaskActionPermissions = (task: TaskListItem): TaskActionPermissions => {
  if (isCompletedTask(task)) return {};

  const { canDeleteTask, canCompleteTask } = task;

  return {
    canDeleteTask: canDeleteTask || false,
    canCompleteTask: canCompleteTask || false
  };
};
