import type { Nullable, Optional } from '@zen/utils/typescript';

import { parseAndFormatTime } from '../jodaConnector';
import type { Time } from '../types';

export const formatTime = (input: Time | string, withSeconds = true): Nullable<string> => {
  return parseAndFormatTime(input, withSeconds);
};

export const formatTimeRange = (
  startTime: Time | string,
  endTime: Optional<Time | string> = undefined,
  withSeconds = true
): Nullable<string> => {
  const formattedStartTime = formatTime(startTime, withSeconds);
  const formattedEndTime = formatTime(endTime || '', withSeconds);

  if (!formattedStartTime) {
    return null;
  }

  if (!formattedEndTime || formattedStartTime === formattedEndTime) {
    return formattedStartTime;
  }

  return `${formattedStartTime} - ${formattedEndTime}`;
};
