import type { Option } from '@zen/DesignSystem';

import { ContractIdType, ContractType } from './types';

export const contractTypeMapping: Record<ContractType, string> = {
  [ContractType.BCO]: 'BCO',
  [ContractType.FAK]: 'FAK',
  [ContractType.NAC]: 'NAC',
  [ContractType.SPOT_FAK]: 'SPOT FAK'
};

export const contractTypeOptions: Option<ContractType>[] = Object.entries(contractTypeMapping).map(([value, label]) => ({
  value: value as ContractType,
  label
}));

export const getContractTypeLabel = (contractIdType: ContractIdType): string =>
  (contractIdType?.contractType && contractTypeMapping[contractIdType?.contractType]) || '';
