import type { FC } from 'react';

import { ModeOfTransport } from '../types';
import { CostTrackingContext } from './CostTrackingContext';
import type { ContextType } from './types';

export const contextValues: ContextType = {
  accountId: 'account-123',
  costTrackingId: 'cost-tracking-id',
  modeOfTransport: ModeOfTransport.OCEAN,
  zencargoReference: 'ZREF-123'
};

const TestCostTrackingProvider: FC = ({ children }) => {
  return <CostTrackingContext.Provider value={contextValues}>{children}</CostTrackingContext.Provider>;
};

export default TestCostTrackingProvider;
