import { isOceanBooking, isRoadBooking } from '@zen/Booking';
import type { ModeOfTransport } from '@zen/Cargo';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { BookingDetailsData, OceanTrackingStatusEnum, RoadTrackingStatus, TrackingDetails, TrackingStatus } from './types';

export const bookingDetailsPageCollapsibleConfigKeyName = 'booking-details-page-config';

export const isBookingTrackable = (modeOfTransport: Optional<ModeOfTransport>): boolean => {
  return isRoadBooking(modeOfTransport) || isOceanBooking(modeOfTransport);
};

export const calculateActivityFeedHeight = (windowScrollPosition: number, canViewNotes: boolean): string => {
  const isFixedHeaderVisible: boolean = windowScrollPosition > 220;
  const isWindowHeightBelowMinimumHeight: boolean = window.innerHeight < 620;
  const topOffset: string = isFixedHeaderVisible ? '16rem' : '26rem';

  if (canViewNotes) {
    return '29rem';
  }

  if (!isFixedHeaderVisible && isWindowHeightBelowMinimumHeight) {
    return '16rem';
  }

  return `calc(100vh - ${topOffset})`;
};

export const getRoadBookingStatus = (status: Optional<RoadTrackingStatus>): TrackingStatus => {
  if (status === RoadTrackingStatus.CURRENTLY_BEING_TRACKED || status === RoadTrackingStatus.TRACKING_COMPLETED) {
    return TrackingStatus.TRACKED;
  }

  return TrackingStatus.NOT_TRACKED;
};

export const getOceanBookingStatus = (status: Optional<OceanTrackingStatusEnum>): TrackingStatus => {
  if (!status) return TrackingStatus.NOT_TRACKED;

  const mapping: Record<OceanTrackingStatusEnum, TrackingStatus> = {
    [OceanTrackingStatusEnum.NOT_TRACKED]: TrackingStatus.NOT_TRACKED,
    [OceanTrackingStatusEnum.TRACKED]: TrackingStatus.TRACKED,
    [OceanTrackingStatusEnum.TRACKING_DISABLED]: TrackingStatus.TRACKING_DISABLED
  };

  return mapping[status];
};

type BookingData = Optional<Pick<BookingDetailsData, 'modeOfTransport' | 'oceanTracking' | 'roadTracking'>>;

export const prepareTrackingDetails = (data: BookingData): Nullable<TrackingDetails> => {
  if (!data || !isBookingTrackable(data.modeOfTransport)) {
    return null;
  }

  const { modeOfTransport, oceanTracking, roadTracking } = data;

  const status = isRoadBooking(modeOfTransport)
    ? getRoadBookingStatus(roadTracking?.trackingInput?.trackingSubscription?.status)
    : getOceanBookingStatus(oceanTracking?.status);

  return {
    status
  };
};
