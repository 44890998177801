import type { Issue } from '@zen/Issues/types';
import { getTimeAgo } from '@zen/utils/date';
import { getFullName } from '@zen/utils/formatting';

import type { StateDescription, StateItem } from './types';

type StageChangesHolder = Pick<Issue, 'openedAt' | 'openedBy' | 'startedAt' | 'startedBy' | 'resolvedAt' | 'resolvedBy'>;

export const buildStateChangesList = (issue: StageChangesHolder): StateDescription => {
  const { openedAt, openedBy, startedAt, startedBy, resolvedAt, resolvedBy } = issue;
  const items: StateItem[] = [];

  if (openedAt) {
    items.push({
      action: 'Opened',
      actor: getFullName(openedBy),
      timeAgo: openedAt.dateTime ? getTimeAgo(openedAt.dateTime) : ''
    });
  }

  if (startedAt) {
    items.push({
      action: 'Investigation started',
      actor: startedBy ? getFullName(startedBy) : '',
      timeAgo: startedAt.dateTime ? getTimeAgo(startedAt.dateTime) : ''
    });
  }

  if (resolvedAt) {
    items.push({
      action: 'Resolved',
      actor: resolvedBy ? getFullName(resolvedBy) : '',
      timeAgo: resolvedAt.dateTime ? getTimeAgo(resolvedAt.dateTime) : ''
    });
  }

  const currentState = items.pop() as StateItem;

  return { currentState, history: items };
};

export const generateLabel = ({ action, timeAgo, actor }: StateItem) => `${action} ${timeAgo} by ${actor}`;
