import cx from 'classnames';
import { get } from 'lodash';
import moment from 'moment';
import type { FC, SyntheticEvent } from 'react';

import { FormArray, FormArrayHelpers, FormInstance, FormNumberInput } from '@zen/Components/Form';
import FormCurrencyInput from '@zen/Components/Form/FormCurrencyInput';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import FormHiddenInput from '@zen/Components/Form/FormHiddenInput';
import FormLabel from '@zen/Components/Form/FormLabel';
import FormProductsSelect from '@zen/Components/Form/FormProductsSelect';
import { Button, DatePicker, Headline, IconButton, Popover } from '@zen/DesignSystem';
import { Currency } from '@zen/graphql/types.generated';
import type { OrderFormOrderedLineItem, OrderFormValues, OrderLineItemValues, Product } from '@zen/Orders/types';
import { formatDate } from '@zen/utils/dateTime';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';
import type { Nullable, Optional } from '@zen/utils/typescript';

export const emptyLineItem: OrderLineItemValues = {
  id: '',
  product: null,
  quantityOrdered: null,
  cbm: null,
  initialCargoReadyDate: null,
  totalCost: {
    currency: Currency.GBP,
    value: null
  },
  requiredDeliveryDate: null
};

interface Props {
  form: FormInstance<OrderFormValues>;
  items: OrderFormOrderedLineItem[];
}

const OrderLineItems: FC<Props> = ({ form, items }) => {
  const today = moment().format(FORMAT_DATE_TRANSFERABLE);
  const orderDate: string | undefined = form.values.orderDate || undefined;
  const hasMultipleItems: boolean = items.length > 1;

  const handleProductSelection = (name: string, _: Nullable<Product>, { setFieldValue }: FormInstance<OrderFormValues>) => {
    const [path] = name.split('.');

    setFieldValue(`${path}.quantityOrdered`, null);
    setFieldValue(`${path}.cbm`, null);
    setFieldValue(`${path}.totalCost.currency`, Currency.GBP);
    setFieldValue(`${path}.totalCost.value`, null);
    setFieldValue(`${path}.initialCargoReadyDate`, null);
  };

  const handleRemove = (
    { values, setFieldValue }: FormInstance<OrderFormValues>,
    itemId: Optional<string>,
    remove: () => void
  ) => {
    const isLastItem = values.orderedLineItems.length === 1;
    const itemsToDelete = values.lineItemIdsToDelete ? values.lineItemIdsToDelete : [];

    if (itemId) {
      setFieldValue('lineItemIdsToDelete', [...itemsToDelete, itemId]);
    }

    if (!isLastItem) {
      remove();
    }
  };

  const setAllDates = (fieldName: string, date: string, onClose: () => void) => {
    items.forEach((_, i) => form.setFieldValue(`orderedLineItems[${i}].${fieldName}`, date));
    onClose();
  };

  const handleQuantityChange = (
    event: SyntheticEvent<HTMLInputElement>,
    name: string,
    { setFieldValue }: FormInstance<unknown>
  ) => {
    const [path] = name.split('.');
    const qty = +event.currentTarget.value;
    const unitCost = get(form.values, `${path}.product.costPrice`, { value: 0, currency: Currency.GBP });
    const cbm = get(form.values, `${path}.product.perUnitCbm.value`, 0);
    const totalCostValue = unitCost ? Number((qty * unitCost.value).toFixed(2)) : null;

    setFieldValue(`${path}.totalCost.currency`, unitCost?.currency || Currency.GBP);
    setFieldValue(`${path}.totalCost.value`, totalCostValue);
    setFieldValue(`${path}.cbm`, cbm * qty || null);
  };

  const renderDatePicker = ({ close }: { close: () => void }, fieldName: string) => (
    <DatePicker mode="single" onChange={(date) => setAllDates(fieldName, date, close)} selected="" />
  );

  return (
    <>
      <Headline level={2}>Line items</Headline>
      <div className="mb-10">
        <FormArray
          addButtonText="Add another"
          empty={emptyLineItem}
          path="orderedLineItems"
          values={items as OrderLineItemValues[]}
        >
          {({ value: lineItem, index, getFieldName, remove }: FormArrayHelpers<OrderLineItemValues>) => {
            const lineItemClassName: string = cx('flex flex-col pt-10 pb-6 border-solid border-grey-light', {
              'border-t': index > 0
            });

            return (
              <div key={index} className={lineItemClassName}>
                <FormLabel isRequired={true} label="Product name/SKU" name={getFieldName('product')} />
                <div className="flex items-start justify-center min-w-0">
                  <FormHiddenInput name={getFieldName('id')} />
                  <FormProductsSelect
                    className="flex-1 min-w-0 mr-4"
                    hideLabel={true}
                    label="Product name/SKU"
                    name={getFieldName('product')}
                    onSelect={handleProductSelection}
                    placeholder="Blue jean shorts / SKU1234"
                  />

                  <IconButton
                    disabled={!hasMultipleItems}
                    icon="zicon-trash"
                    onClick={() => handleRemove(form, lineItem.id, remove)}
                    title="Remove"
                    variant="ghost"
                  />
                </div>
                <div className="flex justify-center space-x-4">
                  <FormNumberInput
                    className="flex-1"
                    isRequired={true}
                    label="Qty ordered"
                    name={getFieldName('quantityOrdered')}
                    onBlur={handleQuantityChange}
                    placeholder="123"
                    step="1"
                  />
                  <FormNumberInput
                    className="flex-1"
                    label="Ordered CBM"
                    name={getFieldName('cbm')}
                    placeholder="123"
                    step="any"
                  />
                  <div>
                    <FormLabel label="Total cost" name={getFieldName('totalCostValue')} />
                    <FormCurrencyInput hideLabel={true} name={getFieldName('totalCost')} />
                  </div>
                  <FormDatePickerInput
                    className="flex-[1.2]"
                    disabledDates={{ minDate: orderDate }}
                    label="Cargo ready date"
                    name={getFieldName('initialCargoReadyDate')}
                    placeholder={formatDate(today)}
                  />
                  <FormDatePickerInput
                    className="flex-[1.2]"
                    disabledDates={{ minDate: orderDate }}
                    label="Required delivery"
                    name={getFieldName('requiredDeliveryDate')}
                    placeholder={formatDate(today)}
                  />
                </div>
              </div>
            );
          }}
        </FormArray>
        <div className="flex justify-end -mt-16">
          {hasMultipleItems && (
            <div className="flex items-center">
              <p className="mr-4">Update all: </p>

              <Popover popover={(args) => renderDatePicker(args, 'initialCargoReadyDate')}>
                <Button className="mr-6" variant="secondary">
                  Cargo ready
                </Button>
              </Popover>
              <Popover popover={(args) => renderDatePicker(args, 'requiredDeliveryDate')}>
                <Button data-testid="required-delivery-button" variant="secondary">
                  Required delivery
                </Button>
              </Popover>
            </div>
          )}
        </div>

        <FormHiddenInput name="lineItemsToDelete" />
      </div>
    </>
  );
};

export default OrderLineItems;
