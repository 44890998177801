import { isEmpty } from 'lodash';

import type { FilterItemType, FilterSection } from './types';

export const getInitialFilters = <T extends object>(filter: T, items: FilterItemType<T>[]): FilterItemType<T>[] => {
  const initialFilterEntries = Object.entries(filter)
    .filter(([_, value]) => !isEmpty(value))
    .map(([key]) => key);

  return items.filter((item) => initialFilterEntries.includes(item.key as string));
};

export const hasSections = <T extends object>(config: Array<T> | Array<FilterSection<T>>): config is Array<FilterSection<T>> => {
  return (config as Array<FilterSection<T>>).every((item) => Object.hasOwn(item, 'items'));
};
