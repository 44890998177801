import useRole from '@zen/Auth/hooks/useRole';
import { Role } from '@zen/Auth/role';
import useAccount from '@zen/utils/hooks/useAccount';

import { useGetCustomerSidebarCountersQuery, useGetTaskListCountersQuery } from '../graphql';
import type { SidebarCounters } from '../types';

interface UseCustomerSidebarCountersResult {
  customerCounters: SidebarCounters;
  customerCountersRefetch: () => void;
  taskListCountersRefetch: () => void;
}

const useCustomerSidebarCounters = (): UseCustomerSidebarCountersResult => {
  const { accountUuid: accountId } = useAccount();

  const role = useRole();

  const bookingRequestFilters: Record<string, boolean> = {
    onlyManufacturerRequested: true,
    onlyReadyForApproval: false
  };

  if (role === Role.CUSTOMER_USER) {
    bookingRequestFilters.onlyManufacturerRequested = false;
    bookingRequestFilters.onlyReadyForApproval = true;
  }

  const { onlyManufacturerRequested, onlyReadyForApproval } = bookingRequestFilters;

  const { data, refetch } = useGetCustomerSidebarCountersQuery({
    variables: {
      accountIds: [accountId],
      customerUuid: accountId,
      onlyManufacturerRequested,
      onlyReadyForApproval
    },
    fetchPolicy: 'no-cache',
    skip: role === Role.MANUFACTURER
  });

  const { data: taskListCountData, refetch: refetchTaskListCounters } = useGetTaskListCountersQuery({
    fetchPolicy: 'no-cache',
    skip: role !== Role.ADMIN,
    variables: { taskDetailsFiltersInput: { onlyCurrentUsersTasks: true } }
  });

  return {
    customerCounters: {
      quotesCount: data?.quotesCount.totalCount || 0,
      bookingRequestsCount: data?.bookingRequestsCount.totalCount || 0,
      taskListCount: taskListCountData?.taskListCount?.activeTaskCount || 0
    },
    customerCountersRefetch: refetch,
    taskListCountersRefetch: refetchTaskListCounters
  };
};

export default useCustomerSidebarCounters;
