import { get, uniqueId } from 'lodash';
import { type FC, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { defaultErrorMessage } from '@zen/utils/validation';

import { useUpdateVesselNameMutation } from './graphql';

interface Props {
  canUpdateVesselName: boolean;
  vehicleId: string;
  vesselName: string;
}

const VesselNameInlineField: FC<Props> = ({ canUpdateVesselName, vehicleId, vesselName }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateVesselName] = useUpdateVesselNameMutation();
  const [inputKey, setInputKey] = useState(uniqueId());

  const label: string = 'Vessel name';

  const handleVesselNameChange = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateVesselName({
          awaitRefetchQueries: true,
          refetchQueries: ['cargoOverview'],
          variables: {
            input: {
              vehicleId,
              vesselName: value,
              zencargoReference
            }
          }
        }),
      onError: (errors): void => {
        setInputKey(uniqueId());

        addError(get(errors, '[0].message', defaultErrorMessage));
      },
      onSuccess: () => {
        addSuccess('Vessel name updated.');
      }
    });
  };

  if (!canUpdateVesselName) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={vesselName} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      key={inputKey}
      label={label}
      labelPlacement="left"
      name="vessel-name"
      onUpdate={handleVesselNameChange}
      placeholder="Enter vessel name"
      value={vesselName}
    />
  );
};

export default VesselNameInlineField;
