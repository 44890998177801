import { type FC, useState } from 'react';

import type { CargoModeEnum, ModeOfTransport } from '@zen/Booking';
import { isRoadBooking } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import { isCollectionStop, isVehicleArrivedAtWarehouse } from '@zen/Journey/helpers';
import type { Nullable, Optional } from '@zen/utils/typescript';

import {
  type JourneyLeg,
  type JourneyMilestoneWithMetadata,
  JourneyShippingMilestoneNameEnum,
  type JourneyStop,
  type MilestoneDateType
} from '../../types';
import MilestoneActualDateModal from '../MilestoneActualDateModal';
import {
  getCarriageLeg,
  getDisabledDates,
  getMilestones,
  getStops,
  isFirstJourneyMilestone,
  isLastCompletedJourneyMilestone,
  isLastJourneyMilestone
} from './helpers';
import { JourneyDetailsContext } from './JourneyDetailsContext';
import type { MilestoneDisabledDateProps } from './types';

interface Props {
  cargoCount: number;
  cargoId: string;
  cargoMode: Optional<CargoModeEnum>;
  cargoReadyDate: Optional<string>;
  legs: JourneyLeg[];
  modeOfTransport: Optional<ModeOfTransport>;
  postLegsShippingMilestones: JourneyMilestoneWithMetadata[];
  preLegsShippingMilestones: JourneyMilestoneWithMetadata[];
  zencargoReference: string;
}

const JourneyDetailsProvider: FC<Props> = (props) => {
  const {
    cargoCount,
    cargoId,
    cargoMode,
    cargoReadyDate,
    children,
    legs,
    modeOfTransport,
    postLegsShippingMilestones,
    preLegsShippingMilestones,
    zencargoReference
  } = props;
  const [milestoneForUpdate, setMilestoneForUpdate] = useState<Nullable<{ date: string; id: string }>>(null);
  const [completedMilestonesVisible, setCompletedMilestonesVisible] = useState<boolean>(false);
  const { cargoMilestones, cargoPreLegsMilestones } = useCargoOverviewContext();

  const carriageLeg = getCarriageLeg(legs);
  const milestones: JourneyMilestoneWithMetadata[] = getMilestones(legs);
  const stops: JourneyStop[] = getStops(legs);

  const gateInEmptyMilestone: Optional<JourneyMilestoneWithMetadata> = postLegsShippingMilestones?.find(
    ({ name }) => name === JourneyShippingMilestoneNameEnum.GATE_IN_EMPTY
  );
  const gateOutEmptyMilestone: Optional<JourneyMilestoneWithMetadata> = preLegsShippingMilestones?.find(
    ({ name }) => name === JourneyShippingMilestoneNameEnum.GATE_OUT_EMPTY
  );

  const getMilestoneDisabledDates = (milestoneId: string, dateType: MilestoneDateType): MilestoneDisabledDateProps => {
    return getDisabledDates({
      cargoMilestones,
      cargoPreLegsMilestones,
      cargoReadyDate,
      dateType,
      gateInEmptyMilestone,
      gateOutEmptyMilestone,
      milestoneId,
      milestones,
      modeOfTransport
    });
  };
  const isJourneyCompleted: boolean = milestones[milestones.length - 1]?.completed;
  const isLastMilestone = (id: string): boolean => isLastJourneyMilestone(milestones, id);
  const isFirstMilestone = (id: string): boolean => isFirstJourneyMilestone(milestones, id);

  const isLastCompletedMilestone = (id: string): boolean => isLastCompletedJourneyMilestone(milestones, id);

  const completedPreLegsMilestoneCount: number = preLegsShippingMilestones.filter(({ completed }) => completed).length;
  const completedMilestoneCount: number = milestones.filter(({ completed }) => completed).length + completedPreLegsMilestoneCount;
  const isCompletedMilestonesSwitcherVisible: boolean = completedMilestoneCount > 1 && !isJourneyCompleted;

  const showCompletedMilestones: boolean =
    isJourneyCompleted || (isCompletedMilestonesSwitcherVisible && completedMilestonesVisible);

  const handleVisibilityChange = (visible: boolean): void => {
    setCompletedMilestonesVisible(visible);
  };

  const handleMilestoneDateUpdate = (milestoneId: string, values: DateWithTimeFields): void => {
    const milestone: JourneyMilestoneWithMetadata | undefined = milestones.find(({ id }) => id === milestoneId);

    if (
      milestone &&
      isRoadBooking(modeOfTransport) &&
      isCollectionStop(milestone.stopAction) &&
      isVehicleArrivedAtWarehouse(milestone.name)
    ) {
      const collectionMilestone: JourneyMilestoneWithMetadata | undefined = milestones.find(({ name, stopAction }) => {
        return name === JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE && isCollectionStop(stopAction);
      });

      if (!collectionMilestone) return;

      setMilestoneForUpdate({ id: collectionMilestone.id, date: values.date });
    }
  };

  return (
    <JourneyDetailsContext.Provider
      value={{
        cargoCount,
        cargoId,
        carriageLeg,
        cargoMilestones,
        cargoMode,
        cargoReadyDate,
        completedMilestoneCount,
        getMilestoneDisabledDates,
        isCompletedMilestonesSwitcherVisible,
        isFirstMilestone,
        isJourneyCompleted,
        isLastCompletedMilestone,
        isLastMilestone,
        milestones,
        modeOfTransport,
        onCompletedMilestonesVisibilityChange: handleVisibilityChange,
        onMilestoneDateChange: handleMilestoneDateUpdate,
        postLegsShippingMilestones,
        preLegsShippingMilestones,
        showCompletedMilestones,
        stops,
        zencargoReference
      }}
    >
      {children}
      {milestoneForUpdate && (
        <MilestoneActualDateModal
          date={milestoneForUpdate.date}
          milestoneId={milestoneForUpdate.id}
          onClose={() => setMilestoneForUpdate(null)}
        />
      )}
    </JourneyDetailsContext.Provider>
  );
};

export type { Props as JourneyDetailsProviderProps };

export default JourneyDetailsProvider;
