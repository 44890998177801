import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { PermittedParties } from '../types';

const PermittedPartiesContext = createContext<PermittedParties>({});

export const usePermittedParties = (): PermittedParties => {
  const parties: PermittedParties = useContextOrThrowError<PermittedParties>(
    PermittedPartiesContext,
    'usePermittesParties must be used inside PermittedPartiesContext'
  );

  return parties;
};

export default PermittedPartiesContext;
