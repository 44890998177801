import { RefObject, useEffect, useRef, useState } from 'react';

const useIsVisible = <T extends HTMLElement>(options?: IntersectionObserverInit): [RefObject<T>, boolean] => {
  const ref = useRef<T>(null);
  const [isIntersecting, setIntersecting] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting), options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  });

  return [ref, isIntersecting];
};

export default useIsVisible;
