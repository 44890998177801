import cx from 'classnames';
import type { ReactNode } from 'react';

import { formatDate } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { PriceCalculationMilestone } from '../types';

interface Params {
  interactive: boolean;
  isPopoverVisible: boolean;
}

export const getClassNames = (params: Params) => {
  const { interactive, isPopoverVisible } = params;

  return cx(
    {
      'text-grey-dark': interactive,
      'hover:bg-grey-lighter': interactive && !isPopoverVisible,
      'text-grey-base': !interactive,
      'border border-solid border-transparent': !isPopoverVisible,
      'bg-white border-solid border border-azure-base': isPopoverVisible
    },
    'flex items-center cursor-pointer flex-1 p-3 rounded transition-all duration-300 h-8 py-1 -ml-3'
  );
};

export const priceCalculationMilestoneLabelMapping: Record<PriceCalculationMilestone, string> = {
  [PriceCalculationMilestone.CARRIER_PUBLISHED]: 'Carrier published',
  [PriceCalculationMilestone.COLLECTION]: 'Collection',
  [PriceCalculationMilestone.CUSTOM]: 'Custom',
  [PriceCalculationMilestone.GATE_IN]: 'Gate in',
  [PriceCalculationMilestone.VESSEL_DEPARTED]: 'Vessel departed'
};

export const getReadOnlyContent = (date: Optional<string>, milestone: Nullable<PriceCalculationMilestone>): ReactNode => {
  if (date) {
    return formatDate(date);
  }

  return milestone === PriceCalculationMilestone.CARRIER_PUBLISHED ? 'Date not set' : 'Waiting for booking info';
};
