import { mapValues } from 'lodash';
import qs from 'qs';

import type { ParsedUrlType, QueryParams } from './types';

type PrimitiveType = number | boolean | string;

type PrimitiveTypeWithObject = PrimitiveType | object;

export const stringifyQueryParams = (params: object): string => {
  return qs.stringify(params, { addQueryPrefix: true, skipNulls: true });
};

export const mergeQueryParams = (searchString: string, params: QueryParams): string => {
  const searchParams = parseQueryParams(searchString);

  return stringifyQueryParams({
    ...searchParams,
    ...params
  });
};

export const parseQueryParams = (query: string, decode?: boolean): ParsedUrlType<QueryParams> => {
  const decoder = (str: string): string => decodeURIComponent(str);
  const queryParams = qs.parse(query, { ignoreQueryPrefix: true, ...(decode ? [decoder] : []) });

  return mapValues(queryParams, inferType);
};

export const inferType = (value: PrimitiveTypeWithObject): PrimitiveTypeWithObject => {
  if (Array.isArray(value)) {
    return value.map((val) => inferType(val));
  }

  if (typeof value === 'object') {
    return mapValues(value, inferType);
  }

  return inferTypeFromString(value as string);
};

export const inferTypeFromString = (value: string): PrimitiveType => {
  if (value === '') {
    return '';
  }

  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  return value;
};

export const getEmptyState = (): { __empty__: true } => {
  return { __empty__: true };
};

export const hasEmptyState = (queryParams?: QueryParams): boolean => {
  return queryParams?.__empty__ || false;
};
