import type { FC, ReactNode } from 'react';

import { Table, TableColumn } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeTableProps } from '../../components/types';
import { getFreightChargeTableColumns } from './freightChargeTableConfig';

const FreightChargeTable: FC<ChargeTableProps> = ({ charges, onAdd, onUpdate, onDelete }) => {
  const { isEditable } = useRateCardContext();

  const columns: TableColumn<RateCardCharge>[] = getFreightChargeTableColumns({ onDelete, onUpdate }, isEditable);
  const renderActions: ReactNode = isEditable && (
    <ChargeTableActions actionConfig={{ title: 'Set freight charges', type: 'primary' }} isDisabled={false} onAdd={onAdd} />
  );

  return (
    <Table
      actions={renderActions}
      columns={columns}
      data={charges}
      isDraggingEnabled={false}
      tableId="freight-charges"
      title="Freight charges"
      totalCountConfig={{ totalCount: charges.length, entityName: 'item' }}
    />
  );
};

export default FreightChargeTable;
