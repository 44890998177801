import type { FC } from 'react';

import { tradeRoleLabelMapping, tradeRoleOptions } from '@zen/Booking/helpers';
import type { TradeRole } from '@zen/Booking/types';
import LabelledValue from '@zen/Components/LabelledValue';
import { Select } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateTradeRoleMutation } from './graphql';

interface Props {
  canUpdateTradeRole: boolean;
  onSuccess?: () => void;
  tradeRole: Optional<TradeRole>;
  zencargoReference: string;
}

const TradeRoleInlineField: FC<Props> = ({ tradeRole, zencargoReference, onSuccess, canUpdateTradeRole }) => {
  const { addSuccess, addError } = useNotification();
  const [updateTradeRole, { loading }] = useUpdateTradeRoleMutation();

  const label: string = 'Trade role';

  const handleUpdateTradeRole = async (tradeRoleValue: Nullable<TradeRole>): Promise<void> => {
    if (tradeRoleValue) {
      await performMutation({
        mutationFn: () =>
          updateTradeRole({
            awaitRefetchQueries: true,
            refetchQueries: ['bookingSummary'],
            variables: {
              input: {
                zencargoReference,
                tradeRole: tradeRoleValue
              }
            }
          }),
        onError: () => addError(),
        onSuccess: () => {
          onSuccess?.();
          addSuccess('Trade role updated.');
        }
      });
    }
  };

  if (!canUpdateTradeRole) {
    return (
      <LabelledValue label={label} variant="default">
        {tradeRole ? tradeRoleLabelMapping[tradeRole] : '-'}
      </LabelledValue>
    );
  }

  return (
    <div className="-ml-3">
      <Select
        isDisabled={loading}
        isLoading={loading}
        label={label}
        name="tradeRole"
        onChange={handleUpdateTradeRole}
        options={tradeRoleOptions}
        renderMenuInPortal={true}
        value={tradeRole}
        variant="inline"
      />
    </div>
  );
};

export default TradeRoleInlineField;
