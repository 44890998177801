import type { DateWithTimeFields } from '@zen/Components/DateWithTimeForm';
import { formatDate, formatTimeRange } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

export const formatDateWithTime = (dateWithTime: Optional<DateWithTimeFields>): string => {
  if (!dateWithTime?.date) return '';

  const { date, startTime, endTime, time } = dateWithTime;

  const formatttedTime = formatTimeRange(time || startTime, endTime, false);

  return `${formatDate(date)}${formatttedTime ? `, ${formatttedTime}` : ''}`;
};
