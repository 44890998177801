import { bookingModeOfTransportLabelMapping } from '@zen/types';

import { ModeOfTransport, ModeOfTransportOption } from './types';

export const modeOfTransportOptions: ModeOfTransportOption[] = [
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.TRUCK], value: ModeOfTransport.TRUCK },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.OCEAN], value: ModeOfTransport.OCEAN },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.AIR], value: ModeOfTransport.AIR },
  { label: bookingModeOfTransportLabelMapping[ModeOfTransport.RAIL], value: ModeOfTransport.RAIL }
];
