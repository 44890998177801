import type { ActivityFeedItemTypeEnum, ActivityFeedTargetTypeEnum } from '@zen/graphql/types.generated';
import useInfiniteScroll from '@zen/utils/hooks/useInfiniteScroll';

import { GetActivityFeedDocument } from '../graphql';
import type { Activity } from '../types';

const useActivityQuery = (targetId: string, targetType: ActivityFeedTargetTypeEnum, itemTypes?: ActivityFeedItemTypeEnum[]) =>
  useInfiniteScroll<Activity>({
    query: GetActivityFeedDocument,
    variables: {
      targetId,
      targetType,
      itemTypes
    },
    responsePath: 'data.activityFeed.activities',
    dependencies: [targetId],
    isReverse: true
  });

export default useActivityQuery;
