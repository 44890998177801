import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type AuthorizationQueryVariables = GraphQLTypes.Exact<{
  isCreateAccountEnabled?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type AuthorizationQueryResult = { __typename?: 'Query' } & {
  authorization: { __typename?: 'Authorization' } & Pick<GraphQLTypes.Authorization, 'isAdmin'> & {
      agentForwarderAccounts: Array<{ __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid'>>;
      customerUserAccounts: Array<{ __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid'>>;
      manufacturerAccounts: Array<{ __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid'>>;
    };
  globalPermissions: { __typename?: 'GlobalPermissions' } & {
    accounts?: GraphQLTypes.Maybe<
      { __typename?: 'AccountPermissions' } & {
        canCreateBusinessUnits?: GraphQLTypes.Maybe<
          { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
        >;
        canDeactivate?: GraphQLTypes.Maybe<
          { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
        >;
        canEditBusinessUnits?: GraphQLTypes.Maybe<
          { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
        >;
        canSwitch?: GraphQLTypes.Maybe<{ __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>>;
        canViewAccountSettings?: GraphQLTypes.Maybe<
          { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
        >;
        canViewAuthentication?: GraphQLTypes.Maybe<
          { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
        >;
      }
    >;
    bookings?: GraphQLTypes.Maybe<
      { __typename?: 'BookingPermissions' } & {
        canRequest?: GraphQLTypes.Maybe<{ __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>>;
      }
    >;
  };
  legacyGlobalPermissions: { __typename?: 'LegacyGlobalPermissions' } & {
    accounts?: GraphQLTypes.Maybe<
      { __typename?: 'Accounts' } & {
        canSwitch: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    accruals?: GraphQLTypes.Maybe<
      { __typename?: 'Accruals' } & {
        canCreate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    adminPanel?: GraphQLTypes.Maybe<
      { __typename?: 'AdminPanel' } & {
        canView: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    backoffice?: GraphQLTypes.Maybe<
      { __typename?: 'Backoffice' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    bookingRequests?: GraphQLTypes.Maybe<
      { __typename?: 'BookingRequests' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewApprovalRequired: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewManufacturerRequested: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewRejected: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    bookings?: GraphQLTypes.Maybe<
      { __typename?: 'Bookings' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canChangeCustomsOnly: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canChangeMultiStop: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canChangeShipmentType: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canChangeTradeRole: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canChangeTruckSwap: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canCreate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canExport: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canPin: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canRequest: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canQuote: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewCustomers: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewDocumentPermissions: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    consolidation?: GraphQLTypes.Maybe<
      { __typename?: 'Consolidation' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canAddConsolidatedShipment: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canManageConsolidatedShipment: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    customerAdmin?: GraphQLTypes.Maybe<
      { __typename?: 'CustomerAdmin' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    dashboard?: GraphQLTypes.Maybe<
      { __typename?: 'Dashboard' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewActionItems: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    documentCreation?: GraphQLTypes.Maybe<
      { __typename?: 'DocumentCreation' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    freightSpend?: GraphQLTypes.Maybe<
      { __typename?: 'FreightSpend' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    intakePlanning?: GraphQLTypes.Maybe<
      { __typename?: 'IntakePlanning' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    networks?: GraphQLTypes.Maybe<
      { __typename?: 'Networks' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canManage: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    operationsDashboard?: GraphQLTypes.Maybe<
      { __typename?: 'OperationsDashboard' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    operationsSettings?: GraphQLTypes.Maybe<
      { __typename?: 'OperationsSettings' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    packingLists?: GraphQLTypes.Maybe<
      { __typename?: 'PackingLists' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    products?: GraphQLTypes.Maybe<
      { __typename?: 'Products' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    productsCsvImport?: GraphQLTypes.Maybe<
      { __typename?: 'ProductsCsvImport' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canImport: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    productSettings?: GraphQLTypes.Maybe<
      { __typename?: 'ProductSettings' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    purchaseOrders?: GraphQLTypes.Maybe<
      { __typename?: 'PurchaseOrders' } & {
        canCreate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canAssignAgent: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canViewCustomers: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canExport: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    quotes?: GraphQLTypes.Maybe<
      { __typename?: 'Quotes' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    rulesEngine?: GraphQLTypes.Maybe<
      { __typename?: 'RulesEngine' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    settings?: GraphQLTypes.Maybe<
      { __typename?: 'Settings' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        canSetPreferences: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
        notificationSettings?: GraphQLTypes.Maybe<
          { __typename?: 'NotificationSettings' } & {
            booking?: GraphQLTypes.Maybe<
              { __typename?: 'NotificationSettingsBookingType' } & {
                canSetApprovedBookingSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetManufacturerRequestedBookingSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetRequiredCustomerApprovalForBookingSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetActionItemSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetCargoSettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canSetCollectionSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetDeliverySettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canSetIssuesSettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canSetQuoteOptionSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetBookingRequestSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromAgentForwarderSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromCustomerUserSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromForwarderSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromManufacturerSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetSlackSettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canSetEtaSettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              }
            >;
            purchaseOrder?: GraphQLTypes.Maybe<
              { __typename?: 'NotificationSettingsPurchaseOrderType' } & {
                canSetPurchaseOrderCreatedSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetPurchaseOrderClosedSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromAgentForwarderSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromCustomerUserSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromForwarderSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageFromManufacturerSetting: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetTextMessageSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetIssuesSettings: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canSetLotsDeliveryEstimateSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
                canSetLotsCargoReadyDateSettings: { __typename?: 'AuthorizationResult' } & Pick<
                  GraphQLTypes.AuthorizationResult,
                  'value'
                >;
              }
            >;
          }
        >;
      }
    >;
    taskList?: GraphQLTypes.Maybe<
      { __typename?: 'TaskList' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    workflows?: GraphQLTypes.Maybe<
      { __typename?: 'Workflows' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
    zencargoNetwork?: GraphQLTypes.Maybe<
      { __typename?: 'ZencargoNetwork' } & {
        canAccessRoute: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      }
    >;
  };
};

export const AuthorizationQueryDocument = /* #__PURE__ */ gql`
  query authorizationQuery($isCreateAccountEnabled: Boolean = false) {
    authorization {
      agentForwarderAccounts {
        uuid
      }
      customerUserAccounts {
        uuid
      }
      manufacturerAccounts {
        uuid
      }
      isAdmin
    }
    globalPermissions {
      accounts @include(if: $isCreateAccountEnabled) {
        canCreateBusinessUnits {
          value
        }
        canDeactivate {
          value
        }
        canEditBusinessUnits {
          value
        }
        canSwitch {
          value
        }
        canViewAccountSettings {
          value
        }
        canViewAuthentication {
          value
        }
      }
      bookings @include(if: $isCreateAccountEnabled) {
        canRequest {
          value
        }
      }
    }
    legacyGlobalPermissions {
      accounts {
        canSwitch {
          value
        }
        canAccessRoute {
          value
        }
      }
      accruals {
        canCreate {
          value
        }
        canAccessRoute {
          value
        }
      }
      adminPanel {
        canView {
          value
        }
      }
      backoffice {
        canAccessRoute {
          value
        }
      }
      bookingRequests {
        canAccessRoute {
          value
        }
        canViewApprovalRequired {
          value
        }
        canViewManufacturerRequested {
          value
        }
        canViewRejected {
          value
        }
      }
      bookings {
        canAccessRoute {
          value
        }
        canChangeCustomsOnly {
          value
        }
        canChangeMultiStop {
          value
        }
        canChangeShipmentType {
          value
        }
        canChangeTradeRole {
          value
        }
        canChangeTruckSwap {
          value
        }
        canCreate {
          value
        }
        canExport {
          value
        }
        canPin {
          value
        }
        canRequest {
          value
        }
        canQuote {
          value
        }
        canViewCustomers {
          value
        }
        canViewDocumentPermissions {
          value
        }
      }
      consolidation {
        canAccessRoute {
          value
        }
        canAddConsolidatedShipment {
          value
        }
        canManageConsolidatedShipment {
          value
        }
      }
      customerAdmin {
        canAccessRoute {
          value
        }
      }
      dashboard {
        canAccessRoute {
          value
        }
        canViewActionItems {
          value
        }
      }
      documentCreation {
        canAccessRoute {
          value
        }
      }
      freightSpend {
        canAccessRoute {
          value
        }
      }
      intakePlanning {
        canAccessRoute {
          value
        }
      }
      networks {
        canAccessRoute {
          value
        }
        canManage {
          value
        }
      }
      operationsDashboard {
        canAccessRoute {
          value
        }
      }
      operationsSettings {
        canAccessRoute {
          value
        }
      }
      packingLists {
        canAccessRoute {
          value
        }
      }
      products {
        canAccessRoute {
          value
        }
      }
      productsCsvImport {
        canAccessRoute {
          value
        }
        canImport {
          value
        }
      }
      productSettings {
        canAccessRoute {
          value
        }
      }
      purchaseOrders {
        canCreate {
          value
        }
        canAccessRoute {
          value
        }
        canAssignAgent {
          value
        }
        canViewCustomers {
          value
        }
        canExport {
          value
        }
      }
      quotes {
        canAccessRoute {
          value
        }
      }
      rulesEngine {
        canAccessRoute {
          value
        }
      }
      settings {
        canAccessRoute {
          value
        }
        canSetPreferences {
          value
        }
        notificationSettings {
          booking {
            canSetApprovedBookingSetting {
              value
            }
            canSetManufacturerRequestedBookingSetting {
              value
            }
            canSetRequiredCustomerApprovalForBookingSetting {
              value
            }
            canSetActionItemSettings {
              value
            }
            canSetCargoSettings {
              value
            }
            canSetCollectionSettings {
              value
            }
            canSetDeliverySettings {
              value
            }
            canSetIssuesSettings {
              value
            }
            canSetQuoteOptionSettings {
              value
            }
            canSetBookingRequestSettings {
              value
            }
            canSetTextMessageFromAgentForwarderSetting {
              value
            }
            canSetTextMessageFromCustomerUserSetting {
              value
            }
            canSetTextMessageFromForwarderSetting {
              value
            }
            canSetTextMessageFromManufacturerSetting {
              value
            }
            canSetTextMessageSettings {
              value
            }
            canSetSlackSettings {
              value
            }
            canSetEtaSettings {
              value
            }
          }
          purchaseOrder {
            canSetPurchaseOrderCreatedSetting {
              value
            }
            canSetPurchaseOrderClosedSetting {
              value
            }
            canSetTextMessageFromAgentForwarderSetting {
              value
            }
            canSetTextMessageFromCustomerUserSetting {
              value
            }
            canSetTextMessageFromForwarderSetting {
              value
            }
            canSetTextMessageFromManufacturerSetting {
              value
            }
            canSetTextMessageSettings {
              value
            }
            canSetIssuesSettings {
              value
            }
            canSetLotsDeliveryEstimateSettings {
              value
            }
            canSetLotsCargoReadyDateSettings {
              value
            }
          }
        }
      }
      taskList {
        canAccessRoute {
          value
        }
      }
      workflows {
        canAccessRoute {
          value
        }
      }
      zencargoNetwork {
        canAccessRoute {
          value
        }
      }
    }
  }
`;

/**
 * __useAuthorizationQuery__
 *
 * To run a query within a React component, call `useAuthorizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthorizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthorizationQuery({
 *   variables: {
 *      isCreateAccountEnabled: // value for 'isCreateAccountEnabled'
 *   },
 * });
 */
export function useAuthorizationQuery(
  baseOptions?: Apollo.QueryHookOptions<AuthorizationQueryResult, AuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AuthorizationQueryResult, AuthorizationQueryVariables>(AuthorizationQueryDocument, options);
}
export function useAuthorizationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AuthorizationQueryResult, AuthorizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AuthorizationQueryResult, AuthorizationQueryVariables>(AuthorizationQueryDocument, options);
}
export type AuthorizationQueryHookResult = ReturnType<typeof useAuthorizationQuery>;
export type AuthorizationQueryLazyQueryHookResult = ReturnType<typeof useAuthorizationQueryLazyQuery>;
