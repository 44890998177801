import { useState } from 'react';

import SaveQuickFiltersForm from '@zen/Shipments/SaveQuickFiltersForm';
import type { IconName } from '@zen/Styleguide';
import { useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import useTracking from '@zen/utils/hooks/useTracking';

import { QUICK_FILTERS_CATEGORY, QuickFiltersAction } from '../../types';
import QuickFiltersActions from '../QuickFiltersActions';

enum ButtonMode {
  ADD = 'ADD',
  REMOVE = 'REMOVE'
}
interface ButtonConfig {
  icon: IconName;
  label: string;
}

const buttonModes: Record<ButtonMode, ButtonConfig> = {
  [ButtonMode.ADD]: {
    label: 'Save current filters',
    icon: 'zicon-open-heart'
  },
  [ButtonMode.REMOVE]: {
    label: 'Remove filter',
    icon: 'zicon-no-heart'
  }
};

const buttonModeMapper = (isQuickFilterActive: boolean): ButtonConfig => {
  if (isQuickFilterActive) {
    return buttonModes[ButtonMode.REMOVE];
  }

  return buttonModes[ButtonMode.ADD];
};

interface Props<T extends {}> {
  filters: T;
}

const QuickFiltersAddRemove = <T extends {}>({ filters }: Props<T>) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { removeSavedFilter, getActiveSavedFilter } = useSavedFilters();
  const { trackEvent } = useTracking();

  const activeQuickFilter = getActiveSavedFilter(filters);

  const clickHandler = () => {
    if (activeQuickFilter) {
      trackEvent({
        category: QUICK_FILTERS_CATEGORY,
        action: QuickFiltersAction.DELETE_QUICK_FILTER,
        label: activeQuickFilter.name
      });
      removeSavedFilter(activeQuickFilter);
    } else {
      setShowModal(true);
    }
  };

  const handleCancel = (): void => setShowModal(false);

  const { label, icon } = buttonModeMapper(!!activeQuickFilter);

  return (
    <>
      <QuickFiltersActions iconName={icon} label={label} onClick={clickHandler} />
      <SaveQuickFiltersForm filters={filters} onCancel={handleCancel} setShowModal={setShowModal} showModal={showModal} />
    </>
  );
};

export default QuickFiltersAddRemove;
