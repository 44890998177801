import { kebabCase } from 'lodash';

import type { FormInstance } from '@zen/Components/Form';
import { Option, Select, SelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import FormField, { FormFieldProps } from '../FormField';

interface Props<T> extends Omit<SelectProps<T>, 'name' | 'onChange'>, FormFieldProps {
  onChange?: (value: Nullable<T>) => void;
}

const FormSelect = <T extends Nullable<{}>>(props: Props<T>) => {
  const { className, label, ...rest } = props;

  return (
    <FormField className={className} label={label} {...rest}>
      {(field: { error: boolean; label: string; name: string; options: Option<T>[] }, form: FormInstance<unknown>) => {
        const { label: fieldLabel, ...fieldRest } = field;

        return (
          <div data-testid={`select-${kebabCase(field.name)}`}>
            <Select
              {...rest}
              {...fieldRest}
              hasError={field.error}
              onBlur={form.handleBlur}
              onChange={(value: Nullable<T>) => {
                // when the option is being cleared, formik removes the field from the values object
                // and the validation does not work for the field anymore
                // casting undefined to null fixes the issue
                form.setFieldValue(field.name, value || null);

                if (props.onChange) {
                  props.onChange(value);
                }
              }}
            />
          </div>
        );
      }}
    </FormField>
  );
};

export default FormSelect;
