import type { ModeOfTransport } from '@zen/Booking';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyMilestoneWithMetadata } from '../../../types';
import DisabledDateTooltip from '../../DisabledDateTooltip';
import { getMilestoneMinAndMaxDatesAcrossBooking } from '../../JourneyDetails/helpers';
import type { MilestoneDisabledDateProps } from '../../JourneyDetails/types';

export const getDisabledDates = (
  cargoMilestones: Record<string, JourneyMilestoneWithMetadata[]>,
  modeOfTransport: Optional<ModeOfTransport>
): MilestoneDisabledDateProps => {
  const { maxDate } = getMilestoneMinAndMaxDatesAcrossBooking(cargoMilestones, modeOfTransport);

  if (!maxDate?.date) return {};

  return {
    disabledDates: {
      maxDate: maxDate.date
    },
    tooltip: [
      {
        matcher: {
          maxDate: maxDate.date
        },
        message: <DisabledDateTooltip dateConstraint="max" dateType={maxDate.dateType} milestoneName={maxDate.milestoneName} />
      }
    ]
  };
};
