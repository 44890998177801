import { FC, useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import DeleteMemberModal from '../DeleteMemberModal';

interface Props {
  isProfileCompleted: boolean;
  memberId: string;
  memberName: string;
}

const DeleteMemberButton: FC<Props> = ({ isProfileCompleted, memberName, memberId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);
  const title: string = isProfileCompleted ? 'Delete' : 'Revoke invitation';

  return (
    <>
      <DeleteMemberModal
        isOpen={isModalOpen}
        isProfileCompleted={isProfileCompleted}
        memberId={memberId}
        memberName={memberName}
        onClose={toggleModal}
      />
      <IconButton icon="zicon-trash" onClick={toggleModal} size="medium" title={title} variant="ghost" />
    </>
  );
};

export default DeleteMemberButton;
