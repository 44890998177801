import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetAccrualTemplatesQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type FinanceGetAccrualTemplatesQueryResult = { __typename?: 'Query' } & {
  accrualTemplates: { __typename?: 'AccrualTemplateConnection' } & Pick<GraphQLTypes.AccrualTemplateConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'AccrualTemplate' } & Pick<GraphQLTypes.AccrualTemplate, 'id' | 'templateName'>>>
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const FinanceGetAccrualTemplatesDocument = /* #__PURE__ */ gql`
  query financeGetAccrualTemplates($after: String, $before: String, $first: Int, $last: Int, $textContains: String) {
    accrualTemplates(after: $after, before: $before, first: $first, last: $last, textContains: $textContains) {
      nodes {
        id
        templateName
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useFinanceGetAccrualTemplatesQuery__
 *
 * To run a query within a React component, call `useFinanceGetAccrualTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetAccrualTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetAccrualTemplatesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      textContains: // value for 'textContains'
 *   },
 * });
 */
export function useFinanceGetAccrualTemplatesQuery(
  baseOptions?: Apollo.QueryHookOptions<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>(
    FinanceGetAccrualTemplatesDocument,
    options
  );
}
export function useFinanceGetAccrualTemplatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetAccrualTemplatesQueryResult, FinanceGetAccrualTemplatesQueryVariables>(
    FinanceGetAccrualTemplatesDocument,
    options
  );
}
export type FinanceGetAccrualTemplatesQueryHookResult = ReturnType<typeof useFinanceGetAccrualTemplatesQuery>;
export type FinanceGetAccrualTemplatesLazyQueryHookResult = ReturnType<typeof useFinanceGetAccrualTemplatesLazyQuery>;
