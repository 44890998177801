import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateShipmentActionTypeMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateShipmentActionTypeInput;
}>;

export type UpdateShipmentActionTypeMutationResult = { __typename?: 'Mutation' } & {
  shipmentActionTypesUpdateShipmentActionType?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateShipmentActionTypePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      shipmentActionType?: GraphQLTypes.Maybe<
        { __typename?: 'ShipmentActionType' } & Pick<GraphQLTypes.ShipmentActionType, 'id' | 'name' | 'title' | 'description'>
      >;
    }
  >;
};

export const UpdateShipmentActionTypeDocument = /* #__PURE__ */ gql`
  mutation updateShipmentActionType($input: UpdateShipmentActionTypeInput!) {
    shipmentActionTypesUpdateShipmentActionType(input: $input) {
      errors {
        message
        path
      }
      shipmentActionType {
        id
        name
        title
        description
      }
    }
  }
`;

/**
 * __useUpdateShipmentActionTypeMutation__
 *
 * To run a mutation, you first call `useUpdateShipmentActionTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShipmentActionTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShipmentActionTypeMutation, { data, loading, error }] = useUpdateShipmentActionTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShipmentActionTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateShipmentActionTypeMutationResult, UpdateShipmentActionTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateShipmentActionTypeMutationResult, UpdateShipmentActionTypeMutationVariables>(
    UpdateShipmentActionTypeDocument,
    options
  );
}
export type UpdateShipmentActionTypeMutationHookResult = ReturnType<typeof useUpdateShipmentActionTypeMutation>;
