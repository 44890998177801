import { FC, useState } from 'react';

import type { Terminal } from '@zen/Components/TerminalSelect';
import { Modal } from '@zen/DesignSystem';
import type { HaulageChargePayload } from '@zen/RateCards/LegacyRateCards//RateCardForm/reducer/types';
import HaulageForm from '@zen/RateCards/LegacyRateCards/HaulageCharge/HaulageForm';
import HaulageTable from '@zen/RateCards/LegacyRateCards/HaulageCharge/HaulageTable';

interface Props {
  haulageCharges: HaulageChargePayload[];
  onAdd: (values: HaulageChargePayload) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: HaulageChargePayload) => void;
  ports: Terminal[];
  type: 'origin' | 'destination';
}

type ModalConfig = { index?: number; type: 'create' | 'update'; values?: HaulageChargePayload };

const HaulageChargeSection: FC<Props> = ({ onUpdate, onDelete, haulageCharges, onAdd, type, ports }) => {
  const title: string = type === 'origin' ? 'Origin haulage' : 'Destination haulage';
  const [config, setConfig] = useState<ModalConfig>();
  const modalTitle: string = config?.type === 'create' ? 'Add custom charge' : 'Update custom charge';

  const closeModal = (): void => setConfig(undefined);

  const handleUpdateClick = (index: number, values: HaulageChargePayload): void => {
    setConfig({ type: 'update', index, values });
  };

  const handleAddClick = (): void => {
    setConfig({ type: 'create' });
  };

  const handleSubmit = (values: HaulageChargePayload): void => {
    if (config?.type === 'create') {
      onAdd(values);
    }

    if (config?.index !== undefined) {
      onUpdate(config.index, values);
    }
  };

  return (
    <>
      <HaulageTable
        editable={true}
        haulageCharges={haulageCharges}
        onAdd={handleAddClick}
        onDelete={onDelete}
        onUpdate={handleUpdateClick}
        title={title}
        type={type}
      />
      <Modal closeOnClickAway={false} isOpen={!!config} modalOverflowY="scroll" onClose={closeModal} title={modalTitle}>
        <HaulageForm
          initialValues={config?.values}
          onCancel={closeModal}
          onSubmit={handleSubmit}
          onSuccess={closeModal}
          ports={ports}
          type={type}
        />
      </Modal>
    </>
  );
};

export default HaulageChargeSection;
