import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  accountName: string;
  className?: string;
}

const AccountAvatar: FC<Props> = ({ accountName, className }) => {
  const classNames: string = cx(
    'flex items-center justify-center w-7 h-7 rounded text-white bg-azure-base text-sm shrink-0',
    className
  );
  const label: string = accountName.replace(/^The /, '').charAt(0).toUpperCase();

  return (
    <span className={classNames} data-testid="account-avatar">
      {label}
    </span>
  );
};

export type { Props as AccountAvatarProps };
export default AccountAvatar;
