import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GroupedTotalCountQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  groupBy: GraphQLTypes.ShipmentsTableCargoGroupsGroupByColumn;
  searchTerm?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  sortBy?: GraphQLTypes.InputMaybe<
    Array<GraphQLTypes.ShipmentsTableCargoGroupsSortByInput> | GraphQLTypes.ShipmentsTableCargoGroupsSortByInput
  >;
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableCargoGroupsFiltersInput>;
}>;

export type GroupedTotalCountQueryResult = { __typename?: 'Query' } & {
  shipmentsTableCargoGroups?: GraphQLTypes.Maybe<
    { __typename?: 'ShipmentsTableCargoGroupsConnection' } & {
      groupsInfo?: GraphQLTypes.Maybe<
        { __typename?: 'ShipmentsTableGroupsInfo' } & Pick<
          GraphQLTypes.ShipmentsTableGroupsInfo,
          'numberOfGroups' | 'numberOfUngroupedCargos'
        >
      >;
    }
  >;
};

export const GroupedTotalCountDocument = /* #__PURE__ */ gql`
  query groupedTotalCount(
    $accountIds: [String!]!
    $groupBy: ShipmentsTableCargoGroupsGroupByColumn!
    $searchTerm: String
    $sortBy: [ShipmentsTableCargoGroupsSortByInput!]
    $filterBy: ShipmentsTableCargoGroupsFiltersInput
  ) {
    shipmentsTableCargoGroups(
      accountIds: $accountIds
      groupBy: $groupBy
      searchTerm: $searchTerm
      sortBy: $sortBy
      filterBy: $filterBy
    ) {
      groupsInfo {
        numberOfGroups
        numberOfUngroupedCargos
      }
    }
  }
`;

/**
 * __useGroupedTotalCountQuery__
 *
 * To run a query within a React component, call `useGroupedTotalCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupedTotalCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupedTotalCountQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      groupBy: // value for 'groupBy'
 *      searchTerm: // value for 'searchTerm'
 *      sortBy: // value for 'sortBy'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useGroupedTotalCountQuery(
  baseOptions: Apollo.QueryHookOptions<GroupedTotalCountQueryResult, GroupedTotalCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GroupedTotalCountQueryResult, GroupedTotalCountQueryVariables>(GroupedTotalCountDocument, options);
}
export function useGroupedTotalCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GroupedTotalCountQueryResult, GroupedTotalCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GroupedTotalCountQueryResult, GroupedTotalCountQueryVariables>(GroupedTotalCountDocument, options);
}
export type GroupedTotalCountQueryHookResult = ReturnType<typeof useGroupedTotalCountQuery>;
export type GroupedTotalCountLazyQueryHookResult = ReturnType<typeof useGroupedTotalCountLazyQuery>;
