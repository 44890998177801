import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Icon, Pill, type TableColumn } from '@zen/DesignSystem';

import type { Webhook } from './types';

interface TableParams {
  onDelete: (id: string) => void;
  onEdit: (webhook: Webhook) => void;
}

export const getColumns = ({ onEdit, onDelete }: TableParams): TableColumn<Webhook>[] => [
  {
    key: 'name',
    title: 'Name',
    sortable: false,
    width: 300
  },
  {
    key: 'callbackUrl',
    title: 'Callback URL',
    render: (_, { callbackUrl }: Webhook) => <div className="font-mono text-xs">{callbackUrl}</div>,
    sortable: false
  },
  {
    key: 'status',
    title: 'Status',
    render: (_, { isDisabled }: Webhook) => (
      <Pill type={isDisabled ? 'problem' : 'success'}>{isDisabled ? 'Disabled' : 'Enabled'}</Pill>
    ),
    sortable: false
  },
  {
    key: 'actions',
    title: '',
    className: 'text-center',
    sortable: false,
    fixed: 'right',
    width: 110,
    render: (_, webhook) => {
      return (
        <div className="flex space-x-2">
          <Icon icon="zicon-edit" interactive={true} onClick={() => onEdit(webhook)} />
          <WithConfirmationModal
            confirmLabel="Delete"
            header="Delete Webhook?"
            message="Removing this means you will no longer be notified about the topics you subscribed to. This action cannot be undone."
            onConfirm={() => onDelete(webhook.id)}
          >
            {({ requestConfirm }) => <Icon icon="zicon-trash" interactive={true} onClick={requestConfirm} />}
          </WithConfirmationModal>
        </div>
      );
    }
  }
];
