import type { FC } from 'react';

import useAuth from '@zen/Auth/hooks/useAuth';
import EmptyState from '@zen/Components/EmptyState';

import image from './container.svg';

interface Props {
  message: string;
}

const RouteError: FC<Props> = ({ message = '' }) => {
  const { logout } = useAuth();

  return (
    <div className="fixed inset-0 flex items-center justify-center" data-testid="error-page">
      <EmptyState
        illustration={image}
        primaryButton={{ onClick: () => logout(), title: 'Return to login' }}
        subTitle={message}
        title="Login failed"
      />
    </div>
  );
};

export default RouteError;
