import type { FC } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';

import BookingSummaryLoadingSkeleton from '../../components/skeletons/BookingSummaryLoadingSkeleton';
import { useBookingSummaryQuery } from '../graphql';
import BookingSummary from './components/BookingSummary';
import RouteSummary from './components/RouteSummary';
import type { BookingSummaryData } from './types';

interface Props {
  canViewForwarder: boolean;
  zencargoReference: string;
}

const BookingSummaryContainer: FC<Props> = ({ canViewForwarder, zencargoReference }) => {
  const { data, loading, error, refetch } = useBookingSummaryQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { zencargoReference }
  });

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<BookingSummaryLoadingSkeleton />}
    >
      {(booking: BookingSummaryData) => {
        return (
          <div className="relative flex flex-col-reverse 2xl:flex-row">
            <RouteSummary booking={booking} onBookingCompleted={refetch} />
            <BookingSummary booking={booking} canViewForwarder={canViewForwarder} />
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default BookingSummaryContainer;
