import { checkPermission } from '@zen/Auth/authHelper';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, useUsedAssignables } from '@zen/Networks';
import { useControlTowerPermissionsQuery, useGetShipmentFiltersQuery } from '@zen/Shipments/graphql';
import type { ShipmentsFilter } from '@zen/Shipments/types';
import useAccount from '@zen/utils/hooks/useAccount';

export const useShipmentFiltersData = () => {
  const { accountUuid: accountId } = useAccount();
  const domainName = AssignmentTargetTypeEnum.BOOKING;

  const { data } = useGetShipmentFiltersQuery({
    variables: accountId ? { accountUuid: accountId } : undefined,
    fetchPolicy: 'no-cache'
  });

  const { data: controlTowerPermissions } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId },
    fetchPolicy: 'no-cache'
  });

  const { data: origins = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.ORIGIN]);
  const { data: destinations = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.DESTINATION]);
  const { data: consignees = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.CONSIGNEE]);
  const { data: consignors = [] } = useUsedAssignables([domainName], [AssignmentTypeValue.CONSIGNOR]);

  const controlTower = controlTowerPermissions?.legacyAccount?.controlTower;

  const canViewForwarder: boolean = controlTower
    ? checkPermission<typeof controlTower>(controlTower, 'canViewForwardersOnShipments')
    : false;

  const filterOptions: ShipmentsFilter | undefined = data?.filters?.shipments as ShipmentsFilter | undefined;

  return { networks: { origins, destinations, consignees, consignors }, filterOptions, permissions: { canViewForwarder } };
};
