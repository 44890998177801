import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAssignedLotsToBookingMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAssignedLotsToBookingInput;
}>;

export type UpdateAssignedLotsToBookingMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersUpdateAssignedLotsToBooking?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAssignedLotsToBookingPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAssignedLotsToBookingDocument = /* #__PURE__ */ gql`
  mutation updateAssignedLotsToBooking($input: UpdateAssignedLotsToBookingInput!) {
    purchaseOrdersUpdateAssignedLotsToBooking(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAssignedLotsToBookingMutation__
 *
 * To run a mutation, you first call `useUpdateAssignedLotsToBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignedLotsToBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignedLotsToBookingMutation, { data, loading, error }] = useUpdateAssignedLotsToBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAssignedLotsToBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAssignedLotsToBookingMutationResult,
    UpdateAssignedLotsToBookingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAssignedLotsToBookingMutationResult, UpdateAssignedLotsToBookingMutationVariables>(
    UpdateAssignedLotsToBookingDocument,
    options
  );
}
export type UpdateAssignedLotsToBookingMutationHookResult = ReturnType<typeof useUpdateAssignedLotsToBookingMutation>;
