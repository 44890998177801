import type { Nullable } from '@zen/utils/typescript';

import { MissingRoadTrackingInputData, RoadTrackingStatusReason } from './types';

export const missingDataPointLabelMapping: Record<MissingRoadTrackingInputData, string> = {
  [MissingRoadTrackingInputData.APPOINTMENT_WINDOW]: 'collection and delivery date and time',
  [MissingRoadTrackingInputData.HAULIER_ID]: 'haulier ID',
  [MissingRoadTrackingInputData.LICENSE_PLATE]: 'plate number',
  [MissingRoadTrackingInputData.SHIPMENT_IDENTIFIER]: 'shipment identifier',
  [MissingRoadTrackingInputData.SHIPMENT_STOP_GEO_COORDINATES]: 'collection or delivery latitude and longitude',
  [MissingRoadTrackingInputData.SHIPMENT_STOP_LOCATION]: 'collection or delivery location',
  [MissingRoadTrackingInputData.TRAILER_ID]: 'trailer ID'
};

export const trackingStatusLabelMapping: Record<RoadTrackingStatusReason, Nullable<string>> = {
  [RoadTrackingStatusReason.ACQUIRING_LOCATION]: 'we are still acquiring the location of the vehicle.',
  [RoadTrackingStatusReason.APPROVAL_DENIED]: 'the haulier has denied tracking.’',
  [RoadTrackingStatusReason.ARRIVED_FINAL_STOP]: null,
  [RoadTrackingStatusReason.CANCELED]: 'tracking has been canceled.',
  [RoadTrackingStatusReason.DATA_ENTERED_TOO_LATE]: 'data was entered too late.',
  [RoadTrackingStatusReason.DEPARTED_FINAL_STOP]: null,
  [RoadTrackingStatusReason.FAILED_TO_ACQUIRE_LOCATION]: 'we were not able to acquire the location.',
  [RoadTrackingStatusReason.HAULIER_NOT_ONBOARDED]: 'the haulier is not onboarded.',
  [RoadTrackingStatusReason.IDLE]: null,
  [RoadTrackingStatusReason.INFO]: null,
  [RoadTrackingStatusReason.IN_MOTION]: null,
  [RoadTrackingStatusReason.PENDING_APPROVAL]: 'tracking is pending haulier’s approval.',
  [RoadTrackingStatusReason.PENDING_CARRIER]: 'the haulier is not valid.',
  [RoadTrackingStatusReason.PENDING_TRACKING_METHOD]: null,
  [RoadTrackingStatusReason.SCHEDULED]: null,
  [RoadTrackingStatusReason.TIMED_OUT]:
    "the vehicle has not been recorded departing the final stop, and it is too far past the final stop's appointment window to continue tracking.",
  [RoadTrackingStatusReason.UNKNOWN]: 'of an unknown reason.',
  [RoadTrackingStatusReason.UNKNOWN_VEHICLE]: 'the plate number was not recognised'
};
