import * as Yup from 'yup';

import type { Optional } from '@zen/utils/typescript';

import type { OceanChargeRow } from './types';

const isCSVColumnSelected = (values: OceanChargeRow, selectedColumn: string): boolean => {
  const matchingValuesList: string[] = Object.values(values).filter((value: string) => value === selectedColumn);

  return matchingValuesList.length > 1;
};
const duplicatedColumnError: string = 'Column has been selected. Please select another column';

const applicabilityValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(applicability: Optional<string>) {
    if (!applicability) {
      return this.createError({ message: 'Please select an applicability column.' });
    }

    if (isCSVColumnSelected(this.parent, applicability)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

const chargeTypeIdValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(chargeTypeId: Optional<string>) {
    if (!chargeTypeId) {
      return this.createError({ message: 'Please select a charge type column.' });
    }

    if (isCSVColumnSelected(this.parent, chargeTypeId)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

const currencyValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(currency: Optional<string>) {
    if (!currency) {
      return this.createError({ message: 'Please select a currency column.' });
    }

    if (isCSVColumnSelected(this.parent, currency)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

const itemNameValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(itemName: Optional<string>) {
    if (!itemName) {
      return this.createError({ message: 'Please select an item name column.' });
    }

    if (isCSVColumnSelected(this.parent, itemName)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

const locationIdValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(locationId: Optional<string>) {
    if (!locationId) {
      return this.createError({ message: 'Please select a port unlocode column.' });
    }

    if (isCSVColumnSelected(this.parent, locationId)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

const valueValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(value: Optional<string>) {
    if (!value) {
      return this.createError({ message: 'Please select a unit price column.' });
    }

    if (isCSVColumnSelected(this.parent, value)) {
      return this.createError({ message: duplicatedColumnError });
    }

    return true;
  });

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  applicability: applicabilityValidator,
  chargeTypeId: chargeTypeIdValidator,
  currency: currencyValidator,
  itemName: itemNameValidator,
  locationId: locationIdValidator,
  value: valueValidator
});
