import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import CollapsibleSection from '@zen/Components/CollapsibleSection';

import MilestoneDelay from '../MilestoneDelay';
import type { JourneyShippingMilestoneDelay } from './types';

interface Props {
  canManageDelays: boolean;
  delays: JourneyShippingMilestoneDelay[];
}

const MilestoneDelays: FC<Props> = ({ canManageDelays, delays }) => {
  const hasMultipleDelays: boolean = delays.length > 1;

  const renderDelay = ({ id, days, reason }: JourneyShippingMilestoneDelay, index: number): ReactNode => {
    const className = cx('border-t border-solid border-grey-lighter', {
      'border-t-0 rounded-tl rounded-tr': index === 0,
      'rounded-bl rounded-br': delays.length - 1 === index,
      'bg-grey-lightest': delays.length > 1 || (delays.length === 1 && delays[0].reason)
    });

    return (
      <MilestoneDelay
        key={id}
        canManageDelays={canManageDelays}
        className={className}
        delayId={id}
        delayInDays={days}
        delayReasonId={reason?.id}
        isDelayInDaysVisible={hasMultipleDelays}
      />
    );
  };

  if (delays.length === 0) {
    return null;
  }

  return (
    <CollapsibleSection
      className="my-1"
      collapseLabel="Show less"
      controlClassNames="flex justify-end mt-1"
      expandLabel="Show more"
      initialDisplayedItems={1}
      renderItems={delays.map(renderDelay)}
    />
  );
};

export default MilestoneDelays;
