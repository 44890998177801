import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpsertUserSettingMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpsertUserSettingsInput;
}>;

export type UpsertUserSettingMutationResult = { __typename?: 'Mutation' } & {
  upsertUserSettings?: GraphQLTypes.Maybe<
    { __typename?: 'UpsertUserSettingsPayload' } & {
      userSettings?: GraphQLTypes.Maybe<{ __typename?: 'UserSettings' } & Pick<GraphQLTypes.UserSettings, 'name' | 'value'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpsertUserSettingDocument = /* #__PURE__ */ gql`
  mutation upsertUserSetting($input: UpsertUserSettingsInput!) {
    upsertUserSettings(input: $input) {
      userSettings {
        name
        value
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpsertUserSettingMutation__
 *
 * To run a mutation, you first call `useUpsertUserSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserSettingMutation, { data, loading, error }] = useUpsertUserSettingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserSettingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpsertUserSettingMutationResult, UpsertUserSettingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpsertUserSettingMutationResult, UpsertUserSettingMutationVariables>(
    UpsertUserSettingDocument,
    options
  );
}
export type UpsertUserSettingMutationHookResult = ReturnType<typeof useUpsertUserSettingMutation>;
