import { get } from 'lodash';
import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';

import { AsyncSelect, AsyncSelectProps } from '@zen/DesignSystem';
import { SortingOrder } from '@zen/types';

import { highlightQuery } from '../Form/utils';
import { useContactListLazyQuery } from './graphql';
import type { Contact, ContactTypeEnum } from './types';

interface Props extends Omit<AsyncSelectProps<Contact>, 'formatOptionLabel' | 'loadOptions'> {
  contactTypes?: ContactTypeEnum[];
}

const ContactInput: FC<Props> = (props) => {
  const [getContactList] = useContactListLazyQuery();

  const handleChange = async (searchQuery: string): Promise<Contact[]> => {
    const { data } = await getContactList({
      variables: {
        order: {
          field: 'name',
          direction: SortingOrder.ASC
        },
        searchQuery,
        types: props.contactTypes
      }
    });

    return get(data, 'contacts.nodes', []);
  };

  const debouncedHandleInputChange = pDebounce(handleChange, 300);

  const getOptionLabel = (contact: Contact, inputValue: string): ReactNode => highlightQuery(contact.name, inputValue);

  return <AsyncSelect<Contact> {...props} formatOptionLabel={getOptionLabel} loadOptions={debouncedHandleInputChange} />;
};

export default ContactInput;
