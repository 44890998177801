import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';

import { cargoRiskLevelLabelMapping } from '../../helpers';
import { RiskLevelsEnum } from '../../types';

interface Props extends FormFieldProps {
  isDisabled?: boolean;
}

const FormCargoRiskSelect: FC<Props> = (props) => {
  const riskLevelOptions: Option<RiskLevelsEnum>[] = [
    {
      label: cargoRiskLevelLabelMapping[RiskLevelsEnum.LOW].title,
      value: RiskLevelsEnum.LOW
    },
    {
      label: cargoRiskLevelLabelMapping[RiskLevelsEnum.MEDIUM].title,
      value: RiskLevelsEnum.MEDIUM
    },
    {
      label: cargoRiskLevelLabelMapping[RiskLevelsEnum.HIGH].title,
      value: RiskLevelsEnum.HIGH
    }
  ];

  return <FormSelect isClearable={true} options={riskLevelOptions} renderMenuInPortal={true} {...props} />;
};

export default FormCargoRiskSelect;
