import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { TerminalTypeValue } from '@zen/Networks/types';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  terminalType: TerminalTypeValue;
}

const TerminalIcon: FC<Props> = ({ terminalType, className = '' }) => {
  const iconName: Record<TerminalTypeValue, IconName> = {
    [TerminalTypeValue.RAIL_TERMINAL]: 'zicon-rail',
    [TerminalTypeValue.SEAPORT]: 'zicon-ship',
    [TerminalTypeValue.AIRPORT]: 'zicon-plane-up',
    [TerminalTypeValue.ROAD_TERMINAL]: 'zicon-road'
  };

  const wrapperClassNames = `p-0.5 flex justify-center items-center rounded-full ${className}`;

  return (
    <div className={wrapperClassNames}>
      <Icon className="text-xs" icon={iconName[terminalType]} />
    </div>
  );
};

export type { Props as TerminalIconProps };

export default TerminalIcon;
