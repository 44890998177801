import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, FormButtons, FormInstance, FormSelect } from '@zen/Components/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { Option } from '@zen/DesignSystem';
import { Button, Modal } from '@zen/DesignSystem';
import { bookingRequestRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useBookingRequestRejectMutation } from '../graphql';
import { RejectedReasonEnum, rejectedReasonLabelMapping, RejectInput } from '../types';
import { validationSchema } from './bookingRequestRejectModal.validation';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  zencargoReference: string;
}

interface FormInitialValues {
  rejectedAdditionalInfo: string;
  rejectedReason: Nullable<RejectedReasonEnum>;
}

interface FormValues {
  rejectedAdditionalInfo: string;
  rejectedReason: RejectedReasonEnum;
}

const BookingRequestRejectModal: FC<Props> = (props) => {
  const { onClose, isOpen, zencargoReference } = props;
  const { addError, addSuccess } = useNotification();
  const navigate = useNavigate();

  const [rejectBookingRequest] = useBookingRequestRejectMutation();

  const handleReject = async (values: FormValues): Promise<IOkOrErrorResult> => {
    const input: RejectInput = {
      zencargoReference,
      ...values
    };

    return performMutation({
      mutationFn: () => rejectBookingRequest({ variables: { input } }),
      onError: () => addError(),
      onSuccess: () => addSuccess('Booking request rejected.')
    });
  };

  const options: Option<RejectedReasonEnum>[] = Object.values(RejectedReasonEnum)
    .map(
      (value: RejectedReasonEnum): Option<RejectedReasonEnum> => ({
        value,
        label: rejectedReasonLabelMapping[value]
      })
    )
    .sort();

  const handleSuccess = (): void => {
    navigate(bookingRequestRoutes.rejected.getUrl());
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<FormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Reject booking">
      <Button onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const initialValues: FormInitialValues = {
    rejectedReason: null,
    rejectedAdditionalInfo: ''
  };

  return (
    <Modal isOpen={isOpen} modalOverflowY="visible" onClose={onClose} title="Reject booking request">
      <Form
        formButtons={renderFormButtons}
        formName="BookingRequestRejectForm"
        initialValues={initialValues}
        onSubmit={handleReject}
        onSuccess={handleSuccess}
        validationSchema={validationSchema}
      >
        {(): ReactNode => (
          <>
            <FormSelect label="Select a reason for rejection" name="rejectedReason" options={options} />
            <FormTextarea label="Add additional information" name="rejectedAdditionalInfo" />
          </>
        )}
      </Form>
    </Modal>
  );
};

export default BookingRequestRejectModal;
