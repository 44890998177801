import { FC, useEffect } from 'react';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import useAccount from '@zen/utils/hooks/useAccount';

interface Props {
  accountIdForUrl: string;
}

const AutomaticAccountSwitcher: FC<Props> = ({ accountIdForUrl }) => {
  const { accountUuid } = useAccount();
  const { switchToAccount } = useSwitchAccount();
  const { check } = useGlobalPermissions();

  const canSwitchAccount = check('accounts.canSwitch');

  useEffect(() => {
    const shouldSwitchAccount: boolean = canSwitchAccount && accountIdForUrl !== accountUuid;

    if (shouldSwitchAccount) {
      switchToAccount(accountIdForUrl);
    }
  });

  return null;
};

export default AutomaticAccountSwitcher;
