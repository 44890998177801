import type { FC, ReactNode } from 'react';

import TimeZoneIcon from '@zen/Components/TimeZoneIcon';
import { Popover, PopoverArguments, Tooltip } from '@zen/DesignSystem';
import { getClassNames } from '@zen/Journey/components/EditableDate/helpers';
import { formatDatesAndTimeZones } from '@zen/Journey/components/MilestoneDates/helpers';
import { getDatesTooltipLabel } from '@zen/Journey/components/MilestoneDatesDialog/helpers';
import MilestoneInitialEstimate from '@zen/Journey/components/MilestoneInitialEstimate';
import {
  type JourneyManageDatesReasonEnum,
  JourneyStopActionEnum,
  type MilestoneDatesType,
  ShippingMilestoneDateTypeEnum
} from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import type {
  CargoJourneysSummaryCargoReadyDateMilestone,
  JourneyShippingCargoReadyDateMilestoneDatesValidation,
  ModeOfTransport
} from '../../types';
import CargoReadyDatePicker from '../CargoReadyDatePicker';

interface Props {
  bookingModeOfTransport: Optional<ModeOfTransport>;
  milestone: CargoJourneysSummaryCargoReadyDateMilestone;
  zencargoReference: string;
}

const CargoReadyDateDetails: FC<Props> = ({ bookingModeOfTransport, milestone, zencargoReference }) => {
  const { delayInDays, datesValidation } = milestone;

  const dates: MilestoneDatesType = {
    actual: null,
    initialEstimate: milestone.initialEstimate,
    latestEstimate: milestone.latestEstimate
  };

  const { initialEstimate, latestEstimate } = formatDatesAndTimeZones(dates);

  const hasDelay: boolean = delayInDays !== 0;
  const hasEstimatedDate: boolean = !!latestEstimate?.date;
  const date = milestone.latestEstimate?.startDateTime.date;
  const hasDate: boolean = !!date;
  const canManageDates: boolean = !!milestone.canManageDates;
  const canManageDatesCurrently: boolean = !!milestone?.canManageDatesCurrently?.value;
  const canManageDatesCurrentlyReason: Optional<JourneyManageDatesReasonEnum> = milestone.canManageDatesCurrently?.reason;
  const isDateEditable: boolean = canManageDates && canManageDatesCurrently;

  const renderOriginalDate = (): ReactNode => {
    if (!hasDelay) return;

    return <MilestoneInitialEstimate delayInDays={delayInDays} initialEstimate={initialEstimate?.date} />;
  };

  const renderTimeZone = (): ReactNode => {
    return (
      <TimeZoneIcon
        className="mr-2 opacity-0 group-hover:opacity-100"
        date={latestEstimate?.date}
        size="small"
        timeZone={latestEstimate?.timeZone}
      />
    );
  };

  const renderDateDetailsAndPicker = (): ReactNode => {
    const handlePopover = ({ close }: PopoverArguments): ReactNode => {
      return (
        <CargoReadyDatePicker
          bookingModeOfTransport={bookingModeOfTransport}
          date={date}
          datesValidation={datesValidation as JourneyShippingCargoReadyDateMilestoneDatesValidation}
          onChange={close}
          zencargoReference={zencargoReference}
        />
      );
    };

    return (
      <Popover disabled={!isDateEditable} popover={handlePopover} renderInPortal={true}>
        {({ isPopoverVisible }) => {
          const classNames: string = getClassNames({
            dateType: ShippingMilestoneDateTypeEnum.PLANNED,
            hasDate,
            hasDelay,
            canManageDates,
            canManageDatesCurrently,
            isPopoverVisible
          });

          const cargoReadyDate: ReactNode = (
            <div className={classNames}>{hasEstimatedDate ? latestEstimate?.date : 'pending estimate'}</div>
          );

          if (canManageDates && canManageDatesCurrentlyReason) {
            return (
              <Tooltip tooltipContent={getDatesTooltipLabel(canManageDatesCurrentlyReason, JourneyStopActionEnum.COLLECTION)}>
                {cargoReadyDate}
              </Tooltip>
            );
          }

          return cargoReadyDate;
        }}
      </Popover>
    );
  };

  const estimateDateNode: ReactNode = (
    <>
      <div className="flex items-center group">
        {renderTimeZone()}
        {renderDateDetailsAndPicker()}
      </div>
      {renderOriginalDate()}
    </>
  );

  return (
    <div className="flex flex-col items-end pr-6 text-xs min-w-56" data-testid="cargo-ready-date-details">
      {hasEstimatedDate ? estimateDateNode : renderDateDetailsAndPicker()}
    </div>
  );
};

export default CargoReadyDateDetails;
