import { ModeOfTransport } from '@zen/Booking';
import { bookingModeOfTransportLabelMapping, type SVGComponent } from '@zen/types';

import { ReactComponent as Air } from './images/air.svg';
import { ReactComponent as Ocean } from './images/ocean.svg';
import { ReactComponent as Rail } from './images/rail.svg';
import { ReactComponent as Road } from './images/road.svg';

interface ImageConfig {
  alt: string;
  imageComponent: SVGComponent;
}

export const modeOfTransportIconMapping: Record<ModeOfTransport, ImageConfig> = {
  [ModeOfTransport.AIR]: {
    imageComponent: Air,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.AIR]
  },
  [ModeOfTransport.OCEAN]: {
    imageComponent: Ocean,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.OCEAN]
  },
  [ModeOfTransport.RAIL]: {
    imageComponent: Rail,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.RAIL]
  },
  [ModeOfTransport.TRUCK]: {
    imageComponent: Road,
    alt: bookingModeOfTransportLabelMapping[ModeOfTransport.TRUCK]
  }
};
