import cx from 'classnames';
import { upperFirst } from 'lodash';

import { FormHiddenInput, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';

import FormNumberInput from '../FormNumberInput';

interface Props<T> {
  className?: string;
  hasUnit?: boolean;
  hideErrorMessage?: boolean;
  initialDimensionsDisabled?: boolean;
  isDisabled?: boolean;
  name: string;
  options: Option<T>[];
  thirdDimensionName?: string;
  variant?: 'narrow' | 'wide';
}

const FormDimensionsInput = <T extends {}>({
  className,
  hideErrorMessage,
  hasUnit = false,
  thirdDimensionName = 'height',
  isDisabled,
  initialDimensionsDisabled,
  name,
  options,
  variant = 'narrow'
}: Props<T>) => {
  const classNames: string = cx(
    {
      'gap-x-4': variant === 'narrow',
      'gap-x-6': variant === 'wide'
    },
    'grid grid-cols-2'
  );
  const wrapperClassNames: string = cx(classNames, className);
  const unitFieldName: string = hasUnit ? `${name}.unit` : `${name}.metric`;

  return (
    <div data-testid="dimensions">
      <div className={wrapperClassNames}>
        <div className={classNames}>
          <FormNumberInput
            disabled={initialDimensionsDisabled || isDisabled}
            hideErrorMessage={hideErrorMessage}
            label="Length"
            min={0}
            name={`${name}.length`}
            placeholder="0.0"
            step={1}
          />
          <FormNumberInput
            disabled={initialDimensionsDisabled || isDisabled}
            hideErrorMessage={hideErrorMessage}
            label="Width"
            min={0}
            name={`${name}.width`}
            placeholder="0.0"
            step={1}
          />
        </div>
        <div className="flex items-end space-x-1">
          <FormNumberInput
            className="flex-1"
            disabled={isDisabled}
            hideErrorMessage={hideErrorMessage}
            label={upperFirst(thirdDimensionName)}
            min={0}
            name={`${name}.${thirdDimensionName}`}
            placeholder="0.0"
            step={1}
          />
          <FormSelect
            hideErrorMessage={hideErrorMessage}
            hideLabel={true}
            isDisabled={initialDimensionsDisabled || isDisabled}
            isSearchable={false}
            name={unitFieldName}
            options={options}
            placeholder="cm"
            renderMenuInPortal={true}
          />
        </div>
      </div>
      <div className="mb-4 -mt-4">
        <FormHiddenInput name={`${name}.length`} />
        <FormHiddenInput name={`${name}.width`} />
        <FormHiddenInput name={`${name}.${thirdDimensionName}`} />
      </div>
    </div>
  );
};

export type { Props as FormDimensionsInputProps };

export default FormDimensionsInput;
