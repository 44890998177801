import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { ContextType } from './types';

const initialContext: ContextType = {
  addItems: (bulkItemKey: string, itemsToAdd: string[]) => {},
  clearBulkSelection: () => {},
  getItems: (bulkItemKey: string) => [],
  removeItems: (bulkItemKey: string, itemsToRemove?: string[]) => []
};

const BulkSelectionContext = createContext<ContextType>(initialContext);

export const useBulkSelectionContext = (): ContextType => {
  const bulkSelection: ContextType = useContextOrThrowError<ContextType>(
    BulkSelectionContext,
    'useBulkSelectionContext must be used inside RoleProvider'
  );

  return bulkSelection;
};

export default BulkSelectionContext;
