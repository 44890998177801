import { get } from 'lodash';
import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveAccountConnectionMutation } from '../../graphql';
import type { AccountConnectionTypeEnum } from '../../types';

interface Props {
  connectionType: AccountConnectionTypeEnum;
  isOpen: boolean;
  onClose: () => void;
  sourceAccountId: string;
  targetAccountId: string;
  targetAccountName: string;
}

const DeleteConnectionModal: FC<Props> = (props) => {
  const { isOpen, onClose, connectionType, sourceAccountId, targetAccountId, targetAccountName } = props;
  const { addError, addSuccess } = useNotification();
  const [archiveConnection, { loading }] = useArchiveAccountConnectionMutation({ refetchQueries: ['getAccountConnections'] });

  const handleArchiveConnection = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        archiveConnection({
          variables: {
            input: {
              connectionType,
              sourceAccountId,
              targetAccountId
            }
          }
        }),
      onError: (errors) =>
        addError(get(errors, '[0].message', 'The connection could not be deleted.'), {
          label: 'Try again',
          onClick: () => handleArchiveConnection()
        }),
      onSuccess: () => {
        addSuccess('Connection was successfully deleted.');
        onClose();
      }
    });
  };

  return (
    <Dialog
      confirmLabel="Delete"
      header={`Delete '${targetAccountName}'?`}
      isLoading={loading}
      isOpen={isOpen}
      message={`Are you sure you want to remove the connection to '${targetAccountName}'?`}
      onClose={onClose}
      onConfirm={handleArchiveConnection}
    />
  );
};

export default DeleteConnectionModal;
export type { Props as DeleteConnectionModalProps };
