import { object, ObjectSchema, string } from 'yup';

import { endTimeValidator, startTimeValidator, timeValidator } from '@zen/utils/validation';

import { TimeOption } from './types';

export const dateWithTimeValidationSchema: ObjectSchema<{}> = object().shape({
  date: string().nullable().required('Please select a date.'),
  time: string()
    .nullable()
    .when('timeOption', {
      is: (value: TimeOption) => value === TimeOption.POINT_IN_TIME,
      then: () => timeValidator
    }),
  startTime: string()
    .nullable()
    .when('timeOption', {
      is: (value: TimeOption) => value === TimeOption.TIME_RANGE,
      then: () => startTimeValidator.required('Please enter start time.')
    }),
  endTime: string()
    .nullable()
    .when('timeOption', {
      is: (value: TimeOption) => value === TimeOption.TIME_RANGE,
      then: () => endTimeValidator.required('Please enter end time.')
    })
});
