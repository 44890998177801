/*
LanguageTags should follow the RFC outlining their use,
for example: 'en-GB', 'en-US', 'fr-FR'
https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
https://datatracker.ietf.org/doc/html/rfc5646
*/
export enum LanguageTag {
  AmericanEnglish = 'en-US',
  BritishEnglish = 'en-GB'
}

/*
We decided to avoid incremental localisation, but we still want to
build components with an eye to implementing it later.

For now this function returns british english as this is ZC's home base.

This function can be imported when we'd like to use a user's
browser language later but don't have localisation yet as
it gives us an easy way to find all instances of this hardcoding
when we localise completely.
*/
export const browserLanguage = (): LanguageTag => LanguageTag.BritishEnglish;
