const REQUESTS_RELATIVE_INDEX_PATH = 'booking-requests';

export const REQUESTS_ROUTE_PREFIX = `/dashboard/${REQUESTS_RELATIVE_INDEX_PATH}`;

interface BookingRequestRoutes {
  manufacturerRequestOrderDetails: {
    getUrl: (orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  manufacturerRequests: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  manufacturerRequestsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  readyForApproval: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  readyForApprovalDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  readyForApprovalOrderDetails: {
    getUrl: (orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  rejected: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  rejectedDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  rejectedOrderDetails: {
    getUrl: (orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  requestIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const bookingRequestRoutes: BookingRequestRoutes = {
  manufacturerRequestOrderDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/:id/*`,
    relativePath: ':id/*',
    getUrl: (orderId: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/${orderId}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  manufacturerRequests: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/*`,
    relativePath: 'manufacturer-requests/*',
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests`
  },
  manufacturerRequestsDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/details/:id/*`,
    relativePath: `${REQUESTS_RELATIVE_INDEX_PATH}/manufacturer-requests/details/:id/*`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/manufacturer-requests/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  readyForApproval: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/*`,
    relativePath: 'ready-for-approval/*',
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/ready-for-approval`
  },
  readyForApprovalDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/details/:id/*`,
    relativePath: `${REQUESTS_RELATIVE_INDEX_PATH}/ready-for-approval/details/:id/*`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  readyForApprovalOrderDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/:id/*`,
    relativePath: ':id/*',
    getUrl: (orderId: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/ready-for-approval/${orderId}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  rejected: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected/*`,
    relativePath: 'rejected/*',
    getUrl: () => `${REQUESTS_ROUTE_PREFIX}/rejected`
  },
  rejectedDetailsPage: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected/details/:id/:sectionName?`,
    relativePath: `${REQUESTS_RELATIVE_INDEX_PATH}/rejected/details/:id/:sectionName?`,
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${REQUESTS_ROUTE_PREFIX}/rejected/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  rejectedOrderDetails: {
    path: `${REQUESTS_ROUTE_PREFIX}/rejected/:id`,
    relativePath: ':id/*',
    getUrl: (orderId: string, tab?: string): string => {
      const url: string = `${REQUESTS_ROUTE_PREFIX}/rejected/${orderId}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  requestIndex: {
    path: `${REQUESTS_ROUTE_PREFIX}/*`,
    getUrl: () => REQUESTS_ROUTE_PREFIX,
    relativePath: `${REQUESTS_RELATIVE_INDEX_PATH}/*`
  }
};

export default bookingRequestRoutes;
