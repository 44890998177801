import { capitalize } from 'lodash';
import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';

import type { BusinessUnit } from '../types';
import EditBusinessUnitButton from './EditBusinessUnitButton';

const getColumnsConfiguration = (permissions: { canEditBusinessUnits: boolean }): TableColumn<BusinessUnit>[] => {
  const tradingNameColumn: TableColumn<BusinessUnit> = {
    key: 'tradingName',
    render: (_, { tradingName }): ReactNode => {
      return <div>{tradingName}</div>;
    },
    sortable: true,
    title: 'Business unit'
  };

  const typeColumn: TableColumn<BusinessUnit> = {
    key: 'type',
    sortable: false,
    alignment: 'left',
    title: 'Type',
    render: (_, { type }): ReactNode => {
      return capitalize(type);
    }
  };

  const actionsColumns: TableColumn<BusinessUnit> = {
    key: 'actions',
    render: (_, { id, referencePrefix, tradingName }): ReactNode => {
      return <EditBusinessUnitButton id={id} name={tradingName} referencePrefix={referencePrefix} />;
    },
    sortable: false,
    title: '',
    width: '5%'
  };

  const columns: TableColumn<BusinessUnit>[] = [tradingNameColumn, typeColumn];

  if (permissions.canEditBusinessUnits) {
    columns.push(actionsColumns);
  }

  return columns;
};

export default getColumnsConfiguration;
