import { FC, ReactElement, ReactNode, useState } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { BookingDocumentState, Document } from '@zen/Components/Documents/types';
import InformationIcon from '@zen/Components/InformationIcon';
import RolePermissionList from '@zen/Components/RolePermissions/RolePermissionList';
import type { RolePermissionMap } from '@zen/Components/RolePermissions/types';
import { Card, ContextMenu, Dialog, IconButton, MenuItemType, Option, TextLink } from '@zen/DesignSystem';
import { downloadS3File } from '@zen/utils';
import loadable from '@zen/utils/component/loadable';
import { formatDateTime } from '@zen/utils/dateTime';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import { getFileExtension } from '../../helpers';
import EditDocumentForm from './EditDocumentForm';

const DocumentPreviewModal = loadable(() => import('./DocumentPreviewModal'));

const getLabel = (documentTypes: Option<string>[], documentType: Undefinable<string>) => {
  const document: Option<string> | undefined = documentTypes.find(({ value }) => value === documentType);

  if (!document) {
    return null;
  }

  return document.label;
};

type DocumentActionMode = 'DELETE' | 'EDIT' | 'PREVIEW';

export interface Props {
  document: Document;
  documentTypes: Option<string>[];
  onDelete: (id: string) => void;
  onEdit: (document: Document) => void;
  showDocumentDate?: boolean;
}

const DocumentItem: FC<Props> = ({ document, documentTypes, onEdit, onDelete, showDocumentDate = true }) => {
  const { actionItemDocument, assetUrl, createdAt, description, documentType, error, permissions, uuid: documentId } = document;
  const { addError } = useNotification();

  const { trackEvent } = useTracking();
  const { check } = useGlobalPermissions();
  const [documentMode, setDocumentMode] = useState<Nullable<DocumentActionMode>>(null);

  const handleSetDeleteMode = (): void => setDocumentMode('DELETE');
  const handleSetEditMode = (): void => setDocumentMode('EDIT');
  const handleSetPreviewMode = (): void => setDocumentMode('PREVIEW');
  const handleResetDocumentMode = (): void => setDocumentMode(null);

  const isEditMode: boolean = documentMode === 'EDIT';
  const isDeleteMode: boolean = documentMode === 'DELETE';
  const isPreviewMode: boolean = documentMode === 'PREVIEW';

  const canReadPermissions: boolean = check('bookings.canViewDocumentPermissions');
  const fileExtension: string = getFileExtension(assetUrl);

  const documentHasError: boolean = !!document?.error;
  const documentProperlyUploaded: boolean = document?.state === BookingDocumentState.UPLOADED && !documentHasError;
  const canPreviewDocument: boolean = documentProperlyUploaded;
  const canEditDocument: boolean = documentProperlyUploaded;
  const canDownloadDocument: boolean = documentProperlyUploaded;

  const handleDocumentPreview = (): void => {
    handleSetPreviewMode();

    trackEvent({
      category: 'Documents',
      action: 'PreviewDocument',
      label: fileExtension,
      properties: {
        documentId,
        fileExtension
      }
    });
  };

  const dropdownPreviewItem: MenuItemType = {
    label: 'Preview',
    onClick: handleDocumentPreview
  };

  const downloadItem: MenuItemType = {
    label: 'Download',
    onClick: () => {
      downloadS3File(assetUrl).catch(() => addError('There was a problem downloading the file.'));
    }
  };

  const dropdownEditItem: MenuItemType = {
    label: 'Edit',
    onClick: handleSetEditMode
  };

  const dropdownDeleteItem: MenuItemType = {
    label: 'Delete',
    onClick: handleSetDeleteMode
  };

  const dropdownItems: MenuItemType[] = [
    ...(canPreviewDocument ? [dropdownPreviewItem] : []),
    ...(canDownloadDocument ? [downloadItem] : []),
    ...(canEditDocument ? [dropdownEditItem] : []),
    dropdownDeleteItem
  ];

  const handleDelete = (): void => {
    onDelete(documentId);
    handleResetDocumentMode();
  };

  const errorTooltipContent: ReactNode = (
    <div className="text-red-base" data-testid="error-tooltip-content">
      {error}
    </div>
  );

  const renderDocumentName = (documentName: string): ReactNode => {
    if (canPreviewDocument) {
      return (
        <TextLink className="font-bold" data-testid="document-preview-link" onClick={handleDocumentPreview}>
          {documentName}
        </TextLink>
      );
    }

    return <div className="font-bold truncate text-grey-dark">{documentName}</div>;
  };

  const documentItemNode: ReactNode = (
    <div className="flex items-center">
      <span>{actionItemDocument ? documentType : getLabel(documentTypes, documentType)}</span>
      {showDocumentDate && !documentHasError && (
        <>
          <span className="px-2 text-grey-light">&bull;</span>
          <div className="text-grey-base" data-testid="creation-date">
            Uploaded on {createdAt && formatDateTime(createdAt, 'D_MMMM_YYYY_HH_MM')}
          </div>
        </>
      )}
      {documentHasError && <InformationIcon className="ml-2 text-base text-red-base" content={errorTooltipContent} />}
    </div>
  );

  const renderContextMenu: ReactElement = (
    <ContextMenu items={dropdownItems}>
      <IconButton className="text-lg" icon="zicon-dots" size="medium" variant="ghost" />
    </ContextMenu>
  );

  if (isEditMode) {
    return (
      <EditDocumentForm
        documentTypes={documentTypes}
        initialValues={document}
        onCancel={handleResetDocumentMode}
        onSubmit={(values: Document) => onEdit(values)}
        onSuccess={handleResetDocumentMode}
      />
    );
  }

  return (
    <>
      <Card className="mt-4" rightIcon={renderContextMenu} title={renderDocumentName(description)}>
        {documentItemNode}
        {canReadPermissions && (
          <RolePermissionList className="mt-4" rolePermissions={permissions as RolePermissionMap} showAdminPermission={false} />
        )}
      </Card>
      {canPreviewDocument && isPreviewMode && (
        <DocumentPreviewModal
          fileTitle={description}
          fileUrl={assetUrl || ''}
          isOpen={isPreviewMode}
          onClosePreview={handleResetDocumentMode}
        />
      )}
      {isDeleteMode && (
        <Dialog
          confirmLabel="Delete"
          header="Are you sure you want to delete this document?"
          isOpen={isDeleteMode}
          message="Deleting will remove visibility and access to this document for all parties"
          onClose={handleResetDocumentMode}
          onConfirm={handleDelete}
        />
      )}
    </>
  );
};

export default DocumentItem;
