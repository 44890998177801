import { object, ObjectSchema, string } from 'yup';

import { getChargeItemsValidator } from '@zen/RateCards/FreightCharges/FreightChargeForm/freightCharge.validation';

import type { ChargeLocationIdType } from './types';

export const getValidationSchema = (locationKey: ChargeLocationIdType): ObjectSchema<{}> =>
  object().shape({
    chargeItems: getChargeItemsValidator(),
    [locationKey]: string().nullable().required('Please select a port.')
  });
