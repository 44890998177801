import type { FC } from 'react';

import type { Option } from '@zen/DesignSystem';
import { ProgressBar } from '@zen/DesignSystem';

import type { Document } from '../types';
import DocumentListForm from './DocumentListForm';
import type { DocumentListValues } from './DocumentListForm/DocumentListForm';

interface Props {
  documentTypes: Option<string>[];
  documents: Document[];
  onCancel: () => void;
  onSubmit: (values: DocumentListValues) => void;
}

const getInitialValues = (documents: Document[]): DocumentListValues => {
  return {
    documents
  };
};

const DocumentUploader: FC<Props> = (props) => {
  const { documents, documentTypes, onCancel, onSubmit } = props;
  const progressCompleted = 100;
  const isUploaded = documents.every((document) => !!document.uuid);
  const documentsInQueue = documents.filter((document) => document.progress !== progressCompleted);

  if (!documents.length) {
    return null;
  }

  return (
    <div className="mt-8">
      {isUploaded && (
        <DocumentListForm
          documentTypes={documentTypes}
          initialValues={getInitialValues(documents)}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      )}
      {!isUploaded &&
        documentsInQueue.map((document: Document, i: number) => (
          <ProgressBar key={i} className="mb-4" label={document.description} percentage={document.progress || 0} />
        ))}
    </div>
  );
};

export default DocumentUploader;
