import type { FC, ReactElement } from 'react';

import { useTimeTracker } from '@zen/utils/hooks/useTimeTracking';
import useTracking from '@zen/utils/hooks/useTracking';

import type { FormProps } from './Form';
import { FormActions, FormTrackingAction, FormTrackingCategory } from './types';

export default (Component: FC<FormProps>) => {
  return (props: FormProps): ReactElement => {
    const { formName = 'UnknownForm', onSuccess } = props;
    const { trackEvent } = useTracking();
    const { getTimeElapsed, resetTime } = useTimeTracker();

    const handleSuccess = (data: unknown, values: unknown, actions: FormActions<unknown>): void => {
      trackEvent({
        category: FormTrackingCategory,
        action: FormTrackingAction.TIME_TO_COMPLETE,
        label: formName,
        properties: {
          formName,
          timeToComplete: getTimeElapsed()
        }
      });

      // we need to reset the time on successful submit
      resetTime();

      if (onSuccess) {
        onSuccess(data, values, actions);
      }
    };

    return <Component {...props} onSuccess={handleSuccess} />;
  };
};
