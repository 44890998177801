import * as Yup from 'yup';

import type { BusinessUnitsAssigments } from './types';

const validationSchema = Yup.object({
  emails: Yup.array()
    .nullable()
    .min(1, 'Please provide email addresses.')
    .test('are-valid-emails', 'Please enter valid email addresses.', (emails) => {
      if (!emails) return true;

      return emails.every((email) => {
        return Yup.string().email().isValidSync(email);
      });
    }),
  businessUnits: Yup.array()
    .nullable()
    .when('businessUnitsAssigments', {
      is: (businessUnitsAssignmentOption: BusinessUnitsAssigments) => businessUnitsAssignmentOption === 'specific-business-units',
      then: (schema) => schema.min(1, 'Please select at least one business unit.')
    })
});

export default validationSchema;
