import { createContext, useContext } from 'react';

type AccountIdContextData = {
  accountId: string;
  fetchAccountId: () => Promise<string>;
  removeAccountId: () => void;
  setAccountId: (value: string) => void;
};

const defaultValues: AccountIdContextData = {
  accountId: '',
  setAccountId: () => {},
  removeAccountId: () => {},
  fetchAccountId: () => Promise.resolve('')
};
const AccountIdContext = createContext<AccountIdContextData>(defaultValues);

export const useAccountId = () => useContext(AccountIdContext);

export { defaultValues as accountIdContextInitialValues };
export default AccountIdContext;
