import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworkUpdateContactMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateContactInput;
}>;

export type NetworkUpdateContactMutationResult = { __typename?: 'Mutation' } & {
  networkUpdateContact?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateContactPayload' } & {
      contact?: GraphQLTypes.Maybe<{ __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const NetworkUpdateContactDocument = /* #__PURE__ */ gql`
  mutation networkUpdateContact($input: UpdateContactInput!) {
    networkUpdateContact(input: $input) {
      contact {
        id
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useNetworkUpdateContactMutation__
 *
 * To run a mutation, you first call `useNetworkUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworkUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networkUpdateContactMutation, { data, loading, error }] = useNetworkUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworkUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<NetworkUpdateContactMutationResult, NetworkUpdateContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworkUpdateContactMutationResult, NetworkUpdateContactMutationVariables>(
    NetworkUpdateContactDocument,
    options
  );
}
export type NetworkUpdateContactMutationHookResult = ReturnType<typeof useNetworkUpdateContactMutation>;
