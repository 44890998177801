import type { RateCardSummary } from '@zen/RateCards/LegacyRateCards/types';
import type { PageSize } from '@zen/types';
import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';

import { RateCardsQueryResult, RateCardsQueryVariables, useRateCardsQuery } from '..';

const useRateCardSummaries = (
  variables: RateCardsQueryVariables,
  pageSize: PageSize
): PaginatedQueryResult<RateCardsQueryResult, RateCardsQueryVariables, RateCardSummary> => {
  return usePagination<RateCardsQueryResult, RateCardsQueryVariables, RateCardSummary>(
    useRateCardsQuery,
    'legacyGetRateCards',
    variables,
    pageSize,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );
};

export default useRateCardSummaries;
