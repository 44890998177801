import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateOriginAgentAssignmentRuleInput;
}>;

export type PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersUpdateOriginAgentAssignmentRule?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateOriginAgentAssignmentRulePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const PurchaseOrdersUpdateOriginAgentAssignmentRuleDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersUpdateOriginAgentAssignmentRule($input: UpdateOriginAgentAssignmentRuleInput!) {
    purchaseOrdersUpdateOriginAgentAssignmentRule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersUpdateOriginAgentAssignmentRuleMutation, { data, loading, error }] = usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationResult,
    PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationResult,
    PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationVariables
  >(PurchaseOrdersUpdateOriginAgentAssignmentRuleDocument, options);
}
export type PurchaseOrdersUpdateOriginAgentAssignmentRuleMutationHookResult = ReturnType<
  typeof usePurchaseOrdersUpdateOriginAgentAssignmentRuleMutation
>;
