import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OceanChargesQueryVariables = GraphQLTypes.Exact<{
  applicabilityTraits: Array<GraphQLTypes.ApplicabilityTraits> | GraphQLTypes.ApplicabilityTraits;
  chargeTypeId?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OceanChargesQueryResult = { __typename?: 'Query' } & {
  getDefaultCharges?: GraphQLTypes.Maybe<
    { __typename?: 'DefaultChargesConnection' } & Pick<GraphQLTypes.DefaultChargesConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'DefaultChargesPayload' } & Pick<
                GraphQLTypes.DefaultChargesPayload,
                'currency' | 'id' | 'itemName' | 'unitPrice'
              > & {
                  chargeType: { __typename?: 'ChargesPayload' } & Pick<GraphQLTypes.ChargesPayload, 'basisGroup' | 'id' | 'name'>;
                  location:
                    | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        })
                    | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                          label?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                          >;
                        });
                }
            >
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const OceanChargesDocument = /* #__PURE__ */ gql`
  query oceanCharges(
    $applicabilityTraits: [ApplicabilityTraits!]!
    $chargeTypeId: String
    $textContains: String
    $order: SortInput
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    getDefaultCharges(
      applicabilityTraits: $applicabilityTraits
      chargeTypeId: $chargeTypeId
      textContains: $textContains
      order: $order
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      nodes {
        chargeType {
          basisGroup
          id
          name
        }
        currency
        id
        itemName
        location {
          id
          label {
            long
            short
          }
        }
        unitPrice
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOceanChargesQuery__
 *
 * To run a query within a React component, call `useOceanChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOceanChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanChargesQuery({
 *   variables: {
 *      applicabilityTraits: // value for 'applicabilityTraits'
 *      chargeTypeId: // value for 'chargeTypeId'
 *      textContains: // value for 'textContains'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useOceanChargesQuery(baseOptions: Apollo.QueryHookOptions<OceanChargesQueryResult, OceanChargesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OceanChargesQueryResult, OceanChargesQueryVariables>(OceanChargesDocument, options);
}
export function useOceanChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OceanChargesQueryResult, OceanChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OceanChargesQueryResult, OceanChargesQueryVariables>(OceanChargesDocument, options);
}
export type OceanChargesQueryHookResult = ReturnType<typeof useOceanChargesQuery>;
export type OceanChargesLazyQueryHookResult = ReturnType<typeof useOceanChargesLazyQuery>;
