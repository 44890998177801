import type { FC, ReactNode } from 'react';

import DatesDifference from '@zen/Components/DatesDifference';
import DateWithTimeZone from '@zen/Components/DateWithTimeZone';
import { Tooltip } from '@zen/DesignSystem';
import type { ConfidenceLevel } from '@zen/Orders';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

export interface Props {
  actualDate: string;
  confidenceLevel: ConfidenceLevel;
  dateLabel: string;
  hasInitialDateForAll: boolean;
  initialDate: Optional<string>;
  timeZone?: Optional<string>;
}

const ActivityFeedDates: FC<Props> = (props) => {
  const { actualDate, confidenceLevel, dateLabel, hasInitialDateForAll, initialDate, timeZone } = props;

  const getDatesDifferenceNode = (): ReactNode => {
    if (hasInitialDateForAll && !initialDate) {
      return null;
    }

    if (hasInitialDateForAll && initialDate) {
      const tooltipContent: ReactNode = (
        <div className="text-sm leading-normal whitespace-nowrap" data-testid="tooltip-content">
          <p className="font-bold text-grey-dark">Initial {dateLabel}</p>
          <p className="text-grey-base">{formatDate(initialDate)}</p>
        </div>
      );

      return (
        <Tooltip tooltipContent={tooltipContent}>
          <DatesDifference className="cursor-pointer" date={actualDate} referenceDate={initialDate} />
        </Tooltip>
      );
    }

    return <p>Various delays</p>;
  };

  return (
    <div className="leading-normal" data-testid="activity-feed-dates">
      <div className="flex justify-end">
        <div className="mr-2 font-bold text-grey-dark">
          <DateWithTimeZone dateWithTimeZone={{ date: actualDate, timeZone }} />
        </div>
        {getDatesDifferenceNode()}
      </div>
      <p className="text-sm text-grey-base">
        New {dateLabel} ({confidenceLevel})
      </p>
    </div>
  );
};

export default ActivityFeedDates;
