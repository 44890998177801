import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import type { TableColumn } from '@zen/DesignSystem';
import { useTableConfiguration } from '@zen/DesignSystem';
import type { ShipmentExportDataInput } from '@zen/graphql/types.generated';
import { prepareFilterVariables } from '@zen/Shipments/Filters/helpers';
import { getColumns } from '@zen/Shipments/TableView/tableConfiguration';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import type { FlatShipment, ShipmentFilters, ShipmentsListCapabilities } from '../types';
import ShipmentsDataExportButton from './ShipmentsDataExportButton';

interface Props {
  capabilities: ShipmentsListCapabilities;
}

const ShipmentsDataHeader: FC<Props> = ({ capabilities }) => {
  const { filters } = useUrlFilters<ShipmentFilters>();

  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const { check } = useGlobalPermissions();
  const canExportData = check('bookings.canExport');

  const columnsKeys: TableColumn<FlatShipment>[] = [...getColumns({ capabilities })];

  return (
    <div className="flex items-center justify-center">
      <CustomiseTableButton
        columns={getColumns()}
        defaultFilterValue="references"
        hiddenColumns={hiddenColumns}
        onChange={setHiddenColumns}
      />
      {canExportData && (
        <ShipmentsDataExportButton<FlatShipment>
          columns={columnsKeys}
          dataFilters={prepareFilterVariables(filters) as ShipmentExportDataInput['dataFilters']}
          hiddenColumns={hiddenColumns}
        />
      )}
    </div>
  );
};

export default ShipmentsDataHeader;
