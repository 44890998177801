import { checkPermission } from '@zen/Auth/authHelper';
import type { Option } from '@zen/DesignSystem';
import { useNetworkPermissionsQuery } from '@zen/Networks/graphql';
import { Network, OrganisationTypeValue } from '@zen/Networks/types';

const useAllowedOrganisationTypes = (networkId: string): Option<OrganisationTypeValue>[] => {
  const { data } = useNetworkPermissionsQuery({ variables: { networkId } });

  const allowedOrganisationTypes: Option<OrganisationTypeValue>[] = [];
  const organisationTypesPermissions = {
    canCreateAgentOrganisation: {
      value: OrganisationTypeValue.AGENT,
      label: 'Agent organisation'
    },
    canCreateSavedOrganisation: {
      value: OrganisationTypeValue.SAVED,
      label: 'Saved organisation'
    },
    canCreateForwarderOrganisation: {
      value: OrganisationTypeValue.FORWARDER,
      label: 'Forwarder organisation'
    }
  };

  Object.entries(organisationTypesPermissions).map(([permission, option]) => {
    if (data?.network && checkPermission<Network>(data.network, permission as keyof Network)) {
      allowedOrganisationTypes.push(option);
    }
  });

  return allowedOrganisationTypes;
};

export default useAllowedOrganisationTypes;
