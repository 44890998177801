import type { FC } from 'react';

import { useAccountId } from '@zen/App/AccountIdContext';
import QueryHandler from '@zen/Components/QueryHandler';
import { SplashScreen } from '@zen/DesignSystem';

import useAuth from '../hooks/useAuth';
import { prepareUserProfile, saveUserData } from './helpers';
import useCurrentUser from './hooks/useCurrentUser';
import type { CurrentUserResponse, UserContextData, UserProfile } from './types';
import UserContext from './UserContext';

const UserDataProvider: FC = ({ children }) => {
  const { accountId } = useAccountId();
  const { data, loading, error, refetch: refetchUser } = useCurrentUser(accountId);
  const { logout } = useAuth();

  const extractAndSaveUserContext = (userData: CurrentUserResponse): UserContextData => {
    const userProfile: UserProfile = prepareUserProfile(userData);

    // we need to save a few information about the user in localstorage
    // because it's needed for the GTM ( custom dimensions )
    saveUserData(userProfile);

    return {
      accountStatus: userData.account?.status,
      accountUuid: accountId,
      referencePrefix: userData.account?.referencePrefix || userData.legacyAccount?.referencePrefix,
      refetchUser,
      userProfile
    };
  };

  if (error) {
    logout();

    return null;
  }

  return (
    <QueryHandler data={data} error={false} isLoading={loading} loadingComponent={<SplashScreen />} noResults={null}>
      {(userData) => {
        return <UserContext.Provider value={extractAndSaveUserContext(userData)}>{children}</UserContext.Provider>;
      }}
    </QueryHandler>
  );
};

export default UserDataProvider;
