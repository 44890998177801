import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components/Form';
import { Button, Modal } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import CustomerSelectFormInput from '../Form/CustomerSelectFormInput';
import { validationSchema } from './customerSelectModal.validation';

export interface CustomerFormValues {
  customerId: string;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (values: CustomerFormValues) => Promise<IOkOrErrorResult>;
}

const CustomerSelectModal: FC<Props> = ({ isOpen, onClose, onSelect }) => {
  const renderFormButtons = ({ isSubmitting }: FormInstance<CustomerFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed">
        <Button onClick={onClose} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Modal
      data-testid="customer-select-modal"
      isOpen={isOpen}
      modalOverflowY="visible"
      onClose={onClose}
      title="Please select a customer"
    >
      <p className="mb-5">To proceed with your booking please choose a customer.</p>
      <Form
        formButtons={renderFormButtons}
        formName="CustomerSelectModal"
        initialValues={{ customerId: null }}
        onSubmit={onSelect}
        validationSchema={validationSchema}
      >
        {() => <CustomerSelectFormInput name="customerId" />}
      </Form>
    </Modal>
  );
};

export default CustomerSelectModal;
