const OPS_SETTINGS_RELATIVE_INDEX_PATH = 'ops-settings';

export const OPS_SETTINGS_PREFIX = `/dashboard/${OPS_SETTINGS_RELATIVE_INDEX_PATH}`;

export interface OpsSettingsRoutes {
  agentAssingmentRulesCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  agentAssingmentRulesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  agentAssingmentRulesUpdate: {
    getUrl: (ruleId: string) => string;
    path: string;
    relativePath: string;
  };
  airlineCarriers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  assignmentRulesCreate: {
    getUrl: () => string;
    path: string;
  };
  assignmentRulesEdit: {
    getUrl: (ruleId: string) => string;
    path: string;
  };
  assignmentRulesIndex: {
    getUrl: () => string;
    path: string;
  };
  bookingDocumentTypes: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  carriers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  chargeTypesCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  chargeTypesEdit: {
    getUrl: (chargeTypeId: string) => string;
    path: string;
    relativePath: string;
  };
  chargeTypesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  charges: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  chargesDestination: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  chargesOrigin: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  chargesUpload: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  contactsCreate: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  contactsEdit: {
    getUrl: (contactId: string) => string;
    path: string;
    relativePath: string;
  };
  contactsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  contractIdsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  couriers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  delayReasons: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  delayReasonsOrder: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  delayReasonsShipment: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  manageAccrualTemplates: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  manageAccrualsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  manageCommodityCodesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  oceanCarriers: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  opsSettingsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  railwayCompanies: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  shipmentActionTypes: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  shippingDelayReasonsIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const opsSettingsRoutes: OpsSettingsRoutes = {
  agentAssingmentRulesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/new`,
    relativePath: 'new',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/new`
  },
  agentAssingmentRulesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules`,
    relativePath: 'agent-assignment-rules/*',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules`
  },
  agentAssingmentRulesUpdate: {
    path: `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/edit/:ruleId`,
    relativePath: 'edit/:ruleId',
    getUrl: (ruleId: string) => `${OPS_SETTINGS_PREFIX}/agent-assignment-rules/edit/${ruleId}`
  },
  airlineCarriers: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/carriers/airline`,
    path: `${OPS_SETTINGS_PREFIX}/carriers/airline`,
    relativePath: 'airline'
  },
  assignmentRulesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/assignment-rules/create`
  },
  assignmentRulesEdit: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules/edit/:ruleId`,
    getUrl: (ruleId: string) => `${OPS_SETTINGS_PREFIX}/assignment-rules/edit/${ruleId}`
  },
  assignmentRulesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/assignment-rules`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/assignment-rules`
  },
  bookingDocumentTypes: {
    path: `${OPS_SETTINGS_PREFIX}/booking-document-types`,
    relativePath: 'booking-document-types',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/booking-document-types`
  },
  carriers: {
    path: `${OPS_SETTINGS_PREFIX}/carriers/*`,
    relativePath: 'carriers/*',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/carriers`
  },
  chargeTypesCreate: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types/create`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charge-types/create`,
    relativePath: 'create'
  },
  chargeTypesEdit: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types/edit/:chargeTypeId`,
    getUrl: (chargeTypeId: string) => `${OPS_SETTINGS_PREFIX}/charge-types/edit/${chargeTypeId}`,
    relativePath: 'edit/:chargeTypeId'
  },
  chargeTypesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/charge-types`,
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charge-types`,
    relativePath: 'charge-types/*'
  },
  charges: {
    path: `${OPS_SETTINGS_PREFIX}/charges/*`,
    relativePath: 'charges/*',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charges`
  },
  chargesDestination: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charges/destination`,
    path: `${OPS_SETTINGS_PREFIX}/charges/destination`,
    relativePath: 'destination'
  },
  chargesOrigin: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charges/origin`,
    path: `${OPS_SETTINGS_PREFIX}/charges/origin`,
    relativePath: 'origin'
  },
  chargesUpload: {
    path: `${OPS_SETTINGS_PREFIX}/charges/upload`,
    relativePath: 'upload',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/charges/upload`
  },
  contactsCreate: {
    path: `${OPS_SETTINGS_PREFIX}/contacts/create`,
    relativePath: 'create',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contacts/create`
  },
  contactsEdit: {
    path: `${OPS_SETTINGS_PREFIX}/contacts/edit/:contactId`,
    relativePath: 'edit/:contactId',
    getUrl: (contactId: string) => `${OPS_SETTINGS_PREFIX}/contacts/edit/${contactId}`
  },
  contactsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/contacts`,
    relativePath: 'contacts/*',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contacts`
  },
  contractIdsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/contract-id`,
    relativePath: 'contract-id',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/contract-id`
  },
  couriers: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/carriers/couriers`,
    path: `${OPS_SETTINGS_PREFIX}/carriers/couriers`,
    relativePath: 'couriers'
  },
  delayReasons: {
    path: `${OPS_SETTINGS_PREFIX}/delay-reasons/*`,
    relativePath: 'delay-reasons/*',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/delay-reasons`
  },
  delayReasonsOrder: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/delay-reasons/purchase-order`,
    path: `${OPS_SETTINGS_PREFIX}/delay-reasons/purchase-order`,
    relativePath: 'purchase-order'
  },
  delayReasonsShipment: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/delay-reasons/shipment`,
    path: `${OPS_SETTINGS_PREFIX}/delay-reasons/shipment`,
    relativePath: 'shipment'
  },
  manageAccrualTemplates: {
    path: `${OPS_SETTINGS_PREFIX}/manage-accrual-templates`,
    relativePath: 'manage-accrual-templates',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/manage-accrual-templates`
  },
  manageAccrualsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/manage-accruals`,
    relativePath: 'manage-accruals',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/manage-accruals`
  },
  manageCommodityCodesIndex: {
    path: `${OPS_SETTINGS_PREFIX}/commodity-codes`,
    relativePath: 'commodity-codes',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/commodity-codes`
  },
  oceanCarriers: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/carriers/ocean`,
    path: `${OPS_SETTINGS_PREFIX}/carriers/ocean`,
    relativePath: 'ocean'
  },
  opsSettingsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/*`,
    getUrl: () => OPS_SETTINGS_PREFIX,
    relativePath: `${OPS_SETTINGS_RELATIVE_INDEX_PATH}/*`
  },
  railwayCompanies: {
    getUrl: () => `${OPS_SETTINGS_PREFIX}/carriers/railway-companies`,
    path: `${OPS_SETTINGS_PREFIX}/carriers/railway-companies`,
    relativePath: 'railway-companies'
  },
  shipmentActionTypes: {
    path: `${OPS_SETTINGS_PREFIX}/shipment-action-types`,
    relativePath: 'shipment-action-types',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/shipment-action-types`
  },
  shippingDelayReasonsIndex: {
    path: `${OPS_SETTINGS_PREFIX}/shipping-delay-reasons`,
    relativePath: 'shipping-delay-reasons',
    getUrl: () => `${OPS_SETTINGS_PREFIX}/shipping-delay-reasons`
  }
};

export default opsSettingsRoutes;
