import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AirlineCarrierQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type AirlineCarrierQueryResult = { __typename?: 'Query' } & {
  airlineCarrier?: GraphQLTypes.Maybe<
    { __typename?: 'AirlineCarrier' } & Pick<GraphQLTypes.AirlineCarrier, 'airlineCarrierName' | 'carrierIata' | 'id'>
  >;
};

export const AirlineCarrierDocument = /* #__PURE__ */ gql`
  query airlineCarrier($id: String!) {
    airlineCarrier(id: $id) {
      airlineCarrierName
      carrierIata
      id
    }
  }
`;

/**
 * __useAirlineCarrierQuery__
 *
 * To run a query within a React component, call `useAirlineCarrierQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirlineCarrierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirlineCarrierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAirlineCarrierQuery(
  baseOptions: Apollo.QueryHookOptions<AirlineCarrierQueryResult, AirlineCarrierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AirlineCarrierQueryResult, AirlineCarrierQueryVariables>(AirlineCarrierDocument, options);
}
export function useAirlineCarrierLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AirlineCarrierQueryResult, AirlineCarrierQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AirlineCarrierQueryResult, AirlineCarrierQueryVariables>(AirlineCarrierDocument, options);
}
export type AirlineCarrierQueryHookResult = ReturnType<typeof useAirlineCarrierQuery>;
export type AirlineCarrierLazyQueryHookResult = ReturnType<typeof useAirlineCarrierLazyQuery>;
