import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ContractIdQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type ContractIdQueryResult = { __typename?: 'Query' } & {
  getContract?: GraphQLTypes.Maybe<
    { __typename?: 'ContractPayload' } & Pick<GraphQLTypes.ContractPayload, 'id' | 'simplifiedContractId'>
  >;
};

export const ContractIdDocument = /* #__PURE__ */ gql`
  query contractId($zencargoReference: String!) {
    getContract(zencargoReference: $zencargoReference) {
      id
      simplifiedContractId
    }
  }
`;

/**
 * __useContractIdQuery__
 *
 * To run a query within a React component, call `useContractIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractIdQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useContractIdQuery(baseOptions: Apollo.QueryHookOptions<ContractIdQueryResult, ContractIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ContractIdQueryResult, ContractIdQueryVariables>(ContractIdDocument, options);
}
export function useContractIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContractIdQueryResult, ContractIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ContractIdQueryResult, ContractIdQueryVariables>(ContractIdDocument, options);
}
export type ContractIdQueryHookResult = ReturnType<typeof useContractIdQuery>;
export type ContractIdLazyQueryHookResult = ReturnType<typeof useContractIdLazyQuery>;
