import type { Nullable } from '@zen/utils/typescript';

import { Currency } from '../../../types';
import { defaultLooseCargoFormInitialValues } from '../../LooseCargo/LooseCargoForm/helpers';
import type { FullTruckFormInitialValues } from './types';

export const initialValueOfGoods: { currency: Currency; value: Nullable<number> } = {
  currency: Currency.GBP,
  value: null
};

export const defaultFullTruckInitialValues: FullTruckFormInitialValues = {
  hazardous: false,
  healthCertificate: false,
  refrigerated: false,
  riskLevel: null,
  tailLift: false,
  truckVariant: null,
  loose: defaultLooseCargoFormInitialValues.loose,
  type: null,
  valueOfGoods: initialValueOfGoods
};
