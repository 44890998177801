import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UploadOceanChargesMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UploadDefaultPortChargesInput;
}>;

export type UploadOceanChargesMutationResult = { __typename?: 'Mutation' } & {
  uploadDefaultPortCharges?: GraphQLTypes.Maybe<
    { __typename?: 'UploadDefaultPortChargesPayload' } & Pick<
      GraphQLTypes.UploadDefaultPortChargesPayload,
      'newCharges' | 'totalCharges' | 'updatedCharges'
    > & {
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
        failedPortCharges?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'FailedPortChargeItem' } & Pick<GraphQLTypes.FailedPortChargeItem, 'itemName'>>
        >;
      }
  >;
};

export const UploadOceanChargesDocument = /* #__PURE__ */ gql`
  mutation uploadOceanCharges($input: UploadDefaultPortChargesInput!) {
    uploadDefaultPortCharges(input: $input) {
      errors {
        message
        path
      }
      failedPortCharges {
        itemName
      }
      newCharges
      totalCharges
      updatedCharges
    }
  }
`;

/**
 * __useUploadOceanChargesMutation__
 *
 * To run a mutation, you first call `useUploadOceanChargesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOceanChargesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOceanChargesMutation, { data, loading, error }] = useUploadOceanChargesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadOceanChargesMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadOceanChargesMutationResult, UploadOceanChargesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UploadOceanChargesMutationResult, UploadOceanChargesMutationVariables>(
    UploadOceanChargesDocument,
    options
  );
}
export type UploadOceanChargesMutationHookResult = ReturnType<typeof useUploadOceanChargesMutation>;
