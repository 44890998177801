import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateShippingDelayReasonMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateShippingDelayReasonInput;
}>;

export type UpdateShippingDelayReasonMutationResult = { __typename?: 'Mutation' } & {
  updateShippingDelayReason: { __typename?: 'UpdateShippingDelayReasonPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    shippingDelayReason?: GraphQLTypes.Maybe<
      { __typename?: 'ShippingDelayReason' } & Pick<
        GraphQLTypes.ShippingDelayReason,
        'description' | 'id' | 'reason' | 'responsibleParty'
      > & {
          lastUpdatedBy?: GraphQLTypes.Maybe<
            { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'>
          >;
        }
    >;
  };
};

export const UpdateShippingDelayReasonDocument = /* #__PURE__ */ gql`
  mutation updateShippingDelayReason($input: UpdateShippingDelayReasonInput!) {
    updateShippingDelayReason(input: $input) {
      errors {
        path
        message
      }
      shippingDelayReason {
        description
        id
        lastUpdatedBy {
          id
          firstName
          lastName
        }
        reason
        responsibleParty
      }
    }
  }
`;

/**
 * __useUpdateShippingDelayReasonMutation__
 *
 * To run a mutation, you first call `useUpdateShippingDelayReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingDelayReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingDelayReasonMutation, { data, loading, error }] = useUpdateShippingDelayReasonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShippingDelayReasonMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateShippingDelayReasonMutationResult, UpdateShippingDelayReasonMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateShippingDelayReasonMutationResult, UpdateShippingDelayReasonMutationVariables>(
    UpdateShippingDelayReasonDocument,
    options
  );
}
export type UpdateShippingDelayReasonMutationHookResult = ReturnType<typeof useUpdateShippingDelayReasonMutation>;
