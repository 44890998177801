import type { HaulageChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';

import type { HaulageFormInitialValues, HaulageFormValues } from './types';

export const preparePayload = (values: HaulageFormValues): HaulageChargePayload => {
  const { chargeType, price, location, port } = values;

  return {
    chargeType,
    chargeValue: price.value,
    currency: price.currency,
    port,
    location,
    incoterms: []
  };
};

export const prepareInitialValues = (values: HaulageChargePayload): HaulageFormInitialValues => {
  const { chargeType, chargeValue, currency, location, port } = values;

  return {
    chargeType,
    price: {
      value: chargeValue,
      currency
    },
    port,
    location
  };
};

export const originHaulageFormLabels: Record<keyof HaulageFormInitialValues, string> = {
  price: 'Cost',
  location: 'Origin address',
  port: 'Port of origin',
  chargeType: 'Charge type'
};

export const destinationHaulageFormLabels: Record<keyof HaulageFormInitialValues, string> = {
  price: 'Cost',
  location: 'Destination address',
  port: 'Port of destination',
  chargeType: 'Charge type'
};

export const getHaulageFormLabels = (type: 'origin' | 'destination') => {
  return type === 'origin' ? originHaulageFormLabels : destinationHaulageFormLabels;
};
