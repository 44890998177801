import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteRateCardMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.LegacyDeleteRateCardInput;
}>;

export type DeleteRateCardMutationResult = { __typename?: 'Mutation' } & {
  legacyDeleteRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyDeleteRateCardPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const DeleteRateCardDocument = /* #__PURE__ */ gql`
  mutation deleteRateCard($input: LegacyDeleteRateCardInput!) {
    legacyDeleteRateCard(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useDeleteRateCardMutation__
 *
 * To run a mutation, you first call `useDeleteRateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRateCardMutation, { data, loading, error }] = useDeleteRateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRateCardMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRateCardMutationResult, DeleteRateCardMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteRateCardMutationResult, DeleteRateCardMutationVariables>(DeleteRateCardDocument, options);
}
export type DeleteRateCardMutationHookResult = ReturnType<typeof useDeleteRateCardMutation>;
