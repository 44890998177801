export interface FilterField {
  label: string;
  name: string;
  path?: string;
}

export enum ResultType {
  purchaseOrder = 'purchase order',
  shipment = 'shipment'
}

export const ALL_DAYS_IN_THE_PAST: number = -10 * 365;
