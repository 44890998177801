import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';

export enum FlashType {
  ERROR = 'error',
  INFO = 'info'
}

export interface Flash {
  id?: number;
  options?: Record<string, string>;
  showClose?: boolean;
  text: string;
  type: FlashType;
}

interface Props {
  message: Flash;
  onRemove?: (id: number) => void;
}

const FlashMessage: FC<Props> = (props) => {
  const { message, onRemove } = props;
  const { type, text, id = new Date().getTime(), showClose } = message;

  const flashTypeClassNameMapping: Record<FlashType, string> = {
    [FlashType.INFO]: 'bg-green-base',
    [FlashType.ERROR]: 'bg-red-dark'
  };

  const handleClose = () => onRemove?.(id);

  return (
    <div className={flashTypeClassNameMapping[type]}>
      {text && (
        <div className="p-5 text-white flex justify-between items-center ">
          <span>{text}</span>
          {showClose && (
            <Icon className="text-xl cursor-pointer" data-testid="close-btn" icon="zicon-close" onClick={handleClose} />
          )}
        </div>
      )}
    </div>
  );
};

export default FlashMessage;
