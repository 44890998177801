import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import {
  formatChargeBasis,
  formatChargeType,
  formatCost,
  formatIncoterms,
  formatPort,
  formatUnit
} from '@zen/RateCards/LegacyRateCards/utils/tableDataFormatting';

import { mapDataToRow } from '../utils';

export const preparePortChargeData = (portCharges: PortChargePayload[], type: 'origin' | 'destination'): string[][] => {
  return portCharges.map((portCharge: PortChargePayload) => {
    const formattedPortCharge: Record<string, string> = getFormattedPortCharge(portCharge, type);

    return mapDataToRow(formattedPortCharge);
  });
};

const getFormattedPortCharge = (portCharge: PortChargePayload, type: 'origin' | 'destination'): Record<string, string> => {
  const portField = type === 'origin' ? 'originPort' : 'destinationPort';
  const { port, chargeType, incoterms, chargeValue, currency } = portCharge;

  return {
    chargeType: formatChargeType(chargeType),
    [portField]: formatPort(port),
    chargeBasis: formatChargeBasis(chargeType),
    unit: formatUnit(chargeType),
    incoterms: formatIncoterms(incoterms),
    cost: formatCost(chargeValue, currency)
  };
};
