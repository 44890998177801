interface AuthRoutes {
  accountActivation: {
    getUrl: () => string;
    path: string;
  };
  accountSwitcher: {
    getUrl: () => string;
    path: string;
  };
  forgotPassword: {
    getUrl: () => string;
    path: string;
  };
  login: {
    getUrl: () => string;
    path: string;
  };
  resetPassword: {
    getUrl: () => string;
    path: string;
  };
  signup: {
    getUrl: () => string;
    path: string;
  };
}

const authRoutes: AuthRoutes = {
  accountActivation: {
    path: '/account-activation',
    getUrl: (): string => '/account-activation'
  },
  accountSwitcher: {
    path: '/account_switcher',
    getUrl: (): string => '/account_switcher'
  },
  forgotPassword: {
    path: '/forgot-password',
    getUrl: () => '/forgot-password'
  },
  login: {
    path: '/login',
    getUrl: (): string => '/login'
  },
  resetPassword: {
    path: '/reset-password',
    getUrl: () => '/reset-password'
  },
  signup: {
    path: '/signup',
    getUrl: () => '/signup'
  }
};

export default authRoutes;
