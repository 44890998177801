import { type FC, type ReactNode, useState } from 'react';

import type { CargoModeEnum, ModeOfTransport } from '@zen/Cargo';
import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import CollectionToggler from '@zen/Journey/components/CollectionToggler';
import type { CanAddCargoResult, JourneyToggleCollectionValidationResult } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import { getAddCargoTooltipMessage } from '../../helpers';
import NewCargoModal from '../NewCargoModal';

interface Props {
  canAddCargo: boolean;
  canAddCargoCurrently: Optional<CanAddCargoResult>;
  canToggleCollection: boolean;
  canToggleCollectionCurrently: Optional<JourneyToggleCollectionValidationResult>;
  cargoMode: CargoModeEnum;
  isCollectionEnabled: boolean;
  modeOfTransport: ModeOfTransport;
  onCargoAdded?: (cargoId: string) => void;
  zencargoReference: string;
}

const CargoActions: FC<Props> = (props) => {
  const [isCargoModalVisible, setIsCargoModalVisible] = useState<boolean>(false);

  const {
    canAddCargo,
    canAddCargoCurrently,
    canToggleCollection,
    canToggleCollectionCurrently,
    cargoMode,
    isCollectionEnabled,
    modeOfTransport,
    onCargoAdded,
    zencargoReference
  } = props;

  const footer: ReactNode = canToggleCollection ? (
    <CollectionToggler
      canToggle={canToggleCollection}
      canToggleCurrently={canToggleCollectionCurrently}
      isCollectionEnabled={isCollectionEnabled}
      zencargoReference={zencargoReference}
    />
  ) : null;

  const addCargoOption: MenuItemType = {
    disabled: !canAddCargoCurrently?.value,
    disabledItemTooltip: getAddCargoTooltipMessage(canAddCargoCurrently?.reason, modeOfTransport),
    label: 'Add cargo',
    onClick: () => setIsCargoModalVisible(true),
    icon: 'zicon-add'
  };

  const items: MenuItemType[] = [...(canAddCargo ? [addCargoOption] : [])];

  if (!canAddCargo && !canToggleCollection) {
    return null;
  }

  return (
    <div data-testid="cargo-actions">
      <ContextMenu footer={footer} items={items} togglerSize="medium" />
      <NewCargoModal
        cargoMode={cargoMode}
        isOpen={isCargoModalVisible}
        modeOfTransport={modeOfTransport}
        onClose={() => setIsCargoModalVisible(false)}
        onSuccess={onCargoAdded}
        zencargoReference={zencargoReference}
      />
    </div>
  );
};

export default CargoActions;
