import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RemoveOriginAgentAssignmentRuleInput;
}>;

export type PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersRemoveOriginAgentAssignmentRule?: GraphQLTypes.Maybe<
    { __typename?: 'RemoveOriginAgentAssignmentRulePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const PurchaseOrdersRemoveOriginAgentAssignmentRuleDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersRemoveOriginAgentAssignmentRule($input: RemoveOriginAgentAssignmentRuleInput!) {
    purchaseOrdersRemoveOriginAgentAssignmentRule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersRemoveOriginAgentAssignmentRuleMutation, { data, loading, error }] = usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationResult,
    PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationResult,
    PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationVariables
  >(PurchaseOrdersRemoveOriginAgentAssignmentRuleDocument, options);
}
export type PurchaseOrdersRemoveOriginAgentAssignmentRuleMutationHookResult = ReturnType<
  typeof usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation
>;
