export enum IndicatorType {
  DECREASING = 'decreasing',
  EQUAL = 'equal',
  INCREASING = 'increasing'
}

export enum IndicatorSize {
  MEDIUM = 'medium',
  SMALL = 'small'
}
