import { isEmpty } from 'lodash';
import type { FC, ReactNode } from 'react';
import usePlacesAutocomplete, { getGeocode, getLatLng, LatLng } from 'use-places-autocomplete';

import type { Nullable } from '@zen/utils/typescript';

import { Option, Select, SelectProps } from '../../DesignSystem';
import HelperText from '../HelperText';
import { prepareAddressData, prepareOptions } from './helpers';
import type { GeoCodeResult, GeoSuggestAddress } from './types';

interface Props extends Omit<SelectProps<string>, 'options'> {
  onManualEntry?: () => void;
  onSuggestionSelect?: (geoAddress: GeoSuggestAddress) => void;
}

const GeosuggestAutocomplete: FC<Props> = ({ name, onSuggestionSelect, value, onManualEntry, ...rest }) => {
  const { value: inputValue, suggestions, setValue } = usePlacesAutocomplete();
  const renderOptionsFromValue: boolean = isEmpty(suggestions.data) && !!value;

  const options: Option<string>[] = renderOptionsFromValue
    ? [{ label: value || '', value: value || '' }]
    : prepareOptions(suggestions.data);

  const handleChange = async (placeId: Nullable<string>): Promise<void> => {
    // if options are rendered based on the select value
    // we don't want to call google api
    if (renderOptionsFromValue || !placeId) {
      return;
    }

    const geocodeResults: GeoCodeResult[] = await getGeocode({ placeId });
    const geocodeResult: GeoCodeResult = geocodeResults[0];
    const geoLatAndLong: LatLng = await getLatLng(geocodeResult);
    const geoSuggestAddress = prepareAddressData(geocodeResult.address_components, geoLatAndLong);

    onSuggestionSelect?.(geoSuggestAddress);
  };

  const handleInputChange = (inputVal: string): void => setValue(inputVal);

  const renderFooter = (): ReactNode => (
    <span className="cursor-pointer" onClick={onManualEntry}>
      Manually enter address
    </span>
  );

  return (
    <>
      <Select
        {...rest}
        dropdownFooterRenderer={renderFooter}
        inputValue={inputValue}
        isLoading={suggestions.loading}
        name={name}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        placeholder="Search for and select an address"
        shouldFilterResults={false}
        showSearchIcon={true}
        value={value}
      />
      <HelperText helperText="Search for and select the locations address using the search box above" />
    </>
  );
};

export type { Props as GeosuggestAutocompleteProps };

export default GeosuggestAutocomplete;
