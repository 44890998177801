import type { FC, ReactElement } from 'react';

import { rateCardRoutes } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  children: (actions: RateCardActionsType) => ReactElement;
}

type RateCardActionsType = {
  handleEdit: (id: string) => void;
};

const RateCardActions: FC<Props> = ({ children }) => {
  const { navigate } = useNavigationHistory();

  const handleEdit = (id: string): void => {
    navigate(rateCardRoutes.edit.getUrl(id));
  };

  return <>{children({ handleEdit })}</>;
};

export default RateCardActions;
