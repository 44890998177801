import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { IconName } from '@zen/Styleguide';
import { SortingOrder } from '@zen/types';

import Icon from '../../Icon';

const getIcon = (isHovered: boolean, direction?: SortingOrder): IconName => {
  if (direction === SortingOrder.ASC) {
    return isHovered ? 'zicon-sort-descending' : 'zicon-sort-ascending';
  }

  return isHovered ? 'zicon-sort-ascending' : 'zicon-sort-descending';
};

const renderIcon = (isHovered: boolean, direction?: SortingOrder): ReactNode => {
  if (!direction) {
    const noSorting: ReactNode = (
      <div className="flex flex-col" style={{ fontSize: '0.4rem' }}>
        <Icon icon="zicon-chevron-up" />
        <Icon icon="zicon-chevron-down" />
      </div>
    );

    return <div className="w-3">{isHovered ? <Icon icon="zicon-sort-ascending" /> : noSorting}</div>;
  }

  return <Icon icon={getIcon(isHovered, direction)} />;
};

interface Props {
  className?: string;
  direction?: SortingOrder;
  isHovered: boolean;
  isResizing?: boolean;
}

const SortIndicator: FC<Props> = ({ className, direction, isHovered, isResizing }) => {
  const classNames: string = cx(
    'flex cursor-pointer pointer-events-none text-xs',
    { 'pointer-events-none': isResizing },
    className
  );

  return (
    <div className={classNames} data-component="table-sort-indicator" data-testid="sort-indicator">
      {renderIcon(isHovered, direction)}
    </div>
  );
};

export type { Props as SortIndicatorProps };

export default SortIndicator;
