import type { FC } from 'react';

import { ContactName, getFullName } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  openedBy: ContactName;
  resolvedBy?: Optional<ContactName>;
  startedBy?: Optional<ContactName>;
}

const IssueManagers: FC<Props> = ({ openedBy, startedBy, resolvedBy }) => {
  const lineClassNames: string = 'text-sm text-grey-base leading-5 whitespace-nowrap';
  const contactClassNames: string = 'font-bold ml-1 text-grey-dark';

  return (
    <>
      <div className={lineClassNames}>
        <span>Opened by:</span>
        <span className={contactClassNames}>{getFullName(openedBy)}</span>
      </div>
      {startedBy && (
        <div className={lineClassNames}>
          <span>Investigation by:</span>
          <span className={contactClassNames}>{getFullName(startedBy)}</span>
        </div>
      )}
      {resolvedBy && (
        <div className={lineClassNames}>
          <span>Resolved by:</span>
          <span className={contactClassNames}>{getFullName(resolvedBy)}</span>
        </div>
      )}
    </>
  );
};

export default IssueManagers;
