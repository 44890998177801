import type { FC } from 'react';

import { FormInput, FormRadioGroup, useForm } from '@zen/Components';

import { LocationLink, locationLinkOptions } from './helpers';

const CompanyNameFields: FC = () => {
  const { values } = useForm<{ linkType: LocationLink }>();
  const isCompanyNameVisible = values.linkType === LocationLink.OTHER_COMPANY;

  return (
    <>
      <FormRadioGroup
        className="col-span-8"
        label="This location is linked to:"
        name="linkType"
        options={locationLinkOptions}
        radioAlignment="column"
      />
      {isCompanyNameVisible && <FormInput className="col-span-8" label="Company name" name="companyName" />}
    </>
  );
};

export default CompanyNameFields;
