import cx from 'classnames';
import type { FC, SyntheticEvent } from 'react';

import type { PageInfo } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingParentAttribute } from '@zen/utils/tracking';

import Button from '../Button';

interface Props {
  className?: string;
  pageInfo: PageInfo;
}

export const displayName = 'Design System/Pagination';

const Pagination: FC<Props> = ({ className, pageInfo: { hasNextPage, hasPreviousPage, onNext, onPrevious } }) => {
  const { trackEvent } = useTracking();

  if (!hasPreviousPage && !hasNextPage) {
    return null;
  }

  const createClickHandler = (handler?: () => void): ((event: SyntheticEvent) => void) => {
    return (event: SyntheticEvent): void => {
      const trackingEvent = createTrackingEvent(displayName, 'click', event);

      trackEvent(trackingEvent);
      handler?.();
    };
  };
  const classNames: string = cx('flex justify-between w-full space-x-2 text-sm', className);

  return (
    <div
      className={classNames}
      data-component="pagination"
      data-testid="pagination"
      {...createTrackingParentAttribute('pagination')}
    >
      <Button
        disabled={!hasPreviousPage}
        iconLeft="zicon-arrow-left"
        onClick={createClickHandler(onPrevious)}
        size="compact"
        variant="ghost"
      >
        Previous
      </Button>
      <Button
        disabled={!hasNextPage}
        iconRight="zicon-arrow-right"
        onClick={createClickHandler(onNext)}
        size="compact"
        variant="ghost"
      >
        Next
      </Button>
    </div>
  );
};

export type { Props as PaginationProps };

export default Pagination;
