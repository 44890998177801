import type { QueryHookOptions, QueryResult } from '@apollo/client';
import { useEffect } from 'react';

import { useWindowFocus } from '@zen/utils/hooks/useWindowFocus';

const useFocusAwarePolling = (
  useQuery: (baseOptions?: QueryHookOptions) => QueryResult,
  queryOptions: QueryHookOptions & { pollInterval: number }
): QueryResult => {
  const { pollInterval, ...otherOptions } = queryOptions;
  const result: QueryResult = useQuery(otherOptions);

  const { refetch, startPolling, stopPolling } = result;
  const { isWindowFocused } = useWindowFocus();

  useEffect(() => {
    if (!isWindowFocused) {
      stopPolling();
    } else {
      if (!otherOptions.skip) {
        refetch?.();
      }
      startPolling(pollInterval);
    }
  }, [isWindowFocused, pollInterval, refetch, startPolling, stopPolling]);

  return result;
};

export { useFocusAwarePolling };
