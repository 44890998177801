import cx from 'classnames';
import type { FC } from 'react';

import { IconButton } from '@zen/DesignSystem';

const GuidesLink: FC = () => {
  const url: string = 'https://help.zencargo.com';
  const classNames: string = cx('flex items-center h-full justify-center');

  return (
    <div>
      <a className={classNames} data-component="guides-link" data-testid="guides-link" href={url} target="_blank">
        <IconButton
          className="text-grey-base"
          icon="zicon-help"
          popoverPlacement="bottom-end"
          title="Platform guide"
          variant="ghost"
        />
      </a>
      <span className="intercom-product-tour-hidden-initializer" />
    </div>
  );
};

export default GuidesLink;
