import type { ReactNode } from 'react';

import { Pill, type TableColumn, TableLink } from '@zen/DesignSystem';

import type { AccountLocationSummary } from '../types';
import AccountLocationsActions from './AccountLocationsActions';
import DeleteLocationButton from './DeleteLocationModalButton';

interface ColumConfiguration {
  accountId: string;
  pathname: string;
}

const getColumnsConfiguration = ({ accountId, pathname }: ColumConfiguration): TableColumn<AccountLocationSummary>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { addressLabel, name, id: locationId }: AccountLocationSummary): ReactNode => {
        return <TableLink linkTo={`${pathname}/${locationId}`}>{name || addressLabel}</TableLink>;
      }
    },
    {
      key: 'isRegisteredLegalAddress',
      sortable: false,
      title: '',
      render: (_, { isRegisteredLegalAddress }: AccountLocationSummary): ReactNode => {
        if (!isRegisteredLegalAddress) {
          return null;
        }

        return (
          <Pill className="capitalize" type="pending">
            HQ
          </Pill>
        );
      },
      width: 80
    },
    {
      key: 'companyName',
      title: 'Linked to'
    },
    {
      key: 'actions',
      title: '',
      render: (_, { companyName, id, name, permissions }: AccountLocationSummary): ReactNode => {
        return (
          <>
            {permissions?.canArchive?.value && <DeleteLocationButton accountId={accountId} locationId={id} locationName={name} />}
            {permissions?.canEdit?.value && (
              <AccountLocationsActions accountId={accountId} companyName={companyName} locationId={id} />
            )}
          </>
        );
      },
      sortable: false,
      width: '5%'
    }
  ];
};

export default getColumnsConfiguration;
