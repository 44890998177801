import { isEqual } from 'lodash';
import { object, ObjectSchema, string } from 'yup';

import type { ModeOfTransport } from '@zen/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { quantityValidator } from '../../cargo.validation';
import { getLooseCargoFieldsValidation } from '../../LooseCargo/LooseCargoForm/looseCargoForm.validation';
import type { LooseFormInitialValues } from '../../LooseCargo/LooseFormFields/types';

const isRequired: boolean = true;
const maxQuantity: number = 50;

export const fullContainerFormValidationSchema: ObjectSchema<{}> = object().shape({
  type: string().nullable().required('Please select size.'),
  containerVariant: string().nullable().required('Please select container variant.'),
  quantity: quantityValidator(isRequired, maxQuantity)
});

export const getFullContainerFormValidationSchema = (
  initialLooseValues?: LooseFormInitialValues['loose'],
  modeOfTransport?: Nullable<ModeOfTransport>
) => {
  return object().shape({
    type: string().nullable().required('Please select size.'),
    containerVariant: string().nullable().required('Please select container variant.'),
    quantity: quantityValidator(isRequired, maxQuantity),
    loose: object()
      .nullable()
      .when({
        is: (val: Optional<LooseFormInitialValues>) => !isEqual(val, initialLooseValues),
        then: () => getLooseCargoFieldsValidation(modeOfTransport || null, false)
      })
  });
};
