import { FunctionComponent, lazy, LazyExoticComponent, PropsWithChildren, PropsWithRef, Suspense } from 'react';

import ErrorBoundary from '@zen/Components/ErrorBoundary';
import { Loading } from '@zen/DesignSystem';

const loadable = <K extends {}, T extends PropsWithChildren<K> & PropsWithRef<K>>(
  importCallback: () => Promise<{ default: FunctionComponent<K> }>
) => {
  const LoadedComponent: LazyExoticComponent<FunctionComponent<K>> = lazy(importCallback);

  return (props: T) => (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <LoadedComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export default loadable;
