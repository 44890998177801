import cx from 'classnames';

import type { Optional } from '@zen/utils/typescript';

import { ShippingMilestoneDateTypeEnum } from '../../types';

interface Params {
  canManageDates: boolean;
  canManageDatesCurrently: boolean;
  dateType: Optional<ShippingMilestoneDateTypeEnum>;
  hasDate: boolean;
  hasDelay: boolean;
  isPopoverVisible: boolean;
}

export const getClassNames = (params: Params) => {
  const { dateType, isPopoverVisible, canManageDates, canManageDatesCurrently, hasDate, hasDelay } = params;

  const isDateEditable: boolean = canManageDates && canManageDatesCurrently;

  return cx(
    {
      'opacity-0 text-grey-light': !isPopoverVisible && !hasDate && !isDateEditable,
      'text-grey-light': !isPopoverVisible && !hasDate && isDateEditable,
      'hover:opacity-100 w-full': !hasDate,
      italic: dateType === ShippingMilestoneDateTypeEnum.DRAFT,
      'text-red-dark': hasDate && hasDelay,
      'text-grey-dark': hasDate && !hasDelay,
      'hover:border hover:border-solid hover:border-grey-lighter': !hasDate && canManageDates && !canManageDatesCurrently,
      'border border-solid border-transparent': !isPopoverVisible,
      'bg-white border-solid border border-azure-base text-grey-dark': isPopoverVisible,
      'hover:bg-grey-dark/[.08] hover:text-grey-base': isDateEditable && !isPopoverVisible,
      'cursor-pointer flex-1': isDateEditable
    },
    'py-2 px-3 rounded transition-all duration-300'
  );
};
