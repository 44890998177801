import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateOceanChargeItemNameMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateDefaultChargeItemNameInput;
}>;

export type UpdateOceanChargeItemNameMutationResult = { __typename?: 'Mutation' } & {
  updateDefaultChargeItemName?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateDefaultChargeItemPayload' } & {
      errors?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>
      >;
    }
  >;
};

export const UpdateOceanChargeItemNameDocument = /* #__PURE__ */ gql`
  mutation updateOceanChargeItemName($input: UpdateDefaultChargeItemNameInput!) {
    updateDefaultChargeItemName(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateOceanChargeItemNameMutation__
 *
 * To run a mutation, you first call `useUpdateOceanChargeItemNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOceanChargeItemNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOceanChargeItemNameMutation, { data, loading, error }] = useUpdateOceanChargeItemNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOceanChargeItemNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOceanChargeItemNameMutationResult, UpdateOceanChargeItemNameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateOceanChargeItemNameMutationResult, UpdateOceanChargeItemNameMutationVariables>(
    UpdateOceanChargeItemNameDocument,
    options
  );
}
export type UpdateOceanChargeItemNameMutationHookResult = ReturnType<typeof useUpdateOceanChargeItemNameMutation>;
