import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersCreateOrderMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateOrderInput;
}>;

export type PurchaseOrdersCreateOrderMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersCreateOrder?: GraphQLTypes.Maybe<
    { __typename?: 'CreateOrderPayload' } & {
      purchaseOrder?: GraphQLTypes.Maybe<{ __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const PurchaseOrdersCreateOrderDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersCreateOrder($input: CreateOrderInput!) {
    purchaseOrdersCreateOrder(input: $input) {
      purchaseOrder {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __usePurchaseOrdersCreateOrderMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersCreateOrderMutation, { data, loading, error }] = usePurchaseOrdersCreateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersCreateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersCreateOrderMutationResult, PurchaseOrdersCreateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersCreateOrderMutationResult, PurchaseOrdersCreateOrderMutationVariables>(
    PurchaseOrdersCreateOrderDocument,
    options
  );
}
export type PurchaseOrdersCreateOrderMutationHookResult = ReturnType<typeof usePurchaseOrdersCreateOrderMutation>;
