import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductsArchiveProductMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveProductInput;
}>;

export type ProductsArchiveProductMutationResult = { __typename?: 'Mutation' } & {
  productsArchiveProduct?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveProductPayload' } & {
      product?: GraphQLTypes.Maybe<{ __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'archived'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const ProductsArchiveProductDocument = /* #__PURE__ */ gql`
  mutation productsArchiveProduct($input: ArchiveProductInput!) {
    productsArchiveProduct(input: $input) {
      product {
        id
        archived
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useProductsArchiveProductMutation__
 *
 * To run a mutation, you first call `useProductsArchiveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProductsArchiveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [productsArchiveProductMutation, { data, loading, error }] = useProductsArchiveProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useProductsArchiveProductMutation(
  baseOptions?: Apollo.MutationHookOptions<ProductsArchiveProductMutationResult, ProductsArchiveProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ProductsArchiveProductMutationResult, ProductsArchiveProductMutationVariables>(
    ProductsArchiveProductDocument,
    options
  );
}
export type ProductsArchiveProductMutationHookResult = ReturnType<typeof useProductsArchiveProductMutation>;
