import type { FC } from 'react';

import type { FormWeightInputProps } from '@zen/Components';
import { FormWeightInput } from '@zen/Components';
import type { Option } from '@zen/DesignSystem';

import { CargoWeightUnitEnum } from './types';

interface Props extends Omit<FormWeightInputProps<CargoWeightUnitEnum>, 'options' | 'hasUnit'> {
  isCoreCargo?: boolean;
}

const metricOptions: Option<CargoWeightUnitEnum>[] = [
  {
    label: 'kg',
    value: CargoWeightUnitEnum.KG
  },
  {
    label: 'lbs',
    value: CargoWeightUnitEnum.LBS
  }
];

const FormCargoWeightInput: FC<Props> = ({ isCoreCargo, ...rest }) => {
  return <FormWeightInput {...rest} hasUnit={isCoreCargo} options={metricOptions} />;
};

export default FormCargoWeightInput;
