import { get } from 'lodash';
import type { FC, ReactNode } from 'react';

import { Button, DatePicker, Icon } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { PriceCalculationMilestone } from '../types';
import { useResetPriceCalculationDateMutation, useSetPriceCalculationDateMutation } from './graphql';

interface Props {
  date: Optional<string>;
  milestone: Nullable<PriceCalculationMilestone>;
  onChange: () => void;
  zencargoReference: string;
}

const PriceCalculationDatePicker: FC<Props> = ({ date, milestone, onChange, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [setPriceCalculationDate] = useSetPriceCalculationDateMutation();
  const [resetPriceCalculationDate] = useResetPriceCalculationDateMutation();
  const queriesToRefetch: string[] = ['priceCalculation'];

  const showResetButton: boolean = milestone === PriceCalculationMilestone.CUSTOM;

  const handleChange = (updatedDate: Nullable<string>): void => {
    onChange();

    if (!updatedDate) {
      return;
    }

    performMutation({
      mutationFn: () =>
        setPriceCalculationDate({
          variables: { input: { date: updatedDate, zencargoReference } },
          refetchQueries: queriesToRefetch
        }),
      onError: (errors): void => {
        addError(get(errors, '[0].message', "We couldn't update the date."));
      },
      onSuccess: () => addSuccess('Price calculation date has been updated.')
    });
  };

  const handleReset = (): void => {
    performMutation({
      mutationFn: () =>
        resetPriceCalculationDate({
          refetchQueries: queriesToRefetch,
          variables: {
            input: {
              zencargoReference
            }
          }
        }),
      onError: (errors): void => {
        addError(get(errors, '[0].message', "We couldn't revert the date."));
      },
      onSuccess: () => {
        addSuccess('Price calculation date was reverted.');
        onChange();
      }
    });
  };

  const resetButton: ReactNode = (
    <div>
      <Button className="grow-0" iconLeft="zicon-delay" onClick={handleReset} variant="secondary">
        Revert to calculated date
      </Button>
    </div>
  );

  return (
    <>
      <DatePicker className="!border-none" mode="single" onChange={handleChange} selected={date || ''} />
      <div className="flex flex-col gap-3 p-4 border-t border-solid border-grey-lighter max-w-[19rem]">
        <div className="flex gap-3 py-3 text-sm text-grey-base max-w-[19rem] bg-white text-left">
          <Icon className="mt-1 text-base" icon="zicon-information" />
          Changes of price calculation date will be reflected on the linked rate card.
        </div>
        {showResetButton && resetButton}
      </div>
    </>
  );
};

export type { Props as PriceCalculationDatePickerProps };

export default PriceCalculationDatePicker;
