import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetShippingDelayReasonListQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetShippingDelayReasonListQueryResult = { __typename?: 'Query' } & {
  shippingDelayReasons?: GraphQLTypes.Maybe<
    { __typename?: 'ShippingDelayReasonConnection' } & Pick<GraphQLTypes.ShippingDelayReasonConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ShippingDelayReason' } & Pick<
                GraphQLTypes.ShippingDelayReason,
                'id' | 'reason' | 'description' | 'responsibleParty'
              > & {
                  lastUpdatedBy?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'>
                  >;
                }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const GetShippingDelayReasonListDocument = /* #__PURE__ */ gql`
  query getShippingDelayReasonList($after: String, $before: String, $first: Int, $last: Int, $order: SortInput) {
    shippingDelayReasons(after: $after, before: $before, first: $first, last: $last, order: $order) {
      nodes {
        id
        reason
        description
        responsibleParty
        lastUpdatedBy {
          id
          firstName
          lastName
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetShippingDelayReasonListQuery__
 *
 * To run a query within a React component, call `useGetShippingDelayReasonListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShippingDelayReasonListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShippingDelayReasonListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetShippingDelayReasonListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetShippingDelayReasonListQueryResult, GetShippingDelayReasonListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetShippingDelayReasonListQueryResult, GetShippingDelayReasonListQueryVariables>(
    GetShippingDelayReasonListDocument,
    options
  );
}
export function useGetShippingDelayReasonListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetShippingDelayReasonListQueryResult, GetShippingDelayReasonListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetShippingDelayReasonListQueryResult, GetShippingDelayReasonListQueryVariables>(
    GetShippingDelayReasonListDocument,
    options
  );
}
export type GetShippingDelayReasonListQueryHookResult = ReturnType<typeof useGetShippingDelayReasonListQuery>;
export type GetShippingDelayReasonListLazyQueryHookResult = ReturnType<typeof useGetShippingDelayReasonListLazyQuery>;
