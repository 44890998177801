import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Dialog, Table } from '@zen/DesignSystem';
import { opsSettingsRoutes } from '@zen/Routes';
import { SortingOrder, SortInput } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation } from '../graphql';
import type { OriginAgentAssignmentRule } from '../types';
import { getColumnsConfiguration } from './agentAssignmentRulesListConfiguration';
import AgentAssignmentRulesFilters from './Filters/AgentAssignmentRulesFilters';
import useAgentAssignmentRules from './hooks/useAgentAssignmentRules';

const initialSortOrder: SortInput = {
  field: 'createdAt',
  direction: SortingOrder.DESC
};

const AssignmentRulesList: FC = () => {
  const { navigate } = useNavigationHistory();

  const [order, setOrder] = useState<SortInput>(initialSortOrder);
  const { addError, addSuccess } = useNotification();
  const { nodes, totalCount, loading: isLoading, error, refetch, paginationInfo } = useAgentAssignmentRules(order);
  const [removeAgentAssignmentRule] = usePurchaseOrdersRemoveOriginAgentAssignmentRuleMutation();
  const [currentAgentAssignmentRuleId, setCurrentAgentAssignmentRuleId] = useState<string>('');

  const actionButton: ReactNode = (
    <Button onClick={() => navigate(opsSettingsRoutes.agentAssingmentRulesCreate.getUrl())} variant="secondary">
      New assignment rule
    </Button>
  );

  const openDeleteConfirmationModal = (agentAssignmentRuleId: string): void =>
    setCurrentAgentAssignmentRuleId(agentAssignmentRuleId);

  const handleDeleteAgentAssignmentRule = (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        removeAgentAssignmentRule({
          variables: {
            input: {
              id: currentAgentAssignmentRuleId
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Assingment rule removed.');
        refetch();
      }
    });
  };

  const handleRuleEdit = (agentAssignmentRuleId: string): void =>
    navigate(opsSettingsRoutes.agentAssingmentRulesUpdate.getUrl(agentAssignmentRuleId));

  const columns = getColumnsConfiguration(openDeleteConfirmationModal, handleRuleEdit);

  return (
    <Page
      actionButtons={actionButton}
      defaultBackUrl={opsSettingsRoutes.opsSettingsIndex.getUrl()}
      tagline="A list of all agent assignment rules."
      title="Agent assignment rules"
    >
      <AgentAssignmentRulesFilters />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={isLoading}
        loadingComponent={<SkeletonTableLoading columns={columns} order={order} tableId="agentAssignmentRules" />}
      >
        {(agentAssignmentRules: OriginAgentAssignmentRule[]) => (
          <>
            <Dialog
              isOpen={!!currentAgentAssignmentRuleId}
              message="Do you want to remove this assignment rule?"
              onClose={() => setCurrentAgentAssignmentRuleId('')}
              onConfirm={() => {
                handleDeleteAgentAssignmentRule();
                setCurrentAgentAssignmentRuleId('');
              }}
            />
            <Table<OriginAgentAssignmentRule>
              className="mt-6"
              columns={columns}
              data={agentAssignmentRules}
              onOrderChange={setOrder}
              order={order}
              paginationInfo={paginationInfo}
              tableId="agentAssignmentRules"
              totalCountConfig={{
                totalCount,
                entityName: 'rule'
              }}
            />
          </>
        )}
      </QueryHandler>
    </Page>
  );
};

export default AssignmentRulesList;
