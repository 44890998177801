import type { ReactNode } from 'react';

import type { Terminal, TerminalTypeValue } from '@zen/types';

import { highlightQuery } from '../Form/utils';
import TerminalIcon from '../TerminalIcon';

const getTerminalIcons = (types: TerminalTypeValue[]) =>
  types?.map(
    (terminalType: TerminalTypeValue, index: number): ReactNode => (
      <TerminalIcon key={index} className="ml-2" terminalType={terminalType} />
    )
  );

export const getTerminalOptionLabel = (terminal: Terminal, inputValue: string = ''): ReactNode => (
  <div className="flex justify-between items-center">
    <div>{highlightQuery(terminal.label || '', inputValue)}</div>
    {terminal.terminalTypes && <div className="flex">{getTerminalIcons(terminal.terminalTypes)}</div>}
  </div>
);
