import type { ArrayElement, DeepNonNullable, Optional } from '@zen/utils/typescript';

import type { GetLotsQueryResult } from './graphql';

export { Status } from '@zen/graphql/types.generated';

export interface CargoLot {
  cargoReadyDate: OrderLotCargoReadyDate;
  estimatedDeliveryDate: Optional<string>;
  id: string;
  orderDate: Optional<string>;
  orderId: Optional<string>;
  orderNumber: Optional<string>;
  product: DeepNonNullable<OrderLot, 'orderedLineItem'>['orderedLineItem']['product'];
  progressStatus: ArrayElement<OrderLot['stages']>;
  quantityFulfilled: Optional<number>;
  quantityOrdered: Optional<number>;
}

export type OrderLot = DeepNonNullable<
  DeepNonNullable<GetLotsQueryResult, 'nodes'>['bookings']['nodes'],
  'nodes'
>['lots']['nodes'];
export type OrderLotCargoReadyDate = OrderLot['cargoReadyDate'];
export type OrderLotCargoReadyDateHistory = Pick<
  DeepNonNullable<NonNullable<OrderLotCargoReadyDate>, 'history'>['history'],
  'reasonForChange' | 'date'
>;

export const CargoLotTracking: string = 'CargoLot';

export enum CargoLotTrackingAction {
  INLINE_SCHEDULE_UPDATE = 'InlineScheduleUpdate',
  MULTIPLE_SCHEDULE_UPDATE = 'MultipleScheduleUpdate'
}
