import { isEmpty } from 'lodash';

import type { OptionListSection } from './types';

export const getFilteredList = <T>(list: OptionListSection<T>[], search: string): OptionListSection<T>[] => {
  const filteredSectionItems: OptionListSection<T>[] = list.map((section) => ({
    ...section,
    items: section.items.filter((item) => item.label?.toString()?.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }));

  return filteredSectionItems.filter((section) => !isEmpty(section.items));
};
