import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ArchiveBookingDocumentMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveInput;
}>;

export type ArchiveBookingDocumentMutationResult = { __typename?: 'Mutation' } & {
  bookingDocumentArchive?: GraphQLTypes.Maybe<
    { __typename?: 'ArchivePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const ArchiveBookingDocumentDocument = /* #__PURE__ */ gql`
  mutation archiveBookingDocument($input: ArchiveInput!) {
    bookingDocumentArchive(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useArchiveBookingDocumentMutation__
 *
 * To run a mutation, you first call `useArchiveBookingDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveBookingDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveBookingDocumentMutation, { data, loading, error }] = useArchiveBookingDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveBookingDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveBookingDocumentMutationResult, ArchiveBookingDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ArchiveBookingDocumentMutationResult, ArchiveBookingDocumentMutationVariables>(
    ArchiveBookingDocumentDocument,
    options
  );
}
export type ArchiveBookingDocumentMutationHookResult = ReturnType<typeof useArchiveBookingDocumentMutation>;
