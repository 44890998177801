import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ConfirmJourneyScheduleMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ConfirmJourneyScheduleInput;
}>;

export type ConfirmJourneyScheduleMutationResult = { __typename?: 'Mutation' } & {
  confirmJourneySchedule: { __typename?: 'ConfirmJourneySchedulePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const ConfirmJourneyScheduleDocument = /* #__PURE__ */ gql`
  mutation confirmJourneySchedule($input: ConfirmJourneyScheduleInput!) {
    confirmJourneySchedule(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useConfirmJourneyScheduleMutation__
 *
 * To run a mutation, you first call `useConfirmJourneyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmJourneyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmJourneyScheduleMutation, { data, loading, error }] = useConfirmJourneyScheduleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmJourneyScheduleMutation(
  baseOptions?: Apollo.MutationHookOptions<ConfirmJourneyScheduleMutationResult, ConfirmJourneyScheduleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ConfirmJourneyScheduleMutationResult, ConfirmJourneyScheduleMutationVariables>(
    ConfirmJourneyScheduleDocument,
    options
  );
}
export type ConfirmJourneyScheduleMutationHookResult = ReturnType<typeof useConfirmJourneyScheduleMutation>;
