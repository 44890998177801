import { createContext } from 'react';

interface InitialContext {
  displayMask: boolean;
  idsToUpdate: string[];
  isSelected: (id: string) => boolean;
  selectLot: (id: string) => void;
  selectLots: (ids: string[]) => void;
}

const initialContext = {
  selectLot: () => {},
  selectLots: () => {},
  isSelected: () => false,
  displayMask: false,
  idsToUpdate: []
};

const UpdateCRDContext = createContext<InitialContext>(initialContext);

export default UpdateCRDContext;
