const NEW_BOOKING_RELATIVE_INDEX_PATH = 'new-booking';

export const NEW_BOOKING_ROUTE_PREFIX = `/dashboard/${NEW_BOOKING_RELATIVE_INDEX_PATH}`;

interface NewBookingRoutes {
  newBookingFromZencargoReference: {
    getUrl: (zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  newBookingIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const newBookingRoutes: NewBookingRoutes = {
  newBookingIndex: {
    path: `${NEW_BOOKING_ROUTE_PREFIX}/*`,
    getUrl: () => NEW_BOOKING_ROUTE_PREFIX,
    relativePath: `${NEW_BOOKING_RELATIVE_INDEX_PATH}/*`
  },
  newBookingFromZencargoReference: {
    path: `${NEW_BOOKING_ROUTE_PREFIX}/:fromZencargoReference?`,
    getUrl: (zencargoReference: string) => `${NEW_BOOKING_ROUTE_PREFIX}/${zencargoReference}`,
    relativePath: `${NEW_BOOKING_RELATIVE_INDEX_PATH}/:fromZencargoReference?`
  }
};

export default newBookingRoutes;
