import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateOriginAndDestinationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AssignInput;
}>;

export type UpdateOriginAndDestinationMutationResult = { __typename?: 'Mutation' } & {
  networksAssign?: GraphQLTypes.Maybe<
    { __typename?: 'AssignPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateOriginAndDestinationDocument = /* #__PURE__ */ gql`
  mutation updateOriginAndDestination($input: AssignInput!) {
    networksAssign(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateOriginAndDestinationMutation__
 *
 * To run a mutation, you first call `useUpdateOriginAndDestinationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOriginAndDestinationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOriginAndDestinationMutation, { data, loading, error }] = useUpdateOriginAndDestinationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOriginAndDestinationMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOriginAndDestinationMutationResult, UpdateOriginAndDestinationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateOriginAndDestinationMutationResult, UpdateOriginAndDestinationMutationVariables>(
    UpdateOriginAndDestinationDocument,
    options
  );
}
export type UpdateOriginAndDestinationMutationHookResult = ReturnType<typeof useUpdateOriginAndDestinationMutation>;
