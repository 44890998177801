import type { Optional } from '@zen/utils/typescript';

import { PagePosition, PaginatedVariables, PaginationDirection } from './types';

export const getNextPageVariables = (limit?: Optional<number>, endCursor?: string): PaginatedVariables => ({
  [PaginationDirection.AFTER]: endCursor,
  first: limit,
  last: undefined
});

export const getPreviousPageVariables = (limit?: Optional<number>, startCursor?: string): PaginatedVariables => ({
  [PaginationDirection.BEFORE]: startCursor,
  last: limit,
  first: undefined
});

export const defaultPageInfo: PagePosition = {
  endCursor: '',
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: ''
};
