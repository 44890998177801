import pDebounce from 'p-debounce';
import type { FC, ReactNode } from 'react';

import { useFinanceGetAccrualTemplatesLazyQuery } from '@zen/Accruals/graphql/getAccrualTemplates.generated';
import FormAsyncSelect from '@zen/Components/Form/FormAsyncSelect';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import { highlightQuery } from '@zen/Components/Form/utils';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';

import type { AccrualTemplate } from '../types';

const FormAccrualTemplateSelect: FC<FormFieldProps> = ({ label = 'Select template', name }) => {
  const [getAccrualTemplates] = useFinanceGetAccrualTemplatesLazyQuery();

  const handleInputChange = async (query: string): Promise<AccrualTemplate[]> => {
    const { data } = await getAccrualTemplates({
      variables: {
        textContains: query
      }
    });

    return (data?.accrualTemplates?.nodes || []) as AccrualTemplate[];
  };

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);

  const getLocationOptionLabel = (template: AccrualTemplate, inputValue: string = ''): ReactNode => {
    return highlightQuery(template.templateName || '', inputValue);
  };

  return (
    <FormAsyncSelect
      formatOptionLabel={getLocationOptionLabel}
      isRequired={true}
      label={label}
      loadOptions={debouncedHandleInputChange}
      name={name}
      placeholder="Select..."
    />
  );
};

export default FormAccrualTemplateSelect;
