import cx from 'classnames';
import type { FC } from 'react';

import { Banner } from '@zen/DesignSystem';

interface Props {
  className?: string;
}

const ArchivedShipmentBanner: FC<Props> = ({ className }) => {
  const classNames: string = cx('p-4 rounded', className);

  return (
    <Banner
      className={classNames}
      message="This shipment has been archived. Currently, archived shipments cannot be unarchived."
      type="error"
    />
  );
};

export default ArchivedShipmentBanner;
