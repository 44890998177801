import type { FC } from 'react';

import InlineEditableField from '@zen/Components/InlineEditableField';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateBookingForwarderReferenceMutation } from './graphql';

interface Props {
  forwarderReference: string;
  label?: string;
  zencargoReference: string;
}

const ForwarderReferenceInlineField: FC<Props> = ({ forwarderReference, label, zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const [updateBookingForwarderReference] = useUpdateBookingForwarderReferenceMutation();

  const handleForwarderReferenceChange = async (forwarderRef: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateBookingForwarderReference({
          variables: {
            input: { zencargoReference, forwarderReference: forwarderRef }
          }
        }),
      onSuccess: () => addSuccess('Forwarder reference updated.'),
      onError: () => addError('Could not update Forwarder reference.')
    });
  };

  return (
    <div className="-ml-3">
      <InlineEditableField
        label={label}
        name="forwarder-reference"
        onUpdate={handleForwarderReferenceChange}
        placeholder="Enter forwarder reference"
        value={forwarderReference}
      />
    </div>
  );
};

export default ForwarderReferenceInlineField;
