import { useFeatureIsOn } from '@growthbook/growthbook-react';
import type { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useSwitchAccount from '@zen/Auth/hooks/useSwitchAccount';
import { useTrackAccountSwitch } from '@zen/Components/VisitedAccounts';
import type { Option } from '@zen/DesignSystem';
import { opsDashboardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import useLegacyAccounts, { Account } from '@zen/utils/hooks/useAccounts';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import { extractFirstLevelPath } from '@zen/utils/routing';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import { Form, FormSelect } from '../Form';
import FormAccountSelect from '../Form/FormAccountSelect';
import { validationSchema } from './accountSwitcherForm.validation';

interface Props {
  label?: ReactNode;
  onAccountChange?: () => void;
  submitOnChange?: boolean;
}

const sortOptions = (array: Option<string>[]) =>
  array.sort((left, right) => {
    return left.label.toLowerCase() < right.label.toLowerCase() ? -1 : 1;
  });

const AccountSwitcherForm: FC<Props> = (props) => {
  const newCreateAccountFlagIsOn = useFeatureIsOn('create-account');
  const { onAccountChange, submitOnChange = false, label = 'Account' } = props;
  const { data: legacyAccounts = [], isLoading } = useLegacyAccounts();
  const { accountUuid } = useAccount();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { navigateBack } = useNavigationHistory();
  const { switchToAccount } = useSwitchAccount();
  const trackAccountSwitch = useTrackAccountSwitch();

  const backUrl: string = opsDashboardRoutes.dashboardIndex.getUrl();

  const handleSubmit = (values: { accountId: string }) => {
    const { accountId } = values;

    switchToAccount(accountId);

    return Promise.resolve({ ok: true, error: null });
  };

  const handleSuccess = () => {
    navigateBack(backUrl, (entry) => ({ ...entry, pathname: extractFirstLevelPath(entry.pathname) }));
  };

  const handleOnChange = (newAccountId: Nullable<string>) => {
    onAccountChange?.();

    if (!submitOnChange || newAccountId === accountUuid) {
      return;
    }

    const companyName: string =
      legacyAccounts.find((account: Account) => account?.uuid === newAccountId)?.registeredCompanyName || '';

    if (companyName) {
      trackAccountSwitch(companyName, 'accountSwitcherForm');
    }
    if (newAccountId) {
      switchToAccount(newAccountId);
    }
    navigate(extractFirstLevelPath(pathname));
  };

  const options: Undefinable<Option<string>[]> = legacyAccounts?.map((account: Account) => {
    return {
      value: account?.uuid || '',
      label: account.registeredCompanyName
    };
  });

  const initialValues = {
    accountId: accountUuid
  };

  const renderSelectAccount = () => {
    if (newCreateAccountFlagIsOn) {
      return <FormAccountSelect label="Account" name="accountId" onChange={handleOnChange} />;
    }

    return (
      <FormSelect
        isLoading={isLoading}
        isSearchable={true}
        label={label}
        name="accountId"
        onChange={handleOnChange}
        options={sortOptions(options)}
        placeholder="Search for an account..."
      />
    );
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={submitOnChange ? () => null : undefined}
      formName="AccountSwitcherForm"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
      validationSchema={validationSchema}
    >
      {renderSelectAccount}
    </Form>
  );
};

export default AccountSwitcherForm;
