import { useEffect, useState } from 'react';

// Note: Inspired by https://github.com/jpalumickas/use-window-focus/blob/main/src/index.ts.
const useWindowFocus = (): { isWindowFocused: boolean } => {
  const [isWindowFocused, setIsWindowFocused] = useState(hasFocus()); // Focus for first render.

  useEffect(() => {
    setIsWindowFocused(hasFocus()); // Focus for following renders.

    const onFocus = () => setIsWindowFocused(true);
    const onBlur = () => setIsWindowFocused(false);

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return { isWindowFocused };
};

function hasFocus() {
  return document.hasFocus();
}

export { useWindowFocus };
