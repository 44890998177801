import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetAccrualQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  zencargoReference: GraphQLTypes.Scalars['String'];
  id: GraphQLTypes.Scalars['String'];
}>;

export type FinanceGetAccrualQueryResult = { __typename?: 'Query' } & {
  legacyAccount?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyAccount' } & {
      booking?: GraphQLTypes.Maybe<
        { __typename?: 'Booking' } & {
          accrual?: GraphQLTypes.Maybe<
            { __typename?: 'Accrual' } & Pick<
              GraphQLTypes.Accrual,
              | 'id'
              | 'invoicedOn'
              | 'invoiceCurrency'
              | 'invoiceExchangeRate'
              | 'invoiceRevenue'
              | 'revenueDate'
              | 'state'
              | 'totalCost'
              | 'totalRevenue'
              | 'totalMargin'
              | 'totalMarginPercentage'
              | 'zencargoReference'
            > & {
                canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canPublish: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                canUpdateInvoicedDate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                costItems: Array<
                  { __typename?: 'CostItem' } & Pick<GraphQLTypes.CostItem, 'chargeDetails' | 'id'> & {
                      cost: { __typename?: 'Cost' } & Pick<
                        GraphQLTypes.Cost,
                        | 'currency'
                        | 'exchangeRate'
                        | 'id'
                        | 'supplierName'
                        | 'totalGbp'
                        | 'totalLocalCcy'
                        | 'quantity'
                        | 'unitCost'
                      > & {
                          chargeType: { __typename?: 'ChargeType' } & Pick<
                            GraphQLTypes.ChargeType,
                            | 'chargeBasis'
                            | 'deletedAt'
                            | 'fortyFiveFtHc'
                            | 'fortyFtDv'
                            | 'fortyFtHc'
                            | 'hazardous'
                            | 'id'
                            | 'modeOfTransport'
                            | 'name'
                            | 'overweight'
                            | 'reefer'
                            | 'twentyFtDv'
                            | 'twentyFtHc'
                            | 'vatApplicable'
                          >;
                        };
                      margin: { __typename?: 'Margin' } & Pick<
                        GraphQLTypes.Margin,
                        'costId' | 'currency' | 'exchangeRate' | 'id' | 'percent' | 'totalGbp' | 'totalLocalCcy'
                      > & {
                          chargeType: { __typename?: 'ChargeType' } & Pick<
                            GraphQLTypes.ChargeType,
                            | 'chargeBasis'
                            | 'deletedAt'
                            | 'fortyFiveFtHc'
                            | 'fortyFtDv'
                            | 'fortyFtHc'
                            | 'hazardous'
                            | 'id'
                            | 'modeOfTransport'
                            | 'name'
                            | 'overweight'
                            | 'reefer'
                            | 'twentyFtDv'
                            | 'twentyFtHc'
                            | 'vatApplicable'
                          >;
                        };
                      revenue: { __typename?: 'Revenue' } & Pick<
                        GraphQLTypes.Revenue,
                        | 'combinedTotal'
                        | 'costId'
                        | 'currency'
                        | 'exchangeRate'
                        | 'id'
                        | 'marginAmount'
                        | 'totalGbp'
                        | 'quantity'
                        | 'unitCost'
                      > & {
                          chargeType: { __typename?: 'ChargeType' } & Pick<
                            GraphQLTypes.ChargeType,
                            | 'chargeBasis'
                            | 'deletedAt'
                            | 'fortyFiveFtHc'
                            | 'fortyFtDv'
                            | 'fortyFtHc'
                            | 'hazardous'
                            | 'id'
                            | 'modeOfTransport'
                            | 'name'
                            | 'overweight'
                            | 'reefer'
                            | 'twentyFtDv'
                            | 'twentyFtHc'
                            | 'vatApplicable'
                          >;
                        };
                    }
                >;
                publishedAt?: GraphQLTypes.Maybe<
                  { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                >;
                publishedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
                updatedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
                updatedByUserAt?: GraphQLTypes.Maybe<
                  { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'dateTime'>
                >;
              }
          >;
        }
      >;
    }
  >;
};

export const FinanceGetAccrualDocument = /* #__PURE__ */ gql`
  query financeGetAccrual($accountId: String!, $zencargoReference: String!, $id: String!) {
    legacyAccount(uuid: $accountId) {
      booking(zencargoReference: $zencargoReference) {
        accrual(id: $id) {
          canArchive {
            value
          }
          canPublish {
            value
          }
          canUpdate {
            value
          }
          canUpdateInvoicedDate {
            value
          }
          costItems {
            chargeDetails
            cost {
              chargeType {
                chargeBasis
                deletedAt
                fortyFiveFtHc
                fortyFtDv
                fortyFtHc
                hazardous
                id
                modeOfTransport
                name
                overweight
                reefer
                twentyFtDv
                twentyFtHc
                vatApplicable
              }
              currency
              exchangeRate
              id
              supplierName
              totalGbp
              totalLocalCcy
              quantity
              unitCost
            }
            id
            margin {
              chargeType {
                chargeBasis
                deletedAt
                fortyFiveFtHc
                fortyFtDv
                fortyFtHc
                hazardous
                id
                modeOfTransport
                name
                overweight
                reefer
                twentyFtDv
                twentyFtHc
                vatApplicable
              }
              costId
              currency
              exchangeRate
              id
              percent
              totalGbp
              totalLocalCcy
            }
            revenue {
              chargeType {
                chargeBasis
                deletedAt
                fortyFiveFtHc
                fortyFtDv
                fortyFtHc
                hazardous
                id
                modeOfTransport
                name
                overweight
                reefer
                twentyFtDv
                twentyFtHc
                vatApplicable
              }
              combinedTotal
              costId
              currency
              exchangeRate
              id
              marginAmount
              totalGbp
              quantity
              unitCost
            }
          }
          id
          invoicedOn
          invoiceCurrency
          invoiceExchangeRate
          invoiceRevenue
          publishedAt {
            date
            dateTime
          }
          publishedBy {
            username
          }
          revenueDate
          state
          updatedBy {
            username
          }
          updatedByUserAt {
            dateTime
          }
          totalCost
          totalRevenue
          totalMargin
          totalMarginPercentage
          zencargoReference
        }
      }
    }
  }
`;

/**
 * __useFinanceGetAccrualQuery__
 *
 * To run a query within a React component, call `useFinanceGetAccrualQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetAccrualQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetAccrualQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      zencargoReference: // value for 'zencargoReference'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinanceGetAccrualQuery(
  baseOptions: Apollo.QueryHookOptions<FinanceGetAccrualQueryResult, FinanceGetAccrualQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetAccrualQueryResult, FinanceGetAccrualQueryVariables>(FinanceGetAccrualDocument, options);
}
export function useFinanceGetAccrualLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FinanceGetAccrualQueryResult, FinanceGetAccrualQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetAccrualQueryResult, FinanceGetAccrualQueryVariables>(FinanceGetAccrualDocument, options);
}
export type FinanceGetAccrualQueryHookResult = ReturnType<typeof useFinanceGetAccrualQuery>;
export type FinanceGetAccrualLazyQueryHookResult = ReturnType<typeof useFinanceGetAccrualLazyQuery>;
