import type { FC } from 'react';

import type { Nullable } from '@zen/utils/typescript';

import StageName from '../StageName';
import type { StageNameEnum } from '../StageName/types';
import type { ModeOfTransport } from './types';

interface Props {
  stageName: StageNameEnum;
  statusUpdate?: Nullable<string>;
  transportMode: ModeOfTransport;
}

const ShipmentStageProgress: FC<Props> = ({ stageName, transportMode, statusUpdate }) => {
  return (
    <>
      <div className="text-grey-dark text-sm leading-5">
        <StageName name={stageName} transportMode={transportMode} />
      </div>
      {statusUpdate && (
        <div className="text-grey-base text-xs leading-4" data-testid="status-update">
          {statusUpdate}
        </div>
      )}
    </>
  );
};

export type { Props as ShipmentStageProgressProps };

export default ShipmentStageProgress;
