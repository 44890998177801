import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ProductListQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  query: GraphQLTypes.Scalars['String'];
  archived: GraphQLTypes.Scalars['Boolean'];
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type ProductListQueryResult = { __typename?: 'Query' } & {
  products: { __typename?: 'ProductConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode' | 'hsCode'> & {
              perUnitCbm?: GraphQLTypes.Maybe<{ __typename?: 'PerUnitCbm' } & Pick<GraphQLTypes.PerUnitCbm, 'value'>>;
              costPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>;
            }
        >
      >
    >;
    pageInfo: { __typename: 'PageInfo' } & Pick<
      GraphQLTypes.PageInfo,
      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
    >;
  };
};

export const ProductListDocument = /* #__PURE__ */ gql`
  query productList(
    $accountUuid: String!
    $query: String!
    $archived: Boolean!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    products(
      customerId: $accountUuid
      textContains: $query
      archived: $archived
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        id
        name
        skuCode
        hsCode
        perUnitCbm {
          value
        }
        costPrice {
          currency
          value
        }
      }
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useProductListQuery__
 *
 * To run a query within a React component, call `useProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductListQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      query: // value for 'query'
 *      archived: // value for 'archived'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProductListQuery(baseOptions: Apollo.QueryHookOptions<ProductListQueryResult, ProductListQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ProductListQueryResult, ProductListQueryVariables>(ProductListDocument, options);
}
export function useProductListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProductListQueryResult, ProductListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ProductListQueryResult, ProductListQueryVariables>(ProductListDocument, options);
}
export type ProductListQueryHookResult = ReturnType<typeof useProductListQuery>;
export type ProductListLazyQueryHookResult = ReturnType<typeof useProductListLazyQuery>;
