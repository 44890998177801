import getDistanceInMeters from 'haversine-distance';

import type { GeoCoordinates } from '@zen/Components/Map/types';

const DEFAULT_DISTANCE_THRESHOLD_IN_METERS = 200;

const isOutsideDistanceThreshold = (
  coordinatesA: GeoCoordinates,
  coordinatesB: GeoCoordinates,
  distanceThresholdInMeters: number = DEFAULT_DISTANCE_THRESHOLD_IN_METERS
): boolean => {
  const distanceInMeters: number = getDistanceInMeters(coordinatesA, coordinatesB);

  return distanceInMeters > distanceThresholdInMeters;
};

export { isOutsideDistanceThreshold };
