import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

interface Props {
  checked: boolean;
  children: ReactNode;
  className?: string;
  description?: string;
  disabled?: boolean;
  error?: boolean;
  name: string;
  onChange: (value: string) => void;
  type: 'default' | 'tile';
  value: string;
}

export const displayName: string = 'Design System/Input/RadioInput';

export const RadioInput: FC<Props> = ({
  checked,
  children,
  description,
  disabled,
  error = false,
  value = '',
  className = '',
  onChange,
  name,
  type
}) => {
  const { trackEvent } = useTracking();

  const radioContainerClasses: string = cx(
    {
      'text-grey-dark cursor-pointer': !disabled,
      'text-grey-base cursor-not-allowed': disabled,
      'p-4 hover:bg-azure-lightest active:bg-azure-lighter': type === 'tile'
    },
    'group/radio inline-flex items-top justify-start',
    className
  );

  const outerCircleClasses: string = cx(
    {
      'border-grey-light group-hover/radio:border-azure-base border group-hover/radio:border': !checked && !disabled && !error,
      'border-azure-base border': checked,
      'bg-white': !disabled && !error,
      'border-grey-lighter border bg-grey-lightest': disabled,
      'border-red-dark border': error
    },
    'w-4 h-4',
    'rounded-full circle border-solid',
    'flex items-center justify-center shrink-0',
    'mr-2.5 mt-0.5'
  );

  const innerCircleClasses: string = 'w-2 h-2 rounded-full border circle border-solid border-azure-base bg-azure-base';

  const handleChange = (event: SyntheticEvent): void => {
    if (disabled) {
      return;
    }
    const trackingEvent = createTrackingEvent(displayName, 'change', event);

    trackEvent(trackingEvent);

    onChange(value);
  };

  return (
    <label className={radioContainerClasses} {...createTrackingParentAttribute('radio-input')}>
      <div className={outerCircleClasses} data-testid="radio-input">
        {checked && <div className={innerCircleClasses} data-testid="check" />}
        <input
          checked={checked}
          className="hidden"
          data-component="radio-input"
          disabled={disabled}
          name={name}
          onChange={handleChange}
          readOnly={true}
          type="radio"
          value={value}
        />
      </div>
      <div className="text-sm leading-normal" {...createTrackingLabelAttribute(name || 'radio-input')}>
        {children}
        {description && <div className="mt-1 text-grey-base">{description}</div>}
      </div>
    </label>
  );
};

export type { Props as RadioInputProps };

export default RadioInput;
