import { FormLabel, FormNumberInput, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';

interface Props<T> {
  autoFocus?: boolean;
  className?: string;
  hasUnit?: boolean;
  hideErrorMessage?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  min?: number;
  name: string;
  options: Option<T>[];
}

const FormWeightInput = <T extends {}>({
  autoFocus,
  className,
  hideErrorMessage,
  hasUnit = false,
  isDisabled,
  isRequired = false,
  label = 'Gross weight',
  min,
  name,
  options
}: Props<T>) => {
  const unitFieldName: string = hasUnit ? `${name}.unit` : `${name}.metric`;

  return (
    <div className={className}>
      <FormLabel label={label} name={`${name}.value`} />
      <div className="flex flex-row space-x-1 whitespace-nowrap">
        <FormNumberInput
          autoFocus={autoFocus}
          className="flex-1 min-w-20"
          disabled={isDisabled}
          hideErrorMessage={hideErrorMessage}
          hideLabel={true}
          isRequired={isRequired}
          min={min}
          name={`${name}.value`}
          placeholder="0.0"
          step="0.01"
        />
        <FormSelect
          hideErrorMessage={hideErrorMessage}
          hideLabel={true}
          isDisabled={isDisabled}
          isRequired={isRequired}
          isSearchable={false}
          name={unitFieldName}
          options={options}
          placeholder="kg"
          renderMenuInPortal={true}
        />
      </div>
    </div>
  );
};

export type { Props as FormWeightInputProps };

export default FormWeightInput;
