import type { FC } from 'react';

import type { ContainerTypeEnum } from '@zen/Cargo';
import { Table, TableColumn } from '@zen/DesignSystem';
import { createRateCardTableId } from '@zen/RateCards/LegacyRateCards/helpers';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';

import type { FreightChargePayload } from '../../RateCardForm/reducer/types';
import { getFreightChargeTableColumns } from './freightChargeTableConfig';
import { getAllContainerTypes, shouldIncludeCharacteristicsColumn } from './helpers';

interface Props {
  editable: boolean;
  freightCharges: FreightChargePayload[];
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  onUpdate?: (index: number, payload: FreightChargePayload) => void;
}

const FreightChargeTable: FC<Props> = ({ freightCharges, onDelete, editable, onUpdate, onAdd }) => {
  const unitTypes: ContainerTypeEnum[] = getAllContainerTypes(freightCharges);
  const includeCharacteristicsColumn: boolean = shouldIncludeCharacteristicsColumn(freightCharges);
  const columns: TableColumn<FreightChargePayload>[] = getFreightChargeTableColumns(
    unitTypes,
    includeCharacteristicsColumn,
    { onDelete, onUpdate },
    editable
  );

  return (
    <Table
      actions={editable && <ChargeTableActions actionConfig={{ title: 'Set freight charge', type: 'primary' }} onAdd={onAdd} />}
      columns={columns}
      data={freightCharges}
      tableId={createRateCardTableId(columns, 'freight-charges')}
      title="Freight charges"
      totalCountConfig={{ totalCount: freightCharges.length, entityName: 'item' }}
    />
  );
};

export default FreightChargeTable;
