import { OceanTrackingIssueTypeEnum } from './types';

export const oceanTrackingIssuesLabelMapping: Record<OceanTrackingIssueTypeEnum, string> = {
  [OceanTrackingIssueTypeEnum.ACTUAL_DATE_VALIDATION_FAILURE]: 'an ATD or ATA is missing',
  [OceanTrackingIssueTypeEnum.API_KEY_EXPIRED]: 'there is an error with our tracking partner',
  [OceanTrackingIssueTypeEnum.BOOKING_CANCELLED]: 'booking has been cancelled with the carrier',
  [OceanTrackingIssueTypeEnum.BOOKING_NOT_CONFIRMED]: 'it has not been confirmed yet',
  [OceanTrackingIssueTypeEnum.CARRIER_BOOKING_REFERENCE_NOT_FOUND]:
    'this carrier booking reference was not found on the carrier’s website',
  [OceanTrackingIssueTypeEnum.CARRIER_NOT_TRACKING]: 'the carrier is not sending the tracking data',
  [OceanTrackingIssueTypeEnum.CONTAINERS_NOT_INITIALISED_WITH_VENDOR]:
    'we are waiting for further details from our tracking partner',
  [OceanTrackingIssueTypeEnum.DUPLICATE_FCL]: 'Duplicated container',
  [OceanTrackingIssueTypeEnum.ESTIMATE_IN_PAST]: 'because we have received incomplete data from our tracking partner',
  [OceanTrackingIssueTypeEnum.INVALID_CARRIER_BOOKING_REFERENCE]:
    'this carrier booking reference was not found on the carrier’s website',
  [OceanTrackingIssueTypeEnum.INVALID_DATA_RECEIVED_FROM_VENDOR]: 'there is an error with our tracking partner',
  [OceanTrackingIssueTypeEnum.LEG_COUNT_MISMATCH]:
    'the number of legs in route progress doesn’t match the number of legs in the carrier’s track and trace',
  [OceanTrackingIssueTypeEnum.MISSING_CARRIER_BOOKING_REFERENCE]: 'it is missing carrier booking reference',
  [OceanTrackingIssueTypeEnum.MISSING_SCAC]: 'it is missing carrier (SCAC)',
  [OceanTrackingIssueTypeEnum.NON_TRACKABLE_SCAC]: 'the carrier (SCAC) is not supported by our tracking partner',
  [OceanTrackingIssueTypeEnum.OTHER_VENDOR_ERROR]: 'there is an error with our tracking partner',
  [OceanTrackingIssueTypeEnum.STOPS_MISMATCH]:
    'the terminals given by our tracking partner do not match the terminals entered in Route Progress',
  [OceanTrackingIssueTypeEnum.TRACKING_INITIALISED]: 'we are waiting for further details from our tracking partner',
  [OceanTrackingIssueTypeEnum.TRACKING_IDENTIFIER_MISMATCH]: 'tracking identifier mismatch',
  [OceanTrackingIssueTypeEnum.UNKNOWN]: 'of an unknown reason',
  [OceanTrackingIssueTypeEnum.UNKNOWN_CONTAINER_NUMBER_RECEIVED_FROM_VENDOR]:
    'the container number given by our tracking partner doesn’t match the container number inputted',
  [OceanTrackingIssueTypeEnum.VENDOR_LEG_COUNT_HIGHER]:
    'the number of legs in route progress doesn’t match the number of legs in the carrier’s track and trace',
  [OceanTrackingIssueTypeEnum.VENDOR_NOT_TRACKING_CONTAINER]: 'vendor is not tracking one or more containers',
  [OceanTrackingIssueTypeEnum.VESSEL_NAME_MISMATCH]:
    'the vessel name given by our tracking partner doesn’t match the vessel name inputted',
  [OceanTrackingIssueTypeEnum.ZENCARGO_LEG_COUNT_HIGHER]:
    'the number of legs in route progress doesn’t match the number of legs in the carrier’s track and trace'
};
