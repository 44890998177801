import { isEqual } from 'lodash';
import { number, object, string } from 'yup';

import type { ModeOfTransport } from '@zen/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { getLooseCargoFieldsValidation } from '../../LooseCargo/LooseCargoForm';
import type { LooseFormInitialValues } from '../../LooseCargo/LooseFormFields/types';

export const getFullTruckFormValidationSchema = (
  initialLooseValues?: LooseFormInitialValues['loose'],
  modeOfTransport?: Nullable<ModeOfTransport>
) => {
  return object().shape({
    truckVariant: string().nullable().required('Please select truck variant.'),
    type: string().nullable().required('Please select size.'),
    valueOfGoods: object({ value: number().nullable() }).test('valueOfGoods test', function test(price) {
      if (price.value && price.value <= 0) {
        return this.createError({ message: 'Must be greater than 0.' });
      }

      return true;
    }),
    loose: object()
      .nullable()
      .when({
        is: (val: Optional<LooseFormInitialValues>) => !isEqual(val, initialLooseValues),
        then: () => getLooseCargoFieldsValidation(modeOfTransport || null, false)
      })
  });
};
