import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceSaveAccrualTemaplateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.SaveAccrualTemplateMutationInput;
}>;

export type FinanceSaveAccrualTemaplateMutationResult = { __typename?: 'Mutation' } & {
  financeSaveAccrualTemplate?: GraphQLTypes.Maybe<
    { __typename?: 'SaveAccrualTemplateMutationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      accrualTemplate?: GraphQLTypes.Maybe<
        { __typename?: 'AccrualTemplate' } & Pick<GraphQLTypes.AccrualTemplate, 'id' | 'templateName'>
      >;
    }
  >;
};

export const FinanceSaveAccrualTemaplateDocument = /* #__PURE__ */ gql`
  mutation financeSaveAccrualTemaplate($input: SaveAccrualTemplateMutationInput!) {
    financeSaveAccrualTemplate(input: $input) {
      errors {
        message
        path
      }
      accrualTemplate {
        id
        templateName
      }
    }
  }
`;

/**
 * __useFinanceSaveAccrualTemaplateMutation__
 *
 * To run a mutation, you first call `useFinanceSaveAccrualTemaplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinanceSaveAccrualTemaplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financeSaveAccrualTemaplateMutation, { data, loading, error }] = useFinanceSaveAccrualTemaplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinanceSaveAccrualTemaplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinanceSaveAccrualTemaplateMutationResult,
    FinanceSaveAccrualTemaplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinanceSaveAccrualTemaplateMutationResult, FinanceSaveAccrualTemaplateMutationVariables>(
    FinanceSaveAccrualTemaplateDocument,
    options
  );
}
export type FinanceSaveAccrualTemaplateMutationHookResult = ReturnType<typeof useFinanceSaveAccrualTemaplateMutation>;
