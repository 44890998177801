import { Role } from './types';

export const roleLabels: Record<Role, string> = {
  [Role.CUSTOMER_USER]: 'Customer',
  [Role.AGENT_FORWARDER]: 'Agent',
  [Role.MANUFACTURER]: 'Manufacturer',
  [Role.ADMIN]: 'Zencargo'
};

export { Role };
