import type { TableColumn } from '@zen/DesignSystem';
import type { CommodityCodes } from '@zen/graphql/types.generated';

const columns: TableColumn<CommodityCodes>[] = [
  {
    key: 'id',
    title: 'id',
    sortable: false
  },
  {
    key: 'code',
    title: 'Code'
  },
  {
    key: 'description',
    title: 'Description'
  }
];

export default columns;
