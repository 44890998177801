import { get } from 'lodash';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import CustomerSelectModal from '@zen/Components/CustomerSelectModal';
import type { CustomerFormValues } from '@zen/Components/CustomerSelectModal/CustomerSelectModal';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import PageContent from '@zen/Components/PageContent';
import QueryHandler from '@zen/Components/QueryHandler';
import { Pagination } from '@zen/DesignSystem';
import { PurchaseOrderBookingStateEnum } from '@zen/graphql/types.generated';
import OrderBasketHeader from '@zen/Orders/components/OrderBasketHeader';
import useOrdersListQuery from '@zen/Orders/hooks/useOrdersListView';
import type { OrderFilters, OrderListViewItem } from '@zen/Orders/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { useCustomersQueryLazyQuery } from '@zen/utils/hooks/graphql';
import type { Customer } from '@zen/utils/hooks/useCustomers';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';
import type { Optional } from '@zen/utils/typescript';

import NoResults from '../NoResults';
import OrderListItem from '../OrderListItem';
import UpdateCRDProvider from '../UpdateCRDProvider';

interface Props {
  canViewOrdersCustomers: boolean;
  filterByUserPreferences: boolean;
  hasFilters: boolean;
}

const ListView: FC<Props> = ({ canViewOrdersCustomers, filterByUserPreferences, hasFilters }) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const { customer, isBasketMode, removeAllItems, setCustomer } = useContext(BasketContext);
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const queryParams: QueryParams = parseQueryParams(search);
  const isBookingMode: boolean = !!queryParams.booking;
  const onlyOwnAccount: boolean = !!queryParams.onlyOwnAccount;

  const { nodes, paginationInfo, loading, error } = useOrdersListQuery({ filterByUserPreferences, onlyOwnAccount });
  const [getCustomers, { data: customersResponse }] = useCustomersQueryLazyQuery();

  const customers: Customer[] = get(customersResponse, 'customers.nodes', []);
  const hasOneCustomer: boolean = customers.length === 1;

  const noResults: ReactNode = (
    <PageContent width="wide">
      <NoResults hasFilters={hasFilters} />
    </PageContent>
  );

  useEffect(() => {
    if (canViewOrdersCustomers) {
      getCustomers();
    }
  }, [canViewOrdersCustomers, getCustomers]);

  useEffect(() => {
    const shouldOpenCustomerModal: boolean = canViewOrdersCustomers && isBasketMode && !customer && isBookingMode;

    if (!isBasketMode && setCustomer) {
      setCustomer(null);
    }

    if (appliedFilters.customer && !customer && isBasketMode && setCustomer && !onlyOwnAccount) {
      setCustomer(appliedFilters.customer);
    } else if (hasOneCustomer && shouldOpenCustomerModal && setCustomer && !onlyOwnAccount) {
      setCustomer(customers[0]);
      setAppliedFilters({ ...appliedFilters, customer: customers[0] });
    } else if (shouldOpenCustomerModal) {
      setShowCustomerModal(true);
    } else {
      setShowCustomerModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appliedFilters.customer,
    hasOneCustomer,
    canViewOrdersCustomers,
    customer,
    customers,
    isBasketMode,
    isBookingMode,
    setAppliedFilters,
    setCustomer,
    setShowCustomerModal
  ]);

  useEffect(() => {
    if (hasFilters && canViewOrdersCustomers && setCustomer) {
      setCustomer(appliedFilters.customer);
    }
  }, [appliedFilters, canViewOrdersCustomers, hasFilters, search, setCustomer]);

  useEffect(() => {
    if (isBookingMode) {
      const bookingState: PurchaseOrderBookingStateEnum[] = [
        PurchaseOrderBookingStateEnum.PARTIALLY_BOOKED,
        PurchaseOrderBookingStateEnum.NOT_BOOKED
      ];

      removeAllItems?.();
      setAppliedFilters({ ...appliedFilters, bookingState });
    } else {
      setAppliedFilters({ ...appliedFilters, bookingState: [] });
    }
  }, [isBookingMode]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleModalClose = (): void => {
    if (!customer) {
      navigate(-1);
    } else {
      setShowCustomerModal(false);
    }
  };

  const handleSelect = ({ customerId }: CustomerFormValues): Promise<IOkOrErrorResult> => {
    const selectedCustomer: Optional<Customer> = customers.find((item: Customer) => item.uuid === customerId);

    setCustomer?.(selectedCustomer);
    setAppliedFilters({ ...appliedFilters, customer: selectedCustomer });
    setShowCustomerModal(false);

    return Promise.resolve({
      ok: {},
      error: null
    });
  };

  return (
    <UpdateCRDProvider>
      {isBasketMode && <OrderBasketHeader onCustomerChangeRequest={() => setShowCustomerModal(true)} />}
      <QueryHandler data={nodes} error={!!error} isLoading={loading} noResults={noResults}>
        {(orders: OrderListViewItem[]) => (
          <>
            <div className="mt-6 orderListView" data-testid="order-list-view">
              {orders.map(
                (order: OrderListViewItem): ReactNode => (
                  <OrderListItem key={order.id} order={order} orderLotsQueryVariables={{ onlyNotBooked: isBookingMode }} />
                )
              )}
            </div>
            <Pagination className="mt-3" pageInfo={paginationInfo} />
          </>
        )}
      </QueryHandler>
      <CustomerSelectModal isOpen={showCustomerModal} onClose={() => handleModalClose()} onSelect={handleSelect} />
    </UpdateCRDProvider>
  );
};

export default ListView;
