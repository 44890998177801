import { upperFirst } from 'lodash';
import type { FC, ReactNode } from 'react';

import { Table, type TableColumn } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeTableProps, LocationType } from '../../components/types';
import { getHaulageChargeTableColumns } from './haulageChargeTableConfig';

interface Props extends ChargeTableProps {
  type: LocationType;
}

const HaulageChargeTable: FC<Props> = ({ charges, onAdd, onUpdate, onDelete, type }) => {
  const { hasFreightCharges, isEditable } = useRateCardContext();

  const columns: TableColumn<RateCardCharge>[] = getHaulageChargeTableColumns({ onDelete, onUpdate }, isEditable, type);
  const title: string = `${upperFirst(type)} haulage charges`;
  const tableId: string = `${type}-charges`;
  const renderActions: ReactNode = isEditable && (
    <ChargeTableActions actionConfig={{ title: 'Add charge', type: 'secondary' }} isDisabled={!hasFreightCharges} onAdd={onAdd} />
  );

  return (
    <Table
      actions={renderActions}
      columns={columns}
      data={charges}
      isDraggingEnabled={false}
      tableId={tableId}
      title={title}
      totalCountConfig={{ totalCount: charges.length, entityName: 'item' }}
    />
  );
};

export type { Props as HaulageChargeTableProps };

export default HaulageChargeTable;
