import cx from 'classnames';

interface InputState {
  disabled?: boolean;
  error?: boolean;
  inline?: boolean;
  locked?: boolean;
}

export const useInputStyles = (inputState: InputState): string => {
  const { disabled, locked, error, inline } = inputState;

  return cx(
    {
      'bg-grey-lightest cursor-not-allowed': disabled,
      'bg-grey-lighter cursor-not-allowed': locked,
      'border-red-dark': error,
      'hover:border-red-dark': error,
      'border-grey-light': !error,
      'hover:border-azure-base active:border-azure-base': !(locked || disabled),
      'border-transparent': inline,
      'bg-transparent focus:bg-white': inline && !disabled
    },
    'rounded border border-solid',
    'focus:border-azure-base focus:outline-none',
    'text-sm text-grey-dark leading-6',
    'transition duration-300 ease-in-out'
  );
};
