import {
  OceanCharge,
  OceanChargesDocument,
  type OceanChargesQueryResult,
  type OceanChargesQueryVariables
} from '@zen/OperationsSettings';
import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';
import type { AllPaginatedResults } from '@zen/utils/hooks/pagination/useAllPaginatedResults';

const useDefaultCharges = (variables: OceanChargesQueryVariables, skip?: boolean): AllPaginatedResults<OceanCharge> => {
  return useAllPaginatedResults<OceanChargesQueryResult, OceanChargesQueryVariables, OceanCharge>({
    document: OceanChargesDocument,
    responsePath: 'getDefaultCharges',
    variables,
    skip
  });
};

export default useDefaultCharges;
