import * as Yup from 'yup';

import { dateWithTimeValidationSchema } from '@zen/Components/DateWithTimeForm/dateWithTimeForm.validation';

export const createValidationSchema = (canUpdateRequestedBy: boolean): Yup.ObjectSchema<{}> => {
  if (!canUpdateRequestedBy) {
    return dateWithTimeValidationSchema;
  }

  return dateWithTimeValidationSchema.shape({
    requestedBy: Yup.string().nullable().required('Please select a value.')
  });
};
