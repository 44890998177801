import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables = GraphQLTypes.Exact<{
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  paginated?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
  accountIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  transportModes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.ModeOfTransport> | GraphQLTypes.ModeOfTransport>;
  origins?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  destinations?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
}>;

export type GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult = { __typename?: 'Query' } & {
  purchaseOrdersOriginAgentAssignmentRules: { __typename?: 'OriginAgentAssignmentRuleConnection' } & Pick<
    GraphQLTypes.OriginAgentAssignmentRuleConnection,
    'totalCount'
  > & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'OriginAgentAssignmentRule' } & Pick<
              GraphQLTypes.OriginAgentAssignmentRule,
              'id' | 'modeOfTransport'
            > & {
                account: { __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid' | 'name'>;
                collectionWarehouse?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    }
                >;
                deliveryWarehouse?: GraphQLTypes.Maybe<
                  { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    }
                >;
                originAgent: { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  };
                portOfDestination?: GraphQLTypes.Maybe<
                  { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'label'>
                >;
                portOfLoad?: GraphQLTypes.Maybe<{ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'label'>>;
              }
          >
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetPurchaseOrdersOriginAgentAssignmentRulesDocument = /* #__PURE__ */ gql`
  query getPurchaseOrdersOriginAgentAssignmentRules(
    $order: SortInput
    $after: String
    $before: String
    $paginated: Boolean
    $accountIds: [String!]
    $transportModes: [ModeOfTransport!]
    $origins: [String!]
    $destinations: [String!]
  ) {
    purchaseOrdersOriginAgentAssignmentRules(
      order: $order
      after: $after
      before: $before
      paginated: $paginated
      accountIds: $accountIds
      transportModes: $transportModes
      origins: $origins
      destinations: $destinations
    ) {
      nodes {
        account {
          uuid
          name
        }
        collectionWarehouse {
          id
          label {
            long
          }
        }
        deliveryWarehouse {
          id
          label {
            long
          }
        }
        id
        modeOfTransport
        originAgent {
          id
          label {
            long
          }
        }
        portOfDestination {
          unlocode
          label
        }
        portOfLoad {
          unlocode
          label
        }
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetPurchaseOrdersOriginAgentAssignmentRulesQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersOriginAgentAssignmentRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersOriginAgentAssignmentRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersOriginAgentAssignmentRulesQuery({
 *   variables: {
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      paginated: // value for 'paginated'
 *      accountIds: // value for 'accountIds'
 *      transportModes: // value for 'transportModes'
 *      origins: // value for 'origins'
 *      destinations: // value for 'destinations'
 *   },
 * });
 */
export function useGetPurchaseOrdersOriginAgentAssignmentRulesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables
  >(GetPurchaseOrdersOriginAgentAssignmentRulesDocument, options);
}
export function useGetPurchaseOrdersOriginAgentAssignmentRulesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRulesQueryVariables
  >(GetPurchaseOrdersOriginAgentAssignmentRulesDocument, options);
}
export type GetPurchaseOrdersOriginAgentAssignmentRulesQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersOriginAgentAssignmentRulesQuery
>;
export type GetPurchaseOrdersOriginAgentAssignmentRulesLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersOriginAgentAssignmentRulesLazyQuery
>;
