import type { FC } from 'react';

import { Pill, PillType } from '@zen/DesignSystem';
import { RoadTrackedShipmentStatus } from '@zen/TrackedShipments/types';

interface PillConfig {
  pillType: PillType;
  title: string;
}

const statusMapping: Record<RoadTrackedShipmentStatus, PillConfig> = {
  [RoadTrackedShipmentStatus.EARLY]: {
    title: 'Early',
    pillType: 'warning'
  },
  [RoadTrackedShipmentStatus.LATE]: {
    title: 'Late',
    pillType: 'problem'
  },
  [RoadTrackedShipmentStatus.ON_TIME]: {
    title: 'On time',
    pillType: 'default'
  },
  [RoadTrackedShipmentStatus.UNKNOWN]: {
    title: 'Unknown',
    pillType: 'pending'
  }
};

interface Props {
  status: RoadTrackedShipmentStatus;
}

const StatusPill: FC<Props> = ({ status }) => {
  const pillConfig: PillConfig = statusMapping[status];

  return (
    <Pill className="leading-none" type={pillConfig.pillType}>
      {pillConfig.title}
    </Pill>
  );
};

export default StatusPill;
