import type { FC } from 'react';

import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { Terminal } from '../types';

interface Props {
  carrier: Optional<string>;
  destinationTerminal: Nullable<Terminal>;
  originTerminal: Nullable<Terminal>;
  showCarrier?: boolean;
}

const RateCardBookingDetails: FC<Props> = ({ carrier, destinationTerminal, originTerminal, showCarrier = true }) => {
  return (
    <div className="grid grid-cols-2 gap-x-6 gap-y-1">
      <LabelledValue label="Port of origin">
        <ValueWithDefault value={originTerminal?.label} />
      </LabelledValue>
      <LabelledValue label="Port of destination">
        <ValueWithDefault value={destinationTerminal?.label} />
      </LabelledValue>
      {showCarrier && (
        <LabelledValue label="Carrier (SCAC)">
          <ValueWithDefault value={carrier} />
        </LabelledValue>
      )}
    </div>
  );
};

export type { Props as RateCardBookingDetailsProps };

export default RateCardBookingDetails;
