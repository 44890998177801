import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrderDelayReasonOptionsQueryVariables = GraphQLTypes.Exact<{
  searchQuery?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type PurchaseOrderDelayReasonOptionsQueryResult = { __typename?: 'Query' } & {
  purchaseOrderDelayReasons: { __typename?: 'PurchaseOrderDelayReasonConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'PurchaseOrderDelayReason' } & Pick<GraphQLTypes.PurchaseOrderDelayReason, 'id' | 'reason'>
        >
      >
    >;
  };
};

export const PurchaseOrderDelayReasonOptionsDocument = /* #__PURE__ */ gql`
  query purchaseOrderDelayReasonOptions($searchQuery: String, $order: SortInput) {
    purchaseOrderDelayReasons(textContains: $searchQuery, order: $order) {
      nodes {
        id
        reason
      }
    }
  }
`;

/**
 * __usePurchaseOrderDelayReasonOptionsQuery__
 *
 * To run a query within a React component, call `usePurchaseOrderDelayReasonOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrderDelayReasonOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseOrderDelayReasonOptionsQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePurchaseOrderDelayReasonOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<PurchaseOrderDelayReasonOptionsQueryResult, PurchaseOrderDelayReasonOptionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<PurchaseOrderDelayReasonOptionsQueryResult, PurchaseOrderDelayReasonOptionsQueryVariables>(
    PurchaseOrderDelayReasonOptionsDocument,
    options
  );
}
export function usePurchaseOrderDelayReasonOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PurchaseOrderDelayReasonOptionsQueryResult,
    PurchaseOrderDelayReasonOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<PurchaseOrderDelayReasonOptionsQueryResult, PurchaseOrderDelayReasonOptionsQueryVariables>(
    PurchaseOrderDelayReasonOptionsDocument,
    options
  );
}
export type PurchaseOrderDelayReasonOptionsQueryHookResult = ReturnType<typeof usePurchaseOrderDelayReasonOptionsQuery>;
export type PurchaseOrderDelayReasonOptionsLazyQueryHookResult = ReturnType<typeof usePurchaseOrderDelayReasonOptionsLazyQuery>;
