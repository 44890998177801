import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersUpdateOrderMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateOrderInput;
}>;

export type PurchaseOrdersUpdateOrderMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersUpdateOrder?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateOrderPayload' } & {
      purchaseOrder?: GraphQLTypes.Maybe<{ __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const PurchaseOrdersUpdateOrderDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersUpdateOrder($input: UpdateOrderInput!) {
    purchaseOrdersUpdateOrder(input: $input) {
      purchaseOrder {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __usePurchaseOrdersUpdateOrderMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersUpdateOrderMutation, { data, loading, error }] = usePurchaseOrdersUpdateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersUpdateOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersUpdateOrderMutationResult, PurchaseOrdersUpdateOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersUpdateOrderMutationResult, PurchaseOrdersUpdateOrderMutationVariables>(
    PurchaseOrdersUpdateOrderDocument,
    options
  );
}
export type PurchaseOrdersUpdateOrderMutationHookResult = ReturnType<typeof usePurchaseOrdersUpdateOrderMutation>;
