import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { BookingStageEnum, isOceanBooking } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';

import { type JourneyVehicleProperty, VehiclePropertyNameEnum } from '../../types';
import AirlineCarrierInlineField from '../forms/AirlineCarrierInlineField';
import CarrierBookingReferenceInlineField from '../forms/CarrierBookingReferenceInlineField';
import CarrierInlineField from '../forms/CarrierInlineField';
import CourierInlineField from '../forms/CourierInlineField';
import FlightNumberInlineField from '../forms/FlightNumberInlineField';
import HaulierInlineField from '../forms/HaulierInlineField';
import MasterAirwayBillInlineField from '../forms/MasterAirwayBillInlineField';
import MasterBillOfLadingInlineField from '../forms/MasterBillOfLadingInlineField';
import OceanCarrierInlineField from '../forms/OceanCarrierInlineField';
import RailwayCompanyInlineField from '../forms/RailwayCompanyInlineField';
import TrackingNumberInlineField from '../forms/TrackingNumberInlineField';
import TrailerIdInlineField from '../forms/TrailerIdInlineField';
import VehiclePlateNumberInlineField from '../forms/VehiclePlateNumberInlineField';
import VesselNameInlineField from '../forms/VesselNameInlineField';
import VoyageNumberInlineField from '../forms/VoyageNumberInlineField';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';

interface Props {
  className?: string;
  properties: JourneyVehicleProperty[];
  vehicleId: string;
}

const VehicleProperties: FC<Props> = ({ className, properties, vehicleId }) => {
  const { modeOfTransport } = useJourneyContext();
  const { bookingStage } = useCargoOverviewContext();
  const filteredProperties: JourneyVehicleProperty[] = properties.filter(({ canView }) => canView);
  const isBookingRequested: boolean = bookingStage === BookingStageEnum.BOOKING_REQUESTED;

  const classNames: string = cx('flex flex-col pt-2 space-y-2', className);

  const renderProperty = ({ canUpdate, name, value }: JourneyVehicleProperty): ReactNode => {
    switch (name) {
      case VehiclePropertyNameEnum.AIRLINE:
        return <AirlineCarrierInlineField key={name} airlineCarrierId={value || ''} canUpdateAirlineCarrier={!!canUpdate} />;
      case VehiclePropertyNameEnum.CARRIER_BOOKING_REFERENCE:
        return (
          <CarrierBookingReferenceInlineField
            key={name}
            canUpdateCarrierBookingReference={!!canUpdate}
            carrierBookingReference={value || ''}
            isBookingRequested={isBookingRequested}
          />
        );
      case VehiclePropertyNameEnum.COURIER:
        return <CourierInlineField key={name} canUpdateCourier={!!canUpdate} courierId={value || ''} />;
      case VehiclePropertyNameEnum.FLIGHT_NUMBER:
        return (
          <FlightNumberInlineField
            key={name}
            canUpdateFlightNumber={!!canUpdate}
            flightNumber={value || ''}
            vehicleId={vehicleId}
          />
        );
      case VehiclePropertyNameEnum.HAULIER_ID:
        return <HaulierInlineField key={name} canUpdateHaulier={!!canUpdate} haulierId={value || ''} vehicleId={vehicleId} />;
      case VehiclePropertyNameEnum.LICENSE_PLATE:
        return (
          <VehiclePlateNumberInlineField
            key={name}
            canUpdateVehiclePlateNumber={!!canUpdate}
            vehicleId={vehicleId}
            vehiclePlateNumber={value || ''}
          />
        );
      case VehiclePropertyNameEnum.MASTER_AIRWAY_BILL:
        return <MasterAirwayBillInlineField key={name} canUpdateMasterAirwayBill={!!canUpdate} masterAirwayBill={value || ''} />;
      case VehiclePropertyNameEnum.MASTER_BILL_OF_LADING:
        return (
          <MasterBillOfLadingInlineField key={name} canUpdateMasterBillOfLading={!!canUpdate} masterBillOfLading={value || ''} />
        );
      case VehiclePropertyNameEnum.RAILWAY_COMPANY:
        return <RailwayCompanyInlineField key={name} canUpdateRailwayCompany={!!canUpdate} railwayCompanyId={value || ''} />;
      case VehiclePropertyNameEnum.SCAC:
        if (isOceanBooking(modeOfTransport)) {
          return <OceanCarrierInlineField key={name} canUpdateCarrier={!!canUpdate} carrier={value || ''} />;
        }

        return <CarrierInlineField key={name} canUpdateCarrier={!!canUpdate} carrier={value || ''} />;
      case VehiclePropertyNameEnum.TRACKING_NUMBER:
        return <TrackingNumberInlineField key={name} canUpdateTrackingNumber={!!canUpdate} trackingNumber={value || ''} />;
      case VehiclePropertyNameEnum.TRAILER_ID:
        return <TrailerIdInlineField key={name} canUpdateTrailerId={!!canUpdate} trailerId={value || ''} vehicleId={vehicleId} />;
      case VehiclePropertyNameEnum.VOYAGE_NUMBER:
        return (
          <VoyageNumberInlineField
            key={name}
            canUpdateVoyageNumber={!!canUpdate}
            vehicleId={vehicleId}
            voyageNumber={value || ''}
          />
        );
      case VehiclePropertyNameEnum.VESSEL_NAME:
        return (
          <VesselNameInlineField key={name} canUpdateVesselName={!!canUpdate} vehicleId={vehicleId} vesselName={value || ''} />
        );
      default:
        return null;
    }
  };

  if (filteredProperties.length === 0) {
    return null;
  }

  return <div className={classNames}>{filteredProperties.map(renderProperty)}</div>;
};

export default VehicleProperties;
