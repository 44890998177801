import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersDeleteFieldMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.RemoveAssignmentsInput;
}>;

export type PurchaseOrdersDeleteFieldMutationResult = { __typename?: 'Mutation' } & {
  networksRemoveAssignments?: GraphQLTypes.Maybe<
    { __typename?: 'RemoveAssignmentsPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const PurchaseOrdersDeleteFieldDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersDeleteField($input: RemoveAssignmentsInput!) {
    networksRemoveAssignments(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __usePurchaseOrdersDeleteFieldMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersDeleteFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersDeleteFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersDeleteFieldMutation, { data, loading, error }] = usePurchaseOrdersDeleteFieldMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersDeleteFieldMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersDeleteFieldMutationResult, PurchaseOrdersDeleteFieldMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersDeleteFieldMutationResult, PurchaseOrdersDeleteFieldMutationVariables>(
    PurchaseOrdersDeleteFieldDocument,
    options
  );
}
export type PurchaseOrdersDeleteFieldMutationHookResult = ReturnType<typeof usePurchaseOrdersDeleteFieldMutation>;
