import type { ActivePortCharge } from '@zen/RateCards/LegacyRateCards/types';
import { useAllPaginatedResults } from '@zen/utils/hooks/pagination';
import type { AllPaginatedResults } from '@zen/utils/hooks/pagination/useAllPaginatedResults';

import { ActivePortChargesDocument, ActivePortChargesQueryResult, ActivePortChargesQueryVariables } from '..';

const usePortCharges = (
  variables: ActivePortChargesQueryVariables,
  skip: boolean = false
): AllPaginatedResults<ActivePortCharge> => {
  return useAllPaginatedResults<ActivePortChargesQueryResult, ActivePortChargesQueryVariables, ActivePortCharge>({
    document: ActivePortChargesDocument,
    responsePath: 'getActivePortCharges',
    variables,
    skip
  });
};

export default usePortCharges;
