import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Search from '@zen/Components/Search';
import useTracking from '@zen/utils/hooks/useTracking';
import { mergeQueryParams, parseQueryParams } from '@zen/utils/QueryParams/queryParams';

const OrdersSearch: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { textContains } = parseQueryParams(location.search);
  const { trackEvent } = useTracking();

  const handleSearch = (value: string): void => {
    updateQuerySearch(value);

    trackEvent({
      action: 'PurchaseOrders',
      category: 'Search',
      label: value
    });
  };

  const updateQuerySearch = (value: string = ''): void => {
    navigate(
      {
        ...location,
        search: mergeQueryParams(location.search, { textContains: value || null })
      },
      { replace: true }
    );
  };

  return (
    <Search
      collapsible={true}
      initialValue={textContains}
      onClear={updateQuerySearch}
      onSubmit={handleSearch}
      placeholder="Search for a PO with order number reference, SKU code, SKU name or manufacturer name"
      size="compact"
      widthClassName="w-48"
    />
  );
};

export default OrdersSearch;
