import { useNavigate } from 'react-router-dom';

import { bookingRequestRoutes, shipmentRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useBookingRequestsUpdateCustomerApprovedMutation, useBookingRequestsUpdateForwarderApprovedMutation } from '../graphql';

const useApproveBookingRequest = (
  zencargoReference: string,
  customerApprovalRequired: boolean,
  forwarderApproved: boolean,
  onClose: () => void,
  onSuccess?: () => void
) => {
  const { addSuccess, addError } = useNotification();
  const navigate = useNavigate();

  const [approveRequestCustomer] = useBookingRequestsUpdateCustomerApprovedMutation();
  const [approveRequestForwarder] = useBookingRequestsUpdateForwarderApprovedMutation();

  const isForwarderApproval: boolean = !forwarderApproved;

  const handleSuccess = (): void => {
    const url: string =
      isForwarderApproval && customerApprovalRequired
        ? bookingRequestRoutes.readyForApproval.getUrl()
        : shipmentRoutes.shipmentIndex.getUrl();

    if (!onSuccess) {
      addSuccess('Booking approved sucessfully.');
      onClose();
      navigate(url);

      return;
    }

    onSuccess();
    onClose();
  };

  const onForwarderApprove = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        approveRequestForwarder({
          variables: {
            input: {
              zencargoReference,
              forwarderApproved: true,
              customerApprovalRequired
            }
          }
        }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  const onCustomerApprove = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        approveRequestCustomer({
          variables: {
            input: {
              zencargoReference,
              customerApproved: true
            }
          }
        }),
      onError: () => addError(),
      onSuccess: handleSuccess
    });
  };

  const handleApprove = (): void => {
    if (isForwarderApproval) {
      onForwarderApprove();

      return;
    }

    onCustomerApprove();
  };

  return {
    onApprove: () => handleApprove()
  };
};

export default useApproveBookingRequest;
