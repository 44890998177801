import { LanguageTag } from '@zen/utils/languageSettings';
import type { Nullable } from '@zen/utils/typescript';

import { formatDate, formatDateRange } from '../date/formatting';
import { isParseableAsDateTime, parseAndFormatDateTime } from '../jodaConnector';
import { formatTime, formatTimeRange } from '../time/formatting';
import { getTimeFromString } from '../time/parsing';
import type { DateTimeFormat, DateTimeFormatOptions, DateTimeLocaleAndOptions, Time } from '../types';

const dateTimeFormats: DateTimeFormatOptions = {
  D_MMMM_YYYY_HH_MM: {
    locale: LanguageTag.BritishEnglish,
    options: {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  },
  MMMM_D_YYYY_HH_MM: {
    locale: LanguageTag.AmericanEnglish,
    options: {
      day: 'numeric',
      hour: 'numeric',
      hourCycle: 'h23',
      minute: 'numeric',
      month: 'long',
      year: 'numeric'
    }
  },
  MMM_D_YYYY_HH_MM: {
    locale: LanguageTag.AmericanEnglish,
    options: {
      day: 'numeric',
      hour: 'numeric',
      hourCycle: 'h23',
      minute: 'numeric',
      month: 'short',
      year: 'numeric'
    }
  }
};

const getDateTimeFormat = (formatString: DateTimeFormat): DateTimeLocaleAndOptions => {
  return dateTimeFormats[formatString];
};

export const formatDateTime = (inputString: string, dateTimeFormatString: DateTimeFormat = 'MMM_D_YYYY_HH_MM'): string => {
  const dateTimeFormat: DateTimeLocaleAndOptions = getDateTimeFormat(dateTimeFormatString);

  return parseAndFormatDateTime(inputString, dateTimeFormat) || '-';
};

export const formatDateTimeRange = (startDateTimeInputString: string, endDateTimeInputString: string): Nullable<string> => {
  if (!isParseableAsDateTime(startDateTimeInputString) || !isParseableAsDateTime(endDateTimeInputString)) {
    return null;
  }

  const startTime: Time = getTimeFromString(startDateTimeInputString);
  const endTime: Time = getTimeFromString(endDateTimeInputString);

  return `${formatDate(startDateTimeInputString)} ${formatTimeRange(startTime, endTime, false)}`;
};

export const formatDateAndTimeRange = (
  start: { date: string; time?: string | null },
  end?: Nullable<{ date: string; time?: string | null }>,
  withSeconds: boolean = false
): string => {
  const formattedDate: string = formatDate(start.date);

  if (!end?.date) {
    if (start.time) {
      return `${formattedDate}, ${formatTime(start.time, withSeconds)}`;
    }

    return formattedDate;
  }

  if (start.date === end.date && start.time) {
    return `${formattedDate}, ${formatTimeRange(start.time, end?.time, withSeconds)}`;
  }

  if (start.date !== end.date && !start.time) {
    return formatDateRange(start.date, end?.date) || formattedDate;
  }

  if (start.date !== end.date && start.time && end?.time) {
    return `${formatDate(start.date)}, ${formatTime(start.time, withSeconds)} - ${formatDate(end.date)}, ${formatTime(
      end.time,
      withSeconds
    )}`;
  }

  return formattedDate;
};
