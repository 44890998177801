import cx from 'classnames';
import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import type { SVGComponent } from '@zen/types';

import { modeOfTransportIconMapping } from './helpers';

interface Props {
  className?: string;
  modeOfTransport: ModeOfTransport;
  size?: 'small' | 'medium' | 'large';
}

const ModeOfTransportIcon: FC<Props> = ({ className, modeOfTransport, size = 'medium' }) => {
  const { alt, imageComponent } = modeOfTransportIconMapping[modeOfTransport];
  const ModeIconComponent: SVGComponent = imageComponent;

  const classNames: string = cx({
    'w-10 h-10': size === 'small',
    'w-16 h-16': size === 'medium',
    'w-28 h-28': size === 'large'
  });

  return (
    <div className={className}>
      <div aria-label={alt} className={classNames} data-testid="mode-of-transport-icon" role="img">
        <ModeIconComponent className="w-full h-full" />
      </div>
    </div>
  );
};

export default ModeOfTransportIcon;
