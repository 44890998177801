import type { FC } from 'react';

import InformationIcon from '@zen/Components/InformationIcon';
import { sectionTitle } from '@zen/Issues/Issues/IssueListSection/helpers';
import { Issue, IssueState } from '@zen/Issues/types';

import { buildStateChangesList, generateLabel } from './helper';
import type { StateDescription, StateItem } from './types';

interface Props {
  className?: string;
  issue: Pick<Issue, 'state' | 'openedAt' | 'openedBy' | 'startedAt' | 'startedBy' | 'resolvedAt' | 'resolvedBy' | 'severity'>;
}

const IssueStateDescription: FC<Props> = ({ issue, className = 'flex text-xs leading-normal' }) => {
  const { state, severity } = issue;
  const hasHistory: boolean = state === IssueState.STARTED || state === IssueState.RESOLVED;
  const isArchived: boolean = IssueState.ARCHIVED === state;

  const { history, currentState }: StateDescription = buildStateChangesList(issue);

  const tooltip = history.map((item: StateItem, i: number) => (
    <div key={i} className="leading-6" data-testid="history">
      {generateLabel(item)}
    </div>
  ));

  const severityLabel: string = sectionTitle[severity];

  const label: string = isArchived ? 'This issue has been archived' : generateLabel(currentState);

  return (
    <div className={className}>
      {severityLabel}
      <div className="text-grey-base">
        <span className="mx-2 leading-normal text-grey-lighter">&bull;</span>
        {label}
        {hasHistory && <InformationIcon className="ml-2" content={tooltip} placement="bottom" size="small" />}
      </div>
    </div>
  );
};

export default IssueStateDescription;
