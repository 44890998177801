import type { FC, ReactNode } from 'react';

import { DateWithTimeFields, TimeOption } from '@zen/Components/DateWithTimeForm';
import DateWithTimeForm from '@zen/Components/DateWithTimeForm/DateWithTimeForm';
import { Button } from '@zen/DesignSystem';
import { prepareDateAndTimePayload } from '@zen/Journey/helpers';
import { JourneyShippingMilestoneNameEnum, MilestoneDateType } from '@zen/Journey/types';
import type { BusinessHours } from '@zen/Networks';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { isSharedMilestone } from '../../JourneyDetails/helpers';
import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import { journeyRefetchQueryList } from '../helpers';
import { useRemoveMilestonePlannedDateMutation, useUpdateMilestonePlannedDateMutation } from './graphql';

interface Props {
  businessHours: BusinessHours;
  canRemovePlannedDateCurrently: boolean;
  hasPlannedDate: boolean;
  initialValues: DateWithTimeFields;
  milestoneId: string;
  milestoneName: JourneyShippingMilestoneNameEnum;
  onSuccess?: () => void;
  timeZone?: Nullable<string>;
  title: string;
}

const PlannedDateForm: FC<Props> = (props) => {
  const {
    businessHours,
    canRemovePlannedDateCurrently,
    hasPlannedDate,
    initialValues,
    milestoneId,
    milestoneName,
    onSuccess,
    timeZone,
    title
  } = props;

  const [removePlannedDate, { loading }] = useRemoveMilestonePlannedDateMutation();
  const [updatePlannedDate] = useUpdateMilestonePlannedDateMutation();
  const { addError, addSuccess } = useNotification();
  const { getMilestoneDisabledDates, zencargoReference } = useJourneyContext();
  const { disabledDates, tooltip } = getMilestoneDisabledDates(milestoneId, MilestoneDateType.PLANNED);

  const buttonText: string = hasPlannedDate ? 'Update booked date' : 'Add booked date';
  const timeConfig: TimeOption[] = isSharedMilestone(milestoneName)
    ? [TimeOption.NO_TIME, TimeOption.POINT_IN_TIME]
    : [TimeOption.TIME_RANGE];

  const handleClear = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removePlannedDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError();
      },
      onSuccess: () => {
        addSuccess('Booked date has been removed.');
        onSuccess?.();
      }
    });
  };

  const handleSubmit = (values: DateWithTimeFields) => {
    return performMutation({
      mutationFn: () =>
        updatePlannedDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              date: prepareDateAndTimePayload(values),
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`Booked date has been ${hasPlannedDate ? 'updated' : 'added'}.`);
      }
    });
  };

  const additionalActionButton: ReactNode =
    hasPlannedDate && canRemovePlannedDateCurrently ? (
      <Button disabled={loading} isLoading={loading} onClick={handleClear} variant="secondary">
        Clear booked date
      </Button>
    ) : null;

  return (
    <DateWithTimeForm
      additionalActionButton={additionalActionButton}
      businessHours={businessHours}
      buttonText={buttonText}
      dateTooltip={tooltip}
      disabledDates={disabledDates}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      timeConfig={timeConfig}
      timeZone={timeZone}
      title={title}
      variant="compact"
    />
  );
};

export default PlannedDateForm;
