import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useDeactivateUserMutation } from '@zen/Networks/graphql';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { getDialogConfig } from './helpers';

interface Props {
  isOpen: boolean;
  isProfileCompleted: boolean;
  memberId: string;
  memberName: string;
  onClose: () => void;
}

const DeleteMemberModal: FC<Props> = ({ isOpen, isProfileCompleted, onClose, memberId, memberName }) => {
  const { addError, addSuccess } = useNotification();
  const [deactivateMember, { loading }] = useDeactivateUserMutation({ refetchQueries: ['accountMembers'] });

  const { header, message, confirmLabel, successMessage, errorMessage } = getDialogConfig(isProfileCompleted, memberName);

  const handleDeactivateMember = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        deactivateMember({
          variables: {
            deactivateUser: id
          }
        }),
      onError: () => addError(errorMessage, { label: 'Try again', onClick: () => handleDeactivateMember(id) }),
      onSuccess: () => {
        addSuccess(successMessage);
        onClose();
      }
    });
  };

  const handleConfirm = async (): Promise<void> => {
    if (memberId) {
      await handleDeactivateMember(memberId);
    }
  };

  return (
    <Dialog
      confirmLabel={confirmLabel}
      header={header}
      isLoading={loading}
      isOpen={isOpen}
      message={message}
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default DeleteMemberModal;
export type { Props as DeleteMemberModalProps };
