import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance, FormMultiSelect, FormSelect } from '@zen/Components/Form';
import { modeOfTransportOptions } from '@zen/Components/ModeOfTransportSelect';
import type { Option } from '@zen/DesignSystem';
import { Button } from '@zen/DesignSystem';
import NetworksContactFormInput from '@zen/Networks/NetworksContactPicker/NetworksContactFormInput';
import type { AgentAssignmentRulesFiltersType } from '@zen/OperationsSettings/AgentAssignmentRules/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Account } from '@zen/utils/hooks/useAccounts';
import useAccounts from '@zen/utils/hooks/useAccounts';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props {
  initialValues: AgentAssignmentRulesFiltersType;
  onClearFilters: () => void;
  onSubmit: (values: AgentAssignmentRulesFiltersType) => Promise<IOkOrErrorResult>;
  onSuccess: () => void;
}

const AgentAssignmentRulesFiltersForm: FC<Props> = (props) => {
  const { initialValues, onClearFilters, onSubmit, onSuccess } = props;
  const { data: accounts } = useAccounts();
  const { accountUuid: accountId } = useAccount();

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed">
        <Button data-testid="clear-filters-button" onClick={onClearFilters} variant="ghost">
          Clear filters
        </Button>
      </FormButtons>
    );
  };

  const accountsOptions = (): Option<string>[] => {
    if (!accounts) {
      return [];
    }

    return accounts.map((account: Account) => ({
      label: account.registeredCompanyName,
      value: account.uuid
    }));
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      formName="FreightSpendFiltersForm"
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
    >
      {({ values }: FormInstance<AgentAssignmentRulesFiltersType>) => {
        const networkId: string = values.accountId ?? accountId;

        return (
          <>
            <FormSelect isClearable={true} label="Account" name="accountId" options={accountsOptions()} />
            <NetworksContactFormInput
              accountUuid={networkId}
              entityType="location"
              label="Origin"
              name="origin"
              showAddButton={false}
            />
            <NetworksContactFormInput
              accountUuid={networkId}
              entityType="location"
              label="Destination"
              name="destination"
              showAddButton={false}
            />
            <FormMultiSelect name="transportModes" options={modeOfTransportOptions} />
          </>
        );
      }}
    </Form>
  );
};

export default AgentAssignmentRulesFiltersForm;
