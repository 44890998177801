import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import type { JourneyStopActionEnum } from '@zen/graphql/types.generated';
import { isCollectionStop } from '@zen/Journey/helpers';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { journeyRefetchQueryList } from '../helpers';
import { useUpdateJourneyStopReferenceMutation } from './graphql';

interface Props {
  canUpdateReference: boolean;
  reference: string;
  stopAction: JourneyStopActionEnum;
  stopId: string;
}

const StopReferenceInlineField: FC<Props> = (props) => {
  const { canUpdateReference, reference, stopAction, stopId } = props;
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateJourneyStopReference] = useUpdateJourneyStopReferenceMutation();

  const label: string = isCollectionStop(stopAction) ? 'Collection reference' : 'Delivery reference';
  const placeholder: string = isCollectionStop(stopAction) ? 'Enter collection reference' : 'Enter delivery reference';

  const handleUpdateStopReference = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateJourneyStopReference({
          variables: {
            input: {
              journeyStopId: stopId,
              reference: value,
              zencargoReference
            }
          },
          refetchQueries: journeyRefetchQueryList
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateReference) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={reference} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="journey-stop-reference"
      onUpdate={handleUpdateStopReference}
      placeholder={placeholder}
      value={reference}
    />
  );
};

export default StopReferenceInlineField;
