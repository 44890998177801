import { type FC, useEffect } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import type { CargoOverviewCargoReadyDate } from '@zen/Booking/BookingDetails/CargoOverview/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import {
  type JourneyLastUpdated,
  type JourneyLeg,
  type JourneyShippingMilestone,
  JourneyTrackingAction,
  JourneyTrackingCategory
} from '../../types';
import JourneyLegs from '../JourneyLegs';
import LastUpdated from '../LastUpdated';
import MilestoneDatesHistoryButton from '../MilestoneDatesHistoryButton';
import { getMilestoneOptions } from './helpers';

interface Props {
  cargoReadyDate: Optional<CargoOverviewCargoReadyDate>;
  lastUpdated: Optional<JourneyLastUpdated>;
  legs: JourneyLeg[];
  modeOfTransport: Optional<ModeOfTransport>;
  postLegsShippingMilestones: JourneyShippingMilestone[];
  preLegsShippingMilestones: JourneyShippingMilestone[];
  zencargoReference: string;
}

const JourneyDetails: FC<Props> = (props: Props) => {
  const {
    cargoReadyDate,
    lastUpdated,
    legs,
    modeOfTransport,
    postLegsShippingMilestones,
    preLegsShippingMilestones,
    zencargoReference
  } = props;

  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      category: JourneyTrackingCategory,
      action: JourneyTrackingAction.CARGO_JOURNEY_VISIBLE,
      label: `${zencargoReference}|${modeOfTransport}`,
      properties: {
        zencargoReference,
        modeOfTransport
      }
    });
  }, [modeOfTransport, trackEvent, zencargoReference]);

  return (
    <>
      <JourneyLegs legs={legs} modeOfTransport={modeOfTransport} preLegsShippingMilestones={preLegsShippingMilestones} />
      {lastUpdated && (
        <div className="mt-auto">
          <LastUpdated
            action={
              <MilestoneDatesHistoryButton
                milestoneOptions={getMilestoneOptions(
                  cargoReadyDate,
                  legs,
                  modeOfTransport,
                  postLegsShippingMilestones,
                  preLegsShippingMilestones
                )}
              />
            }
            lastUpdated={lastUpdated}
          />
        </div>
      )}
    </>
  );
};

export default JourneyDetails;
