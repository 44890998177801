import type { FC } from 'react';

import UserDataProvider from '@zen/Auth/UserDataProvider';

import AccountActivationPage from './AccountActivationPage';

const AccountActivationPageContainer: FC = () => {
  return (
    <UserDataProvider>
      <AccountActivationPage />
    </UserDataProvider>
  );
};

export default AccountActivationPageContainer;
