import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IssuesResolveIssueMutationVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type IssuesResolveIssueMutationResult = { __typename?: 'Mutation' } & {
  issuesResolveIssue?: GraphQLTypes.Maybe<
    { __typename?: 'ResolveIssuePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
      issue?: GraphQLTypes.Maybe<
        { __typename: 'Issue' } & Pick<
          GraphQLTypes.Issue,
          | 'associatedId'
          | 'associatedType'
          | 'category'
          | 'id'
          | 'problemDescription'
          | 'severity'
          | 'solutionDescription'
          | 'state'
          | 'title'
        > & {
            canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canModifyVisibility: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canResolve: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canStart: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUpdateCategory: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUpdateProblemDescription: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
            openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
            openedBy: { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>;
            resolvedAt?: GraphQLTypes.Maybe<
              { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
            >;
            resolvedBy?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
            >;
            startedAt?: GraphQLTypes.Maybe<
              { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
            >;
            startedBy?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
            >;
            visibility: { __typename?: 'IssueVisibility' } & Pick<
              GraphQLTypes.IssueVisibility,
              'agentForwarder' | 'customerUser' | 'manufacturer'
            >;
          }
      >;
    }
  >;
};

export const IssuesResolveIssueDocument = /* #__PURE__ */ gql`
  mutation issuesResolveIssue($id: String!) {
    issuesResolveIssue(input: { id: $id }) {
      errors {
        message
      }
      issue {
        __typename
        associatedId
        associatedType
        canArchive {
          value
        }
        canModifyVisibility {
          value
        }
        canResolve {
          value
        }
        canStart {
          value
        }
        canUpdate {
          value
        }
        canUpdateCategory {
          value
        }
        canUpdateProblemDescription {
          value
        }
        category
        id
        occurredOn {
          date
        }
        openedAt {
          date
          dateTime
        }
        openedBy {
          firstName
          lastName
        }
        problemDescription
        resolvedAt {
          date
          dateTime
        }
        resolvedBy {
          firstName
          lastName
        }
        severity
        solutionDescription
        startedAt {
          date
          dateTime
        }
        startedBy {
          firstName
          lastName
        }
        state
        title
        visibility {
          agentForwarder
          customerUser
          manufacturer
        }
      }
    }
  }
`;

/**
 * __useIssuesResolveIssueMutation__
 *
 * To run a mutation, you first call `useIssuesResolveIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssuesResolveIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issuesResolveIssueMutation, { data, loading, error }] = useIssuesResolveIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIssuesResolveIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<IssuesResolveIssueMutationResult, IssuesResolveIssueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<IssuesResolveIssueMutationResult, IssuesResolveIssueMutationVariables>(
    IssuesResolveIssueDocument,
    options
  );
}
export type IssuesResolveIssueMutationHookResult = ReturnType<typeof useIssuesResolveIssueMutation>;
