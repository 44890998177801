import { components } from 'react-select';

import { IconButton } from '../../../Button';

const ClearIndicator: typeof components.ClearIndicator = (props) => {
  return (
    components.ClearIndicator && (
      <components.ClearIndicator {...props}>
        <IconButton
          className="text-xs cursor-pointer text-grey-light"
          icon="zicon-close"
          size="small"
          title="Clear"
          variant="ghost"
        />
      </components.ClearIndicator>
    )
  );
};

export default ClearIndicator;
