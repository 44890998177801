import type { ReactNode } from 'react';

import type { Nullable } from '@zen/utils/typescript';

import FilterComponent from '../FilterComponents';
import type { ComponentType, FilterItemType } from '../types';
import FilterChip from './FilterChip';

interface Props<FiltersType> {
  activeItemKey: string;
  deselectFilter: () => void;
  filters: Array<FilterItemType<FiltersType>>;
  onChange: (key: keyof FiltersType, value: unknown) => void;
  onClick: (key: keyof FiltersType) => void;
  onDelete: (key: keyof FiltersType) => void;
  value: FiltersType;
}

const FilterChips = <T,>({ filters, activeItemKey, value, onChange, onDelete, deselectFilter, onClick }: Props<T>) => {
  const renderFilters = () =>
    filters.map((filter, index) => {
      const handleDelete = (): void => onDelete(filter.key);

      const openFilter = (): void => {
        onClick(filter.key);
      };

      return (
        <div key={filter.key as string}>
          <FilterComponent
            active={activeItemKey === filter.key}
            componentType={filter.componentType}
            deselectFilter={deselectFilter}
            disabled={filter.disabled}
            onChange={(filterValue) => {
              onChange(filter.key, filterValue);
            }}
            onDelete={handleDelete}
            props={filter.props as ComponentType<unknown>['props']}
            value={value[filter.key] as unknown}
          >
            {(formattedValue: Nullable<ReactNode>) => (
              <div data-filter-key={filter.key}>
                <FilterChip
                  disabled={filter.disabled}
                  label={filter.title}
                  onClick={openFilter}
                  onDelete={handleDelete}
                  value={formattedValue}
                />
              </div>
            )}
          </FilterComponent>
        </div>
      );
    });

  return <div className="flex space-x-2">{renderFilters()}</div>;
};

export default FilterChips;
