import type { FC } from 'react';

import { cargoModeOptions } from '@zen/Cargo';
import FiltersComponent from '@zen/Components/Filters';
import { modeOfTransportOptions } from '@zen/Components/ModeOfTransportSelect';
import { cargoModeLabel } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';

import type { RateCardsFilters } from '../types';

const Filters: FC = () => {
  const { appliedFilters, setAppliedFilters } = useAppliedFilters<RateCardsFilters>();

  return (
    <FiltersComponent
      items={[
        {
          key: 'modeOfTransport',
          title: 'Mode',
          componentType: 'select',
          disabled: true,
          props: { options: modeOfTransportOptions }
        },
        {
          key: 'cargoType',
          disabled: true,
          componentType: 'select',
          title: cargoModeLabel,
          props: { options: cargoModeOptions }
        },
        { key: 'date', title: 'Valid on', componentType: 'date-picker', props: {} }
      ]}
      onChange={setAppliedFilters}
      value={appliedFilters}
    />
  );
};

export default Filters;
