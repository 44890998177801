import type { FC } from 'react';

import { DropZone } from '@zen/DesignSystem';

import DirectUploader from '../DirectUploader';
import type { Document, QueuedDocument } from '../types';

interface Props {
  className?: string;
  dropZoneClassName?: string;
  onError?: (message: string) => void;
  onFinish: (document: Document) => void;
  onProgress?: (document: QueuedDocument) => void;
  onStart: (document: QueuedDocument) => void;
}

const DocumentDropZone: FC<Props> = ({ onStart, onProgress = () => null, onFinish, onError, className, dropZoneClassName }) => {
  const handleDrop = (files: File[]): void => {
    files.forEach((file) => {
      const uploader = new DirectUploader(file, onStart, onFinish, onProgress, onError);

      uploader.upload();
    });
  };

  return (
    <div className={className}>
      <DropZone className={dropZoneClassName} onDrop={handleDrop} />
    </div>
  );
};

export default DocumentDropZone;
