import { sortBy, uniqBy } from 'lodash';
import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { RailwayCompany } from '../../../types';
import { useRailwayCompanyLazyQuery } from '../RailwayCompanyInlineField/graphql';
import { useRailwayCompaniesLazyQuery } from './graphql';

type SearchableOptionsArguments = {
  selectedValue: Optional<string>;
};

export const useSearchableOptions = ({ selectedValue }: SearchableOptionsArguments) => {
  const [fetchRailwayCompanies, { data, loading, error }] = useRailwayCompaniesLazyQuery();
  const [fetchRailwayCompany, { data: railwayCompanyData }] = useRailwayCompanyLazyQuery();

  useEffect(() => {
    if (selectedValue) {
      fetchRailwayCompany({
        variables: {
          id: selectedValue
        }
      });
    } else {
      fetchRailwayCompanies({
        variables: {
          query: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchRailwayCompanies({
        variables: {
          query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createOption = (company: Optional<RailwayCompany>): Option<string> => ({
    label: company?.railwayCompany || '',
    value: company?.id || ''
  });

  const railwayCompanies = [railwayCompanyData?.railwayCompany, ...(data?.railwayCompanies?.nodes || [])].filter(Boolean);
  const uniqueRailwayCompanies = sortBy(uniqBy(railwayCompanies, 'id'), 'railwayCompany');

  return {
    options: uniqueRailwayCompanies.map(createOption),
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
