import cx from 'classnames';
import type { ReactNode } from 'react';

import { Chip, Option, Tooltip } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { ArrayElement } from '@zen/utils/typescript';

interface Props<T> {
  content: ReactNode;
  isDisabled: boolean;
  selectedOptions: Option<ArrayElement<T>>[];
  variant: 'default' | 'inline';
}

const RateCardMultiSelectLabel = <T extends {}>({ content, isDisabled, selectedOptions, variant }: Props<T>) => {
  const { isEditable } = useRateCardContext();

  const isInline: boolean = variant === 'inline';
  const className: string = cx({
    'bg-navy-light': !isInline && isEditable,
    'bg-transparent hover:bg-transparent': isInline || isDisabled
  });

  const tooltipContent: ReactNode = selectedOptions.map((option: Option<ArrayElement<T>>, index: number) => (
    <div key={index} className="pb-1">
      {option.label}
    </div>
  ));
  const chip: ReactNode = <Chip className={className} label={content} size="compact" />;
  const chipWithTooltip: ReactNode = (
    <Tooltip placement="right" tooltipContent={tooltipContent}>
      {chip}
    </Tooltip>
  );
  const labelContent: ReactNode = isInline && selectedOptions.length > 1 ? chipWithTooltip : chip;
  const placeholder: string = !isEditable ? '-' : 'Select...';

  return (
    <div data-testid="rate-card-multi-select-label">
      {!selectedOptions.length ? <div className="w-full text-sm text-grey-light">{placeholder} </div> : labelContent}
    </div>
  );
};

export type { Props as RateCardMultiSelectLabelProps };

export default RateCardMultiSelectLabel;
