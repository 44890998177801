import { get } from 'lodash';
import type { FC } from 'react';

import { useBusinessUnitOptions } from '@zen/Accounts/hooks/useBusinessUnitOptions';
import type { Option } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import OptionsDropdown from '../../components/OptionsDropdown/OptionsDropdown';
import { useUpdateMemberBusinessUnitsMutation } from '../../graphql';

type ItemWithId = { id: string };

interface Props {
  accountId: string;
  buttonText: string;
  memberBusinessUnits: ItemWithId[];
  memberId: string;
}

const AssignBusinessUnitsButton: FC<Props> = ({ buttonText, memberId, memberBusinessUnits, accountId }) => {
  const [updateMemberBusinessUnits] = useUpdateMemberBusinessUnitsMutation();
  const { addError, addSuccess } = useNotification();
  const options: Option<string>[] = useBusinessUnitOptions(accountId);

  const handleApply = async (businessUnits: string[]): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateMemberBusinessUnits({
          awaitRefetchQueries: true,
          refetchQueries: ['getAccountMembers'],
          variables: { input: { memberId, businessUnits } }
        }),
      onError: (errors) => addError(get(errors, '[0].message', 'There was a problem updating the member.')),
      onSuccess: () => {
        addSuccess('Member was successfully updated.');
      }
    });
  };

  const selectedOptions: string[] = memberBusinessUnits?.map((businessUnit) => businessUnit.id) || [];

  return (
    <OptionsDropdown
      allOptionsLabel="All business units"
      buttonText={buttonText}
      initialValues={selectedOptions}
      onApply={handleApply}
      options={options}
    />
  );
};

export default AssignBusinessUnitsButton;
export type { Props as AssignBusinessUnitsButtonProps };
