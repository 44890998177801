import { parseDate } from '../jodaConnector';
import type { Duration } from '../types';

export const subtract = (date: string, duration: Duration): string => {
  let baseDate = parseDate(date);

  if (duration?.days) {
    baseDate = baseDate.minusDays(duration.days);
  }

  if (duration?.months) {
    baseDate = baseDate.minusMonths(duration.months);
  }

  if (duration?.years) {
    baseDate = baseDate.minusYears(duration.years);
  }

  return baseDate.toString();
};

export const add = (date: string, duration: Duration): string => {
  let baseDate = parseDate(date);

  if (duration?.days) {
    baseDate = baseDate.plusDays(duration.days);
  }

  if (duration?.months) {
    baseDate = baseDate.plusMonths(duration.months);
  }

  if (duration?.years) {
    baseDate = baseDate.plusYears(duration.years);
  }

  return baseDate.toString();
};
