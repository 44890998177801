import type { ReactNode } from 'react';

type CellContentPlacement = 'top-start' | 'right' | 'left';

export interface TableColumn<T = {}> {
  alignment?: CellContentPlacement;
  bulkSelection?: boolean;
  className?: string;
  dataKey?: string;
  ellipsis?: boolean;
  fixed?: 'left' | 'right';
  key: string;
  mergeBy?: (record: T, index: number) => string;
  onEdit?: (key: string, record: T) => void;
  render?: (value: unknown, record: T, index: number) => ReactNode;
  resizable?: boolean;
  sortKey?: string;
  sortable?: boolean;
  title: ReactNode;
  width?: number | string;
}

export type TableColumnOrder = Pick<TableColumn, 'fixed' | 'key'>;

export type RowSpan = { atIndex: number; value: number };
export type RowSpanConfiguration = Record<string, RowSpan[]>;

export interface TotalCountConfig {
  entityName?: string;
  isLoading?: boolean;
  totalCount: number;
}

export const TableConfigurationTrackingCategory = 'TableConfiguration';

export enum TableConfigurationTrackingAction {
  CUSTOMISE = 'CustomiseTableColumn',
  REORDER = 'ReorderTableColumn',
  RESIZE = 'ResizeTableColumn',
  SORT = 'SortTableColumn'
}

// should not be exported outside of table
export interface RCTableColumn<T> extends Omit<TableColumn<T>, 'dataKey' | 'sortable' | 'sortKey'> {
  dataIndex: string;
}

export type DataWithRowId<T, K = T> = ExpandableData<T, K> & { uniqueTableRowIdentifier: string };
export type ExpandableData<T, K = T> = T & { nestedRowItems?: K[] };
