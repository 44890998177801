import { sortBy, uniqBy } from 'lodash';
import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { Courier } from '../../../types';
import { useCourierLazyQuery } from '../CourierInlineField/graphql';
import { useCouriersLazyQuery } from './graphql';

type SearchableOptionsArguments = {
  selectedValue: Optional<string>;
};

export const useSearchableOptions = ({ selectedValue }: SearchableOptionsArguments) => {
  const [fetchCouriers, { data, loading, error }] = useCouriersLazyQuery();
  const [fetchCourier, { data: courierData }] = useCourierLazyQuery();

  useEffect(() => {
    if (selectedValue) {
      fetchCourier({
        variables: {
          id: selectedValue
        }
      });
    } else {
      fetchCouriers({
        variables: {
          query: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchCouriers({
        variables: {
          query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createOption = (courier: Optional<Courier>): Option<string> => ({
    label: courier?.courierName || '',
    value: courier?.id || ''
  });

  const couriers = [courierData?.courier, ...(data?.couriers?.nodes || [])].filter(Boolean);
  const uniqueCouriers = sortBy(uniqBy(couriers, 'id'), 'courierName');

  return {
    options: uniqueCouriers.map(createOption),
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
