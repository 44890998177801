import { mergeArrayType } from '../cacheHelpers';

const PurchaseOrder = {
  fields: {
    lotsByStageCount: {
      merge: mergeArrayType
    }
  }
};

export default PurchaseOrder;
