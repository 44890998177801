import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworkPermissionsQueryVariables = GraphQLTypes.Exact<{
  networkId: GraphQLTypes.Scalars['String'];
}>;

export type NetworkPermissionsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      canCreateAgentOrganisation: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canCreateSavedOrganisation: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canCreateForwarderOrganisation: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
    }
  >;
};

export const NetworkPermissionsQueryDocument = /* #__PURE__ */ gql`
  query networkPermissionsQuery($networkId: String!) {
    network(id: $networkId) {
      canCreateAgentOrganisation {
        value
      }
      canCreateSavedOrganisation {
        value
      }
      canCreateForwarderOrganisation {
        value
      }
    }
  }
`;

/**
 * __useNetworkPermissionsQuery__
 *
 * To run a query within a React component, call `useNetworkPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkPermissionsQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useNetworkPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<NetworkPermissionsQueryResult, NetworkPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<NetworkPermissionsQueryResult, NetworkPermissionsQueryVariables>(
    NetworkPermissionsQueryDocument,
    options
  );
}
export function useNetworkPermissionsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NetworkPermissionsQueryResult, NetworkPermissionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<NetworkPermissionsQueryResult, NetworkPermissionsQueryVariables>(
    NetworkPermissionsQueryDocument,
    options
  );
}
export type NetworkPermissionsQueryHookResult = ReturnType<typeof useNetworkPermissionsQuery>;
export type NetworkPermissionsQueryLazyQueryHookResult = ReturnType<typeof useNetworkPermissionsQueryLazyQuery>;
