import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceArchiveAccrualMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveAccrualMutationInput;
}>;

export type FinanceArchiveAccrualMutationResult = { __typename?: 'Mutation' } & {
  financeArchiveAccrual?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveAccrualMutationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      accrual?: GraphQLTypes.Maybe<{ __typename?: 'Accrual' } & Pick<GraphQLTypes.Accrual, 'id' | 'state'>>;
    }
  >;
};

export const FinanceArchiveAccrualDocument = /* #__PURE__ */ gql`
  mutation financeArchiveAccrual($input: ArchiveAccrualMutationInput!) {
    financeArchiveAccrual(input: $input) {
      errors {
        message
        path
      }
      accrual {
        id
        state
      }
    }
  }
`;

/**
 * __useFinanceArchiveAccrualMutation__
 *
 * To run a mutation, you first call `useFinanceArchiveAccrualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinanceArchiveAccrualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financeArchiveAccrualMutation, { data, loading, error }] = useFinanceArchiveAccrualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinanceArchiveAccrualMutation(
  baseOptions?: Apollo.MutationHookOptions<FinanceArchiveAccrualMutationResult, FinanceArchiveAccrualMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinanceArchiveAccrualMutationResult, FinanceArchiveAccrualMutationVariables>(
    FinanceArchiveAccrualDocument,
    options
  );
}
export type FinanceArchiveAccrualMutationHookResult = ReturnType<typeof useFinanceArchiveAccrualMutation>;
