import type { DateRange } from '@zen/DesignSystem';
import type {
  ShipmentsTableCustomsOnlyValue,
  ShipmentsTableMilestone,
  ShipmentsTableModeOfTransport,
  ShipmentsTableQuotesRequestStatus
} from '@zen/graphql/types.generated';
import type { DeepNonNullable } from '@zen/utils/typescript';

import type { IncotermsValue } from '../types';
import type { ShipmentsTableFilterValue } from './Filters/hooks/types';
import type { ShipmentsQueryResult } from './graphql';

export type {
  LiveViewsFilterDateRangeInput,
  ShipmentsTableCargoGroup,
  ShipmentsTableCargoGroupsFiltersInput,
  ShipmentsTableCargoGroupsSortByInput,
  ShipmentsTableDateWithCertainty,
  ShipmentsTableDelayWithStatus
} from '@zen/graphql/types.generated';
export {
  IncotermsValue,
  LiveViewsSortOrder,
  ShipmentsTableCargoGroupsGroupByColumn,
  ShipmentsTableCargoGroupsSortByColumn,
  ShipmentsTableCustomsOnlyValue,
  ShipmentsTableDateCertainty,
  ShipmentsTableDelayStatus,
  ShipmentsTableMilestone,
  ShipmentsTableModeOfTransport
} from '@zen/graphql/types.generated';
export type ShipmentsTableItemType = DeepNonNullable<ShipmentsQueryResult, 'nodes'>['shipmentsTableCargoGroups']['nodes'];

export type ShipmentsTableCargoItem = Omit<ShipmentsTableItemType['cargos'][0], '__typename'>;

export enum RelativeDateRange {
  LAST_14_DAYS = 'LAST_14_DAYS',
  LAST_3_DAYS = 'LAST_3_DAYS',
  LAST_7_DAYS = 'LAST_7_DAYS',
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  NEXT_3_DAYS = 'NEXT_3_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS',
  TODAY = 'TODAY',
  TOMORROW = 'TOMORROW',
  YESTERDAY = 'YESTERDAY'
}

export type ShipmentsTableFilters = Partial<{
  arrivalDate: DateRange<RelativeDateRange> | RelativeDateRange;
  arrivalOffset: { from: number; to: number }[];
  cargoReadyDate: DateRange<RelativeDateRange> | RelativeDateRange;
  carrierScac: string[];
  collectionDate: DateRange<RelativeDateRange> | RelativeDateRange;
  collectionLocation: string[];
  currentMilestone: ShipmentsTableMilestone[];
  customsOnly: ShipmentsTableCustomsOnlyValue;
  deliveryDate: DateRange<RelativeDateRange> | RelativeDateRange;
  deliveryLocation: string[];
  deliveryOffset: { from: number; to: number }[];
  departureDate: DateRange<RelativeDateRange> | RelativeDateRange;
  departureOffset: { from: number; to: number }[];
  incoterms: IncotermsValue[];
  modeOfTransport: ShipmentsTableModeOfTransport[];
  portOfDestination: ShipmentsTableFilterValue[];
  portOfLoading: ShipmentsTableFilterValue[];
  product: ShipmentsTableFilterValue[];
  productCategory: ShipmentsTableFilterValue[];
  quotesRequestStatus?: ShipmentsTableQuotesRequestStatus[];
}>;

export type ShipmentsTableFiltersValues = ModifiedDateRanges<ShipmentsTableFilters>;

export type ShipmentsTableDateFilterKey = 'cargoReadyDate' | 'collectionDate' | 'departureDate' | 'arrivalDate' | 'deliveryDate';

export type ModifiedDateRanges<T extends Pick<ShipmentsTableFilters, ShipmentsTableDateFilterKey>> = Omit<
  T,
  ShipmentsTableDateFilterKey
> &
  Partial<{
    arrivalDate: DateRange<RelativeDateRange>;
    cargoReadyDate: DateRange<RelativeDateRange>;
    collectionDate: DateRange<RelativeDateRange>;
    deliveryDate: DateRange<RelativeDateRange>;
    departureDate: DateRange<RelativeDateRange>;
  }>;

export enum ShipmentsTableColumn {
  ARRIVAL_DATE = 'Arrival date',
  ARRIVAL_STATUS = 'Arrival status',
  CARGO_READY_DATE = 'Cargo ready date',
  CARGO_TYPE = 'Cargo type',
  CARRIER_SCAC = 'Carrier (SCAC)',
  CLIENT_REFERENCE = 'Client ref.',
  COLLECTION = 'Collection',
  COLLECTION_DATE = 'Collection date',
  CONSIGNEE = 'Consignee',
  CONSIGNOR = 'Consignor',
  CONSOLIDATION_REFERENCE = 'Consolidation ref.',
  CONTAINER_NUMBER = 'Container number',
  CURRENT_MILESTONE = 'Current milestone',
  CUSTOMER = 'Customer',
  CUSTOMS_ONLY = 'Customs only',
  DELIVERY_DATE = 'Delivery date',
  DELIVERY_STATUS = 'Delivery status',
  DEPARTURE_DATE = 'Departure date',
  DEPARTURE_STATUS = 'Departure status',
  DESTINATION = 'Destination',
  INCOTERMS = 'Incoterms',
  MASTER_AIRWAY_BILL = 'Master airway bill',
  MASTER_BILL_OF_LADING = 'Master bill of lading',
  MODE = 'Mode',
  PORT_OF_DESTINATION = 'Port of destination',
  PORT_OF_LOADING = 'Port of loading',
  PO_REFERENCES = 'PO references',
  QUOTE_STATUS = 'Status',
  REFERENCES = 'References',
  VEHICLE_PLATE = 'Vehicle plate',
  ZENCARGO_REFERENCE = 'Zencargo ref.'
}
