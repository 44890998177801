import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetPurchaseOrdersOriginAgentAssignmentRuleQueryVariables = GraphQLTypes.Exact<{
  id?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetPurchaseOrdersOriginAgentAssignmentRuleQueryResult = { __typename?: 'Query' } & {
  purchaseOrdersOriginAgentAssignmentRules: { __typename?: 'OriginAgentAssignmentRuleConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'OriginAgentAssignmentRule' } & Pick<
            GraphQLTypes.OriginAgentAssignmentRule,
            'id' | 'modeOfTransport'
          > & {
              account: { __typename?: 'LegacyAccount' } & Pick<GraphQLTypes.LegacyAccount, 'uuid' | 'name'>;
              collectionWarehouse?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  }
              >;
              deliveryWarehouse?: GraphQLTypes.Maybe<
                { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                    label?: GraphQLTypes.Maybe<
                      { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                    >;
                  }
              >;
              originAgent: { __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                  label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>>;
                };
              portOfDestination?: GraphQLTypes.Maybe<
                { __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'label'>
              >;
              portOfLoad?: GraphQLTypes.Maybe<{ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'unlocode' | 'label'>>;
            }
        >
      >
    >;
  };
};

export const GetPurchaseOrdersOriginAgentAssignmentRuleDocument = /* #__PURE__ */ gql`
  query getPurchaseOrdersOriginAgentAssignmentRule($id: String) {
    purchaseOrdersOriginAgentAssignmentRules(id: $id) {
      nodes {
        account {
          uuid
          name
        }
        collectionWarehouse {
          id
          label {
            long
          }
        }
        deliveryWarehouse {
          id
          label {
            long
          }
        }
        id
        modeOfTransport
        originAgent {
          id
          label {
            long
          }
        }
        portOfDestination {
          unlocode
          label
        }
        portOfLoad {
          unlocode
          label
        }
      }
    }
  }
`;

/**
 * __useGetPurchaseOrdersOriginAgentAssignmentRuleQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrdersOriginAgentAssignmentRuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrdersOriginAgentAssignmentRuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrdersOriginAgentAssignmentRuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPurchaseOrdersOriginAgentAssignmentRuleQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryVariables
  >(GetPurchaseOrdersOriginAgentAssignmentRuleDocument, options);
}
export function useGetPurchaseOrdersOriginAgentAssignmentRuleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryResult,
    GetPurchaseOrdersOriginAgentAssignmentRuleQueryVariables
  >(GetPurchaseOrdersOriginAgentAssignmentRuleDocument, options);
}
export type GetPurchaseOrdersOriginAgentAssignmentRuleQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersOriginAgentAssignmentRuleQuery
>;
export type GetPurchaseOrdersOriginAgentAssignmentRuleLazyQueryHookResult = ReturnType<
  typeof useGetPurchaseOrdersOriginAgentAssignmentRuleLazyQuery
>;
