import type { FC } from 'react';

import { Icon, Switch } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  checked: boolean;
  disabled?: boolean;
  icon: IconName;
  name?: string;
  onChange: (checked: boolean) => void;
  subtitle?: string;
  title: string;
}

const PermissionSwitcher: FC<Props> = ({ icon, title, subtitle, name, checked, disabled, onChange }) => {
  const handleChange = () => {
    onChange(!checked);
  };

  return (
    <div className="flex justify-between py-2 items-center">
      <div className="flex">
        <Icon className="pt-1" icon={icon} />
        <div className="flex flex-col pl-2">
          <div className="leading-normal text-grey-dark">{title}</div>
          {subtitle && (
            <div className="text-grey-base text-sm leading-normal" data-testid="permission-switcher-subtitle">
              {subtitle}
            </div>
          )}
        </div>
      </div>
      <Switch isChecked={checked} isDisabled={disabled} name={name} onChange={handleChange} />
    </div>
  );
};

export default PermissionSwitcher;
