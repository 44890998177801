import { ActivityFeedItemTypeEnum } from '../types';

export const isItemWithNoAdditionalData = (itemType: ActivityFeedItemTypeEnum): boolean => {
  const itemsWithNoAdditinalData: ActivityFeedItemTypeEnum[] = [
    ActivityFeedItemTypeEnum.REQUIRED_CUSTOMER_APPROVAL,
    ActivityFeedItemTypeEnum.MANUFACTURER_REQUESTED_BOOKING,
    ActivityFeedItemTypeEnum.REJECTED_BOOKING_REQUEST,
    ActivityFeedItemTypeEnum.CUSTOMER_APPROVED_BOOKING,
    ActivityFeedItemTypeEnum.FORWARDER_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING,
    ActivityFeedItemTypeEnum.FORWARDER_REVERTED_REJECTION_AND_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING,
    ActivityFeedItemTypeEnum.FORWARDER_RESUBMITTED_BOOKING_FOR_CUSTOMER_APPROVAL,
    ActivityFeedItemTypeEnum.MANUFACTURER_RESUBMITTED_BOOKING_FOR_FORWARDER_APPROVAL
  ];

  return !!itemsWithNoAdditinalData.find((type: ActivityFeedItemTypeEnum) => type === itemType);
};

export function isItemWithMetadata(itemType: ActivityFeedItemTypeEnum): boolean {
  const itemTypesWithMetadata: ActivityFeedItemTypeEnum[] = [
    ActivityFeedItemTypeEnum.REQUESTED_COLLECTION,
    ActivityFeedItemTypeEnum.REQUESTED_DELIVERY,
    ActivityFeedItemTypeEnum.REREQUESTED_COLLECTION,
    ActivityFeedItemTypeEnum.REREQUESTED_DELIVERY,
    ActivityFeedItemTypeEnum.CONFIRMED_COLLECTION,
    ActivityFeedItemTypeEnum.CONFIRMED_DELIVERY,
    ActivityFeedItemTypeEnum.COMPLETED_COLLECTION,
    ActivityFeedItemTypeEnum.COMPLETED_DELIVERY,
    ActivityFeedItemTypeEnum.OPENED_ISSUE,
    ActivityFeedItemTypeEnum.STARTED_ISSUE,
    ActivityFeedItemTypeEnum.RESOLVED_ISSUE,
    ActivityFeedItemTypeEnum.UPDATED_ISSUE,
    ActivityFeedItemTypeEnum.ARCHIVED_ISSUE,
    ActivityFeedItemTypeEnum.ADDED_CARGO_ITEM,
    ActivityFeedItemTypeEnum.DELETED_CARGO_ITEM,
    ActivityFeedItemTypeEnum.UPDATED_CARGO_ITEM,
    ActivityFeedItemTypeEnum.UPDATED_CARGO_TYPE,
    ActivityFeedItemTypeEnum.ETA_UPDATED_ITEM,
    ActivityFeedItemTypeEnum.ETA_REMOVED_ITEM
  ];

  return !!itemTypesWithMetadata.find((type: ActivityFeedItemTypeEnum) => type === itemType);
}

export function getStoragePrefix(zencargoReference: string): string {
  const STORAGE_PREFIX: string = 'activity-prefix';

  return `${STORAGE_PREFIX}-${zencargoReference}`;
}
