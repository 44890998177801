import cx from 'classnames';
import type { FC, ReactElement, ReactNode } from 'react';

export interface SummaryRowProps {
  className?: string;
  icon?: ReactNode;
  name: string;
  percentage?: string;
  quantity: string;
  testId?: string;
  value: number | string;
}

interface ParentComposition {
  Row: FC<SummaryRowProps>;
}

export interface SummaryTableProps {
  children: ReactElement<typeof SummaryRow> | Array<ReactElement<typeof SummaryRow>>;
  className?: string;
  testId?: string;
}

const tableClassName: string = 'mt-4 mb-4 pt-2 border-grey-light border-t border-solid text-sm font-bold';
const rowClassName: string = 'relative flex justify-between py-5 pl-14';
const iconWrapperClassName: string = 'absolute top-[calc(50%-1rem)] left-3';

const SummaryRow: FC<SummaryRowProps> = ({ className, name, percentage, quantity, testId, value, icon }) => {
  const placeholder = '-';

  return (
    <div className={cx(rowClassName, className)} data-testid={testId}>
      {icon && <span className={iconWrapperClassName}>{icon}</span>}
      <div className="flex-1">{name}</div>
      <div className="flex-1">{quantity}</div>
      <div className="flex-[0.8]">{percentage ? `${percentage}%` : placeholder}</div>
      <div className="flex-[1.5]">{value || placeholder}</div>
    </div>
  );
};

const SummaryTable: FC<SummaryTableProps> & ParentComposition = ({ children, className, testId }) => {
  return (
    <div className={cx(tableClassName, className)} data-testid={testId}>
      <div className={cx('font-normal py-1 px-0 text-grey-base', rowClassName)}>
        <div className="flex-1" />
        <div className="flex-1">Quantity</div>
        <div className="flex-[0.8]">%</div>
        <div className="flex-[1.5]">Value</div>
      </div>
      {children}
    </div>
  );
};

SummaryTable.Row = SummaryRow;

export default SummaryTable;
