import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCompletedTaskListQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  taskDetailsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.TaskDetailsFiltersInput>;
}>;

export type GetCompletedTaskListQueryResult = { __typename?: 'Query' } & {
  taskDetails?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskDetailsPayloadType' } & {
      tasks?: GraphQLTypes.Maybe<
        { __typename?: 'WorkflowTaskConnection' } & Pick<GraphQLTypes.WorkflowTaskConnection, 'totalCount'> & {
            nodes: Array<
              { __typename?: 'WorkflowTaskType' } & Pick<
                GraphQLTypes.WorkflowTaskType,
                'taskId' | 'name' | 'dueDate' | 'completedDate' | 'priority' | 'zencargoReference'
              > & {
                  assignee?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName' | 'id'>
                  >;
                  workflowTaskVariables?: GraphQLTypes.Maybe<
                    Array<{ __typename?: 'KeyValuePair' } & Pick<GraphQLTypes.KeyValuePair, 'key' | 'value'>>
                  >;
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
              GraphQLTypes.PageInfo,
              'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >;
          }
      >;
    }
  >;
};

export const GetCompletedTaskListDocument = /* #__PURE__ */ gql`
  query getCompletedTaskList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
    $taskDetailsFiltersInput: TaskDetailsFiltersInput
  ) {
    taskDetails(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      taskDetailsFiltersInput: $taskDetailsFiltersInput
    ) {
      tasks: completedTasks {
        nodes {
          assignee {
            firstName
            lastName
            id
          }
          taskId
          name
          dueDate
          completedDate
          priority
          zencargoReference
          workflowTaskVariables {
            key
            value
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

/**
 * __useGetCompletedTaskListQuery__
 *
 * To run a query within a React component, call `useGetCompletedTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompletedTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompletedTaskListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      taskDetailsFiltersInput: // value for 'taskDetailsFiltersInput'
 *   },
 * });
 */
export function useGetCompletedTaskListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompletedTaskListQueryResult, GetCompletedTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCompletedTaskListQueryResult, GetCompletedTaskListQueryVariables>(
    GetCompletedTaskListDocument,
    options
  );
}
export function useGetCompletedTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompletedTaskListQueryResult, GetCompletedTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCompletedTaskListQueryResult, GetCompletedTaskListQueryVariables>(
    GetCompletedTaskListDocument,
    options
  );
}
export type GetCompletedTaskListQueryHookResult = ReturnType<typeof useGetCompletedTaskListQuery>;
export type GetCompletedTaskListLazyQueryHookResult = ReturnType<typeof useGetCompletedTaskListLazyQuery>;
