const WAREHOUSE_INTAKE_RELATIVE_INDEX_PATH = 'warehouse-intake';

export const WAREHOUSE_INTAKE_ROUTE_PREFIX = `/dashboard/${WAREHOUSE_INTAKE_RELATIVE_INDEX_PATH}`;

interface WarehouseIntakeRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const warehouseIntakeRoutes: WarehouseIntakeRoutes = {
  index: {
    getUrl: () => WAREHOUSE_INTAKE_ROUTE_PREFIX,
    path: `${WAREHOUSE_INTAKE_ROUTE_PREFIX}/*`,
    relativePath: `${WAREHOUSE_INTAKE_RELATIVE_INDEX_PATH}/*`
  }
};

export default warehouseIntakeRoutes;
