import { isEmpty } from 'lodash';

import { isGroupOption } from '@zen/DesignSystem';

import type { FilterGroup, FilterOption, FilterOptionsType } from '../../types';
import type { ListItemType } from './OptionList/ListItem/types';
import type { OptionListSection } from './OptionList/types';

type PrioritisableListItemType = ListItemType & { isPrioritised?: boolean };
type ListItemAttributes<T> = {
  isPrioritised?: (option: FilterOption<T>) => boolean;
  isSelected: (option: FilterOption<T>) => boolean;
  onClick: (option: FilterOption<T>) => void;
};

export const buildOptionListSections = <T>(
  options: FilterOptionsType<T>,
  attributes: ListItemAttributes<T>
): Array<OptionListSection> => {
  const groups = getGroupOptions(options);
  const { isSelected, isPrioritised, onClick } = attributes;
  const listItems = groups.map((group: FilterGroup<T>) => ({
    title: group.label,
    items: group.options.map((option: FilterOption<T>) => ({
      label: option.label,
      isSelected: isSelected(option),
      isPrioritised: isPrioritised?.(option),
      onClick: () => onClick(option),
      renderLabel: option.renderLabel
    }))
  }));

  const shouldGroupOptions = listItems.length === 1;

  return shouldGroupOptions ? splitByPrioritised(listItems[0]?.items || []) : listItems;
};

const splitByPrioritised = <T extends PrioritisableListItemType>(listItems: T[]) => {
  const prioritisedItems: PrioritisableListItemType[] = listItems.filter((listItem) => listItem.isPrioritised);
  const remainingItems: PrioritisableListItemType[] = listItems.filter((listItem) => !listItem.isPrioritised);

  return [prioritisedItems, remainingItems]
    .filter((items) => !isEmpty(items))
    .map((items) => ({
      items
    }));
};

export const getGroupOptions = <T>(options: FilterOptionsType<T>): FilterGroup<T>[] =>
  options?.some((option) => isGroupOption(option))
    ? (options as FilterGroup<T>[])
    : [{ options: (options || []) as FilterOption<T>[] }];
