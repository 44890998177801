import cx from 'classnames';
import pDebounce from 'p-debounce';
import { createRef, FC, RefObject, useEffect, useState } from 'react';
import { getGeocode, getLatLng } from 'use-places-autocomplete';

import { FormHiddenInput, FormNumberInput } from '@zen/Components/Form';
import FormCountriesSelect from '@zen/Components/Form/FormCountriesSelect';
import FormGeosuggestAutocomplete from '@zen/Components/Form/FormGeosuggestAutocomplete';
import FormInput from '@zen/Components/Form/FormInput';
import FormRadioGroup from '@zen/Components/Form/FormRadioGroup/FormRadioGroup';
import { useForm } from '@zen/Components/Form/useForm';
import type { GeoSuggestAddress } from '@zen/Components/GeosuggestAutocomplete/types';
import Map from '@zen/Components/Map';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import { Banner } from '@zen/DesignSystem';
import { LocationTypeValue } from '@zen/Networks/types';

import FormBusinessHoursFields from '../FormBusinessHoursFields';
import type { IAddressForm } from './types';

const locationTypeOptions: RadioGroupOption[] = [
  { label: 'Office', value: LocationTypeValue.OFFICE },
  { label: 'Warehouse', value: LocationTypeValue.WAREHOUSE }
];

const commonAddressFields: string[] = ['city', 'countryCode', 'postalCode', 'state', 'street'];

interface Props {
  showLatLngFields?: boolean;
}

const FormAddressFields: FC<Props> = ({ showLatLngFields = false }) => {
  const { errors, setFieldError, setFieldValue, values } = useForm<IAddressForm>();
  const streetFieldRef: RefObject<HTMLInputElement> = createRef();
  const [resizable, setResizable] = useState<boolean>(true);

  const className: string = cx({ 'opacity-0 h-0 pointer-events-none': !values.addressFieldsVisible });

  const handleMapChange = (lat: number, lng: number): void => {
    setResizable(false);
    setCoordinates(lat, lng);
  };

  const setCoordinates = (lat: number, lng: number) => {
    setFieldValue('lat', lat);
    setFieldValue('lng', lng);
  };

  const handleAddressSelect = (geoAddress: GeoSuggestAddress): void => {
    const addressFields: string[] = [...commonAddressFields, 'lat', 'lng'];

    addressFields.forEach((key: string) => {
      setFieldValue(key, geoAddress[key as keyof GeoSuggestAddress]);
    });

    setResizable(true);
    showAddressFields();

    setFieldValue('locationAddress', '');
  };

  const handleManualEntry = (): void => {
    commonAddressFields.forEach((key: string) => setFieldValue(key, ''));

    showAddressFields();

    streetFieldRef.current?.focus();
  };

  const showAddressFields = () => setFieldValue('addressFieldsVisible', true);

  const handleCopyBusinessHours = (): void => {
    ['tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach((dayOfTheWeek: string) => {
      setFieldValue(`businessHours.${dayOfTheWeek}`, values.businessHours.monday);
    });
  };

  useEffect(() => {
    if (errors.timeZone) {
      setFieldError('lat', '');
      setFieldError('lng', '');
    }
  }, [errors, setFieldError]);

  const handleInputChange = (): void => {
    setResizable(true);
  };

  const handleCountryChange = async (country: string) => {
    const results = await getGeocode({ address: country });
    const { lat, lng } = await getLatLng(results[0]);

    setCoordinates(lat, lng);
    setResizable(true);
  };

  const debounceDelay: number = 700;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  return (
    <div data-testid="form-address-fields">
      <FormRadioGroup name="locationType" noMargin={true} options={locationTypeOptions} radioAlignment="column" />

      <FormBusinessHoursFields onCopy={handleCopyBusinessHours} />

      <FormGeosuggestAutocomplete
        isRequired={!values.addressFieldsVisible}
        label="Location address"
        name="locationAddress"
        onManualEntry={handleManualEntry}
        onSuggestionSelect={handleAddressSelect}
      />

      {values.addressFieldsVisible && (
        <div className={className}>
          <FormInput ref={streetFieldRef} isRequired={true} label="Address line 1" name="street" />
          <FormInput label="Town / City" name="city" />
          <FormInput label="State / County" name="state" />
          <FormInput className="w-64" label="ZIP / Post code" name="postalCode" />
          <FormCountriesSelect isRequired={true} label="Country" name="countryCode" onChange={handleCountryChange} />

          {showLatLngFields && (
            <div className="flex">
              <FormNumberInput
                className="w-64 mr-8"
                isRequired={true}
                label="Latitude"
                name="lat"
                onChange={debouncedHandleInputChange}
              />
              <FormNumberInput
                className="w-64"
                isRequired={true}
                label="Longitude"
                name="lng"
                onChange={debouncedHandleInputChange}
              />
            </div>
          )}

          <div className="mb-2 -mt-4">
            {!showLatLngFields && (
              <>
                <FormHiddenInput name="lat" />
                <FormHiddenInput name="lng" />
              </>
            )}
            <FormHiddenInput className="pt-2" name="timeZone" />
          </div>

          <div>
            <Banner
              className="p-4 mt-8 mb-4"
              message="Please make sure that the pin is in the right place on the map."
              type="announcement"
            />

            <div style={{ height: '20rem' }}>
              <Map
                markers={values.lng && values.lat ? [{ lng: values.lng, lat: values.lat }] : []}
                onMapChange={handleMapChange}
                resizable={resizable}
                singleMarkerMode={true}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FormAddressFields;
