import type { FC } from 'react';
import type { Location, unstable_Blocker as Blocker } from 'react-router-dom';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';

import { Dialog } from '@zen/DesignSystem';

export interface Props {
  allowedPaths?: string[];
  confirmationNeeded?: boolean;
  description?: string;
  header: string;
  leaveLabel: string;
  onLeave?: () => void;
  stayLabel: string;
}

const NavigationPrompt: FC<Props> = (props) => {
  const { allowedPaths = [], stayLabel, leaveLabel, header, onLeave = () => {}, confirmationNeeded = true, description } = props;

  useBeforeUnload(confirmationNeeded, "You'll lose your data!");

  const shouldPromptBeforeNavigatingAway = ({
    currentLocation,
    nextLocation
  }: {
    currentLocation: Location;
    nextLocation: Location;
  }) => {
    const isNewLocation = currentLocation.pathname !== nextLocation.pathname;
    const isAllowed = allowedPaths.some((allowPath) => nextLocation.pathname.includes(allowPath));

    if (confirmationNeeded && isNewLocation && !isAllowed) {
      return true;
    }

    return false;
  };

  const blocker: Blocker = useBlocker(shouldPromptBeforeNavigatingAway);
  const isPromptOpen: boolean = blocker.state === 'blocked';

  const handleStayOnPage = () => {
    blocker.reset?.();
  };

  const handleLeavePage = () => {
    blocker.proceed?.();
    onLeave();
  };

  return (
    <Dialog
      cancelLabel={stayLabel}
      confirmLabel={leaveLabel}
      header={header}
      isOpen={isPromptOpen}
      message={description}
      onClose={handleStayOnPage}
      onConfirm={handleLeavePage}
    />
  );
};

export default NavigationPrompt;

export type { Props as NavigationPromptProps };
