import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignedChargesOnDefaultChargesQueryVariables = GraphQLTypes.Exact<{
  applicability?: GraphQLTypes.InputMaybe<GraphQLTypes.Applicability>;
}>;

export type AssignedChargesOnDefaultChargesQueryResult = { __typename?: 'Query' } & {
  assignedChargesOnDefaultCharges?: GraphQLTypes.Maybe<
    Array<{ __typename?: 'ChargesPayload' } & Pick<GraphQLTypes.ChargesPayload, 'id' | 'name'>>
  >;
};

export const AssignedChargesOnDefaultChargesDocument = /* #__PURE__ */ gql`
  query assignedChargesOnDefaultCharges($applicability: Applicability) {
    assignedChargesOnDefaultCharges(applicability: $applicability) {
      id
      name
    }
  }
`;

/**
 * __useAssignedChargesOnDefaultChargesQuery__
 *
 * To run a query within a React component, call `useAssignedChargesOnDefaultChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedChargesOnDefaultChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedChargesOnDefaultChargesQuery({
 *   variables: {
 *      applicability: // value for 'applicability'
 *   },
 * });
 */
export function useAssignedChargesOnDefaultChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignedChargesOnDefaultChargesQueryResult, AssignedChargesOnDefaultChargesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AssignedChargesOnDefaultChargesQueryResult, AssignedChargesOnDefaultChargesQueryVariables>(
    AssignedChargesOnDefaultChargesDocument,
    options
  );
}
export function useAssignedChargesOnDefaultChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignedChargesOnDefaultChargesQueryResult,
    AssignedChargesOnDefaultChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AssignedChargesOnDefaultChargesQueryResult, AssignedChargesOnDefaultChargesQueryVariables>(
    AssignedChargesOnDefaultChargesDocument,
    options
  );
}
export type AssignedChargesOnDefaultChargesQueryHookResult = ReturnType<typeof useAssignedChargesOnDefaultChargesQuery>;
export type AssignedChargesOnDefaultChargesLazyQueryHookResult = ReturnType<typeof useAssignedChargesOnDefaultChargesLazyQuery>;
