import type { FC, ReactElement } from 'react';

import type { QuoteOptionTotal } from '@zen/Quotes/types';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

interface Props {
  accrualId: string;
  accrualLink: string;
  quoteOptionTotal: QuoteOptionTotal;
}

const QuoteOptionAccrual: FC<Props> = (props: Props) => {
  const { accrualId, accrualLink, quoteOptionTotal } = props;
  const shortenAccrualId: string = accrualId.substring(0, 3);
  const { navigate } = useNavigationHistory();

  const prepareAccrualText = (): ReactElement => {
    const linkText: string = `${quoteOptionTotal.currency}${quoteOptionTotal.value} = #${shortenAccrualId}`;
    const handleClick = (): void => navigate(accrualLink);

    return (
      <a data-testid="quote-option-link" onClick={handleClick}>
        {linkText}
      </a>
    );
  };

  return quoteOptionTotal ? prepareAccrualText() : null;
};

export default QuoteOptionAccrual;
