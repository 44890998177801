import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { Role } from './role';

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
const RoleContext = createContext<Role>(undefined);

export const useRoleContext = (): Role => {
  const role: Role = useContextOrThrowError<Role>(RoleContext, 'useRoleContext must be used inside RoleProvider');

  return role;
};

export default RoleContext;
