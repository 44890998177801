export const DOWNLOAD_ROUTE_PREFIX = '/download';

interface DownloadRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const downloadRoutes: DownloadRoutes = {
  index: {
    path: `${DOWNLOAD_ROUTE_PREFIX}/*`,
    relativePath: '*',
    getUrl: () => DOWNLOAD_ROUTE_PREFIX
  }
};

export default downloadRoutes;
