import type { FC, ReactNode } from 'react';

import { createShippingLocationValidation } from '@zen/Accounts/AccountForms/CreateAccountForm/createAccountForm.validation';
import { initialFormValues } from '@zen/Accounts/AccountForms/helpers';
import BusinessHoursFieldsTable from '@zen/Accounts/components/forms/BusinessHoursFieldsTable';
import BusinessHoursRadioGroup from '@zen/Accounts/components/forms/BusinessHoursRadioGroup';
import ShippingLocationFields from '@zen/Accounts/components/forms/ShippingLocationFields';
import { Form, FormButtons, FormInstance } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import type { CreateAccountLocationInput } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import CompanyNameFields from '../../components/CompanyNameFields';
import { LocationLink } from '../../components/CompanyNameFields/helpers';
import { useCreateAccountLocationMutation } from '../../graphql';
import { prepareValues } from './helpers';
import type { CreateAccountLocationFormInitialValues, CreateAccountLocationFormValues } from './types';

interface Props {
  accountId: string;
  accountName: string;
  isOpen: boolean;
  onClose: () => void;
}

const AddLocationModal: FC<Props> = ({ accountId, accountName, isOpen, onClose }) => {
  const [createLocation] = useCreateAccountLocationMutation({ refetchQueries: ['getAccountLocations'] });
  const { addError, addSuccess } = useNotification();

  const renderFormButtons = ({ isSubmitting }: FormInstance<CreateAccountLocationFormValues>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Create location">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const handleSubmit = async (values: CreateAccountLocationFormValues): Promise<IOkOrErrorResult> => {
    const preparedValues: CreateAccountLocationInput = prepareValues(accountId, accountName, values);

    return performMutation({
      mutationFn: () =>
        createLocation({
          variables: {
            input: preparedValues
          }
        }),
      onError: () => addError('There was a problem creating the location.'),
      onSuccess: () => addSuccess('Location was successfully created.')
    });
  };

  const initialValues: CreateAccountLocationFormInitialValues = {
    ...initialFormValues.shippingLocation,
    shippingLocationAddress: initialFormValues.shippingLocationAddress,
    companyName: '',
    businessHours: initialFormValues.businessHours,
    linkType: LocationLink.MY_COMPANY,
    usageContext: null
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Create location">
      <Form
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onClose}
        validationSchema={createShippingLocationValidation}
      >
        {({ values }: FormInstance<CreateAccountLocationFormValues>) => {
          const { businessHoursOption, shippingLocationAddress } = values;
          const hasCustomBusinessHours: boolean = businessHoursOption === 'custom';

          return (
            <>
              <CompanyNameFields />
              <ShippingLocationFields address={shippingLocationAddress} />
              <BusinessHoursRadioGroup />
              {hasCustomBusinessHours && <BusinessHoursFieldsTable />}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default AddLocationModal;
