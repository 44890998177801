export type NetworksRouteType = 'customer' | 'zencargo';

export interface NetworksRoutes {
  contact: {
    details: {
      getUrl: (organisationId: string, contactId: string) => string;
      path: string;
      relativePath: string;
    };
    edit: {
      getUrl: (organisationId: string, contactId: string) => string;
      path: string;
      relativePath: string;
    };
    new: {
      getUrl: (organisationId: string) => string;
      path: string;
      relativePath: string;
    };
  };
  location: {
    details: {
      getUrl: (organisationId: string, locationId: string) => string;
      path: string;
      relativePath: string;
    };
    edit: {
      getUrl: (organisationId: string, locationId: string) => string;
      path: string;
      relativePath: string;
    };
    new: {
      getUrl: (organisationId: string) => string;
      path: string;
      relativePath: string;
    };
  };
  myOrganisation: {
    allOrganisations: {
      getUrl: () => string;
      path: string;
      relativePath: string;
    };
    details: {
      getUrl: () => string;
      path: string;
      relativePath: string;
    };
    edit: {
      getUrl: () => string;
      path: string;
      relativePath: string;
    };
    index: {
      getUrl: () => string;
      path: string;
      relativePath: string;
    };
  };
  organisation: {
    details: {
      getUrl: (organisationId: string) => string;
      path: string;
      relativePath: string;
    };
    edit: {
      getUrl: (organisationId: string) => string;
      path: string;
      relativePath: string;
    };
    new: {
      getUrl: () => string;
      path: string;
      relativePath: string;
    };
  };
}

const networks = (type: NetworksRouteType = 'customer'): NetworksRoutes => {
  const networksRelativePath = type === 'customer' ? 'networks' : 'zencargo-network';
  const networksRootPath = `/dashboard/${networksRelativePath}`;

  return {
    contact: {
      details: {
        path: `${networksRootPath}/organisations/:organisationId/contacts/:contactId`,
        relativePath: 'organisations/:organisationId/contacts/:contactId',
        getUrl: (organisationId: string, contactId: string) =>
          `${networksRootPath}/organisations/${organisationId}/contacts/${contactId}`
      },
      edit: {
        path: `${networksRootPath}/organisations/:organisationId/contacts/:contactId/edit`,
        relativePath: 'organisations/:organisationId/contacts/:contactId/edit',
        getUrl: (organisationId: string, contactId: string) =>
          `${networksRootPath}/organisations/${organisationId}/contacts/${contactId}/edit`
      },
      new: {
        path: `${networksRootPath}/organisations/:organisationId/contacts/new`,
        relativePath: 'organisations/:organisationId/contacts/new',
        getUrl: (organisationId: string) => `${networksRootPath}/organisations/${organisationId}/contacts/new`
      }
    },
    location: {
      details: {
        path: `${networksRootPath}/organisations/:organisationId/locations/:locationId`,
        relativePath: 'organisations/:organisationId/locations/:locationId',
        getUrl: (organisationId: string, locationId: string) =>
          `${networksRootPath}/organisations/${organisationId}/locations/${locationId}`
      },
      edit: {
        path: `${networksRootPath}/organisations/:organisationId/locations/:locationId/edit`,
        relativePath: 'organisations/:organisationId/locations/:locationId/edit',
        getUrl: (organisationId: string, locationId: string) =>
          `${networksRootPath}/organisations/${organisationId}/locations/${locationId}/edit`
      },
      new: {
        path: `${networksRootPath}/organisations/:organisationId/locations/new`,
        relativePath: 'organisations/:organisationId/locations/new',
        getUrl: (organisationId: string) => `${networksRootPath}/organisations/${organisationId}/locations/new`
      }
    },
    myOrganisation: {
      allOrganisations: {
        path: `${networksRootPath}/all-organisations`,
        relativePath: 'all-organisations',
        getUrl: () => `${networksRootPath}/all-organisations`
      },
      details: {
        path: `${networksRootPath}/my-organisation`,
        relativePath: 'my-organisation',
        getUrl: () => `${networksRootPath}/my-organisation`
      },
      edit: {
        path: `${networksRootPath}/edit`,
        relativePath: 'edit',
        getUrl: () => `${networksRootPath}/edit`
      },
      index: {
        path: `${networksRootPath}/*`,
        relativePath: `${networksRelativePath}/*`,
        getUrl: () => networksRootPath
      }
    },
    organisation: {
      details: {
        path: `${networksRootPath}/organisations/:organisationId`,
        relativePath: 'organisations/:organisationId',
        getUrl: (organisationId: string) => `${networksRootPath}/organisations/${organisationId}`
      },
      edit: {
        path: `${networksRootPath}/organisations/:organisationId/edit`,
        relativePath: 'organisations/:organisationId/edit',
        getUrl: (organisationId: string) => `${networksRootPath}/organisations/${organisationId}/edit`
      },
      new: {
        path: `${networksRootPath}/organisations/new`,
        relativePath: 'organisations/new',
        getUrl: () => `${networksRootPath}/organisations/new`
      }
    }
  };
};

export default networks;
