import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor, ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import type { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

export enum ExporterType {
  Collector = 'collector',
  Console = 'console'
}

const buildTracesUrl = (collectorUrl: string): string => {
  const separator: string = collectorUrl.endsWith('/') ? '' : '/';

  return `${collectorUrl}${separator}v1/traces`;
};

export const setupExporters = ({
  provider,
  exporters,
  collectorUrl
}: {
  collectorUrl?: string;
  exporters: ExporterType[];
  provider: WebTracerProvider;
}) => {
  exporters.forEach((exporterType: ExporterType) => {
    switch (exporterType) {
      case ExporterType.Console:
        const consoleExporter = new ConsoleSpanExporter();

        provider.addSpanProcessor(new SimpleSpanProcessor(consoleExporter));

        break;

      case ExporterType.Collector:
        if (!collectorUrl) {
          throw new Error(`Unable to instantiate OTLPTraceExporter. Missing 'collectorUrl: '${collectorUrl}'`);
        }
        const collectorTraceExporter = new OTLPTraceExporter({
          url: buildTracesUrl(collectorUrl)
        });

        provider.addSpanProcessor(
          new BatchSpanProcessor(collectorTraceExporter, {
            scheduledDelayMillis: 1000
          })
        );
        break;

      default:
        throw new Error(`unknown exporter type: '${exporterType}'`);
    }
  });
};
