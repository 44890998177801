import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAllTaskNamesQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetAllTaskNamesQueryResult = { __typename?: 'Query' } & Pick<GraphQLTypes.Query, 'uniqueTaskNames'>;

export const GetAllTaskNamesDocument = /* #__PURE__ */ gql`
  query getAllTaskNames {
    uniqueTaskNames
  }
`;

/**
 * __useGetAllTaskNamesQuery__
 *
 * To run a query within a React component, call `useGetAllTaskNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTaskNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTaskNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllTaskNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllTaskNamesQueryResult, GetAllTaskNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAllTaskNamesQueryResult, GetAllTaskNamesQueryVariables>(GetAllTaskNamesDocument, options);
}
export function useGetAllTaskNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllTaskNamesQueryResult, GetAllTaskNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAllTaskNamesQueryResult, GetAllTaskNamesQueryVariables>(GetAllTaskNamesDocument, options);
}
export type GetAllTaskNamesQueryHookResult = ReturnType<typeof useGetAllTaskNamesQuery>;
export type GetAllTaskNamesLazyQueryHookResult = ReturnType<typeof useGetAllTaskNamesLazyQuery>;
