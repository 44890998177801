import cx from 'classnames';
import type { ReactNode } from 'react';

import { Headline, HeadlineLevel } from '@zen/DesignSystem';

interface FormHeadlineProps {
  className?: string;
  isCompact?: boolean;
  level?: HeadlineLevel;
  tagline?: string | ReactNode;
  text: string;
}

const FormHeadline = ({ className = 'text-grey-dark', isCompact, level = 2, tagline, text }: FormHeadlineProps) => {
  const classNames: string = cx({ 'mb-4': !isCompact }, className);

  return (
    <div className={classNames}>
      <Headline data-testid="form-headline-text" level={level}>
        {text}
      </Headline>
      {tagline && (
        <div className="mt-1 leading-snug" data-testid="form-headline-tagline">
          {tagline}
        </div>
      )}
    </div>
  );
};

export default FormHeadline;
