import type { FC } from 'react';

import { TextLink } from '@zen/DesignSystem';

const TermsAndConditionsLink: FC = () => {
  return (
    <TextLink isExternal={true} linkTo="https://www.zencargo.com/rate-cards-terms-and-conditions">
      Terms and conditions
    </TextLink>
  );
};

export default TermsAndConditionsLink;
