import { gql } from '@apollo/client';

import type * as GraphQLTypes from '../types.generated';

export type PageInfoFragment = { __typename: 'PageInfo' } & Pick<
  GraphQLTypes.PageInfo,
  'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
>;

export const PageInfoFragmentDoc = /* #__PURE__ */ gql`
  fragment PageInfo on PageInfo {
    __typename
    hasNextPage
    hasPreviousPage
    startCursor
    endCursor
  }
`;
