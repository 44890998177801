import cx from 'classnames';
import type { FC, ReactElement, ReactNode, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import type { IconType, LocationDescriptor } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import { extractToAndState } from '@zen/utils/routing';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import { Headline } from '../Typography';

interface Props {
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
  footer?: ReactNode;
  leftIcon?: IconType;
  linkTo?: LocationDescriptor;
  onClick?: () => void;
  rightIcon?: IconType;
  title?: ReactNode;
  variant?: 'compactView' | 'formView' | 'nestedView';
}

export const displayName = 'Design System/Card';

const Card: FC<Props> = (props) => {
  const {
    actions,
    children,
    className,
    footer,
    linkTo,
    onClick,
    rightIcon,
    title,
    leftIcon,
    variant = 'nestedView',
    ...rest
  } = props;
  const { trackEvent } = useTracking();

  const contentCorners: string = footer ? 'rounded-t' : 'rounded';
  const variantSpacing: Record<string, boolean> = {
    'p-4': variant === 'nestedView',
    'p-6': variant === 'formView'
  };
  const classNames: string = cx(
    {
      'hover:border-azure-base active:bg-azure-lighter cursor-pointer': onClick || linkTo,
      ...variantSpacing
    },
    'flex justify-between min-w-0 space-x-4 border border-grey-lighter border-solid transition-all duration-150 ease-linear',
    contentCorners,
    className
  );

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);
    onClick?.();
  };

  const renderCardIcon = (icon: IconType): ReactNode => {
    return typeof icon === 'string' ? <Icon className="text-2xl text-grey-base" icon={icon} /> : <span>{icon}</span>;
  };

  const renderContent = (): ReactNode => {
    return (
      <>
        {leftIcon && renderCardIcon(leftIcon)}
        <div className="flex flex-wrap flex-1 min-w-0 space-x-4">
          <div className="flex-1 min-w-0 text-sm leading-5 text-grey-dark">
            {title && (
              <Headline className="min-w-0 mb-1 truncate" data-testid="card-title" level={4} {...createTrackingLabelAttribute()}>
                {title}
              </Headline>
            )}
            {children}
          </div>
          {actions}
        </div>
        {rightIcon && renderCardIcon(rightIcon)}
      </>
    );
  };

  const footerClassNames: string = cx(
    variantSpacing,
    'min-w-0 space-x-4 border-b border-solid rounded-b border-x border-grey-lighter'
  );

  const renderFooter = (): ReactNode => {
    return <div className={footerClassNames}>{footer}</div>;
  };

  const renderLink = (url: LocationDescriptor): ReactElement => {
    return (
      <Link
        className={classNames}
        onClick={handleClick}
        {...extractToAndState(url)}
        {...createTrackingParentAttribute('card')}
        {...rest}
      >
        {renderContent()}
      </Link>
    );
  };

  const renderWrapper = (): ReactElement => {
    return (
      <>
        <div
          className={classNames}
          data-testid="card-wrapper"
          onClick={handleClick}
          {...createTrackingParentAttribute('card')}
          {...rest}
        >
          {renderContent()}
        </div>
        {footer && renderFooter()}
      </>
    );
  };

  return linkTo ? renderLink(linkTo) : renderWrapper();
};

export type { Props as CardProps };

export default Card;
