import * as Yup from 'yup';

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phoneNumber: Yup.string().required('Phone number is required'),
  email: Yup.string().email('Please enter valid email address').required('Email is required'),
  contactLocationAssignments: Yup.array().of(
    Yup.object().shape({
      locationId: Yup.string().required('Location is required.')
    })
  )
});
