import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackedShipmentFilterOptionsQueryVariables = GraphQLTypes.Exact<{
  customerId: GraphQLTypes.Scalars['String'];
}>;

export type RoadTrackedShipmentFilterOptionsQueryResult = { __typename?: 'Query' } & {
  roadTrackedShipmentFilterOptions: { __typename?: 'RoadTrackedShipmentFilterOptions' } & Pick<
    GraphQLTypes.RoadTrackedShipmentFilterOptions,
    'firstStopCountryCodes' | 'lastStopCountryCodes'
  >;
};

export const RoadTrackedShipmentFilterOptionsDocument = /* #__PURE__ */ gql`
  query roadTrackedShipmentFilterOptions($customerId: String!) {
    roadTrackedShipmentFilterOptions(customerId: $customerId) {
      firstStopCountryCodes
      lastStopCountryCodes
    }
  }
`;

/**
 * __useRoadTrackedShipmentFilterOptionsQuery__
 *
 * To run a query within a React component, call `useRoadTrackedShipmentFilterOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackedShipmentFilterOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackedShipmentFilterOptionsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useRoadTrackedShipmentFilterOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RoadTrackedShipmentFilterOptionsQueryResult,
    RoadTrackedShipmentFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackedShipmentFilterOptionsQueryResult, RoadTrackedShipmentFilterOptionsQueryVariables>(
    RoadTrackedShipmentFilterOptionsDocument,
    options
  );
}
export function useRoadTrackedShipmentFilterOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RoadTrackedShipmentFilterOptionsQueryResult,
    RoadTrackedShipmentFilterOptionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackedShipmentFilterOptionsQueryResult, RoadTrackedShipmentFilterOptionsQueryVariables>(
    RoadTrackedShipmentFilterOptionsDocument,
    options
  );
}
export type RoadTrackedShipmentFilterOptionsQueryHookResult = ReturnType<typeof useRoadTrackedShipmentFilterOptionsQuery>;
export type RoadTrackedShipmentFilterOptionsLazyQueryHookResult = ReturnType<typeof useRoadTrackedShipmentFilterOptionsLazyQuery>;
