import cx from 'classnames';
import type { FC } from 'react';

export type LoadingColor = 'blue' | 'grey' | 'red' | 'white';

export interface Props {
  className?: string;
  color?: LoadingColor;
  size?: 'small' | 'medium' | 'large' | 'smallest';
  withMargin?: boolean;
}

const Loading: FC<Props> = ({ className, withMargin = true, size = 'medium', color = 'blue' }) => {
  const loaderStyle = cx(
    'mx-auto text-sm animate-spin transform-gpu border-solid',
    'indent-offscreen overflow-hidden relative rounded-full',
    {
      'border-l-transparent border-white': color === 'white',
      'border-l-transparent border-grey-base': color === 'grey',
      'border-l-transparent border-red-dark': color === 'red',
      'border-l-azure-base border-azure-lighter': color === 'blue'
    },
    {
      'w-32 h-32 border-[1.1em]': size === 'large',
      'w-16 h-16 border-[0.66em]': size === 'medium',
      'w-8 h-8 border-[0.33em]': size === 'small',
      'w-4 h-4 border-[0.12em]': size === 'smallest'
    },
    {
      'my-20': withMargin,
      'my-0': !withMargin
    },
    className
  );

  return (
    <div className="overflow-hidden">
      <div className={loaderStyle} data-component="loading" data-testid="loading">
        Loading...
      </div>
    </div>
  );
};

export default Loading;
