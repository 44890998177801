import type { FC } from 'react';

import { FormTimeInput as BaseTimeInput, useForm } from '@zen/Components';
import type { BusinessHours } from '@zen/graphql/types.generated';

import type { DayOfWeek } from '../../../AccountForms/types';

interface Props {
  day: DayOfWeek;
  timeType: 'start' | 'end';
}

const TimeInput: FC<Props> = ({ day, timeType }) => {
  const { values } = useForm<{ businessHours: BusinessHours }>();

  const hasOpenTimes: boolean = !!values.businessHours[day];

  if (!hasOpenTimes) {
    return <div className="ml-3 leading-10">-</div>;
  }

  return (
    <BaseTimeInput
      aria-label={`${timeType} time`}
      hideLabel={true}
      inline={true}
      name={`businessHours.${day}.${timeType}Time`}
      noMargin={true}
    />
  );
};

export default TimeInput;
export type { Props as TimeInputProps };
