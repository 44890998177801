import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { BusinessHours, NetworksLabelType } from '@zen/Networks';
import type { Nullable } from '@zen/utils/typescript';

import {
  type JourneyShippingMilestone,
  JourneyShippingMilestoneNameEnum,
  type JourneyStopActionEnum,
  type JourneyStopLocationTypeEnum
} from '../../types';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import JourneyStopLocation from '../JourneyStopLocation';
import Milestone from '../Milestone';

interface Props {
  canUpdateLocation: boolean;
  isStopVisible: boolean;
  locationBusinessHours: BusinessHours;
  locationId: string;
  locationName: Nullable<NetworksLabelType>;
  locationType: JourneyStopLocationTypeEnum;
  milestones: JourneyShippingMilestone[];
  stopAction: JourneyStopActionEnum;
  stopId: string;
  timeZone?: Nullable<string>;
}

const JourneyStop: FC<Props> = (props) => {
  const {
    canUpdateLocation,
    isStopVisible,
    locationId,
    locationBusinessHours,
    locationName,
    locationType,
    milestones,
    stopAction,
    stopId,
    timeZone
  } = props;
  const { isFirstMilestone, isLastMilestone, preLegsShippingMilestones, stops } = useJourneyContext();

  const gateOutEmptyCompleted: boolean = !!preLegsShippingMilestones.find(
    ({ name }) => name === JourneyShippingMilestoneNameEnum.GATE_OUT_EMPTY
  )?.completed;
  const isFirstStop: boolean = stops[0]?.id === stopId;
  const isFirstMilestoneCompleted: boolean = milestones[0]?.completed || (isFirstStop && gateOutEmptyCompleted);

  const classNames: string = cx(
    {
      'border-grey-lighter': isStopVisible,
      'border-white': !isStopVisible
    },
    'border-t border-solid transition-all duration-200'
  );

  const renderMilestone = (milestone: JourneyShippingMilestone, index: number): ReactNode => {
    const { completed, id, name } = milestone;

    const isPreviousMilestoneCompleted: boolean = index
      ? milestones[index - 1]?.completed
      : isFirstMilestone(id) && gateOutEmptyCompleted;
    const isLastMilestoneCompleted: boolean = completed && isLastMilestone(id);
    const isLastMilestoneForStop: boolean = index === milestones.length - 1;
    const isCurrent: boolean = isLastMilestoneCompleted || (isPreviousMilestoneCompleted && !completed);

    return (
      <div key={name}>
        <Milestone
          isCurrent={isCurrent}
          isLast={isLastMilestoneForStop}
          locationBusinessHours={locationBusinessHours}
          locationType={locationType}
          milestone={milestone}
          stopAction={stopAction}
          timeZone={timeZone}
        />
      </div>
    );
  };

  return (
    <div className={classNames}>
      <JourneyStopLocation
        canUpdateLocation={canUpdateLocation}
        isFirstMilestoneCompleted={isFirstMilestoneCompleted}
        isStopVisible={isStopVisible}
        locationId={locationId}
        locationName={locationName}
        locationType={locationType}
        stopAction={stopAction}
        stopId={stopId}
      />
      <div data-testid="journey-stop-milestones">{milestones.map(renderMilestone)}</div>
    </div>
  );
};

export default JourneyStop;
