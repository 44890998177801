import type { RadioGroupOption } from '@zen/Components/RadioGroup';

import { ConfidenceLevel, DateFormType } from './types';

export const confidenceOptions: RadioGroupOption[] = [
  { label: 'Estimate date', value: ConfidenceLevel.ESTIMATED },
  { label: 'Actual date', value: ConfidenceLevel.ACTUAL }
];

export const dateFormInitialValues: DateFormType = {
  confidence: ConfidenceLevel.ESTIMATED,
  reason: '',
  date: '',
  description: ''
};
