import type { FC } from 'react';

import { useCompleteWorkflowTaskMutation } from '@zen/Tasks/graphql';
import { TaskListAction, TaskListTrackingAction, TaskListTrackingCategory } from '@zen/Tasks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import ContextAction from '../../ContextAction';

interface Props extends TaskListAction {}

const MarkCompletedAction: FC<Props> = (props) => {
  const { taskId, isDisabled, onActionEnd, onActionStart, onActionSuccess, trackingProps } = props;
  const { taskUrgency, taskDescription, zencargoReference = '' } = trackingProps;
  const { addSuccess, addError } = useNotification();
  const refetchQueries: string[] = ['getTaskListCounters'];

  const [markTaskAsCompleted] = useCompleteWorkflowTaskMutation({ refetchQueries });
  const { trackEvent } = useTracking();

  const trackTaskCompletion = (): void => {
    trackEvent({
      category: TaskListTrackingCategory,
      action: TaskListTrackingAction.TASK_MARKED_AS_COMPLETED,
      label: `${zencargoReference}|${taskDescription}|${taskUrgency}`,
      properties: {
        taskUrgency,
        zencargoReference,
        taskDescription
      }
    });
  };

  const handleMarkTaskAsCompletedAction = async (): Promise<IOkOrErrorResult> => {
    onActionStart();
    trackTaskCompletion();

    return performMutation({
      mutationFn: () => markTaskAsCompleted({ variables: { input: { taskId } } }),
      onError: () => {
        addError('Task could not be marked as completed.', {
          label: 'Try again',
          onClick: handleMarkTaskAsCompletedAction
        });
        onActionEnd();
      },
      onSuccess: async () => {
        if (onActionSuccess) {
          await onActionSuccess();
        }
        addSuccess('Task completed.');
        onActionEnd();
      }
    });
  };

  return (
    <ContextAction
      icon="zicon-tickoval"
      iconActiveColor="green"
      isDisabled={isDisabled}
      onClick={handleMarkTaskAsCompletedAction}
      tooltipContent="Mark as complete"
    />
  );
};

export default MarkCompletedAction;
