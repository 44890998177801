import { get, mapValues, mergeWith, omit } from 'lodash';

import type {
  BusinessHours as BusinessHoursType,
  BusinessHoursInput,
  GeolocationInput,
  LocationInput,
  TimeRange
} from '@zen/graphql/types.generated';
import { formatTime } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';
import { deepMergeNilValues } from '@zen/utils/utils';

import { apolloClient } from '../graphql/GraphQLProvider';
import type { NetworksLocationData } from '.';
import { createBusinessHoursInput } from './businessHours.mock';
import { OrganisationsQueryDocument } from './graphql';
import type { BusinessHours, LocationData, NetworksAssignableInterface, NetworksOrgLocWithName } from './types';
import { NetworksOrgLoc, Organisation, OrgLoc, OrgLocType } from './types';

const handleOrganisationInputLoad =
  (accountUuid: string) =>
  async (query: string = ''): Promise<Organisation[]> => {
    const QUERY = {
      query: OrganisationsQueryDocument,
      variables: {
        accountUuid,
        ids: null,
        nameContains: query
      }
    };
    const response = await apolloClient.query(QUERY);

    return response.data.network.organisations.nodes;
  };

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'arg' implicitly has an 'any' type.
const isOrgLocType = (arg): arg is NetworksOrgLoc => {
  return arg.typename === OrgLocType.NETWORKS || (arg.id && arg.typename === undefined);
};

const getOrgLocId = (orgLoc: OrgLoc): Optional<string> => {
  return orgLoc && isOrgLocType(orgLoc) ? orgLoc.id : null;
};

export const formatTimeRange = ({ startTime, endTime }: TimeRange, withSeconds = true): TimeRange => {
  return {
    startTime: formatTime(startTime, withSeconds) || '',
    endTime: formatTime(endTime, withSeconds) || ''
  };
};

export const hasTimeRange = (timeRange: TimeRange): boolean => !!(timeRange.startTime && timeRange.endTime);

const prepareBusinessHoursInput = (businessHours: BusinessHours): BusinessHoursInput => {
  return mapValues(businessHours, (timeRange) => (hasTimeRange(timeRange) ? formatTimeRange(timeRange) : null));
};

export const prepareLocationInput = (values: LocationData): LocationInput => {
  const { name, businessHours, city, countryCode, lat, lng, locationType, postalCode, state, street } = values;

  const geolocation: GeolocationInput = {
    latitude: lat,
    longitude: lng
  };

  return {
    address: {
      city,
      country: {
        code: countryCode
      },
      countyOrState: state,
      postalCodeOrZip: postalCode,
      street
    },
    businessHours: prepareBusinessHoursInput(businessHours),
    geolocation,
    locationType,
    name
  };
};

export const prepareBusinessHoursInitialValues = (businessHours?: Optional<BusinessHoursType>): BusinessHours => {
  const hours: BusinessHours = mergeWith({}, omit(businessHours, '__typename'), createBusinessHoursInput(), deepMergeNilValues);

  return mapValues(hours, (timeRange) => (hasTimeRange(timeRange) ? formatTimeRange(timeRange, false) : timeRange));
};

const getNetworksFieldValueWithName = (value: Optional<NetworksAssignableInterface>): Nullable<NetworksOrgLocWithName> => {
  if (!value) {
    return null;
  }

  return {
    ...value,
    name: value.label?.long
  };
};

const getLocationData = (location: Optional<NetworksAssignableInterface>): Nullable<NetworksLocationData> => {
  if (!location) {
    return null;
  }

  return {
    id: location.id,
    label: location.label,
    businessHours: prepareBusinessHoursInitialValues(location.businessHours),
    timeZone: location.timeZone,
    organisation: get(location, 'organisation'),
    geolocation: get(location, 'location.geolocation')
  };
};

export { getLocationData, getNetworksFieldValueWithName, getOrgLocId, handleOrganisationInputLoad, isOrgLocType };
