import { matchPath, useLocation } from 'react-router-dom';

interface RouterHelpers {
  isMatchingPathname: (pathPattern: string, exact?: boolean) => boolean;
}

export const useRouter = (): RouterHelpers => {
  const { pathname } = useLocation();

  const isMatchingPathname = (pathPattern: string, exact: boolean = false): boolean => {
    return !!matchPath({ path: pathPattern, end: exact }, pathname);
  };

  return {
    isMatchingPathname
  };
};
