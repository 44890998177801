import type { FC, KeyboardEvent } from 'react';
import { useState } from 'react';

import { NumberInput, NumberInputProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { checkIfValueChanged, getInputDisplayValue, getInputSubmitValue } from './helpers';

interface Props extends Omit<NumberInputProps, 'onChange'> {
  fixedDecimalNumber?: number;
  onUpdate: (updatedValue: number) => void;
  placeholder?: string;
  size: 'default' | 'compact';
  value: Nullable<number>;
}

const InlineNumberInput: FC<Props> = ({ fixedDecimalNumber, onUpdate, placeholder, size, value, ...rest }) => {
  const [updatedValue, setValue] = useState<Nullable<number | string>>(getInputDisplayValue(value, fixedDecimalNumber));
  const valueToSubmit: Nullable<number> = getInputSubmitValue(updatedValue);
  const hasValueChanged: boolean = !!updatedValue && !!value && checkIfValueChanged(value, updatedValue);

  const handleBlur = () => {
    if (hasValueChanged && valueToSubmit) {
      onUpdate(valueToSubmit);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLElement>): void => {
    const isEnterKey: boolean = event.key === 'Enter';

    if (isEnterKey && hasValueChanged && valueToSubmit) {
      onUpdate(valueToSubmit);
    }
  };

  const handleChange = (newValue: Nullable<number>) => {
    setValue(newValue);
  };

  return (
    <div className="-mr-3">
      <NumberInput
        data-testid="inline-number-input"
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        size={size}
        value={updatedValue}
        variant="inline"
        {...rest}
      />
    </div>
  );
};

export type { Props as InlineNumberInputProps };

export default InlineNumberInput;
