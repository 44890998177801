import cx from 'classnames';
import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Role, roleLabels } from '@zen/Auth/role';
import RolePermissionStatus from '@zen/Components/RolePermissions/RolePermissionStatus';

import { getVisibleRoles, toPermissionKey } from '../helpers';
import type { RolePermissionMap, RolePermissionMapWithAdmin } from '../types';

interface RolePermissionItem {
  label: string;
  value: boolean;
}

const preparePermissionItems = (
  roles: Role[],
  rolePermissions: RolePermissionMap | RolePermissionMapWithAdmin
): RolePermissionItem[] => {
  return roles.map((role: Role) => ({
    label: roleLabels[role],
    value: rolePermissions[toPermissionKey(role)]
  }));
};

export interface Props {
  className?: string;
  rolePermissions: RolePermissionMap;
  showAdminPermission?: boolean;
}

const RolePermissionList: FC<Props> = ({ rolePermissions, showAdminPermission = true, className }) => {
  const currentRole: Role = useRole();
  const permissions: RolePermissionMap | RolePermissionMapWithAdmin = showAdminPermission
    ? { ...rolePermissions, admin: true } // add display-only admin role permission
    : rolePermissions;
  const roles: Role[] = getVisibleRoles(currentRole, permissions);
  const permissionItems: RolePermissionItem[] = preparePermissionItems(roles, permissions);

  return (
    <div className={cx('flex', className)} data-testid="role-permission-list">
      {permissionItems.map((permissionItem: RolePermissionItem, index) => {
        return <RolePermissionStatus key={index} rolePermission={permissionItem} />;
      })}
    </div>
  );
};

export default RolePermissionList;
