import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';

const CollectionNotHandledInformation: FC = () => {
  const classNames: string =
    'relative z-30 flex items-center py-4 text-sm bg-white border-t border-b border-solid -mt-2 mb-8 border-grey-lighter text-grey-light';

  return (
    <div className={classNames} data-testid="collection-not-handled-information">
      <span className={`flex justify-center ml-48 shrink-0 ${JOURNEY_TIMELINE_WIDTH}`}>
        <Icon icon="zicon-information" />
      </span>
      Collection not handled by Zencargo
    </div>
  );
};

export default CollectionNotHandledInformation;
