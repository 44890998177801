import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Popover, PopoverArguments, Tooltip } from '@zen/DesignSystem';
import type { BusinessHours } from '@zen/Networks';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { JourneyManageDatesReasonEnum, JourneyShippingMilestone, JourneyStopActionEnum } from '../../types';
import CargoReadyDatePicker from '../forms/CargoReadyDatePicker';
import { getPlaceholderText, isCargoReadyDate } from '../MilestoneDates/helpers';
import MilestoneDatesDialog from '../MilestoneDatesDialog';
import { getDatesTooltipLabel } from '../MilestoneDatesDialog/helpers';
import { getClassNames } from './helpers';

interface Props {
  businessHours: BusinessHours;
  date?: Optional<string>;
  milestone: JourneyShippingMilestone;
  stopAction: JourneyStopActionEnum;
  timeZone?: Nullable<string>;
}

const EditableDate: FC<Props> = ({ businessHours, date, milestone, stopAction, timeZone }) => {
  const { dateType, delayInDays, latestEstimate, name } = milestone;

  const hasDate: boolean = !!date;
  const hasDelay: boolean = delayInDays !== 0;
  const canManageDates: boolean = !!milestone.canManageDates;
  const canManageDatesCurrently: boolean = !!milestone?.canManageDatesCurrently?.value;
  const canManageDatesCurrentlyReason: Optional<JourneyManageDatesReasonEnum> = milestone.canManageDatesCurrently?.reason;
  const isDateEditable: boolean = canManageDates && canManageDatesCurrently;
  const className: string = cx({ 'flex-1': isDateEditable || (!hasDate && canManageDatesCurrentlyReason) });

  const handlePopover = ({ close }: PopoverArguments): ReactNode => {
    if (isCargoReadyDate(name)) {
      return <CargoReadyDatePicker date={latestEstimate?.startDateTime.date} onChange={close} />;
    }

    return (
      <MilestoneDatesDialog
        businessHours={businessHours}
        milestone={milestone}
        onClose={close}
        stopAction={stopAction}
        timeZone={timeZone}
      />
    );
  };

  const renderDate = (): ReactNode => {
    if (!date) {
      return getPlaceholderText(milestone);
    }

    return date;
  };

  return (
    <Popover disabled={!isDateEditable} popover={handlePopover} renderInPortal={true} triggerClassName={className}>
      {({ isPopoverVisible }) => {
        const classNames: string = getClassNames({
          dateType,
          hasDate,
          hasDelay,
          canManageDates,
          canManageDatesCurrently,
          isPopoverVisible
        });
        const dateElement: ReactNode = <div className={classNames}>{renderDate()}</div>;

        if (canManageDates && canManageDatesCurrentlyReason) {
          return (
            <Tooltip
              tooltipContent={<div className="max-w-56">{getDatesTooltipLabel(canManageDatesCurrentlyReason, stopAction)}</div>}
              triggerClassName="w-full flex-1"
            >
              {dateElement}
            </Tooltip>
          );
        }

        return dateElement;
      }}
    </Popover>
  );
};

export default EditableDate;
