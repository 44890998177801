import cx from 'classnames';
import { differenceBy } from 'lodash';
import { FC, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';

import {
  addDocuments,
  addDocumentToQueue,
  initialState,
  reducer,
  removeDocumentsFromQueue,
  updateDocumentInQueue
} from '@zen/Components/Documents/document.reducer';
import DocumentDropZone from '@zen/Components/Documents/DocumentDropZone';
import DocumentUploader from '@zen/Components/Documents/DocumentUploader';
import { getDefaultDocumentPermissions, getDocumentTypeOptions } from '@zen/Components/Documents/helpers';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button, ContextMenu, MenuItemType, Modal, Option } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { useGetAllBookingDocumentTypesQuery } from '../graphql';
import type { Document, DocumentType, QueuedDocument } from '../types';

interface Props {
  componentType?: 'context-menu' | 'button';
  createDocumentsLink?: string;
  documentType?: string;
  documents?: Document[];
  onAdd: (documents: Document[]) => void;
  showDocumentCreation?: boolean;
}

const DocumentActions: FC<Props> = (props) => {
  const {
    documents: existingDocuments,
    documentType,
    createDocumentsLink,
    showDocumentCreation,
    onAdd,
    componentType = 'context-menu'
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    data: documentTypesData,
    loading: documentTypesLoading,
    error: documentTypesError
  } = useGetAllBookingDocumentTypesQuery();
  const { addError } = useNotification();
  const [isUploadMode, setIsUploadMode] = useState<boolean>(false);
  const { navigate } = useNavigationHistory();

  const handleAdd = useCallback(
    ({ documents }, silent = false) => {
      dispatch(addDocuments(documents));
      setIsUploadMode(false);
      if (!silent) {
        onAdd(documents);
      }
    },
    [onAdd]
  );

  useEffect(() => {
    const newDocuments: Document[] = differenceBy(existingDocuments, state.documents, 'uuid');

    if (newDocuments.length > 0) {
      handleAdd({ documents: newDocuments }, true);
    }
  }, [existingDocuments, state.documents, handleAdd]);

  const handleStart = (document: QueuedDocument): void => {
    dispatch(addDocumentToQueue(document));
  };

  const handleProgress = (document: QueuedDocument): void => {
    dispatch(updateDocumentInQueue(document));
  };

  const handleFinish = (document: Document): void => {
    dispatch(
      updateDocumentInQueue({
        ...document,
        documentType,
        permissions: getDefaultDocumentPermissions()
      })
    );
  };

  const handleCancel = (): void => {
    dispatch(removeDocumentsFromQueue());
    setIsUploadMode(false);
  };

  const handleCreateDocument = (): void => {
    if (createDocumentsLink) {
      navigate(createDocumentsLink);
    }
  };

  const openModal = (): void => setIsUploadMode(true);

  const dropdownItems: MenuItemType[] = [
    {
      icon: 'zicon-upload' as IconName,
      label: 'Upload document',
      onClick: openModal
    },
    ...(showDocumentCreation
      ? [
          {
            icon: 'zicon-add' as IconName,
            label: 'Create document from a template',
            onClick: handleCreateDocument
          }
        ]
      : [])
  ];

  const hasDocumentsInQueue: boolean = state.documentsInQueue.length > 0;

  const modalContentClassnames: string = cx('flex justify-center flex-col', {
    'pb-7': hasDocumentsInQueue
  });

  const renderUploadElement = (): ReactNode => {
    if (componentType === 'button') {
      return (
        <div className="flex space-x-3">
          {showDocumentCreation && (
            <Button iconLeft="zicon-add" onClick={handleCreateDocument} variant="ghost">
              Create document from a template
            </Button>
          )}
          <Button iconLeft="zicon-upload" onClick={openModal} variant="secondary">
            Upload
          </Button>
        </div>
      );
    }

    return <ContextMenu items={dropdownItems} />;
  };

  return (
    <>
      {renderUploadElement()}

      <QueryHandler
        data={documentTypesData?.bookingDocumentTypes?.nodes as DocumentType[]}
        error={!!documentTypesError}
        isLoading={documentTypesLoading}
        loadingComponent={null}
      >
        {(bookingDocumentTypes: DocumentType[]) => {
          const documentTypes: Option<string>[] = getDocumentTypeOptions(bookingDocumentTypes);

          return (
            <Modal
              className="mt-4"
              closeOnClickAway={false}
              isOpen={isUploadMode}
              modalOverflowY="auto"
              onClose={handleCancel}
              title="Upload documents"
            >
              <div className={modalContentClassnames}>
                <DocumentDropZone
                  className="h-full"
                  dropZoneClassName={cx({ 'h-full': !hasDocumentsInQueue })}
                  onError={addError}
                  onFinish={handleFinish}
                  onProgress={handleProgress}
                  onStart={handleStart}
                />
                <DocumentUploader
                  documents={state.documentsInQueue}
                  documentTypes={documentTypes}
                  onCancel={handleCancel}
                  onSubmit={handleAdd}
                />
              </div>
            </Modal>
          );
        }}
      </QueryHandler>
    </>
  );
};

export default DocumentActions;
