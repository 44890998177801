import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import type { ContextType } from './types';

const initialContext: ContextType = {
  accountId: '',
  costTrackingId: '',
  modeOfTransport: null,
  zencargoReference: ''
};

const CostTrackingContext = createContext<ContextType>(initialContext);

const useCostTrackingContext = (): ContextType => {
  return useContextOrThrowError<ContextType>(
    CostTrackingContext,
    'useCostTrackingContext must be used within CostTrackingContext'
  );
};

export { CostTrackingContext, useCostTrackingContext };
