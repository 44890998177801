import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { TruckType, VehicleTypeEnum } from '../../types';

export const ftlConfiguration = {
  [TruckType.BOXED]: [
    VehicleTypeEnum.VAN_BOX,
    VehicleTypeEnum.TRAILER_3_5_T_BOX,
    VehicleTypeEnum.TRAILER_7_5_T_BOX,
    VehicleTypeEnum.STRAIGHT_TRUCK,
    VehicleTypeEnum.TRAILER_18_T_BOX,
    VehicleTypeEnum.TRAILER_13_6_M_BOX,
    VehicleTypeEnum.TRAILER_53_FT_BOXED,
    VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER
  ],
  [TruckType.CURTAIN_SIDED]: [
    VehicleTypeEnum.TRAILER_3_5_T_CURTAIN_SIDED,
    VehicleTypeEnum.TRAILER_7_5_T_CURTAIN_SIDED,
    VehicleTypeEnum.TRAILER_18_T_CURTAIN_SIDED,
    VehicleTypeEnum.TRAILER_13_6_M_CURTAIN_SIDED,
    VehicleTypeEnum.TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED
  ],
  [TruckType.REFRIGERATED_TRUCK]: [VehicleTypeEnum.TRAILER_18_T_REFRIGERATED, VehicleTypeEnum.TRAILER_13_6_M_REFRIGERATED],
  [TruckType.SLIDING_ROOF]: [VehicleTypeEnum.TRAILER_18_T_SLIDING_ROOF, VehicleTypeEnum.TRAILER_13_6_M_SLIDING_ROOF],
  [TruckType.HIAB]: [VehicleTypeEnum.TRAILER_18_T_HIAB, VehicleTypeEnum.TRAILER_13_6_M_HIAB]
};

export const getFullTruckOptions = (truckVariant: Nullable<TruckType>): Option<VehicleTypeEnum>[] => {
  if (!truckVariant) {
    return [];
  }

  return ftlConfiguration[truckVariant].map((item: VehicleTypeEnum) => ({
    label: cargoTypeLabelMapping[item],
    value: item
  }));
};
