import cx from 'classnames';
import { get } from 'lodash';
import { FC, useContext, useState } from 'react';

import NavigationPrompt from '@zen/Components/NavigationPrompt';
import PageSizeSelector from '@zen/Components/PageSizeSelector';
import { Button, Headline } from '@zen/DesignSystem';
import CargoReadyDateSlideout from '@zen/Orders/CargoReadyDateSlideout';
import { usePurchaseOrdersUpdateLotsMutation } from '@zen/Orders/graphql';
import LotEstimatesSlideout from '@zen/Orders/LotEstimatesSlideout';
import type { OrderDetailsLineItem, OrderLot, UpdateLotsInput } from '@zen/Orders/types';
import { getCargoReadyDate, getLotDeliveryDate } from '@zen/Orders/utils';
import { PageSize } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import UpdateCRDContext from '../updateCRDContext';
import LineItem from './LineItem';

interface Props {
  items: OrderDetailsLineItem[];
  onPageSizeChange: (size: PageSize) => void;
  onUpdateAll: () => void;
  orderDate: Optional<string>;
  pageSize?: PageSize;
}

const LineItems: FC<Props> = ({ items, orderDate, onUpdateAll, onPageSizeChange, pageSize = PageSize.TEN }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { displayMask } = useContext(UpdateCRDContext);
  const [activeLot, setActiveLot] = useState<Nullable<{ item: OrderDetailsLineItem; lot: OrderLot }>>(null);
  const [updateLots] = usePurchaseOrdersUpdateLotsMutation();
  const { addError } = useNotification();

  const renderSlideout = () => {
    const canUpdateLotDeliveryDate = get(activeLot, 'item.canUpdateDeliveryDate.value', false);

    const estimates = {
      cargoReadyDate: activeLot ? getCargoReadyDate(activeLot.lot) : undefined,
      deliveryDate: activeLot ? getLotDeliveryDate(activeLot.lot) : undefined
    };

    const commonSlideoutProps = {
      isVisible: !!activeLot,
      lotIds: [activeLot?.lot.id || ''],
      onClose: () => {
        setActiveLot(null);
      },
      onSuccess: () => {
        setActiveLot(null);
      },
      orderDate
    };

    if (canUpdateLotDeliveryDate) {
      return <LotEstimatesSlideout className="!top-0" estimates={estimates} {...commonSlideoutProps} />;
    }

    return (
      <CargoReadyDateSlideout
        initialValues={{
          date: getCargoReadyDate(activeLot?.lot)
        }}
        {...commonSlideoutProps}
      />
    );
  };

  const renderHeadline = () => {
    const expandButtonText = `${isExpanded ? 'Collapse' : 'Expand'} all`;
    const expandButton = !displayMask ? (
      <span
        className="text-base font-bold cursor-pointer text-navy-dark hover:underline"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {expandButtonText}
      </span>
    ) : null;

    const wrapperClassName = cx('flex justify-between', { 'mt-8': displayMask });

    return (
      <div className={wrapperClassName}>
        <Headline className="mb-6 text-navy-dark" level={3}>
          <>
            Line items - <span className="font-normal text-grey-base">expand to view more information</span>
          </>
        </Headline>
        {expandButton}
      </div>
    );
  };

  const handleFulfimentSubmit = (values: UpdateLotsInput): Promise<IOkOrErrorResult> =>
    performFormMutation({
      mutationFn: () => updateLots({ variables: { input: values } }),
      onError: () => addError()
    });

  return (
    <div className="mb-6">
      <div className="flex justify-end mb-6">
        <Button onClick={onUpdateAll} variant="secondary">
          Update multiple schedules
        </Button>
        <PageSizeSelector onChange={onPageSizeChange} pageSize={pageSize} />
      </div>

      {renderHeadline()}
      {items.length > 0 &&
        items.map((lineItem, i) => (
          <div key={i} className="mb-2 border border-solid rounded border-grey-lighter" data-testid="line-item">
            <div className="flex py-2 text-sm leading-normal rounded bg-grey-lightest text-grey-dark">
              <div className="flex-[0.325]"> </div>
              <div className="flex-[2.5]">SKU details</div>
              <div className="flex-1">Actual vs original CBM</div>
              <div className="flex-1">Fulfilled vs ordered Qty</div>
            </div>
            <LineItem
              isExpanded={isExpanded || displayMask}
              item={lineItem}
              onFulfimentSubmit={handleFulfimentSubmit}
              onUpdateSchedule={(lot) => {
                setActiveLot({
                  lot: lot as OrderLot,
                  item: lineItem
                });
              }}
            />
          </div>
        ))}
      {!!activeLot && renderSlideout()}
      {displayMask && (
        <NavigationPrompt
          header="Navigating away from this page will cancel updating the CRD."
          leaveLabel="I want to leave"
          stayLabel="Return to updating"
        />
      )}
    </div>
  );
};

export default LineItems;
