import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useAssignContractIdMutation, useUnassignContractIdMutation } from '../graphql';

export const useContractIdAssignment = (): [(zencargoReference: string, contractId?: string) => Promise<void>] => {
  const [assignContractId] = useAssignContractIdMutation({ refetchQueries: ['contractId'] });
  const [unassignContractId] = useUnassignContractIdMutation({ refetchQueries: ['contractId'] });
  const { addError, addSuccess } = useNotification();

  const handleAssignmentSuccess = (): void => addSuccess('Contract ID assigned successfully.');
  const handleUnassignmentSuccess = (): void => addSuccess('Contract ID removed successfully.');

  const updateContractId = async (zencargoReference: string, contractId?: string) => {
    if (contractId) {
      await performMutation({
        mutationFn: () => assignContractId({ variables: { input: { zencargoReference, contractId } } }),
        onError: () => addError(),
        onSuccess: handleAssignmentSuccess
      });
    } else {
      await performMutation({
        mutationFn: () => unassignContractId({ variables: { input: { zencargoReference } } }),
        onError: () => addError(),
        onSuccess: handleUnassignmentSuccess
      });
    }
  };

  return [updateContractId];
};
