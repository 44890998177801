import { useQuery } from '@apollo/client';

import type { Optional } from '@zen/utils/typescript';

import { GetCurrentUserDocument } from '../graphql';
import type { CurrentUserResponse } from '../types';

const useCurrentUser = (accountId: Optional<string>) =>
  useQuery<CurrentUserResponse>(GetCurrentUserDocument, {
    skip: accountId === undefined,
    variables: { accountId },
    fetchPolicy: 'no-cache'
  });

export default useCurrentUser;
