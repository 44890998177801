import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type OrganisationsQueryVariables = GraphQLTypes.Exact<{
  accountUuid: GraphQLTypes.Scalars['String'];
  nameContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type OrganisationsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      organisations?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksOrganisationInterfaceConnection' } & {
          nodes?: GraphQLTypes.Maybe<
            Array<
              GraphQLTypes.Maybe<
                | ({ __typename?: 'AgentOrganisation' } & Pick<
                    GraphQLTypes.AgentOrganisation,
                    'type' | 'assignedRoles' | 'id' | 'name'
                  >)
                | ({ __typename?: 'ForwarderOrganisation' } & Pick<
                    GraphQLTypes.ForwarderOrganisation,
                    'type' | 'assignedRoles' | 'id' | 'name'
                  >)
                | ({ __typename?: 'MyOrganisation' } & Pick<
                    GraphQLTypes.MyOrganisation,
                    'type' | 'assignedRoles' | 'id' | 'name'
                  >)
                | ({ __typename?: 'SavedOrganisation' } & Pick<
                    GraphQLTypes.SavedOrganisation,
                    'type' | 'assignedRoles' | 'id' | 'name'
                  >)
              >
            >
          >;
          pageInfo: { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >;
        }
      >;
    }
  >;
};

export const OrganisationsQueryDocument = /* #__PURE__ */ gql`
  query organisationsQuery(
    $accountUuid: String!
    $nameContains: String
    $first: Int
    $last: Int
    $before: String
    $after: String
  ) {
    network(id: $accountUuid) {
      organisations(nameContains: $nameContains, first: $first, last: $last, before: $before, after: $after) {
        nodes {
          type
          assignedRoles
          id
          name
        }
        pageInfo {
          ...PageInfo
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *      accountUuid: // value for 'accountUuid'
 *      nameContains: // value for 'nameContains'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useOrganisationsQuery(
  baseOptions: Apollo.QueryHookOptions<OrganisationsQueryResult, OrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OrganisationsQueryResult, OrganisationsQueryVariables>(OrganisationsQueryDocument, options);
}
export function useOrganisationsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsQueryResult, OrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OrganisationsQueryResult, OrganisationsQueryVariables>(OrganisationsQueryDocument, options);
}
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsQueryLazyQueryHookResult = ReturnType<typeof useOrganisationsQueryLazyQuery>;
