import type { MenuItemType, PillType } from '@zen/DesignSystem';

import { ChargeItemType, type ChargeListItem } from '../../types';
import { chargeItemStatusLabelMapping } from '../ChargeItemStatusChangeDialog';
import { type ChargeItemStatus, CostTrackingCostItemStatus, CostTrackingRevenueItemStatus } from '../types';

type StatusConfig = Record<ChargeItemStatus, StatusConfigItem>;
interface StatusConfigItem {
  addDivider?: boolean;
  disabled: boolean;
  isVisible: boolean;
}

export const isStatusChangeEnabled = (chargeTypeGroup: ChargeItemType, status: ChargeItemStatus, total: number): boolean => {
  switch (status) {
    case CostTrackingCostItemStatus.DRAFT:
      return true;
    case CostTrackingCostItemStatus.APPROVED:
      return total > 0 && chargeTypeGroup === ChargeItemType.COST;
    case CostTrackingCostItemStatus.MATCHED:
    case CostTrackingRevenueItemStatus.INVOICED:
    case CostTrackingCostItemStatus.CREDITED:
      return false;
    default:
      return false;
  }
};

const getStatusConfig = (chargeItem: ChargeListItem, status: ChargeItemStatus): StatusConfig => {
  const { chargeTypeGroup, total } = chargeItem;
  const isCostItem: boolean = chargeTypeGroup === ChargeItemType.COST;
  const isRevenueItem: boolean = chargeTypeGroup === ChargeItemType.REVENUE;
  const shouldShowInvoicedStatus: boolean = !isCostItem && !isRevenueItem;

  return {
    [CostTrackingCostItemStatus.DRAFT]: {
      disabled: status !== CostTrackingCostItemStatus.DRAFT,
      isVisible: true
    },
    [CostTrackingCostItemStatus.APPROVED]: {
      addDivider: !isCostItem,
      disabled: (status !== CostTrackingCostItemStatus.DRAFT && status !== CostTrackingCostItemStatus.APPROVED) || total < 0,
      isVisible: true
    },
    [CostTrackingCostItemStatus.MATCHED]: {
      addDivider: true,
      disabled: status !== CostTrackingCostItemStatus.APPROVED && status !== CostTrackingCostItemStatus.MATCHED,
      isVisible: isCostItem
    },
    [CostTrackingRevenueItemStatus.INVOICED]: {
      addDivider: true,
      disabled: status !== CostTrackingRevenueItemStatus.APPROVED && status !== CostTrackingRevenueItemStatus.INVOICED,
      isVisible: shouldShowInvoicedStatus
    },
    [CostTrackingCostItemStatus.CREDITED]: {
      disabled:
        (status !== CostTrackingCostItemStatus.DRAFT && status !== CostTrackingCostItemStatus.CREDITED && total < 0) || total > 0,
      isVisible: true
    }
  };
};

export const getStatusMenuItems = (
  chargeItem: ChargeListItem,
  status: ChargeItemStatus,
  onClick: (status: ChargeItemStatus) => void
): MenuItemType[] => {
  const statusConfig: StatusConfig = getStatusConfig(chargeItem, status);

  return Object.keys(statusConfig)
    .filter((configKey) => statusConfig[configKey as ChargeItemStatus].isVisible)
    .map((configKey) => {
      const key = configKey as ChargeItemStatus;
      const statusConfigItem: StatusConfigItem = statusConfig[key];

      return {
        addDivider: statusConfigItem.addDivider,
        label: chargeItemStatusLabelMapping[key],
        isActive: status === configKey,
        disabled: statusConfigItem.disabled,
        onClick: () => key !== status && onClick(key)
      };
    });
};

export const statusPillTypeMapping: Record<ChargeItemStatus, PillType> = {
  [CostTrackingCostItemStatus.DRAFT]: 'pending',
  [CostTrackingCostItemStatus.APPROVED]: 'default',
  [CostTrackingCostItemStatus.MATCHED]: 'success',
  [CostTrackingCostItemStatus.CREDITED]: 'problem',
  [CostTrackingRevenueItemStatus.INVOICED]: 'warning'
};
