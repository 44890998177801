import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Button } from '@zen/DesignSystem';
import bookingRequestRoutes from '@zen/Routes/bookingRequests';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useBookingRequestsRevertRejectionAsManufacturerMutation } from './graphql';

interface Props {
  zencargoReference: string;
}

const BookingManufacturerResubmitButton: FC<Props> = ({ zencargoReference }) => {
  const [revertRejection] = useBookingRequestsRevertRejectionAsManufacturerMutation();
  const { addError, addSuccess } = useNotification();
  const navigate = useNavigate();

  const handleResubmitBooking = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        revertRejection({
          variables: {
            input: {
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError('Error resubmitting the booking.');
      },
      onSuccess: () => {
        addSuccess('Booking request saved and sent for approval.');
        navigate(bookingRequestRoutes.manufacturerRequests.getUrl());
      }
    });
  };

  return (
    <WithConfirmationModal
      cancelLabel="Cancel"
      confirmLabel="Submit"
      header="Resend for customer approval"
      message="This booking will need to be reviewed and approved by the forwarder and the customer."
      onConfirm={() => handleResubmitBooking()}
    >
      <Button size="compact">Resend for approval</Button>
    </WithConfirmationModal>
  );
};

export default BookingManufacturerResubmitButton;
