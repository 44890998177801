import type { Option } from '@zen/DesignSystem';
import type { RoadTrackedShipmentEvent } from '@zen/graphql/types.generated';
import { StopType } from '@zen/graphql/types.generated';
import type { Nullable } from '@zen/utils/typescript';

export type { RoadTrackedShipmentFilterOptions } from '@zen/graphql/types.generated';

export { StopType };

export interface TrackedShipmentFilters {
  destinationCountryCode: Nullable<string>;
  event: Nullable<StopType>;
  originCountryCode: Nullable<string>;
  timeframe: Nullable<TrackedShipmentTimeframe>;
}

export enum TrackedShipmentTimeframe {
  NEXT_14_DAYS = 'NEXT_14_DAYS',
  NEXT_1_DAY = 'NEXT_1_DAY',
  NEXT_3_DAYS = 'NEXT_3_DAYS',
  NEXT_7_DAYS = 'NEXT_7_DAYS'
}

export interface TrackedShipmentFilterOptions {
  firstStopCountryCodes: Option<string>[];
  lastStopCountryCodes: Option<string>[];
}

export interface TrackedShipmentFiltersInput {
  event?: RoadTrackedShipmentEvent;
  firstStopCountryCodes?: string[];
  lastStopCountryCodes?: string[];
}
