import { get } from 'lodash';

import { defaultPageInfo } from './helpers';
import type {
  BasicQueryResult,
  PaginatedQueryHookResult,
  PaginatedVariables,
  PaginationQueryHookVariables,
  UseQueryHookType
} from './types';

const usePaginatedQuery = <Result, Variables extends PaginatedVariables, NodeType>(
  useQueryHook: UseQueryHookType<Result, Variables>,
  responsePath: string,
  variables: PaginationQueryHookVariables<Variables> & PaginatedVariables,
  options: object = {}
): PaginatedQueryHookResult<Result, Variables, NodeType> => {
  const { data, loading, error, refetch, fetchMore } = useQueryHook({
    fetchPolicy: 'network-only',
    variables: {
      ...variables
    } as Variables,
    ...options
  });

  const resultData = get(data, responsePath, {
    pageInfo: defaultPageInfo,
    nodes: [],
    totalCount: 0
  }) as BasicQueryResult<NodeType>;

  const { pageInfo = defaultPageInfo, nodes = [], totalCount = 0 } = resultData;

  const isLoading: boolean = !data && loading;

  return { nodes, totalCount, loading: isLoading, error, pageInfo, refetch, fetchMore };
};

export default usePaginatedQuery;
