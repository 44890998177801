import type { RateCardCharge, RateCardReducerState } from '../reducer';
import type { ChargeFieldName } from '../reducer/types';
import { ChargeClassification, type RateCard } from './types';

export const getChargesClassification = (rateCard: RateCard): RateCardReducerState => {
  const { charges, issuedBy, ...rest } = rateCard;

  const mapping: Record<ChargeFieldName, RateCardCharge[]> = {
    freightCharges: [],
    originHaulageCharges: [],
    originCharges: [],
    destinationCharges: [],
    destinationHaulageCharges: [],
    otherCharges: []
  };

  const classificationToChargeListMapping: Record<ChargeClassification, RateCardCharge[]> = {
    [ChargeClassification.FREIGHT]: mapping.freightCharges,
    [ChargeClassification.ORIGIN_HAULAGE]: mapping.originHaulageCharges,
    [ChargeClassification.ORIGIN_PORT]: mapping.originCharges,
    [ChargeClassification.ORIGIN]: mapping.originCharges,
    [ChargeClassification.DESTINATION]: mapping.destinationCharges,
    [ChargeClassification.DESTINATION_PORT]: mapping.destinationCharges,
    [ChargeClassification.DESTINATION_HAULAGE]: mapping.destinationHaulageCharges,
    [ChargeClassification.OTHER]: mapping.otherCharges
  };

  charges.forEach((charge: RateCardCharge) => {
    if (charge.chargeClassification) {
      classificationToChargeListMapping[charge.chargeClassification as ChargeClassification].push(charge);
    }
  });

  return {
    ...rest,
    issuedBy: `${issuedBy.firstName} ${issuedBy.lastName}`,
    ...mapping
  };
};
