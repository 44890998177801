import type { QueryResult } from '@apollo/client';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { QUERY_POLLING_INTERVAL } from '@zen/utils/constants';
import { useWindowFocus } from '@zen/utils/hooks/useWindowFocus';
import type { Undefinable } from '@zen/utils/typescript';

import { useGetActiveTaskListQuery, useGetCompletedTaskListQuery } from '../graphql';
import { modifyQueryForFixedFilterOptions } from '../helpers';
import type { MiniTaskListFilter } from '../MiniTaskList/MiniTaskListFilters/MiniTaskListFilters';
import type { ActiveTaskItem, CompletedTaskItem } from '../types';
import { useCoreCargoIds } from './useCoreCargoIds';

type MiniTaskListNodes = Undefinable<(CompletedTaskItem | ActiveTaskItem)[]>;
type MiniTaskListQueryResponse = Omit<QueryResult, 'data'> & { nodes: MiniTaskListNodes };

type RouteParams = {
  id: string;
};

const useMiniTaskListQuery = (taskDetailsFiltersInput: MiniTaskListFilter): MiniTaskListQueryResponse => {
  const { id: zencargoReference } = useParams<RouteParams>() as RouteParams;
  const { isWindowFocused } = useWindowFocus();

  const { activeStatus, ...filterInput } = taskDetailsFiltersInput;

  const modifiedQueryVariables: MiniTaskListFilter = modifyQueryForFixedFilterOptions(filterInput);
  const coreCargoIds: string[] = useCoreCargoIds(zencargoReference);

  const query = activeStatus === 'completed' ? useGetCompletedTaskListQuery : useGetActiveTaskListQuery;

  const { data, refetch, ...queryResult } = query({
    variables: {
      taskDetailsFiltersInput: {
        onlyUnassignedTasks: false,
        ...modifiedQueryVariables,
        businessKey: [zencargoReference, ...coreCargoIds]
      }
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  useEffect(() => {
    if (isWindowFocused) {
      queryResult.startPolling(QUERY_POLLING_INTERVAL);
      refetch();
    } else {
      queryResult.stopPolling();
    }

    return () => queryResult.stopPolling();
  }, [queryResult, refetch, isWindowFocused]);

  const nodes: MiniTaskListNodes = data?.taskDetails?.tasks?.nodes;

  return { nodes, refetch, ...queryResult };
};

export default useMiniTaskListQuery;
