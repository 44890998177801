import type { CentralPortCharge } from '../../types';
import type { ChargeFormInitialValues } from './types';

export const prepareInitialValues = (values: CentralPortCharge): ChargeFormInitialValues => {
  const { chargeType, chargeValue, currency, incoterms, port } = values;

  return {
    chargeType,
    price: {
      value: chargeValue,
      currency
    },
    port,
    incoterms
  };
};
