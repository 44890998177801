import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetPreferencesIndicatorQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetPreferencesIndicatorQueryResult = { __typename?: 'Query' } & {
  currentUser?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUser' } & {
      preferences?: GraphQLTypes.Maybe<{ __typename?: 'Preference' } & Pick<GraphQLTypes.Preference, 'filterViews'>>;
    }
  >;
};

export const GetPreferencesIndicatorQueryDocument = /* #__PURE__ */ gql`
  query getPreferencesIndicatorQuery {
    currentUser {
      preferences {
        filterViews
      }
    }
  }
`;

/**
 * __useGetPreferencesIndicatorQuery__
 *
 * To run a query within a React component, call `useGetPreferencesIndicatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPreferencesIndicatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPreferencesIndicatorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPreferencesIndicatorQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPreferencesIndicatorQueryResult, GetPreferencesIndicatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPreferencesIndicatorQueryResult, GetPreferencesIndicatorQueryVariables>(
    GetPreferencesIndicatorQueryDocument,
    options
  );
}
export function useGetPreferencesIndicatorQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPreferencesIndicatorQueryResult, GetPreferencesIndicatorQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPreferencesIndicatorQueryResult, GetPreferencesIndicatorQueryVariables>(
    GetPreferencesIndicatorQueryDocument,
    options
  );
}
export type GetPreferencesIndicatorQueryHookResult = ReturnType<typeof useGetPreferencesIndicatorQuery>;
export type GetPreferencesIndicatorQueryLazyQueryHookResult = ReturnType<typeof useGetPreferencesIndicatorQueryLazyQuery>;
