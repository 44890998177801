export const DASHBOARD_ROUTE_PREFIX = '/dashboard';

interface DashboardRoutes {
  dashboard: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const dashboardRoutes: DashboardRoutes = {
  dashboard: {
    path: `${DASHBOARD_ROUTE_PREFIX}/*`,
    relativePath: '*',
    getUrl: () => DASHBOARD_ROUTE_PREFIX
  }
};

export default dashboardRoutes;
