import { type FC, type ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import { Button, Switch } from '@zen/DesignSystem';
import bookingRequestRoutes from '@zen/Routes/bookingRequests';
import shipmentRoutes from '@zen/Routes/shipments';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useBookingRequestsRevertRejectionMutation } from './graphql';

interface Props {
  zencargoReference: string;
}

const BookingResubmitForApprovalButton: FC<Props> = ({ zencargoReference }) => {
  const [customerApprovalRequired, setCustomerApprovalRequired] = useState<boolean>(true);
  const [revertRejection] = useBookingRequestsRevertRejectionMutation();
  const { addError, addSuccess } = useNotification();
  const navigate = useNavigate();

  const message: ReactNode = (
    <>
      <div>Does this booking request need to be reviewed and approved by the customer? Opting out will generate a shipment.</div>
      <Switch
        className="flex justify-center mt-4 font-bold text-grey-dark"
        isChecked={customerApprovalRequired}
        label="Send to customer for approval"
        onChange={setCustomerApprovalRequired}
      />
    </>
  );

  const handleResubmitBooking = async (): Promise<void> => {
    const successMessage: string = customerApprovalRequired ? 'Booking request saved and sent for approval' : 'Booking saved';
    const url: string = customerApprovalRequired
      ? bookingRequestRoutes.readyForApproval.getUrl()
      : shipmentRoutes.shipmentIndex.getUrl();

    await performMutation({
      mutationFn: () =>
        revertRejection({
          variables: {
            input: {
              customerApprovalRequired,
              zencargoReference
            }
          }
        }),
      onError: () => {
        addError('Error resubmitting the booking.');
      },
      onSuccess: () => {
        addSuccess(successMessage);
        navigate(url);
      }
    });
  };

  return (
    <WithConfirmationModal
      cancelLabel="Cancel"
      confirmLabel="Submit"
      header="Resend for customer approval"
      message={message}
      onConfirm={handleResubmitBooking}
    >
      <Button size="compact">Resend for approval</Button>
    </WithConfirmationModal>
  );
};

export default BookingResubmitForApprovalButton;
