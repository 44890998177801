import { number, object } from 'yup';

import type { HaulageFormInitialValues } from './types';

const validatePrice = (message: string) =>
  object({ value: number().nullable() }).test('price test', function testPriceValue(price) {
    if (price.value === null) {
      return this.createError({ message });
    }

    return true;
  });

export const getValidationSchema = (labeConfig: Record<keyof HaulageFormInitialValues, string>) =>
  object().shape({
    chargeType: object().nullable().required(`${labeConfig.chargeType} is required.`),
    location: object().nullable().required(`${labeConfig.location} is required.`),
    port: object().nullable().required(`${labeConfig.port} is required.`),
    price: validatePrice(`${labeConfig.price} is required.`)
  });
