import { FC, type ReactNode, useState } from 'react';

import { Button, Container } from '@zen/DesignSystem';

import ApiKeyTable from '../ApiKeyTable/ApiKeyTable';
import GenerateKeyModal from '../GenerateKeyModal/GenerateKeyModal';

interface Props {
  accountId: string;
  isKeyCreationEnabled: boolean;
}

const GenerateKeyPanel: FC<Props> = ({ isKeyCreationEnabled, accountId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const createKeyButton: ReactNode = (
    <Button disabled={!isKeyCreationEnabled} iconLeft="zicon-add" onClick={() => setIsModalOpen(true)} size="compact">
      Create key
    </Button>
  );

  return (
    <>
      <GenerateKeyModal accountId={accountId} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <div className="grid grid-cols-8 col-span-12 gap-8 xl:gap-6 xl:grid-cols-12">
        <Container
          actions={createKeyButton}
          className="col-span-8 p-0"
          compactView={true}
          subtitle="Needed to enable SSO using SCIM and establish a secure connection"
          title="API key"
        >
          <ApiKeyTable accountId={accountId} />
        </Container>
      </div>
    </>
  );
};

export default GenerateKeyPanel;
