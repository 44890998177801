import { useEffect, useState } from 'react';

import type { AddressInput } from '../AccountForms/types';

const useAddressOrientedZoom = (address: AddressInput): number => {
  const [zoomValue, setZoomValue] = useState<number>(1);
  const { country, city, line1, postalCodeOrZip, countyOrState } = address;

  useEffect(() => {
    let zoomLevel: number = 1;

    if (country) zoomLevel = 5;
    if (city || countyOrState) zoomLevel = 10;
    if (postalCodeOrZip) zoomLevel = 13;
    if ((postalCodeOrZip || city) && line1) zoomLevel = 16;

    setZoomValue(zoomLevel);
  }, [city, line1, postalCodeOrZip, countyOrState, address, country]);

  return zoomValue;
};

export { useAddressOrientedZoom };
