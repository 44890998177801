import type { FC } from 'react';

import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateContainerCargoItemMutation } from '../../../graphql';
import {
  CargoManagementTrackingAction,
  CargoManagementTrackingCategory,
  type CoreCargo,
  type ModeOfTransport
} from '../../../types';
import FullContainerForm from '../FullContainerForm';
import type { FullContainerFormValues } from '../FullContainerForm/types';
import { prepareEditFullContainerFormValues, prepareFullContainerFormInitialValues } from './helpers';

interface Props {
  cargo: CoreCargo;
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSuccess: () => void;
}

const EditFullContainer: FC<Props> = ({ cargo, modeOfTransport, onCancel, onSuccess }) => {
  const [editContainer] = useUpdateContainerCargoItemMutation();
  const { addSuccess } = useNotification();
  const { trackEvent } = useTracking();

  const handleSuccess = (): void => {
    onSuccess();
    addSuccess('Cargo successfully updated.');

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.UPDATE_CARGO_ITEM,
      label: cargo.cargoType || ''
    });
  };

  const handleSubmit = (values: FullContainerFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        editContainer({
          variables: { input: { id: cargo.id, ...prepareEditFullContainerFormValues(values) } },
          refetchQueries: [
            'cargoSummary',
            'cargoOverview',
            'bookingDetails',
            'bookingOverview',
            'bookingSummary',
            'cargoJourneysSummary',
            'oceanTrackingStatus'
          ],
          awaitRefetchQueries: true
        })
    });
  };

  return (
    <FullContainerForm
      initialValues={prepareFullContainerFormInitialValues(cargo)}
      modeOfTransport={modeOfTransport}
      onCancel={onCancel}
      onSubmit={handleSubmit}
      onSuccess={handleSuccess}
    />
  );
};

export default EditFullContainer;
