import type { ChargeType } from '@zen/Components/Form/FormChargeTypeSelect';
import type { Terminal } from '@zen/Components/TerminalSelect';
import type {
  CargoModeEnum,
  ContainerChargeInput,
  ContainerTypeEnum,
  Currency,
  FreightChargeInput,
  HaulageChargeInput,
  IncotermsValue,
  LegacyCreateRateCardInput,
  LegacyUpdateRateCardInput as EditRateCardInput,
  ModeOfTransport,
  PortChargeInput
} from '@zen/graphql/types.generated';
import type { NetworksOrgLocOption } from '@zen/Networks';
import type { Nullable } from '@zen/utils/typescript';

export type { ContainerChargeInput, EditRateCardInput, FreightChargeInput, HaulageChargeInput, LegacyCreateRateCardInput };

export enum ActionType {
  ADD_CUSTOM_DESTINATION_CHARGE = 'ADD_CUSTOM_DESTINATION_CHARGE',
  ADD_CUSTOM_ORIGIN_CHARGE = 'ADD_CUSTOM_ORIGIN_CHARGE',
  ADD_DESTINATION_CHARGES = 'ADD_DESTINATION_CHARGES',
  ADD_DESTINATION_HAULAGE_CHARGE = 'ADD_DESTINATION_HAULAGE_CHARGE',
  ADD_FREIGHT_CHARGE = 'ADD_FREIGHT_CHARGE',
  ADD_ORIGIN_CHARGES = 'ADD_ORIGIN_CHARGES',
  ADD_ORIGIN_HAULAGE_CHARGE = 'ADD_ORIGIN_HAULAGE_CHARGE',
  DELETE_CUSTOM_DESTINATION_CHARGE = 'DELETE_CUSTOM_DESTINATION_CHARGE',
  DELETE_CUSTOM_ORIGIN_CHARGE = 'DELETE_CUSTOM_ORIGIN_CHARGE',
  DELETE_DESTINATION_HALUAGE_CHARGE = 'DELETE_DESTINATION_HALUAGE_CHARGE',
  DELETE_FREIGHT_CHARGE = 'DELETE_FREIGHT_CHARGE',
  DELETE_ORIGIN_HAULAGE_CHARGE = 'DELETE_ORIGIN_HAULAGE_CHARGE',
  DISABLE_DESTINATION_CHARGE = 'DISABLE_DESTINATION_CHARGE',
  DISABLE_ORIGIN_CHARGE = 'DISABLE_ORIGIN_CHARGE',
  ENABLE_DESTINATION_CHARGE = 'ENABLE_DESTINATION_CHARGE',
  ENABLE_ORIGIN_CHARGE = 'ENABLE_ORIGIN_CHARGE',
  REINITIALIZE = 'REINITIALIZE',
  REMOVE_CUSTOM_DESTINATION_PORT_CHARGE_PRICE = 'REMOVE_CUSTOM_DESTINATION_PORT_CHARGE_PRICE',
  REMOVE_CUSTOM_ORIGIN_PORT_CHARGE_PRICE = 'REMOVE_CUSTOM_ORIGIN_PORT_CHARGE_PRICE',
  UPDATE_DESTINATION_HALUAGE_CHARGE = 'UPDATE_DESTINATION_HALUAGE_CHARGE',
  UPDATE_DESTINATION_PORT_CHARGE = 'UPDATE_DESTINATION_PORT_CHARGE',
  UPDATE_FREIGHT_CHARGE = 'UPDATE_FREIGHT_CHARGE',
  UPDATE_NOTE = 'ADD_TERMS_AND_CONDITIONS',
  UPDATE_ORIGIN_HAULAGE_CHARGE = 'UPDATE_ORIGIN_HAULAGE_CHARGE',
  UPDATE_ORIGIN_PORT_CHARGE = 'UPDATE_ORIGIN_PORT_CHARGE',
  UPDATE_RATE_CARD_INPUT = 'UPDATE_RATE_CARD_INPUT'
}

export type RateCardInputUpdatePayload = {
  endDate?: string;
  issuedBy?: string;
  note?: string;
  startDate?: string;
};

export type RateCardInput = {
  cargoType: CargoModeEnum;
  destinationCharges: PortChargeInput[];
  destinationHaulageCharges: HaulageChargeInput[];
  endDate: string;
  freightCharges: FreightChargeInput[];
  issuedBy: string;
  modeOfTransport: ModeOfTransport;
  name: string;
  note?: Nullable<string>;
  originCharges: PortChargeInput[];
  originHaulageCharges: HaulageChargeInput[];
  startDate: string;
};

export type ChargeFieldName = keyof ChargePayloadType;

export type RateCardReducerState = {
  cargoType: CargoModeEnum;
  destinationCharges: PortChargePayload[];
  destinationHaulageCharges: HaulageChargePayload[];
  endDate: string;
  freightCharges: FreightChargePayload[];
  issuedBy: string;
  modeOfTransport: ModeOfTransport;
  name: string;
  note?: Nullable<string>;
  originCharges: PortChargePayload[];
  originHaulageCharges: HaulageChargePayload[];
  startDate: string;
};

export type FreightChargePayload = {
  chargeType: ChargeType;
  containerCharges: Array<ContainerChargePayload>;
  destinationPort: Terminal;
  incoterms: Array<IncotermsValue>;
  originPort: Terminal;
};

export type ContainerChargePayload = {
  chargeValue: number | null;
  currency: Currency;
  unitType: ContainerTypeEnum;
};

export type HaulageChargePayload = {
  chargeType: ChargeType;
  chargeValue: number;
  currency: Currency;
  incoterms: Array<IncotermsValue>;
  location: NetworksOrgLocOption;
  port: Terminal;
};

export type PortChargePayload = {
  centralPortChargeId?: Nullable<string>;
  chargeType: ChargeType;
  chargeValue: number;
  currency: Currency;
  customChargeValue?: number;
  customCurrency?: Currency;
  disabled?: boolean;
  incoterms: Array<IncotermsValue>;
  port: Terminal;
};

export type ChargePayloadType = {
  destinationCharges: PortChargePayload;
  destinationHaulageCharges: HaulageChargePayload;
  freightCharges: FreightChargePayload;
  originCharges: PortChargePayload;
  originHaulageCharges: HaulageChargePayload;
};

export type UpdateRateCardInput = {
  payload: RateCardInputUpdatePayload;
  type: ActionType.UPDATE_RATE_CARD_INPUT;
};

export type UpdateTermsAndConditions = {
  payload: string;
  type: ActionType.UPDATE_NOTE;
};

export type AddFreightCharge = {
  payload: FreightChargePayload;
  type: ActionType.ADD_FREIGHT_CHARGE;
};

export type AddOriginHaulageCharge = {
  payload: HaulageChargePayload;
  type: ActionType.ADD_ORIGIN_HAULAGE_CHARGE;
};

export type AddDestinationHaulageCharge = {
  payload: HaulageChargePayload;
  type: ActionType.ADD_DESTINATION_HAULAGE_CHARGE;
};

export type AddOriginCharges = {
  payload: PortChargePayload[];
  type: ActionType.ADD_ORIGIN_CHARGES;
};

export type AddCustomOriginCharge = {
  payload: PortChargePayload;
  type: ActionType.ADD_CUSTOM_ORIGIN_CHARGE;
};

export type AddCustomDestinationCharge = {
  payload: PortChargePayload;
  type: ActionType.ADD_CUSTOM_DESTINATION_CHARGE;
};

export type UpdateFreightCharge = {
  payload: { atIndex: number; value: FreightChargePayload };
  type: ActionType.UPDATE_FREIGHT_CHARGE;
};

export type UpdateOriginPortCharge = {
  payload: { atIndex: number; value: PortChargePayload };
  type: ActionType.UPDATE_ORIGIN_PORT_CHARGE;
};

export type UpdateOriginHaulageCharge = {
  payload: { atIndex: number; value: HaulageChargePayload };
  type: ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE;
};

export type UpdateDestinationPortCharge = {
  payload: { atIndex: number; value: PortChargePayload };
  type: ActionType.UPDATE_DESTINATION_PORT_CHARGE;
};

export type UpdateDestinationHaulageCharge = {
  payload: { atIndex: number; value: HaulageChargePayload };
  type: ActionType.UPDATE_DESTINATION_HALUAGE_CHARGE;
};

export type AddDestinationCharges = {
  payload: PortChargePayload[];
  type: ActionType.ADD_DESTINATION_CHARGES;
};

export type DeleteFreightCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_FREIGHT_CHARGE;
};

export type DeleteDestinationHaulageCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_DESTINATION_HALUAGE_CHARGE;
};

export type DeleteOriginHaulageCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_ORIGIN_HAULAGE_CHARGE;
};

export type DisableOriginCharge = {
  payload: { id: string };
  type: ActionType.DISABLE_ORIGIN_CHARGE;
};

export type EnableOriginCharge = {
  payload: { id: string };
  type: ActionType.ENABLE_ORIGIN_CHARGE;
};

export type DisableDestinationCharge = {
  payload: { id: string };
  type: ActionType.DISABLE_DESTINATION_CHARGE;
};

export type EnableDestinationCharge = {
  payload: { id: string };
  type: ActionType.ENABLE_DESTINATION_CHARGE;
};

export type DeleteCustomOriginCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_CUSTOM_ORIGIN_CHARGE;
};

export type DeleteCustomDestinationCharge = {
  payload: { atIndex: number };
  type: ActionType.DELETE_CUSTOM_DESTINATION_CHARGE;
};

export type RemoveCustomOriginPortChargePrice = {
  payload: { atIndex: number };
  type: ActionType.REMOVE_CUSTOM_ORIGIN_PORT_CHARGE_PRICE;
};

export type RemoveCustomDestinationPortChargePrice = {
  payload: { atIndex: number };
  type: ActionType.REMOVE_CUSTOM_DESTINATION_PORT_CHARGE_PRICE;
};

export type Reinitialize = {
  payload: RateCardReducerState;
  type: ActionType.REINITIALIZE;
};

export type ChargeInput = FreightChargePayload | PortChargePayload | HaulageChargePayload;

export type Action =
  | AddDestinationCharges
  | AddDestinationHaulageCharge
  | AddFreightCharge
  | AddOriginCharges
  | AddOriginHaulageCharge
  | AddCustomDestinationCharge
  | AddCustomOriginCharge
  | DisableDestinationCharge
  | EnableDestinationCharge
  | DeleteDestinationHaulageCharge
  | DeleteFreightCharge
  | DeleteCustomOriginCharge
  | DeleteCustomDestinationCharge
  | DisableOriginCharge
  | EnableOriginCharge
  | DeleteOriginHaulageCharge
  | Reinitialize
  | UpdateDestinationPortCharge
  | UpdateDestinationHaulageCharge
  | UpdateFreightCharge
  | UpdateOriginPortCharge
  | UpdateOriginHaulageCharge
  | UpdateRateCardInput
  | UpdateTermsAndConditions
  | RemoveCustomOriginPortChargePrice
  | RemoveCustomDestinationPortChargePrice;
