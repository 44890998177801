import type { FC, ReactNode } from 'react';

import CollapsibleElement from '@zen/Components/CollapsibleElement';
import ChevronIcon from '@zen/Components/Icons/ChevronIcon';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';
import { useRouter } from '@zen/utils/hooks/useRouter';

import type { SidebarCounters, SidebarLink } from '../../types';
import NavChildLink from '../NavChildLink';
import NavItem from '../NavItem';

interface Props {
  counters?: SidebarCounters;
  item: SidebarLink;
}

const NavDropdown: FC<Props> = ({ item, counters }) => {
  const { children, icon, label, isExpandedByDefault } = item;
  const { isMatchingPathname } = useRouter();
  const [isExpanded, setIsExpanded] = useCollapsibleElement(label, isExpandedByDefault, 'sidebar-nav-config');

  const isAnySubItemActive: boolean = children?.some(({ url }) => isMatchingPathname(url)) || false;
  const isNavItemActive: boolean = isAnySubItemActive && !isExpanded;

  const handleClick = (): void => setIsExpanded(!isExpanded);

  const renderChildLink = ({ url, label: childLabel, openInNewTab, counter }: SidebarLink, index: number): ReactNode => {
    return (
      <div key={index} className="pb-1">
        <NavChildLink
          count={counter && counters?.[counter]}
          isActive={isMatchingPathname(url)}
          label={childLabel}
          openInNewTab={openInNewTab}
          url={url}
        />
      </div>
    );
  };

  return (
    <>
      <div onClick={handleClick}>
        <NavItem icon={icon} isActive={isNavItemActive} label={label}>
          <ChevronIcon className="text-lg mr-2" expanded={isExpanded} size="small" />
        </NavItem>
      </div>
      <CollapsibleElement isOpened={isExpanded}>
        <div className="pt-1">{children?.map(renderChildLink)}</div>
      </CollapsibleElement>
    </>
  );
};

export type { Props as NavDropdownProps };

export default NavDropdown;
