import * as Yup from 'yup';

import { isValidTime } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

export const defaultErrorMessage = 'Something went wrong. Please refresh the page or try again later.';

export const timeValidator = Yup.string()
  .nullable()
  .test('time validation', function test(time: Optional<string>) {
    if (!time) {
      return this.createError({ message: 'Please enter time.' });
    }

    if (time && !isValidTime(time)) {
      return this.createError({ message: 'Please enter a valid time.' });
    }

    return true;
  });

export const startTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function test(startTime: Optional<string>) {
    if (this.parent.endTime && !startTime) {
      return this.createError({ message: 'Please enter start time.' });
    }

    if (startTime && !isValidTime(startTime)) {
      return this.createError({ message: 'Please enter a valid start time.' });
    }

    return true;
  });

export const endTimeValidator = Yup.string()
  .nullable()
  .test('time validation', function test(endTime: Optional<string>) {
    if (this.parent.startTime && !endTime) {
      return this.createError({ message: 'Please enter end time.' });
    }

    if (endTime && !isValidTime(endTime)) {
      return this.createError({ message: 'Please enter a valid end time.' });
    }

    if (endTime && this.parent.startTime > endTime) {
      return this.createError({ message: 'End time can not be earlier than start time.' });
    }

    return true;
  });
