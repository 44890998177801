import type { FC } from 'react';

import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import type { TableColumn } from '@zen/DesignSystem';
import { bookingRequestRoutes } from '@zen/Routes';
import type { SortInput } from '@zen/types';

import { getColumns } from '../BookingRequestsTable/bookingRequestsTableColumns';
import type { BookingRequest } from '../types';

interface Props {
  order: SortInput;
}

const BookingRequestLoadingSkeleton: FC<Props> = ({ order }) => {
  const getShipmentDetailsPageLink = (zencargoReference: string, sectionName?: string): string => {
    const { readyForApprovalDetailsPage } = bookingRequestRoutes;

    return readyForApprovalDetailsPage.getUrl(zencargoReference, sectionName);
  };

  const columns: TableColumn<BookingRequest>[] = getColumns(getShipmentDetailsPageLink);

  return <SkeletonTableLoading columns={columns} order={order} rowCount={7} tableId="bookingRequests" />;
};

export default BookingRequestLoadingSkeleton;
