import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  ['aria-label']?: string;
  icon: IconName;
  onClick: () => void;
}

const GoogleMapStyleIconButton: FC<Props> = (props) => {
  const { onClick, icon } = props;

  return (
    <button
      aria-label={props['aria-label']}
      className="block bg-navy-base text-grey-lightest rounded-[2px] pointer-events-auto appearance-none w-[40px] h-[40px]"
      onClick={(evt) => {
        evt.preventDefault();
        onClick();
      }}
      style={{
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px'
      }}
    >
      <Icon className="text-current text-[16px] font-bold" icon={icon} />
    </button>
  );
};

export default GoogleMapStyleIconButton;
export type { Props as GoogleMapStyleIconButtonProps };
