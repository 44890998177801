import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Pill } from '@zen/DesignSystem';

import type { TrackingStatus } from '../../types';
import { getTrackingConfig } from './helpers';

interface Props {
  className?: string;
  status: TrackingStatus;
}

const BookingTrackingIndicator: FC<Props> = ({ className, status }) => {
  const role = useRole();

  const { type, label } = getTrackingConfig(status, role);

  return (
    <Pill className={className} type={type}>
      {label}
    </Pill>
  );
};

export default BookingTrackingIndicator;
