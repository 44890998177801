import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { checkPermission } from '@zen/Auth/authHelper';
import useRoutePermissions from '@zen/Auth/RoutePermissions/useRoutePermissions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import QueryHandler from '@zen/Components/QueryHandler';
import { ContainerWithTabs } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { isRateCardAvailable } from '@zen/RateCards';
import { documentRoutes } from '@zen/Routes';
import type { CargoModeEnum, ControlTower } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import BookingOverviewLoadingSkeleton from '../components/skeletons/BookingOverviewLoadingSkeleton';
import type { BookingDetailsPageSection } from '../types';
import { useBookingOverviewQuery, useControlTowerPermissionsQuery } from './graphql';
import { getTabsConfig } from './helpers';
import type { BookingOverviewData } from './types';

type RouteParams = {
  sectionName?: BookingDetailsPageSection;
};

interface Props {
  accountId: string;
  showNewQuoteButton: boolean;
  zencargoReference: string;
}

const BookingOverview: FC<Props> = ({ accountId, showNewQuoteButton, zencargoReference }) => {
  const { sectionName } = useParams<RouteParams>() as RouteParams;
  const { check } = useGlobalPermissions();
  const { data, loading, error } = useBookingOverviewQuery({ variables: { zencargoReference } });
  const isLegacyRateCardsEnabled = useFeatureIsOn('legacy-rate-cards');
  const { canAccessRoute } = useRoutePermissions();

  const { data: controlTowerData } = useControlTowerPermissionsQuery({
    variables: { uuid: accountId },
    skip: !accountId
  });

  const controlTower: ControlTower = controlTowerData?.legacyAccount?.controlTower as ControlTower;
  const canViewForwarder: boolean = checkPermission<ControlTower>(controlTower, 'canViewForwardersOnShipments');
  const canAccessAccruals: boolean = check('accruals.canAccessRoute');

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<BookingOverviewLoadingSkeleton />}
    >
      {(booking: BookingOverviewData) => {
        const { modeOfTransport, cargo, issues } = booking;
        const cargoMode: Optional<CargoModeEnum> = cargo?.mode;
        const canAccessRateCards: boolean = isLegacyRateCardsEnabled && isRateCardAvailable(modeOfTransport, cargoMode);
        const canViewIssues: boolean = checkPermission<BookingOverviewData>(booking, 'canViewIssues');
        const canManageAssignedLots: boolean = checkPermission<BookingOverviewData>(booking, 'canManageAssignedLots');
        const canAccessDocumentCreation: boolean = canAccessRoute(documentRoutes.index.path);

        return (
          <ContainerWithTabs
            compactView={true}
            tabViewConfig={getTabsConfig({
              booking,
              canAccessAccruals,
              canAccessDocumentCreation,
              canAccessRateCards,
              canManageAssignedLots,
              canViewIssues,
              canViewForwarder,
              issues,
              sectionName,
              showNewQuoteButton
            })}
          />
        );
      }}
    </QueryHandler>
  );
};

export default BookingOverview;
