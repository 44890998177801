import type { FC } from 'react';

import PriceCalculationDate from '../PriceCalculationDate';
import { usePriceCalculation } from './hooks';

interface Props {
  editable?: boolean;
  zencargoReference: string;
}

const PriceCalculationSection: FC<Props> = ({ editable = true, zencargoReference }) => {
  const { date, milestone } = usePriceCalculation(zencargoReference);

  return <PriceCalculationDate date={date} isEditable={editable} milestone={milestone} zencargoReference={zencargoReference} />;
};

export default PriceCalculationSection;
