import cx from 'classnames';
import type { ReactNode, SyntheticEvent } from 'react';

import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import Tooltip from '../Tooltip';
import type { SegmentedControlOption } from './types';

interface Props<T> {
  className?: string;
  name?: string;
  onChange: (value: T) => void;
  options: SegmentedControlOption<T>[];
  value: T;
}

function SegmentedControl<T = string>({ className, name, onChange, options, value }: Props<T>) {
  const { trackEvent } = useTracking();

  const classNames: string = cx('inline-flex bg-grey-lightest rounded p-1 space-x-1', className);

  const renderOption = (option: SegmentedControlOption<T>, i: number): ReactNode => {
    const { icon, label, value: optionValue, isDisabled, tooltip } = option;
    const isSelected: boolean = optionValue === value;

    const optionClassNames: string = cx(
      {
        'bg-white text-azure-base selected': isSelected && !isDisabled,
        'cursor-pointer text-grey-base hover:text-grey-dark': !isSelected && !isDisabled,
        'text-grey-light cursor-not-allowed': isDisabled
      },
      'flex justify-center items-center space-x-2 px-3 h-8 rounded transition duration-300 ease-in-out text-sm whitespace-nowrap'
    );

    const handleClick = (event: SyntheticEvent): void => {
      if (isDisabled) return;

      const trackingEvent = createTrackingEvent('Design System/Input/SegmentedControl', 'click', event);

      trackEvent(trackingEvent);

      onChange(optionValue);
    };

    const optionElement: ReactNode = (
      <div
        key={i}
        className={optionClassNames}
        data-component="segmented-control"
        data-name={name}
        data-testid="segmented-control-option"
        data-value={optionValue}
        onClick={handleClick}
        {...createTrackingParentAttribute('segmented-control')}
        {...createTrackingLabelAttribute()}
      >
        {icon && <Icon icon={icon} />}
        {label && <span>{label}</span>}
      </div>
    );

    if (tooltip) {
      return (
        <Tooltip key={i} placement="bottom" tooltipContent={tooltip}>
          {optionElement}
        </Tooltip>
      );
    }

    return optionElement;
  };

  return (
    <div className={classNames} data-testid="segmented-control">
      {options.map(renderOption)}
    </div>
  );
}

export type { Props as SegmentedControlProps };

export default SegmentedControl;
