import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateOceanCarrierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateOceanCarrierInput;
}>;

export type UpdateOceanCarrierMutationResult = { __typename?: 'Mutation' } & {
  updateOceanCarrier: { __typename?: 'UpdateOceanCarrierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    oceanCarrier?: GraphQLTypes.Maybe<
      { __typename?: 'OceanCarrier' } & Pick<
        GraphQLTypes.OceanCarrier,
        'carrierScac' | 'id' | 'isTrackable' | 'oceanCarrierName' | 'synonyms'
      >
    >;
  };
};

export const UpdateOceanCarrierDocument = /* #__PURE__ */ gql`
  mutation updateOceanCarrier($input: UpdateOceanCarrierInput!) {
    updateOceanCarrier(input: $input) {
      errors {
        message
        path
      }
      oceanCarrier {
        carrierScac
        id
        isTrackable
        oceanCarrierName
        synonyms
      }
    }
  }
`;

/**
 * __useUpdateOceanCarrierMutation__
 *
 * To run a mutation, you first call `useUpdateOceanCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOceanCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOceanCarrierMutation, { data, loading, error }] = useUpdateOceanCarrierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOceanCarrierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOceanCarrierMutationResult, UpdateOceanCarrierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateOceanCarrierMutationResult, UpdateOceanCarrierMutationVariables>(
    UpdateOceanCarrierDocument,
    options
  );
}
export type UpdateOceanCarrierMutationHookResult = ReturnType<typeof useUpdateOceanCarrierMutation>;
