import cx from 'classnames';
import type { ReactNode } from 'react';
import type * as Yup from 'yup';

import { Button, Matcher, TooltipConfig } from '@zen/DesignSystem';
import type { TimeRange as TimeRangeType } from '@zen/utils/dateTime';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable, Optional } from '@zen/utils/typescript';

import Form from '../Form/Form';
import FormDatePicker from '../Form/FormDatePicker';
import FormTimeFields from '../Form/FormTimeFields';
import { TimeRangeEnum } from '../Form/FormTimeFields/TimeRange';
import type { TimeRangeMapping } from '../Form/FormTimeFields/types';
import type { FormInstance } from '../Form/types';
import { dateWithTimeValidationSchema } from './dateWithTimeForm.validation';
import { calculateTimeRangeValue, getTimeRangeMapping, prepareInitialValues, prepareValues } from './helpers';
import { BusinessHoursType, DateWithTimeFields, TimeOption } from './types';

interface Props<T> {
  additionalActionButton?: ReactNode;
  additionalFields?: ReactNode;
  businessHours?: Optional<BusinessHoursType>;
  buttonText?: string;
  dateTooltip?: TooltipConfig | TooltipConfig[] | undefined;
  disabledDates?: Matcher;
  initialValues?: Partial<T>;
  onSubmit: (values: T) => Promise<IOkOrErrorResult>;
  onSuccess?: (data: unknown, values: T) => void;
  timeConfig?: TimeOption[];
  timeZone?: Nullable<string>;
  title?: string;
  validationSchema?: Yup.ObjectSchema<{}>;
  variant?: 'default' | 'compact';
}

const DateWithTimeForm = <T extends DateWithTimeFields>(props: Props<T>) => {
  const {
    additionalActionButton,
    additionalFields,
    businessHours,
    buttonText = 'Confirm',
    dateTooltip,
    disabledDates,
    initialValues = {},
    onSubmit,
    onSuccess,
    timeConfig = [TimeOption.NO_TIME, TimeOption.POINT_IN_TIME, TimeOption.TIME_RANGE],
    timeZone,
    title,
    validationSchema = dateWithTimeValidationSchema,
    variant = 'default'
  } = props;

  const classNames: string = cx({ 'border border-solid border-grey-lighter rounded': variant === 'default' });

  const renderFormButtons = ({ isSubmitting }: FormInstance<T>): ReactNode => {
    return (
      <div className="flex justify-end px-6 py-4 space-x-4 border-t border-solid border-grey-lighter">
        {additionalActionButton}
        <Button data-testid="date-with-time-submit-button" isLoading={isSubmitting} type="submit">
          {buttonText}
        </Button>
      </div>
    );
  };

  const handleSubmit = (formValues: T): Promise<IOkOrErrorResult> => {
    return onSubmit(prepareValues<T>(formValues));
  };

  return (
    <Form
      className={classNames}
      enableReinitialize={true}
      errorClassName="hidden"
      formButtons={renderFormButtons}
      initialValues={prepareInitialValues(initialValues, timeConfig)}
      isNested={true}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      scrollToError={false}
      submitOnEnter={false}
      validationSchema={validationSchema}
    >
      {({ setFieldValue, values }) => {
        const { date, startTime, endTime } = values;
        const timeRangeMapping: TimeRangeMapping = getTimeRangeMapping(date, businessHours);
        const timeRange: Nullable<TimeRangeEnum> = calculateTimeRangeValue(startTime, endTime, timeRangeMapping);

        const handleTimeRangeChange = (range: TimeRangeEnum): void => {
          setFieldValue('startTime', timeRangeMapping[range].startTime);
          setFieldValue('endTime', timeRangeMapping[range].endTime);
        };

        const handleDateChange = (selectedDate: Nullable<string>): void => {
          if (timeRange === TimeRangeEnum.ALL_DAY) {
            const rangeMapping: TimeRangeMapping = getTimeRangeMapping(selectedDate, businessHours);
            const range: TimeRangeType = rangeMapping[TimeRangeEnum.ALL_DAY];

            setFieldValue('startTime', range.startTime);
            setFieldValue('endTime', range.endTime);
          }
        };

        return (
          <div className="relative flex" data-testid="date-with-time-form">
            <FormDatePicker
              className="-mt-px -mb-px -ml-px -mr-px rounded-tl pb-7"
              disabled={disabledDates}
              errorClassName="absolute bottom-3 ml-6"
              hideLabel={true}
              isInline={true}
              name="date"
              noMargin={true}
              onChange={handleDateChange}
              tooltip={dateTooltip}
            />
            <div className="px-6 pt-4">
              {title && <div className="mb-4 text-sm font-bold">{title}</div>}
              <FormTimeFields
                onTimeRangeChange={handleTimeRangeChange}
                timeConfig={timeConfig}
                timeRange={timeRange}
                timeZone={timeZone}
              />
              {additionalFields}
            </div>
          </div>
        );
      }}
    </Form>
  );
};

export default DateWithTimeForm;
