import type { FC } from 'react';

import type { Account } from '../types';
import LegalDetails from './LegalDetails';
import MainDetails from './MainDetails';

interface Props {
  accountDetails: Account;
}
const GeneralAccountDetails: FC<Props> = ({ accountDetails }) => {
  const { tradingName, referencePrefix, legalInformation, id: accountId } = accountDetails;

  return (
    <div className="grid grid-cols-8 gap-8 xl:gap-6 xl:grid-cols-12 ">
      <MainDetails accountId={accountId} referencePrefix={referencePrefix} tradingName={tradingName} />
      <LegalDetails {...legalInformation} accountId={accountId} />
    </div>
  );
};

export default GeneralAccountDetails;
