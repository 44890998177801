import type { FC } from 'react';

import SidebarLinks from '../components/SidebarLinks';
import { config } from './config';

const CustomerAdminLinks: FC = () => {
  return <SidebarLinks config={config} />;
};

export default CustomerAdminLinks;
