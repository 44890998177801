import shipmentRoutes from './shipments';

const CONSOLIDATION_RELATIVE_INDEX_PATH = 'consolidation';

export const CONSOLIDATION_PREFIX = `/dashboard/${CONSOLIDATION_RELATIVE_INDEX_PATH}`;

interface ConsolidationRoutes {
  consolidationAddShipments: {
    getUrl: (consolidationId: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationAddShipmentsDetailsPage: {
    getUrl: (consolidationId: string, zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationAddShipmentsOrderDetailsPage: {
    getUrl: (consolidationId: string, orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationCreate: {
    getRelativeUrl: () => string;
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  consolidationDetails: {
    getRelativeUrl: () => string;
    getUrl: (consolidationId: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationEdit: {
    getRelativeUrl: () => string;
    getUrl: (consolidationId: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  consolidationPage: {
    getUrl: (consolidationId: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationShipments: {
    getRelativeUrl: () => string;
    getUrl: (consolidationId: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationShipmentsDetailsPage: {
    getUrl: (consolidationId: string, zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  consolidationShipmentsOrderDetailsPage: {
    getUrl: (consolidationId: string, orderId: string, tab?: string) => string;
    path: string;
    relativePath: string;
  };
  legacyConsolidationIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const consolidationRoutes: ConsolidationRoutes = {
  consolidationAddShipments: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add/*`,
    relativePath: ':consolidationId/shipments/add/*',
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add`
  },
  consolidationAddShipmentsDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add/details/:id`,
    relativePath: `${CONSOLIDATION_RELATIVE_INDEX_PATH}/:consolidationId/shipments/add/details/:id`,
    getUrl: (consolidationId: string, zencargoReference: string) =>
      `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add/details/${zencargoReference}`
  },
  consolidationAddShipmentsOrderDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/add/:id/*`,
    relativePath: ':id/*',
    getUrl: (consolidationId: string, orderId: string, tab?: string) => {
      const url: string = `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/add/${orderId}`;

      return `${url}/${tab || 'items'}`;
    }
  },
  consolidationCreate: {
    path: `${CONSOLIDATION_PREFIX}/create`,
    relativePath: 'create',
    getUrl: () => `${CONSOLIDATION_PREFIX}/create`,
    getRelativeUrl: () => 'create'
  },
  consolidationDetails: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/details`,
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/details`,
    relativePath: 'details',
    getRelativeUrl: () => 'details'
  },
  consolidationEdit: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/edit`,
    relativePath: 'edit',
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/edit`,
    getRelativeUrl: () => 'edit'
  },
  legacyConsolidationIndex: {
    path: `${CONSOLIDATION_PREFIX}/*`,
    relativePath: `${CONSOLIDATION_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => CONSOLIDATION_PREFIX
  },
  consolidationIndex: {
    getUrl: () => `${shipmentRoutes.shipmentIndex.getUrl()}/consolidations`,
    path: `${shipmentRoutes.shipmentIndex.getUrl()}/consolidations`,
    relativePath: `${shipmentRoutes.shipmentIndex.path}/consolidations`
  },
  consolidationPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/*`,
    relativePath: ':consolidationId/*',
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}`
  },
  consolidationShipments: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/*`,
    relativePath: 'shipments/*',
    getUrl: (consolidationId: string) => `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments`,
    getRelativeUrl: () => 'shipments'
  },
  consolidationShipmentsDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/details/:id`,
    relativePath: `${CONSOLIDATION_RELATIVE_INDEX_PATH}/:consolidationId/shipments/details/:id`,
    getUrl: (consolidationId: string, zencargoReference: string) =>
      `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/details/${zencargoReference}`
  },
  consolidationShipmentsOrderDetailsPage: {
    path: `${CONSOLIDATION_PREFIX}/:consolidationId/shipments/:id/*`,
    relativePath: ':id/*',
    getUrl: (consolidationId: string, orderId: string, tab?: string) => {
      const url: string = `${CONSOLIDATION_PREFIX}/${consolidationId}/shipments/${orderId}`;

      return `${url}/${tab || 'items'}`;
    }
  }
};

export default consolidationRoutes;
