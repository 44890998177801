import type { FC } from 'react';

import FilterComponent from '@zen/Components/Filters';
import type { FilterItemType } from '@zen/Components/Filters/types';
import Search from '@zen/Components/Search';
import useTracking from '@zen/utils/hooks/useTracking';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { type ShipmentFilters, ShipmentFilterStatus } from '../types';
import { buildFilters, removeEmptyShipmentFilters } from './helpers';
import { useShipmentFiltersData } from './hooks';

const Filters: FC = () => {
  const { trackEvent } = useTracking();
  const { filters, setFilters, search, setSearch } = useUrlFilters<ShipmentFilters>({ status: ShipmentFilterStatus.ACTIVE });
  const { filterOptions, networks, permissions } = useShipmentFiltersData();
  const filterItems: FilterItemType<ShipmentFilters>[] = buildFilters(filterOptions, networks, permissions);

  const clearSearchInput = (): void => setSearch(undefined);

  const handleSearch = (value: string): void => {
    setSearch(value);

    trackEvent({
      action: 'Shipments',
      category: 'Search',
      label: value
    });
  };

  const submitFilters = (values: Partial<ShipmentFilters>) => {
    setFilters(removeEmptyShipmentFilters(values));
  };

  return (
    <div className="flex space-x-2">
      <Search
        className="fs-shipments-search"
        collapsible={true}
        initialValue={search}
        onClear={clearSearchInput}
        onSubmit={handleSearch}
        placeholder="Search for a Shipment..."
        size="compact"
        widthClassName="w-60"
      />
      <FilterComponent items={filterItems} onChange={submitFilters} value={filters} />
    </div>
  );
};

export default Filters;
