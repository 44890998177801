import type { FC } from 'react';

import { FormInput, useForm } from '@zen/Components';
import { getFieldName } from '@zen/utils/forms';

import type { AccountFormData } from '../../../AccountForms/types';
import type { BaseFormFieldsProps } from '../types';

interface Props extends BaseFormFieldsProps {
  isZencargoReferenceDisabled?: boolean;
}

const AccountDetailsFields: FC<Props> = ({ isZencargoReferenceDisabled = false, fieldPrefix }) => {
  const { setFieldValue } = useForm<AccountFormData>();

  const zrefFieldName: string = getFieldName('referencePrefix', fieldPrefix);

  return (
    <div className="grid grid-cols-8 gap-8 xl:gap-6">
      <FormInput
        className="col-span-4"
        helperText="This is the internal display name that we will use to label this organisation."
        label="Trading name"
        name={getFieldName('tradingName', fieldPrefix)}
        noMargin={true}
      />
      <FormInput
        className="col-span-4"
        disabled={isZencargoReferenceDisabled}
        helperText={
          <>
            Choose a ZREF to help identify your bookings.
            <br />
            Note: This cannot be changed at a later stage.
          </>
        }
        label="Zencargo reference"
        labelDescription="The Zencargo reference (ZREF) is our unique booking identifier, beginning with 'Z' and your company name or abbreviation. Example: ZACME-4589. Also used in invoices."
        name={zrefFieldName}
        noMargin={true}
        onChange={(event) => {
          const userInput: string = event.target.value.toUpperCase();

          setFieldValue(zrefFieldName, `Z${userInput.replace(/^./, '')}`);

          return event.target.value.toUpperCase();
        }}
      />
    </div>
  );
};

export default AccountDetailsFields;
