import type { FC } from 'react';

import DatesDifference from '@zen/Components/DatesDifference';
import type { RichDate } from '@zen/Orders/types';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  history: RichDate;
  referenceDate: Optional<string>;
}

const HistoryItem: FC<Props> = ({ history, referenceDate }) => {
  const { date, lastUpdated, reasonForChange } = history;

  return (
    <div className="py-4 px-6 border-solid border-b border-azure-lightest">
      <div className="font-semibold text-base mb-1">{reasonForChange.category}</div>
      <div className="flex justify-between mb-1">
        {date && referenceDate && <DatesDifference date={date} referenceDate={referenceDate} />}
        {lastUpdated?.updatedAt && (
          <div className="text-grey-base" data-testid="change-date">
            Updated on: {formatDate(lastUpdated.updatedAt)}
          </div>
        )}
      </div>
      <div className="text-sm">{reasonForChange.description}</div>
    </div>
  );
};

export default HistoryItem;
