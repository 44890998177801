import type { FC, ReactNode } from 'react';

import { getDefaultWorkDays } from '@zen/Accounts/AccountForms/helpers';
import BusinessHoursRadioGroup from '@zen/Accounts/components/forms/BusinessHoursRadioGroup';
import { Form, FormButtons, FormInstance } from '@zen/Components';
import { Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import type { BusinessHoursOption } from '../../../AccountForms/types';
import BusinessHoursFieldsTable from '../../../components/forms/BusinessHoursFieldsTable';
import { type UpdateAccountLocationBusinessHoursInput, useUpdateLocationBusinessHoursMutation } from '../../graphql';
import type { FormattedHours } from '../types';

interface Props {
  initialValues: {
    businessHours: FormattedHours;
    businessHoursOption: BusinessHoursOption;
    locationId: string;
  };
  isOpen: boolean;
  onClose: () => void;
}

type InitialValues = Props['initialValues'];

const UpdateBusinessHoursModal: FC<Props> = ({ isOpen, onClose, initialValues }) => {
  const [updateLocationBusinessHours] = useUpdateLocationBusinessHoursMutation({ refetchQueries: ['getAccountLocation'] });
  const { addError, addSuccess } = useNotification();

  const handleUpdateAccount = async (values: InitialValues): Promise<IOkOrErrorResult> => {
    const { locationId, businessHoursOption, businessHours } = values;
    const input: UpdateAccountLocationBusinessHoursInput = {
      locationId,
      businessHours: businessHoursOption === 'standard' ? getDefaultWorkDays() : businessHours
    };

    return performFormMutation({
      mutationFn: () =>
        updateLocationBusinessHours({
          variables: { input }
        }),
      onError: () => addError('There was a problem updating the business hours.'),
      onSuccess: () => addSuccess('The business hours were updated.')
    });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<{ members: string[] }>): ReactNode => (
    <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save changes">
      <Button data-testid="cancel-button" onClick={onClose} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Update business hours">
      <Form
        buttonText="Save changes"
        formButtons={renderFormButtons}
        initialValues={initialValues}
        onSubmit={handleUpdateAccount}
        onSuccess={onClose}
      >
        {({ values }: FormInstance<InitialValues>) => {
          return (
            <>
              <BusinessHoursRadioGroup />
              {values.businessHoursOption === 'custom' && <BusinessHoursFieldsTable />}
            </>
          );
        }}
      </Form>
    </Modal>
  );
};

export default UpdateBusinessHoursModal;
export type { Props as UpdateBusinessHoursModalProps };
