import cx from 'classnames';
import type { FC, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

const ActivityFeedItemWrapper: FC<Props> = ({ children, className }) => {
  const wrapperStyles: string = cx('py-6 group', className);

  return <div className={wrapperStyles}>{children}</div>;
};

export default ActivityFeedItemWrapper;
