import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import type { Courier } from '@zen/Journey/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import CourierSelect from '../CourierSelect';
import { useCourierQuery, useUpdateJourneyCourierMutation } from './graphql';

interface Props {
  canUpdateCourier: boolean;
  courierId: string;
}

const CourierInlineField: FC<Props> = ({ canUpdateCourier, courierId }) => {
  const { addSuccess, addError } = useNotification();

  const { cargoIds, zencargoReference } = useCargoOverviewContext();

  const { data } = useCourierQuery({
    variables: { id: courierId },
    fetchPolicy: 'no-cache',
    skip: !courierId
  });
  const [updateJourneyCourier, { loading }] = useUpdateJourneyCourierMutation();

  const courier: Undefinable<Courier> = data?.courier || undefined;

  const label: string = 'Courier name';

  const handleChange = async (value: Nullable<string>): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updateJourneyCourier({
          variables: {
            input: {
              cargoIds,
              courier: value || '',
              zencargoReference
            }
          },
          refetchQueries: ['cargoOverview']
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateCourier) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={courier?.courierName} />
      </LabelledValue>
    );
  }

  return (
    <CourierSelect isLoading={loading} label={label} name="courier" onChange={handleChange} value={courierId} variant="inline" />
  );
};

export default CourierInlineField;
