import cx from 'classnames';
import copy from 'copy-to-clipboard';
import type { FC } from 'react';

import ActivityFeedItemDetails from '@zen/ActivityFeed/components/ActivityFeedItemDetails';
import { Icon } from '@zen/DesignSystem';
import { QuoteOption, QuoteOptionState } from '@zen/Quotes';
import QuoteOptionIcon from '@zen/Quotes/components/QuoteOptionIcon';
import { formatDate } from '@zen/utils/dateTime';
import { formatCurrency } from '@zen/utils/formatting';
import { useNotification } from '@zen/utils/hooks/useNotification';

import ActivityFeedItemWrapper from '../../../components/ActivityFeedItemWrapper';
import type { LegacyActivityFeedUser } from '../../../types';
import QuoteOptionButtons from './QuoteOptionButtons';

const headline: Record<QuoteOptionState, string> = {
  [QuoteOptionState.ACTIVE]: 'You’ve received a new quote',
  [QuoteOptionState.ACCEPTED]: 'Quote accepted',
  [QuoteOptionState.REJECTED]: 'Quote rejected',
  [QuoteOptionState.EXPIRED]: 'Quote has expired',
  [QuoteOptionState.ARCHIVED]: 'Quote archived',
  [QuoteOptionState.DRAFT]: ''
};

interface Props {
  activityDate: string;
  onApprove?: () => void;
  onReject?: () => void;
  quoteOption: QuoteOption;
  showButtons?: boolean;
  user: LegacyActivityFeedUser;
}

const QuoteOptionItem: FC<Props> = (props) => {
  const { activityDate, quoteOption, onApprove = () => {}, onReject = () => {}, user, showButtons = true } = props;
  const { addSuccess } = useNotification();
  const { description, expiryDate, quoteReference, state, total } = quoteOption;
  const expireDateFormatted = expiryDate?.date && formatDate(expiryDate.date);
  const priceFormatted = total && formatCurrency(total.currency, total.value);
  const isActive = state === QuoteOptionState.ACTIVE;
  const expiredClassName = cx({ 'text-orange-base': state === QuoteOptionState.EXPIRED });
  const title: string = state ? headline[state] : '';

  const handleCopy = () => {
    const text = `
      Quote ref: ${quoteReference}
      Cost: ${priceFormatted}
      Expiry date: ${expireDateFormatted}
      Description: ${description}
    `;

    copy(text);

    addSuccess('Quote option details copied.');
  };

  return (
    <ActivityFeedItemWrapper>
      <div className="flex justify-between">
        <div>
          <ActivityFeedItemDetails
            additionalText={description}
            createdAt={activityDate}
            icon={state && <QuoteOptionIcon quoteOptionState={state} />}
            title={title}
            user={user}
          />
          <div className="mt-2 text-sm cursor-pointer ml-7 text-navy-base" onClick={handleCopy}>
            <Icon className="mr-2" icon="zicon-copy" />
            &nbsp;<span className="font-bold">Copy details</span>
          </div>
        </div>
        <div>
          <div className="pl-8 ml-auto text-sm leading-normal text-right whitespace-nowrap text-grey-base">
            <div className="mb-2" data-testid="price">
              Price: <b className="text-base text-grey-dark">{priceFormatted}</b>
            </div>
            <div className={expiredClassName} data-testid="expiry-date">
              Expiry date: <b>{expireDateFormatted}</b>
            </div>
            <div data-testid="reference">
              Reference: <b>{quoteReference}</b>
            </div>
          </div>
          {showButtons && isActive && <QuoteOptionButtons onApprove={onApprove} onReject={onReject} />}
        </div>
      </div>
    </ActivityFeedItemWrapper>
  );
};

export default QuoteOptionItem;
