import type { FC } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';

interface Props {
  accountName: string;
}

const ParentAccountPermissionIndicator: FC<Props> = ({ accountName }) => {
  return (
    <Tooltip tooltipContent={`Has access to ${accountName}`}>
      <div className="px-4 rounded-3xl bg-grey-lighter">
        <Icon className="text-sm" icon="zicon-office" />
      </div>
    </Tooltip>
  );
};

export default ParentAccountPermissionIndicator;
