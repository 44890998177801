import type { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import BookingDetails from '@zen/Booking/BookingDetails';
import { customerAdminRoutes } from '@zen/Routes';

import AllShipments from './AllShipments';

const CustomerAdmin: FC = () => {
  return (
    <Routes>
      <Route element={<BookingDetails />} path={customerAdminRoutes.allShipmentsDetailsPage.relativePath} />
      <Route element={<AllShipments />} path={customerAdminRoutes.allShipments.relativePath} />
      <Route element={<Navigate replace={true} to={customerAdminRoutes.allShipments.getUrl()} />} index={true} />
    </Routes>
  );
};

export default CustomerAdmin;
