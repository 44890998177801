import { QUERY_POLLING_INTERVAL } from '@zen/utils/constants';
import useAccount from '@zen/utils/hooks/useAccount';
import { useFocusAwarePolling } from '@zen/utils/hooks/useFocusAwarePolling';

import { useGetTaskListCountersQuery } from '../graphql';
import type { SidebarCounters } from '../types';

interface UseAdminSidebarCountersResult {
  adminCounters: SidebarCounters;
  adminCountersRefetch: () => void;
}

const useAdminSidebarCounters = (): UseAdminSidebarCountersResult => {
  const {
    userProfile: { isAdmin }
  } = useAccount();

  const shouldSkipQueryCall: boolean = !isAdmin;

  const { data, refetch } = useFocusAwarePolling(useGetTaskListCountersQuery, {
    fetchPolicy: 'no-cache',
    pollInterval: QUERY_POLLING_INTERVAL,
    skip: shouldSkipQueryCall,
    variables: { taskDetailsFiltersInput: { onlyCurrentUsersTasks: true } }
  });

  return {
    adminCounters: {
      taskListCount: data?.taskListCount?.activeTaskCount || 0,
      allTaskListCount: data?.taskListCount?.allOpenTaskCount || 0,
      unassignedTaskListCount: data?.taskListCount?.allUnassignedTaskCount || 0
    },
    adminCountersRefetch: refetch
  };
};

export default useAdminSidebarCounters;
