import type { FC } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import type { CargoModeEnum, ModeOfTransport } from '@zen/Booking';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button, TextLink } from '@zen/DesignSystem';
import { useExportRateCard } from '@zen/RateCards/LegacyRateCards/ExportRateCard';
import type { RateCardFilters } from '@zen/RateCards/LegacyRateCards/RateCardDetails/Filters/types';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { stringifyQueryParams } from '@zen/utils/QueryParams';
import type { Optional } from '@zen/utils/typescript';

import { useBookingRateCardQuery } from '../graphql';
import { usePriceCalculation } from '../PriceCalculationSection/hooks';
import { EmptyState, RateCardError, RateCardLoader } from './components';
import type { BookingRateCardType } from './types';

interface Props {
  cargoType: CargoModeEnum;
  destinationPort: Optional<string>;
  editable?: boolean;
  isBookingConfirmed: boolean;
  modeOfTransport: ModeOfTransport;
  onCreateAccrual?: (rateCardId?: string, params?: string) => void;
  originPort: Optional<string>;
  zencargoReference: string;
}

const BookingRateCard: FC<Props> = (props) => {
  const {
    cargoType,
    destinationPort,
    editable = true,
    isBookingConfirmed,
    modeOfTransport,
    onCreateAccrual,
    originPort,
    zencargoReference
  } = props;
  const { accountUuid: accountId } = useAccount();
  const role = useRole();
  const { date } = usePriceCalculation(zencargoReference);
  const [exportRateCard] = useExportRateCard();
  const canCreateAccrual = role === Role.ADMIN;

  const hasSailingSchedule: boolean = !!originPort && !!destinationPort;
  const shouldSkipRateCardQuery: boolean = !hasSailingSchedule && !date;
  const { data, loading, error } = useBookingRateCardQuery({
    variables: { cargoType, modeOfTransport, accountId, originPort, destinationPort, validOn: date },
    skip: shouldSkipRateCardQuery
  });

  const rateCardData: Optional<BookingRateCardType> = date ? data?.legacyGetRateCards?.nodes?.[0] : null;

  return (
    <QueryHandler
      data={rateCardData}
      error={!!error}
      errorComponent={<RateCardError />}
      isLoading={loading}
      loadingComponent={<RateCardLoader />}
      noResults={
        <EmptyState
          editable={editable}
          hasPriceCalculationPoint={!!date}
          hasSailingSchedule={hasSailingSchedule}
          isBookingConfirmed={isBookingConfirmed}
        />
      }
    >
      {({ rateCardId, name }: BookingRateCardType) => {
        const filters: RateCardFilters = { destinationPorts: [destinationPort || ''], originPorts: [originPort || ''] };
        const params: string = stringifyQueryParams({ filters });
        const linkToRateCard: string = `${rateCardRoutes.details.getUrl(rateCardId)}${params}`;
        const handleExport = () => {
          exportRateCard(rateCardId, accountId);
        };

        return (
          <div className="flex items-center justify-between">
            <TextLink isExternal={true} linkTo={linkToRateCard}>
              {name}
            </TextLink>
            <div className="flex space-x-2">
              {canCreateAccrual && (
                <Button
                  onClick={() => {
                    onCreateAccrual?.(rateCardId, params);
                  }}
                  variant="secondary"
                >
                  Create V&A
                </Button>
              )}
              <Button onClick={handleExport} variant="secondary">
                Export rate card
              </Button>
            </div>
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default BookingRateCard;
