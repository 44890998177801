import type { FC } from 'react';

import { FormInput } from '@zen/Components';
import { getFieldName } from '@zen/utils/forms';

import type { BaseFormFieldsProps } from '../types';

const LegalInformationFields: FC<BaseFormFieldsProps> = ({ fieldPrefix }) => {
  return (
    <div className="grid grid-cols-8 gap-x-8 xl:gap-x-6">
      <FormInput className="col-span-4" label="Registered name" name={getFieldName('registeredName', fieldPrefix)} />
      <FormInput
        className="col-span-4"
        isOptional={true}
        label="Company registration number"
        name={getFieldName('companyRegistrationNumber', fieldPrefix)}
      />
      <FormInput
        className="col-span-4"
        isOptional={true}
        label="VAT identification number"
        name={getFieldName('vatIdentificationNumber', fieldPrefix)}
      />
      <FormInput className="col-span-4" isOptional={true} label="EORI number" name={getFieldName('eoriNumber', fieldPrefix)} />
      {/* <hr className="col-span-8 border-grey-lighter" />
      <Headline className="col-span-4 my-4" level={4}>
        Registered address
      </Headline>
      <AddressFields fieldPrefix="registeredAddress" /> */}
    </div>
  );
};

export default LegalInformationFields;
