import { type FC, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { Button, Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useRemoveJourneyScheduleMutation } from './graphql';

interface Props {
  isBookingConfirmed: boolean;
}

const RemoveScheduleButton: FC<Props> = ({ isBookingConfirmed }) => {
  const { addError, addSuccess } = useNotification();
  const { cargoIds, zencargoReference } = useCargoOverviewContext();
  const [isConfirmationDialogVisible, setIsConfirmationDialogVisible] = useState<boolean>(false);
  const [removeSchedule, { loading }] = useRemoveJourneyScheduleMutation();

  const label: string = isBookingConfirmed ? 'Cancel booking' : 'Remove schedule';
  const message: string = `This will remove the ${
    isBookingConfirmed ? 'booked' : 'planned'
  } status and dates from all legs managed by the same carrier. All vehicle details will also be deleted.`;

  const handleConfirm = async (): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        removeSchedule({
          awaitRefetchQueries: true,
          refetchQueries: ['cargoOverview'],
          variables: {
            input: {
              cargoIds,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        const successMessage: string = isBookingConfirmed ? 'Booking has been cancelled.' : 'Schedule has been removed.';

        addSuccess(successMessage);
      }
    });
  };

  return (
    <>
      <Button iconLeft="zicon-close" onClick={() => setIsConfirmationDialogVisible(true)} size="compact" variant="secondary">
        {label}
      </Button>
      <Dialog
        cancelLabel="Back"
        confirmLabel={label}
        header={label}
        isLoading={loading}
        isOpen={isConfirmationDialogVisible}
        message={message}
        onClose={() => setIsConfirmationDialogVisible(false)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default RemoveScheduleButton;
