import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ArchiveAccountLocationLinkMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveAccountLocationLinkInput;
}>;

export type ArchiveAccountLocationLinkMutationResult = { __typename?: 'Mutation' } & {
  archiveAccountLocationLink?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveAccountLocationLinkPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path'>>>;
    }
  >;
};

export const ArchiveAccountLocationLinkDocument = /* #__PURE__ */ gql`
  mutation archiveAccountLocationLink($input: ArchiveAccountLocationLinkInput!) {
    archiveAccountLocationLink(input: $input) {
      errors {
        path
      }
    }
  }
`;

/**
 * __useArchiveAccountLocationLinkMutation__
 *
 * To run a mutation, you first call `useArchiveAccountLocationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAccountLocationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAccountLocationLinkMutation, { data, loading, error }] = useArchiveAccountLocationLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAccountLocationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveAccountLocationLinkMutationResult, ArchiveAccountLocationLinkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ArchiveAccountLocationLinkMutationResult, ArchiveAccountLocationLinkMutationVariables>(
    ArchiveAccountLocationLinkDocument,
    options
  );
}
export type ArchiveAccountLocationLinkMutationHookResult = ReturnType<typeof useArchiveAccountLocationLinkMutation>;
