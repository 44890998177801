import type { Stakeholder, Subscriber, SubscriptionStakeholder } from './types';

export function isSubscriberType(user: SubscriptionStakeholder): user is Subscriber {
  return (<Subscriber>user).canUnsubscribe !== undefined;
}

export const filterStakeholders = (stakeholders: Stakeholder[], query: string): Stakeholder[] => {
  const escapeRegExp = (text: string): string => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  return stakeholders.filter(({ contactDetails }: Stakeholder) => {
    if (!contactDetails) {
      return false;
    }

    const { firstName, lastName } = contactDetails;

    const queryRegex = new RegExp(escapeRegExp(query), 'gi');

    return queryRegex.test(`${firstName} ${lastName}`);
  });
};
