import cx from 'classnames';
import type { FC } from 'react';

import { BookingStageEnum } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import CollapsibleElement from '@zen/Components/CollapsibleElement';
import type { BusinessHours } from '@zen/Networks';
import type { Nullable, Optional } from '@zen/utils/typescript';

import {
  type JourneyShippingMilestone,
  JourneyShippingMilestoneNameEnum,
  type JourneyStopActionEnum,
  type JourneyStopLocationTypeEnum
} from '../../types';
import CancelRoadLegButton from '../CancelRoadLegButton/CancelRoadLegButton';
import ConfirmRoadLegButton from '../ConfirmRoadLegButton/ConfirmRoadLegButton';
import DateTypePill from '../DateTypePill';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';
import MilestoneDates from '../MilestoneDates';
import { getDatesTooltipLabel } from '../MilestoneDatesDialog/helpers';
import MilestoneDelays from '../MilestoneDelays';
import MilestoneName from '../MilestoneName';
import PathIndicator from '../PathIndicator';
import { areRoadLegButtonsVisible } from './helpers';

interface Props {
  isCurrent: boolean;
  isLast: boolean;
  locationBusinessHours: BusinessHours;
  locationType: JourneyStopLocationTypeEnum;
  milestone: JourneyShippingMilestone;
  stopAction: JourneyStopActionEnum;
  timeZone?: Nullable<string>;
}

const Milestone: FC<Props> = ({ isCurrent, isLast, locationBusinessHours, locationType, milestone, stopAction, timeZone }) => {
  const { carriageLeg, completedMilestoneCount, isLastCompletedMilestone, modeOfTransport, showCompletedMilestones } =
    useJourneyContext();
  const { bookingStage } = useCargoOverviewContext();

  const { canManageDatesCurrently, canManageDelays, completed, dateType, delayInDays, delays, id, name, planned, requestedBy } =
    milestone;

  const hasPlannedDate: boolean = !!planned?.startDateTime?.date;
  const isBookingRequested: boolean = bookingStage === BookingStageEnum.BOOKING_REQUESTED;

  const roadLegActionButtonsVisible: boolean = areRoadLegButtonsVisible({
    canManagePlannedDates: !!carriageLeg?.canManagePlannedDates,
    isMilestoneCompleted: completed,
    milestoneName: name,
    modeOfTransport,
    stopAction
  });

  const classNames: string = cx(
    {
      'pb-2 mb-0.5': isLast,
      'pb-4': !isLast
    },
    'flex items-start group/milestone'
  );

  const isMilestoneVisible = (): boolean => {
    if (completedMilestoneCount === 1 && name === JourneyShippingMilestoneNameEnum.GATE_OUT_EMPTY) {
      return true;
    }

    if (completed && !isLastCompletedMilestone(id) && !showCompletedMilestones) {
      return false;
    }

    return true;
  };

  const isVisible: boolean = isMilestoneVisible();

  const shouldDisableConfirmButton: boolean =
    (canManageDatesCurrently?.value === false && !!canManageDatesCurrently.reason) || isBookingRequested;
  const shouldDisplayMilestoneDelays: boolean = delayInDays !== 0;
  const canManageDatesCurrentlyReason: Optional<string> = isBookingRequested
    ? 'Ensure this booking is approved before confirming it'
    : getDatesTooltipLabel(canManageDatesCurrently?.reason, stopAction);

  return (
    <CollapsibleElement isOpened={isVisible}>
      <div className={classNames}>
        <div className="flex justify-end w-48 -mt-1.5">
          <MilestoneDates
            locationBusinessHours={locationBusinessHours}
            milestone={milestone}
            stopAction={stopAction}
            timeZone={timeZone}
          />
        </div>
        <PathIndicator isCompleted={completed} isCurrent={isCurrent} pathIndicatorVisible={isVisible} />
        <div className="flex-1">
          <div className="flex">
            <MilestoneName
              isMilestoneCompleted={completed}
              modeOfTransport={modeOfTransport}
              name={name}
              stopAction={stopAction}
            />
            {dateType && !completed && (
              <DateTypePill
                className="ml-auto"
                dateType={dateType}
                locationType={locationType}
                modeOfTransport={modeOfTransport}
                requestedBy={requestedBy}
              />
            )}
          </div>
          {shouldDisplayMilestoneDelays && <MilestoneDelays canManageDelays={canManageDelays} delays={delays} />}
          {roadLegActionButtonsVisible && (
            <div className="flex mt-3 space-x-2">
              {!hasPlannedDate && (
                <ConfirmRoadLegButton isDisabled={shouldDisableConfirmButton} tooltipContent={canManageDatesCurrentlyReason} />
              )}
              {hasPlannedDate && <CancelRoadLegButton />}
            </div>
          )}
        </div>
      </div>
    </CollapsibleElement>
  );
};

export default Milestone;
