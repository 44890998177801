import type { FC } from 'react';

import { useGetNetworkLocationsQuery } from '@zen/Accounts/LegacyAccountDetails/graphql';
import { Form, FormButtons, FormInput, FormRadioGroup } from '@zen/Components';
import { Body, Headline } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performFormMutation } from '@zen/utils/performMutation';

import FormEnterpriseConnectionSelect from '../FormEnterpriseConnectionSelect';
import FormLocationSelect from '../FormLocationSelect';
import GenerateKeyPanel from '../GenerateKeyPanel';
import { useEditSsoConfigurationMutation } from '../graphql';
import { provisionTypeOptions } from '../helpers';
import type { CreateSsoConfigurationInput, ProvisionMethod, SSOKeyFormValues } from '../types';
import { validationSchema } from './ssoKeyForm.validation';

interface Props {
  accountId: string;
  accountName: string;
  initialValues: SSOKeyFormValues;
  isSSOEnabled: boolean;
}

interface HandleUpdateSsoConfiguration {
  connectionName: string;
  defaultLocation: string;
  provisionMethod: ProvisionMethod;
}

const SSOKeyForm: FC<Props> = (props) => {
  const { initialValues, accountName, accountId, isSSOEnabled } = props;
  const [editSsoConfiguration] = useEditSsoConfigurationMutation({ refetchQueries: ['getAccountDetails'] });
  const { data } = useGetNetworkLocationsQuery({ variables: { accountId } });

  const { addSuccess } = useNotification();

  const handleUpdateSsoConfiguration = (values: HandleUpdateSsoConfiguration) => {
    const networkOrganisationId: string = data?.network?.myOrganisation?.networkOrganisationId || '';
    const { connectionName, defaultLocation, provisionMethod } = values;
    const newSsoConfig: CreateSsoConfigurationInput = {
      accountId,
      connectionName,
      provisionMethod,
      defaultLocation,
      networkOrganisationId
    };

    return performFormMutation({
      mutationFn: () => editSsoConfiguration({ variables: { input: newSsoConfig } }),
      onSuccess: () => addSuccess('SSO Configuration updated.')
    });
  };

  return (
    <Form
      buttonText="Enable SSO"
      enableReinitialize={true}
      formButtons={() => null}
      initialValues={initialValues}
      onSubmit={handleUpdateSsoConfiguration}
      validationSchema={validationSchema}
    >
      {({ values, isSubmitting }) => {
        const { provisionMethod } = values;
        const formIsValid: boolean = validationSchema.isValidSync(values);
        const isScimProvisioned: boolean = provisionMethod === 'SCIM';

        const buttonText: string = isSSOEnabled ? 'Update default location' : 'Enable SSO';
        const hasProvisionMethod: boolean = provisionMethod !== null;

        return (
          <>
            <Headline level={4}>Enable SSO for {accountName} </Headline>
            <Body className="max-w-xl my-2 text-grey-base">Select a connection to connect Zencargo with {accountName}</Body>
            <div className="grid grid-cols-8 col-span-12 gap-8 xl:gap-6 xl:grid-cols-12 gap-y-0">
              <div className="col-span-4">
                <FormRadioGroup label="SSO type" name="provisionMethod" options={provisionTypeOptions} radioAlignment="column" />
                {hasProvisionMethod && (
                  <>
                    <FormEnterpriseConnectionSelect isDisabled={isSSOEnabled} />
                    <FormInput disabled={true} label="Allowed email address domains" name="domains" />
                    <FormLocationSelect
                      accountId={accountId}
                      helperText="Users will be assigned to this location when they first log in. Users can always be reassigned."
                      label="Location"
                      name="defaultLocation"
                    />
                  </>
                )}
              </div>
            </div>
            <FormButtons isSubmitting={isSubmitting} text={buttonText} />
            {isScimProvisioned && <GenerateKeyPanel accountId={accountId} isKeyCreationEnabled={formIsValid && isSSOEnabled} />}
          </>
        );
      }}
    </Form>
  );
};

export default SSOKeyForm;
export type { Props as SSOKeyFormProps };
