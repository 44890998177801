import { Resource } from '@opentelemetry/resources';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';

export const createProvider = ({
  deploymentEnvironment,
  serviceName,
  serviceVersion
}: {
  deploymentEnvironment: string;
  serviceName: string;
  serviceVersion: string;
}): WebTracerProvider => {
  return new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: deploymentEnvironment,
      [SemanticResourceAttributes.SERVICE_NAME]: serviceName,
      [SemanticResourceAttributes.SERVICE_VERSION]: serviceVersion
    })
  });
};
