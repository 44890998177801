import { ChangeEvent, FC, FocusEvent, forwardRef } from 'react';

import type { Modify } from '@zen/utils/typescript';

import Input, { InputProps } from '../Input';
import { isValidInput } from './helpers';

type Props = Modify<
  InputProps,
  {
    onChange: (time: string) => void;
    value: string;
  }
>;

const TimeInput: FC<Props> = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const { onChange, value } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    let inputValue = event.target.value;

    if (!isValidInput(inputValue)) {
      return;
    }

    if (!inputValue.includes(':') && value?.endsWith(':')) {
      inputValue = inputValue.length === 1 ? '' : inputValue.charAt(0);
    } else if (inputValue.length === 2 && !inputValue.includes(':')) {
      inputValue = `${inputValue}:`;
    }

    onChange(inputValue);
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const inputValue = event.target.value;

    if (inputValue.length === 4) {
      const [hours, minutes] = inputValue.split(':');
      const enhancedInputValue: string = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

      onChange(enhancedInputValue);
    }
  };

  return (
    <Input
      placeholder="hh:mm"
      {...props}
      ref={ref}
      className="w-[4.5rem]"
      data-component="time-input"
      data-testid="time-input"
      fullWidth={false}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  );
});

export type { Props as TimeInputProps };

export default TimeInput;
