const IS_AUTHENTICATED_KEY = 'loggedIn';
const ACCESS_TOKEN_KEY = 'token';

const authStore = () => {
  const setAccessToken = (accessToken: string) => {
    const customEvent = new CustomEvent('tokenset', { detail: accessToken });

    window.dispatchEvent(customEvent);
    window.localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  };
  const getAccessToken = () => window.localStorage.getItem(ACCESS_TOKEN_KEY);
  const unsetAccessToken = () => window.localStorage.removeItem(ACCESS_TOKEN_KEY);

  const setLoggedIn = () => window.localStorage.setItem(IS_AUTHENTICATED_KEY, 'true');
  const getLoggedIn = () => window.localStorage.getItem(IS_AUTHENTICATED_KEY);
  const unsetLoggedIn = () => window.localStorage.removeItem(IS_AUTHENTICATED_KEY);

  const clearSensitiveData = () => {
    window.sessionStorage.removeItem('orderBasketList');
  };

  const removeAccountInfo = () => {
    window.localStorage.removeItem('accountName');
    window.localStorage.removeItem('isAdmin');
    window.localStorage.removeItem('isDivision');
    window.localStorage.removeItem('userId');
    window.localStorage.removeItem('profileLevel');
    window.localStorage.removeItem('profileRoles');
    window.localStorage.removeItem('role');
  };

  return {
    clearSensitiveData,
    setLoggedIn,
    getLoggedIn,
    unsetLoggedIn,
    removeAccountInfo,
    setAccessToken,
    getAccessToken,
    unsetAccessToken
  };
};

export default authStore;
