import type { FC } from 'react';

import Page from '@zen/Components/Page';
import { rateCardRoutes } from '@zen/Routes';
import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useCreateRateCardMutation } from '../graphql';
import type { CreateRateCardMutationResult } from '../graphql/createRateCard.generated';
import { useRateCardDetails } from '../graphql/hooks';
import RateCardForm from '../RateCardForm';
import { prepareRateCardInput } from '../RateCardForm/helpers';
import type { RateCardReducerState } from '../RateCardForm/reducer/types';
import { getInitialValues } from './helpers';
import type { LegacyCreateRateCardInput } from './types';

const NewRateCard: FC = () => {
  const [createRateCard] = useCreateRateCardMutation();
  const { addError, addSuccess } = useNotification();
  const { userProfile, accountUuid } = useAccount();
  const { registeredCompanyName } = userProfile;
  const { navigate } = useNavigationHistory();
  const defaultBackUrl: string = rateCardRoutes.index.getUrl();
  const { data } = useRateCardDetails();

  const handleSubmit = async (values: RateCardReducerState): Promise<void> => {
    const rateCardInput: LegacyCreateRateCardInput = {
      ...prepareRateCardInput(values, registeredCompanyName),
      createdBy: userProfile.uuid,
      customerDivisionId: accountUuid
    };

    const result: IOkOrErrorResult<CreateRateCardMutationResult> = await performMutation({
      mutationFn: () => createRateCard({ variables: { input: rateCardInput } }),
      onError: () => addError()
    });

    if (result.ok?.legacyCreateRateCard?.rateCardId) {
      const id: string = result.ok.legacyCreateRateCard?.rateCardId;

      handleSuccess(id);
    }
  };

  const handleSuccess = (id: string): void => {
    const successMessage: string = data?.legacyGetRateCard?.rateCardId
      ? 'Rate card has been successfully cloned'
      : 'Rate card has been successfully created';

    addSuccess(successMessage);
    navigate(rateCardRoutes.details.getUrl(id));
  };

  return (
    <Page defaultBackUrl={defaultBackUrl} title="New rate card">
      <RateCardForm initialValues={getInitialValues(data?.legacyGetRateCard)} onSubmit={handleSubmit} />
    </Page>
  );
};

export default NewRateCard;
