import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetCommodityCodesQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  textContains?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
}>;

export type GetCommodityCodesQueryResult = { __typename?: 'Query' } & {
  commodityCodes: { __typename?: 'CommodityCodesConnection' } & Pick<GraphQLTypes.CommodityCodesConnection, 'totalCount'> & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<{ __typename?: 'CommodityCodes' } & Pick<GraphQLTypes.CommodityCodes, 'id' | 'code' | 'description'>>
        >
      >;
      pageInfo: { __typename: 'PageInfo' } & Pick<
        GraphQLTypes.PageInfo,
        'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
      >;
    };
};

export const GetCommodityCodesDocument = /* #__PURE__ */ gql`
  query getCommodityCodes($after: String, $before: String, $first: Int, $last: Int, $textContains: String, $order: SortInput) {
    commodityCodes(after: $after, before: $before, first: $first, last: $last, textContains: $textContains, order: $order) {
      nodes {
        id
        code
        description
      }
      totalCount
      pageInfo {
        ...PageInfo
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetCommodityCodesQuery__
 *
 * To run a query within a React component, call `useGetCommodityCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommodityCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommodityCodesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      textContains: // value for 'textContains'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetCommodityCodesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCommodityCodesQueryResult, GetCommodityCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetCommodityCodesQueryResult, GetCommodityCodesQueryVariables>(GetCommodityCodesDocument, options);
}
export function useGetCommodityCodesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommodityCodesQueryResult, GetCommodityCodesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetCommodityCodesQueryResult, GetCommodityCodesQueryVariables>(GetCommodityCodesDocument, options);
}
export type GetCommodityCodesQueryHookResult = ReturnType<typeof useGetCommodityCodesQuery>;
export type GetCommodityCodesLazyQueryHookResult = ReturnType<typeof useGetCommodityCodesLazyQuery>;
