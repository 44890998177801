import type { FC } from 'react';

import { Table, TableColumn } from '@zen/DesignSystem';
import { createRateCardTableId } from '@zen/RateCards/LegacyRateCards/helpers';
import type { PortChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';

import { getPortChargeTableColumns } from './portChargeTableConfig';

interface Props {
  editable: boolean;
  onAdd?: () => void;
  onDelete?: (index: number) => void;
  onDisable?: (id: string, value: boolean) => void;
  onRemoveCustomPrice?: (index: number) => void;
  onUpdate?: (index: number, values: PortChargePayload) => void;
  portCharges: PortChargePayload[];
  title: string;
  type: 'origin' | 'destination';
}

const PortChargeTable: FC<Props> = ({
  editable,
  portCharges,
  onDisable,
  title,
  onAdd,
  onUpdate,
  type,
  onDelete,
  onRemoveCustomPrice
}) => {
  const columns: TableColumn<PortChargePayload>[] = getPortChargeTableColumns(
    type,
    { onDisable, onUpdate, onDelete, onRemoveCustomPrice },
    editable
  );

  return (
    <Table
      actions={editable && <ChargeTableActions onAdd={onAdd} />}
      columns={columns}
      data={portCharges}
      tableId={createRateCardTableId(columns, 'port-charges')}
      title={title}
      totalCountConfig={{ totalCount: portCharges.length, entityName: 'item' }}
    />
  );
};

export default PortChargeTable;
