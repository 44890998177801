import type { IncotermsValue } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import { DESTINATION_INCOTERMS, ORIGIN_INCOTERMS } from '../helpers';
import {
  Action,
  ActionType,
  ChargeFieldName,
  ChargeInput,
  ChargePayloadType,
  FreightChargePayload,
  HaulageChargePayload,
  PortChargePayload,
  RateCardReducerState,
  UpdateRateCardInput
} from './types';

export const rateCardReducer = (state: RateCardReducerState, action: Action) => {
  switch (action.type) {
    case ActionType.UPDATE_RATE_CARD_INPUT: {
      return getStateWithUpdatedRateCardInput(state, action);
    }
    case ActionType.ADD_FREIGHT_CHARGE: {
      return getStateWithNewCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.ADD_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithNewCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.ADD_DESTINATION_HAULAGE_CHARGE: {
      return getStateWithNewCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.ADD_CUSTOM_ORIGIN_CHARGE: {
      return getStateWithNewCustomPortCharges(state, 'originCharges', action.payload);
    }
    case ActionType.ADD_CUSTOM_DESTINATION_CHARGE: {
      return getStateWithNewCustomPortCharges(state, 'destinationCharges', action.payload);
    }
    case ActionType.UPDATE_FREIGHT_CHARGE: {
      return getStateWithUpdatedCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.UPDATE_DESTINATION_HALUAGE_CHARGE: {
      return getStateWithUpdatedCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithUpdatedCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.DELETE_FREIGHT_CHARGE: {
      return getStateWithoutCharge(state, 'freightCharges', action.payload);
    }
    case ActionType.DELETE_DESTINATION_HALUAGE_CHARGE: {
      return getStateWithoutCharge(state, 'destinationHaulageCharges', action.payload);
    }
    case ActionType.DELETE_ORIGIN_HAULAGE_CHARGE: {
      return getStateWithoutCharge(state, 'originHaulageCharges', action.payload);
    }
    case ActionType.DELETE_CUSTOM_ORIGIN_CHARGE: {
      return getStateWithoutCharge(state, 'originCharges', action.payload);
    }
    case ActionType.DELETE_CUSTOM_DESTINATION_CHARGE: {
      return getStateWithoutCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.ADD_DESTINATION_CHARGES: {
      return getStateWithNewPortCharges(state, 'destinationCharges', action.payload);
    }
    case ActionType.ADD_ORIGIN_CHARGES: {
      return getStateWithNewPortCharges(state, 'originCharges', action.payload);
    }
    case ActionType.UPDATE_ORIGIN_PORT_CHARGE: {
      return getStateWithUpdatedPortCharge(state, 'originCharges', action.payload);
    }
    case ActionType.UPDATE_DESTINATION_PORT_CHARGE: {
      return getStateWithUpdatedPortCharge(state, 'destinationCharges', action.payload);
    }
    case ActionType.DISABLE_ORIGIN_CHARGE: {
      return getStateWithDisabledPortCharges(state, 'originCharges', action.payload.id);
    }
    case ActionType.ENABLE_DESTINATION_CHARGE: {
      return getStateWithEnabledPortCharges(state, 'destinationCharges', action.payload.id);
    }
    case ActionType.ENABLE_ORIGIN_CHARGE: {
      return getStateWithEnabledPortCharges(state, 'originCharges', action.payload.id);
    }
    case ActionType.DISABLE_DESTINATION_CHARGE: {
      return getStateWithDisabledPortCharges(state, 'destinationCharges', action.payload.id);
    }
    case ActionType.REMOVE_CUSTOM_ORIGIN_PORT_CHARGE_PRICE: {
      return getStateWithoutCustomPortChargePrice(state, 'originCharges', action.payload);
    }
    case ActionType.REMOVE_CUSTOM_DESTINATION_PORT_CHARGE_PRICE: {
      return getStateWithoutCustomPortChargePrice(state, 'destinationCharges', action.payload);
    }
    case ActionType.REINITIALIZE: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

const getStateWithUpdatedRateCardInput = (state: RateCardReducerState, action: UpdateRateCardInput): RateCardReducerState => {
  return {
    ...state,
    ...action.payload
  };
};

const getStateWithNewCharge = <T extends ChargeFieldName>(
  state: RateCardReducerState,
  chargeField: ChargeFieldName,
  payload: ChargePayloadType[T]
): RateCardReducerState => {
  const stateWithNewCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getChargesWithNewCharge(state[chargeField], payload)
  };

  return getStateWithUpdatedCharges(stateWithNewCharge);
};

const getStateWithNewPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  payload: PortChargePayload[]
): RateCardReducerState => {
  const newPortCharges: PortChargePayload[] = mergePortCharges(state[chargeField], payload, mergePortCharge);

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};

const getStateWithNewCustomPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  payload: PortChargePayload
): RateCardReducerState => {
  const newPortCharges: PortChargePayload[] = mergePortCharges(state[chargeField], [payload], mergeCustomPortCharge);

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};

const getStateWithDisabledPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  id: string
): RateCardReducerState => {
  const newPortCharges: PortChargePayload[] = state[chargeField].map((portCharge: PortChargePayload) => {
    return id === portCharge.centralPortChargeId ? markPortChargeDisabled(portCharge) : portCharge;
  });

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};

const getStateWithEnabledPortCharges = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  id: string
): RateCardReducerState => {
  const newPortCharges: PortChargePayload[] = state[chargeField].map((portCharge: PortChargePayload) => {
    return id === portCharge.centralPortChargeId ? markPortChargeEnabled(portCharge) : portCharge;
  });

  return {
    ...state,
    [chargeField]: newPortCharges
  };
};

const getStateWithUpdatedCharge = <T extends ChargeFieldName>(
  state: RateCardReducerState,
  chargeField: ChargeFieldName,
  payload: { atIndex: number; value: ChargePayloadType[T] }
): RateCardReducerState => {
  const { atIndex, value } = payload;

  const stateWithUpdatedCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getUpdatedCharges(state[chargeField], atIndex, value)
  };

  return getStateWithUpdatedCharges(stateWithUpdatedCharge);
};

const getStateWithUpdatedPortCharge = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  payload: { atIndex: number; value: PortChargePayload }
): RateCardReducerState => {
  const { atIndex, value } = payload;

  const stateWithUpdatedCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getUpdatedPortCharges(state[chargeField], atIndex, value)
  };

  return getStateWithUpdatedCharges(stateWithUpdatedCharge);
};

const getStateWithoutCharge = (
  state: RateCardReducerState,
  chargeField: ChargeFieldName,
  payload: { atIndex: number }
): RateCardReducerState => {
  const { atIndex } = payload;

  const stateWithoutCharge: RateCardReducerState = {
    ...state,
    [chargeField]: getChargesWithoutChargeAtIndex(state[chargeField], atIndex)
  };

  return getStateWithUpdatedCharges(stateWithoutCharge);
};

const getStateWithoutCustomPortChargePrice = (
  state: RateCardReducerState,
  chargeField: 'originCharges' | 'destinationCharges',
  payload: { atIndex: number }
): RateCardReducerState => {
  const { atIndex } = payload;

  return {
    ...state,
    [chargeField]: getChargesWithoutCustomPriceAtIndex(state[chargeField], atIndex)
  };
};

const getChargesWithoutChargeAtIndex = (charges: Optional<ChargeInput[]>, atIndex: number): ChargeInput[] => {
  return getChargesArray(charges).filter((_, index: number) => index !== atIndex);
};

const getChargesWithoutCustomPriceAtIndex = (charges: Optional<PortChargePayload[]>, atIndex: number): ChargeInput[] => {
  const chargesArray = getChargesArray(charges) as PortChargePayload[];

  return chargesArray.map((charge: PortChargePayload, index: number) => {
    if (index === atIndex) {
      return getPortChargeWithoutCustomPrice(charge);
    }

    return charge;
  });
};

const getUpdatedCharges = (charges: Optional<ChargeInput[]>, atIndex: number, value: ChargeInput): ChargeInput[] => {
  const chargeArray = getChargesArray(charges);

  chargeArray[atIndex] = value;

  return chargeArray;
};

const getPortChargeWithoutCustomPrice = (charge: PortChargePayload): PortChargePayload => {
  const { customChargeValue, customCurrency, ...rest } = charge;

  return rest;
};

const getUpdatedPortCharges = (
  charges: Optional<PortChargePayload[]>,
  atIndex: number,
  value: PortChargePayload
): PortChargePayload[] => {
  const chargeArray = getChargesArray(charges) as PortChargePayload[];
  const portChargeToUpdate = chargeArray[atIndex];

  chargeArray[atIndex] = mergeCustomPortCharge(portChargeToUpdate, value);

  return chargeArray;
};

const getChargesWithNewCharge = (charges: Optional<ChargeInput[]>, charge: ChargeInput): ChargeInput[] => {
  return [...getChargesArray(charges), charge];
};

const getChargesArray = (charges: Optional<ChargeInput[]>): ChargeInput[] => {
  return [...(charges || [])];
};

const getStateWithUpdatedCharges = (state: RateCardReducerState): RateCardReducerState => {
  let originIncoterms: IncotermsValue[] = [];
  let destinationIncoterms: IncotermsValue[] = [];
  const originPorts: string[] = [];
  const destinationPorts: string[] = [];

  state.freightCharges.forEach((freightCharge: FreightChargePayload) => {
    originPorts.push(freightCharge.originPort.unlocode);
    destinationPorts.push(freightCharge.destinationPort.unlocode);
    originIncoterms = [...originIncoterms, ...getOriginPortIncoterms(freightCharge)];
    destinationIncoterms = [...destinationIncoterms, ...getDestinationPortIncoterms(freightCharge)];
  });

  return {
    ...state,
    originHaulageCharges: getUpdatedHaulageCharges(state.originHaulageCharges, originIncoterms, originPorts),
    destinationHaulageCharges: getUpdatedHaulageCharges(state.destinationHaulageCharges, destinationIncoterms, destinationPorts),
    originCharges: state.originCharges.filter((originCharge: PortChargePayload) => filterByPort(originCharge, originPorts)),
    destinationCharges: state.destinationCharges.filter((destinationCharge: PortChargePayload) =>
      filterByPort(destinationCharge, destinationPorts)
    )
  };
};

const getOriginPortIncoterms = (freightCharge: FreightChargePayload): IncotermsValue[] =>
  freightCharge.incoterms.filter((incoterm: IncotermsValue) => ORIGIN_INCOTERMS.includes(incoterm));

const getDestinationPortIncoterms = (freightCharge: FreightChargePayload): IncotermsValue[] =>
  freightCharge.incoterms.filter((incoterm: IncotermsValue) => DESTINATION_INCOTERMS.includes(incoterm));

const getUpdatedHaulageCharges = (
  haulageCharges: HaulageChargePayload[],
  incotermValues: IncotermsValue[],
  ports: string[]
): HaulageChargePayload[] => {
  const incoterms: IncotermsValue[] = [...new Set(incotermValues)];

  return haulageCharges
    .filter((haulageCharge: HaulageChargePayload) => filterByPort(haulageCharge, ports))
    .map((haulageCharge: HaulageChargePayload) => ({ ...haulageCharge, incoterms }));
};

const filterByPort = (charge: { port: { unlocode: string } }, ports: string[]) => ports.includes(charge.port.unlocode);

const markPortChargeDisabled = (portCharge: PortChargePayload): PortChargePayload => {
  return { ...portCharge, disabled: true };
};

const markPortChargeEnabled = (portCharge: PortChargePayload): PortChargePayload => {
  return { ...portCharge, disabled: false };
};

const mergePortCharges = (
  previousPortCharges: PortChargePayload[],
  newPortCharges: PortChargePayload[],
  mergeExistingPortCharge: (previousPortCharge: PortChargePayload, nextPortCharge: PortChargePayload) => PortChargePayload
) => {
  const newCharges: PortChargePayload[] = [];
  const updatedPreviousCharges: PortChargePayload[] = [...previousPortCharges];

  newPortCharges.forEach((newPortCharge: PortChargePayload) => {
    const previousPortChargeIndex = updatedPreviousCharges.findIndex((previousPortCharge) =>
      isPortChargeEqual(previousPortCharge, newPortCharge)
    );
    const hasMatchingPreviousPortCharge = previousPortChargeIndex >= 0;

    if (hasMatchingPreviousPortCharge) {
      const existingPortCharge = updatedPreviousCharges[previousPortChargeIndex];

      updatedPreviousCharges[previousPortChargeIndex] = mergeExistingPortCharge(existingPortCharge, newPortCharge);
    } else {
      newCharges.push(newPortCharge);
    }
  });

  return [...updatedPreviousCharges, ...newCharges];
};

const isPortChargeEqual = (previousPortCharge: PortChargePayload, nextPortCharge: PortChargePayload): boolean => {
  const isSamePort = previousPortCharge.port.unlocode === nextPortCharge.port.unlocode;
  const isSameChargeType = previousPortCharge.chargeType.id === nextPortCharge.chargeType.id;

  return isSamePort && isSameChargeType;
};

const mergeCustomPortCharge = (previousPortCharge: PortChargePayload, customPortCharge: PortChargePayload): PortChargePayload => {
  return {
    ...previousPortCharge,
    incoterms: customPortCharge.incoterms,
    customChargeValue:
      customPortCharge.customChargeValue === previousPortCharge.chargeValue ? undefined : customPortCharge.customChargeValue,
    customCurrency: customPortCharge.customCurrency
  };
};

const mergePortCharge = (previousPortCharge: PortChargePayload, newPortCharge: PortChargePayload): PortChargePayload => {
  return {
    ...previousPortCharge,
    centralPortChargeId: previousPortCharge.centralPortChargeId || newPortCharge.centralPortChargeId,
    chargeValue: newPortCharge.chargeValue,
    currency: newPortCharge.currency,
    customChargeValue:
      previousPortCharge.customChargeValue === newPortCharge.chargeValue ? undefined : previousPortCharge.customChargeValue,
    customCurrency: previousPortCharge.customCurrency || newPortCharge.currency
  };
};
