import { type FC, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { Button, Modal } from '@zen/DesignSystem';
import { isCarriageArrivalStop, isCarriageDepartureStop } from '@zen/Journey/helpers';
import type { Terminal } from '@zen/Networks';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyStop } from '../../types';
import { journeyRefetchQueryList } from '../forms/helpers';
import PlannedScheduleForm from '../forms/PlannedScheduleForm';
import { createInitialValues } from '../forms/PlannedScheduleForm/helpers';
import type { PlannedScheduleValues } from '../forms/PlannedScheduleForm/types';
import { getStops } from '../JourneyDetails/helpers';
import { useAddJourneyRequestedScheduleMutation } from './graphql';
import { prepareLegs } from './helpers';

interface Props {
  arrivalMilestoneId: string;
  departureMilestoneId: string;
}

const PlannedScheduleButton: FC<Props> = ({ arrivalMilestoneId, departureMilestoneId }) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { addError, addSuccess } = useNotification();
  const { cargoIds, carriageLegs, modeOfTransport, zencargoReference } = useCargoOverviewContext();

  const [addSchedule] = useAddJourneyRequestedScheduleMutation();

  const stops: JourneyStop[] = getStops(carriageLegs);
  const departureTerminal: Optional<Terminal> = stops.find((stop) => isCarriageDepartureStop(stop.stopAction))?.terminal;
  const arrivalTerminal: Optional<Terminal> = stops.find((stop) => isCarriageArrivalStop(stop.stopAction))?.terminal;

  const handleClose = (): void => setIsModalVisible(false);

  const handleSubmit = async (values: PlannedScheduleValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        addSchedule({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              cargoIds,
              carrier: values.carrier,
              scheduledLegs: prepareLegs(values.stops, modeOfTransport),
              trackingNumber: values.trackingNumber,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Planned schedule has been added.');
        handleClose();
      }
    });
  };

  return (
    <>
      <Button iconLeft="zicon-calendar" onClick={() => setIsModalVisible(true)} size="compact" variant="secondary">
        Enter planned schedule
      </Button>
      <Modal
        closeOnClickAway={false}
        isOpen={isModalVisible}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Enter planned date"
        width="wider"
      >
        <PlannedScheduleForm
          arrivalMilestoneId={arrivalMilestoneId}
          departureMilestoneId={departureMilestoneId}
          initialValues={createInitialValues(departureTerminal, arrivalTerminal)}
          modeOfTransport={modeOfTransport}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default PlannedScheduleButton;
