import type { FC } from 'react';

import { useForm } from '@zen/Components';
import { Switch } from '@zen/DesignSystem';

import { getDefaultWorkingHours } from '../../../AccountForms/helpers';
import type { AccountFormData, DayOfWeek } from '../../../AccountForms/types';

interface Props {
  day: DayOfWeek;
}

const OpenStatusSwitch: FC<Props> = ({ day }) => {
  const { values, setFieldValue } = useForm<AccountFormData>();
  const hasOpeningHours: boolean = !!values.businessHours[day];
  const valuePath: string = `businessHours.${day}`;
  const { startTime, endTime } = getDefaultWorkingHours();

  const handleSwitch = (): void => {
    if (hasOpeningHours) {
      setFieldValue(valuePath, null);
    } else {
      setFieldValue(`${valuePath}.startTime`, startTime);
      setFieldValue(`${valuePath}.endTime`, endTime);
    }
  };

  return (
    <div className="flex items-center justify-center h-full">
      <Switch isChecked={hasOpeningHours} label={hasOpeningHours ? 'Open' : 'Closed'} onChange={handleSwitch} />
    </div>
  );
};

export default OpenStatusSwitch;
