import { useCallback, useMemo } from 'react';

import { checkPermission } from './authHelper';
import { useGlobalPermissionsContext } from './GlobalPermissionsContext';

const useGlobalPermissions = (): { check: (key: string) => boolean } => {
  // Temp solutions, globalPermission type should come from the autogenerated types from graphql schema
  const globalPermissions: any = useGlobalPermissionsContext();
  const check = useCallback(
    (key: string): boolean => {
      return checkPermission<any>(globalPermissions, key);
    },
    [globalPermissions]
  );

  return useMemo(() => ({ check, globalPermissions }), [globalPermissions, check]);
};

export default useGlobalPermissions;
