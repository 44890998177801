import { roleLabels } from '@zen/Auth/role';

import { Role } from '../types';

export function getPermittedRoleLabel(role: Role, showForwarderLabel: boolean): string {
  if (role === Role.ADMIN || showForwarderLabel) {
    return 'Forwarder';
  }

  return roleLabels[role];
}

export function getOrganisationName(organisationName: string, showZencargoOrganisation: boolean): string {
  if (!showZencargoOrganisation) {
    return organisationName;
  }

  return 'Zencargo';
}
