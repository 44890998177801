import { useEffect } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { Role } from '@zen/Networks';
import type { SortInput } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';
import { PaginatedQueryResult, usePagination } from '@zen/utils/hooks/pagination';
import useAccount from '@zen/utils/hooks/useAccount';

import { prepareFilterVariables } from '../components/Filters/helpers';
import type { OrderTableQueryResult, OrderTableQueryVariables } from '../graphql';
import { useOrderTableQuery } from '../graphql';
import type { OrderFilters, OrderTableView } from '../types';

const useOrdersTableView = (
  hasUserPreferences: boolean,
  order: SortInput
): PaginatedQueryResult<OrderTableQueryResult, OrderTableQueryVariables, OrderTableView> => {
  const createAccountFlagIsOn: boolean = useFeatureIsOn('create-account');
  const role = useRole();
  const { accountUuid: accountId } = useAccount();
  const { appliedFilters = {} } = useAppliedFilters<OrderFilters>();

  useEffect(() => {
    results.refetch();
  }, [hasUserPreferences]); // eslint-disable-line react-hooks/exhaustive-deps

  const filter = createAccountFlagIsOn && role !== Role.ADMIN ? { accountIds: [] } : { customerUuid: accountId };

  const results = usePagination<OrderTableQueryResult, OrderTableQueryVariables, OrderTableView>(
    useOrderTableQuery,
    'purchaseOrders',
    {
      ...prepareFilterVariables(appliedFilters),
      ...(accountId && { ...filter }),
      order
    },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  return results;
};

export default useOrdersTableView;
