import type { Option } from '@zen/DesignSystem';

import { requestedByLabelMapping } from '../../../helpers';
import { JourneyShippingMilestoneRequestedByEnum } from './types';

export const requestedByOptions: Option<JourneyShippingMilestoneRequestedByEnum>[] = [
  {
    value: JourneyShippingMilestoneRequestedByEnum.CUSTOMER,
    label: requestedByLabelMapping[JourneyShippingMilestoneRequestedByEnum.CUSTOMER]
  },
  {
    value: JourneyShippingMilestoneRequestedByEnum.CUSTOMERS_SUPPLIER,
    label: requestedByLabelMapping[JourneyShippingMilestoneRequestedByEnum.CUSTOMERS_SUPPLIER]
  },
  {
    value: JourneyShippingMilestoneRequestedByEnum.THIRD_PARTY_WAREHOUSE,
    label: requestedByLabelMapping[JourneyShippingMilestoneRequestedByEnum.THIRD_PARTY_WAREHOUSE]
  },
  {
    value: JourneyShippingMilestoneRequestedByEnum.ZENCARGO,
    label: requestedByLabelMapping[JourneyShippingMilestoneRequestedByEnum.ZENCARGO]
  }
];
