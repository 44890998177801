import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';
import { TaskPriority } from '@zen/Tasks/types';

interface Props {
  priority: TaskPriority;
}

type PillConfig = {
  className: string;
  text: string;
};

const PriorityPill: FC<Props> = ({ priority }) => {
  const priorityAppearance: Record<TaskPriority, PillConfig> = {
    [TaskPriority.minor]: { className: 'bg-azure-lighter', text: 'Minor' },
    [TaskPriority.low]: { className: 'bg-navy-light', text: 'Low' },
    [TaskPriority.medium]: { className: 'bg-orange-light', text: 'Medium' },
    [TaskPriority.high]: { className: 'bg-red-light', text: 'High' },
    [TaskPriority.critical]: { className: 'bg-red-base', text: 'Critical' }
  };

  const { className, text } = priorityAppearance[priority] || priorityAppearance[TaskPriority.medium];

  return (
    <Pill className={className} type="custom">
      {text}
    </Pill>
  );
};

export default PriorityPill;
