import { sortBy, uniqBy } from 'lodash';
import pDebounce from 'p-debounce';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { AirlineCarrier } from '../../../types';
import { useAirlineCarrierLazyQuery } from '../AirlineCarrierInlineField/graphql';
import { useAirlineCarriersLazyQuery } from './graphql';

type SearchableOptionsArguments = {
  selectedValue: Optional<string>;
};

export const useSearchableOptions = ({ selectedValue }: SearchableOptionsArguments) => {
  const [fetchAirlineCarriers, { data, loading, error }] = useAirlineCarriersLazyQuery();
  const [fetchAirlineCarrier, { data: carrierData }] = useAirlineCarrierLazyQuery();

  useEffect(() => {
    if (selectedValue) {
      fetchAirlineCarrier({
        variables: {
          id: selectedValue
        }
      });
    } else {
      fetchAirlineCarriers({
        variables: {
          query: ''
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (query: string): void => {
    if (query) {
      fetchAirlineCarriers({
        variables: {
          query
        }
      });
    }
  };

  const debounceDelay: number = 300;
  const debouncedHandleInputChange = pDebounce(handleInputChange, debounceDelay);

  const createOption = (carrier: Optional<AirlineCarrier>): Option<string> => ({
    label: carrier?.airlineCarrierName || '',
    value: carrier?.id || ''
  });

  const carriers = [carrierData?.airlineCarrier, ...(data?.airlineCarriers?.nodes || [])].filter(Boolean);
  const uniqueCarriers = sortBy(uniqBy(carriers, 'id'), 'airlineCarrierName');

  return {
    getCarrier: (id: string) => uniqueCarriers.find((carrier) => carrier?.id === id),
    options: uniqueCarriers.map(createOption),
    loading,
    error,
    onSearch: debouncedHandleInputChange
  };
};
