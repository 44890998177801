import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { ModeOfTransportIcon } from '@zen/Booking/BookingDetails';
import EmptyState from '@zen/Components/EmptyState';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  isLegacyCargo: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
}

const NoJourney: FC<Props> = ({ isLegacyCargo, modeOfTransport }) => {
  const illustration: ReactNode = modeOfTransport ? <ModeOfTransportIcon modeOfTransport={modeOfTransport} size="large" /> : null;
  const secondaryButton = !isLegacyCargo ? { onClick: () => window.location.reload(), title: 'Try again' } : undefined;
  const subTitle: string = isLegacyCargo
    ? 'We’re sorry. Please contact Zencargo if you need this information.'
    : 'We’re sorry. Please click Try again below to reload the page.';

  return (
    <EmptyState
      illustration={illustration}
      secondaryButton={secondaryButton}
      subTitle={subTitle}
      title="We can’t load the route details"
    />
  );
};

export default NoJourney;
