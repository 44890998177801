import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { Icon, TableLink, Tooltip } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  clientReference?: Nullable<string>;
  customsOnly?: boolean;
  pathname?: string;
  zencargoReference: string;
}

const ShipmentReferences: FC<Props> = ({ clientReference, zencargoReference, pathname, customsOnly }) => {
  const location = useLocation();

  const path: string = pathname || location.pathname;

  const shipmentUrl: string = `${path}/details/${zencargoReference}`;

  return (
    <div className="leading-normal js-intercom-shipment-references">
      <div className="flex items-center space-x-2">
        <TableLink linkTo={shipmentUrl}>{zencargoReference}</TableLink>
        {customsOnly && (
          <Tooltip tooltipContent="Customs only">
            <div className="bg-navy-light w-5 h-5 rounded-full flex items-center justify-center">
              <Icon icon="zicon-customs" />
            </div>
          </Tooltip>
        )}
      </div>

      {clientReference && (
        <div className="text-xs overflow-hidden truncate text-grey-base" data-testid="client-reference">
          {clientReference}
        </div>
      )}
    </div>
  );
};

export type { Props as ShipmentReferencesProps };

export default ShipmentReferences;
