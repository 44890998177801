import type { FC } from 'react';

import { useGetNetworkLocationsQuery } from '@zen/Accounts/LegacyAccountDetails/graphql/getNetworkLocations.generated';
import type { NetworkLocation } from '@zen/Accounts/LegacyAccountDetails/types';
import { FormSelect } from '@zen/Components';
import type { Option } from '@zen/DesignSystem';

const prepareLocationsOptions = (locations: NetworkLocation[]): Option<string>[] =>
  locations.map((location: NetworkLocation): Option<string> => {
    return {
      label: location?.name || '',
      value: location.id || ''
    };
  });

interface Props {
  accountId: string;
  helperText?: string;
  label?: string;
  name: string;
}

const FormLocationSelect: FC<Props> = (props) => {
  const { accountId, label, name, helperText } = props;
  const { data, loading } = useGetNetworkLocationsQuery({ variables: { accountId } });

  const locations = data?.network?.myOrganisation?.locations || [];

  const locationOptions: Option<string>[] = prepareLocationsOptions(locations);

  return <FormSelect helperText={helperText} isLoading={loading} label={label} name={name} options={locationOptions} />;
};

export default FormLocationSelect;
