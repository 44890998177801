import useAccount from '@zen/utils/hooks/useAccount';
import useTracking from '@zen/utils/hooks/useTracking';

type AccountSwitchSource = 'lastVisited' | 'mostFrequentlyVisited' | 'accountSwitcherForm';

const useTrackAccountSwitch = () => {
  const { trackEvent } = useTracking();
  const { userProfile } = useAccount();

  return (newAccountCompanyName: string, source: AccountSwitchSource): void => {
    trackEvent({
      category: 'Account',
      action: 'Switch',
      label: `${userProfile.registeredCompanyName} | ${newAccountCompanyName} | ${source}`,
      properties: {
        previousAccountName: userProfile.registeredCompanyName,
        nextAccountName: newAccountCompanyName,
        source
      }
    });
  };
};

export default useTrackAccountSwitch;
