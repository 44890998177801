import type { FC, ReactNode } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { IconButton } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';

interface Props {
  text?: ReactNode;
  textToCopy?: string;
}

const Copyable: FC<Props> = ({ text, textToCopy }) => {
  const { addSuccess } = useNotification();

  const handleClick = (): void => addSuccess('Copied to clipboard.');

  return (
    <div className="flex items-center space-x-2 group">
      {text}
      <CopyToClipboard text={textToCopy || (text as string)}>
        <IconButton
          className="transition-all duration-150 opacity-0 group-hover:opacity-100"
          icon="zicon-copy"
          onClick={handleClick}
          size="medium"
          title="Copy"
          variant="ghost"
        />
      </CopyToClipboard>
    </div>
  );
};

export default Copyable;
