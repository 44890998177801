import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { JourneyLeg, JourneyTrackingAction, JourneyTrackingCategory } from '@zen/Journey/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import JourneyContentSwitcher from '../JourneyContentSwitcher';
import { isTransshipmentJourney } from '../JourneyLegs/helpers';

interface Props {
  completedMilestoneCount: number;
  isVisible: boolean;
  legs: JourneyLeg[];
  modeOfTransport: Optional<ModeOfTransport>;
  onVisibilityChange: (isVisible: boolean) => void;
}

const CompletedMilestonesSwitcher: FC<Props> = (props) => {
  const { completedMilestoneCount, isVisible, legs, modeOfTransport, onVisibilityChange } = props;
  const { trackEvent } = useTracking();

  const handleCompletedMilestonesVisibilityChange = (): void => {
    onVisibilityChange(!isVisible);

    trackEvent({
      category: JourneyTrackingCategory,
      action: JourneyTrackingAction.COMPLETED_MILESTONES_VISIBILITY_CHANGE,
      label: `visible:${modeOfTransport}`,
      properties: {
        completedMilestoneCount,
        completedMilestonesVisible: isVisible,
        modeOfTransport,
        transshipment: isTransshipmentJourney(legs)
      }
    });
  };

  return (
    <JourneyContentSwitcher
      className="pb-6 ml-48"
      isVisible={isVisible}
      label={`${isVisible ? 'Hide' : 'Show'} completed milestones`}
      onChange={handleCompletedMilestonesVisibilityChange}
    />
  );
};

export default CompletedMilestonesSwitcher;
