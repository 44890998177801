import type { Option } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { DocumentPermissions, DocumentType } from './types';

export const getDefaultDocumentPermissions = (): DocumentPermissions => {
  return {
    agentForwarder: true,
    customerUser: true,
    manufacturer: true
  };
};

export const getDocumentTypeOptions = (docTypes: DocumentType[]): Option<string>[] => {
  if (!docTypes) {
    return [];
  }

  return docTypes.map((docType) => ({
    label: docType.title || '',
    value: docType.name || ''
  }));
};

export const getFileExtension = (fileUrl: Optional<string>): string => fileUrl?.split('.').pop()?.toLowerCase() || '';
