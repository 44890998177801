import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type SummaryRateCardQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  originPort?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  destinationPort?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  cargoType: GraphQLTypes.CargoModeEnum;
  validOn?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Date']>;
}>;

export type SummaryRateCardQueryResult = { __typename?: 'Query' } & {
  legacyGetRateCards?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyRateCardConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<
          GraphQLTypes.Maybe<
            { __typename?: 'LegacyRateCardSummary' } & Pick<GraphQLTypes.LegacyRateCardSummary, 'name' | 'rateCardId'>
          >
        >
      >;
    }
  >;
};

export const SummaryRateCardDocument = /* #__PURE__ */ gql`
  query summaryRateCard(
    $accountId: String!
    $originPort: String
    $destinationPort: String
    $modeOfTransport: ModeOfTransport!
    $cargoType: CargoModeEnum!
    $validOn: Date
  ) {
    legacyGetRateCards(
      customerDivisionId: $accountId
      originPort: $originPort
      destinationPort: $destinationPort
      modeOfTransport: $modeOfTransport
      cargoType: $cargoType
      validOn: $validOn
    ) {
      nodes {
        name
        rateCardId
      }
    }
  }
`;

/**
 * __useSummaryRateCardQuery__
 *
 * To run a query within a React component, call `useSummaryRateCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryRateCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryRateCardQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      originPort: // value for 'originPort'
 *      destinationPort: // value for 'destinationPort'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *      validOn: // value for 'validOn'
 *   },
 * });
 */
export function useSummaryRateCardQuery(
  baseOptions: Apollo.QueryHookOptions<SummaryRateCardQueryResult, SummaryRateCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<SummaryRateCardQueryResult, SummaryRateCardQueryVariables>(SummaryRateCardDocument, options);
}
export function useSummaryRateCardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SummaryRateCardQueryResult, SummaryRateCardQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<SummaryRateCardQueryResult, SummaryRateCardQueryVariables>(SummaryRateCardDocument, options);
}
export type SummaryRateCardQueryHookResult = ReturnType<typeof useSummaryRateCardQuery>;
export type SummaryRateCardLazyQueryHookResult = ReturnType<typeof useSummaryRateCardLazyQuery>;
