import type { FC } from 'react';

import SidebarLinks from '../components/SidebarLinks';
import type { SidebarCounters } from '../types';
import { config } from './config';

interface Props {
  counters: SidebarCounters;
}

const AdminLinks: FC<Props> = ({ counters }) => {
  return <SidebarLinks config={config} counters={counters} />;
};

export default AdminLinks;
