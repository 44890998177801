import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { journeyRefetchQueryList } from '../forms/helpers';
import { useUpdateActualDateMutation } from './graphql';

interface Props {
  date: string;
  milestoneId: string;
  onClose: () => void;
}

const MilestoneActualDateModal: FC<Props> = ({ date, milestoneId, onClose }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateActualDate, { loading }] = useUpdateActualDateMutation();

  const handleApply = (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateActualDate({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              milestoneId,
              dateTime: {
                date
              },
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Actual date has been added.');
        onClose();
      }
    });
  };

  return (
    <Dialog
      confirmLabel="Apply"
      header="Apply same changes"
      isLoading={loading}
      isOpen={true}
      message="Do you want to apply the same dates to the Departing collection point milestone?"
      onClose={onClose}
      onConfirm={handleApply}
    />
  );
};

export default MilestoneActualDateModal;
