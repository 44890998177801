import { isEqual, isPlainObject, omit } from 'lodash';

import type { ModeOfTransport } from '@zen/Booking';
import type { FilterItemType } from '@zen/Components/Filters/types';
import { modeOfTransportOptions } from '@zen/Components/ModeOfTransportSelect';
import type { DateRange } from '@zen/DesignSystem';
import type { WithinTimeRange } from '@zen/graphql/types.generated';
import type { LegacyAddress, NetworksAssignable } from '@zen/Networks';
import type { Customer } from '@zen/Orders/types';
import {
  BookingStep,
  BookingUpcomingEventEnum,
  Country,
  CustomsOnlyFilterEnum,
  IncotermsValue,
  IssuesFilterInput,
  NetworksOrgLoc,
  ShipmentFilterOption,
  ShipmentFilters,
  ShipmentFilterStatus,
  ShipmentsFilter,
  StageValue
} from '@zen/Shipments/types';
import { formatDate, getDateRange, getYesterday } from '@zen/utils/dateTime';
import { omitEmptyValues } from '@zen/utils/Filtering/helpers';
import type { Nullable, Optional } from '@zen/utils/typescript';
import { joinNotNull } from '@zen/utils/utils';

import type {
  NetworkAssignments,
  ShipmentFiltersCapabilities,
  ShipmentFilterVariables,
  ShipmentStatusFilterInput
} from './types';

export const upcomingEventLabelConfig: Record<BookingUpcomingEventEnum, string> = {
  [BookingUpcomingEventEnum.ARRIVAL]: 'Arriving at terminal',
  [BookingUpcomingEventEnum.DEPARTURE]: 'Departing from terminal',
  [BookingUpcomingEventEnum.COLLECTION]: 'Collecting',
  [BookingUpcomingEventEnum.DELIVERY]: 'Delivering'
};

export const withinDaysFilterOptions: ShipmentFilterOption<BookingUpcomingEventEnum>[] = [
  BookingUpcomingEventEnum.ARRIVAL,
  BookingUpcomingEventEnum.DEPARTURE,
  BookingUpcomingEventEnum.COLLECTION,
  BookingUpcomingEventEnum.DELIVERY
].map((value) => ({ value, label: upcomingEventLabelConfig[value] }));

export const customsOnlyOptions: ShipmentFilterOption<CustomsOnlyFilterEnum>[] = [
  { value: CustomsOnlyFilterEnum.SHOW_CUSTOMS_ONLY, label: 'Customs only' },
  { value: CustomsOnlyFilterEnum.SHOW_NOT_CUSTOMS_ONLY, label: 'Not customs only' }
];

export const stageFilterOptions: ShipmentFilterOption<ShipmentFilterStatus>[] = [
  { value: ShipmentFilterStatus.ACTIVE, label: 'Active' },
  { value: ShipmentFilterStatus.DELIVERED, label: 'Delivered' },
  { value: ShipmentFilterStatus.CONFIRMED, label: 'Confirmed' },
  { value: ShipmentFilterStatus.IN_TRANSIT, label: 'In transit' },
  { value: ShipmentFilterStatus.PENDING, label: 'Pending' }
];

export const incotermOptions: ShipmentFilterOption<IncotermsValue>[] = [
  { value: IncotermsValue.CFR, label: 'CFR' },
  { value: IncotermsValue.CIF, label: 'CIF' },
  { value: IncotermsValue.CIP, label: 'CIP' },
  { value: IncotermsValue.CPT, label: 'CPT' },
  { value: IncotermsValue.DAP, label: 'DAP' },
  { value: IncotermsValue.DAT, label: 'DAT' },
  { value: IncotermsValue.DDP, label: 'DDP' },
  { value: IncotermsValue.EXWORKS, label: 'EXWORKS' },
  { value: IncotermsValue.FAS, label: 'FAS' },
  { value: IncotermsValue.FCA, label: 'FCA' },
  { value: IncotermsValue.FOB, label: 'FOB' }
];

export const stageOptions: ShipmentFilterOption<StageValue>[] = [
  { value: StageValue.BOOKING_REQUESTED, label: 'Booking requested' },
  { value: StageValue.QUOTE_REQUESTED, label: 'Quote requested' },
  { value: StageValue.PENDING, label: 'Booking Received' },
  { value: StageValue.BOOKED, label: 'Booking Confirmed' },
  { value: StageValue.CONTAINER_OUT, label: 'On Route to Pick Up' },
  { value: StageValue.DEPARTED_WAREHOUSE, label: 'Departed Warehouse' },
  { value: StageValue.IN_GATE, label: 'Arrived at Port of Origin' },
  { value: StageValue.CARGO_ABOARD, label: 'Cargo aboard' },
  { value: StageValue.DEPARTED_POL, label: 'Departed Port of Origin' },
  { value: StageValue.ARRIVED_POD, label: 'Arrived at Port of Destination' },
  { value: StageValue.DISCHARGED, label: 'Unloaded' },
  { value: StageValue.ON_ROUTE_TO_FINAL_DESTINATION, label: 'On Route to Final Destination' },
  { value: StageValue.ARRIVED, label: 'Shipment Delivered' }
];

export const getDaysFilterOptions = (): ShipmentFilterOption<DateRange>[] => [
  { value: { startDate: getYesterday(), endDate: getYesterday() }, label: 'Yesterday' },
  { value: getDateRange(1), label: 'In the next 1 day' },
  { value: getDateRange(3), label: 'In the next 3 days' },
  { value: getDateRange(7), label: 'In the next 7 days' },
  { value: getDateRange(14), label: 'In the next 14 days' }
];

export const formatWithinTimeRangeLabel = ({ dateRange, eventType }: WithinTimeRange) => {
  const dayOption = getDaysFilterOptions().find((filter) => isEqual(filter.value, dateRange));
  const label: string = upcomingEventLabelConfig[eventType];

  if (dayOption) {
    const dayOptionLabel = dayOption.label.toLocaleLowerCase();

    return `${label} ${dayOptionLabel}`;
  }

  if (dateRange.startDate === dateRange.endDate) {
    return `${label} on ${formatDate(dateRange.startDate)}`;
  }

  return `${label} between ${formatDate(dateRange.startDate)} and ${formatDate(dateRange.endDate)}`;
};

export const mapCustomer = (customer: Customer): ShipmentFilterOption<string> => ({
  label: customer.name || '',
  value: customer.uuid || ''
});

export const mapCountry = (country: Country): ShipmentFilterOption<string> => ({
  label: country.name,
  value: country.code
});

export const getAddress = ({ street, city, postalCodeOrZip }: LegacyAddress): string => {
  return joinNotNull([street, city, postalCodeOrZip], ', ');
};

export const mapContacts = ({ id, label }: NetworksAssignable): ShipmentFilterOption<string> => ({
  label: label?.short || '',
  value: id
});

export const mapNetworksAssignable = ({ id, label, unlocode }: NetworksAssignable): ShipmentFilterOption<string> => ({
  label: label?.long || '',
  value: unlocode || id
});

export const mapVesselNames = (vesselName: Nullable<string>): ShipmentFilterOption<string> => {
  const name: string = vesselName || '';

  return { label: name, value: name };
};

export const mapForwarders = ({ label, organisation }: NetworksOrgLoc): ShipmentFilterOption<string> => {
  return {
    label: label?.short || '',
    value: organisation?.id || ''
  };
};

export const mapIssues = (title: Nullable<string>): ShipmentFilterOption<string> => ({
  label: title || 'User created',
  // BE sends null as a value we convert that to empty string here and back to null when submitting
  value: title || ''
});

export const getTransportModes = (transportModes: Nullable<ModeOfTransport[]>): ModeOfTransport[] | undefined => {
  return transportModes ? getFilterOptionArray<ModeOfTransport>(transportModes) : [];
};

export const getIsActive = (shipmentStatus: Optional<string>): boolean | undefined => {
  if (!shipmentStatus) {
    return undefined;
  }

  if (shipmentStatus === ShipmentFilterStatus.ACTIVE) {
    return true;
  }

  return false;
};

export const getShipmentStatus = (shipmentStatus: Optional<ShipmentFilterStatus>): Optional<ShipmentStatusFilterInput> => {
  switch (shipmentStatus) {
    case ShipmentFilterStatus.ACTIVE:
    case ShipmentFilterStatus.DELIVERED:
      return { active: getIsActive(shipmentStatus) };
    case ShipmentFilterStatus.IN_TRANSIT:
      return { showInTransit: true };

    case ShipmentFilterStatus.PENDING:
      return { withSteps: [BookingStep.PENDING] };
    case ShipmentFilterStatus.CONFIRMED:
      return { withSteps: [BookingStep.BOOKED] };
    default:
      return undefined;
  }
};

export const getFilterOptionArray = <T = string>(shipmentFilters: Optional<T[]>): T[] | undefined => {
  if (shipmentFilters && shipmentFilters.length > 0) {
    return shipmentFilters;
  }

  return undefined;
};

export const getIssuesFilterValue = (issueTitle: Optional<string>): IssuesFilterInput | undefined => {
  if (issueTitle === null || issueTitle === undefined) {
    return undefined;
  }

  const titleEq: Nullable<string> = issueTitle === '' ? null : issueTitle;

  return {
    titleEq,
    active: true
  };
};

const defaultFiltersValue: ShipmentsFilter = {
  customers: [],
  originCountries: [],
  destinationCountries: [],
  forwarders: [],
  issueTitles: [],
  vesselNames: [],
  canViewCustomerFilter: {
    value: false
  }
};

export const buildFilters = (
  filters: ShipmentsFilter | undefined = defaultFiltersValue,
  networkAssignments: NetworkAssignments,
  capabilities: ShipmentFiltersCapabilities = {}
): FilterItemType<ShipmentFilters>[] => {
  const { origins = [], consignors = [], consignees = [], destinations = [] } = networkAssignments;
  const {
    customers = [],
    originCountries = [],
    destinationCountries = [],
    forwarders = [],
    issueTitles = [],
    vesselNames = [],
    canViewCustomerFilter: { value: canViewCustomer }
  } = filters;

  const hasMultipleCustomers = customers.length > 1;

  const customerFilter: FilterItemType<ShipmentFilters> = {
    componentType: 'multi-select',
    key: 'customers',
    title: 'Customer',
    props: { options: customers.map(mapCustomer) }
  };

  const forwarderFilter: FilterItemType<ShipmentFilters> = {
    componentType: 'multi-select',
    key: 'forwarders',
    title: 'Forwarder',
    props: { options: forwarders.map(mapForwarders) }
  };

  return [
    {
      key: 'status',
      componentType: 'select',
      props: { options: stageFilterOptions },
      title: 'Shipment status'
    },
    {
      title: 'Transport mode',
      key: 'transportModes',
      props: { options: modeOfTransportOptions },
      componentType: 'multi-select'
    },
    { key: 'incoterms', props: { options: incotermOptions }, componentType: 'multi-select', title: 'Incoterms' },
    { title: 'Milestone', key: 'stages', componentType: 'multi-select', props: { options: stageOptions } },
    {
      componentType: 'group',
      key: 'withinTimeRange',
      title: 'Event',
      props: {
        configurations: [
          {
            key: 'eventType',
            title: 'Shipment event',
            props: { options: withinDaysFilterOptions },
            componentType: 'select'
          },
          {
            key: 'dateRange',
            title: 'When...',
            componentType: 'date-range-picker',
            props: { options: getDaysFilterOptions() }
          }
        ],
        formatValue: formatWithinTimeRangeLabel
      }
    },
    ...(customers !== null && canViewCustomer && hasMultipleCustomers ? [customerFilter] : []),
    {
      componentType: 'multi-select',
      key: 'originCountries',
      title: 'Origin country',
      props: { options: originCountries.map(mapCountry) }
    },
    {
      componentType: 'multi-select',
      key: 'origins',
      title: 'Origin',
      props: { options: origins.map(mapNetworksAssignable) }
    },
    {
      componentType: 'multi-select',
      key: 'vesselNames',
      title: 'Vessel names',
      props: { options: vesselNames.map(mapVesselNames) }
    },
    {
      componentType: 'multi-select',
      key: 'destinationCountries',
      title: 'Destination country',
      props: { options: destinationCountries.map(mapCountry) }
    },
    {
      componentType: 'multi-select',
      key: 'destinations',
      title: 'Destination',
      props: { options: destinations.map(mapNetworksAssignable) }
    },
    {
      componentType: 'multi-select',
      key: 'consignors',
      title: 'Consignor',
      props: { options: consignors.map(mapContacts) }
    },
    {
      componentType: 'multi-select',
      key: 'consignees',
      title: 'Consignee',
      props: { options: consignees.map(mapContacts) }
    },
    ...(capabilities.canViewForwarder ? [forwarderFilter] : []),
    {
      componentType: 'select',
      key: 'issueTitle',
      title: 'Issue types',
      props: { options: issueTitles.map(mapIssues) }
    }
  ];
};

export const countFilters = <T extends {}>(filters: T): number => {
  const countableFilters = omit(filters, '__typename');

  return Object.values(countableFilters).reduce((prev: number, curr) => {
    if (!curr || isPlainObject(curr)) {
      return prev;
    }

    if (Array.isArray(curr)) {
      return curr.length > 0 ? prev + 1 : prev;
    }

    return prev + 1;
  }, 0) as number;
};

export const prepareFilterVariables = (filters: ShipmentFilters): ShipmentFilterVariables => {
  const {
    status,
    transportModes,
    origins,
    destinations,
    consignees,
    consignors,
    forwarders,
    issueTitle,
    customers,
    originCountries,
    destinationCountries,
    vesselNames,
    incoterms,
    stages,
    withinTimeRange
  } = filters;

  return {
    ...getShipmentStatus(status),
    consignees: getFilterOptionArray(consignees),
    consignors: getFilterOptionArray(consignors),
    customers: getFilterOptionArray(customers),
    destinationCountries: getFilterOptionArray(destinationCountries),
    destinations: getFilterOptionArray(destinations),
    forwarders: getFilterOptionArray(forwarders),
    incoterms: getFilterOptionArray(incoterms),
    issues: getIssuesFilterValue(issueTitle),
    originCountries: getFilterOptionArray(originCountries),
    origins: getFilterOptionArray(origins),
    stages: getFilterOptionArray(stages),
    transportModes: getTransportModes(transportModes),
    vesselNames: getFilterOptionArray(vesselNames),
    withinTimeRange
  };
};

export const removeEmptyShipmentFilters = (values: Partial<ShipmentFilters>): Partial<ShipmentFilters> => {
  const { issueTitle } = values;
  const consideredEmptyValues = issueTitle === '' ? { issueTitle } : {};

  return { ...omitEmptyValues(values), ...consideredEmptyValues };
};
