import type { FC } from 'react';

import { FormInput } from '@zen/Components';
import FormCountriesSelect from '@zen/Components/Form/FormCountriesSelect';
import { getFieldName } from '@zen/utils/forms';

import type { BaseFormFieldsProps } from '../types';

const AddressFields: FC<BaseFormFieldsProps> = ({ fieldPrefix }) => {
  return (
    <>
      <FormCountriesSelect className="col-span-8" label="Country" name={getFieldName('country', fieldPrefix)} />
      <FormInput className="col-span-8" label="Address" name={getFieldName('line1', fieldPrefix)} />
      <FormInput className="col-span-8" isOptional={true} label="Flat / Other" name={getFieldName('line2', fieldPrefix)} />
      <FormInput className="col-span-8" label="City" name={getFieldName('city', fieldPrefix)} />
      <FormInput
        className="col-span-4"
        isOptional={true}
        label="County"
        name={getFieldName('countyOrState', fieldPrefix)}
        noMargin={true}
      />
      <FormInput className="col-span-4" label="Postal code" name={getFieldName('postalCodeOrZip', fieldPrefix)} noMargin={true} />
    </>
  );
};

export default AddressFields;
