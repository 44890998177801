import type { FC, ReactNode } from 'react';

import { FormCheckbox } from '@zen/Components';
import { TextLink } from '@zen/DesignSystem';

interface Props {
  className?: string;
  name: string;
}

const FormTermsAndConditionsCheckbox: FC<Props> = ({ className, name }) => {
  const termsLink: ReactNode = (
    <TextLink className="mx-1 whitespace-nowrap" isExternal={true} linkTo="https://www.zencargo.com/terms">
      Terms and Conditions
    </TextLink>
  );
  const privacyPolicyLink: ReactNode = (
    <TextLink className="whitespace-nowrap" isExternal={true} linkTo="https://www.zencargo.com/privacy-policy">
      Privacy Policy
    </TextLink>
  );

  const termsAndConditionLabel: ReactNode = (
    <div className="leading-normal">
      I agree to the {termsLink} and have read and acknowledge the {privacyPolicyLink}.
    </div>
  );

  return <FormCheckbox className={className} label={termsAndConditionLabel} name={name} />;
};

export default FormTermsAndConditionsCheckbox;
