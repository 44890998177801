import { FC, useContext } from 'react';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import BasketContext from '@zen/Components/OrderBasket/BasketContext';
import ViewSwitcher, { useViewMode, ViewMode } from '@zen/Components/ViewSwitcher';
import type { TableColumn } from '@zen/DesignSystem';
import { useTableConfiguration } from '@zen/DesignSystem';
import Filters from '@zen/Orders/components/Filters';
import type { OrderFilters, OrderTableView } from '@zen/Orders/types';
import type { SortInput } from '@zen/types';
import { useAppliedFilters } from '@zen/utils/Filtering/contexts/FiltersContext';

import { getColumns } from '../TableView/ordersTableConfiguration';
import type { OrderListCapabilities } from '../TableView/types';
import SavedFilters from './SavedFilters';

interface Props {
  canViewCustomer: boolean;
  showTableView: boolean;
  sortOrder: SortInput;
}

export const OrdersToolbar: FC<Props> = ({ canViewCustomer, sortOrder, showTableView }) => {
  const { isBasketMode } = useContext(BasketContext);
  const { setAppliedFilters } = useAppliedFilters<OrderFilters>();
  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const capabilities: OrderListCapabilities = { canViewCustomer };
  const columns: TableColumn<OrderTableView>[] = getColumns({ capabilities });

  const { viewMode, setViewMode } = useViewMode();

  const clearFilters = () => setAppliedFilters({});

  return (
    <div className="flex flex-col mb-6">
      <div className="flex items-center justify-between mb-4">
        {!isBasketMode && (
          <div className="flex items-center w-full mr-6 space-x-2 overflow-hidden">
            <ViewSwitcher onChange={(mode: string) => setViewMode(mode as ViewMode)} selectedView={viewMode} />
            <div className="overflow-x-scroll scrollbar-hidden">
              <Filters showSearch={isBasketMode} />
            </div>
          </div>
        )}
        {isBasketMode && (
          <div className="overflow-hidden">
            <div className="overflow-x-scroll scrollbar-hidden">
              <Filters showSearch={isBasketMode} />
            </div>
          </div>
        )}
        <div className="flex justify-end">
          {showTableView && (
            <CustomiseTableButton
              columns={columns}
              defaultFilterValue="orderReferenceNumber"
              hiddenColumns={hiddenColumns}
              onChange={setHiddenColumns}
              size="default"
            />
          )}
        </div>
      </div>
      {!isBasketMode && <SavedFilters clearFilters={clearFilters} />}
    </div>
  );
};

export default OrdersToolbar;
