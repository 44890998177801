import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTaskByIdQueryVariables = GraphQLTypes.Exact<{
  taskId: GraphQLTypes.Scalars['String'];
}>;

export type GetTaskByIdQueryResult = { __typename?: 'Query' } & {
  taskById?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskType' } & Pick<
      GraphQLTypes.WorkflowTaskType,
      'description' | 'dueDate' | 'name' | 'taskId' | 'zencargoReference'
    >
  >;
};

export const GetTaskByIdDocument = /* #__PURE__ */ gql`
  query getTaskById($taskId: String!) {
    taskById(taskId: $taskId) {
      description
      dueDate
      name
      taskId
      zencargoReference
    }
  }
`;

/**
 * __useGetTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetTaskByIdQuery(baseOptions: Apollo.QueryHookOptions<GetTaskByIdQueryResult, GetTaskByIdQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTaskByIdQueryResult, GetTaskByIdQueryVariables>(GetTaskByIdDocument, options);
}
export function useGetTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskByIdQueryResult, GetTaskByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTaskByIdQueryResult, GetTaskByIdQueryVariables>(GetTaskByIdDocument, options);
}
export type GetTaskByIdQueryHookResult = ReturnType<typeof useGetTaskByIdQuery>;
export type GetTaskByIdLazyQueryHookResult = ReturnType<typeof useGetTaskByIdLazyQuery>;
