import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type OceanTrackingStatusQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type OceanTrackingStatusQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              oceanTracking?: GraphQLTypes.Maybe<
                { __typename?: 'OceanTracking' } & Pick<GraphQLTypes.OceanTracking, 'canViewTrackingDetails' | 'status'> & {
                    issues: Array<
                      { __typename?: 'OceanTrackingIssue' } & Pick<
                        GraphQLTypes.OceanTrackingIssue,
                        'isActionable' | 'message' | 'type'
                      >
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const OceanTrackingStatusDocument = /* #__PURE__ */ gql`
  query oceanTrackingStatus($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        oceanTracking {
          canViewTrackingDetails
          issues {
            isActionable
            message
            type
          }
          status
        }
        zencargoReference
      }
    }
  }
`;

/**
 * __useOceanTrackingStatusQuery__
 *
 * To run a query within a React component, call `useOceanTrackingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useOceanTrackingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOceanTrackingStatusQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useOceanTrackingStatusQuery(
  baseOptions: Apollo.QueryHookOptions<OceanTrackingStatusQueryResult, OceanTrackingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<OceanTrackingStatusQueryResult, OceanTrackingStatusQueryVariables>(OceanTrackingStatusDocument, options);
}
export function useOceanTrackingStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OceanTrackingStatusQueryResult, OceanTrackingStatusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<OceanTrackingStatusQueryResult, OceanTrackingStatusQueryVariables>(
    OceanTrackingStatusDocument,
    options
  );
}
export type OceanTrackingStatusQueryHookResult = ReturnType<typeof useOceanTrackingStatusQuery>;
export type OceanTrackingStatusLazyQueryHookResult = ReturnType<typeof useOceanTrackingStatusLazyQuery>;
