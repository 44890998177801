import type { FC } from 'react';

import { Icon, IconConfig } from '@zen/DesignSystem';
import { QuoteOptionState } from '@zen/Quotes';
import type { Optional } from '@zen/utils/typescript';

type OptionStateIconMapping = {
  [key in QuoteOptionState]?: IconConfig;
};

interface Props {
  quoteOptionState: QuoteOptionState;
}

const QuoteOptionIcon: FC<Props> = ({ quoteOptionState }) => {
  const frontIconConfig: OptionStateIconMapping = {
    [QuoteOptionState.ACCEPTED]: {
      icon: 'zicon-tickoval',
      className: 'text-green-dark'
    },
    [QuoteOptionState.ACTIVE]: {
      icon: 'zicon-warning-oval',
      className: 'text-orange-base'
    },
    [QuoteOptionState.DRAFT]: {
      icon: 'zicon-warning-oval',
      className: 'text-orange-base'
    },
    [QuoteOptionState.REJECTED]: {
      icon: 'zicon-cross-oval',
      className: 'text-red-dark'
    },
    [QuoteOptionState.ARCHIVED]: {
      icon: 'zicon-cross-oval',
      className: 'text-orange-base'
    },
    [QuoteOptionState.EXPIRED]: {
      icon: 'zicon-warning-oval',
      className: 'text-orange-base'
    }
  };

  const iconConfig: Optional<IconConfig> = frontIconConfig[quoteOptionState];

  if (!iconConfig) {
    return null;
  }

  return <Icon className={`bg-white ${iconConfig.className}`} icon={iconConfig.icon} />;
};

export default QuoteOptionIcon;
