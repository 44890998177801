import { type FC, useState } from 'react';

import { Modal } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { LocationType } from '../../components/types';
import HaulageChargeForm from '../HaulageChargeForm';
import HaulageChargeTable from '../HaulageChargeTable';
import type { CostTrackingLocation } from './types';

interface Props {
  haulageCharges: RateCardCharge[];
  onAdd: (values: RateCardCharge[]) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: Partial<RateCardCharge>) => void;
  ports: CostTrackingLocation[];
  type: LocationType;
}

const HaulageChargeSection: FC<Props> = (props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { haulageCharges, onAdd, onDelete, onUpdate, ports, type } = props;

  const handleAdd = (): void => {
    setModalOpen(true);
  };

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <HaulageChargeTable charges={haulageCharges} onAdd={handleAdd} onDelete={onDelete} onUpdate={onUpdate} type={type} />
      <Modal
        closeOnClickAway={false}
        isOpen={isModalOpen}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Add haulage charges"
        width="full"
      >
        <HaulageChargeForm locationType={type} onCancel={handleClose} onSubmit={onAdd} onSuccess={handleClose} ports={ports} />
      </Modal>
    </>
  );
};

export type { Props as HaulageChargeSectionProps };

export default HaulageChargeSection;
