import type { FC } from 'react';

import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import RadioGroup from '@zen/Components/RadioGroup';
import type { Optional } from '@zen/utils/typescript';

import { TimeRangeEnum } from './types';

const options: RadioGroupOption[] = [
  { value: TimeRangeEnum.MORNING, label: 'Morning' },
  { value: TimeRangeEnum.AFTERNOON, label: 'Afternoon' },
  { value: TimeRangeEnum.ALL_DAY, label: 'All day' }
];

interface Props {
  className?: string;
  onChange: (timeRange: TimeRangeEnum) => void;
  value: Optional<TimeRangeEnum>;
}

const TimeRange: FC<Props> = ({ className, onChange, value }) => {
  const handleChange = (timeRange: string): void => onChange(timeRange as TimeRangeEnum);

  return (
    <div className={className} data-testid="time-range">
      <RadioGroup
        className="mb-4"
        name="timeRange"
        onChange={handleChange}
        options={options}
        radioAlignment="row"
        value={value}
      />
    </div>
  );
};

export default TimeRange;
