import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';
import PathIndicator from '@zen/Journey/components/PathIndicator';
import VerticalPathProvider from '@zen/Journey/components/VerticalPathProvider';
import type { Optional } from '@zen/utils/typescript';

import type { BookingStatus } from './types';

interface Props {
  bookingStatus: BookingStatus;
  bookingStatusLabel: string;
  className?: string;
  destination: Optional<string>;
  origin: Optional<string>;
}

const BookingRoute: FC<Props> = (props) => {
  const { bookingStatus, bookingStatusLabel, className, destination, origin } = props;

  const classNames: string = cx('relative', className);
  const bookingInProgress: boolean = bookingStatus === 'inProgress';
  const bookingDelivered: boolean = bookingStatus === 'delivered';

  const renderStop = (location: Optional<string>, label: Optional<string>, isCompleted: boolean): ReactNode => {
    const iconClassName: string = isCompleted ? 'text-azure-base' : 'text-grey-light';

    return (
      <div>
        <div className="relative flex items-end pr-8 mt-6 space-x-8 border-t border-solid border-grey-lighter">
          <div className="relative flex items-start w-full pr-2 ml-48 -mt-6 bg-white">
            <Icon
              className={`bg-white mt-4 py-0.5 z-20 flex justify-center shrink-0 text-xs ${JOURNEY_TIMELINE_WIDTH} ${iconClassName}`}
              data-routedatapoint={true}
              data-routedatapointcompleted={isCompleted}
              icon="zicon-pin"
            />
            <div className="flex-1 w-full">
              <div className="text-xs leading-tight text-grey-base">{label}</div>
              <div className="text-sm text-grey-dark">
                <ValueWithDefault value={location} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <VerticalPathProvider>
      <div className={classNames} data-testid="booking-route">
        <div className="flex flex-col space-y-2.5">
          {renderStop(origin, 'Collection', bookingInProgress || bookingDelivered)}
          {bookingInProgress && (
            <div className="ml-48">
              <div className="flex">
                <PathIndicator isCompleted={true} isCurrent={true} />
                <span className="font-bold">{bookingStatusLabel}</span>
              </div>
            </div>
          )}
          {renderStop(destination, 'Delivery', bookingDelivered)}
        </div>
      </div>
    </VerticalPathProvider>
  );
};

export default BookingRoute;
