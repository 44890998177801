/* eslint-disable no-nested-ternary */
import type { FC } from 'react';

import type { ActivityFeedUser, LegacyActivityFeedUser } from '@zen/ActivityFeed';
import { isCurrentUser } from '@zen/ActivityFeed/helpers/isCurrentUser';
import useRole from '@zen/Auth/hooks/useRole';
import { Tooltip } from '@zen/DesignSystem';
import { Role } from '@zen/Networks/types';
import useAccount from '@zen/utils/hooks/useAccount';
import type { Nullable } from '@zen/utils/typescript';

import { getOrganisationName, getPermittedRoleLabel } from '../../helpers/partiesDetailsHelper';
import robotSrc from './robot.png';

interface Props {
  isZensei?: boolean;
  legacyUser?: Nullable<LegacyActivityFeedUser>;
  showByWhom?: boolean;
  user?: Nullable<ActivityFeedUser>;
}

const UserDetails: FC<Props> = ({ user = null, legacyUser, showByWhom = false, isZensei = false }) => {
  const { userProfile } = useAccount();
  const currentUserRole = useRole();

  const assignedRoles = legacyUser ? legacyUser.assignedRoles : [];
  const firstName = user ? user.firstName : legacyUser ? legacyUser.firstName : '';
  const email = user ? user.email : legacyUser ? legacyUser.email : '';
  const lastName = user ? user.lastName : legacyUser ? legacyUser.lastName : '';
  const organisationName = user ? user.account.tradingName : legacyUser ? legacyUser.organisation.name : '';

  const username = isCurrentUser(userProfile, email) ? 'Me' : `${firstName} ${lastName.charAt(0)}.`;
  const isCustomerViewingAgent = currentUserRole === Role.CUSTOMER_USER && assignedRoles[0] === Role.AGENT_FORWARDER;

  return (
    <div className="flex items-center whitespace-pre-wrap text-base" data-testid="user-details">
      {!isZensei && (
        <>
          {showByWhom && <span className="text-grey-base"> by </span>}
          <p className="max-w-24 truncate font-bold">{username}</p>
          <div className="flex items-baseline">
            <p className="text-grey-base"> from </p>
            <Tooltip tooltipContent={getPermittedRoleLabel(assignedRoles[0], isCustomerViewingAgent)}>
              <p className="max-w-32 truncate text-navy-base text-sm">
                {getOrganisationName(organisationName, isCustomerViewingAgent)}
              </p>
            </Tooltip>
          </div>
        </>
      )}
      {isZensei && (
        <>
          <img alt="Zensei" className="w-5 h-5 mr-1" data-testid="zensei-logo" src={robotSrc} />
          {firstName} {lastName}
        </>
      )}
    </div>
  );
};

export default UserDetails;
