import type { FC } from 'react';

import { BookingStageEnum, isRoadBooking } from '@zen/Booking';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { type JourneyMilestoneWithMetadata, JourneyPlannedDateReasonEnum } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import ConfirmScheduleButton from '../ConfirmScheduleButton';
import EditStopsButton from '../EditStopsButton';
import PlannedScheduleButton from '../PlannedScheduleButton';
import RemoveScheduleButton from '../RemoveScheduleButton';
import { getCarriageArrivalMilestone, getCarriageDepartureMilestone } from './helpers';
import type { ButtonConfig } from './types';

const JourneyActions: FC = () => {
  const { bookingStage, carriageLegs, modeOfTransport } = useCargoOverviewContext();
  const isEditJourneyStopsEnabled: boolean = useFeatureIsOn('edit-journey-stops');
  const carriageDepartureMilestone: Optional<JourneyMilestoneWithMetadata> = getCarriageDepartureMilestone(carriageLegs);
  const carriageArrivalMilestone: Optional<JourneyMilestoneWithMetadata> = getCarriageArrivalMilestone(carriageLegs);
  const canManagePlannedDates: boolean = !!carriageLegs[0]?.canManagePlannedDates;

  if (!carriageDepartureMilestone) return null;

  const { canUpdatePlannedDateCurrently, completed, planned, requested } = carriageDepartureMilestone;

  const hasPlannedDate: boolean = !!planned?.startDateTime?.date;
  const hasRequestedDate: boolean = !!requested?.startDateTime?.date;
  const isBookingRequested: boolean = bookingStage === BookingStageEnum.BOOKING_REQUESTED;
  const departureMilestoneId: string = carriageDepartureMilestone?.id || '';
  const arrivalMilestoneId: string = carriageArrivalMilestone?.id || '';

  const isCarrierBookingReferenceMissing: boolean =
    !canUpdatePlannedDateCurrently?.value &&
    canUpdatePlannedDateCurrently?.reason === JourneyPlannedDateReasonEnum.MISSING_CARRIER_BOOKING_REFERENCE;

  const canViewButtons: boolean = canManagePlannedDates && !isRoadBooking(modeOfTransport);

  const buttonsConfig: ButtonConfig[] = [
    {
      isVisible: (hasRequestedDate || hasPlannedDate) && !completed,
      component: <RemoveScheduleButton isBookingConfirmed={hasPlannedDate} />
    },
    {
      isVisible: hasRequestedDate && !hasPlannedDate && !completed,
      component: (
        <ConfirmScheduleButton
          isBookingRequested={isBookingRequested}
          isCarrierBookingReferenceMissing={isCarrierBookingReferenceMissing}
        />
      )
    },
    {
      isVisible: !hasRequestedDate && !completed,
      component: <PlannedScheduleButton arrivalMilestoneId={arrivalMilestoneId} departureMilestoneId={departureMilestoneId} />
    },
    {
      isVisible: hasPlannedDate && !carriageArrivalMilestone?.completed && isEditJourneyStopsEnabled,
      component: <EditStopsButton arrivalMilestoneId={arrivalMilestoneId} departureMilestoneId={departureMilestoneId} />
    }
  ];

  const visibleButtons: ButtonConfig[] = buttonsConfig.filter(({ isVisible }) => isVisible);

  if (!canViewButtons || !visibleButtons.length) return null;

  return (
    <div className="flex space-x-4">
      {visibleButtons.map(({ component }, index) => (
        <div key={index}>{component}</div>
      ))}
    </div>
  );
};

export default JourneyActions;
