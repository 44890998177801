import { type JourneyLeg, JourneyShippingMilestoneCategoryEnum, JourneyStopActionEnum } from '@zen/graphql/types.generated';
import type { JourneyMilestoneWithMetadata } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import { getMilestones } from '../JourneyDetails/helpers';

export const getCarriageDepartureMilestone = (carriageLegs: JourneyLeg[]): Optional<JourneyMilestoneWithMetadata> => {
  const milestones: JourneyMilestoneWithMetadata[] = getMilestones(carriageLegs);

  return milestones.find(
    ({ category, stopAction }) =>
      category === JourneyShippingMilestoneCategoryEnum.DEPARTED_FROM_STOP &&
      stopAction === JourneyStopActionEnum.CARRIAGE_DEPARTURE
  );
};

export const getCarriageArrivalMilestone = (carriageLegs: JourneyLeg[]): Optional<JourneyMilestoneWithMetadata> => {
  const milestones: JourneyMilestoneWithMetadata[] = getMilestones(carriageLegs);

  return milestones.find(
    ({ category, stopAction }) =>
      category === JourneyShippingMilestoneCategoryEnum.ARRIVED_AT_STOP && stopAction === JourneyStopActionEnum.CARRIAGE_ARRIVAL
  );
};
