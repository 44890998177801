import type { FC } from 'react';

import { Tooltip } from '@zen/DesignSystem';
import type { LocalDateTimeType } from '@zen/graphql/types.generated';
import { formatDate, formatTimeZone } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  dateWithTimeZone: Optional<LocalDateTimeType>;
}

const DateWithTimeZone: FC<Props> = ({ dateWithTimeZone }) => {
  if (!dateWithTimeZone?.date) {
    return <> - </>;
  }

  const { date, timeZone } = dateWithTimeZone;

  return (
    <Tooltip placement="top-start" tooltipContent={formatTimeZone(date, timeZone)}>
      {formatDate(date)}
    </Tooltip>
  );
};

export type { Props as DateWithTimeZoneProps };
export default DateWithTimeZone;
