import type { FC, ReactNode } from 'react';

import type { RejectedReasonEnum } from '@zen/ActivityFeed';
import LabelledValue from '@zen/Components/LabelledValue';
import { Card, Icon } from '@zen/DesignSystem';
import { BookingManufacturerResubmitButton, BookingResubmitForApprovalButton } from '@zen/LegacyBookingRequests';
import { rejectedReasonLabelMapping } from '@zen/LegacyBookingRequests/helper';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  canRevertRejection: boolean;
  canRevertRejectionAsManufacturer: boolean;
  rejectedAdditionalInfo: Optional<string>;
  rejectedReason: Optional<RejectedReasonEnum>;
  zencargoReference: string;
}

const BookingRequestRejected: FC<Props> = (props) => {
  const { canRevertRejection, canRevertRejectionAsManufacturer, rejectedAdditionalInfo, rejectedReason, zencargoReference } =
    props;

  const renderActionButtons = (): ReactNode => {
    if (canRevertRejectionAsManufacturer) {
      return <BookingManufacturerResubmitButton zencargoReference={zencargoReference} />;
    }

    if (canRevertRejection) {
      return <BookingResubmitForApprovalButton zencargoReference={zencargoReference} />;
    }

    return null;
  };

  const footer: ReactNode = (
    <div className="flex">
      <Icon className="mr-6 text-xl" icon="zicon-alert" />
      <LabelledValue className="flex-1" label="Rejection reason">
        {rejectedReason && rejectedReasonLabelMapping[rejectedReason]}
      </LabelledValue>
      <LabelledValue className="flex-1" label="Additional info">
        {rejectedAdditionalInfo || '-'}
      </LabelledValue>
    </div>
  );

  return (
    <div data-testid="booking-request-rejected">
      <Card actions={renderActionButtons()} footer={footer} title="Approval rejected">
        This booking was rejected for reasons listed below. If you’ve made the required changes please resend for approval
      </Card>
    </div>
  );
};

export default BookingRequestRejected;
