import { Chip } from '@zen/DesignSystem';
import { SavedFilter, useSavedFilters } from '@zen/utils/Filtering/contexts/SavedFiltersContext';
import useTracking from '@zen/utils/hooks/useTracking';

import { QUICK_FILTERS_CATEGORY, QuickFiltersAction } from '../types';

interface Props<T extends {}> {
  filters: T;
  setFilters: (filters: Partial<T>) => void;
}

const QuickFilters = <T extends {}>({ filters, setFilters }: Props<T>) => {
  const { savedFilters, getActiveSavedFilter } = useSavedFilters();
  const { trackEvent } = useTracking();

  const activeQuickFilter = getActiveSavedFilter(filters);

  return (
    <div className="flex space-x-2">
      {savedFilters.map((savedFilter: SavedFilter<{}>, i) => {
        const isSelected: boolean = activeQuickFilter === savedFilter;
        const handleDelete = () => {
          trackEvent({
            label: savedFilter.name,
            category: QUICK_FILTERS_CATEGORY,
            action: QuickFiltersAction.DISABLE_QUICK_FILTER
          });
          if (isSelected) {
            setFilters({});
          }
        };

        const handleClick = () => {
          trackEvent({
            label: savedFilter.name,
            category: QUICK_FILTERS_CATEGORY,
            action: QuickFiltersAction.ENABLE_QUICK_FILTER
          });

          setFilters(savedFilter.filters);
        };

        return (
          <Chip
            key={`qFilter-${savedFilter.name}`}
            isSelected={isSelected}
            label={savedFilter.name}
            onDelete={handleDelete}
            onSelect={handleClick}
          />
        );
      })}
    </div>
  );
};

export default QuickFilters;
