import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ContactNameQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type ContactNameQueryResult = { __typename?: 'Query' } & {
  contact: { __typename?: 'NetworkContact' } & Pick<GraphQLTypes.NetworkContact, 'id' | 'name'>;
};

export const ContactNameDocument = /* #__PURE__ */ gql`
  query contactName($id: String!) {
    contact(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useContactNameQuery__
 *
 * To run a query within a React component, call `useContactNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactNameQuery(baseOptions: Apollo.QueryHookOptions<ContactNameQueryResult, ContactNameQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ContactNameQueryResult, ContactNameQueryVariables>(ContactNameDocument, options);
}
export function useContactNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ContactNameQueryResult, ContactNameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ContactNameQueryResult, ContactNameQueryVariables>(ContactNameDocument, options);
}
export type ContactNameQueryHookResult = ReturnType<typeof useContactNameQuery>;
export type ContactNameLazyQueryHookResult = ReturnType<typeof useContactNameLazyQuery>;
