import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksDeleteLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteLocationInput;
}>;

export type NetworksDeleteLocationMutationResult = { __typename?: 'Mutation' } & {
  networksDeleteLocation?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const NetworksDeleteLocationDocument = /* #__PURE__ */ gql`
  mutation networksDeleteLocation($input: DeleteLocationInput!) {
    networksDeleteLocation(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useNetworksDeleteLocationMutation__
 *
 * To run a mutation, you first call `useNetworksDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksDeleteLocationMutation, { data, loading, error }] = useNetworksDeleteLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksDeleteLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<NetworksDeleteLocationMutationResult, NetworksDeleteLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksDeleteLocationMutationResult, NetworksDeleteLocationMutationVariables>(
    NetworksDeleteLocationDocument,
    options
  );
}
export type NetworksDeleteLocationMutationHookResult = ReturnType<typeof useNetworksDeleteLocationMutation>;
