import type { FC } from 'react';

import { Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useToggleOceanTrackingMutation } from '../graphql';
import OceanTrackingForm from './OceanTrackingForm';
import type { OceanTrackingFormValues } from './OceanTrackingForm/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  zencargoReference: string;
}

const DisableOceanTrackingModal: FC<Props> = ({ isOpen, onClose, zencargoReference }) => {
  const [disableOceanTracking] = useToggleOceanTrackingMutation();
  const { addError, addSuccess } = useNotification();

  const handleSubmit = async ({ reason }: OceanTrackingFormValues): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        disableOceanTracking({
          awaitRefetchQueries: true,
          refetchQueries: ['bookingDetails'],
          variables: {
            input: {
              isEnabled: false,
              reason,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Ocean tracking has been disabled.');
        onClose();
      }
    });
  };

  return (
    <Modal closeOnClickAway={false} isOpen={isOpen} onClose={onClose} title="Disable tracking" width="medium">
      <OceanTrackingForm onCancel={onClose} onSubmit={handleSubmit} />
    </Modal>
  );
};

export default DisableOceanTrackingModal;
