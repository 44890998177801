import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTaskListCountQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  taskDetailsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.TaskDetailsFiltersInput>;
}>;

export type GetTaskListCountQueryResult = { __typename?: 'Query' } & {
  taskDetails?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskDetailsPayloadType' } & Pick<
      GraphQLTypes.WorkflowTaskDetailsPayloadType,
      'allOpenTaskCount' | 'allUnassignedTaskCount' | 'activeTaskCount' | 'completedTaskCount'
    >
  >;
};

export const GetTaskListCountDocument = /* #__PURE__ */ gql`
  query getTaskListCount(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
    $taskDetailsFiltersInput: TaskDetailsFiltersInput
  ) {
    taskDetails(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      taskDetailsFiltersInput: $taskDetailsFiltersInput
    ) {
      allOpenTaskCount
      allUnassignedTaskCount
      activeTaskCount
      completedTaskCount
    }
  }
`;

/**
 * __useGetTaskListCountQuery__
 *
 * To run a query within a React component, call `useGetTaskListCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskListCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskListCountQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      taskDetailsFiltersInput: // value for 'taskDetailsFiltersInput'
 *   },
 * });
 */
export function useGetTaskListCountQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTaskListCountQueryResult, GetTaskListCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTaskListCountQueryResult, GetTaskListCountQueryVariables>(GetTaskListCountDocument, options);
}
export function useGetTaskListCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskListCountQueryResult, GetTaskListCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTaskListCountQueryResult, GetTaskListCountQueryVariables>(GetTaskListCountDocument, options);
}
export type GetTaskListCountQueryHookResult = ReturnType<typeof useGetTaskListCountQuery>;
export type GetTaskListCountLazyQueryHookResult = ReturnType<typeof useGetTaskListCountLazyQuery>;
