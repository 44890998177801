import { get } from 'lodash';
import * as Yup from 'yup';

import type { Optional } from '@zen/utils/typescript';

import type { ErrorType, ParseError } from '../types';

const gerErrorMessage = (context: Yup.TestContext): string => {
  const pathInParsedErrors: string[] = context.path.split('.');
  const errorType = pathInParsedErrors[pathInParsedErrors.length - 1] as ErrorType;
  const parsedErrors: ParseError[] = get(context.options.context, `${pathInParsedErrors[0]}.errors`, []);
  const errorMessage: string = parsedErrors.find((error: ParseError) => error.type === errorType)?.message || '';

  return errorMessage;
};

const applicabilityErrorValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(applicability: Optional<string>, context: Yup.TestContext) {
    if (!applicability) {
      return this.createError({ message: gerErrorMessage(context) });
    }

    return true;
  });

const currencyErrorValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(currency: Optional<string>, context: Yup.TestContext) {
    if (!currency) {
      return this.createError({ message: gerErrorMessage(context) });
    }

    return true;
  });

const chargeTypeIdErrorValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(chargeTypeId: Optional<string>, context: Yup.TestContext) {
    if (!chargeTypeId) {
      return this.createError({ message: gerErrorMessage(context) });
    }

    return true;
  });

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  parseErrors: Yup.array().of(
    Yup.object().shape({
      values: Yup.object().shape({
        applicability: applicabilityErrorValidator,
        currency: currencyErrorValidator,
        chargeTypeId: chargeTypeIdErrorValidator
      })
    })
  )
});
