import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetQuoteOptionsDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
  includeQuoteOptionDrafts?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type GetQuoteOptionsDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              quoteOptions: Array<
                GraphQLTypes.Maybe<
                  { __typename?: 'QuoteOption' } & Pick<
                    GraphQLTypes.QuoteOption,
                    | 'id'
                    | 'zencargoReference'
                    | 'quoteReference'
                    | 'accrualId'
                    | 'description'
                    | 'state'
                    | 'reasonDescription'
                    | 'rejectionReason'
                  > & {
                      total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
                      expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                    }
                >
              >;
            }
        >
      >
    >;
  };
};

export const GetQuoteOptionsDetailsDocument = /* #__PURE__ */ gql`
  query getQuoteOptionsDetails($zencargoReference: String!, $includeQuoteOptionDrafts: Boolean) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        quoteOptions(includeDrafts: $includeQuoteOptionDrafts) {
          id
          zencargoReference
          quoteReference
          accrualId
          description
          state
          total {
            value
            currency
          }
          expiryDate {
            date
          }
          reasonDescription
          rejectionReason
        }
      }
    }
  }
`;

/**
 * __useGetQuoteOptionsDetailsQuery__
 *
 * To run a query within a React component, call `useGetQuoteOptionsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuoteOptionsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuoteOptionsDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *      includeQuoteOptionDrafts: // value for 'includeQuoteOptionDrafts'
 *   },
 * });
 */
export function useGetQuoteOptionsDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetQuoteOptionsDetailsQueryResult, GetQuoteOptionsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetQuoteOptionsDetailsQueryResult, GetQuoteOptionsDetailsQueryVariables>(
    GetQuoteOptionsDetailsDocument,
    options
  );
}
export function useGetQuoteOptionsDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetQuoteOptionsDetailsQueryResult, GetQuoteOptionsDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetQuoteOptionsDetailsQueryResult, GetQuoteOptionsDetailsQueryVariables>(
    GetQuoteOptionsDetailsDocument,
    options
  );
}
export type GetQuoteOptionsDetailsQueryHookResult = ReturnType<typeof useGetQuoteOptionsDetailsQuery>;
export type GetQuoteOptionsDetailsLazyQueryHookResult = ReturnType<typeof useGetQuoteOptionsDetailsLazyQuery>;
