import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateLooseCargoItemMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateLooseCargoItemInput;
}>;

export type UpdateLooseCargoItemMutationResult = { __typename?: 'Mutation' } & {
  cargoUpdateLooseCargoItem?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateLooseCargoItemPayload' } & {
      cargoItem?: GraphQLTypes.Maybe<
        | ({ __typename?: 'CargoContainerType' } & Pick<
            GraphQLTypes.CargoContainerType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoLooseCargoType' } & Pick<
            GraphQLTypes.CargoLooseCargoType,
            | 'containerNumber'
            | 'palletType'
            | 'stackable'
            | 'trailerId'
            | 'id'
            | 'type'
            | 'cbm'
            | 'hazardous'
            | 'reefer'
            | 'looseCargoType'
            | 'quantity'
          > & {
              chargeableWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
              dimensions?: GraphQLTypes.Maybe<
                { __typename?: 'CargoDimensionsType' } & Pick<
                  GraphQLTypes.CargoDimensionsType,
                  'length' | 'width' | 'height' | 'metric'
                >
              >;
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
        | ({ __typename?: 'CargoVehicleType' } & Pick<
            GraphQLTypes.CargoVehicleType,
            'id' | 'type' | 'cbm' | 'hazardous' | 'reefer' | 'looseCargoType' | 'quantity'
          > & {
              grossWeight?: GraphQLTypes.Maybe<
                { __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'metric' | 'value'>
              >;
            })
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateLooseCargoItemDocument = /* #__PURE__ */ gql`
  mutation updateLooseCargoItem($input: UpdateLooseCargoItemInput!) {
    cargoUpdateLooseCargoItem(input: $input) {
      cargoItem {
        id
        type
        cbm
        hazardous
        reefer
        looseCargoType
        quantity
        grossWeight {
          metric
          value
        }
        ... on CargoLooseCargoType {
          chargeableWeight {
            metric
            value
          }
          containerNumber
          palletType
          stackable
          dimensions {
            length
            width
            height
            metric
          }
          trailerId
        }
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateLooseCargoItemMutation__
 *
 * To run a mutation, you first call `useUpdateLooseCargoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLooseCargoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLooseCargoItemMutation, { data, loading, error }] = useUpdateLooseCargoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLooseCargoItemMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLooseCargoItemMutationResult, UpdateLooseCargoItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateLooseCargoItemMutationResult, UpdateLooseCargoItemMutationVariables>(
    UpdateLooseCargoItemDocument,
    options
  );
}
export type UpdateLooseCargoItemMutationHookResult = ReturnType<typeof useUpdateLooseCargoItemMutation>;
