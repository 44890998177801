import { ChronoField, DateTimeFormatter, LocalDate, Period } from '@js-joda/core';

import type { DateRange } from '@zen/DesignSystem';

import { compareDate } from '../date/comparing';
import { add } from '../date/manipulating';
import { parseDate } from './parsing';

export const dateDifference = (from: LocalDate, to: LocalDate): Period => {
  return Period.between(from, to);
};

export const getToday = (): string => {
  return LocalDate.now().toString();
};

export const getTomorrow = (): string => {
  const today = LocalDate.now();

  return today.plusDays(1).toString();
};

export const getYesterday = (): string => {
  const today = LocalDate.now();

  return today.minusDays(1).toString();
};

export const getYear = (date: string): number => {
  return parseDate(date).year();
};

export const getMonth = (date: string): number => {
  return parseDate(date).month().value();
};

export const getDayOfMonth = (date: string): number => {
  return parseDate(date).dayOfMonth();
};

export const getDayOfWeek = (date: string): number => {
  return parseDate(date).dayOfWeek().value();
};

export const getBeginningOfWeek = (date: string): string => {
  const beginningOrWeek: LocalDate = parseDate(date).with(ChronoField.DAY_OF_WEEK, 1);

  return beginningOrWeek.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'));
};

export const getEndOfWeek = (date: string): string => {
  const endOfWeek: LocalDate = parseDate(date).with(ChronoField.DAY_OF_WEEK, 7);

  return endOfWeek.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'));
};

export const getBeginningOfMonth = (date: string): string => {
  const beginningOrMonth: LocalDate = parseDate(date).withDayOfMonth(1);

  return beginningOrMonth.format(DateTimeFormatter.ofPattern('yyyy-MM-dd'));
};

export const isValidDate = (date: string): boolean => {
  try {
    parseDate(date);

    return true;
  } catch {
    return false;
  }
};

export const getDateRange = <T>(daysInDuration: number, startDate: string = getToday(), value?: T): DateRange<T> => {
  const endDate = add(startDate, { days: daysInDuration });

  if (compareDate(endDate).isBefore(startDate)) {
    return { startDate: endDate, endDate: startDate, value };
  }

  return { startDate, endDate, value };
};
