import cx from 'classnames';
import type { FC, ReactElement, ReactNode } from 'react';

import { ModeOfTransportIcon } from '@zen/Booking/BookingDetails';
import type { Option } from '@zen/DesignSystem';
import { Select, SelectProps, Tile } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { modeOfTransportOptions } from './modeOfTransport.helper';
import type { ModeOfTransport } from './types';

interface Props extends Omit<SelectProps<ModeOfTransport>, 'options' | 'isClearable'> {
  isTileSelect?: boolean;
}

const ModeOfTransportSelect: FC<Props> = (props) => {
  const { hasError, onChange, isTileSelect, label: fieldLabel, variant = 'default', value, ...rest } = props;
  const selectLabel: ReactNode = variant === 'inline' ? fieldLabel : '';
  const tileSelectClassNames: string = cx(`grid gap-4 grid-cols-${modeOfTransportOptions.length}`);

  const tileSelect: ReactElement = (
    <div className={tileSelectClassNames} data-testid="mode-of-transport-select">
      {modeOfTransportOptions.map((mode: Option<ModeOfTransport>) => {
        const { label, value: modeValue } = mode;

        const handleClick = (): void => {
          const newMode: Nullable<ModeOfTransport> = modeValue === value ? null : modeValue;

          onChange?.(newMode);
        };

        return (
          <Tile key={modeValue} hasError={hasError} isSelected={value === modeValue} onClick={handleClick} title={label}>
            <ModeOfTransportIcon modeOfTransport={modeValue} />
          </Tile>
        );
      })}
    </div>
  );

  const defaultSelect: ReactElement = (
    <Select
      {...rest}
      label={selectLabel}
      name="modeOfTransport"
      onChange={onChange}
      options={modeOfTransportOptions}
      value={value}
      variant={variant}
    />
  );

  return isTileSelect ? tileSelect : defaultSelect;
};

export type { Props as ModeOfTransportSelectProps };

export default ModeOfTransportSelect;
