import { difference } from 'lodash';
import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';

import useTracking from '@zen/utils/hooks/useTracking';

import { TableColumnOrder, TableConfigurationTrackingAction, TableConfigurationTrackingCategory } from '../types';

export const calculateColumnOrder = (initialColumns: TableColumnOrder[], savedColumnOrder: string[]) => {
  // we need to make sure any new columns are added to the order
  // say we have saved [b,c,a] and there are new columns in initial order
  // [z,a,b,c,d], then we will try to preserve the old order
  // and append new columns [b,c,a,z,d]
  // Also, if some columns get removed we want to get rid fo them from saved config
  // eg. initial ['a', 'b', 'c'], saved: ['b','c','f','a']
  // the result will be ['b','c', 'a']
  // we also need to make sure fixed columns are in the right order

  const leftFixedColumns: string[] = initialColumns.filter((column) => column.fixed === 'left').map(({ key }) => key);
  const scrollableColumns: string[] = initialColumns.filter((column) => !column.fixed).map(({ key }) => key);
  const savedScrollableColumns: string[] = savedColumnOrder.filter((columnName) => scrollableColumns.includes(columnName));
  const rightFixedColumns: string[] = initialColumns.filter((column) => column.fixed === 'right').map(({ key }) => key);

  const missingColumns: string[] = difference(scrollableColumns, savedScrollableColumns);

  return [...leftFixedColumns, ...savedScrollableColumns, ...missingColumns, ...rightFixedColumns];
};

type ColumnConfiguration = [string[], (columnOrder: string[]) => void];

export const useColumnOrder = (tableId: string, initialColumns: TableColumnOrder[] = []): ColumnConfiguration => {
  const [columnConfig, saveColumnConfig] = useLocalStorage<Record<string, string[]>>('table-column-order', {});
  const { trackEvent } = useTracking();

  const customColumns: string[] = columnConfig?.[tableId] || [];

  const initialColumnOrder: string[] = initialColumns.map(({ key }) => key);
  const columnOrder: string[] = calculateColumnOrder(initialColumns, customColumns);

  const setColumnOrder = useCallback(
    (newColumnOrder: string[]) => {
      trackEvent({
        category: TableConfigurationTrackingCategory,
        action: TableConfigurationTrackingAction.REORDER,
        label: tableId,
        properties: {
          tableId,
          initialColumnOrder,
          newColumnOrder
        }
      });

      saveColumnConfig({
        ...columnConfig,
        [tableId]: newColumnOrder
      });
    },
    [columnConfig, saveColumnConfig, tableId, trackEvent, initialColumnOrder]
  );

  return [columnOrder, setColumnOrder];
};
