import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateLooseCargoItemMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateLooseCargoItemInput;
}>;

export type CreateLooseCargoItemMutationResult = { __typename?: 'Mutation' } & {
  cargoCreateLooseCargoItem?: GraphQLTypes.Maybe<
    { __typename?: 'CreateLooseCargoItemPayload' } & {
      cargoItem?: GraphQLTypes.Maybe<
        | ({ __typename?: 'CargoContainerType' } & Pick<GraphQLTypes.CargoContainerType, 'id'>)
        | ({ __typename?: 'CargoLooseCargoType' } & Pick<GraphQLTypes.CargoLooseCargoType, 'id'>)
        | ({ __typename?: 'CargoVehicleType' } & Pick<GraphQLTypes.CargoVehicleType, 'id'>)
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const CreateLooseCargoItemDocument = /* #__PURE__ */ gql`
  mutation createLooseCargoItem($input: CreateLooseCargoItemInput!) {
    cargoCreateLooseCargoItem(input: $input) {
      cargoItem {
        id
      }
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useCreateLooseCargoItemMutation__
 *
 * To run a mutation, you first call `useCreateLooseCargoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLooseCargoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLooseCargoItemMutation, { data, loading, error }] = useCreateLooseCargoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLooseCargoItemMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLooseCargoItemMutationResult, CreateLooseCargoItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateLooseCargoItemMutationResult, CreateLooseCargoItemMutationVariables>(
    CreateLooseCargoItemDocument,
    options
  );
}
export type CreateLooseCargoItemMutationHookResult = ReturnType<typeof useCreateLooseCargoItemMutation>;
