import cx from 'classnames';
import { type FC, type ReactNode, useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { Portal } from 'react-portal';

import { isRenderFunction } from './helper';
import PopoverArrow from './PopoverArrow';
import type { ChildrenRenderFunction, PopoverPlacement, PopoverRenderFunction, PopoverTriggerEvent } from './types';

export type Props = {
  children: ReactNode | ChildrenRenderFunction;
  closeOnOutsideClick?: boolean;
  delayHide?: number;
  delayShow?: number;
  disabled?: boolean;
  interactive?: boolean;
  isVisible?: boolean;
  offset?: [number, number];
  onVisibilityChange?: (visible: boolean) => void;
  placement?: PopoverPlacement;
  popover: ReactNode | PopoverRenderFunction;
  popoverClassNames?: string;
  renderInPortal?: boolean;
  showArrow?: boolean;
  trigger?: PopoverTriggerEvent;
  triggerClassName?: string;
  variant?: 'white' | 'dark';
};

const Popover: FC<Props> = (props) => {
  const {
    children,
    delayHide,
    delayShow,
    disabled = false,
    interactive = true,
    closeOnOutsideClick = true,
    offset,
    placement = 'bottom-start',
    popover,
    onVisibilityChange,
    isVisible = false,
    popoverClassNames,
    renderInPortal,
    showArrow,
    trigger = 'click',
    triggerClassName,
    variant = 'white'
  } = props;
  const [isBodyVisible, setIsBodyVisible] = useState<boolean>(false);

  const onVisibleChange = (visibility: boolean) => {
    onVisibilityChange?.(visibility);
    setIsBodyVisible(visibility);
  };

  const { getTooltipProps, setTooltipRef, getArrowProps, setTriggerRef, visible } = usePopperTooltip({
    delayHide,
    delayShow,
    interactive,
    offset,
    onVisibleChange,
    closeOnOutsideClick,
    placement,
    trigger,
    visible: isBodyVisible
  });

  useEffect(() => {
    setIsBodyVisible(isVisible);
  }, [isVisible]);

  const handleClose = (): void => {
    setIsBodyVisible(false);
  };

  const renderPopoverContent = (): ReactNode => {
    if (isRenderFunction(popover)) {
      return popover({ close: handleClose });
    }

    return popover;
  };

  const renderChildren = (): ReactNode => {
    if (isRenderFunction(children)) {
      return children({ isPopoverVisible: visible });
    }

    return children;
  };

  const renderPopover = (): ReactNode => {
    const className: string = cx(
      {
        'bg-white': variant === 'white',
        'bg-navy-light text-navy-dark': variant === 'dark'
      },
      'z-50 rounded shadow-popover',
      popoverClassNames
    );

    const popoverWrapper: ReactNode = (
      <div ref={setTooltipRef} role="tooltip" {...getTooltipProps({ className })}>
        <span>{renderPopoverContent()}</span>
        {showArrow && <PopoverArrow {...getArrowProps()} placement={placement} variant={variant} />}
      </div>
    );

    if (renderInPortal) {
      return <Portal>{popoverWrapper}</Portal>;
    }

    return popoverWrapper;
  };

  const triggerRef = disabled ? undefined : setTriggerRef;

  return (
    <>
      <div ref={triggerRef} className={cx(triggerClassName)}>
        {renderChildren()}
      </div>
      {visible && renderPopover()}
    </>
  );
};

export default Popover;
