import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import { cargoTypeLabelMapping } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { PalletTypeEnum } from '../../types';

interface Props extends FormFieldProps {
  isDisabled?: boolean;
  onPalletTypeChange?: (value: Nullable<PalletTypeEnum>) => void;
}

const FormPalletTypeSelect: FC<Props> = ({ onPalletTypeChange, ...props }) => {
  const palletOptions: Option<PalletTypeEnum>[] = [
    { label: cargoTypeLabelMapping[PalletTypeEnum.EURO], value: PalletTypeEnum.EURO },
    { label: cargoTypeLabelMapping[PalletTypeEnum.UK], value: PalletTypeEnum.UK },
    { label: cargoTypeLabelMapping[PalletTypeEnum.US], value: PalletTypeEnum.US },
    { label: cargoTypeLabelMapping[PalletTypeEnum.OTHER], value: PalletTypeEnum.OTHER }
  ];

  return (
    <FormSelect
      isClearable={true}
      isSearchable={false}
      onChange={onPalletTypeChange}
      options={palletOptions}
      renderMenuInPortal={true}
      {...props}
    />
  );
};

export default FormPalletTypeSelect;
