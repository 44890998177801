import { isEmpty, uniq } from 'lodash';
import getScrollParent from 'scrollparent';

import type { Optional, StringKeys } from '@zen/utils/typescript';

import type { ExpandableData, RowSpan, RowSpanConfiguration, TableColumn } from './types';

export type ColumnWidthMap = Record<string, TableColumn['width']>;

export const suffixColumnKey = (key: string = '') => {
  const rowSpanSuffix: string = 'mergeBy';

  return `${key}.${rowSpanSuffix}`;
};

export const areColumnKeysUnique = <T>(columns: TableColumn<T>[]): boolean => {
  const keys: string[] = columns.map(({ key }) => key);

  return uniq(keys).length === keys.length;
};

export const isMultiBulkSelectionInColumns = <T>(columns: TableColumn<T>[]): boolean => {
  const columnsWithBulkSelection: TableColumn<T>[] = columns.filter((column: TableColumn<T>) => column.bulkSelection);

  return columnsWithBulkSelection.length > 1;
};

export const buildColumnMap = <T>(columns: TableColumn<T>[]): Record<TableColumn['key'], TableColumn<T>> => {
  return columns.reduce((mapping: Record<TableColumn['key'], TableColumn<T>>, column: TableColumn<T>) => {
    mapping[column.key] = column;

    return mapping;
  }, {});
};

export const mergeColumnWidths = <T>(columns: TableColumn<T>[], savedWidths: ColumnWidthMap): ColumnWidthMap => {
  const configWidths = columns.reduce((widths: ColumnWidthMap, column: TableColumn<T>) => {
    const columnWidth: ColumnWidthMap = column.width ? { [column.key]: column.width } : {};

    return {
      ...widths,
      ...columnWidth
    };
  }, {});

  return {
    ...configWidths,
    ...savedWidths
  };
};

export const reorderColumns = ({
  columnOrder,
  targetColumnKey,
  draggedColumnKey
}: {
  columnOrder: string[];
  draggedColumnKey: string;
  targetColumnKey: string;
}): string[] => {
  const toIndex: number = columnOrder.indexOf(targetColumnKey);
  const fromIndex: number = columnOrder.indexOf(draggedColumnKey);
  const newColumnOrder: string[] = [...columnOrder];

  newColumnOrder.splice(fromIndex, 1);
  newColumnOrder.splice(toIndex, 0, draggedColumnKey);

  return newColumnOrder;
};

export const calculateDropMarkerOffset = ({
  columnOrder,
  targetColumnKey,
  draggedColumnKey,
  targetCellElement
}: {
  columnOrder: string[];
  draggedColumnKey: string;
  targetCellElement: Optional<HTMLTableCellElement>;
  targetColumnKey: string;
}): number => {
  // we want to show the drop marked either before or after the column that is being dragged over, this depends on the direction of drag
  const toIndex: number = columnOrder.indexOf(targetColumnKey);
  const fromIndex: number = columnOrder.indexOf(draggedColumnKey);
  const insertBefore: boolean = fromIndex > toIndex;
  const headerCellOffset: number = getElementLeftOffset(targetCellElement);
  const headerCellWidth: number = getElementWidth(targetCellElement);
  // since table is scrollable, we need to deduct whatever value it was scrolled to position marker properly with relation to the start of the table
  const scrollParentOffset: number = targetCellElement ? getScrollParent(targetCellElement)?.scrollLeft || 0 : 0;

  return insertBefore ? headerCellOffset - scrollParentOffset : headerCellOffset - scrollParentOffset + headerCellWidth;
};

export const getElementLeftOffset = (element: Optional<HTMLElement>): number => element?.offsetLeft || 0;

export const getElementWidth = (element: Optional<HTMLElement>): number => element?.clientWidth || 0;

const getMergeByColumns = <T>(columns: TableColumn<T>[]): TableColumn<T>[] =>
  columns.filter((column: TableColumn<T>) => column.mergeBy);

const getColumnKeys = <T>(columns: TableColumn<T>[]): string[] => columns.map((column: TableColumn<T>) => column.key);

const enhanceDataWithMergeIds = <T>(data: T[], mergeByColumns: TableColumn<T>[]): T[] =>
  data.map((item: T, index: number) => {
    const itemWithMergeData: Record<string, string> = {};

    mergeByColumns.forEach((column: TableColumn<T>) => {
      itemWithMergeData[suffixColumnKey(column.key)] = column.mergeBy?.(item, index) || index.toString();
    });

    return { ...item, ...itemWithMergeData };
  });

const buildRowSpanConfiguration = <T>(data: T[], columnKey: string): RowSpan[] =>
  data.reduce((configList: RowSpan[], currentItem: T, currentIndex: number) => {
    const mergeColumnKey: string = suffixColumnKey(columnKey);

    const previousItemIdentifier: Optional<unknown> = data[currentIndex - 1]?.[mergeColumnKey as keyof T];
    const currentItemIdentifier: unknown = currentItem[mergeColumnKey as keyof T];
    const nextItemIdentifier: Optional<unknown> = data[currentIndex + 1]?.[mergeColumnKey as keyof T];

    const shouldAddNewRowSpanItem: boolean =
      previousItemIdentifier !== currentItemIdentifier && currentItemIdentifier === nextItemIdentifier;
    const shouldUpdateExistingRowSpanItem: boolean = nextItemIdentifier === currentItemIdentifier;

    if (shouldAddNewRowSpanItem) {
      configList?.push({ atIndex: currentIndex, value: 1 });
    }

    if (shouldUpdateExistingRowSpanItem) {
      const lastConfigListElement = configList[configList.length - 1];
      const updatedConfigListItem = { ...lastConfigListElement, value: lastConfigListElement.value + 1 };

      configList[configList.length - 1] = updatedConfigListItem;
    }

    return configList;
  }, []);

export const getRowSpanConfiguration = <T extends object>(data: T[], columns: TableColumn<T>[]): RowSpanConfiguration => {
  const rowSpanConfig: RowSpanConfiguration = {};
  const mergeByColumns: TableColumn<T>[] = getMergeByColumns(columns);
  const mergeByColumnKeys: string[] = getColumnKeys(mergeByColumns);
  const dataWithMergeIds: T[] = enhanceDataWithMergeIds(data, mergeByColumns);

  mergeByColumnKeys.forEach((mergeByColumnKey: string) => {
    rowSpanConfig[mergeByColumnKey] = buildRowSpanConfiguration(dataWithMergeIds, mergeByColumnKey);
  });

  return rowSpanConfig;
};

export const addExpandColumn = <T>(columns: TableColumn<T>[]): TableColumn<T>[] => {
  const fixed = columns.some((column) => column.fixed === 'left') ? 'left' : undefined;

  return [
    {
      key: 'expandColumn',
      title: '',
      sortable: false,
      fixed,
      width: 80
    },
    ...columns
  ];
};

export const hasExpandableRow = <T extends {}, K = T>(data: T[]): data is ExpandableData<T, K>[] => {
  return data.some((item) => {
    return Object.hasOwn(item, 'nestedRowItems') && !isEmpty((item as ExpandableData<T, K>).nestedRowItems);
  });
};

export const addExpandableAttributes = <T extends { nestedRowItems?: K[] }, K extends {} = T>(
  data: ExpandableData<T, K>[],
  rowKey?: StringKeys<T>
) => {
  return data.map((item: T, index: number) => {
    const rowIdentifier = rowKey ? item[rowKey] : index.toString();
    const uniqueTableRowIdentifier: string = rowIdentifier?.toString() || '';

    return {
      uniqueTableRowIdentifier,
      ...item,
      nestedRowItems: item.nestedRowItems
        ? item.nestedRowItems.map((nestedItem, nestedIndex) => ({
            uniqueTableRowIdentifier: `${uniqueTableRowIdentifier}-${nestedIndex}`,
            expanded: true,
            ...nestedItem
          }))
        : undefined
    };
  });
};
