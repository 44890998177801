import * as Yup from 'yup';

const validationMessages: Record<string, string> = {
  description: 'Description is required',
  reason: 'Reason is required',
  responsibleParty: 'Responsible party is required'
};

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  description: Yup.string().required(validationMessages.description),
  reason: Yup.string().required(validationMessages.reason),
  responsibleParty: Yup.string().nullable().required(validationMessages.responsibleParty)
});

export { validationMessages as shippingDelayReasonValidationMessages };
