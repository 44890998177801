import type { FC } from 'react';

import GeosuggestAutocomplete, { GeosuggestAutocompleteProps } from '@zen/Components/GeosuggestAutocomplete';
import type { Optional } from '@zen/utils/typescript';

import FormField, { FormFieldProps } from '../FormField';

interface Props extends Omit<GeosuggestAutocompleteProps, 'name'>, FormFieldProps {}

const FormGeosuggestAutocomplete: FC<Props> = (props) => (
  <FormField {...props}>
    {(field: { error: boolean; label: string; name: string; value: Optional<string> }) => {
      const { label, ...rest } = field;

      return <GeosuggestAutocomplete {...rest} hasError={field.error} name={field.name} value={field.value} />;
    }}
  </FormField>
);

export default FormGeosuggestAutocomplete;
