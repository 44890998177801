import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardCreateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateRateCardInput;
}>;

export type RateCardCreateMutationResult = { __typename?: 'Mutation' } & {
  createRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'CreateRateCardPayload' } & Pick<GraphQLTypes.CreateRateCardPayload, 'rateCardId'> & {
        errors?: GraphQLTypes.Maybe<
          Array<GraphQLTypes.Maybe<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>
        >;
      }
  >;
};

export const RateCardCreateDocument = /* #__PURE__ */ gql`
  mutation rateCardCreate($input: CreateRateCardInput!) {
    createRateCard(input: $input) {
      errors {
        path
        message
      }
      rateCardId
    }
  }
`;

/**
 * __useRateCardCreateMutation__
 *
 * To run a mutation, you first call `useRateCardCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRateCardCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rateCardCreateMutation, { data, loading, error }] = useRateCardCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRateCardCreateMutation(
  baseOptions?: Apollo.MutationHookOptions<RateCardCreateMutationResult, RateCardCreateMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<RateCardCreateMutationResult, RateCardCreateMutationVariables>(RateCardCreateDocument, options);
}
export type RateCardCreateMutationHookResult = ReturnType<typeof useRateCardCreateMutation>;
