import pluralize from 'pluralize';

import type { QuoteOption } from './types';
import { QuoteOptionRejectionReason } from './types';

const reasons: QuoteOptionRejectionReason[] = [
  QuoteOptionRejectionReason.PRICE,
  QuoteOptionRejectionReason.SCHEDULE,
  QuoteOptionRejectionReason.RESPONSE_TIME,
  QuoteOptionRejectionReason.OTHER
];

export const generateQuoteStatusLabel = (activeQuoteOptions: number, activatedQuoteOptions: number): string => {
  if (activeQuoteOptions > 0) {
    return `${activeQuoteOptions} Active ${pluralize('quote', activeQuoteOptions)} ${pluralize('option', activeQuoteOptions)}`;
  }

  if (activeQuoteOptions === 0 && activatedQuoteOptions > 0) {
    return 'No valid quote options';
  }

  return 'Requested';
};

export const isOtherReason = (reason: QuoteOptionRejectionReason): boolean => {
  return reason === QuoteOptionRejectionReason.OTHER;
};

export const isReasonProvided = (quoteOption: Pick<QuoteOption, 'rejectionReason' | 'reasonDescription'>): boolean => {
  const { rejectionReason, reasonDescription } = quoteOption;

  // no selected reason, don't block fields and keep them clickable
  if (rejectionReason === QuoteOptionRejectionReason.UNKNOWN) {
    return false;
  }

  // if other has been selected check description
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'QuoteOptionRejectionReason.ACCEP... Remove this comment to see the full error message
  if (isOtherReason(rejectionReason)) {
    return !!reasonDescription;
  }

  // block if any other reasons has been selected
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'QuoteOptionRejectionReason.ACCEP... Remove this comment to see the full error message
  return reasons.includes(rejectionReason);
};
