import Bowser from 'bowser';
import { FC, ReactNode, useEffect, useState } from 'react';

import { Dialog } from '@zen/DesignSystem';

import { ReactComponent as OutdatedBrowserImage } from './outdated-browser.svg';

const OutdatedBrowser: FC = () => {
  const [isValidBrowser, setIsValidBrowser] = useState<boolean>(true);
  const [closed, setClosed] = useState<boolean>(false);

  const message: ReactNode = (
    <div>
      <div className="flex justify-center mt-4 mb-2">
        <OutdatedBrowserImage className="w-56" />
      </div>
      <div>
        Your web browser is outdated, some features will not work. We strongly recommend you to upgrade or change browser.
      </div>
    </div>
  );

  useEffect(() => {
    checkBrowser();
  }, []);

  const checkBrowser = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    const isValid = browser.satisfies({
      // declare browsers per OS
      windows: {
        'internet explorer': '>11'
      },
      macos: {
        safari: '>14'
      },

      // per platform (mobile, desktop or tablet)
      mobile: {
        safari: '>12',
        'android browser': '>109'
      },

      // or in general
      chrome: '>87',
      chromium: '>87',
      edge: '>88',
      electron: '>11.2',
      firefox: '>67',
      opera: '>51'
    });

    setIsValidBrowser(!!isValid);
  };

  if (isValidBrowser || closed) {
    return null;
  }

  const close = () => setClosed(true);

  return (
    <Dialog
      confirmLabel="OK, got it"
      hasCancelButton={false}
      header="Please upgrade your browser"
      isOpen={true}
      message={message}
      onClose={close}
      onConfirm={() => setClosed(true)}
    />
  );
};

export default OutdatedBrowser;
