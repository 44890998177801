import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';

import { requestedByOptions } from './helpers';

interface Props extends FormFieldProps {}

const FormRequestedBySelect: FC<Props> = (props) => {
  return <FormSelect noMargin={true} {...props} options={requestedByOptions} renderMenuInPortal={true} />;
};

export default FormRequestedBySelect;
