import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AccountContactsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  searchContains: GraphQLTypes.Scalars['String'];
  withTerminals: GraphQLTypes.Scalars['Boolean'];
  displayHeadquartersAtTop?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Boolean']>;
}>;

export type AccountContactsQueryResult = { __typename?: 'Query' } & {
  terminals?: GraphQLTypes.Maybe<
    { __typename?: 'TerminalConnection' } & {
      nodes?: GraphQLTypes.Maybe<
        Array<GraphQLTypes.Maybe<{ __typename?: 'Terminal' } & Pick<GraphQLTypes.Terminal, 'label' | 'unlocode' | 'id'>>>
      >;
    }
  >;
  searchAccountAndConnectionLocations?: GraphQLTypes.Maybe<
    { __typename?: 'AccountLocationConnection' } & {
      nodes: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
              label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>>;
            }
        >
      >;
    }
  >;
};

export const AccountContactsDocument = /* #__PURE__ */ gql`
  query accountContacts(
    $accountId: String!
    $searchContains: String!
    $withTerminals: Boolean!
    $displayHeadquartersAtTop: Boolean
  ) {
    terminals(query: $searchContains) @include(if: $withTerminals) {
      nodes {
        label
        unlocode
        id
      }
    }
    searchAccountAndConnectionLocations(
      accountId: $accountId
      first: 50
      searchAccountAndConnectionLocationsFiltersInput: {
        accountIds: []
        displayHeadquartersAtTop: $displayHeadquartersAtTop
        searchQuery: $searchContains
      }
    ) {
      nodes {
        id
        label {
          long
        }
      }
    }
  }
`;

/**
 * __useAccountContactsQuery__
 *
 * To run a query within a React component, call `useAccountContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountContactsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      searchContains: // value for 'searchContains'
 *      withTerminals: // value for 'withTerminals'
 *      displayHeadquartersAtTop: // value for 'displayHeadquartersAtTop'
 *   },
 * });
 */
export function useAccountContactsQuery(
  baseOptions: Apollo.QueryHookOptions<AccountContactsQueryResult, AccountContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AccountContactsQueryResult, AccountContactsQueryVariables>(AccountContactsDocument, options);
}
export function useAccountContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AccountContactsQueryResult, AccountContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AccountContactsQueryResult, AccountContactsQueryVariables>(AccountContactsDocument, options);
}
export type AccountContactsQueryHookResult = ReturnType<typeof useAccountContactsQuery>;
export type AccountContactsLazyQueryHookResult = ReturnType<typeof useAccountContactsLazyQuery>;
