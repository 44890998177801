import { omit } from 'lodash';

import { getDefaultLatLng } from '@zen/Accounts/AccountForms/helpers';

import { LocationLink } from '../../components/CompanyNameFields/helpers';
import type { CreateAccountLocationFormValues, CreateAccountLocationInput } from './types';

const prepareValues = (
  accountId: string,
  accountName: string,
  formValues: CreateAccountLocationFormValues
): CreateAccountLocationInput => {
  const { companyName, linkType, businessHoursOption, shippingLocationAddress, geolocation, ...rest } = formValues;

  return {
    accountId,
    location: {
      ...rest,
      geolocation: omit(geolocation, 'isPinPlacementConfirmed') || getDefaultLatLng(),
      address: shippingLocationAddress,
      companyName: linkType === LocationLink.MY_COMPANY ? accountName : companyName
    }
  };
};

export { prepareValues };
