import { get, uniqueId } from 'lodash';
import { type FC, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { defaultErrorMessage } from '@zen/utils/validation';

import { useUpdateCarrierMutation } from './graphql';

interface Props {
  canUpdateCarrier: boolean;
  carrier: string;
}

const CarrierInlineField: FC<Props> = ({ canUpdateCarrier, carrier }) => {
  const { addSuccess, addError } = useNotification();
  const { cargoIds, zencargoReference } = useCargoOverviewContext();
  const [updateCarrier] = useUpdateCarrierMutation();
  const [inputKey, setInputKey] = useState(uniqueId());

  const label: string = 'Carrier (SCAC)';

  const handleChange = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateCarrier({
          awaitRefetchQueries: true,
          refetchQueries: ['cargoOverview'],
          variables: {
            input: {
              cargoIds,
              scac: value,
              zencargoReference
            }
          }
        }),
      onError: (errors): void => {
        setInputKey(uniqueId());

        addError(get(errors, '[0].message', defaultErrorMessage));
      },
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateCarrier) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={carrier} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      key={inputKey}
      label={label}
      labelPlacement="left"
      name="carrier"
      onUpdate={handleChange}
      placeholder="Enter carrier (SCAC)"
      value={carrier}
    />
  );
};

export default CarrierInlineField;
