import type { ReactNode } from 'react';

import { ModeOfTransport } from '@zen/Booking';
import type { PillType } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import { requestedByLabelMapping } from '../../helpers';
import { JourneyShippingMilestoneRequestedByEnum, JourneyStopLocationTypeEnum, ShippingMilestoneDateTypeEnum } from '../../types';

interface DateTypeConfig {
  label: string;
  pillType: PillType;
  tooltipContent?: ReactNode;
}

interface DateTypeParams {
  dateType: ShippingMilestoneDateTypeEnum;
  locationType: JourneyStopLocationTypeEnum;
  modeOfTransport: Optional<ModeOfTransport>;
  requestedBy: Optional<JourneyShippingMilestoneRequestedByEnum>;
}

const getRequestedByLabel = (requestedBy: Optional<JourneyShippingMilestoneRequestedByEnum>): string => {
  if (requestedBy === JourneyShippingMilestoneRequestedByEnum.ZENCARGO) {
    return requestedByLabelMapping[requestedBy];
  }

  const label = requestedBy
    ? requestedByLabelMapping[requestedBy]
    : requestedByLabelMapping[JourneyShippingMilestoneRequestedByEnum.CUSTOMER];

  return label.toLowerCase();
};

const getRequestedStateConfig = (
  locationType: JourneyStopLocationTypeEnum,
  requestedBy: Optional<JourneyShippingMilestoneRequestedByEnum>
): DateTypeConfig => {
  if (locationType === JourneyStopLocationTypeEnum.WAREHOUSE) {
    const requestedByLabel: string = getRequestedByLabel(requestedBy);

    return {
      label: 'Requested',
      pillType: 'warning',
      tooltipContent: `Dates are requested by ${requestedByLabel}`
    };
  }

  return {
    label: 'Planned',
    pillType: 'warning',
    tooltipContent: 'Planned by Zencargo'
  };
};

const getPartyName = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (!modeOfTransport) return 'carrier';

  const mapping: Record<ModeOfTransport, string> = {
    [ModeOfTransport.AIR]: 'airline',
    [ModeOfTransport.OCEAN]: 'carrier',
    [ModeOfTransport.RAIL]: 'rail company',
    [ModeOfTransport.TRUCK]: 'haulier'
  };

  return mapping[modeOfTransport];
};

export const getDateTypeConfig = ({ dateType, locationType, modeOfTransport, requestedBy }: DateTypeParams): DateTypeConfig => {
  const dateTypeConfig: Record<ShippingMilestoneDateTypeEnum, DateTypeConfig> = {
    [ShippingMilestoneDateTypeEnum.DRAFT]: {
      label: 'Calculated',
      pillType: 'pending',
      tooltipContent: 'Dates are calculated based on previous milestones'
    },
    [ShippingMilestoneDateTypeEnum.PLANNED]: {
      label: 'Booked',
      pillType: 'default',
      tooltipContent: `This is the agreed upon date between customer, ${getPartyName(modeOfTransport)} and Zencargo`
    },
    [ShippingMilestoneDateTypeEnum.REQUESTED]: getRequestedStateConfig(locationType, requestedBy)
  };

  return dateTypeConfig[dateType];
};
