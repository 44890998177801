import { createContext } from 'react';

import type { Permissions } from '@zen/graphql/types.generated';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

export const initialValue = {
  customerUser: false,
  agentForwarder: false,
  manufacturer: false
};

const ActivityFeedItemPermissionsContext = createContext<Permissions>(initialValue);

export const useActivityFeedItemPermissions = (): Permissions => {
  const parties: Permissions = useContextOrThrowError<Permissions>(
    ActivityFeedItemPermissionsContext,
    'usePermittesParties must be used inside ActivityFeedItemPermissionsContext'
  );

  return parties;
};

export default ActivityFeedItemPermissionsContext;
