import { FC, FormEvent, KeyboardEvent, MouseEvent, ReactNode, Ref, SyntheticEvent, useRef } from 'react';

import type { Nullable } from '@zen/utils/typescript';

interface Props {
  children: ReactNode;
  className?: string;
  onSubmit: () => void;
  submitOnEnter: boolean;
}

const NestedForm: FC<Props> = (props) => {
  const { children, className, onSubmit, submitOnEnter, ...rest } = props;
  const ref: Ref<HTMLDivElement> = useRef(null);

  const submitForm = (event: SyntheticEvent): void => {
    event.preventDefault();
    event.stopPropagation();

    onSubmit();
  };

  const isSubmitButton = (event: SyntheticEvent): boolean => {
    let currentEl: Nullable<HTMLElement> = event.target as HTMLElement;

    while (currentEl !== ref.current && currentEl !== null) {
      if (currentEl instanceof HTMLButtonElement && currentEl.type === 'submit' && !currentEl.disabled) {
        return true;
      }
      currentEl = currentEl && currentEl.parentElement;
    }

    return false;
  };

  const handleKeyDownCapture = (event: KeyboardEvent<HTMLDivElement>): void => {
    switch (event.key) {
      case 'Enter': {
        if ((submitOnEnter && event.target instanceof HTMLInputElement) || isSubmitButton(event)) {
          submitForm(event);
        }
        break;
      }
      case 'Space': {
        if (isSubmitButton(event)) {
          submitForm(event);
        }
      }
    }
  };

  const handleClick = (event: MouseEvent): void => {
    if (isSubmitButton(event)) {
      submitForm(event);
    }
  };

  const handleSubmit = (event: FormEvent): void => {
    submitForm(event);
  };

  return (
    <div
      ref={ref}
      className={className}
      onClick={handleClick}
      onKeyDownCapture={handleKeyDownCapture}
      onSubmit={handleSubmit}
      role="form"
      {...rest}
    >
      {children}
    </div>
  );
};

export default NestedForm;
