import type { FC } from 'react';

import { MilestoneDateType } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

const milestoneDateTypeLabelMapping: Record<MilestoneDateType, string> = {
  [MilestoneDateType.DRAFT]: 'draft',
  [MilestoneDateType.REQUESTED]: 'requested',
  [MilestoneDateType.PLANNED]: 'booked',
  [MilestoneDateType.ACTUAL]: 'actual'
};

interface Props {
  dateConstraint: 'min' | 'max';
  dateType: Optional<MilestoneDateType>;
  milestoneName: string;
}

const DisabledDateTooltip: FC<Props> = ({ dateConstraint, dateType, milestoneName }) => {
  const dateTypeLabel: string = dateType ? `the ${milestoneDateTypeLabelMapping[dateType]}` : '';
  const dateConstraintLabel: string = dateConstraint === 'min' ? 'after' : 'before';

  return (
    <div className="max-w-64">
      Selected date must be {dateConstraintLabel} or on the same day as {dateTypeLabel} date for the{' '}
      <span className="font-bold">{milestoneName}</span> milestone.
    </div>
  );
};

export default DisabledDateTooltip;
