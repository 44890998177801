import { isArray, isNil, isObject, isString, mapValues, mergeWith } from 'lodash';

const trimValue = (value: unknown): unknown => {
  if (isArray(value)) {
    return value.map(trimValue);
  }

  if (isObject(value)) {
    return mapValues(value, trimValue);
  }

  if (isString(value)) {
    return value.trim();
  }

  return value;
};

export const trimObjectValues = <T extends {}>(value: T): T => {
  return mapValues(value, trimValue) as T;
};

const mergeNilValue = (a: unknown, b: unknown) => (isNil(a) ? b : a);

export const deepMergeNilValues = (a: unknown, b: unknown): unknown => {
  if (isObject(a)) {
    const initValue = isArray(a) ? [] : {};

    return mergeWith(initValue, a, b, deepMergeNilValues);
  }

  return mergeNilValue(a, b);
};

export const convertStringToNumber = (value: string, defaultValue: number | null = null): number | null => {
  const parsedValue = parseFloat(value);

  // eslint-disable-next-line
  return isNaN(parsedValue) ? defaultValue : parsedValue;
};

export const generateId = (): string => Date.now().toString();

export const joinNotNull = (words: (string | null | undefined)[], separator: string = ' '): string => {
  return words.filter(Boolean).join(separator);
};
