import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type CargoSummaryQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type CargoSummaryQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              canManageAssignedLots: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              calculatedInfo: { __typename?: 'CalculatedInfo' } & {
                cargoSummary?: GraphQLTypes.Maybe<
                  { __typename?: 'CargoSummary' } & Pick<
                    GraphQLTypes.CargoSummary,
                    'totalActualCbm' | 'label' | 'type' | 'highestRiskLevel'
                  > & {
                      totalGrossWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'value' | 'metric'>>
                      >;
                      totalChargeableWeight?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'CargoWeightType' } & Pick<GraphQLTypes.CargoWeightType, 'value' | 'metric'>>
                      >;
                      totalValueOfGoods?: GraphQLTypes.Maybe<
                        Array<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'currency' | 'value'>>
                      >;
                    }
                >;
              };
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    consolidatedCargo?: GraphQLTypes.Maybe<
                      { __typename?: 'ConsolidatedCargo' } & {
                        consolidatedCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'CargoConsolidatedItemType' } & Pick<
                              GraphQLTypes.CargoConsolidatedItemType,
                              'quantity' | 'type' | 'subType'
                            >
                          >
                        >;
                        consolidatedLooseCargoItems?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'ConsolidatedLooseCargoItem' } & Pick<
                              GraphQLTypes.ConsolidatedLooseCargoItem,
                              'quantity' | 'type'
                            >
                          >
                        >;
                      }
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const CargoSummaryDocument = /* #__PURE__ */ gql`
  query cargoSummary($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        canManageAssignedLots {
          value
        }
        calculatedInfo {
          cargoSummary {
            totalGrossWeight {
              value
              metric
            }
            totalChargeableWeight {
              value
              metric
            }
            totalValueOfGoods {
              currency
              value
            }
            totalActualCbm
            label
            type
            highestRiskLevel
          }
        }
        cargo {
          mode
          consolidatedCargo {
            consolidatedCargoItems {
              quantity
              type
              subType
            }
            consolidatedLooseCargoItems {
              quantity
              type
            }
          }
        }
        modeOfTransport
      }
    }
  }
`;

/**
 * __useCargoSummaryQuery__
 *
 * To run a query within a React component, call `useCargoSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useCargoSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCargoSummaryQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useCargoSummaryQuery(baseOptions: Apollo.QueryHookOptions<CargoSummaryQueryResult, CargoSummaryQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CargoSummaryQueryResult, CargoSummaryQueryVariables>(CargoSummaryDocument, options);
}
export function useCargoSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CargoSummaryQueryResult, CargoSummaryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CargoSummaryQueryResult, CargoSummaryQueryVariables>(CargoSummaryDocument, options);
}
export type CargoSummaryQueryHookResult = ReturnType<typeof useCargoSummaryQuery>;
export type CargoSummaryLazyQueryHookResult = ReturnType<typeof useCargoSummaryLazyQuery>;
