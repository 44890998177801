import type { FC } from 'react';

import type { PermittedParties, PermittedParty } from '@zen/ActivityFeed';

import PartyControl from '../PartyControl/PartyControl';
import PermissionSwitcher from '../PermissionSwitcher';
import type { PermissionsInput } from '../types';

interface Props {
  onPermissionChange: (key: keyof PermissionsInput, permissionValue: boolean) => void;
  onPermitAll: (isChecked: boolean) => void;
  permissions: PermissionsInput;
  permitAll: boolean;
  permittedParties: PermittedParties;
}

const PermissionControls: FC<Props> = ({ onPermissionChange, permissions, permittedParties, permitAll, onPermitAll }) => {
  const { customerUser, manufacturer, agentForwarder, forwarder } = permittedParties;

  return (
    <div className="px-6">
      <PermissionSwitcher checked={permitAll} icon="zicon-network" name="everyone" onChange={onPermitAll} title="Everyone" />
      {customerUser && (
        <PartyControl
          isChecked={permissions.customerUser}
          isDisabled={permitAll}
          onPermissionChange={onPermissionChange}
          party={customerUser}
          permissionKey="customerUser"
        />
      )}
      {manufacturer &&
        manufacturer.map((manufacturerDetails: PermittedParty, index: number) => (
          <div key={index}>
            <PartyControl
              isChecked={permissions.manufacturer}
              isDisabled={permitAll}
              onPermissionChange={onPermissionChange}
              party={manufacturerDetails}
              permissionKey="manufacturer"
            />
          </div>
        ))}
      {agentForwarder && (
        <PartyControl
          isChecked={permissions.agentForwarder}
          isDisabled={permitAll}
          onPermissionChange={onPermissionChange}
          party={agentForwarder}
          permissionKey="agentForwarder"
        />
      )}
      {forwarder && (
        <PartyControl
          isChecked={true}
          isDisabled={true}
          onPermissionChange={onPermissionChange}
          party={forwarder}
          permissionKey="agentForwarder"
        />
      )}
    </div>
  );
};

export default PermissionControls;
