import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { createTrackingLabelAttribute } from '@zen/utils/tracking';

import { headlineStyles } from './helpers';
import type { HeadlineLevel, HeadlineTagVariant } from './types';

interface Props {
  children: ReactNode;
  className?: string;
  level: HeadlineLevel;
}

const Headline: FC<Props> = ({ className = '', children, level, ...rest }) => {
  const HeadlineTag: HeadlineTagVariant = `h${level}` as HeadlineTagVariant;

  const classNames: string = cx(className, headlineStyles[level], 'text-grey-dark');

  return (
    <HeadlineTag
      className={classNames}
      data-component="headline"
      data-testid={`headline-${level}`}
      {...rest}
      {...createTrackingLabelAttribute()}
    >
      {children}
    </HeadlineTag>
  );
};

export type { Props as HeadlineProps };

export default Headline;
