import Search from '@zen/Components/Search';
import { ShipmentFilterStatus } from '@zen/Shipments';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import Filters from '../Filters';
import type { CustomerAdminShipmentFilters } from '../Filters/types';

const Toolbar = () => {
  const { filters, search, setFilters, setSearch } = useUrlFilters<CustomerAdminShipmentFilters>({
    status: ShipmentFilterStatus.ACTIVE
  });

  return (
    <div data-testid="all-shipments-toolbar">
      <div className="flex space-x-2">
        <Search
          collapsible={true}
          initialValue={search}
          onClear={() => setSearch(undefined)}
          onSubmit={setSearch}
          placeholder="Search for a shipment..."
          size="compact"
          widthClassName="w-48"
        />

        <Filters filters={filters} onChange={setFilters} />
      </div>
    </div>
  );
};

export default Toolbar;
