import type { FC } from 'react';

import { Body, Button, Headline, Icon } from '@zen/DesignSystem';

interface Props {
  onAction: () => void;
}

const PostDownloadContent: FC<Props> = ({ onAction }) => (
  <>
    <Icon className="m-10 text-5xl text-green-base" icon="zicon-tickoval" />
    <Headline className="m-4 text-grey-base" level={2}>
      Your download has started
    </Headline>
    <Body className="text-grey-base">Got the file? You can now safely close this window or launch Zencargo.</Body>
    <Button className="m-4" onClick={onAction}>
      Launch Zencargo
    </Button>
  </>
);

export default PostDownloadContent;
