import type { FC } from 'react';

import TimeZoneIcon from '../TimeZoneIcon';

interface Props {
  className?: string;
}

const LocalTimeZone: FC<Props> = ({ className }) => {
  const localTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return <TimeZoneIcon className={className} size="small" timeZone={localTimeZone} />;
};

export default LocalTimeZone;
