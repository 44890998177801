import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';

import type { EmptyStateConfig } from './types';

interface Props {
  editable?: boolean;
  hasPriceCalculationPoint: boolean;
  hasSailingSchedule: boolean;
  isBookingConfirmed: boolean;
}

const EmptyState: FC<Props> = ({ hasPriceCalculationPoint, hasSailingSchedule, isBookingConfirmed, editable = true }) => {
  const getEditableConfig = () => {
    const noPriceCalculation: EmptyStateConfig = {
      label: 'Unknown price calculation date',
      tooltip:
        'The price calculation point and/or date for this shipment has not been set yet. The applicable rate card will be available once the price calculation is defined.'
    };

    const noRateCard: EmptyStateConfig = {
      label: 'No rate card available',
      tooltip:
        "We don't have a rate card available for the specified date or route. We'll make the rate card available as soon as it's issued."
    };

    const missingSailingSchedule: EmptyStateConfig = {
      label: 'Waiting for sailing schedule'
    };

    const priceCalculationConfig = hasPriceCalculationPoint ? noRateCard : noPriceCalculation;

    return !hasSailingSchedule ? missingSailingSchedule : priceCalculationConfig;
  };

  const getNonEditableConfig = () => {
    const noRateCard: EmptyStateConfig = {
      label: 'Rate card not yet available',
      tooltip:
        "We don't yet have a rate card available for this specified date or route. You'll find a rate card here as soon as it's available."
    };

    const missingShipmentInformation = {
      label: 'Waiting for shipment information',
      tooltip:
        "We don't yet have enough information to match this shipment with a rate card. You'll find a rate card here as soon as it's available."
    };

    const bookingNotConfirmed = {
      label: 'Booking not yet confirmed',
      tooltip: 'Rate card is not available for unconfirmed bookings.'
    };

    const bookingConfirmed = hasPriceCalculationPoint && hasSailingSchedule ? noRateCard : missingShipmentInformation;

    return isBookingConfirmed ? bookingConfirmed : bookingNotConfirmed;
  };

  const config = editable ? getEditableConfig() : getNonEditableConfig();

  return (
    <Pill tooltip={config.tooltip} type="pending">
      {config.label}
    </Pill>
  );
};

export default EmptyState;
