import moment from 'moment';
import type { FC } from 'react';

import { FormInput } from '@zen/Components/Form';
import FormDatePickerInput from '@zen/Components/Form/FormDatePickerInput';
import { formatDate } from '@zen/utils/dateTime';
import { FORMAT_DATE_TRANSFERABLE } from '@zen/utils/formatting';

const today: string = moment().format(FORMAT_DATE_TRANSFERABLE);

const GeneralInformation: FC = () => (
  <div className="flex">
    <FormInput className="flex-[7]" isRequired={true} label="Order number" name="orderReferenceNumber" placeholder="123456789" />
    <FormDatePickerInput className="flex-[3] ml-4" label="Date raised" name="orderDate" placeholder={formatDate(today)} />
  </div>
);

export default GeneralInformation;
