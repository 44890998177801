import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateAccountLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateAccountLocationInput;
}>;

export type CreateAccountLocationMutationResult = { __typename?: 'Mutation' } & {
  createAccountLocation?: GraphQLTypes.Maybe<
    { __typename?: 'CreateAccountLocationPayload' } & Pick<GraphQLTypes.CreateAccountLocationPayload, 'locationId'> & {
        errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      }
  >;
};

export const CreateAccountLocationDocument = /* #__PURE__ */ gql`
  mutation createAccountLocation($input: CreateAccountLocationInput!) {
    createAccountLocation(input: $input) {
      errors {
        message
        path
      }
      locationId
    }
  }
`;

/**
 * __useCreateAccountLocationMutation__
 *
 * To run a mutation, you first call `useCreateAccountLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountLocationMutation, { data, loading, error }] = useCreateAccountLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAccountLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAccountLocationMutationResult, CreateAccountLocationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateAccountLocationMutationResult, CreateAccountLocationMutationVariables>(
    CreateAccountLocationDocument,
    options
  );
}
export type CreateAccountLocationMutationHookResult = ReturnType<typeof useCreateAccountLocationMutation>;
