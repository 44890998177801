import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateCourierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCourierInput;
}>;

export type UpdateCourierMutationResult = { __typename?: 'Mutation' } & {
  updateCourier: { __typename?: 'UpdateCourierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    courier?: GraphQLTypes.Maybe<{ __typename?: 'Courier' } & Pick<GraphQLTypes.Courier, 'courierName' | 'id'>>;
  };
};

export const UpdateCourierDocument = /* #__PURE__ */ gql`
  mutation updateCourier($input: UpdateCourierInput!) {
    updateCourier(input: $input) {
      errors {
        message
        path
      }
      courier {
        courierName
        id
      }
    }
  }
`;

/**
 * __useUpdateCourierMutation__
 *
 * To run a mutation, you first call `useUpdateCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourierMutation, { data, loading, error }] = useUpdateCourierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCourierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCourierMutationResult, UpdateCourierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateCourierMutationResult, UpdateCourierMutationVariables>(UpdateCourierDocument, options);
}
export type UpdateCourierMutationHookResult = ReturnType<typeof useUpdateCourierMutation>;
