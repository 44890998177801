import type { FC } from 'react';

import type { PermittedParties as PermittedPartiesType, PermittedParty } from '../../types';
import PermittedPartiesItem from './PermittedPartiesItem';

interface Props {
  parties: PermittedPartiesType;
}

const PermittedParties: FC<Props> = ({ parties }) => {
  const { customerUser, agentForwarder, forwarder, manufacturer } = parties;

  return (
    <div className="max-w-100" data-testid="permitted-parties">
      <p className="text-xs font-bold text-grey-dark">Who can see this message</p>
      <div>
        {agentForwarder && <PermittedPartiesItem party={agentForwarder} />}
        {customerUser && <PermittedPartiesItem party={customerUser} />}
        {manufacturer && manufacturer.map((m: PermittedParty, index: number) => <PermittedPartiesItem key={index} party={m} />)}
        {forwarder && <PermittedPartiesItem party={forwarder} />}
      </div>
    </div>
  );
};

export default PermittedParties;
