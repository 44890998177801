import { useWindowSize } from 'react-use';

import { Breakpoint } from '@zen/types';

const calculateBreakpoint = (width: number): Breakpoint => {
  if (width < Breakpoint.MD) {
    return Breakpoint.SM;
  }
  if (width >= Breakpoint.MD && width < Breakpoint.LG) {
    return Breakpoint.MD;
  }
  if (width >= Breakpoint.LG && width < Breakpoint.XL) {
    return Breakpoint.LG;
  }
  if (width >= Breakpoint.XL && width < Breakpoint.XXL) {
    return Breakpoint.XL;
  }
  if (width >= Breakpoint.XXL && width < Breakpoint.XXXL) {
    return Breakpoint.XXL;
  }

  return Breakpoint.XXXL;
};

const useBreakpoint = (): { currentBreakpoint: Breakpoint } => {
  const { width } = useWindowSize();

  return {
    currentBreakpoint: calculateBreakpoint(width)
  };
};

export default useBreakpoint;
