import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useCreateLooseCargoItemMutation } from '../../../graphql';
import { CargoItemTypeEnum, CargoManagementTrackingAction, CargoManagementTrackingCategory } from '../../../types';
import { prepareLooseCargoInput } from '../helpers';
import LooseCargoForm from '../LooseCargoForm/LooseCargoForm';
import type { LooseFormValues } from '../LooseFormFields/types';

interface Props {
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSuccess: (cargoId: string) => void;
  zencargoReference: string;
}

const NewLooseCargo: FC<Props> = ({ zencargoReference, modeOfTransport, onCancel, onSuccess }) => {
  const [createLooseCargo] = useCreateLooseCargoItemMutation();
  const { trackEvent } = useTracking();

  const handleSuccess = (cargoId: string): void => {
    onSuccess(cargoId);

    trackEvent({
      category: CargoManagementTrackingCategory,
      action: CargoManagementTrackingAction.ADD_NEW_CARGO_ITEM,
      label: CargoItemTypeEnum.LOOSE_CARGO
    });
  };

  const handleSubmit = async (values: LooseFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        createLooseCargo({
          variables: {
            input: { zencargoReference, ...prepareLooseCargoInput(values) }
          },
          refetchQueries: ['cargoSummary', 'cargoOverview'],
          awaitRefetchQueries: true
        })
    });
  };

  return (
    <LooseCargoForm modeOfTransport={modeOfTransport} onCancel={onCancel} onSubmit={handleSubmit} onSuccess={handleSuccess} />
  );
};

export default NewLooseCargo;
