import cx from 'classnames';

interface ClassConfig {
  className?: string;
  hasError: boolean;
  isInline: boolean;
}

export const getClassNames = ({ className, hasError, isInline }: ClassConfig) => ({
  caption_label: 'font-bold text-lg px-1 text-grey-dark leading-8',
  caption: cx({ 'rounded-tr rounded-tl': !isInline }, 'flex justify-center items-center px-4 pt-4 pb-12 bg-grey-lightest'),
  cell: 'w-10 h-10 align-middle text-center border-0 border-unset p-0',
  day_disabled: 'opacity-[.48] hover:!bg-white cursor-not-allowed js-disabled',
  day_hidden: 'hidden',
  day_outside: 'enabled:opacity-50',
  day_range_middle:
    'bg-azure-lightest rounded-none !text-grey-dark cursor-pointer hover:bg-azure-lightest hover:!text-white hover:!rounded',
  day_selected: 'text-white bg-azure-base hover:!bg-azure-base cursor-default',
  day_today: 'border border-solid border-azure-base',
  day: 'rounded w-10 h-10 transition-colors text-sm text-grey-dark hover:bg-grey-dark/[.08]',
  head_cell: 'w-10 h-10 uppercase align-middle text-center text-grey-base pb-7 font-normal',
  months: cx(
    {
      'border-red-base': hasError,
      'border-grey-lighter': !hasError,
      rounded: !isInline
    },
    'border border-solid inline-flex relative bg-white',
    className
  ),
  nav_button_next: 'top-3 right-2.5',
  nav_button_previous: 'top-3 left-2.5',
  nav_button: 'inline-flex justify-center items-center absolute top-0 w-10 h-10 text-grey-dark',
  table: 'border-collapse border-spacing-0 -mt-8 mx-3 mb-2.5'
});
