import type { HaulageTableColumnTitleConfig } from './types';

const originHaulageTableColumnTitleConfig: HaulageTableColumnTitleConfig = {
  chargeBasis: 'Charge basis',
  location: 'Origin address',
  port: 'Port of origin',
  chargeType: 'Charge type',
  unit: 'Unit',
  incoterms: 'Incoterms',
  cost: 'Cost'
};

const destinationHaulageTableColumnTitleConfig: HaulageTableColumnTitleConfig = {
  chargeBasis: 'Charge basis',
  location: 'Destination address',
  port: 'Port of destination',
  chargeType: 'Charge type',
  unit: 'Unit',
  incoterms: 'Incoterms',
  cost: 'Cost'
};

export const getHaulageTableColumnTitleConfig = (type: 'origin' | 'destination'): HaulageTableColumnTitleConfig => {
  return type === 'origin' ? originHaulageTableColumnTitleConfig : destinationHaulageTableColumnTitleConfig;
};
