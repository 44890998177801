import { mapValues } from 'lodash';

import { BookingStageEnum, ModeOfTransport, StageNameType, VoyageMilestoneNameEnum } from './types';

type MilestoneTimeConfig = { [K in StageNameType]: string };
type MilestoneLabelConfig = MilestoneTimeConfig | string;
type MileStoneLabelType = { [K in ModeOfTransport]: MilestoneLabelConfig };

const mapLabelToTransportMode = (label: MilestoneLabelConfig): MileStoneLabelType => mapValues(ModeOfTransport, () => label);

const labelConfig: Record<VoyageMilestoneNameEnum | BookingStageEnum, MileStoneLabelType> = {
  [BookingStageEnum.BOOKING_REQUESTED]: mapLabelToTransportMode('Booking requested'),
  [BookingStageEnum.BOOKING_RECEIVED]: mapLabelToTransportMode('Booking received'),
  [BookingStageEnum.BOOKING_CONFIRMED]: mapLabelToTransportMode('Booking confirmed'),
  [VoyageMilestoneNameEnum.GATE_OUT_EMPTY]: mapLabelToTransportMode('En route to pick-up'),
  [VoyageMilestoneNameEnum.COLLECTED]: {
    ...mapLabelToTransportMode({
      future: 'Collecting',
      past: 'Collected',
      standalone: 'Collected'
    }),
    [ModeOfTransport.TRUCK]: {
      future: 'Collecting',
      past: 'Collected',
      standalone: 'In transit'
    }
  },
  [VoyageMilestoneNameEnum.GATE_IN]: {
    [ModeOfTransport.TRUCK]: '',
    [ModeOfTransport.RAIL]: {
      past: 'Arrived at origin station',
      future: 'Arriving at origin station',
      standalone: 'Arrived at origin station'
    },
    [ModeOfTransport.OCEAN]: {
      past: 'Arrived at port of origin',
      future: 'Arriving at port of origin',
      standalone: 'Arrived at port of origin'
    },
    [ModeOfTransport.AIR]: {
      past: 'Arrived at origin airport',
      future: 'Arriving at origin airport',
      standalone: 'Arrived at origin airport'
    }
  },
  [VoyageMilestoneNameEnum.CARGO_ABOARD]: {
    [ModeOfTransport.TRUCK]: '',
    [ModeOfTransport.RAIL]: 'Cargo loaded',
    [ModeOfTransport.OCEAN]: 'Cargo aboard',
    [ModeOfTransport.AIR]: 'Cargo loaded'
  },
  [VoyageMilestoneNameEnum.DEPARTED_ORIGIN_TERMINAL]: {
    [ModeOfTransport.TRUCK]: '',
    [ModeOfTransport.RAIL]: {
      future: 'Departing from origin station',
      past: 'Departed from origin station',
      standalone: 'In transit'
    },
    [ModeOfTransport.OCEAN]: {
      future: 'Departing from port of origin',
      past: 'Departed from port of origin',
      standalone: 'In transit'
    },
    [ModeOfTransport.AIR]: {
      future: 'Departing from origin airport',
      past: 'Departed from origin airport',
      standalone: 'In transit'
    }
  },

  [VoyageMilestoneNameEnum.ARRIVED_AT_DESTINATION_TERMINAL]: {
    [ModeOfTransport.TRUCK]: '',
    [ModeOfTransport.RAIL]: {
      future: 'Arriving at destination station',
      past: 'Arrived at destination station',
      standalone: 'Arrived at destination station'
    },
    [ModeOfTransport.OCEAN]: {
      future: 'Arriving at port of destination',
      past: 'Arrived at port of destination',
      standalone: 'Arrived at port of destination'
    },
    [ModeOfTransport.AIR]: {
      future: 'Arriving at destination airport',
      past: 'Arrived at destination airport',
      standalone: 'Arrived at destination airport'
    }
  },
  [VoyageMilestoneNameEnum.CARGO_UNLOADED]: mapLabelToTransportMode('Cargo unloaded'),
  [VoyageMilestoneNameEnum.GATE_OUT]: mapLabelToTransportMode('En route to final destination'),
  [VoyageMilestoneNameEnum.DELIVERED]: mapLabelToTransportMode({
    future: 'Delivering',
    past: 'Delivered',
    standalone: 'Delivered'
  })
};

export const getStageLabel = (
  name: VoyageMilestoneNameEnum | BookingStageEnum,
  transportMode: ModeOfTransport = ModeOfTransport.OCEAN,
  type: StageNameType = 'standalone'
): string => {
  const stageLabel = labelConfig[name][transportMode];
  const milestoneLabelWithTime = stageLabel as MilestoneTimeConfig;

  return milestoneLabelWithTime[type] || (stageLabel as string);
};
