import { get } from 'lodash';
import type { FC } from 'react';

import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import { FormInput } from '@zen/Components';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import { useUpdateAccountLocationNameMutation } from '../../graphql';
import { locationNameValidation } from './locationDetails.validation';
import type { LocationNameFormValues } from './types';

type Props = {
  initialValues: LocationNameFormValues;
  locationId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const UpdateLocationName: FC<Props> = ({ initialValues, locationId, onCancel, onSuccess }) => {
  const [updateLocation] = useUpdateAccountLocationNameMutation({ refetchQueries: ['getAccountLocation'] });

  const { addError, addSuccess } = useNotification();

  const handleUpdateLocationName = async ({ name }: LocationNameFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateLocation({
          variables: {
            input: {
              locationId,
              name
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was a problem updating the location name.'));
      },
      onSuccess: () => addSuccess('Location name updated.')
    });
  };

  return (
    <AccountFormWrapper<LocationNameFormValues>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateLocationName}
      onSuccess={onSuccess}
      validationSchema={locationNameValidation}
    >
      {() => {
        return <FormInput label="Location name" name="name" />;
      }}
    </AccountFormWrapper>
  );
};

export default UpdateLocationName;
export type { Props as UpdateLocationNameProps };
