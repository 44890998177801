import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinancePublishAccrualMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.PublishAccrualMutationInput;
}>;

export type FinancePublishAccrualMutationResult = { __typename?: 'Mutation' } & {
  financePublishAccrual?: GraphQLTypes.Maybe<
    { __typename?: 'PublishAccrualMutationPayload' } & {
      accrual?: GraphQLTypes.Maybe<
        { __typename?: 'Accrual' } & Pick<GraphQLTypes.Accrual, 'state'> & {
            canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canPublish: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            canUpdateInvoicedDate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            publishedAt?: GraphQLTypes.Maybe<
              { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
            >;
            publishedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
            updatedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
            updatedBy?: GraphQLTypes.Maybe<{ __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username'>>;
          }
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const FinancePublishAccrualDocument = /* #__PURE__ */ gql`
  mutation financePublishAccrual($input: PublishAccrualMutationInput!) {
    financePublishAccrual(input: $input) {
      accrual {
        canArchive {
          value
        }
        canPublish {
          value
        }
        canUpdate {
          value
        }
        canUpdateInvoicedDate {
          value
        }
        publishedAt {
          date
          dateTime
        }
        publishedBy {
          username
        }
        state
        updatedAt {
          date
          dateTime
        }
        updatedBy {
          username
        }
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useFinancePublishAccrualMutation__
 *
 * To run a mutation, you first call `useFinancePublishAccrualMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinancePublishAccrualMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [financePublishAccrualMutation, { data, loading, error }] = useFinancePublishAccrualMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinancePublishAccrualMutation(
  baseOptions?: Apollo.MutationHookOptions<FinancePublishAccrualMutationResult, FinancePublishAccrualMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<FinancePublishAccrualMutationResult, FinancePublishAccrualMutationVariables>(
    FinancePublishAccrualDocument,
    options
  );
}
export type FinancePublishAccrualMutationHookResult = ReturnType<typeof useFinancePublishAccrualMutation>;
