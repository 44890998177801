import { formatNumber } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

import type { Currency } from '../types';

const formatChargeCurrency = (value: number, currency: Currency): string => {
  return `${formatNumber(value, 2, 2)} ${currency}`;
};

export const formatCost = (chargeValue: Optional<number>, currency: Optional<Currency>): string => {
  if (!chargeValue || !currency) {
    return '-';
  }

  return formatChargeCurrency(chargeValue, currency);
};
