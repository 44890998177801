import type { OrderListViewLot, OrderListViewLotWithoutPurchaseOrder, OrderListViewPurchaseOrder } from '@zen/Orders/types';
import type { Optional } from '@zen/utils/typescript';

export const mergePurchaseOrderIntoLots = (
  purchaseOrder: Optional<OrderListViewPurchaseOrder>,
  lots: Optional<OrderListViewLotWithoutPurchaseOrder[]>
): Optional<OrderListViewLot[]> => {
  if (!purchaseOrder || !lots) {
    return undefined;
  }

  if (!lots.length) {
    return [];
  }

  return lots.map((lot: OrderListViewLotWithoutPurchaseOrder) => {
    return { purchaseOrder, ...lot };
  });
};
