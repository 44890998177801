import { convert, DateTimeFormatter, LocalDate } from '@js-joda/core';

import type { Nullable, Optional } from '@zen/utils/typescript';

import type { DateTimeLocaleAndOptions, Time } from '../types';
import { isParseableAsDate, isParseableAsDateTime, isParseableAsTime, parseDate, parseDateTime, parseTime } from './parsing';
import type { JodaDateLike, JodaDateTime } from './types';

export const parseAndFormatDate = (inputString: string, formatOptions: Optional<DateTimeLocaleAndOptions>): Nullable<string> => {
  if (!isParseableAsDate(inputString)) {
    return null;
  }

  const jodaObject: LocalDate = parseDate(inputString);

  if (!formatOptions) {
    const ISO8601Date = DateTimeFormatter.ofPattern('yyyy-MM-dd');

    return jodaObject.format(ISO8601Date);
  }

  return convertJodaToLocaleDateString(jodaObject, formatOptions);
};

export const parseAndFormatDateTime = (inputString: string, formatOptions: DateTimeLocaleAndOptions): Nullable<string> => {
  if (!isParseableAsDateTime(inputString)) {
    return null;
  }

  const jodaObject: JodaDateTime = parseDateTime(inputString);

  return convertJodaToLocaleDateString(jodaObject, formatOptions);
};

export const parseAndFormatTime = (time: Time | string, withSeconds = true): Nullable<string> => {
  const pattern = withSeconds ? 'HH:mm:ss' : 'HH:mm';

  if (!isParseableAsTime(time)) {
    return null;
  }

  return parseTime(time).format(DateTimeFormatter.ofPattern(pattern));
};

function convertJodaToLocaleDateString(jodaObject: JodaDateLike, formatOptions: DateTimeLocaleAndOptions): string {
  const { locale, options } = formatOptions;

  return convert(jodaObject).toDate().toLocaleDateString(locale, options);
}
