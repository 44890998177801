import { FC, useState } from 'react';

import { Icon } from '@zen/DesignSystem';

import StakeholderDetails from '../../StakeholderDetails';
import type { Stakeholder, SubscriptionStakeholder } from '../../types';

interface Props {
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholder: Stakeholder;
}

const NonSubscriberListItem: FC<Props> = ({ onSubscribe, stakeholder }) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const className = 'flex justify-between items-center group px-6 py-4 text-sm hover:bg-grey-lightest';

  const renderConfirmationButtons = () => {
    return (
      <div>
        <span className="text-navy-base mr-2 cursor-pointer" onClick={() => onSubscribe(stakeholder)}>
          Subscribe
        </span>
        <span className="text-grey-base cursor-pointer" onClick={() => setIsEditMode(false)}>
          Cancel
        </span>
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <Icon
        className="hidden group-hover:block cursor-pointer ml-4 text-xs"
        icon="zicon-add"
        onClick={() => setIsEditMode(true)}
      />
    );
  };

  return (
    <div className={className} data-testid="non-subscriber-list-item">
      <StakeholderDetails showCompanyName={!isEditMode} stakeholder={stakeholder} />
      {isEditMode ? renderConfirmationButtons() : renderIcon()}
    </div>
  );
};

export default NonSubscriberListItem;
