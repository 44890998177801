import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type FinanceGetAdjustedExchangeRatesQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type FinanceGetAdjustedExchangeRatesQueryResult = { __typename?: 'Query' } & {
  financeAdjustedExchangeRates?: GraphQLTypes.Maybe<
    Array<
      { __typename?: 'AdjustedExchangeRate' } & Pick<
        GraphQLTypes.AdjustedExchangeRate,
        'id' | 'fromCurrency' | 'toCurrency' | 'originalRate' | 'adjustedRate'
      >
    >
  >;
};

export const FinanceGetAdjustedExchangeRatesDocument = /* #__PURE__ */ gql`
  query financeGetAdjustedExchangeRates {
    financeAdjustedExchangeRates {
      id
      fromCurrency
      toCurrency
      originalRate
      adjustedRate
    }
  }
`;

/**
 * __useFinanceGetAdjustedExchangeRatesQuery__
 *
 * To run a query within a React component, call `useFinanceGetAdjustedExchangeRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinanceGetAdjustedExchangeRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinanceGetAdjustedExchangeRatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFinanceGetAdjustedExchangeRatesQuery(
  baseOptions?: Apollo.QueryHookOptions<FinanceGetAdjustedExchangeRatesQueryResult, FinanceGetAdjustedExchangeRatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<FinanceGetAdjustedExchangeRatesQueryResult, FinanceGetAdjustedExchangeRatesQueryVariables>(
    FinanceGetAdjustedExchangeRatesDocument,
    options
  );
}
export function useFinanceGetAdjustedExchangeRatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FinanceGetAdjustedExchangeRatesQueryResult,
    FinanceGetAdjustedExchangeRatesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<FinanceGetAdjustedExchangeRatesQueryResult, FinanceGetAdjustedExchangeRatesQueryVariables>(
    FinanceGetAdjustedExchangeRatesDocument,
    options
  );
}
export type FinanceGetAdjustedExchangeRatesQueryHookResult = ReturnType<typeof useFinanceGetAdjustedExchangeRatesQuery>;
export type FinanceGetAdjustedExchangeRatesLazyQueryHookResult = ReturnType<typeof useFinanceGetAdjustedExchangeRatesLazyQuery>;
