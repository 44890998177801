import type { FC } from 'react';

import { FormSelect } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import type { Nullable } from '@zen/utils/typescript';

import type { TruckType } from '../../types';
import { getFullTruckOptions } from './helpers';

interface Props extends FormFieldProps {
  isDisabled?: boolean;
  truckVariant: Nullable<TruckType>;
}

const FormVehicleTypeSelect: FC<Props> = ({ isDisabled, truckVariant, ...rest }) => {
  return <FormSelect isClearable={true} isDisabled={isDisabled} options={getFullTruckOptions(truckVariant)} {...rest} />;
};

export default FormVehicleTypeSelect;
