import type { Terminal } from '@zen/Networks';
import type { Nullable, Optional } from '@zen/utils/typescript';

export interface Stop {
  arrivalDate: string;
  arrivalDateDisabled?: boolean;
  departureDate: string;
  departureDateDisabled?: boolean;
  flightNumber?: string;
  location: Optional<Terminal>;
  vesselName?: string;
  voyageNumber?: string;
}

export interface PlannedScheduleValues {
  airScheduleCarrierType?: AirScheduleCarrier;
  carrier: string;
  stops: Stop[];
  trackingNumber?: Nullable<string>;
}

export enum AirScheduleCarrier {
  AIRLINE = 'AIRLINE',
  COURIER = 'COURIER'
}
