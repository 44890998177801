import { number, object, string } from 'yup';

import { endTimeValidator, startTimeValidator } from '@zen/utils/validation';

const timeRangeValidator = object().shape({
  startTime: startTimeValidator,
  endTime: endTimeValidator
});

export const addressValidation = {
  locationAddress: string()
    .nullable()
    .when('addressFieldsVisible', {
      is: (addressFieldsVisible: boolean) => !addressFieldsVisible,
      then: (schema) => schema.required('Location address is required')
    }),
  businessHours: object().shape({
    monday: timeRangeValidator,
    tuesday: timeRangeValidator,
    wednesday: timeRangeValidator,
    thursday: timeRangeValidator,
    friday: timeRangeValidator,
    saturday: timeRangeValidator,
    sunday: timeRangeValidator
  }),
  countryCode: string().nullable().required('Country is required'),
  street: string().trim().nullable().required('Street is required'),
  lat: number().nullable().required('Latitude is required'),
  lng: number().nullable().required('Longitude is required')
};
