import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RailwayCompaniesQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type RailwayCompaniesQueryResult = { __typename?: 'Query' } & {
  railwayCompanies?: GraphQLTypes.Maybe<
    { __typename?: 'RailwayCompanyConnection' } & Pick<GraphQLTypes.RailwayCompanyConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<{ __typename?: 'RailwayCompany' } & Pick<GraphQLTypes.RailwayCompany, 'railwayCompany' | 'id'>>
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const RailwayCompaniesDocument = /* #__PURE__ */ gql`
  query railwayCompanies($after: String, $before: String, $first: Int, $last: Int, $query: String) {
    railwayCompanies(after: $after, before: $before, first: $first, last: $last, query: $query) {
      nodes {
        railwayCompany
        id
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useRailwayCompaniesQuery__
 *
 * To run a query within a React component, call `useRailwayCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRailwayCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRailwayCompaniesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRailwayCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<RailwayCompaniesQueryResult, RailwayCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RailwayCompaniesQueryResult, RailwayCompaniesQueryVariables>(RailwayCompaniesDocument, options);
}
export function useRailwayCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RailwayCompaniesQueryResult, RailwayCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RailwayCompaniesQueryResult, RailwayCompaniesQueryVariables>(RailwayCompaniesDocument, options);
}
export type RailwayCompaniesQueryHookResult = ReturnType<typeof useRailwayCompaniesQuery>;
export type RailwayCompaniesLazyQueryHookResult = ReturnType<typeof useRailwayCompaniesLazyQuery>;
