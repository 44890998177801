import type { FC } from 'react';

interface Props {
  htmlFor?: string;
  label: string;
}

const OverLabel: FC<Props> = ({ htmlFor, label }) => {
  return (
    <label
      className="absolute z-10 left-2 -top-2 px-0.5 text-xs text-grey-base bg-white cursor-pointer rounded w-max"
      htmlFor={htmlFor}
    >
      {label}
    </label>
  );
};

export default OverLabel;
