import type { FC } from 'react';

import { Button } from '@zen/DesignSystem';

export type ActionConfig = {
  title: string;
  type: 'primary' | 'secondary' | 'ghost';
};

const defaultActionConfig: ActionConfig = {
  title: 'Add custom charge',
  type: 'ghost'
};

interface Props {
  actionConfig?: ActionConfig;
  isDisabled?: boolean;
  onAdd?: () => void;
}

const ChargeTableActions: FC<Props> = ({ actionConfig = defaultActionConfig, isDisabled = false, onAdd }) => {
  return (
    <Button disabled={isDisabled} iconLeft="zicon-add" onClick={onAdd} size="compact" variant={actionConfig.type}>
      {actionConfig.title}
    </Button>
  );
};

export { type Props as ChargeTableActionsProps };

export default ChargeTableActions;
