export const CSV_PARSER_ROUTE_PREFIX = '/dashboard/csv-parser';

interface DocumentParserRoutes {
  csvParserIssues: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const csvParserRoutes: DocumentParserRoutes = {
  index: {
    path: `${CSV_PARSER_ROUTE_PREFIX}/*`,
    getUrl: () => CSV_PARSER_ROUTE_PREFIX,
    relativePath: 'csv-parser/*'
  },
  csvParserIssues: {
    path: `${CSV_PARSER_ROUTE_PREFIX}/issues`,
    getUrl: () => `${CSV_PARSER_ROUTE_PREFIX}/issues`,
    relativePath: 'issues'
  }
};

export default csvParserRoutes;
