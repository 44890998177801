import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingRateCardDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingRateCardDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference' | 'modeOfTransport'> & {
              cargo?: GraphQLTypes.Maybe<
                { __typename?: 'Cargo' } & Pick<GraphQLTypes.Cargo, 'mode'> & {
                    cargoItems?: GraphQLTypes.Maybe<
                      Array<
                        | ({ __typename: 'CargoContainerType' } & Pick<
                            GraphQLTypes.CargoContainerType,
                            'containerType' | 'containerNumber' | 'id' | 'reefer' | 'hazardous' | 'quantity' | 'type'
                          >)
                        | ({ __typename: 'CargoLooseCargoType' } & Pick<
                            GraphQLTypes.CargoLooseCargoType,
                            'quantity' | 'looseCargoType' | 'id' | 'reefer' | 'hazardous' | 'type'
                          >)
                        | ({ __typename: 'CargoVehicleType' } & Pick<
                            GraphQLTypes.CargoVehicleType,
                            'quantity' | 'looseCargoType' | 'vehicleType' | 'id' | 'reefer' | 'hazardous' | 'type'
                          >)
                      >
                    >;
                  }
              >;
              coreCargos?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'CoreCargo' } & Pick<
                    GraphQLTypes.CoreCargo,
                    'cargoType' | 'containerNumber' | 'id' | 'quantity' | 'refrigerated' | 'hazardous' | 'overweight'
                  >
                >
              >;
            }
        >
      >
    >;
  };
};

export const GetBookingRateCardDetailsDocument = /* #__PURE__ */ gql`
  query getBookingRateCardDetails($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        modeOfTransport
        cargo {
          mode
          cargoItems {
            id
            reefer
            hazardous
            quantity
            type
            ... on CargoContainerType {
              __typename
              containerType
              containerNumber
            }
            ... on CargoVehicleType {
              __typename
              quantity
              looseCargoType
              vehicleType
            }
            ... on CargoLooseCargoType {
              __typename
              quantity
              looseCargoType
            }
          }
        }
        coreCargos {
          cargoType
          containerNumber
          id
          quantity
          refrigerated
          hazardous
          overweight
        }
      }
    }
  }
`;

/**
 * __useGetBookingRateCardDetailsQuery__
 *
 * To run a query within a React component, call `useGetBookingRateCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingRateCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingRateCardDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingRateCardDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingRateCardDetailsQueryResult, GetBookingRateCardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingRateCardDetailsQueryResult, GetBookingRateCardDetailsQueryVariables>(
    GetBookingRateCardDetailsDocument,
    options
  );
}
export function useGetBookingRateCardDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingRateCardDetailsQueryResult, GetBookingRateCardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingRateCardDetailsQueryResult, GetBookingRateCardDetailsQueryVariables>(
    GetBookingRateCardDetailsDocument,
    options
  );
}
export type GetBookingRateCardDetailsQueryHookResult = ReturnType<typeof useGetBookingRateCardDetailsQuery>;
export type GetBookingRateCardDetailsLazyQueryHookResult = ReturnType<typeof useGetBookingRateCardDetailsLazyQuery>;
