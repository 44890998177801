import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateAirlineCarrierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateAirlineCarrierInput;
}>;

export type CreateAirlineCarrierMutationResult = { __typename?: 'Mutation' } & {
  createAirlineCarrier: { __typename?: 'CreateAirlineCarrierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    airlineCarrier?: GraphQLTypes.Maybe<
      { __typename?: 'AirlineCarrier' } & Pick<GraphQLTypes.AirlineCarrier, 'airlineCarrierName' | 'id' | 'carrierIata'>
    >;
  };
};

export const CreateAirlineCarrierDocument = /* #__PURE__ */ gql`
  mutation createAirlineCarrier($input: CreateAirlineCarrierInput!) {
    createAirlineCarrier(input: $input) {
      errors {
        message
        path
      }
      airlineCarrier {
        airlineCarrierName
        id
        carrierIata
      }
    }
  }
`;

/**
 * __useCreateAirlineCarrierMutation__
 *
 * To run a mutation, you first call `useCreateAirlineCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAirlineCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAirlineCarrierMutation, { data, loading, error }] = useCreateAirlineCarrierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAirlineCarrierMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAirlineCarrierMutationResult, CreateAirlineCarrierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateAirlineCarrierMutationResult, CreateAirlineCarrierMutationVariables>(
    CreateAirlineCarrierDocument,
    options
  );
}
export type CreateAirlineCarrierMutationHookResult = ReturnType<typeof useCreateAirlineCarrierMutation>;
