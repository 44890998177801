import { useLocation } from 'react-router-dom';

import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

const useCatchAuthError = (): { errorMessage: string; isError: boolean } => {
  const { search } = useLocation();
  const { error_description, error } = parseQueryParams(search);

  return {
    isError: !!error,
    errorMessage: error_description
  };
};

export { useCatchAuthError };
