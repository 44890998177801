import opentelemetry, { DiagConsoleLogger, DiagLogLevel } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import type { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

import staticConfig from '../staticConfig';
import { ExporterType, setupExporters } from './exporter';
import { setupInstrumentations } from './instrumentation';
import { createProvider } from './provider';

const setupTracing = ({ debug, collectorUrl }: { collectorUrl: string; debug: boolean }): WebTracerProvider => {
  if (debug) {
    opentelemetry.diag.setLogger(new DiagConsoleLogger(), DiagLogLevel.DEBUG);
  }

  setupInstrumentations();

  const provider: WebTracerProvider = createProvider({
    deploymentEnvironment: staticConfig.environmentName,
    serviceName: 'frontend',
    serviceVersion: staticConfig.applicationVersion
  });

  const exporters: ExporterType[] = [ExporterType.Collector];

  if (debug) {
    exporters.push(ExporterType.Console);
  }

  setupExporters({
    provider,
    collectorUrl,
    exporters
  });

  provider.register({
    contextManager: new ZoneContextManager() // Zone is required to keep async calls in the same trace
  });

  return provider;
};

if (staticConfig.openTelemetryTracesUrl) {
  setupTracing({ debug: false, collectorUrl: staticConfig.openTelemetryTracesUrl });
}
