import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountNetworkContactsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetAccountNetworkContactsQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      myOrganisation?: GraphQLTypes.Maybe<
        { __typename?: 'MyOrganisation' } & {
          contacts: { __typename?: 'NetworksContactConnection' } & {
            nodes?: GraphQLTypes.Maybe<
              Array<
                GraphQLTypes.Maybe<
                  { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'id' | 'firstName' | 'lastName'>
                >
              >
            >;
            pageInfo: { __typename: 'PageInfo' } & Pick<
              GraphQLTypes.PageInfo,
              'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
            >;
          };
        }
      >;
    }
  >;
};

export const GetAccountNetworkContactsDocument = /* #__PURE__ */ gql`
  query getAccountNetworkContacts($accountId: String!, $after: String, $before: String, $first: Int, $last: Int) {
    network(id: $accountId) {
      myOrganisation {
        contacts(after: $after, before: $before, first: $first, last: $last) {
          nodes {
            id
            firstName
            lastName
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetAccountNetworkContactsQuery__
 *
 * To run a query within a React component, call `useGetAccountNetworkContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountNetworkContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountNetworkContactsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAccountNetworkContactsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountNetworkContactsQueryResult, GetAccountNetworkContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountNetworkContactsQueryResult, GetAccountNetworkContactsQueryVariables>(
    GetAccountNetworkContactsDocument,
    options
  );
}
export function useGetAccountNetworkContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountNetworkContactsQueryResult, GetAccountNetworkContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountNetworkContactsQueryResult, GetAccountNetworkContactsQueryVariables>(
    GetAccountNetworkContactsDocument,
    options
  );
}
export type GetAccountNetworkContactsQueryHookResult = ReturnType<typeof useGetAccountNetworkContactsQuery>;
export type GetAccountNetworkContactsLazyQueryHookResult = ReturnType<typeof useGetAccountNetworkContactsLazyQuery>;
