import type { FC } from 'react';

import DatesDifference from '@zen/Components/DatesDifference';
import { formatDate } from '@zen/utils/dateTime';

interface Props {
  newDate: string | undefined;
  previousDate: string;
}

const DatesIndicator: FC<Props> = ({ newDate, previousDate }) => (
  <div className="my-4 leading-normal">
    <div className="flex items-center flex-1 mb-2" data-testid="previous-date">
      Previous: <span className="font-bold">&nbsp;{formatDate(previousDate)}</span>
    </div>
    <div className="flex items-center flex-1" data-testid="new-date">
      New: <span className="font-bold">&nbsp;{newDate && formatDate(newDate)}</span>
      {newDate && <DatesDifference className="ml-3" date={newDate} referenceDate={previousDate} />}
    </div>
  </div>
);

export default DatesIndicator;
