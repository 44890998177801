import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateDirectUploadMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateDirectUploadInput;
}>;

export type CreateDirectUploadMutationResult = { __typename?: 'Mutation' } & {
  createDirectUpload?: GraphQLTypes.Maybe<
    { __typename?: 'CreateDirectUploadPayload' } & {
      directUpload: { __typename?: 'DirectUpload' } & Pick<GraphQLTypes.DirectUpload, 'signedBlobId' | 'url' | 'headers'>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const CreateDirectUploadDocument = /* #__PURE__ */ gql`
  mutation createDirectUpload($input: CreateDirectUploadInput!) {
    createDirectUpload(input: $input) {
      directUpload {
        signedBlobId
        url
        headers
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useCreateDirectUploadMutation__
 *
 * To run a mutation, you first call `useCreateDirectUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectUploadMutation, { data, loading, error }] = useCreateDirectUploadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectUploadMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateDirectUploadMutationResult, CreateDirectUploadMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateDirectUploadMutationResult, CreateDirectUploadMutationVariables>(
    CreateDirectUploadDocument,
    options
  );
}
export type CreateDirectUploadMutationHookResult = ReturnType<typeof useCreateDirectUploadMutation>;
