import { useGetCargoIdsQuery } from '../graphql';

const useCoreCargoIds = (zencargoReference: string): string[] => {
  const { data } = useGetCargoIdsQuery({ fetchPolicy: 'cache-and-network', variables: { zencargoReference } });

  const ids: string[] = [];

  data?.bookings.nodes?.forEach((element) => {
    element?.coreCargos?.forEach((coreCargo) => {
      ids.push(coreCargo.id);
    });
  });

  return ids;
};

export { useCoreCargoIds };
