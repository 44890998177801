import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetLotsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetLotsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              lots?: GraphQLTypes.Maybe<
                { __typename?: 'LotConnection' } & Pick<GraphQLTypes.LotConnection, 'totalCount'> & {
                    nodes?: GraphQLTypes.Maybe<
                      Array<
                        GraphQLTypes.Maybe<
                          { __typename?: 'Lot' } & Pick<
                            GraphQLTypes.Lot,
                            'id' | 'estimatedDeliveryDate' | 'quantityFulfilled'
                          > & {
                              cargoReadyDate?: GraphQLTypes.Maybe<
                                { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                                    history?: GraphQLTypes.Maybe<
                                      Array<
                                        { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                                            lastUpdated?: GraphQLTypes.Maybe<
                                              { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                                  updatedBy?: GraphQLTypes.Maybe<
                                                    { __typename?: 'UpdatedBy' } & Pick<
                                                      GraphQLTypes.UpdatedBy,
                                                      'username' | 'zencargoEmployee'
                                                    >
                                                  >;
                                                }
                                            >;
                                            reasonForChange?: GraphQLTypes.Maybe<
                                              { __typename?: 'ReasonForChange' } & Pick<
                                                GraphQLTypes.ReasonForChange,
                                                'category' | 'description'
                                              >
                                            >;
                                          }
                                      >
                                    >;
                                    lastUpdated?: GraphQLTypes.Maybe<
                                      { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                          updatedBy?: GraphQLTypes.Maybe<
                                            { __typename?: 'UpdatedBy' } & Pick<
                                              GraphQLTypes.UpdatedBy,
                                              'username' | 'zencargoEmployee'
                                            >
                                          >;
                                        }
                                    >;
                                    reasonForChange?: GraphQLTypes.Maybe<
                                      { __typename?: 'ReasonForChange' } & Pick<
                                        GraphQLTypes.ReasonForChange,
                                        'category' | 'description'
                                      >
                                    >;
                                  }
                              >;
                              orderedLineItem?: GraphQLTypes.Maybe<
                                { __typename?: 'OrderedLineItem' } & Pick<GraphQLTypes.OrderedLineItem, 'quantityOrdered'> & {
                                    product?: GraphQLTypes.Maybe<
                                      { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'skuCode' | 'name'>
                                    >;
                                  }
                              >;
                              purchaseOrder?: GraphQLTypes.Maybe<
                                { __typename?: 'PurchaseOrder' } & Pick<
                                  GraphQLTypes.PurchaseOrder,
                                  'id' | 'orderReferenceNumber' | 'orderDate'
                                >
                              >;
                              stages?: GraphQLTypes.Maybe<
                                Array<
                                  { __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'label' | 'statusType'>
                                >
                              >;
                            }
                        >
                      >
                    >;
                    pageInfo: { __typename: 'PageInfo' } & Pick<
                      GraphQLTypes.PageInfo,
                      'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
                    >;
                  }
              >;
            }
        >
      >
    >;
  };
};

export const GetLotsDocument = /* #__PURE__ */ gql`
  query getLots($zencargoReference: String!, $after: String, $before: String, $first: Int, $last: Int) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        lots(after: $after, before: $before, first: $first, last: $last) {
          totalCount
          nodes {
            id
            cargoReadyDate {
              date
              history {
                date
                lastUpdated {
                  updatedAt
                  updatedBy {
                    username
                    zencargoEmployee
                  }
                }
                reasonForChange {
                  category
                  description
                }
              }
              lastUpdated {
                updatedAt
                updatedBy {
                  username
                  zencargoEmployee
                }
              }
              reasonForChange {
                category
                description
              }
            }
            orderedLineItem {
              product {
                id
                skuCode
                name
              }
              quantityOrdered
            }
            estimatedDeliveryDate
            purchaseOrder {
              id
              orderReferenceNumber
              orderDate
            }
            quantityFulfilled
            stages {
              label
              statusType
            }
          }
          pageInfo {
            ...PageInfo
          }
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetLotsQuery__
 *
 * To run a query within a React component, call `useGetLotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLotsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetLotsQuery(baseOptions: Apollo.QueryHookOptions<GetLotsQueryResult, GetLotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetLotsQueryResult, GetLotsQueryVariables>(GetLotsDocument, options);
}
export function useGetLotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLotsQueryResult, GetLotsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetLotsQueryResult, GetLotsQueryVariables>(GetLotsDocument, options);
}
export type GetLotsQueryHookResult = ReturnType<typeof useGetLotsQuery>;
export type GetLotsLazyQueryHookResult = ReturnType<typeof useGetLotsLazyQuery>;
