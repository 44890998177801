import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DivisionQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type DivisionQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'parentAccountId' | 'type'>>;
  legacyAccount?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyAccount' } & {
      division?: GraphQLTypes.Maybe<{ __typename?: 'Division' } & Pick<GraphQLTypes.Division, 'name' | 'parentAccountId'>>;
    }
  >;
};

export const DivisionDocument = /* #__PURE__ */ gql`
  query division($id: String!) {
    account(accountId: $id) {
      parentAccountId
      type
    }
    legacyAccount(uuid: $id) {
      division {
        name
        parentAccountId
      }
    }
  }
`;

/**
 * __useDivisionQuery__
 *
 * To run a query within a React component, call `useDivisionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDivisionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDivisionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDivisionQuery(baseOptions: Apollo.QueryHookOptions<DivisionQueryResult, DivisionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<DivisionQueryResult, DivisionQueryVariables>(DivisionDocument, options);
}
export function useDivisionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DivisionQueryResult, DivisionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<DivisionQueryResult, DivisionQueryVariables>(DivisionDocument, options);
}
export type DivisionQueryHookResult = ReturnType<typeof useDivisionQuery>;
export type DivisionLazyQueryHookResult = ReturnType<typeof useDivisionLazyQuery>;
