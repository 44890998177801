import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetTaskBusinessKeysQueryVariables = GraphQLTypes.Exact<{
  businessKeyLike?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type GetTaskBusinessKeysQueryResult = { __typename?: 'Query' } & {
  businessKeys?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowBusinessKeyConnection' } & Pick<GraphQLTypes.WorkflowBusinessKeyConnection, 'nodes'>
  >;
};

export const GetTaskBusinessKeysDocument = /* #__PURE__ */ gql`
  query getTaskBusinessKeys($businessKeyLike: String) {
    businessKeys(businessKeyLike: $businessKeyLike) {
      nodes
    }
  }
`;

/**
 * __useGetTaskBusinessKeysQuery__
 *
 * To run a query within a React component, call `useGetTaskBusinessKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskBusinessKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskBusinessKeysQuery({
 *   variables: {
 *      businessKeyLike: // value for 'businessKeyLike'
 *   },
 * });
 */
export function useGetTaskBusinessKeysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTaskBusinessKeysQueryResult, GetTaskBusinessKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetTaskBusinessKeysQueryResult, GetTaskBusinessKeysQueryVariables>(GetTaskBusinessKeysDocument, options);
}
export function useGetTaskBusinessKeysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskBusinessKeysQueryResult, GetTaskBusinessKeysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetTaskBusinessKeysQueryResult, GetTaskBusinessKeysQueryVariables>(
    GetTaskBusinessKeysDocument,
    options
  );
}
export type GetTaskBusinessKeysQueryHookResult = ReturnType<typeof useGetTaskBusinessKeysQuery>;
export type GetTaskBusinessKeysLazyQueryHookResult = ReturnType<typeof useGetTaskBusinessKeysLazyQuery>;
