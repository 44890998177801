import type { DeepNonNullable } from '@zen/utils/typescript';

import type { GetChargeGroupsQueryResult, GetChargeTypesListQueryResult } from './graphql';

export { Applicability, ChargeBasisGroup } from '@zen/graphql/types.generated';

export enum ChargeGroupItemType {
  ADDITIONAL_CHARGES = 'Additional charges',
  BOOKING_MANAGEMENT = 'Booking management',
  CUSTOMS_CLEARANCE = 'Customs clearance',
  FREIGHT = 'Freight',
  HAULAGE = 'Haulage',
  PORT_CHARGES = 'Port charges',
  WAREHOUSING = 'Warehousing'
}

export type ChargeType = DeepNonNullable<GetChargeTypesListQueryResult, 'getAllCharges'>['getAllCharges'];
export type ChargeGroup = DeepNonNullable<GetChargeGroupsQueryResult, 'getChargeGroups'>['getChargeGroups'];
