import { orderBy } from 'lodash';

import type { Terminal } from '@zen/Networks';
import type { Optional } from '@zen/utils/typescript';

import type { AccountContactsQueryResult } from './graphql/accountContacts.generated';

const getTerminal = (terminal: Optional<Terminal>) => {
  return {
    id: terminal?.unlocode,
    unlocode: terminal?.unlocode,
    label: {
      long: terminal?.label
    }
  };
};

export const prepareResults = (results: Optional<AccountContactsQueryResult>, withTerminals: boolean) => {
  if (!results) return [];

  const { searchAccountAndConnectionLocations, terminals: terminalsData } = results;

  const locations = searchAccountAndConnectionLocations?.nodes || [];
  const terminals = terminalsData?.nodes || [];

  const options = [...locations, ...terminals.map(getTerminal)];

  return withTerminals ? orderBy(options, 'label.long', 'asc') : options;
};
