import type { FC } from 'react';

import { FormCurrencyInput, useForm } from '@zen/Components';
import { Currency } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { type CargoItemDetailsType, CargoModeEnum, TruckType, VehicleTypeEnum } from '../../../types';
import CargoParams from '../../CargoParams';
import FormCargoRiskSelect from '../../FormCargoRiskSelect';
import FormCargoVariantTiles from '../../FormCargoVariantTiles';
import FormTruckTypeSelect from '../../FormVehicleTypeSelect';

interface Props {
  isDisabled?: boolean;
}

const FullTruckLoadFormFields: FC<Props> = ({ isDisabled }) => {
  const {
    values: { truckVariant, type },
    setFieldValue
  } = useForm<{ truckVariant: Nullable<TruckType>; type: Nullable<VehicleTypeEnum> }>();

  const handleVariantChange = (value: CargoItemDetailsType): void => {
    const refrigeratedValue: boolean = value === TruckType.REFRIGERATED_TRUCK;

    setFieldValue('type', null);
    setFieldValue('refrigerated', refrigeratedValue);
  };

  return (
    <div data-testid="full-truck-form">
      <FormCargoVariantTiles
        cargoMode={CargoModeEnum.FTL}
        isDisabled={isDisabled}
        name="truckVariant"
        onChange={handleVariantChange}
      />
      <div className="grid items-baseline grid-cols-3 gap-x-4">
        <FormTruckTypeSelect
          isDisabled={isDisabled || !truckVariant}
          label="Vehicle size"
          name="type"
          truckVariant={truckVariant}
        />
        <FormCurrencyInput
          availableCurrencies={[Currency.GBP, Currency.EUR, Currency.USD]}
          isOptional={true}
          label="Value of cargo"
          name="valueOfGoods"
          placeholder="0.0"
        />
        <FormCargoRiskSelect isDisabled={isDisabled} isOptional={true} label="Risk level" name="riskLevel" />
      </div>
      {!!type && <CargoParams cargoType={type} className="grid grid-cols-3 gap-4" />}
    </div>
  );
};

export default FullTruckLoadFormFields;
