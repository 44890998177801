import type { OrderBasketLot, OrderListViewLot } from '@zen/Orders/types';

export const prepareBasketItems = (orderLots: OrderListViewLot[]): OrderBasketLot[] => {
  return orderLots.map((lot: OrderListViewLot): OrderBasketLot => {
    const { cargoReadyDate, cbm, id, orderedLineItem, purchaseOrder, quantityFulfilled } = lot;
    const { destination, origin, orderDate, orderReferenceNumber, incoterms, modeOfTransport } = purchaseOrder!;

    return {
      id,
      incoterms: incoterms?.value || null,
      origin: origin || null,
      destination: destination || null,
      modeOfTransport,
      orderDate,
      orderReferenceNumber: orderReferenceNumber || '',
      quantityFulfilled,
      cbm,
      cargoReadyDate,
      productName: orderedLineItem?.product?.name || ''
    };
  });
};
