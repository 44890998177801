import { get } from 'lodash';
import type { FC } from 'react';

import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import LocationUsageRadioGroup from '@zen/Accounts/components/forms/LocationUsageRadioGroup';
import { FormInput } from '@zen/Components';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import CompanyNameFields from '../../components/CompanyNameFields';
import { useUpdateAccountLocalisedLocationMutation } from '../../graphql';
import { prepareValues } from './helpers';
import { locationDetailsValidation } from './locationDetails.validation';
import type { LocationDetailsFormValues } from './types';

type Props = {
  accountId: string;
  accountName: string;
  initialValues: LocationDetailsFormValues;
  locationId: string;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const UpdateLocationDetails: FC<Props> = ({ accountId, accountName, initialValues, locationId, onCancel, onSuccess }) => {
  const [updateLocation] = useUpdateAccountLocalisedLocationMutation({ refetchQueries: ['getAccountLocation'] });
  const { addError, addSuccess } = useNotification();

  const handleUpdateLocationDetails = async (formValues: LocationDetailsFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateLocation({
          variables: {
            input: prepareValues({ accountId, formValues, locationId, accountName })
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'There was a problem updating the location details.'));
      },
      onSuccess: () => addSuccess('Location details updated.')
    });
  };

  return (
    <AccountFormWrapper<LocationDetailsFormValues>
      initialValues={initialValues}
      onCancel={onCancel}
      onSubmit={handleUpdateLocationDetails}
      onSuccess={onSuccess}
      validationSchema={locationDetailsValidation}
    >
      {() => {
        return (
          <>
            <CompanyNameFields />
            <FormInput label="Location name" name="name" />
            <LocationUsageRadioGroup name="usage" noMargin={true} />
          </>
        );
      }}
    </AccountFormWrapper>
  );
};

export default UpdateLocationDetails;
export type { Props as UpdateLocationDetailsProps };
