import type { FC, ReactElement } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';

import Details from './Details';
import { useOrderOtherDetailsQuery } from './graphql';

interface Props {
  orderId: string;
}

const DetailsContainer: FC<Props> = ({ orderId }) => {
  const { loading, error, data } = useOrderOtherDetailsQuery({ variables: { orderId } });

  return (
    <QueryHandler data={data?.purchaseOrders?.nodes?.[0]} error={!!error} isLoading={loading}>
      {({ buyer, seller, manufacturer, origin, destination }): ReactElement => (
        <Details
          buyer={buyer}
          collectionWarehouse={origin}
          deliveryWarehouse={destination}
          manufacturer={manufacturer}
          seller={seller}
        />
      )}
    </QueryHandler>
  );
};

export default DetailsContainer;
