import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';
import type { Optional } from '@zen/utils/typescript';

import type { ModeOfTransport } from './types';

export interface BookingDetailsViewContextData {
  isCustomerView: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  setIsCustomerView: (isCustomerView: boolean) => void;
}

export const initialContext: BookingDetailsViewContextData = {
  isCustomerView: false,
  modeOfTransport: null,
  setIsCustomerView: () => {}
};

const BookingDetailsViewContext = createContext<BookingDetailsViewContextData>(initialContext);

const useBookingDetailsViewContext = (): BookingDetailsViewContextData => {
  const bookingDetailsViewContextData = useContextOrThrowError<BookingDetailsViewContextData>(
    BookingDetailsViewContext,
    'useBookingDetailsViewContext must be used inside BookingDetailsViewContextProvider'
  );

  return bookingDetailsViewContextData;
};

export { BookingDetailsViewContext, useBookingDetailsViewContext };
