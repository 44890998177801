import cx from 'classnames';
import type { FC } from 'react';
import { useWindowSize } from 'react-use';

import { Breakpoint } from '@zen/types';

export enum GridFlow {
  COL = 'col',
  ROW = 'row'
}

interface Props {
  className?: string;
  flow?: GridFlow;
}

const Row: FC<Props> = ({ children, className = '', flow = GridFlow.COL, ...props }) => {
  const { width } = useWindowSize();

  const classNames: string = cx(
    {
      'grid-flow-col grid-cols-12': flow === GridFlow.COL,
      'grid-flow-row': flow === GridFlow.ROW,
      'gap-6': width < Breakpoint.XXL,
      'gap-8': width >= Breakpoint.XXL
    },
    'grid',
    className
  );

  return (
    <div className={classNames} data-component="row" data-testid="row" {...props}>
      {children}
    </div>
  );
};

export type { Props as RowProps };
export default Row;
