import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type RoadTrackedShipmentsQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  customerId: GraphQLTypes.Scalars['String'];
  event?: GraphQLTypes.InputMaybe<GraphQLTypes.RoadTrackedShipmentEvent>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  firstStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  lastStopCountryCodes?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  status: GraphQLTypes.RoadTrackedShipmentStatus;
}>;

export type RoadTrackedShipmentsQueryResult = { __typename?: 'Query' } & {
  roadTrackedShipments?: GraphQLTypes.Maybe<
    { __typename?: 'RoadTrackedShipmentConnection' } & Pick<GraphQLTypes.RoadTrackedShipmentConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'RoadTrackedShipment' } & Pick<
                GraphQLTypes.RoadTrackedShipment,
                | 'estimatedArrivalWindowEndDateTime'
                | 'estimatedArrivalWindowStartDateTime'
                | 'destinationCountryCode'
                | 'originCountryCode'
                | 'zencargoReference'
              > & { geolocation: { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'> }
            >
          >
        >;
        pageInfo?: GraphQLTypes.Maybe<
          { __typename: 'PageInfo' } & Pick<
            GraphQLTypes.PageInfo,
            'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
          >
        >;
      }
  >;
};

export const RoadTrackedShipmentsDocument = /* #__PURE__ */ gql`
  query roadTrackedShipments(
    $after: String
    $before: String
    $customerId: String!
    $event: RoadTrackedShipmentEvent
    $first: Int
    $firstStopCountryCodes: [String!]
    $last: Int
    $lastStopCountryCodes: [String!]
    $status: RoadTrackedShipmentStatus!
  ) {
    roadTrackedShipments(
      active: ACTIVE
      after: $after
      before: $before
      customerId: $customerId
      event: $event
      first: $first
      firstStopCountryCodes: $firstStopCountryCodes
      last: $last
      lastStopCountryCodes: $lastStopCountryCodes
      status: $status
    ) {
      nodes {
        estimatedArrivalWindowEndDateTime
        estimatedArrivalWindowStartDateTime
        destinationCountryCode
        geolocation {
          latitude
          longitude
        }
        originCountryCode
        zencargoReference
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useRoadTrackedShipmentsQuery__
 *
 * To run a query within a React component, call `useRoadTrackedShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoadTrackedShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoadTrackedShipmentsQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      customerId: // value for 'customerId'
 *      event: // value for 'event'
 *      first: // value for 'first'
 *      firstStopCountryCodes: // value for 'firstStopCountryCodes'
 *      last: // value for 'last'
 *      lastStopCountryCodes: // value for 'lastStopCountryCodes'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useRoadTrackedShipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<RoadTrackedShipmentsQueryResult, RoadTrackedShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RoadTrackedShipmentsQueryResult, RoadTrackedShipmentsQueryVariables>(
    RoadTrackedShipmentsDocument,
    options
  );
}
export function useRoadTrackedShipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RoadTrackedShipmentsQueryResult, RoadTrackedShipmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RoadTrackedShipmentsQueryResult, RoadTrackedShipmentsQueryVariables>(
    RoadTrackedShipmentsDocument,
    options
  );
}
export type RoadTrackedShipmentsQueryHookResult = ReturnType<typeof useRoadTrackedShipmentsQuery>;
export type RoadTrackedShipmentsLazyQueryHookResult = ReturnType<typeof useRoadTrackedShipmentsLazyQuery>;
