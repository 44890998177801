const DOCUMENT_RELATIVE_INDEX_PATH = 'documents';

export const DOCUMENT_ROUTE_PREFIX = `/dashboard/${DOCUMENT_RELATIVE_INDEX_PATH}`;

interface DocumentRoutes {
  documentCreate: {
    getUrl: (accountId: string, zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const documentRoutes: DocumentRoutes = {
  documentCreate: {
    path: `${DOCUMENT_ROUTE_PREFIX}/:accountId/:id/create`,
    getUrl: (accountId: string, zencargoReference: string) => `${DOCUMENT_ROUTE_PREFIX}/${accountId}/${zencargoReference}/create`,
    relativePath: ':accountId/:id/create'
  },
  index: {
    getUrl: () => DOCUMENT_ROUTE_PREFIX,
    path: `${DOCUMENT_ROUTE_PREFIX}/*`,
    relativePath: `${DOCUMENT_RELATIVE_INDEX_PATH}/*`
  }
};

export default documentRoutes;
