import type { FC, ReactElement } from 'react';

import NetworkContactItem from '@zen/Components/NetworkContactItem';
import QueryHandler from '@zen/Components/QueryHandler';
import { useNetworksRemoveAssignmentMutation } from '@zen/Networks/graphql';
import useZencargoNetwork from '@zen/Networks/hooks/useZencargoNetwork';
import { AssignmentTargetTypeEnum, AssignmentTypeValue, OrganisationLocation } from '@zen/Networks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useGetOriginAgentQuery, useUpdateOriginAgentMutation } from './graphql';
import type { OriginAgentData } from './types';

interface Props {
  zencargoReference: string;
}

const ManageOriginAgent: FC<Props> = ({ zencargoReference }) => {
  const { addSuccess, addError } = useNotification();
  const { data: zencargoNetworkData } = useZencargoNetwork();
  const zencargoNetworkId: string = zencargoNetworkData?.zencargoNetwork?.id;

  const { data, loading, error, refetch } = useGetOriginAgentQuery({
    variables: {
      zencargoReference
    },
    fetchPolicy: 'no-cache'
  });

  const [updateOriginAgent] = useUpdateOriginAgentMutation();
  const [removeAssignment] = useNetworksRemoveAssignmentMutation();

  const handleUpdateOriginAgent = async (formData: OrganisationLocation): Promise<IOkOrErrorResult> => {
    const variables = {
      assignmentName: AssignmentTypeValue.ORIGIN_AGENT,
      targetId: zencargoReference,
      targetType: AssignmentTargetTypeEnum.BOOKING
    };

    const confirmationText: string = formData ? 'saved' : 'removed';

    const onSuccess = (): void => {
      addSuccess(`Origin agent has been ${confirmationText}.`);
      refetch();
    };

    if (!formData) {
      return performMutation({
        mutationFn: () =>
          removeAssignment({
            variables: {
              input: variables
            }
          }),
        onSuccess,
        onError: () => addError()
      });
    }

    return performMutation({
      mutationFn: () =>
        updateOriginAgent({
          variables: { input: { ...variables, assignableId: formData?.id || '' } },
          refetchQueries: ['shipmentActivityFeedPermittedParties']
        }),
      onSuccess,
      onError: () => addError()
    });
  };

  return (
    <QueryHandler data={data?.bookings.nodes?.[0]} error={!!error} isLoading={loading}>
      {(originAgentData: OriginAgentData): ReactElement => (
        <NetworkContactItem
          accountUuid={zencargoNetworkId}
          assignmentType={AssignmentTypeValue.ORIGIN_AGENT}
          isClearable={true}
          isEditable={originAgentData.canEditAgent.value}
          label="Origin agent"
          name="originAgent"
          onChange={handleUpdateOriginAgent}
          value={originAgentData?.originAgent}
        />
      )}
    </QueryHandler>
  );
};

export default ManageOriginAgent;
