import { convert, LocalDate, LocalDateTime, LocalTime, ZonedDateTime } from '@js-joda/core';

import type { Optional } from '@zen/utils/typescript';

import type { Time } from '../types';
import type { JodaDateLike, JodaDateLikeClass, JodaDateTime } from './types';

// Date

export const parseDate = (inputString: string): LocalDate => {
  // We might receive timestamp-like strings, and Joda won't parse them with LocalDate directly
  // See the docs here https://js-joda.github.io/js-joda/manual/LocalDate.html
  if (isParseableAsDateTime(inputString)) {
    return LocalDate.from(parseDateTime(inputString));
  }

  return LocalDate.parse(inputString);
};

export const isParseableAsDate = (inputString: string): boolean => {
  if (isParseableAsDateTime(inputString)) {
    return true;
  }

  return isParseableByJoda(inputString, LocalDate);
};

export const convertJodaToDate = (jodaObject: JodaDateLike): Date => {
  return convert(jodaObject).toDate();
};

// DateTime

export const parseDateTime = (inputString: string): JodaDateTime => {
  if (isParseableByJoda(inputString, ZonedDateTime)) {
    return ZonedDateTime.parse(inputString);
  }

  return LocalDateTime.parse(inputString);
};

export const isParseableAsDateTime = (inputString: string): boolean => {
  const validJodaClasses: JodaDateLikeClass[] = [LocalDateTime, ZonedDateTime];

  const dateTimeClass: Optional<JodaDateLikeClass> = validJodaClasses.find((jodaClass) =>
    isParseableByJoda(inputString, jodaClass)
  );

  return !!dateTimeClass;
};

// Time

export const parseTime = (input: string | Time): LocalTime => {
  if (typeof input === 'string') {
    return LocalTime.parse(input);
  }
  const { hour, minute } = input;

  return LocalTime.of(hour as number, minute as number);
};

export const isParseableAsTime = (input: string | Time): boolean => {
  if (typeof input === 'string') {
    try {
      LocalTime.parse(input);

      return true;
    } catch {
      return false;
    }
  } else {
    try {
      const { hour, minute } = input;

      LocalTime.of(hour as number, minute as number);

      return true;
    } catch {
      return false;
    }
  }
};

// Helpers

function isParseableByJoda(inputString: string, jodaClass: JodaDateLikeClass): boolean {
  try {
    jodaClass.parse(inputString);

    return true;
  } catch {
    return false;
  }
}
