const OPS_SHIPMENT_RELATIVE_INDEX_PATH = 'ops-shipments';

export const OPS_SHIPMENT_ROUTE_PREFIX = `/dashboard/${OPS_SHIPMENT_RELATIVE_INDEX_PATH}`;

interface OpsShipmentRoutes {
  allShipments: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  allShipmentsDetailsPage: {
    getUrl: (zencargoReference: string, sectionName?: string) => string;
    path: string;
    relativePath: string;
  };
  allShipmentsOrderDetails: {
    getUrl: (orderId: string) => string;
    path: string;
    relativePath: string;
  };
  shipmentDetails: {
    getUrl: (zencargoReference: string, tab?: string) => string;
    path: string;
  };
  shipmentIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const opsShipmentRoutes: OpsShipmentRoutes = {
  allShipments: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments`,
    relativePath: 'all-shipments/*',
    getUrl: () => `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments`
  },
  allShipmentsDetailsPage: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/details/:id/:sectionName?`,
    relativePath: 'all-shipments/details/:id/*',
    getUrl: (zencargoReference: string, sectionName?: string) => {
      const url = `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/details/${zencargoReference}`;

      return sectionName ? `${url}/${sectionName}` : url;
    }
  },
  allShipmentsOrderDetails: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/:id`,
    relativePath: ':id/*',
    getUrl: (orderId: string) => `${OPS_SHIPMENT_ROUTE_PREFIX}/all-shipments/${orderId}`
  },
  shipmentDetails: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/:id?`,
    getUrl: (zencargoReference: string, tab?: string): string => {
      const url = `${OPS_SHIPMENT_ROUTE_PREFIX}/${zencargoReference}`;

      return `${url}/${tab || 'activity'}`;
    }
  },
  shipmentIndex: {
    path: `${OPS_SHIPMENT_ROUTE_PREFIX}/*`,
    relativePath: `${OPS_SHIPMENT_RELATIVE_INDEX_PATH}/*`,
    getUrl: () => OPS_SHIPMENT_ROUTE_PREFIX
  }
};

export default opsShipmentRoutes;
