import { useEffect, useState } from 'react';

import type { Nullable } from '@zen/utils/typescript';

export const usePrioritisedValues = <T>(values: Nullable<T[]>): [T[], (values: T[]) => void] => {
  const [prioritisedValues, setPrioritisedValues] = useState<T[]>([]);

  useEffect(() => {
    if (values) {
      setPrioritisedValues(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [prioritisedValues, setPrioritisedValues];
};
