import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
}

const loaderClassName: string = 'w-6 text-center';
const loaderDots: string = 'w-2 h-2 bg-grey-light rounded-full inline-block animate-flash';

const LoadingDots: FC<Props> = ({ className }) => {
  return (
    <div className={cx(loaderClassName, className)} data-testid="loading-dots">
      <div className={cx(loaderDots)} />
      <div className={cx(loaderDots, 'animation-delay-20')} />
      <div className={cx(loaderDots, 'animation-delay-40')} />
    </div>
  );
};

export default LoadingDots;
