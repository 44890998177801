import type { FC, ReactNode } from 'react';

import { Headline } from '@zen/DesignSystem';

export interface Props {
  className?: string;
  tagline?: ReactNode;
  title: ReactNode;
}

export const displayName: string = 'Components/PageTitle';

const PageTitle: FC<Props> = ({ className, tagline, title }) => (
  <div className={className} data-testid="page-title">
    <Headline level={1}>{title}</Headline>
    {tagline && <div className="mt-3 text-sm font-normal leading-normal text-grey-base">{tagline}</div>}
  </div>
);

export type { Props as PageTitleProps };
export default PageTitle;
