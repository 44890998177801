import type { FC, ReactNode, SyntheticEvent } from 'react';

import { createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Divider from '../Divider';
import MenuItem from '../MenuItem';
import type { MenuItemType } from '../types';

interface Props {
  closeMenu: () => void;
  footer?: ReactNode;
  items: MenuItemType[];
}

const MenuList: FC<Props> = ({ footer, closeMenu, items }) => {
  const renderMenuItem = (item: MenuItemType, i: number): ReactNode => {
    const { addDivider, onClick } = item;

    const handleItemClick = (e: SyntheticEvent): void => {
      e.stopPropagation();

      onClick?.();
      closeMenu();
    };

    const displayDivider: boolean | undefined = i === items.length - 1 ? false : addDivider;

    return <MenuItem key={i} item={{ ...item, addDivider: displayDivider, onClick: handleItemClick }} />;
  };

  return (
    <div
      data-testid="dropdown-menu"
      {...createTrackingParentAttribute('context-menu')}
      {...createTrackingLabelAttribute('context-menu', { ignoreContent: true })}
    >
      <div className="overflow-y-auto max-h-100">{items.map(renderMenuItem)}</div>
      {footer && (
        <>
          <Divider />
          <div className="py-1.5 leading-normal my-1 px-6">{footer}</div>
        </>
      )}
    </div>
  );
};

export default MenuList;
