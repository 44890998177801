import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateMultiStopMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateRoadShipmentInput;
}>;

export type UpdateMultiStopMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateRoadShipment?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateRoadShipmentPayload' } & {
      booking?: GraphQLTypes.Maybe<{ __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'>>;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const UpdateMultiStopDocument = /* #__PURE__ */ gql`
  mutation updateMultiStop($input: UpdateRoadShipmentInput!) {
    bookingUpdateRoadShipment(input: $input) {
      booking {
        zencargoReference
      }
      errors {
        message
      }
    }
  }
`;

/**
 * __useUpdateMultiStopMutation__
 *
 * To run a mutation, you first call `useUpdateMultiStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultiStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultiStopMutation, { data, loading, error }] = useUpdateMultiStopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMultiStopMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMultiStopMutationResult, UpdateMultiStopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateMultiStopMutationResult, UpdateMultiStopMutationVariables>(UpdateMultiStopDocument, options);
}
export type UpdateMultiStopMutationHookResult = ReturnType<typeof useUpdateMultiStopMutation>;
