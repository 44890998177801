import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetUserPreferencesQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetUserPreferencesQueryResult = { __typename?: 'Query' } & {
  currentUser?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUser' } & {
      preferences?: GraphQLTypes.Maybe<
        { __typename?: 'Preference' } & Pick<
          GraphQLTypes.Preference,
          'filterViews' | 'filterViewsBy' | 'manufacturersOfInterestIds' | 'productCategoriesOfInterestIds'
        >
      >;
    }
  >;
};

export const GetUserPreferencesDocument = /* #__PURE__ */ gql`
  query getUserPreferences {
    currentUser {
      preferences {
        filterViews
        filterViewsBy
        manufacturersOfInterestIds
        productCategoriesOfInterestIds
      }
    }
  }
`;

/**
 * __useGetUserPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUserPreferencesQueryResult, GetUserPreferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetUserPreferencesQueryResult, GetUserPreferencesQueryVariables>(GetUserPreferencesDocument, options);
}
export function useGetUserPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserPreferencesQueryResult, GetUserPreferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetUserPreferencesQueryResult, GetUserPreferencesQueryVariables>(
    GetUserPreferencesDocument,
    options
  );
}
export type GetUserPreferencesQueryHookResult = ReturnType<typeof useGetUserPreferencesQuery>;
export type GetUserPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserPreferencesLazyQuery>;
