import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import { Card } from '@zen/DesignSystem';
import type { BookingRequestField } from '@zen/LegacyBookingRequests';
import BookingRequestButtons from '@zen/LegacyBookingRequests/BookingRequestButtons';
import { Role } from '@zen/Networks';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  bookingRequest: Optional<BookingRequestField>;
  zencargoReference: string;
}

const BookingRequestButtonsWrapper: FC<Props> = ({ bookingRequest, zencargoReference }) => {
  const role = useRole();
  const isForwarderApproved: boolean = !!bookingRequest?.forwarderApproved;
  const nonCustomerApprovalMessage: string = 'Your input is required to progress the booking. Please approve or reject.';
  const customerApprovalMessage: string = 'Awaiting approval by Zencargo.';
  const message: string =
    !isForwarderApproved && role === Role.CUSTOMER_USER ? customerApprovalMessage : nonCustomerApprovalMessage;

  return (
    <Card
      rightIcon={<BookingRequestButtons bookingRequest={bookingRequest} zencargoReference={zencargoReference} />}
      title="Approval required"
    >
      {message}
    </Card>
  );
};

export default BookingRequestButtonsWrapper;
