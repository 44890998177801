import { createContext } from 'react';

import { networks, NetworksRoutes } from '@zen/Routes';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

export interface NetworksContextProperties {
  isZencargoNetwork: boolean;
  routes: NetworksRoutes;
}

export const initialContext: NetworksContextProperties = {
  isZencargoNetwork: false,
  routes: networks()
};

const NetworksContext = createContext<NetworksContextProperties>(initialContext);

export const useNetworksContext = (): NetworksContextProperties => {
  const networksContext = useContextOrThrowError<NetworksContextProperties>(
    NetworksContext,
    'useNetworksContext must be used inside NetworksContextProvider'
  );

  return networksContext;
};

export default NetworksContext;
