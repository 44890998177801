import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateVesselNameMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateVesselNameInput;
}>;

export type UpdateVesselNameMutationResult = { __typename?: 'Mutation' } & {
  updateVesselName: { __typename?: 'UpdateVesselNamePayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
  };
};

export const UpdateVesselNameDocument = /* #__PURE__ */ gql`
  mutation updateVesselName($input: UpdateVesselNameInput!) {
    updateVesselName(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateVesselNameMutation__
 *
 * To run a mutation, you first call `useUpdateVesselNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVesselNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVesselNameMutation, { data, loading, error }] = useUpdateVesselNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateVesselNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateVesselNameMutationResult, UpdateVesselNameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateVesselNameMutationResult, UpdateVesselNameMutationVariables>(UpdateVesselNameDocument, options);
}
export type UpdateVesselNameMutationHookResult = ReturnType<typeof useUpdateVesselNameMutation>;
