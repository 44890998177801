import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import TimeZoneIcon from '@zen/Components/TimeZoneIcon';
import type { BusinessHours } from '@zen/Networks';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { JourneyShippingMilestone, JourneyStopActionEnum, MilestoneDatesType } from '../../types';
import EditableDate from '../EditableDate';
import MilestoneInitialEstimate from '../MilestoneInitialEstimate';
import { formatDatesAndTimeZones } from './helpers';

interface Props {
  alignment?: 'left' | 'right';
  locationBusinessHours: BusinessHours;
  milestone: JourneyShippingMilestone;
  stopAction: JourneyStopActionEnum;
  timeZone?: Nullable<string>;
}

const MilestoneDates: FC<Props> = ({ alignment = 'right', locationBusinessHours, milestone, stopAction, timeZone }) => {
  const { canManageDates, delayInDays } = milestone;

  const dates: MilestoneDatesType = {
    actual: milestone.actual,
    initialEstimate: milestone.initialEstimate,
    latestEstimate: milestone.latestEstimate
  };

  const { actual, initialEstimate, latestEstimate } = formatDatesAndTimeZones(dates);

  const hasDelay: boolean = delayInDays !== 0;
  const hasEstimatedDate: boolean = !!latestEstimate?.date;
  const dateWrapperClassNames: string = cx(
    {
      'w-full space-x-1': canManageDates,
      'flex-row-reverse justify-start': alignment === 'left',
      'justify-end': alignment === 'right'
    },
    'flex items-center group'
  );
  const classNames: string = cx(
    {
      'text-right': alignment === 'right',
      'text-left': alignment === 'left'
    },
    'flex flex-col items-end flex-1 w-full space-y-1.5 text-xs leading-snug'
  );

  const renderOriginalDate = (): ReactNode => {
    if (!hasDelay) return;

    return <MilestoneInitialEstimate delayInDays={delayInDays} initialEstimate={initialEstimate?.date} />;
  };

  const renderTimeZone = (date: Optional<string>): ReactNode => {
    return <TimeZoneIcon className="mx-1 opacity-0 group-hover:opacity-100" date={date} size="small" timeZone={timeZone} />;
  };

  const renderDates = (): ReactNode => {
    if (actual) {
      return (
        <>
          <div className={dateWrapperClassNames} data-testid="actual-date">
            {renderTimeZone(milestone.actual?.date)}
            <EditableDate
              businessHours={locationBusinessHours}
              date={actual?.date}
              milestone={milestone}
              stopAction={stopAction}
              timeZone={timeZone}
            />
          </div>
          {renderOriginalDate()}
        </>
      );
    }

    if (hasEstimatedDate) {
      return (
        <>
          <div className={dateWrapperClassNames} data-testid="latest-estimate">
            {renderTimeZone(milestone.latestEstimate?.startDateTime?.date)}
            <EditableDate
              businessHours={locationBusinessHours}
              date={latestEstimate?.date}
              milestone={milestone}
              stopAction={stopAction}
              timeZone={timeZone}
            />
          </div>
          {renderOriginalDate()}
        </>
      );
    }

    return (
      <div className={dateWrapperClassNames}>
        <EditableDate businessHours={locationBusinessHours} milestone={milestone} stopAction={stopAction} timeZone={timeZone} />
      </div>
    );
  };

  return (
    <div className={classNames} data-testid="milestone-dates">
      {renderDates()}
    </div>
  );
};

export default MilestoneDates;
