import type { FC } from 'react';

import { Table } from '@zen/DesignSystem';
import { usePagination } from '@zen/utils/hooks/pagination';

import {
  type GetAccountMembersQueryResult,
  type GetAccountMembersQueryVariables,
  useGetAccountBusinessUnitsQuery,
  useGetAccountMembersQuery
} from '../graphql';
import type { AccountMember } from '../types';
import getColumnsConfiguration from './accountMembersTableConfiguration';
import AddMemberModalButton from './AddMemberModalButton';

interface Props {
  accountId: string;
  accountName: string;
}

const AccountMembers: FC<Props> = ({ accountId, accountName }) => {
  const { data } = useGetAccountBusinessUnitsQuery({ variables: { accountId, first: 20 } });

  const { loading, nodes, paginationInfo, refetch, totalCount } = usePagination<
    GetAccountMembersQueryResult,
    GetAccountMembersQueryVariables,
    AccountMember
  >(useGetAccountMembersQuery, 'accountMembers', { accountId }, 20, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  const accountHasBusinessUnits: boolean = (data?.accountBusinessUnits?.nodes || []).length > 0;

  const handleSuccess = (): void => {
    setTimeout(() => {
      refetch();
    }, 1500);
  };

  return (
    <Table<AccountMember>
      actions={<AddMemberModalButton accountId={accountId} onSuccess={handleSuccess} />}
      columns={getColumnsConfiguration({ accountHasBusinessUnits, accountId, accountName })}
      data={nodes || []}
      emptyText="No members"
      loading={loading}
      paginationInfo={paginationInfo}
      tableId="accountMembers"
      title="Members"
      totalCountConfig={{
        totalCount,
        entityName: 'members'
      }}
    />
  );
};

export default AccountMembers;
