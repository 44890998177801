import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetPoIssuesQueryVariables = GraphQLTypes.Exact<{
  accountIds?: GraphQLTypes.InputMaybe<Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.PurchaseOrderIssueSortInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetPoIssuesQueryResult = { __typename?: 'Query' } & {
  purchaseOrderIssues?: GraphQLTypes.Maybe<
    { __typename?: 'IssuesPurchaseOrderIssueConnection' } & Pick<
      GraphQLTypes.IssuesPurchaseOrderIssueConnection,
      'totalCount'
    > & {
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
        nodes?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'IssuesPurchaseOrderIssue' } & Pick<
                GraphQLTypes.IssuesPurchaseOrderIssue,
                'purchaseOrderId' | 'orderReferenceNumber' | 'issueId' | 'accountId' | 'severity' | 'category' | 'state' | 'title'
              > & {
                  openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
                  startedAt?: GraphQLTypes.Maybe<
                    { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>
                  >;
                  occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
                }
            >
          >
        >;
      }
  >;
};

export const GetPoIssuesDocument = /* #__PURE__ */ gql`
  query getPoIssues(
    $accountIds: [String!]
    $order: PurchaseOrderIssueSortInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    purchaseOrderIssues: legacyPurchaseOrderIssues(
      accountIds: $accountIds
      order: $order
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      totalCount
      pageInfo {
        ...PageInfo
      }
      nodes {
        purchaseOrderId
        orderReferenceNumber
        issueId
        accountId
        severity
        category
        state
        title
        openedAt {
          date
          dateTime
        }
        startedAt {
          date
          dateTime
        }
        occurredOn {
          date
        }
      }
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetPoIssuesQuery__
 *
 * To run a query within a React component, call `useGetPoIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoIssuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      order: // value for 'order'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetPoIssuesQuery(baseOptions?: Apollo.QueryHookOptions<GetPoIssuesQueryResult, GetPoIssuesQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPoIssuesQueryResult, GetPoIssuesQueryVariables>(GetPoIssuesDocument, options);
}
export function useGetPoIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPoIssuesQueryResult, GetPoIssuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPoIssuesQueryResult, GetPoIssuesQueryVariables>(GetPoIssuesDocument, options);
}
export type GetPoIssuesQueryHookResult = ReturnType<typeof useGetPoIssuesQuery>;
export type GetPoIssuesLazyQueryHookResult = ReturnType<typeof useGetPoIssuesLazyQuery>;
