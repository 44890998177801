import type { FC } from 'react';

interface Props {
  className?: string;
}

const DetailsInfoHeader: FC<Props> = ({ children, className }) => {
  const classNames: string = `flex w-full text-xs bg-navy-dark text-white pl-10 pr-5 ${className}`;

  if (!children) {
    return null;
  }

  return (
    <div className={classNames} data-testid="details-info-header">
      {children}
    </div>
  );
};

export type { Props as DetailsInfoHeaderProps };
export default DetailsInfoHeader;
