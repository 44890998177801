import type { Separator } from './types';

const keyMapper = (keysToSeparateOn: Separator[]): string[] => {
  const separatorMapper: Record<Separator, string> = {
    Enter: 'Enter',
    Tab: 'Tab',
    Comma: ',',
    Space: ' '
  };

  return keysToSeparateOn.map((key: Separator) => separatorMapper[key]);
};

export { keyMapper };
