import { get } from 'lodash';
import { FC, useState } from 'react';

import { Button, Modal } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useCreateAccountConnectionMutation, useInviteAccountConnectionMutation } from '../../graphql';
import { AccountConnectionTypeEnum } from '../../types';
import DuplicatedAccountsForm from '../DuplicatedAccountsForm';
import { invitedCompanyId } from '../DuplicatedAccountsForm/DuplicatedAccountsForm';
import InviteCompanyConnectionForm from '../InviteCompanyConnectionForm';
import type { InviteConnectionFormValues } from '../InviteCompanyConnectionForm/types';
import type { MatchedCompanies } from './types';

interface Props {
  accountId: string;
}

const AddConnectionButton: FC<Props> = ({ accountId }) => {
  const { addError, addSuccess } = useNotification();
  const [isAddConnectionModalOpen, setIsAddConnectionModalOpen] = useState(false);
  const [isDuplicatedAccountsModalOpen, setIsDuplicatedAccountsModalOpen] = useState(false);
  const [matchedCompanies, setMatchedCompanies] = useState<Nullable<MatchedCompanies>>(null);

  const [inviteAccount] = useInviteAccountConnectionMutation({ refetchQueries: ['getAccountConnections'] });
  const [createConnection] = useCreateAccountConnectionMutation({ refetchQueries: ['getAccountConnections'] });

  const openAddConnectionModal = (): void => setIsAddConnectionModalOpen(true);
  const openDuplicatedAccountsModal = (): void => setIsDuplicatedAccountsModalOpen(true);
  const closeAddConnectionModal = (): void => setIsAddConnectionModalOpen(false);
  const closeDuplicatedAccountsModal = (): void => setIsDuplicatedAccountsModalOpen(false);

  const closeModals = (): void => {
    closeAddConnectionModal();
    closeDuplicatedAccountsModal();
    setMatchedCompanies(null);
  };

  const handleCompanyMatch = (values: MatchedCompanies): void => {
    setMatchedCompanies(values);
    openDuplicatedAccountsModal();
  };

  const inviteAccountConnection = async ({ companyName, email }: InviteConnectionFormValues): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        inviteAccount({
          variables: {
            input: {
              inviteeCompanyTradingName: companyName,
              inviteeEmail: email,
              inviterAccountId: accountId
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Something went wrong when adding the connection.'));
      },
      onSuccess: () => {
        closeModals();
        addSuccess('Connection invited.');
      }
    });
  };

  const handleAccountSelect = async ({ companyId }: { companyId: string }): Promise<IOkOrErrorResult> => {
    if (companyId === invitedCompanyId) {
      return inviteAccountConnection({ companyName: matchedCompanies?.companyName || '', email: matchedCompanies?.email || '' });
    }

    return performFormMutation({
      mutationFn: () =>
        createConnection({
          variables: {
            input: {
              connectionType: AccountConnectionTypeEnum.SUPPLIER,
              sourceAccountId: accountId,
              targetAccountId: companyId
            }
          }
        }),
      onError: (errors) => {
        addError(get(errors, '[0].message', 'Something went wrong when adding the connection.'));
      },
      onSuccess: () => {
        closeModals();
        addSuccess('Connection added.');
      }
    });
  };

  return (
    <>
      <Modal
        closeOnClickAway={false}
        data-testid="add-connection-modal"
        isOpen={isAddConnectionModalOpen}
        onClose={closeAddConnectionModal}
        title="Add connection"
      >
        <InviteCompanyConnectionForm
          onCancel={closeAddConnectionModal}
          onCompanyMatch={handleCompanyMatch}
          onSubmit={inviteAccountConnection}
        />
      </Modal>
      <Modal
        closeButtonVisible={false}
        closeOnClickAway={false}
        data-testid="duplicated-accounts-modal"
        isOpen={isDuplicatedAccountsModalOpen}
        onClose={closeDuplicatedAccountsModal}
        tagline="We think this company might already exist in our records. Please confirm it's not a duplicate or connect to an existing record instead."
        title="Confirm this is not a duplicate"
      >
        <DuplicatedAccountsForm
          companyName={matchedCompanies?.companyName || ''}
          duplicates={matchedCompanies?.duplicates || []}
          onCancel={closeDuplicatedAccountsModal}
          onSubmit={handleAccountSelect}
        />
      </Modal>
      <Button onClick={openAddConnectionModal} size="compact">
        Add connection
      </Button>
    </>
  );
};

export default AddConnectionButton;
