import { useContext } from 'react';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'context' implicitly has an 'any' type.
function useContextOrThrowError<T>(context, noValueErrorMessage): T {
  const value: T = useContext<T>(context);

  if (value === undefined) {
    throw new Error(noValueErrorMessage);
  }

  return value;
}

export default useContextOrThrowError;
