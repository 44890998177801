import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateQuoteOptionDescriptionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateQuoteOptionDescriptionInput;
}>;

export type UpdateQuoteOptionDescriptionMutationResult = { __typename?: 'Mutation' } & {
  bookingUpdateQuoteOptionDescription?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateQuoteOptionDescriptionPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<
        { __typename?: 'QuoteOption' } & Pick<
          GraphQLTypes.QuoteOption,
          'id' | 'zencargoReference' | 'quoteReference' | 'accrualId' | 'description' | 'state'
        > & { total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>> }
      >;
    }
  >;
};

export const UpdateQuoteOptionDescriptionDocument = /* #__PURE__ */ gql`
  mutation updateQuoteOptionDescription($input: UpdateQuoteOptionDescriptionInput!) {
    bookingUpdateQuoteOptionDescription(input: $input) {
      quoteOption {
        id
        zencargoReference
        quoteReference
        accrualId
        description
        state
        total {
          value
          currency
        }
      }
    }
  }
`;

/**
 * __useUpdateQuoteOptionDescriptionMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteOptionDescriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteOptionDescriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteOptionDescriptionMutation, { data, loading, error }] = useUpdateQuoteOptionDescriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateQuoteOptionDescriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateQuoteOptionDescriptionMutationResult,
    UpdateQuoteOptionDescriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateQuoteOptionDescriptionMutationResult, UpdateQuoteOptionDescriptionMutationVariables>(
    UpdateQuoteOptionDescriptionDocument,
    options
  );
}
export type UpdateQuoteOptionDescriptionMutationHookResult = ReturnType<typeof useUpdateQuoteOptionDescriptionMutation>;
