import { isEqual } from 'lodash';
import { type FC, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';

import type { FilterOptionsType } from '@zen/Components/Filters/types';
import { DatePicker, type DateRange, getAllOptions, type Option, Popover } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import type { FilterComponentProps } from '../../types';
import FilterFooter from '../FilterFooter';
import { buildOptionListSections } from '../helpers';
import OptionList from '../OptionList/OptionList';
import type { OptionListSection } from '../OptionList/types';
import { buildDateRangeLabel } from './helpers';

type FilterDateRangePickerProps = {
  options?: FilterOptionsType<DateRange>;
};

type Props = {
  onChange: (value: Nullable<DateRange>) => void;
  value: Nullable<DateRange>;
} & FilterDateRangePickerProps &
  FilterComponentProps;

const FilterDateRangePicker: FC<Props> = (props) => {
  const { options, value, onChange, deselectFilter, active, onDelete, children, placement, disabled } = props;
  const [dateRange, setDateRange] = useState<Partial<DateRange>>({});
  const { startDate, endDate, value: dateRangeValue } = dateRange;
  const allOptions = getAllOptions(options || []);
  const selectedOption: Option<DateRange> | undefined = allOptions?.find((option) => isEqual(option.value, value));

  useDeepCompareEffect(() => {
    setDateRange(value || {});
  }, [value]);

  useDeepCompareEffect(() => {
    if (!isEqual(dateRange, value) && startDate && endDate) {
      onChange({ startDate, endDate, value: dateRangeValue });
    }
  }, [dateRange]);

  const handleClear = (): void => {
    onChange(null);
  };

  const popover = () => {
    const isSelected = (option: Option<DateRange>): boolean => isEqual(option.value, selectedOption?.value);

    const onClick = (option: Option<DateRange>): void => {
      setDateRange(option.value);
    };

    const list: OptionListSection[] = buildOptionListSections(options || [], { onClick, isSelected });

    const selectedValue = dateRange?.startDate ? { from: dateRange.startDate, to: dateRange.endDate } : undefined;

    return (
      <div className="divide-y divide-solid divide-grey-lighter" data-testid="filter-date-range-picker-popover">
        <div className="flex divide-x divide-grey-lighter divide-solid">
          {!!options?.length && <OptionList className="w-48" list={list} listClassName="max-h-96" />}
          <DatePicker
            className="border-0"
            isInline={true}
            mode="range"
            numberOfMonths={1}
            onChange={(range) => {
              setDateRange({ startDate: range?.from, endDate: range?.to });
            }}
            selected={selectedValue}
          />
        </div>
        <FilterFooter onClear={handleClear} />
      </div>
    );
  };

  const handleVisibilityChange = (visible: boolean): void => {
    if (!visible && (!startDate || !endDate)) {
      onDelete();
    }

    if (!visible) {
      deselectFilter();
    }
  };

  return (
    <Popover
      disabled={disabled}
      isVisible={active}
      onVisibilityChange={handleVisibilityChange}
      placement={placement}
      popover={popover}
      popoverClassNames="border border-solid border-grey-lighter overflow-hidden"
      renderInPortal={true}
    >
      {children(buildDateRangeLabel(dateRange))}
    </Popover>
  );
};

export default FilterDateRangePicker;
export type { FilterDateRangePickerProps };
