import type { FC } from 'react';

import { Dialog } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useArchiveAccountLocationLinkMutation } from '../../graphql';

interface Props {
  accountId: string;
  isOpen: boolean;
  locationId: string;
  locationName: string;
  onClose: () => void;
}

const DeleteLocationModal: FC<Props> = (props) => {
  const { accountId, isOpen, onClose, locationId, locationName } = props;
  const { addError, addSuccess } = useNotification();
  const [archiveLocation, { loading }] = useArchiveAccountLocationLinkMutation({ refetchQueries: ['getAccountLocations'] });

  const handleArchiveLocation = async (id: string): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        archiveLocation({
          variables: {
            input: {
              accountId,
              locationId: id
            }
          }
        }),
      onError: () => addError('Location could not be deleted.', { label: 'Try again', onClick: () => handleArchiveLocation(id) }),
      onSuccess: () => {
        addSuccess('Location was successfully deleted.');
        onClose();
      }
    });
  };

  const handleConfirm = async (): Promise<void> => {
    if (locationId) {
      await handleArchiveLocation(locationId);
    }
  };

  return (
    <Dialog
      confirmLabel="Delete"
      header={`Delete '${locationName}'?`}
      isLoading={loading}
      isOpen={isOpen}
      message="This location will no longer be accessible for future bookings. Existing bookings associated with this location will be retained. This action cannot be undone."
      onClose={onClose}
      onConfirm={handleConfirm}
    />
  );
};

export default DeleteLocationModal;
export type { Props as DeleteLocationModalProps };
