import * as Yup from 'yup';

import type { Optional } from '@zen/utils/typescript';

const incotermsValidator = Yup.array()
  .of(Yup.string())
  .nullable()
  .test('incoterms validation', function test(incoterms: Optional<Array<string | undefined>>) {
    if (incoterms && incoterms.length === 0) {
      return this.createError({ message: 'Incoterms are required.' });
    }

    return true;
  });

const currencyValidator = Yup.string()
  .nullable()
  .test('incoterms validation', function test(currency: Optional<string>) {
    if (!currency) {
      return this.createError({ message: 'Currency is required.' });
    }

    return true;
  });

const chargeTypeIdValidator = Yup.string()
  .nullable()
  .test('charge type validation', function test(chargeTypeId: Optional<string>) {
    if (!chargeTypeId) {
      return this.createError({ message: 'Charge type is required.' });
    }

    return true;
  });

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  parseErrors: Yup.array().of(
    Yup.object().shape({
      values: Yup.object().shape({
        incoterms: incotermsValidator,
        currency: currencyValidator,
        chargeTypeId: chargeTypeIdValidator
      })
    })
  )
});
