import type { BookingRequest, Shipment } from './types';
import { RejectedReasonEnum } from './types';

export const prepareBookingRequestsList = (data: Shipment[]): BookingRequest[] =>
  data.map((shipment: Shipment): BookingRequest => {
    const {
      bookingRequest,
      calculatedInfo,
      canApproveBookingRequest,
      cargo,
      cargoReadyDate,
      clientReference,
      customer,
      delay,
      estimatedArrival,
      estimatedDeparture,
      estimatedDelivery,
      modeOfTransport,
      networksDestination,
      networksOrigin,
      purchaseOrderReferences,
      stage,
      zencargoReference
    } = shipment;

    return {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Maybe<BookingRequest> | undefined' is not as... Remove this comment to see the full error message
      bookingRequest,
      canApproveBookingRequest,
      calculatedInfo,
      cargo,
      cargoReadyDate: cargoReadyDate?.date || '',
      clientReference,
      customer,
      delay,
      destination: networksDestination?.label?.long || '',
      destinationShort: networksDestination?.label?.short || '',
      estimatedArrival,
      estimatedDeparture,
      estimatedDelivery,
      modeOfTransport,
      origin: networksOrigin?.label?.long || '',
      originShort: networksOrigin?.label?.short || '',
      purchaseOrderReferences,
      stage,
      zencargoReference
    };
  });

export const rejectedReasonLabelMapping: Record<RejectedReasonEnum, string> = {
  [RejectedReasonEnum.FAILED_QC_INSPECTION]: 'Failed qc inspection',
  [RejectedReasonEnum.INCOTERM]: 'Incoterm',
  [RejectedReasonEnum.OTHER]: 'Other',
  [RejectedReasonEnum.POS_ATTACHED_OR_ITEMS]: 'POS attached or items',
  [RejectedReasonEnum.SHIPMENT_MODE]: 'Shipment mode',
  [RejectedReasonEnum.SHIPMENT_SAMPLE_NOT_APPROVED]: 'Shipment sample not approved',
  [RejectedReasonEnum.SHIPMENT_SAMPLE_NOT_RECEIVED]: 'Shipment sample not received',
  [RejectedReasonEnum.SHIPPING_TOO_EARLY]: 'Shipping too early',
  [RejectedReasonEnum.SHIPPING_TOO_LATE]: 'Shipping too late',
  [RejectedReasonEnum.UNITS_BOOKED_EXCEED_ORDERED_UNITS]: 'Units booked exceed ordered units',
  [RejectedReasonEnum.UNITS_BOOKED_LESS_THAN_ORDERED_UNITS]: 'Units booked less than ordered units',
  [RejectedReasonEnum.WRONGLY_ENTERED_DATA]: 'Wrongly entered data'
};
