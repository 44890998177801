import type { ReactNode } from 'react';

import { getChargeTypeAttributes } from '@zen/Components/Form';
import WithConfirmationModal from '@zen/Components/WithConfirmationModal';
import type { TableColumn } from '@zen/DesignSystem';
import { TextLink } from '@zen/DesignSystem';

import type { ChargeType } from '../types';
import { chargeBasisLabelMapping, chargeTypeLabelMapping } from './chargeTypeList.helpers';

export const getColumnsConfiguration = (
  onEdit: (chargeTypeId: string) => void,
  onDelete: (chargeType: ChargeType) => void
): TableColumn<ChargeType>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { id, name }): ReactNode => {
        return <TextLink onClick={() => onEdit(id)}>{name}</TextLink>;
      }
    },
    {
      key: 'type',
      title: 'Type',
      render: (_, { chargeType }): ReactNode => <span>{chargeType && chargeTypeLabelMapping[chargeType]}</span>
    },
    {
      key: 'chargeBasis',
      title: 'Charge basis',
      render: (_, { chargeBasis }): ReactNode => {
        return <span>{chargeBasis && chargeBasisLabelMapping[chargeBasis]}</span>;
      }
    },
    {
      key: 'additionalDetails',
      title: 'Additional details',
      render: (_, item): ReactNode => {
        return <span>{getChargeTypeAttributes(item)}</span>;
      }
    },
    {
      key: 'delete',
      title: 'Action',
      render: (_, chargeType): ReactNode => {
        return (
          <WithConfirmationModal message="Do you want to delete this charge type?" onConfirm={() => onDelete(chargeType)}>
            {({ requestConfirm }) => <TextLink onClick={requestConfirm}>Delete</TextLink>}
          </WithConfirmationModal>
        );
      },
      sortable: false,
      width: '100px'
    }
  ];
};
