import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CourierQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type CourierQueryResult = { __typename?: 'Query' } & {
  courier?: GraphQLTypes.Maybe<{ __typename?: 'Courier' } & Pick<GraphQLTypes.Courier, 'id' | 'courierName'>>;
};

export const CourierDocument = /* #__PURE__ */ gql`
  query courier($id: String!) {
    courier(id: $id) {
      id
      courierName
    }
  }
`;

/**
 * __useCourierQuery__
 *
 * To run a query within a React component, call `useCourierQuery` and pass it any options that fit your needs.
 * When your component renders, `useCourierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCourierQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCourierQuery(baseOptions: Apollo.QueryHookOptions<CourierQueryResult, CourierQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CourierQueryResult, CourierQueryVariables>(CourierDocument, options);
}
export function useCourierLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CourierQueryResult, CourierQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CourierQueryResult, CourierQueryVariables>(CourierDocument, options);
}
export type CourierQueryHookResult = ReturnType<typeof useCourierQuery>;
export type CourierLazyQueryHookResult = ReturnType<typeof useCourierLazyQuery>;
