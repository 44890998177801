import { get } from 'lodash';

import type { Nullable, Optional } from '@zen/utils/typescript';

import type { CargoReadyDateValues } from './components/CargoReadyDateForm';
import type { OrderLineItem, OrderListViewLot, OrderLot } from './types';
import { PurchaseOrderLotStageEnum } from './types';

interface OrderWithLotIds {
  orderedLineItems: Array<{
    lots: Array<{ id: string }>;
  }>;
}

export const getCurrentStage = <T extends { currentStage?: Optional<boolean> }>(stages: T[]): T | undefined =>
  stages.find((stage) => stage.currentStage);

export const getCurrentStageIndex = (lot: OrderLot) => lot?.stages?.findIndex((stage) => stage.currentStage);

export const getLotIds = (lots: { id: string }[]): string[] => lots.map(({ id }) => id);

export const getLots = (lineItems: OrderLineItem[]): OrderLot[] => {
  return lineItems.reduce((prev: OrderLot[], next: OrderLineItem) => prev.concat(next.lots), []);
};

export const getCargoReadyDate = (lot: Optional<OrderLot>): string | undefined => get(lot, 'cargoReadyDate.date');

export const getLotDeliveryDate = ({ stages }: OrderLot): string => {
  const deliveryStage = stages ? stages[stages.length - 1] : undefined;

  return get(deliveryStage, 'date.date') || '';
};

export const getQuantityFulfilled = (lots: OrderLot[]): number => {
  return lots
    .filter((lot) => lot.fulfilled)
    .reduce((prev, curr) => {
      const quantityFulfilled: number = curr.quantityFulfilled || 0;

      return prev + quantityFulfilled;
    }, 0);
};

export const getTotalCbm = <T extends { cbm?: Nullable<number> }>(item: T[]): number => {
  return item.reduce((prev, curr) => {
    const cbm: number = curr.cbm || 0;

    return prev + cbm;
  }, 0);
};

export const getOrderLotIds = ({ orderedLineItems }: OrderWithLotIds): string[] => {
  return orderedLineItems
    .reduce((prev: OrderWithLotIds['orderedLineItems'][0]['lots'], next) => prev.concat(next.lots), [])
    .map(({ id }) => id);
};

export const getCargoReadyDateData = (orderLots: OrderListViewLot[]): CargoReadyDateValues => {
  const { purchaseOrder } = orderLots[0];
  const cargoReadyDateEstimate = purchaseOrder?.cargoReadyDateEstimate;
  const orderDate = purchaseOrder?.orderDate;
  const orderReferenceNumber = purchaseOrder?.orderReferenceNumber;

  return {
    lotIds: getLotIds(orderLots),
    cargoReadyDate: cargoReadyDateEstimate?.date,
    orderDate,
    orderReferenceNumber
  };
};

export const lotStageLabelMapping: Record<PurchaseOrderLotStageEnum, string> = {
  [PurchaseOrderLotStageEnum.ORDERED]: 'Ordered',
  [PurchaseOrderLotStageEnum.READY]: 'Ready',
  [PurchaseOrderLotStageEnum.ARRIVED_PORT]: 'Arrived port',
  [PurchaseOrderLotStageEnum.IN_TRANSIT]: 'In transit',
  [PurchaseOrderLotStageEnum.DELIVERED]: 'Delivered'
};
