import type { QuoteOption } from '@zen/Quotes/types';
import { formatDate } from '@zen/utils/dateTime';
import { formatCurrency } from '@zen/utils/formatting';

export const buildClipboardDetailsText = (
  quoteOption: Pick<QuoteOption, 'description' | 'expiryDate' | 'quoteReference' | 'total'>
): string => {
  const { description, expiryDate, quoteReference, total } = quoteOption;
  const expireDateFormatted: string = (expiryDate?.date && formatDate(expiryDate.date)) || '-';
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
  const priceFormatted: string = total && formatCurrency(total.currency, total.value);

  return [
    `Quote ref: ${quoteReference}`,
    `Cost: ${priceFormatted}`,
    `Expiry date: ${expireDateFormatted || ''}`,
    `Description: ${description}`
  ].join('\n');
};
