import { type FC, type ReactNode, useState } from 'react';

import { ContextMenu, Dialog, type MenuItemType } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateAccountHeadquartersMutation } from '../../graphql';

interface Props {
  accountId: string;
  companyName: string;
  locationId: string;
}

const AccountLocationsActions: FC<Props> = (props: Props) => {
  const { accountId, companyName, locationId } = props;

  const [isAssignHQModalOpen, setAssignHQModalOpen] = useState<boolean>(false);
  const [updateAccountHeadquarters, { loading }] = useUpdateAccountHeadquartersMutation();
  const { addError, addSuccess } = useNotification();

  const assignHQ = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateAccountHeadquarters({
          awaitRefetchQueries: false,
          refetchQueries: ['getAccountLocations'],
          variables: {
            input: {
              accountId,
              companyName,
              newHeadquartersLocationId: locationId
            }
          }
        }),
      onError: () => {
        closeAssignHQModal();
        addError('Something went wrong when trying to update the headquarters.', {
          label: 'Try again',
          onClick: assignHQ
        });
      },
      onSuccess: () => {
        closeAssignHQModal();
        addSuccess('Headquarters updated.');
      }
    });
  };

  const closeAssignHQModal = () => setAssignHQModalOpen(false);

  const handleAssignHQ = (): void => {
    return setAssignHQModalOpen(true);
  };

  const renderActions = (): ReactNode => {
    const items: MenuItemType[] = [
      {
        label: 'Make HQ',
        onClick: handleAssignHQ,
        icon: 'zicon-hq'
      }
    ];

    return <ContextMenu inline={false} items={items} />;
  };

  return (
    <>
      <Dialog
        confirmLabel="Make HQ"
        header={`Make this location the HQ for ${companyName}`}
        isLoading={loading}
        isOpen={isAssignHQModalOpen}
        message="The HQ is your company's legal address, but it can also serve as a shipping location. We'll make it simple to assign your HQ as the consignor or consignee on your shipments."
        onClose={closeAssignHQModal}
        onConfirm={assignHQ}
      />
      {renderActions()}
    </>
  );
};

export default AccountLocationsActions;
