import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH, JourneyStopLocationTypeEnum } from '@zen/Journey';
import type { NetworksLabelType } from '@zen/Networks';
import type { Nullable } from '@zen/utils/typescript';

import type { JourneyStopActionEnum } from '../../types';
import StopLocationInlineField from '../forms/StopLocationInlineField';
import { useJourneyContext } from '../JourneyDetails/JourneyDetailsContext';

interface Props {
  canUpdateLocation: boolean;
  isFirstMilestoneCompleted: boolean;
  isStopVisible: boolean;
  locationId: string;
  locationName: Nullable<NetworksLabelType>;
  locationType: JourneyStopLocationTypeEnum;
  stopAction: JourneyStopActionEnum;
  stopId: string;
}

const JourneyStopLocation: FC<Props> = (props) => {
  const {
    canUpdateLocation,
    isFirstMilestoneCompleted,
    isStopVisible,
    locationId,
    locationName,
    locationType,
    stopAction,
    stopId
  } = props;
  const { modeOfTransport } = useJourneyContext();

  const iconClassName: string = cx(
    {
      'text-azure-base': isFirstMilestoneCompleted,
      'text-grey-light': !isFirstMilestoneCompleted,
      'opacity-100': isStopVisible,
      'opacity-0': !isStopVisible
    },
    'text-xs transition-opacity duration-300 py-0.5 bg-white relative z-10'
  );
  const iconWrapperClassName: string = cx('mt-0.5 flex justify-center shrink-0', JOURNEY_TIMELINE_WIDTH);

  const locationClassName: string = cx(
    { 'opacity-100': isStopVisible, 'opacity-0': !isStopVisible },
    'transition-all duration-200 ease flex-1 shrink-0 min-w-0'
  );

  const renderLocationDetails = (): ReactNode => {
    if (canUpdateLocation) {
      return (
        <StopLocationInlineField
          locationId={locationId}
          locationName={locationName?.long || ''}
          modeOfTransport={modeOfTransport}
          stopAction={stopAction}
          stopId={stopId}
        />
      );
    }

    if (!locationName) {
      return <div className="text-sm text-grey-light">Confirmation on {locationType.toLocaleLowerCase()} pending</div>;
    }

    return <div className="truncate">{locationName?.long}</div>;
  };

  return (
    <div className="text-sm leading-normal text-grey-dark ml-48 pt-1.5 pb-3 relative -mt-4 bg-white flex items-start">
      <span
        className={iconWrapperClassName}
        data-routedatapoint={isStopVisible}
        data-routedatapointcompleted={isFirstMilestoneCompleted}
      >
        <Icon className={iconClassName} icon="zicon-pin" />
      </span>
      <div className={locationClassName}>{renderLocationDetails()}</div>
    </div>
  );
};

export default JourneyStopLocation;
