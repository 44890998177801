import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type MyOrganisationQueryVariables = GraphQLTypes.Exact<{
  networkId: GraphQLTypes.Scalars['String'];
}>;

export type MyOrganisationQueryResult = { __typename?: 'Query' } & {
  network?: GraphQLTypes.Maybe<
    { __typename?: 'Network' } & {
      myOrganisation?: GraphQLTypes.Maybe<
        { __typename?: 'MyOrganisation' } & Pick<
          GraphQLTypes.MyOrganisation,
          'vat' | 'eori' | 'assignedRoles' | 'id' | 'name' | 'type'
        > & {
            locations: Array<
              { __typename?: 'NetworksLocation' } & Pick<GraphQLTypes.NetworksLocation, 'id' | 'name' | 'locationType'> & {
                  address: { __typename?: 'LegacyAddress' } & Pick<
                    GraphQLTypes.LegacyAddress,
                    'city' | 'countyOrState' | 'postalCodeOrZip' | 'street'
                  > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>> };
                  geolocation?: GraphQLTypes.Maybe<
                    { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
                  >;
                }
            >;
          }
      >;
    }
  >;
};

export const MyOrganisationQueryDocument = /* #__PURE__ */ gql`
  query myOrganisationQuery($networkId: String!) {
    network(id: $networkId) {
      myOrganisation {
        vat
        eori
        assignedRoles
        id
        name
        type
        locations {
          id
          name
          address {
            city
            country {
              name
              code
            }
            countyOrState
            postalCodeOrZip
            street
          }
          geolocation {
            latitude
            longitude
          }
          locationType
        }
      }
    }
  }
`;

/**
 * __useMyOrganisationQuery__
 *
 * To run a query within a React component, call `useMyOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyOrganisationQuery({
 *   variables: {
 *      networkId: // value for 'networkId'
 *   },
 * });
 */
export function useMyOrganisationQuery(
  baseOptions: Apollo.QueryHookOptions<MyOrganisationQueryResult, MyOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<MyOrganisationQueryResult, MyOrganisationQueryVariables>(MyOrganisationQueryDocument, options);
}
export function useMyOrganisationQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyOrganisationQueryResult, MyOrganisationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<MyOrganisationQueryResult, MyOrganisationQueryVariables>(MyOrganisationQueryDocument, options);
}
export type MyOrganisationQueryHookResult = ReturnType<typeof useMyOrganisationQuery>;
export type MyOrganisationQueryLazyQueryHookResult = ReturnType<typeof useMyOrganisationQueryLazyQuery>;
