import type { FC, ReactNode } from 'react';

import { Form } from '@zen/Components/Form';
import FormTextarea from '@zen/Components/Form/FormTextarea';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Undefinable } from '@zen/utils/typescript';

interface FormValues {
  reasonDescription: string;
}
interface Props {
  initialValues: FormValues;
  onSubmit: (values: FormValues) => void;
}

const OtherRejectReasonForm: FC<Props> = (props) => {
  const { initialValues, onSubmit } = props;
  const { reasonDescription } = initialValues;

  const formButtons: Undefinable<() => ReactNode> = reasonDescription ? () => null : undefined;

  const handleSubmit = async (values: FormValues): Promise<IOkOrErrorResult> => {
    return Promise.resolve({ ok: { data: values }, error: null });
  };

  return (
    <div className="py-2">
      <Form
        formButtons={formButtons}
        formName="OtherRejectReasonForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSubmit}
      >
        {() => (
          <FormTextarea disabled={!!reasonDescription} label="Can you please explain your reason..." name="reasonDescription" />
        )}
      </Form>
    </div>
  );
};

export default OtherRejectReasonForm;
