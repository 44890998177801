import { type FC, ReactNode, useState } from 'react';

import Slideout, { SlideoutHeader } from '@zen/Components/Slideout';
import { IconButton } from '@zen/DesignSystem';
import { CurrentShipmentOpenTaskCount, MiniTaskList } from '@zen/Tasks';
import useTracking from '@zen/utils/hooks/useTracking';

import { MiniTaskListTrackingAction, MiniTaskListTrackingCategory } from './types';

const MiniTaskListToggler: FC = () => {
  const [isMiniTaskListOpen, setIsMiniTaskListOpen] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const handleClose = (): void => setIsMiniTaskListOpen(false);

  const handleToggle = (): void => {
    setIsMiniTaskListOpen(!isMiniTaskListOpen);

    trackEvent({
      category: MiniTaskListTrackingCategory,
      action: MiniTaskListTrackingAction.MINI_TASK_LIST_TOGGLER_CLICKED,
      label: 'Show tasks'
    });
  };

  const title: ReactNode = (
    <>
      Tasks <CurrentShipmentOpenTaskCount className="ml-2" />
    </>
  );

  return (
    <>
      <span className="relative">
        <IconButton icon="zicon-sidebar-closed" onClick={handleToggle} title="Show tasks" variant="secondary" />
        <CurrentShipmentOpenTaskCount className="absolute pointer-events-none -top-1 -right-1" />
      </span>
      <Slideout onOutsideClick={handleClose} show={isMiniTaskListOpen}>
        <SlideoutHeader onClose={handleClose} title={title} />
        <MiniTaskList />
      </Slideout>
    </>
  );
};

export default MiniTaskListToggler;
