import { FC, useState } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';

import type { TaskDetailsFiltersInput } from '../graphql';
import MiniTaskListContainer from './MiniTaskListContainer';
import MiniTaskListFilters from './MiniTaskListFilters';
import type { MiniTaskListFilter } from './MiniTaskListFilters/MiniTaskListFilters';

const MiniTaskList: FC = () => {
  const { userProfile } = useAccount();
  const initialFilterState: MiniTaskListFilter = { assignee: [userProfile.uuid], activeStatus: 'active' };
  const [taskFilters, setTaskFilters] = useState<MiniTaskListFilter>(initialFilterState);

  const handleFilterChange = (newFilters: TaskDetailsFiltersInput): void => {
    setTaskFilters({ ...taskFilters, ...newFilters });
  };

  return (
    <div className="w-100">
      <div className="px-6 py-4 border-b border-solid border-grey-lighter">
        <MiniTaskListFilters currentFilters={taskFilters} onFilterChange={handleFilterChange} />
      </div>
      <div className="p-6 overflow-scroll overscroll-contain max-h-[calc(100vh-13.5rem)]">
        <MiniTaskListContainer filters={taskFilters} />
      </div>
    </div>
  );
};

export default MiniTaskList;
