import * as Yup from 'yup';

import { entityNameValidator } from '@zen/Accounts/validations';

const emailMessage: string = 'Please enter a valid email address.';

const inviteCompanyConnectionValidation = Yup.object({
  companyName: entityNameValidator('Company name'),
  email: Yup.string().nullable().email(emailMessage).required(emailMessage)
}).required();

export { inviteCompanyConnectionValidation };
