import type { ReactElement, ReactNode } from 'react';

import { Body, Button, Headline } from '@zen/DesignSystem';

type EmptyStateButton = { onClick: () => void; title: string };

interface Props {
  className?: string;
  illustration: ReactNode;
  primaryButton?: EmptyStateButton;
  secondaryButton?: EmptyStateButton;
  subTitle?: string;
  title: string;
}

const EmptyState = ({ className = '', title, subTitle, illustration, primaryButton, secondaryButton }: Props): ReactElement => {
  const classNames: string = `flex flex-col items-center m-auto max-w-lg text-center ${className}`;

  const renderImage = (): ReactNode => {
    if (typeof illustration === 'string') {
      return <img alt="illustration" className="h-28" src={illustration} />;
    }

    return illustration;
  };

  return (
    <div className={classNames} data-testid="empty-state">
      {renderImage()}
      <Headline className="mt-6 mb-2 text-grey-dark" level={2}>
        {title}
      </Headline>
      {subTitle && <Body className="text-grey-base">{subTitle}</Body>}
      <div className="flex mt-6 space-x-4">
        {primaryButton && <Button onClick={primaryButton.onClick}>{primaryButton.title}</Button>}
        {secondaryButton && (
          <Button onClick={secondaryButton.onClick} variant="secondary">
            {secondaryButton.title}
          </Button>
        )}
      </div>
    </div>
  );
};

export type { Props as EmptyStateProps };

export default EmptyState;
