import type { FC } from 'react';

import { Pill } from '@zen/DesignSystem';

const RateCardError: FC = () => {
  return (
    <Pill iconLeft="zicon-warning-triangle" type="problem">
      Something went wrong when linking rate card
    </Pill>
  );
};

export default RateCardError;
