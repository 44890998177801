import type { SortInput } from '@zen/types';
import useUrlPagination from '@zen/utils/hooks/pagination/useUrlPagination';
import useAccount from '@zen/utils/hooks/useAccount';

import { BookingRequestsQueryResult, BookingRequestsQueryVariables, useBookingRequestsQuery } from '../graphql';
import type { BookingRequestFilter, Shipment } from '../types';

const useBookingRequests = (filter: BookingRequestFilter, order: SortInput) => {
  const { accountUuid: accountId } = useAccount();

  return useUrlPagination<BookingRequestsQueryResult, BookingRequestsQueryVariables, Shipment>(
    useBookingRequestsQuery,
    'bookings',
    {
      ...(accountId && { customerUuid: accountId }),
      order,
      ...filter
    },
    { fetchPolicy: 'cache-and-network' },
    20
  );
};

export default useBookingRequests;
