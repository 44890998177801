import type { TableColumn } from '@zen/DesignSystem';
import {
  chargeTypeColumn,
  defaultChargeDetailsColumn,
  getActionsColumn,
  getCargoOptionsColumn,
  getChargeBasisColumn,
  getChargeNameColumn,
  getCurrencyColumn,
  getLocationColumn,
  getUnitPriceColumn
} from '@zen/RateCards/components/helpers';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeLocationType, ChargeTableAction, LocationType } from '../../components/types';

export const getPortChargeTableColumns = (
  actions: ChargeTableAction,
  isEditable: boolean = false,
  type: LocationType
): TableColumn<RateCardCharge>[] => {
  const { onUpdate } = actions;
  const locationKey: ChargeLocationType = type === 'origin' ? 'fromLocation' : 'toLocation';

  return [
    getLocationColumn(locationKey, `Port of ${type}`),
    chargeTypeColumn,
    getChargeNameColumn(isEditable, onUpdate),
    getChargeBasisColumn(onUpdate),
    getCargoOptionsColumn(onUpdate),
    defaultChargeDetailsColumn,
    getUnitPriceColumn(isEditable, onUpdate, 'customChargeValue'),
    getCurrencyColumn(isEditable, onUpdate, 'customCurrency', 'Custom currency'),
    getActionsColumn(actions)
  ];
};
