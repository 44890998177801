import type { FC, ReactElement } from 'react';

import { Switch, Tooltip } from '@zen/DesignSystem';
import type { JourneyToggleCollectionValidationResult } from '@zen/Journey/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import { journeyRefetchQueryList } from '../forms/helpers';
import { useToggleJourneyCollectionMutation } from './graphql';
import { getTooltipMessage } from './helpers';

interface Props {
  canToggle: boolean;
  canToggleCurrently: Optional<JourneyToggleCollectionValidationResult>;
  isCollectionEnabled: boolean;
  zencargoReference: string;
}

const CollectionToggler: FC<Props> = ({ canToggle, canToggleCurrently, isCollectionEnabled, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [toggleCollection, { loading }] = useToggleJourneyCollectionMutation();

  const handleChange = async (isEnabled: boolean): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        toggleCollection({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              zencargoReference,
              isEnabled
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Collection has been toggled.');
      }
    });
  };

  if (!canToggle) return null;

  const renderSwitcher = (): ReactElement => {
    return (
      <Switch
        isChecked={isCollectionEnabled}
        isDisabled={!canToggleCurrently?.value || loading}
        label="Collection"
        name="collection"
        onChange={handleChange}
      />
    );
  };

  const renderTooltip = (): ReactElement => {
    return <Tooltip tooltipContent={getTooltipMessage(canToggleCurrently?.reason)}>{renderSwitcher()}</Tooltip>;
  };

  return <div data-testid="collection-toggler">{canToggleCurrently?.value ? renderSwitcher() : renderTooltip()}</div>;
};

export default CollectionToggler;
