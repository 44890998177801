import type { ContainerChargePayload, FreightChargePayload } from '../../RateCardForm/reducer/types';
import {
  formatCharacteristics,
  formatChargeBasis,
  formatChargeType,
  formatContainerType,
  formatCost,
  formatIncoterms,
  formatPort
} from '../../utils/tableDataFormatting';
import { mapDataToRow } from '../utils';

export const prepareFreightChargeData = (freightCharges: FreightChargePayload[]): string[][] => {
  const data: string[][] = [];

  freightCharges.forEach((freightCharge: FreightChargePayload) => {
    const formattedFreightCharges: Record<string, string>[] = getFormattedFreightCharges(freightCharge);

    formattedFreightCharges.forEach((formattedFreightCharge: Record<string, string>) => {
      const row: string[] = mapDataToRow(formattedFreightCharge);

      data.push(row);
    });
  });

  return data;
};

const getFormattedFreightCharges = (freightCharge: FreightChargePayload): Record<string, string>[] => {
  const { originPort, destinationPort, incoterms, chargeType, containerCharges } = freightCharge;

  return containerCharges.map((containerCharge: ContainerChargePayload) => ({
    originPort: formatPort(originPort),
    destinationPort: formatPort(destinationPort),
    incoterms: formatIncoterms(incoterms),
    unit: formatContainerType(containerCharge.unitType),
    cost: formatCost(containerCharge.chargeValue, containerCharge.currency),
    chargeType: formatChargeType(chargeType),
    chargeBasis: formatChargeBasis(chargeType),
    characteristics: formatCharacteristics(chargeType)
  }));
};
