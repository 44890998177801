import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ArchiveQuoteOptionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ArchiveQuoteOptionInput;
}>;

export type ArchiveQuoteOptionMutationResult = { __typename?: 'Mutation' } & {
  bookingArchiveQuoteOption?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveQuoteOptionPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<{ __typename?: 'QuoteOption' } & Pick<GraphQLTypes.QuoteOption, 'state'>>;
    }
  >;
};

export const ArchiveQuoteOptionDocument = /* #__PURE__ */ gql`
  mutation archiveQuoteOption($input: ArchiveQuoteOptionInput!) {
    bookingArchiveQuoteOption(input: $input) {
      quoteOption {
        state
      }
    }
  }
`;

/**
 * __useArchiveQuoteOptionMutation__
 *
 * To run a mutation, you first call `useArchiveQuoteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveQuoteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveQuoteOptionMutation, { data, loading, error }] = useArchiveQuoteOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveQuoteOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveQuoteOptionMutationResult, ArchiveQuoteOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ArchiveQuoteOptionMutationResult, ArchiveQuoteOptionMutationVariables>(
    ArchiveQuoteOptionDocument,
    options
  );
}
export type ArchiveQuoteOptionMutationHookResult = ReturnType<typeof useArchiveQuoteOptionMutation>;
