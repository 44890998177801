import type { FC, ReactNode } from 'react';

import LineProvider from '../LineProvider';
import EventLogItem from './EventLogItem';
import type { EventLog as EventLogType } from './types';

interface Props {
  events: EventLogType[];
}

const EventLog: FC<Props> = ({ events }) => {
  const renderEvent = (event: EventLogType, index: number): ReactNode => {
    return <EventLogItem key={index} {...event} />;
  };

  return (
    <LineProvider dependencies={events}>
      <div className="flex flex-col -ml-1 space-y-3" data-testid="event-log">
        {events.map(renderEvent)}
      </div>
    </LineProvider>
  );
};

export default EventLog;
