import type { FC, ReactNode } from 'react';

import { useNoteQuery, useUpdateNoteMutation } from '@zen/Booking/BookingDetails/components/Notes/graphql';
import { Form } from '@zen/Components/Form';
import FormRichText from '@zen/Components/Form/FormRichText';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { Button, Container } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import type { Note } from './types';

interface Props {
  zencargoReference: string;
}

const Notes: FC<Props> = ({ zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const [updateNote, { loading: isUpdating }] = useUpdateNoteMutation();
  const {
    data: notesData,
    error,
    loading
  } = useNoteQuery({
    variables: {
      id: zencargoReference,
      type: 'booking'
    }
  });

  const handleSubmit = async ({ body }: Note): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateNote({
          variables: {
            input: {
              id: zencargoReference,
              type: 'booking',
              body
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Notes have been saved.');
      }
    });
  };

  const renderFormButtons = (): ReactNode => (
    <Button className="m-6" isLoading={isUpdating} type="submit" variant="secondary">
      Save
    </Button>
  );

  return (
    <Container compactView={true} subtitle="Notes are only visible to Zencargo employees." title="Notes">
      <QueryHandler
        data={notesData?.note}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonLoading className="my-5" height={50} />}
      >
        {(notes: Pick<Note, 'body' | 'id'>) => (
          <Form enableReinitialize={true} formButtons={renderFormButtons} initialValues={notes} onSubmit={handleSubmit}>
            {() => <FormRichText hideLabel={true} name="body" placeholder="Write notes here..." />}
          </Form>
        )}
      </QueryHandler>
    </Container>
  );
};

export default Notes;
