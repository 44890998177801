import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';

import csrfTokenManager from '@zen/utils/csrfToken';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import type { CreateDirectUploadMutationResult } from './graphql';
import { DirectUploadInput, useCreateDirectUploadMutation } from './graphql';

interface FileUploadHandlerProps {
  file: File;
  onError: (error: string) => void;
}

interface UploaderProps {
  directUploadResult?: CreateDirectUploadMutationResult['createDirectUpload'];
  fileData: DirectUploadInput;
  fileObject: File;
  onError: (error: string) => void;
}

export interface UploadedFileData extends DirectUploadInput {
  signedBlobId: string;
}

export const useDirectUploads = () => {
  const [createDirectUpload] = useCreateDirectUploadMutation();

  const fileUploadHandler = (props: FileUploadHandlerProps): Promise<UploadedFileData> => {
    const { file, onError } = props;

    return getFileMetadata(file).then((directUpload: DirectUploadInput) => {
      return performMutation({
        mutationFn: () => createDirectUpload({ variables: { input: { directUpload } } }),
        onError: () => onError('Unable to upload file')
      }).then((result: IOkOrErrorResult<CreateDirectUploadMutationResult>) => {
        return uploadTheFile({
          directUploadResult: result?.ok?.createDirectUpload,
          fileObject: file,
          fileData: directUpload,
          onError
        });
      });
    });
  };

  const uploadTheFile = (props: UploaderProps): Promise<UploadedFileData> => {
    const { fileObject, fileData, onError, directUploadResult } = props;
    const directUpload = directUploadResult?.directUpload;
    const headers = JSON.parse(directUpload?.headers || '{}');

    headers['X-CSRF-Token'] = csrfTokenManager.get();

    const upload = new BlobUpload({ file: fileObject, directUploadData: { url: directUpload?.url || '', headers } });

    return new Promise((resolve) => {
      upload.create((error: Error) => {
        if (error) {
          onError('Unable to upload file');
        } else {
          resolve({ ...fileData, signedBlobId: directUpload?.signedBlobId || '' });
        }
      });
    });
  };

  const getFileMetadata = (file: File): Promise<DirectUploadInput> => {
    return new Promise((resolve) => {
      FileChecksum.create(file, (error: Error, checksum: string) => {
        resolve({
          checksum,
          filename: file.name,
          contentType: file.type,
          byteSize: file.size
        });
      });
    });
  };

  return fileUploadHandler;
};
