import type { PillType } from '@zen/DesignSystem';
import { AccountStatus } from '@zen/graphql/types.generated';

type PillConfig = { label: string; pillType: PillType };

const statusMapping: Record<AccountStatus, PillConfig> = {
  [AccountStatus.ACTIVE]: {
    label: 'Active',
    pillType: 'default'
  },
  [AccountStatus.PENDING]: {
    label: 'Pending',
    pillType: 'pending'
  },
  [AccountStatus.INACTIVE]: {
    label: 'Inactive',
    pillType: 'problem'
  }
};

export { statusMapping };
