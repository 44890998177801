import type { FC } from 'react';

const SplashScreen: FC = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-white">
      <img
        alt="Zencargo"
        className="w-60 animate-bounce"
        data-testid="zencargo-image"
        src="https://assets.zencargo.com/assets/zencargo-logo.svg"
      />
    </div>
  );
};

export default SplashScreen;
