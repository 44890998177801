import type { FC } from 'react';

import { QuoteOption, QuoteOptionState } from '@zen/Quotes';

import { ActivityFeedDataItem, ActivityFeedItemTypeEnum } from '../../types';
import QuoteOptionAutomaticallyRejected from './QuoteOptionAutomaticallyRejected';
import QuoteOptionItem from './QuoteOptionItem';
import QuoteOptionItemContainer from './QuoteOptionItem/QuoteOptionItemContainer';
import QuoteOptionRejectReasonItemContainer from './QuoteOptionRejectReasonItem/QuoteOptionRejectReasonItemContainer';

interface Props {
  item: ActivityFeedDataItem;
  itemType: ActivityFeedItemTypeEnum;
}

const QuoteOptionItems: FC<Props> = ({ itemType, item }) => {
  const { data, ...rest } = item;
  const quoteOption: QuoteOption = data as QuoteOption;

  switch (itemType) {
    case ActivityFeedItemTypeEnum.QUOTE_OPTION:
      return <QuoteOptionItemContainer activityMetadata={rest} quoteOption={quoteOption} />;
    case ActivityFeedItemTypeEnum.ACCEPTED_QUOTE_OPTION:
      return (
        <QuoteOptionItem
          activityDate={rest.createdAt}
          quoteOption={{
            ...quoteOption,
            state: QuoteOptionState.ACCEPTED
          }}
          user={rest.legacyUser}
        />
      );
    case ActivityFeedItemTypeEnum.AUTOMATICALLY_REJECTED_QUOTE_OPTION:
      return <QuoteOptionAutomaticallyRejected activityMetadata={rest} quoteOption={quoteOption} />;
    case ActivityFeedItemTypeEnum.REJECTED_QUOTE_OPTION:
      return (
        <>
          <QuoteOptionItem
            activityDate={rest.createdAt}
            quoteOption={{
              ...quoteOption,
              state: QuoteOptionState.REJECTED
            }}
            user={rest.legacyUser}
          />
          <QuoteOptionRejectReasonItemContainer quoteOption={quoteOption} />
        </>
      );
    default:
      return null;
  }
};

export default QuoteOptionItems;
