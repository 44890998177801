import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetPurchaseOrderReferencesQueryVariables = GraphQLTypes.Exact<{
  ids: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
}>;

export type GetPurchaseOrderReferencesQueryResult = { __typename?: 'Query' } & {
  purchaseOrders: { __typename?: 'PurchaseOrderConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<{ __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id' | 'orderReferenceNumber'>>
      >
    >;
  };
};

export const GetPurchaseOrderReferencesDocument = /* #__PURE__ */ gql`
  query getPurchaseOrderReferences($ids: [String!]!) {
    purchaseOrders(ids: $ids) {
      nodes {
        id
        orderReferenceNumber
      }
    }
  }
`;

/**
 * __useGetPurchaseOrderReferencesQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderReferencesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetPurchaseOrderReferencesQuery(
  baseOptions: Apollo.QueryHookOptions<GetPurchaseOrderReferencesQueryResult, GetPurchaseOrderReferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetPurchaseOrderReferencesQueryResult, GetPurchaseOrderReferencesQueryVariables>(
    GetPurchaseOrderReferencesDocument,
    options
  );
}
export function useGetPurchaseOrderReferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPurchaseOrderReferencesQueryResult, GetPurchaseOrderReferencesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetPurchaseOrderReferencesQueryResult, GetPurchaseOrderReferencesQueryVariables>(
    GetPurchaseOrderReferencesDocument,
    options
  );
}
export type GetPurchaseOrderReferencesQueryHookResult = ReturnType<typeof useGetPurchaseOrderReferencesQuery>;
export type GetPurchaseOrderReferencesLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderReferencesLazyQuery>;
