import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeactivateAccountMutationVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type DeactivateAccountMutationResult = { __typename?: 'Mutation' } & {
  deactivateAccount?: GraphQLTypes.Maybe<
    { __typename?: 'DeactivateAccountPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const DeactivateAccountDocument = /* #__PURE__ */ gql`
  mutation deactivateAccount($accountId: String!) {
    deactivateAccount(accountId: $accountId) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useDeactivateAccountMutation__
 *
 * To run a mutation, you first call `useDeactivateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateAccountMutation, { data, loading, error }] = useDeactivateAccountMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useDeactivateAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeactivateAccountMutationResult, DeactivateAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeactivateAccountMutationResult, DeactivateAccountMutationVariables>(
    DeactivateAccountDocument,
    options
  );
}
export type DeactivateAccountMutationHookResult = ReturnType<typeof useDeactivateAccountMutation>;
