import { FC, useState } from 'react';

import { Button, Dialog } from '@zen/DesignSystem';
import { useArchiveQuoteOptionMutation } from '@zen/Quotes/graphql';
import { QuoteOptionState } from '@zen/Quotes/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

interface Props {
  handleSuccess: (state: QuoteOptionState) => void;
  quoteOptionId: string;
}

const ArchiveQuoteOptionButton: FC<Props> = (props) => {
  const { quoteOptionId, handleSuccess } = props;
  const [archiveConfirmation, setArchiveConfirmation] = useState<boolean>(false);
  const [archiveQuote] = useArchiveQuoteOptionMutation();
  const { addError } = useNotification();

  const onSuccess = (): void => {
    handleSuccess(QuoteOptionState.ARCHIVED);
    setArchiveConfirmation(false);
  };

  const handleArchive = async () =>
    performMutation({
      mutationFn: () => archiveQuote({ variables: { input: { quoteOptionId } } }),
      onError: () => addError(),
      onSuccess
    });

  return (
    <>
      <Button iconLeft="zicon-archive" onClick={() => setArchiveConfirmation(true)} variant="secondary">
        Archive
      </Button>
      <Dialog
        isOpen={archiveConfirmation}
        message="Are you sure you want to archive this quote option and its linked accrual?"
        onClose={() => setArchiveConfirmation(false)}
        onConfirm={handleArchive}
      />
    </>
  );
};

export default ArchiveQuoteOptionButton;
