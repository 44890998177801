import type { FC, ReactNode, SyntheticEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { TextLink } from '@zen/DesignSystem';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';
import type { Undefinable } from '@zen/utils/typescript';

interface Props {
  children: ReactNode;
  isTextLink?: boolean;
  linkTo: string;
  preserveCurrentQueryString?: boolean;
  search?: string;
  state?: object;
}

const TableLink: FC<Props> = ({ children, isTextLink = true, linkTo, state, preserveCurrentQueryString = false, search }) => {
  const { navigate } = useNavigationHistory();
  const { search: searchParamsFromUrl } = useLocation();

  const queryString: Undefinable<string> = preserveCurrentQueryString ? searchParamsFromUrl : search;
  const url: string = queryString ? `${linkTo}${queryString}` : linkTo;

  const handleClick = (event: SyntheticEvent): void => {
    event.preventDefault();

    navigate({ pathname: linkTo, state, search: queryString });
  };

  if (!isTextLink) {
    return (
      <Link onClick={handleClick} to={url}>
        {children}
      </Link>
    );
  }

  return (
    <TextLink linkTo={url} onClick={handleClick}>
      {children}
    </TextLink>
  );
};

export default TableLink;
