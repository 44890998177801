import { array, number, object, ObjectSchema } from 'yup';

const unitPriceValidator = object({ value: number().nullable() }).test(
  'unit price value test',
  'Please enter a unit price.',
  (unitPriceValue) => {
    return unitPriceValue.value !== null;
  }
);

export const validationSchema: ObjectSchema<{}> = object().shape({
  chargeItems: array().of(
    object().shape({
      chargeType: object().nullable().required('Please select a charge type.'),
      unitPrice: unitPriceValidator
    })
  ),
  location: object().nullable().required('Please select a port.')
});
