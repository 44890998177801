import type { FC } from 'react';

import { FormLabel } from '@zen/Components';
import type { Option } from '@zen/DesignSystem';
import { Select } from '@zen/DesignSystem';
import type { TaskStatus } from '@zen/Tasks/types';
import type { Nullable } from '@zen/utils/typescript';

interface Props {
  currentStatus?: TaskStatus;
  onTaskStatusChange: (newStatus: Nullable<TaskStatus>) => void;
}

const TaskStatusSelect: FC<Props> = ({ onTaskStatusChange, currentStatus }) => {
  const taskStatusOptions: Option<TaskStatus>[] = [
    { value: 'active', label: 'Open tasks' },
    { value: 'completed', label: 'Completed tasks' }
  ];

  return (
    <>
      <FormLabel label="Status" />
      <Select
        key="status"
        isSearchable={false}
        name="taskStatus"
        onChange={onTaskStatusChange}
        options={taskStatusOptions}
        value={currentStatus}
      />
    </>
  );
};

export default TaskStatusSelect;
