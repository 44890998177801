import type { ReactNode } from 'react';

import { isAirBooking, isRailBooking, isRoadBooking } from '@zen/Booking/helpers';
import { type CoreCargo, type CoreCargoInitial, transitionCargo } from '@zen/Cargo';
import MilestoneName from '@zen/Journey/components/MilestoneName';
import { formatDate } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

import {
  CargoJourneysSummaryDateType,
  CargoJourneysSummaryMilestone,
  CargoJourneysSummaryMilestoneProgressStage,
  type CargoJourneysSummaryStop,
  JourneyShippingMilestoneNameEnum,
  JourneyStopActionEnum,
  JourneyStopLocationTypeEnum,
  ModeOfTransport
} from '../../types';
import type { CargoJourneysSummaryCargoDetailsType } from './types';

export const SUMMARY_MILESTONE_DELIVERED_LABEL = 'Delivered';
export const SUMMARY_MILESTONE_DELIVERING_LABEL = 'Delivering';
export const SUMMARY_MILESTONE_UNCONFIRMED_DATE = 'Unconfirmed';
export const SUMMARY_MILESTONE_NOT_BOOKED_YET_PLACEHOLDER = 'Not booked yet';

export const milestonesToDisplayCompletionDates: JourneyShippingMilestoneNameEnum[] = [
  JourneyShippingMilestoneNameEnum.AIRCRAFT_ARRIVED,
  JourneyShippingMilestoneNameEnum.CARGO_READY_DATE,
  JourneyShippingMilestoneNameEnum.CARGO_UNLOADED,
  JourneyShippingMilestoneNameEnum.TRAIN_ARRIVED,
  JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_STOP,
  JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE,
  JourneyShippingMilestoneNameEnum.VESSEL_ARRIVED,
  JourneyShippingMilestoneNameEnum.CARGO_AVAILABLE_FOR_PICKUP
];

export const transformCargos = (cargos: CargoJourneysSummaryCargoDetailsType[]): Optional<CoreCargo[]> => {
  return transitionCargo(null, cargos as CoreCargoInitial[])?.cargoList;
};

export const isLocationTypeTerminal = (locationStopType: Optional<JourneyStopLocationTypeEnum>): boolean => {
  return locationStopType === JourneyStopLocationTypeEnum.TERMINAL;
};

export const hasTerminalAsCollectionOrDelivery = (locationStops: CargoJourneysSummaryStop[]): boolean => {
  return locationStops?.some(({ locationType }: CargoJourneysSummaryStop) => isLocationTypeTerminal(locationType));
};

export const getCollectionDeliveryLabel = (
  locationType: 'collection' | 'delivery',
  locationStops: CargoJourneysSummaryStop[]
): string => {
  const getLabel = (): string => {
    const warehouseLabel: Optional<string> = locationStops[0]?.warehouse?.label?.long;
    const terminalLabel: Optional<string> = locationStops[0]?.terminal?.label;

    if (warehouseLabel) {
      return warehouseLabel;
    }

    if (terminalLabel) {
      return terminalLabel;
    }

    return '-';
  };

  const hasMultipleStops: boolean = locationStops.length > 1;

  return hasMultipleStops ? `Multiple ${locationType} addresses` : getLabel();
};

export const getFormattedDateOrPlaceholderLabel = (
  date: Optional<string>,
  dateType?: Optional<CargoJourneysSummaryDateType>,
  hasUnconfirmedDate: boolean = false,
  showNotBookedYet: boolean = false
): string => {
  const prefix: string = dateType === CargoJourneysSummaryDateType.ESTIMATE ? 'exp.' : '';

  const getDefaultLabel = (): string => {
    if (hasUnconfirmedDate) return SUMMARY_MILESTONE_UNCONFIRMED_DATE;
    if (showNotBookedYet) return SUMMARY_MILESTONE_NOT_BOOKED_YET_PLACEHOLDER;

    return '';
  };

  const formattedDate: string = date ? formatDate(date) : getDefaultLabel();

  return prefix ? `${prefix} ${formattedDate}` : formattedDate;
};

export const getScheduledLocationLabels = (
  modeOfTransport: Optional<ModeOfTransport>
): { destinationLabel: string; originLabel: string } => {
  if (isAirBooking(modeOfTransport)) {
    return { originLabel: 'Origin airport', destinationLabel: 'Destination airport' };
  }

  if (isRailBooking(modeOfTransport)) {
    return { originLabel: 'Origin rail station', destinationLabel: 'Destination rail station' };
  }

  return { originLabel: 'Port of loading', destinationLabel: 'Port of destination' };
};

export const getCargoDateDescriptionLabel = (
  milestoneName: Optional<JourneyShippingMilestoneNameEnum>,
  modeOfTransport: Optional<ModeOfTransport>,
  progressStage: CargoJourneysSummaryMilestoneProgressStage
): string => {
  const deliveringMilestones = isRoadBooking(modeOfTransport)
    ? [...milestonesToDisplayCompletionDates, JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE]
    : milestonesToDisplayCompletionDates;

  if (progressStage === CargoJourneysSummaryMilestoneProgressStage.DELIVERED) {
    return SUMMARY_MILESTONE_DELIVERED_LABEL;
  }

  if (milestoneName && deliveringMilestones.includes(milestoneName)) {
    return SUMMARY_MILESTONE_DELIVERING_LABEL;
  }

  return '';
};

export const getMilestonesByProgressStage = (
  milestones: CargoJourneysSummaryMilestone[],
  progressStage: CargoJourneysSummaryMilestoneProgressStage
): CargoJourneysSummaryMilestone[] => {
  return milestones.filter((milestone: CargoJourneysSummaryMilestone) => milestone.progressStage === progressStage);
};

export const shouldShowCargoDateDetails = (
  milestone: CargoJourneysSummaryMilestone,
  bookingModeOfTransport: Optional<ModeOfTransport>,
  progressStage?: CargoJourneysSummaryMilestoneProgressStage
): boolean => {
  if (bookingModeOfTransport === ModeOfTransport.TRUCK) return true;

  if (
    progressStage ===
    CargoJourneysSummaryMilestoneProgressStage.DEPARTED_FROM_FIRST_SCHEDULED_STOP_NOT_ARRIVED_AT_LAST_SCHEDULED_STOP
  ) {
    return false;
  }

  return milestonesToDisplayCompletionDates.some(
    (milestoneName: JourneyShippingMilestoneNameEnum) => milestoneName === milestone.name
  );
};

export const getCargoJourneysSummaryMilestoneName = (
  { name, lastCompletedName, stopAction, progressStage }: CargoJourneysSummaryMilestone,
  bookingModeOfTransport: Optional<ModeOfTransport>
): ReactNode => {
  const TRANSIT_PLACEHOLDER = 'Transit';
  const isMilestoneDelivered = progressStage === CargoJourneysSummaryMilestoneProgressStage.DELIVERED;

  if (
    isRoadBooking(bookingModeOfTransport) &&
    lastCompletedName === JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE &&
    name === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE
  ) {
    return TRANSIT_PLACEHOLDER;
  }

  if (
    lastCompletedName === JourneyShippingMilestoneNameEnum.CARGO_PICKED_UP &&
    name === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE
  ) {
    return TRANSIT_PLACEHOLDER;
  }

  if (
    progressStage ===
      CargoJourneysSummaryMilestoneProgressStage.DEPARTED_FROM_FIRST_SCHEDULED_STOP_NOT_ARRIVED_AT_LAST_SCHEDULED_STOP &&
    stopAction !== JourneyStopActionEnum.CARRIAGE_TRANSSHIPMENT
  ) {
    return TRANSIT_PLACEHOLDER;
  }

  if (
    lastCompletedName === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE &&
    name === JourneyShippingMilestoneNameEnum.GATE_IN
  ) {
    return TRANSIT_PLACEHOLDER;
  }

  if (
    isAirBooking(bookingModeOfTransport) &&
    lastCompletedName === JourneyShippingMilestoneNameEnum.VEHICLE_ARRIVED_AT_WAREHOUSE &&
    name === JourneyShippingMilestoneNameEnum.AIRCRAFT_DEPARTED
  ) {
    return TRANSIT_PLACEHOLDER;
  }

  return (
    <MilestoneName
      isMilestoneCompleted={isMilestoneDelivered}
      modeOfTransport={bookingModeOfTransport}
      name={name}
      stopAction={stopAction}
    />
  );
};
