import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CouriersQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type CouriersQueryResult = { __typename?: 'Query' } & {
  couriers?: GraphQLTypes.Maybe<
    { __typename?: 'CourierConnection' } & Pick<GraphQLTypes.CourierConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<Array<{ __typename?: 'Courier' } & Pick<GraphQLTypes.Courier, 'courierName' | 'id'>>>;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const CouriersDocument = /* #__PURE__ */ gql`
  query couriers($after: String, $before: String, $first: Int, $last: Int, $query: String) {
    couriers(after: $after, before: $before, first: $first, last: $last, query: $query) {
      nodes {
        courierName
        id
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useCouriersQuery__
 *
 * To run a query within a React component, call `useCouriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCouriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCouriersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useCouriersQuery(baseOptions?: Apollo.QueryHookOptions<CouriersQueryResult, CouriersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CouriersQueryResult, CouriersQueryVariables>(CouriersDocument, options);
}
export function useCouriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CouriersQueryResult, CouriersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CouriersQueryResult, CouriersQueryVariables>(CouriersDocument, options);
}
export type CouriersQueryHookResult = ReturnType<typeof useCouriersQuery>;
export type CouriersLazyQueryHookResult = ReturnType<typeof useCouriersLazyQuery>;
