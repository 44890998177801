import { sortBy, xor } from 'lodash';
import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Button, Checkbox, Popover } from '@zen/DesignSystem';

interface Props<T> {
  columns: TableColumn<T>[];
  defaultFilterValue?: string;
  hiddenColumns: string[];
  onChange: (hiddenColumns: string[]) => void;
  size?: 'default' | 'compact';
}

type MultiSelectOptions<T> = {
  isSelected: boolean;
  value: string;
} & TableColumn<T>;

const CustomiseTableButton = <T extends {}>({
  columns,
  defaultFilterValue = '',
  hiddenColumns,
  onChange,
  size = 'compact'
}: Props<T>) => {
  const generalFilteredColumn: string = 'actions';
  const columnsToSkipListing: string = defaultFilterValue || generalFilteredColumn;

  const filteredColumns: TableColumn<T>[] = columns.filter((item) => item.key !== columnsToSkipListing);
  const sortedColumns: TableColumn<T>[] = sortBy(filteredColumns, ['title']);

  const options: MultiSelectOptions<T>[] = sortedColumns.map(
    (col: TableColumn<T>): MultiSelectOptions<T> => ({
      ...col,
      value: col.key,
      isSelected: !hiddenColumns.includes(col.key)
    })
  );

  const handleChange = (key: string): void => {
    const hiddenCols = xor(hiddenColumns, [key]);

    onChange(hiddenCols);
  };

  const handleSelectAll = (): void => (!hiddenColumns.length ? onChange(filteredColumns.map((col) => col.key)) : onChange([]));

  const popover: ReactNode = (
    <div data-testid="menu">
      <Checkbox
        checked={!hiddenColumns.length}
        className="py-2.5 pr-2 pl-4 w-full mt-2 mb-2 hover:bg-grey-lightest text-sm cursor-pointer"
        label="Select all"
        onChange={handleSelectAll}
      />
      <div className="mb-2 border-b border-solid border-grey-lighter" />
      {options.map(
        ({ isSelected, title, value }, i: number): ReactNode => (
          <Checkbox
            key={i}
            checked={isSelected}
            className="py-2.5 pr-2 pl-4 w-full mt-0 mb-0 hover:bg-grey-lightest text-sm cursor-pointer"
            label={title}
            onChange={() => handleChange(value)}
          />
        )
      )}
    </div>
  );

  return (
    <div className="relative fs-customiseTableButton" data-testid="customise-btn">
      <Popover
        placement="bottom-end"
        popover={popover}
        popoverClassNames="w-64 pb-2 overflow-y-auto max-h-56 border border-grey-lighter border-solid"
        renderInPortal={true}
      >
        <Button iconLeft="zicon-settings" size={size} variant="ghost">
          Customise
        </Button>
      </Popover>
    </div>
  );
};

export type { Props as CustomiseTableButtonProps };
export default CustomiseTableButton;
