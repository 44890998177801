import type { ActivePortCharge } from '@zen/RateCards/LegacyRateCards/types';

import type { PortChargePayload } from '../reducer/types';

export const preparePortChargePayloads = (activePortCharges: ActivePortCharge[]): PortChargePayload[] => {
  return activePortCharges.map((activePortCharge: ActivePortCharge) => {
    const { id, incoterms, port, chargeType, chargeValue, currency } = activePortCharge;

    return { centralPortChargeId: id, incoterms, port, chargeType, chargeValue, currency };
  });
};
