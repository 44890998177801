import { defaultLooseCargoFormInitialValues } from '../../LooseCargo/LooseCargoForm/helpers';
import type { FullContainerFormInitialValues } from './types';

export const defaultFullContainerFormInitialValues: FullContainerFormInitialValues = {
  containerVariant: null,
  hazardous: false,
  containerNumber: null,
  containerSealNumber: null,
  overweight: false,
  quantity: 1,
  type: null,
  garmentsOnHangers: false,
  ...defaultLooseCargoFormInitialValues
};
