import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components';
import { TimeOption } from '@zen/Components/DateWithTimeForm';
import FormContactInput from '@zen/Components/Form/FormContactInput';
import FormDateWithTimePickerInput from '@zen/Components/Form/FormDateWithTimePickerInput/FormDateWithTimePickerInput';
import { Banner, Button, Icon, TooltipConfig } from '@zen/DesignSystem';
import { ContactTypeEnum } from '@zen/graphql/types.generated';
import { MilestoneDateType } from '@zen/Journey/types';
import type { BusinessHours } from '@zen/Networks';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable, Optional } from '@zen/utils/typescript';

import DisabledDateTooltip from '../../DisabledDateTooltip';
import { useJourneyContext } from '../../JourneyDetails/JourneyDetailsContext';
import VerticalPathProvider from '../../VerticalPathProvider/VerticalPathProvider';
import { createValidationSchema } from './confirmRoadLegForm.validation';
import { prepareInitialValues } from './helpers';
import type { RoadLegValues } from './types';

const timeConfig: TimeOption[] = [TimeOption.TIME_RANGE];

interface Props {
  cargoReadyDate: Optional<string>;
  collectionBusinessHours?: Optional<BusinessHours>;
  collectionTimeZone?: Nullable<string>;
  deliveryBusinessHours?: Optional<BusinessHours>;
  deliveryTimeZone?: Nullable<string>;
  isDeliveryRequired: boolean;
  onCancel: () => void;
  onSubmit: (values: RoadLegValues) => Promise<IOkOrErrorResult>;
}

const ConfirmRoadLegForm: FC<Props> = (props) => {
  const { cargoCount } = useJourneyContext();
  const {
    cargoReadyDate,
    collectionBusinessHours,
    collectionTimeZone,
    deliveryBusinessHours,
    deliveryTimeZone,
    isDeliveryRequired,
    onCancel,
    onSubmit
  } = props;

  const isBannerVisible: boolean = cargoCount > 1 && !isDeliveryRequired;

  const collectionDateTooltip: TooltipConfig = {
    matcher: { minDate: cargoReadyDate },
    message: (
      <DisabledDateTooltip dateConstraint="min" dateType={MilestoneDateType.ACTUAL} milestoneName="Cargo ready for collection" />
    )
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<{}>): ReactNode => (
    <FormButtons containerClassName="-z-[1]" isSubmitting={isSubmitting} layout="fixed" text="Confirm">
      <Button onClick={onCancel} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const renderIcon = () => {
    return <Icon className="relative z-10 py-1 mt-0.5 bg-white text-grey-light" data-routedatapoint={true} icon="zicon-pin" />;
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={prepareInitialValues()}
      onSubmit={onSubmit}
      validationSchema={createValidationSchema(isDeliveryRequired)}
    >
      {({ values }: FormInstance<RoadLegValues>) => {
        const { collection } = values;

        const deliveryMinDate: Optional<string> = collection?.date ? collection.date : cargoReadyDate;
        const deliveryDateTooltip: TooltipConfig = {
          matcher: { minDate: deliveryMinDate },
          message: (
            <DisabledDateTooltip
              dateConstraint="min"
              dateType={collection.date ? MilestoneDateType.PLANNED : MilestoneDateType.ACTUAL}
              milestoneName={collection.date ? 'Arriving for collection' : 'Cargo ready for collection'}
            />
          )
        };

        return (
          <VerticalPathProvider>
            <div data-testid="confirm-road-leg-form">
              <FormContactInput contactTypes={[ContactTypeEnum.HAULIER]} name="haulier" />
              <div className="px-4 pt-6 pb-2 border border-solid border-grey-lighter">
                <div className="flex items-start space-x-4">
                  {renderIcon()}
                  <FormDateWithTimePickerInput
                    businessHours={collectionBusinessHours}
                    className="flex-1"
                    dateTooltip={collectionDateTooltip}
                    disabledDates={{ minDate: cargoReadyDate }}
                    label="Arriving for collection"
                    name="collection"
                    timeConfig={timeConfig}
                    timeZone={collectionTimeZone}
                  />
                </div>
                <div className="flex items-start space-x-4">
                  {renderIcon()}
                  <FormDateWithTimePickerInput
                    businessHours={deliveryBusinessHours}
                    className="flex-1"
                    dateTooltip={deliveryDateTooltip}
                    disabledDates={{ minDate: deliveryMinDate }}
                    isOptional={!isDeliveryRequired}
                    label="Delivering"
                    name="delivery"
                    timeConfig={timeConfig}
                    timeZone={deliveryTimeZone}
                  />
                </div>
              </div>
              {isBannerVisible && (
                <div className="mt-4">
                  <Banner
                    message="This will only confirm this cargo in the booking. Remember to confirm the other cargo."
                    type="warning"
                  />
                </div>
              )}
            </div>
          </VerticalPathProvider>
        );
      }}
    </Form>
  );
};

export default ConfirmRoadLegForm;
