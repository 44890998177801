import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetEnterpriseConnectionsQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetEnterpriseConnectionsQueryResult = { __typename?: 'Query' } & Pick<
  GraphQLTypes.Query,
  'enterpriseConnectionNames'
>;

export const GetEnterpriseConnectionsDocument = /* #__PURE__ */ gql`
  query getEnterpriseConnections {
    enterpriseConnectionNames
  }
`;

/**
 * __useGetEnterpriseConnectionsQuery__
 *
 * To run a query within a React component, call `useGetEnterpriseConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEnterpriseConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEnterpriseConnectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEnterpriseConnectionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEnterpriseConnectionsQueryResult, GetEnterpriseConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetEnterpriseConnectionsQueryResult, GetEnterpriseConnectionsQueryVariables>(
    GetEnterpriseConnectionsDocument,
    options
  );
}
export function useGetEnterpriseConnectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEnterpriseConnectionsQueryResult, GetEnterpriseConnectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetEnterpriseConnectionsQueryResult, GetEnterpriseConnectionsQueryVariables>(
    GetEnterpriseConnectionsDocument,
    options
  );
}
export type GetEnterpriseConnectionsQueryHookResult = ReturnType<typeof useGetEnterpriseConnectionsQuery>;
export type GetEnterpriseConnectionsLazyQueryHookResult = ReturnType<typeof useGetEnterpriseConnectionsLazyQuery>;
