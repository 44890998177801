import { CSSProperties, useEffect, useRef, useState } from 'react';

import useBreakpoint from '@zen/utils/hooks/useBreakpoint';
import useScrollGestures, { ScrollGesture } from '@zen/utils/hooks/useScrollGestures';
import type { Nullable } from '@zen/utils/typescript';

const getFontSize = (): number =>
  parseInt(window.getComputedStyle(document.querySelector('html') as HTMLHtmlElement).fontSize, 10);

const shouldUpdateHeaderStyle = (topOffsetInRem: number, referenceElement: Nullable<HTMLElement>): boolean => {
  if (!referenceElement) {
    return false;
  }

  const referenceElementTopOffset: number = referenceElement.getBoundingClientRect().top;
  const targetTopOffsetInRem: number = referenceElementTopOffset / getFontSize();

  return targetTopOffsetInRem - topOffsetInRem < 0;
};

const useTableHeaderStyle = ({
  referenceElement,
  topOffsetInRem
}: {
  referenceElement: Nullable<HTMLElement>;
  topOffsetInRem: number;
}): CSSProperties => {
  const [tableHeaderStyle, setTableHeaderStyle] = useState<CSSProperties>({});
  const { currentBreakpoint } = useBreakpoint();
  const translateOffsetInRemRef = useRef(0);

  const referenceElementTopOffset: number | undefined = referenceElement
    ? referenceElement.getBoundingClientRect().top
    : undefined;

  const { gesture } = useScrollGestures(() => shouldUpdateHeaderStyle(topOffsetInRem, referenceElement));

  useEffect(() => {
    if (topOffsetInRem === undefined || referenceElementTopOffset === undefined) {
      return;
    }

    const shouldUpdate: boolean = shouldUpdateHeaderStyle(topOffsetInRem, referenceElement);

    if (translateOffsetInRemRef.current !== 0 && !shouldUpdate) {
      translateOffsetInRemRef.current = 0;
      setTableHeaderStyle({});
    }

    if (shouldUpdate) {
      const fontSize: number = getFontSize();
      const headerTopOffsetInRem: number = referenceElementTopOffset / fontSize;
      const translateOffset: number = -1 * headerTopOffsetInRem + topOffsetInRem;

      const transform: string = `translate(0, ${translateOffset}rem)`;

      translateOffsetInRemRef.current = translateOffset;

      setTableHeaderStyle({ transform });
    }
  }, [currentBreakpoint, topOffsetInRem, referenceElement, referenceElementTopOffset]);

  useEffect(() => {
    if (topOffsetInRem === undefined || referenceElementTopOffset === undefined) {
      return;
    }

    if (gesture === null) {
      setTableHeaderStyle({ opacity: '1' });
    }

    if (gesture === ScrollGesture.STARTED) {
      setTableHeaderStyle({ opacity: '0' });
    }

    if (gesture === ScrollGesture.STOPPED) {
      const headerTopOffsetInRem: number = referenceElementTopOffset / getFontSize();
      const translateOffset: number = -1 * headerTopOffsetInRem + topOffsetInRem;

      const opacity: string = '1';
      const transition: string = 'opacity 0.2s 0.5s ease-in';
      const transform: string = `translate(0, ${translateOffset}rem)`;

      setTableHeaderStyle({ transform, transition, opacity });
    }
  }, [gesture, referenceElementTopOffset, topOffsetInRem]);

  return tableHeaderStyle;
};

export default useTableHeaderStyle;
