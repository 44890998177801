import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type NoteQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
  type: GraphQLTypes.Scalars['String'];
}>;

export type NoteQueryResult = { __typename?: 'Query' } & {
  note?: GraphQLTypes.Maybe<{ __typename?: 'Note' } & Pick<GraphQLTypes.Note, 'id' | 'body'>>;
};

export const NoteDocument = /* #__PURE__ */ gql`
  query note($id: String!, $type: String!) {
    note(id: $id, type: $type) {
      id
      body
    }
  }
`;

/**
 * __useNoteQuery__
 *
 * To run a query within a React component, call `useNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useNoteQuery(baseOptions: Apollo.QueryHookOptions<NoteQueryResult, NoteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<NoteQueryResult, NoteQueryVariables>(NoteDocument, options);
}
export function useNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NoteQueryResult, NoteQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<NoteQueryResult, NoteQueryVariables>(NoteDocument, options);
}
export type NoteQueryHookResult = ReturnType<typeof useNoteQuery>;
export type NoteLazyQueryHookResult = ReturnType<typeof useNoteLazyQuery>;
