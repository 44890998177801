import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { BusinessHoursFragmentDoc } from '../../../graphql/fragments/BusinessHours.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountDetailsQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetAccountDetailsQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id' | 'tradingName' | 'referencePrefix'> & {
        legalInformation: { __typename?: 'AccountLegalInformation' } & Pick<
          GraphQLTypes.AccountLegalInformation,
          'registeredName' | 'companyRegistrationNumber' | 'eoriNumber' | 'vatIdentificationNumber'
        > & {
            registeredAddress: { __typename?: 'AccountLocation' } & {
              address: { __typename?: 'Address' } & Pick<
                GraphQLTypes.Address,
                'city' | 'countyOrState' | 'line1' | 'line2' | 'postalCodeOrZip'
              > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>> };
            };
          };
        businessUnits: Array<
          { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'referencePrefix' | 'tradingName' | 'type' | 'id'>
        >;
        ssoDetails: { __typename?: 'SsoDetails' } & Pick<
          GraphQLTypes.SsoDetails,
          'connectionName' | 'domains' | 'enabled' | 'provisionMethod' | 'uuid'
        > & { defaultLocation: GraphQLTypes.SsoDetails['networkLocationId'] } & {
            apiKeys: Array<{ __typename?: 'ScimApiKey' } & Pick<GraphQLTypes.ScimApiKey, 'expiryDate' | 'id' | 'status'>>;
          };
      }
  >;
  accountLocations?: GraphQLTypes.Maybe<
    { __typename?: 'AccountLocationConnection' } & {
      nodes: Array<
        GraphQLTypes.Maybe<
          { __typename?: 'AccountLocation' } & Pick<
            GraphQLTypes.AccountLocation,
            'id' | 'addressLabel' | 'companyName' | 'name' | 'usageContext' | 'isRegisteredLegalAddress'
          > & {
              geolocation?: GraphQLTypes.Maybe<
                { __typename?: 'Geolocation' } & Pick<GraphQLTypes.Geolocation, 'latitude' | 'longitude'>
              >;
              address: { __typename?: 'Address' } & Pick<
                GraphQLTypes.Address,
                'line1' | 'line2' | 'city' | 'countyOrState' | 'postalCodeOrZip'
              > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'code' | 'name'>> };
              businessHours?: GraphQLTypes.Maybe<
                { __typename?: 'BusinessHours' } & {
                  monday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  tuesday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  wednesday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  thursday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  friday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  saturday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                  sunday?: GraphQLTypes.Maybe<
                    { __typename?: 'TimeRange' } & Pick<GraphQLTypes.TimeRange, 'startTime' | 'endTime'>
                  >;
                }
              >;
            }
        >
      >;
    }
  >;
};

export const GetAccountDetailsDocument = /* #__PURE__ */ gql`
  query getAccountDetails($accountId: String!) {
    account(accountId: $accountId) {
      id
      tradingName
      referencePrefix
      legalInformation {
        registeredName
        companyRegistrationNumber
        eoriNumber
        vatIdentificationNumber
        registeredAddress {
          address {
            city
            country {
              name
              code
            }
            countyOrState
            line1
            line2
            postalCodeOrZip
          }
        }
      }
      businessUnits {
        referencePrefix
        tradingName
        type
        id
      }
      ssoDetails {
        apiKeys {
          expiryDate
          id
          status
        }
        connectionName
        defaultLocation: networkLocationId
        domains
        enabled
        provisionMethod
        uuid
      }
    }
    accountLocations(accountId: $accountId) {
      nodes {
        id
        addressLabel
        companyName
        name
        usageContext
        geolocation {
          latitude
          longitude
        }
        address {
          line1
          line2
          city
          countyOrState
          postalCodeOrZip
          country {
            code
            name
          }
        }
        businessHours {
          ...BusinessHours
        }
        isRegisteredLegalAddress
      }
    }
  }
  ${BusinessHoursFragmentDoc}
`;

/**
 * __useGetAccountDetailsQuery__
 *
 * To run a query within a React component, call `useGetAccountDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountDetailsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetAccountDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export function useGetAccountDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountDetailsQueryResult, GetAccountDetailsQueryVariables>(GetAccountDetailsDocument, options);
}
export type GetAccountDetailsQueryHookResult = ReturnType<typeof useGetAccountDetailsQuery>;
export type GetAccountDetailsLazyQueryHookResult = ReturnType<typeof useGetAccountDetailsLazyQuery>;
