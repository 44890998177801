import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ActivateQuoteOptionMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ActivateQuoteOptionInput;
}>;

export type ActivateQuoteOptionMutationResult = { __typename?: 'Mutation' } & {
  bookingActivateQuoteOption?: GraphQLTypes.Maybe<
    { __typename?: 'ActivateQuoteOptionPayload' } & {
      quoteOption?: GraphQLTypes.Maybe<
        { __typename?: 'QuoteOption' } & Pick<
          GraphQLTypes.QuoteOption,
          'id' | 'zencargoReference' | 'quoteReference' | 'accrualId' | 'description' | 'state'
        > & {
            total?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
            expiryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
          }
      >;
    }
  >;
};

export const ActivateQuoteOptionDocument = /* #__PURE__ */ gql`
  mutation activateQuoteOption($input: ActivateQuoteOptionInput!) {
    bookingActivateQuoteOption(input: $input) {
      quoteOption {
        id
        zencargoReference
        quoteReference
        accrualId
        description
        state
        total {
          value
          currency
        }
        expiryDate {
          date
        }
      }
    }
  }
`;

/**
 * __useActivateQuoteOptionMutation__
 *
 * To run a mutation, you first call `useActivateQuoteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateQuoteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateQuoteOptionMutation, { data, loading, error }] = useActivateQuoteOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateQuoteOptionMutation(
  baseOptions?: Apollo.MutationHookOptions<ActivateQuoteOptionMutationResult, ActivateQuoteOptionMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ActivateQuoteOptionMutationResult, ActivateQuoteOptionMutationVariables>(
    ActivateQuoteOptionDocument,
    options
  );
}
export type ActivateQuoteOptionMutationHookResult = ReturnType<typeof useActivateQuoteOptionMutation>;
