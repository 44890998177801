import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  iconName?: IconName;
  label: string;
  onClick: () => void;
}

const QuickFiltersActions: FC<Props> = ({ onClick, label, iconName }) => {
  return (
    <div className="mr-6 text-base font-bold cursor-pointer text-navy-base" data-testid="quick-filters-action" onClick={onClick}>
      {iconName && <Icon className="mr-2 hover:text-navy-light" icon={iconName} />}
      <span className="hover:border-b hover:border-solid hover:border-bottom-navy-base">{label}</span>
    </div>
  );
};

export default QuickFiltersActions;
