export const rateCardColumnHeaders = [
  'chargeType',
  'chargeBasis',
  'characteristics',
  'originLocation',
  'originPort',
  'destinationPort',
  'destinationLocation',
  'incoterms',
  'unit',
  'cost',
  'startDate',
  'endDate',
  'modeOfTransport',
  'cargoType',
  'issuedBy',
  'issuedAt'
] as const;

export const mapDataToRow = (data: Record<string, string>): string[] =>
  rateCardColumnHeaders.map((key: string) => data[key] || '');
