import type { FC } from 'react';

import type { Option } from '@zen/DesignSystem';
import RateCardMultiSelect, { RateCardMultiSelectProps } from '@zen/RateCards/components/RateCardMultiSelect';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { Nullable } from '@zen/utils/typescript';

import { useGetCargoOptionsQuery } from './graphql';
import { getOptions } from './helpers';
import type { CargoOption, CargoOptionEnum } from './types';

interface Props extends Omit<RateCardMultiSelectProps<CargoOptionEnum>, 'children' | 'onChange' | 'options'> {
  onChange: (values: Nullable<CargoOptionEnum[]>) => void;
}

const CargoOptionsSelect: FC<Props> = (props) => {
  const { onChange, value, variant } = props;
  const { cargoType, modeOfTransport } = useRateCardContext();

  const { data, loading } = useGetCargoOptionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cargoType,
      modeOfTransport
    }
  });

  const cargoOptionsList = (data?.getCargoOptions || []) as CargoOption[];
  const optionsList: Option<CargoOptionEnum>[] = getOptions(cargoOptionsList);

  return (
    <RateCardMultiSelect loading={loading} onChange={onChange} options={optionsList} value={value || null} variant={variant} />
  );
};

export default CargoOptionsSelect;
