import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateVehiclePlateNumberMutation } from './graphql';

interface Props {
  canUpdateVehiclePlateNumber: boolean;
  vehicleId: string;
  vehiclePlateNumber: string;
}

const VehiclePlateNumberInlineField: FC<Props> = ({ canUpdateVehiclePlateNumber, vehicleId, vehiclePlateNumber }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateVehiclePlateNumber] = useUpdateVehiclePlateNumberMutation();

  const label: string = 'Plate number';

  const handleVehiclePlateNumber = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateVehiclePlateNumber({
          variables: {
            input: {
              vehicleId,
              plateNumber: value,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Plate number updated.');
      }
    });
  };

  if (!canUpdateVehiclePlateNumber) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={vehiclePlateNumber} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="vehicle-plate-number"
      onUpdate={handleVehiclePlateNumber}
      placeholder="Enter plate number"
      value={vehiclePlateNumber}
    />
  );
};

export default VehiclePlateNumberInlineField;
