import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CreateCourierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateCourierInput;
}>;

export type CreateCourierMutationResult = { __typename?: 'Mutation' } & {
  createCourier: { __typename?: 'CreateCourierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    courier?: GraphQLTypes.Maybe<{ __typename?: 'Courier' } & Pick<GraphQLTypes.Courier, 'courierName' | 'id'>>;
  };
};

export const CreateCourierDocument = /* #__PURE__ */ gql`
  mutation createCourier($input: CreateCourierInput!) {
    createCourier(input: $input) {
      errors {
        message
        path
      }
      courier {
        courierName
        id
      }
    }
  }
`;

/**
 * __useCreateCourierMutation__
 *
 * To run a mutation, you first call `useCreateCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourierMutation, { data, loading, error }] = useCreateCourierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCourierMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCourierMutationResult, CreateCourierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<CreateCourierMutationResult, CreateCourierMutationVariables>(CreateCourierDocument, options);
}
export type CreateCourierMutationHookResult = ReturnType<typeof useCreateCourierMutation>;
