import type { FC, ReactChild, ReactNode } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { Document } from '@zen/Components/Documents/types';
import { Form, FormButtons, FormInput, FormSelect } from '@zen/Components/Form';
import FormRolePermissionToggles from '@zen/Components/Form/FormRolePermissionToggles';
import type { Option } from '@zen/DesignSystem';
import { Button, Card } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import { validationSchema } from './editDocumentForm.validation';

interface Props {
  documentTypes: Option<string>[];
  initialValues: Document;
  onCancel: () => void;
  onSubmit: (values: Document) => void;
  onSuccess?: () => void;
}

const EditDocumentForm: FC<Props> = ({ documentTypes, initialValues, onSubmit, onCancel, onSuccess }) => {
  const { check } = useGlobalPermissions();
  const canEditPermissions = check('bookings.canViewDocumentPermissions');

  const permissionsLabel = (
    <span>
      Access rights - <span className="font-normal text-xs">Select who can see and manage this document</span>
    </span>
  );

  const handleSubmit = (formValues: Document): Promise<IOkOrErrorResult> => {
    onSubmit(formValues);

    return Promise.resolve({
      ok: true,
      error: null
    });
  };

  const renderFormButtons = ({ isSubmitting }: { isSubmitting: boolean }): ReactChild => (
    <FormButtons containerClassName="mt-4" isSubmitting={isSubmitting}>
      <Button data-testid="cancel-btn" onClick={() => onCancel()} variant="ghost">
        Cancel
      </Button>
    </FormButtons>
  );

  const renderDocumentTypeInput = (): ReactNode => {
    if (initialValues.actionItemDocument) {
      return <FormInput className="flex-1 mr-4" disabled={true} label="Document type" name="documentType" />;
    }

    return <FormSelect className="flex-1" label="Document type" name="documentType" options={documentTypes} />;
  };

  return (
    <Card className="mt-4" data-testid="edit-document-form">
      <Form
        buttonText="Update document"
        formButtons={renderFormButtons}
        formName="EditDocumentForm"
        initialValues={initialValues}
        onSubmit={handleSubmit}
        onSuccess={onSuccess}
        validationSchema={validationSchema}
      >
        {() => (
          <>
            <div className="flex justify-between">
              <FormInput className="flex-1 mr-4" label="Document name" name="description" />
              {renderDocumentTypeInput()}
            </div>
            {canEditPermissions && <FormRolePermissionToggles label={permissionsLabel} name="permissions" />}
          </>
        )}
      </Form>
    </Card>
  );
};

export default EditDocumentForm;
