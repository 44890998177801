import { isEqual } from 'lodash';

import { daysOfWeek, getDefaultWorkDays, getDefaultWorkingHours } from '@zen/Accounts/AccountForms/helpers';
import type { WorkingHours } from '@zen/Accounts/AccountForms/types';
import { formatTime } from '@zen/utils/dateTime';

import { type AccountLocation, type FormattedHours, UsageContext } from './types';

export const formatBusinessHours = (hours?: AccountLocation['businessHours']): FormattedHours => {
  if (!hours) return getDefaultWorkDays();

  return daysOfWeek.reduce<FormattedHours>((accumulatedDays, day) => {
    if (hours[day]) {
      const defaultHours: WorkingHours = getDefaultWorkingHours();
      const { startTime, endTime } = hours[day] || defaultHours;

      const adjustedTimes: WorkingHours = {
        startTime: formatTime(startTime, false) || defaultHours.startTime,
        endTime: formatTime(endTime, false) || defaultHours.endTime
      };

      accumulatedDays[day] = adjustedTimes;
    } else {
      accumulatedDays[day] = null;
    }

    return accumulatedDays;
  }, {} as FormattedHours);
};

export const hasStandardOpeningHours = (businessHours: FormattedHours): boolean => {
  return isEqual(businessHours, getDefaultWorkDays());
};

export const locationUsageLabelMapping: Record<UsageContext, string> = {
  [UsageContext.BOTH]: 'Send & receive shipments',
  [UsageContext.SHIP_FROM]: 'Receive shipments',
  [UsageContext.SHIP_TO]: 'Send shipments'
};
