import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAirlineCarrierMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAirlineCarrierInput;
}>;

export type UpdateAirlineCarrierMutationResult = { __typename?: 'Mutation' } & {
  updateAirlineCarrier: { __typename?: 'UpdateAirlineCarrierPayload' } & {
    errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    airlineCarrier?: GraphQLTypes.Maybe<
      { __typename?: 'AirlineCarrier' } & Pick<GraphQLTypes.AirlineCarrier, 'airlineCarrierName' | 'id' | 'carrierIata'>
    >;
  };
};

export const UpdateAirlineCarrierDocument = /* #__PURE__ */ gql`
  mutation updateAirlineCarrier($input: UpdateAirlineCarrierInput!) {
    updateAirlineCarrier(input: $input) {
      errors {
        message
        path
      }
      airlineCarrier {
        airlineCarrierName
        id
        carrierIata
      }
    }
  }
`;

/**
 * __useUpdateAirlineCarrierMutation__
 *
 * To run a mutation, you first call `useUpdateAirlineCarrierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAirlineCarrierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAirlineCarrierMutation, { data, loading, error }] = useUpdateAirlineCarrierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAirlineCarrierMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAirlineCarrierMutationResult, UpdateAirlineCarrierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAirlineCarrierMutationResult, UpdateAirlineCarrierMutationVariables>(
    UpdateAirlineCarrierDocument,
    options
  );
}
export type UpdateAirlineCarrierMutationHookResult = ReturnType<typeof useUpdateAirlineCarrierMutation>;
