import cx from 'classnames';
import { kebabCase } from 'lodash';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import HelperText from '@zen/Components/HelperText';
import OverLabel from '@zen/Components/OverLabel/OverLabel';
import { formatDate } from '@zen/utils/dateTime';
import type { Nullable, Optional } from '@zen/utils/typescript';

import DatePicker from '../DatePicker';
import type { Matcher, TooltipConfig } from '../DatePicker/types';
import Icon from '../Icon';
import Popover from '../Popover';
import TextInput from '../TextInput';

interface Props {
  autoFocus?: boolean;
  disabled?: boolean;
  disabledDates?: Matcher;
  hasError?: boolean;
  helperText?: string;
  inline?: boolean;
  isClearable?: boolean;
  name?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onDayChange: (date: Nullable<string>) => void;
  overLabel?: string;
  placeholder?: string;
  renderInPortal?: boolean;
  selectedDate?: Optional<string>;
  tooltip?: TooltipConfig | TooltipConfig[];
}

const DatePickerInput: FC<Props> = (props) => {
  const {
    autoFocus,
    disabled,
    disabledDates,
    hasError,
    helperText,
    inline = false,
    isClearable = false,
    name,
    onBlur,
    onDayChange,
    overLabel,
    placeholder = 'Jul 5, 2016',
    renderInPortal = false,
    selectedDate,
    tooltip
  } = props;
  const showClearIcon: boolean = isClearable && !!selectedDate;
  const formattedDate: string = selectedDate ? formatDate(selectedDate) : '';
  const classNames: string = cx('px-9 min-w-40', { 'cursor-pointer': !disabled });

  const handleClearDate = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onDayChange(null);
  };

  const renderDatePickerField = ({ close }: { close: () => void }): ReactNode => {
    const handleDateChange = (date: string): void => {
      onDayChange(date);
      close();
    };

    return (
      <DatePicker
        disabled={disabledDates}
        mode="single"
        onChange={handleDateChange}
        selected={selectedDate || ''}
        tooltip={tooltip}
      />
    );
  };

  return (
    <div className="relative flex-1" data-testid={`${kebabCase(name)}-datepicker-input`}>
      {overLabel && <OverLabel htmlFor={name} label={overLabel} />}
      <Popover disabled={disabled} popover={renderDatePickerField} renderInPortal={renderInPortal}>
        <TextInput
          autoFocus={autoFocus}
          className={classNames}
          data-component="date-picker-input"
          disabled={disabled}
          error={hasError}
          iconLeft={<Icon className="cursor-pointer text-grey-light" icon="zicon-calendar" />}
          iconRight={
            showClearIcon && (
              <Icon
                data-testid={`${kebabCase(name)}-datepicker-clear-icon`}
                icon="zicon-close"
                interactive={true}
                onClick={handleClearDate}
              />
            )
          }
          id={name}
          inline={inline}
          name={name}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={true}
          value={formattedDate}
        />
      </Popover>
      <HelperText helperText={helperText} />
    </div>
  );
};

export type { Props as DatePickerInputProps };
export default DatePickerInput;
