import { object, ObjectSchema, string } from 'yup';

const invalidAirlineLabel: string = 'IATA must be two characters.';

export const validationSchema: ObjectSchema<{}> = object().shape({
  airlineCarrierName: string().required('Please enter carrier name.'),
  carrierIata: string()
    .nullable()
    .min(2, invalidAirlineLabel)
    .max(2, invalidAirlineLabel)
    .matches(/^[a-zA-Z0-9 ]+$/, invalidAirlineLabel)
    .required('Please enter IATA.')
});
