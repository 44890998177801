import type { FC } from 'react';

import { Body, Button, Headline, Icon } from '@zen/DesignSystem';

interface Props {
  onAction: () => void;
}

const PreDownloadContent: FC<Props> = ({ onAction }) => (
  <>
    <Icon className="m-10 text-5xl" icon="zicon-download" />
    <Headline className="m-4 text-grey-base" level={2}>
      Start your download
    </Headline>
    <Body className="text-grey-base">Once it's done, the file will be on your computer (often in the downloads folder).</Body>
    <Button className="m-4" onClick={onAction}>
      Download file
    </Button>
  </>
);

export default PreDownloadContent;
