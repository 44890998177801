import type { Option } from '@zen/DesignSystem';
import type { Undefinable } from '@zen/utils/typescript';

import type { ChargeGroup, ChargeGroupItemType, ChargeType } from './types';

export const prepareOptions = (chargeTypes: ChargeType[]): Option<ChargeType>[] => {
  return chargeTypes?.map((chargeType: ChargeType) => {
    return {
      label: chargeType.name,
      value: chargeType
    };
  });
};

export const getChargeType = (chargeTypes: ChargeType[], id: string): Undefinable<ChargeType> => {
  return chargeTypes.find((type: ChargeType) => type.id === id);
};

export const getChargeGroupIds = (searchableChargeGroups: ChargeGroupItemType[], chargeGroups: ChargeGroup[]): string[] => {
  const groupsIds: string[] = [];

  searchableChargeGroups.forEach((searchableGroup: ChargeGroupItemType) =>
    chargeGroups.find((chargeGroup: ChargeGroup) => {
      if (chargeGroup.name === searchableGroup) {
        groupsIds.push(chargeGroup.id);
      }
    })
  );

  return groupsIds;
};
