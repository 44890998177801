import type { FC } from 'react';

import NavigationPrompt from '@zen/Components/NavigationPrompt';

interface Props {
  allowedPaths: string[];
  onLeave: () => void;
}

const BookingNavigationPrompt: FC<Props> = ({ allowedPaths, onLeave }) => {
  return (
    <NavigationPrompt
      allowedPaths={allowedPaths}
      description="This will cancel the booking process and all unsaved changes will be lost. This cannot be undone."
      header="Leave page with unsaved changes?"
      leaveLabel="Leave page"
      onLeave={onLeave}
      stayLabel="Stay"
    />
  );
};

export default BookingNavigationPrompt;
