import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AssignedLocationsOnDefaultChargesQueryVariables = GraphQLTypes.Exact<{
  applicability?: GraphQLTypes.InputMaybe<GraphQLTypes.Applicability>;
}>;

export type AssignedLocationsOnDefaultChargesQueryResult = { __typename?: 'Query' } & {
  assignedLocationsOnDefaultCharges?: GraphQLTypes.Maybe<
    Array<
      | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
          })
      | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
            label?: GraphQLTypes.Maybe<
              { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
            >;
          })
    >
  >;
};

export const AssignedLocationsOnDefaultChargesDocument = /* #__PURE__ */ gql`
  query assignedLocationsOnDefaultCharges($applicability: Applicability) {
    assignedLocationsOnDefaultCharges(applicability: $applicability) {
      id
      label {
        long
        short
      }
    }
  }
`;

/**
 * __useAssignedLocationsOnDefaultChargesQuery__
 *
 * To run a query within a React component, call `useAssignedLocationsOnDefaultChargesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignedLocationsOnDefaultChargesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignedLocationsOnDefaultChargesQuery({
 *   variables: {
 *      applicability: // value for 'applicability'
 *   },
 * });
 */
export function useAssignedLocationsOnDefaultChargesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AssignedLocationsOnDefaultChargesQueryResult,
    AssignedLocationsOnDefaultChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AssignedLocationsOnDefaultChargesQueryResult, AssignedLocationsOnDefaultChargesQueryVariables>(
    AssignedLocationsOnDefaultChargesDocument,
    options
  );
}
export function useAssignedLocationsOnDefaultChargesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AssignedLocationsOnDefaultChargesQueryResult,
    AssignedLocationsOnDefaultChargesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AssignedLocationsOnDefaultChargesQueryResult, AssignedLocationsOnDefaultChargesQueryVariables>(
    AssignedLocationsOnDefaultChargesDocument,
    options
  );
}
export type AssignedLocationsOnDefaultChargesQueryHookResult = ReturnType<typeof useAssignedLocationsOnDefaultChargesQuery>;
export type AssignedLocationsOnDefaultChargesLazyQueryHookResult = ReturnType<
  typeof useAssignedLocationsOnDefaultChargesLazyQuery
>;
