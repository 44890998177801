import type { FC, ReactNode } from 'react';

import { Form, FormButtons, FormInstance } from '@zen/Components';
import { Button } from '@zen/DesignSystem';
import { ModeOfTransport } from '@zen/types';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

import type { CreateLooseCargoItemMutationResult } from '../../../graphql';
import LooseFormFields from '../LooseFormFields/LooseFormFields';
import type { LooseFormInitialValues, LooseFormValues } from '../LooseFormFields/types';
import { defaultLooseCargoFormInitialValues } from './helpers';
import { getLooseCargoFormValidationSchema } from './looseCargoForm.validation';

interface Props {
  initialValues?: LooseFormInitialValues;
  modeOfTransport: ModeOfTransport;
  onCancel: () => void;
  onSubmit: (values: LooseFormValues) => Promise<IOkOrErrorResult>;
  onSuccess?: (cargoId: string) => void;
}

const LooseCargoForm: FC<Props> = ({
  initialValues = defaultLooseCargoFormInitialValues,
  onCancel,
  onSubmit,
  onSuccess,
  modeOfTransport
}) => {
  const handleSuccess = (results: CreateLooseCargoItemMutationResult['cargoCreateLooseCargoItem']) => {
    onSuccess?.(results?.cargoItem?.id || '');
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<LooseFormValues>): ReactNode => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save">
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={handleSuccess}
      validationSchema={getLooseCargoFormValidationSchema(modeOfTransport)}
    >
      {() => (
        <LooseFormFields
          showChargeableWeight={modeOfTransport === ModeOfTransport.AIR}
          showContainerNumber={modeOfTransport === ModeOfTransport.OCEAN}
        />
      )}
    </Form>
  );
};

export default LooseCargoForm;
