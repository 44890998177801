import type { ReactNode } from 'react';
import { components } from 'react-select';

const getMenuList =
  (footerRenderer?: (inputValue: string) => ReactNode): typeof components.MenuList =>
  (props) => {
    const {
      selectProps: { inputValue, isLoading }
    } = props;

    return (
      <>
        <components.MenuList {...props} />
        {footerRenderer && !isLoading && (
          <div className="px-4 py-3 text-sm border-t border-solid border-grey-lighter bg-grey-lightest" data-testid="menu-footer">
            {footerRenderer(inputValue)}
          </div>
        )}
      </>
    );
  };

export default getMenuList;
