import type { FC, ReactNode } from 'react';

import { Dialog } from '@zen/DesignSystem';

import { ReactComponent as NewUpdateImage } from './new-update.svg';

interface Props {
  isVisible: boolean;
  onConfirm: () => void;
}

const UpdateRequiredModal: FC<Props> = ({ isVisible, onConfirm }) => {
  const message: ReactNode = (
    <>
      <div>We’ve rolled out a fresh new version of our app. Please reload the page to get the update.</div>
      <div className="flex justify-center mt-4">
        <NewUpdateImage />
      </div>
    </>
  );

  return (
    <Dialog
      confirmLabel="Reload to update"
      hasCancelButton={false}
      header="A new version is available"
      isOpen={isVisible}
      message={message}
      onClose={() => {}}
      onConfirm={onConfirm}
    />
  );
};

export default UpdateRequiredModal;
