import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@zen/DesignSystem';

import { getOrdersUrl } from '../bookingButtonHelper';

const BookingRequestButton: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = getOrdersUrl(location);

  const handleClick = (): void => {
    navigate(url);
  };

  return (
    <Button className="w-full outline-none" data-testid="request-booking-button" onClick={handleClick} variant="secondary">
      Request booking
    </Button>
  );
};

export default BookingRequestButton;
