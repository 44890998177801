const RULES_ENGINE_RELATIVE_INDEX_PATH = 'rules-engine';

export const RULES_ENGINE_PREFIX = `/dashboard/${RULES_ENGINE_RELATIVE_INDEX_PATH}`;

interface RulesEngineRoutes {
  exceptions: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
  rulesEdit: {
    getUrl: (ruleId: string) => string;
    path: string;
    relativePath: string;
  };
  rulesIndex: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const rulesEngineRoutes: RulesEngineRoutes = {
  exceptions: {
    getUrl: () => `${RULES_ENGINE_PREFIX}/exceptions`,
    path: `${RULES_ENGINE_PREFIX}/exceptions`,
    relativePath: 'exceptions'
  },
  index: {
    getUrl: () => RULES_ENGINE_PREFIX,
    path: `${RULES_ENGINE_PREFIX}/*`,
    relativePath: `${RULES_ENGINE_RELATIVE_INDEX_PATH}/*`
  },
  rulesIndex: {
    getUrl: () => `${RULES_ENGINE_PREFIX}/rules`,
    path: `${RULES_ENGINE_PREFIX}/rules/*`,
    relativePath: 'rules/*'
  },
  rulesEdit: {
    getUrl: (ruleId: string) => `${RULES_ENGINE_PREFIX}/rules/${ruleId}/edit`,
    path: `${RULES_ENGINE_PREFIX}/rules/:id/edit`,
    relativePath: ':id/edit'
  }
};

export default rulesEngineRoutes;
