import type { ReactElement, ReactNode } from 'react';
import type * as Yup from 'yup';

import { Form, FormButtons, FormInstance } from '@zen/Components';
import { Button } from '@zen/DesignSystem';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

interface Props<T extends {}> {
  children: (result: FormInstance<T>) => ReactElement | null;
  initialValues: T;
  onCancel?: () => void;
  onSubmit: (values: T) => Promise<IOkOrErrorResult<{}>>;
  onSuccess?: () => void;
  validationSchema?: Yup.ObjectSchema<{}>;
}

const AccountFormWrapper = <T extends {}>(props: Props<T>) => {
  const { children, initialValues, onCancel, onSubmit, validationSchema, onSuccess } = props;

  const renderFormButtons = ({ isSubmitting }: FormInstance<T>): ReactNode => {
    if (onCancel) {
      return (
        <FormButtons isSubmitting={isSubmitting} layout="fixed" text="Save changes">
          <Button data-testid="cancel-button" onClick={onCancel} variant="ghost">
            Cancel
          </Button>
        </FormButtons>
      );
    }

    return <FormButtons containerClassName="pt-2" isSubmitting={isSubmitting} text="Next" />;
  };

  return (
    <Form
      enableReinitialize={true}
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {(form: FormInstance<T>) => children(form)}
    </Form>
  );
};

export default AccountFormWrapper;
