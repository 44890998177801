import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';

export const setupInstrumentations = () => {
  const ignoredUrlPatterns: RegExp[] = [/api\.segment\.io/, /doubleclick\.net/, /fullstory\.com/, /google-analytics\.com/];

  registerInstrumentations({
    instrumentations: [
      new DocumentLoadInstrumentation(),
      new XMLHttpRequestInstrumentation({
        ignoreUrls: ignoredUrlPatterns,
        propagateTraceHeaderCorsUrls: [/zencargo\.com$/]
      }),
      new FetchInstrumentation({
        ignoreUrls: ignoredUrlPatterns,
        propagateTraceHeaderCorsUrls: [/zencargo\.com$/]
      })
    ]
  });
};
