import type { FC } from 'react';

interface Props {
  className?: string;
  span: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const colSpanClassNames: Record<Props['span'], string> = {
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12'
};

const Column: FC<Props> = ({ children, span, className = '', ...rest }) => {
  const classNames = `${colSpanClassNames[span]} ${className}`;

  return (
    <div className={classNames} data-testid="column" {...rest}>
      {children}
    </div>
  );
};

export type { Props as ColumnProps };
export default Column;
