import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UploadCommodityCodesMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UploadCommodityCodesInput;
}>;

export type UploadCommodityCodesMutationResult = { __typename?: 'Mutation' } & {
  commodityCodesUploadCommodityCodes?: GraphQLTypes.Maybe<
    { __typename?: 'UploadCommodityCodesPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
      result?: GraphQLTypes.Maybe<
        { __typename?: 'UploadCommodityCodesResult' } & Pick<GraphQLTypes.UploadCommodityCodesResult, 'created' | 'updated'>
      >;
    }
  >;
};

export const UploadCommodityCodesDocument = /* #__PURE__ */ gql`
  mutation uploadCommodityCodes($input: UploadCommodityCodesInput!) {
    commodityCodesUploadCommodityCodes(input: $input) {
      errors {
        message
      }
      result {
        created
        updated
      }
    }
  }
`;

/**
 * __useUploadCommodityCodesMutation__
 *
 * To run a mutation, you first call `useUploadCommodityCodesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCommodityCodesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCommodityCodesMutation, { data, loading, error }] = useUploadCommodityCodesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadCommodityCodesMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadCommodityCodesMutationResult, UploadCommodityCodesMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UploadCommodityCodesMutationResult, UploadCommodityCodesMutationVariables>(
    UploadCommodityCodesDocument,
    options
  );
}
export type UploadCommodityCodesMutationHookResult = ReturnType<typeof useUploadCommodityCodesMutation>;
