import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetSavedFiltersQueryVariables = GraphQLTypes.Exact<{ [key: string]: never }>;

export type GetSavedFiltersQueryResult = { __typename?: 'Query' } & {
  currentUser?: GraphQLTypes.Maybe<
    { __typename?: 'CurrentUser' } & {
      preferences?: GraphQLTypes.Maybe<
        { __typename?: 'Preference' } & {
          savedShipmentFilters?: GraphQLTypes.Maybe<
            Array<
              { __typename?: 'SavedFilters' } & Pick<GraphQLTypes.SavedFilters, 'name'> & {
                  filters?: GraphQLTypes.Maybe<
                    { __typename?: 'SavedFiltersShipments' } & Pick<
                      GraphQLTypes.SavedFiltersShipments,
                      | 'consignees'
                      | 'consignors'
                      | 'originCountries'
                      | 'destinationCountries'
                      | 'origins'
                      | 'destinations'
                      | 'option'
                      | 'days'
                      | 'status'
                      | 'transportModes'
                      | 'customers'
                      | 'incoterms'
                      | 'stages'
                      | 'vesselNames'
                      | 'forwarders'
                    >
                  >;
                }
            >
          >;
        }
      >;
    }
  >;
};

export const GetSavedFiltersDocument = /* #__PURE__ */ gql`
  query getSavedFilters {
    currentUser {
      preferences {
        savedShipmentFilters {
          name
          filters {
            consignees
            consignors
            originCountries
            destinationCountries
            origins
            destinations
            option
            days
            status
            transportModes
            customers
            incoterms
            stages
            vesselNames
            forwarders
          }
        }
      }
    }
  }
`;

/**
 * __useGetSavedFiltersQuery__
 *
 * To run a query within a React component, call `useGetSavedFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSavedFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSavedFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSavedFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSavedFiltersQueryResult, GetSavedFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetSavedFiltersQueryResult, GetSavedFiltersQueryVariables>(GetSavedFiltersDocument, options);
}
export function useGetSavedFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSavedFiltersQueryResult, GetSavedFiltersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetSavedFiltersQueryResult, GetSavedFiltersQueryVariables>(GetSavedFiltersDocument, options);
}
export type GetSavedFiltersQueryHookResult = ReturnType<typeof useGetSavedFiltersQuery>;
export type GetSavedFiltersLazyQueryHookResult = ReturnType<typeof useGetSavedFiltersLazyQuery>;
