import { isRoadBooking } from '@zen/Booking';
import type { ModeOfTransport } from '@zen/graphql/types.generated';
import { isCollectionStop, isVehicleArrivedAtWarehouse } from '@zen/Journey/helpers';
import type { JourneyShippingMilestoneNameEnum, JourneyStopActionEnum } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

export interface RoadLegParams {
  canManagePlannedDates: boolean;
  isMilestoneCompleted: boolean;
  milestoneName: JourneyShippingMilestoneNameEnum;
  modeOfTransport: Optional<ModeOfTransport>;
  stopAction: JourneyStopActionEnum;
}

export const areRoadLegButtonsVisible = (params: RoadLegParams): boolean => {
  const { canManagePlannedDates, isMilestoneCompleted, milestoneName, modeOfTransport, stopAction } = params;

  return (
    canManagePlannedDates &&
    !isMilestoneCompleted &&
    isRoadBooking(modeOfTransport) &&
    isCollectionStop(stopAction) &&
    isVehicleArrivedAtWarehouse(milestoneName)
  );
};
