import { groupBy } from 'lodash';

import type { Option, SelectOption } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import { type DelayReason, ShippingDelayReasonResponsiblePartyEnum } from './types';

const getGroupOptions = (delayReasons: DelayReason[]): Option<string>[] => {
  return delayReasons.map(({ id, reason, responsibleParty }) => {
    const isDisabled: boolean = responsibleParty === ShippingDelayReasonResponsiblePartyEnum.AI_ESTIMATE;

    return {
      value: id,
      label: reason,
      isDisabled
    };
  });
};

export const getOptions = (delayReasons: DelayReason[]): SelectOption<string>[] => {
  const groupedDelayReasons = groupBy(delayReasons, 'responsibleParty');

  return Object.keys(groupedDelayReasons)
    .sort()
    .map((responsibleParty) => {
      return {
        label: responsibleParty,
        options: getGroupOptions(groupedDelayReasons[responsibleParty as keyof typeof groupedDelayReasons])
      };
    });
};

export const responsiblePartyIconMapping: Record<ShippingDelayReasonResponsiblePartyEnum, IconName> = {
  [ShippingDelayReasonResponsiblePartyEnum.CARRIER]: 'zicon-ship',
  [ShippingDelayReasonResponsiblePartyEnum.AI_ESTIMATE]: 'zicon-menu-meatballs-small',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_BUYER]: 'zicon-garments',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_DC]: 'zicon-warehouse',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMER_SHIPPING]: 'zicon-box',
  [ShippingDelayReasonResponsiblePartyEnum.CUSTOMS]: 'zicon-customs',
  [ShippingDelayReasonResponsiblePartyEnum.HAULIER]: 'zicon-road',
  [ShippingDelayReasonResponsiblePartyEnum.OTHER]: 'zicon-menu-meatballs-small',
  [ShippingDelayReasonResponsiblePartyEnum.PORT]: 'zicon-port-discharge',
  [ShippingDelayReasonResponsiblePartyEnum.SUPPLIER]: 'zicon-manufacturer',
  [ShippingDelayReasonResponsiblePartyEnum.ZENCARGO]: 'zicon-zencargo'
};
