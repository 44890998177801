import type { FC } from 'react';

import type { Option } from '@zen/DesignSystem';

import FormDimensionsInput, { FormDimensionsInputProps } from '../FormDimensionsInput';
import { CargoDimensionsUnitEnum } from './types';

interface Props extends Omit<FormDimensionsInputProps<CargoDimensionsUnitEnum>, 'options' | 'hasUnit'> {
  isCoreCargo?: boolean;
}

const FormCargoDimensionsInput: FC<Props> = ({ isCoreCargo, ...rest }) => {
  const metricOptions: Option<CargoDimensionsUnitEnum>[] = [
    { label: 'cm', value: CargoDimensionsUnitEnum.CM },
    { label: 'mm', value: CargoDimensionsUnitEnum.MM },
    { label: 'in', value: CargoDimensionsUnitEnum.IN }
  ];

  return <FormDimensionsInput {...rest} hasUnit={isCoreCargo} options={metricOptions} />;
};

export default FormCargoDimensionsInput;
