import type { FC } from 'react';

import { dashboardRoutes } from '@zen/Routes';

import NoResults, { ButtonConfig } from '../NoResults';

const button = {
  linkTo: dashboardRoutes.dashboard.getUrl(),
  label: 'Go to dashboard'
};

interface Props {
  displayButton?: boolean;
}

const PageNotFound: FC<Props> = ({ displayButton = true }) => {
  const buttonConfig: ButtonConfig | undefined = displayButton ? button : undefined;

  return (
    <NoResults
      button={buttonConfig}
      headline="We can't find that page"
      tagline="We’re sorry. This link is broken or this page is missing."
    />
  );
};

export default PageNotFound;
