const ACCRUAL_RELATIVE_INDEX_PATH = 'accruals';

export const ACCRUAL_ROUTE_PREFIX = `/dashboard/${ACCRUAL_RELATIVE_INDEX_PATH}`;

interface AccrualRoutes {
  accrualDetails: {
    getUrl: (accountId: string, zencargoReference: string, accrualId: string, rateCardId?: string) => string;
    path: string;
    relativePath: string;
  };
  accrualIndex: {
    getUrl: (accountId: string, zencargoReference: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const accrualRoutes: AccrualRoutes = {
  accrualDetails: {
    path: `${ACCRUAL_ROUTE_PREFIX}/:accountId/:id/:accrualId/:rateCardId?`,
    getUrl: (accountId: string, zencargoReference: string, accrualId: string, rateCardId?: string): string => {
      const url = `${ACCRUAL_ROUTE_PREFIX}/${accountId}/${zencargoReference}/${accrualId}`;

      if (rateCardId) {
        return `${url}/${rateCardId}`;
      }

      return url;
    },
    relativePath: ':accountId/:id/:accrualId/:rateCardId?'
  },
  accrualIndex: {
    path: `${ACCRUAL_ROUTE_PREFIX}/:accountId/:id`,
    getUrl: (accountId: string, zencargoReference: string) => `${ACCRUAL_ROUTE_PREFIX}/${accountId}/${zencargoReference}`,
    relativePath: ':accountId/:id'
  },
  index: {
    getUrl: () => ACCRUAL_ROUTE_PREFIX,
    path: `${ACCRUAL_ROUTE_PREFIX}/*`,
    relativePath: `${ACCRUAL_RELATIVE_INDEX_PATH}/*`
  }
};

export default accrualRoutes;
