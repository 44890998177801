import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingIssueQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingIssueQueryResult = { __typename?: 'Query' } & {
  bookingIssue: { __typename?: 'BookingIssue' } & Pick<
    GraphQLTypes.BookingIssue,
    | 'associatedId'
    | 'associatedType'
    | 'category'
    | 'id'
    | 'problemDescription'
    | 'severity'
    | 'solutionDescription'
    | 'state'
    | 'title'
  > & {
      canArchive: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canModifyVisibility: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canResolve: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canStart: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canUpdate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canUpdateCategory: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      canUpdateProblemDescription: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
      occurredOn: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>;
      openedAt: { __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>;
      openedBy: { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>;
      resolvedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>>;
      resolvedBy?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
      >;
      startedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date' | 'dateTime'>>;
      startedBy?: GraphQLTypes.Maybe<
        { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName'>
      >;
      visibility: { __typename?: 'IssueVisibility' } & Pick<
        GraphQLTypes.IssueVisibility,
        'agentForwarder' | 'customerUser' | 'manufacturer'
      >;
    };
};

export const GetBookingIssueDocument = /* #__PURE__ */ gql`
  query getBookingIssue($id: String!) {
    bookingIssue(id: $id) {
      associatedId
      associatedType
      category
      canArchive {
        value
      }
      canModifyVisibility {
        value
      }
      canResolve {
        value
      }
      canStart {
        value
      }
      canUpdate {
        value
      }
      canUpdateCategory {
        value
      }
      canUpdateProblemDescription {
        value
      }
      id
      occurredOn {
        date
      }
      openedAt {
        date
        dateTime
      }
      openedBy {
        firstName
        lastName
      }
      problemDescription
      resolvedAt {
        date
        dateTime
      }
      resolvedBy {
        firstName
        lastName
      }
      severity
      solutionDescription
      startedAt {
        date
        dateTime
      }
      startedBy {
        firstName
        lastName
      }
      state
      title
      visibility {
        agentForwarder
        customerUser
        manufacturer
      }
    }
  }
`;

/**
 * __useGetBookingIssueQuery__
 *
 * To run a query within a React component, call `useGetBookingIssueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingIssueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingIssueQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBookingIssueQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingIssueQueryResult, GetBookingIssueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingIssueQueryResult, GetBookingIssueQueryVariables>(GetBookingIssueDocument, options);
}
export function useGetBookingIssueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingIssueQueryResult, GetBookingIssueQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingIssueQueryResult, GetBookingIssueQueryVariables>(GetBookingIssueDocument, options);
}
export type GetBookingIssueQueryHookResult = ReturnType<typeof useGetBookingIssueQuery>;
export type GetBookingIssueLazyQueryHookResult = ReturnType<typeof useGetBookingIssueLazyQuery>;
