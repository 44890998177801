import type { FC, ReactNode } from 'react';

import { useSearchableAccountOptions } from '@zen/Accounts/hooks/useSearchableAccountOptions';
import { TextLink } from '@zen/DesignSystem';
import { accountsRoutes } from '@zen/Routes';
import type { Nullable, Optional } from '@zen/utils/typescript';

import FormSelect from '../FormSelect';

interface Props {
  error?: boolean;
  isLoading?: boolean;
  label?: string;
  name: string;
  noMargin?: boolean;
  onChange?: (value: Nullable<string>) => void;
  value?: Optional<string>;
  variant?: 'inline' | 'default';
}

const FormAccountSelect: FC<Props> = (props) => {
  const { options, loading, onSearch } = useSearchableAccountOptions({ selectedValues: props.value ? [props.value] : undefined });

  const renderFooter = (): ReactNode => {
    return <TextLink linkTo={{ pathname: accountsRoutes.accountsCreate.getUrl() }}>Create new account</TextLink>;
  };

  return (
    <FormSelect
      {...props}
      dropdownFooterRenderer={renderFooter}
      isLoading={loading}
      labelPlacement="left"
      noMargin={props.noMargin}
      onInputChange={onSearch}
      options={options}
      placeholder="Search for an account..."
      renderMenuInPortal={true}
    />
  );
};

export default FormAccountSelect;
