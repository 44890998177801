import type { FC, ReactNode } from 'react';

import NoResults from '@zen/Components/NoResults';
import QueryHandler from '@zen/Components/QueryHandler';
import type { Optional } from '@zen/utils/typescript';

import TaskListActions from '../../components/TaskListActions';
import { getTaskActionPermissions, isCompletedTask } from '../../helpers';
import useMiniTaskListQuery from '../../hooks/useMiniTaskListQuery';
import type { MiniTaskListFilter, TaskActionPermissions, TaskListItem } from '../../types';
import MiniTaskListCard from '../MiniTaskListCard';
import { getNoResultsMessage, NoTasksDisplayMessages } from './helpers';

const MiniTaskListContainer: FC<{ filters: MiniTaskListFilter }> = ({ filters }) => {
  const { loading, error, nodes, refetch } = useMiniTaskListQuery(filters);

  const renderNoTasksResults = (): ReactNode => {
    const noResultsMessage: NoTasksDisplayMessages = getNoResultsMessage(filters.activeStatus);

    return <NoResults {...noResultsMessage} />;
  };

  return (
    <QueryHandler data={nodes} error={!!error} isLoading={loading} noResults={renderNoTasksResults()}>
      {(taskDetails: TaskListItem[]) => {
        return (
          <div className="flex gap-2 flex-col">
            {taskDetails.map((task: TaskListItem) => {
              const { assignee, taskId, name, zencargoReference, priority, dueDate } = task;
              const completedDate: Optional<string> = isCompletedTask(task) ? task.completedDate : undefined;
              const assigneeName: Optional<string> = assignee && `${assignee.firstName} ${assignee.lastName}`;
              const permissions: TaskActionPermissions = getTaskActionPermissions(task);

              const taskActions: ReactNode = (
                <TaskListActions
                  {...permissions}
                  onTaskListChange={refetch}
                  taskDescription={name}
                  taskId={taskId}
                  zencargoReference={zencargoReference}
                />
              );

              return (
                <MiniTaskListCard
                  key={taskId}
                  actions={taskActions}
                  assignee={assigneeName}
                  completedDate={completedDate}
                  description={name}
                  dueDate={dueDate}
                  priority={priority}
                />
              );
            })}
          </div>
        );
      }}
    </QueryHandler>
  );
};

export default MiniTaskListContainer;
