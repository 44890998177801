import { createContext } from 'react';

import type { JourneyLeg, JourneyMilestoneWithMetadata } from '@zen/Journey/types';
import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';
import type { Optional } from '@zen/utils/typescript';

import type { BookingStageEnum, ModeOfTransport } from '../types';

export interface CargoOverviewContextData {
  accountId: string;
  bookingStage: Optional<BookingStageEnum>;
  cargoCount: number;
  cargoIds: string[];
  cargoMilestones: Record<string, JourneyMilestoneWithMetadata[]>;
  cargoPreLegsMilestones: Record<string, JourneyMilestoneWithMetadata[]>;
  cargoReadyDate: Optional<string>;
  carriageLegs: JourneyLeg[];
  isCollectionEnabled: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

export const initialContext: CargoOverviewContextData = {
  accountId: '',
  bookingStage: null,
  cargoCount: 0,
  cargoIds: [],
  cargoMilestones: {},
  cargoPreLegsMilestones: {},
  cargoReadyDate: null,
  carriageLegs: [],
  isCollectionEnabled: true,
  modeOfTransport: null,
  zencargoReference: ''
};

const CargoOverviewContext = createContext<CargoOverviewContextData>(initialContext);

const useCargoOverviewContext = (): CargoOverviewContextData => {
  const cargoOverviewContextData = useContextOrThrowError<CargoOverviewContextData>(
    CargoOverviewContext,
    'useCargoOverviewContext must be used inside CargoOverviewContextProvider'
  );

  return cargoOverviewContextData;
};

export { CargoOverviewContext, useCargoOverviewContext };
