import type { ExecutionResult } from 'graphql';
import { get, isEmpty, set } from 'lodash';

import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';

export type ErrorType = IOkOrErrorResult['error'];

interface BaseMutationProps<T> {
  modifyDataErrors?: (errors: Array<ErrorType>) => Array<ErrorType>;
  mutationFn: () => Promise<ExecutionResult<T>>;
  onError?: (errors: ErrorType[]) => void;
  onSuccess?: () => void;
  skipDataErrors?: boolean;
}

export async function baseMutation<T>(props: BaseMutationProps<T>): Promise<IOkOrErrorResult<T>> {
  const { mutationFn, onSuccess, onError, skipDataErrors, modifyDataErrors } = props;
  const result: IOkOrErrorResult<T> = { ok: null, error: null };

  try {
    const response: ExecutionResult<T> = await mutationFn();
    const { data } = response;
    let responseDataErrors = [];

    if (data) {
      const errorsPath: string = `data.${Object.keys(data)[0]}.errors`;
      const initialDataErrors = get(response, errorsPath);

      if (modifyDataErrors && !isEmpty(initialDataErrors)) {
        set(response, `data.${Object.keys(data)[0]}.errors`, modifyDataErrors(initialDataErrors));
      }

      responseDataErrors = get(response, errorsPath);
    }

    const dataErrors = skipDataErrors ? [] : responseDataErrors;
    const errors: ErrorType[] = response.errors || dataErrors;

    if (errors?.length > 0) {
      result.error = errors;

      if (onError) {
        onError(errors);
      }

      return result;
    }

    result.ok = response.data || null;
    if (onSuccess) {
      onSuccess();
    }

    return result;
  } catch (err) {
    if (onError) {
      onError([err as Error]);
    }

    result.error = (err as Error)?.message; // ApolloError inherits from Error

    return result;
  }
}

interface PerformMutationProps<T> extends BaseMutationProps<T> {
  onError: (errors: ErrorType[]) => void;
}

export async function performMutation<T>(props: PerformMutationProps<T>): Promise<IOkOrErrorResult<T>> {
  return baseMutation(props);
}

export async function performFormMutation<T>(props: Omit<BaseMutationProps<T>, 'skipDataErrors'>): Promise<IOkOrErrorResult<T>> {
  return baseMutation({
    ...props,
    skipDataErrors: true
  });
}
