import type { FC } from 'react';

import LabelledValue from '@zen/Components/LabelledValue';
import { type JourneyShippingMilestone, JourneyStopActionEnum } from '@zen/Journey/types';
import { prepareBusinessHoursInitialValues } from '@zen/Networks/networksHelpers';
import type { Optional } from '@zen/utils/typescript';

import MilestoneDates from '../MilestoneDates';

interface Props {
  milestone: JourneyShippingMilestone;
  timeZone: Optional<string>;
}

const GateInEmptyPicker: FC<Props> = ({ milestone, timeZone }) => {
  return (
    <LabelledValue label="Gating in empty">
      <div className="-mx-3" data-testid="gate-in-empty-picker">
        <MilestoneDates
          alignment="left"
          locationBusinessHours={prepareBusinessHoursInitialValues()}
          milestone={milestone}
          stopAction={JourneyStopActionEnum.DELIVERY}
          timeZone={timeZone}
        />
      </div>
    </LabelledValue>
  );
};

export default GateInEmptyPicker;
