import type { SegmentedControlOption } from '@zen/DesignSystem';

import { TimeOption } from '../../DateWithTimeForm/types';

export const timeOptions: SegmentedControlOption<TimeOption>[] = [
  {
    label: 'No time',
    value: TimeOption.NO_TIME
  },
  {
    label: 'Point in time',
    value: TimeOption.POINT_IN_TIME
  },
  {
    label: 'Time range',
    value: TimeOption.TIME_RANGE
  }
];

export const getOptions = (config: TimeOption[]): SegmentedControlOption<TimeOption>[] => {
  return timeOptions.filter(({ value }) => config.includes(value));
};
