import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersCloseOrderMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ClosePurchaseOrderInput;
}>;

export type PurchaseOrdersCloseOrderMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersCloseOrder?: GraphQLTypes.Maybe<
    { __typename?: 'ClosePurchaseOrderPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const PurchaseOrdersCloseOrderDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersCloseOrder($input: ClosePurchaseOrderInput!) {
    purchaseOrdersCloseOrder(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __usePurchaseOrdersCloseOrderMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersCloseOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersCloseOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersCloseOrderMutation, { data, loading, error }] = usePurchaseOrdersCloseOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersCloseOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersCloseOrderMutationResult, PurchaseOrdersCloseOrderMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersCloseOrderMutationResult, PurchaseOrdersCloseOrderMutationVariables>(
    PurchaseOrdersCloseOrderDocument,
    options
  );
}
export type PurchaseOrdersCloseOrderMutationHookResult = ReturnType<typeof usePurchaseOrdersCloseOrderMutation>;
