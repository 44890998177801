import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import { Counter } from '@zen/DesignSystem';
import { useGetTaskListCountersQuery } from '@zen/Layout/Sidebar/graphql';
import { useCoreCargoIds } from '@zen/Tasks/hooks/useCoreCargoIds';
import { QUERY_POLLING_INTERVAL } from '@zen/utils/constants';
import useAccount from '@zen/utils/hooks/useAccount';
import { useFocusAwarePolling } from '@zen/utils/hooks/useFocusAwarePolling';

type RouteParams = {
  id: string;
};

interface Props {
  className?: string;
}

const CurrentShipmentOpenTaskCount: FC<Props> = ({ className = '' }) => {
  const { userProfile } = useAccount();
  const { id: zencargoReference } = useParams<RouteParams>() as RouteParams;
  const cargoIds: string[] = useCoreCargoIds(zencargoReference);

  const variables = { taskDetailsFiltersInput: { businessKey: [zencargoReference, ...cargoIds] } };

  const { data } = useFocusAwarePolling(useGetTaskListCountersQuery, {
    variables,
    pollInterval: QUERY_POLLING_INTERVAL,
    skip: !userProfile.isAdmin
  });

  const activeTaskCount = data?.taskListCount?.activeTaskCount;

  return <Counter className={className} type="active" value={activeTaskCount || ''} />;
};

export default CurrentShipmentOpenTaskCount;
