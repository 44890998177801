import type { FC } from 'react';

import type { PermittedParty } from '@zen/ActivityFeed';
import getPermittedRoleLabel from '@zen/ActivityFeed/helpers/getPermittedRoleLabel';
import { userRoleIconMapping } from '@zen/ActivityFeed/helpers/roleIconHelper';

import PermissionSwitcher from '../PermissionSwitcher';
import type { PermissionsInput } from '../types';

interface Props {
  isChecked: boolean;
  isDisabled: boolean;
  onPermissionChange: (key: keyof PermissionsInput, permissionValue: boolean) => void;
  party: PermittedParty;
  permissionKey: keyof PermissionsInput;
}

const PartyControl: FC<Props> = ({ party, permissionKey, isChecked, isDisabled = false, onPermissionChange }) => {
  const { role, organisationName } = party;

  const handleChange = (value: boolean): void => {
    if (isDisabled) {
      return;
    }

    onPermissionChange(permissionKey, value);
  };

  return (
    <PermissionSwitcher
      checked={isChecked}
      disabled={isDisabled}
      icon={userRoleIconMapping[role]}
      name={permissionKey}
      onChange={handleChange}
      subtitle={getPermittedRoleLabel(role)}
      title={organisationName}
    />
  );
};

export default PartyControl;
