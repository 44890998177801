import Cookies from 'js-cookie';

class CsrfTokenManager {
  public get(): string {
    return Cookies.get('ocean_csrf_token') || '';
  }
}

const csrfTokenManager = new CsrfTokenManager();

export default csrfTokenManager;
