import type { FC, ReactNode } from 'react';

import { formatDate } from '@zen/utils/dateTime';

interface Props {
  date: string;
  hideBullet?: boolean;
}

const ActivityFeedDay: FC<Props> = ({ date, hideBullet }) => {
  const formattedDate: string = formatDate(date, 'D_MMMM_YYYY');
  const renderBullet = (): ReactNode => (
    <div
      className="absolute w-6 h-6 rounded-full border-2 border-solid border-white bg-azure-base z-50 flex justify-center items-center"
      data-testid="activity-item-bullet"
      style={{ left: '-2.8rem' }}
    >
      <div className="absolute w-4 h-4 rounded-full border-2 border-solid border-white bg-azure-base z-50" />
    </div>
  );

  return (
    <div className="relative flex justify-center items-center text-grey-base text-xs mb-4">
      {!hideBullet && renderBullet()}
      <hr className="w-6/12 text-grey-lighter" />
      <span className="min-w-32 text-center">{formattedDate}</span>
      <hr className="w-6/12 text-grey-lighter" />
    </div>
  );
};

export default ActivityFeedDay;
