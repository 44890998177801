import type { FC } from 'react';

import { AsyncSelect } from '@zen/DesignSystem';
import type { TerminalTypeValue } from '@zen/types';
import type { Nullable } from '@zen/utils/typescript';

import { useGetTerminalsLazyQuery } from './graphql';
import { getTerminalOptionLabel } from './helpers';
import type { Terminal } from './types';

interface Props {
  hasError?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  name: string;
  onBlur?: () => void;
  onChange?: (terminal: Nullable<Terminal>) => void;
  placeholder?: string;
  renderMenuInPortal?: boolean;
  terminalTypes?: TerminalTypeValue[];
  value: Nullable<Terminal>;
  variant?: 'default' | 'inline';
}

const TerminalSelect: FC<Props> = (props) => {
  const { terminalTypes, variant = 'default' } = props;

  const [fetchTerminals] = useGetTerminalsLazyQuery();

  const handleInputChange = async (query: string): Promise<Terminal[]> => {
    const response = await fetchTerminals({ variables: { terminalTypes, query } });

    return (response?.data?.terminals?.nodes || []) as Terminal[];
  };

  return (
    <AsyncSelect<Terminal>
      {...props}
      formatOptionLabel={getTerminalOptionLabel}
      loadOptions={handleInputChange}
      variant={variant}
    />
  );
};

export default TerminalSelect;
