import { deburr, toLower } from 'lodash';

export const highlightQuery = (text: string, query: string) => {
  const index = deburr(toLower(text)).indexOf(query);

  if (query && index >= 0) {
    const highlighted = text.substring(index, index + query.length);

    return [
      text.substring(0, index),
      <b key="h" className="font-bold">
        {highlighted}
      </b>,
      text.substring(index + query.length)
    ];
  }

  return text;
};
