import type { FC } from 'react';

import type { Optional } from '@zen/utils/typescript';

import { formatDelay } from '../MilestoneDates/helpers';

interface Props {
  delayInDays: number;
  initialEstimate: Optional<string>;
}

const MilestoneInitialEstimate: FC<Props> = ({ delayInDays, initialEstimate }) => {
  return (
    <div className="px-2 group text-grey-dark" data-testid="initial-estimate">
      <div className="hidden group-hover:block">{initialEstimate}</div>
      <div className="text-red-dark group-hover:hidden">{formatDelay(delayInDays)}</div>
    </div>
  );
};

export default MilestoneInitialEstimate;
