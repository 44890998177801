import { isEqual } from 'lodash';
import pDebounce from 'p-debounce';
import { ReactElement, ReactNode, useState } from 'react';
import AsyncSelectComponent from 'react-select/async';

import type { LabelPlacement } from '@zen/Components/Label';
import Label from '@zen/Components/Label';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import { useHover } from '@zen/utils/hooks/useHover';
import type { Nullable } from '@zen/utils/typescript';

import { ClearIndicator, getMenuList, getNoOptionsMessage, ValueContainer } from '../components';
import DropdownIndicator from '../components/DropdownIndicator';
import { asyncSelectStyles, customTheme } from '../select-styles';
import type { CommonProps, SelectVariant } from '../types';

interface Props<Value> extends CommonProps {
  cacheOptions?: boolean;
  defaultOptions?: boolean | Value[];
  formatOptionLabel: (value: Value, inputValue: string, isSelected: boolean, context: 'menu' | 'value') => ReactNode;
  label?: ReactNode;
  labelPlacement?: LabelPlacement;
  loadOptions: (inputValue: string) => Promise<Value[]>;
  onChange?: (value: Nullable<Value>) => void;
  onInputChange?: (inputValue: string) => void;
  optionKey?: keyof Value;
  renderMenuInPortal?: boolean;
  size?: 'default' | 'compact';
  value?: Nullable<Value>;
  variant?: SelectVariant;
}

const AsyncSelect = <ResultType extends {}>(props: Props<ResultType>) => {
  const {
    autoFocus,
    cacheOptions = true,
    defaultOptions,
    dropdownFooterRenderer,
    className,
    hasError,
    inputValue,
    isClearable,
    isDisabled,
    isLoading,
    label,
    isSearchable = true,
    labelPlacement,
    loadOptions,
    name,
    onBlur,
    onChange,
    onInputChange,
    optionKey,
    placeholder = 'Search...',
    renderMenuInPortal = false,
    size = 'default',
    suggestion,
    variant = 'default',
    value
  } = props;

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [ref, isHovered] = useHover();

  const onInputValueChange = (input: string): void => {
    setIsMenuOpen(input.length > 0);

    onInputChange?.(input);
  };

  const handleFocus = (): void => setIsMenuOpen(true);

  const debouncedHandleInputChange = pDebounce(loadOptions, SEARCH_DEBOUNCE_DELAY);

  const formatLabel = (
    option: ResultType,
    {
      context,
      inputValue: input,
      selectValue
    }: { context: 'menu' | 'value'; inputValue: string; selectValue: readonly ResultType[] }
  ): ReactNode => {
    const isSelected = isOptionSelected(option, selectValue);

    return props.formatOptionLabel(option, input, isSelected, context);
  };

  const isOptionSelected = (selectedOption: ResultType, options: readonly ResultType[]) => {
    return !!options.find((option) => {
      if (optionKey) {
        return selectedOption[optionKey] === option[optionKey];
      }

      return isEqual(selectedOption, option);
    });
  };

  const shouldEnableBlurOnSelect: boolean = variant !== 'default';
  const asyncSelect: ReactElement = (
    <div ref={ref} className="flex-1">
      <AsyncSelectComponent<ResultType>
        autoFocus={autoFocus}
        blurInputOnSelect={shouldEnableBlurOnSelect}
        cacheOptions={cacheOptions}
        className={className}
        classNames={{ option: () => 'async-select-option' }}
        closeMenuOnSelect={true}
        components={{
          DropdownIndicator,
          ...(variant === 'default' ? { ValueContainer } : {}),
          MenuList: getMenuList(dropdownFooterRenderer),
          NoOptionsMessage: getNoOptionsMessage(suggestion, true),
          ClearIndicator
        }}
        defaultOptions={defaultOptions}
        formatOptionLabel={formatLabel}
        inputId={name}
        inputValue={inputValue}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isMulti={false}
        isOptionSelected={isOptionSelected}
        isSearchable={isSearchable}
        loadOptions={debouncedHandleInputChange}
        menuIsOpen={isMenuOpen}
        menuPlacement="auto"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={defaultOptions ? handleFocus : undefined}
        onInputChange={onInputValueChange}
        placeholder={placeholder}
        styles={asyncSelectStyles({ hasError, variant, isHovered, isCompactSize: size === 'compact' })}
        theme={customTheme}
        value={value}
        {...(renderMenuInPortal ? { menuPortalTarget: document.body } : {})}
      />
    </div>
  );

  if (!label) {
    return asyncSelect;
  }

  return (
    <Label content={label} labelPlacement={labelPlacement} name={name || 'select'} variant={variant}>
      {asyncSelect}
    </Label>
  );
};

export type { Props as AsyncSelectProps };

export default AsyncSelect;
