import cx from 'classnames';
import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import { Status } from '@zen/Orders/types';
import type { IconName } from '@zen/Styleguide';

const iconConfig: Record<Status, { className: string; icon: IconName }> = {
  [Status.ALERT]: {
    icon: 'zicon-bell',
    className: 'bg-red-base text-xl'
  },
  [Status.NONE]: {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'undefined' is not assignable to type 'IconNa... Remove this comment to see the full error message
    icon: undefined,
    className: 'bg-azure-lighter'
  },
  [Status.PROBLEM]: {
    icon: 'zicon-close',
    className: 'bg-red-base text-sm'
  },
  [Status.OK]: {
    icon: 'zicon-tick',
    className: 'text-sm bg-azure-base'
  },
  [Status.WARNING]: {
    icon: 'zicon-warning-triangle',
    className: 'bg-orange-base text-base'
  }
};

interface Props {
  className?: string;
  type: Status;
}

const ProgressStatusIcon: FC<Props> = ({ type, className }) => {
  const classNames: string = cx(
    'flex justify-center items-center rounded-full w-8 h-8 text-white',
    iconConfig[type].className,
    className
  );

  return <Icon className={classNames} icon={iconConfig[type].icon} />;
};

export default ProgressStatusIcon;
