import { flatten, groupBy } from 'lodash';

import type { ContainerTypeEnum } from '@zen/Cargo';
import type { ChargeType } from '@zen/Components/Form/FormChargeTypeSelect';

import type { FreightChargePayload } from '../../RateCardForm/reducer/types';

export const getAllContainerTypes = (freightCharges: FreightChargePayload[]): ContainerTypeEnum[] => {
  const containerChargesUnitTypes: Array<ContainerTypeEnum[]> = freightCharges.map((freightCharge: FreightChargePayload) => {
    const containerChargesByUnitType = groupBy(freightCharge.containerCharges, 'unitType');

    return Object.keys(containerChargesByUnitType) as ContainerTypeEnum[];
  });

  return [...new Set(flatten(containerChargesUnitTypes))];
};

export const shouldIncludeCharacteristicsColumn = (freightCharges: FreightChargePayload[]): boolean => {
  const chargeTypes: ChargeType[] = freightCharges.map((freightCharge: FreightChargePayload) => freightCharge.chargeType);

  return chargeTypes.some((chargeType: ChargeType) => chargeType.reefer || chargeType.hazardous || chargeType.overweight);
};
