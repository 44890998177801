import cx from 'classnames';
import { FC, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';

import { ContextMenu, MenuItemType } from '@zen/DesignSystem';
import CargoReadyDateSlideout from '@zen/Orders/CargoReadyDateSlideout';
import useTracking from '@zen/utils/hooks/useTracking';
import type { Optional } from '@zen/utils/typescript';

import OrderLot from '../OrderLot';
import { CargoLot, CargoLotTracking, CargoLotTrackingAction } from '../types';

interface Props {
  className?: string;
  linkTo: string;
  lots: CargoLot[];
  orderReferenceNumber: string;
}

const BookingOrderListItem: FC<Props> = ({ className, lots, linkTo, orderReferenceNumber }) => {
  const [isSlideoutOpened, setIsSlideoutOpened] = useState<boolean>(false);
  const { trackEvent } = useTracking();

  const containerClassNames: string = cx(className, 'pt-4 pb-1');
  const lotIds: string[] = lots.map((lot: CargoLot) => lot.id);
  const orderDate: Optional<string> = lots[0]?.orderDate || '';
  const cargoReadyDate: string = lots[0].cargoReadyDate?.date || '';

  const closeSideout = (): void => {
    setIsSlideoutOpened(false);
  };

  const openSlideout = (): void => {
    setIsSlideoutOpened(true);
  };

  const handleSuccess = (): void => {
    trackEvent({
      action: CargoLotTrackingAction.MULTIPLE_SCHEDULE_UPDATE,
      label: CargoLotTracking,
      category: CargoLotTracking
    });
  };

  const renderLotItem = (lot: CargoLot, i: number): ReactNode => <OrderLot key={i} lot={lot} />;

  const contextItems: MenuItemType[] = [{ icon: 'zicon-edit', label: 'Update multiple lot schedules', onClick: openSlideout }];

  return (
    <div className={containerClassNames} data-testid="order">
      <div className="flex justify-between items-center font-bold mb-0.5">
        <Link className="text-navy-base text-normal hover:underline fs-cargoTab-poRef" to={linkTo}>
          {orderReferenceNumber}
        </Link>
        <ContextMenu items={contextItems} />
      </div>

      <div>{lots.map(renderLotItem)}</div>
      <CargoReadyDateSlideout
        initialValues={{ date: cargoReadyDate }}
        isVisible={isSlideoutOpened}
        lotIds={lotIds}
        onClose={closeSideout}
        onSuccess={handleSuccess}
        orderDate={orderDate}
      />
    </div>
  );
};

export default BookingOrderListItem;
