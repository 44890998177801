import type { FC } from 'react';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import NoResultsComponent, { ButtonConfig } from '@zen/Components/NoResults';
import { orderRoutes } from '@zen/Routes';

interface Props {
  hasFilters: boolean;
}

const NoResults: FC<Props> = ({ hasFilters }) => {
  const { check } = useGlobalPermissions();

  const canCreateOrders = check('purchaseOrders.canCreate');

  if (hasFilters) {
    return (
      <NoResultsComponent
        headline="We can't find any results."
        isCentered={false}
        tagline="There are no results for your query, try adjusting your search or filter criteria."
      />
    );
  }

  if (canCreateOrders) {
    const button: ButtonConfig = {
      linkTo: orderRoutes.orderNew.getUrl(),
      label: 'Create a new PO'
    };

    return (
      <NoResultsComponent
        button={button}
        headline="You don't have any orders"
        isCentered={false}
        tagline="You can create a new order by clicking on the create PO button below."
      />
    );
  }

  return <NoResultsComponent headline="You have no orders on Zencargo yet" isCentered={false} />;
};

export default NoResults;
