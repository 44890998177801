import { type FC, useState } from 'react';

import { cargoModeLabelMapping } from '@zen/Cargo';
import CargoModeSelect from '@zen/Components/CargoModeSelect';
import LabelledValue from '@zen/Components/LabelledValue';
import { Dialog } from '@zen/DesignSystem';
import { type CargoModeEnum, cargoModeLabel } from '@zen/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateCargoModeMutation } from './graphql';

interface Props {
  canManageCargo: boolean;
  cargoMode: Optional<CargoModeEnum>;
  onSuccess?: () => void;
  zencargoReference: string;
}

const CargoModeInlineField: FC<Props> = ({ canManageCargo, cargoMode, zencargoReference, onSuccess }) => {
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);
  const [nextCargoMode, setNextCargoMode] = useState<CargoModeEnum>();
  const { addSuccess, addError } = useNotification();
  const [updateCargoMode, { loading }] = useUpdateCargoModeMutation();

  const handleCloseConfirmationModal = (): void => setIsConfirmationModalVisible(false);

  const handleChange = async (mode: Nullable<CargoModeEnum>): Promise<void> => {
    if (!mode) return;

    setNextCargoMode(mode);
    setIsConfirmationModalVisible(true);
  };

  const changeCargoMode = async (): Promise<void> => {
    if (!nextCargoMode) return;

    setIsConfirmationModalVisible(false);

    await performMutation({
      mutationFn: () =>
        updateCargoMode({
          variables: {
            input: {
              cargoMode: nextCargoMode,
              zencargoReference
            }
          },
          refetchQueries: ['cargoSummary', 'cargoOverview'],
          awaitRefetchQueries: true
        }),
      onError: () => addError(),
      onSuccess: () => {
        onSuccess?.();
        addSuccess('Cargo mode updated.');
      }
    });
  };

  if (!canManageCargo) {
    return (
      <LabelledValue label={cargoModeLabel} variant="default">
        {cargoMode ? cargoModeLabelMapping[cargoMode] : '-'}
      </LabelledValue>
    );
  }

  return (
    <>
      <div className="-ml-3">
        <CargoModeSelect
          isDisabled={loading}
          isLoading={loading}
          label={cargoModeLabel}
          name="cargoMode"
          onChange={handleChange}
          value={cargoMode}
          variant="inline"
        />
      </div>
      <Dialog
        confirmLabel="Change cargo mode"
        header="Edit cargo mode"
        isOpen={isConfirmationModalVisible}
        message="Are you sure you want to change the cargo mode? This means that you will loose all your current cargo data."
        onClose={handleCloseConfirmationModal}
        onConfirm={changeCargoMode}
      />
    </>
  );
};

export default CargoModeInlineField;
