import type { SortInput } from '@zen/types';

import { useQueryParams } from '../QueryParams';

type UrlFilters<T extends {}, K extends string = string> = T & {
  groupBy?: K;
  hiddenColumns?: string[];
  search?: string;
  sortInput?: SortInput;
};

const useUrlFilters = <T extends {}, K extends string = string>(initialFilters?: Partial<UrlFilters<T, K>>) => {
  const { queryParams, setQueryParams, pushWithQueryParams } = useQueryParams<UrlFilters<T, K>>('filters', initialFilters);

  const { search, groupBy, hiddenColumns, sortInput, ...otherFilters } = queryParams;

  const setFilters = (filters: Partial<T>): void => {
    const searchValue = search ? { search } : {};
    const groupByValue = groupBy ? { groupBy } : {};

    setQueryParams({ ...filters, ...searchValue, ...groupByValue }, false);
  };

  const setSearch = (value: string | undefined): void => {
    setQueryParams({ ...(queryParams as T), search: value }, false);
  };

  const setGroupBy = (value: K | undefined): void => {
    setQueryParams({ ...(queryParams as T), groupBy: value }, false);
  };

  const setHiddenColumns = (value: string[] | undefined): void => {
    setQueryParams({ ...(queryParams as T), hiddenColumns: value }, false);
  };

  const setSortInput = (value: SortInput | undefined): void => {
    setQueryParams({ ...(queryParams as T), sortInput: value }, false);
  };

  const pushWithFilters = (path: string, filters: Partial<T>, includeExistingParams: boolean = true) => {
    pushWithQueryParams(path, filters, includeExistingParams);
  };

  const setSearchAndFilters = (values: {
    filters?: Partial<T>;
    groupBy?: K;
    hiddenColumns?: string[];
    searchTerm?: string;
    sortInput?: SortInput;
  }): void => {
    const searchValue = values.searchTerm ? { search: values.searchTerm } : {};
    const hiddenColumnsValue = values.hiddenColumns ? { hiddenColumns: values.hiddenColumns } : {};
    const sortInputValue = values.sortInput ? { sortInput: values.sortInput } : {};
    const groupByValue = values.groupBy ? { groupBy: values.groupBy } : {};

    setQueryParams(
      { ...(values.filters || ({} as T)), ...searchValue, ...hiddenColumnsValue, ...sortInputValue, ...groupByValue },
      false
    );
  };

  return {
    search: queryParams.search && String(queryParams.search),
    setSearch,
    groupBy,
    sortInput,
    setSortInput,
    setGroupBy,
    hiddenColumns,
    setHiddenColumns,
    filters: otherFilters,
    setFilters,
    pushWithFilters,
    setSearchAndFilters
  };
};

export default useUrlFilters;
