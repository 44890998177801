import cx from 'classnames';
import { isEmpty } from 'lodash';
import { Fragment, ReactNode, useState } from 'react';

import { Loading, SearchInput } from '@zen/DesignSystem';

import FilterFooter from '../FilterFooter';
import { getFilteredList } from './helpers';
import ListItem from './ListItem/ListItem';
import type { ListItemType } from './ListItem/types';
import type { OptionListSection } from './types';

interface Props<T = {}> {
  className?: string;
  error?: boolean;
  list: Array<OptionListSection<T>>;
  listClassName?: string;
  loading?: boolean;
  onClear?: () => void;
  onSearch?: (query: string) => void;
  renderItem?: (item: ListItemType & T) => ReactNode;
  searchPlaceholder?: string;
  searchable?: boolean;
  selection?: 'default' | 'checkbox' | 'menu';
}

const OptionList = <T,>(props: Props<T>) => {
  const {
    list,
    selection = 'default',
    searchable,
    className,
    listClassName,
    renderItem,
    loading = false,
    onSearch,
    onClear,
    error,
    searchPlaceholder = 'Search'
  } = props;
  const [search, setSearch] = useState<string>('');

  const searchValue = onSearch ? '' : search;
  const filteredList: OptionListSection<T>[] = getFilteredList(list, searchValue);
  const hasOptions: boolean = !!filteredList.length;

  const noResultMessage = () => {
    if (onSearch && isEmpty(search)) {
      return 'Start typing to see results';
    }

    return 'No results found';
  };

  const handleSearch = (query: string): void => {
    setSearch(query);
    onSearch?.(query);
  };

  const renderSearch = (): ReactNode => (
    <div className="p-2">
      <SearchInput autoFocus={true} onChange={handleSearch} placeholder={searchPlaceholder} value={search} />
    </div>
  );

  const classNames: string = cx('divide-y divide-solid divide-grey-lighter min-w-56 max-w-160', className);

  const renderListItem = (item: ListItemType & T, index: number): ReactNode => {
    if (renderItem) {
      return <Fragment key={index}>{renderItem(item)}</Fragment>;
    }

    return <ListItem key={index} item={item} selection={selection} />;
  };

  const renderList = () => {
    if (loading) {
      return (
        <div className="relative flex items-center justify-between px-4 py-3 text-grey-base" data-testid="section-title">
          <span>Loading...</span>
          <div className="absolute right-4">
            <Loading color="grey" size="smallest" />
          </div>
        </div>
      );
    }

    if (error) {
      return <div className="px-4 py-3 text-red-base">Failed to load results. Please refresh the page and try again</div>;
    }

    if (!hasOptions) {
      return <div className="px-4 py-3 text-grey-base">{noResultMessage()}</div>;
    }

    const listClassNames: string = cx('overflow-auto divide-y divide-solid divide-grey-lighter max-h-64', listClassName);

    return (
      <div className={listClassNames}>
        {filteredList.map((section, index) => {
          return (
            <div key={index} className="py-1" data-testid="option-list-section">
              {section.title && (
                <div className="w-full px-4 py-2 text-grey-base" data-testid="section-title">
                  {section.title}
                </div>
              )}
              {section.items.map(renderListItem)}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classNames} data-testid="option-list">
      {searchable && renderSearch()}
      {renderList()}
      {!loading && onClear && <FilterFooter onClear={onClear} />}
    </div>
  );
};

export default OptionList;
