import { cargoModeLabelMapping } from '@zen/Cargo';
import TransportModeIcon from '@zen/Components/TransportModeIcon';
import { ContextMenu, IconButton, MenuItemType, TableColumn, TableLink } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import { cargoModeLabel } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';

import type { RateCardSummary } from './types';

type RateCardsTableActions = {
  onClone?: (id: string) => void;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
  onExport?: (id: string) => void;
};

export const getRateCardsTableConfiguration = (
  isEditable: boolean,
  { onDelete, onEdit, onClone, onExport }: RateCardsTableActions = {}
): TableColumn<RateCardSummary>[] => {
  return [
    {
      key: 'modeOfTransport',
      title: 'Mode',
      render: (_, item: RateCardSummary) => (
        <div className="text-center">
          <TransportModeIcon mode={item.modeOfTransport} />
        </div>
      )
    },
    {
      key: 'name',
      title: 'Rate card ID',
      render: (_, item: RateCardSummary) => (
        <TableLink linkTo={rateCardRoutes.details.getUrl(item.rateCardId)}>{item.name}</TableLink>
      )
    },
    {
      key: 'cargoType',
      title: cargoModeLabel,
      sortable: false,
      render: (_, item: RateCardSummary) => cargoModeLabelMapping[item.cargoType]
    },
    {
      key: 'startDate',
      title: 'Start date',
      render: (_, item: RateCardSummary) => formatDate(item.startDate)
    },
    {
      key: 'endDate',
      title: 'Expiry date',
      render: (_, item: RateCardSummary) => formatDate(item.endDate)
    },
    {
      title: '',
      key: 'actions',
      render: (_: unknown, item: RateCardSummary) => {
        const handleExport = (): void => onExport?.(item.rateCardId);
        const allActions: MenuItemType[] = [
          { label: 'Edit', icon: 'zicon-edit', onClick: () => onEdit?.(item.rateCardId) },
          {
            label: 'Export',
            icon: 'zicon-download',
            onClick: handleExport
          },
          { label: 'Clone', icon: 'zicon-copy', onClick: () => onClone?.(item.rateCardId), addDivider: true },
          { label: 'Delete', icon: 'zicon-trash', onClick: () => onDelete?.(item.rateCardId) }
        ];

        if (!isEditable) {
          return <IconButton icon="zicon-download" onClick={handleExport} size="small" title="Export" variant="ghost" />;
        }

        return <ContextMenu inline={false} items={allActions} />;
      },
      sortable: false,
      width: 80
    }
  ];
};
