import './styles.css';

import cx from 'classnames';
import RCTable from 'rc-table';
import type { DefaultRecordType } from 'rc-table/lib/interface';
import { ReactElement, ReactNode, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import NoResults from '@zen/Components/NoResults';
import { HEADER_HEIGHT_IN_REM } from '@zen/Layout';
import type { PageInfo, SortInput } from '@zen/types';
import { createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';
import type { StringKeys, Undefinable } from '@zen/utils/typescript';

import { IconButton } from '../Button';
import {
  addExpandableAttributes,
  addExpandColumn,
  areColumnKeysUnique,
  buildColumnMap,
  calculateDropMarkerOffset,
  ColumnWidthMap,
  getRowSpanConfiguration,
  hasExpandableRow,
  isMultiBulkSelectionInColumns,
  mergeColumnWidths,
  reorderColumns
} from './helpers';
import { useColumnOrder } from './hooks/useColumnOrder';
import { useColumnWidths } from './hooks/useColumnWidths';
import { useTableScroll } from './hooks/useTableScroll';
import { generateLoadingAttributes } from './loading';
import TableCell, { Props as CellProps } from './TableCell';
import TableColumnActionIndicator from './TableColumnActionIndicator';
import TableFooter from './TableFooter';
import TableHeader from './TableHeader';
import TableHeaderCell, { Props as TableHeaderProps } from './TableHeaderCell';
import TableTotal from './TableTotal';
import type { DataWithRowId, RCTableColumn, TableColumn, TotalCountConfig } from './types';

type RowAttributes<T> = {
  disabled?: boolean;
  expanded?: boolean;
  nestedRowItems?: T[];
};

type TableRowData<T> = RowAttributes<T> | {};

interface Props<T extends TableRowData<K>, K extends {} = T> {
  actions?: ReactNode;
  additionalActions?: ReactNode;
  className?: string;
  columns: TableColumn<T>[];
  data: T[];
  emptyText?: string;
  error?: boolean;
  hiddenColumns?: string[];
  isDraggingEnabled?: boolean;
  loading?: boolean;
  onOrderChange?: (order: SortInput) => void;
  order?: SortInput;
  paginationInfo?: PageInfo;
  persistColumnWidths?: boolean;
  renderExpandedRow?: (record: T, index: number) => ReactNode;
  rowKey?: StringKeys<T>;
  stickyHeaderOffset?: number;
  tableId: string;
  title?: ReactNode;
  titleClassNames?: string;
  totalCount?: ReactNode;
  totalCountConfig?: TotalCountConfig;
}

const Table = <T extends TableRowData<K>, K extends {} = T>(props: Props<T, K>): ReactElement => {
  const {
    actions,
    additionalActions,
    className,
    columns: columnConfiguration,
    data,
    renderExpandedRow,
    emptyText = "We can't find any results",
    hiddenColumns = [],
    isDraggingEnabled = true,
    loading = false,
    totalCount = null,
    paginationInfo,
    onOrderChange,
    error,
    order,
    rowKey,
    stickyHeaderOffset = HEADER_HEIGHT_IN_REM,
    tableId,
    title,
    titleClassNames,
    totalCountConfig,
    persistColumnWidths
  } = props;
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>();
  const expandableTable = hasExpandableRow(data);
  const columns = expandableTable ? addExpandColumn(columnConfiguration) : columnConfiguration;

  // safeguard for bad column configuration where keys are not unique
  if (!areColumnKeysUnique(columns)) {
    throw new Error(`Each column key for table ${tableId} must be unique`);
  }

  if (isMultiBulkSelectionInColumns(columns)) {
    throw new Error('Table can contain only one column with bulk assignment enabled');
  }

  const handleExpandClick = (keys: readonly React.Key[]): void => {
    setExpandedRowKeys(keys as string[]);
  };

  const tableWrapperRef = useRef<HTMLDivElement>(null);
  const [isMarkerVisible, setMarkerVisible] = useState<boolean>(false);
  const [markerLeftOffset, setMarkerLeftOffset] = useState<number>(-1);
  const [pageScroll, setPageScroll] = useTableScroll(tableId);
  const [savedColumnWidths, setSavedColumnWidths] = useColumnWidths(tableId, persistColumnWidths);
  const [columnOrder, setColumnOrder] = useColumnOrder(
    tableId,
    columns.map(({ fixed, key }) => ({ fixed, key }))
  );
  const [widths, setWidths] = useState<ColumnWidthMap>(mergeColumnWidths(columns, savedColumnWidths));
  const columnMapping: Record<TableColumn['key'], TableColumn<T>> = useMemo(() => buildColumnMap<T>(columns), [columns]);
  const orderedColumns: TableColumn<T>[] = columnOrder.map((key: string) => columnMapping[key]);
  const visibleColumns: TableColumn<T>[] = orderedColumns.filter(({ key }) => !hiddenColumns.includes(key));

  const handleColumnDraggedOver = ({
    targetColumnKey,
    draggedColumnKey,
    targetCellElement
  }: {
    draggedColumnKey: string;
    targetCellElement: HTMLTableCellElement | undefined;
    targetColumnKey: string;
  }): void => {
    if (targetColumnKey === draggedColumnKey) {
      setMarkerVisible(false);

      return;
    }

    const markerOffset: number = calculateDropMarkerOffset({
      columnOrder,
      targetColumnKey,
      draggedColumnKey,
      targetCellElement
    });

    setMarkerLeftOffset(markerOffset);
    setMarkerVisible(true);
  };

  const handleColumnDragEnd = (): void => {
    setMarkerVisible(false);
  };

  const handleColumnDropped = ({
    targetColumnKey,
    draggedColumnKey
  }: {
    draggedColumnKey: string;
    targetColumnKey: string;
  }): void => {
    setMarkerVisible(false);
    if (targetColumnKey === draggedColumnKey) {
      return;
    }
    const newColumnOrder: string[] = reorderColumns({
      columnOrder,
      targetColumnKey,
      draggedColumnKey
    });

    setColumnOrder(newColumnOrder);
  };

  const handleResizeEnd = (key: string, width: number): void => {
    const newWidths: ColumnWidthMap = {
      ...widths,
      [key]: width
    };

    setWidths(newWidths);
    setSavedColumnWidths(newWidths);
  };
  const handleOrderChange = useCallback(
    (newOrder: SortInput) => {
      const wrap = tableWrapperRef.current?.querySelector('.zen-table-content');
      const tableScrollPosition = Number(wrap?.scrollLeft) || 0;

      setPageScroll(tableScrollPosition);
      if (onOrderChange && newOrder) {
        setTimeout(() => {
          onOrderChange(newOrder);
        }, 0);
      }
    },
    [setPageScroll, onOrderChange, tableWrapperRef]
  );

  const rowSpanConfiguration = getRowSpanConfiguration(data, columns);
  const enhancedColumns: RCTableColumn<DataWithRowId<T>>[] = visibleColumns.map((column: TableColumn<T>, index: number) => {
    const { key, dataKey, ellipsis, mergeBy, onEdit, sortKey, sortable = true, resizable = true, ...columnRest } = column;
    const dataIndex: string = dataKey || key;
    const sortByKey: string = sortKey || key;

    return {
      ...columnRest,
      render: (value: unknown, record: DataWithRowId<T>, i: number) => {
        const { render } = column;
        const { uniqueTableRowIdentifier, ...rest } = record;

        if (render) {
          return render(value, rest as T, i);
        }

        return value;
      },
      width: widths[key],
      key,
      dataIndex,
      resizable,
      ellipsis: resizable || ellipsis,
      onCell: (record: T, rowIndex: number): Partial<CellProps> => {
        return {
          columnConfiguration: {
            ...column,
            isPartOfMergeByColumn: !!mergeBy,
            onEdit: onEdit ? () => onEdit(key, record) : undefined,
            width: widths[key],
            rowSpans: rowSpanConfiguration?.[key]
          },
          rowIndex,
          tableId
        };
      },
      onHeaderCell: ({ alignment, fixed, width }: RCTableColumn<T>): Partial<TableHeaderProps> => {
        return {
          columnKey: key,
          draggable: !fixed && isDraggingEnabled,
          fixed: !!fixed,
          headerOffset: stickyHeaderOffset,
          offsetElementRef: tableWrapperRef,
          onColumnDropped: handleColumnDropped,
          onColumnDraggedOver: handleColumnDraggedOver,
          onColumnDragEnd: handleColumnDragEnd,
          onOrderChange: handleOrderChange,
          onResizeEnd: (resizedWidth: number) => handleResizeEnd(key, resizedWidth),
          order,
          resizable: index !== visibleColumns.length - 1 ? resizable : false,
          sortable,
          rightAligned: alignment === 'right',
          sortKey: sortByKey,
          tableId,
          width,
          loading
        };
      }
    };
  });

  const onRow = (record: T) => {
    const classNames = cx({
      disabled: (record as RowAttributes<K>).disabled,
      expanded: (record as RowAttributes<K>).expanded
    });

    return { className: classNames };
  };

  const renderExpandIcon = (parentRecordProps: DefaultRecordType): ReactNode => {
    const { record } = parentRecordProps;
    const expandableColumnName: Undefinable<string> = expandableTable ? 'nestedRowItems' : 'children';
    const hasExpandedContent: boolean = record[expandableColumnName]?.length > 0;

    const chevronDirection: 'down' | 'right' = parentRecordProps.expanded ? 'down' : 'right';

    if (!hasExpandedContent) {
      return null;
    }

    return (
      <div className="w-6">
        <IconButton
          icon={`zicon-chevron-${chevronDirection}`}
          onClick={() => parentRecordProps.onExpand(record)}
          size="medium"
          variant="ghost"
        />
      </div>
    );
  };

  useEffect(() => {
    if (!loading) {
      const heads = tableWrapperRef?.current?.querySelectorAll('th');

      if (heads) {
        let newWidth = {};

        visibleColumns.forEach(({ resizable = true, ...item }, i) => {
          if (resizable) {
            const width = heads[i].offsetWidth;

            newWidth = { ...newWidth, [item.key]: width };
          }
        });

        setSavedColumnWidths(newWidth);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loadingColumns, loadingData } = generateLoadingAttributes(enhancedColumns, 20);

  const tableData = loading ? (loadingData as T[]) : data;
  const preparedData = addExpandableAttributes(tableData, rowKey);

  useLayoutEffect(() => {
    if (tableWrapperRef) {
      const wrap = tableWrapperRef.current?.querySelector('.zen-table-content');

      if (pageScroll && wrap) {
        wrap.scrollLeft = pageScroll;
      }
    }
  }, [tableWrapperRef, pageScroll]);

  const getRowKeyId = (record: DataWithRowId<T, K>): string => {
    return record.uniqueTableRowIdentifier;
  };

  const tableTotals: ReactNode = totalCountConfig ? <TableTotal totalCountConfig={totalCountConfig} /> : totalCount;
  const showTableHeader: boolean = !!title || !!actions || !!additionalActions;
  const showTableFooter: boolean = Boolean(!!paginationInfo?.hasNextPage || !!paginationInfo?.hasPreviousPage || tableTotals);

  const wrapperClassNames: string = cx(
    'rounded border border-grey-lighter border-solid divide-y divide-solid divide-grey-lighter overflow-hidden',
    className,
    tableId
  );

  const table = () => {
    const tableColumns = loading ? loadingColumns : enhancedColumns;

    if (error) {
      return <NoResults headline="Something went wrong" isCentered={false} tagline="Please try again later." />;
    }

    return (
      <DndProvider backend={HTML5Backend}>
        <div ref={tableWrapperRef} className="relative" data-testid="table-wrapper">
          <RCTable
            columns={tableColumns}
            components={{
              header: {
                cell: TableHeaderCell
              },
              body: {
                cell: TableCell
              }
            }}
            data={preparedData as DataWithRowId<T, K>[]}
            data-component="table"
            data-testid="table"
            emptyText={<div className="text-center">{emptyText}</div>}
            expandable={{
              expandIcon: renderExpandIcon,
              expandedRowKeys,
              onExpandedRowsChange: handleExpandClick,
              expandedRowRender: renderExpandedRow,
              childrenColumnName: renderExpandedRow ? undefined : 'nestedRowItems'
            }}
            onRow={onRow}
            prefixCls="zen-table"
            rowKey={getRowKeyId}
            scroll={{ x: true }}
            sticky={false}
            {...createTrackingParentAttribute('table')}
            {...createTrackingLabelAttribute(tableId, { ignoreContent: true })}
          />
          <TableColumnActionIndicator leftOffset={markerLeftOffset} visible={isMarkerVisible} />
        </div>
      </DndProvider>
    );
  };

  return (
    <div className={wrapperClassNames}>
      {showTableHeader && <TableHeader actions={actions} classNames={titleClassNames} title={title} />}
      {additionalActions && (
        <div className="px-6 py-3" data-testid="table-additional-actions">
          {additionalActions}
        </div>
      )}
      {table()}
      {showTableFooter && <TableFooter paginationInfo={paginationInfo} totalCount={tableTotals} />}
    </div>
  );
};

export type { Props as TableProps };

export default Table;
