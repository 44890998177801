import './switcher.css';

import cx from 'classnames';
import type { FC, ReactElement, ReactNode, SyntheticEvent } from 'react';
import SwitchComponent from 'react-switch';

import { Color } from '@zen/Styleguide';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

interface Props {
  className?: string;
  isChecked: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (isChecked: boolean) => void;
}

export const displayName: string = 'Design System/Input/Switch';

const Switch: FC<Props> = ({ className, isDisabled, isChecked, label, name, onChange }) => {
  const { trackEvent } = useTracking();

  // NOTE: React Switch has wrongly typed the Synthetic event in handler as
  // `event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent`
  // whereas React's SyntheticEvent generic requires element and event arguments:
  // `interface SyntheticEvent<T = Element, E = Event> ...`
  const handleChange = (checked: boolean, event: unknown): void => {
    if (isDisabled) {
      return;
    }

    const trackingEvent = createTrackingEvent(displayName, 'click', event as SyntheticEvent);

    trackEvent(trackingEvent);

    onChange?.(!isChecked);
  };

  const wrapWithLabel = (children: ReactElement, containerClassName: string | undefined): ReactElement => {
    if (!label) {
      return (
        <span
          className="inline-block"
          data-component="switch"
          {...createTrackingParentAttribute('switch')}
          {...createTrackingLabelAttribute(name || 'switch')}
        >
          {children}
        </span>
      );
    }

    const labelClassNames: string = cx(
      {
        'cursor-not-allowed text-grey-base': isDisabled,
        'cursor-pointer text-grey-dark': !isDisabled
      },
      'inline-flex items-center text-sm',
      containerClassName
    );

    return (
      <label
        className={labelClassNames}
        data-component="switch"
        data-testid="switch-label-container"
        {...createTrackingParentAttribute('switch')}
        {...createTrackingLabelAttribute(name || 'switch')}
      >
        <span className={cx({ 'pointer-events-none': isDisabled }, 'h-4')}>{children}</span>
        <span className="ml-4">{label}</span>
      </label>
    );
  };

  const additionalAttrs = label ? {} : { 'data-component': 'switch' };

  return wrapWithLabel(
    <SwitchComponent
      boxShadow="0px 0px 4px rgba(0, 0, 0, 0.25)"
      checked={isChecked}
      checkedIcon={false}
      data-testid="switch"
      disabled={isDisabled}
      handleDiameter={14}
      height={16}
      id={name}
      name={name}
      offColor={isDisabled ? Color.GREY_LIGHTEST : Color.GREY_LIGHT}
      offHandleColor={isDisabled ? Color.GREY_LIGHT : Color.WHITE}
      onChange={handleChange}
      onColor={Color.AZURE_BASE}
      uncheckedIcon={false}
      width={32}
      {...additionalAttrs}
    />,
    className
  );
};

export type { Props as SwitchProps };

export default Switch;
