import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ExportAccrualsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ExportAccrualsMutationInput;
}>;

export type ExportAccrualsMutationResult = { __typename?: 'Mutation' } & {
  result?: GraphQLTypes.Maybe<
    { __typename?: 'ExportAccrualsMutationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>>;
    }
  >;
};

export const ExportAccrualsDocument = /* #__PURE__ */ gql`
  mutation exportAccruals($input: ExportAccrualsMutationInput!) {
    result: financeExportAccruals(input: $input) {
      errors {
        message
      }
    }
  }
`;

/**
 * __useExportAccrualsMutation__
 *
 * To run a mutation, you first call `useExportAccrualsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportAccrualsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportAccrualsMutation, { data, loading, error }] = useExportAccrualsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportAccrualsMutation(
  baseOptions?: Apollo.MutationHookOptions<ExportAccrualsMutationResult, ExportAccrualsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ExportAccrualsMutationResult, ExportAccrualsMutationVariables>(ExportAccrualsDocument, options);
}
export type ExportAccrualsMutationHookResult = ReturnType<typeof useExportAccrualsMutation>;
