import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingTradePartiesQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type GetBookingTradePartiesQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
              consignee?: GraphQLTypes.Maybe<
                | ({ __typename: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & { typename: 'Account' } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      typename: 'AccountLocation';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      typename: 'NetworksLegacyOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                          'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocLocation' } & {
                          address?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLegacyAddress' } & Pick<
                              GraphQLTypes.NetworksLegacyAddress,
                              'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                >;
                              }
                          >;
                        }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocContact,
                          'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & { typename: 'NetworksOrgLoc' } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'LegacyAddress' } & Pick<
                                GraphQLTypes.LegacyAddress,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      typename: 'NetworksTerminal';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
              >;
              consignor?: GraphQLTypes.Maybe<
                | ({ __typename: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & { typename: 'Account' } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      typename: 'AccountLocation';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      typename: 'NetworksLegacyOrgLoc';
                    } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                          'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocLocation' } & {
                          address?: GraphQLTypes.Maybe<
                            { __typename?: 'NetworksLegacyAddress' } & Pick<
                              GraphQLTypes.NetworksLegacyAddress,
                              'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                            > & {
                                country?: GraphQLTypes.Maybe<
                                  { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                >;
                              }
                          >;
                        }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLegacyOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksLegacyOrgLocContact,
                          'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & { typename: 'NetworksOrgLoc' } & {
                      organisation?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                          GraphQLTypes.NetworksOrgLocOrganisation,
                          'id' | 'name'
                        >
                      >;
                      location?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
                            address?: GraphQLTypes.Maybe<
                              { __typename?: 'LegacyAddress' } & Pick<
                                GraphQLTypes.LegacyAddress,
                                'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                              > & {
                                  country?: GraphQLTypes.Maybe<
                                    { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                  >;
                                }
                            >;
                          }
                      >;
                      contact?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksOrgLocContact' } & Pick<
                          GraphQLTypes.NetworksOrgLocContact,
                          'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                        >
                      >;
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
                | ({ __typename: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      typename: 'NetworksTerminal';
                    } & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                      >;
                    })
              >;
              forwarder?: GraphQLTypes.Maybe<
                | ({ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
                | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                      label?: GraphQLTypes.Maybe<
                        { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long'>
                      >;
                    })
              >;
              networksNotifiedParties?: GraphQLTypes.Maybe<
                Array<
                  { __typename?: 'NotifiedParty' } & Pick<GraphQLTypes.NotifiedParty, 'notifiedPartyId'> & {
                      assignable?: GraphQLTypes.Maybe<
                        | ({ __typename: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & { typename: 'Account' } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                              typename: 'AccountLocation';
                            } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename: 'NetworksLegacyOrgLoc' } & Pick<GraphQLTypes.NetworksLegacyOrgLoc, 'id'> & {
                              typename: 'NetworksLegacyOrgLoc';
                            } & {
                              organisation?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<
                                  GraphQLTypes.NetworksLegacyOrgLocOrganisation,
                                  'name'
                                >
                              >;
                              location?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLegacyOrgLocLocation' } & {
                                  address?: GraphQLTypes.Maybe<
                                    { __typename?: 'NetworksLegacyAddress' } & Pick<
                                      GraphQLTypes.NetworksLegacyAddress,
                                      'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                                    > & {
                                        country?: GraphQLTypes.Maybe<
                                          { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                        >;
                                      }
                                  >;
                                }
                              >;
                              contact?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLegacyOrgLocContact' } & Pick<
                                  GraphQLTypes.NetworksLegacyOrgLocContact,
                                  'firstName' | 'lastName' | 'email' | 'phoneNumber'
                                >
                              >;
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename: 'NetworksOrgLoc' } & Pick<GraphQLTypes.NetworksOrgLoc, 'id'> & {
                              typename: 'NetworksOrgLoc';
                            } & {
                              organisation?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLocOrganisation' } & Pick<
                                  GraphQLTypes.NetworksOrgLocOrganisation,
                                  'id' | 'name'
                                >
                              >;
                              location?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLocLocation' } & Pick<
                                  GraphQLTypes.NetworksOrgLocLocation,
                                  'id' | 'name'
                                > & {
                                    address?: GraphQLTypes.Maybe<
                                      { __typename?: 'LegacyAddress' } & Pick<
                                        GraphQLTypes.LegacyAddress,
                                        'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
                                      > & {
                                          country?: GraphQLTypes.Maybe<
                                            { __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>
                                          >;
                                        }
                                    >;
                                  }
                              >;
                              contact?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksOrgLocContact' } & Pick<
                                  GraphQLTypes.NetworksOrgLocContact,
                                  'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
                                >
                              >;
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                        | ({ __typename: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              typename: 'NetworksTerminal';
                            } & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>
                              >;
                            })
                      >;
                    }
                >
              >;
              canViewOperationsManager: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canSetOperationsManager: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewCommercialManager: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canSetCommercialManager: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canManageTradeParties: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
            }
        >
      >
    >;
  };
};

export type AssignableBookingTradePartyAccountFragment = { __typename: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
    typename: 'Account';
  } & {
    label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
  };

export type AssignableBookingTradePartyAccountLocationFragment = { __typename: 'AccountLocation' } & Pick<
  GraphQLTypes.AccountLocation,
  'id'
> & { typename: 'AccountLocation' } & {
    label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
  };

export type AssignableBookingTradePartyNetworksLegacyOrgLocFragment = { __typename: 'NetworksLegacyOrgLoc' } & Pick<
  GraphQLTypes.NetworksLegacyOrgLoc,
  'id'
> & { typename: 'NetworksLegacyOrgLoc' } & {
    organisation?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksLegacyOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksLegacyOrgLocOrganisation, 'name'>
    >;
    location?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksLegacyOrgLocLocation' } & {
        address?: GraphQLTypes.Maybe<
          { __typename?: 'NetworksLegacyAddress' } & Pick<
            GraphQLTypes.NetworksLegacyAddress,
            'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
          > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>> }
        >;
      }
    >;
    contact?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksLegacyOrgLocContact' } & Pick<
        GraphQLTypes.NetworksLegacyOrgLocContact,
        'firstName' | 'lastName' | 'email' | 'phoneNumber'
      >
    >;
    label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
  };

export type AssignableBookingTradePartyNetworksOrgLocFragment = { __typename: 'NetworksOrgLoc' } & Pick<
  GraphQLTypes.NetworksOrgLoc,
  'id'
> & { typename: 'NetworksOrgLoc' } & {
    organisation?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLocOrganisation' } & Pick<GraphQLTypes.NetworksOrgLocOrganisation, 'id' | 'name'>
    >;
    location?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLocLocation' } & Pick<GraphQLTypes.NetworksOrgLocLocation, 'id' | 'name'> & {
          address?: GraphQLTypes.Maybe<
            { __typename?: 'LegacyAddress' } & Pick<
              GraphQLTypes.LegacyAddress,
              'street' | 'city' | 'postalCodeOrZip' | 'countyOrState'
            > & { country?: GraphQLTypes.Maybe<{ __typename?: 'Country' } & Pick<GraphQLTypes.Country, 'name' | 'code'>> }
          >;
        }
    >;
    contact?: GraphQLTypes.Maybe<
      { __typename?: 'NetworksOrgLocContact' } & Pick<
        GraphQLTypes.NetworksOrgLocContact,
        'id' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'
      >
    >;
    label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
  };

export type AssignableBookingTradePartyNetworksTerminalFragment = { __typename: 'NetworksTerminal' } & Pick<
  GraphQLTypes.NetworksTerminal,
  'id'
> & { typename: 'NetworksTerminal' } & {
    label?: GraphQLTypes.Maybe<{ __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'short' | 'long'>>;
  };

export type AssignableBookingTradePartyFragment =
  | AssignableBookingTradePartyAccountFragment
  | AssignableBookingTradePartyAccountLocationFragment
  | AssignableBookingTradePartyNetworksLegacyOrgLocFragment
  | AssignableBookingTradePartyNetworksOrgLocFragment
  | AssignableBookingTradePartyNetworksTerminalFragment;

export const AssignableBookingTradePartyFragmentDoc = /* #__PURE__ */ gql`
  fragment AssignableBookingTradeParty on NetworksAssignableInterface {
    __typename
    typename: __typename
    id
    label {
      short
      long
    }
    ... on NetworksOrgLoc {
      typename: __typename
      organisation {
        id
        name
      }
      location {
        id
        name
        address {
          street
          city
          country {
            name
            code
          }
          postalCodeOrZip
          countyOrState
        }
      }
      contact {
        id
        firstName
        lastName
        email
        phoneNumber
      }
    }
    ... on NetworksLegacyOrgLoc {
      id
      typename: __typename
      organisation {
        name
      }
      location {
        address {
          street
          city
          country {
            name
            code
          }
          postalCodeOrZip
          countyOrState
        }
      }
      contact {
        firstName
        lastName
        email
        phoneNumber
      }
    }
  }
`;
export const GetBookingTradePartiesDocument = /* #__PURE__ */ gql`
  query getBookingTradeParties($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        zencargoReference
        consignee {
          ...AssignableBookingTradeParty
        }
        consignor {
          ...AssignableBookingTradeParty
        }
        forwarder {
          id
          label {
            long
          }
        }
        networksNotifiedParties {
          notifiedPartyId
          assignable {
            ...AssignableBookingTradeParty
          }
        }
        canViewOperationsManager {
          value
        }
        canSetOperationsManager {
          value
        }
        canViewCommercialManager {
          value
        }
        canSetCommercialManager {
          value
        }
        canManageTradeParties {
          value
        }
      }
    }
  }
  ${AssignableBookingTradePartyFragmentDoc}
`;

/**
 * __useGetBookingTradePartiesQuery__
 *
 * To run a query within a React component, call `useGetBookingTradePartiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingTradePartiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingTradePartiesQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useGetBookingTradePartiesQuery(
  baseOptions: Apollo.QueryHookOptions<GetBookingTradePartiesQueryResult, GetBookingTradePartiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingTradePartiesQueryResult, GetBookingTradePartiesQueryVariables>(
    GetBookingTradePartiesDocument,
    options
  );
}
export function useGetBookingTradePartiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingTradePartiesQueryResult, GetBookingTradePartiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingTradePartiesQueryResult, GetBookingTradePartiesQueryVariables>(
    GetBookingTradePartiesDocument,
    options
  );
}
export type GetBookingTradePartiesQueryHookResult = ReturnType<typeof useGetBookingTradePartiesQuery>;
export type GetBookingTradePartiesLazyQueryHookResult = ReturnType<typeof useGetBookingTradePartiesLazyQuery>;
