import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { useFeatureIsOn } from '@zen/FeatureFlag';

import SidebarLinks from '../components/SidebarLinks';
import type { SidebarCounters } from '../types';
import { getConfig } from './config';
import type { CustomerLinksFeatureFlags } from './types';

interface Props {
  counters: SidebarCounters;
}

const CustomerLinks: FC<Props> = ({ counters }) => {
  const { check } = useGlobalPermissions();
  const isCreateAccountFeatureEnabled: boolean = useFeatureIsOn('create-account');
  const isFreightSpendEnabled: boolean = useFeatureIsOn('freight-spend');
  const isLegacyRateCardsEnabled: boolean = useFeatureIsOn('legacy-rate-cards');
  const isUtilisationEnabled: boolean = useFeatureIsOn('utilisation');
  const role = useRole();
  const canViewAccountSettings: boolean = check('accounts.canViewAccountSettings');

  const featureFlags: CustomerLinksFeatureFlags = {
    isCreateAccountFeatureEnabled,
    isFreightSpendEnabled,
    isLegacyRateCardsEnabled,
    isUtilisationEnabled
  };

  const config = getConfig(role, featureFlags, { canViewAccountSettings });

  return <SidebarLinks config={config} counters={counters} />;
};

export default CustomerLinks;
