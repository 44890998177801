import type { OrderLotCargoReadyDate, OrderLotCargoReadyDateHistory } from '../types';

export const buildHistoryList = (cargoReadyDate: OrderLotCargoReadyDate): OrderLotCargoReadyDateHistory[] => {
  if (!(cargoReadyDate?.history && cargoReadyDate?.history?.length > 0)) {
    return [];
  }

  const currentCargoReadyState: OrderLotCargoReadyDateHistory[] = [
    { date: cargoReadyDate.date, reasonForChange: cargoReadyDate.reasonForChange }
  ];

  const fullHistory: OrderLotCargoReadyDateHistory[] = [
    ...cargoReadyDate.history.slice(1, cargoReadyDate.history.length),
    ...currentCargoReadyState
  ];

  return fullHistory.reverse();
};
