import { MultiSelect, Option, Select, SelectProps } from '@zen/DesignSystem';
import type { Nullable } from '@zen/utils/typescript';

import { useNetworkContactOptions } from './hooks/useNetworkContactOptions';

interface Props<T extends boolean> extends Omit<SelectProps<string>, 'options' | 'onChange' | 'value'> {
  accountId: string;
  isMulti: T;
  isSearchable?: boolean;
  onChange: T extends true ? (values: string[]) => void : (value: Nullable<string>) => void;
  value: T extends true ? string[] : Nullable<string>;
}

const NetworkContactSelect = <T extends boolean>(props: Props<T>) => {
  const { accountId, isMulti } = props;
  const networksOptions: Option<string>[] = useNetworkContactOptions(accountId);

  if (isMulti === true) {
    return <MultiSelect options={networksOptions} {...(props as Props<true>)} />;
  }

  return <Select options={networksOptions} {...(props as Props<false>)} />;
};

export default NetworkContactSelect;
