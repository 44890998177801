import cx from 'classnames';
import type { FC } from 'react';

interface Props {
  className?: string;
  textClass?: string;
  variant?: 'primary' | 'secondary';
}

const Message: FC<Props> = ({ children, className, variant = 'primary', textClass }) => {
  const classNames: string = cx(
    {
      'bg-grey-lighter rounded-tr-lg rounded-bl-lg': variant === 'primary',
      'bg-azure-light rounded-tl-lg rounded-br-lg': variant === 'secondary',
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [textClass]: textClass,
      [variant === 'primary' ? 'text-grey-dark' : 'text-black-base']: !textClass
    },
    'text-sm leading-normal py-3 px-4 mb-1',
    textClass,
    className
  );

  return <div className={classNames}>{children}</div>;
};

export default Message;
