import { lowerCase, upperFirst } from 'lodash';
import type { ReactNode } from 'react';

import LabelledValue from '@zen/Components/LabelledValue';
import { PriceCalculationSection } from '@zen/RateCards';
import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import type { CostTrackingSummaryBooking } from '../../types';
import { ModeOfTransport } from '../../types';
import SummaryRateCard from '../SummaryRateCard';
import {
  Applicability,
  type CostTrackingJourneyLeg,
  CostTrackingJourneyProperty,
  CostTrackingLocationType,
  JourneyLegTypeEnum,
  Terminal,
  VehiclePropertyNameEnum
} from './types';

interface SummaryRateCardProps {
  cargo: CostTrackingSummaryBooking['cargo'];
  destinationLocation: Nullable<Terminal>;
  isBookingConfirmed: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  originLocation: Nullable<Terminal>;
  zencargoReference: string;
}

export const getModeLocationName = (
  modeOfTransport: Optional<ModeOfTransport>,
  locationType: CostTrackingLocationType
): string => {
  const locationTypeName: string = lowerCase(locationType);

  switch (modeOfTransport) {
    case ModeOfTransport.AIR:
      return `${locationTypeName} airport`;
    case ModeOfTransport.RAIL:
      return `${locationTypeName} rail station`;
    case ModeOfTransport.OCEAN:
      return `port of ${locationTypeName}`;
    default:
      return locationTypeName;
  }
};

const getOceanLegs = (legs: CostTrackingJourneyLeg[]): CostTrackingJourneyLeg[] => {
  return legs.filter((leg: CostTrackingJourneyLeg) => leg.type === JourneyLegTypeEnum.CARRIAGE);
};

const getSCACProperty = (leg: CostTrackingJourneyLeg): Undefinable<CostTrackingJourneyProperty> => {
  return leg.vehicle.properties?.find((property: CostTrackingJourneyProperty) => property.name === VehiclePropertyNameEnum.SCAC);
};

export const getCarrier = (cargoJourneys: CostTrackingSummaryBooking['cargoJourneys']): string => {
  if (!cargoJourneys?.journeys[0]?.journey?.legs?.length) {
    return '';
  }

  const oceanLegs: CostTrackingJourneyLeg[] = getOceanLegs(cargoJourneys.journeys[0].journey.legs);
  const carrier: string = getSCACProperty(oceanLegs[0])?.value || '';

  return carrier;
};

export const getLocation = (
  cargoJourneys: CostTrackingSummaryBooking['cargoJourneys'],
  locationType: CostTrackingLocationType
): Nullable<Terminal> => {
  if (!cargoJourneys?.journeys[0]?.journey?.legs?.length) {
    return null;
  }

  const oceanLegs: CostTrackingJourneyLeg[] = getOceanLegs(cargoJourneys.journeys[0].journey.legs);
  const legIndex: number = locationType === Applicability.ORIGIN ? 0 : oceanLegs.length - 1;
  const legLocationKey: keyof CostTrackingJourneyLeg = locationType === Applicability.ORIGIN ? 'from' : 'to';

  const { terminal } = oceanLegs[legIndex][legLocationKey];

  return terminal || null;
};

export const renderLabelWithValue = (label: string, element: ReactNode): ReactNode => {
  return (
    <LabelledValue label={upperFirst(label)} variant="compact">
      {element || '-'}
    </LabelledValue>
  );
};

export const renderSummaryRateCard = (params: SummaryRateCardProps): ReactNode => {
  const { cargo, destinationLocation, isBookingConfirmed, modeOfTransport, originLocation, zencargoReference } = params;

  if (!cargo?.mode || !modeOfTransport) {
    return null;
  }

  return (
    <SummaryRateCard
      cargoType={cargo?.mode}
      className="mt-1"
      destinationPort={destinationLocation?.unlocode}
      isBookingConfirmed={isBookingConfirmed}
      modeOfTransport={modeOfTransport}
      originPort={originLocation?.unlocode}
      zencargoReference={zencargoReference}
    />
  );
};

export const renderPriceCalculation = (zencargoReference: string): ReactNode => (
  <div className="col-span-4">
    <div className="grid grid-cols-2 gap-x-4">
      <PriceCalculationSection zencargoReference={zencargoReference} />
    </div>
  </div>
);
