import type { Option } from '@zen/DesignSystem';

import { TruckSwapEnum } from './types';

export const truckSwapLabelMapping: Record<TruckSwapEnum, string> = {
  [TruckSwapEnum.TRUCK_SWAP]: 'Truck swap',
  [TruckSwapEnum.NOT_TRUCK_SWAP]: 'Not truck swap',
  [TruckSwapEnum.UNKNOWN]: 'Skip for now'
};

export const truckSwapOptions: Option<TruckSwapEnum>[] = [
  { value: TruckSwapEnum.TRUCK_SWAP, label: truckSwapLabelMapping[TruckSwapEnum.TRUCK_SWAP] },
  { value: TruckSwapEnum.NOT_TRUCK_SWAP, label: truckSwapLabelMapping[TruckSwapEnum.NOT_TRUCK_SWAP] },
  { value: TruckSwapEnum.UNKNOWN, label: truckSwapLabelMapping[TruckSwapEnum.UNKNOWN] }
];
