import { isEmpty, omitBy } from 'lodash';

import type { FlatShipment, Shipment, ShipmentFilters } from './types';

export const prepareFlatShipments = (shipments: Shipment[]): FlatShipment[] => {
  return shipments.map((shipment: Shipment) => {
    const {
      bookingStage,
      calculatedInfo,
      cargo,
      clientReference,
      customsOnly,
      delay,
      estimatedArrival,
      estimatedDeparture,
      estimatedCollection,
      estimatedDelivery,
      incoterms,
      modeOfTransport,
      consigneeLabel,
      consignorLabel,
      forwarder,
      forwarderReference,
      customer,
      networksDestination,
      networksOrigin,
      pinned,
      issues,
      stage,
      zencargoReference,
      updatedAt,
      actionItems,
      note,
      purchaseOrderReferences,
      voyageMilestone
    } = shipment;

    return {
      actionItems,
      modeOfTransport,
      cargoSummary: calculatedInfo?.cargoSummary,
      clientReference,
      customsOnly,
      zencargoReference,
      stage: stage || null,
      stageName: voyageMilestone?.name || bookingStage?.name,
      delay,
      issues,
      origin: networksOrigin?.label?.long || '',
      originShort: networksOrigin?.label?.short || '',
      destination: networksDestination?.label?.long || '',
      destinationShort: networksDestination?.label?.short || '',
      cargo,
      consignee: consigneeLabel || '',
      consignor: consignorLabel || '',
      forwarder: forwarder?.label?.short || '',
      forwarderReference: forwarderReference || '',
      customer,
      incoterms: incoterms?.value,
      pinned,
      purchaseOrderReferences,
      estimatedArrival,
      estimatedDeparture,
      estimatedCollection,
      estimatedDelivery,
      updatedAt: updatedAt?.dateTime || '',
      note
    };
  });
};

interface ShipmentFiltersTrackingPayload {
  appliedFilters: Partial<ShipmentFilters>;
  nameOfAppliedFilters: string[];
}

export const buildFiltersTrackingPayload = (filters: ShipmentFilters): ShipmentFiltersTrackingPayload => {
  const appliedFiltersWithValues = omitBy(filters, isEmpty) as Partial<ShipmentFilters>;

  // BigQuery handles nested objects by creating a column dynamically for each eventual key-value
  // so we send the names of the appliedFilters separately to save on lots of complicated SQL
  return {
    nameOfAppliedFilters: Object.keys(appliedFiltersWithValues),
    appliedFilters: appliedFiltersWithValues
  };
};
