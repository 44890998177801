import cx from 'classnames';
import type { FC, ReactNode } from 'react';
import { useWindowSize } from 'react-use';

import type { ModeOfTransport } from '@zen/Booking';
import { CargoModeEnum, CoreCargo, DeleteCargoButton, getCargoDetails } from '@zen/Cargo';
import LabelledValue from '@zen/Components/LabelledValue';
import { IconButton } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { getDeliveryStopTimeZone } from '@zen/Journey';
import GateInEmptyPicker from '@zen/Journey/components/GateInEmptyPicker';
import { JourneyShippingMilestone, JourneyShippingMilestoneNameEnum } from '@zen/Journey/types';
import { Breakpoint } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

import type { CargoSummary } from '../../types';
import EditCargo from '../EditCargo';
import { cargoConfiguration } from './helpers';

interface Props {
  canManageCargo: boolean;
  cargo: CoreCargo;
  cargoMode?: Optional<CargoModeEnum>;
  cargoSummary: Optional<CargoSummary>;
  isCompactView?: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

const CargoDetails: FC<Props> = (props) => {
  const { canManageCargo, cargo, cargoMode, cargoSummary, isCompactView = true, modeOfTransport, zencargoReference } = props;
  const { width } = useWindowSize();
  const gateInOutEmptyEnabled: boolean = useFeatureIsOn('gate-in-out-empty');
  const fields = getCargoDetails(cargo, cargoMode, cargoSummary, modeOfTransport, cargoConfiguration);

  const deliveryStopTimeZone: Optional<string> = getDeliveryStopTimeZone(cargo.journey?.legs || []);
  const gateInEmptyMilestone: Optional<JourneyShippingMilestone> = cargo?.journey?.postLegsShippingMilestones?.find(
    (milestone) => milestone.name === JourneyShippingMilestoneNameEnum.GATE_IN_EMPTY
  );

  const wrapperClassNames: string = cx(
    {
      'border-b 2xl:border-b-0 2xl:border-l border-solid border-grey-lighter': isCompactView,
      'w-1/3 shrink-0': width >= Breakpoint.XXL && isCompactView,
      'flex-1': !isCompactView
    },
    'flex-flex-col space-y-4 p-6'
  );

  const classNames: string = cx(
    {
      'grid-cols-3': !isCompactView || width < Breakpoint.XXL
    },
    'grid gap-y-3 gap-x-4'
  );

  const renderField = (item: { label: Optional<string>; value: ReactNode }, index: number): ReactNode => {
    const { label, value } = item;

    if (!label) {
      const fieldClassName: string = cx({ 'mt-3': !isCompactView });

      return (
        value && (
          <div key={index} className={fieldClassName}>
            {value}
          </div>
        )
      );
    }

    return (
      <LabelledValue key={index} label={label} variant="default">
        {value || '-'}
      </LabelledValue>
    );
  };

  return (
    <div className={wrapperClassNames}>
      {canManageCargo && (
        <div className="flex justify-end space-x-4">
          {cargoMode && modeOfTransport && (
            <EditCargo
              cargo={cargo}
              cargoMode={cargoMode}
              modeOfTransport={modeOfTransport}
              zencargoReference={zencargoReference}
            >
              {(openModal) => (
                <IconButton icon="zicon-edit" onClick={openModal} size="medium" title="Edit cargo" variant="secondary" />
              )}
            </EditCargo>
          )}
          <DeleteCargoButton cargoId={cargo.id} cargoType={cargo.cargoType} />
        </div>
      )}
      <div className={classNames}>{fields?.map(renderField)}</div>
      {gateInEmptyMilestone && gateInOutEmptyEnabled && (
        <div className="pt-4 border-t border-solid border-grey-lighter">
          <GateInEmptyPicker milestone={gateInEmptyMilestone} timeZone={deliveryStopTimeZone} />
        </div>
      )}
    </div>
  );
};

export default CargoDetails;
