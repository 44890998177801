import { PageSize } from '@zen/types';
import { usePagination } from '@zen/utils/hooks/pagination';
import type { Nullable } from '@zen/utils/typescript';

import { type MilestoneDateLogQueryResult, type MilestoneDateLogQueryVariables, useMilestoneDateLogQuery } from '../graphql';
import type { JourneyMilestoneDateLog } from '../types';

const useMilestoneDateHistory = (milestoneId: Nullable<string>) => {
  return usePagination<MilestoneDateLogQueryResult, MilestoneDateLogQueryVariables, JourneyMilestoneDateLog>(
    useMilestoneDateLogQuery,
    'journeyMilestoneDateLog',
    {
      milestoneId: milestoneId || ''
    },
    PageSize.TWENTY,
    { skip: !milestoneId }
  );
};

export default useMilestoneDateHistory;
