import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import { ForwarderInlineField } from '@zen/Booking/BookingDetails';
import SkeletonLoading from '@zen/Components/SkeletonLoading';

import QueryHandler from '../Components/QueryHandler';
import BookingCoordinators from './BookingCoordinators';
import type { BookingCoordinatorsPolicies } from './BookingCoordinators/BookingCoordinators';
import ConsignorConsigneeFields from './ConsignorConsigneeFields';
import { useGetBookingTradePartiesQuery } from './graphql';
import NotifiedPartiesFields from './NotifiedPartiesFields';
import ManageOriginAgent from './OriginAgent/ManageOriginAgent';
import type { BookingTradeParties } from './types';

interface Props {
  accountUuid: string;
  canViewForwarder: boolean;
  zencargoReference: string;
}

const TradeParties: FC<Props> = (props) => {
  const { accountUuid, canViewForwarder, zencargoReference } = props;
  const { data, error, loading } = useGetBookingTradePartiesQuery({
    variables: { zencargoReference },
    fetchPolicy: 'no-cache'
  });

  return (
    <div className="trade-parties">
      <QueryHandler
        data={data?.bookings.nodes?.[0]}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonLoading className="my-5" height={50} />}
      >
        {(bookingTradePartiesPolicies: BookingTradeParties) => {
          const canManageTradeParties: boolean = checkPermission<BookingTradeParties>(
            bookingTradePartiesPolicies,
            'canManageTradeParties'
          );
          const bookingCoordinatorsPolicies: BookingCoordinatorsPolicies = {
            canSetCustomerCoordinator: checkPermission<BookingTradeParties>(
              bookingTradePartiesPolicies,
              'canSetCommercialManager'
            ),
            canSetOperationsCoordinator: checkPermission<BookingTradeParties>(
              bookingTradePartiesPolicies,
              'canSetOperationsManager'
            ),
            canViewCustomerCoordinator: checkPermission<BookingTradeParties>(
              bookingTradePartiesPolicies,
              'canViewCommercialManager'
            ),
            canViewOperationsCoordinator: checkPermission<BookingTradeParties>(
              bookingTradePartiesPolicies,
              'canViewOperationsManager'
            )
          };

          return (
            <div className="grid grid-cols-2 gap-5">
              <ConsignorConsigneeFields
                accountUuid={accountUuid}
                canManageTradeParties={canManageTradeParties}
                data={bookingTradePartiesPolicies}
                zencargoReference={zencargoReference}
              />
              <ManageOriginAgent zencargoReference={zencargoReference} />
              <BookingCoordinators permissions={bookingCoordinatorsPolicies} zencargoReference={zencargoReference} />
              <NotifiedPartiesFields
                accountUuid={accountUuid}
                canManageTradeParties={canManageTradeParties}
                data={bookingTradePartiesPolicies}
                zencargoReference={zencargoReference}
              />
              {canViewForwarder && (
                <ForwarderInlineField forwarder={bookingTradePartiesPolicies?.forwarder} zencargoReference={zencargoReference} />
              )}
            </div>
          );
        }}
      </QueryHandler>
    </div>
  );
};

export default TradeParties;
