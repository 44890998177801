import type { FC } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@zen/DesignSystem';
import { packingListsRoutes } from '@zen/Routes';

interface Props {
  zencargoReference: string;
}

const PackingListsButton: FC<Props> = ({ zencargoReference }) => {
  return (
    <Link to={packingListsRoutes.packingListIndex.getUrl(zencargoReference)}>
      <Button iconLeft="zicon-packing-list" variant="ghost">
        View packing lists
      </Button>
    </Link>
  );
};

export default PackingListsButton;
