import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateCostTrackingChargeItemCurrencyMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateCostTrackingItemCurrencyInput;
}>;

export type UpdateCostTrackingChargeItemCurrencyMutationResult = { __typename?: 'Mutation' } & {
  updateCostTrackingItemCurrency?: GraphQLTypes.Maybe<
    { __typename?: 'CostTrackingPayload' } & {
      costTracking?: GraphQLTypes.Maybe<
        { __typename?: 'CostTracking' } & Pick<GraphQLTypes.CostTracking, 'costTrackingId' | 'zencargoReference'> & {
            charges?: GraphQLTypes.Maybe<
              Array<
                { __typename?: 'CostTrackingChargePayload' } & Pick<
                  GraphQLTypes.CostTrackingChargePayload,
                  'applicability' | 'id'
                > & {
                    chargeType: { __typename?: 'ChargesPayload' } & Pick<
                      GraphQLTypes.ChargesPayload,
                      'basisGroup' | 'id' | 'name'
                    > & { group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'> };
                    costItems?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'CostTrackingCostItem' } & Pick<
                          GraphQLTypes.CostTrackingCostItem,
                          | 'currency'
                          | 'id'
                          | 'name'
                          | 'note'
                          | 'paidTo'
                          | 'paidFrom'
                          | 'quantity'
                          | 'status'
                          | 'total'
                          | 'unitPrice'
                        >
                      >
                    >;
                    revenueItems?: GraphQLTypes.Maybe<
                      Array<
                        { __typename?: 'CostTrackingRevenueItem' } & Pick<
                          GraphQLTypes.CostTrackingRevenueItem,
                          | 'currency'
                          | 'id'
                          | 'name'
                          | 'note'
                          | 'paidTo'
                          | 'paidFrom'
                          | 'quantity'
                          | 'status'
                          | 'total'
                          | 'unitPrice'
                        >
                      >
                    >;
                  }
              >
            >;
            totals: { __typename?: 'CostTrackingTotals' } & Pick<
              GraphQLTypes.CostTrackingTotals,
              | 'displayCurrency'
              | 'profit'
              | 'margin'
              | 'thresholdDate'
              | 'totalCostInDisplayCurrency'
              | 'totalRevenueInDisplayCurrency'
            >;
          }
      >;
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const UpdateCostTrackingChargeItemCurrencyDocument = /* #__PURE__ */ gql`
  mutation updateCostTrackingChargeItemCurrency($input: UpdateCostTrackingItemCurrencyInput!) {
    updateCostTrackingItemCurrency(input: $input) {
      costTracking {
        charges {
          applicability
          chargeType {
            basisGroup
            group {
              id
              name
            }
            id
            name
          }
          id
          costItems {
            currency
            id
            name
            note
            paidTo
            paidFrom
            quantity
            status
            total
            unitPrice
          }
          revenueItems {
            currency
            id
            name
            note
            paidTo
            paidFrom
            quantity
            status
            total
            unitPrice
          }
        }
        costTrackingId
        totals {
          displayCurrency
          profit
          margin
          thresholdDate
          totalCostInDisplayCurrency
          totalRevenueInDisplayCurrency
        }
        zencargoReference
      }
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useUpdateCostTrackingChargeItemCurrencyMutation__
 *
 * To run a mutation, you first call `useUpdateCostTrackingChargeItemCurrencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostTrackingChargeItemCurrencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostTrackingChargeItemCurrencyMutation, { data, loading, error }] = useUpdateCostTrackingChargeItemCurrencyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostTrackingChargeItemCurrencyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCostTrackingChargeItemCurrencyMutationResult,
    UpdateCostTrackingChargeItemCurrencyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<
    UpdateCostTrackingChargeItemCurrencyMutationResult,
    UpdateCostTrackingChargeItemCurrencyMutationVariables
  >(UpdateCostTrackingChargeItemCurrencyDocument, options);
}
export type UpdateCostTrackingChargeItemCurrencyMutationHookResult = ReturnType<
  typeof useUpdateCostTrackingChargeItemCurrencyMutation
>;
