import { FC, ReactNode, useState } from 'react';

import { FormButtons } from '@zen/Components/Form';
import FormLabel from '@zen/Components/Form/FormLabel';
import { Button, DropZone, Loading, TextInput } from '@zen/DesignSystem';
import type { UploadCommodityCodesResult } from '@zen/graphql/types.generated';
import { UploadedFileData, useDirectUploads } from '@zen/utils/hooks/useDirectUploads';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable } from '@zen/utils/typescript';

import { useUploadCommodityCodesMutation } from '../graphql';
import type { UploadCommodityCodesMutationResult } from './types';

interface Props {
  onCancel: () => void;
  onComplete: () => void;
}

const UploadCommodityCodes: FC<Props> = ({ onCancel, onComplete }) => {
  const [isUploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<Nullable<File>>(null);
  const { addSuccess, addError } = useNotification();
  const uploadFile = useDirectUploads();
  const [uploadCommodityCodesMutation] = useUploadCommodityCodesMutation();

  const handleDrop = (files: File[]): void => {
    if (files.length > 1) {
      addError('Please upload a single CSV file.');

      return;
    }
    setFile(files[0]);
  };

  const handleUpload = async (): Promise<void> => {
    if (file) {
      setUploading(true);
      const { signedBlobId }: UploadedFileData = await uploadFile({
        file,
        onError: () => {
          addError();
          setUploading(false);
          setFile(null);
        }
      });

      const { ok: uploadCommodityCodesResult, error } = await performMutation({
        mutationFn: () => uploadCommodityCodesMutation({ variables: { input: { signedBlobId } } }),
        onError: () => {
          addError('There was a problem uploading your file. Please try again.');
          setUploading(false);
        }
      });

      if (uploadCommodityCodesResult && !error) {
        const mutationResponse = uploadCommodityCodesResult as UploadCommodityCodesMutationResult;

        if (mutationResponse) {
          const { updated, created } = mutationResponse.commodityCodesUploadCommodityCodes?.result as UploadCommodityCodesResult;

          addSuccess(`${updated} codes(s) updated and ${created} codes(s) created.`);
        }

        onComplete();
        onCancel();
      }
    }
  };

  const renderCSVLabel = (): ReactNode => {
    if (!file) {
      return null;
    }

    return (
      <>
        <FormLabel label="Document name" />
        <TextInput className="w-1/4" locked={true} value={file.name} />
      </>
    );
  };

  const renderButtons = (): ReactNode => {
    const isDisabled: boolean = !file || isUploading;

    return (
      <FormButtons isDisabled={isDisabled} isSubmitting={isUploading} layout="fixed" onClick={handleUpload} text="Upload codes">
        <Button data-testid="cancel-btn" onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <div className="w-160">
      <p>
        The first row of the CSV file should be a header row, there should be two columns within this titled; ‘code’ and
        ‘description’.
      </p>
      <p className="mt-5">The import will:</p>
      <ul className="mt-3">
        <li>&#183; Reject records where the code or description has blank values </li>
        <li>&#183; Attempts an update if a record of a duplicate code is found else it create a new record for that code</li>
      </ul>
      <div className="my-4">
        {!file && <DropZone onDrop={handleDrop} />}
        {renderCSVLabel()}
        {isUploading && <Loading />}
        {renderButtons()}
      </div>
    </div>
  );
};

export default UploadCommodityCodes;
