import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentsCountQueryVariables = GraphQLTypes.Exact<{
  customerUuid?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type ShipmentsCountQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & Pick<GraphQLTypes.BookingConnection, 'totalCount'>;
};

export const ShipmentsCountDocument = /* #__PURE__ */ gql`
  query shipmentsCount($customerUuid: String) {
    bookings(customerUuid: $customerUuid) {
      totalCount
    }
  }
`;

/**
 * __useShipmentsCountQuery__
 *
 * To run a query within a React component, call `useShipmentsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsCountQuery({
 *   variables: {
 *      customerUuid: // value for 'customerUuid'
 *   },
 * });
 */
export function useShipmentsCountQuery(
  baseOptions?: Apollo.QueryHookOptions<ShipmentsCountQueryResult, ShipmentsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentsCountQueryResult, ShipmentsCountQueryVariables>(ShipmentsCountDocument, options);
}
export function useShipmentsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsCountQueryResult, ShipmentsCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentsCountQueryResult, ShipmentsCountQueryVariables>(ShipmentsCountDocument, options);
}
export type ShipmentsCountQueryHookResult = ReturnType<typeof useShipmentsCountQuery>;
export type ShipmentsCountLazyQueryHookResult = ReturnType<typeof useShipmentsCountLazyQuery>;
