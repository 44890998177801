import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetLocationCompaniesQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type GetLocationCompaniesQueryResult = { __typename?: 'Query' } & Pick<GraphQLTypes.Query, 'accountCompanies'>;

export const GetLocationCompaniesDocument = /* #__PURE__ */ gql`
  query getLocationCompanies($accountId: String!) {
    accountCompanies(accountId: $accountId)
  }
`;

/**
 * __useGetLocationCompaniesQuery__
 *
 * To run a query within a React component, call `useGetLocationCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationCompaniesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useGetLocationCompaniesQuery(
  baseOptions: Apollo.QueryHookOptions<GetLocationCompaniesQueryResult, GetLocationCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetLocationCompaniesQueryResult, GetLocationCompaniesQueryVariables>(
    GetLocationCompaniesDocument,
    options
  );
}
export function useGetLocationCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationCompaniesQueryResult, GetLocationCompaniesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetLocationCompaniesQueryResult, GetLocationCompaniesQueryVariables>(
    GetLocationCompaniesDocument,
    options
  );
}
export type GetLocationCompaniesQueryHookResult = ReturnType<typeof useGetLocationCompaniesQuery>;
export type GetLocationCompaniesLazyQueryHookResult = ReturnType<typeof useGetLocationCompaniesLazyQuery>;
