import type { FC } from 'react';

import { Icon, TextLink } from '@zen/DesignSystem';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { consolidationRoutes } from '@zen/Routes';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  id: Optional<string>;
  reference: Optional<string>;
}

const ConsolidatedShipment: FC<Props> = ({ id, reference }) => {
  const consolidation = useFeatureIsOn('consolidation');

  if (!consolidation || !id) {
    return null;
  }

  return (
    <div className="flex items-center text-sm font-normal text-navy-base">
      <Icon className="mr-2" icon="zicon-pallet" />
      <TextLink linkTo={consolidationRoutes.consolidationShipments.getUrl(id)}>Consolidated shipment {reference}</TextLink>
    </div>
  );
};

export default ConsolidatedShipment;
