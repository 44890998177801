import { useContext, useMemo } from 'react';

import type { UserProfile } from '@zen/Auth/UserDataProvider/types';
import UserContext from '@zen/Auth/UserDataProvider/UserContext';
import type { AccountStatus } from '@zen/graphql/types.generated';

import type { Optional } from '../typescript';

interface Account {
  accountStatus: Optional<AccountStatus>;
  accountUuid: string;
  referencePrefix: Optional<string>;
  refetchUser: () => void;
  userProfile: UserProfile;
}

const useAccount = (): Account => {
  const { accountUuid, accountStatus, referencePrefix, userProfile, refetchUser } = useContext(UserContext);

  return useMemo(
    () => ({ accountUuid, accountStatus, referencePrefix, userProfile, refetchUser }),
    [accountUuid, accountStatus, referencePrefix, userProfile, refetchUser]
  );
};

export default useAccount;
