import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type NetworksAssignMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.AssignInput;
}>;

export type NetworksAssignMutationResult = { __typename?: 'Mutation' } & {
  networksAssign?: GraphQLTypes.Maybe<
    { __typename?: 'AssignPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const NetworksAssignDocument = /* #__PURE__ */ gql`
  mutation networksAssign($input: AssignInput!) {
    networksAssign(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useNetworksAssignMutation__
 *
 * To run a mutation, you first call `useNetworksAssignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNetworksAssignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [networksAssignMutation, { data, loading, error }] = useNetworksAssignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNetworksAssignMutation(
  baseOptions?: Apollo.MutationHookOptions<NetworksAssignMutationResult, NetworksAssignMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<NetworksAssignMutationResult, NetworksAssignMutationVariables>(NetworksAssignDocument, options);
}
export type NetworksAssignMutationHookResult = ReturnType<typeof useNetworksAssignMutation>;
