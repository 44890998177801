import { createCSVFile, downloadFile } from '@zen/utils';

import type { HaulageChargePayload } from '../RateCardForm/reducer/types';
import type { RateCard } from '../types';
import { prepareFreightChargeData } from './freightCharge/prepareFreightChargeData';
import { prepareHaulageChargeData } from './haulageCharge/prepareHaulageChargeData';
import { enhanceRowsWithOverviewData } from './overview/overviewData';
import { preparePortChargeData } from './portCharge/preparePortChargeData';
import { rateCardColumnHeaders } from './utils';

export const exportRateCardData = (rateCard: RateCard): void => {
  const data: string[][] = getRateCardData(rateCard);

  const csv: Blob = createCSVFile([...rateCardColumnHeaders], data);

  downloadFile(rateCard.name || 'ratecard', csv);
};

export const getRateCardData = (rateCard: RateCard): string[][] => {
  const { freightCharges, originCharges, destinationCharges, originHaulageCharges, destinationHaulageCharges } = rateCard;
  const freightChargeData: string[][] = prepareFreightChargeData(freightCharges);
  const originHaulageChargesData: string[][] = prepareHaulageChargeData(
    (originHaulageCharges || []) as HaulageChargePayload[],
    'origin'
  );
  const destinationHaulageChargesData: string[][] = prepareHaulageChargeData(
    (destinationHaulageCharges || []) as HaulageChargePayload[],
    'destination'
  );
  const originPortCharges: string[][] = preparePortChargeData(originCharges || [], 'origin');
  const destinationPortCharges: string[][] = preparePortChargeData(destinationCharges || [], 'destination');

  const rows: string[][] = [
    ...freightChargeData,
    ...originHaulageChargesData,
    ...originPortCharges,
    ...destinationPortCharges,
    ...destinationHaulageChargesData
  ];

  return enhanceRowsWithOverviewData(rows, rateCard);
};
