import type { FC } from 'react';

import AccountFormWrapper from '@zen/Accounts/components/forms/AccountFormWrapper';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performFormMutation } from '@zen/utils/performMutation';

import LegalInformationFields from '../../components/forms/LegalInformationFields';
import type { UpdateAccountProps } from '../../components/forms/types';
import { updateAccountLegalDetailsValidation } from '../CreateAccountForm/createAccountForm.validation';
import { type AccountLegalInformationInput, useUpdateAccountLegalDetailsMutation } from '../graphql';
import type { UpdateAccountLegalDetailsInput } from '../types';

type Props = UpdateAccountProps<AccountLegalInformationInput>;

const UpdateLegalInformation: FC<Props> = ({ onCancel, onSuccess, initialValues }) => {
  const [updateContactDetails] = useUpdateAccountLegalDetailsMutation({ refetchQueries: ['getAccountDetails'] });
  const { addError } = useNotification();

  const handleUpdateAccount = async (values: UpdateAccountLegalDetailsInput): Promise<IOkOrErrorResult> => {
    return performFormMutation({
      mutationFn: () =>
        updateContactDetails({
          variables: {
            input: values
          }
        }),
      onError: () => addError()
    });
  };

  const { accountId, ...legalDetails } = initialValues;

  return (
    <AccountFormWrapper<AccountLegalInformationInput>
      initialValues={legalDetails}
      onCancel={onCancel}
      onSubmit={(values: AccountLegalInformationInput) => {
        const mappedValues: UpdateAccountLegalDetailsInput = {
          accountId,
          legalInformation: values
        };

        return handleUpdateAccount(mappedValues);
      }}
      onSuccess={onSuccess}
      validationSchema={updateAccountLegalDetailsValidation}
    >
      {() => <LegalInformationFields />}
    </AccountFormWrapper>
  );
};

export default UpdateLegalInformation;
