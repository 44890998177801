import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { Activity } from '@zen/ActivityFeed/types';
import { useAcceptQuoteOptionMutation, useRejectQuoteOptionMutation } from '@zen/Quotes/graphql';
import { QuoteOption, QuoteOptionState } from '@zen/Quotes/types';
import { shipmentRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

import QuoteOptionItem from './QuoteOptionItem';

interface Props {
  activityMetadata: Activity;
  quoteOption: QuoteOption;
}

const QuoteOptionItemContainer: FC<Props> = ({ activityMetadata, quoteOption }) => {
  const { createdAt, legacyUser } = activityMetadata;

  const { addError } = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const [acceptQuote] = useAcceptQuoteOptionMutation();
  const [rejectQuote] = useRejectQuoteOptionMutation();

  const handleSuccess = (): void => {
    if (!location.pathname.includes('shipment')) {
      const url: string = shipmentRoutes.shipmentDetailsPage.getUrl(quoteOption.zencargoReference);

      navigate(url);
    }
  };

  const handleApprove = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        acceptQuote({
          variables: {
            input: {
              quoteOptionId: quoteOption.id as string
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => handleSuccess()
    });
  };

  const handleReject = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        rejectQuote({
          variables: {
            input: {
              quoteOptionId: quoteOption.id as string
            }
          }
        }),
      onError: () => addError()
    });
  };

  // use active state when quote opt has been rejected or accepted (displaying other components)
  const excludedStates: QuoteOptionState[] = [QuoteOptionState.ACCEPTED, QuoteOptionState.REJECTED];

  const isQuoteOptionStateExcluded = quoteOption?.state && excludedStates.includes(quoteOption.state);

  const showButtons: boolean = quoteOption.state ? !isQuoteOptionStateExcluded : true;
  const quoteOptionState: Optional<QuoteOptionState> = isQuoteOptionStateExcluded ? QuoteOptionState.ACTIVE : quoteOption.state;

  return (
    <QuoteOptionItem
      activityDate={createdAt}
      onApprove={handleApprove}
      onReject={handleReject}
      quoteOption={{
        ...quoteOption,
        state: quoteOptionState
      }}
      showButtons={showButtons}
      user={legacyUser}
    />
  );
};

export default QuoteOptionItemContainer;
