import { uniqBy } from 'lodash';

import type { FilterItemType } from '@zen/Components/Filters/types';
import type { Terminal } from '@zen/Components/TerminalSelect';
import type { Option } from '@zen/DesignSystem';
import type { NetworksOrgLoc, NetworksOrgLocOption } from '@zen/Networks';
import { prepareFreightPorts } from '@zen/RateCards/LegacyRateCards/helpers';
import type { RateCard } from '@zen/RateCards/LegacyRateCards/types';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { RateCardFilters, RateCardSelectableValues } from './types';

export const prepareSelectableValues = (rateCard: Optional<RateCard>): RateCardSelectableValues => {
  const { originPorts, destinationPorts } = prepareFreightPorts(rateCard?.freightCharges || []);
  const originLocations: NetworksOrgLocOption[] = prepareLocations(rateCard?.originHaulageCharges || []);
  const destinationLocations: NetworksOrgLocOption[] = prepareLocations(rateCard?.destinationHaulageCharges || []);

  return { destinationLocations, destinationPorts, originLocations, originPorts };
};

const prepareLocations = (charges: Array<{ location?: Nullable<NetworksOrgLoc> }>): NetworksOrgLocOption[] => {
  return uniqBy(charges.map((charge) => charge.location).filter(Boolean), 'id') as NetworksOrgLocOption[];
};

const defaultFiltersValue: RateCardSelectableValues = {
  destinationLocations: [],
  destinationPorts: [],
  originLocations: [],
  originPorts: []
};

export const mapOrgLocs = ({ label, id }: NetworksOrgLocOption): Option<string> => {
  return {
    label: label?.long || '',
    value: id || ''
  };
};

export const mapTerminals = ({ label, unlocode }: Terminal): Option<string> => {
  return {
    label: label || '',
    value: unlocode || ''
  };
};

export const buildFilters = (filters: RateCardSelectableValues = defaultFiltersValue): FilterItemType<RateCardFilters>[] => {
  const { destinationLocations = [], destinationPorts = [], originLocations = [], originPorts = [] } = filters;

  return [
    {
      title: 'Origin locations',
      key: 'originLocations',
      props: { options: originLocations.map(mapOrgLocs) },
      componentType: 'multi-select'
    },
    {
      title: 'Origin ports',
      key: 'originPorts',
      props: { options: originPorts.map(mapTerminals) },
      componentType: 'multi-select'
    },
    {
      title: 'Destination ports',
      key: 'destinationPorts',
      props: { options: destinationPorts.map(mapTerminals) },
      componentType: 'multi-select'
    },
    {
      title: 'Destination locations',
      key: 'destinationLocations',
      props: { options: destinationLocations.map(mapOrgLocs) },
      componentType: 'multi-select'
    }
  ];
};
