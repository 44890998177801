import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { shipmentRoutes } from '@zen/Routes';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import QuoteOptionItem from '../components/QuoteOptionItem';
import { useAcceptQuoteOptionMutation, useRejectQuoteOptionMutation } from '../graphql';
import QuoteOptionRejectReasonContainer from '../QuoteOptionRejectReasonContainer';
import { isReasonProvided } from '../quotes.helpers';
import type { QuoteOption } from '../types';
import { QuoteOptionState } from '../types';

interface Props {
  onReject?: () => void;
  quoteOption: QuoteOption;
}

const QuoteOptionContainer: FC<Props> = ({ quoteOption, onReject }) => {
  const { state } = quoteOption;
  const navigate = useNavigate();

  const { addError } = useNotification();

  const isRejected: boolean = state === QuoteOptionState.REJECTED;
  const [showRejectionReason, setShowRejectionReason] = useState<boolean>(false);
  const reasonProvided: boolean = isReasonProvided(quoteOption);

  const [acceptQuote] = useAcceptQuoteOptionMutation();
  const [rejectQuote] = useRejectQuoteOptionMutation();

  useEffect(() => {
    setShowRejectionReason(isRejected && !reasonProvided);
  }, [isRejected, reasonProvided]);

  const handleSuccess = (): void => {
    navigate(shipmentRoutes.shipmentDetailsPage.getUrl(quoteOption.zencargoReference));
  };

  const handleApprove = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        acceptQuote({
          variables: {
            input: {
              quoteOptionId: quoteOption.id as string
            }
          },
          refetchQueries: ['bookingSummary']
        }),
      onError: () => addError(),
      onSuccess: () => handleSuccess()
    });
  };

  const handleReject = async (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        rejectQuote({
          variables: {
            input: {
              quoteOptionId: quoteOption.id as string
            }
          },
          refetchQueries: ['bookingSummary']
        }),
      onError: () => addError(),
      onSuccess: () => {
        if (onReject) {
          onReject();
        }
      }
    });
  };

  return (
    <>
      <QuoteOptionItem
        isRejectionReasonExpanded={showRejectionReason}
        onApprove={handleApprove}
        onReject={handleReject}
        onRejectionReasonToggle={setShowRejectionReason}
        quoteOption={quoteOption}
      />
      {showRejectionReason && <QuoteOptionRejectReasonContainer quoteOption={quoteOption} />}
    </>
  );
};

export default QuoteOptionContainer;
