import { FC, useState } from 'react';

import { IconButton } from '@zen/DesignSystem';

import DeleteLocationModal from '../DeleteLocationModal';

interface Props {
  accountId: string;
  locationId: string;
  locationName: string;
}

const DeleteLocationButton: FC<Props> = ({ accountId, locationName, locationId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen((isOpen: boolean) => !isOpen);

  return (
    <>
      <DeleteLocationModal
        accountId={accountId}
        isOpen={isModalOpen}
        locationId={locationId}
        locationName={locationName}
        onClose={toggleModal}
      />
      <IconButton icon="zicon-trash" onClick={toggleModal} size="medium" title="Delete" variant="ghost" />
    </>
  );
};

export default DeleteLocationButton;
