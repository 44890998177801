import type { CSSProperties } from 'react';

import { Color } from '@zen/Styleguide';

import type { PieChartDataPoint, PieChartMetrics, SliceDatum } from './types';

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'innerRadius' implicitly has an 'a... Remove this comment to see the full error message
export const getPieChartMetrics = ({ innerRadius, ringWidth, expandedRingWidth }): PieChartMetrics => {
  const endRadius: number = innerRadius + ringWidth;
  const expandedEndRadius: number = innerRadius + expandedRingWidth;

  const labelRadius: number = innerRadius + 10;

  const width: number = expandedEndRadius * 2;
  const height: number = expandedEndRadius * 2;

  return {
    startRadius: innerRadius,
    endRadius,
    expandedEndRadius,
    labelRadius,
    width,
    height
  };
};

export const toPercentage = (value: number): number => Math.round(value * 100);

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'fontSize' implicitly has an 'any'... Remove this comment to see the full error message
export const svgLabelStyle = ({ fontSize, fill, ...rest }): CSSProperties => ({
  fontFamily: 'Lato, sans-serif',
  fontSize,
  fill,
  ...rest
});

export const getSliceColor = (index: number, colors: string[]): string => {
  return colors[index % colors.length];
};

export const getChartData = (dataPoints: PieChartDataPoint[], defaultColors: Color[]): SliceDatum[] => {
  const sum: number = dataPoints.reduce((partialSum, value) => partialSum + value.value, 0);

  return dataPoints.map((dataPoint: PieChartDataPoint, index: number) => ({
    eventKey: dataPoint.key || String(index),
    x: dataPoint.key || index,
    y: dataPoint.value,
    percentage: toPercentage(dataPoint.value / sum),
    fill: dataPoint.color || getSliceColor(index, defaultColors)
  }));
};

// we need to provide styles for all label rows, otherwise it will wrap from 0 again
export const getInnerLabelStyles = (): CSSProperties[] => [
  svgLabelStyle({ fontSize: '24', fontWeight: 'bold', fill: Color.GREY_DARK }),
  svgLabelStyle({ fontSize: '12', fontWeight: 'bold', fill: Color.GREY_DARK }),
  svgLabelStyle({ fontSize: '12', fontWeight: 'bold', fill: Color.GREY_DARK })
];
