export const DEVELOPER_ROUTE_PREFIX = '/graphiql';

interface DeveloperRoutes {
  graphiql: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const developerRoutes: DeveloperRoutes = {
  graphiql: {
    path: `${DEVELOPER_ROUTE_PREFIX}/*`,
    getUrl: () => DEVELOPER_ROUTE_PREFIX,
    relativePath: '*'
  }
};

export default developerRoutes;
