import { FC, useState } from 'react';

import {
  DeleteWorkflowTaskInput,
  GetTaskListCountDocument,
  GetTaskListCountersDocument,
  useDeleteWorkflowTaskMutation
} from '@zen/Tasks/graphql';
import { RefetchQuery, TaskListAction, TaskListTrackingAction, TaskListTrackingCategory } from '@zen/Tasks/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import useTracking from '@zen/utils/hooks/useTracking';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import ContextAction from '../../ContextAction';
import DeleteTaskModal from '../../DeleteTaskModal';

interface Props extends TaskListAction {}

const DeleteAction: FC<Props> = (props) => {
  const { taskId, isDisabled, onActionStart, onActionEnd, onActionSuccess, trackingProps } = props;
  const { taskUrgency, taskDescription, zencargoReference = '' } = trackingProps;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const refetchQueries: RefetchQuery[] = [{ query: GetTaskListCountersDocument }, { query: GetTaskListCountDocument }];
  const { addSuccess, addError } = useNotification();
  const [markTaskAsDeleted] = useDeleteWorkflowTaskMutation({ refetchQueries });

  const { trackEvent } = useTracking();

  const trackTaskDeletion = (deleteReason: string): void => {
    trackEvent({
      category: TaskListTrackingCategory,
      action: TaskListTrackingAction.TASK_DELETED,
      label: `${zencargoReference}|${taskDescription}|${taskUrgency}`,
      properties: {
        deleteReason,
        taskUrgency,
        zencargoReference,
        taskDescription
      }
    });
  };

  const handleMarkTaskAsDeletedAction = async (markTaskAsDeletedInput: DeleteWorkflowTaskInput): Promise<IOkOrErrorResult> => {
    onActionStart();
    trackTaskDeletion(markTaskAsDeletedInput.deleteReason);

    return performMutation({
      mutationFn: () => markTaskAsDeleted({ variables: { input: markTaskAsDeletedInput } }),
      onError: () => {
        addError('Task could not be deleted.', {
          label: 'Try again',
          onClick: () => handleMarkTaskAsDeletedAction(markTaskAsDeletedInput)
        });
        onActionEnd();
      },
      onSuccess: async () => {
        if (onActionSuccess) {
          await onActionSuccess();
        }
        addSuccess('Task deleted.');
        onActionEnd();
      }
    });
  };

  return (
    <>
      <DeleteTaskModal
        closeModal={() => setIsModalOpen(false)}
        isModalOpen={isModalOpen}
        onConfirm={handleMarkTaskAsDeletedAction}
        taskId={taskId}
      />
      <ContextAction
        icon="zicon-trash"
        iconActiveColor="red"
        isDisabled={isDisabled}
        onClick={() => setIsModalOpen(true)}
        tooltipContent="Delete task"
      />
    </>
  );
};

export default DeleteAction;
