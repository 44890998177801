import { Chip } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type { ChipOption } from './types';

interface Props<T> {
  disabled?: boolean;
  onChange: (value: T) => void;
  options: ChipOption<T>[];
  value: Optional<T>;
}

const ChipSelect = <T extends {}>({ options, onChange, value, disabled = false }: Props<T>) => {
  const isSelected = (chipValue: T): boolean => chipValue === value;

  return (
    <div className="flex flex-wrap space-x-3">
      {options.map(({ label, value: chipValue, isDisabled = disabled }: ChipOption<T>, i: number) => {
        const handleSelect = () => {
          onChange(chipValue);
        };

        return (
          <Chip
            key={i}
            isDisabled={isDisabled}
            isSelected={isSelected(chipValue)}
            label={label?.toString() ?? ''}
            onSelect={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default ChipSelect;
