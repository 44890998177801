import type { FC } from 'react';

import { FormHiddenInput, FormSelect } from '@zen/Components/Form';
import type { Option } from '@zen/DesignSystem';
import type { OrganisationTypeValue } from '@zen/Networks';

interface Props {
  fieldName?: string;
  options: Option<OrganisationTypeValue>[];
}

const OrganisationTypeSelect: FC<Props> = ({ options, fieldName = 'type' }) => {
  const renderHiddenInput = () => {
    return <FormHiddenInput name={fieldName} />;
  };

  const renderSelectInput = () => {
    return (
      <FormSelect
        isRequired={true}
        label="Organisation type"
        name={fieldName}
        options={options}
        placeholder="Choose organisation type"
      />
    );
  };

  return (
    <>
      {options?.length === 1 && renderHiddenInput()}
      {options?.length > 1 && renderSelectInput()}
    </>
  );
};

export default OrganisationTypeSelect;
