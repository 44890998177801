import { startCase } from 'lodash';
import type { FC } from 'react';

import { FormCheckbox } from '@zen/Components';
import type { Nullable } from '@zen/utils/typescript';

import type { CargoFullContainerVariant, CargoParam, CargoType } from '../../types';
import { getCargoParams } from './helpers';

interface Props {
  cargoType: CargoType;
  className?: string;
  getFieldName?: (param: CargoParam) => string;
  variant?: Nullable<CargoFullContainerVariant>;
}

const CargoParams: FC<Props> = ({ cargoType, className, getFieldName, variant }) => {
  const params: CargoParam[] = getCargoParams(cargoType, variant);

  return (
    <div className={className} data-testid="cargo-params">
      {params.map((param: CargoParam) => {
        const name: string = getFieldName ? getFieldName(param) : param;

        return <FormCheckbox key={param} label={startCase(param)} name={name} />;
      })}
    </div>
  );
};

export default CargoParams;
