import type { FC, ReactNode } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonLoading from '@zen/Components/SkeletonLoading';
import { InlineEmptyState, Pagination } from '@zen/DesignSystem';
import { shipmentRoutes } from '@zen/Routes';
import usePagination from '@zen/utils/hooks/pagination/usePagination';

import BookingOrderListItem from './BookingOrderListItem';
import { GetLotsQueryResult, GetLotsQueryVariables, useGetLotsQuery } from './graphql';
import OrderListButton from './OrderListButton';
import PackingListsButton from './PackingListsButton/PackingListsButton';
import type { CargoLot, OrderLot as OrderLotType } from './types';
import { prepareOrders } from './utils';

interface Props {
  canManageAssignedLots: boolean;
  isUpdateLotsButtonVisible?: boolean;
  zencargoReference: string;
}

const OrderList: FC<Props> = ({ canManageAssignedLots, isUpdateLotsButtonVisible = false, zencargoReference }) => {
  const { nodes, loading, error, paginationInfo } = usePagination<GetLotsQueryResult, GetLotsQueryVariables, OrderLotType>(
    useGetLotsQuery,
    'bookings.nodes[0].lots',
    { zencargoReference },
    10,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  const renderActionButtonsAndCargoReadyDateSummary = (): ReactNode => {
    const showUpdateLotsButton: boolean = canManageAssignedLots && isUpdateLotsButtonVisible;

    return (
      <div className="flex justify-end mb-4 space-x-3">
        <PackingListsButton zencargoReference={zencargoReference} />
        {showUpdateLotsButton && <OrderListButton zencargoReference={zencargoReference} />}
      </div>
    );
  };

  const noResults: ReactNode = (
    <>
      {renderActionButtonsAndCargoReadyDateSummary()}
      <InlineEmptyState title="No orders are linked to this booking.">
        Orders describe information about the goods being carried in the shipment.
      </InlineEmptyState>
    </>
  );

  return (
    <div data-testid="lots-list">
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonLoading className="my-5" height={50} />}
        noResults={noResults}
      >
        {(ordersData: OrderLotType[]) => {
          const orders: [string, CargoLot[]][] = prepareOrders(ordersData);

          const renderBookingOrderListItem = ([orderNumber, lots]: [string, CargoLot[]], index: number) => {
            if (lots[0].orderId) {
              const linkTo: string = shipmentRoutes.shipmentDetailsPageOrders.getUrl(zencargoReference, lots[0].orderId, 'items');

              return <BookingOrderListItem key={index} linkTo={linkTo} lots={lots} orderReferenceNumber={orderNumber} />;
            }
          };

          return (
            <>
              {renderActionButtonsAndCargoReadyDateSummary()}
              <div>{orders.map(renderBookingOrderListItem)}</div>
              <Pagination pageInfo={paginationInfo} />
            </>
          );
        }}
      </QueryHandler>
    </div>
  );
};

export default OrderList;
