import cx from 'classnames';
import type { FC } from 'react';

import { Icon, PopoverPlacement, Tooltip } from '@zen/DesignSystem';
import { getToday } from '@zen/utils/date';
import { formatTimeZone } from '@zen/utils/dateTime';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  className?: string;
  date?: Optional<string>;
  placement?: PopoverPlacement;
  size?: 'small' | 'medium';
  timeZone: Optional<string>;
}

const TimeZoneIcon: FC<Props> = ({ className, date, placement = 'top', size = 'medium', timeZone }) => {
  const classNames: string = cx(
    {
      'text-xs': size === 'small',
      'text-normal leading-10': size === 'medium'
    },
    'inline-block text-grey-light',
    className
  );

  return (
    <div className={classNames} data-testid="time-zone-icon">
      <Tooltip placement={placement} tooltipContent={formatTimeZone(date || getToday(), timeZone)}>
        <Icon icon="zicon-time" />
      </Tooltip>
    </div>
  );
};

export default TimeZoneIcon;
