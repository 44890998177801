import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IssueTemplatesDeleteIssueTemplateMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteIssueTemplateInput;
}>;

export type IssueTemplatesDeleteIssueTemplateMutationResult = { __typename?: 'Mutation' } & {
  issueTemplatesDeleteIssueTemplate?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteIssueTemplatePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const IssueTemplatesDeleteIssueTemplateDocument = /* #__PURE__ */ gql`
  mutation issueTemplatesDeleteIssueTemplate($input: DeleteIssueTemplateInput!) {
    issueTemplatesDeleteIssueTemplate(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useIssueTemplatesDeleteIssueTemplateMutation__
 *
 * To run a mutation, you first call `useIssueTemplatesDeleteIssueTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssueTemplatesDeleteIssueTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issueTemplatesDeleteIssueTemplateMutation, { data, loading, error }] = useIssueTemplatesDeleteIssueTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIssueTemplatesDeleteIssueTemplateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IssueTemplatesDeleteIssueTemplateMutationResult,
    IssueTemplatesDeleteIssueTemplateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<IssueTemplatesDeleteIssueTemplateMutationResult, IssueTemplatesDeleteIssueTemplateMutationVariables>(
    IssueTemplatesDeleteIssueTemplateDocument,
    options
  );
}
export type IssueTemplatesDeleteIssueTemplateMutationHookResult = ReturnType<typeof useIssueTemplatesDeleteIssueTemplateMutation>;
