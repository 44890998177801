import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingDocumentGenerationSubscriptionVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type BookingDocumentGenerationSubscriptionResult = { __typename?: 'Subscription' } & {
  bookingDocumentGeneration: { __typename?: 'BookingDocument' } & Pick<GraphQLTypes.BookingDocument, 'state' | 'error'>;
};

export const BookingDocumentGenerationDocument = /* #__PURE__ */ gql`
  subscription bookingDocumentGeneration($zencargoReference: String!) {
    bookingDocumentGeneration(zencargoReference: $zencargoReference) {
      state
      error
    }
  }
`;

/**
 * __useBookingDocumentGenerationSubscription__
 *
 * To run a query within a React component, call `useBookingDocumentGenerationSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBookingDocumentGenerationSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDocumentGenerationSubscription({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useBookingDocumentGenerationSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    BookingDocumentGenerationSubscriptionResult,
    BookingDocumentGenerationSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useSubscription<BookingDocumentGenerationSubscriptionResult, BookingDocumentGenerationSubscriptionVariables>(
    BookingDocumentGenerationDocument,
    options
  );
}
export type BookingDocumentGenerationSubscriptionHookResult = ReturnType<typeof useBookingDocumentGenerationSubscription>;
