import { FC, useState } from 'react';

import { Modal } from '@zen/DesignSystem';
import FreightChargeForm from '@zen/RateCards/LegacyRateCards/FreightCharge/FreightChargeForm';
import FreightChargeTable from '@zen/RateCards/LegacyRateCards/FreightCharge/FreightChargeTable';
import type { FreightChargePayload } from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';

import type { FreightChargeValidationValues } from './types';

interface Props {
  freightCharges: FreightChargePayload[];
  onAdd: (values: FreightChargePayload) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: FreightChargePayload) => void;
}

type ModalConfig = { index?: number; type: 'create' | 'update'; values?: FreightChargePayload };

const FreightChargeSection: FC<Props> = ({ onUpdate, onDelete, freightCharges, onAdd }) => {
  const [config, setConfig] = useState<ModalConfig>();
  const modalTitle: string = config?.type === 'create' ? 'Set freight charge' : 'Update freight charge';

  const closeModal = (): void => setConfig(undefined);

  const handleUpdateClick = (index: number, values: FreightChargePayload): void => {
    setConfig({ type: 'update', index, values });
  };

  const handleAddClick = (): void => {
    setConfig({ type: 'create' });
  };

  const handleSubmit = (values: FreightChargePayload): void => {
    if (config?.type === 'create') {
      onAdd(values);
    }

    if (config?.index !== undefined) {
      onUpdate(config.index, values);
    }
  };

  const getFreightChargeValidationValues = (): FreightChargeValidationValues[] => {
    const selectedFreightChargeValidationValues = freightCharges.map(
      ({ originPort, destinationPort, chargeType }: FreightChargePayload) => ({
        originUnlocode: originPort.unlocode,
        destinationUnlocode: destinationPort.unlocode,
        chargeTypeId: chargeType.id
      })
    );

    if (config?.values) {
      const initialValuesFilter = (value: FreightChargeValidationValues): boolean => {
        const { originUnlocode, destinationUnlocode, chargeTypeId } = value;
        const { values } = config;

        return (
          originUnlocode !== values?.originPort.unlocode &&
          destinationUnlocode !== values?.destinationPort.unlocode &&
          chargeTypeId !== values?.chargeType.id
        );
      };

      return selectedFreightChargeValidationValues.filter(initialValuesFilter);
    }

    return selectedFreightChargeValidationValues;
  };

  return (
    <>
      <FreightChargeTable
        editable={true}
        freightCharges={freightCharges}
        onAdd={handleAddClick}
        onDelete={onDelete}
        onUpdate={handleUpdateClick}
      />
      <Modal closeOnClickAway={false} isOpen={!!config} modalOverflowY="scroll" onClose={closeModal} title={modalTitle}>
        <FreightChargeForm
          initialValues={config?.values}
          onCancel={closeModal}
          onSubmit={handleSubmit}
          onSuccess={closeModal}
          selectedFreightCharges={getFreightChargeValidationValues()}
        />
      </Modal>
    </>
  );
};

export default FreightChargeSection;
