import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type AirlineCarriersQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  query?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
}>;

export type AirlineCarriersQueryResult = { __typename?: 'Query' } & {
  airlineCarriers?: GraphQLTypes.Maybe<
    { __typename?: 'AirlineCarrierConnection' } & Pick<GraphQLTypes.AirlineCarrierConnection, 'totalCount'> & {
        nodes?: GraphQLTypes.Maybe<
          Array<
            { __typename?: 'AirlineCarrier' } & Pick<GraphQLTypes.AirlineCarrier, 'airlineCarrierName' | 'id' | 'carrierIata'>
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const AirlineCarriersDocument = /* #__PURE__ */ gql`
  query airlineCarriers($after: String, $before: String, $first: Int, $last: Int, $query: String) {
    airlineCarriers(after: $after, before: $before, first: $first, last: $last, query: $query) {
      nodes {
        airlineCarrierName
        id
        carrierIata
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useAirlineCarriersQuery__
 *
 * To run a query within a React component, call `useAirlineCarriersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAirlineCarriersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAirlineCarriersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useAirlineCarriersQuery(
  baseOptions?: Apollo.QueryHookOptions<AirlineCarriersQueryResult, AirlineCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<AirlineCarriersQueryResult, AirlineCarriersQueryVariables>(AirlineCarriersDocument, options);
}
export function useAirlineCarriersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AirlineCarriersQueryResult, AirlineCarriersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<AirlineCarriersQueryResult, AirlineCarriersQueryVariables>(AirlineCarriersDocument, options);
}
export type AirlineCarriersQueryHookResult = ReturnType<typeof useAirlineCarriersQuery>;
export type AirlineCarriersLazyQueryHookResult = ReturnType<typeof useAirlineCarriersLazyQuery>;
