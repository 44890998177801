import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { RouteTabConfig as TabConfig } from '../RouteTabs';
import RouteTabs from '../RouteTabs';
import RouteTabSwitcher from '../RouteTabSwitcher';

export { TabConfig };
interface Props {
  contentClassName?: string;
  header: ReactNode;
  headerClassName?: string;
  headerMargin?: string;
  tabsConfig: TabConfig[];
}

const PageWithTabs: FC<Props> = ({ tabsConfig, header, contentClassName, headerClassName = '', headerMargin = 'mb-6' }) => {
  const classNames: string = cx('flex border-b border-solid border-grey-lighter', headerMargin, headerClassName);

  return (
    <>
      <div className={classNames} data-testid="page-with-tabs">
        <div className="w-full">
          <div className="pt-4">{header}</div>
          <div className="bottom-0 px-6">
            <RouteTabs tabsConfig={tabsConfig} />
          </div>
        </div>
      </div>
      <div className={contentClassName} id="pageContent">
        <RouteTabSwitcher tabsConfig={tabsConfig} />
      </div>
    </>
  );
};

export default PageWithTabs;
