import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ShipmentsTableFilterValuesQueryVariables = GraphQLTypes.Exact<{
  accountIds: Array<GraphQLTypes.Scalars['String']> | GraphQLTypes.Scalars['String'];
  filterBy?: GraphQLTypes.InputMaybe<GraphQLTypes.ShipmentsTableFiltersInput>;
}>;

export type ShipmentsTableFilterValuesQueryResult = { __typename?: 'Query' } & {
  shipmentsTableFilterValues: { __typename?: 'ShipmentsTableFilterValues' } & Pick<
    GraphQLTypes.ShipmentsTableFilterValues,
    'carrierScac'
  >;
};

export const ShipmentsTableFilterValuesDocument = /* #__PURE__ */ gql`
  query shipmentsTableFilterValues($accountIds: [String!]!, $filterBy: ShipmentsTableFiltersInput) {
    shipmentsTableFilterValues(accountIds: $accountIds, filterBy: $filterBy) {
      carrierScac
    }
  }
`;

/**
 * __useShipmentsTableFilterValuesQuery__
 *
 * To run a query within a React component, call `useShipmentsTableFilterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useShipmentsTableFilterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShipmentsTableFilterValuesQuery({
 *   variables: {
 *      accountIds: // value for 'accountIds'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useShipmentsTableFilterValuesQuery(
  baseOptions: Apollo.QueryHookOptions<ShipmentsTableFilterValuesQueryResult, ShipmentsTableFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ShipmentsTableFilterValuesQueryResult, ShipmentsTableFilterValuesQueryVariables>(
    ShipmentsTableFilterValuesDocument,
    options
  );
}
export function useShipmentsTableFilterValuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsTableFilterValuesQueryResult, ShipmentsTableFilterValuesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ShipmentsTableFilterValuesQueryResult, ShipmentsTableFilterValuesQueryVariables>(
    ShipmentsTableFilterValuesDocument,
    options
  );
}
export type ShipmentsTableFilterValuesQueryHookResult = ReturnType<typeof useShipmentsTableFilterValuesQuery>;
export type ShipmentsTableFilterValuesLazyQueryHookResult = ReturnType<typeof useShipmentsTableFilterValuesLazyQuery>;
