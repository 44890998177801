import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { Pill } from '@zen/DesignSystem';
import type { Optional } from '@zen/utils/typescript';

import type {
  JourneyShippingMilestoneRequestedByEnum,
  JourneyStopLocationTypeEnum,
  ShippingMilestoneDateTypeEnum
} from '../../types';
import { getDateTypeConfig } from './helpers';

interface Props {
  className?: string;
  dateType: ShippingMilestoneDateTypeEnum;
  locationType: JourneyStopLocationTypeEnum;
  modeOfTransport: Optional<ModeOfTransport>;
  requestedBy: Optional<JourneyShippingMilestoneRequestedByEnum>;
}

const DateTypePill: FC<Props> = ({ className, dateType, locationType, modeOfTransport, requestedBy }) => {
  const { label, pillType, tooltipContent } = getDateTypeConfig({ dateType, locationType, modeOfTransport, requestedBy });

  return (
    <div className={className}>
      <Pill tooltip={<div className="max-w-56">{tooltipContent}</div>} type={pillType}>
        {label}
      </Pill>
    </div>
  );
};

export default DateTypePill;
