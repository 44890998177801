import { type FC, useState } from 'react';

import { Modal } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import FreightChargeForm from '../FreightChargeForm';
import FreightChargeTable from '../FreightChargeTable';

interface Props {
  freightCharges: RateCardCharge[];
  onAdd: (values: RateCardCharge[]) => void;
  onDelete: (index: number) => void;
  onUpdate: (index: number, values: Partial<RateCardCharge>) => void;
}

const FreightChargeSection: FC<Props> = (props) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { freightCharges, onAdd, onDelete, onUpdate } = props;

  const handleAdd = (): void => {
    setModalOpen(true);
  };

  const handleClose = (): void => {
    setModalOpen(false);
  };

  return (
    <>
      <FreightChargeTable charges={freightCharges} onAdd={handleAdd} onDelete={onDelete} onUpdate={onUpdate} />
      <Modal
        closeOnClickAway={false}
        isOpen={isModalOpen}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Set freight charges"
        width="full"
      >
        <FreightChargeForm onCancel={handleClose} onSubmit={onAdd} onSuccess={handleClose} />
      </Modal>
    </>
  );
};

export type { Props as FreightChargeSectionProps };

export default FreightChargeSection;
