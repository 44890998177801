import cx from 'classnames';
import { uniqueId } from 'lodash';
import { FC, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useMountedState } from 'react-use';

import { TabConfig, Tabs } from '@zen/DesignSystem';

import { enhanceTabViewConfig, getDefaultTab, prepareComponentMapping, prepareTabsConfig } from './helpers';
import type { EnhancedTabViewConfig, TabViewConfig } from './types';

type TabViewControllerChildren = (args: { content: ReactElement; tabs: ReactElement }) => ReactElement;

interface Props {
  children: TabViewControllerChildren;
  onTabChange?: (tab: string) => void;
  tabViewConfig: TabViewConfig[];
}

const TabViewController: FC<Props> = ({ children, onTabChange, tabViewConfig }) => {
  const [activeTab, setActiveTab] = useState<string>();
  const [animationInProgress, setAnimationInProgress] = useState<boolean>();
  const isMounted = useMountedState();

  const tabsIdentifier = useRef(`tabs-${uniqueId()}`);

  const enhancedTabViewConfig: EnhancedTabViewConfig[] = useMemo(
    () => enhanceTabViewConfig(tabViewConfig, tabsIdentifier.current),
    [tabViewConfig]
  );
  const componentMapping: Record<string, ReactElement> = prepareComponentMapping(enhancedTabViewConfig);
  const defaultTab: string = getDefaultTab(enhancedTabViewConfig);
  const tabsConfig: TabConfig[] = prepareTabsConfig(enhancedTabViewConfig, activeTab);

  const classNames: string = cx(
    {
      'opacity-100': !animationInProgress,
      'opacity-0': animationInProgress
    },
    'transition-all duration-300 ease-in-out'
  );

  const handleActiveTabChange = (tab: string): void => {
    if (!isMounted) return;

    setAnimationInProgress(true);

    setTimeout(() => {
      setAnimationInProgress(false);
      setActiveTab(tab);
    }, 300);

    onTabChange?.(tab);
  };

  useEffect(() => {
    handleActiveTabChange(defaultTab);
  }, [defaultTab, enhancedTabViewConfig.length]);

  if (!activeTab) {
    return null;
  }

  const tabs: ReactElement = <Tabs onTabClick={handleActiveTabChange} tabsConfig={tabsConfig} />;
  const content: ReactElement = <div className={classNames}>{componentMapping[activeTab]}</div>;

  return children({ tabs, content });
};

export default TabViewController;
