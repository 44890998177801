import type { ComponentType, FilterItemType } from '@zen/Components/Filters/types';

import type { OptionListSection } from '../OptionList/types';

export const prepareGroupFilterOptionList = <T>(
  configurations: Array<FilterItemType<T>>,
  handleClick: (key: string) => void
): OptionListSection<ComponentType<T>>[] => {
  const createListOption = (configuration: FilterItemType<T>): OptionListSection<ComponentType<T>> => {
    return {
      title: configuration.title,
      items: [
        {
          label: '',
          isSelected: false,
          componentType: configuration.componentType,
          props: configuration.props,
          key: configuration.key,
          onClick: () => handleClick(configuration.key as string)
        }
      ]
    } as OptionListSection<ComponentType<T>>;
  };

  return configurations.map(createListOption);
};
