import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ValidityDatesQueryVariables = GraphQLTypes.Exact<{
  customerDivisionId: GraphQLTypes.Scalars['String'];
  modeOfTransport: GraphQLTypes.ModeOfTransport;
  cargoType: GraphQLTypes.CargoModeEnum;
}>;

export type ValidityDatesQueryResult = { __typename?: 'Query' } & {
  legacyGetValidityDates?: GraphQLTypes.Maybe<
    { __typename?: 'LegacyCustomerValidityDates' } & {
      validityDates?: GraphQLTypes.Maybe<
        Array<{ __typename?: 'DateRange' } & Pick<GraphQLTypes.DateRange, 'startDate' | 'endDate'>>
      >;
    }
  >;
};

export const ValidityDatesDocument = /* #__PURE__ */ gql`
  query validityDates($customerDivisionId: String!, $modeOfTransport: ModeOfTransport!, $cargoType: CargoModeEnum!) {
    legacyGetValidityDates(customerDivisionId: $customerDivisionId, modeOfTransport: $modeOfTransport, cargoType: $cargoType) {
      validityDates {
        startDate
        endDate
      }
    }
  }
`;

/**
 * __useValidityDatesQuery__
 *
 * To run a query within a React component, call `useValidityDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidityDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidityDatesQuery({
 *   variables: {
 *      customerDivisionId: // value for 'customerDivisionId'
 *      modeOfTransport: // value for 'modeOfTransport'
 *      cargoType: // value for 'cargoType'
 *   },
 * });
 */
export function useValidityDatesQuery(
  baseOptions: Apollo.QueryHookOptions<ValidityDatesQueryResult, ValidityDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ValidityDatesQueryResult, ValidityDatesQueryVariables>(ValidityDatesDocument, options);
}
export function useValidityDatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ValidityDatesQueryResult, ValidityDatesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ValidityDatesQueryResult, ValidityDatesQueryVariables>(ValidityDatesDocument, options);
}
export type ValidityDatesQueryHookResult = ReturnType<typeof useValidityDatesQuery>;
export type ValidityDatesLazyQueryHookResult = ReturnType<typeof useValidityDatesLazyQuery>;
