import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type IssuesArchiveIssueMutationVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
}>;

export type IssuesArchiveIssueMutationResult = { __typename?: 'Mutation' } & {
  issuesArchiveIssue?: GraphQLTypes.Maybe<
    { __typename?: 'ArchiveIssuePayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'path' | 'message'>>>;
    }
  >;
};

export const IssuesArchiveIssueDocument = /* #__PURE__ */ gql`
  mutation issuesArchiveIssue($id: String!) {
    issuesArchiveIssue(input: { id: $id }) {
      errors {
        path
        message
      }
    }
  }
`;

/**
 * __useIssuesArchiveIssueMutation__
 *
 * To run a mutation, you first call `useIssuesArchiveIssueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIssuesArchiveIssueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [issuesArchiveIssueMutation, { data, loading, error }] = useIssuesArchiveIssueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIssuesArchiveIssueMutation(
  baseOptions?: Apollo.MutationHookOptions<IssuesArchiveIssueMutationResult, IssuesArchiveIssueMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<IssuesArchiveIssueMutationResult, IssuesArchiveIssueMutationVariables>(
    IssuesArchiveIssueDocument,
    options
  );
}
export type IssuesArchiveIssueMutationHookResult = ReturnType<typeof useIssuesArchiveIssueMutation>;
