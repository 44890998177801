import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';

import Slideout from '@zen/Components/Slideout';
import { ORDERS_ROUTE_PREFIX } from '@zen/Routes';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import OrderDetails from '../OrderDetails';

interface Props {
  originPathname?: string;
}
type RouteParams = {
  id: string;
};

const OrderDetailsContainer: FC<Props> = ({ originPathname = ORDERS_ROUTE_PREFIX }) => {
  const { id } = useParams<RouteParams>() as RouteParams;

  const { navigateBack } = useNavigationHistory();

  // we need to check whether the id param is a valid uuid
  const isUuid = uuidValidate(id);

  const handleClose = () => navigateBack(originPathname);

  return (
    <Slideout onOutsideClick={handleClose} show={!!isUuid}>
      <OrderDetails onClose={handleClose} originPath={originPathname} />
    </Slideout>
  );
};

export default OrderDetailsContainer;
