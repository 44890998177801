import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import type { IconName } from '@zen/Styleguide';
import { createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import { Headline } from '../Typography';

interface Props {
  actions?: ReactNode;
  children?: ReactNode;
  className?: string;
  compactView?: boolean;
  control?: ReactNode;
  footer?: ReactNode;
  id?: string;
  subtitle?: string;
  title?: string;
  titleIcon?: IconName;
}

const Container: FC<Props> = (props) => {
  const { actions, children, className = '', compactView = false, control, footer, subtitle, title, titleIcon, ...rest } = props;
  const headerClassNames: string = cx(
    {
      'border-b border-solid border-grey-lighter': children
    },
    'px-6 pt-4'
  );

  const childrenClassName: string = cx({ 'p-6': !compactView });
  const titleClassNames: string = cx('flex justify-between items-center pb-4');
  const containerClassNames: string = `bg-white border border-solid border-grey-lighter rounded ${className}`;
  const isHeadlineVisible: boolean = !!title || !!subtitle || !!actions;

  return (
    <div className={containerClassNames} {...createTrackingParentAttribute('container')} data-testid="container" {...rest}>
      <div className={headerClassNames} data-testid="container-header">
        {isHeadlineVisible && (
          <div className={titleClassNames}>
            <div>
              <Headline level={3}>
                <>
                  {titleIcon && <Icon className="mr-2" icon={titleIcon} />}
                  {title}
                </>
              </Headline>
              {subtitle && <p className="mt-1 text-sm leading-6 text-grey-base">{subtitle}</p>}
            </div>
            {actions && <div className="flex space-x-4">{actions}</div>}
          </div>
        )}
        {control}
      </div>
      {children && <div className={childrenClassName}>{children}</div>}
      {footer && <div className="py-4 pl-6">{footer}</div>}
    </div>
  );
};

export type { Props as ContainerProps };
export default Container;
