import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateAccountLocalisedLocationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountLocalisedLocationInput;
}>;

export type UpdateAccountLocalisedLocationMutationResult = { __typename?: 'Mutation' } & {
  updateAccountLocalisedLocation?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountLocalisedLocationPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateAccountLocalisedLocationDocument = /* #__PURE__ */ gql`
  mutation updateAccountLocalisedLocation($input: UpdateAccountLocalisedLocationInput!) {
    updateAccountLocalisedLocation(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateAccountLocalisedLocationMutation__
 *
 * To run a mutation, you first call `useUpdateAccountLocalisedLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountLocalisedLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountLocalisedLocationMutation, { data, loading, error }] = useUpdateAccountLocalisedLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAccountLocalisedLocationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAccountLocalisedLocationMutationResult,
    UpdateAccountLocalisedLocationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateAccountLocalisedLocationMutationResult, UpdateAccountLocalisedLocationMutationVariables>(
    UpdateAccountLocalisedLocationDocument,
    options
  );
}
export type UpdateAccountLocalisedLocationMutationHookResult = ReturnType<typeof useUpdateAccountLocalisedLocationMutation>;
