import type { FC } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import type { Optional } from '@zen/utils/typescript';

import type { JourneyShippingMilestoneNameEnum, JourneyStopActionEnum } from '../../types';
import { getMilestoneLabel } from './helpers';

interface Props {
  isMilestoneCompleted: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  name: JourneyShippingMilestoneNameEnum;
  stopAction: JourneyStopActionEnum;
}

const MilestoneName: FC<Props> = ({ isMilestoneCompleted, modeOfTransport, name, stopAction }) => {
  const milestoneName: string = getMilestoneLabel({ completed: isMilestoneCompleted, modeOfTransport, name, stopAction });

  return <div className="text-base font-bold leading-tight">{milestoneName}</div>;
};

export default MilestoneName;
