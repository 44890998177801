import type { FC } from 'react';

import { Select, SelectProps } from '@zen/DesignSystem';

import { truckSwapOptions } from './helpers';
import type { TruckSwapEnum } from './types';

interface Props extends Omit<SelectProps<TruckSwapEnum>, 'options'> {}

const TruckSwapSelect: FC<Props> = (props) => {
  return (
    <Select
      {...props}
      isClearable={false}
      isSearchable={false}
      label="Truck swap booking"
      labelTooltip={
        <>
          A booking where the cargo moved from one <br /> truck or trailer to another
        </>
      }
      options={truckSwapOptions}
    />
  );
};

export default TruckSwapSelect;
