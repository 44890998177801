import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetActiveTaskListQueryVariables = GraphQLTypes.Exact<{
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  order?: GraphQLTypes.InputMaybe<GraphQLTypes.SortInput>;
  taskDetailsFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.TaskDetailsFiltersInput>;
}>;

export type GetActiveTaskListQueryResult = { __typename?: 'Query' } & {
  taskDetails?: GraphQLTypes.Maybe<
    { __typename?: 'WorkflowTaskDetailsPayloadType' } & {
      tasks?: GraphQLTypes.Maybe<
        { __typename?: 'WorkflowTaskConnection' } & Pick<GraphQLTypes.WorkflowTaskConnection, 'totalCount'> & {
            nodes: Array<
              { __typename?: 'WorkflowTaskType' } & Pick<
                GraphQLTypes.WorkflowTaskType,
                'canDeleteTask' | 'canCompleteTask' | 'taskId' | 'name' | 'priority' | 'dueDate' | 'zencargoReference'
              > & {
                  formMetaData?: GraphQLTypes.Maybe<
                    { __typename?: 'WorkflowFormMetaData' } & {
                      formInputs?: GraphQLTypes.Maybe<
                        Array<
                          GraphQLTypes.Maybe<{ __typename?: 'WorkflowFormInput' } & Pick<GraphQLTypes.WorkflowFormInput, 'name'>>
                        >
                      >;
                    }
                  >;
                  assignee?: GraphQLTypes.Maybe<
                    { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'lastName' | 'id'>
                  >;
                  workflowTaskVariables?: GraphQLTypes.Maybe<
                    Array<{ __typename?: 'KeyValuePair' } & Pick<GraphQLTypes.KeyValuePair, 'key' | 'value'>>
                  >;
                }
            >;
            pageInfo: { __typename?: 'PageInfo' } & Pick<
              GraphQLTypes.PageInfo,
              'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >;
          }
      >;
    }
  >;
};

export const GetActiveTaskListDocument = /* #__PURE__ */ gql`
  query getActiveTaskList(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $order: SortInput
    $taskDetailsFiltersInput: TaskDetailsFiltersInput
  ) {
    taskDetails(
      after: $after
      before: $before
      first: $first
      last: $last
      order: $order
      taskDetailsFiltersInput: $taskDetailsFiltersInput
    ) {
      tasks: activeTasks {
        nodes {
          formMetaData {
            formInputs {
              name
            }
          }
          assignee {
            firstName
            lastName
            id
          }
          canDeleteTask
          canCompleteTask
          taskId
          name
          priority
          dueDate
          zencargoReference
          workflowTaskVariables {
            key
            value
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

/**
 * __useGetActiveTaskListQuery__
 *
 * To run a query within a React component, call `useGetActiveTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTaskListQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      order: // value for 'order'
 *      taskDetailsFiltersInput: // value for 'taskDetailsFiltersInput'
 *   },
 * });
 */
export function useGetActiveTaskListQuery(
  baseOptions?: Apollo.QueryHookOptions<GetActiveTaskListQueryResult, GetActiveTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetActiveTaskListQueryResult, GetActiveTaskListQueryVariables>(GetActiveTaskListDocument, options);
}
export function useGetActiveTaskListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTaskListQueryResult, GetActiveTaskListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetActiveTaskListQueryResult, GetActiveTaskListQueryVariables>(GetActiveTaskListDocument, options);
}
export type GetActiveTaskListQueryHookResult = ReturnType<typeof useGetActiveTaskListQuery>;
export type GetActiveTaskListLazyQueryHookResult = ReturnType<typeof useGetActiveTaskListLazyQuery>;
