import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type PurchaseOrdersUpdateLotsMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateLotsInput;
}>;

export type PurchaseOrdersUpdateLotsMutationResult = { __typename?: 'Mutation' } & {
  purchaseOrdersUpdateLots?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateLotsPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
      purchaseOrder?: GraphQLTypes.Maybe<
        { __typename?: 'PurchaseOrder' } & Pick<
          GraphQLTypes.PurchaseOrder,
          'id' | 'lotsTotalCount' | 'bookedCbm' | 'fulfilledCbm' | 'orderedCbm' | 'lotsBookedCount' | 'orderReferenceNumber'
        > & {
            lotsByStageCount?: GraphQLTypes.Maybe<
              Array<
                { __typename?: 'PurchaseOrderLotStageCountType' } & Pick<
                  GraphQLTypes.PurchaseOrderLotStageCountType,
                  'count' | 'stage'
                >
              >
            >;
            orderedLineItems: Array<
              { __typename?: 'OrderedLineItem' } & Pick<GraphQLTypes.OrderedLineItem, 'cbm' | 'id' | 'quantityOrdered'> & {
                  canUpdateDeliveryDate: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                  initialCargoReadyDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                  lots: Array<
                    { __typename?: 'Lot' } & Pick<
                      GraphQLTypes.Lot,
                      'atRisk' | 'cbm' | 'estimatedDeliveryDate' | 'fulfilled' | 'id' | 'quantityFulfilled'
                    > & {
                        booking?: GraphQLTypes.Maybe<
                          { __typename?: 'Booking' } & Pick<GraphQLTypes.Booking, 'zencargoReference'> & {
                              stage?: GraphQLTypes.Maybe<{ __typename?: 'Stage' } & Pick<GraphQLTypes.Stage, 'value'>>;
                            }
                        >;
                        cargoReadyDate?: GraphQLTypes.Maybe<
                          { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                              history?: GraphQLTypes.Maybe<
                                Array<
                                  { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'> & {
                                      lastUpdated?: GraphQLTypes.Maybe<
                                        { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                            updatedBy?: GraphQLTypes.Maybe<
                                              { __typename?: 'UpdatedBy' } & Pick<
                                                GraphQLTypes.UpdatedBy,
                                                'username' | 'zencargoEmployee'
                                              >
                                            >;
                                          }
                                      >;
                                      reasonForChange?: GraphQLTypes.Maybe<
                                        { __typename?: 'ReasonForChange' } & Pick<
                                          GraphQLTypes.ReasonForChange,
                                          'category' | 'description'
                                        >
                                      >;
                                    }
                                >
                              >;
                              lastUpdated?: GraphQLTypes.Maybe<
                                { __typename?: 'LastUpdated' } & Pick<GraphQLTypes.LastUpdated, 'updatedAt'> & {
                                    updatedBy?: GraphQLTypes.Maybe<
                                      { __typename?: 'UpdatedBy' } & Pick<GraphQLTypes.UpdatedBy, 'username' | 'zencargoEmployee'>
                                    >;
                                  }
                              >;
                              reasonForChange?: GraphQLTypes.Maybe<
                                { __typename?: 'ReasonForChange' } & Pick<
                                  GraphQLTypes.ReasonForChange,
                                  'category' | 'description'
                                >
                              >;
                            }
                        >;
                        currentStage?: GraphQLTypes.Maybe<
                          { __typename?: 'ProgressStatus' } & Pick<GraphQLTypes.ProgressStatus, 'name'>
                        >;
                        purchaseOrder?: GraphQLTypes.Maybe<
                          { __typename?: 'PurchaseOrder' } & Pick<GraphQLTypes.PurchaseOrder, 'id'>
                        >;
                        stages?: GraphQLTypes.Maybe<
                          Array<
                            { __typename?: 'ProgressStatus' } & Pick<
                              GraphQLTypes.ProgressStatus,
                              'currentStage' | 'label' | 'name' | 'statusType'
                            > & {
                                date?: GraphQLTypes.Maybe<
                                  { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'> & {
                                      history?: GraphQLTypes.Maybe<
                                        Array<
                                          { __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'confidenceLevel' | 'date'>
                                        >
                                      >;
                                    }
                                >;
                              }
                          >
                        >;
                      }
                  >;
                  product?: GraphQLTypes.Maybe<
                    { __typename?: 'Product' } & Pick<GraphQLTypes.Product, 'id' | 'name' | 'skuCode'> & {
                        costPrice?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
                      }
                  >;
                  requiredDeliveryDate?: GraphQLTypes.Maybe<{ __typename?: 'RichDate' } & Pick<GraphQLTypes.RichDate, 'date'>>;
                  totalCost?: GraphQLTypes.Maybe<{ __typename?: 'Money' } & Pick<GraphQLTypes.Money, 'value' | 'currency'>>;
                }
            >;
          }
      >;
    }
  >;
};

export const PurchaseOrdersUpdateLotsDocument = /* #__PURE__ */ gql`
  mutation purchaseOrdersUpdateLots($input: UpdateLotsInput!) {
    purchaseOrdersUpdateLots(input: $input) {
      errors {
        message
        path
      }
      purchaseOrder {
        id
        lotsTotalCount
        bookedCbm
        fulfilledCbm
        orderedCbm
        lotsBookedCount
        lotsByStageCount {
          count
          stage
        }
        orderedLineItems {
          canUpdateDeliveryDate {
            value
          }
          cbm
          id
          initialCargoReadyDate {
            date
          }
          lots {
            atRisk
            booking {
              stage {
                value
              }
              zencargoReference
            }
            cargoReadyDate {
              date
              history {
                date
                lastUpdated {
                  updatedAt
                  updatedBy {
                    username
                    zencargoEmployee
                  }
                }
                reasonForChange {
                  category
                  description
                }
              }
              lastUpdated {
                updatedAt
                updatedBy {
                  username
                  zencargoEmployee
                }
              }
              reasonForChange {
                category
                description
              }
            }
            cbm
            currentStage {
              name
            }
            estimatedDeliveryDate
            fulfilled
            id
            purchaseOrder {
              id
            }
            quantityFulfilled
            stages {
              currentStage
              date {
                confidenceLevel
                date
                history {
                  confidenceLevel
                  date
                }
              }
              label
              name
              statusType
            }
          }
          product {
            id
            costPrice {
              value
              currency
            }
            name
            skuCode
          }
          quantityOrdered
          requiredDeliveryDate {
            date
          }
          totalCost {
            value
            currency
          }
        }
        orderReferenceNumber
      }
    }
  }
`;

/**
 * __usePurchaseOrdersUpdateLotsMutation__
 *
 * To run a mutation, you first call `usePurchaseOrdersUpdateLotsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseOrdersUpdateLotsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseOrdersUpdateLotsMutation, { data, loading, error }] = usePurchaseOrdersUpdateLotsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePurchaseOrdersUpdateLotsMutation(
  baseOptions?: Apollo.MutationHookOptions<PurchaseOrdersUpdateLotsMutationResult, PurchaseOrdersUpdateLotsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<PurchaseOrdersUpdateLotsMutationResult, PurchaseOrdersUpdateLotsMutationVariables>(
    PurchaseOrdersUpdateLotsDocument,
    options
  );
}
export type PurchaseOrdersUpdateLotsMutationHookResult = ReturnType<typeof usePurchaseOrdersUpdateLotsMutation>;
