import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type EditSsoConfigurationMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.CreateSsoConfigurationInput;
}>;

export type EditSsoConfigurationMutationResult = { __typename?: 'Mutation' } & {
  createSsoConfiguration: { __typename?: 'CreateSsoConfigurationPayload' } & Pick<
    GraphQLTypes.CreateSsoConfigurationPayload,
    'accountId' | 'connectionName'
  > & { errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message'>>> };
};

export const EditSsoConfigurationDocument = /* #__PURE__ */ gql`
  mutation editSsoConfiguration($input: CreateSsoConfigurationInput!) {
    createSsoConfiguration(input: $input) {
      accountId
      connectionName
      errors {
        message
      }
    }
  }
`;

/**
 * __useEditSsoConfigurationMutation__
 *
 * To run a mutation, you first call `useEditSsoConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSsoConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSsoConfigurationMutation, { data, loading, error }] = useEditSsoConfigurationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSsoConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<EditSsoConfigurationMutationResult, EditSsoConfigurationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<EditSsoConfigurationMutationResult, EditSsoConfigurationMutationVariables>(
    EditSsoConfigurationDocument,
    options
  );
}
export type EditSsoConfigurationMutationHookResult = ReturnType<typeof useEditSsoConfigurationMutation>;
