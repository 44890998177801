const FINANCE_RELATIVE_INDEX_PATH = 'freight-spend';

export const FINANCE_ROUTE_PREFIX = `/dashboard/${FINANCE_RELATIVE_INDEX_PATH}`;

interface FinanceRoutes {
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const financeRoutes: FinanceRoutes = {
  index: {
    getUrl: () => FINANCE_ROUTE_PREFIX,
    path: `${FINANCE_ROUTE_PREFIX}/*`,
    relativePath: `${FINANCE_RELATIVE_INDEX_PATH}/*`
  }
};

export default financeRoutes;
