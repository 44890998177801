import { Role } from '@zen/Auth';
import type { PillType } from '@zen/DesignSystem';

import { TrackingStatus } from '../../types';

interface PillConfig {
  label: string;
  type: PillType;
}

export const getTrackingConfig = (status: TrackingStatus, role: Role): PillConfig => {
  const isCustomerOrManufacturer: boolean = role === Role.CUSTOMER_USER || role === Role.MANUFACTURER;

  const statusMapping: Record<TrackingStatus, PillConfig> = {
    [TrackingStatus.TRACKED]: {
      label: isCustomerOrManufacturer ? 'AI tracking' : 'Tracked',
      type: 'success'
    },
    [TrackingStatus.NOT_TRACKED]: {
      label: isCustomerOrManufacturer ? 'Standard tracking' : 'Not tracked',
      type: 'pending'
    },
    [TrackingStatus.TRACKING_DISABLED]: {
      label: isCustomerOrManufacturer ? 'Standard tracking' : 'Tracking disabled',
      type: isCustomerOrManufacturer ? 'pending' : 'problem'
    }
  };

  return statusMapping[status];
};
