import { FC, ReactNode, useState } from 'react';

import OperationsSettingsSearch from '@zen/Components/OperationsSettingsSearch';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import SkeletonTableLoading from '@zen/Components/SkeletonTableLoading';
import { Button, Modal, Table } from '@zen/DesignSystem';
import type { CommodityCodes } from '@zen/graphql/types.generated';
import opsSettingsRoutes from '@zen/Routes/opsSettings';
import { SortingOrder, SortInput } from '@zen/types';
import usePagination from '@zen/utils/hooks/pagination/usePagination';

import { GetCommodityCodesQueryResult, GetCommodityCodesQueryVariables, useGetCommodityCodesQuery } from './graphql';
import tableColumns from './tableConfiguration';
import UploadCommodityCodes from './UploadCommodityCodes';

const initialOrder: SortInput = {
  field: 'id',
  direction: SortingOrder.ASC
};

const ManageCommodityCodes: FC = () => {
  const [order, setOrder] = useState<SortInput>(initialOrder);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

  const { loading, error, nodes, paginationInfo, totalCount, refetch } = usePagination<
    GetCommodityCodesQueryResult,
    GetCommodityCodesQueryVariables,
    CommodityCodes
  >(
    useGetCommodityCodesQuery,
    'commodityCodes',
    {
      textContains: searchQuery,
      order
    },
    20
  );

  const uploadCommodityCodesButton: ReactNode = (
    <Button onClick={() => setShowUploadModal(true)} variant="secondary">
      Upload commodity codes
    </Button>
  );

  const handleClose = () => setShowUploadModal(false);

  const tableId = 'commodityCodesTable';

  return (
    <Page
      actionButtons={uploadCommodityCodesButton}
      defaultBackUrl={opsSettingsRoutes.opsSettingsIndex.getUrl()}
      tagline="A list of all commodity codes."
      title="Commodity codes"
    >
      <OperationsSettingsSearch onSearch={setSearchQuery} placeholder="Search for a commodity code..." />
      <QueryHandler
        data={nodes}
        error={!!error}
        isLoading={loading}
        loadingComponent={<SkeletonTableLoading columns={tableColumns} order={order} tableId={tableId} />}
      >
        {(commodityCodes: CommodityCodes[]) => {
          return (
            <Table<CommodityCodes>
              columns={tableColumns}
              data={commodityCodes}
              onOrderChange={setOrder}
              order={order}
              paginationInfo={paginationInfo}
              tableId={tableId}
              totalCountConfig={{
                totalCount,
                entityName: 'commodity code'
              }}
            />
          );
        }}
      </QueryHandler>

      <Modal isOpen={showUploadModal} onClose={handleClose} title="Upload commodity codes">
        <UploadCommodityCodes onCancel={handleClose} onComplete={refetch} />
      </Modal>
    </Page>
  );
};

export default ManageCommodityCodes;
