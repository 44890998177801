import cx from 'classnames';
import type { FC } from 'react';

import { Icon, IconConfig } from '@zen/DesignSystem';

import { IndicatorSize, IndicatorType } from './types';

export interface IndicatorIconConfiguration extends IconConfig {
  textColor: string;
}

const iconConfig: Record<IndicatorType, IndicatorIconConfiguration> = {
  [IndicatorType.INCREASING]: {
    icon: 'zicon-arrow-up-oval',
    textColor: 'text-azure-base'
  },
  [IndicatorType.DECREASING]: {
    icon: 'zicon-arrow-down-oval',
    textColor: 'text-red-dark'
  },
  [IndicatorType.EQUAL]: {
    icon: 'zicon-tickoval',
    textColor: 'text-green-dark'
  }
};

const indicatorSize: Record<IndicatorSize, { iconSize: string }> = {
  [IndicatorSize.SMALL]: {
    iconSize: 'text-xs'
  },
  [IndicatorSize.MEDIUM]: {
    iconSize: 'text-base'
  }
};

interface Props {
  className?: string;
  size?: IndicatorSize;
  type: IndicatorType;
}

export const getIndicatorType = (value: number, comparedValue: number): IndicatorType => {
  if (value === comparedValue) {
    return IndicatorType.EQUAL;
  }

  return value > comparedValue ? IndicatorType.INCREASING : IndicatorType.DECREASING;
};

const IndicatorIcon: FC<Props> = ({ className, size = IndicatorSize.MEDIUM, type }) => {
  const { icon, textColor } = iconConfig[type];

  const { iconSize } = indicatorSize[size];
  const iconClassNames: string = cx(textColor, iconSize, className);

  return <Icon className={iconClassNames} icon={icon} />;
};

export default IndicatorIcon;
