import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Icon } from '@zen/DesignSystem';
import { JOURNEY_TIMELINE_WIDTH } from '@zen/Journey';
import type { IconName } from '@zen/Styleguide';

interface Props {
  className?: string;
  expandedContentPosition?: 'above' | 'below';
  iconPosition?: 'right' | 'left';
  isVisible: boolean;
  label: string;
  onChange: (showContent: boolean) => void;
  renderedInJourney?: boolean;
}

const JourneyContentSwitcher: FC<Props> = ({
  className,
  expandedContentPosition = 'below',
  iconPosition = 'left',
  isVisible,
  label,
  onChange,
  renderedInJourney = true
}) => {
  const classNames: string = cx(
    { [JOURNEY_TIMELINE_WIDTH]: renderedInJourney },
    'flex justify-center text-base bg-white relative z-10 py-3'
  );
  const iconClassNames: string = cx(
    {
      'rotate-90': isVisible && expandedContentPosition === 'below',
      '-rotate-180': isVisible && expandedContentPosition === 'above',
      'ml-1': expandedContentPosition === 'above'
    },
    'transform-all duration-300 text-grey-base group-hover:text-grey-dark'
  );

  const renderIcon = (): ReactNode => {
    const icon: IconName = expandedContentPosition === 'below' ? 'zicon-chevron-right-small' : 'zicon-chevron-down-small';

    return (
      <div className={classNames}>
        <Icon className={iconClassNames} icon={icon} />
      </div>
    );
  };

  return (
    <div
      className={cx('inline-flex items-center cursor-pointer text-grey-base group', className)}
      data-testid="journey-content-switcher"
      onClick={() => onChange(!isVisible)}
    >
      {iconPosition === 'left' && renderIcon()}
      <div className="flex text-sm transition-all duration-300 group-hover:text-grey-dark">{label}</div>
      {iconPosition === 'right' && renderIcon()}
    </div>
  );
};

export default JourneyContentSwitcher;
