import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type UpdateLocationAddressMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.UpdateAccountLocationAddressInput;
}>;

export type UpdateLocationAddressMutationResult = { __typename?: 'Mutation' } & {
  updateAccountLocationAddress?: GraphQLTypes.Maybe<
    { __typename?: 'UpdateAccountLocationAddressPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const UpdateLocationAddressDocument = /* #__PURE__ */ gql`
  mutation updateLocationAddress($input: UpdateAccountLocationAddressInput!) {
    updateAccountLocationAddress(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useUpdateLocationAddressMutation__
 *
 * To run a mutation, you first call `useUpdateLocationAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationAddressMutation, { data, loading, error }] = useUpdateLocationAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLocationAddressMutationResult, UpdateLocationAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<UpdateLocationAddressMutationResult, UpdateLocationAddressMutationVariables>(
    UpdateLocationAddressDocument,
    options
  );
}
export type UpdateLocationAddressMutationHookResult = ReturnType<typeof useUpdateLocationAddressMutation>;
