import { dateDifference, parseDate } from '../jodaConnector';
import type { ComparableDate, DateDifference, DateUnit } from '../types';

export const compareDate = (date: string): ComparableDate => {
  const parsedDate = parseDate(date);

  const isAfter = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isAfter(comparedDate);
  };

  const isBefore = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isBefore(comparedDate);
  };

  const isEqual = (comparator: string): boolean => {
    const comparedDate = parseDate(comparator);

    return parsedDate.isEqual(comparedDate);
  };

  const isSame = (comparator: string, unit: DateUnit) => {
    const comparedDate = parseDate(comparator);

    if (unit === 'day') {
      return parsedDate.dayOfMonth() === comparedDate.dayOfMonth();
    }

    if (unit === 'month') {
      return parsedDate.month().equals(comparedDate.month());
    }

    return parsedDate.year() === comparedDate.year();
  };

  return {
    isAfter,
    isBefore,
    isEqual,
    isSame
  };
};

export const getRelativeDateDifference = (fromDate: string, toDate?: string): DateDifference => {
  const now = new Date().toISOString();
  const parsedFromDate = parseDate(fromDate);
  const parsedToDate = parseDate(toDate || now);
  const difference = dateDifference(parsedFromDate, parsedToDate);

  return {
    days: difference.days(),
    months: difference.months(),
    years: difference.years()
  };
};
