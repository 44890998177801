import type { FC } from 'react';

import type { Address } from '@zen/Accounts/AccountForms/types';
import { joinNotNull } from '@zen/utils/utils';

const AccountAddress: FC<Address> = (props) => {
  const { city, country, countyOrState, line1, line2, postalCodeOrZip } = props;

  return (
    <div>
      <div>{joinNotNull([line1, line2, city], ', ')}</div>
      <div>{joinNotNull([countyOrState, postalCodeOrZip?.toUpperCase()], ', ')}</div>
      <div>{country?.name}</div>
    </div>
  );
};

export default AccountAddress;
