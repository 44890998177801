import type { Nullable, Undefinable } from '@zen/utils/typescript';
import { convertStringToNumber } from '@zen/utils/utils';

export const getInputDisplayValue = (
  newValue: Nullable<number>,
  fixedDecimalNumber: Undefinable<number>
): Nullable<number | string> => {
  return fixedDecimalNumber && newValue && typeof newValue === 'number' ? newValue.toFixed(fixedDecimalNumber) : newValue;
};

export const getInputSubmitValue = (value: Nullable<number | string>): Nullable<number> => {
  return typeof value === 'string' ? convertStringToNumber(value) : value;
};

export const checkIfValueChanged = (value: number, newValue: Nullable<string | number>): boolean => {
  const valueToOverride: Nullable<number> = typeof newValue === 'string' ? convertStringToNumber(newValue) : newValue;

  return valueToOverride !== value;
};
