import type { FC, ReactNode } from 'react';

interface Props {
  className?: string;
  content: string | ReactNode;
  label: string;
}

const QuoteOptionItemField: FC<Props> = (props) => {
  const { label, content, className = '' } = props;

  return (
    <div className={`leading-normal ${className}`}>
      <span className="text-sm text-grey-dark font-bold">{label}:</span> {content}
    </div>
  );
};

export default QuoteOptionItemField;
