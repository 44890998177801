import { Issue, IssueSeverity, IssueState } from '@zen/Issues/types';

export const sectionTitle: Record<IssueSeverity | IssueState.RESOLVED, string> = {
  [IssueSeverity.HIGH]: 'Severe',
  [IssueSeverity.MEDIUM]: 'Moderate',
  [IssueSeverity.LOW]: 'Minor',
  [IssueState.RESOLVED]: 'Resolved'
};

const severityMapping: Record<IssueSeverity, number> = {
  HIGH: 1,
  MEDIUM: 2,
  LOW: 3
};

const stateMapping: Record<IssueState, number> = {
  OPEN: 1,
  STARTED: 2,
  RESOLVED: 3,
  ARCHIVED: 4
};

export const issueSeverityComparator = (issueLeft: Issue, issueRight: Issue): number => {
  if (severityMapping[issueLeft.severity] < severityMapping[issueRight.severity]) {
    return -1;
  }
  if (severityMapping[issueLeft.severity] > severityMapping[issueRight.severity]) {
    return 1;
  }

  return 0;
};

export const issueStateComparator = (issueLeft: Issue, issueRight: Issue): number => {
  if (stateMapping[issueLeft.state] < stateMapping[issueRight.state]) {
    return -1;
  }
  if (stateMapping[issueLeft.state] > stateMapping[issueRight.state]) {
    return 1;
  }

  return 0;
};

export const sortResolvedIssueByDescDate = (issues: Issue[]): Issue[] => {
  const sortedResolvedIssuesByDescData = issues
    .filter((issue: Issue) => issue.state === IssueState.RESOLVED)
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    .sort((issueA: Issue, issueB: Issue) => +new Date(issueB.openedAt.date) - +new Date(issueA.openedAt.date));

  return [...issues.filter((issue: Issue) => issue.state !== IssueState.RESOLVED), ...sortedResolvedIssuesByDescData];
};
