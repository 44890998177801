import pDebounce from 'p-debounce';
import type { FC } from 'react';

import { AsyncSelect } from '@zen/DesignSystem';
import { SEARCH_DEBOUNCE_DELAY } from '@zen/utils/constants';
import type { Nullable } from '@zen/utils/typescript';

import { useGetCostTrackingLocationsLazyQuery } from './graphql';
import { getLocationOptionLabel } from './helpers';
import { type CostTrackingLocation, CostTrackingLocationType } from './types';

interface Props {
  hasError?: boolean;
  isLoading?: boolean;
  label?: string;
  locationType?: CostTrackingLocationType;
  name?: string;
  onChange: (location: Nullable<CostTrackingLocation>) => void;
  placeholder?: string;
  value?: Nullable<CostTrackingLocation>;
}

const CostTrackingLocationSelect: FC<Props> = (props) => {
  const { locationType = CostTrackingLocationType.TERMINAL, value, ...rest } = props;
  const [fetchLocations] = useGetCostTrackingLocationsLazyQuery();

  const handleInputChange = async (query: string): Promise<CostTrackingLocation[]> => {
    const response = await fetchLocations({
      variables: {
        costTrackingLocationType: locationType,
        textContains: query
      }
    });

    return (response?.data?.getCostTrackingLocations?.nodes || []) as CostTrackingLocation[];
  };

  const debouncedHandleInputChange = pDebounce(handleInputChange, SEARCH_DEBOUNCE_DELAY);

  return (
    <div data-testid="cost-tracking-location-select">
      <AsyncSelect
        {...rest}
        defaultOptions={value ? [value] : undefined}
        formatOptionLabel={getLocationOptionLabel}
        loadOptions={debouncedHandleInputChange}
        optionKey="id"
        value={value}
      />
    </div>
  );
};

export type { Props as CostTrackingLocationSelectProps };

export default CostTrackingLocationSelect;
