import type { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TabConfig, Tabs } from '@zen/DesignSystem';

export interface RouteTabConfig {
  className?: string;
  component: ReactNode;
  isDefault?: boolean;
  label: ReactNode;
  path: string;
  totalCount?: number;
  url: string;
}

interface Props {
  tabsConfig: RouteTabConfig[];
}

const RouteTabs: FC<Props> = ({ tabsConfig }) => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const prepareConfigForTabs = (config: RouteTabConfig[]): TabConfig[] => {
    return config.map(({ label, totalCount, className, url }: RouteTabConfig): TabConfig => {
      return {
        isActive: pathname.includes(url),
        label,
        value: `${url}${search}`,
        totalCount,
        className
      };
    });
  };

  const handleTabClick = (tab: string): void => navigate(tab);

  return <Tabs onTabClick={handleTabClick} tabsConfig={prepareConfigForTabs(tabsConfig)} />;
};

export default RouteTabs;
