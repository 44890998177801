import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../graphql/types.generated';

const defaultOptions = {} as const;

export type DeleteCargoItemMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.DeleteCargoItemInput;
}>;

export type DeleteCargoItemMutationResult = { __typename?: 'Mutation' } & {
  cargoDeleteCargoItem?: GraphQLTypes.Maybe<
    { __typename?: 'DeleteCargoItemPayload' } & {
      errors?: GraphQLTypes.Maybe<Array<{ __typename?: 'Error' } & Pick<GraphQLTypes.Error, 'message' | 'path'>>>;
    }
  >;
};

export const DeleteCargoItemDocument = /* #__PURE__ */ gql`
  mutation deleteCargoItem($input: DeleteCargoItemInput!) {
    cargoDeleteCargoItem(input: $input) {
      errors {
        message
        path
      }
    }
  }
`;

/**
 * __useDeleteCargoItemMutation__
 *
 * To run a mutation, you first call `useDeleteCargoItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCargoItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCargoItemMutation, { data, loading, error }] = useDeleteCargoItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCargoItemMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCargoItemMutationResult, DeleteCargoItemMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<DeleteCargoItemMutationResult, DeleteCargoItemMutationVariables>(DeleteCargoItemDocument, options);
}
export type DeleteCargoItemMutationHookResult = ReturnType<typeof useDeleteCargoItemMutation>;
