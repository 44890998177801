import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetBookingCoordinatorsByTypeQueryVariables = GraphQLTypes.Exact<{
  managerType?: GraphQLTypes.InputMaybe<GraphQLTypes.ManagerTeam>;
}>;

export type GetBookingCoordinatorsByTypeQueryResult = { __typename?: 'Query' } & {
  bookingManagers: Array<
    { __typename?: 'BookingManager' } & Pick<GraphQLTypes.BookingManager, 'id' | 'firstName' | 'lastName' | 'teams'>
  >;
};

export const GetBookingCoordinatorsByTypeDocument = /* #__PURE__ */ gql`
  query getBookingCoordinatorsByType($managerType: ManagerTeam) {
    bookingManagers(managerType: $managerType) {
      id
      firstName
      lastName
      teams
    }
  }
`;

/**
 * __useGetBookingCoordinatorsByTypeQuery__
 *
 * To run a query within a React component, call `useGetBookingCoordinatorsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingCoordinatorsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingCoordinatorsByTypeQuery({
 *   variables: {
 *      managerType: // value for 'managerType'
 *   },
 * });
 */
export function useGetBookingCoordinatorsByTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBookingCoordinatorsByTypeQueryResult, GetBookingCoordinatorsByTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetBookingCoordinatorsByTypeQueryResult, GetBookingCoordinatorsByTypeQueryVariables>(
    GetBookingCoordinatorsByTypeDocument,
    options
  );
}
export function useGetBookingCoordinatorsByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBookingCoordinatorsByTypeQueryResult, GetBookingCoordinatorsByTypeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetBookingCoordinatorsByTypeQueryResult, GetBookingCoordinatorsByTypeQueryVariables>(
    GetBookingCoordinatorsByTypeDocument,
    options
  );
}
export type GetBookingCoordinatorsByTypeQueryHookResult = ReturnType<typeof useGetBookingCoordinatorsByTypeQuery>;
export type GetBookingCoordinatorsByTypeLazyQueryHookResult = ReturnType<typeof useGetBookingCoordinatorsByTypeLazyQuery>;
