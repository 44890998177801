import { ContainerTypeEnum, Currency } from '@zen/Cargo';
import type { Option } from '@zen/DesignSystem';
import type {
  ContainerChargeInput,
  ContainerChargePayload,
  FreightChargePayload
} from '@zen/RateCards/LegacyRateCards/RateCardForm/reducer/types';
import { containerTypeLabelMapping } from '@zen/types';

import type { ContainerCharge, FreightChargeFormInitialValues, FreightChargeFormType, InitialContainerCharge } from './types';

export const preparePayload = (values: FreightChargeFormType): FreightChargePayload => {
  const containerCharges: ContainerChargeInput[] = values.containerCharges.map(
    ({ containerValue, unitType }: ContainerCharge) => ({
      currency: containerValue.currency,
      chargeValue: containerValue.value,
      unitType
    })
  );

  return { ...values, containerCharges };
};

export const prepareInitialValues = (values: FreightChargePayload): FreightChargeFormInitialValues => {
  const containerCharges: InitialContainerCharge[] = values.containerCharges.map(
    ({ currency, chargeValue, unitType }: ContainerChargePayload) => ({
      containerValue: {
        value: chargeValue,
        currency
      },
      unitType
    })
  );

  return { ...values, containerCharges };
};

const containerTypes: ContainerTypeEnum[] = [
  ContainerTypeEnum.X20DV,
  ContainerTypeEnum.X40DV,
  ContainerTypeEnum.X40HC,
  ContainerTypeEnum.X45HC
];

export const getAvailableContainerTypeOptions = (selectedContainerTypes: ContainerTypeEnum[]): Option<ContainerTypeEnum>[] => {
  const availableContainerCharges: ContainerTypeEnum[] = getAvailableContainerCharges(selectedContainerTypes);

  return availableContainerCharges.map((containerType: ContainerTypeEnum) => {
    return {
      label: containerTypeLabelMapping[containerType].short,
      value: containerType
    };
  });
};

export const getEmptyContainerCharge = (selectedContainerTypes: ContainerTypeEnum[] = []): InitialContainerCharge => {
  const availableContainerCharges: ContainerTypeEnum[] = getAvailableContainerCharges(selectedContainerTypes);

  return {
    containerValue: { currency: Currency.USD, value: null },
    unitType: availableContainerCharges[0]
  };
};

export const hasAvailableContainerCharge = (selectedContainerTypes: ContainerTypeEnum[]): boolean => {
  return getAvailableContainerCharges(selectedContainerTypes).length > 0;
};

const getAvailableContainerCharges = (selectedContainerTypes: ContainerTypeEnum[]): ContainerTypeEnum[] => {
  return containerTypes.filter((containerType: ContainerTypeEnum) => !selectedContainerTypes.includes(containerType));
};
