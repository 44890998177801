import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking, isOceanBooking } from '@zen/Booking';
import type { Optional } from '@zen/utils/typescript';

import type { Stop } from '../forms/PlannedScheduleForm/types';
import { type ScheduledLegInput, type VehiclePropertyInput, VehiclePropertyNameEnum } from './types';

export const getVehicleProperties = (stop: Stop, modeOfTransport: Optional<ModeOfTransport>): VehiclePropertyInput[] => {
  if (isOceanBooking(modeOfTransport)) {
    return [
      {
        name: VehiclePropertyNameEnum.VESSEL_NAME,
        value: stop.vesselName
      },
      {
        name: VehiclePropertyNameEnum.VOYAGE_NUMBER,
        value: stop.voyageNumber
      }
    ];
  }

  if (isAirBooking(modeOfTransport)) {
    return [
      {
        name: VehiclePropertyNameEnum.FLIGHT_NUMBER,
        value: stop.flightNumber
      }
    ];
  }

  return [];
};

export const prepareLegs = (stops: Stop[], modeOfTransport: Optional<ModeOfTransport>): ScheduledLegInput[] => {
  return stops.reduce((prev: ScheduledLegInput[], stop: Stop, index: number): ScheduledLegInput[] => {
    const isLastStop: boolean = stops.length - 1 === index;

    const nextStop = stops[index + 1];

    const { departureDate, location } = stop;

    if (isLastStop) return prev;

    return [
      ...prev,
      {
        fromRequestedDate: {
          startDateTime: {
            date: departureDate
          }
        },
        fromUnlocode: location?.unlocode || '',
        toRequestedDate: {
          startDateTime: {
            date: nextStop.arrivalDate
          }
        },
        toUnlocode: nextStop.location?.unlocode || '',
        vehicleProperties: getVehicleProperties(stop, modeOfTransport)
      }
    ];
  }, []);
};
