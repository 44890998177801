import cx from 'classnames';
import pluralize from 'pluralize';

import { isVehicleMilestone } from '@zen/Journey/helpers';
import { formatDate, formatDateAndTimeRange, formatTime, formatTimeZone } from '@zen/utils/dateTime';
import type { Nullable } from '@zen/utils/typescript';

import { JourneyShippingMilestone, JourneyShippingMilestoneNameEnum, LocalDateTimeType, MilestoneDatesType } from '../../types';
import type { MilestoneDatesFormatted } from './types';

export const formatActualDate = (actualDateAndTime: LocalDateTimeType): string => {
  const formattedDate: string = formatDate(actualDateAndTime.date);

  if (actualDateAndTime?.time) {
    return `${formattedDate}, ${formatTime(actualDateAndTime.time, false)}`;
  }

  return formattedDate;
};

export const formatDatesAndTimeZones = (dates: MilestoneDatesType): MilestoneDatesFormatted => {
  const { actual, initialEstimate, latestEstimate } = dates;

  return {
    actual: actual ? { date: formatActualDate(actual), timeZone: formatTimeZone(actual.date, actual.timeZone) } : null,
    initialEstimate: initialEstimate
      ? {
          date: formatDateAndTimeRange(initialEstimate.startDateTime, initialEstimate.endDateTime),
          timeZone: formatTimeZone(initialEstimate.startDateTime.date, initialEstimate.startDateTime.timeZone)
        }
      : null,
    latestEstimate: latestEstimate
      ? {
          date: formatDateAndTimeRange(latestEstimate.startDateTime, latestEstimate.endDateTime),
          timeZone: formatTimeZone(latestEstimate.startDateTime.date, latestEstimate.startDateTime.timeZone)
        }
      : null
  };
};

export const getPlaceholderText = (milestone: JourneyShippingMilestone): string => {
  const { canManageDates, canManageDatesCurrently, name, milestoneType } = milestone;

  const isDateEditable: boolean = !!canManageDates && !!canManageDatesCurrently;

  if (isDateEditable) return 'Enter date';

  if (isCargoReadyDate(name) || (isVehicleMilestone(milestoneType) && !isVehicleDepartedFromWarehouse(name))) {
    return 'pending estimate';
  }

  return '';
};

export const getTriggerClassNames = (isPopoverVisible: boolean, isEditable: boolean) => {
  return cx(
    {
      'border border-solid border-transparent': !isPopoverVisible,
      'bg-white border-solid border border-azure-base text-grey-dark': isPopoverVisible,
      'hover:bg-grey-dark/[.08] hover:text-grey-base': isEditable && !isPopoverVisible,
      'cursor-pointer': isEditable
    },
    'p-2 rounded transition-opacity duration-300'
  );
};

export const isCargoReadyDate = (name: JourneyShippingMilestoneNameEnum) =>
  name === JourneyShippingMilestoneNameEnum.CARGO_READY_DATE;

export const isVehicleDepartedFromWarehouse = (name: JourneyShippingMilestoneNameEnum) =>
  name === JourneyShippingMilestoneNameEnum.VEHICLE_DEPARTED_FROM_WAREHOUSE;

export const formatDelay = (delayInDays: number, noDelayMessage: Nullable<string> = null): Nullable<string> => {
  const absoluteNumber = Math.abs(delayInDays);

  if (delayInDays === 0) {
    return noDelayMessage;
  }

  return `${absoluteNumber} ${pluralize('day', absoluteNumber)} ${delayInDays > 0 ? 'late' : 'early'}`;
};
