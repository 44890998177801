import * as Yup from 'yup';

import type { ChargeType } from '@zen/Components/Form/FormChargeTypeSelect';
import type { Nullable } from '@zen/utils/typescript';

const chargeItemValidation = Yup.array().of(
  Yup.object().shape({
    name: Yup.string().nullable().required('Please add a name.'),
    quantity: Yup.number().nullable().positive('Must be greater than 0.').required('Please add a quantity.'),
    unitPrice: Yup.object().shape({
      value: Yup.number().nullable().required('Please enter a unit price.')
    }),
    paidFrom: Yup.string().nullable().required('Please specify who it’s paid from.'),
    paidTo: Yup.string().nullable().required('Please specify who it’s paid to.')
  })
);

export const validationSchema: Yup.ObjectSchema<{}> = Yup.object().shape({
  chargeType: Yup.object().nullable().required('Please select a charge type.'),
  applicability: Yup.string()
    .nullable()
    .when('chargeType', {
      is: (val: Nullable<ChargeType>) => !!val,
      then: (schema) => schema.required('Please select where the charge type applies at.')
    }),
  costItems: chargeItemValidation,
  revenueItems: chargeItemValidation
});
